<div class="modal-body-income">
  <div class="row teste">
    <div class="col text-right">
      <button
        class="close"
        mat-button
        matSuffix
        mat-icon-button
        aria-label="Close"
        (click)="closeModal()"
        appTagger
        [event_category]="category"
        event_label="fechar"
      >
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <div class="">
    <div class="icon-row">
      <div class="row">
        <div class="col">
          <img src="./assets/Icon-cash.svg" alt="" />
        </div>
      </div>
    </div>

    <div class="row-title">
      <div class="row">
        <div class="col text-left title">
          {{ 'STEP-SIMULATION-MODAL-INCOME-TITLE' | translate }}
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col text-left subtitle">
        {{ 'STEP-SIMULATION-MODAL-INCOME-SUBTITLE' | translate }}
      </div>
    </div>

    <div class="row-input">
      <div class="row">
        <div class="col text-left info">
          <mat-form-field class="w-100">
            <mat-label>{{ 'STEP-SIMULATION-MODAL-INCOME-INFO-INCOME' | translate }}</mat-label>
            <input
              class="text-left input-value"
              maxlength="13"
              matInput
              currencyMask
              [(ngModel)]="inputValue"
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
              [disabled]="nextStepButton"
              appTagger
              [event_category]="category"
              event_label="Informe sua renda mensal"
              [event_action]="Insert"
            />

            <button
              *ngIf="!nextStepButton"
              class="info"
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="clear()"
            >
              <mat-icon>info</mat-icon>
            </button>
            <ng-container *ngIf="preAnalysis$ | async as preAnalysis">
              <mat-icon *ngIf="preAnalysis?.IsUpdateMaxFinancedValue === 'false'" matSuffix class="sufix-icon error"
                >warning
              </mat-icon>
              <mat-icon *ngIf="preAnalysis?.IsUpdateMaxFinancedValue === 'true'" matSuffix class="sufix-icon success"
                >check_circle
              </mat-icon>
            </ng-container>
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="remember">
      <div class="row">
        <div class="col text-left">
          <span>
            {{ 'STEP-SIMULATION-MODAL-INCOME-REMEMBER' | translate }}
          </span>
        </div>
      </div>
    </div>

    <ng-container
      *ngIf="preAnalysis$ | async as preAnalysis"
      appTagger
      [event_category]="category"
      [event_label]="
        preAnalysis?.IsUpdateMaxFinancedValue === 'true'
          ? 'novo limite pré-aprovado'
          : ' não foi possível aumentar o limite'
      "
      [event_action]="pageView"
    >
      <div *ngIf="preAnalysis?.IsUpdateMaxFinancedValue === 'true'" class="card-new-limit success">
        Novo limite de <strong>{{ preAnalysis.maxFinancedValue | localeCurrency }}</strong> pré-aprovado.
      </div>
      <div *ngIf="preAnalysis?.IsUpdateMaxFinancedValue === 'false'" class="card-new-limit error">
        Não foi possível aumentar o limite no momento.
      </div>
    </ng-container>

    <div class="row-button" *ngIf="!nextStepButton">
      <div class="row">
        <div class="col text-center">
          <button
            mat-flat-button
            id="modal-cancel-button"
            color="primary"
            class="button-white w-100 h-50"
            mat-mat-flat-button
            color="primary"
            (click)="submitIdentification()"
            [disabled]="inputValue === 0 || !inputValue"
            appTagger
            [event_category]="category"
            event_label="{{ 'STEP-SIMULATION-MODAL-INCOME-BUTTON-CALCULATE' | translate }}"
          >
            {{ 'STEP-SIMULATION-MODAL-INCOME-BUTTON-CALCULATE' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="row-button" *ngIf="nextStepButton">
      <div class="row">
        <div class="col text-center">
          <button
            mat-flat-button
            id="modal-cancel-button"
            color="primary"
            class="button-white w-100 h-50"
            mat-mat-flat-button
            color="primary"
            (click)="continueButton()"
            appTagger
            [event_category]="category"
            event_label=" {{ 'STEP-SIMULATION-MODAL-INCOME-BUTTON-CONTINUE' | translate }}"
          >
            {{ 'STEP-SIMULATION-MODAL-INCOME-BUTTON-CONTINUE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
