import { createFeatureSelector } from '@ngrx/store';

export interface AlertState {
  alerts: any;
}

export const initialAlertState: AlertState = {
  alerts: []
};

export const alertsSelector = createFeatureSelector<AlertState>('alert');
