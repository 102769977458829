<div class="container store-list">
  <div class="row">
    <div class="col-12">
      <app-stores-list-filters (selectedFilters)="loadStores($event)"></app-stores-list-filters>
    </div>
    <div class="col-12">
      <mat-list *ngIf="stores && stores.length > 0">
        <mat-list-item class="store-line" *ngFor="let store of stores" (click)="selectStore(store)">
          <div class="row">
            <div class="col-10">
              <div class="store-name">{{ store.name }}</div>
              <div class="store-details text-secondary">{{ getAddress(store.address) }}</div>
              <div class="store-details text-secondary">{{ getPhone(store.phone) }}</div>
            </div>
            <div class="col-2 my-auto text-right">
              <img class="svg" src="./assets/ic-arrow-right.svg" alt="Select Store" />
            </div>
          </div>
        </mat-list-item>
      </mat-list>
    </div>
  </div>
</div>
