import { BestOffer } from './../../models/best-offer.model';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import { GetNppInstallments } from '@app/core/state/simulation/simulation.actions';
import { getBestOffer, getNppInstallments } from '@app/core/state/simulation/simulation.selectors';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Countries } from '@shared/constants/countries.enum';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'installments-modal',
  templateUrl: './installments-modal.component.html',
  styleUrls: ['./installments-modal.component.scss']
})
export class InstallmentsModalComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();

  public countries = Countries;
  public flexPlanType: string;
  public defaultInstallmentValue: number;
  public installmentQuantity: number;
  public quantityInstallment: number;
  public firstInstallmentValue
  installments: any;
  customInstallmentValue;
  installmentQuantityFixed

  currentCountry: string;
  public monthsRange: string;
  public currentUrl = this.router.url;
  public lastInstallmentsValue : number;
  public flexPlanList = [];
  public isNPP = JSON.parse(environment.useNPP);
  public installmentPaymentMethodsAmount;
  public installmentPaymentMethodsValue;

  readonly category = '/portallojista/pagamentos/detalhe/parcelas';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public router: Router,
    public dialogRef: MatDialogRef<InstallmentsModalComponent>,
    private genTagger: GenTagger,
    private translate: TranslateService,
    private store$: Store<AppState>
  ) {}

  ngOnInit() {
    this.store$
      .pipe(
        select(getConfirmationData),
        filter(data => !!data),
        take(1)
      )
      .subscribe(state => {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: `${Tag.AbriuModal} - Parcelas`,
          event_label: this.translate.instant('INSTALLMENT-MODAL-DESC')
        });
      });

    this.currentCountry = this.data.country;

    if (this.isNPP) {
      this.store$
        .select(getNppInstallments)
        .pipe(
          filter(data => !!data),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(installments => {
          this.installments = installments.installments;
          setTimeout(() => {
            this.data.close();
          }, 3000);
        });
      this.store$
        .select(selectProposalId)
        .pipe(
          filter(data => !!data),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(proposalId => {
          this.store$.dispatch(new GetNppInstallments(proposalId));
        });
        this.getValuesPlanFelx();
    } else {
      this.installments = this.data.installments;
      setTimeout(() => {
        this.data.close();
      }, 3000);
    }

  }

  private getValuesPlanFelx() {
    this.store$
      .select(getBestOffer)
      .pipe(
        filter(data => !!data),
        takeUntil(this.ngUnsubscribe)
      ).subscribe(res => {
        this.flexPlanType = res?.bestOffer.flexPlanType;
        this.monthsRange = res?.bestOffer.paymentMethods[0]?.monthsRange[0]?.description;
        this.defaultInstallmentValue = res?.bestOffer?.defaultInstallmentValue;
        this.lastInstallmentsValue = res?.bestOffer?.paymentMethods[0]?.lastInstallmentValue;
        this.quantityInstallment = res?.bestOffer?.installmentQuantity;
        this.firstInstallmentValue =  res?.bestOffer?.paymentMethods[0]?.firstInstallmentValue
        this.installmentQuantityFixed = res?.bestOffer?.fixedInstallmentQuantity;

        this.installmentQuantity =  res?.bestOffer?.paymentMethods[0]?.installmentDefaultAmount;
        this.installmentPaymentMethodsAmount = res?.bestOffer.paymentMethods[0]?.installmentPaymentMethodsAmount;
        this.installmentPaymentMethodsValue = res?.bestOffer.paymentMethods[0]?.installmentPaymentMethodsValue;
        this.flexPlanList = res?.flexPlanList;
        this.customInstallmentValue = res?.bestOffer?.customInstallmentValue;
        this.defaultInstallmentValue = res?.bestOffer?.defaultInstallmentValue
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  openInstallmentsModalFlex() {
    this.dialogRef.close({ openInstallmentFlex: true });
  }
}
