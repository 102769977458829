import { LoginActions, LoginActionTypes } from './login.actions';

export enum FType {
  PASSWORDRECOVERY = 'password-recovery',
  SIGNUP = 'sign-up'
}

export interface DocumentType {
  id: any;
  documentMaxLength: any;
  documentMinLength: any;
  loginTypeId: any;
  loginDescription: any;
  personType: any;
  documentFlag: any;
  documentId: any;
}

export interface LoginState {
  user: {
    documentNumber: string;
  };
  errors: any[];
  recoveryId: string;
  challengeType: string;
  challengeResponse: 'approved' | 'rejected';
  passwordChangeOutcome: 'success' | 'failure';
  daysToExpire: any;
  isShowModalDaysToExpire: boolean;
  showTutorial: boolean;
  showTutorialPrt: boolean;
  showTutorialSmula: boolean;
  showTutorialPgto: boolean;
  showTutorialForml: boolean;
  showUpData: any;
  termId: any;
  showTerms: boolean;
  loginType: DocumentType[];
  loginForm: any;
  ftype: FType;
}

export const initialLoginState: LoginState = {
  user: {
    documentNumber: null
  },
  errors: [],
  recoveryId: null,
  challengeType: null,
  challengeResponse: null,
  passwordChangeOutcome: null,
  daysToExpire: null,
  isShowModalDaysToExpire: false,
  showTutorial: null,
  showTutorialPrt: null,
  showTutorialSmula: null,
  showTutorialPgto: null,
  showTutorialForml: null,
  showUpData: null,
  termId: null,
  showTerms: false,
  loginType: null,
  loginForm: null,
  ftype: null
};

export function loginReducer(state = initialLoginState, action: LoginActions): LoginState {
  switch (action.type) {
    case LoginActionTypes.REMEMBER_USER: {
      return { ...state, user: { documentNumber: action.payload } };
    }

    case LoginActionTypes.FORGET_USER: {
      return { ...state, user: { documentNumber: null } };
    }

    case LoginActionTypes.SUBMIT_RECOVERY_TOKEN_SUCCESS:
    case LoginActionTypes.SUBMIT_RECOVERY_TOKEN_ERROR: {
      return {
        ...state,
        challengeResponse: action.payload.status
      };
    }

    case LoginActionTypes.REFRESH_RECOVERY_TOKEN: {
      return {
        ...state,
        challengeResponse: null
      };
    }

    case LoginActionTypes.REVOKE_TOKEN_SUCCESS: {
      return {
        ...state,
        errors: [...state.errors, 'expired_password']
      };
    }
    // case LoginActionTypes.SUBMIT_NEW_PASSWORD_SUCCESS: {
    //   return { ...initialLoginState };
    // }
    // case LoginActionTypes.SUBMIT_NEW_PASSWORD_ERROR: {
    //   return {
    //     ...state,
    //     passwordChangeOutcome: action.payload
    //   };
    // }

    case LoginActionTypes.RESET_LOGIN_DATA: {
      return { ...initialLoginState };
    }
    case LoginActionTypes.RESET_ERRORS: {
      return {
        ...state,
        errors: []
      };
    }
    case LoginActionTypes.SUBMIT_LOGIN_DATA_ERROR: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }

    case LoginActionTypes.SUBMIT_LOGIN_DATA_BLOCK: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }
    case LoginActionTypes.SUBMIT_LOGIN_DATA_INCORRECT: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }
    case LoginActionTypes.GET_RECOVERY_TOKEN_SUCCESS: {
      return {
        ...state,
        recoveryId: action.payload.recoveryId
      };
    }

    case LoginActionTypes.REFRESH_RECOVERY_TOKEN_ERROR: {
      return {
        ...state,
        recoveryId: null
      };
    }

    case LoginActionTypes.GET_USER_PROPERTIES_SUCCESS: {
      return {
        ...state,
        daysToExpire: action.payload.daysToExpire,
        isShowModalDaysToExpire: action.payload.isShowModalDaysToExpire,
        showTerms: action.payload.showTerms,
        showUpData: action.payload.showUpData,
        termId: action.payload.termId,
        showTutorial: action.payload.showTutorial,
        showTutorialPrt: action.payload.showTutorialPrt,
        showTutorialSmula: action.payload.showTutorialSmula,
        showTutorialPgto: action.payload.showTutorialPgto,
        showTutorialForml: action.payload.showTutorialForml
      };
    }

    case LoginActionTypes.DOCUMENT_TYPES_REQUEST_SUCCESS: {
      return {
        ...state,
        loginType: action.payload
      };
    }

    case LoginActionTypes.SUBMIT_LOGIN_DATA: {
      return {
        ...state,
        errors: [],
        loginForm: action.payload
      };
    }

    case LoginActionTypes.SET_TUTORIAL_FLAG_TO_FALSE: {
      return {
        ...state,
        showTutorial: action.payload === 'menu' ? false : state.showTutorial,
        showTutorialPrt: action.payload === 'proposal' ? false : state.showTutorialPrt,
        showTutorialSmula: action.payload === 'simulation' ? false : state.showTutorialSmula,
        showTutorialPgto: action.payload === 'payment' ? false : state.showTutorialPgto,
        showTutorialForml: action.payload === 'formalization' ? false : state.showTutorialForml
      };
    }

    case LoginActionTypes.SET_FTYPE: {
      return {
        ...state,
        ftype: action.payload
      };
    }

    default:
      return state;
  }
}
