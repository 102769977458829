<div class="border upload-card p-3 rounded mt-4 mb-4">
  <div class="row vertical p-2">
    <div class="vertical">
      <img src="./assets/images/icon_download.svg" class="align-self-center download-icon" alt="download icon" />
    </div>
    <div class="vertical">
      <h4 class="font-weight-bold vertical ml-1 mb-3 mt-1 mb-0">{{ checklistItem?.document?.name | titlecase }}*</h4>
      <h5 class="vertical ml-1 mt-3">
        {{ 'FORMALIZATION-STEP-DOCUMENT-UPLOAD-ACCEPTED-FORMATS' | translate }} -
        {{ 'MAX-FILE-SIZE-ALLOWED-MSG' | translate: { maxSize: maxSizeAllowed } }}
      </h5>
    </div>

    <div
      *ngIf="checklistItem?.document?.description"
      class="vertical info-button"
      [ngClass]="!isPendency ? 'd-sm-none' : ''"
    >
      <span
        matTooltip="{{ checklistItem?.document?.description }}"
        matTooltipClass="custom-tooltip"
        [matTooltipPosition]="'before'"
        [matTooltipShowDelay]="10"
        [matTooltipHideDelay]="3000"
        class="material-icons"
      >
        error_outline
      </span>
    </div>
  </div>

  <div class="mt-2">
    <!-- SUCCESSFULLY UPLOADED FILES LIST -->
    <ng-container *ngFor="let file of filesSuccess">
      <div class="row no-gutters file-row">
        <div class="col-10">
          <h4 class="d-inline">
            {{ file?.name }}.pdf <span class="ml-3">{{ file?.sizeArtifact | truncateSize }}</span>
          </h4>
        </div>

        <div class="col-1">
          <img src="./assets/images/ic_check.svg" class="success-icon" alt="check icon" />
        </div>

        <div class="col-1">
          <img
            src="./assets/images/ic_lixeira.svg"
            class="delete-icon"
            (click)="onDeleteClick(file)"
            alt="delete icon"
            [event_category]="category"
            event_label="Deletou - {{ checklistItem?.document?.name | titlecase }}"
            [event_action]="Insert"
          />
        </div>
      </div>
    </ng-container>

    <!-- FILES WITH ERROR ON UPLOAD -->
    <ng-container *ngFor="let file of filesError">
      <div class="row no-gutters">
        <div class="col-11">
          <h4 class="d-inline">{{ file?.name }}</h4>
        </div>
        <div class="col-1">
          <span class="material-icons text-danger">
            warning
          </span>
        </div>
      </div>
      <div class="row no-gutters">
        <h4 class="error-msg col-12">{{ file?.errorMsg }}</h4>
      </div>
    </ng-container>
  </div>

  <div class="row p-0 mt-1">
    <div class="col-6 col-sm-12">
      <button
        class="btn btn-block attach-btn"
        (click)="handleUploadMethod('attach')"
        [disabled]="disableAttachButton || filesSuccess.length > 0"
        mat-button
        color="primary"
        appTagger
        [event_category]="category"
        event_label="{{ 'ATTACH' | translate }} - {{ checklistItem?.document?.name | titlecase }}"
        [event_action]="Insert"
      >
        {{ 'ATTACH' | translate }}
      </button>
    </div>
    <div class="col-6 d-sm-none">
      <button
        class="btn btn-block attach-btn"
        [disabled]="disableAttachButton || filesSuccess.length > 0"
        mat-button
        color="primary"
        (click)="handleUploadMethod('picture')"
        appTagger
        [event_category]="category"
        event_label="{{ 'ATTACH' | translate }} - {{ checklistItem?.document?.name | titlecase }}"
        [event_action]="Insert"
      >
        {{ 'TAKE-PICTURE' | translate }}
      </button>
    </div>
  </div>

  <div *ngIf="errorMessage" class="row">
    <h4 class="error-msg col-12 mt-2">{{ errorMessage }}</h4>
  </div>

  <!-- FILE UPLOD MODAL -->
  <app-content-modal *ngIf="showFileUploadModal">
    <div class="text-right cancel-file-upload-btn">
      <span class="material-icons mx-auto col-1" (click)="handleCancelUploads()">
        close
      </span>
    </div>

    <app-file-upload
      [checklistItem]="checklistItem"
      [maxSizeAllowed]="maxSizeAllowed"
      (uploadFinished)="handleFileUpload($event)"
    >
    </app-file-upload>
    <button
      class="btn btn-block finish-btn"
      [disabled]="disableUploadButton"
      mat-button
      color="primary"
      (click)="saveArtifact()"
    >
      {{ 'ATTACH-FILES' | translate }}
    </button>

    <div *ngIf="errorMessage" class="row">
      <h4 class="error-msg mt-1 col-12">{{ errorMessage }}</h4>
    </div>
  </app-content-modal>

  <!-- IMAGE GALLERY -->
  <app-image-gallery
    class="image-show"
    [baseModel]="checklistItem"
    (done)="saveArtifact()"
    *ngIf="showImageGallery"
    [enableCancel]="true"
    (cancel)="handleCancelUploads()"
  >
  </app-image-gallery>
</div>
