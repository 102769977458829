import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormalizationProposalDTO } from '@app/showcase/proposals/models/api/query.proposal-list.model';

@Component({
  selector: 'app-signature-capture',
  templateUrl: './signature-capture.component.html',
  styleUrls: ['./signature-capture.component.scss']
})
export class SignatureCaptureComponent implements OnInit {
  @Input() signatureData: Partial<FormalizationProposalDTO>;
  @Input() isCrossedFlux: boolean;
  @Output() sendSignature: EventEmitter<string | null> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public handleSendSignature(signature: string): void {
    this.sendSignature.emit(signature);
  }
}
