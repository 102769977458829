import { ArgentinaMasks } from '@shared/interfaces/masks/argentina-masks.interface';

export const argentinaMasks: ArgentinaMasks = {
  documentNumber: {
    mask: '00.000.009',
    hiddenMask: '00.XXX.0009',
    maskAlt: '0.000.0009',
    hiddenAltMask: '0.XXX.0009',
    companyMask: '00-00.000.000-0',
    hiddenCompanyMask: '00-XX.XXX.XXX-0'
  },
  cellPhone: { mask: '(00) 0000-0000', prefix: '(+54) ' },
  preToken: { mask: '000.000-00' }, // apenas exemplo
  date: { mask: '00/00/0000' },
  maskDate: { mask: '00/00/0000', maskBack: 'AA/AA/AAAA' },
  income: {
    options: {
      align: 'left',
      allowNegative: false,
      decimal: ',',
      thousands: '.',
      precision: 2,
      prefix: '$ ',
      suffix: '',
      min: 0.01,
      max: 999999999.99,
      inputMode: 'FINANCIAL'
    },
    maxLength: '19'
  },
  zipCode: { mask: '0000' }
};
