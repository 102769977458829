import { FiltersModel } from '@app/showcase/stores/models/filters.model';
import { Action } from '@ngrx/store';
import { CityModel } from '@shared/models/city.model';
import { StateModel } from '@shared/models/state.model';
import { StoreModel } from '@shared/models/store.model';

export enum ShowcaseActionTypes {
  RESET_SHOWCASE = '[Showcase] Reset',

  GET_PERSONALIZED_OFFERS = '[Showcase] [App] Get Personalized Offers',
  GET_PERSONALIZED_OFFERS_SUCCESS = '[Showcase] [App] Get Personalized Offers Success',
  GET_PERSONALIZED_OFFERS_ERROR = '[Showcase] [App] Get Personalized Offers Error',
  INCREMENT_PERSONALIZED_OFFERS_ERROR_ATTEMPT = '[Showcase] [App] Increment Personalized Offers Error Attempt',
  RESET_PERSONALIZED_OFFERS_DATA = '[Showcase] [App] Reset Personalized Offers Data',
  DISABLE_LOADING_PERSONALIZED_OFFERS = '[Showcase] [App] Disable Loading Personalized Offers',

  GET_SPECIAL_OFFERS = '[Showcase] [App] Get Special Offers',
  GET_SPECIAL_OFFERS_SUCCESS = '[Showcase] [App] Get Special Offers Success',
  GET_SPECIAL_OFFERS_ERROR = '[Showcase] [App] Get Special Offers Error',

  GET_SUBSEGMENTS = '[Showcase] [App] Get Subsegments',
  GET_SUBSEGMENTS_SUCCESS = '[Showcase] [App] Get Subsegments Success',
  GET_SUBSEGMENTS_ERROR = '[Showcase] [App] Get Subsegments Error',

  SAVE_SUBSEGMENT = '[Showcase] [User] Save Subsegment',

  GET_STATES = '[Showcase] [App] Get States',
  GET_STATES_SUCCESS = '[Showcase] [App] Get States Success',
  GET_STATES_ERROR = '[Showcase] [App] Get States Error',

  GET_CITIES = '[Showcase] [App] Get Cities',
  GET_CITIES_SUCCESS = '[Showcase] [App] Get Cities Success',
  GET_CITIES_ERROR = '[Showcase] [App] Get Cities Error',

  GET_STORES = '[Showcase] [App] Get Stores',
  GET_STORES_SUCCESS = '[Showcase] [App] Get Stores Success',
  GET_STORES_ERROR = '[Showcase] [App] Get Stores Error',

  SELECT_STORE = '[Showcase] [User] Select Store',

  GET_ECONOMIC_ACTIVITIES = '[Showcase] [App] Get Economic Activities',
  GET_ECONOMIC_ACTIVITIES_SUCCESS = '[Showcase] [App] Get Economic Activities Success',
  GET_ECONOMIC_ACTIVITIES_ERROR = '[Showcase] [App] Get Economic Activities Error',

  GET_MAX_FINANCIAL_VALUE = '[Showcase] [App] Get Max Financial Value',
  GET_MAX_FINANCIAL_VALUE_SUCCESS = '[Showcase] [App] Get Max Financial Value Success',
  GET_MAX_FINANCIAL_VALUE_ERROR = '[Showcase] [App] Get Max Financial Value Error',

  SUBMIT_IDENTIFICATION = '[PreAnalysis] [User] Submit Identification',
  SUBMIT_IDENTIFICATION_SUCCESS = '[PreAnalysis] [App] Submit Identification Success',
  SUBMIT_IDENTIFICATION_ERROR = '[PreAnalysis] [App] Submit Identification Error',

  SHOW_TOUR_TUTORIAL_SHOWCASE = '[Showcase] Show tour showcase tutorial',
  LOAD_SHOWCASE = '[Showcase] Load showcase'
}

export class ShowTourTutorialShowcaseAction implements Action {
  readonly type = ShowcaseActionTypes.SHOW_TOUR_TUTORIAL_SHOWCASE;
  constructor() {}
}
export class LoadShowcaseAction implements Action {
  readonly type = ShowcaseActionTypes.LOAD_SHOWCASE;
  constructor() {}
}

export class GetPersonalizedOffersAction implements Action {
  readonly type = ShowcaseActionTypes.GET_PERSONALIZED_OFFERS;
  constructor() {}
}

export class ResetPersonalizedOffersDataAction implements Action {
  readonly type = ShowcaseActionTypes.RESET_PERSONALIZED_OFFERS_DATA;
  constructor() {}
}

export class GetPersonalizedOffersSuccessAction implements Action {
  readonly type = ShowcaseActionTypes.GET_PERSONALIZED_OFFERS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetPersonalizedOffersErrorAction implements Action {
  readonly type = ShowcaseActionTypes.GET_PERSONALIZED_OFFERS_ERROR;
  constructor(public payload: any) {}
}

export class DisableLoadingPersonalizedOffersAction implements Action {
  readonly type = ShowcaseActionTypes.DISABLE_LOADING_PERSONALIZED_OFFERS;
  constructor() {}
}
export class IncrementPersonalizedOffersErrorAttemptAction implements Action {
  readonly type = ShowcaseActionTypes.INCREMENT_PERSONALIZED_OFFERS_ERROR_ATTEMPT;
  constructor() {}
}

export class GetSubsegmentsAction implements Action {
  readonly type = ShowcaseActionTypes.GET_SUBSEGMENTS;
  constructor() {}
}

export class GetSubsegmentsSuccessAction implements Action {
  readonly type = ShowcaseActionTypes.GET_SUBSEGMENTS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetSubsegmentsErrorAction implements Action {
  readonly type = ShowcaseActionTypes.GET_SUBSEGMENTS_ERROR;
  constructor(public payload: any) {}
}

export class SaveSubsegmentAction implements Action {
  readonly type = ShowcaseActionTypes.SAVE_SUBSEGMENT;
  constructor(public payload: any) {}
}

export class GetStatesAction implements Action {
  readonly type = ShowcaseActionTypes.GET_STATES;
  constructor() {}
}
export class GetStatesSuccessAction implements Action {
  readonly type = ShowcaseActionTypes.GET_STATES_SUCCESS;
  constructor(public payload: StateModel[]) {}
}
export class GetStatesErrorAction implements Action {
  readonly type = ShowcaseActionTypes.GET_STATES_ERROR;
  constructor(public payload: any) {}
}

export class GetCitiesAction implements Action {
  readonly type = ShowcaseActionTypes.GET_CITIES;
  constructor(public payload: string) {}
}
export class GetCitiesSuccessAction implements Action {
  readonly type = ShowcaseActionTypes.GET_CITIES_SUCCESS;
  constructor(public payload: CityModel[]) {}
}
export class GetCitiesErrorAction implements Action {
  readonly type = ShowcaseActionTypes.GET_CITIES_ERROR;
  constructor(public payload: any) {}
}

export class GetStoresAction implements Action {
  readonly type = ShowcaseActionTypes.GET_STORES;
  constructor(public payload: FiltersModel) {}
}
export class GetStoresSuccessAction implements Action {
  readonly type = ShowcaseActionTypes.GET_STORES_SUCCESS;
  constructor(public payload: StoreModel[]) {}
}
export class GetStoresErrorAction implements Action {
  readonly type = ShowcaseActionTypes.GET_STORES_ERROR;
  constructor(public payload: any) {}
}

export class SelectStoreAction implements Action {
  readonly type = ShowcaseActionTypes.SELECT_STORE;
  constructor(public payload: any) {}
}

export class GetSpecialOffersAction implements Action {
  readonly type = ShowcaseActionTypes.GET_SPECIAL_OFFERS;
  constructor() {}
}
export class GetSpecialOffersSuccessAction implements Action {
  readonly type = ShowcaseActionTypes.GET_SPECIAL_OFFERS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetSpecialOffersErrorAction implements Action {
  readonly type = ShowcaseActionTypes.GET_SPECIAL_OFFERS_ERROR;
  constructor(public payload: any) {}
}

export class GetEconomicActivitiesAction implements Action {
  readonly type = ShowcaseActionTypes.GET_ECONOMIC_ACTIVITIES;
  constructor() {}
}
export class GetEconomicActivitiesSuccessAction implements Action {
  readonly type = ShowcaseActionTypes.GET_ECONOMIC_ACTIVITIES_SUCCESS;
  constructor(public payload: any) {}
}
export class GetEconomicActivitiesErrorAction implements Action {
  readonly type = ShowcaseActionTypes.GET_ECONOMIC_ACTIVITIES_ERROR;
  constructor(public payload: any) {}
}

export class GetMaxFinancialValueAction implements Action {
  readonly type = ShowcaseActionTypes.GET_MAX_FINANCIAL_VALUE;
  constructor() {}
}
export class GetMaxFinancialValueSuccessAction implements Action {
  readonly type = ShowcaseActionTypes.GET_MAX_FINANCIAL_VALUE_SUCCESS;
  constructor(public payload: any) {}
}
export class GetMaxFinancialValueErrorAction implements Action {
  readonly type = ShowcaseActionTypes.GET_MAX_FINANCIAL_VALUE_ERROR;
  constructor(public payload: any) {}
}

export class SubmitIdentificationAction implements Action {
  readonly type = ShowcaseActionTypes.SUBMIT_IDENTIFICATION;
  constructor(public payload: any) {}
}
export class SubmitIdentificationSuccessAction implements Action {
  readonly type = ShowcaseActionTypes.SUBMIT_IDENTIFICATION_SUCCESS;
  constructor(public payload: any) {}
}
export class SubmitIdentificationErrorAction implements Action {
  readonly type = ShowcaseActionTypes.SUBMIT_IDENTIFICATION_ERROR;
  constructor(public payload: any) {}
}

export class ResetShowcaseAction implements Action {
  readonly type = ShowcaseActionTypes.RESET_SHOWCASE;
  constructor() {}
}

export type ShowcaseActions =
  | GetPersonalizedOffersAction
  | ResetPersonalizedOffersDataAction
  | DisableLoadingPersonalizedOffersAction
  | GetPersonalizedOffersSuccessAction
  | GetPersonalizedOffersErrorAction
  | IncrementPersonalizedOffersErrorAttemptAction
  | GetSpecialOffersAction
  | GetSpecialOffersSuccessAction
  | GetSpecialOffersErrorAction
  | GetSubsegmentsAction
  | GetSubsegmentsSuccessAction
  | GetSubsegmentsErrorAction
  | SaveSubsegmentAction
  | GetStatesAction
  | GetStatesSuccessAction
  | GetStatesErrorAction
  | GetCitiesAction
  | GetCitiesSuccessAction
  | GetCitiesErrorAction
  | GetStoresAction
  | GetStoresSuccessAction
  | GetStoresErrorAction
  | SelectStoreAction
  | GetEconomicActivitiesAction
  | GetEconomicActivitiesSuccessAction
  | GetEconomicActivitiesErrorAction
  | GetMaxFinancialValueAction
  | GetMaxFinancialValueSuccessAction
  | GetMaxFinancialValueErrorAction
  | SubmitIdentificationAction
  | SubmitIdentificationSuccessAction
  | ShowTourTutorialShowcaseAction
  | LoadShowcaseAction
  | SubmitIdentificationErrorAction
  | ResetShowcaseAction;
