<div class="row no-guters" *ngIf="!showCSC && !showCSCP">
  <div class="col-md-4 pt-1">
    <p class="gray text-sm">
      <ng-container [ngSwitch]="proposal?.proposalData?.product">
        <span *ngSwitchCase="productCode.CP">{{ 'ESIGN-LOAN-VALUE' | translate }}</span>
        <span *ngSwitchCase="productCode.CDC">{{ 'ESIGN-FINANCED-VALUE' | translate }}</span>
        <span *ngSwitchCase="productCode.CSC">{{ 'ESIGN-INSTALLMENT-VALUE' | translate }}</span>
        <span *ngSwitchCase="productCode.CSCP">{{ 'ESIGN-INSTALLMENT-VALUE' | translate }}</span>
      </ng-container>
    </p>
    <p class="text-lg bold mb-4">
      {{ proposal?.financedValue | localeCurrency }}
    </p>

    <p class="gray text-sm">{{ 'Entry Price' | translate }}</p>
    <p *ngIf="!!proposal?.entryValue" class="text-lg bold mb-4">
      {{ proposal?.entryValue | localeCurrency }}
    </p>
    <p *ngIf="!proposal?.entryValue" class="text-lg bold mb-4">
      {{ 'STEP-CONFIRMATION-WITHOUT-ENTRY' | translate }}
    </p>

    <p class="gray text-sm">{{ 'FORMALIZATION-CONDITION-CARD-PARCELS-INFO' | translate }}</p>
    <p
      class="text-lg mb-4"
      *ngIf="proposal?.planType === paymentPlanType.STANDARD_PLAN && !proposal?.planTypeSchemaData.hasFlexInstallments"
    >
      <span class="text-sm-qtd">{{ proposal?.installmentTotalQuantity }}x de</span> <br />
      <strong *ngIf="proposal?.planType !== paymentPlanType.STANDARD_PLAN">
        {{ paymentPlanType.CUSTOMIZED_PLAN }}</strong
      >
      <strong *ngIf="proposal?.planType === paymentPlanType.STANDARD_PLAN">
        {{ proposal?.installmentValue | localeCurrency }}</strong
      >
    </p>
    <p
      class="text-lg mb-4"
      *ngIf="proposal?.planType != paymentPlanType.STANDARD_PLAN || proposal?.planTypeSchemaData.hasFlexInstallments"
    >
      <strong>
        {{ proposal?.installmentTotalQuantity }}
      </strong>
    </p>
  </div>

  <div class="col-md-8" *ngIf="!showCSC && !showCSCP">
    <div class="row no-gutters ">
      <span class="text-md col-md-6">
        {{ 'OFFER-RESUME' | translate }}
      </span>
    </div>
    <div class="row no-gutters details-row" >
      <span class="text-sm col-md-6">
        {{ 'MSG-OFFERS-SUMMARY' | translate }}
      </span>
    </div>

    <div class="row no-gutters" >
      <span class="text-md col-md-8">
        {{ 'CET-TOTAL-OFFER-RESUME' | translate }}
      </span>
    </div>

    <div class="row no-gutters details-row">
      <div class="text-sm col-md-10">
        <span class="gray">{{ 'FINANCED_AMOUNT' | translate }}:</span>
        <span>
          {{ proposal?.financedValue | localeCurrency }}
        </span>
        <span> ({{ percentFinancedValue | percent: '1.2-2' }}) </span>
      </div>
      <div class="text-sm col-md-10">
        <span class="gray">{{ 'DEADLINE' | translate }}:</span>
        <span>
          {{ proposal?.installmentTotalQuantity }}
        </span>
        <span *ngIf="proposal?.installmentTotalQuantity > 1">
          {{ 'MONTHS' | translate }}
        </span>
        <span *ngIf="proposal?.installmentTotalQuantity == 1">
          {{ 'MONTH' | translate }}
        </span>
      </div>
      <div class="text-sm col-md-10">
        <span class="gray">{{ 'INSTALLMENT-VALUE' | translate }}:</span>
        <span>
          {{ proposal?.installmentValue | localeCurrency }}
        </span>
      </div>
      <div class="text-sm col-md-10 mt-3">
        <span class="gray">{{ 'STEP-CONFIRMATION-REGISTER-TARIFF' | translate }}:</span>
        <span>
          {{ proposal?.registerTariff | localeCurrency }}
        </span>
        <span> ({{ percentRegisterTariff | percent: '1.2-2' }}) </span>
      </div>
      <div class="text-sm col-md-10">
        <span class="gray">{{ 'INSURANCE-PROTECTION' | translate }}:</span>
        <span>
          {{ proposal?.insuranceValue | localeCurrency }}
        </span>
        <span> ({{ percentInsuranceValue | percent: '1.2-2' }}) </span>
      </div>
      <div class="text-sm col-md-10 mt-1">
        <span class="gray">{{ 'REGISTRO' | translate }}</span>
      </div>
      <div class="text-sm col-md-10">
        <span class="gray">{{ 'TAX-IOF' | translate }} </span>
        <span>
          {{ proposal?.iof | localeCurrency }}
        </span>
        <span> ({{ percentIof | percent: '1.2-2' }}) </span>
      </div>
      <div class="text-sm col-md-10">
        <span class="gray">{{ 'MODAL-LOAN-FEES-INTEREST-RATE' | translate }}: </span>
        <span>
          {{ proposal?.interestRate | number: '1.2-2' }}{{ 'STEP-CONFIRMATION-PER-MONTH' | translate }}
          {{ proposal?.interestRateByYear | number: '1.2-2' }}{{ 'STEP-CONFIRMATION-PER-YEAR' | translate }}
        </span>
      </div>
      <div class="text-sm col-md-10">
        <span class="gray">{{ 'TAX-CET' | translate }} </span>
        <span>
          {{ proposal?.returnRate | number: '1.2-2' }}{{ 'STEP-CONFIRMATION-PER-MONTH' | translate }}
          {{ proposal?.annualReturnRate | number: '1.2-2' }}{{ 'STEP-CONFIRMATION-PER-YEAR' | translate }}
        </span>
      </div>

      <div class="text-sm col-md-10 mt-3 bold">
        <span>{{ 'TOTAL-SUM' | translate }}: </span>
        <span> {{ proposal?.financedTotalValue | localeCurrency }} (100%) </span>
      </div>
      <div class="text-sm col-md-10 mt-1 bold">
        <span>{{ 'TOTAL-SUM-INSURANCE' | translate }}: </span>
        <span>
          {{ totalInsurance | localeCurrency }}
        </span>
      </div>
    </div>
  </div>
</div>

<div class="row no-guters" *ngIf="showCSC || showCSCP">
  <div class="col-md-4 pt-4">
    <p class="gray text-sm">
      <ng-container [ngSwitch]="proposal?.proposalData?.product">
        <span *ngSwitchCase="productCode.CP">{{ 'ESIGN-LOAN-VALUE' | translate }}</span>
        <span *ngSwitchCase="productCode.CDC">{{ 'ESIGN-FINANCED-VALUE' | translate }}</span>
        <span *ngSwitchCase="productCode.CSC">{{ 'ESIGN-INSTALLMENT-VALUE' | translate }}</span>
        <span *ngSwitchCase="productCode.CSCP">{{ 'ESIGN-INSTALLMENT-VALUE' | translate }}</span>
      </ng-container>
    </p>
    <p class="text-lg bold mb-4">
      {{ proposal?.financedValue | localeCurrency }}
    </p>

    <p class="gray text-sm">{{ 'Entry Price' | translate }}</p>
    <p *ngIf="!!proposal?.entryValue" class="text-lg bold mb-4">
      {{ proposal?.entryValue | localeCurrency }}
    </p>
    <p *ngIf="!proposal?.entryValue" class="text-lg bold mb-4">
      {{ 'STEP-CONFIRMATION-WITHOUT-ENTRY' | translate }}
    </p>

    <p class="gray text-sm">{{ 'FORMALIZATION-CONDITION-CARD-PARCELS-INFO' | translate }}</p>
    <p
      class="text-lg mb-4"
      *ngIf="proposal?.planType === paymentPlanType.STANDARD_PLAN && !proposal?.planTypeSchemaData.hasFlexInstallments"
    >
      <span class="text-sm-qtd">{{ proposal?.installmentTotalQuantity }}x de</span> <br />
      <strong *ngIf="proposal?.planType !== paymentPlanType.STANDARD_PLAN">
        {{ paymentPlanType.CUSTOMIZED_PLAN }}</strong
      >
      <strong *ngIf="proposal?.planType === paymentPlanType.STANDARD_PLAN">
        {{ proposal?.installmentValue | localeCurrency }}</strong
      >
    </p>
    <p
      class="text-lg mb-4"
      *ngIf="proposal?.planType != paymentPlanType.STANDARD_PLAN || proposal?.planTypeSchemaData.hasFlexInstallments"
    >
      <strong>
        {{ proposal?.installmentTotalQuantity }}
      </strong>
    </p>
  </div>

  <div class="col-md-8">
    <div class="row no-gutters details-row">
      <span class="gray text-sm col-md-6">
        {{ 'STEP-CONFIRMATION-FIRST-DUE-DATE' | translate }}
      </span>
      <span class="col-md-6">
        {{ proposal?.firstInstallmentDate | date: 'dd/MM/yyyy' }}
      </span>
    </div>

    <div class="row no-gutters details-row">
      <div class="gray text-sm col-md-6">
        {{ 'STEP-CONFIRMATION-PAYMENT-PLAN' | translate }}
      </div>
      <div class="col-md-6">
        {{ proposal?.liquidationType }}
      </div>
    </div>

    <ng-container *ngIf="proposal?.proposalData?.product === productCode.CDC">
      <div class="row no-gutters details-row">
        <div class="gray text-sm col-md-6">
          {{ 'STEP-CONFIRMATION-INTEREST-RATE' | translate }}
        </div>
        <div class="col-md-6">
          {{ proposal?.interestRate | number: '1.2-2' }}{{ 'STEP-CONFIRMATION-PER-MONTH' | translate }}<br />
          {{ proposal?.interestRateByYear | number: '1.2-2' }}{{ 'STEP-CONFIRMATION-PER-YEAR' | translate }}
        </div>
      </div>

      <div class="row no-gutters details-row">
        <div class="gray text-sm col-md-6">
          {{ 'PAYMENT-PLAN' | translate }}
        </div>
        <div class="col-md-6">
          <span class="d-flex">
            {{ planType }}
            <app-tooltip-payment-plan *ngIf="planType !== paymentPlanType.STANDARD_PLAN"></app-tooltip-payment-plan>
          </span>
        </div>
      </div>

      <div class="row no-gutters details-row">
        <div class="gray text-sm col-md-6">
          {{ 'STEP-CONFIRMATION-IOF' | translate }}
          <mat-icon
            class="info-icon icon-v-inverse"
            matTooltip="{{ 'STEP-CONFIRMATION-IOF-TOOLTIP' | translate }}"
            matTooltipPosition="after"
            matTooltipHideDelay="5000"
            appTagger
            [event_category]="event_category"
            event_label="ajuda - IOF"
            >error_outline</mat-icon
          >
        </div>
        <div class="col-md-6">
          {{ proposal?.iof | localeCurrency }}
        </div>
      </div>

      <div class="row no-gutters details-row">
        <div class="gray text-sm col-md-6">
          {{ 'STEP-CONFIRMATION-REGISTER-TARIFF' | translate }}
        </div>
        <div
          class="col-md-6"
          *ngIf="
            !proposal?.registerTariff &&
            !proposal?.registerTariffExemptionFlag &&
            !proposal?.registerTariffOneTimePaymentFlag
          "
        >
          {{ proposal?.registerTariff | localeCurrency }}
        </div>
        <div
          class="col-md-6"
          *ngIf="
            !!proposal?.registerTariff &&
            !proposal?.registerTariffExemptionFlag &&
            !proposal?.registerTariffOneTimePaymentFlag
          "
        >
          {{ proposal?.registerTariff | localeCurrency }}
        </div>
        <div class="col-md-6" *ngIf="proposal?.registerTariffExemptionFlag">
          {{ 'STEP-CONFIRMATION-WITHOUT-TARIFF_EXEMPTION' | translate }}
        </div>
        <div
          class="col-md-6"
          *ngIf="!proposal?.registerTariffExemptionFlag && proposal?.registerTariffOneTimePaymentFlag"
        >
          {{ 'STEP-CONFIRMATION-WITHOUT-TARIFF_PAYMENT' | translate }}
        </div>
      </div>

      <div class="row no-gutters details-row">
        <div class="gray text-sm col-md-6">
          {{ 'STEP-CONFIRMATION-INSURANCE' | translate }}
          <mat-icon
            class="info-icon icon-v-inverse"
            matTooltip="{{ 'STEP-CONFIRMATION-FINANCIAL-PROTECTION-TOOLTIP' | translate }}"
            matTooltipPosition="after"
            matTooltipHideDelay="5000"
            >error_outline</mat-icon
          >
        </div>
        <div class="col-md-6">
          {{ proposal?.insuranceValue | localeCurrency }}
        </div>
      </div>

      <div class="row no-gutters details-row">
        <div class="gray text-sm col-md-6">
          {{ 'STEP-CONFIRMATION-RETURN-RATE' | translate }}
          <mat-icon
            class="info-icon icon-v-inverse"
            matTooltip="{{ 'STEP-CONFIRMATION-CET-TOOLTIP' | translate }}"
            matTooltipPosition="after"
            matTooltipHideDelay="5000"
            appTagger
            [event_category]="event_category"
            event_label="ajuda - CET"
            >error_outline</mat-icon
          >
        </div>
        <div class="col-md-6">
          {{ proposal?.returnRate | number: '1.2-2' }}{{ 'STEP-CONFIRMATION-PER-MONTH' | translate }} <br />
          {{ proposal?.annualReturnRate | number: '1.2-2' }}{{ 'STEP-CONFIRMATION-PER-YEAR' | translate }}
        </div>
      </div>
    </ng-container>
  </div>
</div>
