<div class="row mt-4 d-md-none d-sm-flex mt-4">
  <div *ngIf="isShowFilters" class="col-12 ">
    <button type="button" class="btn btn-filter" [ngClass]="{ red: selectedProduct === 'CSC' }"
      (click)="product('CSC')">
      <span class="button-text">CSC</span>
    </button>

    <button type="button" class="btn btn-filter" [ngClass]="{ red: selectedProduct === 'CSCP' }"
      (click)="product('CSCP')">
      <span class="button-text">CSCP</span>
    </button>

    <button type="button" class="btn btn-filter sm-float-right" [ngClass]="{ red: selectedProduct === 'CDC' }"
      (click)="product('CDC')">
      <img src="./assets/santander/icons/ic_events.svg" alt="icon calendar" />
      <span class="button-img-text">{{ 'PERIOD' | translate }}</span>
    </button>
  </div>
  <div *ngIf="isShowFilters" class="col-12 mt-2 show-text-result">
    <div class="showing-results" *ngIf="!!totalElements">
      {{ 'SHOWING-LAST-DAYS' | translate }}
      <strong *ngIf="totalElements === 1">{{ totalElements }} {{ 'ONE_DAY' | translate }}</strong>
      <strong *ngIf="totalElements > 1">{{ 'NINETY_DAYS' | translate }}</strong>
    </div>
  </div>
  <div class="row col-12" [ngClass]="{ 'mt-0': isHiddenFilters, 'mt-2': isShowFilters }">
    <div class="col-1 sm-mat-checkbox white">
      <mat-checkbox color="primary" [checked]="allComplete" [indeterminate]="someChecked()"
        (change)="checkSelectAll($event.checked)" appTagger [event_category]="category" [event_action]="Selected"
        event_label="página – anterior">
      </mat-checkbox>
    </div>
    <div class="col-8">
      <div class="showing-results">
        <p>{{ 'FINANCIAL-STATEMENT_MSG-SELECT-ALL-PROPOSAL-SM' | translate }}</p>
      </div>
    </div>
    <div class="col-2 sm-float-right">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="options menu" appTagger [event_category]="category"
        event_label="opções – selecione todas as propostas">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="openDialogFinancialStatementPeriodDownload()">
          <span> {{ 'DOWNLOAD-SELECTED-PERIOD' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
  <div *ngIf="isHiddenFilters" class="col-12 hidden-filters">
    <p (click)="toggleShow()" appTagger [event_category]="category" event_label="mostrar filtros">
      {{ 'SHOW_FILTERS' | translate }}
    </p>
  </div>
  <div *ngIf="isShowFilters" class="col-12 hidden-filters">
    <p (click)="toggleShow()" appTagger [event_category]="category" event_label="ocultar filtros">
      {{ 'HIDDEN_FILTERS' | translate }}
    </p>
  </div>

  <div class="col-12 responsive-content ">
    <ul>
      <li *ngFor="let item of listPayments">
        <div class="responsive-content-row">
          <div class="responsive-content-col sm-mat-checkbox white-col">
            <mat-checkbox color="primary" [checked]="allComplete" [indeterminate]="someChecked()"
              (change)="checkSelectAll($event.checked)">
            </mat-checkbox>
          </div>
          <div class="responsive-content-col mr-5">
            <span class="title">{{ 'Date' | translate }}</span>
            <span>{{ item.paymentDate | date: 'dd/MM' }}</span>
          </div>

          <div class="responsive-content-col" *ngIf="isShowingRecebidos === 'Recebidos'">
            <span class="title">{{ 'AMOUNT-RECEIVED' | translate }}</span>
            <span class="swap-text-item">{{ item.receivedValue | currency: 'BRL' }}</span>
          </div>
          <div class="responsive-content-col" *ngIf="isShowingRecebidos === 'Realizados'">
            <span class="title">{{ 'TOTAL-AMOUNT-FINANCED' | translate }}</span>
            <span class="swap-text-item">{{ item.paymentFinanced | currency: 'BRL' }}</span>
          </div>

          <div class="responsive-content-col sm-mat-checkbox white-col">
            <button mat-icon-button [matMenuTriggerFor]="menuSM">
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #menuSM="matMenu">
              <button mat-menu-item (click)="openDialogFinancialStatementPeriodDownload()">
                <span> {{ 'DOWNLOAD-SELECTED-PERIOD' | translate }}</span>
              </button>
            </mat-menu>
          </div>
        </div>

        <div class="responsive-content-row">
          <div class="responsive-content-col" *ngIf="isShowingRecebidos === 'Recebidos'">
            <span class="title">{{ 'DOCUMENT-NUMBER-CNPJ' | translate }}</span>
            <span class="swap-text-item" *ngIf="item.document?.length === 11">
              {{ item.document | mask: '000.000.000-00' }}
            </span>
            <span class="swap-text-item" *ngIf="item.document?.length >= 12">
              {{ item.document | mask: '00.000.000/0000-00' }}
            </span>
          </div>
          <div class="responsive-content-col" *ngIf="isShowingRecebidos !== 'Recebidos'">
            <span class="title">{{ 'TOTAL-AMOUNT-RELEASED' | translate }}</span>
            <span class="swap-text-item">{{ item.paymentValue | currency: 'BRL' }}</span>
          </div>
        </div>

        <div class="responsive-content-row space-between">
          <div class="responsive-content-col mr-2">
            <span class="title">{{ 'NUMBER-OF-PROPOSALS' | translate }}</span>
            <span class="swap-text-item">{{ item.proposalQuantity }}</span>
          </div>

          <div class="responsive-content-col">
            <div class="status-column" (click)="downloadExtract(item.paymentDate)">
              <img src="./assets/images/icon_download_grey.svg" alt="icon" class="icon-status mr-2" />
              <span>{{ 'DOWNLOAD' | translate }}</span>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
<div class="row mt-4 d-none d-md-flex">

  <div class="col-12 col-md-3 col-lg-4 d-md-flex">
    <button *ngIf="(extractSubTab === undefined || subTab?.tab?.textLabel === 'Recebidos' || subTab === undefined
    || subTab?.tab?.textLabel === 'Realizados' ) &&
      !(tabSelected === 'Saldo Futuro' || tabSelected === 'Pagamentos Realizados')" type="button"
      class="btn btn-filter" [ngClass]="{ red: selectedProduct === 'CSC' }" (click)="product('CSC')">
      <span class="button-text">CSC</span>
    </button>
    <button *ngIf=" extractSubTab === undefined || subTab?.tab?.textLabel === 'Recebidos' || subTab === undefined
    || tabSelected === 'Saldo Futuro' || tabSelected === 'Pagamentos Realizados'
    " type="button" class="btn btn-filter" [ngClass]="{ red: selectedProduct === 'CSCP' }" (click)="product('CSCP')">
      <span class="button-text">CSCP</span>
    </button>
    <button *ngIf="(extractSubTab === undefined || subTab?.tab?.textLabel === 'Recebidos' || subTab === undefined
    || subTab?.tab?.textLabel === 'Realizados' ) &&
      !(tabSelected === 'Saldo Futuro' || tabSelected === 'Pagamentos Realizados')" type="button"
      class="btn btn-filter sm-float-right" [ngClass]="{ red: selectedProduct === 'CDC' }" (click)="product('CDC')">
      <span class="button-text">{{ 'CDC' | translate }}</span>
    </button>
  </div>
  <div class="col-12 col-md-5 col-lg-6 d-none d-md-flex">
    <button type="button" class="btn btn-filter" [ngClass]="{ red: selectedDays === 0 }" (click)="selectPeriod(0)">
      <span class="button-text">{{ 'TODAY' | translate }}</span>
    </button>
    <button type="button" class="btn btn-filter" [ngClass]="{ red: selectedDays === 7 }" (click)="selectPeriod(7)">
      <span class="button-text">{{ 'SEVEN_DAYS' | translate }}</span>
    </button>
    <button type="button" class="btn btn-filter" [ngClass]="{ red: selectedDays === 30 }" (click)="selectPeriod(30)">
      <span class="button-text">{{ 'THIRTY_DAYS' | translate }}</span>
    </button>
    <button type="button" class="btn btn-filter" [ngClass]="{ red: selectedDays === 90 }" (click)="selectPeriod(90)">
      <span class="button-text">{{ 'NINETY_DAYS' | translate }}</span>
    </button>
    <button type="button" class="btn btn-filter light" (click)="openDialogDateRangePicker()">
      <img src="./assets/santander/icons/ic_events.svg" alt="icon calendar" />
      <span class="button-img-text">{{ 'OTHER' | translate }}</span>
    </button>
  </div>
  <div class="col-12 col-md-4 col-lg-2 float-right show-text-result">
    <div class="showing-results">
      {{ 'SHOWING' | translate }}
      <strong *ngIf="totalElements === 0 || totalElements === 1">{{ totalElements }} {{ 'RECORD' | translate }}</strong>
      <strong *ngIf="totalElements > 1">{{ totalElements }} {{ 'RECORDS' | translate }}</strong>
    </div>
  </div>
</div>

<div class="table-responsive mt-4 d-none d-md-flex">
  <table class="table table-borderless table-responsive-md table-select-all">
    <tbody>
      <tr>
        <td class="text-nowrap table-select-all-checkbox">
          <mat-checkbox color="primary" [checked]="allComplete" [indeterminate]="someChecked()"
            (change)="checkSelectAll($event.checked)" appTagger [event_category]="category"
            event_label="{{ 'OTHER' | translate }}"></mat-checkbox>
        </td>
        <td class="text-nowrap table-select-all-text ">
          <div class=" align-items-center ">
            <div [ngClass]="{ 'show-msg-proposals': checkedCount > 0 }">
              <p *ngIf="!checkedCount">
                {{ 'FINANCIAL-STATEMENT_MSG-SELECT-ALL-PROPOSAL' | translate }}
              </p>
              <p *ngIf="!!checkedCount">
                <strong>{{ checkedCount }} </strong>
                <span *ngIf="checkedCount === 1">
                  <strong>{{ 'RECORD' | translate }}</strong> selecionado com total de
                </span>
                <span *ngIf="checkedCount > 1">
                  <strong>{{ 'RECORDS' | translate }}</strong> selecionados com total de
                </span>

                <strong>{{ sumCheckedValues | currency: 'BRL' }}</strong>
              </p>
            </div>
            <button *ngIf="checkedCount > 0" class="btn btn-download btn-download-period" type="button"
              (click)="openDialogFinancialStatementPeriodDownload()" appTagger [event_category]="category"
              event_label="{{ 'DOWNLOAD-SELECTED-PERIOD' | translate }}">
              {{ 'DOWNLOAD-SELECTED-PERIOD' | translate }}
            </button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div *ngIf="!(tabSelected === 'Pagamentos Realizados' || tabSelected === 'Saldo Futuro')"
  class="table-responsive mt-4 d-none d-md-flex">
  <table class="table table-borderless table-responsive-md">
    <thead>
      <th scope="col" class="text-nowrap"></th>
      <th scope="col" class="text-nowrap">{{ 'Date' | translate }}</th>
      <th scope="col" class="text-nowrap" *ngIf="isShowingRecebidos === 'Recebidos'">{{ 'DOCUMENT-NUMBER-CNPJ' |
        translate }}</th>
      <th scope="col" class="text-nowrap" *ngIf="isShowingRecebidos === 'Recebidos'">{{ 'AMOUNT-RECEIVED' | translate }}
      </th>
      <th scope="col" class="text-nowrap" *ngIf="isShowingRecebidos !== 'Recebidos'">{{ 'TOTAL-AMOUNT-FINANCED' |
        translate }}</th>
      <th scope="col" class="text-nowrap" *ngIf="isShowingRecebidos !== 'Recebidos'">{{ 'TOTAL-AMOUNT-RELEASED' |
        translate }}</th>
      <th scope="col" class="text-nowrap">{{ 'NUMBER-OF-PROPOSALS' | translate }}</th>
      <th scope="col" class="text-nowrap" colspan="3">{{ 'DOWNLOAD' | translate }}</th>
    </thead>

    <tbody>
      <tr *ngFor="let proposta of listPayments; let i = index">
        <td class="text-nowrap table-list-checkbox">
          <mat-checkbox color="primary" [(ngModel)]="proposta.checked" (ngModelChange)="updateCheckSelectAll()"
            appTagger [event_category]="category" [event_action]="Selected" event_label="proposta + {{ i }}">
          </mat-checkbox>
        </td>

        <td class="text-nowrap">
          <span class="swap-text-item">{{ proposta.paymentDate | date: 'dd/MMMM' }}</span>
        </td>

        <td class="text-nowrap" *ngIf="isShowingRecebidos === 'Recebidos'">
          <span class="swap-text-item" *ngIf="proposta.document?.length === 11">
            {{ proposta.document | mask: '000.000.000-00' }}
          </span>
          <span class="swap-text-item" *ngIf="proposta.document?.length >= 12">
            {{ proposta.document | mask: '00.000.000/0000-00' }}
          </span>
        </td>
        <td class="text-nowrap" *ngIf="isShowingRecebidos === 'Recebidos'">
          <span class="swap-text-item">{{ proposta.receivedValue | currency: 'BRL' }}</span>
        </td>
        <td class="text-nowrap" *ngIf="isShowingRecebidos !== 'Recebidos'">
          <span class="swap-text-item">{{ proposta.paymentFinanced | currency: 'BRL' }}</span>
        </td>
        <td class="text-nowrap" *ngIf="isShowingRecebidos !== 'Recebidos'">
          <span class="swap-text-item">{{ proposta.paymentValue | currency: 'BRL' }}</span>
        </td>

        <td class="text-nowrap">
          <span class="swap-text-item">{{ proposta.proposalQuantity }}</span>
        </td>

        <td class="text-nowrap">
          <button class="btn-upload " type="button" (click)="downloadExtract(proposta.paymentDate)" appTagger
            [event_category]="category" event_label="baixar - proposta + {{ i }}">
            <img src="./assets/images/icon_download_grey.svg" alt="icon upload" class="icon-download" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<div *ngIf="tabSelected === 'Pagamentos Realizados' || tabSelected === 'Saldo Futuro'"
  class="table-responsive mt-4 d-none d-md-flex">
  <table class="table table-borderless table-responsive-md">
    <thead>
      <th scope="col" class="text-nowrap"></th>
      <th scope="col" class="text-nowrap">{{ 'Date' | translate }}</th>
      <th scope="col" class="text-nowrap" *ngIf="tabSelected === 'Pagamentos Realizados'">{{ 'AMOUNT-RECEIVED' |
        translate }}</th>
      <th scope="col" class="text-nowrap" *ngIf="tabSelected === 'Saldo Futuro'">{{ 'TOTAL-AMOUNT-SCHEDULED' |
        translate }}</th>
      <th scope="col" class="text-nowrap">{{ 'NUMBER-OF-PROPOSALS' | translate }}</th>
      <th scope="col" class="text-nowrap" colspan="3">{{ 'DOWNLOAD' | translate }}</th>
    </thead>

    <tbody>
      <tr *ngFor="let proposta of listPaymentsCSCP; let i = index">
        <td class="text-nowrap table-list-checkbox">
          <mat-checkbox color="primary" [(ngModel)]="proposta.checked" (ngModelChange)="updateCheckSelectAllCSCP()"
            appTagger [event_category]="category" [event_action]="Selected" event_label="proposta + {{ i }}">
          </mat-checkbox>

        </td>

        <td class="text-nowrap">
          <span class="swap-text-item">{{ proposta.paymentDate | date: 'dd/MMMM' }}</span>
        </td>

        <td *ngIf="proposta.totalScheduledValue || proposta.totalScheduledValue === 0" class="text-nowrap">
          <span class="swap-text-item">{{ proposta.totalScheduledValue | currency: 'BRL' }}</span>
        </td>
        <td *ngIf="proposta.receivedValue || proposta.receivedValue === 0" class="text-nowrap">
          <span class="swap-text-item">{{ proposta.receivedValue | currency: 'BRL' }}</span>
        </td>

        <td class="text-nowrap">
          <span class="swap-text-item">{{ proposta.proposalQuantity }}</span>
        </td>

        <td class="text-nowrap">
          <button class="btn-upload " type="button" (click)="downloadExtractCSCP(proposta.paymentDate, tabSelected)"
            appTagger [event_category]="category" event_label="baixar - proposta + {{ i }}">
            <img src="./assets/images/icon_download_grey.svg" alt="icon upload" class="icon-download" />
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ngb-pagination [collectionSize]="totalElements" [(page)]="page" (pageChange)="loadPage(page)" [boundaryLinks]="true"
  [maxSize]="10" [pageSize]="perPage" size="sm">
  <ng-template ngbPaginationPrevious>
    <button class="no-style" appTagger event_label="página - anterior" [event_category]="category">
      {{ 'Previous' | translate }}
    </button>
  </ng-template>

  <ng-template ngbPaginationNext>
    <button class="no-style" appTagger event_label="página - próximo" [event_category]="category">
      {{ 'Next' | translate }}
      <span class="arrow-icon icon-next"></span>
    </button>
  </ng-template>
</ngb-pagination>
