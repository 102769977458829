<div class="container">
  <div class="success-icon">
    <span class="material-icons">
      check_circle
    </span>
  </div>
  <h2 mat-dialog-title>
    {{ 'REGISTRATION-SUCCESS-MODAL-TITLE' | translate }}
  </h2>

  <mat-dialog-content>
    <p>{{ 'REGISTRATION-SUCCESS-MODAL-MSG' | translate }}</p>
  </mat-dialog-content>

  <mat-dialog-actions class="justify-content-md-center">
    <button mat-raised-button class="btn" (click)="enter()">Ingresar</button>
  </mat-dialog-actions>
</div>
