import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  BiometryErrorEnum,
  BiometryManagerService,
  BiometryPictureEnum
} from '@app/biometry/services/biometry-manager.service';
import { AppState } from '@app/core/state';
import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { FormalizationProposalDTO } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { Store } from '@ngrx/store';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { EnableCameraStepsComponent } from '../../../enable-camera-steps/enable-camera-steps.component';

@Component({
  selector: 'app-facial-capture',
  templateUrl: './facial-capture.component.html',
  styleUrls: ['./facial-capture.component.scss']
})
export class FacialCaptureComponent implements OnInit, OnDestroy, OnChanges {
  @Input() proposal: FormalizationProposalDTO | Partial<FormalizationProposalDTO>;
  @Output() biometryResultsEmit: EventEmitter<any> = new EventEmitter();
  @Output() closeBiometryCaptureEmit: EventEmitter<void> = new EventEmitter();
  protected ngUnsubscribe: Subject<any> = new Subject();

  public openCapture = false;
  public facialBiometryStage$ = this.biometryManagerService.sdkStage;
  public loadedProposal: FormalizationProposalDTO | Partial<FormalizationProposalDTO>;
  public proposalData: ConfirmationData;
  public paymentPlanType = PaymentPlanType;

  constructor(
    private biometryManagerService: BiometryManagerService,
    private dialog: MatDialog,
    private store$: Store<AppState>
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.proposal && changes.proposal) {
      this.loadedProposal = this.proposal;
    }
  }

  ngOnInit() {
    this.verifyState();
  }

  public closeBiometryCapture(): void {
    this.closeBiometryCaptureEmit.emit();
  }

  public verifyState() {
    this.facialBiometryStage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(stage => {
      if (stage === BiometryPictureEnum.SUCCESS) {
        const images = this.biometryManagerService.getImages();
        this.biometryManagerService.initiateBiometry();
        this.biometryResultsEmit.emit(images);
      }

      if (stage === BiometryPictureEnum.ERROR) {
        const error = this.biometryManagerService.getError();

        if (error === BiometryErrorEnum.NOT_ALLOWED) {
          const modalRef = this.dialog.open(EnableCameraStepsComponent, {
            disableClose: true,
            position: { top: '150px', left: '10vw' }
          });
          modalRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
              this.closeBiometryCaptureEmit.emit();
            });
        }

        if (error === BiometryErrorEnum.STEGGER) {
          const modalRef = this.dialog.open(PionModalComponent, {
            data: {
              description: 'Ocorreu um erro na validação, tente novamente mais tarde',
              confirmText: 'Fechar',
              type: 'error'
            },
            disableClose: true
          });
          modalRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
              this.closeBiometryCaptureEmit.emit();
              this.facialBiometryStage$.next(BiometryPictureEnum.OFFLINE);
            });
        }
      }
    });
  }

  public handleSuccessfulScan(res: any): void {
    this.biometryResultsEmit.emit(res);
  }

  public handleErrorScan(message: any): void {
    console.log(message);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
