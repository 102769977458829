import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-proposal-page',
  templateUrl: './proposal-page.component.html',
  styleUrls: ['./proposal-page.component.scss']
})
export class ProposalPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
