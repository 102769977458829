import {
  ChecklistDocumentEnum,
  ChecklistItemClientDTO,
  ChecklistItemGroupTypeDTO,
  ChecklistItemSizeDTO,
  ChecklistItemTypeDTO
} from './api/query.checklist.model';

export interface ChecklistFileModel {
  id?: string;
  data?: string | File;
  loaded?: string;
  total?: string;
  order?: number;
  status?: string;
  name?: string;
  inProgress?: boolean;
  progress?: number;
  errorMsg?: string;
  sizeArtifact?: string;
}

export enum ChecklistStatusEnum {
  PENDING = 'PENDING',
  INDEXED = 'INDEXED',
  ANALYSIS = 'ANALYSIS',
  REMAKE = 'REMAKE',
  CANCELLED = 'CANCELLED',
  DENIED = 'DENIED',
  RELEASE_INTERNAL = 'RELEASE_INTERNAL'
}

export interface ChecklistModel {
  id: string;
  client?: ChecklistItemClientDTO;
  proposal?: {
    id: number;
    channelSupplier?: { id: string; code?: number; description?: string };
  };
  type?: ChecklistItemTypeDTO;
  artifact?: ChecklistItemSizeDTO;
  document?: {
    code: number;
    docCode: ChecklistDocumentEnum;
    name: string;
    description?: string;
    maxAmount?: number;
    allowedTypes?: string;
    integrationTypes?: {
      id: number;
      description: string;
      integrationCode: string;
    }[];
  };
  encrypted: string;
  uploadGroup: ChecklistItemGroupTypeDTO;
  files?: ChecklistFileModel[];
  status?: ChecklistStatusEnum;
  file?: ChecklistFileModel;
  arquivo?: string;
}
export interface ChecklistModelMobile {
  id: string;
  client?: ChecklistItemClientDTO;
  proposal?: {
    id: number;
    channelSupplier?: { id: string; code?: number; description?: string };
  };
  type?: ChecklistItemTypeDTO;
  artifact?: ChecklistItemSizeDTO;
  document?: {
    id: string;
    code: number;
    docCode: ChecklistDocumentEnum;
    name: string;
    description?: string;
    allowedTypes?: string;
    integrationTypes?: {
      id: number;
      description: string;
      integrationCode: string;
    }[];
  };
  uploadGroup: ChecklistItemGroupTypeDTO;
  files?: ChecklistFileModel[];
  status?: ChecklistStatusEnum;
  file?: ChecklistFileModel;
  arquivo?: string;
  value: string;
}
