<div class="update-bpo-container">
  <div class="pending">
    <div class="pending-title">
      <h2 class="pending-title-primary"></h2>
      <h2 class="pending-title-primary"></h2>
    </div>

    <pion-snackbar *ngIf="message" icon="error" [closable]="false" type="warning">
      <p [innerHTML]="message"></p>
    </pion-snackbar>

    <h3 *ngIf="checklistItems?.length > 0" class="pending-title-secondary">
      {{ 'PENDENCY-ATTACH-PENDING-DOCUMENTS' | translate }}
    </h3>
    <div *ngFor="let file of checklistItems">
      <section id="nf-section" *ngIf="!!nfValue && file.document.docCode === '20'">
        <div class="nf-main">
          <p class="nf-label">{{ 'PENDENCY-BIPARTITE-NF-VALUE-LABEL' | translate }}*</p>
          <p class="nf-value">{{ nfValue | localeCurrency }}</p>
        </div>
      </section>
      <app-checklist-item
        [isPendency]="true"
        [checklistItem]="file"
        [maxSizeAllowed]="maxSizeAllowed"
        (uploadFinished)="handleUploadFinished($event)"
      ></app-checklist-item>
    </div>
  </div>

  <div class="row" *ngIf="!!personalForm && codeFields?.length > 0">
    <div class="col-12">
      <h3 class="pending-title-secondary">
        {{ 'PENDENCY-UPDATE-BPO-DATA' | translate }}
      </h3>
      <div [formGroup]="personalForm">
        <div class="row">
          <!-- FULL NAME FOR PHYSICAL PERSON -->
          <div class="col-12 col-md-6 mb-4" *ngIf="nameClient">
            <mat-form-field class="full-field-input">
              <mat-label> {{ 'STEP-REGISTER-FULL-NAME' | translate }} </mat-label>
              <app-custom-input class="input-with-icon" [required]="true" formControlName="name"></app-custom-input>
              <mat-hint>{{ 'PENDENCY-NAME-HINT' | translate }}</mat-hint>
              <mat-error *ngIf="personalForm.get('name').touched && personalForm.get('name').invalid">{{
                'STEP-REGISTER-NAME-ERROR' | translate
              }}</mat-error>
            </mat-form-field>
          </div>

          <!-- COMPANY NAME FOR JURIDICAL PERSON -->
          <div class="col-12 col-md-6 mb-4" *ngIf="nameCorporate">
            <mat-form-field class="full-field-input">
              <mat-label> {{ 'STEP-REGISTER-COMPANY-NAME' | translate }} </mat-label>
              <app-custom-input class="input-with-icon" [required]="true" formControlName="nameCorporate">
              </app-custom-input>
              <mat-error *ngIf="personalForm.get('nameCorporate').touched && personalForm.get('nameCorporate').invalid">
                {{ 'STEP-REGISTER-COMPANY-NAME-ERROR' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <!-- COMPANY TYPE JURIDICAL PERSON -->
          <div class="col-12 col-md-6 mb-4" *ngIf="companyType">
            <mat-form-field
              class="full-field-input"
              *ngIf="!!personalForm.get('companyType') && comboParams?.companyTypes as types"
            >
              <mat-label> {{ 'STEP-REGISTER-COMPANY-TYPE' | translate }}</mat-label>
              <app-search-input
                valueFrom="id"
                label="description"
                [items]="types | orderBy: 'description'"
                formControlName="companyType"
              ></app-search-input>
            </mat-form-field>
          </div>

          <!-- FULLNAME FOR REPRESENTANT -->
          <div class="col-12 col-md-6 mb-4" *ngIf="nameRepresentative">
            <mat-form-field class="full-field-input">
              <mat-label> {{ 'PENDENCY-UPDATE-BPO-NAME-REPRESENTATIVE' | translate }} </mat-label>
              <app-custom-input class="input-with-icon" [required]="true" formControlName="nameRepresentative">
              </app-custom-input>
              <mat-hint>{{ 'STEP-REGISTER-NAME-HINT' | translate }}</mat-hint>
              <mat-error
                *ngIf="personalForm.get('nameRepresentative').touched && personalForm.get('nameRepresentative').invalid"
                >{{ 'STEP-REGISTER-NAME-ERROR' | translate }}</mat-error
              >
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
