<div class="container-fluid">
  <div class="row">
    <button class="button-back" [routerLink]="['/showcase']">
      <img src="./assets/images/ic_arrow_left.svg" alt="Previous" />
    </button>
  </div>

  <div class="row subsegment-title">
    {{ 'SUBSEGMENT-TITLE' | translate }}
  </div>

  <div class="row justify-content-center d-sm-none">
    <mat-card class="subsegment-qr-card">
      <mat-card-header>
        <div mat-card-avatar class="subsegment-qr-icon">
          <img src="./assets/images/ic_qrcode.svg" />
        </div>
        <mat-card-title> {{ 'SUBSEGMENT-CARD-QR-TITLE' | translate }} </mat-card-title>
        <mat-card-subtitle> {{ 'SUBSEGMENT-CARD-QR-SUBTITLE' | translate }} </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>

  <div class="row justify-content-center">
    <div class="col-" *ngFor="let subsegment of subsegments">
      <mat-card class="subsegment-card" (click)="selectSubsegment(subsegment)">
        <img class="subsegment-icon" [src]="subsegment.imageUrl" />
        <div class="card-content">
          <p class="mat-card-text">
            {{ subsegment.name }}
          </p>
        </div>
      </mat-card>
    </div>
  </div>
</div>
