import {
  RegisterAdditionalInformation,
  RegisterAddress,
  RegisterBacenAnswers,
  RegisterTravelersList,
  StepRegisterPut
} from '@app/proposal-workflow/models/step-register-put.model';
import { RegisterAction, RegisterTypes } from './register.actions';

export interface RegisterState {
  customer: StepRegisterPut;
  company: StepRegisterPut;
  addressPerson: RegisterAddress;
  addressCompany: RegisterAddress;
  travelers: RegisterTravelersList;
  additionalInformation: RegisterAdditionalInformation;
  bacenAnswers: RegisterBacenAnswers;
}

export const initialRegisterState: RegisterState = {
  customer: null,
  company: null,
  addressPerson: null,
  addressCompany: null,
  travelers: null,
  additionalInformation: null,
  bacenAnswers: null
};

export function registerReducer(state = initialRegisterState, action: RegisterAction) {
  switch (action.type) {
    case RegisterTypes.SET_CUSTOMER_DATA: {
      return {
        ...state,
        customer: {
          ...state.customer,
          ...action.payload
        }
      };
    }
    case RegisterTypes.SET_COMPANY_DATA: {
      return {
        ...state,
        company: {
          ...state.company,
          ...action.payload
        }
      };
    }
    case RegisterTypes.SET_ADDRESS_PERSON_DATA: {
      return {
        ...state,
        addressPerson: {
          ...state.addressPerson,
          ...action.payload
        }
      };
    }
    case RegisterTypes.SET_ADDRESS_COMPANY_DATA: {
      return {
        ...state,
        addressCompany: {
          ...state.addressCompany,
          ...action.payload
        }
      };
    }
    case RegisterTypes.SET_TRAVELERS_DATA: {
      return {
        ...state,
        travelers: {
          ...state.travelers,
          ...action.payload
        }
      };
    }
    case RegisterTypes.SET_ADDITIONAL_INFORMATION_DATA: {
      return {
        ...state,
        additionalInformation: {
          ...state.additionalInformation,
          ...action.payload
        }
      };
    }
    case RegisterTypes.SET_BACEN_ANSWERS_DATA: {
      return {
        ...state,
        bacenAnswers: {
          ...state.bacenAnswers,
          ...action.payload
        }
      };
    }

    case RegisterTypes.CLEAR_REGISTER_DATA: {
      return { ...initialRegisterState };
    }

    default:
      return state;
  }
}
