<div class="pion-dialog-container">
  <div class="modal-header">
    <button mat-icon-button aria-label="Fechar" class="close-terms" (click)="handleCloseButtonClick()">
      <img src="./assets/ic_close.svg" alt="fechar" />
    </button>
    <div class="modal-icon text-center">
      <img src="./assets/images/satander-icon.png" alt="Logo Santander" class="modal-logo" />
    </div>

    <h3 class="modal-title">{{ 'TERMS-AND-CONDITIONS' | translate }}</h3>
  </div>
  <div #termsContent class="modal-scroll-area" (scroll)="handleScroll($event)">
    <div *ngIf="data">
      <div [innerHTML]="this.data[0].description"></div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      mat-button
      [disabled]="disableBtn"
      color="primary"
      class="btn modal-button full-width"
      (click)="handleAcceptButtonClick()"
    >
      {{ 'AGREE' | translate }}
    </button>
  </div>
</div>

<ng-template #error>
  <p class="generic-error-message">{{ 'ERROR-LOADING-TERMS' | translate }}</p>
</ng-template>
