import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { environment } from '@env/environment';
import { CONFIG_PROFILE_QUERY } from '@shared/graphql/queries/config.query';
import { GET_ECONOMIC_ACTIVITIES } from '@shared/graphql/queries/domains.query';
import { GET_SPECIAL_OFFERS } from '@shared/graphql/queries/offers.query';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { Apollo } from 'apollo-angular';
import { ExecutionResult } from 'apollo-link';
import { Observable } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';
import { GetPersonalizedOffersReqDTO, GetPersonalizedOffersResDTO } from '../models/api/post.personalized-offers.model';
import { SpecialOfferDTO } from '../models/api/query.special-offers.model';
import { SpecialOffer } from '../models/showcase.model';
import { SalaryInputDialogComponent } from './../components/salary-input/salary-input-dialog/salary-input-dialog.component';

const mapSpecialOffers = (response: ExecutionResult): SpecialOffer[] => {
  if (!response.data) return;
  const mappedList = response.data.listSpecialOffers.map((specialOffer: SpecialOfferDTO) => ({
    ...specialOffer,
    isEmptyCard: false
  }));
  if (mappedList.length > 3) mappedList.push({ ...mappedList[0], isEmptyCard: true });
  return mappedList;
};

@Injectable({
  providedIn: 'root'
})
export class OffersService {
  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private sessionStorageService: SessionStorageService,
    public dialog: MatDialog
  ) {}

  public getPersonalizedOffers(): Observable<GetPersonalizedOffersResDTO[]> {
    const user = this.sessionStorageService.getUser();
    const endpoint = `${environment.apiRootUrl}/structure/offer/custom`;
    const body: GetPersonalizedOffersReqDTO = {
      documentNumber: user.document,
      objectTypeFinanced: 'CP',
      sequenceOffer: '001',
      betterOffer: 'S'
    };
    return this.http.post<GetPersonalizedOffersResDTO[]>(endpoint, body);
  }

  public getSpecialOffers(): Observable<SpecialOffer[]> {
    return this.apollo
      .watchQuery({
        query: GET_SPECIAL_OFFERS,
        variables: {
          sortField: 'descriptionPriorityNumber',
          activeOffers: true
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(map((response: ExecutionResult) => mapSpecialOffers(response)));
  }

  public getMaxFinancialValue(): Observable<any> {
    return this.apollo
      .watchQuery({
        query: CONFIG_PROFILE_QUERY,
        variables: { profile: ['maxFinancial'] },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: ExecutionResult) => {
          if (!response.data) return;
          const res = response.data.configProfiles[0];
          return { financedAmount: res.value ? res.value : null };
        })
      );
  }

  public getEconomicActivities(): Observable<any> {
    return this.apollo
      .watchQuery({
        query: GET_ECONOMIC_ACTIVITIES,
        variables: {},
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: ExecutionResult) => {
          if (!response.data) return;
          return response.data.proposalRegisterRender.economicActivityPf;
        })
      );
  }

  public handleIncomeModal(): Observable<any> {
    const incomeDialogRef = this.dialog.open(SalaryInputDialogComponent, {
      disableClose: false,
      position: { top: '150px', left: '10vw' }
    });

    return incomeDialogRef.afterClosed().pipe(
      take(1),
      filter(val => !!val)
    );
  }
}
