import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ClientType } from '@shared/constants/client-type.enum';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { Product } from '@shared/models/product.model';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { BehaviorSubject, Observable } from 'rxjs';

// MOCK
const productsMockRes: Product[] = [
  {
    id: 1,
    code: ProductCodeEnum.CDC,
    desc: 'CDC - Crédito Directo ao Consumidor',
    subsegment: {
      id: 1,
      code: 'A',
      name: 'Subsegment 1',
      imageUrl: ''
    },
    isEnabledForStore: true,
    isEnabledForSalesman: true,
    isEnabledForClientType: ClientType.PF
  },
  {
    id: 2,
    code: ProductCodeEnum.CSC,
    desc: 'CSC - Crédito Cessao',
    subsegment: {
      id: 1,
      code: 'A',
      name: 'Subsegment 1',
      imageUrl: ''
    },
    isEnabledForStore: true,
    isEnabledForSalesman: true,
    isEnabledForClientType: ClientType.PF
  },
  {
    id: 3,
    code: ProductCodeEnum.CSC,
    desc: 'CSC - Crédito Cessao [TURISMO]',
    subsegment: {
      id: 1,
      code: '301',
      name: 'Subsegment 1',
      imageUrl: ''
    },
    isEnabledForStore: true,
    isEnabledForSalesman: true,
    isEnabledForClientType: ClientType.PF
  }
];

@Injectable({
  providedIn: 'root'
})
export class TabService {
  secondaryUser$: BehaviorSubject<any> = new BehaviorSubject(null);
  secondaryUserObservable = this.secondaryUser$.asObservable();

  constructor(private http: HttpClient, private sessionStorageService: SessionStorageService) {}

  getTabs(document): Observable<any> {
    const user = this.sessionStorageService.getUser();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + user.accessToken
      })
    };
    const endpoint = `${environment.apiRootUrl}/shopkeeper-login/1.0.0/stores`;
    return this.http.get<any>(endpoint, httpOptions);
  }

  getProducts(storeCode: string): Observable<Product[]> {
    // return of(productsMockRes);
    const endpoint = `${environment.apiRootUrl}/shopuserprofile/1.0.0/stores/${storeCode}/products`;
    return this.http.get<Product[]>(endpoint);
  }
}
