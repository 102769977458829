import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@app/core/state';
import * as fromProposal from '@app/core/state/proposal';
import * as fromProposalInformation from '@app/core/state/proposal-information';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import { RedirectStepRegister, SetPayloadBestOffers } from '@app/core/state/simulation/simulation.actions';
import { getPayloadBestOffers } from '@app/core/state/simulation/simulation.selectors';
import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { ProposalStageEnum } from '@app/proposal-workflow/models/proposal-stage.enum';
import { ProposalProceedRequest, ProposalProceedResponse } from '@app/proposal-workflow/models/step-register-requests';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { RegistrationFeeType } from '@shared/constants/registration-fee-type.enum';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-simulation-summary',
  templateUrl: './simulation-summary.component.html',
  styleUrls: ['./simulation-summary.component.scss']
})
export class SimulationSummaryComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  proposal: ConfirmationData;
  public paymentPlanType = PaymentPlanType;
  public proposalId: string;
  public calculationPayload;
  public innerWidth: any;
  public MOBILE_BREAKPOINT = 768;
  public productCode = ProductCodeEnum;
  public RegistrationFee = RegistrationFeeType;
  public proposalStageEnum = ProposalStageEnum;
  public analysisShow = false;
  public payloadBestOffers: any;

  @Input() category: string;

  constructor(
    private readonly store$: Store<AppState>,
    private simulationService: SimulationService,
    private genTagger: GenTagger,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.onResize();
    this.getCurrentProposalId();
    this.getProposalData();

    this.store$
      .pipe(
        select(getConfirmationData),
        filter(data => !!data),
        take(1)
      )
      .subscribe(state => {
        if (state.proposalData.product === ProductCodeEnum.CDC) {
          this.category = '/portallojista/criarproposta/cdc/simulacao';
          this.genTagger.setTag({
            event_category: this.category,
            event_label: 'Simulação - CDC',
            event_action: Tag.pgView
          });
        } else if (state.proposalData.product === ProductCodeEnum.CSC) {
          this.category = '/portallojista/criarproposta/csc/simulacao';
          this.genTagger.setTag({
            event_category: this.category,
            event_label: 'Simulação - CSC',
            event_action: Tag.pgView
          });
        } else if (state?.proposalData?.product === ProductCodeEnum.CSCP) {
          this.category = '/portallojista/criarproposta/cscp/simulacao';
          this.genTagger.setTag({
            event_category: this.category,
            event_label: 'Simulação - CSCP',
            event_action: Tag.pgView
          });
        }
      });
    this.getAnalysisCd();
  }

  public simulationSumaryBackEdit(){
    this.store$.select(getPayloadBestOffers).pipe(takeUntil(this.ngUnsubscribe)).subscribe( res=>{
      this.payloadBestOffers = res;
      if(!!res.payload){
      this.payloadBestOffers.isReturn = true;
      }
    })
    this.store$.dispatch(new SetPayloadBestOffers(this.payloadBestOffers.payload, this.payloadBestOffers.isReturn));
  }

  private getAnalysisCd() {
    this.simulationService
      .getProposalAnalysisCd(this.proposalId)
      .toPromise()
      .then(v => (this.analysisShow = v));
  }

  public getCurrentProposalId() {
    this.store$
      .select(selectProposalId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(proposalId => !!proposalId)
      )
      .subscribe((proposalId: string) => {
        this.proposalId = proposalId;
        this.store$.dispatch(new fromProposalInformation.actions.GetConfirmationData(proposalId));
      });
  }

  public getProposalData() {
    this.store$
      .select(getConfirmationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: ConfirmationData) => {
        if (state) {
          this.proposal = state;
          this.setDecimalValues();
        }
      });
  }

  setDecimalValues() {
    const interestRate = +this.proposal.interestRate;
    const interestRateByYear = +this.proposal.interestRateByYear;
    const returnRate = +this.proposal.returnRate;
    const returnRateByYear = +this.proposal.returnRateByYear;

    this.proposal.interestRate = interestRate.toFixed(2);
    this.proposal.interestRateByYear = interestRateByYear.toFixed(2);
    this.proposal.returnRate = returnRate.toFixed(2);
    this.proposal.returnRateByYear = returnRateByYear.toFixed(2);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
  goRegister() {
    const payload: ProposalProceedRequest = {
      channel: 1,
      proposalId: this.proposalId,
      currentStage: this.proposalStageEnum.SIMULATION
    };
    this.store$.dispatch(new fromProposal.actions.SetProposalIdAction(this.proposal.proposalData.id));

    this.simulationService
      .proposalProceed(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: ProposalProceedResponse) => {
        this.store$.dispatch(new RedirectStepRegister());
      });
  }

  get getToolTipText(): string {
    if (!this.proposal) return '';

    if (
      this.proposal.planType !== this.paymentPlanType.STANDARD_PLAN ||
      this.proposal.planTypeSchemaData.hasFlexInstallments
    ) {
      return this.translateService.instant('SIMULATION-SUMARY-CUSTOM-PLAN');
    } else {
      return this.proposal.planTypeSchemaData.planTypeDescription;
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.screen.width;
  }
}
