import { ChallengeActions, ChallengeActionTypes } from '@app/core/state/challenge/challenge.actions';
import { Person } from '@shared/constants/person-type.enum';
import { BiometryPollingStatusEnum } from '@app/signup/models/biometry-challenge.model';

export interface ChallengeState {
  flowId: string;
  fType: string;
  legalEntity: boolean;
  getChallenge: any;
  answerChallenge: any;
  error?: any;
  isLoading?: boolean;
  challengeStatus: string;
  isRetryBiometry: boolean;
  crossedFluxLink: any;
  isCrossedFlux: boolean;
  crossedFluxSuccess: { success?: boolean };
  latestBiometryStatus: {
    attempt: number;
    status: BiometryPollingStatusEnum;
    nextStep: string;
  };
}

export const initialChallengeState: ChallengeState = {
  flowId: '',
  fType: '',
  legalEntity: false,
  getChallenge: null,
  answerChallenge: null,
  error: null,
  isLoading: false,
  challengeStatus: null,
  isRetryBiometry: false,
  crossedFluxLink: null,
  isCrossedFlux: false,
  crossedFluxSuccess: null,
  latestBiometryStatus: {
    attempt: 1,
    status: null,
    nextStep: ''
  }
};

export function challengeReducer(state = initialChallengeState, action: ChallengeActions): ChallengeState {
  switch (action.type) {
    case ChallengeActionTypes.START_CHALLENGE: {
      return {
        ...state,
        fType: action.payload.fType
      };
    }

    case ChallengeActionTypes.ERROR_CHALLENGE: {
      return {
        ...state,
        error: action.payload
      };
    }

    case ChallengeActionTypes.START_CHALLENGE_SUCCESS: {
      return {
        ...state,
        flowId: action.payload.flowId,
        legalEntity: action.payload.person === Person.LEGAL_ENTITY
      };
    }

    case ChallengeActionTypes.GET_CHALLENGE_SUCCESS: {
      return {
        ...state,
        getChallenge: action.payload
      };
    }

    case ChallengeActionTypes.ANSWER_CHALLENGE_SUCCESS: {
      return {
        ...state,
        answerChallenge: action.payload
      };
    }

    case ChallengeActionTypes.ANSWER_CHALLENGE_ERROR: {
      return {
        ...state,
        answerChallenge: action.payload
      };
    }

    case ChallengeActionTypes.SET_CHALLENGE_STATUS: {
      return {
        ...state,
        challengeStatus: action.payload
      };
    }

    case ChallengeActionTypes.RETRY_BIOMETRY_CHALLENGE: {
      return {
        ...state,
        isRetryBiometry: action.isRetry
      };
    }

    case ChallengeActionTypes.GET_CROSSED_FLUX_LINK_SUCCESS: {
      return {
        ...state,
        crossedFluxLink: action.payload
      };
    }

    case ChallengeActionTypes.GET_CROSSED_FLUX_LINK_ERROR: {
      return {
        ...state,
        crossedFluxLink: initialChallengeState.crossedFluxLink
      };
    }

    case ChallengeActionTypes.GET_CROSSED_FLUX_INFO_SUCCESS: {
      return {
        ...state,
        flowId: action.payload.flowId,
        fType: action.payload.flowType,
        getChallenge: {
          ...state.getChallenge,
          type: action.payload.challengeType,
          iv: action.payload.iv,
          nonce: action.payload.nonce
        }
      };
    }

    case ChallengeActionTypes.GET_CROSSED_FLUX_INFO_ERROR: {
      return {
        ...state
      };
    }

    case ChallengeActionTypes.SET_CROSSED_FLUX: {
      return {
        ...state,
        isCrossedFlux: action.payload
      };
    }

    case ChallengeActionTypes.CHECK_BIOMETRY_STATUS_SUCCESS: {
      return {
        ...state,
        latestBiometryStatus: {
          attempt: ++state.latestBiometryStatus.attempt,
          status: action.payload.status,
          nextStep: action.payload.nextStep
        }
      };
    }

    case ChallengeActionTypes.CHECK_BIOMETRY_STATUS_ERROR: {
      return {
        ...state,
        latestBiometryStatus: {
          attempt: ++state.latestBiometryStatus.attempt,
          status: initialChallengeState.latestBiometryStatus.status,
          nextStep: initialChallengeState.latestBiometryStatus.nextStep
        }
      };
    }

    case ChallengeActionTypes.CLEAR_BIOMETRY_STATUS: {
      return {
        ...state,
        latestBiometryStatus: initialChallengeState.latestBiometryStatus
      };
    }

    case ChallengeActionTypes.SET_CROSSED_FLUX_BIOMETRY_STATUS: {
      return {
        ...state,
        crossedFluxSuccess: { success: action.payload }
      };
    }

    default:
      return state;
  }
}
