import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BroadcastPaymentMethodService {
  receivePaymentMethod = new EventEmitter<string>();

  constructor() {}

  sendPaymentValue(paymentMethod: string) {
    this.receivePaymentMethod.emit(paymentMethod);
  }
}
