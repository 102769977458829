import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '@app/core/state';
import * as challenge from '@app/core/state/challenge';
import { errorChallenge, selectChallengeError } from '@app/core/state/challenge/challenge.selectors';
import { ErrorCodesChallenge } from '@app/core/state/challenge/model/challenge.model';
import { SetFtype } from '@app/core/state/login/login.actions';
import { FType } from '@app/core/state/login/login.reducer';
// Enum
import { DeliveryMethods } from '@app/login/interface/DeliveryMethods.enum';
import { SignUpService } from '@app/signup/services/sign-up.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
// NGRX stuff
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Countries } from '@shared/constants/countries.enum';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { FormValidationService } from '@shared/services/form-validation/form-validation.service';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { delay, filter } from 'rxjs/operators';

@Component({
  selector: 'app-pre-token',
  templateUrl: './pre-token.component.html',
  styleUrls: ['./pre-token.component.scss']
})
export class PreTokenComponent implements OnInit {
  public currentCountry = this.internationalizationService.currentCountry;
  public pioneerMasks = this.internationalizationService.pioneerMasks;
  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public countries = Countries;
  public documentNumberMask: string;
  public userExist = false;
  public labelButton = 'SEND-CODE';

  public challengeError$ = this.store$.pipe(select(selectChallengeError));
  public errorCodes = ErrorCodesChallenge;
  public recoveryError = false;
  private errorId = null;

  preTokenField: FormControl;
  fieldPlaceholder: string;

  fType: FType;
  recoveryType: string;

  public category = '';
  public label = '';
  readonly Insert: Tag = Tag.Insert;

  constructor(
    private internationalizationService: InternationalizationService,
    private translateService: TranslateService,
    private store$: Store<AppState>,
    private validationService: FormValidationService,
    private activatedRouter: ActivatedRoute,
    private signupService: SignUpService,
    private router: Router,
    public genTagger: GenTagger
  ) { }

  ngOnInit() {
    this.fType = this.activatedRouter.snapshot.params.fType;
    this.recoveryType = this.activatedRouter.snapshot.params.recoveryType;
    this.store$.dispatch(new SetFtype(this.fType))

    this.fieldPlaceholder = this.translateService.instant('PRE-TOKEN-PLACEHOLDER');
    this.preTokenField = new FormControl('', this.pioneerValidators.preToken);
    this.documentNumberMask = this.pioneerMasks.preToken.mask;

    if (this.activatedRouter.snapshot.params.recoveryType === 'recover-password') {
      this.category = '/portallojista/recuperarsenha';
      this.label = this.translateService.instant('RECOVER-PASSWORD-TITLE');
    }
    else if (this.activatedRouter.snapshot.params.recoveryType === 'primary-access') {
      this.category = '/portallojista/primeiroacesso';
      this.label = this.translateService.instant('LOGIN-PRIMARY-ACCESS');
    }
    this.genTagger.setTag({ event_category: this.category, event_action: Tag.pgView, event_label: this.label });

    if (this.currentCountry === Countries.BR) {
      this.preTokenField.valueChanges
        .pipe(
          delay(1),
          filter(val => !!val)
        )
        .subscribe(value => {
          this.applyBRValidatorsAndMasks(value);
        });
    }
  }

  applyBRValidatorsAndMasks(document: string) {
    this.documentNumberMask = this.pioneerMasks.documentNumber.mask;
    this.preTokenField.setValidators([...this.pioneerValidators.documentNumber, this.validationService.isCpfValid]);
  }

  parseDeliveryMethod(country) {
    return DeliveryMethods.DOCUMENT;
  }

  public characterChange(event: any): void {
    if (event) {
      this.userExist = false;
      this.labelButton = 'SEND-CODE';
    }
  }

  /**
   * Send token code request
   */
  public actionButton(isExist: boolean, isRecoveryError: boolean) {
    // Clear error state to dismiss erro msg
    this.store$.dispatch(new challenge.actions.ErrorChallenge(null));
    this.store$.pipe(select('challenge')).subscribe(res => {
      if (res.error && res.flowId && res.flowId !== this.errorId) {
        this.userExist = true;
        this.labelButton = 'Back-to-login';
        this.errorId = res.flowId;
      } else {
        this.labelButton = 'SEND-CODE';
        this.userExist = false;
      }
    });

    if (isExist || isRecoveryError) {
      // tslint:disable-next-line: no-unused-expression
      // this.userExist ? false : true;
      // tslint:disable-next-line: no-unused-expression
      // this.recoveryError ? false : true;
      this.router.navigate(['/login']);
    } else {
      const payload = {
        documentNumber: this.preTokenField.value,
        fType: this.fType,
        idLoginType: 3
      };
      this.signupService.sendDocInput$.next(this.preTokenField.value);

      // localStorage.setItem('documentNumber', payload.documentNumber);

      this.store$.dispatch(new challenge.actions.StartChallenge(payload));
      this.store$.pipe(select(errorChallenge)).subscribe((error: any) => {
        if (error && error.error && error.error.errorId === this.errorCodes.SAFETY_REASONS) {
          this.recoveryError = true;
          this.labelButton = 'Back-to-login';
        }
      });
    }
  }

}
