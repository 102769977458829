<form [formGroup]="form" autocomplete="off">
  <ng-container formGroupName="minimumData">
    <div class="container-group" [ngClass]="handleSelectedProduct()">
      <div class="group">
        <div class="col-12 input-row">
          <mat-form-field color="black" appearance="legacy" class="input">
            <mat-label>{{ 'CpfOrCnpj' | translate }}</mat-label>
            <input
              autocomplete="off"
              mask="{{ documentNumberMask }}"
              type="tel"
              matInput
              class="input-with-icon"
              formControlName="documentNumber"
              [showMaskTyped]="showMaskTypedForDocument"
              required
            />
            <img
              [hidden]="minimumData?.get('documentNumber')?.value === '' || !minimumData?.get('documentNumber')?.valid"
              class="valid-icon"
              src="./assets/icon-green.svg"
              alt="icon right green"
              matSuffix
            />
            <mat-error *ngIf="!minimumData?.get('documentNumber')?.valid">
              {{ 'CpfOrCnpj-INPUT-ERROR-MSG' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 input-row">
          <mat-form-field color="black" appearance="legacy" class="input">
            <mat-label>{{ 'DATE-OF-BIRTH-FOUNDATION' | translate }}</mat-label>
            <input
              autocomplete="off"
              type="tel"
              matInput
              class="input-with-icon"
              formControlName="date"
              mask="{{ $any(masks).dateOfBirth.mask }}"
              (focus)="showMaskBirthFoundation = true"
              (blur)="showMaskBirthFoundation = false"
              [showMaskTyped]="showMaskBirthFoundation"
              required
            />
            <button
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              class="calendar-button"
              type="button"
              (click)="openDialogCalendar('birth')"
            >
              <mat-icon>calendar_today</mat-icon>
            </button>
            <img
              [hidden]="minimumData?.get('date')?.value === '' || !minimumData?.get('date')?.valid"
              class="valid-icon"
              src="./assets/icon-green.svg"
              alt="icon right green"
              matSuffix
            />
            <mat-error *ngIf="!minimumData?.get('date')?.valid && !minimumData?.get('date')?.errors?.dateTooRecent">
              {{ 'DATE-OF-BIRTH-FOUNDATION-INPUT-ERROR-MSG' | translate }}
            </mat-error>
            <mat-error *ngIf="!minimumData?.get('date')?.valid && minimumData?.get('date')?.errors?.dateTooRecent">
              {{ 'DATE-OF-BIRTH-UNDER-18-INPUT-ERROR-MSG' | translate }}
            </mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 input-row">
          <mat-form-field color="black" appearance="legacy" class="input">
            <mat-label>{{ 'Telephone' | translate }}</mat-label>
            <input
              autocomplete="off"
              mask="{{ phoneMask }}"
              [showMaskTyped]="$any(masks).phone.showMaskedType"
              type="tel"
              matInput
              class="input-with-icon"
              formControlName="phone"
              required
              [patterns]="customPatterns"
            />
            <img
              [hidden]="minimumData?.get('phone')?.value === '' || !minimumData?.get('phone')?.valid"
              class="valid-icon"
              src="./assets/icon-green.svg"
              alt="icon right green"
              matSuffix
            />
            <mat-error *ngIf="!minimumData?.get('phone')?.valid">
              {{ 'PHONE-INPUT-ERROR-MSG' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="group">
        <div class="col-12 input-row">
          <mat-form-field class="col-12 input-row" appearance="legacy" class="input">
            <mat-label class="label-col">{{ 'FINANCED-VALUE-INPUT' | translate }}</mat-label>
            <input
              currencyMask
              type="tel"
              matInput
              [options]="$any(masks).financedValue.options"
              maxlength="{{ $any(masks).financedValue.maxLength }}"
              placeholder="{{ $any(masks).financedValue.placeholder }}"
              formControlName="financedValue"
              required
            />
            <mat-error
              *ngIf="!minimumData?.get('financedValue')?.valid && minimumData?.get('financedValue')?.errors?.min"
            >
              {{ 'FINANCED-VALUE-INPUT-ERROR-MIN-MSG' | translate }}
            </mat-error>
            <mat-error
              *ngIf="
                !minimumData?.get('financedValue')?.valid &&
                (minimumData?.get('financedValue')?.errors?.max || minimumData?.get('financedValue')?.errors?.required)
              "
            >
              {{ 'FINANCED-VALUE-INPUT-ERROR-MAX-MSG' | translate }}
            </mat-error>
            <img
              [hidden]="minimumData?.get('financedValue')?.value === '' || !minimumData?.get('financedValue')?.valid"
              class="valid-icon"
              src="./assets/icon-green.svg"
              alt="icon right green"
              matSuffix
            />
          </mat-form-field>
        </div>

        <div class="col-12 input-row">
          <mat-form-field class="col-12 input-row" appearance="legacy" class="input">
            <mat-label class="label-col">{{ 'ENTRY-VALUE-INPUT' | translate }}</mat-label>
            <input
              currencyMask
              type="tel"
              matInput
              [options]="$any(masks).entryValue.options"
              maxlength="{{ $any(masks).entryValue.maxLength }}"
              placeholder="{{ $any(masks).entryValue.placeholder }}"
              formControlName="entryValue"
            />
            <mat-error
              *ngIf="
                !minimumData?.get('entryValue')?.valid &&
                minimumData?.get('entryValue')?.errors?.isEqualOrGreaterThanFinancedValue
              "
            >
              {{ 'ENTRY-VALUE-INPUT-ERROR-EQUAL-OR-GREATER-THAN-FINANCED-VALUE-MSG' | translate }}
            </mat-error>
            <mat-error *ngIf="!minimumData?.get('entryValue')?.valid && minimumData?.get('entryValue')?.errors?.max">
              {{ 'ENTRY-VALUE-INPUT-ERROR-MAX-MSG' | translate }}
            </mat-error>
            <img
              [hidden]="minimumData?.get('entryValue')?.untouched || !minimumData?.get('entryValue')?.valid"
              class="valid-icon"
              src="./assets/icon-green.svg"
              alt="icon right green"
              matSuffix
            />
          </mat-form-field>
        </div>
      </div>

      <div class="group">
        <div *ngIf="minimumData?.get('travelDate')" class="col-12 input-row">
          <mat-form-field color="black" appearance="legacy" class="input">
            <mat-label>{{ 'TRAVEL-DATE' | translate }}</mat-label>
            <input
              autocomplete="off"
              type="tel"
              matInput
              class="input-with-icon"
              formControlName="travelDate"
              mask="{{ $any(masks).travelDate.mask }}"
              (focus)="showMasktravelDate = true"
              (blur)="showMasktravelDate = false"
              [showMaskTyped]="showMasktravelDate"
              required
            />
            <button
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              class="calendar-button"
              type="button"
              (click)="openDialogCalendar('travel')"
            >
              <mat-icon>calendar_today</mat-icon>
            </button>
            <img
              [hidden]="minimumData?.get('travelDate')?.value === '' || !minimumData?.get('travelDate')?.valid"
              class="valid-icon"
              src="./assets/icon-green.svg"
              alt="icon right green"
              matSuffix
            />
            <mat-error *ngIf="!minimumData?.get('travelDate')?.valid">
              {{ 'TRAVEL-DATE-INPUT-ERROR-MSG' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </ng-container>
</form>
