import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterEvent } from '@angular/router';
import { AppState } from '@app/core/state';
import { SubmitIdentificationIncomeAction } from '@app/core/state/pre-analysis/pre-analysis.actions';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import {
  getBestOffer,
  getSimulationParameters,
  selectStatusProposal
} from '@app/core/state/simulation/simulation.selectors';
import { InputChange } from '@app/proposal-workflow/models/input-change.model';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { SubsidyData } from '@app/proposal-workflow/models/subsidy-data.model';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import { MonthlyIncomeModalComponent } from '@shared/components/monthlyIncome-modal/monthlyIncome-modal.component';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { SimulationService } from '@shared/services/simulation/simulation.service';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { BrazilValidators } from '@shared/interfaces/validators/brazil-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';

@Component({
  selector: 'app-proposal-conditions-npp',
  templateUrl: './proposal-conditions.component.html',
  styleUrls: ['./proposal-conditions.component.scss']
})
export class ProposalConditionsNppComponent implements OnInit, OnChanges, OnDestroy {
  @Input() proposalData: any;
  @Output() showStoreConditionsEmitter = new EventEmitter<any>();
  @Output() changeValuesRecalculate = new EventEmitter<any>();
  @Output() changeValueSubsidy = new EventEmitter<any>();

  protected ngUnsubscribe: Subject<any> = new Subject();
  private timeOpen = 0;
  public fee = '';
  public rate = false;
  public env = environment;
  public hasPersonalData = false;
  public hasOffersData = false;
  public blueModal = false;
  public orangeModal = false;
  public isEdit = false;
  public whiteModal = false;
  public showStoreConditions = true;
  public showEntryFields = false;
  public editValues = false;
  public valuesForm: FormGroup;
  public minPurchaseValue = 1;
  public minEntryValue = 0;
  public documentType = { name: 'CPF', birthDate: 'Data de nascimento' };
  public paymentPlanType = PaymentPlanType;
  public removedData = false;
  public productCode = ProductCodeEnum;
  public validators = this.internationalizationService.pioneerValidators as BrazilValidators;


  public show = false;
  public in = false;

  public innerWidth: any;
  public MOBILE_BREAKPOINT = 768;
  public isMobile: boolean;

  public currentUrl: string;

  public withSubsidy = false;
  public subsidyData: SubsidyData;

  proposal: ConfirmationData;

  public statusProposal = {
    IsUpdateMaxFinancedValue: 'false',
    showCard: false,
    maxFinancedValue: '100000'
  };
  public isEnabledToInformIncome = false;

  public couponData = {
    sectionNumber: '7/7',
    coupon: '',
    proposalId: '',
    stepOffer: false
  };

  get purchaseValue() {
    return this.valuesForm.get('purchaseValue');
  }

  get entryValue() {
    return this.valuesForm.get('entryValue');
  }

  get financedValue() {
    return this.valuesForm.get('financedValue');
  }

  @Input() category: string;
  constructor(
    public matDialog: MatDialog,
    private store$: Store<AppState>,
    public router: Router,
    private genTagger: GenTagger,
    private internationalizationService: InternationalizationService,
    private simulationService: SimulationService
  ) {
    this.innerWidth = window.screen.width;
    this.isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;
  }

  ngOnInit() {
    this.getCurrentUrl();

    this.valuesForm = new FormGroup({
      purchaseValue: new FormControl(null, []),
      entryValue: new FormControl(0, this.validators.entryValue),
      financedValue: new FormControl(0, this.validators.financedValue)
    });
    this.valuesForm.valueChanges.subscribe(newValue => {
      if (this.proposalData && this.proposalData.offersData) {
        const newFinancedValue = newValue.purchaseValue - newValue.entryValue;

        if (newFinancedValue !== this.proposalData.offersData.financedValue) {
          this.proposalData.offersData.financedValue = newFinancedValue < 0 ? 0 : newFinancedValue;
          setTimeout(() => {
            this.setFinancedValue();
          }, 300);
        }
      }
    });

    this.getBestOffer();

    this.store$
      .select(getSimulationParameters)
      .pipe(
        filter(data => !!data),
        take(1)
      )
      .subscribe(params => {
        this.isEnabledToInformIncome = params.isEnabledToInformIncome;
      });

    this.store$
      .pipe(
        select(selectStatusProposal),
        takeUntil(this.ngUnsubscribe),
        filter(data => !!data)
      )
      .subscribe(status => {
        this.statusProposal = status;
      });

    this.store$
      .select(getConfirmationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: ConfirmationData) => {
        if (state) {
          this.proposal = state;
        }
      });

    this.innerWidth = window.screen.width;

    this.toggleEntryFields();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private getBestOffer() {
    this.store$
      .select(getBestOffer)
      .pipe(
        filter(data => !!data),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(res => {
        const isProcessing: boolean = res?.messages?.find(res => res?.message == 'PROCESSING').message
        if (isProcessing) {
          return
        }
        if (res && res.bestOffer) {
          this.withSubsidy = !!res.bestOffer.withSubsidy ? true : false;
          this.subsidyData = !!res.bestOffer.subsidy ? res.bestOffer.subsidy : null;
        }
      });
  }

  private getCurrentUrl() {
    this.currentUrl = this.router.url;
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: RouterEvent) => {
      this.currentUrl = event.url ? event.url : this.currentUrl;
    });
  }

  setProduct() {
    const code = this.proposalData.product.code;
    const customerType = this.proposalData.customerType;
    this.rate =
      (code === ProductCodeEnum.CP && customerType === SubmitPersonType.PF) ||
      (code === ProductCodeEnum.CDC && customerType === SubmitPersonType.PF) ||
      (code === ProductCodeEnum.CDC && customerType === SubmitPersonType.PJ);
  }

  ngOnChanges() {
    if (this.proposalData && this.proposalData.product && this.proposalData.customerType) {
      this.setProduct();
    }
    if (this.proposalData && this.proposalData.fees && this.proposalData.fees.length) {
      this.fee = this.proposalData.fees[0].feeValue ? this.proposalData.fees[0].feeValue : '';
    }
    if (this.proposalData && this.proposalData.personalData && this.proposalData.personalData.phone) {
      const completPhone = this.proposalData.personalData.phone.dddNumber + this.proposalData.personalData.phone.number;
      this.proposalData.personalData.phone.completPhone = completPhone;
      this.hasPersonalData = true;

      if (this.proposalData.personalData.documentNumber.length > 11) {
        this.documentType.name = 'CNPJ';
        this.documentType.birthDate = 'Data de fundação';
      } else {
        this.documentType.name = 'CPF';
        this.documentType.birthDate = 'Data de nascimento';
      }
    }
    if (this.proposalData && this.proposalData.offersData) {
      this.hasOffersData = true;

      this.couponData.proposalId = this.proposalData.offersData.proposalId;
      this.couponData.stepOffer = this.proposalData.stepOffer;
      this.couponData.coupon = this.proposalData.offersData.couponCode;

      // set values for edition
      if (!!this.valuesForm) {
        this.valuesForm.patchValue({
          purchaseValue: this.proposalData.offersData.purchaseValue,
          entryValue: this.proposalData.offersData.entryValue,
          financedValue: this.proposalData.offersData.financedValue
        });
        // validations form
        this.setValidationsForm();
      }
    }
    if (
      this.proposalData &&
      this.proposalData.offersData.shopkeeperData.isEntryValueAjusted &&
      this.currentUrl === '/proposal/step-offer'
    ) {
      this.orangeModal = true;
      this.genTagger.setTag({
        event_category: this.category,
        event_action: `${Tag.AbriuModal} - Sua entrada precisou ser alterada`,
        event_label: `Essa operação para o seu cliente precisa de pelo menos
        ${this.proposalData.offersData.shopkeeperData.minEntryValue}
        de entrada para que o financiamento seja possível.`
      });
    }
    if (this.proposalData && this.proposalData.calculationLimit.bannerShowFlag && this.timeOpen === 0) {
      this.timeOpen = 1;
      this.blueModal = true;
      this.genTagger.setTag({
        event_category: this.category,
        event_action: `${Tag.AbriuModal} - Melhores condições para seu cliente`,
        event_label: `Este ${this.documentType.name} possui um limite de ${this.proposalData.calculationLimit.maxBannerValue} pré-aprovado.
        Edite o valor e simule novamente a operação para aumentar o valor do financiamento.`
      });
      if (!this.orangeModal) {
        this.closeBlueModal();
      }
    }
    this.toggleEntryFields();
  }

  setFinancedValue() {
    this.valuesForm.patchValue({
      financedValue: this.proposalData.offersData.financedValue
    });
  }

  setValidationsForm(newMinEntryValue?: boolean) {
    this.minPurchaseValue = this.proposalData.offersData.shopkeeperData.minFinancedValue;
    this.valuesForm.controls['purchaseValue'].setValidators([
      Validators.required,
      Validators.min(this.minPurchaseValue)
    ]);

    if (!newMinEntryValue) {
      this.minEntryValue = this.proposalData.offersData.shopkeeperData.minEntryValue;
    }

  }

  // close blue modal after 5sec
  closeBlueModal() {
    setTimeout(() => {
      if (this.blueModal === true) {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: `${Tag.Fechou_Modal} - Melhores condições para seu cliente`,
          event_label: `x`
        });
      }
      this.blueModal = false;
    }, 5000);
  }

  openBlueModal(event: Event) {
    if (this.show) {
      event.stopPropagation();
      this.blueModal = true;
    }
    this.genTagger.setTag({
      event_category: this.category,
      event_action: `${Tag.AbriuModal} - Melhores condições para seu cliente`,
      event_label: `Este ${this.documentType.name} possui um limite de ${this.proposalData.calculationLimit.maxBannerValue} pré-aprovado.
      Edite o valor e simule novamente a operação para aumentar o valor do financiamento.`
    });
  }

  closeOrangeModal() {
    this.orangeModal = false;
    if (this.blueModal) {
      this.closeBlueModal();
    }
    this.genTagger.setTag({
      event_category: this.category,
      event_action: `${Tag.Fechou_Modal} - Sua entrada precisou ser alterada`,
      event_label: `Essa operação para o seu cliente precisa de pelo menos
      ${this.proposalData.offersData.shopkeeperData.minEntryValue}
      de entrada para que o financiamento seja possível.`
    });
  }

  public toggleStoreConditionsVisibility(): void {
    this.showStoreConditions = !this.showStoreConditions;
    this.showStoreConditionsEmitter.emit(this.showStoreConditions);
  }

  public toggleEntryFields(): void {
    this.proposalData && this.proposalData.offersData.entryValue !== 0
      ? (this.showEntryFields = true)
      : (this.showEntryFields = false);
  }

  changeValues() {
    this.isEdit = false;
    this.editValues = !this.editValues;

    this.valuesForm.patchValue({
      financedValue: this.proposalData.offersData.financedValue
    });

    if (!this.editValues) {
      this.isEdit = true;
      const param = {
        valueForm: this.valuesForm.value,
        isEdit: this.isEdit
      }
      const payload = {
        proposalId: this.proposal.proposalData.id
      }

      this.simulationService.deleteEnquadramento(payload).pipe(take(1), takeUntil(this.ngUnsubscribe)).subscribe()
      this.changeValuesRecalculate.emit(this.valuesForm.value);
    }

    this.isEdit = false;

    this.toggleEntryFields();
  }

  public subsidyValueChanged(event: any): void {
    this.changeValueSubsidy.emit(event);
  }

  cleanPurchaseValue() {
    this.valuesForm.patchValue({
      purchaseValue: 0
    });
  }

  cleanEntryValue() {
    this.valuesForm.patchValue({
      entryValue: 0
    });
  }

  public opendialog() {
    this.removedData = false;
    const isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;
    this.matDialog.open(MonthlyIncomeModalComponent, {
      width: isMobile ? '320px' : '510px',
      height: isMobile ? '520px' : '505px',
      disableClose: true,
      autoFocus: false,
      data: { isOffers: this.currentUrl === '/proposal/step-offer' }
    });
  }

  removeCredit() {
    this.removedData = true;

    const payload: InputChange = {
      value: {
        preAnalysis: {
          income: 0
        }
      },
      input: InputTypes.INPUT_CREDIT
    };

    this.store$.dispatch(
      new SubmitIdentificationIncomeAction({
        inputChanged: payload,
        income: 0,
        redirectProposal: false,
        offerPayload: this.currentUrl === '/proposal/step-offer' ? this.getOfferPayload() : null
      })
    );
  }

  getOfferPayload() {
    return {
      proposalId: parseInt(this.proposal.proposalData.id),
      purchaseValue: this.proposal.purchaseValue,
      entryValue: this.proposal.entryValue,
      financedValue: this.proposal.financedValue
    };
  }

  handleBottomSheet() {
    this.show ? (this.in = true) : (this.in = false);
    this.show = !this.show;
    this.show
      ? (document.querySelector('body').style.overflow = 'hidden')
      : document.querySelector('body').removeAttribute('style');
  }
}
