import { Component, OnInit } from '@angular/core';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { FormGroup, FormControl, Validators, AbstractControl } from '@angular/forms';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatDialog } from '@angular/material/dialog';
import { BottomSheetCalendarComponent } from './bottomSheetCalendar/bottomSheetCalendar.component';
import { CalendarConfig } from '@shared/components/ui/calendar/calendar.model';
import { DialogChallengeComponent } from './dialog-challenge/dialog-challenge.component';
import { select, Store } from '@ngrx/store';
import { AppState } from '@app/core/state';
import * as challenge from '@app/core/state/challenge';
import { ChallengeService, ChallengeType, typeInputChallenge } from '@shared/services/challenge/challenge.service';

@Component({
  selector: 'app-challenge-question',
  templateUrl: './challenge-question.component.html',
  styleUrls: ['./challenge-question.component.scss']
})
export class ChallengeQuestionComponent implements OnInit {
  public challengeForm: any;
  public pioneerMasks: PioneerMasks;
  public pioneerValidators: PioneerValidators;
  public typeInputChallegnge = typeInputChallenge;

  constructor(
    private internationalizationService: InternationalizationService,
    private router: Router,
    private _bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private store$: Store<AppState>,
    private challengeService: ChallengeService
  ) {
    this.pioneerValidators = this.internationalizationService.pioneerValidators;
    this.pioneerMasks = this.internationalizationService.pioneerMasks;

    this.store$.dispatch(new challenge.actions.GetChallenge({ refresh: false }));
  }

  public inputs: [ChallengeType];

  ngOnInit() {
    this.store$.pipe(select('challenge')).subscribe(res => {
      if (res && res.getChallenge && res.getChallenge.challenges && res.getChallenge.challenges.questions) {
        this.inputs = res.getChallenge.challenges.questions.slice(0, 4);
        this.putMaskField();
        this.challengeForm = new FormGroup(this.generateForm());
      }
    });
  }

  public openCalendar(idInput: number): void {
    const yearNow = new Date().getFullYear();
    const bottomSheet = this._bottomSheet.open(BottomSheetCalendarComponent, {
      panelClass: 'bottomSheet',
      data: {
        isTruncateYears: false,
        isTruncateDays: { toFront: true },
        startYear: yearNow - 20,
        endYear: yearNow
      } as CalendarConfig
    });
    bottomSheet.afterDismissed().subscribe(res => {
      if (res) {
        res = moment().format(res.replace(/\//g, ''));
        this.challengeForm.controls[idInput].setValue(res);
      }
    });
  }

  public sendAnswer(): void {
    const tryError = false;
    if (tryError) {
      this.dialog.open(DialogChallengeComponent, {
        width: '600px'
      });
    } else {
      // const formValue: Array<ChallengeAnswerType> = [];
      // const controlForm = this.challengeForm.controls;

      // for (const key in controlForm) {
      //   if (Object.prototype.hasOwnProperty.call(controlForm, key)) {
      //     const element = controlForm[key];
      //     const objFormValue: ChallengeAnswerType = { id: Number(key), answer: element.value };
      //     formValue.push(objFormValue);
      //   }
      // }

      this.store$.dispatch(new challenge.actions.AnswerChallenge({ questions: [] }));
    }
  }

  public goBackPage(): void {
    this.router.navigate(['/singup/confirmation-token']);
  }

  private validatorDay(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value && (Number(control.value) === 0 || Number(control.value) > 31)) {
      return { day: true };
    }

    return null;
  }

  private validatorDate(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value.length === 8) {
      const dayString = Number(control.value[0] + control.value[1]);
      const monthString = Number(control.value[2] + control.value[3]);
      // const yearString = Number(control.value[4] + control.value[5] + control.value[6] + control.value[7]);

      if (dayString < 1 || dayString > 31) {
        return { day: true };
      } else {
        if (monthString < 1 || monthString > 12) {
          return { day: true };
        }
      }
      return null;
    } else {
      return { day: true };
    }
  }

  private validatorMonth(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value.length === 2) {
      if (control.value < 1 || control.value > 12) {
        return { month: true };
      }
    }
    return null;
  }

  private validatorYear(control: AbstractControl): { [key: string]: boolean } | null {
    if (control.value.length === 4) {
      return null;
    } else {
      return { day: true };
    }
  }

  private generateForm(): { [key: string]: AbstractControl } {
    const objFormControls: { [key: string]: AbstractControl } = {};
    this.inputs.forEach((element: any) => {
      for (const key in this.inputs) {
        if (Object.prototype.hasOwnProperty.call(this.inputs, key)) {
          const typeQuestion = element.id.toString();
          switch (element.field.type) {
            case typeInputChallenge.number:
              objFormControls[typeQuestion] = new FormControl('', [Validators.required]);
              break;
            case typeInputChallenge.date:
              objFormControls[typeQuestion] = new FormControl('', [Validators.required, this.validatorDate]);
              break;
            case typeInputChallenge.currency:
              objFormControls[typeQuestion] = new FormControl('', [Validators.required, Validators.min(1)]);
              break;
            case typeInputChallenge.day:
              objFormControls[typeQuestion] = new FormControl('', [Validators.required, this.validatorDay]);
              break;
            case typeInputChallenge.month:
              objFormControls[typeQuestion] = new FormControl('', [Validators.required, this.validatorMonth]);
              break;
            case typeInputChallenge.year:
              objFormControls[typeQuestion] = new FormControl('', [Validators.required, this.validatorYear]);
              break;
            case typeInputChallenge.string:
              objFormControls[typeQuestion] = new FormControl('', [
                Validators.required,
                Validators.pattern('/^[A-Za-z]+$/')
              ]);
              break;
            case typeInputChallenge.number:
              objFormControls[typeQuestion] = new FormControl('', [
                Validators.required,
                Validators.pattern('^[0-9]*$)]')
              ]);
              break;
            default:
              objFormControls[typeQuestion] = new FormControl('', [Validators.required, Validators.minLength(1)]);
              break;
          }
        }
      }
    });
    return objFormControls;
  }

  private putMaskField() {
    if (this.inputs && this.inputs.length > 0) {
      this.inputs.forEach((element: any) => {
        for (const key in element.field) {
          if (Object.prototype.hasOwnProperty.call(element.field, key)) {
            const keyValue = element.field[key];
            if (key === 'type') {
              switch (keyValue) {
                case typeInputChallenge.cep:
                  element.field['mask'] = this.pioneerMasks.zipCode.mask;
                  break;
                case typeInputChallenge.cpf:
                  element.field['mask'] = this.pioneerMasks.documentNumber.mask;
                  break;
                case typeInputChallenge.currency:
                  element.field['mask'] = '';
                  break;
                case typeInputChallenge.date:
                  element.field['mask'] = '';
                  break;
                case typeInputChallenge.day:
                case typeInputChallenge.month:
                  element.field['mask'] = '00';
                  break;
                case typeInputChallenge.year:
                  element.field['mask'] = '0000';
                  break;
                default:
                  break;
              }
            }
          }
        }
      });
    }
  }
}
