import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material/tabs';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { FinancialStatementService } from './services/financial-statement.service';

@Component({
  selector: 'app-financial-statement',
  templateUrl: './financial-statement.component.html',
  styleUrls: ['./financial-statement.component.scss']
})
export class FinancialStatementComponent implements OnInit {
  public window = window;
  public listPayments: any = [];
  public quickFilters: any = [];
  public tabSelected: string;
  isShowFilters = false;
  isHiddenFilters = true;
  public productType: string;
  public callControl = false;
  public callControlRealizados = true;
  // pagination
  public totalElements: number;
  public totalPages: number;
  public page = 1;
  public perPage = 30;
  public previousPage: number;

  // Tag
  public readonly category = '/portallojista/pagamentos/detalhe/extrato';
  public Selected: Tag = Tag.Selecionar;

  // Tagueamento
  readonly event_category: '/portallojista/propostas';

  public showTabRecebidos;
  public isShowingRecebidos = 'Recebidos';
  @ViewChild(MatTabGroup, { static: true }) extractTab: MatTabGroup;
  public extractSubTab: any;



  constructor(
    private financialStatementService: FinancialStatementService,
    private cdr: ChangeDetectorRef,
    public genTagger: GenTagger
  ) {}

  ngOnInit() {
    this.isShowReceived();
    this.genTagger.setTag({ event_category: this.category, event_action: Tag.pgView, event_label: 'parcelas' });
  }

  public onTabChange(event: MatTabChangeEvent)
  {
    this.extractSubTab = event

  }
  public receivedProduct(product){

    this.productType = product
    if(product === 'CSCP'){
      const tabGroup = this.extractTab;
     this.extractTab._tabs.first.isActive = false;
     this.extractTab._tabs.last.isActive = true;
     this.extractTab.selectedIndex = 2
    }

    if(this.tabSelected === 'Pagamentos Realizados' || this.tabSelected === 'Saldo Futuro'){
      this.productType = 'CSCP'
      const tabGroup = this.extractTab;
      tabGroup._tabs.last.isActive = true;
    }
  }
  private isShowReceived(): void {
    this.financialStatementService.getShowReceived().subscribe(ret => {
      this.showTabRecebidos = ret;
      if(this.productType === 'CSC'){
        const tabGroup = this.extractTab;
        tabGroup._tabs.first.isActive = true;
        tabGroup._tabs.first.position = 2;
       return;
      }
      if (!!this.showTabRecebidos) {
      //  this.isShowingRecebidos = true;
        this.callControl = true;
        const tabGroup = this.extractTab;
        tabGroup._tabs.first.isActive = true;
        tabGroup._tabs.first.position = 0;
        tabGroup._tabs.last.isActive = false;
        tabGroup._tabs.last.position = -1;
      } else {
      //  this.isShowingRecebidos = false;
        this.callControlRealizados = false;
      }
      // if(this.callControl){
      //   this.callControl = false
      // }
    });
  }

  public onTabClick(event) {
    this.tabSelected = event.tab.textLabel
    if(this.tabSelected === 'Propostas Efetivadas'){
      this.productType = 'CSC'
    }
    if (event.index === 0) {
      this.isShowingRecebidos = 'Recebidos';
    } else {
      this.isShowingRecebidos = 'Realizados';
    }
  }
}
