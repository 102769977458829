import { PipeTransform, Pipe } from '@angular/core';
import { MaskPipe } from 'ngx-mask';

@Pipe({ name: 'orderBy' })
export class OrderByPipe extends MaskPipe implements PipeTransform {
  transform(arr: any, field): any {
    arr.sort((a: any, b: any) => {
      if (a[field] < b[field]) {
        return -1;
      } else if (a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });

    return arr;
  }
}
