import { ActionReducerMap } from '@ngrx/store';
import * as challenge from './challenge';
import * as contracts from './contracts';
import * as domains from './domains';
import * as formalization from './formalization';
import * as identification from './identification';
import * as login from './login';
import * as notifications from './notifications';
import * as filter from './period-filter';
import * as preanalysis from './pre-analysis';
import * as profile from './profile';
import * as proposal from './proposal';
import * as proposalInformation from './proposal-information';
import * as proposalNegotiation from './proposal-negotiation';
import * as register from './register';
import * as showcase from './showcase';
import * as simulation from './simulation';
import * as startup from './startup';
import * as tabs from './tab';

export interface AppState {
  challenge: challenge.reducer.ChallengeState;
  contracts: contracts.reducer.ContractsState;
  domains: domains.reducer.DomainsState;
  identification: identification.reducer.IdentificationState;
  filter: filter.reducer.PeriodFilterState;
  formalization: formalization.reducer.FormalizationState;
  login: login.reducer.LoginState;
  notifications: notifications.reducer.NotificationsState;
  preanalysis: preanalysis.reducer.PreAnalysisState;
  profile: profile.reducer.ProfileState;
  proposal: proposal.reducer.ProposalState;
  proposalInformation: proposalInformation.reducer.ProposalInformation;
  simulation: simulation.reducer.SimulationState;
  showcase: showcase.reducer.ShowcaseState;
  startup: startup.reducer.StartupState;
  tabs: tabs.reducer.TabsState;
  proposalNegotiation: proposalNegotiation.reducer.ProposalNegotiationState;
  register: register.reducer.RegisterState;
}

export const reducers: ActionReducerMap<AppState> = {
         startup: startup.reducer.startupReducer,
         domains: domains.reducer.domainsReducer,
         profile: profile.reducer.profileReducer,
         showcase: showcase.reducer.showcaseReducer,
         proposal: proposal.reducer.proposalReducer,
         challenge: challenge.reducer.challengeReducer,
         contracts: contracts.reducer.contractsReducer,
         simulation: simulation.reducer.simulationReducer,
         preanalysis: preanalysis.reducer.preAnalysisReducer,
         filter: filter.reducer.PeriodFilterReducer,
         formalization: formalization.reducer.formalizationReducer,
         login: login.reducer.loginReducer,
         proposalInformation: proposalInformation.reducer.proposalInformationReducer,
         notifications: notifications.reducer.notificationsReducer,
         tabs: tabs.reducer.tabsReducer,
         identification: identification.reducer.identificationReducer,
         proposalNegotiation: proposalNegotiation.reducer.proposalNegotiationReducer,
         register: register.reducer.registerReducer
       };

export const effects: Array<any> = [
         startup.effects,
         domains.effects,
         profile.effects,
         showcase.effects,
         proposal.effects,
         challenge.effects,
         contracts.effects,
         preanalysis.effects,
         simulation.effects,
         formalization.effects,
         login.effects,
         proposalInformation.effects,
         notifications.effects,
         tabs.effects,
         identification.effects,
         proposalNegotiation.effects
       ];

export const initialState = {
         startup: startup.reducer.startupState,
         domains: domains.reducer.initialDomainsState,
         profile: profile.reducer.profileInitialState,
         showcase: showcase.reducer.initialShowcaseState,
         proposal: proposal.reducer.initialProposalState,
         challenge: challenge.reducer.initialChallengeState,
         contracts: contracts.reducer.initialContractsState,
         preanalysis: preanalysis.reducer.initialPreAnalysisState,
         filter: filter.reducer.initPeriodFilterState,
         simulation: simulation.reducer.initialSimulationState,
         formalization: formalization.reducer.initialFormalizationState,
         login: login.reducer.initialLoginState,
         proposalInformation: proposalInformation.reducer.proposalInformationInitial,
         notifications: notifications.reducer.initialNotificationsState,
         tabs: tabs.reducer.startupState,
         identification: identification.reducer.initialIdentificationState,
         proposalNegotiation: proposalNegotiation.reducer.initialState,
         register: register.reducer.initialRegisterState
       };
