export enum ProposalMacroStage {
  SIMULATION = 10,
  REGISTER = 11,
  CONFIRMATION = 12,
  FORMALIZATION = 13
}

export interface GetProposalStageDTO {
  stageId: number;
  stageName: string;
  portalStage: number;
  idStage?: number;
  groupId: number;
  groupName: string;
  actionStartedAt: string;
  actionEndedAt: string;
  position: string;
  status: string;
  retailOperator: string;
  flagBackToSimulation: boolean;
  stageProposal: string;
}
