<div id="modal-content-wrapper" class="pion-modal text-center">
  <header id="modal-header" class=" mt-4">
    <div *ngIf="data.type === 'proposal-denied'">
      <h1 id="modal-title" mat-dialog-title>{{ 'NO-OFFERS-AVAILABLE-MODAL-TITLE' | translate }}</h1>
      <p class="ml-4">{{ 'NO-OFFERS-AVAILABLE-MODAL-DESCRIPTION' | translate }}</p>
    </div>
    <div *ngIf="data.type === 'proposal-formalization'">
      <h1 id="modal-title" mat-dialog-title>{{ 'OPEN-FINANCE' | translate }}</h1>
    </div>
  </header>
  <section id="modal-body" mat-dialog-content>
    <span class="title-open-finance" *ngIf="data.type !== 'proposal-formalization'">
      {{ 'OPEN-FINANCE' | translate }}
    </span>
    <p class="msg-open-finance" *ngIf="data.type === 'proposal-acepted' || data.type === 'proposal-formalization'">
      {{ 'OPEN-FINANCE-APPROVED-MSG' | translate }}
    </p>
    <p class="msg-open-finance" *ngIf="data.type === 'proposal-denied'">
      {{ 'OPEN-FINANCE-NO-APPROVED-MSG' | translate }}
    </p>
    <p class="msg-open-finance " *ngIf="data.type === 'preAnaliseShow'">
      <br />
      {{ 'OPEN-FINANCE-ACEPTED-MSG' | translate }}
    </p>
    <div
      class="col-12 col-md-6 mx-auto d-none d-md-block"
      *ngIf="
        data.type === 'proposal-acepted' || data.type === 'proposal-denied' || data.type === 'proposal-formalization'
      "
    >
      <div *ngIf="qrCodeSize" class="qr-canvas-container" [ngStyle]="containerStyles">
        <qrcode class="qr-code-canvas mt-0" [qrdata]="urlOpenFinance" [width]="qrCodeSize" [errorCorrectionLevel]="'M'">
        </qrcode>
      </div>
    </div>
    <p class="msg-open-finance" *ngIf="data.type === 'proposal-respesc'">
      {{ 'OPEN-FINANCE-ACEPTED-MSG' | translate }}
    </p>
  </section>
  <footer id="modal-footer" class="modal-footer" mat-dialog-actions>
    <button
      *ngIf="
        data.type === 'proposal-acepted' || data.type === 'proposal-denied' || data.type === 'proposal-formalization'
      "
      mat-stroked-button
      id="modal-cancel-button"
      color="primary"
      (click)="closeModal()"
      appTagger
      [event_category]="category"
      event_label=" {{ 'NO-SHARE' | translate }}"
    >
      {{ 'NO-SHARE' | translate }}
    </button>
    <button
      *ngIf="
        data.type === 'proposal-acepted' || data.type === 'proposal-denied' || data.type === 'proposal-formalization'
      "
      mat-flat-button
      id="modal-action-button"
      color="primary"
      (click)="openWindowOpenFinance()"
      appTagger
      [event_category]="category"
      event_label="{{ 'SHARE' | translate }}"
    >
      {{ 'SHARE' | translate }}
    </button>

    <button
      *ngIf="data.type === 'proposal-respesc'"
      mat-flat-button
      id="modal-action-button"
      color="primary"
      (click)="closeModal()"
      appTagger
      [event_category]="category"
      event_label="{{ 'CONTINUE' | translate }}"
    >
      {{ 'CONTINUE' | translate }}
    </button>
    <span>
      <button
        *ngIf="data.type === 'preAnaliseShow'"
        mat-flat-button
        id="modal-action-button"
        color="primary"
        (click)="closeModal()"
        appTagger
        [event_category]="category"
        event_label="{{ 'CONTINUE' | translate }}"
      >
        {{ 'CONTINUE' | translate }}
      </button>
      <br /><br />
    </span>
  </footer>
</div>
