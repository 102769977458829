import { HeaderItem } from '@shared/components/ui/header/interfaces/header-shopkeeper.interface';

// Notice: be conscious of the ordering on this enum since it is reused for view ordering as well
export enum HeaderItemCode {
  HEAD_PROPOSALS = 'LPRP',
  HEAD_PAYMENTS = 'LPAG',
  HEAD_MANAGEMENT = 'LGST',
  HEAD_USER_MGMT = 'LGSU',
  HEAD_POST_SALES = 'LSLE',
  HEAD_NOTIFICATIONS = 'LNTF',
  HEAD_HELP = 'LHLP',
  HEAD_HELP_FAQ = 'LFAQ',
  HEAD_HELP_TUTORIAL = 'LTUT',
  HEAD_QRCODE_READR = 'LQRC',
  HEAD_PROFILE = 'LPRF',
  HEAD_CHANG_PASSWD = 'LCPW',
  HEAD_SETTINGS = 'LCFG',
  HEAD_SUBMENU = 'LMNU',
  HEAD_SET_INITIAL_SCREEN = 'LCFG',
  HEAD_NOTIFICATION = 'LNTF',
  HEAD_DETAILS = 'LGDS',
  TUTORIAL_MENU = 'LTMN',
  TUTORIAL_PAYMENT = 'LTPG',
  TUTORIAL_PANEL = 'LTPR',
  TUTORIAL_FORMALIZATION = 'LTFM',
  TUTORIAL_SIMULATION = 'LTSM',
  HEAD_AGENT_CERT = 'LGAC'
}

export const HeaderShopKeeperModel: Array<HeaderItem> = [
  {
    code: HeaderItemCode.HEAD_PROPOSALS,
    position: 0,
    label: 'LPRP-MENU-LABEL',
    actionType: 'routeTo',
    targetAddress: '/showcase/open-proposal'
  },
  {
    code: HeaderItemCode.HEAD_PAYMENTS,
    position: 1,
    label: 'LPAG-MENU-LABEL',
    actionType: 'routeTo',
    targetAddress: '/showcase/payments'
  },
  {
    code: HeaderItemCode.HEAD_MANAGEMENT,
    position: 2,
    label: 'LGST-MENU-LABEL',
    actionType: 'routeTo',
    targetAddress: '/showcase/management'
  },
  {
    code: HeaderItemCode.HEAD_HELP,
    position: 3,
    label: 'LHLP-MENU-LABEL',
    actionType: 'callComponent',
    targetAddress: 'help',
    submenu: [
      {
        code: HeaderItemCode.HEAD_HELP_FAQ,
        position: 5,
        label: 'LFAQ-MENU-LABEL',
        actionType: 'routeTo',
        targetAddress: '/not/FAQ'
      },
      {
        code: HeaderItemCode.HEAD_HELP_TUTORIAL,
        position: 6,
        label: 'LTUT-MENU-LABEL',
        actionType: 'callComponent',
        targetAddress: null,
        submenu: [
          {
            code: HeaderItemCode.TUTORIAL_MENU,
            position: 7,
            label: 'LTMN-MENU-LABEL',
            actionType: 'routeTo',
            targetAddress: 'tutorials/menu',
            mobileOnly: false
          },
          {
            code: HeaderItemCode.TUTORIAL_PANEL,
            position: 8,
            label: 'LTPR-MENU-LABEL',
            actionType: 'routeTo',
            targetAddress: 'tutorials/showcase',
            mobileOnly: false
          },
          {
            code: HeaderItemCode.TUTORIAL_PAYMENT,
            position: 9,
            label: 'LTPG-MENU-LABEL',
            actionType: 'routeTo',
            targetAddress: 'tutorials/payment',
            mobileOnly: false
          },
          {
            code: HeaderItemCode.TUTORIAL_SIMULATION,
            position: 10,
            label: 'LTSM-MENU-LABEL',
            actionType: 'routeTo',
            targetAddress: 'tutorials/simulation',
            mobileOnly: false
          },
          {
            code: HeaderItemCode.TUTORIAL_FORMALIZATION,
            position: 11,
            label: 'LTFM-MENU-LABEL',
            actionType: 'routeTo',
            targetAddress: 'tutorials/formalization',
            mobileOnly: false
          }
        ]
      }
    ]
  },
  {
    code: HeaderItemCode.HEAD_SUBMENU,
    position: 7,
    label: 'LMNU-MENU-LABEL',
    actionType: 'callComponent',
    targetAddress: 'mat-menu-user',
    submenu: [
      {
        code: HeaderItemCode.HEAD_PROFILE,
        position: 8,
        label: 'LPRF-MENU-LABEL',
        actionType: 'callComponent',
        targetAddress: 'profile',
        mobileOnly: false
      },
      {
        code: HeaderItemCode.HEAD_CHANG_PASSWD,
        position: 9,
        label: 'LCPW-MENU-LABEL',
        actionType: 'callComponent',
        targetAddress: 'change-password',
        mobileOnly: false
      },
      {
        code: HeaderItemCode.HEAD_SET_INITIAL_SCREEN,
        position: 10,
        label: 'LCFG-MENU-LABEL',
        actionType: 'callComponent',
        targetAddress: 'set-initial-screen',
        mobileOnly: false
      },
      {
        code: HeaderItemCode.HEAD_USER_MGMT,
        position: 11,
        label: 'LGSU-MENU-LABEL',
        actionType: 'callComponent',
        targetAddress: 'user-management',
        mobileOnly: false
      },
      {
        code: HeaderItemCode.HEAD_DETAILS,
        position: 12,
        label: 'LGPD-MENU-LABEL',
        actionType: 'callComponent',
        targetAddress: 'proposal-detail',
        mobileOnly: false
      },
      {
        code: HeaderItemCode.HEAD_AGENT_CERT,
        position: 13,
        label: 'LGCA-MENU-LABEL',
        actionType: 'callComponent',
        targetAddress: 'certified-agent',
        mobileOnly: false
      }
    ]
  }
];
