import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({ name: 'localeCurrency' })
export class LocaleCurrencyPipe implements PipeTransform {
  // NOTE: - Se erro 'Missing locale data for the locale "xx".' for pipe 'DecimalPipe',
  // adicionar, no modulo que utiliza o pipe, por exemplo, para col:
  // registerLocaleData(localeEsCo, 'es-CO'); // e dentro de providers
  // { provide: LOCALE_ID, useValue: 'es-CO' }
  constructor(private currencyPipe: CurrencyPipe) { }

  getCurrencyCode() {
    if (environment.currentCountry) return 'BRL';
  }

  getLocaleCode() {
    if (environment.currentCountry) return 'pt-BR';
  }

  transform(
    value: any,
    currencyCode?: string,
    display?: string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string {
    currencyCode = this.getCurrencyCode();
    locale = this.getLocaleCode();

    if (value != null) {
      return this.currencyPipe.transform(value, currencyCode, display, digitsInfo, locale);
    }

    return this.currencyPipe.transform(0, currencyCode, display, digitsInfo, locale).split('0.00')[0];
  }
}
