import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, RouterEvent } from '@angular/router';
import { AppState } from '@app/core/state';
import * as fromProposal from '@app/core/state/proposal';
import { selectors } from '@app/core/state/proposal';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { selectors as simulationSelectors } from '@app/core/state/simulation';
import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { ProposalMacroStage } from '@app/proposal-workflow/models/proposal-stage.model';
import { BroadcastPaymentMethodService } from '@app/proposal-workflow/service/broadcast-payment-method.service';
import { ScrollToSimulationStepService } from '@app/proposal-workflow/service/scroll-to-simulation-step.service';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { FormalizationProposalDTO } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Payment } from '@shared/constants/payment-type.enum';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bottom-sheet',
  templateUrl: './bottom-sheet.component.html',
  styleUrls: ['./bottom-sheet.component.scss']
})
export class BottomSheetComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() public identification: any;
  @Input() public offer: any;
  public bottomSheetData: FormalizationProposalDTO;
  public showCard = true;
  public show = false;
  public rate = false;
  public fee = '';
  public in = false;

  public proposal: any;
  public currentUrl: string;
  public paymentType = 'Boleto bancário';
  public currentStep = null;

  public paymentPlanType = PaymentPlanType;
  scroll = this.scrollToSimulationStepService.scrollToSimulationStep;
  showInitialFee = false;
  showTransition: boolean;
  hideTransition: boolean;

  newCodeForm = new FormGroup({
    code: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(3)])
  });

  get code() {
    return this.newCodeForm.get('code');
  }

  constructor(
    private simulationService: SimulationService,
    private translate: TranslateService,
    private store$: Store<AppState>,
    private broadcastPaymentMethodService: BroadcastPaymentMethodService,
    private router: Router,
    private scrollToSimulationStepService: ScrollToSimulationStepService
  ) {}

  setProduct() {
    const code = this.bottomSheetData.product.code;
    const customerType = this.bottomSheetData.customerType;
    this.rate =
      (code === ProductCodeEnum.CP && customerType === SubmitPersonType.PF) ||
      (code === ProductCodeEnum.CDC && customerType === SubmitPersonType.PF) ||
      (code === ProductCodeEnum.CDC && customerType === SubmitPersonType.PJ);
  }

  handleBottomSheet() {
    this.show ? (this.in = true) : (this.in = false);
    this.show = !this.show;
    this.show
      ? (document.querySelector('body').style.overflow = 'hidden')
      : document.querySelector('body').removeAttribute('style');
  }

  handleCloseButton() {
    this.showCard = false;
  }

  ngOnInit() {
    this.store$
      .select(selectors.selectProposal)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(state => {
        this.bottomSheetData = state.data;
        if (this.bottomSheetData && this.bottomSheetData.fees && this.bottomSheetData.fees.length) {
          this.fee = this.bottomSheetData.fees[0].feeValue ? this.bottomSheetData.fees[0].feeValue : '';
        }
        if (this.bottomSheetData && this.bottomSheetData.product && this.bottomSheetData.customerType) {
          this.setProduct();
        }
      });

    this.getCurrentUrl();
    this.getProposalId();
    this.getProposalStage();
  }

  private getCurrentUrl() {
    this.currentUrl = this.router.url;
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: RouterEvent) => {
      this.currentUrl = event.url ? event.url : this.currentUrl;
      this.getProposalId();
    });
  }

  public getProposalId(): void {
    this.store$
      .select(fromProposal.selectors.selectProposalId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(proposalId => !!proposalId)
      )
      .subscribe(proposalId => {
        this.store$.dispatch(new fromProposal.actions.GetProposalAction(proposalId));
        if (this.currentUrl === '/proposal/step-formalization') {
          this.getConfirmationData();
        } else if (this.currentUrl === '/proposal/step-simulation') {
          this.getSimulationData();
        }
      });
  }

  public getSimulationData() {
    this.store$
      .select(simulationSelectors.selectSimulationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res && this.currentStep === ProposalMacroStage.SIMULATION) {
          this.broadcastPaymentMethodService.receivePaymentMethod.subscribe(result => {
            if (result === Payment.ACCOUNT_DEBIT) {
              this.paymentType = 'Débito em conta';
            } else {
              this.paymentType = 'Boleto bancário';
            }
          });
          this.proposal = {
            planType: res.paymentPlan,
            installmentAmount: res.installmentAmount,
            installmentValue: res.installmentValue,
            financedValue: res.financedValue,
            expireDate: moment(res.firstInstallmentDueDate).format('DD/MM/YYYY'),
            paymentType: {
              id: 0,
              description: this.paymentType
            }
          };
        }
      });
  }

  private getProposalStage(): void {
    this.store$
      .select(fromProposal.selectors.selectProposalStage)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(state => {
        if (!state || !state.groupId || (state && state.groupId === ProposalMacroStage.SIMULATION)) {
          this.currentStep = ProposalMacroStage.SIMULATION;
          return;
        }

        if (state && state.groupId === ProposalMacroStage.FORMALIZATION) {
          this.currentStep = ProposalMacroStage.FORMALIZATION;
          return;
        }

        if (state && state.groupId === ProposalMacroStage.REGISTER) {
          this.currentStep = ProposalMacroStage.REGISTER;
          return;
        }

        if (state && state.groupId === ProposalMacroStage.CONFIRMATION) {
          this.currentStep = ProposalMacroStage.CONFIRMATION;
          return;
        }
      });
  }

  public getConfirmationData() {
    this.store$
      .select(getConfirmationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: ConfirmationData) => {
        if (state) {
          this.proposal = state;
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
