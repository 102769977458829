import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { graphqlUri } from './../../../graphql/graphql.module';
import { AddRequestAction, RemoveRequestAction } from './store/loading.actions';

const EXCLUDED_QUERIES_NAMES: string[] = ['crossedFluxPolling'];
const EXCLUDED_REST_URLS_REGEX: RegExp[] = [
  new RegExp(`\/shopkeeper-formalization\/transferlink\/validate\/.+`),
  new RegExp(`\/shopkeeper-formalization\/1\.0\.0\/checklist\/.+\/file`),
  new RegExp(`\/shopkeeper-simulation\/1\.0\.0\/simulation\/([0-9]+)$`)
];

const isRequestExcluded = (request: HttpRequest<any>): boolean => {
  const isGraphQL = request.url === graphqlUri;

  if (isGraphQL) {
    const queryName = request.body.operationName;
    return EXCLUDED_QUERIES_NAMES.some(name => name === queryName);
  }

  if (!isGraphQL) {
    const requestUrl = request.url;
    return EXCLUDED_REST_URLS_REGEX.some(urlRegex => urlRegex.test(requestUrl));
  }

  return false;
};

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {
  private UID = 0;

  constructor(private store: Store<any>) {}

  generateUID() {
    return `UID-${this.UID++}`;
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const id = this.generateUID();

    const payload = { id, request };

    if (!isRequestExcluded(request)) {
      this.store.dispatch(new AddRequestAction(payload));
    }

    return next.handle(request).pipe(finalize(() => this.store.dispatch(new RemoveRequestAction(payload))));
  }
}
