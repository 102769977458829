<app-header-section [position]="pos.pos" [size]="pos.size" titleSection="STEP-REGISTER-INTRODUCTION-REPRESENTANT">
</app-header-section>

<div class="row">
  <div class="col-12 col-md-11">
    <div *ngIf="form" [formGroup]="form">
      <div formGroupName="person">
        <!-- FIRST ROW -->
        <div class="row">
          <!-- FIRST COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- FULLNAME FOR REPRESENTANT -->
            <mat-form-field>
              <mat-label> {{ 'STEP-REGISTER-FULL-NAME' | translate }} </mat-label>
              <app-custom-input class="input-with-icon" [required]="true" formControlName="name" appTagger
                [event_action]="Insert" [event_category]="event_category" event_label="nome completo"
                (focus)="checkFocus('person.name')">
              </app-custom-input>
              <mat-hint>{{ 'Enter the full name as per identification document' | translate }}</mat-hint>
              <mat-error *ngIf="form.get('person')?.get('name')?.touched && form.get('person')?.get('name')?.invalid">
                {{ 'STEP-REGISTER-NAME-ERROR' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <!-- SECOND COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- DOCUMENT NUMBER FOR REPRESENTANT-->
            <mat-form-field>
              <mat-label> {{ 'CPF' | translate }} </mat-label>
              <app-custom-input [required]="true" [customMask]="maskDocument" formControlName="documentNumber" appTagger
                [event_action]="Insert" [event_category]="event_category" event_label="cpf"
                (focus)="checkFocus('person.documentNumber')"></app-custom-input>
              <mat-hint>{{ 'Enter document' | translate }}</mat-hint>
              <mat-error *ngIf="
                  form.get('person')?.get('documentNumber')?.touched &&
                  form.get('person')?.get('documentNumber')?.invalid
                ">
                {{ 'Invalid CPF' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <!-- SECOND ROW -->
        <div class="row">
          <!-- FIRST COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- BIRTH DATE FOR REPRESENTANT -->
            <mat-form-field *ngIf="!!form.get('person')?.get('physical')" formGroupName="physical">
              <mat-label> {{ 'STEP-REGISTER-BIRTH-DATE' | translate }}</mat-label>
              <app-custom-input [required]="true" formControlName="birthDate" appTagger [customMask]="maskBirthDate"
                [event_action]="Insert" [event_category]="event_category" event_label="data de nascimento"
                (focus)="checkFocus('person.physical.birthDate')">
              </app-custom-input>
              <mat-hint>{{ 'Enter date of birth' | translate }}</mat-hint>
              <mat-error *ngIf="
                  form
                    .get('person')
                    ?.get('physical')
                    ?.get('birthDate')?.touched &&
                  form
                    .get('person')
                    ?.get('physical')
                    ?.get('birthDate')?.invalid
                ">
                {{ 'Invalid date' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <!-- SECOND COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- NATIONALITY FOR REPRESENTANT -->
            <mat-form-field *ngIf="!!form.get('person')?.get('physical') && comboParams?.nationalities as nationalities"
              formGroupName="physical">
              <mat-label> {{ 'STEP-REGISTER-NATIONALITY' | translate }}</mat-label>
              <app-search-input valueFrom="id" label="description" [items]="nationalities | orderBy: 'description'"
                formControlName="nationalityId" appTagger [event_action]="Insert" [event_category]="event_category"
                event_label="nacionalidade"></app-search-input>
            </mat-form-field>
          </div>
        </div>

        <!-- THIRD ROW -->
        <div class="row">
          <!-- FIRST COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- GENDER FOR REPRESENTANT -->
            <mat-form-field *ngIf="!!form.get('person')?.get('physical') && comboParams?.genders as genders"
              formGroupName="physical">
              <mat-label> {{ 'STEP-REGISTER-GENDER' | translate }}</mat-label>
              <app-search-input valueFrom="id" label="description" [items]="genders | orderBy: 'description'"
                formControlName="genderId" (changeOut)="onGenreChange()"></app-search-input>
            </mat-form-field>
          </div>

          <!-- SECOND COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- PROFESSION FOR REPRESENTANT -->
            <mat-form-field *ngIf="!!form.get('person')?.get('physical') && comboParams?.professions as professions"
              formGroupName="physical">
              <mat-label> {{ 'STEP-REGISTER-PROFESSION' | translate }}</mat-label>
              <app-search-input valueFrom="id" label="description" [items]="professions | orderBy: 'description'"
                formControlName="professionId"></app-search-input>
            </mat-form-field>
          </div>
        </div>

        <!-- FOURTH ROW -->
        <div class="row">
          <!-- FIRST COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- EMAIL FOR REPRESENTANT -->
            <mat-form-field formGroupName="mainEmail">
              <mat-label> {{ 'STEP-REGISTER-EMAIL' | translate }}</mat-label>
              <app-custom-input formControlName="email" [required]="true" appTagger [event_action]="Insert"
                [event_category]="event_category" event_label="e-mail" (focus)="checkFocus('person.mainEmail.email')">
              </app-custom-input>
              <mat-hint>{{ 'STEP-REGISTER-EMAIL-HINT' | translate }}</mat-hint>
              <mat-error *ngIf="
                  form
                    .get('person')
                    ?.get('mainEmail')
                    ?.get('email')?.touched &&
                  form
                    .get('person')
                    ?.get('mainEmail')
                    ?.get('email')?.invalid
                ">
                {{ 'STEP-REGISTER-EMAIL-ERROR' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6 mb-4">
            <!-- CELLPHONE FOR REPRESENTANT -->
            <mat-form-field formGroupName="mainPhone">
              <mat-label> {{ 'STEP-REGISTER-CELL-TELEPHONE' | translate }}</mat-label>
              <app-custom-input [required]="true" formControlName="number" [customMask]="maskPhone" appTagger
                [event_action]="Insert" [event_category]="event_category" event_label="telefone principal"
                (focus)="checkFocus('person.mainPhone.number')">
              </app-custom-input>
              <mat-hint>{{ 'STEP-REGISTER-CELL-HINT' | translate }}</mat-hint>
              <mat-error
                *ngIf="form.get('person')?.get('mainPhone')?.touched && form.get('person')?.get('mainPhone')?.invalid">
                {{ 'STEP-REGISTER-CELL-ERROR' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- FIFTH ROW -->
      <div class="row">
        <!-- FIRST COLUMN -->
        <div class="col-12 col-md-6 mb-4" formGroupName="person">
          <!-- MOTHER NAME REPRESENTANT -->
          <mat-form-field *ngIf="!!form.get('person')?.get('physical')" formGroupName="physical">
            <mat-label> {{ 'STEP-REGISTER-MOTHER-NAME' | translate }} </mat-label>
            <app-custom-input (focus)="checkFocus('person.physical.motherName')" [required]="true"
              formControlName="motherName">
            </app-custom-input>
            <mat-hint>{{ 'STEP-REGISTER-NAME-HINT' | translate }}</mat-hint>
            <mat-error *ngIf="
                form
                  .get('person')
                  ?.get('physical')
                  ?.get('motherName')?.touched &&
                form
                  .get('person')
                  ?.get('physical')
                  ?.get('motherName')?.invalid
              ">
              {{ 'STEP-REGISTER-NAME-ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <!-- SECOND COLUMN -->
        <div class="col-12 col-md-6 mb-4">
          <!-- RELATIONSHIP TYPE REPRESENTANT -->
          <mat-form-field
            *ngIf="!!form.get('companyRelationshipTypeId') && relationshipTypes$ | async as relationshipTypes">
            <mat-label> {{ 'STEP-REGISTER-RELATIONSHIP-TYPE' | translate }}</mat-label>
            <app-search-input valueFrom="id" label="description" [items]="relationshipTypes | orderBy: 'description'"
              formControlName="companyRelationshipTypeId" (changeOut)="onLegalNatureChange()"></app-search-input>
          </mat-form-field>
          <ng-template #relationshipTypesEmpty>
            <mat-form-field>
              <mat-label class="label-col">{{ 'STEP-REGISTER-RELATIONSHIP-TYPE' | translate }}*</mat-label>
              <input matInput disabled />
            </mat-form-field>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>