import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UnicoService {
  private unicoApiKey: string;

  setApiKey(key: string): void {
    this.unicoApiKey = key;
  }

  getApiKey(): string {
    return this.unicoApiKey;
  }
}
