import { Component, OnInit, TemplateRef, Input, OnDestroy, ViewChild, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../services/modal.service';
import { ModalOptions } from '../interfaces/ModalOptions.interface';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit, OnDestroy {
  @ViewChild('contentModal', { static: true }) contentModal: TemplateRef<any>;

  @Input() title: string;
  @Input() header = true;
  @Input() identifier: string;
  @Input() body: TemplateRef<any>;
  @Input() footer: TemplateRef<any>;
  @Input() size = 'lg';
  @Input() closable = true;
  @Input() autostart = false;
  @Input() customClass = '';
  @Input() backdrop = 'static';
  @Input() keyboard = false;

  @Output() openFinished: EventEmitter<any> = new EventEmitter();
  @Output() closeFinished: EventEmitter<any> = new EventEmitter();

  constructor(private modalService: ModalService) {}

  ngOnInit() {
    /**
     * Prevents modal initialization when it doesn't have an identifier
     */
    if (!this.identifier || !this.identifier.length) {
      throw new Error('Identifier field isn’t set. Please set one before calling <app-modal> in a template.');
    }

    this.onCreate(this.createOptionsPayload());
  }

  ngOnDestroy() {
    this.onDelete();
  }

  /**
   * Closes modal
   */
  modalClose() {
    this.modalService.closeModal(this.identifier);
  }

  /**
   * Create modal instance and add it to the stack
   */
  private onCreate(options: ModalOptions) {
    this.modalService.addModal(this, options);
  }

  /**
   * Removes modal instance on destroy
   */
  private onDelete() {
    this.modalService.removeModal(this.identifier);
  }

  /**
   * Creates form instance options payload
   */
  private createOptionsPayload(): ModalOptions {
    return {
      autostart: this.autostart,
      closable: this.closable,
      windowClass: this.customClass,
      size: this.size,
      backdrop: this.backdrop,
      keyboard: this.keyboard,
      animated: true,
      ignoreBackdropClick: true
    };
  }
}
