import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import JSEncrypt from 'jsencrypt';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import * as nacl from 'tweetnacl';
import * as util from 'tweetnacl-util';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {
  public backendkey!: string;
  public signKey!: string;
  public openSignkey!: string;

  get parsedKey(): Uint8Array {
    return util.decodeBase64(this.backendkey);
  }

  get parsedOpenSignkey(): Uint8Array {
    return util.decodeBase64(this.openSignkey);
  }

  get parsedSignKey(): Uint8Array {
    return util.decodeBase64(this.signKey);
  }

  get cipherPath(): string {
    if (JSON.parse(environment.newGateway)) {
      if (environment.pathCipherNewGateway.startsWith('/')) {
        return environment.pathCipherNewGateway;
      }

      return `/${environment.pathCipherNewGateway}`;
    }

    if (environment.pathCipher.startsWith('/')) {
      return environment.pathCipher;
    }

    return `/${environment.pathCipher}`;
  }

  constructor(private http: HttpClient) {}

  start() {
    const encrypt = new JSEncrypt();
    const keys = nacl.box.keyPair();
    const sign = nacl.sign.keyPair();

    encrypt.setPublicKey(util.encodeUTF8(util.decodeBase64(environment.key)));
    const fem = encodeURIComponent(encrypt.encrypt(util.encodeBase64(sign.secretKey)) as string);
    const zxw = encodeURIComponent(encrypt.encrypt(util.encodeBase64(keys.publicKey)) as string);

    return this.http
      .post(this.cipherPath + '/sign', {
        fem,
        zxw
      })
      .pipe(
        tap((res: any) => {
          const open: any = nacl.sign.open(util.decodeBase64(decodeURIComponent(res.data)), sign.publicKey);

          const parsedData: any = JSON.parse(util.encodeUTF8(open));
          this.backendkey = parsedData.key;

          const text: any = nacl.box.open.after(
            util.decodeBase64(parsedData.cypher),
            util.decodeBase64(parsedData.nonce),
            nacl.box.before(this.parsedKey, keys.secretKey)
          );

          this.signKey = JSON.parse(util.encodeUTF8(text)).s;
          this.openSignkey = JSON.parse(util.encodeUTF8(text)).p;
        })
      );
  }

  signData(data: string): Uint8Array {
    return nacl.sign(util.decodeUTF8(data), this.parsedSignKey);
  }

  encrypt(data: Object, keys: nacl.BoxKeyPair, one_time_code: Uint8Array): Uint8Array {
    const shared_key: Uint8Array = nacl.box.before(util.decodeBase64(this.backendkey), keys.secretKey);

    return nacl.box.after(util.decodeUTF8(JSON.stringify(data)), one_time_code, shared_key);
  }

  decrypt(data: any, keys: nacl.BoxKeyPair, one_time_code: Uint8Array): Uint8Array | null {
    const shared_key: Uint8Array = nacl.box.before(this.parsedKey, keys.secretKey);

    return nacl.box.open.after(data, one_time_code, shared_key);
  }
}
