import { Component } from '@angular/core';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.html',
  styleUrls: ['./carousel.scss']
})
export class NgbdCarouselBasic {
  images = [944, 1011, 984].map(n => `https://picsum.photos/id/${n}/900/500`);

  constructor(private genTagger: GenTagger) {}

  navIndicatorClicked(event) {
    // NOTE: - For ng-bootstrap version 5.1.0 or above the new 'source' field from ng-carousel slide event might be better
    if (event.target && event.target.id) {
      let bullet_number = ((event.target.id.split('-').pop() % (event.target.parentElement.children.length)));
      bullet_number++;
      this.genTagger.setTag({
        event_category: '/portallojista/login',
        event_action: Tag.Click,
        event_label: `bullet ${bullet_number}`
      });
    }
  }
}
