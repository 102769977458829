import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { IdentificationService } from '@app/identification/services/identification.service';
import { TaggerService } from '@app/tagging/tagger.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ModalOptions, PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { RemoveRequestAction } from '@shared/components/widgets/loading/store/loading.actions';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AppState } from './../index';
import { selectSelectedTab } from './../tab/tab.selectors';
import * as actions from './identification.actions';
import { selectRenderConfig } from './identification.selectors';

@Injectable()
export class IdentificationEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private identificationService: IdentificationService,
    private dialog: MatDialog,
    private taggerService: TaggerService
  ) {}

  @Effect()
  getIdentificationConfigAction = this.actions$.pipe(
    ofType<actions.GetIdentificationConfigAction>(actions.IdentificationActionTypes.GET_IDENTIFICATION_CONFIG),
    withLatestFrom(this.store$.select(selectSelectedTab)),
    switchMap(([action, selectedTab]) =>
      this.identificationService.getIdentificationConfig(selectedTab).pipe(
        map(response => new actions.GetIdentificationConfigSuccessAction(response)),
        catchError(err => of(new actions.GetIdentificationConfigErrorAction(err)))
      )
    )
  );

  @Effect()
  submitIdentificationAction = this.actions$.pipe(
    ofType<actions.SubmitIdentificationAction>(actions.IdentificationActionTypes.SUBMIT_IDENTIFICATION),
    withLatestFrom(this.store$.select(selectSelectedTab), this.store$.select(selectRenderConfig)),
    switchMap(([action, selectedTab, config]) => {
      return this.identificationService.submitIdentification(action.payload, selectedTab, config).pipe(
        map(response => new actions.SubmitIdentificationSuccessAction(response)),
        catchError(err => of(new actions.SubmitIdentificationErrorAction(err)))
      );
    })
  );

  @Effect({ dispatch: false })
  handleErrorSubmitIdentificationAction = this.actions$.pipe(
    ofType<actions.SubmitIdentificationErrorAction>(actions.IdentificationActionTypes.SUBMIT_IDENTIFICATION_ERROR),
    tap(() => {
      this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));

      this.dialog.open(PionModalComponent, {
        width: '560px',
        autoFocus: false,
        id: 'error-proposal',
        data: <ModalOptions>{
          title: 'Não é possível Iniciar uma proposta nova',
          description: 'Sistema indisponível. Por favor tente mais tarde.',
          confirmText: 'Ok',
          type: 'error'
        }
      });
    })
  );

  @Effect({ dispatch: false })
  submitIdentificationSuccessAction = this.actions$.pipe(
    ofType<actions.SubmitIdentificationSuccessAction>(actions.IdentificationActionTypes.SUBMIT_IDENTIFICATION_SUCCESS),
    map(action => this.identificationService.handleSubmitResponse(action.payload))
  );

  @Effect()
  cancelExistingProposalAction = this.actions$.pipe(
    ofType<actions.CancelExistingProposalAction>(actions.IdentificationActionTypes.CANCEL_EXISTING_PROPOSAL),
    switchMap(action => {
      return this.identificationService.cancelExistingProposal(action.payload).pipe(
        map(response => new actions.CancelExistingProposalSuccessAction(response)),
        catchError(err => of(new actions.CancelExistingProposalErrorAction(err)))
      );
    })
  );

  @Effect()
  getManagersEffect = this.actions$.pipe(
    ofType<actions.GetManagersAction>(actions.IdentificationActionTypes.GET_MANAGERS),
    withLatestFrom(this.store$.select(selectSelectedTab)),
    switchMap(([_action, selectedTab]) =>
      this.identificationService.getManagers(selectedTab.code).pipe(
        map(response => new actions.GetManagersActionSuccess(response)),
        catchError(err => of(new actions.GetManagersActionError(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  SaveProductEffect = this.actions$.pipe(
    ofType<actions.SaveProductAction>(actions.IdentificationActionTypes.SAVE_PRODUCT),
    map(action => this.taggerService.setProposalType(action.payload.code))
  );
}
