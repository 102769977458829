<!-- QUESTIONS FOR PAYMENT IN BANK ACCOUNT -->
<div class="mb-5" [formGroup]="form">
  <!-- <p class="title-section">
    <strong>{{ 'STEP-REGISTER-BACEN' | translate }}</strong>
  </p> -->
  <div class="mb-3">
    <p>1. {{ 'FORMALIZATION-PAYMENT-FIRST-QUESTION' | translate }}</p>
    <mat-radio-group formControlName="firstAnswer">
      <div class="d-flex">
        <ng-container *ngFor="let item of options">
          <div class="mb-1 p-1 mr-2">
            <mat-radio-button color="primary" [value]="item.id">
              {{ item.description }}
            </mat-radio-button>
          </div>
        </ng-container>
      </div>
    </mat-radio-group>
  </div>

  <!-- <div class="mb-3">
    <p>2. {{ 'FORMALIZATION-PAYMENT-SECOND-QUESTION' | translate }}</p>
    <mat-radio-group formControlName="secondAnswer">
      <div class="d-flex">
        <ng-container *ngFor="let item of options">
          <div class="mb-1 p-1 mr-2">
            <mat-radio-button color="primary" [value]="item.id">
              {{ item.description }}
            </mat-radio-button>
          </div>
        </ng-container>
      </div>
    </mat-radio-group>
  </div> -->
</div>
