<div class="container">
  <!-- Pendency success analysis -->
  <div *ngIf="proposal" class="col-12">
    <div class="biometry-status" *ngIf="showSuccessMessage">
      <!-- Close button -->
      <div class="close-button">
        <span class="material-icons close-icon" (click)="closeSuccessMessage()">
          close
        </span>
      </div>

      <!-- Biometry status-->
      <img class="biometry-icon" src="./assets/images/Check.svg" alt="" />
      <h1
        *ngIf="!bpoPendencyChanged || !proposalPendencyBiometryFlowEnabled"
        class="biometry-description"
        innerHTML="{{ 'PENDENCY-SUMMARY-SUCCESS-STATUS' | translate: { proposalId: proposal?.proposalData?.id } }}"
      ></h1>
      <h1
        *ngIf="bpoPendencyChanged && proposalPendencyBiometryFlowEnabled"
        class="biometry-description"
        innerHTML="{{
          'PENDENCY-MODIFY-SUMMARY-SUCCESS-STATUS' | translate: { proposalId: proposal?.proposalData?.id }
        }}"
      ></h1>
    </div>
  </div>

  <!-- Summary steps content-->
  <div class="title">
    {{ summaryData?.title | translate }}
  </div>

  <div class="row step-content" *ngFor="let data of summaryData?.steps; index as i">
    <div class="col-1 p-0 indicator-step">{{ data?.step }}.</div>
    <div class="col-11 p-0 description">{{ data?.description | translate }}</div>
  </div>

  <div class="card shadow">
    <div class="card-body">
      <div class="order-1 proposal-status">
        <!-- Proposal -->
        <div class="col-12 subtitle">
          {{ 'FORMALIZATION-SUMMARY-PROPOSAL-STATE-TITLE' | translate }}
        </div>
        <div class="col-12">
          <img
            class="proposal-icon"
            *ngIf="proposalFlag === summaryResult.BLANK"
            src="./assets/images/ic_cancel.svg"
            alt=""
          />
          <img
            class="proposal-icon"
            *ngIf="proposalFlag === summaryResult.YELLOW"
            src="./assets/images/ic_exclamation.svg"
            alt=""
          />
          <img
            class="proposal-icon"
            *ngIf="proposalFlag === summaryResult.GREEN"
            src="./assets/images/ic_check.svg"
            alt=""
          />
          <span class="proposal-text">{{ proposalStageText | firstLetterUppercase }}</span>
        </div>
      </div>

      <hr class="w-100 order-2 d-xl-none" />

      <div class="order-3 order-xl-2 proposal-status">
        <div class="col-12" *ngFor="let group of summaryStatus?.groups; index as i">
          <img
            class="proposal-icon"
            *ngIf="group?.semaphore?.value === summaryResult.BLANK"
            src="./assets/images/ic_cancel.svg"
            alt=""
          />
          <img
            class="proposal-icon"
            *ngIf="group?.semaphore?.value === summaryResult.YELLOW"
            src="./assets/images/ic_exclamation.svg"
            alt=""
          />
          <img
            class="proposal-icon"
            *ngIf="group?.semaphore?.value === summaryResult.GREEN"
            src="./assets/images/ic_check.svg"
            alt=""
          />
          <span class="status-text">{{ group?.groupName | firstLetterUppercase }}</span>
        </div>
      </div>

      <!-- Download proposal contract -->
      <div class="order-4 order-xl-3 download-proposal">
        <div class="col-12">
          <div class="float-left">
            <img
              class="icon-download"
              *ngIf="proposal?.contract?.UUID && proposal?.proposalData?.id"
              src="./assets/images/icon_download.svg"
              alt=""
            />
            <img
              class="icon-download"
              *ngIf="!proposal?.contract?.UUID || !proposal?.proposalData?.id"
              src="./assets/images/icon_download_grey.svg"
              alt=""
            />
          </div>
          <span
            (click)="downloadContract()"
            class="download-text"
            [ngClass]="{ dowloadEnabled: proposal?.contract?.UUID && proposal?.proposalData?.id }"
            >{{ 'FORMALIZATION-SUMMARY-PROPOSAL-DOWNLOAD' | translate }}</span
          >
          <span class="download-info">{{ 'FORMALIZATION-SUMMARY-PROPOSAL-DOWNLOAD-INFO' | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Back to intial process -->
  <div class="col-12">
    <button
      mat-raised-button
      (click)="handleLeave()"
      class="btn"
      *ngIf="!bpoPendencyChanged || !proposalPendencyBiometryFlowEnabled"
    >
      {{ 'FORMALIZATION-SUMMARY-BACK-TO-INITIAL-PAGE' | translate }}
    </button>
    <button
      mat-raised-button
      (click)="handleGoToProposal()"
      class="btn"
      *ngIf="bpoPendencyChanged && proposalPendencyBiometryFlowEnabled"
    >
      {{ 'ACCESS-STEP-FORMALIZATION' | translate }}
    </button>
  </div>
</div>
