<div
  id="modal-content-wrapper"
  *ngIf="cameFromProposalsList || (cameFromPaymentList && !isMassive) || cameFromPendency"
>
  <header id="modal-header" class="text-center mt-4">
    <img src="./assets/images/ic_exclamation_red.svg" width="44px" height="44px" alt="Alerta" class="mb-4" />
    <h1 id="modal-title mt-2 mb-2" mat-dialog-title>
      {{ 'DO-YOU-REALLY-WANTED-CANCEL-THE-PROPOSAL-REASON-DIALOG' | translate }}
    </h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <p class="text-center mb-3">{{ 'SELECT-A-REASON-FOR-CANCELLATION-MSG' | translate }}</p>
    <mat-form-field class="w-100">
      <mat-label>Motivo</mat-label>
      <mat-select [(ngModel)]="reasonIdSelected" (ngModelChange)="sendTagger($event)">
        <mat-option *ngFor="let reason of reasons" [value]="reason.idDominioConteudo">
          {{ reason.descricaoDominioConteudo }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </section>
  <footer id="modal-footer" mat-dialog-actions class="mb-3">
    <button mat-stroked-button id="modal-action-button" color="primary" (click)="closeDialog()">
      {{ 'KEEP-PROPOSAL' | translate }}
    </button>
    <button
      [disabled]="!reasonIdSelected"
      mat-flat-button
      id="modal-cancel-button"
      color="primary"
      (click)="cancelProposal()"
      appTagger
      [event_category]="category"
      event_label="{{ 'CANCEL_PROPOSAL' | translate }}"
    >
      {{ 'CANCEL_PROPOSAL' | translate }}
    </button>
  </footer>
</div>

<div id="modal-content-wrapper" *ngIf="cameFromPaymentList && isMassive">
  <header id="modal-header" class="text-center mt-4">
    <img src="./assets/images/ic_exclamation_red.svg" width="44px" height="44px" alt="Alerta" class="mb-4" />
    <h1 id="modal-title mt-2 mb-2" mat-dialog-title>{{ 'REASON-CANCEL-PROPOSAL-ASK' | translate }}</h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <p class="text-center mb-3 count">{{ countItem }} / {{ data.list.length }}</p>
    <p class="text-center mb-3">
      {{ 'SELECT-A-REASON-FOR-CANCELLATION-PROPOSAL-MSG' | translate }} <b class="text-nowrap">{{ item.id }}</b>
      {{ 'TO' | translate }} <b class="text-nowrap">{{ item.user }}</b> {{ 'AT-THE-COST-OF' | translate }}
      <b class="text-nowrap">{{ item.value | currency: 'BRL' }}</b
      ><b class="text-nowrap"> ({{ item.amountParcels }}x {{ item.valueParcels | currency: 'BRL' }})</b>.
    </p>
    <mat-form-field class="w-100">
      <mat-label>Motivo</mat-label>
      <mat-select [(ngModel)]="reasonIdSelected" (ngModelChange)="sendTagger($event)">
        <mat-option *ngFor="let reason of reasons" [value]="reason.idDominioConteudo">
          {{ reason.descricaoDominioConteudo }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </section>
  <footer id="modal-footer" mat-dialog-actions class="mb-3">
    <button mat-stroked-button id="modal-cancel-button" color="primary" (click)="goBack()">
      {{ 'Back' | translate }}
    </button>
    <button
      *ngIf="!lastItem && data.list.length > 1"
      [disabled]="!reasonIdSelected"
      mat-flat-button
      id="modal-action-button"
      color="primary"
      (click)="nextItem()"
      appTagger
      [event_category]="category"
      event_label="{{ 'Next' | translate }}"
    >
      {{ 'Next' | translate }}
    </button>
    <button
      *ngIf="lastItem || data.list.length === 1"
      [disabled]="!reasonIdSelected"
      mat-flat-button
      id="modal-action-button"
      color="primary"
      appTagger
      [event_category]="category"
      event_label="{{ 'CANCEL_PROPOSAL' | translate }}"
      (click)="massiveCancelProposal()"
    >
      {{ data.list.length === 1 ? 'Cancelar a proposta' : 'Cancelar propostas' }}
    </button>
  </footer>
</div>
