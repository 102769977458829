<div class="container-fluid">
  <div class="row">
    <div class="d-sm-none d-md-block col-md-5 col-sm-4 mx-0 px-0">
      <app-sidebar *ngIf="innerWidth > MOBILE_BREAKPOINT"></app-sidebar>
    </div>
    <div class="col-12 col-md-7 col-sm-12">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
