import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { AppState } from '@app/core/state';
import * as fromProposal from '@app/core/state/proposal';
import * as fromProposalInformation from '@app/core/state/proposal-information';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { SetPaymentPlanSelectedAction } from '@app/core/state/proposal/proposal.actions';
import * as fromSimulation from '@app/core/state/simulation';
import { selectors as simulationSelectors } from '@app/core/state/simulation';
import { SimulationActionTypes } from '@app/core/state/simulation/simulation.actions';
import { selectOffers } from '@app/core/state/simulation/simulation.selectors';
import { Offers } from '@app/proposal-workflow/containers/step-offers/model/offer.model';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { ProposalMacroStage } from '@app/proposal-workflow/models/proposal-stage.model';
import { SidebarConditions } from '@app/proposal-workflow/models/sidebar-data.model';
import { BroadcastPaymentMethodService } from '@app/proposal-workflow/service/broadcast-payment-method.service';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'simulation-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() sidebarData: SidebarConditions;
  public pioneerMasks: PioneerMasks;

  public proposalData: any;
  public proposal: ConfirmationData;

  public paymentType = 'Boleto bancário';

  public paymentShippingTypeId: number;
  public paymentShippingTypeDescription: string;
  public financedValue: number;
  public initialFinancedValue: number;

  installmentAmount: number;
  installmentValue: number;
  installmentAmountDouble: number;
  installmentValueDouble: number;

  public cardVisible: boolean;

  public rate = '';
  public proposalStage = ProposalMacroStage;

  public currentUrl: string;
  public currentStep = null;

  public simulationSidebar = false;

  public show = false;
  public in = false;

  @Input() public identification: any;
  @Input() public offer: any;
  @Input() public dueDate: string;
  simulationData: any;
  simulationParams: any;
  public productCode = ProductCodeEnum;

  /**
   * Data to adjust size
   */
  public innerWidth: any;
  public innerHeight: any;
  public MOBILE_BREAKPOINT = 768;
  public isMobile: boolean;

  zoom: string;

  public showStoreConditions = true;

  public offers: Offers;
  public proposalId;

  @Input() category: string;
  constructor(
    private internationalizationService: InternationalizationService,
    private simulationService: SimulationService,
    private broadcastPaymentMethodService: BroadcastPaymentMethodService,
    private store$: Store<AppState>,
    public router: Router,
    private updates$: Actions
  ) {
    this.innerWidth = window.screen.width;
    this.isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;
  }

  ngOnInit() {
    this.pioneerMasks = this.internationalizationService.pioneerMasks;
    this.getCurrentUrl();
    this.getProposalId();
    this.showSimulationSidebar();
    this.getProposalStage();
    this.simulationStep();
    this.findZoomRange(window.innerHeight);
    this.getPersonalEmail();
  }

  private getPersonalEmail(): void {
    this.store$
      .select(fromProposalInformation.selectors.personalEmailSelector)
      .pipe(
        filter(email => !!email),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.getConfirmationData(this.proposalId);
      });
  }

  private getCurrentUrl() {
    this.currentUrl = this.router.url;
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: RouterEvent) => {
      this.currentUrl = event.url ? event.url : this.currentUrl;
      this.getProposalId();
      this.showSimulationSidebar();
    });
  }

  public getProposalId(): void {
    this.store$
      .select(fromProposal.selectors.selectProposalId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(proposalId => !!proposalId)
      )
      .subscribe(proposalId => {
        this.proposalId = proposalId;
        if (
          this.currentUrl === '/proposal/step-register' ||
          this.currentUrl === '/proposal/step-formalization' ||
          this.currentUrl === '/proposal/step-confirmation' ||
          this.currentUrl === '/proposal/step-simulation/summary' ||
          this.currentUrl === '/proposal/step-offer'
        ) {
          this.getConfirmationData(proposalId.toString());
          this.getProposalInformationData();
        } else if (this.currentUrl === '/proposal/step-simulation') {
          this.getConfirmationData(proposalId.toString());
          this.getProposalInformationData();
          this.simulationStep();
        }
      });
  }

  private getConfirmationData(proposalId: string): void {
    this.store$.dispatch(new fromProposalInformation.actions.GetConfirmationData(proposalId));
  }

  private showSimulationSidebar() {
    if (this.currentUrl === '/proposal/step-simulation' || this.currentUrl === '/proposal/step-offer') {
      this.simulationSidebar = true;
    } else {
      this.simulationSidebar = false;
    }
  }

  public getProposalInformationData() {
    this.store$
      .select(getConfirmationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: ConfirmationData) => {
        if (state) {
          this.proposal = state;
          this.setSelectedPlan(state);
          if (this.currentUrl === '/proposal/step-offer') {
            this.selectOffersData();
          }
        }
      });
  }

  public setSelectedPlan(res) {
    this.store$.dispatch(
      new SetPaymentPlanSelectedAction({
        code: res.planTypeSchemaData.planTypeCode,
        firstInstallmentPMethodsDate: moment(res.planTypeSchemaData.monthOccurrency).format('MM/YYYY'),
        installmentDefaultValue: res.installmentValue,
        installmentPaymentMethodsValue: res.planTypeSchemaData.installmentPaymentMethodsValue,
        firstInstallmentValue: res.planTypeSchemaData.firstInstallmentValue,
        lastInstallmentValue: res.planTypeSchemaData.lastInstallmentValue,
        installmentDefaultAmount: res.planTypeSchemaData.installmentDefaultAmount,
        installmentPaymentMethodsAmount: res.planTypeSchemaData.installmentPaymentMethodsAmount,
        monthsRange: res.planTypeSchemaData.frequencedescription,
        hasFlexInstallments: res.planTypeSchemaData.hasFlexInstallments,
        installments: this.simulationData ? this.simulationData.installments : []
      })
    );
  }

  private getProposalStage(): void {
    this.store$
      .select(fromProposal.selectors.selectProposalStage)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(state => {
        if (!state || !state.groupId || (state && state.groupId === ProposalMacroStage.SIMULATION)) {
          this.currentStep = ProposalMacroStage.SIMULATION;
          return;
        }

        if (state && state.groupId === ProposalMacroStage.FORMALIZATION) {
          this.currentStep = ProposalMacroStage.FORMALIZATION;
          return;
        }

        if (state && state.groupId === ProposalMacroStage.REGISTER) {
          this.currentStep = ProposalMacroStage.REGISTER;
          return;
        }

        if (state && state.groupId === ProposalMacroStage.CONFIRMATION) {
          this.currentStep = ProposalMacroStage.CONFIRMATION;
          return;
        }
      });
  }

  private simulationStep(recalculate = false) {
    this.store$
      .select(simulationSelectors.selectSimulationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res) {
          this.simulationData = res;
          let offerSimulation = {};

          if (recalculate) {
            offerSimulation = {
              proposalId: this.proposalId,
              purchaseValue: +res.entryValue + +res.financedValue,
              entryValue: +res.entryValue,
              financedValue: +res.financedValue,
              couponCode: res.cupom,
              shopkeeperData: res.shopkeeperData,
              simulationOffer: []
            };
          } else {
            offerSimulation = {
              proposalId: this.proposalId,
              purchaseValue: +this.proposal.purchaseValue,
              entryValue: +this.proposal.entryValue,
              financedValue: +this.proposal.financedValue,
              couponCode: this.proposal.cupom,
              shopkeeperData: res.shopkeeperData,
              simulationOffer: []
            };
          }
          this.proposalData = {
            financedValue: res.financedValue,
            personalData: this.proposal.personalData,
            offersData: offerSimulation,
            calculationLimit: this.proposal.calculationLimit,
            hasFlexInstallments: this.simulationData.paymentMethods[0].hasFlexInstallments,
            planType: this.simulationData.paymentPlan,
            installmentAmount: this.simulationData.installmentAmount,
            installmentValue: this.simulationData.paymentMethods[0].lastInstallmentValue,
            productCode: this.proposal.proposalData.product,
            stepOffer: false
          };
        }
      });
  }

  public findZoomRange(innerHeight: number) {
    switch (true) {
      case innerHeight >= 813:
        this.zoom = 'oneHundredPerCent';
        break;
      case innerHeight >= 762:
        this.zoom = 'ninetyNinePercent';
        break;
      case innerHeight >= 678:
        this.zoom = 'ninetySevenPercent';
        break;
      case innerHeight >= 610:
        this.zoom = 'ninetyFourPercent';
        break;
      case innerHeight >= 550:
        this.zoom = 'ninetyTwoPercent';
        break;
      case innerHeight < 550:
        this.zoom = 'ninetyPercent';
        break;

      default:
        break;
    }
  }

  @HostListener('window:resize', ['$event'])
  public resizeListener() {
    this.findZoomRange(window.innerHeight);
  }

  public handleShowStoreConditions(event: boolean): void {
    this.showStoreConditions = event;
  }

  selectOffersData() {
    this.store$
      .select(selectOffers)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: Offers) => {
        if (state) {
          this.offers = state;
          this.mountProposalDataOffers();
        } else {
          this.getOffer();
        }
      });
  }

  recalculateValues(event) {
    if (event.purchaseValue > -1 && event.entryValue > -1) {
      if (this.currentUrl === '/proposal/step-offer') {
        this.updateOffer(event.purchaseValue, event.entryValue, event.financedValue);
      } else if (this.currentUrl === '/proposal/step-simulation') {
        this.updateOffer(event.purchaseValue, event.entryValue, event.financedValue);

        this.updates$
          .pipe(ofType(SimulationActionTypes.UPDATE_OFFER_SUCCESS))
          .pipe(take(1))
          .subscribe(() => {
            this.simulationService.changeValuesSidebar.next(event);

            this.updates$
              .pipe(ofType(SimulationActionTypes.GET_NEW_CALCULATION_SUCCESS))
              .pipe(take(1))
              .subscribe(() => {
                this.simulationStep(true);
              });
          });
      }
    }
  }

  public updateOffer(purchaseValue?, entryValue?, financedValue?) {
    const payload = {
      proposalId: parseInt(this.proposal.proposalData.id),
      purchaseValue: purchaseValue,
      entryValue: entryValue,
      financedValue: financedValue
    };

    this.store$.dispatch(new fromSimulation.actions.UpdateOffer(payload));

    this.updates$
      .pipe(ofType(SimulationActionTypes.UPDATE_OFFER_SUCCESS))
      .pipe(take(1))
      .subscribe(() => {
        if (this.currentUrl !== '/proposal/step-simulation') {
          this.saveConfirmationData(entryValue, purchaseValue);

          this.offers.entryValue = entryValue;
          this.offers.purchaseValue = purchaseValue;
          this.offers.financedValue = financedValue;
          this.getOffer();
        }
      });
  }

  saveConfirmationData(entryValue, purchaseValue) {
    let confirmationData: ConfirmationData;

    this.store$
      .select(getConfirmationData)
      .pipe(take(1))
      .subscribe(res => (confirmationData = res));

    confirmationData.entryValue = entryValue;
    confirmationData.purchaseValue = purchaseValue;

    this.store$.dispatch(new fromProposalInformation.actions.SaveConfirmationData(confirmationData));
    this.getConfirmationData(this.proposalId);
  }

  public getOffer() {
    const payload = {
      proposalId: parseInt(this.proposal.proposalData.id),
      purchaseValue: this.offers && this.offers.purchaseValue ? this.offers.purchaseValue : this.proposal.purchaseValue,
      entryValue: this.offers && this.offers.entryValue > -1 ? this.offers.entryValue : this.proposal.entryValue,
      financedValue: this.offers && this.offers.financedValue ? this.offers.financedValue : this.proposal.financedValue
    };

    this.store$.dispatch(new fromSimulation.actions.GetOffer(payload));

    // mount new proposal data offers after get offers
    this.updates$
      .pipe(ofType(SimulationActionTypes.GET_OFFER_SUCCESS))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.mountProposalDataOffers();
      });
  }

  mountProposalDataOffers() {
    if (this.proposal && this.offers) {
      this.offers.couponCode = this.proposal.cupom;
      this.proposalData = {
        financedValue: this.offers.financedValue,
        personalData: this.proposal.personalData,
        offersData: this.offers,
        calculationLimit: this.proposal.calculationLimit,
        stepOffer: true,
        productCode: this.proposal.proposalData.product
      };
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
