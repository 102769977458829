import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DocumentScanModel } from '@app/document-scan/models/document-scan.enum';

@Component({
  selector: 'app-arg-document-scan',
  templateUrl: './arg-document-scan.component.html',
  styleUrls: ['./arg-document-scan.component.scss']
})
export class ArgDocumentScanComponent implements OnInit {
  @Output() scanOutput: EventEmitter<DocumentScanModel> = new EventEmitter();

  private documentResponse: DocumentScanModel = new DocumentScanModel();

  public selphiIdConfigs = {
    fullCaptureMode: 2,
    widgetWidth: 700,
    widgetHeight: 450,
    dpiList: [163, 326, 489],
    captureMode: 2, // 0 = Document Front // 1 = Document Back // 2 Full Document,
    previewCapture: false,
    documentDimensions: { x: 0, y: 0, width: 85.6, height: 53.98 },
    qualityThreshold: 60,
    cameraWidth: 1920,
    cameraHeight: 1080
  };

  constructor() {}

  ngOnInit() {}

  onExtractionFinished(extractionResult) {
    console.log('extraction finished', extractionResult);
    if (extractionResult.detail.images) {
      const imagesSrc = extractionResult.detail.map(elements => {
        return elements.src;
      });

      this.documentResponse = {
        ...this.documentResponse,
        images: imagesSrc
      };
    }
    this.scanOutput.emit(this.documentResponse);
  }

  onExceptionCaptured(customEvent) {
    console.log('exception', customEvent);
    this.documentResponse = {
      ...this.documentResponse,
      error: customEvent
    };
    this.scanOutput.emit(this.documentResponse);
  }

  onUserCancelled() {
    this.documentResponse = {
      ...this.documentResponse,
      error: 'USER-CANCEL'
    };
    console.log('USER CANCELLED');
    this.scanOutput.emit(this.documentResponse);
  }

  onExtractionTimeout() {
    this.documentResponse = {
      ...this.documentResponse,
      error: 'TIMEOUT'
    };
    console.log('TIMEOUT');
    this.scanOutput.emit(this.documentResponse);
  }
}
