import { CommonModule, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import localePt from '@angular/common/locales/pt';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTabsModule } from '@angular/material/tabs';
import { BiometryModule } from '@app/biometry/biometry.module';
import { DisconnectedModule } from '@app/disconnected/disconnected.module';
import { DocumentScanModule } from '@app/document-scan/document-scan.module';
import { SignatureModule } from '@app/esign/signature.module';
import { LocaleCurrencyPipe } from '@app/pipes/locale-currency.pipe';
import { LocalePercentagePipe } from '@app/pipes/locale-percentage.pipe';
import { StepRegisterContainerComponent } from '@app/proposal-workflow/containers/step-register/step-register-container.component';
import { CountryGuard } from '@app/proposal-workflow/guards/country.guard';
import { ProposalWorkflowRoutingModule } from '@app/proposal-workflow/proposal-workflow-routing.module';
import { ProposalWorkflowContainerComponent } from '@app/proposal-workflow/proposal-workflow.container';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FirstLetterUppercasePipe } from '@shared/pipes/first-letter-uppercase.pipe';
import { SharedModule } from '@shared/shared.module';
import { QRCodeModule } from 'angularx-qrcode';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxCurrencyModule } from 'ngx-currency';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { AuthInterceptor } from './../core/auth/interceptors/auth.interceptor';
import { InsuranceModalComponent } from './../shared/components/insurance-modal/insurance-modal.component';
import { LoanFeesModalComponent } from './../shared/components/loan-fees-modal/loan-fees-modal.component';
import { PionModalComponent } from './../shared/components/pion-modal/pion-modal.component';
import { SummaryModalComponent } from './../shared/components/summary-modal/summary-modal.component';
import { BestOfferErrorComponent } from './components/best-offer-error/best-offer-error.component';
import { BestOfferLoadingComponent } from './components/best-offer-loading/best-offer-loading.component';
import { BottomSheetSumaryComponent } from './components/bottom-sheet-sumary/bottom-sheet-sumary.component';
import { BottomSheetComponent } from './components/bottom-sheet/bottom-sheet.component';
import { CashbackBenefitsComponent } from './components/cashback-benefits/cashback-benefits.component';
import { DiscountCouponComponent } from './components/discount-coupon/discount-coupon.component';
import { DueDateComponent } from './components/due-date/due-date.component';
import { InstallmentInfoAsc } from './components/installment-info-asc/installment-info-asc.component';
import { InstallmentInfoBln } from './components/installment-info-bln/installment-info-bln.component';
import { InstallmentInfoFixedComponent } from './components/installment-info-fixed/installment-info-fixed.component';
import { InstallmentInfoDec } from './components/installment-info-dec/installment-info-dec.component';
import { InstallmentInfoFlt } from './components/installment-info-flt/installment-info-flt.component';
import { InstallmentInfoLack } from './components/installment-info-lack/installment-info-lack.component';
import { InstallmentStepComponent } from './components/installment-step/installment-step.component';
import { InstallmentsModalFlexComponent } from './components/installments-modal-flex/installments-modal-flex.component';
import { InstallmentsModalComponent } from './components/installments-modal/installments-modal.component';
import { InsuranceStepComponent } from './components/insurance-step/insurance-step.component';
import { LoadingBarComponent } from './components/loading-bar/loading-bar.component';
import { OpenFinanceModalComponent } from './components/open-finance-modal/open-finance-modal.component';
import { PaymentPlanCalendarComponent } from './components/payment-plan-calendar/payment-plan-calendar.component';
import { PaymentPlanInstallmentInfoComponent } from './components/payment-plan-installment-info/payment-plan-installment-info.component';
import { PaymentStepComponent } from './components/payment-step/payment-step.component';
import { QrCodeComponent } from './components/qr-code/qr-code.component';
import { RegisterDataComponent } from './components/register-data/register-data.component';
import { RegistrationFeeComponent } from './components/registration-fee';
import { RegistrationFeeCardComponent } from './components/registration-fee-card/registration-fee-card.component';
import { CardInfoNppComponent } from './components/sidebar-npp/components/card-info/card-info.component';
import { InstallmentValueNppComponent } from './components/sidebar-npp/components/installment-value/installment-value.component';
import { LoanSummaryNppComponent } from './components/sidebar-npp/components/loan-summary/loan-summary.component';
import { ProposalConditionsNppComponent } from './components/sidebar-npp/components/proposal-conditions/proposal-conditions.component';
import { ProposalSumaryNppComponent } from './components/sidebar-npp/components/proposal-sumary/proposal-sumary.component';
import { SimulationDetailsNppComponent } from './components/sidebar-npp/components/simulation-details/simulation-details.component';
import { StoreConditionsNppComponent } from './components/sidebar-npp/components/store-conditions/store-conditions.component';
import { SubsidiaryValueComponent } from './components/sidebar-npp/components/subsidiary-value/subsidiary-value.component';
import { SummaryNppComponent } from './components/sidebar-npp/components/summary/summary.component';
import { SidebarNppComponent } from './components/sidebar-npp/sidebar.component';
import { CardInfoComponent } from './components/sidebar/components/card-info/card-info.component';
import { InstallmentValueComponent } from './components/sidebar/components/installment-value/installment-value.component';
import { LoanSummaryComponent } from './components/sidebar/components/loan-summary/loan-summary.component';
import { ProposalConditionsComponent } from './components/sidebar/components/proposal-conditions/proposal-conditions.component';
import { ProposalSumaryComponent } from './components/sidebar/components/proposal-sumary/proposal-sumary.component';
import { SimulationDetailsComponent } from './components/sidebar/components/simulation-details/simulation-details.component';
import { StoreConditionsComponent } from './components/sidebar/components/store-conditions/store-conditions.component';
import { SummaryComponent } from './components/sidebar/components/summary/summary.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { SimulationSummaryModalComponent } from './components/simulation-summary-modal/simulation-summary-modal.component';
import { SimulationSummaryComponent } from './components/simulation-summary/simulation-summary.component';
import { StepperComponent } from './components/stepper/stepper.component';
import { TooltipPaymentPlanComponent } from './components/tooltip-payment-plan/tooltip-payment-plan.component';
import { StepConfirmationProposalComponent } from './containers/step-confirmation/components/step-confirmation-proposal/step-confirmation-proposal.component';
import { StepConfirmationComponent } from './containers/step-confirmation/step-confirmation.component';
import { BiometryControllerComponent } from './containers/step-formalization/components/biometry-controller/biometry-controller.component';
import { AgentLegalStatusComponent } from './containers/step-formalization/components/biometry-controller/components/agent-legal-status/agent-legal-status.component';
import { AgentLegalComponent } from './containers/step-formalization/components/biometry-controller/components/agent-legal/agent-legal.component';
import { BiometryInstructionsComponent } from './containers/step-formalization/components/biometry-controller/components/biometry-instructions/biometry-instructions.component';
import { BiometryStatusComponent } from './containers/step-formalization/components/biometry-controller/components/biometry-status/biometry-status.component';
import { FacialCaptureComponent } from './containers/step-formalization/components/biometry-controller/components/facial-capture/facial-capture.component';
import { SignatureCaptureComponent } from './containers/step-formalization/components/biometry-controller/components/signature-capture/signature-capture.component';
import { CardConditionSelfieComponent } from './containers/step-formalization/components/card-condition-selfie/card-condition-selfie.component';
import { CardConditionComponent } from './containers/step-formalization/components/card-condition/card-condition.component';
import { CardStepComponent } from './containers/step-formalization/components/card-step/card-step.component';
import { ChecklistComponent } from './containers/step-formalization/components/checklist/checklist.component';
import { ChecklistAdditionalComponent } from './containers/step-formalization/components/checklist/components/checklist-additional/checklist-additional.component';
import { ChecklistItemComponent } from './containers/step-formalization/components/checklist/components/checklist-item/checklist-item.component';
import { FileUploadComponent } from './containers/step-formalization/components/checklist/components/file-upload/file-upload.component';
import { ImageGalleryComponent } from './containers/step-formalization/components/checklist/components/image-gallery/image-gallery.component';
import { EnableCameraStepsComponent } from './containers/step-formalization/components/enable-camera-steps/enable-camera-steps.component';
import { FormBipartiteComponent } from './containers/step-formalization/components/form-bipartite/form-bipartite.component';
import { FormPaymentComponent } from './containers/step-formalization/components/form-payment/form-payment.component';
import { FormalizationSectionComponent } from './containers/step-formalization/components/formalization-section/formalization-section.component';
import { FormalizationSummaryComponent } from './containers/step-formalization/components/formalization-summary/formalization-summary.component';
import { StepFormalizationComponent } from './containers/step-formalization/step-formalization.component';
import { StepOfferNppComponent } from './containers/step-offers-npp/offer/step-offer.component';
import { StepOffersNppComponent } from './containers/step-offers-npp/step-offers-npp.component';
import { StepOfferComponent } from './containers/step-offers/offer/step-offer.component';
import { StepOffersComponent } from './containers/step-offers/step-offers.component';
import { StepOffersContainer } from './containers/step-offers/step-offers.container';
import { AddressDataComponent } from './containers/step-register/components/address-data/address-data.component';
import { BacenQuestionsComponent } from './containers/step-register/components/bacen-questions/bacen-questions.component';
import { ObservationDataComponent } from './containers/step-register/components/observation-data/observation-data.component';
import { PersonalDataComponent } from './containers/step-register/components/personal-data/personal-data.component';
import { ProceedErrorModalComponent } from './containers/step-register/components/proceed-error/proceed-error-modal.component';
import { RecoveryDataModalComponent } from './containers/step-register/components/recovery-data-modal/recovery-data-modal.component';
import { RepresentantAddressDataComponent } from './containers/step-register/components/representant-address-data/representant-address-data.component';
import { RepresentantDataComponent } from './containers/step-register/components/representant-data/representant-data.component';
import { TravelerDataComponent } from './containers/step-register/components/traveler-data/traveler-data.component';
import { BraStepSimulationNppComponent } from './containers/step-simulation-npp/bra/bra-step-simulation-npp.component';
import { BraStepSimulationComponent } from './containers/step-simulation/bra/bra-step-simulation.component';
import { StepSimulationContainer } from './containers/step-simulation/step-simulation.container';
import { ProposalStatusInformationComponent } from './containers/step-register/components/proposal-status-information/proposal-status-information.component';
import { InstallmentInfoSeasonalComponent } from './components/installment-info-seasonal/installment-info-seasonal.component';
import { MonthChartComponent } from './components/month-chart/month-chart.component';

registerLocaleData(localePt);

@NgModule({
  declarations: [
    ProposalWorkflowContainerComponent,
    SidebarComponent,
    SidebarNppComponent,
    CardInfoComponent,
    CardInfoNppComponent,
    StoreConditionsComponent,
    StoreConditionsNppComponent,
    SummaryComponent,
    SummaryNppComponent,
    StepperComponent,
    StepOffersNppComponent,
    StepOffersComponent,
    StepOfferComponent,
    StepOfferNppComponent,
    StepSimulationContainer,
    StepOffersContainer,
    StepFormalizationComponent,
    StepConfirmationComponent,
    RegistrationFeeComponent,
    RegistrationFeeCardComponent,
    InstallmentStepComponent,
    InsuranceStepComponent,
    PaymentStepComponent,
    CashbackBenefitsComponent,
    InstallmentValueComponent,
    InstallmentValueNppComponent,
    BottomSheetComponent,
    DiscountCouponComponent,
    DueDateComponent,
    BestOfferLoadingComponent,
    LoadingBarComponent,
    FormalizationSectionComponent,
    CardConditionComponent,
    CardStepComponent,
    InstallmentsModalComponent,
    FileUploadComponent,
    FormPaymentComponent,
    BestOfferErrorComponent,
    QrCodeComponent,
    SimulationDetailsComponent,
    SimulationDetailsNppComponent,
    LoanSummaryComponent,
    LoanSummaryNppComponent,
    ProposalConditionsComponent,
    ChecklistComponent,
    BiometryInstructionsComponent,
    ProposalConditionsComponent,
    ProposalConditionsNppComponent,
    BiometryStatusComponent,
    FacialCaptureComponent,
    EnableCameraStepsComponent,
    BiometryControllerComponent,
    SimulationSummaryModalComponent,
    StepRegisterContainerComponent,
    AddressDataComponent,
    PersonalDataComponent,
    ChecklistItemComponent,
    ChecklistAdditionalComponent,
    ImageGalleryComponent,
    FormalizationSummaryComponent,
    SignatureCaptureComponent,
    StepConfirmationProposalComponent,
    RegisterDataComponent,
    ProposalSumaryComponent,
    ProposalSumaryNppComponent,
    BottomSheetSumaryComponent,
    RecoveryDataModalComponent,
    InstallmentsModalFlexComponent,
    ProceedErrorModalComponent,
    PaymentPlanCalendarComponent,
    PaymentPlanInstallmentInfoComponent,
    InstallmentInfoBln,
    InstallmentInfoFixedComponent,
    InstallmentInfoDec,
    InstallmentInfoFlt,
    InstallmentInfoLack,
    InstallmentInfoAsc,
    TooltipPaymentPlanComponent,
    TravelerDataComponent,
    RepresentantDataComponent,
    RepresentantAddressDataComponent,
    ObservationDataComponent,
    BacenQuestionsComponent,
    SimulationSummaryComponent,
    AgentLegalComponent,
    FormBipartiteComponent,
    AgentLegalStatusComponent,
    CardConditionSelfieComponent,
    SubsidiaryValueComponent,
    BraStepSimulationNppComponent,
    BraStepSimulationComponent,
    OpenFinanceModalComponent,
    ProposalStatusInformationComponent,
    InstallmentInfoSeasonalComponent,
    MonthChartComponent
  ],
  imports: [
    CommonModule,
    ProposalWorkflowRoutingModule,
    SharedModule,
    NgbModule,
    BiometryModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    DocumentScanModule,
    MatIconModule,
    MatTabsModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    MatTooltipModule,
    FormsModule,
    NgxCurrencyModule,
    MatProgressBarModule,
    NgxSkeletonLoaderModule,
    MatCardModule,
    CarouselModule,
    DisconnectedModule,
    MatCheckboxModule,
    QRCodeModule,
    SignatureModule,
    MatExpansionModule,
    PdfViewerModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    DatePipe,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    LocaleCurrencyPipe,
    LocalePercentagePipe,
    DecimalPipe,
    CountryGuard,
    FirstLetterUppercasePipe
  ],
  exports: [
    InstallmentStepComponent,
    RegistrationFeeComponent,
    InsuranceStepComponent,
    DueDateComponent,
    PaymentStepComponent,
    DiscountCouponComponent,
    BiometryInstructionsComponent,
    BiometryControllerComponent,
    ChecklistItemComponent,
    SidebarComponent,
    SidebarNppComponent,
    BottomSheetComponent,
    TooltipPaymentPlanComponent
  ]
})
export class ProposalWorkflowModule {}
