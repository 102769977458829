export enum DocumentTypeEnum {
  CPF = 'CPF',
  CPF_IB = 'CPF IB',
  CNPJ = 'CNPJ',
  CC = 'Cédula de Ciudadanía',
  CE = 'Cédula de Extranjería',
  NIT = 'NIT',
  DNI_PER = 'DNI',
  CEP = 'Carnet de Extranjería',
  RUC = 'RUC',
  DNI_ARG = 'DNI',
  CUIT = 'CUIT',
  CUIL = 'CUIL',
  GENERIC = 'Número de Documento'
}

export enum DocumentTypeCodeEnum {
  CPF = '200',
  CNPJ = '201',
  DNI = '200',
  CUIT = '201',
  CUIL = '202',
  NIT = '200',
  RUC = '200'
}
