
import { Injectable } from '@angular/core';
import { HeaderItem, HeaderItemDTO } from '@shared/components/ui/header/interfaces/header-shopkeeper.interface';
import { HeaderShopKeeperModel } from '@shared/components/ui/header/models/header-shopkeeper.model';

const clone = items => items.map(item => (Array.isArray(item) ? clone(item) : item));

@Injectable({
  providedIn: 'root'
})
export class HeaderFactoryService {
  constructor() {}

  public translateToHeaderInterface(menuResponse?: HeaderItemDTO[]) {
    let normalizedMenuObject: HeaderItem[] = [];

    if (menuResponse) {
      normalizedMenuObject = this.filterMenus(menuResponse, HeaderShopKeeperModel);
      normalizedMenuObject.sort((a, b) => {
        return a.position - b.position;
      });

      return normalizedMenuObject;
    }
  }

  private filterMenus(items: HeaderItemDTO[], models: HeaderItem[]): HeaderItem[] {
    const normalizedMenu: HeaderItem[] = [];
    const menuModel = clone(models);

    items.forEach(item => {
      const itemSubmenu = item.subMenus;
      const allowedItem = menuModel.find(modelItem => modelItem.code === item.code);

      if (!!allowedItem) {
        let normalizedSubmenu = [];
        if (itemSubmenu) {
          normalizedSubmenu = this.filterMenus(itemSubmenu, allowedItem.submenu);
          normalizedSubmenu.sort((a, b) => {
            return a.position - b.position;
          });
        }

        normalizedMenu.push({ ...allowedItem, submenu: normalizedSubmenu.length ? normalizedSubmenu : null });
      }
    });
    return normalizedMenu;
  }
}
