<div class="modal-header" *ngIf="data">
  <div class="row mb-0" *ngIf="window.screen.width < 768">
    <div class="col-12">
      <button type="button" class="close" aria-label="Close" (click)="dialogRef.close('Cross click')">
        <img aria-hidden="true" src="./assets/ic_close.svg" alt="icon close" />
      </button>
    </div>

    <div class="col-12">
      <h1 class="title">{{ 'DETAIL-PROPOSAL' | translate }}</h1>
    </div>
    <div class="col-12">
      <span *ngIf="data?.productCode === 'CDC'" class="badge badge-cdc">{{ 'CDC label' | translate }}</span>
      <span *ngIf="data?.productCode === 'CSC'" class="badge badge-csc">{{ 'CSC' | translate }}</span>
      <span *ngIf="data?.productCode === 'CSCP'" class="badge badge-cscp">{{ 'CSCP' | translate }}</span>
    </div>
  </div>
  <div class="container content" *ngIf="window.screen.width >= 768">
    <div class="row">
      <div class="col-10 col-md-10">
        <span *ngIf="data?.productCode === 'CDC'" class="badge badge-cdc">{{ 'CDC label' | translate }}</span>
        <span *ngIf="data?.productCode === 'CSC'" class="badge badge-csc">{{ 'CSC' | translate }}</span>
        <span *ngIf="data?.productCode === 'CSCP'" class="badge badge-cscp">{{ 'CSCP' | translate }}</span>
      </div>
      <div class="col-md-2">
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="dialogRef.close('Cross click')"
          appTagger
          [event_category]="category + data.proposalId"
          event_label="Fechar"
        >
          <img aria-hidden="true" src="./assets/ic_close.svg" alt="icon close" />
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal-body">
  <div class="card-infor mb-5 cashback-card" *ngIf="analysisShow && showAlert === true">
    <img src="./assets/images/info-white.svg" class="imagem" />
    <span class="customText"
      >{{
        'WE REMIND YOU THAT THIS PROPOSAL WILL UNDERGO AN ADDITIONAL CREDIT ANALYSIS AFTER THE FORMALIZATION STAGE'
          | translate
      }}.</span
    >
  </div>
  <div class="" *ngIf="data">
    <div class="row">
      <div class="col-12">
        <ngb-alert *ngIf="closed" class="mt-5 d-flex align-items-center" [ngClass]="alert.type" [dismissible]="false">
          <mat-icon matSuffix class="sufix-icon" [ngClass]="alert.type">{{ alert.icon }}</mat-icon>
          {{ alert.message }}
        </ngb-alert>
      </div>
    </div>
    <!-- DESKTOP -->
    <div class="row" *ngIf="window.screen.width >= 768">
      <div class="col-md-4" *ngIf="data.proposalId">
        <p>{{ 'PENDENCY-SUMMARY-TITLE-NUMBER-PROPOSAL' | translate }}</p>
        <h1>{{ data.proposalId }}</h1>
      </div>
      <div class="col-md-4" *ngIf="data.expirationDays">
        <p>{{ 'PENDENCY-SUMMARY-TITLE-EXPIRE' | translate }}</p>
        <h1 *ngIf="!data.showClock">
          <img
            *ngIf="data.expirationDays > 10"
            src="./assets/santander/icons/ic_clock_yellow.svg"
            alt="icon clock"
            class="icon-status mr-1"
          />
          <img
            *ngIf="data.expirationDays <= 10"
            src="./assets/santander/icons/ic_clock_red.svg"
            alt="icon clock"
            class="icon-status mr-1"
          />
          {{ data.expirationDays === 1 ? data.expirationDays + ' dia' : data.expirationDays + ' dias' }}
        </h1>
        <h1 *ngIf="data.showClock">
          <img src="./assets/santander/icons/ic_clock.svg" alt="icon clock" class="icon-status mr-1" />
          0 dias
        </h1>
      </div>
      <div class="col-md-4" *ngIf="data.status">
        <p>{{ 'PENDENCY-SUMMARY-TITLE-STATUS' | translate }}</p>
        <h1>
          <img
            src="./assets/santander/icons/{{ data.status.icon }}"
            *ngIf="data.status?.icon"
            alt="icon"
            class="icon-status mr-1"
          />
          {{ data.status?.description }}

          <img
            *ngIf="!!data.status?.tooltip"
            src="./assets/images/tooltip-payment-col.svg"
            alt="icon"
            class="icon-tooltip ml-1"
            matTooltip="{{ data.status.tooltip }}"
            matTooltipClass="custom-tooltip-proposal"
            matTooltipHideDelay="100"
            appTagger
            [event_category]="category + data.proposalId"
            event_label="ajuda - {{ data.status.tooltip }}"
          />
        </h1>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="row" *ngIf="window.screen.width < 768">
      <div class="col-12" *ngIf="data.installmentValue">
        <p class="mb-0">{{ 'PENDENCY-SUMMARY-TITLE-NUMBER-PROPOSAL' | translate }}</p>
        <span class="bold-value">{{ data.proposalId }}</span>
      </div>
      <div class="col-12 mt-mobile" *ngIf="data.expirationDays">
        <p class="mb-0">{{ 'PENDENCY-SUMMARY-TITLE-EXPIRE' | translate }}</p>
        <h1 *ngIf="!data.showClock">
          <img
            *ngIf="data.expirationDays > 10"
            src="./assets/santander/icons/ic_clock_yellow.svg"
            alt="icon clock"
            class="icon-status mr-1"
          />
          <img
            *ngIf="data.expirationDays <= 10"
            src="./assets/santander/icons/ic_clock_red.svg"
            alt="icon clock"
            class="icon-status mr-1"
          />
          <span class="bold-value">{{
            data.expirationDays === 1 ? data.expirationDays + ' dia' : data.expirationDays + ' dias'
          }}</span>
        </h1>
        <h1 *ngIf="data.showClock">
          <img src="./assets/santander/icons/ic_clock.svg" alt="icon clock" class="icon-status mr-1" />
          <span class="bold-value"> 0 dia </span>
        </h1>
      </div>
      <div class="col-12 mt-mobile" *ngIf="data.status">
        <p class="mb-0">{{ 'Current Status' | translate }}</p>
        <h1>
          <img
            src="./assets/santander/icons/{{ data.status.icon }}"
            *ngIf="data.status?.icon"
            alt="icon"
            class="icon-status mr-1"
          />
          <span class="bold-value">{{ data.status?.description }}</span>
          <img
            *ngIf="!!data.status?.tooltip"
            src="./assets/images/tooltip-payment-col.svg"
            alt="icon"
            class="icon-tooltip ml-1"
            matTooltip="{{ data.status.tooltip }}"
            matTooltipClass="custom-tooltip-proposal"
            matTooltipHideDelay="100"
          />
        </h1>
      </div>
    </div>
    <hr *ngIf="window.screen.width >= 768" />
    <ngb-alert
      *ngIf="data.errorDescription && !warningAlerterrorDescription"
      class="warning-alert-details"
      type="warning"
      (close)="warningAlerterrorDescription = true"
    >
      <img
        src="./assets/santander/icons/ic_close_orange.svg"
        alt="icon"
        class="icon-status"
        (click)="warningAlerterrorDescription = true"
      />
      <span class="user-name"> {{ data.errorDescription }} </span>
    </ngb-alert>

    <div
      *ngIf="status.rejected || status.expired"
      class="arrow d-none d-lg-flex cursor-p"
      (click)="openCard()"
      appTagger
      [event_category]="category + data.proposalId"
      event_label="{{ 'SHOW-PROPOSAL-DETAILS' | translate }}"
    >
      {{ 'SHOW-PROPOSAL-DETAILS' | translate }}
      <span class="material-icons arrow-icon icon">
        {{ isOpen ? 'expand_more' : 'expand_less' }}
      </span>
    </div>

    <div *ngIf="isOpen">
      <div class="row alerts">
        <div class="col-md-12">
          <ngb-alert
            *ngIf="status.rejected && !warningAlertClosedRejected"
            class="warning-alert-details"
            type="warning"
            (close)="closeAlert()"
          >
            <img
              src="./assets/santander/icons/ic_close_orange.svg"
              alt="icon"
              class="icon-status"
              (click)="warningAlertClosedRejected = true"
            />
            <span class="user-name"
              >A proposta <span class="font-weight-bold"> {{ data.proposalId }} </span> foi negada.</span
            >
          </ngb-alert>
          <ngb-alert
            *ngIf="status.expired && !warningAlertClosedExpired"
            class="warning-alert-details"
            type="warning"
            (close)="warningAlertClosedExpired = true"
          >
            <img
              src="./assets/santander/icons/ic_close_orange.svg"
              alt="icon"
              class="icon-status"
              (click)="warningAlertClosedExpired = true"
            />
            <span class="user-name"
              >A proposta <span class="font-weight-bold"> {{ data.proposalId }} </span> se encontra expirada.</span
            >
          </ngb-alert>
        </div>
      </div>

      <div
        *ngIf="status.rejected && status.rejectedDescription.length > 0"
        class="row"
        appTagger
        [event_category]="category + data.proposalId"
        event_label="{{ status.rejectedDescription }}"
      >
        <span class="mt-2 user-name font-weight-bold">Orientação para o cliente</span>
        <span class="mt-2 user-name"> {{ status.rejectedDescription }}</span>
      </div>

      <div *ngIf="!status.expired" class="row">
        <!-- DESKTOP -->
        <div class="col-md-4" *ngIf="window.screen.width >= 768">
          <div *ngIf="data.financingValue">
            <p *ngIf="data?.productCode === 'CDC'">{{ 'Financing Amount' | translate }}</p>
            <p *ngIf="data?.productCode === 'CSC' || data?.productCode === 'CSCP'">{{ 'VALUE-INSTALLMENT' | translate }}</p>
            <h1>{{ data.financingValue | currency: 'BRL' }}</h1>
          </div>
          <div class="mt-5" *ngIf="data.entryValue">
            <p>{{ 'ENTRANCE-VALUE-LABEL' | translate }}</p>
            <h1>{{ data.entryValue | currency: 'BRL' }}</h1>
          </div>
          <div class="mt-5" *ngIf="data.installmentAmount">
            <p>{{ data.installmentAmount }}x de</p>
            <h1 [hidden]="data.planCustom">{{ data.installmentValue | currency: 'BRL' }}</h1>
            <h1 [hidden]="!data.planCustom">Plano Customizado</h1>
          </div>
        </div>
        <!-- MOBILE -->
        <div *ngIf="window.screen.width < 768">
          <div class="col-12" *ngIf="data.financingValue">
            <p *ngIf="data?.productCode === 'CDC'">{{ 'Financing Amount' | translate }}</p>
            <p *ngIf="data?.productCode === 'CSC' || data?.productCode === 'CSCP'">{{ 'VALUE-INSTALLMENT' | translate }}</p>
            <span class="bold-value">{{ data.financingValue | currency: 'BRL' }}</span>
          </div>
          <div class="col-12 mt-mobile" *ngIf="data.entryValue">
            <p class="mb-0">{{ 'ENTRANCE-VALUE-LABEL' | translate }}</p>
            <span class="bold-value">{{ data.entryValue | currency: 'BRL' }}</span>
          </div>
          <div class="col-12 mt-mobile" *ngIf="data.installmentAmount">
            <p class="mb-0">{{ data.installmentAmount }}x de</p>
            <span class="bold-value" [hidden]="data.planCustom">{{ data.installmentValue | currency: 'BRL' }}</span>
            <span class="bold-value" [hidden]="!data.planCustom">Plano Customizado</span>
          </div>
        </div>
        <!-- CLIENT DATA -->
        <app-client-data [data]="data" class="col-md-8"></app-client-data>
      </div>

      <hr />
      <div *ngIf="!status.expired" class="row center">
        <div class="row border proposal-carnet my-4" *ngIf="data.openValue">
          <div class="col-2 col-md-1 text-right">
            <img src="./assets/ic_barcode.svg" alt="icon warning" class="icon-status ml-1 centralized" />
          </div>
          <div class="col-10 col-md-5">
            <p>
              {{ 'ISSUANCE-SLIP-PAYMENT-CADASTRAL-FEE' | translate }}
              <img
                src="./assets/images/tooltip-payment-col.svg"
                alt="icon warning"
                class="icon-status mr-1"
                matTooltip="{{ 'FORMALIZATION-SUMMARY-TICKET-TOOLTIP' | translate }}"
                matTooltipClass="custom-tooltip-details"
                appTagger
                [event_category]="category + data.proposalId"
                event_label="{{ 'ISSUANCE-SLIP-PAYMENT-CADASTRAL-FEE' | translate }}"
              />
            </p>
          </div>
          <div class="col-12 col-md-6 mt-3 mt-md-0">
            <button
              type="button"
              class="edit-button"
              (click)="emitCarnet()"
              appTagger
              [event_category]="category + data.proposalId"
              event_label="{{ 'ISSUE-SLIP-PAYMENT' | translate }}"
            >
              {{ 'ISSUE-SLIP-PAYMENT' | translate }}
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="!status.expired" class="row center">
        <progress-card [data]="data.proposalGroups" *ngIf="window.screen.width >= 768" class="step-component mt-3 mb-3">
        </progress-card>
        <progress-card [data]="data.proposalGroups" *ngIf="window.screen.width < 768" class="mobile"></progress-card>
      </div>
      <div *ngIf="!status.expired" class="row center">
        <button
          [hidden]="data.showClock"
          *ngIf="continueButtonLabel && !isProposalStatusAnaliseDocumental"
          type="button"
          class="action-button mt-5"
          (click)="continueProposta()"
          appTagger
          [event_category]="category + data.proposalId"
          event_label="{{ continueButtonLabel }}"
        >
          {{ continueButtonLabel }}
        </button>

        <mat-accordion
          class="example-headers-align w-100 mt-4"
          multi
          [ngClass]="{ 'mt-5 mb-4': additionalInformation.length || data.showNegotiation }"
        >
          <mat-expansion-panel *ngIf="showAditionalInfo">
            <mat-expansion-panel-header
              appTagger
              [event_category]="category + data.proposalId"
              event_label="exibir - {{ 'Additional Info' | translate }}"
            >
              <mat-panel-title>
                {{ 'Additional Info' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <div class="row mt-2" *ngIf="additionalInformation.nameTraveler">
              <div class="col-md-6">
                <p>{{ 'Traveler Name' | translate }}</p>
              </div>
              <div class="col-md-6 text-right">
                <h2>{{ additionalInformation.nameTraveler }}</h2>
              </div>
            </div>
            <hr *ngIf="showAditionalInfo" />
            <div class="row mt-2" *ngIf="additionalInformation.documentNumber">
              <div class="col-md-6">
                <p>{{ 'CPF' | translate }}</p>
              </div>
              <div class="col-md-6 text-right">
                <h2>{{ additionalInformation.documentNumber | mask: '000.000.000-00' }}</h2>
              </div>
            </div>
            <hr *ngIf="additionalInformation.documentNumber" />
            <div class="row mt-2" *ngIf="additionalInformation.birthdate">
              <div class="col-md-6">
                <p>{{ 'Date of birth' | translate }}</p>
              </div>
              <div class="col-md-6 text-right">
                <h2>{{ additionalInformation.birthdate | dateFormatPipe }}</h2>
              </div>
            </div>
            <hr *ngIf="additionalInformation.birthdate" />
            <div class="row mt-2" *ngIf="additionalInformation.orderNumber">
              <div class="col-md-12">
                <p>{{ 'Request number' | translate }}</p>
              </div>
            </div>
            <div class="row" *ngIf="additionalInformation.orderNumber">
              <div class="col-md-12">
                <h2>{{ additionalInformation.orderNumber }}</h2>
              </div>
            </div>
            <div class="row mt-2" *ngIf="additionalInformation.comment.length > 0">
              <div class="col-md-12">
                <div class="">
                  <label for="exampleFormControlTextarea1">{{ 'Comments' | translate }}</label>
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    disabled
                    [ngModel]="comments"
                  >
                  </textarea>
                </div>
              </div>
            </div>
          </mat-expansion-panel>

          <div class="confirmation-footer" *ngIf="showCETLink">
            <p class="credit-conditions gray">
              {{ 'STEP-CONFIRMATION-CREDIT-CONDITIONS' | translate }}
              <a href="javascript:void(0)" (click)="getProposalContract()" class="link">
                <span *ngIf="data?.productCode === 'CP'">
                  {{ 'STEP-CONFIRMATION-LOAN-CONDITIONS-LINK' | translate }}
                </span>
                <span *ngIf="data?.productCode === 'CDC'">
                  {{ 'STEP-CONFIRMATION-FINANCING-CONDITIONS-LINK' | translate }}
                </span>
                <span *ngIf="data?.productCode === 'CSC'">
                  {{ 'STEP-CONFIRMATION-PERCEMENT-CONDITIONS-LINK' | translate }}
                </span>
                <span *ngIf="data?.productCode === 'CSCP'">
                  {{ 'STEP-CONFIRMATION-PERCEMENT-CONDITIONS-LINK' | translate }}
                </span>
              </a>
              <span *ngIf="data?.productCode !== 'CSC' && data?.productCode !== 'CSCP'">
                {{ 'STEP-CONFIRMATION-CREDIT-CONDITIONS-COMPLEMENT' | translate }}
              </span>
            </p>
          </div>

          <mat-expansion-panel *ngIf="data.showNegotiation">
            <mat-expansion-panel-header
              appTagger
              [event_category]="category + data.proposalId"
              event_label="exibir - {{ 'TAX-NEGOTIATION' | translate }}"
            >
              <mat-panel-title>
                {{ 'TAX-NEGOTIATION' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <app-negotiation-detail
              *ngIf="negotiationDetail?.status"
              [negotiation]="negotiationDetail"
              [event_category]="category + data.proposalId"
            >
            </app-negotiation-detail>

            <app-negotiation-form
              *ngIf="!negotiationDetail?.status"
              (saveNegotiation)="saveNegotiation($event)"
              [event_category]="category + data.proposalId"
            >
            </app-negotiation-form>
          </mat-expansion-panel>

          <mat-expansion-panel *ngIf="data?.showNegotiationNpp">
            <mat-expansion-panel-header
              appTagger
              [event_category]="category + data?.proposalId"
              event_action="{{ openModal }} - {{ 'NEGOTIATION' | translate }}"
              event_label="ocultar {{ 'NEGOTIATION' | translate }}"
            >
              <mat-panel-title>
                {{ 'NEGOTIATION' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>

            <app-npp-negotiation-form
              [productCode]="data?.productCode"
              [installmentValue]="data?.installmentValue"
              [interestRate]="data?.returnTaxPercentage"
              [retentionPercentage]="data?.retentionPercentage"
              [negotiation]="negotiation"
              [isDisabled]="isNppNegotiationFormDisabled"
              (saveNppNegotiation)="saveNppNegotiation($event)"
            ></app-npp-negotiation-form>
          </mat-expansion-panel>
        </mat-accordion>

        <button
          type="button"
          class="btn-cancel-proposal mt-4"
          [hidden]="!data?.canCancel"
          (click)="cancelProposal()"
          appTagger
          [event_category]="category + data.proposalId"
          event_label="{{ 'PROPOSAL-CANCEL' | translate }}"
        >
          <img src="./assets/ic_delete_red.svg" alt="icon warning" class="icon-status mr-1" />{{
            'PROPOSAL-CANCEL' | translate
          }}
        </button>
      </div>
    </div>
  </div>
</div>
