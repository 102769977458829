import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import {
  SetConfirmedExemptionRequestTariff,
  SetConfirmedInCashTariff
} from '@app/core/state/proposal-information/proposal-information.actions';
import {
  selectConfirmedExemptionRequestTariff,
  selectConfirmedInCashTariff
} from '@app/core/state/proposal-information/proposal-information.selectors';
import { getEnquadramento } from '@app/core/state/simulation/simulation.selectors';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { RegistrationFeeType } from '@shared/constants/registration-fee-type.enum';
import { SimulationModalService } from '@shared/services/modal/simulation-modal.service';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-registration-fee',
  templateUrl: './registration-fee.component.html',
  styleUrls: ['./registration-fee.component.scss']
})
export class RegistrationFeeComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() registrationFeeData: any;
  @Output() selectedFeeOptionEmitter = new EventEmitter();

  restPaymentOptions: any[];
  pickedRegFeeOption: any;
  selectedFeeOption: any;
  hideFeeOptions: boolean;
  hasConfirmedInCash = false;
  hasConfirmedExemptionRequest = false;
  isEnquadramento: boolean;
  public RegistrationFee = RegistrationFeeType;

  public fees = [
    {
      id: 1,
      icon: 'how_to_vote',
      label: 'Incluir na parcela',
      fee: {
        feePrefix: '',
        feeValue: 0
      },
      description: 'Tarifa será inclusa no valor das parcelas do empréstimo.',
      descriptionMoreInfoURL: null,
      extraTreatment: 'confirmInInstallmentsPayment'
    },
    {
      id: 2,
      // icon: '@assets/o/caminho/eu/nao/sei.jpg',
      icon: 'assessment',
      label: 'Pagar a vista',
      fee: {
        feePrefix: 'À vista',
        feeValue: 0
      },
      description: 'Um boleto é gerado e deve ser pago antes da liberação do empréstimo.',
      descriptionMoreInfoURL: null,
      extraTreatment: 'confirmUpfrontPayment'
    },
    {
      id: 3,
      // icon: '@assets/o/caminho/eu/nao/sei.jpg',
      icon: 'emoji_symbols',
      label: 'Requisitar isenção de tarifa',
      fee: {
        feePrefix: 'Envio obrigatório de documentos',
        feeValue: null
      },
      description: 'O cliente deverá enviar documentos obrigatórios para solicitar a isenção a tarifa.',
      descriptionMoreInfoURL: 'https://santander.com/para/ter/isencao/tenha/muita/fe',
      extraTreatment: 'confirmRequestExemption'
    }
  ];

  @Input() category: string;
  readonly Insert: Tag = Tag.Insert;
  readonly Selecionar: Tag = Tag.Selecionar;

  get eventLabelOpen() {
    return 'ajuda - ' + this.translateService.instant('INCLUDES-REGISTRATION-FEE');
  }

  constructor(
    private simulationModalService: SimulationModalService,
    private store$: Store<AppState>,
    private translateService: TranslateService,
    private genTagger: GenTagger
  ) {}

  ngOnInit() {

    this.store$.select(getEnquadramento).pipe(  takeUntil(this.ngUnsubscribe)).subscribe(state => {
      this.isEnquadramento = state.isEnquadramento;
    });
    this.store$
      .select(selectConfirmedInCashTariff)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(data => !!data)
      )
      .subscribe(confirmation => {
        this.hasConfirmedInCash = confirmation;
      });
    this.store$
      .select(selectConfirmedExemptionRequestTariff)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(data => !!data)
      )
      .subscribe(confirmation => {
        this.hasConfirmedExemptionRequest = confirmation;
      });

    this.hideFeeOptions = true;
    if (
      !this.registrationFeeData.registrationFeeTypeSelected ||
      this.registrationFeeData.registrationFeeTypeSelected === RegistrationFeeType.IN_INSTALLMENTS
    ) {
      this.selectedFeeOption = this.fees[0];
    } else if (this.registrationFeeData.registrationFeeTypeSelected === RegistrationFeeType.IN_CASH) {
      this.selectedFeeOption = this.fees[1];
    } else if (this.registrationFeeData.registrationFeeTypeSelected === RegistrationFeeType.EXEMPTION_REQUEST) {
      this.selectedFeeOption = this.fees[2];
    }
    if (this.registrationFeeData.tariff.paymentType === this.RegistrationFee.EXEMPT_CUSTOMER) {
      this.genTagger.setTag({
        event_category: this.category,
        event_action: `${Tag.AbriuModal} - Tarifa de cadastro`,
        event_label: `Seu cliente tem condições especiais e está isento da tarifa de cadastro.`
      });
    }
  }

  showModalRegistrationFee(postionCard: number) {
    if (this.hasConfirmedInCash) {
      this.emitSelectedFeeOption(this.fees[1]);
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '510px';
      dialogConfig.height = '570px';
      dialogConfig.data = {
        category: this.category
      };
      this.simulationModalService.openRegistrationFeeModal({}, dialogConfig);
      this.simulationModalService.confirmed().subscribe(res => {
        !res ? (this.selectedFeeOption = postionCard) : (this.selectedFeeOption = this.fees[0]);
        this.emitSelectedFeeOption(this.selectedFeeOption);
        this.store$.dispatch(new SetConfirmedInCashTariff(true));
      });
    }
  }

  showModalPaymentExemption(postionCard: number) {
    if (this.hasConfirmedExemptionRequest) {
      this.emitSelectedFeeOption(this.fees[2]);
    } else {
      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.id = 'modal-component';
      dialogConfig.width = '510px';
      dialogConfig.height = '570px';
      dialogConfig.data = {
        category: this.category
      };
      this.simulationModalService.openPaymentExemptionModal({}, dialogConfig);
      this.simulationModalService.confirmed().subscribe(res => {
        !res ? (this.selectedFeeOption = postionCard) : (this.selectedFeeOption = this.fees[0]);
        this.emitSelectedFeeOption(this.selectedFeeOption);
        this.store$.dispatch(new SetConfirmedExemptionRequestTariff(true));

        this.genTagger.setTag({
          event_category: this.category,
          event_action: 'fechou modal - Quer mesmo seguir com esta opção?',
          event_label: res ? 'incluir na parcela' : 'solicitar isenção'
        });
      });
    }
  }

  emitSelectedFeeOption(selectedFeeOption) {

    let registrationFee = '';

    if (selectedFeeOption.extraTreatment === 'confirmRequestExemption') {
      registrationFee = RegistrationFeeType.EXEMPTION_REQUEST;
    } else if (selectedFeeOption.extraTreatment === 'confirmUpfrontPayment') {
      registrationFee = RegistrationFeeType.IN_CASH;
    } else {
      registrationFee = RegistrationFeeType.IN_INSTALLMENTS;
    }

    if (this.isEnquadramento) {
      const feePayload = {
        value: {
          payment: {
            registrationFee
          }
        },
        input: InputTypes.REGISTRATION_FEE,
        isEnquadramento: true,
        type: 'TC'
      }
      this.selectedFeeOptionEmitter.emit(feePayload);
    return;
  }
  const feePayload = {
    value: {
      payment: {
        registrationFee
      }
    },
    input: InputTypes.REGISTRATION_FEE
  }
  this.selectedFeeOptionEmitter.emit(feePayload);
  }

  expandFeeOptionsHandler(e) {
    if (e) e.preventDefault();
    this.hideFeeOptions = !this.hideFeeOptions;
  }

  regFeeSelected(feeOption) {
    this.selectedFeeOption = feeOption;

    if (feeOption.extraTreatment === 'confirmUpfrontPayment') {
      this.showModalRegistrationFee(this.selectedFeeOption);
    } else if (feeOption.extraTreatment === 'confirmRequestExemption') {
      this.showModalPaymentExemption(this.selectedFeeOption);
    } else {
      this.emitSelectedFeeOption(this.selectedFeeOption);
    }
  }

  openTooltip(): void {
    this.genTagger.setTag({
      event_category: this.category,
      event_action: `abriu tooltip - ${this.translateService.instant('INCLUDES-REGISTRATION-FEE')}`,
      event_label: this.translateService.instant('INCLUDES-REGISTRATION-FEE-TOOLTIP')
    });
  }

  closeTooltip() {
    this.genTagger.setTag({
      event_category: this.category,
      event_action: `fechou tooltip - ${this.translateService.instant('INCLUDES-REGISTRATION-FEE')}`,
      event_label: 'x'
    });
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
