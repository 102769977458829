import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CalendarDialogComponent } from '@shared/components/ui/calendar-dialog/calendar-dialog.component';
import { CalendarConfig } from '@shared/components/ui/calendar/calendar.model';
import { SubsegmentModel } from '@shared/models/subsegment.model';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-store-subsegment',
  templateUrl: './store-subsegment.component.html',
  styleUrls: ['./store-subsegment.component.scss']
})
export class StoreSubsegmentComponent implements OnChanges {
  @Input() subsegment: SubsegmentModel;
  public dateTravel = '';
  public isTourismSubsegment = false;

  constructor(public dialog: MatDialog) {}

  public openDialogCalendar(): void {
    const refDialogCalendar = this.dialog.open(CalendarDialogComponent, {
      disableClose: true,
      position: { top: '100px', left: '220px' },
      data: {
        isTruncateDays: { toBack: true },
        isTruncateYears: true
      } as CalendarConfig
    });

    refDialogCalendar
      .afterClosed()
      .pipe(take(1))
      .subscribe((dateTravel: string) => {
        if (dateTravel !== 'back') this.dateTravel = dateTravel;
      });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.subsegment && this.subsegment) {
      this.isTourismSubsegment = this.subsegment.code === '301';
    }
  }
}
