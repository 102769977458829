<div [ngClass]="{ 'installment-details':  showTooltip }">

  <div [ngClass]=" showTooltip ? 'description' : 'descriptionModal'" *ngIf="code === 'FLT' || codePlan === 'FLT'">
    <div [ngClass]="{ 'd-flex flex-row':  showTooltip }">
      <span *ngIf="showTooltip"><app-tooltip-payment-plan></app-tooltip-payment-plan> </span>
      <p *ngIf="showTooltip" class="pl-3" [innerHTML]="'STANDARD-PLAN-DESCRIPTION' | translate"></p>
      <p *ngIf="!showTooltip && flexPlanList?.length > 1" [innerHTML]="'STANDARD-PLAN-DESCRIPTION' | translate"></p>
    </div>
  </div>
  <div [ngClass]=" showTooltip ? 'description' : 'descriptionModal'" *ngIf="code === 'ASC' || codePlan === 'ASC' ">
    <div class="d-flex flex-row ">
      <span *ngIf="showTooltip"><app-tooltip-payment-plan></app-tooltip-payment-plan> </span>
      <p *ngIf="showTooltip" class="pl-3" [innerHTML]="'CRESCENT-PLAN-DESCRIPTION' | translate"></p>
      <p *ngIf="!showTooltip" [innerHTML]="'CRESCENT-PLAN-DESCRIPTION' | translate"></p>
    </div>
  </div>
  <div [ngClass]=" showTooltip ? 'description' : 'descriptionModal'" *ngIf="code === 'DEC' || codePlan === 'DEC'">
    <div [ngClass]="{ 'd-flex flex-row':  showTooltip }">
      <span *ngIf="showTooltip"><app-tooltip-payment-plan></app-tooltip-payment-plan> </span>
      <p *ngIf="showTooltip" class="pl-3" [innerHTML]="'DECREASING-PLAN-DESCRIPTION' | translate"></p>
      <p *ngIf="!showTooltip" [innerHTML]="'DECREASING-PLAN-DESCRIPTION' | translate"></p>

    </div>
  </div>
  <div [ngClass]=" showTooltip ? 'description' : 'descriptionModal'"
    *ngIf="code === 'SEASONAL' || codePlan === 'SEASONAL'">
    <div [ngClass]="{ 'd-flex flex-row':  showTooltip }">
      <span *ngIf="showTooltip"><app-tooltip-payment-plan></app-tooltip-payment-plan> </span>
      <p *ngIf="showTooltip" class="pl-3" [innerHTML]="'DESCRIPTION-PLAN-SEASONAL' | translate"></p>
      <p *ngIf="!showTooltip" [innerHTML]="'DESCRIPTION-PLAN-SEASONAL' | translate"></p>

    </div>
  </div>

  <div [ngClass]=" showTooltip ? 'description' : 'descriptionModal'" *ngIf="code === 'FIXED' || codePlan === 'FIXED'">
    <div [ngClass]="{ 'd-flex flex-row':  showTooltip }">
      <span *ngIf="showTooltip"><app-tooltip-payment-plan></app-tooltip-payment-plan> </span>
      <p *ngIf="showTooltip" class="pl-3" [innerHTML]="'FIXED-INSTALLMENTS-INFO' | translate"></p>
      <p *ngIf="!showTooltip" [innerHTML]="'FIXED-INSTALLMENTS' | translate"></p>

    </div>
  </div>
  <div [ngClass]=" showTooltip ? 'description' : 'descriptionModal'" *ngIf="code === 'BLN' || codePlan === 'BLN' ">
    <div [ngClass]="{ 'd-flex flex-row':  showTooltip }">
      <span *ngIf="showTooltip"><app-tooltip-payment-plan></app-tooltip-payment-plan> </span>
      <p *ngIf="showTooltip" class="pl-3" [innerHTML]="'BALLOON-PLAN-DESCRIPTION' | translate"></p>
      <span *ngIf="!showTooltip" [innerHTML]="'BALLOON-PLAN-DESCRIPTION' | translate"></span>
    </div>
  </div>
  <div [ngClass]=" showTooltip ? 'description' : 'descriptionModal'" *ngIf="code === 'LACK' || codePlan === 'LACK'">
    <div [ngClass]="{ 'd-flex flex-row':  showTooltip }">
      <span *ngIf="showTooltip"><app-tooltip-payment-plan></app-tooltip-payment-plan> </span>
      <p class="pl-3" *ngIf="showTooltip" [innerHTML]="'SKIP-PARCEL-PLAN-DESCRIPTION' | translate"></p>
      <span *ngIf="!showTooltip" [innerHTML]="'SKIP-PARCEL-PLAN-DESCRIPTION' | translate"></span>
    </div>
  </div>
</div>