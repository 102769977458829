<div class="container-fluid px-0">
  <div class="row no-gutters">
    <div
      *ngIf="!isCrossedFlux"
      [ngClass]="isMobile ? 'bottom-sheet-offers-wrapper' : 'col-md-4 d-none d-md-block sidebar fixed-top'"
    >
      <simulation-sidebar *ngIf="!isNpp"></simulation-sidebar>
      <simulation-sidebar-npp *ngIf="isNpp"></simulation-sidebar-npp>
    </div>
    <div class="col offset-md-4 col-md-8 content">
      <app-loading-bar *ngIf="inRequest"></app-loading-bar>
      <div class="content-items">
        <div *ngIf="!isCrossedFlux" class="close-button d-none d-md-block text-right">
          <button (click)="leave()">
            <img src="./assets/images/ic_close.svg" alt="button-close" />
          </button>
        </div>
        <app-stepper
          [isCrossedFlux]="isCrossedFlux"
          *ngIf="showStepper$ | async as showStepper"
          (closeOut)="leave()"
        ></app-stepper>
        <router-outlet></router-outlet>
        <bottom-sheet *ngIf="currentUrl === '/proposal/step-formalization' && !isCrossedFlux" class="d-block d-md-none">
        </bottom-sheet>
      </div>
    </div>
  </div>
</div>
