import { Injectable } from '@angular/core';
import { PendencyService } from '@app/pendency/pendency.service';
import { BiometryService } from '@app/proposal-workflow/containers/step-formalization/components/biometry-controller/services/biometry.service';
import {
  ChecklistItemDTO,
  ChecklistItemMobileDTO,
  ChecklistItemTypeEnum
} from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/api/query.checklist.model';
import {
  ChecklistModel,
  ChecklistModelMobile
} from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/checklist.model';
import { FormalizationService } from '@app/proposal-workflow/containers/step-formalization/services/formalization.service';
import { TaggerService } from '@app/tagging/tagger.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProposalStageService } from '@shared/services/proposal/proposal-stage.service';
import { EMPTY, of } from 'rxjs';
import { catchError, delay, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '..';
import { OpenProposalService } from './../../../showcase/proposals/services/open-proposal.service';
import * as actions from './proposal.actions';
import * as selectors from './proposal.selectors';

const maskDocNumber = (d: string): string => {
  if (d) {
    if (d.length === 11) {
      return `${d.slice(0, 3)}.${d.slice(3, 6)}.${d.slice(6, 9)}-${d.slice(9)}`;
    }
    if (d.length === 14) {
      return `${d.slice(0, 2)}.${d.slice(2, 5)}.${d.slice(5, 8)}/${d.slice(8, 12)}-${d.slice(12)}`;
    }
  }
  return d;
};

const mapChecklistItemMobile = (
  checklistItem: ChecklistItemMobileDTO
  //channelSupplier: { id: string; code?: number; description?: string }
): ChecklistModelMobile => {
  const {
    checklistId: id,
    client,
    type,
    artifact,
    document: { id: code, code: docCode, name, description, allowedTypes, integrationTypes },
    uploadGroup,
    value,
    status
  } = checklistItem;

  const result: ChecklistModelMobile = {
    id,
    client,
    type,
    artifact,
    status,
    document: {
      id,
      code,
      docCode,
      name,
      description,
      allowedTypes,
      integrationTypes
    },
    uploadGroup,
    value
  };

  if (client.documentNumber) {
    result.document.name = `${name} (${maskDocNumber(client.documentNumber)})`;
  }

  return result;
};

const mapChecklistItem = (
  checklistItem: ChecklistItemDTO,
  proposalId: string,
  channelSupplier: { id: string; code?: number; description?: string }
): ChecklistModel => {
  const {
    checklistId: id,
    client,
    type,
    artifact,
    document: { id: code, code: docCode, name, description, allowedTypes, maxImages: maxAmount, integrationTypes },
    uploadGroup,
    encrypted,
    status
  } = checklistItem;

  const result: ChecklistModel = {
    id,
    client,
    proposal: { id: +proposalId, channelSupplier },
    type,
    artifact,
    status,
    document: {
      code,
      docCode,
      name,
      description,
      maxAmount,
      allowedTypes,
      integrationTypes
    },
    encrypted,
    uploadGroup
  };

  if (client.documentNumber) {
    result.document.name = `${name} (${maskDocNumber(client.documentNumber)})`;
  }

  // if (result.document.docCode === ChecklistDocumentEnum.ID_DOC) {
  //   result = {
  //     ...result,
  //     document: {
  //       ...result.document,
  //       name: `${result.document.name} (${maskDocNumber(result.client.documentNumber)})`
  //     }
  //   };
  // }

  return result;
};

@Injectable()
export class ProposalEffects {
  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private openProposalService: OpenProposalService,
    private formalizationService: FormalizationService,
    private proposalStageService: ProposalStageService,
    private pendencyService: PendencyService,
    private biometryService: BiometryService,
    private taggerService: TaggerService
  ) {}

  @Effect()
  getOpenProposalsAction = this.actions$.pipe(
    ofType<actions.GetOpenProposalsAction>(actions.ProposalActionTypes.GET_OPEN_PROPOSALS),
    switchMap(() =>
      this.openProposalService.getOpenProposals().pipe(
        switchMap(res => of(new actions.GetOpenProposalsSuccessAction(res))),
        catchError(err => of(new actions.GetOpenProposalsErrorAction(err)))
      )
    )
  );

  @Effect()
  getPendingProposalsAction = this.actions$.pipe(
    ofType<actions.GetPendingProposalsAction>(actions.ProposalActionTypes.GET_PENDING_PROPOSALS),
    switchMap(() =>
      this.openProposalService.getPendingProposals().pipe(
        switchMap(res => of(new actions.GetPendingProposalsSuccessAction(res))),
        catchError(err => of(new actions.GetPendingProposalsErrorAction(err)))
      )
    )
  );

  @Effect()
  getProposalAction = this.actions$.pipe(
    ofType<actions.GetProposalAction>(actions.ProposalActionTypes.GET_PROPOSAL),
    switchMap(obj =>
      this.formalizationService.getProposal(obj.payload).pipe(
        switchMap(res => of(new actions.GetProposalSuccessAction(res))),
        catchError(err => of(new actions.GetProposalErrorAction(err)))
      )
    )
  );

  @Effect()
  getProposalStage = this.actions$.pipe(
    ofType<actions.GetProposalStageAction>(actions.ProposalActionTypes.GET_PROPOSAL_STAGE),
    filter(action => !!action.payload),
    switchMap(action =>
      this.proposalStageService.getProposalStage(action.payload).pipe(
        switchMap(res => of(new actions.GetProposalStageSuccessAction({ stage: res, proposalId: action.payload }))),
        catchError(err => of(new actions.GetProposalStageErrorAction(err)))
      )
    )
  );

  @Effect()
  getChecklistAction = this.actions$.pipe(
    ofType<actions.GetChecklistAction>(actions.ProposalActionTypes.GET_CHECKLIST),
    withLatestFrom(this.store$.select(selectors.selectProposal)),
    filter(proposalState => !!proposalState[1].data),
    switchMap(([action, proposalState]) => {
      const { id, channelSupplier } = proposalState.data;
      return this.formalizationService.getChecklist(action.payload).pipe(
        map(res => res.map(item => mapChecklistItem(item, id, channelSupplier))),
        switchMap(res => of(new actions.GetChecklistSuccessAction(res))),
        catchError(err => of(new actions.GetChecklistErrorAction(err)))
      );
    })
  );

  //CHECKLIST MOBILE
  @Effect()
  getChecklistMobileAction = this.actions$.pipe(
    ofType<actions.GetChecklistMobileAction>(actions.ProposalActionTypes.GET_CHECKLIST_MOBILE),
    switchMap(action => {
      return this.biometryService.getChecklistMobile(action.payload).pipe(
        delay(6000),
        map(res => res.map(item => mapChecklistItemMobile(item))),
        switchMap(res => of(new actions.GetChecklistMobileSuccessAction(res))),
        catchError(err => of(new actions.GetChecklistMobileErrorAction(err)))
      );
    })
  );
  //CHECKLIST MOBILE TESTAR
  @Effect()
  getUploadChecklisMobiletAction = this.actions$.pipe(
    ofType<actions.GetUploadChecklistMobileAction>(actions.ProposalActionTypes.GET_UPLOAD_CHECKLIST_MOBILE),
    switchMap(action => {
      return this.biometryService.getChecklistMobile(action.payload).pipe(
        map(res => res.map(item => mapChecklistItemMobile(item))),
        map(res => res.filter(item => item.type.code === ChecklistItemTypeEnum.UPLOAD)),

        switchMap(res => of(new actions.GetUploadChecklistMobileSuccessAction(res))),
        catchError(err => of(new actions.GetUploadChecklistMobileErrorAction(err)))
      );
    })
  );

  @Effect()
  getUploadChecklistAction = this.actions$.pipe(
    ofType<actions.GetUploadChecklistAction>(actions.ProposalActionTypes.GET_UPLOAD_CHECKLIST),
    withLatestFrom(this.store$.select(selectors.selectProposal)),
    switchMap(([action, proposalState]) => {
      const { id, channelSupplier } = proposalState.data;
      return this.formalizationService.getChecklist(action.payload).pipe(
        map(res => res.map(item => mapChecklistItem(item, id, channelSupplier))),
        map(res => res.filter(item => item.type.code === ChecklistItemTypeEnum.UPLOAD)),
        switchMap(res => of(new actions.GetUploadChecklistSuccessAction(res))),
        catchError(err => of(new actions.GetUploadChecklistErrorAction(err)))
      );
    })
  );

  @Effect()
  getUploadChecklistMobileAction = this.actions$.pipe(
    ofType<actions.GetUploadChecklistAction>(actions.ProposalActionTypes.GET_UPLOAD_CHECKLIST),
    withLatestFrom(this.store$.select(selectors.selectProposal)),
    switchMap(([action, proposalState]) => {
      const { id, channelSupplier } = proposalState.data;

      return this.formalizationService.getChecklist(action.payload).pipe(
        map(res => res.map(item => mapChecklistItem(item, id, channelSupplier))),
        map(res => res.filter(item => item.type.code === ChecklistItemTypeEnum.UPLOAD)),
        switchMap(res => of(new actions.GetUploadChecklistSuccessAction(res))),
        catchError(err => of(new actions.GetUploadChecklistErrorAction(err)))
      );
    })
  );

  @Effect({ dispatch: false })
  getProposalStageSuccess = this.actions$.pipe(
    ofType<actions.GetProposalStageSuccessAction>(actions.ProposalActionTypes.GET_PROPOSAL_STAGE_SUCCESS),
    map(action => this.proposalStageService.redirectProposal(action.payload.stage))
  );

  @Effect()
  onGetPendingProposalAction = this.actions$.pipe(
    ofType<actions.GetPendingProposalAction>(actions.ProposalActionTypes.GET_PENDING_PROPOSAL),
    switchMap(action =>
      this.pendencyService.getProposalInformation(action.payload).pipe(
        map(res => new actions.GetPendingProposalSuccessAction(res)),
        catchError(err => of(new actions.GetPendingProposalErrorAction(err)))
      )
    )
  );

  @Effect()
  onSubmitPendingProposalAction = this.actions$.pipe(
    ofType<actions.SubmitPendingProposalAction>(actions.ProposalActionTypes.SUBMIT_PENDING_PROPOSAL),
    switchMap(action =>
      this.pendencyService.updateBankAccountData(action.payload.bankData, action.payload.proposalId).pipe(
        map(res => new actions.SubmitPendingProposalSuccessAction(res)),
        catchError(err => of(new actions.SubmitPendingProposalErrorAction(err)))
      )
    )
  );

  @Effect()
  onUpdateProposalAction = this.actions$.pipe(
    ofType<actions.UpdateProposals>(actions.ProposalActionTypes.UPDATE_PROPOSAL),
    switchMap(action =>
      this.pendencyService.proceed(action.payload.proposalId, action.payload.groupName).pipe(
        map(() => new actions.UpdateProposalsSuccess()),
        catchError(() => EMPTY)
      )
    )
  );

  @Effect({ dispatch: false })
  SetProposalIdAction = this.actions$.pipe(
    ofType<actions.SetProposalIdAction>(actions.ProposalActionTypes.SET_PROPOSAL_ID),
    map(_action => this.taggerService.setProposalScope())
  );

  @Effect()
  getProposalPendencyBiometryFlowEnabled$ = this.actions$.pipe(
    ofType<actions.GetProposalPendencyBiometryFlowEnabled>(
      actions.ProposalActionTypes.GET_PROPOSAL_PENDENCY_BIOMETRY_FLOW_ENABLED
    ),
    switchMap(_ => {
      return this.pendencyService.getProposalPendencyBiometryFlowEnabled().pipe(
        map(res => new actions.SetProposalPendencyBiometryFlowEnabled(res)),
        catchError(() => of(new actions.SetProposalPendencyBiometryFlowEnabled(false)))
      );
    })
  );
}
