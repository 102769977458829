import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core/state';
import { LiquidationTypes } from '@app/proposal-workflow/models/liquidation-types.enum';
import { BankSignUpData, ProposalParameters } from '@app/proposal-workflow/models/proposal-data.model';
import { Store } from '@ngrx/store';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { ValidateAccountCode, ValidateAgency } from '@shared/validators/brazil.validators';
import { Subject } from 'rxjs';
import { debounceTime, delay, filter, takeUntil } from 'rxjs/operators';

// TO DO Ivirson - Check with bank code. Actually is comparing with id
const SANTANDER_BANK_CODE = '33';

@Component({
  selector: 'app-bank-data',
  templateUrl: './bank-data.component.html',
  styleUrls: ['./bank-data.component.scss']
})
export class BankDataComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();

  @Input() bankData: BankSignUpData;
  @Input() pos: { pos: number; size: number };
  @Input() comboParams: ProposalParameters;
  @Input() formalization = true;
  @Output() start = new EventEmitter<FormGroup>();
  @Output() pendencyEmitter = new EventEmitter<BankSignUpData>();

  public bankForm: FormGroup;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  public accountCodeMask = '000-09';
  public confirmAccountCodeMask = '000-09';
  public hasSantanderAccount = true;
  public liquidationTypes = LiquidationTypes;

  constructor(private internationalizationService: InternationalizationService, private store$: Store<AppState>) {}

  ngOnInit() {
    this.buildForm();
    this.start.emit(this.bankForm);
    this.setSectionData();
    this.handleBankList();
    this.setBankFormSubscription();
    this.setBankCodeSubscription();
    this.setAccountCodeSubscription();
    this.setAgencySubscription();
    this.bankForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => this.start.emit(this.bankForm));
  }

  public buildForm() {
    this.bankForm = new FormGroup({
      bankCode: new FormControl(null, Validators.required),
      accountType: new FormControl(null, Validators.required),
      agency: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(4)]),
      accountCode: new FormControl(null, [Validators.required, Validators.minLength(4), Validators.maxLength(12)]),
      confirmAgency: new FormControl(null),
      confirmAccountCode: new FormControl(null)
    });
  }

  public setSectionData() {
    // this.store$
    //   .select(proposalInformationSelect.bankDataSection)
    //   .pipe(
    //     takeUntil(this.ngUnsubscribe),
    //     filter((data: BankSignUpData) => !!data && Object.values(data).some(item => !!item)),
    //     take(1)
    //   )
    //   .subscribe((data: BankSignUpData) => {
    //     let formData: any = { ...data };
    //     if (data.agency) {
    //       formData = {
    //         ...data,
    //         agency: `${data.agency.padStart(4, '0')}`,
    //         confirmAgency: data.bankCode !== SANTANDER_BANK_CODE ? `${data.agency.padStart(4, '0')}` : null,
    //         confirmAccountCode: data.bankCode !== SANTANDER_BANK_CODE ? data.accountCode : null
    //       };
    //     }
    //     this.bankForm.patchValue(formData);
    //     this.accountCodeMask = this.getAccountCodeDynamicMask(data.accountCode);
    //     if (data.liquidationType === this.liquidationTypes.DEBITO) {
    //       this.bankForm.controls.bankCode.disable();
    //     }
    //     if (this.bankForm.valid) {
    //       this.store$.dispatch(new SetBankDataSection(this.bankForm.getRawValue()));
    //     }
    //   });
  }

  public handleBankList() {
    if (this.comboParams && this.comboParams.banks) {
      this.comboParams.banks.forEach(b => (b.description = `${b.id.padStart(4, '0')} - ${b.description}`));
    }
  }

  private setBankFormSubscription(): void {
    this.bankForm.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(1000),
        filter(() => this.bankForm.valid && this.bankForm.touched)
      )
      .subscribe(() => {
        if (this.formalization) {
          // this.store$.dispatch(new SetBankDataSection(this.bankForm.getRawValue()));
        } else {
          this.pendencyEmitter.emit(this.bankForm.getRawValue());
        }
      });
  }

  private setBankCodeSubscription(): void {
    this.bankForm.controls.bankCode.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      this.bankForm.controls.confirmAgency.setValue(null);
      this.bankForm.controls.confirmAccountCode.setValue(null);
      if (+res && res !== SANTANDER_BANK_CODE) {
        this.hasSantanderAccount = false;
        this.bankForm.controls.confirmAgency.setValidators([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(4)
        ]);
        this.bankForm.controls.confirmAccountCode.setValidators([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(12)
        ]);
        this.bankForm.setValidators([ValidateAgency(), ValidateAccountCode()]);
        this.setConfirmAccountCodeSubscription();
      } else if (+res && res === SANTANDER_BANK_CODE) {
        this.hasSantanderAccount = true;
        this.bankForm.controls.confirmAgency.setValidators([]);
        this.bankForm.controls.confirmAccountCode.setValidators([]);
        this.bankForm.controls.confirmAgency.setErrors(null);
        this.bankForm.controls.confirmAccountCode.setErrors(null);
        this.bankForm.setValidators([]);
      }
    });
  }

  private setAccountCodeSubscription(): void {
    this.bankForm.controls.accountCode.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        delay(0)
      )
      .subscribe(res => {
        this.bankForm.controls.confirmAccountCode.updateValueAndValidity();
        this.accountCodeMask = this.getAccountCodeDynamicMask(res);
      });
  }

  private setAgencySubscription(): void {
    const field = this.bankForm.controls.agency;
    field.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        delay(0)
      )
      .subscribe(value => {
        if (value <= 0) {
          field.setErrors({ ...field.errors, invalidAgency: true });
          field.markAsTouched();
          return;
        }
        this.bankForm.controls.confirmAgency.updateValueAndValidity();
      });
  }

  private setConfirmAccountCodeSubscription(): void {
    this.bankForm.controls.confirmAccountCode.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        delay(0)
      )
      .subscribe(res => (this.confirmAccountCodeMask = this.getAccountCodeDynamicMask(res)));
  }

  public getAccountCodeDynamicMask(value: string): string {
    if (!value || value.length <= 4) {
      return '000-09';
    }

    if (value.length < 12) {
      return `${'0'.repeat(value.length - 1)}-09`;
    }

    return `${'0'.repeat(value.length - 1)}-0`;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
