import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@app/core/state';
import { DocumentType } from '@app/core/state/login/login.reducer';
import * as loginSelectors from '@app/core/state/login/login.selectors';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

interface ResponseSupplementaryData {
  loginTypes: Array<{
    loginTypeId: any;
    loginTypeCode: any;
    loginTypeDesc: any;
    channelAccessId: any;
    charMaxAmount: any;
    personType: any;
    documentId: any;
    documentFlag: any;
  }>;
}

@Injectable({
  providedIn: 'root'
})
export class DocumentTypeService {
  constructor(private http: HttpClient, private store$: Store<AppState>) {}

  public getDocumentTypes(): Observable<ResponseSupplementaryData> {
    return this.http.get<any>(`${environment.apiRootUrl}/shopkeeper-login/1.0.0/supplementary-data`);
  }

  public getDocumentTypeByNumber(documentNumber: string): Observable<DocumentType> {
    return this.store$.pipe(
      select(loginSelectors.selectListDocumentType),
      first(),
      map(types => {
        return types.find(v => +v.documentMaxLength === +documentNumber.length);
      })
    );
  }
}
