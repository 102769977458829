import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { environment } from '@env/environment';
import { ModalOptions } from '@shared/components/pion-modal/pion-modal.component';

@Component({
  selector: 'app-registration-fee-card',
  templateUrl: './registration-fee-card.component.html',
  styleUrls: ['./registration-fee-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RegistrationFeeCardComponent implements OnInit, OnChanges {
  @Input() customInstallmentAmmount: number;
  @Input() customInstallmentValue: number;
  @Input() registrationValue: number;

  @Input() registrationFeeOption: any;
  @Input() selectedFeeOption: any;
  @Input() showOtherFeeOptions: boolean;
  @Output() changeSelectedFeeOption = new EventEmitter();
  @Input() category: string;
  readonly Selecionar: Tag = Tag.Selecionar;
  pickedRegFeeOption: any;
  readonly useNPP = JSON.parse(environment.useNPP);

  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: ModalOptions,
    private genTagger: GenTagger,
    private readonly dialog: MatDialog
  ) {}

  ngOnInit() {
    if (this.registrationFeeOption.extraTreatment === 'confirmInInstallmentsPayment') {
      this.registrationFeeOption.fee.feePrefix = String(this.customInstallmentAmmount) + ' x ';
      if (this.useNPP) {
        this.registrationFeeOption.fee.feeValue = this.customInstallmentValue;
      } else {
        this.registrationFeeOption.fee.feeValue = this.registrationValue;
        const portionValue = (this.registrationValue / this.customInstallmentAmmount).toFixed(2);
        this.registrationFeeOption.fee.feeValue = String(portionValue);
      }
      return;
    }

    if (this.registrationFeeOption.extraTreatment === 'confirmUpfrontPayment') {
      this.registrationFeeOption.fee.feeValue = this.registrationValue;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.customInstallmentAmmount || changes.registrationValue || changes.registrationFeeOption) {
      this.ngOnInit();
    }
  }

  regFeeSelected() {
    this.genTagger.setTag({
      event_category: this.category,
      event_label: this.registrationFeeOption.label,
      event_action: 'selecionou – tarifa de cadastro'
    });

    this.changeSelectedFeeOption.emit(this.registrationFeeOption);
  }

  regFeeSelectedLink(e) {
    e.preventDefault();
    this.changeSelectedFeeOption.emit(this.registrationFeeOption);
  }
}
