import { DatePipe, Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import * as actions from '@app/core/state/pre-analysis/pre-analysis.actions';
import { selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import {
  PostIdentificationReqDTO,
  PostIdentificationResDTO,
  SubmitClientType,
  SubmitPersonType
} from '@app/showcase/models/api/post.identification.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AddRequestAction } from '@shared/components/widgets/loading/store/loading.actions';
import { Countries } from '@shared/constants/countries.enum';
import { DocumentTypeCodeEnum, DocumentTypeEnum } from '@shared/constants/documents.enum';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { SimulationModalService } from '@shared/services/modal/simulation-modal.service';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PreAnalysisService {
  public dialogConfig = new MatDialogConfig();
  private flagRedirectProposal = false;

  constructor(
    private http: HttpClient,
    private location: Location,
    private sessionStorageService: SessionStorageService,
    private modalService: SimulationModalService,
    private router: Router,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private store$: Store<AppState>,
    private genTagger: GenTagger,
    private internationalizationService: InternationalizationService
  ) {
    this.configDialog();
  }

  private configDialog(): void {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.id = 'modal-component';
    this.dialogConfig.width = '520px';
    this.dialogConfig.height = '330px';
  }

  public submitIdentification(payload: any): Observable<PostIdentificationResDTO> {
    // return of(<PostIdentificationResDTO>{
    //   simulation: {
    //     id: '0',
    //     idExistent: '222'
    //   }
    // });
    const user = this.sessionStorageService.getUser();
    const {
      economicActivity,
      income,
      cupom,
      travelDate,
      store,
      subsegment,
      product,
      financedValue,
      installmentAmount,
      paymentPlan,
      paymentMethod
    } = payload;

    const requestPayload: PostIdentificationReqDTO = {
      identification: {
        storeCode: store && store.code ? store.code : null,
        userCode: user.document,
        subsegmentId: subsegment ? subsegment.id : null,
        productCode: product ? product.code : null,
        persons: [
          {
            personType: SubmitPersonType.PF,
            clientType: SubmitClientType.BUYER,
            documents: [
              {
                typeCode: DocumentTypeCodeEnum.CPF,
                code: user.document
              }
            ],
            income,
            cupom,
            economyActivityId: economicActivity ? economicActivity.id : null
          }
        ],
        financedValue,
        installmentAmount,
        paymentPlan,
        paymentMethod,
        travelDate
      },
      cancelLastProposal: false
    };

    let endpoint = `${environment.apiRootUrl}/shopkeeper-simulation/simulation/identification/pre-analysis`;
    let proposalId;
    const myFlag = payload.hasOwnProperty('payload');

    if (!myFlag) {
      if (!payload.redirectProposal) {
        this.store$
          .select(selectProposalId)
          .pipe(
            filter(data => !!data),
            take(1)
          )
          .subscribe(propId => (proposalId = propId));

        endpoint = `${environment.apiRootUrl}/shopkeeper-simulation/simulation/identification/pre-analysis/${proposalId}`;
      }
    } else {
      requestPayload.identification.productCode = payload.payload.product.code;

      if (!payload.payload.redirectProposal) {
        this.store$
          .select(selectProposalId)
          .pipe(
            filter(data => !!data),
            take(1)
          )
          .subscribe(propId => (proposalId = propId));

        endpoint = `${environment.apiRootUrl}/shopkeeper-simulation/simulation/identification/pre-analysis/${proposalId}`;
      }
    }
    this.store$.dispatch(new AddRequestAction({ id: 'LOADING', request: null }));
    return this.http.post<PostIdentificationResDTO>(endpoint, requestPayload);
  }

  public handleSubmitResponse(submitResponse: PostIdentificationResDTO): void {
    // Response indicates flux to follow:

    // Maximum limit of proposals by customer or store reached.
    // if (submitResponse.maximumLimitExceeded) {
    //   this.handleNoOffersAvailable(submitResponse);
    // }
    // Proposal already exists for current user.
    if (submitResponse.proposal && submitResponse.proposal.id) {
      this.handleProposalAlreadyExists(submitResponse);
    }
    // Happy path, no constraints, user can continue with simulation.
    if (submitResponse.simulation && submitResponse.simulation.id) {
      this.router.navigate(['/proposal']);
    }
  }

  private handleNoOffersAvailable(submitResponse: PostIdentificationResDTO): void {
    const docType = submitResponse.identification.persons[0].documents[0].typeCode;
    let category = '/portallojista/criarproposta';
    let modalRef;
    let modalData = {
      title: this.translate.instant('NO-OFFERS-AVAILABLE-MODAL-TITLE'),
      description: this.translate.instant('NO-OFFERS-AVAILABLE-MODAL-DESCRIPTION', { docType }),
      confirmText: this.translate.instant('NO-OFFERS-AVAILABLE-MODAL-CONFIRM-TEXT')
    };
    modalRef = this.modalService.open(modalData, this.dialogConfig);
    modalRef
      .afterOpened()
      .pipe(take(1))
      .subscribe(() => {
        this.genTagger.setTag({
          event_category: category,
          event_action: Tag.AbriuModal + ' - ' + modalData.title,
          event_label: modalData.description
        });
      });
    modalRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.genTagger.setTag({
          event_category: category,
          event_action: Tag.Fechou_Modal + ' - ' + modalData.title,
          event_label: modalData.confirmText
        });
      });
  }

  private handleProposalAlreadyExists(submitResponse: PostIdentificationResDTO): void {
    const country = this.internationalizationService.currentCountry;
    const buyer = submitResponse.identification.persons.find(person => person.clientType === SubmitClientType.BUYER);
    let docType;

    switch (country) {
      case Countries.BR:
        docType = DocumentTypeEnum.CPF;
        break;
      case Countries.AR:
        docType = buyer.personType === SubmitPersonType.PF ? DocumentTypeEnum.DNI_ARG : DocumentTypeEnum.CUIT;
        break;
      case Countries.PE:
        docType = buyer.personType === SubmitPersonType.PF ? DocumentTypeEnum.GENERIC : DocumentTypeEnum.RUC;
        break;
      case Countries.CO:
        docType = buyer.personType === SubmitPersonType.PF ? DocumentTypeEnum.GENERIC : DocumentTypeEnum.NIT;
        break;
      default:
        docType = DocumentTypeEnum.GENERIC;
        break;
    }

    const date = submitResponse.proposal.creationDate;
    const creationDate = this.datePipe.transform(date, 'dd/MM/yyyy');

    this.modalService.open(
      {
        title: this.translate.instant('EXISTING-PROPOSAL-MODAL-TITLE'),
        description: this.translate.instant('EXISTING-PROPOSAL-MODAL-DESCRIPTION', { docType, creationDate }),
        confirmText: this.translate.instant('EXISTING-PROPOSAL-MODAL-CONFIRM-TEXT'),
        cancelText: this.translate.instant('EXISTING-PROPOSAL-MODAL-CANCEL-TEXT')
      },
      this.dialogConfig
    );

    this.modalService
      .confirmed()
      .pipe(take(1))
      .subscribe(confirmed => {
        if (confirmed) {
          this.router.navigate(['showcase/open-proposal']); // Redirect to proposal summary
        } else {
          this.handleCancelExistentProposal(submitResponse);
        }
      });
  }

  private handleCancelExistentProposal(submitResponse: PostIdentificationResDTO): void {
    this.modalService.open(
      {
        title: this.translate.instant('CANCEL-PROPOSAL-WARNING-MODAL-TITLE'),
        description: this.translate.instant('CANCEL-PROPOSAL-WARNING-MODAL-DESCRIPTION'),
        confirmText: this.translate.instant('CANCEL-PROPOSAL-WARNING-MODAL-CONFIRM-TEXT'),
        cancelText: this.translate.instant('CANCEL-PROPOSAL-WARNING-MODAL-CANCEL-TEXT')
      },
      this.dialogConfig
    );

    this.modalService
      .confirmed()
      .pipe(take(1))
      .subscribe(confirmed => {
        if (confirmed) {
          this.store$.dispatch(new actions.CancelExistingProposalAction(submitResponse));
        }
      });
  }
}
