import {
  RegisterAdditionalInformation,
  RegisterAddress,
  RegisterBacenAnswers,
  RegisterTravelersList,
  StepRegisterPut
} from '@app/proposal-workflow/models/step-register-put.model';
import { Action } from '@ngrx/store';

export enum RegisterTypes {
  SET_CUSTOMER_DATA = '[Set] Set Customer data',
  SET_COMPANY_DATA = '[Set] Set Company data',
  SET_ADDRESS_PERSON_DATA = '[Set] Set Address Person data',
  SET_ADDRESS_COMPANY_DATA = '[Set] Set Address Company data',
  SET_TRAVELERS_DATA = '[Set] Set Travelers list data',
  SET_ADDITIONAL_INFORMATION_DATA = '[Set] Set Additional Information Data',
  SET_BACEN_ANSWERS_DATA = '[Set] Set Bacen Answers data',
  GET_REGISTER_DATA = '[Get] Get Customer data',
  CLEAR_REGISTER_DATA = '[Clear] Clear Register data'
}

export class SetCustomerData implements Action {
  readonly type = RegisterTypes.SET_CUSTOMER_DATA;
  constructor(public payload: StepRegisterPut) {}
}

export class SetCompanyData implements Action {
  readonly type = RegisterTypes.SET_COMPANY_DATA;
  constructor(public payload: StepRegisterPut) {}
}

export class SetAddressPersonData implements Action {
  readonly type = RegisterTypes.SET_ADDRESS_PERSON_DATA;
  constructor(public payload: RegisterAddress) {}
}

export class SetAddressCompanyData implements Action {
  readonly type = RegisterTypes.SET_ADDRESS_COMPANY_DATA;
  constructor(public payload: RegisterAddress) {}
}

export class SetTravelersData implements Action {
  readonly type = RegisterTypes.SET_TRAVELERS_DATA;
  constructor(public payload: RegisterTravelersList) {}
}

export class SetAdditionalInformationData implements Action {
  readonly type = RegisterTypes.SET_ADDITIONAL_INFORMATION_DATA;
  constructor(public payload: RegisterAdditionalInformation) {}
}

export class SetBacenAnswersData implements Action {
  readonly type = RegisterTypes.SET_BACEN_ANSWERS_DATA;
  constructor(public payload: RegisterBacenAnswers) {}
}

export class GetRegisterData implements Action {
  readonly type = RegisterTypes.GET_REGISTER_DATA;
  constructor(public payload: any) {}
}

export class ClearRegisterData implements Action {
  readonly type = RegisterTypes.CLEAR_REGISTER_DATA;
  constructor() {}
}

export type RegisterAction =
  | SetCustomerData
  | SetCompanyData
  | SetAddressPersonData
  | SetAddressCompanyData
  | SetTravelersData
  | SetAdditionalInformationData
  | SetBacenAnswersData
  | GetRegisterData
  | ClearRegisterData;
