import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '@shared/shared.module';
import { BottomSheetCalendarComponent } from './components/challenge-question/bottomSheetCalendar/bottomSheetCalendar.component';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ChallengeQuestionComponent } from './components/challenge-question/challenge-question.component';
import { ConfirmationTokenComponent } from './components/confirmation-token/confirmation-token.component';
import { DialogNotAvailableComponent } from './components/password/dialog-not-available/dialog-not-available.component';
import { DialogPasswordSuccessComponent } from './components/password/dialog-password-success/dialog-password-success.component';
import { PasswordComponent } from './components/password/password.component';
import { SignUpDataComponent } from './components/sign-up-data/sign-up-data.component';
import { SignUpContainer } from './containers/sign-up/sign-up.container.component';
import { SignUpRoutingModule } from './signup-routing.module';
import { SignUpEffects } from './state/signup.effects';
import { initSignUpState, signUpReducer } from './state/signup.reducer';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { DialogChallengeComponent } from './components/challenge-question/dialog-challenge/dialog-challenge.component';
import { UserIdentificationComponent } from './components/user-identification/user-identification.component';
import { BiometryChallengeComponent } from './components/biometry-challenge/biometry-challenge.component';

// QRCODE LIB IMPORT
import { QRCodeModule } from 'angularx-qrcode';

// CAROUSEL LIB IMPORT
import { CarouselModule } from 'ngx-owl-carousel-o';

import { BiometryModule } from '@app/biometry/biometry.module';

import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { DocumentScanModule } from '@app/document-scan/document-scan.module';
import { ProposalWorkflowModule } from '@app/proposal-workflow/proposal-workflow.module';

@NgModule({
  declarations: [
    SignUpContainer,
    SignUpDataComponent,
    DialogNotAvailableComponent,
    DialogPasswordSuccessComponent,
    ConfirmationTokenComponent,
    ChallengeQuestionComponent,
    PasswordComponent,
    BottomSheetCalendarComponent,
    DialogChallengeComponent,
    UserIdentificationComponent,
    BiometryChallengeComponent,
    TermsAndConditionsComponent
  ],
  imports: [
    BiometryModule,
    StoreModule.forFeature('signUp', signUpReducer, {
      initialState: initSignUpState
    }),
    EffectsModule.forFeature([SignUpEffects]),
    CommonModule,
    MatFormFieldModule,
    BiometryModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    SharedModule,
    SignUpRoutingModule,
    MatDialogModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatIconModule,
    CurrencyMaskModule,
    MatCardModule,
    QRCodeModule,
    DocumentScanModule,
    CarouselModule,
    ProposalWorkflowModule
  ]
})
export class SignUpModule {}
