<button
  type="button"
  class="btn btn-back"
  (click)="backManagementList()"
  appTagger
  [event_category]="category()"
  event_label="voltar"
>
  <img src="./assets/santander/images/ic_arrow_left.svg" alt="icon arrow left" />
</button>

<div class="container">
  <div class="full-content" *ngIf="showMsgAgenteCertificado">
    <div class="d-flex">
      <img src="./assets/images/Check.svg" alt="check icon" />
      <p>{{ 'SUCCESS-SAVE-AGENT' | translate }}</p>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <h3 *ngIf="screenType === 'new-user'" class="title">{{ 'ADD-AGENT' | translate }}</h3>
    </div>
  </div>

  <form [formGroup]="newUserForm">
    <div class="row">
      <div class="col-12 col-md-12">
        <p class="form-label">{{ 'AGENT-DATA' | translate }}</p>
      </div>

      <div class="col-12 col-md-6">
        <mat-form-field appearance="legacy" class="input w-100">
          <mat-label>{{ 'CPF' | translate }}*</mat-label>
          <input
            autocomplete="off"
            mask="{{ $any(pioneerMasks).documentNumber.mask }}"
            type="text"
            matInput
            formControlName="documentNumber"
            maxlength="14"
            appTagger
            [event_category]="category()"
            event_label="{{ 'Document Number' | translate }}"
            [event_action]="Insert"
          />
          <img
            [hidden]="newUserForm.get('documentNumber').value === '' || !newUserForm.get('documentNumber').valid"
            class="valid-icon"
            src="./assets/icon-green.svg"
            alt="icon right green"
            matSuffix
          />
          <img
            [hidden]="newUserForm.get('documentNumber').untouched || newUserForm.get('documentNumber').valid"
            class="invalid-icon"
            src="./assets/atention-red.svg"
            alt="icon right red"
            matSuffix
          />
        </mat-form-field>
      </div>

      <div class="col-12 col-md-6 mx-auto">
        <button
          mat-raised-button
          class="btn w-100"
          color="primary"
          [disabled]="!newUserForm.get('documentNumber').valid || !!agenteCertificado"
          (click)="searchAgent()"
          appTagger
          [event_category]="category"
          event_label="{{ 'SEARCH-AGENT' | translate }}"
        >
          {{ 'SEARCH-AGENT' | translate }}
        </button>
      </div>
    </div>
  </form>

  <div class="row mt-5">
    <div class="col-12 col-md-12">
      <div class="card mb-4" *ngIf="!agenteCertificado">
        <p>{{ 'NO-AGENT' | translate }}</p>
      </div>
      <div class="card mb-4" *ngIf="!!agenteCertificado">
        <div class="col-12 mb-3">
          <mat-label class="form-label">{{ 'LGCA-LABEL' | translate }}</mat-label>
          <p>{{ agenteCertificado.agentName }}</p>
        </div>
        <div class="col-12 mb-3">
          <mat-label class="form-label">{{ 'Document Number' | translate }}</mat-label>
          <p>{{ agenteCertificado.documentNumber | mask: '000.000.000-00' }}</p>
        </div>
        <div class="col-12 mb-1">
          <mat-label class="form-label">{{ 'DUE-DATE' | translate }}</mat-label>
          <p>{{ agenteCertificado.expirationDate | date: 'dd/MM/yyyy' }}</p>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="!!agenteCertificado">
    <div class="col-12 mx-auto">
      <button
        mat-raised-button
        class="btn w-100"
        color="primary"
        [disabled]="!agenteCertificado"
        (click)="associateAgent()"
        appTagger
        [event_category]="category"
        event_label="{{ 'New' | translate }}"
      >
        {{ 'New' | translate }}
      </button>
    </div>
  </div>
</div>
