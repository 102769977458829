<ng-container *ngFor="let offer of offer">
  <p class="best-offer">{{ offer.simulationOffer.offerTitle }}</p>
  <div class="offer-card">
    <div class="card-header">
      <p class="title">{{ title }}</p>
      <p [class]="proposal?.proposalData?.product">{{ proposal?.proposalData?.product }}</p>
    </div>
    <div class="card-body">
      <div class="financied-value">
        <p class="subtitle" *ngIf="proposal?.proposalData?.product === productCode.CDC">Valor do financiamento</p>
        <p
          class="subtitle"
          *ngIf="
            proposal?.proposalData?.product === productCode.CSC || proposal?.proposalData?.product === productCode.CSCP
          "
        >
          Valor da cessão
        </p>
        <p class="card-text" *ngIf="proposal?.proposalData?.product === productCode.CDC">
          {{ offer.simulationOffer.installmentAmount }} parcelas de
        </p>
        <p
          class="card-text mt-2"
          *ngIf="
            proposal?.proposalData?.product === productCode.CSC || proposal?.proposalData?.product === productCode.CSCP
          "
        >
          {{ offer.simulationOffer.installmentAmount }}x de
        </p>
        <p class="financied-total" *ngIf="offer.simulationOffer.hasInsurance">
          {{ offer.simulationOffer.installmentValueWithoutInsurance | localeCurrency }}
        </p>
        <p class="financied-total" *ngIf="!offer.simulationOffer.hasInsurance">
          {{ offer.simulationOffer.installmentValue | localeCurrency }}
        </p>
      </div>
      <div class="insurance-value" *ngIf="offer.simulationOffer.hasInsurance">
        <p class="subtitle">Com seguro</p>
        <p class="card-text">
          + {{ offer.simulationOffer.installmentInsuranceValue | localeCurrency }} com seguro e benefícios
        </p>
        <p>
          Total de <span class="proposal-total">{{ offer.simulationOffer.installmentValue | localeCurrency }}</span>
        </p>
      </div>
      <div class="summary">
        <p
          class="subtitle"
          *ngIf="
            proposal?.proposalData?.product === productCode.CSC || proposal?.proposalData?.product === productCode.CSCP
          "
        >
          Resumo da cessão
        </p>
        <p class="card-text">Primeira parcela em até {{ offer.simulationOffer.dueDaysIntervalMax }} dias</p>
      </div>
    </div>
    <div class="card-footer">
      <button
        class="simulation-button"
        (click)="selecionar(offer)"
        appTagger
        [event_category]="category"
        event_label="Simular o financiamento"
      >
        <span *ngIf="proposal?.proposalData?.product === productCode.CP">Simular o empréstimo</span>
        <span *ngIf="proposal?.proposalData?.product === productCode.CDC">Simular o financiamento</span>
        <span *ngIf="proposal?.proposalData?.product === productCode.CSC">Simular o parcelamento</span>
        <span *ngIf="proposal?.proposalData?.product === productCode.CSCP">Simular o parcelamento</span>
      </button>
    </div>
  </div>
</ng-container>
