<div class="border upload-card p-3 rounded mt-4 mb-4">
  <div class="row vertical p-2">
    <div class="vertical">
      <img src="./assets/images/icon_download.svg" class="align-self-center download-icon" alt="download icon" />
    </div>
    <div class="vertical">
      <h4 class="font-weight-bold vertical ml-1 mt-1 mb-0">{{ checklistItem?.document?.name | titlecase }}*</h4>
      <h5 class="vertical ml-1 mt-3">
        {{ 'FORMALIZATION-STEP-DOCUMENT-UPLOAD-ACCEPTED-FORMATS' | translate }} -
        {{ 'MAX-FILE-SIZE-ALLOWED-MSG' | translate: { maxSize: maxSizeAllowed } }}
      </h5>
    </div>
    <div class="d-sm-none vertical info-button">
      <span
        matTooltip="{{ checklistItem?.document?.description }}"
        matTooltipClass="custom-tooltip"
        [matTooltipPosition]="'before'"
        [matTooltipShowDelay]="10"
        [matTooltipHideDelay]="3000"
        class="material-icons"
      >
        error_outline
      </span>
    </div>
  </div>

  <div class="mt-2">
    <!-- FILES CURRENTLY UPLOADING LIST -->
    <ng-container *ngFor="let file of files">
      <div class="row no-gutters file-row">
        <h4 class="col-11 file-name">{{ file?.name }}</h4>
        <span class="material-icons cancel-button col-1" (click)="onCancelClick(file)">
          close
        </span>
      </div>
      <mat-progress-bar [value]="file?.progress" class="row no-gutters progress-config"></mat-progress-bar>
      <h4 class="row no-gutters text-muted mt-2">
        {{ 'UPLOAD-PROGRESS-MSG' | translate: { loaded: file?.loaded, total: file?.total, progress: file?.progress } }}
      </h4>
    </ng-container>

    <!-- SUCCESSFULLY UPLOADED FILES LIST -->
    <ng-container *ngFor="let file of filesSuccess">
      <div class="row no-gutters file-row">
        <div class="col-10">
          <h4 class="d-inline">{{ file?.name }} ({{ file?.total }})</h4>
        </div>

        <div class="col-1">
          <img src="./assets/images/ic_check.svg" class="success-icon" alt="check icon" />
        </div>

        <div class="col-1">
          <img
            src="./assets/images/ic_lixeira.svg"
            class="delete-icon"
            (click)="onDeleteClick(file)"
            alt="delete icon"
          />
        </div>
      </div>
    </ng-container>

    <!-- FILES WITH ERROR ON UPLOAD -->
    <ng-container *ngFor="let file of filesError">
      <div class="row no-gutters">
        <div class="col-11">
          <h4 class="d-inline">{{ file?.name }}</h4>
        </div>
        <div class="col-1">
          <span class="material-icons text-danger">
            warning
          </span>
        </div>
      </div>
      <div class="row no-gutters">
        <h4 class="error-msg col-12">{{ file?.errorMsg }}</h4>
      </div>
    </ng-container>
  </div>

  <div class="row p-0 mt-1">
    <div class="col-12">
      <button
        class="btn btn-block attach-btn"
        (click)="handleAttach()"
        [disabled]="handleDisable()"
        mat-button
        color="primary"
      >
        {{ attachText }}
      </button>
    </div>
  </div>

  <div *ngIf="errorMessage" class="row">
    <h4 class="error-msg col-12 mt-2">{{ errorMessage }}</h4>
  </div>

  <input
    type="file"
    accept=".png, .jpeg, .jpg, .pdf"
    #fileUpload
    id="fileUpload"
    name="fileUpload"
    class="d-none"
    multiple="multiple"
    (change)="uploadFileHandler()"
  />
</div>
