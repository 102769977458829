import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';

@Pipe({
  name: 'localePercentage'
})
export class LocalePercentagePipe implements PipeTransform {
  constructor(private _decimalPipe: DecimalPipe) {}

  getLocaleCode() {
    switch (environment.currentCountry) {
      case 'bra':
        return 'pt-BR';
      case 'col':
        return 'es-CO';
      case 'per':
        return 'es-PE';
      case 'arg':
        return 'es-AR';
      default:
        return 'pt-BR';
    }
  }

  transform(value: any, digitsInfo?: string, locale?: string): string {
    const suffix = '%';
    locale = this.getLocaleCode();

    if (!digitsInfo) {
      // {minIntegerDigits}.{minFractionDigits}-{maxFractionDigits}.
      digitsInfo = '0.2-4';
    }

    if (value !== null) {
      return this._decimalPipe.transform(value, digitsInfo, locale) + suffix;
    }

    return null;
  }
}
