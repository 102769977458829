import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const formalizationSelector = (state: AppState) => state.formalization;

export const selectAgentsLegal = createSelector(
  formalizationSelector,
  state => state.agentsLegal
);

export const selectBiometry = createSelector(
  formalizationSelector,
  state => state.biometry
);

export const selectBiometryResponse = createSelector(
  formalizationSelector,
  state => state.biometryResponse
);

export const selectLatestBiometryStatus = createSelector(
  formalizationSelector,
  state => state.latestBiometryStatus
);

export const selectCrossedFluxLink = createSelector(
  formalizationSelector,
  state => state.crossedFluxLink
);

export const selectLatestCrossedFluxStatus = createSelector(
  formalizationSelector,
  state => state.latestCrossedFluxStatus
);

export const selectLatestBiometryCrossFluxStatus = createSelector(
  formalizationSelector,
  state => state.latestBiometryCrossFluxStatus
);

export const selectCrossedFluxInfo = createSelector(
  formalizationSelector,
  state => state.crossedFluxInfo
);

export const selectMaxFileSizeAllowed = createSelector(
  formalizationSelector,
  state => state.maxFileSizeAllowed
);

export const selectSendTypes = createSelector(
  formalizationSelector,
  state => state.sendTypes
);

export const selectTutorialSteps = createSelector(
  formalizationSelector,
  state => state.tutorialSteps
);

export const selectStatus = createSelector(
  formalizationSelector,
  state => state.status
);

export const selectSummarySteps = createSelector(
  formalizationSelector,
  state => state.summarySteps
);

export const selectSummaryStatus = createSelector(
  formalizationSelector,
  state => state.summaryStatus
);

export const selectCurrentRepresentative = createSelector(
  formalizationSelector,
  state => state.currentRepresentative
);

export const selectIvNonce = createSelector(
  formalizationSelector,
  state => state.ivNonce
);

export const selectSubmitResponse = createSelector(
  formalizationSelector,
  state => state.submitResponse
);

export const selectCrossedFluxUuid = createSelector(
  selectCrossedFluxLink,
  state => state.uuid
);
