import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ErrorModalComponent } from '@app/proposal-workflow/components/error-modal/error-modal.component';
import { InputChange } from '@app/proposal-workflow/models/input-change.model';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { SimulationStatusModel } from '@app/proposal-workflow/models/simulation-status.model';
import { CouponStatus } from '@app/proposal-workflow/models/validade-coupon.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { environment } from '@env/environment';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LoadingMessagesModalComponent } from '@shared/components/loading-messages-modal/loading-messages-modal';
import { AddRequestAction, RemoveRequestAction } from '@shared/components/widgets/loading/store/loading.actions';
import { Countries } from '@shared/constants/countries.enum';
import { Severity, SimulationStatus } from '@shared/constants/simulation-status.enum';
import { VivereError } from '@shared/models/alert.model';
import { SimulationProposalService } from '@shared/services/simulation/simulation-proposal.service';
import { SimulationService } from '@shared/services/simulation/simulation.service';
import { EMPTY, of } from 'rxjs';
import { catchError, delay, distinctUntilChanged, finalize, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { AppState } from '..';
import { SubmitIdentificationIncomeAction } from '../pre-analysis/pre-analysis.actions';
import { GetConfirmationData } from '../proposal-information/proposal-information.actions';
import { SetPaymentPlanSelectedAction, SetProposalIdAction } from '../proposal/proposal.actions';
import * as actions from './simulation.actions';

@Injectable()
export class SimulationEffects {
  countries = Countries;
  proposalId = null;
  maxPooling = Number(environment.maxAttemptsSimulation);
  delayPooling = Number(environment.delayAttemptsSimulation);
  public isNPP = JSON.parse(environment.useNPP);
  loadingMessagesModalRef: MatDialogRef<LoadingMessagesModalComponent>;

  constructor(
    private actions$: Actions,
    private simulationService: SimulationService,
    private router: Router,
    private modal: MatDialog,
    private store$: Store<AppState>,
    private simulationProposalService: SimulationProposalService,
    private snackBar: MatSnackBar,
    private genTagger: GenTagger,
    private translateService: TranslateService
  ) {}

  @Effect()
  getStatusProposals = this.actions$.pipe(
    ofType<actions.GetStatusProposals>(actions.SimulationActionTypes.GET_STATUS_PROPOSALS),
    switchMap(action => {
      this.store$.dispatch(new SetProposalIdAction(action.payload.simulationId));
      this.proposalId = action.payload;
      return this.simulationService.getStatusProposals(action.payload.simulationId).pipe(
        switchMap((response: SimulationStatusModel) => {
          let nextAction: any;
          if (response.status.toLowerCase() === SimulationStatus.success) {
            nextAction = new actions.GetStatusProposalsSuccess({
              ...response,
              recalculateNpp: action.payload.recalculateNpp,
              redirectProposal: action.payload.redirectProposal,
              offerPayload: action.payload.offerPayload
            });
          } else {
            nextAction = new actions.GetStatusProposalsInc({ ...action.payload, ...response });
          }

          return of(nextAction);
        }),
        catchError((err: VivereError) => {
          return of(new actions.GetStatusProposalsError(err));
        })
      );
    })
  );

  @Effect()
  getStatusProposalsInc = this.actions$.pipe(
    ofType<actions.GetStatusProposalsInc>(actions.SimulationActionTypes.GET_STATUS_PROPOSALS_INC),
    switchMap(action => {
      return this.simulationService.getStatusProposals(action.payload.simulationId).pipe(
        switchMap((response: SimulationStatusModel) => {
          let currentPooling = 0;
          this.store$
            .pipe(
              select('simulation'),
              take(1)
            )
            .subscribe((s: any) => (currentPooling = s.currentPooling));
          this.handlePollingModal(true);
          return of({ ...response, currentPooling });
        }),
        switchMap((response: SimulationStatusModel) => {
          let nextAction: any;
          if (response.status.toLowerCase() === SimulationStatus.success) {
            this.handlePollingModal(false);
            nextAction = new actions.GetStatusProposalsSuccess({
              ...response,
              redirectProposal: action.payload.redirectProposal,
              recalculateNpp: action.payload.recalculateNpp,
              offerPayload: action.payload.offerPayload
            });
            return of(nextAction);
          } else if (response.status.toLowerCase() === SimulationStatus.unapproved) {
            nextAction = new actions.LoadingDismiss();
          } else if (response.status.toLowerCase() === SimulationStatus.error) {
            nextAction = new actions.GetStatusProposalsError(response);
          } else if (response.status.toLowerCase() === SimulationStatus.processing) {
            if (response.currentPooling === this.maxPooling) {
              nextAction = new actions.GetStatusProposalsError(response);
            } else {
              if (response.showCardsOffers === false) {
                let category = '/portallojista/criarproposta';
                let modalData = {
                  errorIcon: 'cancel',
                  errorTitle: this.translateService.instant('NO-OFFERS-AVAILABLE-MODAL-TITLE'),
                  errorText: this.translateService.instant('LOADING-MODAL-DESCRIPTION'),
                  errorButton: this.translateService.instant('LOAD-CONTENT-ERROR-MODAL-CONFIRM-BUTTON')
                };
                let modalRef;
                this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));
                modalRef = this.modal.open(ErrorModalComponent, {
                  maxWidth: '80vw',
                  width: '500px',
                  ...modalData
                });
                modalRef
                  .afterClosed()
                  .pipe(
                    take(1),
                    tap(() => {
                      this.genTagger.setTag({
                        event_category: category,
                        event_action: Tag.Fechou_Modal + ' - ' + modalData.errorTitle,
                        event_label: modalData.errorButton
                      });
                    })
                  )
                  .subscribe(() => this.router.navigate(['/identification']));
                modalRef
                  .afterOpened()
                  .pipe(take(1))
                  .subscribe(() => {
                    this.genTagger.setTag({
                      event_category: category,
                      event_action: Tag.AbriuModal + ' - ' + modalData.errorTitle,
                      event_label: modalData.errorText
                    });
                  });

                return EMPTY;
              } else {
                nextAction = new actions.GetStatusProposalsInc({ ...action.payload, ...response });
              }
            }
          } else if (response && response.status && !nextAction) {
            this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));
            return EMPTY;
          }

          return of(nextAction).pipe(delay(this.delayPooling));
        }),
        catchError((err: VivereError) =>
          of(new actions.GetStatusProposalsError(err)).pipe(
            finalize(() => {
              this.handlePollingModal(false);
            })
          )
        )
      );
    })
  );

  @Effect({ dispatch: false })
  onSuccess = this.actions$.pipe(
    ofType<actions.GetStatusProposalsSuccess>(actions.SimulationActionTypes.GET_STATUS_PROPOSALS_SUCCESS),
    tap(action => {
      if (action.payload.redirectProposal) {
        this.router.navigate(['proposal/step-offer']);
        this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));
      } else if (action.payload.recalculateNpp) {
        this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));
        this.store$.dispatch(new actions.UpdateNppOfferSuccess(new Date()));
      } else if (action.payload.offerPayload) {
        this.store$.dispatch(new actions.GetOffer(action.payload.offerPayload));
      } else {
        this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));

        let calculationPayload;

        this.store$
          .pipe(
            select('simulation'),
            take(1)
          )
          .subscribe((res: any) => {
            calculationPayload = res.calculationPayload;
          });

        this.store$.dispatch(new actions.GetNewCalculation(calculationPayload));
      }
    })
  );

  @Effect({ dispatch: false })
  GetStatusProposalsErro = this.actions$.pipe(
    ofType<actions.GetStatusProposalsError>(actions.SimulationActionTypes.GET_STATUS_PROPOSALS_ERROR),
    distinctUntilChanged(),
    tap(({ payload }) => {
      this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));

      const errorMessages =
        payload.error && payload.error.messages
          ? payload.error.messages
          : [
              { message: this.translateService.instant('GENERIC-SIMULATION-ERROR-MODAL-TITLE') },
              { message: this.translateService.instant('GENERIC-SIMULATION-ERROR-MODAL-DESCRIPTION') },
              { message: this.translateService.instant('GENERIC-SIMULATION-ERROR-MODAL-CODE') }
            ];

      if (payload.error && errorMessages) {
        this.handleErrorModal(errorMessages, '/identification', payload.error.severity);
      } else {
        this.handleErrorModal(errorMessages, null, Severity.ERROR, 'cancel', 'OK');
      }
    })
  );

  @Effect({ dispatch: false })
  onError = this.actions$.pipe(
    ofType<actions.LoadingDismiss>(actions.SimulationActionTypes.LOADING_DISMISS),
    tap(err => {
      this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));
      let category = '/portallojista/criarproposta';
      let modalRef;
      let modalData = {
        errorIcon: 'cancel',
        errorTitle: this.translateService.instant('NO-OFFERS-AVAILABLE-MODAL-TITLE'),
        errorText: this.translateService.instant('NO-OFFERS-AVAILABLE-MODAL-DESCRIPTION'),
        errorButton: this.translateService.instant('NO-OFFERS-AVAILABLE-MODAL-CONFIRM-TEXT')
      };

      modalRef = this.modal.open(ErrorModalComponent, {
        maxWidth: '80vw',
        width: '500px',
        ...modalData
      });

      modalRef
        .afterClosed()
        .pipe(
          take(1),
          tap(() => {
            this.genTagger.setTag({
              event_category: category,
              event_action: Tag.Fechou_Modal + ' - ' + modalData.errorTitle,
              event_label: modalData.errorButton
            });
          })
        )
        .subscribe(() => this.router.navigate(['/identification']));

      modalRef
        .afterOpened()
        .pipe(take(1))
        .subscribe(() => {
          this.genTagger.setTag({
            event_category: category,
            event_action: Tag.AbriuModal + ' - ' + modalData.errorTitle,
            event_label: modalData.errorText
          });
        });
    })
  );

  @Effect()
  getNewCalculationEffect = this.actions$.pipe(
    ofType<actions.GetNewCalculation>(actions.SimulationActionTypes.GET_NEW_CALCULATION),
    switchMap(action =>
      this.simulationProposalService.getNewCalculation(action.payload).pipe(
        mergeMap(response => {
          return of(new actions.GetNewCalculationSuccess(response));
        }),
        catchError((err: HttpErrorResponse) => {
          if (err) {
            const errorMessages = err.error.messages;

            if (errorMessages) {
              this.handleErrorModal(errorMessages, '/identification', err.error.severity);
            }
          }
          return [new actions.GetNewCalculationError(err.error)];
        })
      )
    )
  );

  @Effect()
  getNewFlexInstallmentsCalculationEffect = this.actions$.pipe(
    ofType<actions.GetNewFlexInstallmentsCalculation>(
      actions.SimulationActionTypes.GET_NEW_FLEX_INSTALLMENTS_CALCULATION
    ),
    switchMap(action => {
      let calculationPayload;

      this.store$
        .pipe(
          select('simulation'),
          take(1)
        )
        .subscribe((res: any) => (calculationPayload = res.calculationPayload));

      calculationPayload.flexInstallmentsInput = action.payload.value.flexInstallmentsInput;

      return [new actions.GetNewCalculation(calculationPayload)];
    })
  );

  @Effect()
  postSimulationEffect = this.actions$.pipe(
    ofType<actions.PostSimulationRequest>(actions.SimulationActionTypes.POST_SIMULATION_REQUEST),
    switchMap(action =>
      this.simulationProposalService.postSimulation(action.payload).pipe(
        switchMap(response => {
          const proposalId = action.payload.proposalId || action.payload.SimulationInput.proposalId;
          return [
            new actions.PostSimulationSuccess(response),
            new GetConfirmationData(proposalId),
            new actions.RedirectSimulationSummary()
          ];
        }),
        catchError((err: HttpErrorResponse) => {
          const errorMessages = err.error.messages;

          if (errorMessages) {
            this.handleErrorModal(errorMessages, null, Severity.ERROR, 'cancel', 'OK');
          } else {
            const snackbarConfig: MatSnackBarConfig = { duration: 5000 };
            const snackbarMessage = this.translateService.instant('POST-SIMULATION-ERROR-SNACKBAR');
            this.snackBar.open(snackbarMessage, null, snackbarConfig);
          }

          return of(new actions.PostSimulationError(err));
        })
      )
    )
  );

  @Effect({ dispatch: false })
  redirectStepRegisterEffect = this.actions$.pipe(
    ofType<actions.RedirectStepRegister>(actions.SimulationActionTypes.REDIRECT_STEP_REGISTER),
    tap(() => this.router.navigate(['/proposal/step-register']))
  );
  @Effect({ dispatch: false })
  redirectSimulationSummaryEffect = this.actions$.pipe(
    ofType<actions.RedirectSimulationSummary>(actions.SimulationActionTypes.REDIRECT_SIMULATION_SUMMARY),
    tap(() => this.router.navigate(['/proposal/step-simulation/summary']))
  );

  @Effect()
  delayProposalStatusEffect = this.actions$.pipe(
    ofType<actions.DelayProposalStatus>(actions.SimulationActionTypes.DELAY_PROPOSAL_STATUS),
    mergeMap(action => this.simulationService.getStatusProposals(action.payload)),
    mergeMap(res => of(res).pipe(delay(this.delayPooling))),
    mergeMap(response => [new actions.GetSimulation(), new actions.ShowSimulationLoader(false)])
  );

  @Effect()
  getSimulationParametersEffect = this.actions$.pipe(
    ofType<actions.GetSimulationParameters>(actions.SimulationActionTypes.GET_SIMULATION_PARAMETERS),
    mergeMap(action => this.simulationProposalService.getSimulationParameters(action.payload)),
    mergeMap(res => [new actions.GetSimulationParametersSuccess(res)]),
    catchError(err => [new actions.GetSimulationParametersError(err)])
  );

  @Effect()
  getSimulationOffers = this.actions$.pipe(
    ofType<actions.GetOffer>(actions.SimulationActionTypes.GET_OFFER),
    switchMap(action => {
      return this.simulationService.getOffers(action.payload).pipe(
        map(response => {
          this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));
          return new actions.GetOfferSuccess(response);
        }),
        catchError(err => of(new actions.GetOfferError(err)))
      );
    })
  );

  @Effect()
  getEnquadramento = this.actions$.pipe(
    ofType<actions.GetEnquadramento>(actions.SimulationActionTypes.GET_FRAMEWORK),
    mergeMap(action => {
      return this.simulationService.getEnquadramento(action.payload).pipe(
        map(response => {
          return new actions.GetEnquadramentoSuccess(response);
        }),
        catchError(err => of(new actions.GetEnquadramentoError(err)))
      );
    })
  );

  @Effect({ dispatch: false })
  getSimulationOffersError = this.actions$.pipe(
    ofType<actions.GetOfferError>(actions.SimulationActionTypes.GET_OFFER_ERROR),
    distinctUntilChanged(), // fix modal opening twice
    tap(({ payload }) => {
      this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));

      // NOTE: - Shouldn't an general error message be displayed when payload.error === null?
      const errorMessages = payload.error ? payload.error.messages : null;

      if (errorMessages) {
        this.handleErrorModal(errorMessages, '/identification', payload.error.severity);
      }
    })
  );

  @Effect()
  getAllOffers = this.actions$.pipe(
    ofType<actions.GetOffer>(actions.SimulationActionTypes.GET_ALL_OFFERS),
    switchMap(action => {
      return this.simulationService.getAllOffers(action.payload).pipe(
        map(response => {
          this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));
          return new actions.GetAllOffersSuccess(response);
        }),
        catchError(err => of(new actions.GetAllOffersError(err)))
      );
    })
  );

  @Effect()
  postBestOfferEffect = this.actions$.pipe(
    ofType<actions.PostBestOffer>(actions.SimulationActionTypes.POST_BEST_OFFER),
    mergeMap(action => {
      return this.simulationService.postBestOffer(action.payload, action.subOfferId).pipe(
        map(response => {
          // Ajuste objeto insurace
          if(response.messages){
            this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));
            return new actions.PostBestOfferSuccess(response);
          }
          if (!!response.bestOffer.insurance && response.bestOffer.insurance.length > 0) {
            if (!!response.bestOffer.insurance[0]) {
              response.bestOffer.insurance.forEach(i => {
                i.id = Number(i.code);
                i.description = i.text;
                i.installmentAmount = response.bestOffer.term;
              });
            } else {
              response.bestOffer.insurance = [];
            }
          }

          this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));
          this.store$.dispatch(new SetPaymentPlanSelectedAction({ code: response.bestOffer.flexPlanType }));
          return new actions.PostBestOfferSuccess(response);
        }),
        catchError(err => {
          const error = err?.error?.messages;

          this.modal.open(ErrorModalComponent, {
            data: {
              errorIcon: 'error',
              errorTitle: error[0].message,
              errorText: error[1].message,
              errorCode: error[2].message,
              errorButton: 'Ok'
            },
            maxWidth: '80vw',
            width: '500px'
          });

          return of(new actions.PostBestOfferError(err));
        })
      );
    })
  );

  @Effect()
  updateNppOfferEffect = this.actions$.pipe(
    ofType<actions.UpdateNppOffer>(actions.SimulationActionTypes.UPDATE_NPP_OFFER),
    switchMap(action =>
      this.simulationService.updateOfferNpp(action.payload).pipe(
        switchMap(() => {
          return [
            new AddRequestAction({ id: 'LOADING', request: null }),
            new actions.GetStatusProposals({
              simulationId: action.payload.proposalId,
              recalculateNpp: true,
              redirectProposal: false
            })
          ];
        }),
        catchError(err => {
          const snackbarConfig: MatSnackBarConfig = {
            duration: 5000
          };

          const snackbarMessage = 'Erro ao atualizar a proposta npp';

          this.snackBar.open(snackbarMessage, null, snackbarConfig);

          return of(new actions.UpdateNppOfferError(err));
        })
      )
    )
  );

  @Effect()
  updateSimulationOffers = this.actions$.pipe(
    ofType<actions.GetOffer>(actions.SimulationActionTypes.UPDATE_OFFER),
    switchMap(action => {
      return this.simulationService.updateOffer(action.payload).pipe(
        map(response => {
          if (action.payload.redirectToSimulation) {
            this.router.navigate(['/proposal/step-simulation']);
          }
          return new actions.UpdateOfferSuccess(response);
        }),
        catchError(err => of(new actions.UpdateOfferError(err)))
      );
    })
  );

  @Effect({ dispatch: false })
  updateSimulationOffersError = this.actions$.pipe(
    ofType<actions.UpdateOfferError>(actions.SimulationActionTypes.UPDATE_OFFER_ERROR),
    distinctUntilChanged(), // fix modal opening twice
    tap(({ payload }) => {
      this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));

      const errorMessages = payload.error.messages;

      if (errorMessages) {
        this.handleErrorModal(errorMessages, '/identification', payload.error.severity);
      }
    })
  );

  @Effect()
  validateCouponEffect = this.actions$.pipe(
    ofType<actions.ValidateCoupon>(actions.SimulationActionTypes.VALIDATE_COUPON),
    switchMap(action => {
      if (!action.payload.code) {
        const r = {
          response: '',
          appliedCoupon: action.payload.appliedCoupon,
          onlyValidation: action.payload.onlyValidation,
          offerPayload: action.payload.offerPayload,
          proposalId: action.payload.proposalId
        };
        return [new actions.ValidateCouponSuccess(r)];
      }

      return this.simulationService.validateCoupon(action.payload.code, action.payload.proposalId).pipe(
        switchMap(response => {
          const r = {
            response,
            appliedCoupon: response.status === CouponStatus.VALID,
            onlyValidation: action.payload.onlyValidation,
            offerPayload: action.payload.offerPayload,
            proposalId: action.payload.proposalId
          };
          return [new actions.ValidateCouponSuccess(r)];
        }),
        catchError(err => {
          return [new actions.ValidateCouponError(err.error)];
        })
      );
    })
  );

  @Effect({ dispatch: false })
  validateCouponSuccessEffect = this.actions$.pipe(
    ofType<actions.ValidateCouponSuccess>(actions.SimulationActionTypes.VALIDATE_COUPON_SUCCESS),
    tap(action => {
      if (action.payload.onlyValidation) {
        return;
      }

      if (
        action.payload.response.status === CouponStatus.VALID ||
        action.payload.appliedCoupon ||
        action.payload.response === ''
      ) {
        let code = '';

        if (action.payload.response && action.payload.response.status === CouponStatus.VALID) {
          code = action.payload.response.code;
        }

        const payload: InputChange = {
          value: {
            pricing: {
              couponCode: code
            }
          },
          input: InputTypes.COUPON_CODE
        };

        this.store$.dispatch(
          new SubmitIdentificationIncomeAction({
            inputChanged: payload,
            couponCode: code,
            offerPayload: action.payload.offerPayload,
            redirectProposal: false
          })
        );
      }
    })
  );

  @Effect({ dispatch: false })
  validateCouponErrorEffect = this.actions$.pipe(
    ofType<actions.ValidateCouponError>(actions.SimulationActionTypes.VALIDATE_COUPON_ERROR),
    distinctUntilChanged(),
    tap(({ payload }) => {
      this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));

      const errorMessages = payload.error.messages ? payload.error.messages : [];

      if (errorMessages) {
        this.handleErrorModal(errorMessages, null, payload.error.severity);
      }
    })
  );

  @Effect()
  getNppInstallments = this.actions$.pipe(
    ofType<actions.GetNppInstallments>(actions.SimulationActionTypes.GET_NPP_INSTALLMENTS),
    mergeMap(action => {
      return this.simulationService.getNppInstallments(action.payload).pipe(
        map(response => {
          return new actions.GetNppInstallmentsSuccess(response);
        }),
        catchError(err => of(new actions.GetNppInstallmentsError(err)))
      );
    })
  );

  handlePollingModal(action: boolean) {
    if (action && !this.loadingMessagesModalRef) {
      const isMobile = window.innerWidth < 768;
      this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));
      this.loadingMessagesModalRef = this.modal.open(LoadingMessagesModalComponent, {
        disableClose: true,
        maxWidth: '100vw',
        width: isMobile ? '100%' : '40%',
        height: isMobile ? '100%' : '40%'
      });
    }
    if (!action) {
      if (this.loadingMessagesModalRef !== null) {
        this.loadingMessagesModalRef.close();
        this.loadingMessagesModalRef = null;
      }
    }
  }

  handleErrorModal(
    errorMessages: any[],
    redirectTo: string,
    severity: string,
    errorIcon = 'cancel',
    errorButton = 'OK'
  ) {
    return this.modal
      .open(ErrorModalComponent, {
        data: {
          errorIcon,
          errorTitle: errorMessages[0].message,
          errorText: errorMessages[1].message,
          errorCode: errorMessages[2].message,
          errorButton
        },
        maxWidth: '80vw',
        width: '500px'
      })
      .afterClosed()
      .subscribe(() => {
        if (redirectTo && severity === Severity.ERROR) {
          this.router.navigate([redirectTo]);
        }
      });
  }
}
