import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const showcaseSelector = (state: AppState) => state.showcase;

export const selectPersonalizedOffers = createSelector(
  showcaseSelector,
  state => state.personalizedOffers
);

export const selectSpecialOffers = createSelector(
  showcaseSelector,
  state => state.specialOffers
);

export const selectSubsegmentsList = createSelector(
  showcaseSelector,
  state => state.subsegmentsList
);

export const selectSelectedSubsegment = createSelector(
  showcaseSelector,
  state => state.selectedSubsegment
);

export const statesList = createSelector(
  showcaseSelector,
  state => state.statesList
);

export const citiesList = createSelector(
  showcaseSelector,
  state => state.citiesList
);

export const storesList = createSelector(
  showcaseSelector,
  state => state.storesList
);

export const economicActivities = createSelector(
  showcaseSelector,
  state => state.economicActivities
);

export const maxFinancialValue = createSelector(
  showcaseSelector,
  state => state.maxFinancialValue
);

export const selectSubmitResponse = createSelector(
  showcaseSelector,
  state => state.submitResponse
);

export const selectIsLoadShowcase = createSelector(
  showcaseSelector,
  state => state.isLoadShowCase
);
