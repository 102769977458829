import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Offers } from '@app/proposal-workflow/containers/step-offers/model/offer.model';
import { SimulationStatusModel } from '@app/proposal-workflow/models/simulation-status.model';
import { ValidateCouponModel } from '@app/proposal-workflow/models/validade-coupon.model';
import { environment } from '@env/environment';
import { Domains } from '@shared/models/domains.model';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SimulationService {
  constructor(private http: HttpClient) {}

  getEconomicActivityByGroup(idEconomicActivityGroup): Observable<Domains[]> {
    return this.http
      .get(`${environment.apiRootUrl}/estrutura/1.0.0/ramoatividade`, {
        params: {
          cdGrupoRamo: idEconomicActivityGroup + '',
          per_page: '200'
        }
      })
      .pipe(
        map((response: any) => {
          return response.ramosAtividades.map(c => {
            return <Domains>{ code: c.idRamoAtividade, description: c.nomeRamo };
          });
        })
      );
  }

  getStatusProposals(code: string): Observable<SimulationStatusModel> {
    let endpoint;
    if (JSON.parse(environment.useNPP)) {
      endpoint = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/simulation/${code}`;
    } else {
      endpoint = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/simulation/pre-analysis/${code}`;
    }
    return this.http.get<SimulationStatusModel>(endpoint);
  }

  public getOffers(payload: any): Observable<Offers> {
    const URL = `${environment.apiRootUrl}/shopkeeper-simulation/simulation/offers`;
    return this.http.post<any>(URL, payload);
  }
  public updateOffer(payload: any): Observable<Offers> {
    const URL = `${environment.apiRootUrl}/shopkeeper-simulation/proposal/update-offer`;
    return this.http.put<any>(URL, payload);
  }
  public getAllOffers(proposalId: number): Observable<any> {
    const URL = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/simulation/${proposalId}/allOffers`;
    return this.http.get(URL);
  }

  public validateCoupon(code: string, proposalId: string): Observable<ValidateCouponModel> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-simulation/validate/coupon/${proposalId}/${code}`;
    return this.http.get<ValidateCouponModel>(endpoint);
  }
  public postBestOffer(proposalId: number, payload: any): Observable<any> {
    const URL = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/simulation/${proposalId}/bestOffer`;
    return this.http.post(URL, payload);
  }

  public getEnquadramento(proposalId: any): Observable<any>{
    const URL = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/enquadramento/${proposalId}`;
    return this.http.get<Boolean>(URL);
  }

  public deleteEnquadramento(proposalId: any): Observable<any>{
    const URL = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/enquadramento/register`;
    return this.http.post<any>(URL, proposalId);
  }
  public postSimulation(proposalId: number, payload: any): Observable<any> {
    const URL = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/simulation/${proposalId}`;
    return this.http.post(URL, payload);
  }
  public getNppInstallments(proposalId: number): Observable<any> {
    const URL = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/simulation/${proposalId}/bestOffer/installments`;
    return this.http.get<any>(URL);
  }

  public updateOfferNpp(payload: any): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/simulation/${payload.proposalId}`;
    return this.http.post(endpoint, payload);
  }

  public getOpenFinance(proposalId: string): Observable<Boolean> {
    const URL = `${environment.apiRootUrl}/shopkeeper-structure/1.0.0/openFinance/${proposalId}`;
    return this.http.get<Boolean>(URL).pipe(catchError(() => of(false)));
  }
  public getOpenFinancePreAnalysis(proposalId: string): Observable<any> {
    const URL = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/openFinance/bestOffer/${proposalId}`;
    return this.http.get<Boolean>(URL).pipe(catchError(() => of(false)));
  }

  public getOpenFinanceEnabled(produto: string): Observable<boolean> {
    const URL = `${environment.apiRootUrl}/shopkeeper-structure/1.0.0/enable-open-finance?produto=${produto}`;
    return this.http.get<boolean>(URL).pipe(catchError(() => of(false)));
  }
}
