export enum PaymentButtonEnum {
  SHOW_DETAILS = 'SHOW-DETAILS',
  EMITBLETO = 'EMITBLETO',
  SHOW_DETAILS_KEBAB = 'DETPROP',
  PAYMENT_REQUEST = 'PAYMENT-REQUEST',
  PAYMENT_MULTIPLE_REQUEST = 'PAYMENT-MULTIPLE-REQUEST',
  PROPOSAL_EXTRACT = 'PROPOSAL-EXTRACT',
  PAYMENT_MASSIVE_UPLOAD = 'PAYMENT-MASSIVE-UPLOAD',
  PAYMENT_MASSIVE_CANCEL = 'PAYMENT-MASSIVE-CANCEL',
  PROPOSAL_CANCEL = 'APAGPROP',
  REQUEST_PAYMENT = 'REQUEST-PAYMENT',
  REQUEST_SCHEDULING = 'REQUEST-SCHEDULING',
  PAYMENT_REQUEST_AGAIN = 'REQAGAIN-PAYMENT'
}

export enum PaymentRequestStatusEnum {
  SCHEDULED_PAYMENT = 'SCHEDULED-PAYMENT',
  PAYMENT_REQUEST_SUCCESS = 'PAYMENT-REQUEST-SUCCESS',
  PAYMENT_REQUEST_FAILURE = 'PAYMENT-REQUEST-FAILURE',
  REQUEST_SCHEDULING_SUCCESSFULLY_MADE = 'REQUEST_SCHEDULING_SUCCESSFULLY_MADE'
}

export enum PaymentStatusEnum {
  PAYMENT_PENDING_TARRIF = 'TARIFA PENDENTE',
  PAYMENT_ERROR = 'ERRO DE PAGAMENTO',
  PAYMENT_AVAILABLE = 'PAGAMENTO DISPONÍVEL',
  PAYMENT_IN_RELEASE = 'PAGAMENTO EM LIBERAÇÃO',
  PAYMENT_PROPOSAL_PAID = 'PROPOSTA PAGA',
  PAYMENT_PROPOSAL_CANCEL = 'PROPOSTA CANCELADA'
}

export enum PaymentIconStatusEnum {
  PAYMENT_PENDING_TARRIF = 'ic_barcode.svg',
  PAYMENT_ERROR = 'ic_email_invoice.svg',
  PAYMENT_AVAILABLE = 'ic_email_invoice.svg',
  PAYMENT_IN_RELEASE = 'ic_file.svg',
  PAYMENT_PROPOSAL_PAID = 'ic_file.svg',
  PAYMENT_PROPOSAL_CANCEL = 'ic_file.svg'
}
