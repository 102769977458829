import { Component, OnInit } from '@angular/core';
import { routerTransition } from '../router.animations';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss'],
  animations: [routerTransition()]
})
export class NotFoundComponent implements OnInit {
  skin = environment.pathClientAssets;

  constructor() {}

  ngOnInit() {}
}
