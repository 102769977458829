import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanDeactivate, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { IdentificationContainer } from './identification.container';
import { IdentificationService } from './services/identification.service';

@Injectable()
export class IdentificationGuard implements CanActivate, CanDeactivate<IdentificationContainer> {
  constructor(private identificationService: IdentificationService) {}

  canActivate(): boolean {
    return true;
  }

  canDeactivate(
    component: IdentificationContainer,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    const nextRoute = nextState.url;
    const isFormPristine = component.identificationForm.pristine;
    if (
      isFormPristine ||
      nextRoute === '/open-proposal' ||
      nextRoute.includes('proposal/step-offer') ||
      nextRoute.includes('proposal')
    ) {
      return true;
    }
    return this.identificationService.handleExitIdentification();
  }
}
