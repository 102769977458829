import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'pion-snackbar',
  templateUrl: './pion-snackbar.component.html',
  styleUrls: ['./pion-snackbar.component.scss']
})
export class PionSnackbarComponent implements OnInit {
  /**
   * Input [icon] - string
   * One of mat-icon
   */
  @Input() icon: string;

  /**
   * Input [closable] - boolean
   * Boolean to display or not the close button
   */
  @Input() closable: boolean;

  /**
   * Input [type] - string
   * Type of alert (error/alert/success/info)
   */
  @Input() type: string;

  /**
   * Output [close]
   * Emit when close button clicked
   */
  @Output() close: EventEmitter<any> = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  closeSnackbar() {
    this.close.emit();
  }
}
