export enum InputTypes {
  INPUT_CREDIT = 'financedValue',
  INSTALLMENT = 'numberOfInstallments',
  PAYMENT_PLAN = 'paymentPlan',
  REGISTRATION_FEE = 'registrationFee',
  INSURANCE = 'insuranceId',
  DUE_DATE = 'firstInstallmentDueDate',
  COUPON_CODE = 'couponCode',
  PAYMENT_METHOD = 'paymentMethod',
  INSTALLMENT_INFO_MONTHS = 'installmentInfoMonths',
  INSTALLMENT_INFO_DATE_BLN = 'installmentInfoDateBln',
  INSTALLMENT_INFO_DATE_LACK = 'installmentInfoDateLack',
  ACCOUNT_DATA = 'accountData',
  INSTALLMENT_FLEX_DATA = 'installmentFlexData',
  SIDEBAR_VALUES = 'sidebarValues'
}
