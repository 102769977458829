import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-step',
  templateUrl: './card-step.component.html',
  styleUrls: ['./card-step.component.scss']
})
export class CardStepComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() img: string;
  public imgPlaceholder = 'assets/images/biometry_placeholder.svg';

  constructor() {}

  ngOnInit() {}
}
