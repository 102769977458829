export enum Payment {
  ACCOUNT_DEBIT = 'ACCOUNT_DEBIT',
  BILL = 'BILL',
  FISICO_PER = 'FISICO_PER',
  DIGITAL_PER = 'DIGITAL_PER',
  OTHER_BANKS = 'OTHER_BANKS'
}

export enum PaymentConfirmationData {
  ACCOUNT_DEBIT = 'D',
  BILL = 'B',
  OTHER_BANKS = 'O'
}
