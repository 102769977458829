import { createSelector, createFeatureSelector } from '@ngrx/store';

export const startupSelector = createFeatureSelector<any>('startup');

export const selectUserInfo = createSelector(
  startupSelector,
  state => state.userInfo
);

export const selectTerms = createSelector(
  startupSelector,
  state => state.terms
);
