import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { Router } from '@angular/router';
import { DocumentsService } from '@app/esign/services/documents.service';
import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import { ConfirmationContractResponse } from '@app/proposal-workflow/models/step-register-requests';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { FormalizationProposalDTO } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { PDFDocumentProxy } from 'ng2-pdf-viewer';
import { Subject, fromEvent, merge } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnChanges, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() signatureData: Partial<FormalizationProposalDTO>;
  @Input() isCrossedFlux: boolean;
  @Output() sendSignature: EventEmitter<string | null> = new EventEmitter();
  @ViewChild('pdfViewerContainer') pdfViewer: ElementRef;

  public docData: string;
  public mimeType: string;
  public docName: string;
  public zoom$ = this.documentService.currentZoom;

  public showDoc = false;
  public isSigning = false;
  public isFooterVisible = false;
  public cetTax = '';
  public annualCetTax = '';
  public paymentPlanType = PaymentPlanType;
  public productCodes = ProductCodeEnum;

  private category: string;
  readonly closeAction: Tag = Tag.Fechou_Modal;

  constructor(
    private documentService: DocumentsService,
    private router: Router,
    private simulationService: SimulationService,
    private genTagger: GenTagger,
    private translateService: TranslateService,
    private taggerService: TaggerService
  ) {}


  ngOnChanges(changes: SimpleChanges): void {
    if (this.signatureData && changes.signatureData) {
      if (this.isCrossedFlux) {
        this.category = this.categoryPrefix + `/cliente/contrato-tutorial`;
        this.taggerService.setPageLocation(`/criarproposta${this.signatureData?.product?.code}/formalizacao/cliente/contrato-tutorial`);
      } else {
        this.category = this.categoryPrefix + `/contrato-tutorial`;
        this.taggerService.setPageLocation(`/criarproposta/${this.signatureData?.product?.code}/formalizacao/contrato-tutorial`);
      }
      this.loadContract();
    }
  }

  get categoryPrefix() {
    return `/portallojista/criarproposta/${this.signatureData?.product?.code}`
  }

  private loadContract(): void {
    this.tagPgView('read');
    if (this.isCrossedFlux) {
      this.docData = `data:application/pdf;base64,${this.signatureData.contract.file}`;
      this.mimeType = 'application/pdf';
      this.showDoc = true;
      this.cetTax = this.signatureData.cetValue;
      this.annualCetTax = this.signatureData.annualCetValue;
      return;
    }

    const { id, contract } = this.signatureData;
    this.cetTax = `${this.signatureData.conditionFinanced.returnTaxPercentage}`;
    this.annualCetTax = `${this.signatureData.conditionFinanced.annualReturnTaxPercentage}`;
    if (contract && contract.UUID) {
      this.simulationService
        .getProposalContract(id, contract.UUID)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          filter(res => !!res)
        )
        .subscribe((res: ConfirmationContractResponse) => {
          this.docData = `data:${res.mimeType};base64,${res.data}`;
          this.mimeType = res.mimeType;
          this.docName = res.name;
          this.showDoc = true;
        });
    }
  }

  public afterDocumentLoad(document: PDFDocumentProxy): void {
    this.isFooterVisible = true;
    this.subscribeToPinchZoomEvents();
  }

  private subscribeToPinchZoomEvents(): void {
    const pdfViewerElement = this.pdfViewer.nativeElement;

    const pointerMove$ = fromEvent(pdfViewerElement, 'onpointermove');
    const pointerDown$ = fromEvent(pdfViewerElement, 'onpointerdown');
    const pointerUp$ = fromEvent(pdfViewerElement, 'onpointerup');
    const pointerCancel$ = fromEvent(pdfViewerElement, 'onpointercancel');
    const pointerOut$ = fromEvent(pdfViewerElement, 'onpointerout');
    const pointerLeave$ = fromEvent(pdfViewerElement, 'onpointerleave');

    pointerMove$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event: PointerEvent) => this.documentService.handlePointerMove(event));

    pointerDown$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event: PointerEvent) => this.documentService.handlePointerDown(event));

    merge(pointerUp$, pointerCancel$, pointerOut$, pointerLeave$)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((event: PointerEvent) => this.documentService.handlePointerUp(event));
  }

  public downloadFile(): void {
    if (!this.docData) return;
    const downloadLink = document.createElement('a');
    const fileName = this.docName || 'Contrato.pdf';
    downloadLink.href = this.docData;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  public signContract(): void {
    this.isSigning = true;
    this.tagPgView('sign');
  }

  public backToContract(): void {
    this.isSigning = false;
    this.tagPgView('read');
  }

  public saveSignature(signature: string): void {
    this.sendSignature.emit(signature);
  }

  public goBack(): void {
    this.router.navigate([this.isCrossedFlux ? '/login' : '/showcase']);
  }

  public exitSignature(): void {
    this.sendSignature.emit(null);
  }

  private tagPgView(type: string) {
    let page_location;
    let event_label;
    if (type === 'sign') {
      event_label = this.translateService.instant('ESIGN-CONTRACT-SIGNATURE');
      page_location = this.categoryPrefix + '/contrato-assinatura';
      if (this.isCrossedFlux) {
          page_location = this.categoryPrefix + '/cliente/contrato-assinatura';
      }
    } else {
      page_location = this.categoryPrefix + '/contrato-leitura';
      event_label = this.translateService.instant('Contrato')
      if (this.isCrossedFlux) {
          page_location = this.categoryPrefix + '/cliente/contrato-leitura';
      }
    }

    this.taggerService.setPageLocation(page_location);

    this.genTagger.setTag({
      event_category: page_location,
      event_label: event_label,
      event_action: Tag.pgView
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
