<section id="partners-list-section" *ngIf="registeredPartners?.reverse()">
  <!-- PARTNERS LIST -->
  <ng-container *ngFor="let partner of registeredPartners.reverse(); let i = index">
    <div class="row px-3">
      <div class="col-12 col-md-12 p-0 mt-2 mb-4">
        <strong>{{ i + 1 }}{{ 'PENDENCY-BIPARTITE-PARTNER' | translate }}</strong>
      </div>

      <div class="nf-card col-12 col-md-12 mb-4">
        <button
          (click)="deletePartner(partner?.id)"
          class="float-right"
          mat-button
          appTagger
          [event_category]="categoria"
          event_label="excluir participante"
        >
          <img src="./assets/images/ic_close.svg" alt="" />
        </button>
        <div class="row mt-5">
          <div class=" col-12 col-md-6" [ngClass]="bipartiteType === bipartiteTypeEnum.CUSTOM ? 'mb-4' : ''">
            <p class="nf-label">{{ 'AGREEMENT' | translate }}</p>
            <p class="nf-value">{{ partner?.dsAcordoComercial }}</p>
          </div>

          <div class=" col-12 col-md-6" [ngClass]="bipartiteType === bipartiteTypeEnum.CUSTOM ? 'mb-4' : ''">
            <p class="nf-label">{{ 'PENDENCY-BIPARTITE-PARTNER-NAME-LABEL' | translate }}</p>
            <p class="nf-value">{{ partner?.partnerDescription }}</p>
          </div>

          <div class="col-12 col-md-6" [ngClass]="bipartiteType === bipartiteTypeEnum.CUSTOM ? 'mb-4' : ''">
            <p class="nf-label">{{ 'PENDENCY-BIPARTITE-PARTNER-DOCUMENT-LABEL' | translate }}</p>
            <p class="nf-value">{{ partner?.partnerDocumentCode | mask: '00.000.000/0000-00' }}</p>
          </div>

          <div class="col-12 col-md-6" *ngIf="bipartiteType === bipartiteTypeEnum.CUSTOM">
            <p class="nf-label">{{ 'PENDENCY-BIPARTITE-NF-VALUE-LABEL' | translate }}</p>
            <p class="nf-value">{{ partner?.partnerValue | localeCurrency }}</p>
          </div>
        </div>
        <ng-container *ngIf="partner?.checklist">
          <app-checklist-item
            [isPendency]="true"
            [checklistItem]="partner?.checklist"
            [maxSizeAllowed]="maxSizeAllowed"
            (uploadFinished)="handleUploadFinished($event)"
          ></app-checklist-item>
        </ng-container>
      </div>
    </div>
  </ng-container>
</section>

<!-- ADD PARTNER -->
<section id="partners-section" *ngIf="showPartnersForm" [formGroup]="form">
  <div class="row px-3">
    <div class="col-12 col-md-12 mb-4">
      <strong>{{ registeredPartners?.length + 1 }}{{ 'PENDENCY-BIPARTITE-PARTNER' | translate }}</strong>
    </div>

    <div class="nf-card col-12 col-md-12 mb-4">
      <button
        (click)="buttonClose()"
        class="float-right"
        mat-button
        appTagger
        [event_category]="categoria"
        event_label="excluir participante"
      >
        <img src="./assets/images/ic_close.svg" alt="" />
      </button>
      <div class="row mt-5">
        <div class="col-12 col-md-6 mb-4">
          <mat-form-field *ngIf="acordosComerciais as BipartitePartnerDTO">
            <mat-label> {{ 'AGREEMENT' | translate }}</mat-label>
            <app-search-input
              valueFrom="idAcordoComercial"
              label="dsAcordoComercial"
              (changeOut)="setFieldsByDsAcordo(form.getRawValue('dsAcordoComercial'))"
              (ngControlValue)="checksIfTheValueIsEmpty($event)"
              [items]="BipartitePartnerDTO | orderBy: 'dsAcordoComercial'"
              formControlName="idAcordoComercial"
            >
            </app-search-input>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 mb-4">
          <mat-form-field *ngIf="proposalPartners as BipartitePartnerDTO">
            <mat-label> {{ 'PENDENCY-BIPARTITE-PARTNER-NAME-LABEL' | translate }}</mat-label>
            <app-search-input
              valueFrom="idWithAcordo"
              label="partnerDescription"
              (changeOut)="setDropdownVariables(form.getRawValue('partnerDescription'))"
              (ngControlValue)="checksIfTheValueIsEmpty($event)"
              [items]="BipartitePartnerDTO | orderBy: 'partnerDescription'"
              formControlName="supplierChannelId"
            >
            </app-search-input>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 mb-4">
          <mat-form-field *ngIf="proposalPartners as BipartitePartnerDTO">
            <mat-label> {{ 'PENDENCY-BIPARTITE-PARTNER-DOCUMENT-LABEL' | translate }}</mat-label>
            <app-search-input
              valueFrom="idWithAcordo"
              label="partnerDocumentCode"
              (changeOut)="setDropdownVariables(form.getRawValue('partnerDocumentCode'))"
              (ngControlValue)="checksIfTheValueIsEmpty($event)"
              mask="{{ $any(pioneerMasks).documentNumber.companyMask }}"
              [items]="BipartitePartnerDTO | orderBy: 'partnerDocumentCode'"
              formControlName="documentNumber"
            >
            </app-search-input>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-6 mb-4" *ngIf="bipartiteType === bipartiteTypeEnum.CUSTOM">
          <mat-form-field>
            <mat-label> {{ 'PENDENCY-BIPARTITE-NF-VALUE-LABEL' | translate }} </mat-label>
            <input
              class="nf-value"
              currencyMask
              matInput
              [options]="$any(pioneerMasks).entryValue.options"
              maxlength="{{ $any(pioneerMasks).income.maxLength }}"
              formControlName="value"
              required
              appTagger
              [event_category]="categoria"
              event_label="valor da nf"
              [event_action]="Insert"
              autocomplete="off"
            />

            <img
              [hidden]="isValueValid"
              src="./assets/atention-red.svg"
              alt="icon right red"
              class="invalid-icon align-icon"
              matSuffix
            />
          </mat-form-field>
          <span class="alertRed" *ngIf="!isValueValid && !isValidMinPercentage">
            {{ 'INFORMED-VALUE-IS-INCOMPATIBLE-WITH-INTERNAL CRITERIA. PLEASE INCLUDE AN AMOUNT FROM' | translate
            }}{{ validMinValue | currency: 'BRL' }}
          </span>
          <span class="alertRed" *ngIf="!isValueValid && !isValidMaxPercentage">
            {{ 'VALUE INFORMED IS INCOMPATIBLE WITH THE INTERNAL RESPONSIBLE. PLEASE INCLUDE A VALUE BELOW' | translate
            }}{{ validMaxValue | currency: 'BRL' }}
          </span>
          <span class="alertRed" *ngIf="!isValueValid && valueOverFinanced">
            {{ 'VALUE-OVER-FINANCED' | translate }}{{ saldoDisponivel | currency: 'BRL' }}
          </span>
        </div>
      </div>
      <div class="col-12 col-md-6 mb-4"></div>
    </div>
  </div>
</section>

<div class="mb-3" *ngIf="!!canAddBipartite">
  <app-outlined-button
    (click)="addPartner()"
    [disabled]="maxPartners <= 0 || saldoDisponivel <= 0 || showPartnersForm"
    appTagger
    [event_category]="categoria"
    event_label="Adicionar participante"
  >
    <ng-container
      *ngIf="maxPartners > 0 && saldoDisponivel > 0 && (!showPartnersForm || form.valid); else disabledImage"
    >
      <img class="mr-2" src="./assets/images/ic_circle_account_user_add.svg" alt="" />
    </ng-container>
    <ng-template #disabledImage>
      <img class="mr-2 muted-img" src="./assets/images/ic_circle_account_user_add_mutted.svg" alt="" />
    </ng-template>
    {{ 'PENDENCY-BIPARTITE-ADD-PARTNER-BUTTON' | translate }}
  </app-outlined-button>
  <p class="nf-label mt-3" *ngIf="maxPartners > 1">
    {{ 'PENDENCY-BIPARTITE-ADD-PARTNER-MESSAGE' | translate: { maxPartnersQty: maxPartners } }}
  </p>
  <p class="nf-label mt-3" *ngIf="maxPartners === 1">
    {{ 'PENDENCY-BIPARTITE-ADD-ONE-PARTNER-MESSAGE' | translate: { maxPartnersQty: maxPartners } }}
  </p>
</div>
