import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentsService } from '@app/showcase/payments/services/payments.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';

@Component({
  selector: 'app-proposal-extract-dialog',
  templateUrl: './proposal-extract-dialog.component.html',
  styleUrls: ['./proposal-extract-dialog.component.scss']
})
export class ProposalExtractDialogComponent implements OnInit {
  readonly category = '/portallojista/pagamentos';

  constructor(
    public dialogRef: MatDialogRef<ProposalExtractDialogComponent>,
    private _paymentsService: PaymentsService,
    private genTagger: GenTagger,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit() {
    this.downloadFile();
    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.pgView,
      event_label: 'Painel de Pagamento - Modal Extrair propostas'
    });
  }

  public downloadFile() {
    this._paymentsService.downloadMultipleProposal(this.data.shopId, this.data.filter).subscribe(response => {
      const fileName = response.headers
        .get('content-disposition')
        .split(';')[1]
        .split('=')[1]
        .replace(/\"/g, '');
      const newBlob = new Blob([response.body], { type: 'text/csv' });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(newBlob, fileName);
      } else {
        const link = document.createElement('a');
        if (link.download !== undefined) {
          const url = URL.createObjectURL(newBlob);
          link.setAttribute('href', url);
          link.setAttribute('download', fileName);
          link.style.visibility = 'hidden';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    });
  }
}
