import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { SignUpService } from '../services/sign-up.service';
import { SignUpState } from './signup.reducer';

@Injectable()
export class SignUpEffects {
  constructor(
    private actions$: Actions,
    private signUpService: SignUpService,
    private store$: Store<SignUpState>,
    private router: Router
  ) {}

  // @Effect()
  // getSignUpRenderDataAction = this.actions$.pipe(
  //   ofType<actions.GetSignUpRenderDataAction>(actions.SignUpActionTypes.GET_SIGN_UP_RENDER_DATA),
  //   switchMap(action =>
  //     this.signUpService.getSignUpRenderData().pipe(
  //       map(renderData => new actions.GetSignUpRenderDataSuccessAction(renderData)),
  //       catchError(err => of(new actions.GetSignUpRenderDataErrorAction(err)))
  //     )
  //   )
  // );

  // @Effect()
  // getAfipDataAction = this.actions$.pipe(
  //   ofType<actions.GetAfipDataAction>(actions.SignUpActionTypes.GET_AFIP_DATA),
  //   switchMap(action =>
  //     this.signUpService.getAfipData(action.payload.documentNumber, action.payload.gender).pipe(
  //       map(afipResponse => new actions.GetAfipDataSuccessAction(afipResponse)),
  //       catchError(err => of(new actions.GetAfipDataErrorAction(err)))
  //     )
  //   )
  // );

  // @Effect()
  // submitUserDataAction = this.actions$.pipe(
  //   ofType<actions.SubmitUserDataAction>(actions.SignUpActionTypes.SUBMIT_USER_DATA),
  //   switchMap(action =>
  //     this.signUpService.postUserData(action.payload).pipe(
  //       map(response => new actions.SubmitUserDataSuccessAction(response)),
  //       catchError(err => of(new actions.SubmitUserDataErrorAction(err)))
  //     )
  //   )
  // );

  // @Effect({ dispatch: false })
  // submitUserDataSucessAction = this.actions$.pipe(
  //   ofType<actions.SubmitUserDataSuccessAction>(actions.SignUpActionTypes.SUBMIT_USER_DATA_SUCCESS),
  //   map(action => this.router.navigate(['/signup/confirmation-token']))
  // );

  // @Effect()
  // submitConfirmationTokenAction = this.actions$.pipe(
  //   ofType<actions.SubmitConfirmationTokenAction>(actions.SignUpActionTypes.SUBMIT_CONFIRMATION_TOKEN),
  //   withLatestFrom(this.store$.select(selectSignUpId)),
  //   switchMap(([action, signUpId]) =>
  //     this.signUpService.postConfirmationToken(action.payload, signUpId).pipe(
  //       map(response =>
  //         response.status === 'APPROVED'
  //           ? new actions.SubmitConfirmationTokenSuccessAction()
  //           : new actions.SubmitConfirmationTokenErrorAction()
  //       ),
  //       catchError(err => of(new actions.SubmitConfirmationTokenErrorAction()))
  //     )
  //   )
  // );

  // @Effect()
  // refreshConfirmationTokenAction = this.actions$.pipe(
  //   ofType<actions.RefreshConfirmationTokenAction>(actions.SignUpActionTypes.REFRESH_CONFIRMATION_TOKEN),
  //   withLatestFrom(this.store$.select(selectSignUpId)),
  //   switchMap(([action, signUpId]) =>
  //     this.signUpService.refreshConfirmationToken(signUpId).pipe(
  //       map(response => new actions.RefreshConfirmationTokenSuccessAction(response)),
  //       catchError(err => of(new actions.RefreshConfirmationTokenErrorAction(err)))
  //     )
  //   )
  // );

  // @Effect()
  // submitNewPasswordAction = this.actions$.pipe(
  //   ofType<actions.SubmitNewPasswordAction>(actions.SignUpActionTypes.SUBMIT_NEW_PASSWORD),
  //   withLatestFrom(this.store$.select(selectSignUpId)),
  //   switchMap(([action, signUpId]) =>
  //     this.signUpService.postNewPassword(action.payload, signUpId).pipe(
  //       map(response =>
  //         response.created
  //           ? new actions.SubmitNewPasswordSuccessAction()
  //           : new actions.SubmitNewPasswordErrorAction(response)
  //       ),
  //       catchError(err => of(new actions.SubmitNewPasswordErrorAction(err)))
  //     )
  //   )
  // );
}
