import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { ConfirmationContractResponse } from '@app/proposal-workflow/models/step-register-requests';
import { SummaryModel, SummaryStageModel } from '@app/proposal-workflow/models/summary.model';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export enum SummaryEnum {
  BLANK = 0,
  GREEN = 3,
  YELLOW = 1
}

@Component({
  selector: 'app-pendency-success',
  templateUrl: './pendency-success.component.html',
  styleUrls: ['./pendency-success.component.scss']
})
export class PendencySuccessComponent implements OnChanges, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() proposal: ConfirmationData;
  @Input() summaryData: SummaryModel;
  @Input() summaryStatus: SummaryStageModel;
  @Input() bpoPendencyChanged: boolean;
  @Input() proposalPendencyBiometryFlowEnabled: boolean;

  @Output() leaveEmitter: EventEmitter<void> = new EventEmitter();
  @Output() goToProposal: EventEmitter<void> = new EventEmitter();

  public summaryResult = SummaryEnum;
  public productCodes = ProductCodeEnum;
  public showSuccessMessage = true;
  public proposalFlag: SummaryEnum;
  public proposalStageText: string;

  constructor(private simulationService: SimulationService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.summaryStatus && changes.summaryStatus) {
      this.proposalStageText = this.summaryStatus.proposalStatus.vendorStatus;
      this.proposalFlag = this.getProposalStatus();
    }

    if (changes.summaryData && this.summaryData && this.summaryData[0]) {
      this.summaryData = this.summaryData[0];
    }
  }

  public downloadContract(): void {
    if (!this.proposal.proposalData || !this.proposal.proposalData.id || !this.proposal.contract.UUID) return;

    this.simulationService
      .getProposalContract(this.proposal.proposalData.id, this.proposal.contract.UUID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: ConfirmationContractResponse) => {
        const linkSource = `data:${res.mimeType};base64,${res.data}`;
        const downloadLink = document.createElement('a');
        const fileName = `${res.name}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  }

  public handleLeave(): void {
    this.leaveEmitter.emit();
  }

  public getProposalStatus(): SummaryEnum {
    if (!this.summaryStatus.groups) return;
    return this.summaryStatus.groups.some(({ semaphore }) => semaphore.value !== SummaryEnum.GREEN)
      ? SummaryEnum.YELLOW
      : SummaryEnum.GREEN;
  }

  public closeSuccessMessage(): void {
    this.showSuccessMessage = false;
  }

  public handleGoToProposal(): void {
    this.goToProposal.emit();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
