import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-outlined-button',
  templateUrl: './outlined-button.component.html',
  styleUrls: ['./outlined-button.component.scss']
})
export class OutlinedButtonComponent {
  @Input() disabled;
  @Output() clickEmitter = new EventEmitter();
}
