export enum DocumentSidePeruSDK {
  FRONT = 'front',
  BACK = 'back'
}

export enum DocumentTypePeruSDK {
  CH1 = 'CHL1', // Documento de identidad chileno antiguo
  CH2 = 'CHL2', // Documento de identidad chileno nuevo
  PE1 = 'PER1', // Documento de identidad peruano antiguo
  PE2 = 'PER2', // Documento de identidad peruano nuevo
  PE3 = 'PER3', // Documento de EXTRANJERIA PERUANA
  CO1 = 'COL1', // Documento de identidad colombiano
  AR1 = 'ARG1', // Documento de identidad argentino antiguo
  AR2 = 'ARG2' // Documento de identidad argentino nuevo
}
