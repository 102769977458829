import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recovery-data-modal',
  templateUrl: './recovery-data-modal.component.html',
  styleUrls: ['./recovery-data-modal.component.scss']
})
export class RecoveryDataModalComponent implements OnInit {
  public title = 'STEP-REGISTER-RECOVERY-MODAL-TITLE-PF';
  public fechouModal = Tag.Fechou_Modal;
  public abriuModal = Tag.AbriuModal;

  constructor(
    public dialogRef: MatDialogRef<RecoveryDataModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { personType: SubmitPersonType; event_category: string },
    private genTagger: GenTagger,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    if (!!this.data && this.data.personType === SubmitPersonType.PJ) {
      this.title = 'STEP-REGISTER-RECOVERY-MODAL-TITLE-PJ';
    }

    if (!!this.data && this.data.event_category) {
      this.genTagger.setTag({
        event_category: this.data.event_category,
        event_action: `${this.abriuModal} - ${this.translate.instant(this.title)}`,
        event_label: this.translate.instant('STEP-REGISTER-RECOVERY-MODAL-SUBTITLE')
      });
    }
  }

  registerClick(label: string): void {
    if (!!this.data && this.data.event_category) {
      this.genTagger.setTag({
        event_category: this.data.event_category,
        event_action: `${this.fechouModal} - ${this.translate.instant(this.title)}`,
        event_label: label
      });
    }
  }
}
