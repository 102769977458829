<div class="d-sm-none">
  <div class="mobile">
    <div class="body container d-sm-none">
      <!-- Propostas em aberto -->
      <div class="row">
        <div style="margin-left: 7%;" class="col">
          <h2 style="font-weight: 400;">Ofertas Personalizadas</h2>
        </div>
      </div>

      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let card of ofertaPersonalizada | slice: 0:2">
          <div class="card shadow card-with-steper">
            <div class="card-body">
              <div class="row">
                <div class="col align-self-start">
                  <p class="card-title specialOffer">{{ card.title }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col align-self-start">
                  <h3 style="font-size: 24px; font-weight: 500; margin-bottom: 20px;">
                    {{ card.parcel }}x R${{ card.price }}
                  </h3>
                </div>
              </div>
              <div class="row">
                <div class="col align-self-start">
                  <p class="card-text" style="font-weight: 500;">Total de R$ {{ card.totalPrice }}</p>
                </div>
              </div>
              <div class="row">
                <div class="col align-self-start" style="font-weight: 500;">
                  <p>Taxa {{ card.rate }}% ao mes</p>
                </div>
              </div>
              <div class="row">
                <div style="padding-bottom: 10px;" class="col align-self-start">
                  <small>
                    *Sujeito a análise de crédito
                  </small>
                </div>
              </div>
              <div class="row">
                <div class="col align-self-start">
                  <button (click)="chamadaPreAnalise()" type="button" class="btn btn-outline-danger">Simular</button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

        <ng-template carouselSlide *ngIf="ofertaPersonalizada.length > 2">
          <div class="card shadow card-with-steper">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <img class="aro-propAberto" src="./assets/Line.svg" alt="" />
                  <div class="iconSpecialOfferQrCode">
                    <img class="paper-icon-propAberto" src="./assets/ic_paper_stack.svg" />
                  </div>
                </div>
              </div>
              <div class="row" style="margin-bottom: 5px;">
                <div class="col">
                  <p style="font-size: 18px; font-weight: 500;">
                    Confira mais ofertas personalizadas
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col align-self-start">
                  <button type="button" class="btn btn-outline-danger">Acessar</button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>

      <!-- Compras parcelas-->
      <div class="row">
        <div class="card shadow">
          <div class="card-body">
            <div class="row">
              <div class="col align-self-start">
                <p class="card-title specialOffer">Compras Parceladas</p>
              </div>
            </div>
            <div class="row">
              <div style="margin-bottom: 15px;" class="col align-self-start">
                <p class="card-text" style="font-weight: 500;">
                  Faça compras em lojas de diversos segmentos com condições especiais.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <button
                  style="margin-bottom: 0px!important;"
                  (click)="chamadaPreAnalise()"
                  type="button"
                  class="btn btn-outline-danger"
                >
                  Simular
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 80%;" class="mx-auto">
        <mat-divider></mat-divider>
      </div>
      <!-- <div class="line"></div> -->

      <!--OFERTAS ESPECIAIS-->
      <div style="margin-left: 7%;" class="row">
        <h2 class="specialOffer" style="margin-top: 9%; font-weight: 400;">Ofertas Especiais</h2>
      </div>
      <owl-carousel-o [options]="customOptions">
        <ng-template carouselSlide *ngFor="let card of ofertaEspecial | slice: 0:2">
          <div class="card shadow card-with-steper" style="padding: 0px;">
            <img
              src="./assets/flag-card.svg"
              alt=""
              style="width: 50px; position: absolute; left: 231px; bottom: 207px;"
            />
            <div class="card-body">
              <div class="row" style="margin-bottom: 9%;">
                <div class="col-5 align-self-center">
                  <div
                    class="iconSpecialOffer"
                    style="background-color: #d00000; height: 70px; border-radius: 50%; width: 74px; margin-left: 96px;"
                  ></div>
                </div>
              </div>
              <div class="row">
                <div class="col align-self-center">
                  <h3 class="specialOffer" style="text-align: center;">
                    {{ card.title }}
                  </h3>
                </div>
              </div>
              <mat-divider class="mt-2 mb-2"></mat-divider>
              <!-- <div class="row">
                <div class="line" style="margin-bottom: 9%; margin-left: 12%;"></div>
              </div> -->
              <p class="card-text" style="font-weight: 500; margin-bottom: 0px;">
                {{ card.text }}
              </p>
            </div>
            <div
              (click)="chamadaPreAnalise()"
              style=" background-color: #cc0000 !important; height: 50px; width: 100%; border-bottom-right-radius: 6px; border-bottom-left-radius: 6px;"
            >
              <div class="row">
                <div class="col" style="color: white; color: white;position: relative; top: 16px; right: -12px;">
                  Simular
                </div>
                <div class="col" style="color: white; ">
                  <img
                    src="./assets/arrow-card.svg"
                    alt=""
                    style="width: 34px; position: relative; left: 69px; top: 17px;"
                  />
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>

      <div class="row">
        <div class="card shadow specialCard">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <img class="aro" src="./assets/Line.svg" alt="" />
                <div class="iconSpecialOfferQrCode">
                  <img class="qrCode-icon" src="./assets/images/ic_qrcode.svg" />
                </div>
              </div>
              <div class="col-7">
                <div class="row">
                  <p style="font-weight: 700">
                    Ler codio da loja
                  </p>
                </div>
                <div class="row subText">
                  Leia o QR Code da loja para simular sea compra parcelada.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-none d-md-block">
  <app-header-desktop></app-header-desktop>
  <div class="desktop">
    <div class=" body container d-none d-md-block">
      <div class="row">
        <div class="col" class="breadcrumbs">
          <small>
            Home
          </small>
        </div>
      </div>

      <!-- Ofertas Personalizadas-->
      <div class="row">
        <span>Ofertas Personalizadas</span>
      </div>
      <owl-carousel-o [options]="customOptionsDesktopPersonalOffer">
        <ng-template carouselSlide *ngFor="let card of ofertaPersonalizada">
          <div class="card shadow card-with-steper">
            <div class="card-body">
              <div class="row" style="margin-bottom: 35px;">
                <div class="col" style="margin-left: 18px;">
                  <div class="row">
                    <h3 style="font-weight: bold; margin-bottom: 19px;">
                      Empréstimo pessoal
                    </h3>
                  </div>
                  <div class="row price">{{ card.parcel }}x</div>
                  <div class="row price">R$ {{ card.price }}</div>
                </div>
                <div class="col align-self-end">
                  <div class="row">Total de R$ {{ card.totalPrice }}*</div>
                  <div class="row">Taxa {{ card.rate }}% ao mes</div>
                  <div class="row">
                    <small>
                      * Sujeito a análise de crédito
                    </small>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <button (click)="chamadaPreAnalise()" type="button" class="btn btn-outline-danger">Simular</button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>

      <!-- Compras parcelas-->
      <div class="row card-parcel-payment">
        <div class="col">
          <div class="card-body">
            <div class="row">
              <div class="col align-self-start">
                <p class="card-title specialOffer">Compras Parceladas</p>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <p class="card-text">
                  Faça compras em lojas de diversos segmentos com condições especiais.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-4 align-self-center">
          <button (click)="chamadaPreAnalise()" type="button" class="btn btn-outline-danger button-parcel-payment">
            Simular
          </button>
        </div>
      </div>
      <!--OFERTAS ESPECIAIS-->
      <div class="row">
        <span style="margin-top: 3%">Ofertas Especiais</span>
      </div>
      <owl-carousel-o [options]="customOptionsDesktopPersonalOffer">
        <ng-template carouselSlide *ngFor="let card of ofertaEspecial">
          <div class="card shadow card-carousel" style="padding: 0px;">
            <img
              src="./assets/flag-card.svg"
              alt=""
              style="width: 50px; position: absolute; left: 400px; bottom: 200px;"
            />
            <div class="card-body">
              <div class="row" style="margin-bottom: 9%;">
                <div class="col-5 align-self-center">
                  <div class="iconSpecialOffer"></div>
                </div>
              </div>
              <div class="row">
                <div class="col align-self-center">
                  <h3 class="specialOffer">
                    {{ card.title }}
                  </h3>
                </div>
              </div>
              <mat-divider></mat-divider>
              <p class="card-text" style="font-weight: 500; margin-bottom: 0px;">
                {{ card.text }}
              </p>
            </div>
            <div (click)="chamadaPreAnalise()" class="button-offer">
              <div class="row">
                <div class="col" style="color: white; color: white;position: relative; top: 16px; right: -12px;">
                  Simular
                </div>
                <div class="col" style="color: white; ">
                  <img
                    src="./assets/arrow-card.svg"
                    alt=""
                    style="width: 34px; position: relative; left: 170px; top: 17px;"
                  />
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </owl-carousel-o>
    </div>
  </div>
</div>
