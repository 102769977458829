import gql from 'graphql-tag';

export const INIT_USER_QUERY = gql`
  query initRenderQuery($channelAccessCode: String, $supplierChannelCode: String) {
    initRenderQuery(channelAccessCode: $channelAccessCode, supplierChannelCode: $supplierChannelCode) {
      uiConfig {
        menus {
          code
          subMenus {
            code
            subMenus {
              code
            }
          }
        }
      }
      userInfo {
        preferences {
          homePage
          initialScreen
        }
        profile {
          id
          name
          documentNumber
          phoneInternationalCode
          phoneAreaCode
          phoneNumber
          email
          role
          documentType
          userProfiles
          codeProfiles
          ##nickname ##aguardando back
        }
      }
    }
  }
`;

export const CONFIG_PROFILE_QUERY = gql`
  query configProfileQuery($profile: [String]) {
    configProfiles(profile: $profile) {
      code
      value
    }
  }
`;
