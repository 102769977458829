import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Renderer2,
  Self,
  ViewChild,
  ViewEncapsulation,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { MatMenuTrigger } from '@angular/material/menu';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-date-range-picker-dialog',
  templateUrl: './date-range-picker-dialog.component.html',
  styleUrls: ['./date-range-picker-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,

  providers: [{ provide: MatFormFieldControl, useExisting: DateRangePickerDialogComponent }]
})
export class DateRangePickerDialogComponent
  implements OnInit, ControlValueAccessor, AfterViewInit, MatFormFieldControl<any[]>, OnDestroy {
  static nextId = 0;
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() src;
  public currentTab: string;

  periods: any;
  public periodo: any;
  control = new FormControl(Validators.required);

  private _required = false;
  private _placeholder = 'DD/MM/AAAA';
  private _disabled = false;

  from = new FormControl();
  to = new FormControl();
  _dateFrom: number;
  _dateTo: number;
  _value: any;
  dateOver: any;
  minDate: Date;
  maxDate: Date;
  cells: any[];
  separator: string;
  order: number[] = [];
  controlType = 'date-picker-range';
  id = `date-picker-range-${DateRangePickerDialogComponent.nextId++}`;
  describedBy = '';

  stateChanges = new Subject<void>();
  focused = false;

  onChange = (_: any) => {};
  onTouched = () => {};

  constructor(
    public activeModal: NgbActiveModal,
    private renderer: Renderer2,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private cdRef: ChangeDetectorRef,
    @Optional() @Self() public ngControl: NgControl
  ) {
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  ngOnInit() {
    this.separator = this._placeholder.replace(/[AMD]/g, '').substr(0, 1);
    const parts = this._placeholder.replace(/[.\/]/g, '-').split('-');
    this.order[0] = parts.indexOf('AAAA');
    this.order[1] = parts.indexOf('MM');
    this.order[2] = parts.indexOf('DD');
    if(this.currentTab === 'Saldo Futuro'){
      const today = new Date();
      const currentYear = new Date().getFullYear();
      if(today){
        this.minDate = today;
        this.maxDate = new Date(currentYear + 2, 2, 0);
      }
    }else{
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 0, 0, 1);
    this.maxDate = new Date(currentYear + 2, 2, 0);
    }

    if (!this.from.value && this._dateFrom) {
      this.dateFrom = this.parse(this.format(new Date(this._dateFrom)));
    }
    if (!this.to.value && this._dateTo) {
      this.dateTo = this.parse(this.format(new Date(this._dateTo)));
    }

    this.from.valueChanges.subscribe(res => {
      const date = this.parse(res);
      this._dateFrom = date ? date.getTime() : 0;
      this.value = { from: this.dateFrom, to: this.dateTo };
    });
    this.to.valueChanges.subscribe(res => {
      const date = this.parse(res);
      this._dateTo = date ? date.getTime() : 0;
      this.value = { from: this.dateFrom, to: this.dateTo };
    });
  }

  public onSubmit() {
    // FAZER UMA VALIDAÇÃO NA DATA ANTES DE RETORNAR
    if (this.dateFrom && this.dateTo) {
      const dataSelecionadas = {
        dataInical: this.from.value,
        dataFinal: this.to.value
      };
      // this.trigger.closeMenu();
      this.activeModal.close(dataSelecionadas);
    }
  }
  public isDateValid(): boolean {
    if (this.currentTab === 'Saldo Futuro') {
      const moment = require('moment');
      const dataInputFrom = moment(this.from.value, "DD/MM/YYYY").toDate();
      const dataInputTo  = moment(this.to.value, "DD/MM/YYYY").toDate();
     if (dataInputTo > dataInputFrom) {
        return false;
      } else {
        return true;
      }
    }
  }
  public onDismiss() {
    this.from.setValue(null);
    this.to.setValue(null);
    // this.trigger.openMenu();
  }

  get dateFrom() {
    return this._dateFrom ? new Date(this._dateFrom) : null;
  }
  set dateFrom(value) {
    this._dateFrom = value ? value.getTime() : 0;
    this.from.setValue(this.format(value));
    this.value = { from: this.dateFrom, to: this.dateTo };
  }
  get dateTo() {
    return this._dateTo ? new Date(this._dateTo) : null;
  }
  set dateTo(value) {
    this._dateTo = value ? value.getTime() : 0;
    this.to.setValue(this.format(value));
    this.value = { from: this.dateFrom, to: this.dateTo };
  }

  get errorState() {
    return this.ngControl ? this.ngControl.invalid && this.ngControl.touched : false;
  }
  get empty() {
    return !this.from.value && !this.to.value;
  }
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get placeholder(): string {
    return this._placeholder;
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }

  get required(): boolean {
    return this._required;
  }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }

  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
    if (this._disabled) {
      this.from.disable();
      this.to.disable();
    } else {
      this.from.enable();
      this.to.enable();
    }
    this.stateChanges.next();
  }

  get value(): any | null {
    return this._value;
  }
  set value(value: any | null) {
    this._value = value;
    this.onChange(value);
    this.stateChanges.next();
  }

  setClass() {
    return (date: any) => {
      if (date.getDate() === 1) {
        this.setCells();
      }
      const time = new Date(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()).getTime();
      let classCss = '';
      if (time >= this._dateFrom && time <= this._dateTo) {
        classCss = 'inside';
        if (time === this._dateFrom) {
          classCss += ' from';
        }

        if (time === this._dateTo) {
          classCss += ' to';
        }
      }
      return classCss;
    };
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  setDescribedByIds(ids: string[]) {
    this.describedBy = ids.join(' ');
  }

  /* ngAfterContentChecked() {
    this.cd.detectChanges();
  } */

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      // tslint:disable-next-line: no-non-null-assertion
      this._elementRef.nativeElement.querySelector('input')!.focus();
    }
  }
  writeValue(value: any | null): void {
    this._dateFrom = value ? value.from : null;
    this._dateTo = value ? value.to : null;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  change(value, key) {
    this.onTouched();
  }

  select(date: any) {
    date = new Date(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate());
    if (!this.from.value || (this.from.value && this.to.value) || this._dateFrom > date.getTime()) {
      this.dateFrom = date;
      this.dateTo = null;
      this.redrawCells(date.getTime());
    } else {
      this.dateTo = date;
    }
  }
  format(date: any) {
    if (!date) return null;
    const parts = [
      '' + date.getFullYear(),
      ('00' + (date.getMonth() + 1)).slice(-2),
      ('00' + date.getDate()).slice(-2)
    ];
    return parts[this.order[0]] + this.separator + parts[this.order[1]] + this.separator + parts[this.order[2]];
  }
  parse(value: string) {
    const parts = value ? value.replace(/[.\/]/g, '-').split('-') : [];
    const date: any =
      parts.length === 3
        ? new Date(parts[this.order[0]] + '-' + parts[this.order[1]] + '-' + parts[this.order[2]])
        : null;
    return date && date.getTime && date.getTime() ? date : null;
  }
  setCells() {
    setTimeout(() => {
      if (this.cells) {
        this.cells.forEach(x => {
          x.listen();
        });
      }
      this.dateOver = null;
      const elements = document.querySelectorAll('.calendar');
      if (!elements || elements.length === 0) {
        return;
      }
      const cellsElement = elements[0].querySelectorAll('.mat-calendar-body-cell');
      this.cells = [];
      cellsElement.forEach((x, index) => {
        const date = this.convertFromString(x.getAttribute('aria-label'));
        const time = new Date(date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()).getTime();
        this.cells.push({
          date: time,
          element: x,
          change: time >= this._dateFrom && time <= this._dateTo
        });
      });
      this.cells.forEach(x => {
        if (!x.listen) {
          x.listen = this.renderer.listen(x.element, 'mouseover', () => {
            if (!this._dateTo && this.dateOver !== x.date) {
              this.dateOver = x.date;
              this.redrawCells(this.dateOver);
            }
          });
        }
      });
    });
  }
  private convertFromString(str) {
    str = str.replaceAll('de ', '/');
    str = str.replaceAll(' ', '');
    str = str.trim();
    const dd = str.substring(0, str.indexOf('/'));
    const mm = this.convertMouthString(str.substring(str.indexOf('/') + 1, str.length - 5));
    const yy = str.substr(str.lastIndexOf('/') + 1);
    const car = mm
      .concat('/')
      .concat(dd)
      .concat('/')
      .concat(yy);
    return new Date(car);
  }

  private convertMouthString(str: string) {
    switch (str) {
      case 'janeiro':
      case 'jan':
        return '01';
      case 'fevereiro':
      case 'fev':
        return '02';
      case 'março':
      case 'mar':
        return '03';
      case 'abril':
      case 'abr':
        return '04';
      case 'maio':
      case 'mai':
        return '05';
      case 'junho':
      case 'jun':
        return '06';
      case 'julho':
      case 'jul':
        return '07';
      case 'agosto':
      case 'ago':
        return '08';
      case 'setembro':
      case 'set':
        return '09';
      case 'outubro':
      case 'out':
        return '10';
      case 'novembro':
      case 'nov':
        return '11';
      case 'dezembro':
      case 'dez':
        return '12';
      default:
        return '01';
    }
  }

  onOpen(calendar) {
    if (this._dateFrom) {
      const date = new Date(this._dateFrom);
      if (calendar && this._dateFrom && calendar.activeDate.getMonth() !== date.getMonth()) {
        calendar.activeDate = date;
      }
    }
    this.setCells();
  }
  redrawCells(timeTo: number) {
    timeTo = timeTo || this._dateTo;
    if (timeTo < this._dateFrom) {
      timeTo = this._dateFrom;
    }
    this.cells.forEach(x => {
      const change = this._dateFrom && x.date >= this._dateFrom && x.date <= timeTo;
      if (change || x.change) {
        x.change = change;
        const addInside = x.change ? 'addClass' : 'removeClass';
        const addFrom =
          x.date === this._dateFrom
            ? 'addClass'
            : x.date === timeTo && this._dateFrom === timeTo
            ? 'addClass'
            : 'removeClass';
        const addTo =
          x.date === timeTo
            ? 'addClass'
            : x.date === this._dateFrom && this._dateFrom === timeTo
            ? 'addClass'
            : 'removeClass';

        this.renderer[addInside](x.element, 'inside');
        this.renderer[addFrom](x.element, 'from');
        this.renderer[addTo](x.element, 'to');
      }
    });
  }
}
