import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { CalendarConfig } from '@shared/components/ui/calendar/calendar.model';

@Component({
  selector: 'app-calendar-dialog',
  templateUrl: './calendar-dialog.component.html',
  styleUrls: ['./calendar-dialog.component.scss']
})
export class CalendarDialogComponent implements OnInit {
  public calendarConfig: CalendarConfig;

  constructor(
    public dialogRef: MatDialogRef<CalendarDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: CalendarConfig
  ) {}

  ngOnInit() {
    if (!this.data) return;
    this.calendarConfig = {
      titleMobile: '',
      subTitleMobile: '',
      startYear: this.data.startYear || null,
      endYear: this.data.endYear || null,
      isTruncateYears: this.data.isTruncateYears || false,
      isTruncateDays: this.data.isTruncateDays || { toBack: false, toFront: false },
      isDateRange: this.data.isDateRange || false,
      minDate: this.data.minDate || null,
      maxDate: this.data.maxDate || null
    };

    this.dialogRef.addPanelClass('custom-dialog');
  }

  public navAction(date: string): void {
    if (!date) return;
    this.closeDialogCalendar(date);
  }

  public closeDialogCalendar(date?: string): void {
    this.dialogRef.close(date);
  }
}
