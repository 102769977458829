import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { AgentCertified, CertifiedAgentModel } from '../models/certified-agent.model';

@Injectable({
  providedIn: 'root'
})
export class CertifiedAgentService {
  constructor(private http: HttpClient) {}

  public getCertifiedAgents(): Observable<CertifiedAgentModel[]> {
    const URL = `${environment.apiRootUrl}/shopkeeper-cert-agents/1.0.0/shop/certified-agent`;
    return this.http.get<any>(URL);
  }

  public getCertifiedAgent(params): Observable<AgentCertified> {
    const url = `${environment.apiRootUrl}/shopkeeper-cert-agents/1.0.0/certified-agent/certifieds/`;
    return this.http.post<AgentCertified>(url, params);
  }

  public updateAgentRelationship(payload): Observable<any> {
    const url = `${environment.apiRootUrl}/shopkeeper-cert-agents/1.0.0/certified-agent/relationship/`;
    return this.http.put<any>(url, payload);
  }

  public deleteAgentRelationship(agentCertifiedNumber, certificationNumber): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: {
        agentCertifiedNumber: agentCertifiedNumber,
        certificationNumber: certificationNumber
      }
    };

    const url = `${environment.apiRootUrl}/shopkeeper-cert-agents/1.0.0/certified-agent/relationship/`;

    return this.http.delete<any>(url, options);
  }
}
