import { createSelector } from '@ngrx/store';
import { AppState } from '@app/core/state';

export const domainsSelector = (state: AppState) => state.domains;

export const selectAllDomains = createSelector(
  domainsSelector,
  domains => domains
);

export const selectTerms = createSelector(
  domainsSelector,
  domains => domains.terms
);
