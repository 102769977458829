import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';

interface ModalOptions {
  title: string;
  description: string;
  confirmText: string;
  cancelText: string;
  type: string;
  category: string;
}

@Component({
  selector: 'app-payment-exemption-modal',
  templateUrl: './payment-exemption-modal.component.html',
  styleUrls: ['./payment-exemption-modal.component.scss']
})
export class PaymentExemptionModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: ModalOptions,
    public dialogRef: MatDialogRef<PaymentExemptionModalComponent>,
    private genTagger: GenTagger
  ) {}

  public confirmActionFunction() {
    this.close(true);
  }

  public cancelActionFunction() {
    this.close(false);
  }

  public close(value) {
    this.dialogRef.close(value);
  }

  changeStatus(value: string, isOpen = true) {
    this.genTagger.setTag({
      event_category: this.modalData.category,
      event_action: Tag.Click,
      event_label: `${isOpen ? 'exibir' : 'ocultar'} - ${value}`
    });
  }

  @HostListener('keydown.esc')
  public onEsc() {
    if (!this.dialogRef.disableClose) {
      this.close(false);
    }
  }
}
