<div class="row">
  <mat-card>
    <div class="row card-height">
      <div class="col-1 align-self-center">
        <div (click)="openCard()" *ngIf="!openAcordion">
          <img src="./assets/images/keyboard_arrow_down-black-18dp.svg" width="30px" alt="" />
        </div>
        <div (click)="openCard()" *ngIf="openAcordion">
          <img src="./assets/images/keyboard_arrow_up-black-18dp.svg" width="30px" alt="" />
        </div>
      </div>
      <!--Titulo-->
      <div class="col-2 align-self-center">
        <div class="row">
          <div class="col">
            <p class="proposta">{{ proposta }}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-3 col-3-pad">
            <img class="icon-status" src="./assets/images/ic_exclamation.svg" />
          </div>
          '
          <div class="col-md-12 .ml-mt-auto align-self-center">
            {{ aprovacao }}
          </div>
        </div>
      </div>
      <!--Status proposta-->
      <div class="col-3 align-self-center extra-right-margin">
        <div class="row" *ngFor="let status of statusProposal">
          <div class="col-2 statuses-placement">
            <img class="icon-status" src="./assets/images/ic_check.svg" />
          </div>
          <div class="col-5 p-0">
            {{ status }}
          </div>
        </div>
      </div>
      <div class="col-3 align-self-center">
        <button mat-stroked-button type="button" color="warn" class="mat-outline-button button">Exibir detalhes</button>
      </div>
    </div>
    <div *ngIf="openAcordion">
      <hr />
    </div>
    <div class="row" *ngIf="openAcordion">
      <div class="col muted-info">Iniciando em {{ data }}</div>
      <div class="col">
        <div class="row reduced-margin">
          {{ parcela1 }} x
          <p class="parcel-highlight">R$ {{ valorParcela }}</p>
        </div>
        <div class="row total-value">Total de R$ {{ valorTotal }}</div>
      </div>
    </div>
  </mat-card>
</div>
