<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="status mb-3 biometry-{{ color }}" appTagger [event_category]="category"
        event_label="{{ 'Status na biometria' | translate }}">
        <img class="py-4" src="./assets/images/{{ icon }}.svg" alt="" />
        <h1 class="py-4">
          {{ title }}
        </h1>
      </div>
    </div>
    <div class="col-12 mt-3">
      <p *ngIf="showBackButton  && typePerson === 'J'">{{ subtitle }}</p>
    </div>
    <div class="col-12 col-md-6 mb-3" *ngIf="currentPlatform.isMobile && showBackButton && !isCrossedFlux">
      <app-filled-button (click)="returnToQrCode()">
        {{ 'FORMALIZATION-STEP-BACK' | translate }}
      </app-filled-button>
    </div>
  </div>
</div>
