import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-proposta',
  templateUrl: './card-proposta.component.html',
  styleUrls: ['./card-proposta.component.scss']
})
export class CardPropostaComponent implements OnInit {
  public panelOpenState = true;
  public proposta = 'Etna Móveis Tietê';
  public aprovacao = 'Aguardando aprovação';
  public simulacao = 'Simulação';
  public cadastro = 'Cadastro';
  public formalizacao = 'Formalização';
  public finalizacao = 'Finalização';
  public data = '20/11/2019';
  public statusProposal = [this.simulacao, this.cadastro, this.formalizacao, this.finalizacao];
  public parcela1 = '18';
  public valorParcela = '1.100,00';
  public valorTotal = '19.800,00';
  public openAcordion = false;

  constructor() {}

  ngOnInit() {}

  public openCard(): void {
    this.openAcordion = !this.openAcordion;
  }
}
