<form [formGroup]="filtersForm" (submit)="onSubmit()">
  <div class="row">
    <div class="col-4 col-md-3">
      <!-- States -->
      <mat-form-field class="states">
        <mat-label>{{ 'SIMULATION_STORES_FILTERS_STATES' | translate }}</mat-label>
        <mat-select class="store-select" formControlName="state" (selectionChange)="loadCitiesByState($event.value)">
          <mat-option *ngFor="let state of states" [value]="state">
            {{ state.code }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="col-8 col-md-6">
      <!-- Cities -->
      <mat-form-field class="cities">
        <mat-label>{{ 'SIMULATION_STORES_FILTERS_CITIES' | translate }}</mat-label>
        <mat-select class="store-select" formControlName="city" (selectionChange)="onSubmit()">
          <mat-option *ngFor="let city of cities" [value]="city">
            {{ city.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <!-- Search Bar -->
    <div class="col-12 searchbar-container">
      <img class="searchbar-icon" src="./assets/images/ic_search.svg" alt="Search" />
      <input
        class="w-100 searchbar-input"
        formControlName="searchTerm"
        [placeholder]="'SIMULATION_STORES_FILTERS_SEARCH' | translate"
      />
    </div>
    <!--
    <div class="col-2 text-right my-auto">
      <button mat-button type="submit" color="primary">{{ 'SIMULATION_STORES_FILTERS_BUTTON' | translate}}</button>
    </div>
    -->
  </div>
</form>
