<div class="container stores">
  <div class="row">
    <div class="col-12">
      <button class="button-back" [routerLink]="['/showcase/subsegment']">
        <img src="./assets/images/ic_arrow_left.svg" alt="Previous" />
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-5">
      <app-store-subsegment [subsegment]="selectedSubsegment"></app-store-subsegment>
    </div>
    <div class="col-md-7">
      <app-stores-list [subsegment]="selectedSubsegment" (selectedStore)="handleStore($event)"></app-stores-list>
    </div>
  </div>
</div>
