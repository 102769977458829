import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppState } from '@app/core/state';
import * as fromFormalization from '@app/core/state/formalization';
import * as fromProposal from '@app/core/state/proposal';
import { PendencyTypeEnum } from '@app/pendency/models/pendency-type.enum';
import { PendencyService } from '@app/pendency/pendency.service';
import {
  ChecklistDocumentEnum,
  ChecklistItemTypeEnum
} from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/api/query.checklist.model';
import {
  ChecklistModel,
  ChecklistStatusEnum
} from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/checklist.model';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-attach-pending-documents',
  templateUrl: './attach-pending-documents.component.html',
  styleUrls: ['./attach-pending-documents.component.scss']
})
export class AttachPendingDocumentsComponent implements OnInit, OnChanges, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() checklist: ChecklistModel[] = [];
  @Input() pendencyType: PendencyTypeEnum;
  @Input() message: string;
  @Input() category: string;
  @Input() nfValue: number;
  @Output() uploadFinished = new EventEmitter();

  public maxSizeAllowed = '10';
  public checklistItems: ChecklistModel[] = [];
  public completedChecklistIds: string[] = [];
  public uploadsComplete = false;
  public pendencyTitle = 'PENDENCY-PENDING-DOCUMENTS-TITLE';

  constructor(private store$: Store<AppState>, private pendencyService: PendencyService) {
    this.handleChecklist();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.pendencyType && this.checklist.length && (changes.pendencyType || changes.checklist)) {
      this.handleChecklist();
    }
  }

  ngOnInit(): void {
    this.setMaxFileAllowedSubscription();
  }

  private handleChecklist(): void {
    if (
      this.pendencyType === PendencyTypeEnum.BPO_PENDENCY ||
      this.pendencyType === PendencyTypeEnum.SHOPKEEPER_PENDENCY ||
      this.pendencyType === PendencyTypeEnum.BIPARTITE_PENDENCY
    ) {
      this.checklistItems = this.checklist.filter(
        item =>
          (
            item.status === ChecklistStatusEnum.PENDING ||
            item.status === ChecklistStatusEnum.RELEASE_INTERNAL 
            ) 
          && item.type?.code === ChecklistItemTypeEnum.UPLOAD 
          && item.document?.docCode !== ChecklistDocumentEnum.PARTNER_INVOICE
      );
    }

    if (this.pendencyType === PendencyTypeEnum.CD_PENDENCY) {
      this.checklistItems = this.checklist
        .filter(item => item.document && item.document.docCode)
        .filter(
          ({ document: { docCode } }) =>
            docCode === ChecklistDocumentEnum.INCOME_DOC ||
            docCode === ChecklistDocumentEnum.TRIAL_BALANCE ||
            docCode === ChecklistDocumentEnum.GENERAL_DOCS_UPDATE_REGISTER_EXCEPTION
        );
    }

    this.resolvePendency();

  }

  private setMaxFileAllowedSubscription(): void {
    this.store$
      .select(fromFormalization.selectors.selectMaxFileSizeAllowed)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(maxSizeAllowed => (this.maxSizeAllowed = maxSizeAllowed));

    this.store$.dispatch(new fromFormalization.actions.GetMaxFileSizeAllowedAction());
  }

  public handleUploadFinished(obj: { id: string; completed: boolean }): void {
    // If upload is successful and the element is not in the list, add it.
    if (obj.completed) {
      if (this.completedChecklistIds.find(ele => ele === obj.id)) return;
      this.completedChecklistIds.push(obj.id);
    }

    // If deletion is successful and the element is in the list, remove it.
    if (!obj.completed) {
      if (!this.completedChecklistIds.find(ele => ele === obj.id)) return;
      const index = this.completedChecklistIds.findIndex(ele => ele === obj.id);
      this.completedChecklistIds.splice(index, 1);
    }

    this.resolvePendency();

  }

  private resolvePendency(): void {
    // Compare checklist to successful uploads to determine if we need to enable
    // submit button.
    this.uploadsComplete = this.checklistItems.every(ele => !!this.completedChecklistIds.find(id => ele.id === id));

    this.uploadsComplete ? this.uploadFinished.emit(true) : this.uploadFinished.emit(false);

  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.store$.dispatch(new fromProposal.actions.ClearChecklistAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalStageAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalIdAction());
  }
}
