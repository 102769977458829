<header class="modal-header">
  <span>Valide sua identidade</span>

  <button mat-button (click)="close()" class="close-icon">
    <mat-icon class="btn-close">close</mat-icon>
  </button>
</header>
<section class="modal-body w-100">
  <title class="title">
    Valide sua identidade
  </title>

  <main class="mb-3">
    <span class="info">
      Informe o código de verificação gerado no aplicativo/plugin Google Authenticator ou selecione
      <a class="link" (click)="requestCode()" href="javascript:void(0)">enviar código por sms</a><br />
    </span>
    <span class="sucessMsg" *ngIf="sucessMsg">
      Sms enviado com sucesso!
    </span>
  </main>

  <form role="form" [formGroup]="formCode">
    <div class="form-content">
      <div class="form-group">
        <app-pion-token-input [numberOfDigits]="6" (submitEmit)="sendToken($event)"> </app-pion-token-input>
      </div>
      <div class="erroMsg" *ngIf="submitForm && f.code.errors?.required">
        É necessario preencher o código para validação
      </div>
      <div class="erroMsg" *ngIf="codeInvalid">
        Código de verificação invalido
      </div>
    </div>
  </form>

  <div class="d-flex mb-3 mt-4">
    <span>Primeiro Acesso</span>
    <mat-icon
      [matTooltipPosition]="positionToolTip"
      [matTooltipClass]="'tooltip-custom'"
      matTooltip="
                No primeiro acesso ultilize a opção 'enviar código por sms'.
                Você receberá uma mensagem no sms cadastrado na plataforma com um QR Code para vincular seus dados
                de acesso no aplicativo ou plugin Google Autheticator"
    >
      info
    </mat-icon>
  </div>

  <div class="d-flex">
    <span>Não recebi o sms</span>
    <mat-icon
      [matTooltipPosition]="positionToolTip"
      [matTooltipClass]="'tooltip-custom'"
      matTooltip="Caso não  tenha recebido o código por sms  informe ao administrador e solicite o acesso."
      >info</mat-icon
    >
  </div>
</section>
<footer class="modal-footer" mat-dialog-actions>
  <button (click)="close()" type="button" class="btn btn-principal btn-principal-sm btn-custom">
    Voltar
  </button>
  <button (click)="submit()" class="btn btn-secundario btn-secundario-sm">
    Validar
  </button>
</footer>
