import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Observer } from 'rxjs';

@Injectable()
export class ProposalService {
  private _defineFlow = new BehaviorSubject<object>({
    countSteps: 1,
    choiceSegment: true
  });

  /* Mock */
  // public url = 'http://localhost:3000';
  public tabs = new Observable((observer: Observer<object>) => {
    return observer.next([
      {
        name: 'Tok&Stok',
        address: 'Av. Ibirapuera, 2904 - Indianópolis',
        shortState: 'sp',
        longState: 'são paulo'
      },
      {
        name: 'Tok&Stok Botafogo',
        address: 'R. Gen. Severiano, 97 - Botafogo',
        shortState: 'rj',
        longState: 'rio de janeiro'
      },
      {
        name: 'Etna - Brooklin',
        address: 'Av. Dr. Churcri Zaidan, 281 - Brooklin',
        shortState: 'sp',
        longState: 'são paulo'
      },
      {
        name: 'Leroy Merlin Morumbi',
        address: 'Av. Magalhães de Castro, 1200 - Morumbi',
        shortState: 'sp',
        longState: 'são paulo'
      }
    ]);
  });

  constructor(private http: HttpClient) {}

  public getTabs(): Observable<object> {
    return this.tabs;
  }

  get defineFlow(): any {
    return this._defineFlow.value;
  }

  set defineFlow(defineFlow: any) {
    this._defineFlow.next(defineFlow);
  }
}
