 import gql from 'graphql-tag';
//
// export const GET_ACTIVE_CONTRACTS = gql`
//   query listActiveContracts($document: String!, $documentType: String!, $companyCode: String!) {
//     listActiveContracts(document: $document, documentType: $documentType, companyCode: $companyCode) {
//       store
//       totalValue
//       loanRate
//       totalInstallments
//       currentInstallment
//       installmentValue
//       dueDate
//     }
//   }
// `;


export const GET_ALL_CONTRACTS = gql`
  query getAllContractsQuery {
    listActivesContracts {
            contractNumber #id contrato 
            paymentMode # quando seu valor for 90 informar "plano customizado" no campo de valor de a parcela
            objectFinanced {
              description  # nome de o negocio
            }
            portion {
              amount # numero de las parcelas,
              value  #   valor de a parcela
            },
            appreciation # 60000 total de o valor 
          },
      }
`;

