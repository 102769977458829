<section id="2" class="simulation-section simulation-section-height">
  <div *ngIf="analysisShow" class="card-infor mb-5 cashback-card">
    <img src="./assets/images/info-white.svg" class="imagem" alt="info icon" />
    <span class="customText">{{
      'WE REMIND YOU THAT THIS PROPOSAL WILL UNDERGO AN ADDITIONAL CREDIT ANALYSIS AFTER THE FORMALIZATION STAGE'
      | translate
      }}.</span>
  </div>
  <header class="section-header">
    <h2 class="title">{{ 'SECTION-INSTALLMENTS-TITLE' | translate }}</h2>
    <span>{{ installmentStepData?.sectionNumber }}</span>
  </header>
  <div class="section-main">
    <div class="installments-container">
      <div class="form" [formGroup]="installmentOptions">
        <ng-container>
          <div class="extra-info">
            <p class="label">{{ 'PAYMENT-PLAN' | translate }}</p>
            <mat-form-field *ngIf="!isNPP" color="black" appearance="legacy" class="mt-2">
              <mat-select formControlName="paymentPlan" name="paymentPlan" [compareWith]="compareObjects">
                <mat-option *ngFor="let paymentPlan of installmentStepData?.paymentMethods" [value]="paymentPlan"
                  appTagger [event_category]="category" event_label="fechar" [event_action]="Selecionar">{{
                  paymentPlan?.description }}</mat-option>
              </mat-select>
            </mat-form-field>
            <!-- NPP -->
            <mat-form-field *ngIf="isNPP" color="black" appearance="legacy" class="mt-2">
              <mat-select formControlName="paymentPlan" name="paymentPlan" [compareWith]="compareObjectsPaymentPlan">
                <mat-option *ngFor="let paymentPlan of installmentStepData?.paymentMethods" [value]="paymentPlan"
                  appTagger [event_category]="category" event_label="fechar" [event_action]="Selecionar">{{
                  paymentPlan?.flexPlanName }}</mat-option>
              </mat-select>
            </mat-form-field>
            <!-- NPP -->
          </div>
          <mat-form-field color="black" appearance="legacy" class="mt-2">
            <p class="label">{{ 'INSTALLMENT-AMMOUNT-FIELD-LABEL' | translate }}</p>

            <input class="input-value" *ngIf="!isNPP" matInput formControlName="installment"
              (blur)="inputInstallmentChange()" appTagger mask="00" [event_category]="category"
              event_label="{{ 'INSTALLMENT-AMMOUNT-FIELD-LABEL' | translate }}" [event_action]="FillingError"
              autocomplete="off" />

            <!-- Array de parcelas npp -->
            <mat-select *ngIf="isNPP" formControlName="installment" (selectionChange)="inputInstallmentChange()"
              name="installment" [compareWith]="compareObjectsInstallment">
              <mat-option *ngFor="let installment of installmentStepData?.installmentAmountArray" [value]="installment">
                {{ installment }}
              </mat-option>
            </mat-select>
            <!--/ Array de parcelas npp -->

            <mat-error *ngIf="installment?.errors?.max" appTagger [event_category]="category"
              event_label="{{ 'INSTALLMENT-VALUE-MAX-ERROR' | translate }}" [event_action]="FillingError">
              {{ 'INSTALLMENT-VALUE-MAX-ERROR' | translate: { value: installmentStepData?.maxInstallmentAmount } }}
            </mat-error>
            <mat-error *ngIf="installment?.errors?.min" appTagger [event_category]="category"
              event_label="{{ 'INSTALLMENT-VALUE-MIN-ERROR' | translate }}" [event_action]="FillingError">
              {{ 'INSTALLMENT-VALUE-MIN-ERROR' | translate: { value: installmentStepData?.minInstallmentAmount } }}
            </mat-error>
            <mat-error *ngIf="installment?.errors?.required" appTagger [event_category]="category"
              event_label="{{ 'INSTALLMENT-VALUE-REQUIRED-ERROR' | translate }}" [event_action]="FillingError">
              {{ 'INSTALLMENT-VALUE-REQUIRED-ERROR' | translate }}
            </mat-error>
            <mat-error *ngIf="installment?.errors?.incorrect" appTagger [event_category]="category"
              event_label="{{ 'INSTALLMENT-AMOUNT-ERROR' | translate }}" [event_action]="FillingError">
              {{ 'INSTALLMENT-AMOUNT-ERROR' | translate }}
            </mat-error>
          </mat-form-field>
          <ng-container>
            <app-payment-plan-installment-info #paymentPlanInstallmentInfo
              [code]="isNPP ? currentPaymentPlan?.flexPlanType : currentPaymentPlan?.code"
              [months]="isNPP ? installmentStepData?.seasonalPeriods : currentPaymentPlan?.months"
              [installmentPMethodsDate]="installmentStepData?.installmentPMethodsDate"
              [selectedPaymentMethod]="installmentStepData?.selectedPaymentMethod"
              [installmentStepData$]=" installmentStepData$" [codeMonthsRange]="installmentStepData?.codeMonthsRange"
              [shopkeeperData]="bestOffer?.bestOffer?.shopkeeperData" [bestOffer]="bestOffer"
              [firstInstallmentPMethodsDate]="installmentStepData?.firstInstallmentPMethodsDate"
              (installmentInfoChanged)="receivePeriodSelected($event)" (resValidator)="isValidValues($event)"
              (installmentInfoChangedEmitter)="installmentInfoChangedHandler($event)" [category]="category">
            </app-payment-plan-installment-info>
            <div class="financing-fees " *ngIf="proposalData?.product !== 'CSC' && proposalData?.product !== 'CSCP'">
              <div class="row subtitle" *ngIf="paymentMethods?.paymentMethods?.length === 1">
                <div class="col">
                  <p class="details-title font-weight-bold">{{ 'FINANCING-FEES' | translate }}</p>
                </div>
              </div>
              <div class="row details">
                <div class="col-12 col-sm-6">
                  <p class="label">{{ 'MODAL-LOAN-FEES-INTEREST-RATE' | translate }}</p>
                  <p class="data">{{ installmentStepData.calcContractFee | localePercentage }} ao mês</p>
                </div>
              </div>
              <div class="row details" *ngIf="taxIOF">
                <div class="col-12 col-sm-6">
                  <p class="label">{{ taxIOF.description }}</p>
                  <p class="data">{{ taxIOF.value | localeCurrency }}</p>
                </div>
              </div>

              <div class="row details">
                <div class="col-12 col-sm-6">
                  <p class="label">{{ 'CET-TOTAL' | translate }}</p>
                  <p class="data">{{ installmentStepData.monthlyInternalReturnRate | localePercentage }} ao mês</p>
                  <p class="data space">{{ installmentStepData.annualInternalReturnRate | localePercentage }} ao ano</p>
                </div>
              </div>

              <div class="row details" *ngIf="
                  installmentStepData.registrationFeeType !== 'EXEMPTION_REQUEST' &&
                  installmentStepData.tariff &&
                  !!installmentStepData.tariff[0]
                ">
                <div class="col-12 col-sm-6">
                  <p class="label">{{ 'REGISTRATION-FEE-TITLE' | translate }}</p>
                  <p class="data">{{ installmentStepData.tariff[0].value | localeCurrency }}</p>
                  <p class="data space" *ngIf="
                      installmentStepData.registrationFeeType === 'IN_INSTALLMENTS' &&
                      installmentStepData.tariff[0].value > 0
                    ">
                    {{ 'INCLUDED-ON-FINANCING' | translate }}
                  </p>
                </div>
              </div>

              <div class="row details" *ngIf="
                  installmentStepData.registrationFeeType === 'EXEMPTION_REQUEST' &&
                  installmentStepData.tariff &&
                  !!installmentStepData.tariff[0]
                ">
                <div class="col-12 col-sm-6">
                  <p class="label">{{ 'REGISTRATION-FEE-TITLE' | translate }}</p>
                  <p class="data">{{ 0 | localeCurrency }}</p>
                </div>
              </div>

              <div class="row details"
                *ngIf="(installmentStepData?.hasInsurance || installmentStepData?.protectionInsuranceIncluded) && !!(installmentStepData?.insurance[0])">
                <div class="col-12 col-sm-6">
                  <p class="label">{{ 'INSURANCE-VALUE' | translate }}</p>
                  <p class="data">{{ installmentStepData.insurance[0].value | localeCurrency }}</p>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <div class="infos">
        <app-payment-plan-calendar [showTooltip]="true" [showGraphic]="true"
          [code]="isNPP ? currentPaymentPlan?.flexPlanType : currentPaymentPlan?.code">
        </app-payment-plan-calendar>
        <app-month-chart *ngIf="bestOffer?.flexPlanList?.length > 1" [selectedPeriod]="selectedPeriod"
          [bestOffer]="bestOffer"></app-month-chart>
        <div class="row plans-buttons" [ngClass]="currentCountry">
          <div class="col-12 col-md-6 mb-3 mb-sm-0">
            <button class="plan-button" (click)="openInstallmentsModal()" appTagger [event_category]="category"
              event_label="{{ 'INSTALLMENTS' | translate }}">
              <div class="button-icon">
                <img src="./assets/images/installment-icon.png" alt="installment" />
              </div>
              <div class="text">
                <strong>{{ 'INSTALLMENTS' | translate }}</strong>
                <p>
                  <span>{{ 'ALL-DETAILS' | translate }}</span>
                  <mat-icon>north_east</mat-icon>
                </p>
              </div>
            </button>
          </div>
          <div class="col-12 col-md-6" *ngIf="installmentStepData?.isAllowedFlexInstallments && !isNPP">
            <button class="plan-button" (click)="openInstallmentsModalFlex()" appTagger [event_category]="category"
              event_label="{{ 'FLEX-PLAN' | translate }}">
              <div class="button-icon">
                <img src="./assets/images/flex-plan-icon.png" alt="flex" />
              </div>
              <div class="text">
                <strong>{{ 'FLEX-PLAN' | translate }}</strong>
                <p>
                  <span>{{ 'CUSTOM-PLAN' | translate }}</span>
                  <mat-icon>north_east</mat-icon>
                </p>
              </div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
