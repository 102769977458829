<div class="sign-in-container">
  <div class="sidebar-container">
    <app-sidebar></app-sidebar>
  </div>

  <div class="content-container mt-md-5 align-items-center justify-content-center d-flex">
    <div class="content">
      <p class="title">{{ 'LOGIN-TITLE' | translate }}</p>
      <p class="description">{{ 'LOGIN-SUBTITLE' | translate }}</p>
      <div>
        <form [formGroup]="loginForm" autocomplete="off" (submit)="handleSubmitLoginForm()">
          <div class="col-12 input-row">
            <mat-form-field color="black" appearance="legacy" class="input">
              <mat-label>{{ 'DOCUMENT-NUMBER' | translate }}</mat-label>
              <input
                autocomplete="off"
                mask="{{ documentNumberMask }}"
                type="tel"
                matInput
                class="input-with-icon"
                formControlName="documentNumber"
                [hiddenInput]="hideInputDocumentMask"
                (paste)="onPaste($event)"
                appTagger
                [event_category]="category"
                event_label="{{ 'DOCUMENT-NUMBER' | translate }}"
                [event_action]="Insert"
              />
              <mat-icon
                [hidden]="loginForm.get('documentNumber').value === '' || !loginForm.get('documentNumber').valid"
                matSuffix
                class="sufix-icon success"
                >check_circle
              </mat-icon>
              <mat-icon
                *ngIf="loginForm.get('documentNumber').dirty && loginForm.get('documentNumber').invalid"
                matSuffix
                class="sufix-icon error"
                >cancel
              </mat-icon>
              <mat-error *ngIf="!loginForm.get('documentNumber').valid">
                {{ 'DOCUMENT-NUMBER-ERROR-MSG' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
          <br />
          <div class="col-12 input-row">
            <mat-form-field color="black" appearance="legacy" class="input w-100">
              <mat-label>{{ 'PASSWORD' | translate }}</mat-label>
              <input
                autocomplete="off"
                matInput
                maxlength="12"
                class="input-with-icon"
                formControlName="password"
                [value]="arr_password.mask"
                appTagger
                [event_category]="category"
                event_label="{{ 'PASSWORD' | translate }}"
                [event_action]="Insert"
              />
              <img class="caps-ico" *ngIf="capslockOn" src="./assets/images/caps-lock.svg" alt="icon caps active" />
              <mat-hint
                [id]="null"
                class="error-hint"
                *ngIf="
                  (loginForm.get('password').dirty || loginForm.get('password').touched) &&
                  loginForm.get('password').invalid
                "
              >
                {{ 'PASSWORD-INPUT-ERROR-MSG' | translate }}
              </mat-hint>
            </mat-form-field>
          </div>
          <div *ngIf="errors$ | async as errors">
            <p class="text-error" *ngIf="errors.length > 0 && errors.includes('invalid_grant')">
              {{ 'INVALID-LOGIN-MSG' | translate }}
            </p>
          </div>
          <br />
          <div class="col-12 input-row">
            <mat-checkbox
              color="primary"
              formControlName="rememberUser"
              appTagger
              [event_category]="category"
              event_label="{{ 'LOGIN-SAVE-USER' | translate }}"
              >{{ 'LOGIN-SAVE-USER' | translate }}
            </mat-checkbox>
          </div>
          <br />
          <div class="col-12 input-row">
            <button
              mat-flat-button
              color="primary"
              class="login-button w-100"
              [disabled]="!loginForm.valid"
              appTagger
              [event_category]="category"
              event_label="{{ 'LOGIN-BTN-NAME' | translate }}"
            >
              {{ 'LOGIN-BTN-NAME' | translate }}
            </button>
          </div>
          <br />
          <div>
            <button
              class="recover-link link primary"
              (click)="handlePassRecoverClick('recover-password')"
              type="button"
              appTagger
              [event_category]="category"
              event_label="{{ 'LOGIN-PASSWORD-RECOVERY' | translate }}"
            >
              {{ 'LOGIN-PASSWORD-RECOVERY' | translate }}
            </button>
          </div>
          <div>
            <button
              class="recover-link link primary"
              (click)="handlePassRecoverClick('primary-access')"
              type="button"
              appTagger
              [event_category]="category"
              event_label="{{ 'LOGIN-PRIMARY-ACCESS' | translate }}"
            >
              {{ 'LOGIN-PRIMARY-ACCESS' | translate }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
