<div class="container-fluid splash">
  <div class="sidebar-login">
    <app-sidebar></app-sidebar>
  </div>

  <br />
  <div class="buttons px-4 pt-3">
    <button
      [routerLink]="['/login/sign-in']"
      mat-flat-button
      color="primary"
      appTagger
      [event_category]="category"
      event_label="{{ 'LOGIN-BTN-NAME' | translate }}"
    >
      {{ 'LOGIN-BTN-NAME' | translate }}
    </button>

    <button
      (click)="redirectIdentification()"
      mat-stroked-button
      color="primary"
      appTagger
      [event_category]="category"
      event_label="{{ 'HEADER-REGISTER' | translate }}"
    >
      {{ 'HEADER-REGISTER' | translate }}
    </button>
  </div>
</div>
