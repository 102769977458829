import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { HeaderShopKeeperModel } from '../models/header-shopkeeper.model';

export class HeaderItem {
  code: string;
  label: string;
  actionType: 'routeTo' | 'callComponent';
  targetAddress: string;
  submenu?: HeaderItem[];
  mobileOnly?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class HeaderService {
  public header$: BehaviorSubject<string> = new BehaviorSubject('');
  public currentHeader = this.header$.asObservable();
  public appHeader: HeaderItem[];
  public helpGoBackUrl: string;

  constructor(private i18n: TranslateService) {
    this.appHeader = HeaderShopKeeperModel;
    this.appHeader.forEach(actionableItem => {
      actionableItem.label = this.i18n.instant(actionableItem.code + '-MENU-LABEL');
      if (actionableItem.code === 'SMN') {
        actionableItem.submenu.forEach(e => {
          e.label = this.i18n.instant(e.code + '-MENU-LABEL');
        });
      }
    });
  }

  updateHeader(headerType: string) {
    this.header$.next(headerType);
  }

  setGobackUrl(helpGoBackUrl) {
    this.helpGoBackUrl = helpGoBackUrl;
  }

  getGobackUrl() {
    return this.helpGoBackUrl;
  }

  public translateToHeaderInterface(menuResponse?) {
    let normalizedMenuObject: Array<HeaderItem> = [];
    if (!menuResponse) {
      return this.appHeader;
    } else {
      menuResponse.menus.map(menu => {
        const menuLabels: HeaderItem[] = this.appHeader.filter(appHeaderEntry => appHeaderEntry.code === menu.code);
        normalizedMenuObject = [].concat(...normalizedMenuObject, menuLabels);
      });
      return normalizedMenuObject;
    }
  }
}
