import gql from 'graphql-tag';

export const GET_STATES = gql`
  query statesQuery {
    listStates {
      code
      description
    }
  }
`;

export const GET_CITIES = gql`
  query citiesQuery($stateCode: String) {
    listCities(stateCode: $stateCode) {
      id
      stateCode
      name
    }
  }
`;
