<div class="mobile">
  <div class="container d-sm-none">
    <!-- Propostas com pendencias-->
    <div class="row">
      <div class="col">
        <p class="titleOffer">
          Propostas com pedências
        </p>
      </div>
    </div>
    <div class="row" *ngFor="let card of propostasPendencias; let i = index">
      <!-- Restante-->
      <div class="card shadow" *ngIf="i !== 0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-10 align-self-start">
                  <p class="card-title specialOffer" style="margin-bottom: 0px;">{{ card.title }}</p>
                </div>
              </div>
            </div>
            <div class="col-3 align-self-end">
              <img style="align-items: end; display: contents;" src="./assets/images/ic_lixeira.svg" />
            </div>
          </div>

          <div class="row" style="margin-bottom: 2%;">
            <div class="col align-self-start">
              <small>Iniciado em {{ card.date }}</small>
            </div>
          </div>
          <div
            class="line"
            style="
            width: 110%;
            margin-left: -6%;
            margin-bottom: 6%;"
          ></div>
          <div class="row">
            <div class="col" style="font-weight: 600">
              <p>{{ card.rate }}X R${{ card.price }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col" style="font-weight: 600">
              <p>Total de R${{ card.totalPrice }}</p>
            </div>
          </div>
          <div
            class="line"
            style="
          width: 110%;
          margin-left: -6%;
          margin-bottom: 6%;"
          ></div>
          <div class="row">
            <div class="col-2">
              <img src="./assets/images/ic_check.svg" />
            </div>
            <div class="col">
              Simulação
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <img src="./assets/images/ic_check.svg" />
            </div>
            <div class="col">
              Cadastro
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <img src="./assets/images/ic_exclamation.svg" />
            </div>
            <div class="col">
              Formalização
            </div>
          </div>
          <div class="row" style="margin-bottom: 6%;">
            <div class="col-2">
              <img src="./assets/images/ic_exclamation.svg" />
            </div>
            <div class="col">
              Finalização
            </div>
          </div>
          <div class="row">
            <div class="col align-self-start">
              <button type="button" class="btn btn-outline-danger">Enviar documentos</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="line" style="margin-bottom: 5%;"></div>

    <!-- Outras propostas em aberto -->
    <div class="row">
      <div class="col">
        <p class="titleOffer">
          Outras propostas em aberto
        </p>
      </div>
    </div>
    <div class="row" *ngFor="let card of outrasAberto; let i = index">
      <!-- Restante-->
      <div class="card shadow" *ngIf="i !== 0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-10 align-self-start">
                  <p class="card-title specialOffer" style="margin-bottom: 0px;">{{ card.title }}</p>
                </div>
              </div>
            </div>
            <div class="col-3 align-self-end">
              <img style="align-items: end; display: contents;" src="./assets/images/ic_lixeira.svg" />
            </div>
          </div>

          <div class="row" style="margin-bottom: 2%;">
            <div class="col align-self-start">
              <small>Iniciado em {{ card.date }}</small>
            </div>
          </div>
          <div
            class="line"
            style="
            width: 110%;
            margin-left: -6%;
            margin-bottom: 6%;"
          ></div>
          <div class="row">
            <div class="col" style="font-weight: 600">
              <p>{{ card.rate }}X R${{ card.price }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col" style="font-weight: 600">
              <p>Total de R${{ card.totalPrice }}</p>
            </div>
          </div>
          <div
            class="line"
            style="
          width: 110%;
          margin-left: -6%;
          margin-bottom: 6%;"
          ></div>
          <div class="row">
            <div class="col-2">
              <img src="./assets/images/ic_exclamation.svg" />
            </div>
            <div class="col">
              Simulação
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <img src="./assets/images/ic_exclamation.svg" />
            </div>
            <div class="col">
              Cadastro
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <img src="./assets/circle_block.svg" />
            </div>
            <div class="col" style="color: #bfbaba;">
              Formalização
            </div>
          </div>
          <div class="row" style="margin-bottom: 6%;" style="color: #bfbaba;">
            <div class="col-2">
              <img src="./assets/circle_block.svg" />
            </div>
            <div class="col">
              Finalização
            </div>
          </div>
          <div class="row">
            <div class="col align-self-start">
              <button type="button" class="btn btn-outline-danger">Continuar proposta</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="line" style="margin-bottom: 5%;"></div>

    <div class="row">
      <div class="col">
        <p class="titleOffer">
          Propostas em aberto
        </p>
      </div>
    </div>
    <div class="row" *ngFor="let card of propostasAberto; let i = index">
      <!-- Restante-->
      <div class="card shadow" *ngIf="i !== 0">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <div class="row">
                <div class="col-10 align-self-start">
                  <p class="card-title specialOffer" style="margin-bottom: 0px;">{{ card.title }}</p>
                </div>
              </div>
            </div>
            <div class="col-3 align-self-end">
              <img style="align-items: end; display: contents;" src="./assets/images/ic_lixeira.svg" />
            </div>
          </div>

          <div class="row" style="margin-bottom: 2%;">
            <div class="col align-self-start">
              <small>Iniciado em {{ card.date }}</small>
            </div>
          </div>
          <div
            class="line"
            style="
            width: 110%;
            margin-left: -6%;
            margin-bottom: 6%;"
          ></div>
          <div class="row">
            <div class="col" style="font-weight: 600">
              <p>{{ card.rate }}X R${{ card.price }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col" style="font-weight: 600">
              <p>Total de R${{ card.totalPrice }}</p>
            </div>
          </div>
          <div
            class="line"
            style="
          width: 110%;
          margin-left: -6%;
          margin-bottom: 6%;"
          ></div>
          <div class="row">
            <div class="col-2">
              <img src="./assets/images/ic_check.svg" />
            </div>
            <div class="col">
              Simulação
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <img src="./assets/images/ic_check.svg" />
            </div>
            <div class="col">
              Cadastro
            </div>
          </div>
          <div class="row">
            <div class="col-2">
              <img src="./assets/images/ic_check.svg" />
            </div>
            <div class="col">
              Formalização
            </div>
          </div>
          <div class="row" style="margin-bottom: 6%;">
            <div class="col-2">
              <img src="./assets/images/ic_check.svg" />
            </div>
            <div class="col">
              Finalização
            </div>
          </div>
          <div class="row">
            <div class="col align-self-center">
              <span style="color: #bfbaba;">
                Aguardando aprovação
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="desktop">
  <div class="body container d-none d-md-block" *ngIf="!uniqueProposal.exist">
    <!-- Propostas em aberto -->
    <div *ngIf="false">
      <app-card-proposta></app-card-proposta>
    </div>

    <div class="row" *ngIf="true">
      <div class="col">
        <span>Propostas em aberto</span>
      </div>
    </div>
    <!-- Carousel -->
    <owl-carousel-o [options]="customOptionsDesktop" *ngIf="true">
      <ng-template carouselSlide *ngFor="let card of propostaAberto; let i = index">
        <div class="card shadow card-with-steper">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-10 align-self-start">
                    <p class="card-title specialOffer" style="margin-bottom: 0px;">{{ card.title }}</p>
                  </div>
                </div>
              </div>
              <div class="col-3 align-self-start">
                <img style="width: 20px;" (click)="deleteArray(i)" src="./assets/images/ic_lixeira.svg" />
              </div>
            </div>

            <div class="row" style="margin-bottom: 2%;">
              <div class="col align-self-start">
                <small>Iniciado em {{ card.date }}</small>
              </div>
            </div>
            <div
              class="line"
              style="
                    width: 110%;
                    margin-left: -6%;
                    margin-bottom: 6%;"
            ></div>
            <div class="row">
              <div class="col-2">
                <img class="icon-status" src="./assets/images/ic_check.svg" />
              </div>
              <div class="col">
                Simulação
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img class="icon-status" src="./assets/images/ic_check.svg" />
              </div>
              <div class="col">
                Cadastro
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img class="icon-status" src="./assets/images/ic_exclamation.svg" />
              </div>
              <div class="col">
                Formalização
              </div>
            </div>
            <div class="row" style="margin-bottom: 6%;">
              <div class="col-2">
                <img class="icon-status" src="./assets/images/ic_exclamation.svg" />
              </div>
              <div class="col">
                Finalização
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <button type="button" class="btn btn-outline-danger" (click)="chamadaPreAnalise()">Simular</button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>

    <!-- Ofertas Personalizadas-->
    <div class="row">
      <div class="col">
        <span>Ofertas Personalizadas</span>
      </div>
    </div>
    <owl-carousel-o [options]="customOptionsDesktop">
      <ng-template carouselSlide *ngFor="let card of ofertaPersonalizada">
        <div class="card shadow card-with-steper">
          <div class="card-body">
            <div class="row">
              <div class="col align-self-start">
                <p
                  class="card-title specialOffer"
                  style="font-size: 17px; font-weight: 900; margin-left: 0px; margin-bottom: 0px;"
                >
                  {{ card.title }}
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <h3
                  style="    font-size: 24px;
                font-weight: bold;"
                >
                  {{ card.parcel }}x R${{ card.price }}
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <p class="card-text" style="font-weight: 600;">Total de R$ {{ card.totalPrice }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start" style="font-weight: 600;">
                <p style=" margin-bottom: 0px;">Taxa {{ card.rate }}% ao mes</p>
              </div>
            </div>
            <div class="row" style="margin-bottom: 17px;">
              <div class="col align-self-start">
                <small>
                  *Sujeito a analise de crédito
                </small>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <button type="button" class="btn btn-outline-danger" (click)="chamadaPreAnalise()">Simular</button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>

    <!-- Compras parcelas-->
    <div class="row card-parcel-payment">
      <div class="col">
        <div class="card-body">
          <div class="row">
            <div class="col align-self-start">
              <p class="card-title specialOffer">Compras Parceladas</p>
            </div>
          </div>
          <div class="row">
            <div class="col align-self-start">
              <p class="card-text" style="font-weight: 500;">
                Faca compras em lojas de diversos segmentos com condicoes especiais.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 align-self-center">
        <button
          [routerLink]="['/products/simulation']"
          type="button"
          class="btn btn-outline-danger button-parcel-payment"
          (click)="chamadaPreAnalise()"
        >
          Simular
        </button>
      </div>
    </div>
    <!--OFERTAS ESPECIAIS-->
    <div class="row">
      <div class="col">
        <span class="specialOffer" style="margin-top: 3%">Ofertas Especiais</span>
      </div>
    </div>
    <owl-carousel-o [options]="customOptionsDesktop">
      <ng-template carouselSlide *ngFor="let card of ofertaEspecial">
        <div class="card shadow card-with-steper" style="padding: 0px;">
          <img src="./assets/flag-card.svg" alt="" class="flag-card" />
          <div class="card-body">
            <div class="row" style="margin-bottom: 9%;">
              <div class="col-5 align-self-center">
                <div class="iconSpecialOffer"></div>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-center">
                <h3 class="specialOffer" style="text-align: center;">
                  {{ card.title }}
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="line" style="margin-bottom: 9%; margin-left: 12%;"></div>
            </div>
            <p class="card-text" style="font-weight: 500; margin-bottom: 0px;">
              {{ card.text }}
            </p>
          </div>
          <div
            style=" background-color: #cc0000 !important; height: 50px; width: 100%; border-bottom-right-radius: 6px; border-bottom-left-radius: 6px;"
          >
            <div class="row">
              <div
                class="col"
                style="color: white; color: white;position: relative; top: 16px; right: -12px;"
                (click)="chamadaPreAnalise()"
              >
                Simular
              </div>
              <div class="col" style="color: white; ">
                <img src="./assets/arrow-card.svg" alt="" class="arrow-button" />
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>

  <div class="body container d-none d-md-block" *ngIf="uniqueProposal.exist">
    <div class="row">
      <div class="col breadcrumbs">
        <small>
          Home
        </small>
      </div>
    </div>

    <!-- Proposta com pendencia -->
    <div *ngIf="!hiddenProposal" class="row">
      <div class="col">
        <span style="margin-left: 50px;">
          Propostas com pendências
        </span>
      </div>
    </div>

    <div *ngIf="!hiddenProposal" class="row">
      <div class="col">
        <div class="card shadow card-with-steper">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-10 align-self-start">
                    <p class="card-title specialOffer" style="margin-bottom: 0px;font-size: 15px; font-weight: bold;">
                      {{ uniqueProposal.title }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-3 align-self-start">
                <img
                  style="cursor: pointer; width: 20px; position: absolute; bottom: -15px; right: 15px;"
                  (click)="deleteArray(i)"
                  src="./assets/images/ic_lixeira.svg"
                />
              </div>
            </div>

            <div class="row" style="margin-bottom: 8%;">
              <div class="col-5 align-self-start">
                <small>Iniciado em {{ uniqueProposal.date }}</small>
              </div>

              <div class="col">
                <div class="row">
                  <div class="col-2" style="padding-right: 0;">
                    <img class="icon-status" src="./assets/images/ic_check.svg" />
                  </div>
                  <div class="col" style="font-size: 14px; padding-left: 0px;">
                    Foto do RG
                  </div>
                </div>
                <div class="row">
                  <div class="col-2" style="padding-right: 0;">
                    <img class="icon-status" src="./assets/images/ic_exclamation.svg" />
                  </div>
                  <div class="col" style="font-size: 14px; padding-left: 0px;">
                    Comprovante de residência
                  </div>
                </div>
                <div class="row">
                  <div class="col-2" style="padding-right: 0;">
                    <img class="icon-status" src="./assets/images/ic_exclamation.svg" />
                  </div>
                  <div class="col" style="font-size: 14px; padding-left: 0px;">
                    Insenção tarifa de cadastro
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <button type="button" class="btn btn-outline-danger">Enviar Documentos</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card shadow card-with-steper">
          <div class="card-body">
            <img class="aro-paper" src="./assets/Line.svg" alt="" />
            <img class="paper-icon" src="./assets/ic_paper_stack.svg" />
            <p style="font-weight: 500; margin-left: auto; margin-right: auto; width: 74%;">
              Não há outras propostas com pendência
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Outras propostas em aberto -->
    <div class="row">
      <div class="col">
        <span style="margin-left: 50px;">
          Outras Propostas
        </span>
      </div>
    </div>

    <!--   <div class="row" *ngIf = "otherOpenProposal.length <= 2 ">
      <div class="col" *ngFor="let card of otherOpenProposal">
        <div class="card shadow card-with-steper">
          <div class="card-body">
            <div class="row" style="margin-bottom: 33px;">
              <div class="col-5">
                <div class="row">
                  <div class="col-10 align-self-start">
                    <p class="card-title specialOffer" style="margin-bottom: 0px;font-size: 15px; font-weight: bold;">
                      {{ card.title }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-7">
                <img
                  style="width: 20px; position: absolute; bottom: -15px; right: 15px;"
                  (click)="deleteArray(i)"
                  src="./assets/images/ic_lixeira.svg"
                  *ngIf="trash"
                />
                <div class="row" *ngIf="!trash">
                  <div class="col-2" style="padding-right: 0;">
                    <img class="icon-status" src="./assets/images/ic_exclamation.svg" />
                  </div>
                  <div class="col" style="font-size: 14px; padding-left: 0px;">
                    Aguardando aprovação
                  </div>
                </div>
              </div>
            </div>

            <div class="row" style="margin-bottom: 8%;">
              <div class="col-5 align-self-start">
                <div class="row" style=" margin-bottom: 38px;">
                  <div class="col">
                    <small>Iniciado em {{ card.date }}</small>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="row">
                      <div class="col" style="font-size: 13px;">
                        {{ card.parcel }}x <b>R${{ card.price }}</b>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col" style="font-size: 13px;">
                        Total de <b>R$ {{ card.totalPrice }}</b>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-2" style="padding-right: 0;">
                    <img class="icon-status" src="./assets/images/ic_check.svg" />
                  </div>
                  <div class="col" style="font-size: 14px; padding-left: 0px;">
                    Simulação
                  </div>
                </div>
                <div class="row">
                  <div class="col-2" style="padding-right: 0;">
                    <img class="icon-status" src="./assets/images/ic_check.svg" />
                  </div>
                  <div class="col" style="font-size: 14px; padding-left: 0px;">
                    Cadastro
                  </div>
                </div>
                <div class="row">
                  <div class="col-2" style="padding-right: 0;">
                    <img class="icon-status" src="./assets/images/ic_check.svg" />
                  </div>
                  <div class="col" style="font-size: 14px; padding-left: 0px;">
                    Formalização
                  </div>
                </div>
                <div class="row">
                  <div class="col-2" style="padding-right: 0;">
                    <img class="icon-status" src="./assets/images/ic_check.svg" />
                  </div>
                  <div class="col" style="font-size: 14px; padding-left: 0px;">
                    Finalização
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <button type="button" class="btn btn-outline-danger">Exibir detalhes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <div class="row">
      <div class="col">
        <!-- Carousel -->
        <owl-carousel-o [options]="customOptionsDesktop">
          <ng-template carouselSlide *ngFor="let card of otherOpenProposal; let i = index">
            <div class="col">
              <div class="card shadow card-with-steper">
                <div class="card-body">
                  <div class="row" style="margin-bottom: 33px;">
                    <div class="col-5">
                      <div class="row">
                        <div class="col-10 align-self-start">
                          <p
                            class="card-title specialOffer"
                            style="margin-bottom: 0px;font-size: 15px; font-weight: bold;"
                          >
                            {{ card.title }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-7">
                      <img
                        style="width: 20px; position: absolute; bottom: -15px; right: 15px;"
                        (click)="deleteArray(i)"
                        src="./assets/images/ic_lixeira.svg"
                        *ngIf="trash"
                      />
                      <div class="row" *ngIf="!trash">
                        <div class="col-2" style="padding-right: 0;">
                          <img class="icon-status" src="./assets/images/ic_exclamation.svg" />
                        </div>
                        <div class="col" style="font-size: 14px; padding-left: 0px;">
                          Aguardando aprovação
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row" style="margin-bottom: 8%;">
                    <div class="col-5 align-self-start">
                      <div class="row" style=" margin-bottom: 38px;">
                        <div class="col">
                          <small>Iniciado em {{ card.date }}</small>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <div class="col" style="font-size: 13px;">
                              {{ card.parcel }}x <b>R${{ card.price }}</b>
                            </div>
                          </div>
                          <div class="row">
                            <div class="col" style="font-size: 13px;">
                              Total de <b>R$ {{ card.totalPrice }}</b>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col">
                      <div class="row">
                        <div class="col-2" style="padding-right: 0;">
                          <img class="icon-status" src="./assets/images/ic_check.svg" />
                        </div>
                        <div class="col" style="font-size: 14px; padding-left: 0px;">
                          Simulação
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2" style="padding-right: 0;">
                          <img class="icon-status" src="./assets/images/ic_check.svg" />
                        </div>
                        <div class="col" style="font-size: 14px; padding-left: 0px;">
                          Cadastro
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2" style="padding-right: 0;">
                          <img class="icon-status" src="./assets/images/ic_check.svg" />
                        </div>
                        <div class="col" style="font-size: 14px; padding-left: 0px;">
                          Formalização
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-2" style="padding-right: 0;">
                          <img class="icon-status" src="./assets/images/ic_check.svg" />
                        </div>
                        <div class="col" style="font-size: 14px; padding-left: 0px;">
                          Finalização
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col align-self-start">
                      <button type="button" class="btn btn-outline-danger">Exibir detalhes</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
  </div>
</div>
