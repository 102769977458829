<br />
<div class="mt-5">
  <app-proposal-status-information *ngIf="isStageAnalysisCD === true" [stageProposal]="isStageAnalysisCD">
  </app-proposal-status-information>
</div>

<span *ngIf="!isStageAnalysisCD">
  <ng-container *ngIf="comboParams$ | async as comboParams">
    <div class="row mt-5">
      <div class="col-12 col-md-12 introdution mt-3">
        <h1 class="introdution">{{ 'STEP-REGISTER-INTRODUCTION' | translate }}</h1>
      </div>
    </div>

    <!-- PERSONAL DATA SECTION -->
    <div
      class="section-container d-flex flex-column justify-content-between minHeight"
      [id]="registerSections.PERSONAL_DATA"
    >
      <app-personal-data
        [pos]="getPos(registerSections.PERSONAL_DATA)"
        (toStart)="listenFormChange($event, 1)"
        [comboParams]="comboParams"
        [proposalDataRes]="proposalDataRes"
        [setPersistedData]="setPersistedData"
      ></app-personal-data>

      <div
        class="circle"
        (click)="scrollToNextSection(registerSections.PERSONAL_DATA)"
        appTagger
        [event_category]="event_category"
        event_label="próximo passo"
      >
        <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
      </div>
    </div>

    <!-- ADDRESS DATA SECTION -->
    <div class="section-container d-flex flex-column justify-content-between" [id]="registerSections.ADDRESS_DATA">
      <app-address-data
        [pos]="getPos(registerSections.ADDRESS_DATA)"
        (toStart)="listenFormChange($event, 2)"
        [comboParams]="comboParams"
        [proposalDataRes]="proposalDataRes"
        [setPersistedData]="setPersistedData"
      ></app-address-data>

      <div
        class="circle"
        (click)="scrollToNextSection(registerSections.ADDRESS_DATA)"
        appTagger
        [event_category]="event_category"
        event_label="próximo passo"
      >
        <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
      </div>
    </div>

    <!-- TRAVELER DATA SECTION -->
    <div
      *ngIf="proposalData?.isTravelSubSegment"
      class="section-container d-flex flex-column justify-content-between"
      [class.minHeight]="mainClient?.person?.type === personsType.PJ"
      [id]="registerSections.TRAVELER_DATA"
    >
      <app-traveler-data
        [pos]="getPos(registerSections.TRAVELER_DATA)"
        [comboParams]="comboParams"
        (toStart)="listenFormChange($event, 3)"
      ></app-traveler-data>

      <div
        class="circle"
        (click)="scrollToNextSection(registerSections.TRAVELER_DATA)"
        appTagger
        [event_category]="event_category"
        event_label="próximo passo"
      >
        <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
      </div>
    </div>

    <ng-container *ngIf="mainClientData?.person?.type === personsType.PJ">
      <!-- REPRESENTANT DATA SECTION -->
      <div
        class="section-container d-flex flex-column justify-content-between"
        [id]="registerSections.REPRESENTANT_DATA"
      >
        <app-representant-data
          [pos]="getPos(registerSections.REPRESENTANT_DATA)"
          (toStart)="listenFormChange($event, 4)"
          [comboParams]="comboParams"
          [setPersistedData]="setPersistedData"
          [event_category]="event_category"
        ></app-representant-data>

        <div
          class="circle"
          (click)="scrollToNextSection(registerSections.REPRESENTANT_DATA)"
          appTagger
          [event_category]="event_category"
          event_label="próximo passo"
        >
          <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
        </div>
      </div>

      <!-- REPRESENTANT ADDRESS DATA SECTION -->
      <div
        class="section-container d-flex flex-column justify-content-between"
        [class.minHeight]="proposalData?.isTravelSubSegment"
        [id]="registerSections.REPRESENTANT_ADDRESS_DATA"
      >
        <app-representant-address-data
          [pos]="getPos(registerSections.REPRESENTANT_ADDRESS_DATA)"
          (begin)="listenFormChange($event, 5)"
          [setPersistedData]="setPersistedData"
          [comboParams]="comboParams"
          [event_category]="event_category"
        >
        </app-representant-address-data>

        <div
          class="circle"
          (click)="scrollToNextSection(registerSections.REPRESENTANT_ADDRESS_DATA)"
          appTagger
          [event_category]="event_category"
          event_label="próximo passo"
        >
          <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
        </div>
      </div>
    </ng-container>

    <!-- ADDITIONAL DATA SECTION -->
    <div
      class="section-container d-flex flex-column justify-content-between"
      [class.minHeight]="!showLgpdSection || proposalData?.liquidationTypeCode === liquidationTypes.DEBITO"
      [id]="registerSections.OBSERVATION_DATA"
    >
      <app-observation-data
        [pos]="getPos(registerSections.OBSERVATION_DATA)"
        [proposal]="proposalData"
        (begin)="listenFormChange($event, 6)"
      >
      </app-observation-data>
      <div class="ml-3" *ngIf="proposal?.proposalData?.product === productCode.CDC">
        <div class="position-section">
          <app-header-section
            [position]="getPos(registerSections.CERTIFICATE_AGENTE)?.pos"
            [size]="getPos(registerSections.CERTIFICATE_AGENTE)?.size"
          ></app-header-section>
        </div>

        <ng-container *ngIf="proposal?.proposalData?.product === productCode.CDC">
          <div *ngIf="certifiedAgents?.length">
            <div [formGroup]="formAgent">
              <mat-form-field class="col-12 certified-agent col-md-7 p-0 mb-2">
                <mat-label> {{ 'Certified Agent' | translate }}</mat-label>
                <app-search-input
                  valueFrom="agentCertifiedNumber"
                  label="agentName"
                  [items]="certifiedAgents | orderBy: 'agentName'"
                  formControlName="certifiedAgentCode"
                  appTagger
                  [event_category]="event_category"
                  [event_action]="Select"
                  event_label="agente certificado"
                >
                </app-search-input>
              </mat-form-field>
            </div>
          </div>

          <div class="col-12 p-0" *ngIf="!certifiedAgents?.length">
            <div class="no-agents mt-5">
              <div class="row no-gutters">
                <div class="col-1 d-flex justify-content-center">
                  <mat-icon>error</mat-icon>
                </div>
                <div class="col-11">
                  <p>{{ 'STEP-CONFIRMATION-WITHOUT-CERTIFIED-AGENT' | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
      <div
        class="circle"
        *ngIf="
          proposalData?.liquidationTypeCode === liquidationTypes.DEBITO ||
          (mainClient?.person?.type === personsType.PF && showLgpdSection)
        "
        (click)="scrollToNextSection(registerSections.OBSERVATION_DATA)"
        appTagger
        [event_category]="event_category"
        event_label="próximo passo"
      >
        <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
      </div>
    </div>

    <!-- BACEN DATA-->
    <div
      *ngIf="proposalData?.liquidationTypeCode === liquidationTypes.DEBITO"
      class="section-container d-flex flex-column"
      [class.minHeight]="!showLgpdSection || mainClient?.person?.type === personsType.PJ"
      [id]="registerSections.BACEN_DATA"
    >
      <app-header-section
        [position]="getPos(registerSections.BACEN_DATA)?.pos"
        [size]="getPos(registerSections.BACEN_DATA)?.size"
        [titleSection]="'STEP-REGISTER-INTRODUCTION-BACEN'"
      >
      </app-header-section>
      <app-bacen-questions (bacenAnswers)="handleBacenAnswers($event)" [proposal]="proposalData"></app-bacen-questions>

      <div
        *ngIf="showLgpdSection && mainClient?.person?.type === personsType.PF"
        class="circle"
        (click)="scrollToNextSection(registerSections.BACEN_DATA)"
        appTagger
        [event_category]="event_category"
        event_label="próximo passo"
      >
        <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
      </div>
    </div>

    <!-- LGPD DATA SECTION -->
    <div
      *ngIf="showLgpdSection && mainClient?.person?.type === personsType.PF"
      class="section-container d-flex flex-column lgpd-section"
      [id]="registerSections.LGPD_DATA"
    >
      <app-header-section
        [position]="getPos(registerSections.LGPD_DATA)?.pos"
        [size]="getPos(registerSections.LGPD_DATA)?.size"
        [titleSection]="'STEP-REGISTER-INTRODUCTION-LGPD'"
      >
      </app-header-section>
      <app-lgpd-form
        #lgpdForm
        [accessType]="lgpdConsentAccessType.PROPOSAL_REGISTER"
        (lgpdAnsweredToRegister)="onFormLgpdAnswered()"
        (loaded)="onFormLgpdLoaded($event)"
      ></app-lgpd-form>
    </div>

    <div class="row actions">
      <div
        *ngIf="
          (flagBackToSimulation$ | async)?.flagBackToSimulation ||
          (flagBackToSimulation$ | async)?.flagBackToSimulation === null
        "
        class="col-12 col-md-6 mb-3 back-button"
      >
        <app-outlined-button
          (clickEmitter)="backToSimulation()"
          appTagger
          [event_category]="event_category"
          event_label="voltar para simulação"
        >
          {{ 'STEP-REGISTER-BACK-TO-SIMULATION' | translate }}
        </app-outlined-button>
      </div>

      <div
        [ngClass]="{
          col: !(flagBackToSimulation$ | async)?.flagBackToSimulation,
          'col-12 col-md-6 mb-3': (flagBackToSimulation$ | async)?.flagBackToSimulation
        }"
      >
        <button
          class="btn-block mb-2 button-save-proposal"
          mat-flat-button
          color="primary"
          (click)="backToOpenProposals()"
          *ngIf="proposal?.proposalData?.product === productCode.CDC && !certifiedAgents?.length; else elseBlock"
          appTagger
          [event_category]="event_category"
          event_label="continuar para formalização"
        >
          {{ 'STEP-CONFIRMATION-SAVE-PROPOSAL' | translate }}
        </button>
        <ng-template #elseBlock>
          <app-filled-button
            (click)="saveRegister($event)"
            [disabled]="form.invalid || !lgpdFormIsValid || !bacenFormIsValid || formAgent.invalid"
            appTagger
            [event_category]="event_category"
            event_label="enviar dados"
          >
            {{ 'STEP-REGISTER-PROCEED-TO-CONFIRMATION' | translate }}
          </app-filled-button>
        </ng-template>
      </div>
    </div>
  </ng-container>
</span>
