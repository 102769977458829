export class BiometryTutorialSteps {
  type?: BiometryStepsType;
  title?: string;
  steps: Array<BiometrySteps>;
}

export enum BiometryStepsType {
  BIOMETRY = 1,
  SIGNATURE = 2
}

export class BiometrySteps {
  step: number;
  text: string;
  description?: string;
  img?: string;
}
