import { AlertMaterialComponent } from './../../../dialog/alert/alertMaterial.component';
import { Component, OnInit } from '@angular/core';

import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-home-page-body',
  templateUrl: './home-page-body.component.html',
  styleUrls: ['./home-page-body.component.scss']
})
export class HomePageBodyComponent implements OnInit {
  // variavel de configuracao do carrousel
  public customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 3
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false
  };

  public customOptionsDesktopPersonalOffer: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<img src="https://img.icons8.com/material-rounded/24/000000/chevron-left.png"/>',
      '<img src="https://img.icons8.com/material-rounded/24/000000/chevron-right.png"/>'
    ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    },
    nav: true
  };

  public customOptionsDesktopSpecialOffers: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<img src="https://img.icons8.com/material-rounded/24/000000/chevron-left.png"/>',
      '<img src="https://img.icons8.com/material-rounded/24/000000/chevron-right.png"/>'
    ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 3
      }
    },
    nav: true
  };

  // variavel para mostrar propostas em aberto ou ofertas personalizadas
  public openProposal = true;

  public propostaAberto: Array<any> = [
    {
      title: 'Etna1',
      date: '20/04/2020',
      parcel: '18',
      price: '24,49'
    },
    {
      title: 'Etna1',
      date: '20/04/2020',
      parcel: '18',
      price: '24,49'
    },
    {
      title: 'Etna1',
      date: '20/04/2020',
      parcel: '18',
      price: '24,49'
    }
  ];

  public ofertaPersonalizada: Array<any> = [
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    },
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    },
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    }
  ];

  public ofertaEspecial: Array<any> = [
    {
      title: 'Magazine Luiza',
      text: 'Desconto de até 20% no mês de maio'
    },
    {
      title: 'Magazine Luiza',
      text: 'Desconto de até 20% no mês de maio'
    },
    {
      title: 'Magazine Luiza',
      text: 'Desconto de até 20% no mês de maio'
    }
  ];
  // variavel para abrir pag para quando nao ha oferta
  public withoutOffers = true;
  constructor(public router: Router, public dialog: MatDialog) {}

  ngOnInit() {}

  public deleteArray(i): void {
    const dialog = this.dialog.open(AlertMaterialComponent, {
      width: '500px',
      data: {
        title: 'Quer mesmo apagar esta proposta?',
        text: 'Você não vai conseguir recuperar a proposta e pode perder as condições simuladas.',
        numberButton: '2',
        labelButton: [{ text: 'Manter proposta', color: 'danger' }, { text: 'Apagar', color: 'light' }]
      }
    });

    dialog.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.propostaAberto.splice(i, 1);
      }
    });
  }

  public chamadaPreAnalise(): void {
    const preAnalise = true;

    if (preAnalise) {
      this.dialog.open(AlertMaterialComponent, {
        width: '500px',
        data: {
          title: 'Sem ofertas disponíveis',
          text: 'Não temos ofertas disponíveis para esse CPF no momento.',
          numberButton: '1',
          labelButton: [{ text: 'Ok', color: 'danger' }]
        }
      });
    }
  }
}
