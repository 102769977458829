<div class="container signup-data-container">
  <app-arrow-back (click)="loc.back()"></app-arrow-back>
  <p class="title">{{ 'SIGNUP-DATA-TITLE' | translate }}</p>
  <p class="subtitle">
    {{ 'SIGNUP-DATA-SUBTITLE' | translate }}
  </p>

  <form [formGroup]="signUpForm">
    <ng-container *ngIf="!isAccountHolder">
      <div class="col-12 input-row">
        <mat-form-field color="black" appearance="legacy" class="input">
          <mat-label>{{ 'PERSONAL-DOCUMENT-NUMBER' | translate }}*</mat-label>
          <input
            autocomplete="off"
            mask="{{ $any(pioneerMasks).documentNumber.mask }}"
            type="text"
            matInput
            formControlName="documentNumber"
            maxlength="14"
          />
          <img
            [hidden]="signUpForm.get('documentNumber').value === '' || !signUpForm.get('documentNumber').valid"
            class="valid-icon"
            src="./assets/icon-green.svg"
            alt="icon right green"
            matSuffix
          />
          <img
            [hidden]="signUpForm.get('documentNumber').untouched || signUpForm.get('documentNumber').valid"
            class="invalid-icon"
            src="./assets/atention-red.svg"
            alt="icon right red"
            matSuffix
          />
          <mat-error *ngIf="!signUpForm.get('documentNumber').valid">
            {{ 'PERSONAL-DOCUMENT-NUMBER-ERROR-MSG' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 input-row">
        <mat-form-field color="black" appearance="legacy" class="input">
          <mat-label>{{ 'NICK-NAME' | translate }}</mat-label>
          <input
            autocomplete="off"
            type="text"
            matInput
            class="input-with-icon"
            formControlName="nickName"
            (keypress)="omitSpecialChar($event)"
            maxlength="20"
          />
          <img
            [hidden]="signUpForm.get('nickName').value === '' || !signUpForm.get('nickName').valid"
            class="valid-icon"
            src="./assets/icon-green.svg"
            alt="icon right green"
            matSuffix
          />
          <img
            [hidden]="signUpForm.get('nickName').untouched || signUpForm.get('nickName').valid"
            class="invalid-icon"
            src="./assets/atention-red.svg"
            alt="icon right red"
            matSuffix
          />
          <mat-error *ngIf="!signUpForm.get('nickName').valid">
            {{ 'NICK-NAME-INPUT-ERROR-MSG' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 input-row" *ngIf="signUpForm.get('fullName')">
        <mat-form-field color="black" appearance="legacy" class="input">
          <mat-label>{{ 'FULL-NAME' | translate }}*</mat-label>
          <input
            autocomplete="off"
            type="text"
            matInput
            class="input-with-icon"
            formControlName="fullName"
            maxlength="60"
            (keypress)="omitSpecialChar($event)"
          />
          <img
            [hidden]="signUpForm.get('fullName').value === '' || !signUpForm.get('fullName').valid"
            class="valid-icon"
            src="./assets/icon-green.svg"
            alt="icon right green"
            matSuffix
          />
          <img
            [hidden]="signUpForm.get('fullName').untouched || signUpForm.get('fullName').valid"
            class="invalid-icon"
            src="./assets/atention-red.svg"
            alt="icon right red"
            matSuffix
          />
          <mat-error *ngIf="!signUpForm.get('fullName').valid">
            {{ 'FULL-NAME-INPUT-ERROR-MSG' | translate }}
          </mat-error>
        </mat-form-field>
        <p class="fixed-name-guidance">{{ 'FIXED-NAME-GUIDANCE' | translate }}</p>
      </div>

      <div class="col-12 input-row" *ngIf="signUpForm.get('dateOfBirth') && currentCountry !== 'arg'">
        <mat-form-field color="black" appearance="legacy" class="input">
          <mat-label>{{ 'DATE-OF-BIRTH' | translate }}*</mat-label>
          <input
            autocomplete="off"
            type="text"
            matInput
            class="input-with-icon"
            formControlName="dateOfBirth"
            mask="{{ $any(pioneerMasks).date.mask }}"
          />
          <img
            [hidden]="signUpForm.get('dateOfBirth').value === '' || !signUpForm.get('dateOfBirth').valid"
            class="valid-icon"
            src="./assets/icon-green.svg"
            alt="icon right green"
            matSuffix
          />
          <img
            [hidden]="signUpForm.get('dateOfBirth').untouched || signUpForm.get('dateOfBirth').valid"
            class="invalid-icon"
            src="./assets/atention-red.svg"
            alt="icon right red"
            matSuffix
          />
          <mat-error *ngIf="!signUpForm.get('dateOfBirth').valid">
            {{ 'DATE-OF-BIRTH-INPUT-ERROR-MSG' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 input-row">
        <mat-form-field color="black" appearance="legacy" class="input">
          <mat-label>{{ 'EMAIL' | translate }}*</mat-label>
          <input autocomplete="off" type="text" matInput class="input-with-icon" formControlName="email" />
          <img
            [hidden]="signUpForm.get('email').value === '' || !signUpForm.get('email').valid"
            class="valid-icon"
            src="./assets/icon-green.svg"
            alt="icon right green"
            matSuffix
          />
          <img
            [hidden]="signUpForm.get('email').untouched || signUpForm.get('email').valid"
            class="invalid-icon"
            src="./assets/atention-red.svg"
            alt="icon right red"
            matSuffix
          />
          <mat-error *ngIf="!signUpForm.get('email').valid">
            {{ 'EMAIL-INPUT-ERROR-MSG' | translate }}
          </mat-error>
        </mat-form-field>
      </div>

      <div class="col-12 input-row">
        <mat-form-field color="black" appearance="legacy" class="input">
          <mat-label>{{ 'CELL-PHONE' | translate }}*</mat-label>
          <input
            autocomplete="off"
            mask="{{ $any(pioneerMasks).cellPhone.mask }}"
            type="text"
            matInput
            class="input-with-icon"
            formControlName="cellPhone"
          />
          <img
            [hidden]="signUpForm.get('cellPhone').value === '' || !signUpForm.get('cellPhone').valid"
            class="valid-icon"
            src="./assets/icon-green.svg"
            alt="icon right green"
            matSuffix
          />
          <img
            [hidden]="signUpForm.get('cellPhone').untouched || signUpForm.get('cellPhone').valid"
            class="invalid-icon"
            src="./assets/atention-red.svg"
            alt="icon right red"
            matSuffix
          />
          <mat-error *ngIf="!signUpForm.get('cellPhone').valid">
            {{ 'CELL-PHONE-INPUT-ERROR-MSG' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
    </ng-container>
    <div class="col-12 input-row">
      <mat-label class="terms-label">{{ 'TERMS-AND-CONDITIONS' | translate }}</mat-label>
      <div class="modal-scroll-area" (scroll)="handleScroll($event)">
        <div class="text-term" [innerHTML]="terms"></div>
      </div>
    </div>

    <div class="col-12 terms-agreement-checkbox">
      <mat-checkbox
        [disabled]="!scrollComplete"
        (change)="handleTermsAndAgreementsCheckboxChange($event.checked)"
        formControlName="acceptTerms"
        color="primary"
        >{{ 'ACCEPT-TERMS-AND-CONDITIONS' | translate }}</mat-checkbox
      >
    </div>
  </form>

  <app-lgpd-form
    #lgpdForm
    (loaded)="onLgpdLoaded($event)"
    [documentNumber]="this.signUpForm.get('documentNumber').value"
    [accessType]="LgpdConsentAccessType.REGISTER"
    (lgpdAnsweredToRegister)="onFormLgpdAnswered()"
  ></app-lgpd-form>

  <div class="col-12 input-row pb-5">
    <button
      mat-button
      color="primary"
      class="submit-btn"
      [disabled]="!signUpForm.valid || !lgpdFormIsValid"
      (click)="submit()"
    >
      {{ 'SIGNUP-DATA-BTN-NAME' | translate }}
    </button>
  </div>
</div>
