<app-header-section [position]="pos.pos" [size]="pos.size" titleSection="STEP-REGISTER-INTRODUCTION-TRAVELER">
</app-header-section>

<!-- TRAVELER ADDITIONAL -->
<div class="row">
  <div class="col-12 col-md-12 mb-4">
    <div *ngIf="form" [formGroup]="form">
      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <mat-form-field *ngIf="!!form.get('name')">
            <mat-label> {{ 'ESIGN-NAME' | translate }} </mat-label>
            <app-custom-input
              class="input-with-icon"
              [required]="true"
              formControlName="name"
              appTagger
              [event_action]="Insert"
              [event_category]="event_category"
              event_label="viajante - nome"
            ></app-custom-input>
            <mat-error *ngIf="form.get('name').touched && form.get('name').invalid">{{
              'STEP-REGISTER-NAME-ERROR' | translate
            }}</mat-error>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-6 mb-4">
          <mat-form-field *ngIf="!!form.get('birthDate')">
            <mat-label> {{ 'STEP-REGISTER-BIRTH-DATE' | translate }}</mat-label>
            <app-custom-input
              [required]="true"
              [customMask]="$any(pioneerMasks).date.mask"
              formControlName="birthDate"
              appTagger
              [event_action]="Insert"
              [event_category]="event_category"
              event_label="viajante - data de nascimento"
            >
            </app-custom-input>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <mat-form-field *ngIf="!!form.get('documentNumber')">
            <mat-label> {{ 'STEP-REGISTER-DOCUMENT-NUMBER' | translate }} </mat-label>
            <app-custom-input
              [required]="true"
              [customMask]="$any(pioneerMasks).documentNumber.mask"
              formControlName="documentNumber"
              appTagger
              [event_action]="Insert"
              [event_category]="event_category"
              event_label="viajante - cpf"
            ></app-custom-input>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-6 mb-4">
          <mat-form-field *ngIf="!!form.get('dateTravel')">
            <mat-label> {{ 'TRAVEL-DATE' | translate }}</mat-label>
            <app-custom-input
              [required]="true"
              [customMask]="$any(pioneerMasks).date.mask"
              formControlName="dateTravel"
              appTagger
              [event_action]="Insert"
              [event_category]="event_category"
              event_label="data da viagem"
            >
            </app-custom-input>
          </mat-form-field>
        </div>
      </div>

      <!-- add traveler additional -->
      <div
        formArrayName="additionalTravelersList"
        *ngFor="let item of form.get('additionalTravelersList')['controls']; let i = index"
      >
        <div [formGroupName]="i">
          <div class="row">
            <div class="col-12 col-md-12 mb-4">
              {{ 'TRAVELER-ADDITIONAL' | translate }} {{ i + 1 }}
              <button
                (click)="deleteTravelerAdditional(i, item.get('id').value)"
                class="float-right"
                appTagger
                [event_category]="event_category"
                event_label="excluir viajante"
                mat-button
              >
                <img class="proposal-icon" src="./assets/images/ic_lixeira.svg" alt="" />
              </button>
              <hr />
            </div>

            <div class="col-12 col-md-6 mb-4">
              <mat-form-field *ngIf="!!form.get('name')">
                <mat-label> {{ 'ESIGN-NAME' | translate }} </mat-label>
                <app-custom-input
                  class="input-with-icon"
                  [required]="true"
                  formControlName="name"
                  appTagger
                  [event_action]="Insert"
                  [event_category]="event_category"
                  event_label="viajante adicional - nome"
                ></app-custom-input>
                <mat-error *ngIf="form.get('name').touched && form.get('name').invalid">{{
                  'STEP-REGISTER-NAME-ERROR' | translate
                }}</mat-error>
              </mat-form-field>
            </div>

            <div class="col-12 col-md-6 mb-4">
              <mat-form-field *ngIf="!!form.get('birthDate')">
                <mat-label> {{ 'STEP-REGISTER-BIRTH-DATE' | translate }}</mat-label>
                <app-custom-input
                  [required]="true"
                  [customMask]="$any(pioneerMasks).date.mask"
                  formControlName="birthDate"
                  appTagger
                  [event_action]="Insert"
                  [event_category]="event_category"
                  event_label="viajante adicional - data de nascimento"
                >
                </app-custom-input>
              </mat-form-field>
            </div>
          </div>

          <div class="row">
            <div class="col-12 col-md-6 mb-4">
              <mat-form-field *ngIf="!!form.get('documentNumber')">
                <mat-label> {{ 'STEP-REGISTER-DOCUMENT-NUMBER' | translate }} </mat-label>
                <app-custom-input
                  [required]="true"
                  [customMask]="$any(pioneerMasks).documentNumber.mask"
                  formControlName="documentNumber"
                  appTagger
                  [event_action]="Insert"
                  [event_category]="event_category"
                  event_label="viajante adicional - cpf"
                ></app-custom-input>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- BTN ADD TRAVELER -->
<div class="row">
  <div class="col-12 col-md-12 mb-3">
    <button
      mat-stroked-button
      class="btn-block outlined-button"
      (click)="addTraveler()"
      [disabled]="form?.get('additionalTravelersList')['controls']?.length >= travelersQty || form.invalid"
      appTagger
      [event_category]="event_category"
      event_label="adicionar viajante"
    >
      {{ 'BTN-ADD-TRAVELER' | translate }}
    </button>
  </div>
</div>
