import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FinancialStatementComponent } from './financial-statement/financial-statement.component';
import { CanActivateRouteGuard } from './payments/guard/can-active-router.guard';
import { PaymentsComponent } from './payments/payments.component';
import { OpenProposalComponent } from './proposals/open-proposal.component';
import { ShowcaseComponent } from './showcase.component';
import { StoresComponent } from './stores/stores.component';
import { SubsegmentComponent } from './subsegment/subsegment.component';

const routes: Routes = [
  {
    path: '',
    component: ShowcaseComponent
  },
  {
    path: 'open-proposal',
    component: OpenProposalComponent
  },
  {
    path: 'payments',
    component: PaymentsComponent,
    canActivate: [CanActivateRouteGuard]
  },
  {
    path: 'management',
    loadChildren: () => import('./management-panel/management-panel.module').then(m => m.ManagementPanelModule)
  },
  {
    path: 'subsegment',
    component: SubsegmentComponent
  },
  {
    path: 'store-selection',
    component: StoresComponent
  },
  {
    path: 'financial-statement',
    component: FinancialStatementComponent
  },
  // NOTE: - Remove contracts and special-offers paths and components?
  // {
  //   path: 'contracts',
  //   component: ActiveContractsComponent
  // },
  // {
  //   path: 'special-offers',
  //   component: OffersComponent
  // },
  { path: '', redirectTo: '/showcase', pathMatch: 'full' },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ShowcaseRoutingModule {}
