<div class="main-container">
  <div class="row scroll">
    <div *ngIf="enableCancel" class="col-12 text-right">
      <span class="material-icons mx-auto col-1" (click)="close()">
        close
      </span>
    </div>

    <div *ngFor="let document of showList" class="photo-container shadow p-2 col-6">
      <button class="photo-delete-button">
        <img src="./assets/images/ic_lixeira.svg" (click)="deleteDocument(document.id)" alt="close icon" />
      </button>
      <img [src]="transformToSafeResource(document.data)" class="photo-document img-preview" />
    </div>
    <div *ngIf="this.showList.length < maxFiles || noLimits" class="photo-container shadow p-2 col-6">
      <button (click)="newImage()" class="photo-button-container shadow">
        <span class="photo-icon medium material-icons">camera_alt</span>
        <span>{{ 'NEW-PICTURE' | translate }}</span>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 mx-auto fixed-bottom">
      <div *ngIf="hasError">
        <h4 class="error-msg col-12">{{ msgError }}</h4>
      </div>
      <button class="save-button col-12" [disabled]="disableFinishButton()" (click)="save()">
        {{ 'SAVE-DOCUMENTATION' | translate }}
      </button>
    </div>
  </div>

  <app-document-scan-wrapper
    *ngIf="openDocumentUpload"
    class="camera-cl"
    [scanType]="scanType"
    (scanOutput)="handleScan($event)"
  ></app-document-scan-wrapper>
</div>
