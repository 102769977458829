import './hash-helper.ts';
export const environment = {
  env: '${APP_FLAG}',
  businessCode: '${APP_BUSINESS_CODE}',
  minimumExpireDays: '${LOGIN_MINIMUM_EXPIRE_DAYS}',
  timerSecondsRecoveryPassword: '${LOGIN_TIMER_SECONDS_RECOVERY_PASSWORD}',
  expireDays: '${LOGIN_EXPIRE_DAYS}',
  currentCountry: '${APP_COUNTRY}',
  currentLanguage: '${APP_LANGUAGE}',
  idleTimer: '${APP_IDLE_TIMER_LOJ}',
  crossedFluxExpirationTime: '${PIONEER_BIOMETRY_TRANSFERLINK_TTL_FRONTEND_MS}',
  perLivenessSDKURL: '${PERU_LIVENESS_SDK}',
  perAutoCaptureSDK: '${PERU_AUTOCAPTURE_SDK}',
  assetsPath: '${APP_ASSETS_PATH}',
  maxAttemptsSimulation: '${APP_SIMULATION_MAX_ATTEMPTS}',
  delayAttemptsSimulation: '${APP_SIMULATION_DELAY_ATTEMPTS}',
  production: true,
  pathClientAssets: 'santander',
  apiRootUrl: '/${APP_PATH}',
  urlBase: '${APP_URL_BASE}',
  urlOpenFinance: '${URL_OPEN_FINANCE}',
  pathCipher: '${APP_PATH_CIPHER}',
  crypto: {
    active: 'true',
    iv: '${APP_CRYPTO_IV}',
    key: '${APP_CRYPTO_KEY}'
  },

  key: '${APP_PUBLIC_KEY_RSA}',
  path: '/originacao-loj',

  tokenApiRootUrl: '/${APP_PATH}/token',
  apiKeyApiSecret: '${APP_KEY_ORIG_LOJ}',
  apiKeyApiSecretVisit: '${APP_KEY_ORIG_LOJ_VISIT}',
  urlNewArch: '',
  urlPortalParceiro: '',
  urlPropostas: '${URL_PROP}',
  urlCoreNovo: '${URL_CORE_NOVO}',
  urlCoreAntigo: '${URL_CORE_ANT}',
  urlCoreAntigoSCI: '/login/iframePrincipal.html?funcao=NSCI',
  urlCoreAntigoDoc: '/login/iframePrincipal.html?funcao=NSCIDOC',
  urlCoreAntigoMenu: '/login/iframePrincipal.html?funcao=index',
  urlConsultDoc: urlConsultDoc,
  urlDocPendentes: urlDocPendentes,
  urlCockPit: urlCockPit,
  urlMinuta: urlMinuta,
  urlSelecaoFase: urlSelecaoFase,
  expiredUrl: '',
  graphqlSchema: {
    version: '2'
  },
  featureToggle: {
    novoRoteamento: { active: false },
    voltarBaseadoEmPilha: { active: true },
    validateFileSumSize: { active: '${VALIDATE_FILE_SUM_SIZE}' },
    tagueamentoGA: { active: true }
  },
  useNPP: '${USE_NPP_PORTAL_LOJISTA}',
  mfa: {
    loj: '${PIONEER_MFA_PORTAL_LOJISTA_ENABLED}',
    active: '${PIONEER_MFA_ENABLED}'
  },

  kc_loj_clientid: '${APP_KC_BENS_CLIENTID}',
  kc_loj_clientid_visitor: '${APP_KC_BENS_CLIENTID_VISITOR}',
  kc_loj_clientsecret: '${APP_KC_BENS_CLIENTSECRET}',
  keycloak_realm: '${PIONEER_KEYCLOAK_REALM}',
  pathCipherNewGateway: 'cipherasym',
  newGateway: '${PIONEER_KEYCLOAK_ENABLED}'
};

export function urlConsultDoc(proposta: string, idPropostaFase: string, idFase: string, timesstamp: number) {
  // tslint:disable-next-line:max-line-length
  return `/centralDoc/consultaDoc.do?cd_programa=WEB_ATEND_RECEPTIVO&funcao=consultar&origem=coreNovo&padrao=ocorrencias&tipo=contrato&idPropostaFase=${idPropostaFase}&proposta=${proposta}&idfase=${idFase}&id_fase=${idFase}&tp_sub_padrao_ocor=F2&timestamp=${timesstamp}`;
}

export function urlDocPendentes(proposta: string, idPropostaFase: string, timesstamp: number) {
  // tslint:disable-next-line:max-line-length
  return `/aceite/relacaoDocCliente.do?tipo=cockpit-pendente&padrao=ocorrencias&origem=coreNovo&idPropostaFase=${idPropostaFase}&proposta=${proposta}&timestamp=${timesstamp}`;
}

export function urlCockPit(proposta: string, timesstamp: number) {
  // tslint:disable-next-line:max-line-length
  return `/cockPit/gestaoProposta.html?cd_programa=WEB_PROP_ANDA&gravafaselog_acao=0&cd_contrato=&origem=coreNovo&id_proposta=${proposta}&timestamp=${timesstamp}`;
}

export function urlMinuta(proposta: string, idPropostaFase: string, idFase: string) {
  // tslint:disable-next-line:max-line-length
  return `/minuta/minuta.html?retorno=resumo&origem=coreNovo&fase=&idfase=${idFase}&id_fase=${idFase}&id_prt_fase=${idPropostaFase}&padrao=1&tipo=&fluxo=&idModeloNegocio=&tp_minuta=M&id_proposta=${proposta}`;
}

export function urlValoresContratos(cdContrato, timesstamp: number) {
  // tslint:disable-next-line:max-line-length
  return `/atendimento/atendimento.html?fase=atendimento&cd_programa=WEB_ATEND_RECEPTIVO&padraoTela=receptivo&referbuild=v20190207194943&__action_type=continuar&action_type=&tp_filtro=C&cd_contrato=${cdContrato}&cd_contrato_inter=&ds_origem=selecao&&timestamp=${timesstamp}`;
}

export function urlSelecaoFase(proposta: string, timesstamp: number) {
  // tslint:disable-next-line:max-line-length
  return `/cockPit/gestaoProposta.html?cd_programa=WEB_PROP_ANDA&gravafaselog_acao=0&cd_contrato=&origem=selecaoFase&id_proposta=${proposta}&timestamp=${timesstamp}`;
}
