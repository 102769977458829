import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges, OnDestroy, OnInit, Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppState } from '@app/core/state';
import { FType } from '@app/core/state/login/login.reducer';
import { selectFtype } from '@app/core/state/login/login.selectors';
import { SignUpService } from '@app/signup/services/sign-up.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { of, Subject, timer } from 'rxjs';
import { first, map, repeatWhen, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-token-input',
  templateUrl: './token-input.component.html',
  styleUrls: ['./token-input.component.scss']
})
export class TokenInputComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() btnMessage: string;
  @Input() numberOfDigits = 4;
  @Input() notYoursText: string;
  @Input() tokenDestination: string;
  @Input() wrongCode: boolean;
  @Input() tokenDestinationMask: { [key: string]: string };
  @Input() tokenExpirationTime: number;
  @ViewChildren('digitInput') digitInputList: QueryList<ElementRef>;
  @Output() submitEmit: EventEmitter<string> = new EventEmitter();
  @Output() resendEmit: EventEmitter<any> = new EventEmitter();
  category: string;

  get isTimeover() {
    return this.secondsLeft < 1;
  }

  private readonly stopTimer$ = new Subject<void>();
  private readonly startTimer$ = new Subject<void>();
  public secondsLeft: number;
  public timeLeftText: string;
  public allowBtn = true;

  public naturalPerson: boolean;
  // public wrongCode: boolean;

  public digitsForm: FormGroup = this.fb.group({});
  public digitsList: number[] = [];

  public fType$ = this.store$.select(selectFtype).pipe(
    map(ftype => {
      if (ftype === FType.PASSWORDRECOVERY) {
        return '/portallojista/recuperarsenha/token';
      } else if (ftype === FType.SIGNUP) {
        return '/portallojista/primeiroacesso/token';
      }
    })
  );

  readonly FillingError: Tag = Tag.FillingError;
  readonly Insert: Tag = Tag.Insert;
  readonly click: Tag = Tag.Click;
  protected ngUnsubscribe: Subject<any> = new Subject();
  constructor(
    private fb: FormBuilder,
    private signUpService: SignUpService,
    private store$: Store<AppState>,
    public genTagger: GenTagger,
    private activatedRouter: ActivatedRoute,
    private translateService: TranslateService
  ) {
  }

  ngOnInit(): void {

    this.secondsLeft = this.tokenExpirationTime;
    this.calculateTimeLeft();
    this.setUpTimer();
    this.digitsForm.statusChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(data => {
      if (data === 'VALID' && this.digitsForm.valid) {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: this.Insert,
          event_label: 'token'
        });
      }
    });

    of(this.wrongCode)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if (data) {
          this.genTagger.setTag({
            event_category: this.category,
            event_action: this.FillingError,
            event_label: this.translateService.instant('CODE-ERROR-MSG')
          });
        }
      });

    this.fType$.pipe(first()).subscribe(resolvedCategory => {
      this.category = resolvedCategory
      this.genTagger.setTag({
        event_category: this.category,
        event_action: Tag.pgView,
        event_label: this.translateService.instant(this.title)
      });
    });

  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.numberOfDigits && changes.numberOfDigits) {
      this.createDigitsForm();
    }
  }

  ngAfterViewInit(): void {
    this.disableFieldsByDefault();
  }

  private disableFieldsByDefault(): void {
    this.digitInputList.forEach(e => {
      e.nativeElement.value = '';
      if (e !== this.digitInputList.first) {
        e.nativeElement.disabled = true;
      } else {
        e.nativeElement.focus();
      }
    });
  }

  /**
   * Fills the form group property with the proper number of
   * FormControls based on input of desired digits.
   */
  private createDigitsForm(): void {
    this.digitsList = Array.from(Array(this.numberOfDigits).keys());
    this.digitsList.forEach(digit => {
      this.digitsForm.addControl('digit' + digit, new FormControl(null, [Validators.required]));
    });
  }

  /**
   * In case user presses the delete key into an input that has no value, it selects the previous one
   * and deletes it's content; as long as the current input isn't the first of the list
   * @param digit input position
   * @param e keyboard pressed event
   */
  public isDeletePressed(digit: number, e: KeyboardEvent): void {
    if (e.key === 'Backspace' && digit > 0 && this.digitInputList.toArray()[digit].nativeElement.value === '') {
      this.deletePrevious(digit);
    }
  }
  /**
   * Disables all the inputs after the current digit
   * @param digit input position
   */
  private disableFollowingDigits(digit: number): void {
    const dArr = this.digitInputList.toArray();
    for (let i = 0; i < this.digitInputList.length; i++) {
      if (i > digit) {
        dArr[i].nativeElement.value = '';
        dArr[i].nativeElement.disabled = true;
      }
    }
  }
  /**
   * In case the selected input has no value,
   * disables and deletes the value of the previous position
   * @param digit input position
   */
  private deletePrevious(digit: number): void {
    const dArr = this.digitInputList.toArray();
    const last = dArr[digit - 1].nativeElement;
    dArr[digit].nativeElement.disabled = true;
    last.value = '';
    last.select();
  }
  /**
   * When the value of the input changes, validates if it was deleted or changed.
   * In the first case, disables the following digits if the input is not the last of the list.
   * In the second case, enables the following input and then selects it if the input is not the last
   * of the list.
   * @param value value of the input
   * @param digit input position
   */
  public digitChanged(value: string, digit: number): void {
    const dArr = this.digitInputList.toArray();
    const lastDigit = this.digitInputList.length - 1;

    if (digit !== lastDigit) {
      if (value) {
        dArr[digit + 1].nativeElement.disabled = false;
        dArr[digit + 1].nativeElement.focus();
      } else {
        this.disableFollowingDigits(digit);
      }
    } else {
      this.allowBtn = true;
    }
    this.signUpService.statusWrongCode$.next(false);
  }

  /**
   * Method to retrieve full code value from form controls
   */
  private getFullToken(): string {
    let code = '';
    Object.values(this.digitsForm.controls).forEach(control => {
      code += control.value;
    });
    return code;
  }

  public submitToken(): void {
    this.allowBtn = false;
    this.submitEmit.emit(this.getFullToken());
    this.stopTimer();
  }

  public resendToken(): void {
    this.signUpService.statusWrongCode$.next(false);
    this.resendEmit.emit('resendToken');
    this.digitsForm.reset();
    this.disableFieldsByDefault();
    this.secondsLeft = this.tokenExpirationTime;
    this.startTimer();
  }

  public doesntGetCode(): void {
    this.signUpService.statusWrongCode$.next(false);
    this.genTagger.setTag({
      event_category: this.category,
      event_action: this.click,
      event_label: this.translateService.instant('DOESNT-GET-CODE')
    });

    this.resendEmit.emit('doesntGetCode');

    this.digitsForm.reset();
    this.disableFieldsByDefault();
    this.secondsLeft = this.tokenExpirationTime;
    this.startTimer();
  }

  /**
   * Configures timer for countdown
   */
  private setUpTimer(): void {
    timer(0, 1000)
      .pipe(
        map(second => this.tokenExpirationTime - second),
        takeUntil(this.stopTimer$),
        take(this.tokenExpirationTime + 1),
        repeatWhen(() => this.startTimer$)
      )
      .subscribe(decreasedTime => {
        this.secondsLeft = decreasedTime;
        if (decreasedTime < 0) {
          this.stopTimer();
        } else {
          this.calculateTimeLeft();
        }
      });
  }
  private startTimer(): void {
    this.startTimer$.next();
  }
  private stopTimer(): void {
    this.stopTimer$.next();
  }

  /**
   * Calculates the time left to be displayed on the view
   */
  private calculateTimeLeft(): void {
    const mins = Math.floor(this.secondsLeft / 60);
    const secs = this.secondsLeft - mins * 60;
    this.timeLeftText = (mins < 10 ? '0' : '') + mins + ' : ' + (secs < 10 ? '0' : '') + secs;
  }
  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
