import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationHeaders } from '@shared/components/ui/header/header.models';
import { ChallengeQuestionComponent } from './components/challenge-question/challenge-question.component';
import { PasswordComponent } from './components/password/password.component';
import { SignUpDataComponent } from './components/sign-up-data/sign-up-data.component';
import { ConfirmationTokenComponent } from './components/confirmation-token/confirmation-token.component';
import { SignUpContainer } from './containers/sign-up/sign-up.container.component';
import { UserIdentificationComponent } from '@app/signup/components/user-identification/user-identification.component';
import { BiometryChallengeComponent } from './components/biometry-challenge/biometry-challenge.component';
import { PreTokenComponent } from '@app/login/components/pre-token/pre-token.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';

const routes: Routes = [
  {
    path: '',
    component: SignUpContainer,
    children: [
      {
        path: 'identification',
        component: PreTokenComponent,
        data: { header: ApplicationHeaders.Login }
      },
      {
        path: 'data',
        component: SignUpDataComponent,
        data: { header: ApplicationHeaders.Login }
      },
      {
        path: 'confirmation-token',
        component: ConfirmationTokenComponent,
        data: { header: ApplicationHeaders.Login }
      },
      {
        path: 'biometry',
        component: BiometryChallengeComponent,
        data: { header: ApplicationHeaders.Login }
      },
      {
        path: 'biometry/:flowId',
        component: BiometryChallengeComponent,
        data: { header: ApplicationHeaders.Login }
      },
      {
        path: 'active-validation',
        component: ChallengeQuestionComponent,
        data: { header: ApplicationHeaders.Login }
      },
      {
        path: 'password',
        component: PasswordComponent,
        data: { header: ApplicationHeaders.Login }
      },
      {
        path: 'user-identification',
        component: UserIdentificationComponent,
        data: { header: ApplicationHeaders.Login }
      },
      {
        path: 'terms',
        component: TermsAndConditionsComponent,
        data: { header: ApplicationHeaders.Login }
      },
      {
        path: 'new-password',
        component: PasswordComponent,
        data: { header: ApplicationHeaders.Login }
      },
      { path: '', redirectTo: 'data', pathMatch: 'full' },
      { path: '**', component: SignUpDataComponent, data: { header: ApplicationHeaders.Login } }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SignUpRoutingModule {}
