import { Action } from '@ngrx/store';
import {
  getBranchs,
  getChannels,
  getProducts,
  getSalespersons,
  getStores,
  getSubsegs
} from '@shared/graphql/types/query-types';

export enum ManagementFiltersActionTypes {
  UpdateClientFiltersList = '[MgmFilters] Update Client Filters List',
  UpdateProductsFiltersList = '[MgmFilters] Update Products Filters List',
  UpdateBipartiteFiltersList = '[MgmFilters] Update Bipartite Filters List',

  LoadInitialFilters = '[MgmFilters] Load All Initial Filters',

  LoadSubsidiaryFilters = '[MgmFilters] Load Subsidiary Filters',
  LoadSubsidiaryFiltersSuccess = '[MgmFilters] Load Subsidiary Filters Success',
  LoadSubsidiaryFiltersFailure = '[MgmFilters] Load Subsidiary Filters Failure',

  LoadStoreFilters = '[MgmFilters] Load Store Filters',
  LoadStoreFiltersSuccess = '[MgmFilters] Load Store Filters Success',
  LoadStoreFiltersFailure = '[MgmFilters] Load Store Filters Failure',

  LoadSubsegmentFilters = '[MgmFilters] Load Subsegment Filters',
  LoadSubsegmentFiltersSuccess = '[MgmFilters] Load Subsegment Filters Success',
  LoadSubsegmentFiltersFailure = '[MgmFilters] Load Subsegment Filters Failure',

  LoadCollaboratorsFilters = '[MgmFilters] Load Collaborators Filters',
  LoadCollaboratorsFiltersSuccess = '[MgmFilters] Load Collaborators Filters Success',
  LoadCollaboratorsFiltersFailure = '[MgmFilters] Load Collaborators Filters Failure',

  LoadChannelFilters = '[MgmFilters] Load Channel Filters',
  LoadChannelFiltersSuccess = '[MgmFilters] Load Channel Filters Success',
  LoadChannelFiltersFailure = '[MgmFilters] Load Channel Filters Failure',

  LoadProductsFilters = '[MgmFilters] Load Products Filters',
  LoadProductsFiltersSuccess = '[MgmFilters] Load Products Filters Success',
  LoadProductsFiltersFailure = '[MgmFilters] Load Products Filters Failure',

  UpdateSelectedSubsidiaryFilter = '[MgmFilters] Update Selected Subsidiary Filter',
  UpdateSelectedStoreFilter = '[MgmFilters] Update Selected Store Filter',
  UpdateSelectedSubsegmentFilter = '[MgmFilters] Update Selected Subsegment Filter',
  UpdateSelectedCollaboratorFilter = '[MgmFilters] Update Selected Collaborator Filter',
  UpdateSelectedChannelFilter = '[MgmFilters] Update Selected Channel Filter',
  UpdateSelectedProductFilter = '[MgmFilters] Update Selected Product Filter',
  UpdateSelectedClientFilter = '[MgmFilters] Update Selected Client Filter',
  UpdateSelectedBipartiteFilter = '[MgmFilters] Update Selected Bipartite Filter',

  UpdateSelectedStoreRankingSearchTerm = '[MgmFilters] Update Selected Store Ranking Search Term',
  UpdateSelectedSellerRankingSearchTerm = '[MgmFilters] Update Selected Seller Ranking Search Term',

  UpdateAvailableBipartiteFilter = '[MgmFilters] Update available Bipartite Filter',
  UpdateShouldPassFiltersToProposalPanel = '[MgmFilters] Update Should Pass Filters To Proposal Panel',

  ClearAllFilters = '[MgmFilters] Clear all Filters',

  FiltersUpToDate = '[MgmFilters] Filters Up to Date'
}

export class UpdateClientFiltersList implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateClientFiltersList;
  constructor() {}
}

export class UpdateProductsFiltersList implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateProductsFiltersList;
  constructor() {}
}

export class UpdateBipartiteFiltersList implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateBipartiteFiltersList;
  constructor() {}
}

// Ações de carregamento de management-filters

export class LoadInitialFilters implements Action {
  readonly type = ManagementFiltersActionTypes.LoadInitialFilters;
  constructor() {}
}

export class LoadSubsidiaryFilters implements Action {
  readonly type = ManagementFiltersActionTypes.LoadSubsidiaryFilters;
  constructor(public searchTerm: string) {}
}

export class LoadSubsidiaryFiltersSuccess implements Action {
  readonly type = ManagementFiltersActionTypes.LoadSubsidiaryFiltersSuccess;
  constructor(public payload: getBranchs) {}
}

export class LoadSubsidiaryFiltersFailure implements Action {
  readonly type = ManagementFiltersActionTypes.LoadSubsidiaryFiltersFailure;
  constructor(public payload: any) {}
}

export class LoadStoreFilters implements Action {
  readonly type = ManagementFiltersActionTypes.LoadStoreFilters;
  constructor(public searchTerm: string) {}
}

export class LoadStoreFiltersSuccess implements Action {
  readonly type = ManagementFiltersActionTypes.LoadStoreFiltersSuccess;
  constructor(public payload: getStores) {}
}

export class LoadStoreFiltersFailure implements Action {
  readonly type = ManagementFiltersActionTypes.LoadStoreFiltersFailure;
  constructor(public payload: any) {}
}

export class LoadSubsegmentFilters implements Action {
  readonly type = ManagementFiltersActionTypes.LoadSubsegmentFilters;
  constructor(public searchTerm?: string) {}
}

export class LoadSubsegmentFiltersSuccess implements Action {
  readonly type = ManagementFiltersActionTypes.LoadSubsegmentFiltersSuccess;
  constructor(public payload: getSubsegs) {}
}

export class LoadSubsegmentFiltersFailure implements Action {
  readonly type = ManagementFiltersActionTypes.LoadSubsegmentFiltersFailure;
  constructor(public payload: any) {}
}

export class LoadCollaboratorsFilters implements Action {
  readonly type = ManagementFiltersActionTypes.LoadCollaboratorsFilters;
  constructor(public searchTerm: string) {}
}

export class LoadCollaboratorsFiltersSuccess implements Action {
  readonly type = ManagementFiltersActionTypes.LoadCollaboratorsFiltersSuccess;
  constructor(public payload: getSalespersons) {}
}

export class LoadCollaboratorsFiltersFailure implements Action {
  readonly type = ManagementFiltersActionTypes.LoadCollaboratorsFiltersFailure;
  constructor(public payload: any) {}
}

export class LoadChannelFilters implements Action {
  readonly type = ManagementFiltersActionTypes.LoadChannelFilters;
  constructor(public searchTerm: string) {}
}

export class LoadChannelFiltersSuccess implements Action {
  readonly type = ManagementFiltersActionTypes.LoadChannelFiltersSuccess;
  constructor(public payload: getChannels) {}
}

export class LoadChannelFiltersFailure implements Action {
  readonly type = ManagementFiltersActionTypes.LoadChannelFiltersFailure;
  constructor(public payload: any) {}
}

export class LoadProductsFilters implements Action {
  readonly type = ManagementFiltersActionTypes.LoadProductsFilters;
  constructor(public payload: any) {}
}

export class LoadProductsFiltersSuccess implements Action {
  readonly type = ManagementFiltersActionTypes.LoadProductsFiltersSuccess;
  constructor(public payload: getProducts) {}
}

export class LoadProductsFiltersFailure implements Action {
  readonly type = ManagementFiltersActionTypes.LoadProductsFiltersFailure;
  constructor(public payload: any) {}
}

// Ações de seleção de management-filters

export class UpdateSelectedSubsidiaryFilter implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateSelectedSubsidiaryFilter;
  constructor(public payload: any, public isDefault = false) {}
}

export class UpdateSelectedStoreFilter implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateSelectedStoreFilter;
  constructor(public payload: any, public isDefault = false) {}
}

export class UpdateSelectedSubsegmentFilter implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateSelectedSubsegmentFilter;
  constructor(public payload: any, public isDefault = false) {}
}

export class UpdateSelectedCollaboratorFilter implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateSelectedCollaboratorFilter;
  constructor(public payload: any, public isDefault = false) {}
}

export class UpdateSelectedChannelFilter implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateSelectedChannelFilter;
  constructor(public payload: any, public isDefault = false) {}
}

export class UpdateSelectedProductFilter implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateSelectedProductFilter;
  constructor(public payload: any, public isDefault = false) {}
}

export class UpdateSelectedClientFilter implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateSelectedClientFilter;
  constructor(public payload: any, public isDefault = false) {}
}

export class UpdateSelectedBipartiteFilter implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateSelectedBipartiteFilter;
  constructor(public payload: any, public isDefault = false) {}
}

export class UpdateSelectedStoreRankingSearchTerm implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateSelectedStoreRankingSearchTerm;
  constructor(public searchTerm: string) {}
}

export class UpdateSelectedSellerRankingSearchTerm implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateSelectedSellerRankingSearchTerm;
  constructor(public searchTerm: string) {}
}

export class UpdateAvailableBipartiteFilter implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateAvailableBipartiteFilter;
  constructor(public payload: boolean) {}
}

export class UpdateShouldPassFiltersToProposalPanel implements Action {
  readonly type = ManagementFiltersActionTypes.UpdateShouldPassFiltersToProposalPanel;
  constructor(public updateValue: boolean, public status: { statusPG?: string[]; statusFunil?: string[] }) {}
}

export class ClearAllFilters implements Action {
  readonly type = ManagementFiltersActionTypes.ClearAllFilters;
  constructor() {}
}

export class FiltersUpToDate implements Action {
  readonly type = ManagementFiltersActionTypes.FiltersUpToDate;
  constructor(public payload: any) {}
}

export type ManagementFiltersActions =
  | UpdateClientFiltersList
  | UpdateProductsFiltersList
  | UpdateBipartiteFiltersList
  | LoadInitialFilters
  | LoadSubsidiaryFilters
  | LoadSubsidiaryFiltersSuccess
  | LoadSubsidiaryFiltersFailure
  | LoadStoreFilters
  | LoadStoreFiltersSuccess
  | LoadStoreFiltersFailure
  | LoadSubsegmentFilters
  | LoadSubsegmentFiltersSuccess
  | LoadSubsegmentFiltersFailure
  | LoadCollaboratorsFilters
  | LoadCollaboratorsFiltersSuccess
  | LoadCollaboratorsFiltersFailure
  | LoadChannelFilters
  | LoadChannelFiltersSuccess
  | LoadChannelFiltersFailure
  | LoadProductsFilters
  | LoadProductsFiltersSuccess
  | LoadProductsFiltersFailure
  | UpdateSelectedStoreFilter
  | UpdateSelectedSubsidiaryFilter
  | UpdateSelectedSubsegmentFilter
  | UpdateSelectedCollaboratorFilter
  | UpdateSelectedChannelFilter
  | UpdateSelectedProductFilter
  | UpdateSelectedClientFilter
  | UpdateSelectedBipartiteFilter
  | UpdateSelectedStoreRankingSearchTerm
  | UpdateSelectedSellerRankingSearchTerm
  | UpdateAvailableBipartiteFilter
  | UpdateShouldPassFiltersToProposalPanel
  | ClearAllFilters
  | FiltersUpToDate;
