import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class UnicoPathFileService {
  constructor(private http: HttpClient) {}
  private url = window.location.toString().toLocaleLowerCase();
  private host = window.location.host;

  private clienteMaisVezes() {
    return this.url.includes('maisvezes');
  }

  private accenture() {
    return this.url.includes('brpioneer.accenture');
  }

  private vivere() {
    return this.url.includes('brpioneer.viverebrasil');
  }

  private hml() {
    return this.url.includes('brpioneerhml');
  }

  private prova() {
    return this.url.includes('brpioneerpro');
  }

  private hfx() {
    return this.url.includes('brpioneerhfx');
  }

  getBundle(): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-biometry/unico/bundle`;
    const payload = {
      url: this.host,
      portal: 'bens'
    };
    return this.http.post<any>(endpoint, payload);
  }

  getFileName(): string {
    const plataform = 'shopkeeper';

    if (!environment.production) {
      return '/local/services.local.json';
    } else if (this.clienteMaisVezes()) {
      return plataform + '/services.maisvezes.json';
    } else if (this.vivere()) {
      return plataform + '/services.viverebrasil.json';
    } else if (this.accenture()) {
      return plataform + '/services.accenture.json';
    } else if (this.hml()) {
      return plataform + '/services.hml.json';
    } else if (this.prova()) {
      return plataform + '/services.prova.json';
    } else if (this.hfx()) {
      return plataform + '/services.hfx.json';
    }
  }
}
