<div class="container">
  <div class="row">
    <div class="col-12">
      <header class="modal-header">
        <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()">
          <img aria-hidden="true" src="./assets/ic_close.svg" alt="icon close" />
        </button>

        <button type="button" class="btn-clear" aria-label="Close" (click)="onDismiss()">
          <span>Limpar</span>
        </button>
      </header>
      <section class="modal-body">
        <mat-form-field *ngIf="control" class="row">
          <div class="date-picker-range">
            <input matInput #fromID (click)="fromID.select()" (change)="isDateValid()"
              [placeholder]="focused && !from.value ? placeholder : ''" [formControl]="from"
              [style.text-align]="!focused ? 'center' : null" />
            <span [style.visibility]="empty && !focused ? 'hidden' : null"> - </span>
            <input (change)="isDateValid()" matInput #toID (click)="toID.select()"
              [style.text-align]="!focused ? 'right' : null" [placeholder]="focused && !to.value ? placeholder : ''"
              [formControl]="to" />
          </div>
          <mat-icon *ngIf="!disabled" [style.opacity]="disabled ? '.5' : null"
            (menuOpened)="onOpen(calendar)">calendar_today</mat-icon>

          <mat-icon *ngIf="disabled" [style.opacity]="disabled ? '.5' : null">calendar_today</mat-icon>
          <div (click)="$event.stopPropagation()" class="drop-calendar">
            <mat-calendar class="calendar" #calendar (selectedChange)="select($event)" [dateClass]="setClass()"
              [maxDate]="maxDate" [minDate]="minDate">
            </mat-calendar>
            <div class="modal-footer">
              <button [disabled]="isDateValid()" mat-stroked-button class="btn-selecionar" (click)="onSubmit()">
                {{ 'SELECT-PERIOD' | translate }}
              </button>
            </div>
          </div>
        </mat-form-field>
      </section>
    </div>
  </div>
</div>