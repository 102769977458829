import { createSelector } from '@ngrx/store';
import { AppState } from '..';

export const proposalSelector = (state: AppState) => state.proposal;

export const selectOpenProposals = createSelector(
  proposalSelector,
  state => state.openProposals
);

export const selectPendingProposals = createSelector(
  proposalSelector,
  state => state.pendingProposals
);

export const selectProposal = createSelector(
  proposalSelector,
  state => state.proposalData
);

export const selectChecklists = createSelector(
  proposalSelector,
  state => state.checklists
);

export const selectChecklistsMobile = createSelector(
  proposalSelector,
  state => state.checklistsMobile
);

export const selectShowDownloadLink = createSelector(
  proposalSelector,
  state => state.showDownloadLink
);

export const showDownloadContract = createSelector(
  proposalSelector,
  state => state.showDownloadContract
);

export const selectBiometryChecklist = createSelector(
  proposalSelector,
  state => state.biometryChecklist
);

export const selectUploadChecklist = createSelector(
  proposalSelector,
  state => state.uploadChecklist
);
export const selectBiometryChecklistMobile = createSelector(
  proposalSelector,
  state => state.biometryChecklistMobile
);

export const selectUploadChecklistMobile = createSelector(
  proposalSelector,
  state => state.uploadChecklistMobile
);
export const selectChecklist = createSelector(
  proposalSelector,
  state => {
    if (state.biometryChecklist.data && state.uploadChecklist.data) {
      return [...state.biometryChecklist.data, ...state.uploadChecklist.data];
    }
    return [];
  }
);

export const selectChecklistMobile = createSelector(
  proposalSelector,
  state => {
    if (state.biometryChecklistMobile.data && state.uploadChecklistMobile.data) {
      return [...state.biometryChecklistMobile.data, ...state.uploadChecklistMobile.data];
    }
    return [];
  }
);

export const selectProposalId = createSelector(
  proposalSelector,
  state => state.proposalId
);

export const selectProposalStage = createSelector(
  proposalSelector,
  state => state.proposalStage
);

export const selectPendingProposal = createSelector(
  proposalSelector,
  state => state.pendingProposal.data
);

export const selectBankDataStatus = createSelector(
  proposalSelector,
  state => state.pendingProposal.bankData
);

export const getShowcaseChoosedCard = createSelector(
  proposalSelector,
  state => state.choosedProposalCard
);

export const updateProposals = createSelector(
  proposalSelector,
  state => state.updatedProposal
);

export const selectPaymentPlan = createSelector(
  proposalSelector,
  state => state.paymentPlanSelected
);

export const getProposalById = createSelector(
  proposalSelector,
  state => state.proposalData
);

export const selectIsProposalPendencyBiometryFlowEnabled = createSelector(
  proposalSelector,
  state => state.isProposalPendencyBiometryFlowEnabled
);
