import gql from 'graphql-tag';

export const GET_TERMS = gql`
  query getDomains($accessChannelType: String, $termType: String) {
    listTerms(accessChannelType: $accessChannelType, termType: $termType) {
      id
      code
      description
    }
  }
`;

export const GET_DOMAINS_REGISTER = gql`
  query proposalRegisterRender {
    proposalRegisterRender {
      accountTypes {
        id
        code
        description
      }
      addressTypes {
        id
        code
        description
      }
      banks {
        id
        code
        description
      }
      companyTypes {
        id
        code
        description
      }
      economicActivityGroups {
        id
        code
        description
      }
      genders {
        id
        code
        description
      }
      legalNatures {
        id
        code
        description
      }
      nationalities {
        id
        code
        description
      }
      federationUnits {
        id
        code
        description
      }
      companyRelationshipTypes {
        id
        code
        description
      }
    }
  }
`;

export const GET_ECONOMIC_ACTIVITIES = gql`
  query getEconomicActivities {
    proposalRegisterRender {
      economicActivityPf {
        id
        code
        description
      }
    }
  }
`;

export const SEND_TYPE_QUERY = gql`
  query getSendType {
    proposalRegisterRender {
      sendType {
        id
        code
        description
      }
    }
  }
`;
