import { Component, Input, OnInit } from '@angular/core';
import { AppState } from '@app/core/state/index';
import * as filter from '@app/core/state/period-filter/period-filter.actions';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { select, Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-filter-period-dialog',
  templateUrl: './filter-period-dialog.component.html',
  styleUrls: ['./filter-period-dialog.component.scss']
})
export class FilterPeriodDialogComponent implements OnInit {
  @Input() isLoadFilterPeriodStored;

  periods: any;
  public periodo: any;
  @Input() category = window.location.href;
  constructor(public activeModal: NgbActiveModal, private store$: Store<AppState>, private genTagger: GenTagger) {
    this.periods = [
      { name: 'Ontem', value: '-1d', checked: true },
      { name: 'Últimos 7 dias', value: '-7d', checked: false },
      { name: 'Últimos 6 meses', value: '-6m', checked: false }
    ];
  }

  ngOnInit() {
    this.store$
      .pipe(
        select('filter'),
        first()
      )
      .subscribe(res => {
        if (this.isLoadFilterPeriodStored && res.period_filter) {
          this.periodo = res.period_filter;
        } else {
          this.periodo = this.periods[0].value;
        }
      });
    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'Pagamentos - período',
      event_action: `${Tag.AbriuModal} - período`
    });
  }

  public onSubmit() {
    if (this.periodo) {
      const payload = this.periodo;
      this.store$.dispatch(new filter.SetSelectedPeriodFilter(payload));
      this.activeModal.close(this.periodo);
    }
  }

  public onDismiss() {
    this.activeModal.dismiss('cancelled');
  }

  clickPeriod(event_label) {
    this.genTagger.setTag({
      event_category: this.category,
      event_label: event_label,
      event_action: Tag.Selecionar + ' - datas'
    });

    return;
  }

  closeModal() {
    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'x',
      event_action: Tag.Fechou_Modal + ' - período'
    });
    this.activeModal.dismiss('Cross click');
  }
}
