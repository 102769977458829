<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="modal-header">
        <div>
          <h3 class="modal-title">{{ 'Export' | translate }}</h3>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()" appTagger
            [event_category]="category" event_action="fechou modal – baixar período selecionado" event_label="x">
            <img aria-hidden="true" src="./assets/ic_close.svg" alt="icon close" />
          </button>
        </div>

      </div>
      <div class="modal-body ">
        <table class="table">
          <thead>
            <th scope="col" class="text-nowrap first-column"></th>
          </thead>
          <tbody>
            <tr>
              <td class="ml-5"><strong>Tipo de arquivo</strong></td>
              <mat-radio-group [(ngModel)]="optionSelected" (change)="valueSelected($event)">
                <td>
                  <mat-radio-button [value]="'csv'">csv</mat-radio-button>
                </td>
                <td class="text-nowrap first-column">
                  <mat-radio-button [value]="'Excel'">Excel</mat-radio-button>
                </td>

              </mat-radio-group>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="modal-footer ">
        <button type="button" class="btn btn-action" (click)="downloadExtract(optionSelected)" appTagger
          [event_category]="category" event_label="{{ 'Export' | translate }}">
          {{ 'Export' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
