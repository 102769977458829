import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeliveryMethods } from '@app/login/interface/DeliveryMethods.enum';
import { environment } from '@env/environment';
import { Countries } from '@shared/constants/countries.enum';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { EMPTY, Observable } from 'rxjs';



interface PhonePayload {
  phoneAreaCode: string;
  phoneNumber: string;
}

@Injectable({
  providedIn: 'root'
})
export class PasswordRecoveryService {
  constructor(private http: HttpClient, private internationalizationService: InternationalizationService) { }

  private parsePhoneNumber(phone: string): { phoneAreaCode: string; phoneNumber: string } {
    const country = this.internationalizationService.currentCountry;

    let result: PhonePayload;

    if (country === Countries.BR) {
      result = {
        phoneAreaCode: phone.slice(0, 4),
        phoneNumber: phone.slice(4)
      };
    } else {
      result = {
        phoneAreaCode: phone.slice(0, 3),
        phoneNumber: phone.slice(3)
      };
    }

    return result;
  }

  public getRecoveryToken(payload: string, deliveryMethod: DeliveryMethods): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-login/1.0.0/clients/login/recovery`;
    // const endpoint = 'http://localhost:8080/api/recovery';

    const reqBody = {
      userCode: deliveryMethod === DeliveryMethods.DOCUMENT ? payload : null,
      email: deliveryMethod === DeliveryMethods.EMAIL ? payload : null,
      phone: deliveryMethod === DeliveryMethods.SMS ? this.parsePhoneNumber(payload) : null
    };

    return this.http.post<any>(endpoint, reqBody);
  }

  public postRecoveryToken(recoveryToken: string, recoveryId: string): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-login/1.0.0/clients/recovery/${recoveryId}/challenge`;
    // const endpoint = 'http://localhost:8080/api/challenge';

    const reqBody: any = {
      challenge: {
        answer: recoveryToken
      }
    };

    return this.http.post<any>(endpoint, reqBody);
  }

  public refreshRecoveryToken(recoveryId: string): Observable<any> {
    if (!recoveryId) return EMPTY;
    const endpoint = `${environment.apiRootUrl}/shopkeeper-login/1.0.0/clients/recovery/${recoveryId}/refresh-challenge`;
    // const endpoint = 'http://localhost:8080/api/refresh-challenge';

    return this.http.post<any>(endpoint, {});
  }

  public postNewPassword(newPassword: string, recoveryId: string): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-login/1.0.0/clients/recovery/${recoveryId}/password`;
    // const endpoint = 'http://localhost:8080/api/new-password';

    const reqBody = {
      password: newPassword
    };

    return this.http.put<any>(endpoint, reqBody);
  }
}
