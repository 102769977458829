import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-best-offer-error',
  templateUrl: './best-offer-error.component.html',
  styleUrls: ['./best-offer-error.component.scss']
})
export class BestOfferErrorComponent implements OnInit {
  message: string;

  constructor() {
    this.message = 'BEST-OFFER-ERROR';
  }

  ngOnInit() {}
}
