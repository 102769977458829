import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TranslateService } from '@ngx-translate/core';
import { ProposalReasonCancelDialogComponent } from '../proposal-reason-cancel-dialog/proposal-reason-cancel-dialog.component';

@Component({
  selector: 'app-proposal-cancel-dialog',
  templateUrl: './proposal-cancel-dialog.component.html',
  styleUrls: ['./proposal-cancel-dialog.component.scss']
})
export class ProposalCancelDialogComponent implements OnInit {
  cameFromProposalsList = false;
  @Output() okExit = new EventEmitter<any>();
  @Input() category: string;

  closeModal = Tag.Fechou_Modal;

  constructor(
    public dialogRef: MatDialogRef<ProposalCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog, // private location: Location
    private genTagger: GenTagger,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    if (this.data.routedBy && this.data.routedBy === 'open-proposal') {
      this.cameFromProposalsList = true;
    }
    this.genTagger.setTag({
      event_category: this.category,
      event_label: this.translateService.instant(`CANCEL_PROPOSAL`),
      event_action: `${Tag.AbriuModal} - ${this.translateService.instant(`CANCEL_PROPOSAL`)}`
    });
  }

  public closeButton() {
    this.dialogRef.close('cancelled');
  }

  public cancelProposal() {
    const ref = this.dialog.open(ProposalReasonCancelDialogComponent, {
      width: '560px',
      autoFocus: false,
      data: {
        id: !!this.data.proposalId ? this.data.proposalId : this.data.id,
        routedBy: this.data.routedBy
      }
    });

    ref.componentInstance.category = this.category;

    ref.afterClosed().subscribe(res => {
      if (res === 'cancelProposal') {
        this.okExit.emit();
      }
    });

    this.dialogRef.close();
  }
}
