import { Directive, HostListener, Input } from '@angular/core';

@Directive({
  selector: '[regexFilter]'
})
export class RegexFilterDirective {
  @Input() regexFilterPattern = null;

  @HostListener('keypress', ['$event']) onKeyPress(event) {
    return this.executeRegex(event.key);
  }

  @HostListener('paste', ['$event'])
  blockPaste(event: ClipboardEvent) {
    this.validateFields(event);
  }

  executeRegex(value) {
    if (this.regexFilterPattern === null) return true;
    return new RegExp(this.regexFilterPattern).test(value);
  }

  validateFields(event: ClipboardEvent) {
    event.preventDefault();
    const data = event.clipboardData
      .getData('text/plain')
      .split('')
      .filter(letter => this.executeRegex(letter))
      .join('');

    document.execCommand('insertHTML', false, data);
  }
}
