import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-faq-search',
  templateUrl: './faq-search.component.html',
  styleUrls: ['./faq-search.component.scss']
})
export class FaqSearchComponent implements OnInit {
  @Output() search = new EventEmitter<string>();
  @Output() reset = new EventEmitter<any>();

  searchForm: FormGroup;
  isFiltred = false;

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.searchForm = this.fb.group({
      searchTerm: []
    });
  }

  onReset() {
    this.isFiltred = false;
    this.searchForm.reset();
    this.reset.emit();
  }

  onSearch() {
    const searchTerm = this.searchForm.getRawValue().searchTerm;
    if (searchTerm) {
      this.isFiltred = true;
      this.search.emit(searchTerm);
    }
  }

  setSearchTerm(term) {
    this.searchForm.get('searchTerm').setValue(term);
    this.isFiltred = true;
  }
}
