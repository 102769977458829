export enum RegisterSections {
  PERSONAL_DATA = 'PERSONAL_DATA',
  ADDRESS_DATA = 'ADDRESS_DATA',
  TRAVELER_DATA = 'TRAVELER_DATA',
  OBSERVATION_DATA = 'OBSERVATION_DATA',
  COMPANY_DATA = 'COMPANY_DATA',
  REPRESENTANT_DATA = 'REPRESENTANT_DATA',
  REPRESENTANT_ADDRESS_DATA = 'REPRESENTANT_ADDRESS_DATA',
  LGPD_DATA = 'LGPD_DATA',
  BACEN_DATA = 'BACEN_DATA',
  CERTIFICATE_AGENTE = ' CERTIFICATE_AGENTE'
}
