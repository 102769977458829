import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, switchMap, withLatestFrom } from 'rxjs/operators';

import { LoginService } from '@app/login/services/login.service';
import { Store } from '@ngrx/store';
import { AllowedProfiles } from '@shared/enums/allowed-profiles.enum';
import { EMPTY } from 'rxjs';
import { ManagementPanelState } from '..';
import * as profileActions from './profile.actions';
import { userProfileSelector } from './profile.selectors';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private store$: Store<ManagementPanelState>
  ) {}

  @Effect()
  HeaderData = this.actions$.pipe(
    ofType<profileActions.ProfileRequest>(profileActions.ProfileActionTypes.PROFILE_INIT_REQUEST),
    switchMap(action =>
      this.loginService.getUserUIConfigs(action.payload).pipe(
        switchMap(res => {
          if (res.userInfo && res.userInfo.profile && res.userInfo.profile.documentNumber.length < 11) {
            res.userInfo.profile.documentNumber = this.pad(Number(res.userInfo.profile.documentNumber), 11);
          }

          return [
            new profileActions.ProfileRequestSuccess({
              menus: res.uiConfig.menus,
              userProfile: res.userInfo.profile,
              userPreferences: res.userInfo.preferences,
              homePage: res.userInfo && res.userInfo.preferences && res.userInfo.preferences.initialScreen
            })
          ];
        }),
        catchError(e => {
          return [new profileActions.ProfileRequestError({})];
        })
      )
    )
  );

  @Effect()
  profileRequestSuccess$ = this.actions$.pipe(
    ofType<profileActions.ProfileRequestSuccess>(profileActions.ProfileActionTypes.PROFILE_INIT_REQUEST_SUCCESS),
    withLatestFrom(this.store$.select(userProfileSelector)),
    switchMap(([, userProfile]) => {
      const { codeProfiles } = userProfile;

      const isMasterShopkeeper = codeProfiles.findIndex(profile => profile === AllowedProfiles.LOJISTA_MASTER) !== -1;
      const isGcOrGr =
        codeProfiles.findIndex(profile => profile === AllowedProfiles.GR || profile === AllowedProfiles.GC) !== -1;

      return [new profileActions.UpdateProfileType({ isGcOrGr, isMasterShopkeeper })];
    }),
    catchError(e => EMPTY)
  );

  pad(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }
}
