import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, ChangeDetectorRef, Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { selectLoadingSteps } from '@app/core/state/simulation/simulation.selectors';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-loading-messages-modal',
  templateUrl: './loading-messages-modal.html',
  styleUrls: ['./loading-messages-modal.scss'],
  animations: [
    trigger('inOutAnimation', [
      state('true', style({ opacity: 0 })),
      state('false', style({ opacity: 1 })),
      transition('true => false', [
        style({ transform: 'translateY(-20px)', opacity: 0 }),
        animate('0.3s ease-out', style({ transform: 'translateY(0px)', opacity: 1 }))
      ]),
      transition('false => true', [
        style({ transform: 'translateY(0px)', opacity: 1 }),
        animate('0.3s ease-in', style({ transform: 'translateY(-20px)', opacity: 0 }))
      ])
    ])
  ]
})
export class LoadingMessagesModalComponent implements AfterViewInit {
  public lottieSantander = {
    path: 'assets/media/lottie/santander-logo.json',
    renderer: 'canvas',
    autoplay: true,
    loop: true
  };

  public anim: any;

  public state = 'false';

  public message = 'Buscando informações';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<LoadingMessagesModalComponent>,
    private store$: Store<AppState>,
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit() {
    this.getDataLoading();
    this.cdr.detectChanges();
  }

  getDataLoading() {
    this.store$.select(selectLoadingSteps).subscribe((res: any) => {
      this.changeMsg(res[0]?.message);
    });
  }

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  changeMsg(msg: string) {
    if (msg !== this.message) {
      this.state = 'true';
      setTimeout(() => {
        this.message = msg;
      }, 350);
      setTimeout(() => {
        this.state = 'false';
      }, 350);
    }
  }
}
