import { GetIvNonceDTOResponse } from '@app/proposal-workflow/containers/step-formalization/components/biometry-controller/models/api/get.iv-nonce.model';
import { AgentLegalPersonDTO } from '@app/proposal-workflow/containers/step-formalization/components/biometry-controller/models/api/post.agent-legal.model';
import { PostBiometryDTOResponse } from '@app/proposal-workflow/containers/step-formalization/components/biometry-controller/models/api/post.biometry.model';
import {
  CrossedFluxInfo,
  CrossedFluxPollingStatusEnum
} from '@app/proposal-workflow/containers/step-formalization/components/biometry-controller/models/biometry.model';
import { ChecklistItemClientDTO } from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/api/query.checklist.model';
import {
  ChecklistModel,
  ChecklistStatusEnum
} from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/checklist.model';
import { BiometryTutorialSteps } from '@app/proposal-workflow/models/biometry-tutorial-steps.model';
import { FormalizationStatusEnum } from '@app/proposal-workflow/models/formalization-status.enum';
import { SummaryModel, SummaryStageModel } from '@app/proposal-workflow/models/summary.model';
import { FormalizationActions, FormalizationActionTypes } from './formalization.actions';

export interface FormalizationState {
  status: FormalizationStatusEnum;
  crossedFluxLink: { link: string; uuid: string };
  crossedFluxInfo: CrossedFluxInfo;
  agentsLegal: AgentLegalPersonDTO[];
  biometry: ChecklistModel;
  biometryResponse: PostBiometryDTOResponse;
  currentRepresentative: ChecklistItemClientDTO;
  latestBiometryStatus: {
    checklist: {
      clientId: number;
      status: ChecklistStatusEnum;
    }[];
    attempt: number;
  };
  latestBiometryCrossFluxStatus: {
    status: ChecklistStatusEnum;
    attempt: number;
  };
  latestCrossedFluxStatus: {
    attempt: number;
    status: CrossedFluxPollingStatusEnum;
    timestamp: string;
  };
  submitResponse: { nextStageCode: string };
  maxFileSizeAllowed: string;
  tutorialSteps: BiometryTutorialSteps[];
  sendTypes: { id: string; code: string; description: string }[];
  summarySteps: SummaryModel[];
  summaryStatus: SummaryStageModel[];
  ivNonce: GetIvNonceDTOResponse;
}

export const initialFormalizationState: FormalizationState = {
  status: FormalizationStatusEnum.PENDING,
  crossedFluxLink: { link: null, uuid: null },
  crossedFluxInfo: null,
  agentsLegal: null,
  biometry: null,
  biometryResponse: null,
  latestBiometryStatus: {
    checklist: [],
    attempt: 0
  },
  latestBiometryCrossFluxStatus: {
    status: null,
    attempt: 0
  },
  currentRepresentative: null,
  latestCrossedFluxStatus: {
    attempt: 0,
    status: null,
    timestamp: null
  },
  submitResponse: null,
  maxFileSizeAllowed: null,
  tutorialSteps: null,
  sendTypes: [],
  summarySteps: null,
  summaryStatus: null,
  ivNonce: null
};

export function formalizationReducer(state = initialFormalizationState, action: FormalizationActions) {
  switch (action.type) {
    case FormalizationActionTypes.SEND_AGENT_LEGAL_SUCCESS: {
      return {
        ...state,
        agentsLegal: action.payload
      };
    }

    case FormalizationActionTypes.SAVE_BIOMETRY_ITEM: {
      return {
        ...state,
        biometry: action.payload
      };
    }

    case FormalizationActionTypes.SEND_BIOMETRY_SUCCESS:
    case FormalizationActionTypes.SEND_BIOMETRY_PENDING:
    case FormalizationActionTypes.SEND_BIOMETRY_REMAKE:
    case FormalizationActionTypes.SEND_BIOMETRY_ERROR:
    case FormalizationActionTypes.SEND_SIGNATURE_SUCCESS:
    case FormalizationActionTypes.SEND_SIGNATURE_REMAKE:
    case FormalizationActionTypes.SEND_SIGNATURE_ERROR:
      return { ...state, biometryResponse: action.payload };

    case FormalizationActionTypes.CLEAR_BIOMETRY: {
      return {
        ...state,
        biometry: initialFormalizationState.biometry
      };
    }

    case FormalizationActionTypes.GET_MAX_FILE_SIZE_ALLOWED_SUCCESS: {
      return {
        ...state,
        maxFileSizeAllowed: action.payload
      };
    }

    case FormalizationActionTypes.GET_MAX_FILE_SIZE_ALLOWED_ERROR: {
      return {
        ...state,
        maxFileSizeAllowed: initialFormalizationState.maxFileSizeAllowed
      };
    }

    case FormalizationActionTypes.GET_SEND_TYPES_SUCCESS: {
      return {
        ...state,
        sendTypes: action.payload
      };
    }

    case FormalizationActionTypes.GET_SEND_TYPES_ERROR: {
      return {
        ...state,
        sendTypes: initialFormalizationState.sendTypes
      };
    }

    case FormalizationActionTypes.SUBMIT_FORMALIZATION_SUCCESS: {
      return {
        ...state,
        submitResponse: action.payload
      };
    }

    case FormalizationActionTypes.SUBMIT_FORMALIZATION_ERROR: {
      return {
        ...state,
        submitResponse: initialFormalizationState.submitResponse
      };
    }

    case FormalizationActionTypes.GET_TUTORIAL_STEPS_SUCCESS: {
      return {
        ...state,
        tutorialSteps: action.payload
      };
    }

    case FormalizationActionTypes.GET_CROSSED_FLUX_LINK_SUCCESS: {
      return {
        ...state,
        crossedFluxLink: action.payload
      };
    }

    case FormalizationActionTypes.GET_CROSSED_FLUX_LINK_ERROR: {
      return {
        ...state,
        crossedFluxLink: initialFormalizationState.crossedFluxLink
      };
    }

    case FormalizationActionTypes.GET_CROSSED_FLUX_STATUS_SUCCESS: {
      return {
        ...state,
        latestCrossedFluxStatus: {
          attempt: ++state.latestCrossedFluxStatus.attempt,
          status: action.payload.status,
          timestamp: action.payload.validateDate
        }
      };
    }

    case FormalizationActionTypes.GET_CROSSED_FLUX_STATUS_ERROR: {
      return {
        ...state,
        latestCrossedFluxStatus: {
          attempt: ++state.latestCrossedFluxStatus.attempt,
          status: initialFormalizationState.latestCrossedFluxStatus.status,
          timestamp: initialFormalizationState.latestCrossedFluxStatus.timestamp
        }
      };
    }

    case FormalizationActionTypes.GET_CROSSED_FLUX_INFO_SUCCESS: {
      return {
        ...state,
        crossedFluxInfo: action.payload
      };
    }

    case FormalizationActionTypes.GET_CROSSED_FLUX_INFO_ERROR: {
      return {
        ...state,
        crossedFluxInfo: initialFormalizationState.crossedFluxInfo
      };
    }

    case FormalizationActionTypes.CLEAR_CROSSED_FLUX: {
      return {
        ...state,
        crossedFluxInfo: initialFormalizationState.crossedFluxInfo,
        latestCrossedFluxStatus: initialFormalizationState.latestCrossedFluxStatus,
        crossedFluxLink: initialFormalizationState.crossedFluxLink
      };
    }

    case FormalizationActionTypes.SET_FORMALIZATION_STATUS: {
      return {
        ...state,
        status: action.payload
      };
    }

    case FormalizationActionTypes.CHECK_BIOMETRY_STATUS_SUCCESS: {
      return {
        ...state,
        latestBiometryStatus: { attempt: ++state.latestBiometryStatus.attempt, checklist: action.payload }
      };
    }

    case FormalizationActionTypes.CHECK_BIOMETRY_STATUS_ERROR: {
      return {
        ...state,
        latestBiometryStatus: {
          attempt: ++state.latestBiometryStatus.attempt,
          checklist: initialFormalizationState.latestBiometryStatus.checklist
        }
      };
    }

    case FormalizationActionTypes.CHECK_BIOMETRY_CROSS_FLUX_STATUS_SUCCESS: {
      return {
        ...state,
        latestBiometryCrossFluxStatus: {
          attempt: ++state.latestBiometryCrossFluxStatus.attempt,
          status: action.payload
        }
      };
    }

    case FormalizationActionTypes.CHECK_BIOMETRY_CROSS_FLUX_STATUS_ERROR: {
      return {
        ...state,
        latestBiometryCrossFluxStatus: {
          attempt: ++state.latestBiometryCrossFluxStatus.attempt,
          status: initialFormalizationState.latestBiometryCrossFluxStatus.status
        }
      };
    }

    case FormalizationActionTypes.CLEAR_BIOMETRY_STATUS: {
      return {
        ...state,
        latestBiometryStatus: initialFormalizationState.latestBiometryStatus
      };
    }

    case FormalizationActionTypes.SET_REPRESENTATIVE: {
      return {
        ...state,
        currentRepresentative: action.payload
      };
    }

    case FormalizationActionTypes.GET_SUMMARY_STEPS_SUCCESS: {
      return {
        ...state,
        summarySteps: action.payload
      };
    }

    case FormalizationActionTypes.GET_SUMMARY_STEPS_ERROR: {
      return {
        ...state,
        summarySteps: initialFormalizationState.summarySteps
      };
    }

    case FormalizationActionTypes.GET_SUMMARY_STATUS_SUCCESS: {
      return {
        ...state,
        summaryStatus: action.payload
      };
    }

    case FormalizationActionTypes.GET_SUMMARY_STATUS_ERROR: {
      return {
        ...state,
        summaryStatus: initialFormalizationState.summaryStatus
      };
    }

    case FormalizationActionTypes.GET_IV_NONCE_SUCCESS: {
      return {
        ...state,
        ivNonce: action.payload
      };
    }

    case FormalizationActionTypes.GET_IV_NONCE_ERROR: {
      return {
        ...state,
        ivNonce: initialFormalizationState.ivNonce
      };
    }

    case FormalizationActionTypes.CLEAR_FORMALIZATION: {
      return { ...initialFormalizationState };
    }

    default:
      return state;
  }
}
