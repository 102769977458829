<div id="box-camera">
  <span id="message"></span>

  <img id="camera--trigger" src="assets/public/images/camera.svg" />

  <!-- Popup Loading ... -->
  <div id="box--loading" style="display: none;">
    <div class="box-in">
      <div class="loader">Loading...</div>
    </div>
  </div>

  <!-- Popup Orientation ... -->
  <div id="box--orientation">
    <div class="box-in">
      <div class="text">Mantenha o celular no modo Portrait:</div>
      <img id="lock-image" src="assets/public/images/lock.svg" alt="Lock" />
    </div>
  </div>
</div>

<!-- Popup Completed ... -->
<div id="box--completed" style="display: none;">
  <div class="box-in">
    <div id="message">
      <div id="success-checkmark" class="success-checkmark">
        <div class="check-icon">
          <span class="icon-line line-tip"></span>
          <span class="icon-line line-long"></span>
          <div class="icon-circle"></div>
          <div class="icon-fix"></div>
        </div>
      </div>
    </div>
  </div>
</div>

<div id="box--loading-models" style="display: none;">
  <div class="box-in">
    <div class="loader">Carregando modelos...</div>
    <div style="margin-top: 15px; font-family: 'Roboto', sans-serif;">Carregando modelos...</div>
  </div>
</div>

<div id="box--error" style="display: none;">
  <div class="box-in">
    <div class="col-12 col-md-12 mb-3">
      <img *ngIf="showCustonMessage" src="./assets/images/ic_exclamation.svg" alt="" />
    </div>
    <div id="error-message">
      Ops... Algo inesperado aconteceu!
    </div>
    <div class="col-12 mt-3 description" *ngIf="showCustonMessage">
      Para prosseguir, é necessário acessar as configurações no navegador e permitir o uso da câmera.
    </div>
    <div class="col-12 col-md-12 mt-3">
      <button type="button" class="btn btn-block p-2" (click)="backSignature()">OK, entendi</button>
    </div>
  </div>
</div>
