import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BiometryManagerService } from '@app/biometry/services/biometry-manager.service';
import { AppState } from '@app/core/state';
import * as fromFormalization from '@app/core/state/formalization';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { SetShowStepper } from '@app/core/state/simulation/simulation.actions';
import { FormalizationService } from '@app/proposal-workflow/containers/step-formalization/services/formalization.service';
import { BiometryTutorialSteps } from '@app/proposal-workflow/models/biometry-tutorial-steps.model';
import { OsEnum } from '@app/proposal-workflow/models/os.enum';
import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { CustomerTypeEnum } from '@app/proposal-workflow/models/step-register-put.enum';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { FormalizationProposalDTO } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { Observable, Subject, of } from 'rxjs';
import { delay, filter, map, takeUntil, tap } from 'rxjs/operators';
import { ChecklistItemClientDTO, ChecklistItemTypeEnum } from '../checklist/models/api/query.checklist.model';
import { ChecklistModel, ChecklistStatusEnum } from '../checklist/models/checklist.model';
import { BiometryStatusEnum } from './components/biometry-status/biometry-status.component';
import { GetCrossedFluxLinkDTORequest } from './models/api/get.crossed-flux-info.model';
import { GetIvNonceDTORequest } from './models/api/get.iv-nonce.model';
import { AgentLegalPersonDTO } from './models/api/post.agent-legal.model';
import { BiometryResponseEnum } from './models/api/post.biometry.model';
import { ClientBiometryStatusEnum, CrossedFluxPollingStatusEnum } from './models/biometry.model';
import { BiometryService, BiometryStageEnum } from './services/biometry.service';

export enum BiometryTypeEnum {
  SIGNATURE = 1,
  FACIAL = 2,
  SIGNATURE_FACIAL = 3
}
export enum ParametrosBiometryEnum {
  PERMITE_BIO_SMS = 'PERMITE_BIO_SMS',
  PERMITE_BIO_CAMERA = 'PERMITE_BIO_CAMERA',
  PERMITE_BIO_QRCODE = 'PERMITE_BIO_QRCODE',
  PERMITE_BIO_EMAIL = 'PERMITE_BIO_EMAIL'
}
@Component({
  selector: 'app-biometry-controller',
  templateUrl: './biometry-controller.component.html',
  styleUrls: ['./biometry-controller.component.scss']
})
export class BiometryControllerComponent implements OnInit, OnDestroy, OnChanges, AfterContentChecked, AfterContentInit {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() proposal: FormalizationProposalDTO | Partial<FormalizationProposalDTO>;
  @Input() checklist: ChecklistModel[];
  @Input() crossedFluxLink: { link: string; uuid: string };
  @Input() isCrossedFlux = true;
  @Input() clientList: ChecklistItemClientDTO[];
  @Input() proposalData: ConfirmationData;
  @Input() showContractDownload = false;
  @Input() uploadChecklist: ChecklistModel[];
  @Input() tutorialNextButtonDisabled = false;
  @Output() finish: EventEmitter<boolean> = new EventEmitter();
  @Output() proposalConditionsAcceptedEmitter: EventEmitter<boolean> = new EventEmitter();
  @Output() ishandleSendSignature: EventEmitter<boolean> = new EventEmitter(false);
  @Output() ishandleFacialCapture: EventEmitter<boolean> = new EventEmitter(false);
  @Output() stageBiometry: EventEmitter<any> = new EventEmitter();
  @Output() getBiometryStatus: EventEmitter<ClientBiometryStatusEnum> = new EventEmitter();
  public tutorialSteps: BiometryTutorialSteps;
  public proposalConditionSelfieAccepted = false;
  public proposalConditionsAccepted = false;
  public agentLegal: number;
  public getChangeBiometryStatus: ClientBiometryStatusEnum;
  public clientBiometryStatusEnum = ClientBiometryStatusEnum;
  @Output() statusBiometry: EventEmitter<ChecklistStatusEnum> = new EventEmitter();

  public biometryType = BiometryTypeEnum.FACIAL;
  public biometryTypeEnum = BiometryTypeEnum;
  public biometryStageEnum = BiometryStageEnum;
  public parametrosBiometryEnum = ParametrosBiometryEnum;
  public biometryStage = this.biometryService.biometryStage;

  public biometryStatusEnum = BiometryStatusEnum;
  public biometryStatusProgress = BiometryStatusEnum.BIOMETRY_PENDING;
  public biometryStatusHasQuestions = false;
  public currentPlatform: { deviceInfo: DeviceInfo; isMobile: boolean; isDesktop: boolean };
  public osEnum = OsEnum;
  public statusBiometryValue: ClientBiometryStatusEnum;
  public showParameter: boolean;

  public errorMessage = '';
  public errorFlag = false;

  public signatureChecklistItem: ChecklistModel;
  public facialChecklistItem: ChecklistModel;
  public antifraudChecklistItem: ChecklistModel;
  public selectedClient: ChecklistItemClientDTO;
  public personType = SubmitPersonType;
  public agentsLegalReturnAntiFraud: ChecklistModel[];

  public additionalFluxTitle = '';
  public additionalFluxButtonText = '';
  public qrTitle = '';
  public smsTitle = '';
  public typebiometry: Observable <boolean>;
  public permitEmail: Observable <boolean>;
  public permitQrCode: Observable <boolean>;
  public permitSMS: Observable <boolean>;
  public permitCamera: Observable <boolean>;;
  public isBiometryPollRunning = false;
  public isCrossedFluxPollRunning = false;
  public isBiometryCrossFluxStatusRunning = false;
  public isDesktopBiometry = false;
  public isCrossedFluxTimerActive = false;
  public informativeEmail = '';
  public textButtonEmail = '';
  public multipleOptions = '';
  public smsTextButton = ''
  public typePerson: string;
  public isBrackToAgentsForm = false;
  public showButtonCam = true;
  public noAdditionalAgent = false;
  public statusBiometryValueJuridica: boolean;

  @Input() category: string;

  constructor(
    public biometryService: BiometryService,
    public deviceDetectorService: DeviceDetectorService,
    private store$: Store<AppState>,
    public dialog: MatDialog,
    public translateService: TranslateService,
    private biometryManagerService: BiometryManagerService,
    private ref: ChangeDetectorRef,
    private formalizationService: FormalizationService,
    private sessionStorageService: SessionStorageService,
    private genTagger: GenTagger,
    private taggerService: TaggerService
  ) {
    this.setInitialBiometryState();
  }
  ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }
  ngAfterContentInit(): void{
    this.biometryStage = this.biometryService.biometryStage;
    this.stageBiometry.emit(this.biometryStage);

    if (!this.isCrossedFlux) {
      this.setSelectedClientSubscription();
      //this.pollBiometryStatus();
      this.pollCrossedFluxStatus();
      this.getTypePerson();
      this.getBiometry();
      this.getStatusBiometryPJ();
    }
    this.showParameter = this.showButtonNotAntifraud();
  }
  ngOnChanges(changes: SimpleChanges) {
    this.biometryStage = this.biometryService.biometryStage;
    if (changes.isCrossedFlux || changes.proposalData) {
      this.checkCrossedFlux();
    }

    if (changes.crossedFluxLink && this.crossedFluxLink && this.crossedFluxLink.uuid) {
      this.isCrossedFluxTimerActive = false;
      const payload = { uuid: this.crossedFluxLink.uuid };
      this.store$.dispatch(new fromFormalization.actions.GetCrossedFluxStatusAction(payload));
    }

    if (this.checklist && changes.checklist) {
      const isDenied = this.checklist.some(item => {
        return item.status === ChecklistStatusEnum.DENIED;
      });
      if (isDenied) return this.resolveBiometryDenied();

      this.taggerService.setProposalType(this.proposal?.product?.code);
      this.setClientList();
      this.checkBiometryStatus();
    }

  }

  ngOnInit() {
    this.biometryStage = this.biometryService.biometryStage;
    if (!this.isCrossedFlux) {
      this.setSelectedClientSubscription();
      this.pollBiometryStatus();
      this.pollCrossedFluxStatus();
      this.getTypePerson();
    }
  }

  private getTypePerson() {
    this.store$.select(getConfirmationData).subscribe(res => {
      this.typePerson = res?.proposalData?.client
    })
  }
  public actualBiometryStatus(event: ClientBiometryStatusEnum){
    this.getChangeBiometryStatus = event;
    this.getBiometryStatus.emit(event);
  }

  private checksTheTypeOfBiometricsOrActiveSignature() {
    this.permitEmail = this.biometryService.checkFeatureToggleActive(this.parametrosBiometryEnum.PERMITE_BIO_EMAIL);
    this.permitQrCode = this.biometryService.checkFeatureToggleActive(this.parametrosBiometryEnum.PERMITE_BIO_QRCODE);
    this.permitSMS = this.biometryService.checkFeatureToggleActive(this.parametrosBiometryEnum.PERMITE_BIO_SMS);
    this.permitCamera = this.biometryService.checkFeatureToggleActive(this.parametrosBiometryEnum.PERMITE_BIO_CAMERA);
  }

  public hideParameters(hideParameters:boolean){
    if(hideParameters){
    this.permitEmail = of(false)
    this.permitQrCode = of(false)
    this.permitSMS = of(false)
    this.permitCamera = of(false)
    this.showParameter = false;
    }
  }

  private pollBiometryCrossFluxStatus(): void {
    if (!this.isBiometryCrossFluxStatusRunning) {
      this.isBiometryCrossFluxStatusRunning = true;

      this.store$
        .select(fromFormalization.selectors.selectLatestBiometryCrossFluxStatus)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          map(latestStatus => latestStatus.status),
          tap(status => {
            if (status === ChecklistStatusEnum.INDEXED) {
              this.biometryStatusProgress = BiometryStatusEnum.FINISHED;
              this.isBiometryCrossFluxStatusRunning = false;
            }

            if (status === ChecklistStatusEnum.DENIED) {
              this.biometryStatusProgress = BiometryStatusEnum.BIOMETRY_DENIED;
              this.isBiometryCrossFluxStatusRunning = false;
            }
          }),
          delay(5000)
        )
        .subscribe(status => {
          this.statusBiometry.emit(status);
          const stage = this.biometryStage.getValue();
          const isPending = status !== ChecklistStatusEnum.INDEXED && status !== ChecklistStatusEnum.DENIED;
          const isFluxFinalizing =
            stage === BiometryStageEnum.BIOMETRY_STATUS ||
            stage === BiometryStageEnum.DONE ||
            stage === BiometryStageEnum.PENDING ||
            stage === BiometryStageEnum.FACIAL_CAPTURE ||
            stage === BiometryStageEnum.SIGNATURE_CAPTURE;

          if (isPending && isFluxFinalizing) {
            this.biometryStatusProgress = BiometryStatusEnum.BIOMETRY_PENDING;
            if (this.checklist && this.checklist[0]) {
              this.store$.dispatch(
                new fromFormalization.actions.CheckBiometryCrossFluxStatusAction(this.checklist[0].id)
              );
            }
          }
        });
    }
  }

  private setSelectedClientSubscription(): void {
    this.store$
      .select(fromFormalization.selectors.selectCurrentRepresentative)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(client => !!client)
      )
      .subscribe(client => {
        this.selectedClient = client;
        const currClient = this.checklist.find(item => !!item.client && item.client.id === this.selectedClient.id);
        this.checkBiometryType(currClient);
      });
  }

  private setClientList(): void {
    this.clientList = [];

    this.checklist.forEach((item: ChecklistModel) => {
      const clientIsAlreadyInList = this.clientList.some(c => !!c && c.id === item.client.id);
      if (!clientIsAlreadyInList) {
        const fullClient = {
          ...item.client,
          biometryStatus: this.setClientStatus(item.status)
        };
        this.statusBiometryValue = fullClient.biometryStatus;
        this.clientList = [...this.clientList, fullClient];
      }
    });
    this.getStatusBiometryPJ();
  }

  private getStatusBiometryPJ() {
    const typeAssinatura = this.checklist.some(t => !!t && (t?.type?.code === ChecklistItemTypeEnum.SIGNATURE || t?.type?.code === ChecklistItemTypeEnum.FACIAL || t?.type?.code === ChecklistItemTypeEnum.ANTIFRAUD));
    this.clientList.forEach(s => {
      if ((s?.biometryStatus === ClientBiometryStatusEnum.PENDING ||
        s?.biometryStatus === ClientBiometryStatusEnum.REMAKE) && typeAssinatura) {
        this.statusBiometryValueJuridica = true;
        this.noAdditionalAgent = true;
      }
    })
  }

  private setClientStatus(status: ChecklistStatusEnum): ClientBiometryStatusEnum {
    let clientStatus: ClientBiometryStatusEnum;
    switch (status) {
      case ChecklistStatusEnum.INDEXED:
        clientStatus = ClientBiometryStatusEnum.DONE;
        break;

      case ChecklistStatusEnum.ANALYSIS:
      case ChecklistStatusEnum.RELEASE_INTERNAL:
        clientStatus = ClientBiometryStatusEnum.ANALYSIS;
        break;

      case ChecklistStatusEnum.REMAKE:
        clientStatus = ClientBiometryStatusEnum.REMAKE;
        break;

      default:
        clientStatus = ClientBiometryStatusEnum.PENDING;
        break;
    }
  //  this.clientBiometryStatusEnum = clientStatus;
    return clientStatus;
  }

  /* Will classify if its SIGNATURE, FACIAL or BOTH,
  with this enum we can use specific behaviors from the application that each option needs */
  private checkBiometryType(item: ChecklistModel): void {
    if (item.type.code === ChecklistItemTypeEnum.SIGNATURE) {
      this.facialChecklistItem = null;
      return this.setBiometrySignatureFlux(item);
    }

    if (item.type.code === ChecklistItemTypeEnum.FACIAL || item.type.code === ChecklistItemTypeEnum.ANTIFRAUD) {
      this.signatureChecklistItem = null;
      return this.setBiometryFacialFlux(item);
    }
  }

  /** @param  {ChecklistModel} item [ Set the flux of biometry signature for electronic signature] */
  private setBiometrySignatureFlux(item: ChecklistModel) {
    this.signatureChecklistItem = item;
    this.biometryType = BiometryTypeEnum.SIGNATURE;
    this.getSignatureSteps();
  }
  /** @param  {ChecklistModel} item [ Set the flux of biometry facial check for biometry signature] */
  private setBiometryFacialFlux(item: ChecklistModel): void {
    this.facialChecklistItem = item;
    this.biometryType = BiometryTypeEnum.FACIAL;
    this.getBiometrySteps();
    this.checkBiometryReturn(this.facialChecklistItem);
    this.setIvNonceSubscription();
  }

  private setAdditionalFluxLabel(
    additionalFluxTitle: string,
    qrTitle: string,
    additionalFluxButtonText: string,
    smsTitle: string,
    smsTextButton: string,
    informativeEmail?: string,
    textButtonEmail?: string,
    multipleOptions?: string
  ): void {
    this.additionalFluxTitle = `${this.translateService.instant(additionalFluxTitle)}`;
    this.qrTitle = `${this.translateService.instant(qrTitle)}`;
    this.additionalFluxButtonText = `${this.translateService.instant(additionalFluxButtonText)}`;
    this.smsTitle = `${this.translateService.instant(smsTitle)}`;
    this.smsTextButton = `${this.translateService.instant(smsTextButton)}`;
    this.informativeEmail = `${this.translateService.instant(informativeEmail)}`;
    this.textButtonEmail =  `${this.translateService.instant(textButtonEmail)}`;
    this.multipleOptions = `${this.translateService.instant(multipleOptions)}`;
  }

  private setIvNonceSubscription(): void {
    this.store$
      .select(fromFormalization.selectors.selectIvNonce)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(ivNonce => !!ivNonce && !!ivNonce.ivCode && !!ivNonce.nonceCode)
      )
      .subscribe(ivNonce => {
        this.biometryManagerService.setIvNonce(ivNonce.ivCode, ivNonce.nonceCode);
        this.getBiometrySteps();
        this.goToFacialCapture();
      });
  }

  // cancels all polling, emits finish flag and navigates to DONE.
  public finishBiometryProgress(): void {
    this.biometryStatusProgress = BiometryStatusEnum.FINISHED;
    this.isBiometryPollRunning = false;
    this.isCrossedFluxPollRunning = false;
    this.finish.emit(true);
    this.goToDone();
  }

  private checkBiometryStatus(): void {
    if (this.isCrossedFlux) return;

    const hasOneClient = !!this.clientList && this.clientList.length === 1;
    const hasMultipleClients = !!this.clientList && this.clientList.length > 1;
    const isPhysicalPerson = hasOneClient && this.clientList[0].type === CustomerTypeEnum.MAIN_CLIENT;
    this.antifraudChecklistItem = this.checklist.find(item => item.type.code === ChecklistItemTypeEnum.ANTIFRAUD);
    const hasItemChecklistOptional = this.uploadChecklist.find(
      item => item.client.type === CustomerTypeEnum.OPTIONAL_LEGAL_REPRESENTATIVE
    );

    // Checks if all biometry checklist items are indexed, sets biometry status progress to FINISHED,
    if (this.checklist.every(item => item.status === ChecklistStatusEnum.INDEXED)) {
      return this.finishBiometryProgress();
    }

    // Checks if biometry type is antifraud, triggers link generation, polling and navigates to QR Code.
    if (!!this.antifraudChecklistItem) {
      this.uploadChecklist.forEach(item => {
        if (item.client.type === CustomerTypeEnum.OPTIONAL_LEGAL_REPRESENTATIVE) {
          this.clientList.push(item.client);
        }
      });

      if (
        !hasItemChecklistOptional &&
        this.proposalData &&
        this.proposalData?.proposalData?.client === SubmitPersonType.PJ
      ) {
        return this.goToAgentLegal();
      }

      if (this.currentPlatform.isMobile && this.checklist[0].client.person === SubmitPersonType.PF) {
        this.handleSelectedClient(this.checklist[0].client);
        this.goToInstructions();
        return;
      }

      if (this.currentPlatform.isDesktop && isPhysicalPerson) {
        this.handleSelectedClient(this.checklist[0].client);

       // this.checksTheTypeOfBiometricsOrActiveSignature();
      }

      this.generateNewLink(this.antifraudChecklistItem.client);
      this.goToQRCode();
      this.pollBiometryStatus();
      return;
    }

    // Checks if client is physical person, triggers link generation, polling and navigates to QR Code.
    if (isPhysicalPerson) {
      if (this.currentPlatform.isMobile && this.checklist[0].client.person === SubmitPersonType.PF) {
        this.handleSelectedClient(this.checklist[0].client);
        this.goToInstructions();
        return;
      }

      this.goToQRCode();
      this.pollBiometryStatus();
      return;
    }

    // Check if proposal has only one client and is not physical person, navigates to Agent Legal.
    if (hasOneClient || !hasItemChecklistOptional) {
      if (this.clientList[0].biometryStatus === ClientBiometryStatusEnum.REMAKE) {
        if (this.currentPlatform.isDesktop) return this.goToQRCode();

        this.handleSelectedClient(this.checklist[0].client);
        return this.goToInstructions();
      }

      return this.goToAgentLegal();
    }

    // Check if proposal already has multiple clients, triggers polling and navigates to QR Code.
    if (hasMultipleClients) {
      this.goToQRCode();
      this.pollBiometryStatus();
      return;
    }
  }

  public checkBiometryReturn(checklist: ChecklistModel): void {
    if (
      !this.currentPlatform.isDesktop &&
      (checklist.status === ChecklistStatusEnum.ANALYSIS ||
        checklist.status === ChecklistStatusEnum.REMAKE ||
        checklist.status === ChecklistStatusEnum.RELEASE_INTERNAL)
    ) {
      this.pollBiometryStatus();
    }
  }

  private setInitialBiometryState(): void {
    this.getCurrentPlatform();
    this.biometryStatusProgress = BiometryStatusEnum.BIOMETRY_PENDING;
    this.currentPlatform.isMobile ? this.goToInstructions() : this.goToAgentLegal();
  }

  private checkCrossedFlux(): void {
    if (!this.isCrossedFlux) {
      this.proposalConditionsAccepted = false;

      if (!!this.proposalData) {
        const { contract, annualReturnRate, returnRate, planType, insurance } = this.proposalData;
        this.proposal = {
          ...this.proposal,
          contract,
          conditionFinanced: {
            ...this.proposal.conditionFinanced,
            returnTaxPercentage: +returnRate,
            annualReturnTaxPercentage: +annualReturnRate
          },
          planType: planType as PaymentPlanType,
          insurance: insurance === 'Sim'
        };
        this.getBiometry();
      }

      this.biometryService.clearCrossedFluxTimer();
      return;
    }

    this.biometryService.setCrossedFluxTimer();
    this.checkBiometryType(this.checklist[0]);
    this.goToInstructions();
  }

  private getBiometry() {
    this.biometryStage.subscribe(res => { this.agentLegal = res; });
    if ((this.proposalData?.proposalData?.client === 'J' || this.proposalData?.proposalData?.client === 'F') && this.agentLegal !== BiometryStageEnum.AGENT_LEGAL) {
      this.checksTheTypeOfBiometricsOrActiveSignature();
      this.setAdditionalFluxLabel(
        'FORMALIZATION-ADDITIONAL-FLUX-BIOMETRY-TITLE',
        'FORMALIZATION-ADDITIONAL-FLUX-FACIAL-QR',
        'FORMALIZATION-ADDITIONAL-FLUX-BIOMETRY-BUTTON',
        'SEND-SMS',
        'SEND-TO-SMS',
        'FORMALIZATION-ADDITIONAL-FLUX-SIGNATURE-TITLE',
        'FORMALIZATION-ADDITIONAL-FLUX-SIGNATURE-BUTTON',
        'SELECT-THE-OPTION-TO-SIGN-THE-CONTRACT'
      );
    }
  }

  public acceptProposalConditionSelfie(): void {
    this.proposalConditionSelfieAccepted = true;
  }

  public acceptProposalConditions(): void {
    this.proposalConditionsAccepted = true;
    this.proposalConditionsAcceptedEmitter.emit(this.proposalConditionsAccepted);
  }

  public conditionCardSelfie(): boolean {
    return (
      this.currentPlatform.isMobile &&
      !this.proposalConditionSelfieAccepted &&
      this.biometryType === this.biometryTypeEnum.FACIAL
    );
  }

  public handleNextStep(): void {
    if (!!this.signatureChecklistItem) {
      this.getSignatureSteps();
      this.goToSignatureCapture();
      return;
    }

    if (!!this.facialChecklistItem) {
      const { id, proposal, document } = this.facialChecklistItem;
      const { product, customer } = this.proposal;

      const payload: GetIvNonceDTORequest = {
        id,
        proposal: {
          id: proposal.id,
          supplierChannel: {
            id: proposal.channelSupplier.id
          },
          product
        },
        client: {
          documentNumber: customer ? customer.documentNumber : this.selectedClient.documentNumber
        },
        document: {
          code: document.code,
          docCode: document.docCode,
          name: document.name
        }
      };

      this.store$.dispatch(new fromFormalization.actions.GetIvNonceAction(payload));
    }
  }

  private getSignatureSteps(): void {
    this.biometryService
      .getSignatureTutorialSteps()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.tutorialSteps = res;
      });
  }

  private getBiometrySteps(): void {
    this.biometryService
      .getBiometryTutorialSteps()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.tutorialSteps = res;
      });
  }

  public smsSignature(){
    this.genTagger.setTag({
      page_location: this.category,
      page_section: this.smsTitle,
      event_element: 'button',
      event_label: this.smsTextButton,
      event_action: Tag.Click
    });
    this.sendSMSToClient();
  }

  public handleAdditionalFluxButton(): void {

    if (this.showButtonCam) {
      this.showButtonCam = false;
      return this.sendToBiometry();

    }
    if (this.biometryType === BiometryTypeEnum.SIGNATURE && !!this.selectedClient.email) {
      this.sendMailToClient();
    }

  }

  public sendToTagger(page_section, event_label) {
    this.genTagger.setTag({
      page_location: this.category,
      page_section: page_section,
      event_element: 'button',
      event_label: event_label,
      event_action: Tag.Click
    });
  }

  private sendToBiometry(): void {
    this.proposalConditionsAccepted = false;
    this.proposalConditionsAcceptedEmitter.emit(this.proposalConditionsAccepted);
    this.isDesktopBiometry = true;
    this.goToInstructions();
  }

  private sendMailToClient(): void {
    const payload = {
      id: +this.getClientChecklist(this.selectedClient).id,
      proposal: {
        id: +this.proposal.id
      },
      client: {
        id: +this.selectedClient.id
      }
    };

    this.formalizationService
      .sendMail(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
  }

  private sendSMSToClient(): void {
    const payload = {
      id: +this.getClientChecklist(this.selectedClient).id,
      proposalId: +this.proposal.id,
      clientId: +this.selectedClient.id
    };
    this.formalizationService
      .sendSMS(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe();
  }

  private getCurrentPlatform(): void {
    this.currentPlatform = {
      deviceInfo: this.deviceDetectorService.getDeviceInfo(),
      isMobile: this.deviceDetectorService.isMobile(),
      isDesktop: this.deviceDetectorService.isDesktop()
    };
  }

  public closeBiometryInstructions(): void {
    // this.biometryStatusProgress = BiometryStatusEnum.SIGNATURE_BIOMETRY;
    this.currentPlatform.isDesktop ? this.goToQRCode() : this.goToInstructions();
    this.proposalConditionsAccepted = false;
    this.proposalConditionsAcceptedEmitter.emit(this.proposalConditionsAccepted);
  }

  public handleSelectedClient(client: ChecklistItemClientDTO) {
    this.proposal = {
      ...this.proposal,
      customer: {
        id: client.id,
        name: client.name,
        documentNumber: client.documentNumber,
        email: client.email
      }
    };

    this.store$.dispatch(new fromFormalization.actions.SetRepresentativeAction(client));
    if (this.currentPlatform.isDesktop) return this.generateNewLink(client);
    const clientChecklist = this.getClientChecklist(client);

    this.checkBiometryType(clientChecklist);
  }

  public generateNewLink(client: ChecklistItemClientDTO): void {
    const clientChecklist = this.getClientChecklist(client);

    const payload: GetCrossedFluxLinkDTORequest = {
      id: +clientChecklist.id,
      proposal: {
        id: +this.proposal.id
      },
      client: {
        id: +clientChecklist.client.id
      }
    };

    this.store$.dispatch(new fromFormalization.actions.GetCrossedFluxLinkAction(payload));
  }

  private getClientChecklist(client: ChecklistItemClientDTO) {
    return client ? this.checklist.find(item => !!item.client && item.client.id === client.id) : this.checklist[0];
  }

  public getAgentsLegal(payload: { agentsLegal: AgentLegalPersonDTO[]; isReturningToEdit: boolean }): void {

    this.selectedClient = null;
    this.crossedFluxLink = { link: null, uuid: null };
    this.biometryService.clearCrossedFluxTimer();
    this.pollCrossedFluxStatus();
    this.isCrossedFluxTimerActive = false;
    if(payload){
      this.checksTheTypeOfBiometricsOrActiveSignature();
      this.setAdditionalFluxLabel(
        'FORMALIZATION-ADDITIONAL-FLUX-BIOMETRY-TITLE',
        'FORMALIZATION-ADDITIONAL-FLUX-FACIAL-QR',
        'FORMALIZATION-ADDITIONAL-FLUX-BIOMETRY-BUTTON',
        'SEND-SMS',
        'SEND-TO-SMS',
        'FORMALIZATION-ADDITIONAL-FLUX-SIGNATURE-TITLE',
        'FORMALIZATION-ADDITIONAL-FLUX-SIGNATURE-BUTTON',
        'SELECT-THE-OPTION-TO-SIGN-THE-CONTRACT'
      );
    }
    this.store$.dispatch(new fromFormalization.actions.SendAgentLegalAction(payload));

  }

  public handleClose(): void {
    this.goToQRCode();
  }

  public handleFacialCapture(results?: any): void {
    if (results && results[0]) {
      const facialImage = results[0].images;

      this.facialChecklistItem.files = [];
      this.facialChecklistItem.files.push({ data: facialImage });

      this.store$.dispatch(
        new fromFormalization.actions.SaveBiometryItemAction({
          ...this.facialChecklistItem,
          encrypted: results[0].encrypted
        })
      );
      this.ishandleFacialCapture.emit(!!results);

      this.endBiometryFlux();
      return;
    }

    this.store$.dispatch(new fromFormalization.actions.ClearBiometryAction());
    this.biometryStatusProgress = BiometryStatusEnum.BIOMETRY_PENDING;
    if (!results) return this.goToInstructions();
  }

  public handleSendSignature(signature: string): void {
    this.ishandleSendSignature.emit(!!signature);
    if (signature) {
      this.signatureChecklistItem.files = [];
      this.signatureChecklistItem.files.push({ data: signature });

      this.store$.dispatch(new fromFormalization.actions.SaveBiometryItemAction(this.signatureChecklistItem));
      this.endBiometryFlux();
      return;
    }

    this.store$.dispatch(new fromFormalization.actions.ClearBiometryAction());
    this.biometryStatusProgress = BiometryStatusEnum.BIOMETRY_PENDING; // Biometry Pending or do we need a SignaturePending state?
    this.goToInstructions();
  }

  public endBiometryFlux(): void {
    this.store$
      .select(fromFormalization.selectors.selectBiometryResponse)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(res => !!res)
      )
      .subscribe(res => {
        this.handleBiometryResponse(res.status);
      });

    if (this.isCrossedFlux) {
      const crossedFluxInfo = this.sessionStorageService.getCrossedFlux();
      this.checklist = crossedFluxInfo.checklists;
      this.pollBiometryCrossFluxStatus();
    }

    if (this.biometryType === BiometryTypeEnum.SIGNATURE) {
      this.store$.dispatch(new fromFormalization.actions.SendSignatureAction(this.proposal));
    }

    if (this.biometryType === BiometryTypeEnum.FACIAL) {
      this.store$.dispatch(new fromFormalization.actions.SendBiometryUploadAction(this.proposal));
    }
  }

  private handleBiometryResponse(status: BiometryResponseEnum): void {
    switch (status) {
      case BiometryResponseEnum.PENDING:
        return this.resolveBiometryPending();
      case BiometryResponseEnum.APPROVED:
        return this.resolveBiometryApproved();
      case BiometryResponseEnum.REMAKE:
        return this.resolveBiometryRemake();
      case BiometryResponseEnum.DENIED:
      case BiometryResponseEnum.CANCELLED:
        return this.resolveBiometryDenied();
      default:
        // This one is when the return is REMAKE
        this.errorFlag = true;
        this.errorMessage = `${this.translateService.instant('FORMALIZATION-BIOMETRY-REMAKE-TEXT')}`;
    }
  }

  private resolveBiometryPending() {
    this.biometryStatusProgress = BiometryStatusEnum.BIOMETRY_PENDING;

    if (this.isCrossedFlux) {
      this.finish.emit(true);
      this.biometryService.crossedFluxLogout();
    }
  }

  private resolveBiometryApproved() {
    switch (this.biometryType) {
      case BiometryTypeEnum.FACIAL:
        this.errorFlag = false;
        this.biometryStatusProgress = BiometryStatusEnum.FACIAL_BIOMETRY_SUCCESS;
        break;
      case BiometryTypeEnum.SIGNATURE:
        this.errorFlag = false;
        this.biometryStatusProgress = this.biometryStatusEnum.SIGNATURE_BIOMETRY_SUCCESS;
        break;
      case BiometryTypeEnum.SIGNATURE_FACIAL:
        // TBD
        break;
    }

    if (this.isCrossedFlux) {
      this.biometryService.crossedFluxLogout();
    }

    if (!this.clientList.every(client => client.biometryStatus === ClientBiometryStatusEnum.DONE)) {
      this.goToQRCode();
    }

    this.finish.emit(true);
  }

  private resolveBiometryRemake() {
    if (this.currentPlatform.isMobile) {
      this.isBiometryPollRunning = false;
      this.goToInstructions();
    }

    if (this.currentPlatform.isDesktop) {
      this.isCrossedFluxTimerActive = false;
      if (!this.isDesktopBiometry) this.goToQRCode();
      this.store$.dispatch(new fromFormalization.actions.CheckBiometryStatusAction(this.proposal.id));
    }
  }

  private resolveBiometryDenied() {
    this.isBiometryPollRunning = false;
    this.isCrossedFluxPollRunning = false;
    this.biometryService.handleBiometryDenied();
  }

  private isCurrClientOutOfCrossFlux(clientID: number): boolean {
    if (!this.isCrossedFlux && this.selectedClient && +this.selectedClient.id === clientID) {
      return true;
    }
    return false;
  }

  private isCurrClientInCrossFluxActivated(clientID: number, clientStatus: ChecklistStatusEnum): boolean {
    if (
      this.isCrossedFluxTimerActive &&
      this.crossedFluxLink &&
      this.selectedClient &&
      +this.selectedClient.id === clientID &&
      clientStatus !== ChecklistStatusEnum.INDEXED &&
      clientStatus !== ChecklistStatusEnum.ANALYSIS &&
      clientStatus !== ChecklistStatusEnum.REMAKE &&
      clientStatus !== ChecklistStatusEnum.DENIED &&
      clientStatus !== ChecklistStatusEnum.RELEASE_INTERNAL
    ) {
      return true;
    }
    return false;
  }

  private setClientBiometryStatus(checklistStatus: { clientId: number; status: ChecklistStatusEnum }[]): void {
    this.clientList = this.clientList.map(client => {
      const currClient = checklistStatus.find(statusClient => statusClient.clientId === +client.id);

      if (currClient) {
        let status: ClientBiometryStatusEnum;

        if (currClient.status === ChecklistStatusEnum.INDEXED) {
          status = ClientBiometryStatusEnum.DONE;
          this.isBiometryPollRunning = false;

          if (this.isCurrClientOutOfCrossFlux(currClient.clientId) && this.currentPlatform.isMobile) {
            this.goToQRCode();
          }

          // remove finished client selected
          if (this.isCurrClientOutOfCrossFlux(currClient.clientId)) this.selectedClient = null;
        }

        if (currClient.status === ChecklistStatusEnum.REMAKE) {
          const stage = this.biometryStage.getValue();

          if (stage === BiometryStageEnum.BIOMETRY_STATUS || stage === BiometryStageEnum.PENDING) {
            this.isBiometryPollRunning = false;
            if (this.isCurrClientOutOfCrossFlux(currClient.clientId)) this.selectedClient = null;
            this.crossedFluxLink = { link: null, uuid: null };
            this.goToQRCode();

            if (this.currentPlatform.isMobile && this.clientList.length === 1) {
              this.checkBiometryType(this.checklist[0]);
              this.goToInstructions();
            }
          }
        }

        if (this.isCurrClientInCrossFluxActivated(currClient.clientId, currClient.status)) {
          status = ClientBiometryStatusEnum.IN_PROGRESS;
        }

        return {
          ...client,
          biometryStatus: status || this.setClientStatus(currClient.status)
        };
      }
      return client;
    });
  }

  private finishBiometryStep(isAllBiometryPassed: boolean) {
    if (isAllBiometryPassed) {
      this.finish.emit(true);
      this.isBiometryPollRunning = false;
      this.isCrossedFluxPollRunning = false;
      this.biometryStatusProgress = BiometryStatusEnum.FINISHED;
      return this.goToDone();
    }
    this.biometryStatusProgress = BiometryStatusEnum.BIOMETRY_PENDING;
    this.goToPending();
  }

  private isAllBiometryPassed(checklistStatus): boolean {
    return checklistStatus.every(item => item.status === ChecklistStatusEnum.INDEXED);
  }

  private isBiometryFinished(checklistStatus): boolean {
    return checklistStatus.every(
      item =>
        item.status === ChecklistStatusEnum.INDEXED ||
        item.status === ChecklistStatusEnum.ANALYSIS ||
        item.status === ChecklistStatusEnum.RELEASE_INTERNAL
    );
  }

  private pollBiometryStatus(): void {
    if (!this.isBiometryPollRunning) {
      this.store$.dispatch(new fromFormalization.actions.CheckBiometryStatusAction(this.proposal.id));
      this.isBiometryPollRunning = true;
      this.store$
        .select(fromFormalization.selectors.selectLatestBiometryStatus)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          tap(status => {
            this.getStatusBiometryPJ();
            const checklistStatus = status.checklist;
            this.setClientBiometryStatus(checklistStatus);
            if (checklistStatus.length && this.isBiometryFinished(checklistStatus)) {
              this.isCrossedFluxTimerActive = false;
              const isAllBiometryPassed = this.isAllBiometryPassed(checklistStatus);
              this.finishBiometryStep(isAllBiometryPassed);
            }

            const isDenied = checklistStatus.some(item => {
              return item.status === ChecklistStatusEnum.DENIED;
            });
            if (isDenied) return this.resolveBiometryDenied();
          }),
          delay(10000)
        )
        .subscribe(latestStatus => {
          const checklistStatus = latestStatus.checklist;
          if (!this.isAllBiometryPassed(checklistStatus)) {
            this.store$.dispatch(new fromFormalization.actions.CheckBiometryStatusAction(this.proposal.id));
          }
        });
    }
  }

  public noAdditionalAgents(event){
    if(event) {this.noAdditionalAgent= true}
    if (this.noAdditionalAgent) {
      this.checksTheTypeOfBiometricsOrActiveSignature();
      this.setAdditionalFluxLabel(
        'FORMALIZATION-ADDITIONAL-FLUX-BIOMETRY-TITLE',
        'FORMALIZATION-ADDITIONAL-FLUX-FACIAL-QR',
        'FORMALIZATION-ADDITIONAL-FLUX-BIOMETRY-BUTTON',
        'SEND-SMS',
        'SEND-TO-SMS',
        'FORMALIZATION-ADDITIONAL-FLUX-SIGNATURE-TITLE',
        'FORMALIZATION-ADDITIONAL-FLUX-SIGNATURE-BUTTON',
        'SELECT-THE-OPTION-TO-SIGN-THE-CONTRACT'
      );
    }
  }

  private pollCrossedFluxStatus(): void {
    if (!this.isCrossedFluxPollRunning) {
      this.isCrossedFluxPollRunning = true;

      this.store$
        .select(fromFormalization.selectors.selectLatestCrossedFluxStatus)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          map(latestStatus => latestStatus.status),
          tap(status => {
            if (status === CrossedFluxPollingStatusEnum.INITIATED) {
              this.isCrossedFluxTimerActive = true;
              this.isCrossedFluxPollRunning = false;
              this.genTagger.setTag({
                page_location: this.category,
                page_section: `${this.translateService.instant(this.biometryType === this.biometryTypeEnum.FACIAL ? 'QR-TITLE-BIOMETRY': 'QR-TITLE-SIGNATURE')}`,
                event_element: 'img',
                event_label: `${this.translateService.instant('QR-SUBTITLE')}`,
                event_action: Tag.qrCode
              });
            }
          }),
          delay(5000)
        )
        .subscribe(status => {
          if (status === CrossedFluxPollingStatusEnum.NOT_INITIATED && this.crossedFluxLink.uuid) {
            const payload = { uuid: this.crossedFluxLink.uuid };
            this.store$.dispatch(new fromFormalization.actions.GetCrossedFluxStatusAction(payload));
          }
        });
    }
  }

  public showAdditionalFluxButton(): boolean {
    if (
      this.selectedClient &&
      ((this.biometryType === BiometryTypeEnum.SIGNATURE && !!this.selectedClient.email) ||
        (this.biometryType === BiometryTypeEnum.FACIAL && this.currentPlatform.isDesktop)) &&
      this.selectedClient.biometryStatus !== ClientBiometryStatusEnum.DONE
    ) {
      return true;
    }

    return false;
  }

  public showButtonNotAntifraud(): boolean {
    const antifraudChecklistItem = this.checklist.some(item => {
      return item.type.code === ChecklistItemTypeEnum.ANTIFRAUD;
    });
    const hasRemake = this.clientList.some(client => {
      return client.biometryStatus === ClientBiometryStatusEnum.REMAKE;
    });

    if (antifraudChecklistItem && hasRemake) return false;
    return true;
  }

  public showBackToAgentLegalOrQrCode() {
    this.noAdditionalAgent = false;
    const antifraudChecklistItem = this.checklist.some(item => {
      return item.type.code === ChecklistItemTypeEnum.ANTIFRAUD;
    });

    if (antifraudChecklistItem || this.currentPlatform.isDesktop) {
      return this.goToAgentLegal();
    }

    return this.goToQRCode();
  }

  public returnToGoToInstructions(): void {
    this.proposalConditionsAccepted = false;
    this.proposalConditionsAcceptedEmitter.emit(this.proposalConditionsAccepted);
    this.goToInstructions();
  }

  public goToAgentLegal(): void {
    this.biometryService.setBiometryStage(BiometryStageEnum.AGENT_LEGAL);
  }

  public goToQRCode(): void {
    this.biometryService.setBiometryStage(BiometryStageEnum.QR_CODE);
  }

  public goToAccessLink(): void {
    this.biometryService.setBiometryStage(BiometryStageEnum.ACCESS_LINK);
  }

  public goToInstructions(): void {
    this.biometryService.setBiometryStage(BiometryStageEnum.INSTRUCTIONS);
  }

  public goToSignatureCapture(): void {
    this.biometryService.setBiometryStage(BiometryStageEnum.SIGNATURE_CAPTURE);
  }

  public goToFacialCapture(): void {
    this.biometryService.setBiometryStage(BiometryStageEnum.FACIAL_CAPTURE);
  }

  public goToChallengeQuestions(): void {
    this.biometryService.setBiometryStage(BiometryStageEnum.CHALLENGE_QUESTIONS);
  }

  public goToStatus(): void {
    this.biometryService.setBiometryStage(BiometryStageEnum.BIOMETRY_STATUS);
  }

  public goToDone(): void {
    this.biometryService.setBiometryStage(BiometryStageEnum.DONE);
  }

  public goToPending(): void {
    this.biometryService.setBiometryStage(BiometryStageEnum.PENDING);
  }

  public returnToQrCode(): void {
    this.store$.dispatch(new fromFormalization.actions.CheckBiometryStatusAction(this.proposal.id));
    this.selectedClient = null;
    this.goToQRCode();
  }

  public onShowStepper(isVisible: boolean): void {
    this.store$.dispatch(new SetShowStepper(isVisible));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
