import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Optional,
  Output,
  Self,
  ViewChild
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: SearchInputComponent }]
})
export class SearchInputComponent implements MatFormFieldControl<any>, ControlValueAccessor, OnInit {
  static keyError = 'invalid-item';

  @ViewChild('trigger', { read: ElementRef }) input: ElementRef<HTMLElement>;

  @Input() valueFrom;
  @Input() label;
  @Input() items = [];
  @Input() showLabelFn: Function;
  @Input() validateSelectedItem = true;
  @Input() required = true;

  @Output() changeOut = new EventEmitter();
  @Output() ngControlValue = new EventEmitter();
  @Input() timeOutChange = 0;

  filteredOptions: Observable<any>;

  id: string;
  value: any;
  stateChanges = new Subject<void>();
  placeholder: string;
  focused: boolean;
  disabled: boolean;
  controlType?: string;
  autofilled?: boolean;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private errorMatcher: ErrorStateMatcher,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    if (!this.showLabelFn) {
      this.showLabelFn = this.displayWith;
    }
  }

  ngOnInit() {
    this.filteredOptions = this.ngControl.control.valueChanges.pipe(
      startWith(''),
      filter(value => value !== null),
      map(value => {
        const searchedvalue = this.formatValue(value[this.label]) || this.formatValue(value);
        return this.items.filter(item => item[this.label].toLowerCase().includes(searchedvalue));
      })
    );

    this.fm.monitor(this.elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    const validators = [];

    if (this.ngControl.control.value !== null) {
      this.ngControl.control.markAsDirty();
    }

    if (this.required) {
      validators.push(Validators.required);
    }

    if (this.validateSelectedItem) {
      validators.push(this.validatorSelectItem.bind(this));
    }

    this.ngControl.control.setValidators(validators);

    this.ngControl.control.valueChanges
      .pipe(
        distinctUntilChanged(),
        debounceTime(this.timeOutChange)
      )
      .subscribe(() => {
        if (this.ngControl.control.valid) {
          this.changeOut.emit();
        }
      });
  }

  setDescribedByIds(ids: string[]): void {}
  onContainerClick(event: MouseEvent): void {}
  writeValue(obj: any): void {}
  registerOnChange(fn: any): void {}
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {}

  displayWith(value): string {
    if (!value) return '';
    const foundItem = this.items.find(item => this.formatValue(item[this.valueFrom]) === this.formatValue(value));

    if (foundItem) {
      return foundItem[this.label];
    } else {
      return '';
    }
  }

  formatValue(value) {
    if (!value) return value;
    return value.toString().toLowerCase();
  }

  get empty() {
    return this.ngControl.control && !this.ngControl.control.value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  get errorState() {
    return this.errorMatcher.isErrorState(this.ngControl.control as FormControl, null);
  }

  validatorSelectItem(control: AbstractControl) {
    const { value } = control;
    const foundItem = this.items.find(
      item => value && item[this.valueFrom] && item[this.valueFrom].toString() === value.toString()
    );

    if (!foundItem && control.dirty) {
      return {
        [SearchInputComponent.keyError]: true
      };
    } else {
      return null;
    }
  }

  public handleDropdownClick(event: any, trigger: MatAutocompleteTrigger): void {
    if (this.ngControl.disabled) return;

    this.ngControl.control.patchValue('');
    this.ngControlValue.emit(this.ngControl);

    event.stopPropagation();
    trigger.openPanel();
  }

  public onOpenPanel(event: any, trigger: MatAutocompleteTrigger): void {
    event.stopPropagation();

    if (trigger.autocomplete && trigger.autocomplete.isOpen) {
      trigger.closePanel();
    } else {
      this.input.nativeElement.click();
    }
  }
}
