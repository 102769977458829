<!-- <div class="container showcase">
  <div class="showcase-title d-none d-sm-block">
    <div>{{ 'SHOWCASE-TITLE' | translate }}</div>
  </div>
  PROPOSALS
  <app-showcase-section
    [title]="'OPEN-PROPOSALS'"
    [link]="'ACCESS-ALL-PROPOSALS'"
    [linkRoute]="'/showcase/open-proposal'"
    *ngIf="openProposals?.isLoading || openProposals?.data?.length"
  >
    <owl-carousel-o [options]="proposalsOptions" *ngIf="!openProposals?.isLoading; else carouselSkeleton">
      OPEN-PROPOSALS
      <ng-container *ngIf="openProposals?.data?.length">
        <ng-container *ngFor="let card of openProposals?.data; let i = index">
          <ng-template carouselSlide>
            <app-proposal-card
              [total]="openProposals?.data.length"
              [pendency]="card.stProposals.codigoBotaoVitrine === 'SOLVE-PENDING'"
              [data]="card"
              (actionClick)="handleDetailsProposal($event)"
              (continueProposal)="handleContinueProposal($event)"
              (resumeProposal)="handleResumeProposal($event)"
              (solvePending)="handleSolvePending($event)"
              [indexCardAllProposal]="i"
            ></app-proposal-card>
          </ng-template>
        </ng-container>
      </ng-container>
      PENDING PROPOSALS

    </owl-carousel-o>
  </app-showcase-section>

  ACTIVE CONTRACTS
  <app-showcase-section
    [title]="'CONTRACT-CONT-TITLE'"
    [link]="contracts?.data?.length > 2 ? 'ACCESS-ALL-CONTRACTS' : null"
    [linkRoute]="'../contract'"
    *ngIf="contracts?.data?.length > 0 && contracts?.data != null && (!contracts?.isLoading || contracts?.data?.length)"
  >
    <ng-container *ngIf="contracts?.data?.length > 0">
      <owl-carousel-o
        [options]="activeContractsOptions"
        *ngIf="contracts?.data?.length > 0 && contracts?.data != null; else carouselSkeleton"
      >
        <ng-container *ngFor="let card of contracts.data; let i = index">
          <ng-template carouselSlide>
            <app-active-contracts-card
              [data]="card"
              (actionClick)="handleActiveContractsEmit($event)"
              [indexCardAllContracts]="i"
              [flagOneContract]="flagOneContract"
              [lengthContract]="contracts.data.length"
            ></app-active-contracts-card>
          </ng-template>
        </ng-container>
      </owl-carousel-o>
    </ng-container>
  </app-showcase-section>

  PERSONALIZED OFFERS

  <app-showcase-section
    [title]="'PERSONALIZED-OFFERS'"
    *ngIf="
      pendingProposals?.isLoading ||
      personalizedOffers?.isLoading ||
      (personalizedOffers?.data?.length && !openProposals?.data?.length && !activeContracts?.data?.length)
    "
  >
    <owl-carousel-o
      [options]="offersOptions"
      *ngIf="
        !openProposals?.isLoading && !activeContracts?.isLoading && !personalizedOffers?.isLoading;
        else carouselSkeleton
      "
    >
      <ng-container *ngFor="let card of personalizedOffers?.data">
        <ng-template carouselSlide>
          <app-personalized-offer-card
            [data]="card"
            (actionClick)="handlePersonalizedOfferEmit($event)"
          ></app-personalized-offer-card>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </app-showcase-section>

  INSTALLMENT PURCHASES
  <ng-container *ngIf="window > 425" class="col">
    <app-showcase-section class="single-row-padding">
      <app-installment-purchases-card
        (actionClick)="handleInstallmentPurchasesEmit()"
        *ngIf="!openProposals?.isLoading; else carouselSkeleton"
      ></app-installment-purchases-card>
    </app-showcase-section>

    <div class="divider d-sm-none"></div>
  </ng-container>

  <ng-container *ngIf="window <= 425" class="col">
    <app-showcase-section class="single-row-padding" *ngIf="pendingProposals?.isLoading">
      <app-installment-purchases-card
        (actionClick)="handleInstallmentPurchasesEmit()"
        *ngIf="!openProposals?.isLoading; else carouselSkeleton"
      ></app-installment-purchases-card>
    </app-showcase-section>

    <div class="divider d-sm-none"></div>
  </ng-container>
  SPECIAL OFFERS

  <app-showcase-section
    [title]="'SPECIAL-OFFERS'"
    [link]="specialOffers?.data?.length > 3 ? 'ACCESS-ALL-SPECIAL-OFFERS' : null"
    [linkRoute]="'/showcase/special-offers'"
    *ngIf="specialOffers?.isLoading || specialOffers?.data?.length"
  >
    <owl-carousel-o
      [options]="offersOptions"
      *ngIf="
        !openProposals?.isLoading && !activeContracts?.isLoading && !specialOffers?.isLoading;
        else carouselSkeleton
      "
    >
      <ng-container *ngFor="let card of specialOffers?.data">
        <ng-template carouselSlide>
          <app-special-offer-card [data]="card" (actionClick)="handleSpecialOfferEmit($event)"></app-special-offer-card>
        </ng-template>
      </ng-container>
    </owl-carousel-o>
  </app-showcase-section>

  <div class="row justify-content-center d-sm-none">
    <mat-card class="showcase-qr-card">
      <mat-card-header>
        <div mat-card-avatar class="showcase-qr-icon">
          <img src="./assets/images/ic_qrcode.svg" alt="qr-code" />
        </div>
        <mat-card-title> {{ 'SUBSEGMENT-CARD-QR-TITLE' | translate }} </mat-card-title>
        <mat-card-subtitle> {{ 'SUBSEGMENT-CARD-QR-SUBTITLE' | translate }} </mat-card-subtitle>
      </mat-card-header>
    </mat-card>
  </div>
</div>

<ng-template #carouselSkeleton>
  <ngx-skeleton-loader
    [count]="skeletonConfig.count"
    [appearance]="skeletonConfig.appearance"
    [theme]="skeletonConfig.theme"
  >
  </ngx-skeleton-loader>
</ng-template> -->

<ng-template #carouselSkeleton>
  <ngx-skeleton-loader
    [count]="skeletonConfig.count"
    [appearance]="skeletonConfig.appearance"
    [theme]="skeletonConfig.theme"
  >
  </ngx-skeleton-loader
></ng-template>
