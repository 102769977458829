import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { GET_PROPOSAL_CONFIRMATION } from '@shared/graphql/queries/proposal-information.query';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PaymentResponse } from '../models/payment.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentsService {
  restartOrdenation$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  restartOrdenation = this.restartOrdenation$.asObservable();

  constructor(private http: HttpClient, private apollo: Apollo) {}

  public getValidationAccount() {
    return this.http.get(`${environment.apiRootUrl}/paymentpanel/1.0.0/validation-of-account`);
  }

  public getListPayments(storeId, page, perPage, filter): Observable<PaymentResponse> {
    return this.getListPaymentsByFilter(storeId, page, perPage, filter);
  }

  public getFilterPayments(shopId, filters): Observable<any> {
    const searchString = this.createFilterString(filters);
    return this.http.get(`${environment.apiRootUrl}/paymentpanel/1.0.0/status/count/${shopId}?${searchString}`);
  }

  public getListPaymentsByFilter(storeId, page, perPage, filter): Observable<any> {
    let payload = {
      shopId: storeId,
      page,
      perPage
    } as any;

    if (filter.status) {
      payload = {
        ...payload,
        status: filter.status
      };
    }
    if (filter.type) {
      payload = {
        ...payload,
        type: filter.type.toString()
      };
    }
    if (filter.salesman) {
      payload = {
        ...payload,
        seller: filter.salesman.toString()
      };
    }
    if (filter.period) {
      payload = {
        ...payload,
        period: filter.period
      };
    }
    if (filter.search) {
      payload = {
        ...payload,
        search: filter.search
      };
    }
    if (filter.sort && filter.sort.length) {
      payload = {
        ...payload,
        sort: filter.sort
      };
    }

    return this.http.post<any>(`${environment.apiRootUrl}/paymentpanel/1.0.0/listProposals/`, payload);
  }

  // Listagem de vendedores
  public getSellers(shopId, search): Observable<any> {
    const path = search === '' ? '' : `?search=${search}`;
    return this.http.get(`${environment.apiRootUrl}/paymentpanel/1.0.0/commercialUnit/${shopId}/vendors` + path);
  }

  public getPaymentDetail(id: number): Observable<any> {
    const body = {
      proposalId: id
    };
    return this.http.post(`${environment.apiRootUrl}/paymentpanel/1.0.0/detailProposal/`, body);
  }

  public paymentRequest(payload: number[]): Observable<any> {
    return this.http.post(`${environment.apiRootUrl}/paymentpanel/1.0.0/request-payment`, payload);
  }

  public paymentRequestShedule(payload: number[]): Observable<any> {
    return this.http.post(`${environment.apiRootUrl}/paymentpanel/1.0.0/request-schedule`, payload);
  }


  public paymentMultipleRequest(payload: number[]): Observable<any> {
    return this.http.post(`${environment.apiRootUrl}/paymentpanel/1.0.0/request-payment`, payload);
  }

  public paymentMassiveUpload(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(`${environment.apiRootUrl}/paymentpanel/1.0.0/upload`, formData);
  }

  public cancelProposal(proposalId: number, reasonId: number, reasonDetails: string): Observable<any> {
    const payload: any[] = [];

    const obj = {
      proposalId: proposalId.toString(),
      channel: '2',
      cancelId: reasonId.toString(),
      reasonDetails
    };

    payload.push(obj);

    return this.http.post(`${environment.apiRootUrl}/paymentpanel/1.0.0/proposal/cancel`, payload);
  }

  public massiveCancelProposal(payload: any[]): Observable<any> {
    return this.http.post(`${environment.apiRootUrl}/paymentpanel/1.0.0/proposal/cancel`, payload);
  }

  public downloadMultipleProposal(shopId, filter): Observable<any> {
    const searchString = this.createFilterString(filter);
    return this.http
      .get(`${environment.apiRootUrl}/paymentpanel/1.0.0/listProposals/download/${shopId}?${searchString}`, {
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response;
        })
      );
  }

  private createFilterString(filter): string {
    let searchString = '';

    if (filter.search) {
      searchString = searchString + `&search=${filter.search}`;
    }

    if (filter.period) {
      searchString = searchString + `&period=${filter.period}`;
    }

    if (filter.salesman) {
      searchString = searchString + `&seller=${filter.salesman.toString()}`;
    }

    if (filter.type) {
      searchString = searchString + `&type=${filter.type.toString()}`;
    }

    if (filter.status) {
      searchString = searchString + `&status=${filter.status.toString()}`;
    }

    if (filter.sort && filter.sort.length) {
      searchString = searchString + `&sort=${filter.sort[0].sort}:${filter.sort[0].ordenation}`;
      if (filter.sort.length > 1) {
        filter.sort.forEach((item, index) => {
          if (index >= 1) {
            searchString = searchString + `,${item.sort}:${item.ordenation}`;
          }
        });
      }
    }

    return searchString;
  }

  public restartOrdenationAfterCleanFilters(value: boolean) {
    this.restartOrdenation$.next(value);
  }

  public getProposalConfirmation(proposalCode?: number): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: GET_PROPOSAL_CONFIRMATION,
        variables: {
          proposalCode
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: any) => {
          return response.data.getProposalConfirmationDataByCode;
        })
      );
  }
}
