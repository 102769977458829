import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SubsegmentImagesMap } from '@shared/constants/subsegments.enum';
import { SubsegmentModel } from '@shared/models/subsegment.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

const subsegmentsMock: Partial<SubsegmentModel>[] = [
  {
    id: 4,
    code: '301',
    name: 'Viagens'
  },
  {
    id: 1,
    code: '300',
    name: 'Móveis e decoração'
  },
  {
    id: 14,
    code: '311',
    name: 'Materiais de construção'
  },
  {
    id: 5,
    code: '302',
    name: 'Tecnologia'
  },
  {
    id: 7,
    code: '304',
    name: 'Equipamento médico'
  },
  {
    id: 37,
    code: '356',
    name: 'Energia solar'
  }
];

@Injectable({
  providedIn: 'root'
})
export class SubsegmentService {
  constructor(private http: HttpClient) {}

  public getSubsegments(): Observable<SubsegmentModel[]> {
    // MOCK
    return of(subsegmentsMock).pipe(
      map(subsegmentList =>
        subsegmentList.map(subsegment => {
          return { ...subsegment, imageUrl: SubsegmentImagesMap[subsegment.id] } as SubsegmentModel;
        })
      )
    );
    // END MOCK
    // TBD: Endpoint
    // code block commented out for being after the "return" and being pointed by sonar as issue
    // return this.http.get<SubsegmentModel[]>(`${environment.apiRootUrl}`).pipe(
    //   map(subsegmentList =>
    //     subsegmentList.map(subsegment => {
    //       return { ...subsegment, imageUrl: SubsegmentImagesMap[subsegment.id] } as SubsegmentModel;
    //     })
    //   )
    // );
  }
}
