import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { map, delay, mergeMap } from 'rxjs/operators';
import { of, merge } from 'rxjs';
import { actions } from './index';

@Injectable()
export class AlertEffects {
  constructor(private actions$: Actions) {}

  @Effect()
  addAlert$ = this.actions$.pipe(
    ofType<actions.AddAlert>(actions.AlertActionsTypes.ADD_ALERT),
    mergeMap(action => {
      const id = new Set();
      return merge(
        of(new actions.AddAlertSuccess({ id, ...action.payload })),
        of(new actions.RemoveAlert(id)).pipe(delay(10000))
      );
    })
  );

  @Effect()
  removeAlert$ = this.actions$.pipe(
    ofType<actions.RemoveAlert>(actions.AlertActionsTypes.REMOVE_ALERT),
    map(action => new actions.RemoveAlertSuccess(action.payload))
  );
}
