import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-recovery-container',
  templateUrl: './password-recovery.container.component.html',
  styleUrls: ['./password-recovery.container.component.scss']
})
export class PasswordRecoveryContainer implements OnInit {
  constructor() {}

  ngOnInit() {}
}
