<app-header-section
  [position]="pos.pos"
  [size]="pos.size"
  titleSection="STEP-REGISTER-INTRODUCTION-OBSERVATION"
></app-header-section>

<!-- NUMBER ORDER/RESERVATION -->
<div class="row">
  <div class="col-12 col-md-12">
    <div *ngIf="form" [formGroup]="form">
      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <mat-form-field *ngIf="!!form.get('orderNumber')">
            <mat-label *ngIf="!proposal?.isTravelSubSegment">
              {{ 'Request number' | translate }}
            </mat-label>
            <mat-label *ngIf="proposal?.isTravelSubSegment">
              {{ 'NUMBER_RESERVATION' | translate }}
            </mat-label>

            <app-custom-input
              class="input-with-icon"
              formControlName="orderNumber"
              appTagger
              [event_action]="Insert"
              [event_category]="event_category"
              event_label="número do pedido"
            >
            </app-custom-input>
            <mat-error *ngIf="form.get('orderNumber').touched && form.get('orderNumber').invalid">
              {{ 'STEP-REGISTER-OBSERVATION_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12">
          <span>{{ 'Additional comments' | translate }}</span>
          <mat-form-field *ngIf="!!form.get('comment')" appearance="outline">
            <textarea
              matInput
              class="input-with-icon m-0"
              formControlName="comment"
              rows="10"
              placeholder="Insira aqui seus comentários..."
              appTagger
              [event_action]="Insert"
              [event_category]="event_category"
              event_label="comentários adicionais"
            ></textarea>
            <mat-error *ngIf="form.get('comment').touched && form.get('comment').invalid">
              {{ 'STEP-REGISTER-OBSERVATION_ERROR' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </div>
</div>
