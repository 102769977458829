export enum CustomerTypeEnum {
  MAIN_CLIENT = 'CLI',
  MAIN_LEGAL_REPRESENTATIVE = 'RPP',
  OPTIONAL_LEGAL_REPRESENTATIVE = 'RPO'
}

export enum MailTypeEnum {
  COMMERCIAL = 'C',
  PERSONAL = 'P'
}

export enum PhoneTypeEnum {
  MOBILE_PHONE = 'C',
  TELEPHONE = 'T'
}

export enum AddressTypeEnum {
  COMMERCIAL = 'C',
  RESIDENTIAL = 'R'
}

export enum RegisterProposalFlags {
  // CIP e Personas
  RECOVERED_BY_CIP_OR_PE = 'RECUPCIPPE', // Existem dados recuperados do CIP ou Personas
  ACCEPTED_DATA_CIP_OR_PE = 'AUTRCCIPPE', // Uma vez foi aceito ou não a recuperação de dados dos serviços de CIP ou Personas

  // Perguntas do BACEN
  AUT_UTILZ_LIM_CRED = 'AUTLIMCRED',
  AUT_DEB_PCIAL_CTA = 'AUTDEBPCTA',

  BIOMETRY_ENABLED = 'SOLVALBMET',
  SOLIC_DOCUMENTOSCOPIA = 'SOLDOCSCOP',
  SOLIC_ANLSE_CD = 'SOLANLSECD',

  PGTO_BG_HORA_VALID = 'BGHRPGVLID',
  PGTO_PZ_HORA_VALID = 'PZHRPGVLID',
  PGTO_BG_RPROC_HORA = 'BGHRPGRPRC',
  PGTO_PZ_RPROC_HORA = 'PZHRPGRPRC'
}
