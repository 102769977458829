import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AgentCertified, CertifiedAgentModel } from '@app/certified-agent/models/certified-agent.model';
import { CertifiedAgentService } from '@app/certified-agent/services/certified-agent.service';
import { AppState } from '@app/core/state';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { BrazilValidators } from '@shared/interfaces/validators/brazil-validators.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { SwalService } from '@shared/services/swal/swal.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-certified-agent-form',
  templateUrl: './certified-agent-form.component.html',
  styleUrls: ['./certified-agent-form.component.scss']
})
export class CertifiedAgentFormComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();

  public window = window;
  public newUserForm: FormGroup;
  type: any;

  readonly Insert: Tag = Tag.Insert;
  readonly Selecionar: Tag = Tag.Selecionar;

  @Input() screenType: string;

  @Output() back = new EventEmitter<any>();

  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  public documentNumberMask: string;
  public saleId$: Observable<number> = this.store$.select('tabs').pipe(map(tab => +tab.selectedTab.code));

  initialDocument: any = null;
  public agenteCertificado: CertifiedAgentModel;
  public showMsgAgenteCertificado = false;

  constructor(
    private fb: FormBuilder,
    private internationalizationService: InternationalizationService,
    private modalService: NgbModal,
    private genTagger: GenTagger,
    private store$: Store<AppState>,
    private swalService: SwalService,
    private agentService: CertifiedAgentService
  ) {}

  ngOnInit() {
    this.createForm();

    this.newUserForm
      .get('documentNumber')
      .valueChanges.pipe(debounceTime(200))
      .subscribe(([newValue]) => {
        if (newValue.length !== 11 && newValue === this.initialDocument) {
          this.newUserForm.get('documentNumber').setErrors({ valid: false });
        }

        this.agenteCertificado = null;
      });

    this.genTagger.setTag({
      event_category: this.category(),
      event_label: this.screenType === 'new-user' ? 'Novo usuário' : 'Editar usuário',
      event_action: `Abriu modal - ${this.screenType === 'new-user' ? 'Novo usuário' : 'Editar usuário'}`
    });
  }

  public category(): string {
    if (this.screenType === 'new-user') {
      return '/portallojista/gestaodeusuarios/novousuario';
    }
    if (this.screenType === 'update-user') {
      return '/portallojista/gestaodeusuarios/editarusuario';
    }
  }

  backManagementList() {
    this.back.emit({
      type: 'listagem'
    });
  }

  close(content) {
    this.modalService.dismissAll(content);
  }

  public searchAgent(): void {
    const payload = {
      documentNumber: this.newUserForm.get('documentNumber').value,
      certificationNumber: ''
    };

    this.agentService.getCertifiedAgent(payload).subscribe(
      (agente: AgentCertified) => {
        this.agenteCertificado = agente.agentCertified[0];
      },
      error => {
        const msg = error.error.messages[2].message;
        this.swalService.createAlertWarning(msg);
      }
    );
  }

  public associateAgent(): void {
    if (this.newUserForm.invalid || !this.agenteCertificado) return;

    const payload = {
      agentCertifiedNumber: this.agenteCertificado.agentCertifiedNumber,
      certificationNumber: this.agenteCertificado.certificationNumber
    };

    this.agentService.updateAgentRelationship(payload).subscribe(
      (data: any) => {
        this.showMsgAgenteCertificado = true;
        this.agenteCertificado = null;
        this.newUserForm.reset();
      },
      error => {
        this.showMsgAgenteCertificado = false;
        const msg = error.error.messages[2].message;
        this.swalService.createAlertWarning(msg).then(data => {
          if (data.value) {
            this.backManagementList();
          }
        });
      }
    );
  }

  private createForm(): void {
    const validatorsBr = this.pioneerValidators as BrazilValidators;

    this.newUserForm = this.fb.group({
      documentNumber: new FormControl('', validatorsBr.documentHybrid)
    });
  }

  tag(category, action, label) {
    this.genTagger.setTag({
      event_action: `Selecionou - ${action}`,
      event_category: category,
      event_label: label
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
