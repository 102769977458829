import { AppState } from '@app/core/state';
import { createSelector } from '@ngrx/store';

export const profileSelect = (state: AppState) => state.profile;

export const menuSelector = createSelector(
  profileSelect,
  state => state.menus
);

export const userProfileSelector = createSelector(
  profileSelect,
  state => state.userProfile
);

export const userPreferencesSelector = createSelector(
  profileSelect,
  // state => state.userPreferences
  state => state.userPreferences.homePage
);

// NOTE: - Se redundante refatorar posteriormente
export const homePageSelector = createSelector(
  profileSelect,
  state => state.homePage
);
