import { TranslationWidth } from '@angular/common';
import { Injectable } from '@angular/core';
import { NgbDatepickerI18n, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

const I18N_VALUES = {
  weekdays: ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'],
  monthsShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  monthsFull: [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
};

@Injectable()
export class DatepickerI18nService extends NgbDatepickerI18n {
  constructor(private translate: TranslateService) {
    super();
  }

  getWeekdayShortName(weekday: number): string {
    return this.translate.instant(I18N_VALUES.weekdays[weekday - 1]);
  }

  getMonthShortName(month: number): string {
    return this.translate.instant(I18N_VALUES.monthsShort[month - 1]);
  }

  getMonthFullName(month: number): string {
    return this.translate.instant(I18N_VALUES.monthsFull[month - 1]);
  }

  getDayAriaLabel(date: NgbDateStruct): string {
    return `${date.day}-${date.month}-${date.year}`;
  }

  getWeekdayLabel(weekday: number, width?: TranslationWidth) {
    return this.translate.instant(I18N_VALUES.weekdays[weekday - 1]);
  }
}
