import { FormControl, ValidatorFn, AbstractControl } from '@angular/forms';
import * as moment from 'moment';

/**
 * Define the interface for create validator on the application
 */
export interface PioneerValidators {
  documentNumber: Array<ValidatorFn>;
  password: Array<ValidatorFn>;
}

/**
 * @param min Minimum valid date for age/date validation.
 * @param max Maximum valid date for age/date validation.
 * @param msg Error message.
 */
export const ValidatePastDate = (min = 0, max = 100, msg = 'dateTooRecent'): ValidatorFn => {
  return (control: FormControl): { [key: string]: boolean } | null => {
    const momentDate: moment.Moment = moment(control.value, ['DD-MM-YYYY', 'DDMMYYYY'], true);

    if (!momentDate.isValid() || control.value.length < 8) {
      return { ['invalidDate']: true };
    } else {
      if (moment().diff(momentDate, 'years', true) < 0) {
        return { ['futureDate']: true };
      } else if (moment().diff(momentDate, 'years', true) < min) {
        return { ['dateTooRecent']: true };
      } else if (moment().diff(momentDate, 'years', true) > max) {
        return { ['dateTooFarInThePast']: true };
      } else {
        return null;
      }
    }
  };
};

/**
 * @param max Max amount of years in the future
 * @param msg Error message.
 */
export const ValidateFutureDate = (max = 100, msg = 'invalidFutureDate'): ValidatorFn => {
  return (control: FormControl): { [key: string]: boolean } | null => {
    const momentDate: moment.Moment = moment(control.value, ['DD-MM-YYYY', 'DDMMYYYY']);

    if (!momentDate.isValid() || control.value.length < 8) {
      return { ['invalidDate']: true };
    } else if (momentDate.isValid() && momentDate.diff(moment(), 'years', true) < 0) {
      return { [msg]: true };
    } else if (momentDate.diff(moment(), 'years', true) > max) {
      return { ['dateTooFarInTheFuture']: true };
    } else {
      return null;
    }
  };
};

export const ValidateDateStartingToday = (min = 1, max = 100, msg = 'invalidFutureDate'): ValidatorFn => {
  return (control: FormControl): { [key: string]: boolean } | null => {
    const momentDate: moment.Moment = moment(control.value, ['DD-MM-YYYY', 'DDMMYYYY']);
    const minDay = moment().subtract(min, 'days');

    if (!momentDate.isValid() || control.value.length < 8) {
      return { ['invalidDate']: true };
    } else if (momentDate.isValid() && momentDate.diff(minDay, 'years', true) < 0) {
      return { [msg]: true };
    } else if (momentDate.diff(minDay, 'years', true) > max) {
      return { ['dateTooFarInTheFuture']: true };
    } else {
      return null;
    }
  };
};

export const ValidateAge = (minAge = 18, maxAge = 100, msg = 'under18'): ValidatorFn => {
  return ValidatePastDate(minAge, maxAge, msg);
};

/**
 * Generic function to validate inputs against a given Regular Expression.
 */
export const GenericRegexValidator = (regex: RegExp | string, msg: string): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control || !control.value) return null;
    return new RegExp(regex).test(control.value) ? null : { [msg]: true };
  };
};

export const ValidateEmail = (msg = 'invalidEmail') => {
  const validEmailRegex: RegExp = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z-]+(\.[a-zA-Z]+)+$/;
  return GenericRegexValidator(validEmailRegex, msg);
};

export const ValidateExactlyXWords = (x = 1, msg = 'invalidNumberOfWords') => {
  const validRegex: RegExp = new RegExp('^[a-zA-Z]+([ ]{1}[a-zA-Z]+){' + (x - 1) + '}$', 'i');
  return GenericRegexValidator(validRegex, msg);
};

export const ValidateAtLeastXWords = (x = 1, msg = 'invalidNumberOfWords') => {
  const validRegex: RegExp = new RegExp('^[a-zA-Z]+([ ]{1}[a-zA-Z]+){' + (x - 1) + ',}$', 'i');
  return GenericRegexValidator(validRegex, msg);
};

export const ValidateBetweenXAndYWords = (x = 1, y = 2, msg = 'invalidNumberOfWords') => {
  const validRegex: RegExp = new RegExp('^[a-zA-Z]+([ ]{1}[a-zA-Z]+){' + (x - 1) + ',' + (y - 1) + '}$', 'i');
  return GenericRegexValidator(validRegex, msg);
};
