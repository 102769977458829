import { SubmitPersonType } from '@app/identification/models/api/post.identification.model';
import { CustomerTypeEnum } from '@app/proposal-workflow/models/step-register-put.enum';
import { ClientBiometryStatusEnum } from '../../../biometry-controller/models/biometry.model';
import { ChecklistStatusEnum } from '../checklist.model';

export enum ChecklistItemTypeEnum {
  CONTRACT_UPLOAD = '10',
  UPLOAD = '11',
  DOCUMENTARY = '12',
  FACIAL = '13',
  SIGNATURE = '16',
  ANTIFRAUD = '17',
  CONTRACT_DOWNLOAD = '18'
}

export enum ChecklistDocumentEnum {
  ID_DOC = '2',
  INCOME_DOC = '11',
  FACIAL_DOC = '9',
  SIGNATURE_DOC = '10',
  CONTRACT_DOC = '8',
  CONTRACT_STORE_DOC = '16',
  SEARCH_DEBT_DOC = '4',
  SEARCH_PROTEST_DOC = '6',
  SEARCH_STATUS_DOC = '7',
  INCOME_PROOF_DOC = '5',
  INVOICE = '20',
  FORM_REGISTER_DOC = '22', // 10-18,
  TRIAL_BALANCE = '12', // 10-18,
  GENERAL_DOCS_UPDATE_REGISTER_EXCEPTION = '29',
  PARTNER_INVOICE = '21'
}

export interface ChecklistItemTypeDTO {
  id: number;
  description: string;
  code: ChecklistItemTypeEnum;
}

export interface ChecklistItemGroupTypeDTO {
  id: number;
  description: string;
  code: string;
}
export interface ChecklistItemSizeDTO {
  size: string;
}

export interface ChecklistItemDocumentDTO {
  id: number;
  code: ChecklistDocumentEnum;
  docCode: ChecklistDocumentEnum;
  name: string;
  allowedTypes: string;
  description: string;
  maxImages: number;
  integrationTypes: {
    id: number;
    description: string;
    integrationCode: string;
  }[];
}

export interface ChecklistItemClientDTO {
  id: string;
  documentNumber: string;
  name: string;
  email: string;
  person: SubmitPersonType;
  type: CustomerTypeEnum;
  phoneDDD: string;
  phoneNumber: string;
  biometryStatus?: ClientBiometryStatusEnum;
}

export interface ChecklistItemDTO {
  checklistId: string;
  client: ChecklistItemClientDTO;
  type: ChecklistItemTypeDTO;
  artifact: ChecklistItemSizeDTO;
  document: ChecklistItemDocumentDTO;
  uploadGroup: ChecklistItemGroupTypeDTO;
  status: ChecklistStatusEnum;
  encrypted: string;
  reused?: string;
}

export interface ChecklistItemMobileDTO {
  checklistId: string;
  client?: any; //CORRIGIR A TIPAGEM
  type: ChecklistItemTypeDTO;
  artifact: ChecklistItemArtifactDTO;
  document: ChecklistItemDocumentMobileDTO;
  uploadGroup: ChecklistItemGroupTypeDTO;
  status: ChecklistStatusEnum;
  reused?: string;
  documentStatus: string;
  artifactId?: string;
  value: string;
}
export interface ChecklistItemArtifactDTO {
  id?: string;
  codeUuidEcm?: string;
  descriptionMimeType?;
  nameArtifact?: string;
  size: string;
}
export interface ChecklistItemDocumentMobileDTO {
  id: number;
  code: ChecklistDocumentEnum;
  docCode?: ChecklistDocumentEnum;
  name: string;
  allowedTypes: string;
  description?: string;
  maxImages?: number;
  integrationTypes: {
    id: number;
    description: string;
    integrationCode: string;
  }[];
}
