import { CommonModule, DecimalPipe, registerLocaleData } from '@angular/common';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';
import { DocumentScanModule } from '@app/document-scan/document-scan.module';
import { LocaleCurrencyPipe } from '@app/pipes/locale-currency.pipe';
import { LocalePercentagePipe } from '@app/pipes/locale-percentage.pipe';
import { BankDataComponent } from '@app/proposal-workflow/containers/step-register/components/bank-data/bank-data.component';
import { CustomInputComponent } from '@app/proposal-workflow/containers/step-register/components/custom-input/custom-input.component';
import { HeaderSectionComponent } from '@app/proposal-workflow/containers/step-register/components/header-section/header-section.component';
import { SearchInputComponent } from '@app/proposal-workflow/containers/step-register/components/search-input/search-input.component';
import { TaggerValidatorDirective } from '@app/tagging/tagger-validator.directive';
import { TaggerDirective } from '@app/tagging/tagger.directive';
import {
  NgbDateParserFormatter,
  NgbDatepickerI18n,
  NgbDatepickerModule,
  NgbDropdownModule,
  NgbModule,
  NgbPaginationModule
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HeaderShopkeeperComponent } from '@shared/components/ui/header/components/header-shopkeeper/header-shopkeeper.component';
import { NewHeaderComponent } from '@shared/components/ui/header/components/new-header/new-header.component';
import { HeaderComponent } from '@shared/components/ui/header/header.component';
import { PionAutocompleteComponent } from '@shared/components/ui/pion-autocomplete/pion-autocomplete.component';
import { NgbdCarouselBasic } from '@shared/components/widgets/carousel/carousel.component';
import { AddressService } from '@shared/services/address/address.service';
import { DomainsService } from '@shared/services/domains/domains.service';
import { SimulationService } from '@shared/services/simulation/simulation.service';
import { NgxCurrencyModule } from 'ngx-currency';
import { LottieModule } from 'ngx-lottie';
import { NgxMaskModule } from 'ngx-mask';
import { FilledButtonComponent } from './components/filled-button/filled-button.component';
import { InsuranceModalComponent } from './components/insurance-modal/insurance-modal.component';
import { LoanFeesModalComponent } from './components/loan-fees-modal/loan-fees-modal.component';
import { MonthlyIncomeModalComponent } from './components/monthlyIncome-modal/monthlyIncome-modal.component';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { PionModalComponent } from './components/pion-modal/pion-modal.component';
import { PionSnackbarComponent } from './components/pion-snackbar/pion-snackbar.component';
import { RegistrationFeeModalComponent } from './components/registration-fee-modal/registration-fee-modal.component';
import { PaymentExemptionModalComponent } from './components/registration-fee-payment-exemption-modal/payment-exemption-modal.component';
import { RegistrationUnavailableModalComponent } from './components/registration-unavailable-modal/registration-unavailable-modal.component';
import { SummaryModalComponent } from './components/summary-modal/summary-modal.component';
import { TermsModalComponent } from './components/terms-modal/terms-modal.component';
import { CalendarDialogComponent } from './components/ui/calendar-dialog/calendar-dialog.component';
import { CalendarComponent } from './components/ui/calendar/calendar.component';
import { CreatePasswordComponent } from './components/ui/create-password/create-password.component';
import { ExitPageComponent } from './components/ui/header/components/menu/exit-page/exit-page.component';
import { MenuHelpComponent } from './components/ui/header/components/menu/menu-help/menu-help.component';
import { MenuMobileComponent } from './components/ui/header/components/menu/menu-mobile/menu-mobile.component';
import { MenuProfileComponent } from './components/ui/header/components/menu/menu-profile/menu-profile.component';
import { ModalModule } from './components/ui/modal/modal.module';
import { SideContentComponent } from './components/ui/side-content/side-content.component';
import { TokenInputComponent } from './components/ui/token-input/token-input.component';
import { ArrowBackComponent } from './components/widgets/arrow-back/arrow-back.component';
import { DateParserFormatterI18n } from './components/widgets/datepicker/date-parser-formatter-i18n';
import { DatepickerI18nService } from './components/widgets/datepicker/datepicker-i18n.service';
import { DatepickerComponent } from './components/widgets/datepicker/datepicker/datepicker.component';
import { SidebarComponent } from './components/widgets/sidebar/sidebar.component';
import { GraphqlModule } from './graphql/graphql.module';
import { DateFormatPipe } from './pipes/dateFormat-pipe';
import { DocumentNumberPipe } from './pipes/document-number.pipe';
import { MaskPhone } from './pipes/mask-phone.pipe';
import { TruncateSize } from './pipes/truncateSize.pipe';
import { ChallengeService } from './services/challenge/challenge.service';
import { FormValidationService } from './services/form-validation/form-validation.service';
import { SimulationModalService } from './services/modal/simulation-modal.service';
import { SwalService } from './services/swal/swal.service';
import { TermsService } from './services/terms/terms.service';

import localeEsAr from '@angular/common/locales/es-AR';
import localeEsCo from '@angular/common/locales/es-CO';
import localeEsPe from '@angular/common/locales/es-PE';
import { ChallengeTermsComponent } from '@app/login/components/challenge-terms/challenge-terms.component';
import { PreTokenComponent } from '@app/login/components/pre-token/pre-token.component';
import { ProgressCardComponent } from '@app/profile/components/proposal-details/progress-card/progress-card.component';
import { ErrorLoadingContentModalComponent } from '@shared/components/error-loading-content-modal/error-loading-content-modal.component';
import { TutorialModalComponent } from '@shared/components/tutorial-modal/tutorial-modal.component';
import { ModalLeavingComponent } from '@shared/modal-leaving/modal-leaving.component';

import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CancelProposalComponent } from './components/cancel-proposal/cancel-proposal.component';
import { ContentModalComponent } from './components/content-modal/content-modal.component';
import { DateRangePickerDialogComponent } from './components/date-range-picker-dialog/date-range-picker-dialog.component';
import { ExitProposalModalComponent } from './components/exit-proposal-modal/exit-proposal-modal.component';
import { FilterOrdenationDialogComponent } from './components/filter-ordenation-dialog/filter-ordenation-dialog.component';
import { FilterPeriodDialogComponent } from './components/filter-period-dialog/filter-period-dialog.component';
import { LgpdFormComponent } from './components/lgpd-form/lgpd-form.component';
import { LgpdModalComponent } from './components/lgpd-modal/lgpd-modal.component';
import { OutlinedButtonComponent } from './components/outlined-button/outlined-button.component';
import { PionDocumentScanComponent } from './components/pion-document-scan/pion-document-scan.component';
import { PionPasswordComponent } from './components/pion-password/pion-password.component';
import { PionTooltipComponent } from './components/pion-tooltip/pion-tooltip.component';
import { SearchSellerDialogComponent } from './components/search-seller-dialog/search-seller-dialog.component';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { SignaturePadComponent } from './components/widgets/signature-pad/signature-pad.component';
import { LoadDirective } from './directives/load.directive';
import { RegexFilterDirective } from './directives/regex-filter.directive';
import { FirstLetterUppercasePipe } from './pipes/first-letter-uppercase.pipe';
import { OrderByPipe } from './pipes/orderBy.pipe';
import { RemoveDuplicatedPipe } from './pipes/removeDuplicated.pipe';
import { LgpdService } from './services/lgpd/lgpd.service';

// Note we need a separate function as it's required
// by the AOT compiler.
// Or if you load `lottie-web` on demand
export function playerFactory() {
  return import(/* webpackChunkName: 'lottie-web' */ 'lottie-web/build/player/lottie_svg');
}

registerLocaleData(localeEsAr, 'es-AR');
registerLocaleData(localeEsPe, 'es-PE');
registerLocaleData(localeEsCo, 'es-CO');

@NgModule({
  declarations: [
    HeaderComponent,
    HeaderShopkeeperComponent,
    NewHeaderComponent,
    TokenInputComponent,
    DatepickerComponent,
    SidebarComponent,
    NgbdCarouselBasic,
    ArrowBackComponent,
    CreatePasswordComponent,
    MaskPhone,
    TruncateSize,
    RegistrationFeeModalComponent,
    PaymentExemptionModalComponent,
    SummaryModalComponent,
    MonthlyIncomeModalComponent,
    PionAutocompleteComponent,
    ModalLeavingComponent,
    CalendarComponent,
    CalendarDialogComponent,
    LoanFeesModalComponent,
    InsuranceModalComponent,
    MenuMobileComponent,
    MenuProfileComponent,
    MenuHelpComponent,
    SideContentComponent,
    PionSnackbarComponent,
    ExitPageComponent,
    TermsModalComponent,
    LocaleCurrencyPipe,
    LocalePercentagePipe,
    PionModalComponent,
    RegistrationUnavailableModalComponent,
    PionDocumentScanComponent,
    PreTokenComponent,
    PionTooltipComponent,
    ChallengeTermsComponent,
    DocumentNumberPipe,
    DateFormatPipe,
    ContentModalComponent,
    PdfViewerComponent,
    TutorialModalComponent,
    LgpdFormComponent,
    LgpdModalComponent,
    FilledButtonComponent,
    OutlinedButtonComponent,
    OrderByPipe,
    RemoveDuplicatedPipe,
    FirstLetterUppercasePipe,
    BankDataComponent,
    CustomInputComponent,
    HeaderSectionComponent,
    SearchInputComponent,
    RegexFilterDirective,
    ExitProposalModalComponent,
    CancelProposalComponent,
    SignaturePadComponent,
    ErrorLoadingContentModalComponent,
    PionPasswordComponent,
    TermsAndConditionsComponent,
    ProgressCardComponent,
    SearchSellerDialogComponent,
    FilterPeriodDialogComponent,
    DateRangePickerDialogComponent,
    FilterOrdenationDialogComponent,
    TaggerDirective,
    TaggerValidatorDirective,
    LoadDirective
  ],
  imports: [
    // GuidedTourModule,
    CommonModule,
    MatToolbarModule,
    TranslateModule,
    GraphqlModule,
    FormsModule,
    ReactiveFormsModule,
    NgbDatepickerModule,
    NgbPaginationModule,
    NgbDropdownModule,
    ModalModule,
    NgxMaskModule.forRoot(),
    NgbModule,
    RouterModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    NgxCurrencyModule,
    MatCardModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatDividerModule,
    MatSidenavModule,
    MatListModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatDialogModule,
    MatSelectModule,
    LottieModule.forRoot({ player: playerFactory }),
    MatRadioModule,
    MatProgressBarModule,
    DocumentScanModule,
    MatRippleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMenuModule
  ],
  exports: [
    // HeaderComponent,
    MaskPhone,
    TruncateSize,
    NewHeaderComponent,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    PionDocumentScanComponent,
    TokenInputComponent,
    NgbPaginationModule,
    NgbDropdownModule,
    ModalModule,
    NgxMaskModule,
    DatepickerComponent,
    HeaderComponent,
    ArrowBackComponent,
    CreatePasswordComponent,
    SidebarComponent,
    RegistrationFeeModalComponent,
    PaymentExemptionModalComponent,
    SummaryModalComponent,
    InsuranceModalComponent,
    RegistrationFeeModalComponent,
    MonthlyIncomeModalComponent,
    RegistrationFeeModalComponent,
    PionAutocompleteComponent,
    CalendarComponent,
    CalendarDialogComponent,
    LoanFeesModalComponent,
    SideContentComponent,
    PionSnackbarComponent,
    TermsModalComponent,
    LocaleCurrencyPipe,
    LocalePercentagePipe,
    PionModalComponent,
    RegistrationUnavailableModalComponent,
    PionDocumentScanComponent,
    PreTokenComponent,
    PionTooltipComponent,
    ChallengeTermsComponent,
    DocumentNumberPipe,
    DateFormatPipe,
    ContentModalComponent,
    PdfViewerComponent,
    LgpdFormComponent,
    FilledButtonComponent,
    OutlinedButtonComponent,
    OrderByPipe,
    RemoveDuplicatedPipe,
    FirstLetterUppercasePipe,
    BankDataComponent,
    CustomInputComponent,
    HeaderSectionComponent,
    SearchInputComponent,
    RegexFilterDirective,
    ExitProposalModalComponent,
    SignaturePadComponent,
    PionPasswordComponent,
    CancelProposalComponent,
    TermsAndConditionsComponent,
    ProgressCardComponent,
    SearchSellerDialogComponent,
    FilterOrdenationDialogComponent,
    TaggerDirective,
    TaggerValidatorDirective,
    LoadDirective
  ],
  providers: [
    // ParametrosService,
    FormValidationService,
    [{ provide: NgbDatepickerI18n, useClass: DatepickerI18nService }],
    [{ provide: NgbDateParserFormatter, useClass: DateParserFormatterI18n }],
    /*[
          {provide: MAT_DATE_FORMATS, useValue: MY_NATIVE_DATE_FORMATS},
        ],*/
    {
      provide: LOCALE_ID,
      useValue: 'es-AR'
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-PE'
    },
    {
      provide: LOCALE_ID,
      useValue: 'es-CO'
    },
    SimulationModalService,
    AddressService,
    DomainsService,
    SimulationService,
    TermsService,
    ChallengeService,
    LgpdService,
    SwalService,
    DecimalPipe
    // GuidedTourService
  ]
})
export class SharedModule {
  constructor(private translateService: TranslateService) {}
}
