import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private sessionStorageService: SessionStorageService) {}

  canActivate(): boolean {
    if (this.sessionStorageService.getUser()) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}

@Injectable()
export class AuthUserGuard implements CanActivate {
  constructor(private router: Router, private sessionStorageService: SessionStorageService) {}

  canActivate(): boolean {
    const user = this.sessionStorageService.getUser();

    // Accepts both password or client_credentials authentication
    if (user && (user.grantType === 'password' || user.grantType === 'client_credentials')) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}