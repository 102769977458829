import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';

@Component({
  selector: 'app-certified-agent',
  templateUrl: './certified-agent.component.html',
  styleUrls: ['./certified-agent.component.scss']
})
export class CertifiedAgentComponent implements OnInit {
  users = {};
  totalElements = 2;
  page = 1;
  perPage = 5;

  public userChoice: string;
  searchForm: FormGroup;
  isFiltred = false;

  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  closeResult: string;

  tela = 'listagem';
  nameUser = 'Gestão de Agentes Certificados';
  userId: string;
  count = 0;

  constructor(private internationalizationService: InternationalizationService) {}

  ngOnInit() {}

  changeScreen(e) {
    this.count += 1;
    this.tela = e.type;
    this.userId = e.userId;
    if (e.type === 'new-user') {
      this.nameUser = 'Novo Agente';
    } else if (e.type === 'update-user') {
      this.nameUser = 'Editar Agente';
    } else if (e.type === 'listagem') {
      this.nameUser = 'Agente Certificado';
    }
  }
}
