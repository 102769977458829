import { Action } from '@ngrx/store';

export enum ContractsActionTypes {
  GET_ACTIVE_CONTRACTS = '[Showcase] [App] Get Active Contracts',
  GET_ACTIVE_CONTRACTS_SUCCESS = '[Showcase] [App] Get Active Contracts Success',
  GET_ACTIVE_CONTRACTS_ERROR = '[Showcase] [App] Get Active Contracts Error'
}

export class GetActiveContractsAction implements Action {
  readonly type = ContractsActionTypes.GET_ACTIVE_CONTRACTS;
  constructor() {}
}
export class GetActiveContractsSuccessAction implements Action {
  readonly type = ContractsActionTypes.GET_ACTIVE_CONTRACTS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetActiveContractsErrorAction implements Action {
  readonly type = ContractsActionTypes.GET_ACTIVE_CONTRACTS_ERROR;
  constructor(public payload: any) {}
}

export type ContractsActions =
  | GetActiveContractsAction
  | GetActiveContractsSuccessAction
  | GetActiveContractsErrorAction;
