<app-installment-info-flt *ngIf="code === 'FLT'"
  [selectedPaymentMethod]="selectedPaymentMethod[0]"></app-installment-info-flt>
<app-installment-info-asc *ngIf="code === 'ASC'"
  [selectedPaymentMethod]="selectedPaymentMethod[0]"></app-installment-info-asc>
<app-installment-info-dec *ngIf="code === 'DEC'"
  [selectedPaymentMethod]="selectedPaymentMethod[0]"></app-installment-info-dec>
<app-installment-info-lack #infoLack *ngIf="code === 'LACK'" [months]="months"
  (installmentInfoEmitterLack)="installmentInfoeEmit($event)" [installmentPMethodsDate]="installmentPMethodsDate"
  [selectedPaymentMethod]="selectedPaymentMethod[0]" [codeMonthsRange]="codeMonthsRange"
  (selectedValidatorsEmitter)="validatorForm($event)" [firstInstallmentPMethodsDate]="firstInstallmentPMethodsDate"
  (installmentInfoEmitter)="installmentInfoHandler($event)" [category]="category"></app-installment-info-lack>
<app-installment-info-bln #infoBln *ngIf="code === 'BLN'" [months]="months"
  [installmentPMethodsDate]="installmentPMethodsDate" [selectedPaymentMethod]="selectedPaymentMethod[0]"
  [codeMonthsRange]="codeMonthsRange" [firstInstallmentPMethodsDate]="firstInstallmentPMethodsDate"
  (selectedValidatorsEmitter)="validatorForm($event)" (installmentInfoEmitterBLN)="installmentInfoeEmit($event)"
  (installmentInfoEmitter)="installmentInfoHandler($event)" [category]="category"></app-installment-info-bln>
<app-installment-info-fixed *ngIf="code === 'FIXED'" [offerId]="bestOffer?.bestOffer?.offerId"
  [customInstallmentValue]="bestOffer?.bestOffer?.customInstallmentValue"
  [installmentDefaultAmount]="bestOffer?.bestOffer?.paymentMethods[0]?.installmentDefaultAmount"
  [lastInstallmentValue]="bestOffer?.bestOffer?.paymentMethods[0].lastInstallmentValue "
  [installmentPaymentMethodsAmount]="bestOffer?.bestOffer?.paymentMethods[0]?.installmentPaymentMethodsAmount"
  [months]="bestOffer?.bestOffer?.fixedInstallments" [codeMonthsRange]="codeMonthsRange"
  [maxCustomInstallmentValue]="bestOffer?.bestOffer?.maxCustomInstallmentValue"
  [minCustomInstallmentValue]="bestOffer?.bestOffer?.minCustomInstallmentValue"
  [customInstallmentValue]="bestOffer?.bestOffer?.customInstallmentValue"
  (selectedValidatorsEmitter)="validatorForm($event)"
  [defaultInstallmentValue]="bestOffer?.bestOffer?.defaultInstallmentValue"
  [fixedInstallmentQuantity]="bestOffer?.bestOffer?.fixedInstallmentQuantity" [bestOffer]="bestOffer"
  (installmentInfoEmitter)="installmentInfoHandler($event)" (installmentInfoEmitter)="installmentInfoeEmit($event)"
  [selectedPaymentMethod]="selectedPaymentMethod[0]"
  [installmentPMethodsDate]="installmentPMethodsDate"></app-installment-info-fixed>
<app-installment-info-seasonal *ngIf="code === 'SEASONAL'" [months]="months"
  [firstInstallmentPMethodsDate]="bestOffer?.bestOffer?.paymentMethods[0]?.firstInstallmentPMethodsDate"
  [instalmentDefaultValue]="bestOffer?.bestOffer?.instalmentDefaultValue"
  [installmentPMethodsDate]="installmentPMethodsDate" [firstInstallmentPMethodsDate]="firstInstallmentPMethodsDate"
  (selectedValidatorsEmitter)="validatorForm($event)"
  [installmentDefaultAmount]="bestOffer?.bestOffer?.paymentMethods[0]?.installmentDefaultAmount"
  [installmentDefaultValue]="bestOffer?.bestOffer?.paymentMethods[0]?.installmentDefaultValue"
  [installmentPaymentMethodsValue]="bestOffer?.bestOffer?.paymentMethods[0]?.installmentPaymentMethodsValue"
  [monthsRange]="bestOffer?.bestOffer?.paymentMethods[0]?.monthsRange[0]?.code"
  [installmentPaymentMethodsAmount]="bestOffer?.bestOffer?.paymentMethods[0]?.installmentPaymentMethodsAmount"
  (installmentInfoEmitter)="installmentInfoHandler($event)"
  (installmentInfoEmitterSeasonal)="installmentInfoeEmit($event)"></app-installment-info-seasonal>
