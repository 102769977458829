import gql from 'graphql-tag';

export const GET_PROPOSAL = gql`
  query getProposalQuery($proposalId: String) {
    listProposals(
      proposalCode: $proposalId
      page: 1
      per_page: 30
      sort: { field: "status", priority: 1, order: "ASC" }
      nosalesman: false
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalElements
        totalPages
        endCursor
        startCursor
      }
      edges {
        cursor
        node {
          id ##OK
          createdDate ##creationDate OK
          expireDate ##expirationDate OK
          statusCode ##statusCode OK
          product {
            ##product
            code
            description
          }
          channelSupplier {
            id
            code
            name
          }
          financedValue ##financedValue OK
          entryValue ##entryValue OK
          installmentValue ##installmentValue OK
          installmentAmount ##installmentAmount OK
          salesmen {
            ##salesmen OK
            id ##code OK
            name ##name OK
          }
          lastPaymentStatus {
            code
          }
          payments {
            id
          }
          paymentType {
            id
            description
          }
          customerType
          customer {
            ##OK
            id ##OK
            name ##OK
            documentNumber ##OK
            documentType ##OK
            email
          }
          createdByCustomer ##createdByCustomer OK
          loanShares {
            ##loadShares OK
            id ##id OK
            value ##value OK
            documentNumber ##documentNumber OK
            documentType ##OK
            percent ##OK
          }
          fees {
            ##OK
            id ##OK
            feeType ##type OK
            feeValue ##value OK
          }
          certifiedAgent {
            ##certifiedAgent OK
            name ##name OK
            documentNumber ##documentNumber OK
            documentType ##documentType OK
          }
          orderNumber ##orderNumber OK
          comments ##comments OK
          proposalMessage {
            ##proposalMessage OK
            status ##status OK
            message ##message OK
          }
          additionalInformation {
            ##additionalInformation OK
            label ##label OK
            value ##value OK
          }
        }
      }
    }
  }
`;

export const GET_PROPOSAL_BY_ID = gql`
  query getProposalById($proposalId: String) {
    getProposalById(proposalCode: $proposalId) {
      id
      createdDate
      statusCode
      expireDate
      financedValue
      totalFinancedValue
      entryValue
      installmentAmount
      installmentValue
      product {
        code
      }
      conditionFinanced {
        id
        tableCode
        maximumAprovedValue
        totalFinancedValue
        taxTypeId
        monthOccurrency
        frequenceId
        deadLineQuantity
        returnTaxPercentage
        contractTaxPercentage
        fabricationSubsidiaryTaxPercentage
        lodgeSubsidiaryTaxPercentage
        passiveTaxPercentage
        userUpdateCode
        dobleInstallmentAmount
        simpleInstallmentAmount
        maxInstallmentValue
        minInstallmentValue
      }
      paymentPlan {
        id
        installmentValue
        amortizationQuotaValue
        interestRateValue
        debitBalanceAmountValue
        amortizationDate
        installmentNumber
        installmentBalanceDueAfter
      }
      customer {
        id
        documentNumber
        email
        name
      }
      conditionPlanFlex {
        id
        paymentDate
        paymentValue
        sequenceNumber
        simulationId
        updatedUserCode
      }
      paymentType {
        id
        code
        description
      }
      channelSupplier {
        id
        code
        name
      }
      integrationGenericFields {
        id
        hubIntegrationCode
        genericFieldCode
        fieldTypeCode
        fieldValueCode
      }
    }
  }
`;

export const PROPOSAL_CHECKLISTS = gql`
  query proposalsCheckQuery($proposalId: String, $reused: Boolean, $isPendency: Boolean) {
    listCheckValidate(
      proposalId: $proposalId
      reused: $reused
      isPendency: $isPendency
      status: ["PENDING", "ANALYSIS", "REMAKE", "INDEXED", "DENIED", "RELEASE_INTERNAL"]
    ) {
      checklistId
      client {
        id
        name
        documentNumber
        email
        person
        type
      }
      type {
        id
        description
        code
      }
      artifact {
        id
        codeUuidEcm
        descriptionMimeType
        nameArtifact
        updateUserCode
        updatedAt
        size
      }
      document {
        id
        code
        name
        description
        maxImages
        allowedTypes
        integrationTypes {
          id
          description
          integrationCode
        }
      }
      uploadGroup {
        id
        code
        description
      }
      status
      reused
    }
  }
`;

export const CROSSED_FLUX_POLLING = gql`
  query crossedFluxPolling($proposalId: String, $reused: Boolean) {
    listCheckValidate(
      proposalId: $proposalId
      reused: $reused
      status: ["PENDING", "ANALYSIS", "REMAKE", "INDEXED", "DENIED", "RELEASE_INTERNAL"]
    ) {
      status
      client {
        id
        name
      }
      type {
        code
      }
    }
  }
`;

export const PROPOSALS_NEW_QUERY = gql`
  query proposalsListQuery($companyCode: String, $documentType: Int, $document: String) {
    proposalsList(
      companyCode: $companyCode
      documentCode: $document
      documentId: $documentType
      main: "S"
      page: 0
      size: 500
      statusProposal: [
        "PRCAP"
        "PRCDP"
        "PRCFG"
        "PRCRS"
        "PRDTC"
        "PRFAP"
        "PRFOR"
        "ADAHS"
        "ADAPR"
        "ADEAN"
        "ADHSE"
        "EDESB"
        "EPGPC"
        "MSAPR"
        "PGTLB"
        "PRPAG"
        "ADHSP"
        "CDPND"
        "DCPND"
        "EPGTO"
        "PEDBP"
        "PEDCD"
        "PEDHS"
        "PEDTR"
      ]
    ) {
      edges {
        node {
          id
          product
          accessChannelId
          operator
          saleOffTypeId
          receiptDeliveryType
          supplierChannel
          storeCode
          subSegmentId
          financedObjectCode
          companyCode
          certifiedAgent
          legacyStatusId
          statusProposal
          buyerType
          cityId
          mailingAddress
          contractShippingForm
          lastRiskRateCode
          couponCode
          expirationDayInstallment
          installmentAmount
          firstParcelValue
          deadlinePaymentFirstInstallment
          exemptMonthCode
          creationDate
          proposalDate
          expirationFirstInstallmentDate
          proposalValidityDate
          entryValue
          valueFinancing
          principalValue
          retentionValue
          subsidyAmount
          totalFareAmount
          financedFareAmount
          unfundedTariffAmount
          taxAmountFinanced
          taxAmountNotFinanced
          totalTaxAmount
          contractCode
          termUse
          termUseActDate
          userCodeLastUpdate
          dateLastUpdate
          emailCode
          countAll
          statusDescription
          legacyProposalCode
          retailOperatorId
          productCode
          stage {
            portalStage
            groupName
          }
          proposalGroups {
            groupName
            color
          }
          stProposals {
            identificadorStProposta
            codigoStProposta
            descricaoStProposta
            grupoStProposta
            descricaoStVitrine
            codigoIconeVitrine
            codigoBotaoVitrine
          }
        }
      }
      pageInfo {
        totalPages
        hasNextPage
        hasPreviousPage
        totalPages
        endCursor
        startCursor
      }
    }
  }
`;

export const PROPOSALS_QUERY = gql`
  query proposalsRenderQuery($customerDocument: String!, $nosalesman: Boolean) {
    listProposals(
      nosalesman: $nosalesman
      customerDocument: $customerDocument
      statusCodes: ["D", "G", "R", "I", "L", "B", "S", "A", "F", "M", "P", "T", "V"]
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        totalElements
        totalPages
        endCursor
        startCursor
      }
      edges {
        cursor
        node {
          id ##OK
          createdDate ##creationDate OK
          expireDate ##expirationDate OK
          statusCode ##statusCode OK
          product {
            ##product
            code
            description
          }
          channelSupplier {
            id
            code
            name
          }
          financedValue ##financedValue OK
          entryValue ##entryValue OK
          installmentValue ##installmentValue OK
          installmentAmount ##installmentAmount OK
          salesmen {
            ##salesmen OK
            id ##code OK
            name ##name OK
          }
          lastPaymentStatus {
            code
          }
          payments {
            id
          }
          customer {
            ##OK
            id ##OK
            name ##OK
            documentNumber ##OK
            documentType ##OK
          }
          createdByCustomer ##createdByCustomer OK
          loanShares {
            ##loadShares OK
            id ##id OK
            value ##value OK
            documentNumber ##documentNumber OK
            documentType ##OK
            percent ##OK
          }
          fees {
            ##OK
            id ##OK
            feeType ##type OK
            feeValue ##value OK
          }
          certifiedAgent {
            ##certifiedAgent OK
            name ##name OK
            documentNumber ##documentNumber OK
            documentType ##documentType OK
          }
          orderNumber ##orderNumber OK
          comments ##comments OK
          proposalMessage {
            ##proposalMessage OK
            status ##status OK
            message ##message OK
          }
          additionalInformation {
            ##additionalInformation OK
            label ##label OK
            value ##value OK
          }
        }
      }
    }
  }
`;
