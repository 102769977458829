<div class="notification mt-2">
  <div class="row mb-1">
    <div class="col">
      <mat-label class="form-label">{{ 'Name' | translate }}</mat-label>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-sm-5">
      <strong>
        <h2>{{ data.agentName }}</h2>
      </strong>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-label class="form-label">{{ 'CPF' | translate }}</mat-label>
      <p>{{ data.documentNumber | mask: '000.000.000-00' }}</p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-label class="form-label">{{ 'DUE-DATE' | translate }}</mat-label>
      <p>{{ data.expirationDate | date: 'dd/MM/yyyy' }}</p>
    </div>
  </div>
</div>
