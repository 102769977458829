export enum NegotiationDetailEnum {
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  REPROVED = 'REPROVED'
}

export enum negotiationStatusEnum {
  APPROVED = 'APPROVED',
  PARTIAL = 'PARTIAL',
  DENIED = 'DENIED'
}

export interface Negotiation {
  installmentValue?: number;
  interestRate?: number;
  retentionPercentage?: number;
  negotiationStatus?: string;
  status?: string;
}
