import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { SubmitIdentificationIncomeAction } from '@app/core/state/pre-analysis/pre-analysis.actions';
import { selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import { UpdateNppOffer } from '@app/core/state/simulation/simulation.actions';
import { InputChange } from '@app/proposal-workflow/models/input-change.model';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { PartnerBanksModel } from '@app/proposal-workflow/models/partner-banks.model';
import { PaymentWayModel } from '@app/proposal-workflow/models/payment-way.model';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Payment, PaymentConfirmationData } from '@shared/constants/payment-type.enum';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { CashbackBenefitsComponent } from '../cashback-benefits/cashback-benefits.component';

@Component({
  selector: 'payment-step',
  templateUrl: './payment-step.component.html',
  styleUrls: ['./payment-step.component.scss']
})
export class PaymentStepComponent implements OnInit, OnChanges, OnDestroy {
  public isNPP = JSON.parse(environment.useNPP);

  @Input() paymentData: any;
  @Input() public data = new PaymentWayModel();
  @Input() public confirmationData: ConfirmationData;
  @Input() category: string;
  @Input() othersBanks: PartnerBanksModel[];

  @Output() public paymentChangeEmitter = new EventEmitter();
  @Output() public accountChanged = new EventEmitter();
  @Output() public addForm = new EventEmitter<FormGroup>();

  isSantanderClient: boolean;
  isEligible: boolean;
  inValidBankAccount = true;
  bankAccountServiceVallidated = true;
  inValidBankAccount$: Observable<boolean>;
  selectedPaymentMethod: string;
  accountWasValid = false;
  isCashbackCardVisible = true;
  docNum: string;
  public PaymentType = Payment;

  accountFields: FormGroup;

  readonly Insert: Tag = Tag.Insert;
  readonly Selecionar: Tag = Tag.Selecionar;

  private destroy$ = new Subject();

  public get inValid(): boolean {
    if (this.data.paymentType === 1) {
      if (
        this.accountFields.controls.accountAgency.value === '' ||
        this.accountFields.controls.accountAndDigit.value === ''
      ) {
        return true;
      } else {
        // we already know the above fields are filled
        return this.inValidBankAccount;
      }
    } else {
      return false;
    }
  }

  bankAccountType = [
    {
      id: 1,
      type: this.translate.instant('Savings Account'),
      desc: 'CP'
    },
    {
      id: 2,
      type: this.translate.instant('Current Account'),
      desc: 'CC'
    }
  ];

  public selectedIndex: number;

  public sendBill: boolean;
  public digitalBill: boolean;
  public proposalId;
  protected ngUnsubscribe: Subject<any> = new Subject();

  public isShowOthersBanks: boolean;
  public isLoadOthersBanks: boolean;
  public isShowErrorValidationAccount: boolean;
  public paymentMethodSelected;
  public agency: string;

  constructor(
    private translate: TranslateService,
    public dialog: MatDialog,
    private simulationService: SimulationService,
    private store$: Store<AppState>,
    private genTagger: GenTagger
  ) {
    this.accountFields = new FormGroup({
      accountType: new FormControl('', [Validators.required]),
      accountAgency: new FormControl('', [Validators.required]),
      accountNumberAndDigit: new FormControl('', [Validators.required]),
      santanderBank: new FormControl({ value: '033', disabled: true }),
      otherBank: new FormControl({ disabled: false })
    });

    this.store$
      .select(selectProposalId)
      .pipe(
        filter(data => !!data),
        take(1)
      )
      .subscribe(proposalId => {
        this.proposalId = proposalId;
      });
  }

  ngOnInit() {
    this.isLoadOthersBanks = false;
    this.data.paymentType = this.getTypePaymentByLiquidationTypeCode(this.paymentData?.liquidationTypeCode);
    this.isShowErrorValidationAccount = false;

    this.accountFields
      .get('accountType')
      .valueChanges.pipe(
        filter(value => !!value),
        takeUntil(this.destroy$))
      .subscribe((value: string) => {
        let label = null;

        if (value.toLowerCase() === 'cc') {
          label = this.translate.instant('Current Account');
        }

        if (value.toLowerCase() === 'cp') {
          label = this.translate.instant('Savings Account');
        }

        this.genTagger.setTag({
          event_category: this.category,
          event_label: label,
          event_action: 'selecionou – tipo de conta'
        });
      });

    this.accountFields.valueChanges
      .pipe(
        debounceTime(1000),
        distinctUntilChanged(),
        filter(res => Object.values(res).every(item => item != null && item !== '')),
        map(param => {
          let documentType = 'CPF';
          if (this.confirmationData.personalData.documentNumber.length > 11) {
            documentType = 'CNPJ';
          }

          const accountAgency = param.accountAgency;
          const accountAndDigit = param.accountNumberAndDigit.replace('-', '');

          const bankNumber = !!this.isLoadOthersBanks ? param.otherBank : this.accountFields.get('santanderBank').value;
          const accountDigitOtherBank = accountAndDigit.slice(-1);
          return {
            accountNumber: accountAndDigit.slice(0, -1),
            branchCode: accountAgency,
            documentType: documentType,
            userDocument: this.confirmationData.personalData.documentNumber,
            bankNumber: bankNumber,
            accountDigit: accountDigitOtherBank
          };
        }),
        switchMap(payload => this.simulationService.proposalAccountValidator(payload)),
        map(res => res.status === 'OK')
      )
      .subscribe(valid => {
        this.isShowErrorValidationAccount = true;
        this.bankAccountServiceVallidated = valid;

        const setErrors = !valid ? { invalidAccount: true } : null;

        this.accountFields.controls.accountAgency.setErrors(setErrors);
        this.accountFields.controls.accountNumberAndDigit.setErrors(setErrors);

        this.inValidBankAccount = !valid;

        const accountPayload = {
          value: {
            branchCode: this.accountFields.valid ? this.accountFields.controls.accountAgency.value : '',
            accountNumber: this.accountFields.valid ? this.accountFields.controls.accountNumberAndDigit.value : '',
            digit: this.accountFields.valid ? this.accountFields.controls.accountNumberAndDigit.value.slice(-1) : '',
            accountType: this.accountFields.valid ? this.accountFields.controls.accountType.value : '',
            bankAccountServiceVallidated: this.bankAccountServiceVallidated
          },
          input: InputTypes.ACCOUNT_DATA
        };

        this.accountChanged.emit(accountPayload);
      });

    // setting the two flags that holds blue card (cashback) logic
    if (this.paymentData) {
      this.isSantanderClient = this.paymentData.accountantIndicator;
      this.isEligible = this.paymentData.cashbackEligible;
    }

    if (this.paymentData.elegibleType === 'CE') {
      this.genTagger.setTag({ event_category: this.category, event_label: 'abriu modal', event_action: Tag.pgView });
    }

    this.store$
      .select('profile')
      .pipe(take(1))
      .subscribe(prf => {
        this.docNum = prf.userProfile.documentNumber;
      });

    if (!!this.othersBanks) {
      this.isShowOthersBanks = this.othersBanks.length > 0 ? true : false;
    } else {
      this.isShowOthersBanks = false;
    }
    this.checkIfAccountHasBeenCompleted();
    this.addForm.emit(this.accountFields);
  }

  checkIfAccountHasBeenCompleted() {

    if (!!this.confirmationData) {
      if (!!this.confirmationData.accountData) {
        this.accountFields.get('accountType').patchValue(this.confirmationData.accountData.accountType);
        this.setDigitAgency();
        this.accountFields.get('accountAgency').patchValue(this.agency);
        this.accountFields.get('accountNumberAndDigit').patchValue(this.confirmationData.accountData.accountNumber);
      }
    }
  }

  private setDigitAgency() {
    if (!!this.confirmationData.accountData.branchCode) {
      this.agency = this.confirmationData.accountData.branchCode.padStart(4, "0");
    }
  }

  openDialog() {
    this.dialog.open(CashbackBenefitsComponent, {
      disableClose: true,
      width: '32rem',
      autoFocus: false,
      data: {
        cashbackValue: this.paymentData.cashbackAmount,
        isSantanderClient: this.isSantanderClient
      }
    });
  }

  closeCard() {
    this.isCashbackCardVisible = false;

    this.genTagger.setTag({
      event_action: 'fechou modal - ' + this.translate.instant('PAYMENT-TYPE-TITLE'),
      event_label: 'x',
      event_category: this.category
    });
  }

  loadModal(modalPayment: HTMLElement): void {
    setTimeout(() => {
      this.genTagger.setTag({
        event_action: 'abriu modal – ' + this.translate.instant('PAYMENT-TYPE-TITLE'),
        event_label: modalPayment.innerText,
        event_category: this.category
      });

      this.genTagger.setTag({
        event_action: 'abriu modal – tarifa de cadastro',
        event_label: modalPayment.innerText,
        event_category: this.category
      });
    }, 300);
  }

  selectPayment(paymentType) {
    console.log(paymentType);
    if (paymentType === 'BILL') {
      this.accountFields.reset();
    }
    const paymentMethod = this.getSelectedTypePayment(paymentType);

    const payload: InputChange = {
      value: {
        pricing: {
          paymentMethod
        }
      },
      input: InputTypes.PAYMENT_METHOD
    };

    if (this.isNPP) {
      this.store$.dispatch(
        new UpdateNppOffer({
          proposalId: parseInt(this.proposalId),
          paymentFormType: paymentMethod
        })
      );
    } else {
      this.store$.dispatch(
        new SubmitIdentificationIncomeAction({
          inputChanged: payload,
          paymentMethod,
          redirectProposal: false
        })
      );
    }

    if (paymentType === Payment.ACCOUNT_DEBIT || paymentType === Payment.OTHER_BANKS) {
      if (this.accountFields && this.accountFields.controls && this.accountFields.controls.length) {
        this.accountFields.controls.bankAccountType.setValidators([Validators.required]);
        this.accountFields.controls.accountAgency.setValidators([Validators.required]);
        this.accountFields.controls.accountNumberAndDigit.setValidators([Validators.required]);
      }
    } else {
      if (this.accountFields && this.accountFields.controls && this.accountFields.controls.length) {
        this.accountFields.controls.bankAccountType.clearValidators();
        this.accountFields.controls.accountAgency.clearValidators();
        this.accountFields.controls.accountNumberAndDigit.clearValidators();
      }
    }

    this.setTaggerPayment(paymentType);

    this.accountFields.updateValueAndValidity();
  }

  private setTaggerPayment(paymentType): void {
    let label = null;

    if (paymentType === Payment.ACCOUNT_DEBIT) {
      label = this.translate.instant('ACCOUNT-DEBIT');
    }

    if (paymentType === Payment.BILL) {
      label = this.translate.instant('BANK BILL');
    }

    if (paymentType === Payment.OTHER_BANKS) {
      label = this.translate.instant('OTHERS-BANKS');
    }

    this.genTagger.setTag({
      event_action: 'selecionou – tipo de conta',
      event_category: this.category,
      event_label: label
    });
  }

  private getSelectedTypePayment(paymentType) {
    let paySel = '';
    switch (paymentType) {
      case Payment.ACCOUNT_DEBIT:
        paySel = 'D';
        break;
      case Payment.BILL:
        paySel = 'B';
        break;
      case Payment.OTHER_BANKS:
        paySel = 'O';
        break;
      default:
        paySel = 'D';
        break;
    }
    this.paymentMethodSelected = paySel;
    return paySel;
  }

  selectSend() {
    this.sendBill = true;
    this.digitalBill = false;
  }

  selectDigital() {
    this.digitalBill = true;
    this.sendBill = false;
  }

  setAgencyMask(num): string {
    if (num.length === 1) {
      return '000' + num;
    } else if (num.length === 2) {
      return '00' + num;
    } else if (num.length === 3) {
      return '0' + num;
    }

    return num;
  }

  accountChangedPrepare(valid: boolean) {
    this.bankAccountServiceVallidated = valid;
    const setErrors = !valid ? { invalidAccount: true } : null;

    this.accountFields.controls.accountAgency.setErrors(setErrors);
    this.accountFields.controls.accountNumberAndDigit.setErrors(setErrors);

    this.inValidBankAccount = !valid;

    const accountPayload = {
      value: {
        branchCode: this.accountFields.valid ? this.accountFields.controls.accountAgency.value : '',
        accountNumber: this.accountFields.valid ? this.accountFields.controls.accountNumberAndDigit.value : '',
        digit: this.accountFields.valid ? this.accountFields.controls.accountNumberAndDigit.value.slice(-1) : '',
        accountType: this.accountFields.valid ? this.accountFields.controls.accountType.value : '',
        bankAccountServiceVallidated: this.bankAccountServiceVallidated
      },
      input: InputTypes.ACCOUNT_DATA
    };

    this.accountChanged.emit(accountPayload);
  }

  ngOnChanges(change) {
    if (change.confirmationData) {
      if (change.confirmationData.currentValue) {
        // tslint:disable-next-line: no-shadowed-variable
        const { accountData } = this.confirmationData;

        const branch = this.setAgencyMask(accountData.branchCode);
        this.accountFields.patchValue({
          accountType: accountData.accountType,
          accountAgency: branch,
          accountNumberAndDigit: accountData.accountNumber
        });

        // marca a conta como válida se ela vier preenchida.
        if (accountData.accountType !== '' && accountData.branchCode !== '' && accountData.accountNumber !== '') {
          this.accountChangedPrepare(true);
        }
      }
    }
  }

  public loadOthersBanks(isLoad: boolean) {
    this.isLoadOthersBanks = isLoad;
    this.isShowErrorValidationAccount = false;
    if (!!isLoad || this.paymentMethodSelected === 'D' || this.paymentMethodSelected === 'B') {
      const accountPayload = {
        value: {
          branchCode: null,
          accountNumber: null,
          digit: null,
          accountType: null,
          bankAccountServiceVallidated: false,
          newPaymentMethodSelected: this.paymentMethodSelected
        },
        input: InputTypes.ACCOUNT_DATA
      };
      this.accountChanged.emit(accountPayload);
      this.accountFields.controls.accountType.setValue('');
      this.accountFields.controls.accountAgency.setValue('');
      this.accountFields.controls.accountNumberAndDigit.setValue('');
    }
  }

  private getTypePaymentByLiquidationTypeCode(paymentType) {
    let paySelTypeCode = 0;
    switch (paymentType) {
      case PaymentConfirmationData.ACCOUNT_DEBIT:
        paySelTypeCode = 1;
        this.paymentMethodSelected = 'D';
        this.loadOthersBanks(false);
        break;
      case PaymentConfirmationData.BILL:
        paySelTypeCode = 2;
        this.paymentMethodSelected = 'B';
        this.loadOthersBanks(false);
        break;
      case PaymentConfirmationData.OTHER_BANKS:
        paySelTypeCode = 4;
        this.paymentMethodSelected = 'O';
        this.loadOthersBanks(true);
        this.isShowOthersBanks = true;
        break;
      default:
        paySelTypeCode = 1;
        this.paymentMethodSelected = 'D';
        break;
    }
    return paySelTypeCode;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
