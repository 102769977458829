import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { PaymentRequestStatusEnum } from '../../models/payment.enum';
import { PaymentRequestResponseServiceModel, PaymentShowcaseModel } from '../../models/payment.model';
import { PaymentsService } from '../../services/payments.service';
import { PaymentRequestStatusDialogComponent } from '../payment-request-status-dialog/payment-request-status-dialog.component';

@Component({
  selector: 'app-payment-multiple-request',
  templateUrl: './payment-multiple-request.component.html',
  styleUrls: ['./payment-multiple-request.component.scss']
})
export class PaymentMultipleRequestComponent implements OnInit {
  @Input() data: PaymentShowcaseModel[];
  @Input() category: string;
  @Output() reloadListPayments = new EventEmitter<boolean>();

  public window = window;
  public sumRequestValues: number;
  public paymentSuccess = false;
  public countSuccessPayments: PaymentShowcaseModel[] = [];
  public sumSuccessPayments: number;
  public countUnsuccessfulPayments: PaymentShowcaseModel[] = [];
  public sumUnsuccessfulPayments: number;
  private messagePayment: string;
  private msgPaymentAgendado =
    'não foi possível realizar a solicitação de pagamento porque atingimos o horário limite para esta ação. ' +
    'você não precisa se preocupar, ' +
    'o seu pagamento foi agendado para o próximo dia útil.';
  private statusPayment: string;
  private isOpenDialogPaymentRequestStatus = false;
  readonly closeActionTagger = `${Tag.Fechou_Modal} - ${this.translateService.instant('PAYMENT-MULTIPLE-REQUEST')}`;
  constructor(
    public activeModal: NgbActiveModal,
    private paymentsService: PaymentsService,
    private genTagger: GenTagger,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<PaymentMultipleRequestComponent>,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.sumRequestValues = this.sumValues();
    this.genTagger.setTag({
      event_category: this.category,
      event_label: this.translateService.instant('PAYMENT-MULTIPLE-REQUEST'),
      event_action: `${Tag.AbriuModal} - ${this.translateService.instant('PAYMENT-MULTIPLE-REQUEST')}`
    });
  }

  private sumValues(status?: string): number {
    let sumValues = 0;
    this.data.forEach((item: PaymentShowcaseModel) => {
      sumValues = sumValues + item.value;
      // if (!status && !!item.value) {
      //   sumValues = sumValues + item.value;
      // } else if (status === 'successful' && !!item.successPayment && !!item.value) {
      //   sumValues = sumValues + item.value;
      // } else if (status === 'unsuccessful' && !item.successPayment && !!item.value) {
      //   sumValues = sumValues + item.value;
      // }
    });
    return sumValues;
  }

  public confirm() {
    const payload = [];
    this.isOpenDialogPaymentRequestStatus = false;
    this.data.forEach((item: PaymentShowcaseModel) => {
      payload.push(item.id);
    });

    this.paymentsService.paymentMultipleRequest(payload).subscribe((res: PaymentRequestResponseServiceModel[]) => {
      this.data.forEach((item: PaymentShowcaseModel) => {
        const sameResItem = res.find((i: PaymentRequestResponseServiceModel) => i.proposalId === item.id);
        if (!!sameResItem) {
          item.successPayment = sameResItem.isSuccess;
          item.statusDescription = sameResItem.status;
          item.statusTooltipMessage = sameResItem.message;
          item.statusIcon = 'ic_clock.svg';
          if (!item.successPayment) {
            item.statusIcon = 'ic_warning_red.svg';
          }

          this.openDialogPaymentRequestByMessage(sameResItem.message);
        }
      });
      this.paymentSuccess = true;
      this.successfulPayments();
      this.unsuccessfulPayments();

      this.reloadListPayments.emit(true);
    });
  }

  private successfulPayments(): void {
    this.countSuccessPayments = this.data.filter((item: PaymentShowcaseModel) => !!item.successPayment);
    this.sumSuccessPayments = this.sumValues('successful');
  }

  private unsuccessfulPayments(): void {
    this.countUnsuccessfulPayments = this.data.filter((item: PaymentShowcaseModel) => !item.successPayment);
    this.sumUnsuccessfulPayments = this.sumValues('unsuccessful');
  }

  private openDialogPaymentRequestByMessage(statusMsg: string) {
    if (
      !this.isOpenDialogPaymentRequestStatus &&
      statusMsg.toLowerCase().trim() === this.msgPaymentAgendado.toLowerCase().trim()
    ) {
      this.statusPayment = PaymentRequestStatusEnum.SCHEDULED_PAYMENT;
      this.messagePayment = statusMsg;
      this.isOpenDialogPaymentRequestStatus = true;
      this.openDialogPaymentRequestStatus();
    }
  }

  private openDialogPaymentRequestStatus() {
    this.dialog.open(PaymentRequestStatusDialogComponent, {
      width: '560px',
      autoFocus: false,
      data: {
        status: this.statusPayment,
        text: this.messagePayment
      }
    });
  }
}
