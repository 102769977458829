import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core/state';
import { ProfileData } from '@app/core/state/profile/profile.reducer';
import { userProfileSelector } from '@app/core/state/profile/profile.selectors';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { BrazilValidators } from '@shared/interfaces/validators/brazil-validators.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import * as moment from 'moment';
import { Observable, Subject, timer } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  switchMap,
  take,
  takeUntil,
  withLatestFrom
} from 'rxjs/operators';
import { ManagementService } from '../services/management.service';

export interface Options {
  value: boolean;
  viewValue: string;
}

export interface Profile {
  value: string;
  viewValue: string;
}

export interface Profiles {
  type: string;
  startDate: string;
  endDate: string;
  user: string;
}

export interface UserData {
  code: string;
  name: string;
  email: string;
  cellPhoneNumber: string;
  cellPhoneAreaCode: string;
  nrDddComercial: string;
  nrTelComercial: string;
  fgAtivo: boolean;
  shopId: number;
  includeProfile: [
    {
      cdProfile: string;
      dtValidadeIni: string;
      dtValidadeFim: string;
    }
  ];
  products: [];
}

@Component({
  selector: 'app-update-user',
  templateUrl: './update-user.component.html',
  styleUrls: ['./update-user.component.scss']
})
export class UpdateUserComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();

  public datePickerIni: any;
  page = 1;
  perPage = 20;
  status: any;
  tipo: any;
  perfis: any;
  search = new FormControl('');
  users = [];
  totalElements: any;
  ativo = true;
  inativo = true;
  vendedor = true;
  representante = true;
  financeiro = true;
  master = true;
  colaborador = true;

  public window = window;
  public newUserForm: FormGroup;
  public newAssociateForm: FormGroup;
  public includeProfile: any = [];
  public includeProducts: any = [];
  public actualDate: any;
  public documentExists: boolean;
  readonly Insert: Tag = Tag.Insert;
  readonly Selecionar: Tag = Tag.Selecionar;
  type: any;
  editUser = false;
  statusOptions: Options[] = [{ value: true, viewValue: 'Ativo' }, { value: false, viewValue: 'Inativo' }];

  insuranceSalesOptions: Options[] = [{ value: true, viewValue: 'Sim' }, { value: false, viewValue: 'Não' }];

  subsidyOptions: Options[] = [{ value: true, viewValue: 'Sim' }, { value: false, viewValue: 'Não' }];

  bipartidoOptions: Options[] = [{ value: true, viewValue: 'Sim' }, { value: false, viewValue: 'Não' }];

  @Input() screenType: string;
  @Input() userId: string;

  @Output() back = new EventEmitter<any>();

  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  public documentNumberMask: string;
  public profile$: Observable<ProfileData> = this.store$.select(userProfileSelector);
  public saleId$: Observable<number> = this.store$.select('tabs').pipe(map(tab => +tab.selectedTab.id));

  cdProfile: any;
  profileDescription: any;
  dtValidadeIni: any;
  dtValidadeFim: any;
  profileOptions: any;
  profilesHistory: [];
  profile: any;
  perfId: any;
  totalElementsHistory: any;
  hasPerfilValue: boolean;
  initialDocument: any = null;
  shopProducts: any;
  associateCategory: string;
  associateLabel: string;

  constructor(
    private fb: FormBuilder,
    private internationalizationService: InternationalizationService,
    private _managementService: ManagementService,
    private modalService: NgbModal,
    private genTagger: GenTagger,
    private store$: Store<AppState>,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.createForm();
    this.createAssociateForm();
    this.listenerDatePicker();
    this.listenerInputValidationDate();
    this.getPerfis();
    // Data atual
    this.actualDate = new Date();

    this.getProducts();
    if (this.screenType === 'update-user') {
      this.saleId$
        .pipe(
          first(),
          switchMap(id => this._managementService.getUserDetailsById(this.userId, id.toString()))
        )
        .subscribe((data: any) => {
          this.initialDocument = data.documentNumber;
          this.newUserForm.controls['documentNumber'].setValue(data.documentNumber);
          this.newUserForm.controls['fullName'].setValue(data.name);
          this.newUserForm.controls['cellPhone'].setValue(data.cellPhoneAreaCode + data.cellPhoneNumber);
          this.newUserForm.controls['businessPhone'].setValue(data.nrDddComercial + data.nrTelComercial);
          this.newUserForm.controls['email'].setValue(data.email);
          this.newUserForm.controls['status'].setValue(data.active);

          // lista perfis
          // tslint:disable-next-line: no-shadowed-variable
          this.saleId$
            .pipe(
              first(),
              switchMap(id => this._managementService.getUsuarioById(id, this.userId))
            )
            .subscribe((res: any) => {
              this.includeProfile = res.perfis.map(({ dtFim, dtInicio, ...otherItens }) => {
                return {
                  dtValidadeFim: dtFim,
                  dtValidadeIni: dtInicio,
                  ...otherItens
                };
              });
              this.includeProducts = res.products;

              this.newUserForm.controls['cdc'].setValue(this.includeProducts.cdc);
              this.newUserForm.controls['cessao'].setValue(this.includeProducts.csc);
              this.newUserForm.controls['allowSubsidiary'].setValue(Boolean(this.includeProducts.subsidio));
              this.newUserForm.controls['allowInsuranceSell'].setValue(Boolean(this.includeProducts.seguro));
              this.newUserForm.controls['allowBipartido'].setValue(Boolean(this.includeProducts.bipartido));
              this.verifyPerfis();
            });

          // lista historico perfis
          const params = {
            perPage: 5,
            page: 0
          };

          // tslint:disable-next-line: no-shadowed-variable
          this.saleId$
            .pipe(
              first(),
              switchMap(id => this._managementService.getHistoricoPerfisById(id.toString(), this.userId, params))
            )
            .subscribe((prof: any) => {
              this.profilesHistory = prof.content;
              this.totalElementsHistory = prof.totalElements;
            });
        });
    }

    this.newUserForm
      .get('documentNumber')
      .valueChanges.pipe(
        debounceTime(200),
        withLatestFrom(this.saleId$)
      )
      .subscribe(([newValue, saleId]) => {
        if (newValue.length === 11 && newValue !== this.initialDocument) {
          this._managementService.validateDocument(saleId, newValue).subscribe((valid: boolean) => {
            if (valid) {
              this.newUserForm.get('documentNumber').setErrors({ valid: false });
            }
            this.documentExists = valid;
          });
        }
      });
    this.genTagger.setTag({
      event_category: this.category(),
      event_label: this.screenType === 'new-user' ? 'Novo usuário' : 'Editar usuário',
      event_action: Tag.pgView
    });
    this.genTagger.setTag({
      event_category: this.category(),
      event_label: this.screenType === 'new-user' ? 'Novo usuário' : 'Editar usuário',
      event_action: `Abriu modal - ${this.screenType === 'new-user' ? 'Novo usuário' : 'Editar usuário'}`
    });

    this.newAssociateForm.controls['dtValidadeIni'].valueChanges.pipe(debounceTime(1000)).subscribe(data => {
      const data1a = this.newAssociateForm.controls['dtValidadeIni'].valid;
      const dataInvalid = this.newAssociateForm.controls['dtValidadeIni'].invalid;
      if (data1a) {
        this.genTagger.setTag({
          event_category: this.associateCategory,
          event_action: Tag.Insert,
          event_label: 'Data Início'
        });
      } else if (dataInvalid) {
        this.genTagger.setTag({
          event_category: this.associateCategory,
          event_action: Tag.FillingError,
          event_label: 'Data inválida'
        });
      }
    });

    // this.newAssociateForm.controls['dtValidadeFim'].valueChanges.pipe(debounceTime(1000)).subscribe(data => {
    //   const data2a = this.newAssociateForm.controls['dtValidadeFim'].valid;
    //   if (data2a) {
    //     this.genTagger.setTag({
    //       event_category: this.category(),
    //       event_action: Tag.Insert,
    //       event_label: 'data final de validade'
    //     });
    //   }
    // });
    window.scrollTo(0, 0);
  }

  getUsers() {
    if (this.ativo && this.inativo) {
      this.status = 'S,N';
    } else if (this.ativo) {
      this.status = 'S';
    } else if (this.inativo) {
      this.status = 'N';
    }

    if (this.master && this.colaborador) {
      this.tipo = 'M,U';
    } else if (this.master) {
      this.tipo = 'M';
    } else if (this.colaborador) {
      this.tipo = 'U';
    }

    if (this.vendedor && this.representante && this.financeiro) {
      this.perfis = 'V,R,F';
    } else if (!this.vendedor && !this.representante && !this.financeiro) {
      this.perfis = '';
    } else if (this.vendedor && this.representante) {
      this.perfis = 'V,R';
    } else if (this.representante && this.financeiro) {
      this.perfis = 'R,F';
    } else if (this.vendedor) {
      this.perfis = 'V';
    } else if (this.representante) {
      this.perfis = 'R';
    } else if (this.financeiro) {
      this.perfis = 'F';
    }

    let fornecedorCanalParams: any;
    fornecedorCanalParams = {
      perPage: this.perPage,
      page: this.page,
      status: this.status ? this.status : null,
      type: this.tipo ? this.tipo : null,
      profiles: this.perfis ? this.perfis : null,
      search: this.search.value ? this.search.value : ''
    };
    this.users = [];
    this.saleId$
      .pipe(
        first(),
        switchMap(id => this._managementService.getUsers(id, fornecedorCanalParams))
      )
      .subscribe((data: any) => {
        this.users = data.content;
        this.totalElements = data.totalElements;
      });
  }

  public category(): string {
    if (this.screenType === 'new-user') {
      return '/portallojista/gestaodeusuarios/novousuario';
    }
    if (this.screenType === 'update-user') {
      return '/portallojista/gestaodeusuarios/editarusuario';
    }
  }

  public label(): string {
    if (this.screenType === 'new-user') {
      return 'Novo usuário';
    }
    if (this.screenType === 'update-user') {
      return 'Editar usuário';
    }
  }

  backManagementList() {
    this.back.emit({
      type: 'listagem',
      userId: ''
    });
  }

  omitSpecialChar(event) {
    const keyPressed = String.fromCharCode(event.keyCode);
    const verifyKeyPressed = /^[a-zA-Z\' \u00C0-\u00FF]*$/.test(keyPressed);
    return verifyKeyPressed === true;
  }

  statusChangeHandler($event) {
    console.log($event);
  }

  openProfile($event) {
    $event.preventDefault();
  }

  public handleCdcCheckboxChange(checked: boolean): void {
    const cdc = this.newUserForm.get('cdc');
    const csc = this.newUserForm.get('cessao');
    checked ? cdc.patchValue(checked) : cdc.reset(null);
    checked ? csc.patchValue(checked) : csc.reset(null);
  }

  open(content, type, perfId?) {
    this.type = type;
    this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
      windowClass: 'modal-sidenav'
    });

    // Reset form before opening modal
    this.perfId = null;
    this.newAssociateForm.reset();
    this.newAssociateForm.updateValueAndValidity();

    if (type === 'edit') {
      this.perfId = perfId.perfId;
      let newArray;

      if (this.perfId) {
        newArray = this.includeProfile.filter(el => {
          return el.perfId === perfId.perfId;
        });
      } else {
        newArray = this.includeProfile.filter(el => {
          return el.cdProfile === perfId.cdProfile;
        });
      }

      this.associateCategory = 'portallojista/gestaodeusuarios/editarusuario';
      this.associateLabel = 'Editar perfil';

      this.genTagger.setTag({
        event_action: Tag.pgView,
        event_category: this.associateCategory,
        event_label: 'Editar perfil'
      });

      this.genTagger.setTag({
        event_action: 'Abriu modal - Editar perfil',
        event_category: this.associateCategory,
        event_label: 'Editar perfil'
      });

      this.newAssociateForm.controls['cdProfile'].setValue(newArray[0].cdProfile);
      this.newAssociateForm.controls['dtValidadeIni'].setValue(this.MomentToString(newArray[0].dtValidadeIni));
      if (newArray[0].dtValidadeFim && moment(newArray[0].dtValidadeFim).format('DD/MM/YYYY') !== '31/12/9999') {
        this.newAssociateForm.controls['dtValidadeFim'].setValue(this.MomentToString(newArray[0].dtValidadeFim));
      }
    } else if (type === 'new') {
      this.associateCategory = 'portallojista/gestaodeusuarios/associarnovoperfil';
      this.associateLabel = 'Associar novo perfil';
      this.genTagger.setTag({
        event_action: Tag.pgView,
        event_category: this.associateCategory,
        event_label: 'Associar novo perfil'
      });
      this.genTagger.setTag({
        event_action: 'Abriu modal - Associar novo perfil',
        event_category: this.associateCategory,
        event_label: 'Associar novo perfil'
      });
    }

    // check if user is active
    if (!this.newUserForm.get('status').value) {
      this.newAssociateForm.controls['dtValidadeFim'].disable();
      this.newAssociateForm.controls['dtValidadeFimPicker'].disable();
    }
  }

  close(content) {
    this.modalService.dismissAll(content);
  }

  public subimitEditPerfil(): void {
    const initialDateSubmit = this.transformToMoment(this.newAssociateForm.get('dtValidadeIni').value);
    const finalDateSubmit = this.transformToMoment(this.newAssociateForm.get('dtValidadeFim').value);
    let dtValidadeFim;
    if (finalDateSubmit.toString() !== 'Invalid Date') {
      dtValidadeFim = moment(finalDateSubmit)
        .set({ hour: 23, minute: 59 })
        .format('YYYY-MM-DDTHH:mm:ss');
    }
    const newProfile = {
      perfId: this.perfId,
      cdProfile: this.newAssociateForm.get('cdProfile').value,
      dtValidadeIni: moment(initialDateSubmit)
        .set({ hour: 0, minute: 0 })
        .format('YYYY-MM-DDTHH:mm:ss'),
      dtValidadeFim
    };

    // Procura o id selecionado e remove do array
    this.includeProfile.splice(this.includeProfile.findIndex(v => v.perfId === this.perfId), 1);

    // Adiciona o novo elemento
    this.includeProfile.push(newProfile);

    // Reseta formulário
    this.newAssociateForm.reset();

    this.verifyPerfis();
  }

  public verifyPerfis() {
    const perfis = this.includeProfile.map(el => el.cdProfile);
    this.hasPerfilValue = perfis.includes('LOJISTA_REPRESENTANTE') || perfis.includes('LOJISTA_VENDEDOR');
  }

  public subimitNewPerfil(): void {
    const initialDateSubmit = this.transformToMoment(this.newAssociateForm.get('dtValidadeIni').value);
    const finalDateSubmit = this.transformToMoment(this.newAssociateForm.get('dtValidadeFim').value);
    let dtValidadeFim;
    if (finalDateSubmit.toString() !== 'Invalid Date') {
      dtValidadeFim = moment(finalDateSubmit)
        .set({ hour: 23, minute: 59 })
        .format('YYYY-MM-DDTHH:mm:ss');
    }
    const newProfile = {
      cdProfile: this.newAssociateForm.get('cdProfile').value,
      dtValidadeIni: moment(initialDateSubmit)
        .set({ hour: 0, minute: 0 })
        .format('YYYY-MM-DDTHH:mm:ss'),
      dtValidadeFim
    };

    this.includeProfile.push(newProfile);
    this.newAssociateForm.reset();
    this.verifyPerfis();
  }

  public submit(): void {
    if (this.newUserForm.invalid) return;
    this.includeProducts = [];
    let payload = <UserData>{
      code: this.newUserForm.get('documentNumber').value,
      name: this.newUserForm.get('fullName').value,
      email: this.newUserForm.get('email').value,
      cellPhoneNumber: this.newUserForm.get('cellPhone').value.slice(2, 12),
      cellPhoneAreaCode: this.newUserForm.get('cellPhone').value.slice(0, 2),
      fgAtivo: this.newUserForm.get('status').value,
      shopId: null,
      includeProfile: this.includeProfile,
      products: this.includeProducts
    };

    if (this.newUserForm.get('businessPhone').value) {
      payload = {
        ...payload,
        nrDddComercial: this.newUserForm.get('businessPhone').value.slice(0, 2),
        nrTelComercial: this.newUserForm.get('businessPhone').value.slice(2, 12)
      };
    }

    let productsList;
    if (this.newUserForm.get('cdc').value) {
      productsList = { cdProduct: 'CDC' };
      this.includeProducts.push(productsList);
    }

    if (this.newUserForm.get('cessao').value) {
      productsList = { cdProduct: 'CSC' };
      this.includeProducts.push(productsList);
    }

    if (this.newUserForm.get('allowInsuranceSell').value) {
      productsList = { cdProduct: 'SEGURO' };
      this.includeProducts.push(productsList);
    }

    if (this.newUserForm.get('allowSubsidiary').value) {
      productsList = { cdProduct: 'SUBSIDIO' };
      this.includeProducts.push(productsList);
    }

    if (this.newUserForm.get('allowBipartido').value) {
      productsList = { cdProduct: 'BIPARTIDO' };
      this.includeProducts.push(productsList);
    }

    if (this.screenType === 'new-user') {
      this._managementService.saveUser(payload).subscribe((data: any) => {
        this.includeProfile = [];
        this.newUserForm.reset();
      });
    } else {
      this.saleId$
        .pipe(
          first(),
          switchMap(shopId => this._managementService.updateUser({ shopId, ...payload }))
        )
        .subscribe((data: any) => {
          this.includeProfile = [];
          this.newUserForm.reset();
        });
    }
    this.backManagementList();
  }

  private createAssociateForm(): void {
    this.newAssociateForm = this.fb.group({
      cdProfile: new FormControl('', Validators.required),
      dtValidadeIni: new FormControl('', Validators.required),
      dtValidadeFim: new FormControl(''),
      dtValidadeIniPicker: new FormControl(''),
      dtValidadeFimPicker: new FormControl('')
    });
    this.newAssociateForm.get('dtValidadeFim').valueChanges.subscribe(response => {
      if (response && this.newAssociateForm.get('dtValidadeIni').valid) {
        if (response.length <= 10) {
          // tslint:disable-next-line: max-line-length
          if (
            moment(this.transformToMoment(this.newAssociateForm.get('dtValidadeIni').value)).isAfter(
              this.transformToMoment(response)
            )
          ) {
            this.newAssociateForm.controls['dtValidadeFim'].setErrors({ invalid: true });
          }
        }
      }
    });
    this.onNewAssociateFormChanges();
  }

  private onNewAssociateFormChanges(): void {
    this.newAssociateForm.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(() => this.includeProfile && this.includeProfile.length),
        distinctUntilChanged((prev, current) => JSON.stringify(prev) === JSON.stringify(current))
      )
      .subscribe(() => {
        const data = this.newAssociateForm.getRawValue();
        const profiles = this.includeProfile.filter(profile => profile.cdProfile === data.cdProfile);

        if (profiles && profiles.length) {
          this.includeProfile.forEach((profile: any) => {
            if (!data.dtValidadeFim && profile.dtValidadeFim === '9999-12-31T00:00:00') {
              profile.dtValidadeFim = undefined;
            }

            if (this.isDuplicatedProfile(profile, data.dtValidadeIni, data.dtValidadeFim)) {
              timer(0)
                .pipe(take(1))
                .subscribe(() => {
                  this.newAssociateForm.controls['dtValidadeIni'].markAsTouched();
                  this.newAssociateForm.controls['dtValidadeIni'].setErrors({ invalid: true });
                });
            }
          });
        }
      });
  }

  private isDuplicatedProfile(profile: any, dtValidadeIni: any, dtValidadeFim: any): boolean {
    const momDtValidateIni = moment(this.transformToMoment(dtValidadeIni))
      .set({ hour: 0, minute: 0 })
      .format('YYYY-MM-DDTHH:mm:ss');
    const momDtValidadeFim = dtValidadeFim
      ? moment(this.transformToMoment(this.newAssociateForm.get('dtValidadeFim').value))
          .set({ hour: 23, minute: 59 })
          .format('YYYY-MM-DDTHH:mm:ss')
      : undefined;

    return (
      (!this.perfId || this.perfId !== profile.perfId) &&
      momDtValidateIni === profile.dtValidadeIni &&
      momDtValidadeFim === profile.dtValidadeFim
    );
  }

  listenerDatePicker() {
    this.newAssociateForm.get('dtValidadeIniPicker').valueChanges.subscribe(() => {
      if (this.newAssociateForm.get('dtValidadeIniPicker').value) {
        // tslint:disable-next-line: max-line-length
        this.newAssociateForm
          .get('dtValidadeIni')
          .setValue(moment(this.newAssociateForm.get('dtValidadeIniPicker').value).format('DD/MM/YYYY'));
      }
    });
    this.newAssociateForm.get('dtValidadeFimPicker').valueChanges.subscribe(() => {
      if (this.newAssociateForm.get('dtValidadeFimPicker').value) {
        // tslint:disable-next-line: max-line-length
        this.newAssociateForm
          .get('dtValidadeFim')
          .setValue(moment(this.newAssociateForm.get('dtValidadeFimPicker').value).format('DD/MM/YYYY'));
      }
    });
  }

  listenerInputValidationDate() {
    const today = new Date().toLocaleDateString();
    this.newAssociateForm.get('dtValidadeIni').valueChanges.subscribe(response => {
      if (response) {
        if (response.length <= 10) {
          if (response < this.transformToMoment(today)) {
            this.newAssociateForm.controls['dtValidadeIni'].setErrors({ invalid: true });
          }
        }
      }
    });
    this.newAssociateForm.get('dtValidadeFim').valueChanges.subscribe(response => {
      if (response && this.newAssociateForm.get('dtValidadeIni').valid) {
        if (response.length <= 10) {
          // tslint:disable-next-line: max-line-length
          if (
            moment(this.transformToMoment(this.newAssociateForm.get('dtValidadeIni').value)).isAfter(
              this.transformToMoment(response)
            )
          ) {
            this.newAssociateForm.controls['dtValidadeFim'].setErrors({ invalid: true });
          }
        }
      }
    });
  }

  transformToMoment(value: string) {
    return moment(value, 'DD/MM/YYYY').toDate();
  }

  MomentToString(obj: any) {
    return moment(obj).format('DD/MM/YYYY');
  }

  private createForm(): void {
    const validatorsBr = this.pioneerValidators as BrazilValidators;

    this.newUserForm = this.fb.group({
      documentNumber: new FormControl('', validatorsBr.documentHybrid),
      fullName: new FormControl('', validatorsBr.fullName),
      cellPhone: new FormControl('', validatorsBr.cellPhone),
      businessPhone: new FormControl('', Validators.minLength(10)),
      email: new FormControl('', validatorsBr.email),
      status: new FormControl('', Validators.required),
      cdc: new FormControl(''),
      cessao: new FormControl(''),
      allowInsuranceSell: new FormControl(''),
      allowSubsidiary: new FormControl(''),
      allowBipartido: new FormControl('')
    });

    this.newUserForm.controls['allowBipartido'].setValue(true);
  }

  getPerfis() {
    this._managementService.searchPerfil().subscribe((data: any) => {
      this.profileOptions = data;
    });
  }

  getPerfilsDescription(cd) {
    return this.profileOptions.filter(v => {
      return v.code === cd;
    })[0].description;
  }

  private getProducts(): any {
    this.saleId$
      .pipe(
        first(),
        switchMap(id => this._managementService.getProductsByShop(id))
      )
      .subscribe((data: any) => {
        this.shopProducts = data;
      });
  }

  tag(category, action, label) {
    this.genTagger.setTag({
      event_action: `Selecionou - ${action}`,
      event_category: category,
      event_label: label
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
