<section id="5" class="simulation-section simulation-section-height">
  <header class="section-header">
    <h2 class="title">{{ 'STEPPER-SIMULATION-SECTION-5-TITLE' | translate }}</h2>
    <span>{{ dueDateData.sectionNumber }}</span>
  </header>
  <div class="section-main" [formGroup]="form">
    <p class="text">
      {{ 'STEPPER-SIMULATION-SECTION-5-TEXT' | translate }}
    </p>
    <p class="installment-label">
      {{ 'first due date' | translate }}
    </p>
    <mat-form-field class="input-form">
      <mat-label></mat-label>
      <input
        readonly
        class="form-control date-time-mask readonly"
        matInput
        formControlName="dueDate"
        mask="00/00/0000"
        placeholder="DD/MM/AAAA"
        appTagger
        [event_category]="category"
        event_label="{{ 'first due date' | translate }}"
        [event_action]="Insert"
      />
      <button
        mat-button
        matSuffix
        mat-icon-button
        (click)="openCalendar()"
        appTagger
        [event_category]="category"
        event_label="calendario"
        [event_action]="Selecionar"
      >
        <mat-icon color="primary">calendar_today</mat-icon>
      </button>
    </mat-form-field>
  </div>
  <p *ngIf="!dueDateData.isValidFirstInstallmentDueDate" class="error-msg">
    Escolher entre {{ dueDateData.minAllowedFirstInstallmentDueDate }} até
    {{ dueDateData.maxAllowedFirstInstallmentDueDate }}
  </p>
  <app-calendar [calendarConfig]="calendarConfig" (navAction)="navAction($event)" *ngIf="showCalendar"></app-calendar>
</section>
