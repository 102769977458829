<div id="modal-content-wrapper">
  <header id="modal-header" class="text-center mt-3">
    <h1 id="modal-title" mat-dialog-title><strong>Cadastro Indisponível</strong></h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <div class="info-section-1">
      Não foi possível criar uma conta com esse CPF no momento.
    </div>
  </section>
  <footer id="modal-footer" class="footer-buttons" mat-dialog-actions>
    <button
      mat-flat-button
      id="modal-cancel-button"
      color="primary"
      class="button-red"
      (click)="cancelActionFunction()"
    >
      Ok
    </button>
  </footer>
</div>
