<div class="sign-in-container">
  <div class="sidebar-container">
    <app-sidebar></app-sidebar>
  </div>

  <section>
    <div class="content">
      <p class="title">{{ 'TABSCREEN-TITLE' | translate }}</p>
      <p class="subtitle">
        {{ 'TABSCREEN-SUBTITLE' | translate }}
      </p>
      <form [formGroup]="selectStore">
        <div class="input-icon">
          <ng-template #rt let-r="result" let-t="term">
            <p [ngClass]="{ 'grey-tab': r.statusCode === shopStatus.INACTIVE || r.statusCode === shopStatus.BLOCKED }">
              {{ r.code }} - {{ r.name }}
            </p>
          </ng-template>
          <input
            id="typeahead"
            name="store"
            type="text"
            class="form-control"
            placeholder="{{ 'SELECT-STORE' | translate }}"
            formControlName="storeSelected"
            [ngbTypeahead]="search"
            (selectItem)="selectTabHandler($event.item)"
            (focus)="focus$.next($any($event).target.value)"
            [resultFormatter]="formatter"
            [inputFormatter]="formatter"
            [resultTemplate]="rt"
            #instance="ngbTypeahead"
            appTagger
            [event_category]="categoria"
            event_label="selecione uma loja"
            [event_action]="Insert"
          />
          <small
            *ngIf="
              selectStore.controls.storeSelected.value.statusCode === shopStatus.INACTIVE ||
              selectStore.controls.storeSelected.value.statusCode === shopStatus.BLOCKED
            "
            class="field-error"
          >
            {{ 'SELECT-STORE-ERROR-MSG' | translate }}
          </small>
        </div>
        <button
          class="btn"
          (click)="handleSubmitForm()"
          [disabled]="
            !selectStore.controls.storeSelected.value.statusCode ||
            selectStore.controls.storeSelected.value.statusCode === shopStatus.INACTIVE ||
            selectStore.controls.storeSelected.value.statusCode === shopStatus.BLOCKED
          "
          name="continuar"
          appTagger
          [event_category]="categoria"
          event_label="{{ 'CONTINUE' | translate }}"
        >
          {{ 'CONTINUE' | translate }}
        </button>
      </form>
    </div>
  </section>
</div>
