import { Component, EventEmitter, Input, OnInit, Output, ViewChild, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { InputChange } from '@app/proposal-workflow/models/input-change.model';
import { InstallmentInfoBln } from '../installment-info-bln/installment-info-bln.component';
import { InstallmentInfoLack } from '../installment-info-lack/installment-info-lack.component';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-payment-plan-installment-info',
  templateUrl: './payment-plan-installment-info.component.html',
  styleUrls: ['./payment-plan-installment-info.component.scss']
})
export class PaymentPlanInstallmentInfoComponent implements OnInit, OnChanges, OnDestroy {
  @Input() code: string;

  @Input() selectedPaymentMethod: any;

  @Input() months: any = [];

  @Input() codeMonthsRange;
  @Input() firstInstallmentPMethodsDate;
  @Input() shopkeeperData;
  @Input() bestOffer: any

  @Input() installmentPMethodsDate;

  @Input() installmentStepData$: Observable<any>;
  @Output() installmentInfoChangedEmitter = new EventEmitter<InputChange>();
  @Output() installmentInfoChanged = new EventEmitter<InputChange>();
  @Output() public addForm = new EventEmitter<FormGroup>();
  @Output() public resValidator = new EventEmitter<boolean>();

  @Input() category: string;

  @ViewChild('infoLack') infoLack: InstallmentInfoLack;
  @ViewChild('infoBln') infoBln: InstallmentInfoBln;
  protected ngUnsubscribe: Subject<any> = new Subject();

  constructor() {}

  ngOnInit() {
    this.installmentStepData$
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => this.installmentPMethodsDate = res.installmentPMethodsDate)
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.installmentPMethodsDate) {
      this.installmentPMethodsDate = this.installmentPMethodsDate;
    }
  }

  validatorForm(res: boolean) {
    if (res) {
      this.resValidator.emit(true);
    } else {
      this.resValidator.emit(false);
    }
  }
 installmentInfoeEmit(data){
  this.installmentInfoChanged.emit(data);
 }

  installmentInfoHandler(data: InputChange) {
    this.installmentInfoChangedEmitter.emit(data);
  }
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
