import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core/state';
import * as fromProposal from '@app/core/state/proposal';
import { BipartiteTypeEnum, PartnerTypeEnum } from '@app/pendency/models/bipartite-type.enum';
import { BipartitePartnerDTO, BipartitePartnersQueryDTO } from '@app/pendency/models/bipartite.model';
import { PendencyService } from '@app/pendency/pendency.service';
import {
  ChecklistDocumentEnum,
  ChecklistItemTypeEnum
} from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/api/query.checklist.model';
import {
  ChecklistModel,
  ChecklistStatusEnum
} from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/checklist.model';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { Subject } from 'rxjs';
import { debounceTime, delay, filter, first, take, takeUntil, tap } from 'rxjs/operators';
import { AddBipartitePartnerPayload } from './../../models/bipartite.model';

@Component({
  selector: 'app-pendency-bipartite',
  templateUrl: './pendency-bipartite.component.html',
  styleUrls: ['./pendency-bipartite.component.scss']
})
export class PendencyBipartiteComponent implements OnChanges, OnDestroy {
  protected ngUnsubscribe = new Subject<any>();

  @Input() public proposalInformation: ConfirmationData;
  @Input() public bipartiteData: BipartitePartnersQueryDTO;
  @Output() public uploadFinished = new EventEmitter();
  @Output() public sendRegisteredPartners = new EventEmitter();
  @Input() public sendhandleUploadFinished: any;

  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;

  public maxSizeAllowed = '10';
  public maxPartners = 2;
  public completedChecklistIds: string[] = [];
  public uploadsComplete = false;
  public isValidMinPercentage = true;
  public isValidMaxPercentage = true;
  public Select: Tag = Tag.Selecionar;

  public bipartiteTypeEnum = BipartiteTypeEnum;

  public form: FormGroup;
  public showPartnersForm = false;
  public partnerDocumentCode: string;
  public supplierChannelId: number;
  public getSupplierChannelId: BipartitePartnerDTO[] = [];
  public getSupplierChannelIdSave: number;

  public proposalId: number;
  public position: number;

  public proposalPartners: BipartitePartnerDTO[];
  public registeredPartners: BipartitePartnerDTO[];
  public getRegisterdPartners: Array<any> = [];
  public bipartiteType: string;
  public mainPartner: BipartitePartnerDTO;
  public wasSaved = false;

  public validMinValue: number;
  public validMaxValue: number;
  public partnerResPercentMin: BipartitePartnerDTO;
  public partnerResPercentMax: BipartitePartnerDTO;
  public partnerRes: BipartitePartnerDTO[];
  public isValueValid = true;

  public idAcordoComercial: number;
  public canAddBipartite = true;
  public itsNotAFixedAgreement: boolean;
  public addedValue: number;
  public addedFixBipartite: number;

  public checklist: ChecklistModel[] = [];
  readonly categoria: '/portallojista/pendencia';
  readonly Selecionar: Tag = Tag.Selecionar;
  readonly Insert: Tag = Tag.Insert;

  public saldoDisponivel: number;
  public valueOverFinanced = false;

  public acordosComerciais: any;

  constructor(
    private store$: Store<AppState>,
    private internationalizationService: InternationalizationService,
    private pendencyService: PendencyService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.proposalInformation && this.proposalDataExist()) {
      this.proposalId = +this.proposalInformation.proposalData.id;
      this.addedValue = 0;
      this.addedFixBipartite = 0;
      this.getRegisteredPartners();
      this.getProposalPartners();
    }

    if ((changes.bipartiteData || changes.proposalInformation) && !!this.bipartiteData && this.proposalDataExist()) {
      this.bipartiteType = this.bipartiteData.proposalData.bipartiteType;

      if (this.bipartiteData.proposalData.fgBipartido === 'N') return this.setSingleBipartite();
    }
    this.receiveHandleUploadFinished();
  }
  public receiveHandleUploadFinished() {
    if (!!this.sendhandleUploadFinished) {
      this.getRegisteredPartners();
    }
  }

  public getRegisteredPartners(): void {
    this.pendencyService
      .getProposalPartners(+this.proposalId)
      .pipe(take(1))
      .subscribe((data: BipartitePartnerDTO[]) => {
        const partnersFiltered = this.filterPartnersRegistered(data);
        this.registeredPartners = partnersFiltered;
        const totalBipartido = partnersFiltered.reduce((saldo, partner) => saldo + partner.partnerValue, 0);

        if (!!this.proposalInformation.releasedAmount && this.proposalInformation.releasedAmount > 0) {
          this.saldoDisponivel = +this.proposalInformation.releasedAmount - totalBipartido;
        } else {
          this.saldoDisponivel = +this.proposalInformation.financedValue - totalBipartido;
        }
        this.checkIfYouHaveaPartner();

        this.checkIfThereIsChecklist(this.registeredPartners);

        if (!!this.getRegisterdPartners) {
          this.sendRegisteredPartners.emit(this.getRegisterdPartners);
        }

        if (this.registeredPartners.length > 0) {
          this.proposalPartners = this.filterPartners(this.proposalPartners, this.registeredPartners);
        }
      });
    this.getRegisterdPartners = [];
  }

  private checkIfThereIsChecklist(data: BipartitePartnerDTO[]) {
    data.forEach(c => {
      if (!!c.checklist) {
        this.getRegisterdPartners.push(c.checklist.status);
      } else if (this.wasSaved) {
        this.getRegisterdPartners = null;
        this.sendRegisteredPartners.emit(this.getRegisterdPartners);
        return;
      } else {
        this.sendRegisteredPartners.emit(this.getRegisterdPartners);
      }
    });
  }

  public checkIfYouHaveaPartner() {
    this.position = this.registeredPartners.length - 1;
    if (this.registeredPartners.length !== 0) {
      this.wasSaved = true;
    } else {
      this.wasSaved = false;
    }
  }

  public filterPartners(partnersAvailable: BipartitePartnerDTO[], partnersRegistered: BipartitePartnerDTO[]) {
    const registeredSupplierChannel = partnersRegistered.map(item => item.supplierChannelId);
    const registeredAcordo = partnersRegistered.map(item => item.idAcordoComercial);
    const partnersFiltrados = partnersAvailable.filter(item => !registeredAcordo.includes(item.idAcordoComercial));

    return partnersFiltrados.filter(item => !registeredSupplierChannel.includes(item.supplierChannelId));
  }

  public filterPartnersRegistered(partners: BipartitePartnerDTO[]) {
    this.getSupplierChannelId = partners.filter(partner => partner.partnerType === PartnerTypeEnum.PARTNER);
    this.getSupplierChannelId.forEach(id => {
      this.getSupplierChannelIdSave = id.supplierChannelId;
    });
    return partners.filter(partner => partner.partnerType === PartnerTypeEnum.PARTNER);
  }

  private proposalDataExist(): boolean {
    return this.proposalInformation && !!this.proposalInformation.proposalData;
  }

  public buildForm(): void {
    this.form = new FormGroup({
      supplierChannelId: new FormControl(null, [Validators.required]),
      documentNumber: new FormControl(null, [Validators.required, Validators.min(0), Validators.maxLength(14)]),
      value: new FormControl({ value: null, disabled: false }, [Validators.required, Validators.min(1)]),
      idAcordoComercial: new FormControl(null, [Validators.required])
    });

    this.form.valueChanges.pipe(debounceTime(1000)).subscribe(form => {
      if (!!form.value) {
        this.checkValue(form);
      } else {
        this.validateAndSave();
      }
    });
  }

  public addPartner() {
    this.buildForm();
    this.hideForm();
    this.idAcordoComercial = null;
    this.showPartnersForm = true;
  }

  public buttonClose() {
    this.idAcordoComercial = null;
    this.getSupplierChannelId = null;
    this.getRegisteredPartners();
    this.hideForm();
  }

  public hideForm() {
    this.form.reset();
    this.isValueValid = true;
    this.getRegisteredPartners();
    this.showPartnersForm = false;
  }

  private filterInvoiceUploadCheckList(checklistArray: ChecklistModel[]): ChecklistModel[] {
    return checklistArray.filter(item => {
      return item.type.code === ChecklistItemTypeEnum.UPLOAD && item.document.docCode === ChecklistDocumentEnum.INVOICE;
    });
  }

  private setSingleMainPartner(mainPartner: BipartitePartnerDTO): void {
    this.mainPartner = mainPartner;
  }

  private setSingleBipartite() {
    this.store$
      .select(fromProposal.selectors.selectChecklist)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(state => !!state),
        delay(200)
      )
      .subscribe(checklist => {
        if (!this.proposalInformation || !checklist.length || !this.bipartiteData) {
          return;
        }

        const onlyInvoiceList = this.filterInvoiceUploadCheckList(Array.from(checklist));
        this.checklist = onlyInvoiceList;
        this.setSingleMainPartner({
          id: +onlyInvoiceList[0].client.id,
          proposalId: this.bipartiteData.proposalData.proposalId,
          partnerDescription: '',
          partnerDocumentCode: onlyInvoiceList[0].client.documentNumber,
          supplierChannelId: this.bipartiteData.proposalData.channelSupplierId,
          partnerType: PartnerTypeEnum.MAIN,
          partnerPecentage: 100,
          partnerPecentageMax: this.bipartiteData.proposalData.partnerPecentageMax,
          partnerPecentageMin: this.bipartiteData.proposalData.partnerPecentageMin,
          partnerValue: this.bipartiteData.proposalData.totalValueFinanc,
          checklist: onlyInvoiceList[0],
          dsAcordoComercial: this.bipartiteData.proposalData.dsAcordoComercial,
          idAcordoComercial: this.bipartiteData.proposalData.idAcordoComercial
        });
      });
  }

  private calculaMaxPartners(): void {
    this.maxPartners = 2;
    const qtdRegistrados = this.registeredPartners.length;
    const qtdPartners = this.proposalPartners.length;

    if (qtdRegistrados >= this.maxPartners || qtdPartners === 0) {
      this.maxPartners = 0;
      return;
    }
    if (qtdPartners === 1) {
      this.maxPartners = 1;
      return;
    }

    this.maxPartners = this.maxPartners - qtdRegistrados;
  }

  public getProposalPartners(): void {
    this.pendencyService
      .getPartners(+this.proposalInformation.proposalData.id) // partners V2
      .pipe(
        filter(partners => !!partners),
        tap((partners: BipartitePartnerDTO[]) => {
          partners.forEach(p => {
            this.checklist.push(p.checklist);
          });
        }),
        first(),
        delay(700)
      )
      .subscribe((partners: BipartitePartnerDTO[]) => {
        if (this.registeredPartners.length > 0) {
          this.proposalPartners = this.filterPartners(partners, this.registeredPartners);
        } else {
          this.proposalPartners = partners;
        }
        if (this.proposalPartners.length === 0) {
          this.maxPartners = 0;
        }
        if (partners.length === 1 && this.registeredPartners.length === 0) {
          this.maxPartners = 1;
          this.addPartner();
          this.setTheStoreField(partners[0]);
        }

        this.proposalPartners = this.proposalPartners.map(item => ({
          ...item,
          idWithAcordo: item.supplierChannelId + ':' + item.idAcordoComercial
        }));
        this.acordosComerciais = this.proposalPartners;
        this.calculaMaxPartners();
        this.checkPartner();
      });
  }

  private checkPartner() {
    if (this.registeredPartners.length) {
      if (this.bipartiteType === BipartiteTypeEnum.CUSTOM || this.bipartiteType === BipartiteTypeEnum.FIXED) {
        const notNeed = this.registeredPartners.find(
          proposal => !!proposal.checklist && proposal.checklist.status !== ChecklistStatusEnum.INDEXED
        );
        if (!notNeed) {
          return this.uploadFinished.emit(this.getRegisterdPartners);
        }
      }
    }
    this.uploadFinished.emit(this.getRegisterdPartners);
  }

  private getPercentageValue(value) {
    if (!!this.proposalInformation.releasedAmount && this.proposalInformation.releasedAmount > 0) {
      return +this.proposalInformation.releasedAmount * (value / 100);
    }

    return +this.proposalInformation.financedValue * (value / 100);
  }

  private setMinMaxValuesAndCompare(partner) {
    this.validMinValue = this.getPercentageValue(partner.partnerPecentageMin);
    this.validMaxValue = this.getPercentageValue(partner.partnerPecentageMax);

    if (this.validMinValue === this.validMaxValue) {
      if (this.validMinValue > this.saldoDisponivel) {
        this.isValueValid = false;
        this.valueOverFinanced = true;
      } else {
        this.isValueValid = true;
      }
      this.form.get('value').patchValue(this.validMinValue);
      this.form.get('value').disable();
      this.addedFixBipartite = this.validMinValue;
    } else {
      this.form.get('value').enable();
    }
  }

  public checksIfTheValueIsEmpty(res: any) {
    if (res.name === 'documentNumber' && res.value === '') {
      this.form.get('supplierChannelId').patchValue(null);
    } else if (res.name === 'supplierChannelId' && res.value === '') {
      this.form.get('documentNumber').patchValue(null);
    } else if (res.name === 'idAcordoComercial' && res.value === '') {
      this.form.get('documentNumber').patchValue(null);
      this.form.get('supplierChannelId').patchValue(null);
    }
  }

  private setTheStoreField(res: any): void {
    const partner = this.proposalPartners.find(
      p => p.supplierChannelId === res.supplierChannelId && p.idAcordoComercial === res.idAcordoComercial
    );
    if (partner) {
      this.form.get('idAcordoComercial').patchValue(partner.idAcordoComercial);
      const idWithAcordo = res.hasOwnProperty('idWithAcordo')
        ? res.idWithAcordo
        : res.supplierChannelId + ':' + res.idAcordoComercial;
      this.form.get('documentNumber').patchValue(idWithAcordo);
      this.form.get('supplierChannelId').patchValue(idWithAcordo);
      this.setMinMaxValuesAndCompare(partner);
      this.idAcordoComercial = partner.idAcordoComercial;
    } else {
      this.form.get('documentNumber').patchValue(null);
      this.form.get('idAcordoComercial').patchValue(null);
      this.idAcordoComercial = null;
    }
  }

  public setDropdownVariables(res: any) {
    let response: any;
    if (res.supplierChannelId && res.supplierChannelId.toString().includes(':') === true) {
      response = {
        supplierChannelId: parseInt(res.supplierChannelId.split(':')[0]),
        idAcordoComercial: parseInt(res.supplierChannelId.split(':')[1]),
        idWithAcordo: res.supplierChannelId
      };
    }

    if (res.documentNumber && res.documentNumber.toString().includes(':') === true) {
      response = {
        supplierChannelId: parseInt(res.documentNumber.split(':')[0]),
        idAcordoComercial: parseInt(res.documentNumber.split(':')[1]),
        idWithAcordo: res.documentNumber
      };
    }

    if (typeof response === 'undefined') {
      response = res;
    }

    this.setTheStoreField(response);
  }

  public setFieldsByDsAcordo(res: any): void {
    this.proposalPartners = this.acordosComerciais;
    const partner = this.proposalPartners.find(p => p.idAcordoComercial === res.idAcordoComercial);
    if (partner) {
      this.proposalPartners = this.proposalPartners.filter(
        item => item.idAcordoComercial === partner.idAcordoComercial
      );
      if (this.proposalPartners.length === 1) {
        this.form.get('supplierChannelId').patchValue(partner.supplierChannelId);
        this.form.get('documentNumber').patchValue(partner.partnerDocumentCode);
        this.form.get('idAcordoComercial').patchValue(partner.idAcordoComercial);
        this.setMinMaxValuesAndCompare(partner);
        this.idAcordoComercial = partner.idAcordoComercial;
      } else {
        this.form.get('idAcordoComercial').patchValue(partner.idAcordoComercial);
        this.idAcordoComercial = partner.idAcordoComercial;
      }
    } else {
      this.form.get('documentNumber').patchValue(null);
      this.form.get('supplierChannelId').patchValue(null);
      this.form.get('idAcordoComercial').patchValue(null);
      this.idAcordoComercial = null;
    }
  }

  public checkValue(form) {
    const value = form.value;
    // default is true
    this.isValueValid = true;
    this.isValidMinPercentage = true;
    this.isValidMaxPercentage = true;
    this.valueOverFinanced = false;

    if (value < this.validMinValue || value === 0) {
      this.isValueValid = false;
      this.isValidMinPercentage = false;
      this.validateAndSave();
      return;
    }

    if (this.validMaxValue > this.saldoDisponivel) {
      if (value > +this.saldoDisponivel) {
        this.isValueValid = false;
        this.valueOverFinanced = true;
        this.validateAndSave();
        return;
      }
    } else {
      if (value > this.validMaxValue) {
        this.isValueValid = false;
        this.isValidMaxPercentage = false;
        this.validateAndSave();
        return;
      }
    }

    this.validateAndSave();
  }

  public validateAndSave() {
    if (this.form.valid && this.isValueValid) {
      this.savePartner();
    }
  }

  public savePartner(): void {
    const formData = this.form.getRawValue();
    const payload: AddBipartitePartnerPayload = {
      supplierChannelId:
        formData.supplierChannelId.toString().includes(':') === true
          ? parseInt(formData.supplierChannelId.split(':')[0])
          : formData.supplierChannelId,
      value: formData.value,
      idAcordoComercial: this.idAcordoComercial
    };
    this.pendencyService
      .addPartner(this.proposalId, payload)
      .pipe(first())
      .subscribe(() => {
        this.getRegisteredPartners();
        this.getProposalPartners();
        this.hideForm();
      });
  }

  public deletePartner(partnerId: number): void {
    this.getRegisterdPartners = [];
    this.canAddBipartite = true;
    this.pendencyService
      .deletePartner(partnerId)
      .pipe(first())
      .subscribe(() => {
        this.getRegisteredPartners();
        this.getProposalPartners();
      });
  }

  public handleUploadFinished(obj: { id: string; completed: boolean }): void {
    // If upload is successful and the element is not in the list, add it.
    if (obj.completed) {
      if (this.completedChecklistIds.find(ele => ele === obj.id)) return;
      this.completedChecklistIds.push(obj.id);
    }

    // If deletion is successful and the element is in the list, remove it.
    if (!obj.completed) {
      if (!this.completedChecklistIds.find(ele => ele === obj.id)) return;
      const index = this.completedChecklistIds.findIndex(ele => ele === obj.id);
      this.completedChecklistIds.splice(index, 1);
    }

    // Compare checklist to successful uploads to determine if we need to enable submit button.
    this.uploadsComplete = this.checklist.every(_ele => !!this.completedChecklistIds.find(id => obj.id === id));

    this.uploadsComplete ? this.uploadFinished.emit(true) : this.uploadFinished.emit(false);
    if (this.uploadsComplete || !this.uploadsComplete) {
      this.getRegisteredPartners();
    }
  }

  ngOnDestroy() {
    this.proposalPartners = [];
    this.mainPartner = null;
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.addedValue = 0;
    this.addedFixBipartite = 0;

    this.store$.dispatch(new fromProposal.actions.ClearChecklistAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalStageAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalIdAction());
  }
}
