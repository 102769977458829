import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { selectShowTutorialMenu, selectShowTutorialPrt } from '@app/core/state/login/login.selectors';
import * as fromProposal from '@app/core/state/proposal';
import { PendencyComponent } from '@app/pendency/pendency.component';
import { ProposalDetailsComponent } from '@app/profile/components/proposal-details/proposal-details.component';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FilterOrdenationDialogComponent } from '@shared/components/filter-ordenation-dialog/filter-ordenation-dialog.component';
import { FilterPeriodDialogComponent } from '@shared/components/filter-period-dialog/filter-period-dialog.component';
import { SearchSellerDialogComponent } from '@shared/components/search-seller-dialog/search-seller-dialog.component';
import { TutorialService } from '@shared/services/tutorial/tutorial.service';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, first, map, switchMap, take } from 'rxjs/operators';
import { ProposalCancelDialogComponent } from './components/proposal-cancel-dialog/proposal-cancel-dialog.component';
import {
  GetPanelProposalListDTOResponse,
  PanelProposalActionButtonEnum,
  PanelProposalDTO
} from './models/api/get.proposal-list.model';
import { ProposalButtonIconStatusEnum, ProposalStatusEnum } from './models/proposal.enum';
import { OpenProposalService } from './services/open-proposal.service';

@Component({
  selector: 'app-open-proposal',
  templateUrl: './open-proposal.component.html',
  styleUrls: ['./open-proposal.component.scss']
})
export class OpenProposalComponent implements OnInit, OnDestroy {
  // Material checkbox
  checked = false;
  indeterminate = false;
  // Mobile breakpoints on template
  public window = window;
  public MOBILE_BREAKPOINT = 768;

  // Pagination
  users = [];
  totalElements: number;
  totalPages: number;
  page = 1;
  perPage = 30;
  paginationMaxSize = 9;
  initialPage = 1;
  previousPage = this.initialPage;

  // Current store
  shopId$: Observable<any> = this.store.select('tabs').pipe(map(tab => tab.selectedTab.id));

  // input
  public searchString: string;

  // from management panel
  public fromGestao = false;

  // filter
  private filterObj: any = {
    period: '-6m'
  };

  // Check Ok Santander Filter
  filterOkSantanderBoolean = false;

  // MOCK
  public proposals: PanelProposalDTO[];
  public filters;
  public showFilters: boolean;
  public firstLoad = true;

  public customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 10,
    nav: true,
    navText: ['<', '>'],
    stagePadding: 0,
    autoHeight: false,
    responsive: {
      0: {
        items: 1
        // mouseDrag e touchDrag não funcionam com items = 1.5, por isso navegação é necessária
        // nav: false
      },
      568: {
        items: 3
      },
      940: {
        items: 5
      }
    }
  };

  private layoutChanges$: Subscription;
  private isLoadFilterPeriodStored = false;
  public allFiltersAmount: number;

  private ordenationIconUp = 'ic_diag_arrow_up';
  private ordenationIconDown = 'ic_diag_arrow_down';
  public ordenationIconsAllProposals = this.ordenationIconDown;

  readonly Pesquisar: Tag = Tag.Pesquisar;
  readonly Selecionar: Tag = Tag.Selecionar;
  readonly Insert: Tag = Tag.Insert;
  public categoria = '/portallojista/propostas';
  public isOpenFinanceEnabled = false;

  constructor(
    private translateService: TranslateService,
    private proposalService: OpenProposalService,
    private modalService: NgbModal,
    private store: Store<AppState>,
    private tutorialService: TutorialService,
    private dialog: MatDialog,
    private breakpointObserver: BreakpointObserver,
    private genTagger: GenTagger,
    private simulateService: SimulationService
  ) { }

  ngOnDestroy() {
    this.layoutChanges$.unsubscribe();
  }

  ngOnInit() {
    this.subscribeToLayoutChanges();

    // Tutorial
    this.store
      .select(selectShowTutorialMenu)
      .pipe(take(1))
      .subscribe(showTutorial => {
        if (showTutorial) {
          this.tutorialService.showTutorial('menu');
        }
        this.store
          .select(selectShowTutorialPrt)
          .pipe(take(1))
          // tslint:disable-next-line: no-shadowed-variable
          .subscribe(show => {
            if (show) {
              this.tutorialService.showTutorial('proposal');
            }
          });
      });

    this.getFromStore();
    this.getOpenFinanceEnabled('');
    this.getProposals(this.page, this.perPage);

    this.getFilterProposals();
    this.firstLoad = false;

    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: Tag.pgView,
      event_label: this.translateService.instant('PROPOSAL TITLE')
    });
  }

  // Check for layout changes to determine how many pages to show on pagination
  private subscribeToLayoutChanges(): void {
    this.layoutChanges$ = this.breakpointObserver
      .observe(['(orientation: portrait)', '(orientation: landscape)'])
      .pipe(debounceTime(300))
      .subscribe(() => {
        const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 768px)');
        this.paginationMaxSize = isSmallScreen ? 4 : 9;
      });
  }

  openDialogSeller(_link) {
    const modalRef = this.modalService.open(SearchSellerDialogComponent, {
      windowClass: 'modal-filter'
    });
    if (this.filterObj.seller) {
      modalRef.componentInstance.selected = this.filterObj.seller;
    } else {
      modalRef.componentInstance.selected = [];
    }
    modalRef.componentInstance.routedBy = 'open-proposal';

    modalRef.result.then(
      result => {
        if (result) {
          this.filterObj = {
            ...this.filterObj,
            seller: result,
            salesman: result.map(r => r.id)
          };
          this.getProposalsByFilter();
          this.getFilterProposals();
        }
      },
      reason => {
        if (reason === 0) {
          this.cleanFilterObj();
        }
      }
    );
  }

  filterOkSantander() {
    this.filterObj = {
      ...this.filterObj,
      openFinance: this.filterOkSantanderBoolean
    };
    this.proposalService.restartOrdenationAfterCleanFilters(false);
    this.getProposalsByFilter();
  }

  openDialogPeriod(_link) {
    const modalRef = this.modalService.open(FilterPeriodDialogComponent, {
      windowClass: 'modal-filter'
    });

    modalRef.componentInstance.isLoadFilterPeriodStored = this.isLoadFilterPeriodStored;
    modalRef.result.then(
      result => {
        this.filterObj = {
          ...this.filterObj,
          period: result.toString()
        };

        this.getProposalsByFilter();
        this.getFilterProposals();
        this.isLoadFilterPeriodStored = true;
      },
      reason => {
        if (reason === 'cancelled') {
          this.cleanFilterObj();
        }
      }
    );
  }

  openDialogProposalDetails(link) {
    const isMobile = window.innerWidth < this.MOBILE_BREAKPOINT;
    const modalRefMaterial = this.dialog.open(ProposalDetailsComponent, {
      width: isMobile ? '100%' : '68%',
      maxWidth: '61.375rem',
      id: link,
      panelClass: 'summary-modal-container',
      height: '100%',
      autoFocus: false,
      position: {
        right: '0px',
        top: '0px'
      }
    });
    modalRefMaterial.componentInstance.src = link;
    modalRefMaterial
      .afterClosed()
      .toPromise()
      .then(
        result => {
          console.log(`Dialog result: ${result}`);
          if (result === 'cancelProposal') {
            this.cleanFilterObj();
          }
        },
        reason => {
          console.log(reason);
          console.log(`Dialog result: ${reason}`);
        }
      );
  }

  public handleProposalActionBtn(proposal: PanelProposalDTO): void {
    this.store.dispatch(new fromProposal.actions.ShowcaseChoosedCard(proposal));
    this.store.dispatch(new fromProposal.actions.SetProposalIdAction(proposal.id));
    this.openSidebar(proposal);
  }

  public openSidebar(proposal: PanelProposalDTO): void {
    switch (proposal.button) {
      case PanelProposalActionButtonEnum.CONTINUE:
        this.openDialogProposalDetails(proposal.id);
        break;
      case PanelProposalActionButtonEnum.RESUME:
        this.openDialogProposalDetails(proposal.id);
        break;
      case PanelProposalActionButtonEnum.PENDING:
        this.openDialogPendency();
        break;
      case PanelProposalActionButtonEnum.EMITBLETO:
        this.openDialogProposalDetails(proposal.id);
        break;
    }
  }

  private openDialogPendency() {
    const isMobile = window.innerWidth < this.MOBILE_BREAKPOINT;
    const dialogConfig: MatDialogConfig = {
      width: isMobile ? '100%' : '68%',
      maxWidth: '61.375rem',
      id: 'summary-side-modal',
      panelClass: 'summary-modal-container',
      height: '100%',
      autoFocus: false,
      position: {
        right: '0px',
        top: '0px'
      }
    };
    const modalRefMaterialPendency = this.dialog.open(PendencyComponent, dialogConfig);

    modalRefMaterialPendency.afterClosed().subscribe(() => {
      this.searchByFilters();
    });
  }

  private getProposals(page, perPage): void {
    this.shopId$
      .pipe(
        first(),
        switchMap(shopId => this.proposalService.getProposals(shopId, page, perPage, this.filterObj))
      )
      .subscribe((proposals: GetPanelProposalListDTOResponse) => {
        this.setVariables(proposals);
      });
  }

  private getProposalsByFilter(): void {
    this.shopId$
      .pipe(
        first(),
        switchMap(shopId =>
          this.proposalService.getProposalsByFilter(shopId, this.initialPage, this.perPage, this.filterObj)
        )
      )
      .subscribe(proposals => {
        this.setVariables(proposals);
      });
  }

  private setVariables(proposals) {
    this.page = proposals.page;
    this.perPage = proposals.perPage;
    this.totalElements = proposals.totalElements;
    this.totalPages = proposals.totalPages;
    this.proposals = proposals.content.map(proposal => {
      const kebab = proposal.kebab.filter(kebab => kebab.hasOwnProperty('description'))
      proposal = { ...proposal, kebab }
      return proposal
    });
    this.setIconProposal();
  }

  private getFilterProposals(): void {
    this.shopId$
      .pipe(
        first(),
        switchMap(shopId => this.proposalService.getSummary(shopId, this.filterObj))
      )
      .subscribe(filters => {
        this.filters = filters.flatMap(filter => (filter.amount > 0 ? filter : []));
        const filtersAmountFiltred = this.filters.filter(
          item =>
            item.name.toUpperCase().trim() !== 'PROPOSTAS À VENCER' &&
            item.name.toUpperCase().trim() !== 'PROPOSTAS A EXPIRAR' &&
            item.name.toUpperCase().trim() !== 'TODAS PROPOSTAS'
        );
        this.allFiltersAmount = filtersAmountFiltred.map(payment => payment.amount).reduce((total, qtd) => total + qtd);
        this.filters.forEach(filter => {
          filter['iconArrow'] = this.ordenationIconDown;
        });
      });
  }

  public loadPage(pageNumber) {
    this.page = pageNumber;
    this.getProposals(pageNumber, this.perPage);
  }
  public searchByFilters(): void {
    if (this.searchString) {
      this.filterObj = {
        ...this.filterObj,
        search: this.searchString
      };
    }

    this.getProposalsByFilter();
    this.getFilterProposals();
  }

  public tagSearchBlur() {
    if (this.isNumeric(this.searchString)) {
      if (this.searchString.length === 11) {
        this.genTagger.setTag({
          event_category: this.categoria,
          event_action: this.eventActionCPF,
          event_label: 'busque por nº, cpf, nome, valor'
        });
      } else {
        this.genTagger.setTag({
          event_category: this.categoria,
          event_action: this.eventActionNumber,
          event_label: 'busque por nº, cpf, nome, valor'
        });
      }
    } else {
      this.genTagger.setTag({
        event_category: this.categoria,
        event_action: this.eventActionNome,
        event_label: 'busque por nº, cpf, nome, valor'
      });
    }
  }

  public isNumeric(data) {
    return !isNaN(data);
  }

  get eventActionCPF(): ExtendedTag {
    return {
      event_action_type: Tag.Pesquisar,
      event_action_complement: 'CPF'
    };
  }

  get eventActionNumber(): ExtendedTag {
    return {
      event_action_type: Tag.Pesquisar,
      event_action_complement: 'Nº '
    };
  }

  get eventActionNome(): ExtendedTag {
    return {
      event_action_type: Tag.Pesquisar,
      event_action_complement: 'Nome '
    };
  }

  get eventActionFilter(): ExtendedTag {
    return {
      event_action_type: Tag.Selecionar,
      event_action_complement: 'filtros rápidos '
    };
  }
  public filterByStatus(name) {
    if (name.length === 0) {
      this.genTagger.setTag({
        event_category: this.categoria,
        event_action: this.eventActionFilter,
        event_label: 'Todas as propostas'
      });

      delete this.filterObj.status;
      this.clickFilterAllProposals();
    } else {
      this.genTagger.setTag({
        event_category: this.categoria,
        event_action: this.eventActionFilter,
        event_label: name
      });
      if (!this.filterObj.status) {
        this.filterObj.status = new Array();
      }

      const filter = this.filterObj.status.filter(item => item !== name);
      if (filter.length === this.filterObj.status.length) {
        this.filterObj.status.push(name);
      } else {
        this.filterObj.status = filter;
      }

      this.clickFilterProposals(name);
    }

    this.getProposalsByFilter();
  }

  private clickFilterAllProposals(): void {
    this.ordenationIconsAllProposals =
      this.ordenationIconsAllProposals === this.ordenationIconDown ? this.ordenationIconUp : this.ordenationIconDown;

    this.filters.forEach(filterSel => {
      filterSel.iconArrow = this.ordenationIconDown;
    });
  }

  private clickFilterProposals(statusName: string): void {
    this.ordenationIconsAllProposals = this.ordenationIconDown;
    this.filters.forEach(filterSel => {
      if (filterSel.name === statusName) {
        filterSel.iconArrow =
          filterSel.iconArrow === this.ordenationIconDown ? this.ordenationIconUp : this.ordenationIconDown;
      }
    });
  }

  public cleanFilterObj() {
    this.searchString = '';
    this.filterObj = { period: '-6m' };
    this.proposalService.restartOrdenationAfterCleanFilters(true);
    this.getProposals(this.initialPage, this.perPage);
    this.getFilterProposals();
    this.isLoadFilterPeriodStored = false;
  }

  public kebabAction({ code, id }): void {
    if (code === 'DETPROP') {
      this.openDialogProposalDetails(id);
    } else if (code === 'APAGPROP') {
      this.openDialogProposalCancel(id);
    }
  }

  private openDialogProposalCancel(id: number) {
    const proposal = this.proposals.find(p => p.id === id);

    if (!!proposal) {
      const ref = this.dialog.open(ProposalCancelDialogComponent, {
        width: '560px',
        autoFocus: false,
        data: {
          id: id,
          userName: proposal.user,
          value: proposal.value,
          amountParcels: proposal.amountParcels,
          valueParcels: proposal.valueParcels,
          routedBy: 'open-proposal'
        }
      });
      ref.componentInstance.okExit.subscribe(() => {
        this.cleanFilterObj();
      });
    }
  }

  public orderProposals(values: any): void {
    this.filterObj = {
      ...this.filterObj,
      sort: values
    };
    this.proposalService.restartOrdenationAfterCleanFilters(false);
    this.getProposalsByFilter();
  }

  public openDialogProposalOrdenation(): void {
    const modalRef = this.modalService.open(FilterOrdenationDialogComponent, {
      windowClass: 'modal-filter-ordenation'
    });
    modalRef.componentInstance.categoria = this.categoria;
    modalRef.componentInstance.selected =
      !!this.filterObj.sort && !!this.filterObj.sort.length ? this.filterObj.sort[0].sort : '';
    modalRef.result.then(result => {
      if (!!result && !!result.sort) {
        this.orderProposals(result);
      }
    });
  }

  private getFromStore() {
    const filtersStore = JSON.parse(localStorage.getItem('mgm_managementFilters'));

    if (filtersStore && filtersStore.shouldPassFiltersToProposalPanel) {
      this.fromGestao = true;
      this.filterObj = {
        ...this.filterObj,
        seller:
          filtersStore.selected.collaborator && filtersStore.selected.collaborator.idSalesPerson !== 'TODOS'
            ? filtersStore.selected.collaborator.idSalesPerson
            : null,
        statusPG: filtersStore.selected.statusPG,
        statusFunil: filtersStore.selected.statusFunil,
        tpClient:
          filtersStore.selected.client && filtersStore.selected.client.key !== 'TODOS'
            ? filtersStore.selected.client.key
            : null,
        type:
          filtersStore.selected.product && filtersStore.selected.product.cdProduct !== 'TODOS'
            ? filtersStore.selected.product.cdProduct
            : null,
        bipartite:
          filtersStore.selected.bipartite && filtersStore.selected.bipartite.key !== 'TODOS'
            ? filtersStore.selected.bipartite.key
            : null,
        idChannel:
          filtersStore.selected.channel && filtersStore.selected.channel.idChannel !== 'TODOS'
            ? filtersStore.selected.channel.idChannel
            : null,
        idSubseg:
          filtersStore.selected.subsegment && filtersStore.selected.subsegment.idSubseg !== 'TODOS'
            ? filtersStore.selected.subsegment.idSubseg
            : null,
        period: '-180d'
      };
    }
  }

  public selectedFilter(name) {
    if (this.filterObj.status && this.filterObj.status.length) {
      return this.filterObj.status.some(item => item === name);
    }
  }

  private setIconProposal(): void {
    this.proposals.forEach(proposal => {
      proposal['iconStatusProposal'] = this.getIconStatusbyDescription(proposal.statusDescription);
    });
  }

  private getIconStatusbyDescription(statusDescription: string): string {
    let statusDescriptionReturn = '';
    if (statusDescription !== undefined && statusDescription !== '') {
      switch (statusDescription.toUpperCase().trim()) {
        case ProposalStatusEnum.PAYMENT_AVAILABLE:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PAYMENT_AVAILABLE;
          break;
        case ProposalStatusEnum.PROPOSAL_IN_PROGRESS:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PROPOSAL_IN_PROGRESS;
          break;
        case ProposalStatusEnum.PROPOSAL_IN_FORMALIZATION:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PROPOSAL_IN_FORMALIZATION;
          break;
        case ProposalStatusEnum.PROPOSAL_IN_NEGOCIATION:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PROPOSAL_IN_NEGOCIATION;
          break;
        case ProposalStatusEnum.PROPOSAL_IN_DOCUMENTAL_ANALYSIS:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PROPOSAL_IN_DOCUMENTAL_ANALYSIS;
          break;
        case ProposalStatusEnum.PROPOSAL_IN_DOCUMENT_VALIDATION:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PROPOSAL_IN_DOCUMENT_VALIDATION;
          break;
        case ProposalStatusEnum.PROPOSAL_IN_PENDING_DOCUMENT:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PROPOSAL_IN_PENDING_DOCUMENT;
          break;
        case ProposalStatusEnum.PROPOSAL_IN_DOCUMENT_PENDING:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PROPOSAL_IN_DOCUMENT_PENDING;
          break;
        case ProposalStatusEnum.PROPOSAL_IN_ANALYSIS:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PROPOSAL_IN_ANALYSIS;
          break;
        case ProposalStatusEnum.PROPOSAL_DENIED:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PROPOSAL_DENIED;
          break;
        case ProposalStatusEnum.PROPOSAL_EXPIRED:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PROPOSAL_EXPIRED;
          break;
        case ProposalStatusEnum.PROPOSAL_CANCEL:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PROPOSAL_CANCEL;
          break;
        case ProposalStatusEnum.PROPOSAL_TO_EXPIRY:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PROPOSAL_NO_STATUS_UN_LIST;
          break;
        case ProposalStatusEnum.PAYMENT_PENDING_TARRIF:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PAYMENT_PENDING_TARRIF;
          break;
        default:
          statusDescriptionReturn = ProposalButtonIconStatusEnum.PROPOSAL_NO_STATUS_UN_LIST;
          break;
      }
    }
    return statusDescriptionReturn;
  }
  private getOpenFinanceEnabled(product): void {
    this.simulateService.getOpenFinanceEnabled(product).subscribe(data => {
      if (!!data) {
        this.isOpenFinanceEnabled = true;
      } else {
        this.isOpenFinanceEnabled = false;
      }
    });
  }

  public generateTag(nextPage) {
    this.genTagger.setTag({
      event_category: '/portallojista/propostas',
      event_action: {
        event_action_type: Tag.Click,
        event_action_complement: nextPage > this.previousPage ? 'Próximo' : 'Anterior'
      },
      event_label: 'Página ' + nextPage
    });
    this.previousPage = nextPage;
  }
}
