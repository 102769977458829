import { AbstractControl, Validators } from '@angular/forms';
import { ArgentinaValidators } from '@shared/interfaces/validators/argentina-validators.interface';
import { ValidateAge } from './multi-country.validators';

export const argentinaValidators: ArgentinaValidators = {
  documentNumber: [Validators.required, Validators.minLength(7), Validators.maxLength(11), isValidCuitOrDni],
  fullName: [Validators.required, Validators.minLength(2), Validators.maxLength(40)],
  nickName: [Validators.required, Validators.minLength(2), Validators.maxLength(20)],
  password: [Validators.required, Validators.minLength(6), Validators.maxLength(12)],
  email: [
    Validators.required,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    Validators.minLength(5),
    Validators.maxLength(40)
  ],
  cellPhone: [Validators.required, Validators.minLength(10)],
  gender: [Validators.required],
  acceptTerms: [Validators.required],
  cuit: [Validators.required, Validators.minLength(11), Validators.maxLength(11)],
  preToken: [Validators.required],
  dateOfBirth: [Validators.required, ValidateAge(18, 100)],
  financedValue: [Validators.required, Validators.min(0.01), Validators.max(999999999.99)],
  income: [Validators.required, Validators.min(0.01)]
};

function isValidCuitOrDni({ value: doc }: AbstractControl) {
  if (!doc) {
    return null;
  }

  const DNI_LENGTH = 8;
  const NOT_START_WITH_ZERO_REGEX = /^[1-9][0-9]*$/;
  const START_WITH_30_REGEX = /^30/;

  if (doc.length <= DNI_LENGTH) {
    if (NOT_START_WITH_ZERO_REGEX.test(doc)) {
      return null;
    }
  } else {
    if (START_WITH_30_REGEX.test(doc)) {
      return null;
    }
  }

  return { invalidDniOrCuit: true };
}
