<div class="update-register-container">
  <div *ngIf="checklistItems" class="pending">
    <div class="pending-title">
      <h2 class="pending-title-primary">{{ 'PENDENCY-UPDATE-REGISTER-TITLE' | translate }}</h2>
      <h2 class="pending-title-primary"></h2>
    </div>

    <pion-snackbar *ngIf="message" icon="error" [closable]="false" type="warning">
      <p [innerHTML]="message"></p>
    </pion-snackbar>

    <h3 *ngIf="checklistItems.length > 0" class="pending-title-secondary">
      {{ 'PENDENCY-ATTACH-PENDING-DOCUMENTS' | translate }}
    </h3>
    <div *ngFor="let file of checklistItems">
      <app-checklist-item
        [isPendency]="true"
        [checklistItem]="file"
        [maxSizeAllowed]="maxSizeAllowed"
        (uploadFinished)="handleUploadFinished($event)"
      ></app-checklist-item>
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-12">
      <h3 class="pending-title-secondary">
        {{ 'PENDENCY-UPDATE-REGISTER-DATA' | translate }}
      </h3>
      <div *ngIf="!!personalForm" [formGroup]="personalForm">
        <div class="row">
          <div class="col-12 col-md-6 mb-4">
            <mat-form-field class="full-name-input">
              <mat-label> {{ 'STEP-REGISTER-FULL-NAME' | translate }} </mat-label>
              <app-custom-input class="input-with-icon" [required]="true" formControlName="fullName"></app-custom-input>
              <mat-hint>{{ 'PENDENCY-NAME-HINT' | translate }}</mat-hint>
              <mat-error *ngIf="personalForm.get('fullName').touched && personalForm.get('fullName').invalid">{{
                'STEP-REGISTER-NAME-ERROR' | translate
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>

        <pion-snackbar *ngIf="personalForm.get('fullName').dirty" icon="info" [closable]="false" type="info">
          <p>{{ 'PENDENCY-UPDATE-REGISTER-MSG' | translate }}</p>
        </pion-snackbar>
      </div>
    </div>
  </div>

  <div class="update-register-send">
    <button
      class="update-register-container-button-send"
      mat-button
      [disabled]="!isPendencyResolved()"
      (click)="submitUpdateRegister()"
    >
      {{ 'PENDENCY-UPDATE-REGISTER-SEND-BTN' | translate }}
    </button>
  </div>

  <div class="biometry-container" *ngIf="isBiometryOrSignatureEnabled">
    <div class="pending-title">
      <h2 class="pending-title-primary">
        {{ 'PENDENCY-UPDATE-REGISTER-DATA-BIOMETRY-SIGNATURE' | translate }}
      </h2>
      <h2 class="pending-title-primary"></h2>
    </div>
    <app-biometry-controller
      [proposal]="proposal"
      [checklist]="checklist"
      [crossedFluxLink]="crossedFluxLink"
      (finish)="handleBiometryFinished($event)"
    ></app-biometry-controller>
  </div> -->
</div>
