import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AppState } from '@app/core/state';
import { PaymentsService } from '@app/showcase/payments/services/payments.service';
import { OpenProposalService } from '@app/showcase/proposals/services/open-proposal.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { debounceTime, first, map, switchMap, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'app-search-seller-dialog',
  templateUrl: './search-seller-dialog.component.html',
  styleUrls: ['./search-seller-dialog.component.scss']
})
export class SearchSellerDialogComponent implements OnInit {
  @Input() selected;
  @Input() routedBy;
  searchForm: FormGroup;
  submitted = false;
  shopId$: Observable<any> = this.store$.select('tabs').pipe(map(tab => tab.selectedTab.id));
  salesmanSelected: any = [];

  sellers: any = [];

  @Input() category = window.location.href;
  readonly Insert: Tag = Tag.Insert;
  constructor(
    public activeModal: NgbActiveModal,
    private proposalService: OpenProposalService,
    private paymentsService: PaymentsService,
    private formBuilder: FormBuilder,
    private genTagger: GenTagger,
    private store$: Store<AppState>,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'filtrar vendedores',
      event_action: Tag.AbriuModal + ' - filtrar vendedores'
    });

    this.searchForm = this.formBuilder.group({
      search: ['', Validators.required]
    });

    if (this.routedBy === 'open-proposal') {
      this.searchSellers();
    }

    if (this.routedBy === 'payments') {
      this.shopId$
        .pipe(
          first(),
          switchMap(id => this.paymentsService.getSellers(id, ''))
        )
        .subscribe(searchSellers => {
          this.sellers = searchSellers.map(obj => ({ ...obj, checked: false }));
        });
    }

    if (this.selected.length) {
      this.salesmanSelected = this.selected;
    }

    this.searchForm
      .get('search')
      .valueChanges.pipe(
        debounceTime(400),
        withLatestFrom(this.shopId$)
      )
      .subscribe(([newValue, shopId]) => {
        if (newValue != null && (newValue.length >= 3 || !newValue)) {
          this.genTagger.setTag({
            event_category: this.category,
            event_label: 'nome do vendedor',
            event_action: Tag.Pesquisar + ' - ' + newValue
          });

          this.proposalService.getSellers(shopId, newValue).subscribe(searchSellers => {
            this.sellers = searchSellers.map(obj => ({ ...obj, checked: false }));
          });
        }
      });
  }

  searchSellers(): void {
    this.shopId$
      .pipe(
        first(),
        switchMap(id => this.proposalService.getSellers(id, ''))
      )
      .subscribe(searchSellers => {
        this.sellers = searchSellers.map(obj => ({ ...obj, checked: false }));
        this.inicializeCheckedRecentSellers();
      });
  }

  inicializeCheckedRecentSellers() {
    if (this.salesmanSelected.length) {
      const seletecRecentSellers = this.salesmanSelected.filter(seller => seller.checked);
      seletecRecentSellers.forEach(sellerSelected => {
        this.sellers.forEach(seller => {
          if (seller.id === sellerSelected.id) seller.checked = sellerSelected.checked;
        });
      });
    }
  }

  public selectOption(event: MatCheckboxChange, label?: string): void {
    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'Vendedor',
      event_action: `${Tag.Selecionar} - ${this.translateService.instant(label)}`
    });
    if (event.checked) {
      const gotIndex = this.sellers.findIndex(x => x.id === event.source.value);
      this.sellers[gotIndex].checked = true;

      const verifyBeforePush = this.salesmanSelected.findIndex(x => x.id === event.source.value);
      if (verifyBeforePush < 0) this.salesmanSelected.push(this.sellers[gotIndex]);
    } else {
      const i = this.salesmanSelected.findIndex(x => x.id === event.source.value);
      this.salesmanSelected.splice(i, 1);
    }
  }

  public clearFilters() {
    this.salesmanSelected = [];
    this.sellers = this.sellers.map(obj => ({ ...obj, checked: false }));
    this.activeModal.dismiss(0);
  }

  public onSubmit() {
    if (this.salesmanSelected.length) {
      this.activeModal.close(this.salesmanSelected);
    }
  }

  clearSearchSellers(): void {
    this.searchForm.get('search').setValue('');
  }
}
