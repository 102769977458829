import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-splash',
  templateUrl: './splash.component.html',
  styleUrls: ['./splash.component.scss']
})
export class SplashComponent implements OnInit {
  constructor() {}
  readonly category = '/portallojista/login';
  ngOnInit() {}

  public redirectIdentification(): void {
    const url = 'https://www.santander.com.br/form/credenciamento';
    window.open(url, '_blank');
  }
}
