import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { AppState } from '@app/core/state';
import * as challenge from '@app/core/state/challenge';
import { Store } from '@ngrx/store';
import { Countries } from '@shared/constants/countries.enum';
import { ChallengeService } from '@shared/services/challenge/challenge.service';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent {
  public currentCountry: Countries = this.internationalizationService.currentCountry;

  constructor(
    private store$: Store<AppState>,
    private internationalizationService: InternationalizationService,
    private challengeService: ChallengeService,
    public loc: Location
  ) {
    this.store$.dispatch(new challenge.actions.GetChallenge({ refresh: false }));
  }

  public handlePasswordEmit(password: string): void {
    this.store$.dispatch(new challenge.actions.AnswerChallenge({ password }));
  }

  goback() {
    this.challengeService.goback().subscribe(res => {
      this.loc.back();
    });
  }
}
