<div class="card">
  <div class="mb-2 card-body">
    <div class="title">
      {{ 'FORMALIZATION-CAROUSEL-STEP-1-TITLE-MOBILE-LOJ' | translate }}
    </div>

    <div class="col-12 img">
      <img src="./assets/images/biometry_face.svg" alt="card image" />
    </div>

    <div class="col-12 description">
      <span innerHTML="{{ 'FORMALIZATION-CAROUSEL-STEP-1-NEW-SUBTITLE-MOBILE-LOJ' | translate }}"></span>
    </div>
  </div>
</div>
