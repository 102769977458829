import { HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import * as fromLogin from '@app/core/state/login';
import { Store } from '@ngrx/store';
import { TutorialModalComponent } from '@shared/components/tutorial-modal/tutorial-modal.component';
import { AddRequestAction, RemoveRequestAction } from '@shared/components/widgets/loading/store/loading.actions';
import { debounceTime } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TutorialService {
  constructor(private dialog: MatDialog, private router: Router, private store: Store<any>) { }

  showTutorial(tutorialPage: 'menu' | 'proposal' | 'payment' | 'simulation' | 'formalization') {
    // Show each tutorial modal at most once per session
    this.store.dispatch(new fromLogin.actions.SetTutorialFlagToFalse(tutorialPage));

    let modalTitle;
    let modalDescription;
    let url;
    let payload;

    switch (tutorialPage) {
      case 'menu':
        modalTitle = 'TOUR-TUTORIAL-MODAL-MENU-TITLE';
        modalDescription = 'TOUR-TUTORIAL-MODAL-MENU-DESCRIPTION';
        url = 'tutorials/menu';
        payload = {
          showTutorial: false
        };

        break;
      case 'proposal':
        modalTitle = 'TOUR-TUTORIAL-MODAL-PROPOSAL-TITLE';
        modalDescription = 'TOUR-TUTORIAL-MODAL-PROPOSAL-DESCRIPTION';
        url = 'tutorials/showcase';
        payload = {
          showTutorialPrt: false
        };

        break;
      case 'payment':
        modalTitle = 'TOUR-TUTORIAL-MODAL-PAYMENT-TITLE';
        modalDescription = 'TOUR-TUTORIAL-MODAL-PAYMENT-DESCRIPTION';
        url = 'tutorials/payment';
        payload = {
          showTutorialPgto: false
        };
        break;
      case 'simulation':
        modalTitle = 'TOUR-TUTORIAL-MODAL-SIMULATION-TITLE';
        modalDescription = 'TOUR-TUTORIAL-MODAL-SIMULATION-DESCRIPTION';
        url = 'tutorials/simulation';
        payload = {
          showTutorialSmula: false
        };
        break;
      case 'formalization':
        modalTitle = 'TOUR-TUTORIAL-MODAL-FORMALIZATION-TITLE';
        modalDescription = 'TOUR-TUTORIAL-MODAL-FORMALIZATION-DESCRIPTION';
        url = 'tutorials/formalization';
        payload = {
          showTutorialForml: false
        };
        break;
    }

    setTimeout(() => {
      this.openTutorial(modalTitle, modalDescription, url, payload);
    }, 200);
  }

  private openTutorial(modalTitle: string, modalDescription: string, url: string, payload?: any) {
    this.dialog
      .open(TutorialModalComponent, {
        autoFocus: false,
        role: 'dialog',
        maxWidth: '500px',
        id: 'tourTutorialModal',
        data: { title: modalTitle, description: modalDescription }
      })
      .afterClosed()
      .pipe(debounceTime(1))
      .subscribe((result: { isNeverAsk: boolean; startTutorial: boolean }) => {
        if (result) {
          if (result.isNeverAsk && payload) {
            this.store.dispatch(new fromLogin.actions.UpdateUserFlags(payload));
          }

          if (result.startTutorial) {
            // show loader block-ui
            let UID = 0;
            const fakeRequest = new HttpRequest('GET', 'navigate/tutorials');
            // tslint:disable-next-line: no-shadowed-variable
            const payload = {
              id: `UID-${UID++}`,
              request: fakeRequest
            };
            // close all dialogs (fix overlay bug on tutorial pages)
            this.dialog.closeAll();

            this.store.dispatch(new AddRequestAction(payload));

            // navigate and remove loader
            this.router.navigateByUrl(url).then(() => this.store.dispatch(new RemoveRequestAction(payload)));
          }
        }
      });
  }
}
