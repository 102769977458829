import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TokenResponse } from '@app/core/startup/services/startup.service';
import { AppState } from '@app/core/state';
import { selectLoginForm } from '@app/core/state/login/login.selectors';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { INIT_USER_QUERY } from '@shared/graphql/queries/config.query';
import { DocumentTypeService } from '@shared/services/document-type/document-type.service';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { Apollo } from 'apollo-angular';
import { Observable, of, throwError } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';

export enum UserCategory {
  MASTER = 'MASTER',
  PROFILE_PANEL = 'PRF_PAINEL',
  PORTAL_SALESMAN = 'VENDEDOR_PORTAL',
  FINANCE_PROFILE = 'FINANCEIRO_PORT'
}


@Injectable({
  providedIn: 'root'
})
export class LoginService {
  public dataUser = this.sessionStorageService.getUser();

  get mfa(): boolean {
    try {
      if (JSON.parse(environment.mfa.active as any)) {
        return JSON.parse(environment.mfa.loj as any)
      }

      return false
    } catch (error) {
      console.log('MFA ERROR: ', error);
      return false;
    }
  }

  constructor(
    private http: HttpClient,
    private sessionStorageService: SessionStorageService,
    private readonly apollo: Apollo,
    private readonly dialog: MatDialog,
    private documentService: DocumentTypeService,
    private store$: Store<AppState>
  ) { }

  /**
   * This function is responsible to get information from the backend to customize the user experience
   */
  public getUserUIConfigs(supplierChannelCode: string) {
    return this.apollo
      .watchQuery<any>({
        query: INIT_USER_QUERY,
        variables: {
          channelAccessCode: 'LOJISTA',
          supplierChannelCode // store code
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: any) => {
          return response.data.initRenderQuery;
        })
      );
  }

  login(loginForm: any): Observable<TokenResponse> {
    if (JSON.parse(environment.newGateway)) {
      return this.loginNewGateway(loginForm);
    } else {
      return this.loginWSO2(loginForm);
    }
  }

  loginWithStore(loginForm: any) {
    return this.store$.select(selectLoginForm).pipe(
      first(),
      switchMap((_loginForm: any) => this.login({ ...loginForm, ..._loginForm }))
    );
  }

  getStores(loginForm: any) {
    return this.documentService.getDocumentTypeByNumber(loginForm.document).pipe(
      switchMap((dcType: any) => {
        const body = {
          username: loginForm.document,
          password: loginForm.password,
          loginTypeId: dcType?.loginTypeId,
          enableLoggingRequestDetails: false,
          businessCode: environment.businessCode,
        };
        return this.http.post<any>(`${environment.apiRootUrl}/shopkeeper-login/stores/login/stores`, body);
      })
    );
  }

  loginWSO2(loginForm: any): Observable<TokenResponse> {

    let body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', loginForm.documentNumber)
      .set('password', loginForm.password)
      .set('loginTypeId', '3')
      .set('businessCode', environment.businessCode)
      .set('pioneerApp', 'true');

    if (loginForm.revokeSession) {
      body = body.append('revokeSession', 'true');
    }

    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Basic ' + environment.apiKeyApiSecret,
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    if (this.mfa) {
      return this.http.post<TokenResponse>(`${environment.apiRootUrl}/token`, body, httpOptions).pipe(
        switchMap(res => {
          if (loginForm.storeId) {
            body = body.append('storeId', loginForm.storeId ? loginForm.storeId : null);
            return this.http.post<TokenResponse>(`${environment.apiRootUrl}/token`, body, httpOptions);
          }
          if (res?.auth2Data?.is2Auth) {
            return of(res);
          }

          body = body.append('storeId', loginForm.storeId ? loginForm.storeId : null);
          return this.http.post<TokenResponse>(`${environment.apiRootUrl}/token`, body, httpOptions);
        })
      );
    }

    body = body.append('storeId', loginForm.storeId ? loginForm.storeId : null);

    return this.http.post<TokenResponse>(`${environment.apiRootUrl}/token`, body, httpOptions);
  }

  loginNewGateway(loginForm: any): Observable<TokenResponse> {
    return this.documentService.getDocumentTypeByNumber(loginForm.documentNumber).pipe(
      switchMap(dcType => {
        let body = new HttpParams()
          .set('grant_type', 'password')
          .set('username', loginForm.documentNumber)
          .set('password', loginForm.password)
          .set('loginTypeId', dcType.loginTypeId)
          .set('businessCode', environment.businessCode)
          .set('client_id', environment.kc_loj_clientid)
          .set('x-client-id', environment.kc_loj_clientid);

        if (loginForm.revokeSession) {
          body = body.append('revokeSession', 'true');
        }
        if (loginForm.storeId) {
          body = body.append('storeId', loginForm.storeId);
        }

        return this.http
          .post<TokenResponse>(`/lgn/realms/${environment.keycloak_realm}/protocol/openid-connect/token`, body)
          .pipe(
            catchError(loginError => {
              return this.http
                .post<TokenResponse>(`${environment.apiRootUrl}/shopkeeper-login/stores/login/bad-credentials`, {
                  username: loginForm.documentNumber,
                  loginTypeId: dcType?.loginTypeId,
                  businessCode: environment.businessCode
                })
                .pipe(
                  switchMap(error =>
                    throwError({
                      status: loginError.status,
                      error
                    })
                  )
                );
            })
          );
      })
    );
  }

  resendCodeMFA({ documentNumber, password }): Observable<any> {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + environment.apiKeyApiSecret
    });


    let body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', documentNumber)
      .set('password', password)
      .set('pioneerApp', 'true')
      .set('loginTypeId', '3')
      .set('businessCode', environment.businessCode)
      .set('validationCode', '0')
      .set('sendEmail', 'true');

    return this.http.post(environment.apiRootUrl + '/token', body, { headers: header });
  }

  validationMFA({ documentNumber, password }, validationCode) {
    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Authorization: 'Basic ' + environment.apiKeyApiSecret
    });


    let body = new HttpParams()
      .set('grant_type', 'password')
      .set('username', documentNumber)
      .set('password', password)
      .set('loginTypeId', '3')
      .set('businessCode', environment.businessCode + validationCode)
      .set('pioneerApp', 'true')
      .set('storeId', null);

    return this.http.post(environment.apiRootUrl + '/token', body, { headers: header });
  }

  getLoginIBDetails(documentNumber: string): Observable<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        url: document.URL,
        'x-url-success': location.href,
        'x-url-error': location.href
      })
    };
    // localStorage.setItem('documentNumberKey', documentNumber);
    return this.http
      .get(`${environment.apiRootUrl}/loginib/1.0.0/client/${documentNumber}/configuration`, httpOptions)
      .pipe(
        catchError(err => {
          this.dialog.open(PionModalComponent, {
            data: {
              title: 'Login indisponível',
              description:
                'Não foi possível completar seu login pelo Internet Banking neste momento, tente novamente mais tarde.',
              confirmText: 'OK',
              cancelText: null,
              type: 'alert'
            },
            maxWidth: '500px'
          });
          return of(null);
        })
      );
  }

  getLoginIBToken({ documentNumber, loginIbToken }): Observable<any> {
    const payload = {
      token: loginIbToken,
      documentNumber: documentNumber,
      documentType: 'CPF'
    };
    return this.http.post(`${environment.apiRootUrl}/loginib/1.0.0/client/login/ib`, payload);
  }

  logout() {
    return this.http.post(`${environment.apiRootUrl}/shopkeeper-login/1.0.0/stores/logout`, {});
  }
}
