import { Location } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AppState } from '@app/core/state';
import * as challenge from '@app/core/state/challenge';
import { challengeSelect, getTerms } from '@app/core/state/challenge/challenge.selectors';
import { SignUpUserData } from '@app/signup/models/sign-up.model';
import { SignUpService } from '@app/signup/services/sign-up.service';
import { SaveUserDataAction } from '@app/signup/state/signup.actions';
import { SignUpState } from '@app/signup/state/signup.reducer';
import { selectUserData } from '@app/signup/state/signup.selectors';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import { LgpdFormComponent } from '@shared/components/lgpd-form/lgpd-form.component';
import { Countries } from '@shared/constants/countries.enum';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { BrazilValidators } from '@shared/interfaces/validators/brazil-validators.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { LgpdConsentAccessType } from '@shared/models/lgpd-consent.enum';
import { ChallengeService } from '@shared/services/challenge/challenge.service';
import { DocumentTypeService } from '@shared/services/document-type/document-type.service';
import { FormValidationService } from '@shared/services/form-validation/form-validation.service';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sign-up-data',
  templateUrl: './sign-up-data.component.html',
  styleUrls: ['./sign-up-data.component.scss']
})
export class SignUpDataComponent implements OnInit, OnDestroy {
  @ViewChild('terms') terms: ElementRef;
  @ViewChild('lgpdForm', { static: true }) lgpdForm: LgpdFormComponent;
  public signUpForm: FormGroup;
  public term: string;
  public termId: any = null;
  public currentCountry: Countries = this.internationalizationService.currentCountry;
  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  public documentNumberMask: string;
  public scrollComplete = false;
  public setDocumentNumber: string;
  public isAccountHolder: boolean;
  LgpdConsentAccessType = LgpdConsentAccessType;
  lgpdSuccessLoaded = false;
  protected ngUnsubscribe: Subject<any> = new Subject();
  lgpdFormIsValid = false;
  flagRegisterForm = false;

  constructor(
    private store$: Store<SignUpState>,
    private store: Store<AppState>,
    private fb: FormBuilder,
    private validationService: FormValidationService,
    private internationalizationService: InternationalizationService,
    private signupService: SignUpService,
    public loc: Location,
    private documentService: DocumentTypeService,
    private challengeService: ChallengeService
  ) {}
  // this.challengeService.getChallenge(action.payload)
  ngOnInit(): void {
    console.log('ingreso !!!!');

    this.createForm();
    this.loadFormFromState();
    this.renderDataSubscription();
    this.store$.dispatch(new challenge.actions.GetChallenge({ refresh: false }));
    this.store
      .pipe(select(challengeSelect))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res.getChallenge && res.getChallenge.challenges.registerForm.isAccountHolder) {
          this.isAccountHolder = res.getChallenge.challenges.registerForm.isAccountHolder;
          this.signUpForm.controls.documentNumber.setErrors(null);
          this.signUpForm.controls.nickName.setErrors(null);
          this.signUpForm.controls.email.setErrors(null);
          this.signUpForm.controls.cellPhone.setErrors(null);
          this.signUpForm.controls.fullName.setErrors(null);
          this.signUpForm.controls.dateOfBirth.setErrors(null);
          this.signUpForm.updateValueAndValidity();
        }
      });
  }

  public handleTermsAndAgreementsCheckboxChange(checked: boolean): void {
    const termsControl = this.signUpForm.get('acceptTerms');
    checked ? termsControl.patchValue(checked) : termsControl.reset(null);
  }

  public submit(): void {
    if (this.signUpForm.invalid) return;
    const documentNumber = this.signUpForm.get('documentNumber').value;
    this.documentService
      .getDocumentTypeByNumber(documentNumber)
      .pipe(first())
      .subscribe(documentType => {
        let payload = <SignUpUserData>{
          businessCode: environment.businessCode,
          documentNumber: this.signUpForm.get('documentNumber').value,
          documentType: documentType.personType,
          firstName: '',
          fullName: this.signUpForm.get('fullName').value,
          middleName: '',
          lastName: '',
          nickName: this.signUpForm.get('nickName').value,
          dateOfBirth: this.isAccountHolder
            ? null
            : moment(this.signUpForm.get('dateOfBirth').value, 'DD/MM/YYYY')
                .toDate()
                .toISOString(),
          email: this.signUpForm.get('email').value,
          cellPhone: this.signUpForm.get('cellPhone').value,
          acceptTerms: this.signUpForm.get('acceptTerms').value,
          termId: this.termId || null,
          idLoginType: 3,
          idDocumentType: documentType.documentId,
          isAccountHolder: this.isAccountHolder
        };

        if (this.lgpdSuccessLoaded) {
          this.lgpdForm.saveConsent(this.signUpForm.get('documentNumber').value, this.signUpForm.get('email').value);
        }
        this.store$.dispatch(new challenge.actions.AnswerChallenge({ registerForm: payload }));

        if (this.lgpdSuccessLoaded) {
          payload = {
            ...payload,
            isSaveLgpd: this.lgpdSuccessLoaded,
            answerCompanyOffer: this.lgpdForm.answerCompanyOffer,
            answerPartnerOffer: this.lgpdForm.answerPartnerOffer,
            answerGroupOffer: this.lgpdForm.answerGroupOffer
          };
        }

        this.store$.dispatch(new SaveUserDataAction(payload));
      });
  }

  omitSpecialChar(event) {
    const keyPressed = String.fromCharCode(event.keyCode);
    const verifyKeyPressed = /^[a-zA-Z\' \u00C0-\u00FF]*$/.test(keyPressed);
    return verifyKeyPressed === true;
  }

  public handleScroll(event: any): void {
    const target = event.target;
    if (target.scrollTop + target.offsetHeight > target.scrollHeight - 1) {
      this.scrollComplete = true;
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  onLgpdLoaded(success: boolean) {
    this.lgpdSuccessLoaded = success;
    if (!this.lgpdSuccessLoaded) {
      this.lgpdFormIsValid = true;
      return;
    }

    if (
      this.lgpdForm.answerCompanyOffer !== 'S' &&
      this.lgpdForm.answerGroupOffer !== 'S' &&
      this.lgpdForm.answerPartnerOffer !== 'S'
    ) {
      this.lgpdFormIsValid = false;
    }
  }

  public onFormLgpdAnswered() {
    this.lgpdFormIsValid = true;
  }

  private createForm(): void {
    const validatorsBr = this.pioneerValidators as BrazilValidators;

    this.signupService.getDocumentInput.subscribe((res: string) => {
      console.log(res);
      this.setDocumentNumber = res;
    });

    this.signUpForm = this.fb.group({
      documentNumber: new FormControl(
        { value: this.setDocumentNumber, disabled: true },
        this.pioneerValidators.documentNumber
      ),
      nickName: new FormControl('', validatorsBr.nickName),
      email: new FormControl('', validatorsBr.email),
      cellPhone: new FormControl('', validatorsBr.cellPhone),
      acceptTerms: new FormControl('', validatorsBr.acceptTerms),
      fullName: new FormControl('', validatorsBr.fullName),
      dateOfBirth: new FormControl('', validatorsBr.dateOfBirth)
    });
  }

  private renderDataSubscription(): void {
    this.store
      .pipe(select(getTerms))
      .pipe(filter(item => !!item))
      .subscribe(res => {
        if (res && res.challenges && res.challenges.registerForm) {
          this.isAccountHolder = res.challenges.registerForm.isAccountHolder;
        }
        if (res && res.challenges && res.challenges.term) {
          this.terms = res.challenges.term.content;
          this.termId = res.challenges.term.termId;
        }
      });
  }

  private loadFormFromState() {
    this.store$
      .select(selectUserData)
      .pipe(first())
      .subscribe(userData => {
        console.log(userData);
        if (userData) {
          this.scrollComplete = true;
          this.signUpForm.patchValue(userData);
          this.signUpForm.controls.dateOfBirth.patchValue(
            moment(userData.dateOfBirth, 'YYYY-MM-DD').format('DD-MM-YYYY')
          );
          this.signUpForm.controls.dateOfBirth.updateValueAndValidity();
          this.lgpdForm.answerGroupOffer = userData.answerGroupOffer;
          this.lgpdForm.answerCompanyOffer = userData.answerCompanyOffer;
          this.lgpdForm.answerPartnerOffer = userData.answerPartnerOffer;
        }
      });
  }
}
