<div>
  <div class="form-row">
    <div class="form-group col-md-9">
      <p class="desktop d-none d-md-block title">{{ 'USERS-MANAGEMENT' | translate }}</p>
    </div>
    <div *ngIf="window.screen.width >= 768" class="form-group col-md-3">
      <button
        type="button"
        class="action-button mt-0"
        (click)="newUserClick()"
        appTagger
        [event_category]="category"
        event_label="{{ 'New User' | translate }}"
      >
        <img src="./assets/santander/icons/ic_circle_add.svg" class="mr-2" alt="close" />
        {{ 'New User' | translate }}
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-md-7">
      <div class="search-form">
        <div class="search-wrapper">
          <button type="button" (click)="searchUsers()">
            <img src="./assets/images/ic_search.svg" class="esquerda" alt="search" />
          </button>
          <input
            type="text"
            placeholder="Busque por CPF ou nome"
            [formControl]="search"
            appTagger
            [event_category]="category"
            event_label="Busque por CPF ou nome"
            [event_action]="Insert"
          />
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <button
        class="btn btn-light"
        (click)="open(confirmChanges)"
        appTagger
        [event_category]="category"
        event_label="{{ 'Filter' | translate }}"
      >
        <img src="./assets/santander/icons/noun_filter.svg" alt="filter" />
        {{ 'Filter' | translate }}
      </button>
    </div>
    <div class="col-md-3 mt-2">{{ 'SHOWING' | translate }}: {{ totalElements }} {{ 'Users' | translate }}</div>
  </div>

  <div class="mt-4" *ngIf="window.screen.width >= 768; else mobile">
    <div class="table-responsive">
      <table class="table table-borderless table-responsive-md">
        <thead class="">
          <th scope="col" class="text-nowrap w-2"></th>
          <th scope="col" class="text-nowrap">{{ 'TYPE' | translate }}</th>
          <th scope="col" class="text-nowrap">{{ 'User' | translate }}</th>
          <th scope="col" class="text-nowrap">{{ 'Perfis' | translate }}</th>
          <th scope="col" class="text-nowrap"></th>
          <th scope="col" class="text-nowrap w-2"></th>
        </thead>
        <tbody>
          <tr *ngFor="let user of users; let i = index">
            <td class="text-nowrap">
              <img
                *ngIf="user.active === 'S'"
                src="./assets/green_check.svg"
                placement="bottom"
                ngbTooltip="Status ativo"
                alt="close"
                class="user-status"
                appTagger
                [event_category]="category"
                event_label="ajuda - status"
              />
              <img
                *ngIf="user.active === 'N'"
                src="./assets/error.svg"
                placement="bottom"
                ngbTooltip="Status inativo"
                alt="close"
                class="user-status"
                appTagger
                [event_category]="category"
                event_label="ajuda - status"
              />
            </td>
            <td class="text-nowrap">
              <div class="inline">
                <div class="square">
                  <div class="block">
                    <div
                      placement="bottom"
                      ngbTooltip="Master"
                      class="user-icon master"
                      *ngIf="user.type === 'M'"
                      appTagger
                      [event_category]="category"
                      event_label="ajuda- tipo"
                    >
                      <strong>
                        <p>MAS</p>
                      </strong>
                    </div>
                    <div
                      placement="bottom"
                      ngbTooltip="Usuário"
                      class="user-icon user"
                      *ngIf="user.type === 'U'"
                      appTagger
                      [event_category]="category"
                      event_label="ajuda- tipo"
                    >
                      <strong>
                        <p>USU</p>
                      </strong>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <td class="text-nowrap text-left col-profile">
              <div class="user-details" (mouseenter)="showDocument[i] = true" (mouseleave)="showDocument[i] = false">
                <span class="user-name" *ngIf="!showDocument[i]">{{ user.name }}</span>
                <span class="user-code" *ngIf="showDocument[i]">{{
                  user.documentNumber | mask: '000.000.000-00'
                }}</span>
              </div>
            </td>
            <td class="text-nowrap text-left">
              <img
                *ngIf="user.profile.vendedor"
                src="./assets/santander/icons/vendedor.svg"
                alt="vendedor"
                placement="bottom"
                ngbTooltip="Vendedor"
                appTagger
                [event_category]="category"
                event_label="ajuda - perfil"
              />
              <img
                *ngIf="user.profile.representante"
                src="./assets/santander/icons/revendedor.svg"
                alt="revendedor"
                placement="bottom"
                ngbTooltip="Representante"
                appTagger
                [event_category]="category"
                event_label="ajuda - perfil"
              />
              <img
                *ngIf="user.profile.financeiro"
                src="./assets/santander/icons/fiscal.svg"
                alt="fiscal"
                placement="bottom"
                ngbTooltip="Financeiro"
                appTagger
                [event_category]="category"
                event_label="ajuda - perfil"
              />
            </td>
            <td class="text-nowrap">
              <button
                type="button"
                class="edit-button mt-0"
                (click)="updateUserClick(user.id)"
                [disabled]="!user.editable"
                appTagger
                [event_category]="category"
                event_label="{{ 'Edit' | translate }} - {{ user.id }} "
              >
                <img src="./assets/santander/icons/ic_edit.svg" alt="edit" />
                {{ 'Edit' | translate }}
              </button>
            </td>
            <td class="text-nowrap">
              <button
                type="button"
                class="simple-button mt-0"
                [disabled]="user.type === 'M'"
                (click)="removeUser(user.id, user.name)"
                appTagger
                [event_category]="category"
                event_label="excluir - {{ user.id }}"
              >
                <img src="./assets/santander/icons/ic_delete.svg" alt="delete" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <ngb-pagination
      [collectionSize]="totalElements"
      [(page)]="page"
      (pageChange)="loadPage($event); onClick($event)"
      [boundaryLinks]="false"
      [pageSize]="perPage"
      size="sm"
    >
      <ng-template
        ngbPaginationPrevious
        appTagger
        [event_category]="category"
        event_label="página - {{ 'Previous' | translate }}"
      >
        <span class="arrow-icon icon-prev"></span>
        {{ 'Previous' | translate }}
      </ng-template>
      <ng-template
        ngbPaginationNext
        appTagger
        [event_category]="category"
        event_label="página - {{ 'Next' | translate }}"
      >
        {{ 'Next' | translate }}
        <span class="arrow-icon icon-next"></span>
      </ng-template>
    </ngb-pagination>
  </div>
  <div class="mt-4">
    <div class="container">
      <ng-template #mobile>
        <div *ngFor="let user of users">
          <hr class="my-0 mb-2" />
          <div class="row no-gutters">
            <div class="col">
              <users-card [data]="user"></users-card>
            </div>

            <div class="col-1 mt-2">
              <div *ngIf="window.screen.width < 768" class="actions-menu" [matMenuTriggerFor]="menu">
                <mat-icon appTagger [event_category]="category" event_label="opcões do usuário">more_vert</mat-icon>
                <mat-menu #menu="matMenu">
                  <ng-template [ngIf]="user.type !== 'M' || user.editable">
                    <button
                      mat-menu-item
                      (click)="removeUser(user.id, user.name)"
                      appTagger
                      [event_category]="category"
                      event_label="{{ 'DELETE' | translate }} - {{ user.id }}"
                    >
                      {{ 'DELETE' | translate }}
                    </button>
                  </ng-template>
                  <ng-template [ngIf]="user.type !== 'M' || user.editable">
                    <mat-divider></mat-divider>
                    <button
                      mat-menu-item
                      (click)="updateUserClick(user.id)"
                      appTagger
                      [event_category]="category"
                      event_label="{{ 'Edit' | translate }} - {{ user.id }}"
                    >
                      {{ 'Edit' | translate }}
                    </button>
                  </ng-template>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div *ngIf="window.screen.width < 768" class="form-group col-md-3">
    <hr class="my-0 mb-2" />
    <button type="button" class="action-button mt-0" (click)="newUserClick()">
      <img src="./assets/santander/icons/ic_circle_add.svg" alt="close" />
      {{ 'New User' | translate }}
    </button>
  </div>
</div>

<ng-template #confirmChanges let-c="close" let-d="dismiss" class="modal" event_label="" Filtrar usuários>
  <div class="modal-header">
    <p class="title" id="modal-basic-title">{{ 'FILTER_USERS' | translate }}</p>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="d('Cross click')"
      appTagger
      [event_category]="category"
      event_label="X"
      event_action="Fechou modal - Filtrar usuários"
    >
      <img src="./assets/ic_close.svg" alt="filter" />
    </button>
  </div>
  <hr />
  <div class="modal-body">
    <div class="form-group">
      <div [ngClass]="window.screen.width >= 768 ? 'row mb-5 mt-5' : 'row mb-1 mt-1'">
        <div class="col-md-3">
          <strong
            ><label>{{ 'Status' | translate }}</label></strong
          >
        </div>
        <hr />
        <div class="col-md-3">
          <mat-checkbox color="primary" [(ngModel)]="ativo" name="{{ ativo }}" (click)="tag('ACTIVE')">{{
            'ACTIVE' | translate
          }}</mat-checkbox>
        </div>
        <div class="col-md-3">
          <mat-checkbox color="primary" [(ngModel)]="inativo" name="{{ inativo }}" (click)="tag('INACTIVE')">{{
            'INACTIVE' | translate
          }}</mat-checkbox>
        </div>
      </div>
      <hr />
      <div [ngClass]="window.screen.width >= 768 ? 'row mb-5 mt-5' : 'row mb-1 mt-1'">
        <div class="col-md-3">
          <strong
            ><label>{{ 'TYPE' | translate }}</label></strong
          >
        </div>
        <div class="col-md-3">
          <mat-checkbox color="primary" [(ngModel)]="master" name="{{ master }}" (click)="tag('MASTER')">{{
            'MASTER' | translate
          }}</mat-checkbox>
        </div>
        <div class="col-md-3">
          <mat-checkbox color="primary" [(ngModel)]="colaborador" name="{{ colaborador }}" (click)="tag('Colaborador')">
            {{ 'Colaborador' | translate }}</mat-checkbox
          >
        </div>
      </div>
      <hr />
      <div [ngClass]="window.screen.width >= 768 ? 'row mb-5 mt-5' : 'row mb-1 mt-1'">
        <div class="col-md-3">
          <strong
            ><label>{{ 'Perfis' | translate }}</label></strong
          >
        </div>
        <div class="col-md-3">
          <mat-checkbox color="primary" [(ngModel)]="vendedor" name="{{ vendedor }}" (click)="tag('SELLER')"
            >{{ 'SELLER' | translate }}
          </mat-checkbox>
        </div>
        <div class="col-md-3">
          <mat-checkbox
            color="primary"
            [(ngModel)]="representante"
            name="{{ representante }}"
            (click)="tag('Representative')"
            >{{ 'Representative' | translate }}</mat-checkbox
          >
        </div>
        <div class="col-md-3">
          <mat-checkbox color="primary" [(ngModel)]="financeiro" name="{{ financeiro }}" (click)="tag('FINANCIAL')"
            >{{ 'FINANCIAL' | translate }}
          </mat-checkbox>
        </div>
      </div>
      <hr />
    </div>
  </div>
  <div class="modal-footer right-itens">
    <button
      type="button"
      class="btn button-white"
      (click)="clearFilters(); c('Close click')"
      appTagger
      [event_category]="category"
      event_label="{{ 'CLEAR_FILTERS' | translate }}"
    >
      {{ 'CLEAR_FILTERS' | translate }}
    </button>
    <button
      type="button"
      class="btn button-red"
      (click)="searchUsers(); c('Close click')"
      appTagger
      [event_category]="category"
      event_label="{{ 'APPLY' | translate }}"
    >
      {{ 'APPLY' | translate }}
    </button>
  </div>
</ng-template>
