import { Directive, HostListener, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';
import { IdleDetectService } from '@shared/services/idle-detect/idle-detect.service';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { filter, first, map, mergeMap } from 'rxjs/operators';

@Directive({
  selector: '[appIdleDetect]'
})
export class IdleDetectDirective implements OnInit {
  // Time to logout
  readonly TIMER = Number(environment.idleTimer) || 60000;
  shouldWatch = false;

  currentUrl;

  constructor(
    private idleDetectService: IdleDetectService,
    private router: Router,
    private activeRouter: ActivatedRoute,
    private dialog: MatDialog,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit() {
    // colocar aqui o tempo do logout 1 minuto = 60000 milisegudos
    // this.idleDetectService.startTimer(this.TIMER);
    this.watchIdle();
    this.watchCurrentRoute();
    this.watchModal();
  }

  /**
   * Responsible to st]art watch the idle
   */
  watchIdle() {
    this.idleDetectService.watcher().subscribe(res => {
      if (res) {
        this.dialog.closeAll();
        this.dialog.afterAllClosed.pipe(first()).subscribe(() => {
          setTimeout(() => {
            this.sessionStorageService.clearUser();
            this.router.navigateByUrl('/');
          }, 30);
        });

        this.idleDetectService.openComponents.forEach(() => {
          this.idleDetectService.viewContainer.clear();
        });
      }
    });
  }

  /**
   * Check if the current route should redirect to logout after the idle time
   * The shouldWatch parameter prevents the HostListeners to trigger the timer;
   */
  watchCurrentRoute() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activeRouter),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap(route => route.data)
      )
      .subscribe(currentRouteData => {
        this.currentUrl = this.router.url;
        if (!currentRouteData.disableLogoutTime) {
          this.shouldWatch = true;
          return this.idleDetectService.startTimer(this.TIMER);
        }
        return (this.shouldWatch = false);
      });
  }

  private watchModal() {
    this.dialog.afterOpened.subscribe(() => {
      if (this.currentUrl && this.currentUrl.includes('login')) {
        this.shouldWatch = true;
        this.idleDetectService.startTimer(this.TIMER);
      }
    });
    this.dialog.afterAllClosed.subscribe(() => {
      if (this.currentUrl && this.currentUrl.includes('login')) {
        this.shouldWatch = false;
        this.idleDetectService.stopTimer();
      }
    });
  }

  /**
   * Listeners on the user events for check the idle time;
   */

  @HostListener('keydown', ['$event'])
  onKeydown(event) {
    if (this.shouldWatch) {
      this.idleDetectService.resetTimer();
    }
  }

  @HostListener('mousemove', ['$event'])
  onMove(event) {
    if (this.shouldWatch) {
      this.idleDetectService.resetTimer();
    }
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    if (this.shouldWatch) {
      this.idleDetectService.resetTimer();
    }
  }

  @HostListener('touchstart', ['$event'])
  onTouch(event) {
    if (this.shouldWatch) {
      this.idleDetectService.resetTimer();
    }
  }
  @HostListener('contextmenu', ['$event'])
  onContextMenuClick(event) {
    if (this.shouldWatch) {
      this.idleDetectService.resetTimer();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWheelClick(event) {
    if (this.shouldWatch) {
      this.idleDetectService.resetTimer();
    }
  }
}
