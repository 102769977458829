import { HttpClient, HttpEvent, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { handleFileB64 } from '@shared/functions/files-handler';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { Observable } from 'rxjs';
import { PostArtifactDTOResponse } from '../models/api/post.artifact.model';
import { PostFileDTOResponse } from '../models/api/post.file.model';
import { ChecklistModel } from '../models/checklist.model';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  constructor(
    private httpClient: HttpClient,
    private sessionStorageService: SessionStorageService,
    ) {}

  public uploadDocument(
    item: ChecklistModel,
    type: string = '',
    name: string = ''
  ): Observable<HttpEvent<PostFileDTOResponse>> {
     const url = `${environment.apiRootUrl}/shopkeeper-biometry/1.0.0/checklist/${item.id}/uploadRest`;
    const payload = { ...item, arquivo: handleFileB64(item.file.data), type };
    delete payload.file;

    return this.httpClient.post<PostFileDTOResponse>(url, payload, {
      reportProgress: true,
      observe: 'events'
    });
  }

  public deleteFile(checklistId: string, fileId: string): Observable<any> {
    const url  = `${environment.apiRootUrl}/shopkeeper-biometry/checklist/${checklistId}/file/${fileId}`;
    return this.httpClient.delete(url);
  }

  public deleteAllFiles(checklistId: string): Observable<any> {
    const url = `${environment.apiRootUrl}/shopkeeper-biometry/1.0.0/checklist/${checklistId}/file`;
    return this.httpClient.delete(url);
  }

  public deleteArtifact(checklistId: string): Observable<any> {
    const url = `${environment.apiRootUrl}/shopkeeper-biometry/checklist/${checklistId}/artifact`;
    return this.httpClient.delete(url);
  }

  public finishArtifact(checklist: ChecklistModel): Observable<HttpResponse<PostArtifactDTOResponse>> {
    const url = `${environment.apiRootUrl}/shopkeeper-biometry/checklist/${checklist.id}/artifact`;
    const payload = { checklistId: checklist.id };

    return this.httpClient.post<PostArtifactDTOResponse>(url, payload, {
      reportProgress: true,
      observe: 'response'
    });
  }
}
