<div class="card shadow">
  <div class="card-body">
    <!-- CARROUSEL DE FORMALIZAÇÃO -->

    <!-- IMAGEM DO STEP -->
    <div class="col-12 img">
      <img [src]="img ? img : imgPlaceholder" alt="card image" />
    </div>
    <!-- FIM DA IMAGEM -->

    <!-- TÍTULO DO STEP -->
    <div class="col-12 p-0">
      <div class="title">
        <span>{{ title | translate }}</span>
      </div>
    </div>
    <!-- FIM TÍTULO DO STEP -->
    <!-- DESCRIÇÃO DO STEP -->
    <div class="col-12 p-0" *ngIf="description">
      <div class="description">
        <span>{{ description | translate }}</span>
      </div>
    </div>
    <!-- FIM- DESCRIÇÃO DO STEP -->

    <ng-content></ng-content>
  </div>
</div>
