import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { AppState } from '@app/core/state';
import { GetCitiesAction, GetStatesAction } from '@app/core/state/showcase/showcase.actions';
import { citiesList, statesList } from '@app/core/state/showcase/showcase.selectors';
import { Store } from '@ngrx/store';
import { CityModel } from '@shared/models/city.model';
import { StateModel } from '@shared/models/state.model';
import { Subject } from 'rxjs';
import { takeUntil, filter } from 'rxjs/operators';
import { FiltersModel } from '../../models/filters.model';

@Component({
  selector: 'app-stores-list-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {
  @Output() selectedFilters = new EventEmitter<FiltersModel>();
  protected ngUnsubscribe: Subject<any> = new Subject();
  public states: StateModel[] = [];
  public cities: CityModel[] = [];
  public filtersForm: FormGroup;

  constructor(private fb: FormBuilder, private store: Store<AppState>) {}

  ngOnInit() {
    this.createForm();
    this.getCurrentLocation();

    this.loadStates();
  }

  /**
   * Create form to filters list of stores
   */
  private createForm() {
    this.filtersForm = this.fb.group({
      state: [''],
      city: [''],
      searchTerm: ['']
    });
  }

  /**
   * Load states to options in select
   */
  loadStates() {
    this.store
      .select(statesList)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(val => !!val)
      )
      .subscribe(list => (this.states = Array.from(list)));

    this.store.dispatch(new GetStatesAction());
  }

  /**
   * Load cities to options in select
   * @param state State
   */
  loadCitiesByState(state: StateModel) {
    this.store
      .select(citiesList)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(val => !!val)
      )
      .subscribe(list => (this.cities = list));

    this.store.dispatch(new GetCitiesAction(state.code));
  }

  /**
   * Get current location of user
   */
  private getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        console.log(`${position.coords.latitude}, ${position.coords.longitude}`);
      });
    } else {
      console.log('Geolocation is not supported by this browser.');
    }
  }

  /**
   * Submit form to filters list of stores
   */
  onSubmit() {
    const filters: FiltersModel = this.filtersForm.value;
    this.selectedFilters.emit(filters);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
