import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TranslateService } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { Subject, Subscription } from 'rxjs';
import { filter, retry, take, takeUntil } from 'rxjs/operators';
import { ChecklistFileModel, ChecklistModel, ChecklistStatusEnum } from '../../models/checklist.model';
import { UploadService } from '../../service/upload.service';

@Component({
  selector: 'app-checklist-item',
  templateUrl: './checklist-item.component.html',
  styleUrls: ['./checklist-item.component.scss']
})
export class ChecklistItemComponent implements OnInit, OnChanges, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() checklistItem: ChecklistModel;
  @Input() maxSizeAllowed: string; // Value in MB
  @Input() isPendency = false;
  @Output() uploadFinished = new EventEmitter();
  @Input() category: string;

  readonly Insert: Tag = Tag.Insert;
  public files: ChecklistFileModel[] = [];
  public filesSuccess: ChecklistFileModel[] = [];
  public filesError: ChecklistFileModel[] = [];

  public uploadsInProgress: { [key: string]: Subscription } = {};

  public errorMessage = '';

  public disableAttachButton = false;
  public disableUploadButton = true;

  public showImageGallery = false;
  public showFileUploadModal = false;

  constructor(
    private uploadService: UploadService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private genTagger: GenTagger
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      this.checklistItem &&
      changes.checklistItem &&
      (this.checklistItem.status === ChecklistStatusEnum.INDEXED ||
        this.checklistItem.status === ChecklistStatusEnum.RELEASE_INTERNAL)
    ) {
      this.preloadIndexedArtifact();
    }
  }

  ngOnInit() {}

  /**
   * Method responsible for preloading the artifact as successfully created
   * when the checklistItem status is INDEXED
   */
  public preloadIndexedArtifact(): void {
    const { id, document, artifact } = this.checklistItem;
    setTimeout(() => {
      this.filesSuccess = [{ name: document.name, sizeArtifact: artifact.size, id: '' }];
      this.uploadFinished.emit({ id, completed: true });
    }, 0);
  }

  /**
   * Method responsible for cleaning files from previous session on the backend
   * and opening the document upload method selected by the user (attach | picture)
   */
  public handleUploadMethod(method: 'attach' | 'picture'): void {
    this.sendToTagger('Anexar - ' + this.checklistItem?.document?.name.split('(')[0]);
    this.filesError = [];
    this.errorMessage = '';
    this.deleteAllFiles(method);
  }

  public deleteAllFiles(method: 'attach' | 'picture'): void {
    this.uploadService
      .deleteAllFiles(this.checklistItem.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.showFileUploadModal = method === 'attach';
          this.showImageGallery = method === 'picture';
        },
        (error: HttpErrorResponse) => (this.errorMessage = this.translate.instant('ALL-FILES-DELETE-ERROR-MSG'))
      );
  }

  public handleFileUpload(response: boolean) {
    this.disableUploadButton = !response;
  }

  public handleCancelUploads(): void {
    this.showImageGallery = false;
    this.showFileUploadModal = false;
  }

  public saveArtifact(): void {
    this.showFileUploadModal = false;
    this.showImageGallery = false;

    this.uploadService
      .finishArtifact(this.checklistItem)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        retry(2),
        filter(res => !!(res && res.body && res.body.artifact && res.body.proposal))
      )
      .subscribe(
        response => {
          const res = response.body;

          const { artifact } = res;
          const { document, id } = this.checklistItem;

          this.filesSuccess.push({
            name: document.name,
            sizeArtifact: artifact.size,
            id: '' + artifact.id
          });

          this.uploadFinished.emit({ id, completed: true });
        },
        (error: HttpErrorResponse) => {
          this.errorMessage = this.translate.instant('ARTIFACT-UPLOAD-ERROR');
        }
      );
  }

  public onDeleteClick(file: ChecklistFileModel): void {
    this.sendToTagger('Deletar - ' + this.checklistItem?.document?.name.split('(')[0]);
    const dialogRef = this.dialog.open(PionModalComponent, {
      data: {
        title: this.translate.instant('FILE-DELETE-MODAL-TITLE'),
        cancelText: this.translate.instant('FILE-DELETE-MODAL-BUTTON-NO'),
        confirmText: this.translate.instant('FILE-DELETE-MODAL-BUTTON-YES'),
        type: null
      },
      disableClose: true,
      id: 'modal-component',
      width: '520px',
      minHeight: '230px'
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        if (res) {
          this.deleteArtifact(file);
        }
      });
  }

  public deleteArtifact(file: ChecklistFileModel): void {
    this.errorMessage = '';
    const { id } = this.checklistItem;

    this.uploadService
      .deleteArtifact(id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          const index = this.filesSuccess.findIndex(f => f.id === file.id);
          this.filesSuccess.splice(index, 1);
          this.uploadFinished.emit({ id, completed: false });
          this.disableAttachButton = false;
        },
        (error: HttpErrorResponse) => {
          this.errorMessage = this.translate.instant('FILE-DELETE-ERROR-MSG');
        }
      );
  }

  public formatSize(bytes: number): string {
    const k = 1024;
    const decimals = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
  }

  private sendToTagger(label) {
    this.genTagger.setTag({
      page_location: this.category,
      page_section: this.translate.instant('FORMALIZATION-STEP-DOCUMENT-UPLOAD'),
      event_element: 'button',
      event_label: label,
      event_action: Tag.Click
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
