<section id="3" class="simulation-section simulation-section-height">
  <header class="section-header">
    <h2 class="title d-flex align-items-center">
      <div class="mr-2">{{ 'INCLUDES-REGISTRATION-FEE' | translate }}</div>
      <pion-tooltip
        (openOut)="openTooltip()"
        (closeOut)="closeTooltip()"
        [eventLabelOpen]="eventLabelOpen"
        alignTo="left"
        [category]="category"
      >
        {{ 'INCLUDES-REGISTRATION-FEE-TOOLTIP' | translate }}
      </pion-tooltip>
    </h2>
    <span>{{ registrationFeeData.sectionNumber }}</span>
  </header>
  <div class="section-main" *ngIf="registrationFeeData.tariff.paymentType !== RegistrationFee.EXEMPT_CUSTOMER">
    <mat-radio-group class="w-100" aria-label="Incluir tarifas de cadastro">
      <app-registration-fee-card
        class="w-100"
        *ngIf="hideFeeOptions; else elseBlock"
        (changeSelectedFeeOption)="regFeeSelected($event)"
        [selectedFeeOption]="selectedFeeOption"
        [registrationFeeOption]="selectedFeeOption"
        [customInstallmentAmmount]="registrationFeeData?.installmentAmount"
        [customInstallmentValue]="registrationFeeData?.tariff?.installmentValue"
        [registrationValue]="registrationFeeData?.tariff?.value"
        [category]="category"
      >
      </app-registration-fee-card>

      <ng-template #elseBlock>
        <app-registration-fee-card
          *ngFor="let registrationFeeChoice of fees"
          (changeSelectedFeeOption)="regFeeSelected($event)"
          [registrationFeeOption]="registrationFeeChoice"
          [selectedFeeOption]="selectedFeeOption"
          [customInstallmentAmmount]="registrationFeeData?.installmentAmount"
          [customInstallmentValue]="registrationFeeData?.tariff?.installmentValue"
          [registrationValue]="registrationFeeData?.tariff?.value"
          [category]="category"
        >
        </app-registration-fee-card>
      </ng-template>
    </mat-radio-group>
    <a
      class="showFeeOptionsStyle"
      href="#"
      (click)="expandFeeOptionsHandler($event)"
      appTagger
      [event_category]="category"
      [event_label]="hideFeeOptions ? 'exibir outras formas de pagamento' : 'ocultar outras formas de pagamento'"
    >
      <div class="showFeeOptionsStyle" *ngIf="hideFeeOptions">
        <span>
          {{ 'SHOW-ALTERNATE-PAYMENT-OPTIONS' | translate }}
        </span>
        <mat-icon>expand_more</mat-icon>
      </div>
      <div class="showFeeOptionsStyle" *ngIf="false === hideFeeOptions">
        <span>
          {{ 'HIDE-ALTERNATE-PAYMENT-OPTIONS' | translate }}
        </span>
        <mat-icon>expand_less</mat-icon>
      </div>
    </a>
  </div>
  <div
    class="section-main card-exempt-customer"
    *ngIf="registrationFeeData.tariff.paymentType === RegistrationFee.EXEMPT_CUSTOMER"
  >
    <div class="row">
      <div class="col-md-1">
        <img src="./assets/santander/images/ic_bank_credit.svg" alt="bank-credit" class="bank-credit" />
      </div>
      <div class="col-md-11  card-exempt-customer-text">
        <span>Seu cliente tem condições especiais e está isento da tarifa de cadastro.</span>
      </div>
    </div>
  </div>
</section>
