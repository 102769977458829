import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AppState } from '@app/core/state';
import { userProfileSelector } from '@app/core/state/profile/profile.selectors';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { LgpdConsentConsultRequest, LgpdConsentConsultResponse } from '@shared/models/lgpd-consent-consult.model';
import { LgpdConsentRegisterRequest } from '@shared/models/lgpd-consent-register.model';
import { LgpdConsentAccessType } from '@shared/models/lgpd-consent.enum';
import { Subject } from 'rxjs';
import { first, take, takeUntil } from 'rxjs/operators';
import { LgpdService } from './../../services/lgpd/lgpd.service';

@Component({
  selector: 'app-lgpd-form',
  templateUrl: './lgpd-form.component.html',
  styleUrls: ['./lgpd-form.component.scss']
})
export class LgpdFormComponent implements OnInit, OnChanges, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  @Input() formData: LgpdConsentConsultResponse;
  @Input() isIntegraProposal = false;
  @Input() accessType;
  @Input() documentNumber;
  userDocumentNumber;
  userEmail;

  public lgpdConsentAccessType = LgpdConsentAccessType;

  @Output() consentSuccess = new EventEmitter();
  @Output() loaded = new EventEmitter<boolean>();
  @Output() lgpdAnswered = new EventEmitter<LgpdConsentConsultResponse>();
  @Output() lgpdAnsweredToRegister = new EventEmitter<LgpdConsentConsultResponse>();
  public answerCompanyOffer: string;
  public shopkeeperAlertMessage = 'LGPD-SHOPKEEPER-HINT';
  public answerGroupOffer: string;
  public answerPartnerOffer: string;
  public parameterFirstLineCompanyOffer: string;
  public parameterSecondLineCompanyOffer: string;
  public parameterFirstLinePartnerOffer: string;
  public parameterSecondLinePartnerOffer: string;
  public parameterFirstLineSantanderGroupOffer: string;
  public parameterSecondLineSantanderGroupOffer: string;
  public showQuestions: boolean;

  showForm = false;

  constructor(private store$: Store<AppState>, public lgpdService: LgpdService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.accessType && changes.accessType) {
      this.handleAccessType();
    }
  }

  ngOnInit() {}

  private handleAccessType(): void {
    switch (this.accessType) {
      case LgpdConsentAccessType.REGISTER:
        this.store$
          .select(userProfileSelector)
          .pipe(first())
          .subscribe(profile => {
            this.userDocumentNumber = profile.documentNumber;
            this.userEmail = profile.email;
          });
        break;

      case LgpdConsentAccessType.IDENTIFICATION:
        this.store$
          .select(userProfileSelector)
          .pipe(first())
          .subscribe(profile => {
            this.userDocumentNumber = this.documentNumber;
            this.userEmail = profile.email;
          });
        break;

      case LgpdConsentAccessType.PROPOSAL_REGISTER:
        this.store$
          .select(getConfirmationData)
          .pipe(first())
          .subscribe((state: any) => {
            this.userDocumentNumber = state ? state.personalData.documentNumber : this.documentNumber;
            this.userEmail = 'sistema';
          });
        break;

      default:
        this.userDocumentNumber = this.documentNumber;
        this.userEmail = 'sistema';
        break;
    }

    this.loadConsent();
  }

  private loadConsent(): void {
    if (this.formData) {
      this.setFormData(this.formData);
      return;
    }

    const requestLoadConsent: LgpdConsentConsultRequest = {
      documentNumber: this.userDocumentNumber,
      partnerUser: this.userEmail,
      accessType:
        this.accessType === LgpdConsentAccessType.IDENTIFICATION ? LgpdConsentAccessType.REGISTER : this.accessType,
      companyId: environment.businessCode
    };

    this.lgpdService
      .consentConsult(requestLoadConsent)
      .pipe(take(1))
      .subscribe(
        (response: LgpdConsentConsultResponse) => {
          this.showQuestions = response.showQuestions;
          this.loaded.emit(!!(response.showQuestions && response.parameterFirstLineCompanyOffer));
          this.setFormData(response);
          this.radioChange();

          if (this.accessType === this.lgpdConsentAccessType.PROPOSAL_REGISTER) {
            if (this.answerCompanyOffer === 'S' || this.answerGroupOffer === 'S' || this.answerPartnerOffer === 'S') {
              this.loaded.emit(false);
              return;
            }
            this.answerCompanyOffer = ' ';
            this.answerGroupOffer = ' ';
            this.answerPartnerOffer = ' ';
          }
        },
        err => this.loaded.emit(false)
      );
  }

  public saveConsent(documentNumber = null, email = null): void {
    const requestSaveConsent: LgpdConsentRegisterRequest = {
      documentNumber: documentNumber || this.userDocumentNumber,
      answerCompanyOffer: this.answerCompanyOffer,
      answerGroupOffer: this.answerGroupOffer,
      answerPartnerOffer: this.answerPartnerOffer,
      accessType:
        this.accessType === LgpdConsentAccessType.IDENTIFICATION ? LgpdConsentAccessType.REGISTER : this.accessType,
      companyId: +environment.businessCode,
      partnerUser: email || this.userEmail
    };

    this.lgpdAnswered.emit({
      parameterFirstLineCompanyOffer: this.parameterFirstLineCompanyOffer,
      parameterSecondLineCompanyOffer: this.parameterSecondLineCompanyOffer,
      parameterFirstLinePartnerOffer: this.parameterFirstLinePartnerOffer,
      parameterSecondLinePartnerOffer: this.parameterSecondLinePartnerOffer,
      parameterFirstLineSantanderGroupOffer: this.parameterFirstLineSantanderGroupOffer,
      parameterSecondLineSantanderGroupOffer: this.parameterSecondLineSantanderGroupOffer,
      answerCompanyOffer: this.answerCompanyOffer,
      answerGroupOffer: this.answerGroupOffer,
      answerPartnerOffer: this.answerPartnerOffer
    });

    if (
      (this.showForm && this.showQuestions) ||
      (this.accessType === this.lgpdConsentAccessType.PROFILE && this.showForm)
    ) {
      this.lgpdService
        .consentRegister(requestSaveConsent)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(response => {
          this.consentSuccess.emit();
        });

      return;
    }

    this.consentSuccess.emit();
  }

  private setFormData(data) {
    this.parameterFirstLineCompanyOffer = data.parameterFirstLineCompanyOffer;
    this.parameterSecondLineCompanyOffer = data.parameterSecondLineCompanyOffer;
    this.parameterFirstLinePartnerOffer = data.parameterFirstLinePartnerOffer;
    this.parameterSecondLinePartnerOffer = data.parameterSecondLinePartnerOffer;
    this.parameterFirstLineSantanderGroupOffer = data.parameterFirstLineSantanderGroupOffer;
    this.parameterSecondLineSantanderGroupOffer = data.parameterSecondLineSantanderGroupOffer;
    this.answerCompanyOffer = data.answerCompanyOffer;
    this.answerGroupOffer = data.answerGroupOffer;
    this.answerPartnerOffer = data.answerPartnerOffer;
    this.showQuestions = data.showQuestions;
    this.showForm = !!data.parameterFirstLineCompanyOffer;
  }

  public radioChange() {
    setTimeout(() => {
      if (!!this.answerCompanyOffer && !!this.answerGroupOffer && !!this.answerPartnerOffer) {
        if (!!this.answerCompanyOffer.trim() && !!this.answerGroupOffer.trim() && !!this.answerPartnerOffer.trim()) {
          this.lgpdAnsweredToRegister.emit();
        }
      }
    }, 0);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
