import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import * as fromLogin from '@app/core/state/login';
import { ShowTourTutorialShowcaseAction } from '@app/core/state/showcase/showcase.actions';
import { LoginService } from '@app/login/services/login.service';
import { InitialScreenOptions } from '@app/profile/interface/initial-screen-options.enum';
import { ProfileService } from '@app/profile/service/profile/profile.service';
import { HeaderFactoryService } from '@app/shared/components/ui/header/header-factory.service';
import { TaggerService } from '@app/tagging/tagger.service';
import { LgpdService } from '@shared/services/lgpd/lgpd.service';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import * as profileActions from './profile.actions';
// import { ProfileState } from '@app/core/state/profile/profile.reducer';

@Injectable()
export class ProfileEffects {
  constructor(
    private actions$: Actions,
    private loginService: LoginService,
    private profileService: ProfileService,
    private headerFactoryService: HeaderFactoryService,
    private router: Router,
    private lgpd: LgpdService,
    private dialog: MatDialog,
    private store$: Store<AppState>,
    private sessionStorageService: SessionStorageService,
    private taggerService: TaggerService
  ) {}

  @Effect()
  HeaderData = this.actions$.pipe(
    ofType<profileActions.ProfileRequest>(profileActions.ProfileActionTypes.PROFILE_INIT_REQUEST),
    switchMap(action =>
      this.loginService.getUserUIConfigs(action.payload).pipe(
        switchMap(res => {
          if (res.userInfo.profile.documentNumber.length < 11) {
            res.userInfo.profile.documentNumber = this.pad(Number(res.userInfo.profile.documentNumber), 11);
          }
          this.taggerService.setUserScope();
          return [
            new profileActions.ProfileRequestSuccess(<any>{
              menus: this.headerFactoryService.translateToHeaderInterface(res.uiConfig.menus),
              userProfile: res.userInfo.profile,
              userPreferences: res.userInfo.preferences,
              homePage: res.userInfo.preferences.initialScreen
            })
          ];
        }),
        catchError(() => {
          return [new profileActions.ProfileRequestError(this.headerFactoryService.translateToHeaderInterface())];
        })
      )
    )
  );

  @Effect()
  UserProfile = this.actions$.pipe(
    ofType<profileActions.UpdateUserProfileRequest>(profileActions.ProfileActionTypes.UPDATE_USER_PROFILE_REQUEST),
    switchMap(action =>
      this.profileService.updateProfileData(action.payload).pipe(
        switchMap(res => {
          this.taggerService.setUserScope();
          return [new profileActions.UpdateUserProfileSuccess(action.payload)];
        }),
        catchError(error => {
          return [new profileActions.UpdateUserProfileError({ message: error })];
        })
      )
    )
  );

  @Effect()
  UserPreference = this.actions$.pipe(
    ofType<profileActions.UpdateUserPreferencesRequest>(
      profileActions.ProfileActionTypes.UPDATE_USER_PREFERENCES_REQUEST
    ),
    switchMap(action =>
      this.profileService.updateUserPreferences(action.payload).pipe(
        switchMap(() => {
          return [new profileActions.UpdateUserPreferencesSuccess(action.payload)];
        }),
        catchError(error => {
          return [new profileActions.UpdateUserPreferencesError({ message: error })];
        })
      )
    )
  );

  @Effect({ dispatch: false })
  onSuccessGetProfileInfo = this.actions$.pipe(
    ofType<profileActions.ProfileRequestSuccess>(profileActions.ProfileActionTypes.PROFILE_INIT_REQUEST_SUCCESS),
    switchMap(action =>
      of(action).pipe(
        withLatestFrom(this.store$.select(fromLogin.selectors.loginSelector)),
        tap(obj => {
          switch (action.payload.homePage) {
            case InitialScreenOptions.Panel:
              this.router.navigate(['/showcase/open-proposal']);

              break;
            case InitialScreenOptions.NewProposal:
              this.router.navigate(['/identification']);

              break;

            default:
              this.router.navigate(['/showcase']);

              break;
          }
          return obj;
        })
        // map(([act, loginState]) => {
        //   // if (!loginState.showTerms) {
        //   this.lgpd
        //     .verifyShowConsentForm(act.payload.userProfile.documentNumber, act.payload.userProfile.email)
        //     .subscribe(response => {
        //       if (response.showQuestions && response.parameterFirstLinePartnerOffer) {
        //         this.dialog
        //           .open(LgpdModalComponent, {
        //             width: '768px',
        //             autoFocus: false,
        //             id: 'modal-lgpd',
        //             role: 'dialog',
        //             data: response,
        //             hasBackdrop: false
        //           })
        //           .afterClosed()
        //           .subscribe(() => this.handleOpenModalTutor());
        //       } else {
        //         // else... open tutor modal
        //         this.handleOpenModalTutor();
        //       }
        //     });
        //   // }
        //   return [];
        // })
      )
    )
  );

  @Effect()
  changeInitialScreen = this.actions$.pipe(
    ofType<profileActions.ChangeInitialScreen>(profileActions.ProfileActionTypes.CHANGE_INITIAL_SCREEN),
    switchMap(action =>
      this.profileService.postHomePage(action.payload).pipe(
        switchMap(res => [new profileActions.ChangeInitialScreenSuccess(action.payload)]),
        catchError(error => [new profileActions.ChangeInitialScreenError(error)])
      )
    )
  );

  /**
   * Handle if should display the tutorial modal after expiration modal.
   */
  handleOpenModalTutor() {
    console.log('handle tutor modal if expiration | profile effects');
    const user = this.sessionStorageService.getUser();
    if (!user) return;

    const expirationDialog = this.dialog.getDialogById('expiration-modal-component');

    // if no expirationModal opened.. open tutor modal
    if (!expirationDialog) {
      this.store$.dispatch(new ShowTourTutorialShowcaseAction());
    } else {
      // if expirationModal is opened..
      // subscribe to expirationModal and open tutor after it closes
      expirationDialog.afterClosed().subscribe(() => {
        this.store$.dispatch(new ShowTourTutorialShowcaseAction());
      });
    }
  }

  pad(num: number, size: number): string {
    let s = num + '';
    while (s.length < size) s = '0' + s;
    return s;
  }
}
