<div class="container">
  <form [formGroup]="paymentForm">
    <mat-radio-group formControlName="paymentType">
      <ng-container *ngFor="let type of parsedSendTypes; index as i">
        <div class="send-type pt-3 pl-3 pr-3 pb-2 mb-2" *ngIf="expansion || i === 0">
          <mat-radio-button color="primary" [value]="type.id" (click)="onSendTypeChange(type.code)">
            {{ 'RECEIVE-BY-' + type.code | translate }}
          </mat-radio-button>

          <p *ngIf="type.code === sendTypesEnum.EMAIL">{{ 'WILL-BE-SENT-TO' | translate }} {{ obfuscatedEmail }}</p>
        </div>
      </ng-container>
    </mat-radio-group>
  </form>

  <div (click)="handleShowOptions()">
    <h1 *ngIf="expansion">{{ 'HIDE-FORM-RECEIPT' | translate }}</h1>
    <h1 *ngIf="!expansion">{{ 'DISPLAY-FORM-RECEIPT' | translate }}</h1>

    <img class="icons-hide" *ngIf="expansion" src="./assets/santander/images/ic_arrow_top.svg" alt="" />
    <img class="icons-show" *ngIf="!expansion" src="./assets/santander/images/ic_arrow_bottom.svg" alt="" />
  </div>
</div>
