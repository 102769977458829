import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DocumentScanModel, DocumentScanType } from '@app/document-scan/models/document-scan.enum';
import { DocumentSidePeruSDK, DocumentTypePeruSDK } from '@app/document-scan/models/peru-sdk.enums';
import { environment } from '@env/environment';
import { Countries } from '@shared/constants/countries.enum';

@Component({
  selector: 'app-document-scan-wrapper',
  templateUrl: './document-scan-wrapper.component.html',
  styleUrls: ['./document-scan-wrapper.component.scss']
})
export class DocumentScanWrapperComponent implements OnInit {
  @Output() scanOutput: EventEmitter<DocumentScanModel> = new EventEmitter();

  @Input() scanType: DocumentScanType.DOCUMENT | DocumentScanType.GENERIC = DocumentScanType.DOCUMENT;

  /**
   * Inputs of PER SDK
   */
  @Input() documentType: DocumentTypePeruSDK = DocumentTypePeruSDK.PE1;
  @Input() sessionId = '';
  @Input() documentSide: DocumentSidePeruSDK = DocumentSidePeruSDK.BACK;

  public currentCountry = environment.currentCountry;
  public documentScanTypes = DocumentScanType;
  public countries = Countries;

  constructor() {}

  ngOnInit() {}

  scanCallback(scanOutput: DocumentScanModel) {
    console.log(scanOutput);
    this.scanOutput.emit(scanOutput);
  }
}
