import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApplicationHeaders } from '@shared/components/ui/header/header.models';
import { HeaderService } from '@shared/components/ui/header/services/header.service';
import { filter, map, mergeMap } from 'rxjs/operators';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public currentHeader = '';
  public headerTypes = ApplicationHeaders;
  public hiddeNavBar;
  private urls = [
    '/proposal/step-simulation',
    '/proposal/step-register',
    '/proposal/step-confirmation',
    '/proposal/step-formalization',
    '/proposal/step-offer',
    '/proposal/step-simulation/summary',
    '/login',
    '/signup/data',
    '/signup/confirmation-token',
    '/recovery/pre-token',
    '/login/select-store'
  ];

  constructor(private router: Router, private activeRouter: ActivatedRoute, private headerService: HeaderService) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activeRouter),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.hiddeNavBar = false;
        this.urls.forEach(url => {
          if (this.router.url === url) this.hiddeNavBar = true;
        });

        if (event && event.header) {
          this.headerService.updateHeader(event.header);
          return (this.currentHeader = event.header);
        }

        // Default option
        this.headerService.updateHeader(ApplicationHeaders.Checkout);
        return (this.currentHeader = ApplicationHeaders.Checkout);
      });
  }
}
