import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AppState } from '@app/core/state';
import { BiometryStepsType, BiometryTutorialSteps } from '@app/proposal-workflow/models/biometry-tutorial-steps.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { BiometryService, BiometryStageEnum } from '../../services/biometry.service';

@Component({
  selector: 'app-biometry-instructions',
  templateUrl: './biometry-instructions.component.html',
  styleUrls: ['./biometry-instructions.component.scss']
})
export class BiometryInstructionsComponent implements OnInit {
  @Input() tutorial: BiometryTutorialSteps;
  @Input() tutorialNextButtonDisabled = false;
  @Input() category: string;
  @Output() closeEmitter: EventEmitter<any> = new EventEmitter();
  @Output() finishButtonEmitter: EventEmitter<any> = new EventEmitter();
  @ViewChild('instructionsCarousel', { static: true }) carousel: CarouselComponent;

  public biometryStepsTypeEnum = BiometryStepsType;
  public hidePrev = false;
  public hideNext = false;
  public showProposalText = false;
  public itemSelected = false;
  public isFinishButtonDisabled = false;

  public carouselOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    autoHeight: false,
    responsive: {
      0: {
        items: 1
      },
      540: {
        items: 1
      },
      720: {
        items: 1
      },
      960: {
        items: 1
      }
    },
    nav: false
  };

  constructor(
    private biometryService: BiometryService,
    private store$: Store<AppState>,
    private genTagger: GenTagger,
    private translate: TranslateService,
    private taggerService: TaggerService
  ) {}

  ngOnInit() {
    if (this.tutorial.type === BiometryStepsType.SIGNATURE) {
      this.category = this.category + '/contrato-tutorial';
    } else {
      this.category = this.category + '/biometria-tutorial';
    }
    if (!this.category.length) {
      this.category = this.taggerService.getPageLocation();
    }
    this.biometryService.biometryStage.subscribe(stage => {
      if (stage === BiometryStageEnum.INSTRUCTIONS) {
        this.resetCarousel();
      }
    });
    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'Assine o contrato',
      event_action: Tag.pgView
    });
  }

  public changeOnSlider(slider): void {
    if (slider.startPosition === 0) {
      this.showProposalText = true;
      this.hideNext = true;
      this.hidePrev = false;
    } else if (slider.startPosition === 1) {
      this.hideNext = true;
      this.hidePrev = true;
    } else if (slider.startPosition === 2) {
      this.hideNext = this.tutorial && this.tutorial.type === BiometryStepsType.SIGNATURE ? null : true;
      this.hidePrev = true;
    } else if (slider.startPosition === 3 || slider.startPosition === 4) {
      this.hideNext = null;
      this.hidePrev = true;
    }
  }

  public carouselNext(owlRef): void {
    owlRef.next();
  }

  public carouselPrevious(owlRef): void {
    owlRef.prev();
  }

  public resetCarousel(): void {
    if (!this.carousel) return;
    this.carousel.to('initial');
  }

  public selectItem(): void {
    this.itemSelected = true;
  }

  public handleClose(): void {
    this.closeEmitter.emit();
  }

  public handleFinishButton(): void {
    const event_label =
      this.tutorial.type === this.biometryStepsTypeEnum.BIOMETRY
        ? this.translate.instant('FORMALIZATION-BIOMETRY-BTN')
        : this.translate.instant('FORMALIZATION-SIGNATURE-BTN');
    this.genTagger.setTag({
      event_category: this.category,
      event_label: event_label,
      page_section: 'tab',
      event_element: 'button',
      event_action: Tag.Click
    });
    this.finishButtonEmitter.emit();
  }
}
