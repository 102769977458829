import { AfterViewInit, Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import * as fromFormalization from '@app/core/state/formalization';
import { selectShowTutorialForml } from '@app/core/state/login/login.selectors';
import * as fromProposal from '@app/core/state/proposal';
import { GetConfirmationData } from '@app/core/state/proposal-information/proposal-information.actions';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { PendencyService } from '@app/pendency/pendency.service';
import { OpenFinanceModalComponent } from '@app/proposal-workflow/components/open-finance-modal/open-finance-modal.component';
import { FormalizationStatusEnum } from '@app/proposal-workflow/models/formalization-status.enum';
import { OsEnum } from '@app/proposal-workflow/models/os.enum';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { CustomerTypeEnum } from '@app/proposal-workflow/models/step-register-put.enum';
import { ConfirmationContractResponse } from '@app/proposal-workflow/models/step-register-requests';
import { SummaryModel, SummaryStageModel } from '@app/proposal-workflow/models/summary.model';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import {
  FormalizationProposalDTO,
  PaymentTypeEnum
} from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LgpdFormComponent } from '@shared/components/lgpd-form/lgpd-form.component';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { LgpdConsentAccessType } from '@shared/models/lgpd-consent.enum';
import { ProposalStageService } from '@shared/services/proposal/proposal-stage.service';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { TutorialService } from '@shared/services/tutorial/tutorial.service';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { Subject } from 'rxjs';
import { delay, filter, first, map, take, takeUntil, tap } from 'rxjs/operators';
import { AgentLegalPersonDTO } from './components/biometry-controller/models/api/post.agent-legal.model';
import { ClientBiometryStatusEnum, CrossedFluxInfo } from './components/biometry-controller/models/biometry.model';
import { BiometryService } from './components/biometry-controller/services/biometry.service';
import { ChecklistItemClientDTO, ChecklistItemTypeEnum } from './components/checklist/models/api/query.checklist.model';
import { ChecklistModel, ChecklistModelMobile, ChecklistStatusEnum } from './components/checklist/models/checklist.model';
import { FormBipartiteModel } from './components/form-bipartite/form-bipartite-model';
import { FormPaymentModel } from './components/form-payment/models/form-payment.model';
import { FormalizationService } from './services/formalization.service';

@Component({
  selector: 'step-formalization',
  templateUrl: './step-formalization.component.html',
  styleUrls: ['./step-formalization.component.scss']
})
export class StepFormalizationComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild('uploadSection') uploadSection: ElementRef;
  @ViewChild('lgpdForm') lgpdForm: LgpdFormComponent;

  public currentPlatform: { deviceInfo: DeviceInfo; isMobile: boolean; isDesktop: boolean };
  public osEnum = OsEnum;
  public proposal: FormalizationProposalDTO | Partial<FormalizationProposalDTO>;
  public proposalId: number | string = 0;
  public checklists: ChecklistModel[] = [];
  public biometryChecklist: ChecklistModel[] = [];
  public uploadChecklist: ChecklistModel[] = [];
  public uploadChecklistMoblie: ChecklistModelMobile[] = [];
  public maxSizeAllowed: string;
  public maxSizeAllowedMobile: string;
  public email: string;
  public showSummary = false;
  public summaryData: SummaryModel[];
  public summaryStatus: SummaryStageModel[];
  public isBiometryAndSignatureDone = false;
  public mobileSubscription = false;
  public bipartiteMandatory = false;
  public uploadsComplete = false;
  public maxSteps = 4;
  public clientType: string;
  public hasSignatureBiometry: boolean;
  public hasFacialBiometry: boolean;
  public isAntifraudBiometry: boolean;
  public ischecklistMobilePollRunning = false;
  public pollingChecklistRunning = false
  public isChecklistPend = false;
  checkSatus: boolean;

  public crossedFluxLink: { link: string; uuid: string };
  public isCrossedFlux: boolean;

  public customerEmail: string;
  public isAccountDebit = false;
  public paymentForm: FormPaymentModel;
  public paymentBipartiteForm: FormBipartiteModel;
  public incompletePayment = true;
  public incompleteBipartitePayment = true;
  public statusBiometry: string;

  public isDepositary = '';
  public depositary = false;

  public proposalData: ConfirmationData;

  public sendTypes: FormPaymentModel[] = [];
  public paymentTypeEnum = PaymentTypeEnum;
  public biometryStatus = ChecklistStatusEnum;
  public biometryTitle = 'FORMALIZATION-PJ-SECTION-1-TITLE';

  public clientList: ChecklistItemClientDTO[] = [];
  //TODO TIPAR A VARIAVEL checkListMobile
  public checkListMobile
  public showContractDownload = false;
  public ishandleSignature = false;
  public isFacialCapture = false;

  public hasUploadRPP = false;
  public showUploadRPO = false;
  public personType = SubmitPersonType;
  public productType = ProductCodeEnum;
  public category: string;

  public hasAnalysis = false;

  public showBipartido = false;
  public proposalConditionsAccepted = false;

  public showIntegraLgpdFlag = false;
  public lgpdFormIsValid = true;
  public lgpdConsentAccessType = LgpdConsentAccessType;
  public tutorialNextButtonDisabled = false;
  public biometriaStatus: any;
  public actualBiometriaStatus: ClientBiometryStatusEnum;
  public isFirstPolling = true;
  public comparationStatus: ClientBiometryStatusEnum;

  constructor(
    private store$: Store<AppState>,
    public translate: TranslateService,
    public translateService: TranslateService,
    public dialog: MatDialog,
    public biometryService: BiometryService,
    private sessionStorageService: SessionStorageService,
    private tutorialService: TutorialService,
    private store: Store<any>,
    private simulationService: SimulationService,
    public deviceDetectorService: DeviceDetectorService,
    private genTagger: GenTagger,
    private proposalStageService: ProposalStageService,
    private formalizationService: FormalizationService,
    private router: Router,
    private pendencyService: PendencyService,
    private taggerService: TaggerService
  ) {
    this.store$.dispatch(new fromProposal.actions.ClearProposalAction());
    this.store$.dispatch(new fromProposal.actions.ClearChecklistAction());
    this.store$.dispatch(new fromFormalization.actions.ClearFormalizationAction());
  }

  ngAfterViewInit(): void {
    const crossedFluxData = this.sessionStorageService.getCrossedFlux();
    this.isCrossedFlux = !!crossedFluxData;
    if (!this.isCrossedFlux) {
      this.setBiometryChecklistSubscription();
      //  this.pollingChecklist()
    }

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (this.clientList && changes.clientList) {
      let isBiometry: boolean = false;
      this.clientList.filter(res => {
        if (res?.biometryStatus === ClientBiometryStatusEnum.ANALYSIS ||
          res?.biometryStatus === ClientBiometryStatusEnum.DONE) {
          return isBiometry = true;
        }
      })

    }
  }
  ngOnInit() {
    // Tutorial
    this.store
      .select(selectShowTutorialForml)
      .pipe(take(1))
      .subscribe((showTutorial: boolean) => {
        if (showTutorial) {
          this.tutorialService.showTutorial('formalization');
        }
      });

    const crossedFluxData = this.sessionStorageService.getCrossedFlux();
    this.isCrossedFlux = !!crossedFluxData;
    this.store$
      .select(fromFormalization.selectors.selectLatestBiometryStatus).subscribe(res => {
        if ((this.statusBiometry !== 'RELEASE_INTERNAL' && this.statusBiometry !== 'INDEXED') &&
          (res?.checklist[0]?.status === 'RELEASE_INTERNAL' || res?.checklist[0]?.status === 'INDEXED')) {
          this.store$.dispatch(new GetConfirmationData(this.proposalId.toString()));
        }
        this.statusBiometry = res?.checklist[0]?.status;
      })

    if (this.isCrossedFlux) {
      this.maxSteps = 0;

      this.getCrossedFluxData(crossedFluxData);
      this.category = `/portallojista/criarproposta/${this.proposal?.product?.code}/formalizacao/cliente`;
      this.genTagger.setTag({
        event_category: this.category,
        event_label: `Formalização - ${this.proposal?.product?.code}`,
        event_action: Tag.pgView
      });
      this.getShowIntegraLgpdFlag();
      this.getChecklistMobile();
      this.getBiometryStatus();
      this.mobileSubscription = true;
      this.clientType = crossedFluxData.proposal.clientType;
    }

    if (!this.isCrossedFlux) {
      debugger
      this.getProposalId();
      this.setProposalSubscription();

      this.setChecklistsSubscription();
      this.getProposalStage();

      // this.setBiometryChecklistSubscription();
      this.setUploadChecklistSubscription();
      this.setDownloadLinkSubscription();

      this.setFormalizationStatusSubscription();
      this.getMaxFileSizeAllowed();
      this.getSummarySteps();
      this.getSummaryStatus();
      this.getCrossedFluxId();
      this.getSendType();
      // this.pollingChecklist();
    }


    this.getCurrentPlatform();

    this.store$
      .pipe(
        select(getConfirmationData),
        filter(data => !!data),
        take(1)
      )
      .subscribe(state => {
        if (state.proposalData.product) {
          this.category = `/portallojista/criarproposta/${state?.proposalData?.product}/formalizacao`;
          this.genTagger.setTag({
            event_category: this.category,
            event_label: `Formalização - ${state.proposalData.product}`,
            event_action: Tag.pgView
          });
        }
      });
    window.scrollTo(0, 0);
  }
  public ishandleFacialCapture(event: boolean) {
    this.store$
      .select(fromFormalization.selectors.selectBiometryResponse)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(res => !!res)
      )
      .subscribe(res => {
        if (res.status !== null) {
          this.ishandleSignature = true;
          this.category = `/criarproposta/${this.proposal?.product?.code}/formalizacao/cliente/documentos`;
          this.taggerService.setPageLocation(`/criarproposta/${this.proposal?.product?.code}/formalizacao/cliente/documentos`);
        }
      });
  }

  public ishandleSendSignature(event: any) {
    this.ishandleSignature = false;
    if (event) {
      this.store$
        .select(fromFormalization.selectors.selectBiometryResponse)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          filter(res => !!res)
        )
        .subscribe(res => {
          if (res.status !== null) {
            this.ishandleSignature = true;
            this.category = `/criarproposta/${this.proposal?.product?.code}/formalizacao/cliente/documentos`;
            this.taggerService.setPageLocation(`/criarproposta/${this.proposal?.product?.code}/formalizacao/cliente/documentos`);
          }
        });
    }
  }
  private getBiometryStatus() {
    this.store$
      .select(fromFormalization.selectors.selectLatestBiometryCrossFluxStatus).subscribe(res => {
        this.biometriaStatus = res;
        // if(!!this.biometriaStatus){
        //   this.getChecklistDispatch();
        //   this.pollingChecklist();
        // }
      });
  }
  private getChecklistMobilePollRunning() {
    this.store$.dispatch(
      new fromProposal.actions.GetChecklistMobileAction({ proposalId: this.proposal.id, isPendency: false })
    );
    this.store$
      .select(fromProposal.selectors.selectChecklistsMobile)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(state => !!state),
        map(state => state)
      )
      .subscribe(uploadChecklist => {
        this.uploadChecklistMoblie = uploadChecklist.filter(
          item =>
            item.type &&
            (item.type.code === ChecklistItemTypeEnum.UPLOAD ||
              item.type.code === ChecklistItemTypeEnum.CONTRACT_UPLOAD)
        );

        if (this.checkListMobile) this.setUploadChecklistSubscriptionMobilePollRunning();
      });
  }
  private getChecklistMobile() {
    this.store$.dispatch(
      new fromProposal.actions.GetChecklistMobileAction({ proposalId: this.proposal.id, isPendency: false })
    );
    this.store$
      .select(fromProposal.selectors.selectChecklistsMobile)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(state => !!state),
        map(state => state)
      )
      .subscribe(uploadChecklist => {
        this.uploadChecklistMoblie = uploadChecklist.filter(
          item =>
            item.type &&
            item.type.code === ChecklistItemTypeEnum.UPLOAD &&
            item.status === 'PENDING' &&
            item.type.description === 'UPLOAD DE DOCUMENTO'

        )
        this.maxSizeAllowed = this.uploadChecklistMoblie[0]?.value;
      });
  }

  private getCurrentPlatform(): void {
    this.currentPlatform = {
      deviceInfo: this.deviceDetectorService.getDeviceInfo(),
      isMobile: this.deviceDetectorService.isMobile(),
      isDesktop: this.deviceDetectorService.isDesktop()
    };
  }

  private getShowIntegraLgpdFlag(): void {
    this.formalizationService
      .getShowIntegraLgpdFlag(+this.proposal.id)
      .pipe(first())
      .subscribe(res => {
        this.showIntegraLgpdFlag = res;
      });
  }

  public onFormLgpdAnswered() {
    this.lgpdFormIsValid = true;
    this.tutorialNextButtonDisabled = false;
    if (this.proposal && this.proposal.customer) {
      this.lgpdForm.saveConsent(this.proposal.customer.documentNumber, this.proposal.customer.email);
    }
  }

  public onFormLgpdLoaded(event: boolean) {
    if (!event) {
      this.lgpdFormIsValid = true;
      this.tutorialNextButtonDisabled = false;
      return;
    }

    this.lgpdFormIsValid = false;
    this.tutorialNextButtonDisabled = true;
  }

  public getProposalInformationData() {
    this.store$
      .select(getConfirmationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: ConfirmationData) => {
        if (state) {
          this.proposalData = state;
          this.proposal = {
            ...this.proposal,
            contract: this.proposalData.contract
          };
          this.calculateMaxSteps();
          this.formalizationService.getBipartite().subscribe(hasMandatoryBipartite => {
            this.bipartiteMandatory = hasMandatoryBipartite;
            this.verifyBipartido(this.bipartiteMandatory);
          });
        }
      });

    this.store$.dispatch(new GetConfirmationData(this.proposalId.toString()));
  }

  public getCrossedFluxData(crossedFluxData: CrossedFluxInfo): void {
    const { proposal, contract, checklists } = crossedFluxData;
    this.proposal = {
      id: proposal.id,
      clientType: proposal.clientType,
      channelSupplier: { id: proposal.channelSupplierId, description: proposal.channelSupplierName },
      conditionFinanced: {
        returnTaxPercentage: +proposal.cetValue,
        annualReturnTaxPercentage: +proposal.annualCetValue
      },
      companyName: proposal.companyName,
      createdDate: proposal.proposalDate,
      financedValue: proposal.valueFinancing,
      installmentAmount: proposal.installmentAmount,
      installmentValue: proposal.installmentValue,
      cetValue: proposal.cetValue,
      annualCetValue: proposal.annualCetValue,
      integrationGenericFields: proposal.integrationGenericFields,
      customer: {
        id: proposal.clientId,
        name: proposal.name,
        email: proposal.email,
        documentNumber: proposal.document
      },
      planType: proposal.planType,
      totalFinancedValue: proposal.totalFinancedValue,
      insurance: proposal.insurance === 'Sim',
      product: proposal.product
    };

    this.biometryChecklist = checklists;

    this.isAntifraudBiometry = checklists.some(item => item.type.code === ChecklistItemTypeEnum.ANTIFRAUD);
    this.hasSignatureBiometry = checklists.some(item => item.type.code === ChecklistItemTypeEnum.SIGNATURE);
    this.hasFacialBiometry = checklists.some(item => item.type.code === ChecklistItemTypeEnum.FACIAL);

    if (this.hasSignatureBiometry) {
      this.setBiometryTitle();
      this.proposal.contract = { file: contract.file };
    }
    if (this.isAntifraudBiometry) {
      this.setBiometryTitle();
    }
  }

  private setBiometryTitle() {
    if (this.isAntifraudBiometry) {
      return (this.biometryTitle = 'FORMALIZATION-ANTIFRAUD-SECTION-1-TITLE');
    }

    this.biometryTitle =
      !!this.proposalData && this.proposalData.proposalData.client === SubmitPersonType.PF
        ? 'FORMALIZATION-PF-SECTION-1-TITLE'
        : 'FORMALIZATION-PJ-SECTION-1-TITLE';
  }

  public getCrossedFluxId(): void {
    this.store$
      .select(fromFormalization.selectors.selectCrossedFluxLink)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(id => (this.crossedFluxLink = id));
  }

  public setFormalizationStatusSubscription(): void {
    this.store$
      .select(fromFormalization.selectors.selectStatus)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(status => {
        if (status === FormalizationStatusEnum.FINISHED) {
          this.getProposalStage();
          this.store$.dispatch(new fromFormalization.actions.GetSummaryStatus(this.proposalId));
          this.showSummary = true;
          return;
        }
        this.showSummary = false;
      });
  }

  private getProposalStage(): void {
    this.proposalStageService
      .getProposalStage(this.proposalId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        tap(() =>
          this.store$.dispatch(
            new fromProposal.actions.GetChecklistAction({ proposalId: this.proposalId, isPendency: false })
          )
        )
      )
      .subscribe(stage => {
        this.store$
          .select(fromProposal.selectors.selectChecklists)
          .pipe(
            takeUntil(this.ngUnsubscribe),
            filter(state => !!state)
          )
          .subscribe(checklists => {
            this.checklists = checklists;
            this.getSummaryStepsDispatch(stage.idStage);
          });
      });
  }

  private getSummaryStepsDispatch(stageProposal) {
    this.store$.dispatch(
      new fromFormalization.actions.GetSummarySteps({
        productCode: this.proposal.product.code,
        stageCode: stageProposal,
        checklists: this.checklists
      })
    );
  }

  public getProposalId(): void {
    this.store$
      .select(fromProposal.selectors.selectProposalId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(proposalId => !!proposalId)
      )
      .subscribe(proposalId => {
        this.proposalId = proposalId;
        this.getProposalInformationData();
        this.store$.dispatch(new fromProposal.actions.GetProposalAction(proposalId));
      });
  }

  private getSummarySteps(): void {
    this.store$
      .select(fromFormalization.selectors.selectSummarySteps)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => (this.summaryData = value));
  }

  private getSummaryStatus(): void {
    this.store$
      .select(fromFormalization.selectors.selectSummaryStatus)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => (this.summaryStatus = value));
  }

  private getMaxFileSizeAllowed(): void {
    this.store$
      .select(fromFormalization.selectors.selectMaxFileSizeAllowed)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(maxSizeAllowed => {
        (
          this.maxSizeAllowed = maxSizeAllowed)

      });
    this.maxSizeAllowedMobile = this.maxSizeAllowed;

    this.store$.dispatch(new fromFormalization.actions.GetMaxFileSizeAllowedAction());
  }

  private setProposalSubscription(): void {
    this.store$
      .select(fromProposal.selectors.selectProposal)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(proposalData => !!proposalData.data),
        map(proposalData => proposalData.data)
      )
      .subscribe(proposal => {
        this.proposal = { ...this.proposal, ...proposal };
        this.proposal.installmentValue = this.proposal.conditionFinanced.maxInstallmentValue;
        this.isAccountDebit = this.proposal.paymentType.code === PaymentTypeEnum.ACCOUNT;
        this.checkBipartPartners(this.proposal.id);
        this.calculateMaxSteps();
        this.store$.dispatch(
          new fromProposal.actions.GetChecklistAction({ proposalId: proposal.id, isPendency: false })
        );

      });
  }

  private checkBipartPartners(proposal: string): void {
    this.pendencyService
      .getPartners(Number(proposal))
      .pipe(
        first(),
        map(partnerList =>
          (partnerList.length > 0 && this.proposalData?.proposalData?.product !== this.productType.CSCP ? true : false)),
      )
      .subscribe(res => this.showBipartido = res);
  }

  private pollingChecklist() {
    this.store$
      .select(fromProposal.selectors.selectChecklists)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(state => !!state),
        map(state => state),
        delay(7000)
      )
      .subscribe(checklists => {
        this.isFirstPolling = false
        this.checklists = checklists;
        this.checklists.forEach(res => {
          if (((res.type.code === ChecklistItemTypeEnum.UPLOAD ||
            res.type.code === ChecklistItemTypeEnum.CONTRACT_UPLOAD) &&
            res.status === 'PENDING' &&
            res.type.description === 'UPLOAD DE DOCUMENTO')
          ) {
            this.isChecklistPend = false
            this.uploadsComplete = false;
          }
          if ((res.type.code === ChecklistItemTypeEnum.UPLOAD ||
            res.type.code === ChecklistItemTypeEnum.CONTRACT_UPLOAD) &&
            res.status === 'INDEXED' &&
            res.type.description === 'UPLOAD DE DOCUMENTO') {
            this.uploadsComplete = true;
          }
          else {

            this.isChecklistPend = true;
            setTimeout(() => {
              this.store$.dispatch(
                new fromProposal.actions.GetChecklistAction({ proposalId: this.proposalId, isPendency: false })
              );
            }, 30000)
          }
        })
      });
  }
  private getChecklistDispatch() {
    if (this.isChecklistPend) {
      this.store$.dispatch(
        new fromProposal.actions.GetChecklistAction({ proposalId: this.proposalId, isPendency: false })
      );
    }
  }

  private setChecklistsSubscription(): void {
    this.store$
      .select(fromProposal.selectors.selectChecklists)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(state => !!state),
        map(state => state)
      )
      .subscribe(checklists => {
        this.checklists = checklists
        checklists.forEach(res => {
          if ((res.type.code === ChecklistItemTypeEnum.UPLOAD ||
            res.type.code === ChecklistItemTypeEnum.CONTRACT_UPLOAD) &&
            res.status === 'PENDING' &&
            res.type.description === 'UPLOAD DE DOCUMENTO'
          ) {
            this.isChecklistPend = true;
            this.uploadsComplete = false;
          } else {
            this.uploadsComplete = true;
          }

        })

      }
      );
  }
  public receivedStatusBiometry(status) {
    this.biometryStatus = status;
  }

  private setBiometryChecklistSubscription(): void {
    this.store$
      .select(fromProposal.selectors.selectBiometryChecklist)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(state => !!state && !!state.data),
        map(state => state.data)
      )
      .subscribe(biometryChecklist => {
        this.biometryChecklist = biometryChecklist;

        this.isAntifraudBiometry = biometryChecklist.some(item => item.type.code === ChecklistItemTypeEnum.ANTIFRAUD);
        this.hasSignatureBiometry = biometryChecklist.some(
          item => item.type && item.type.code === ChecklistItemTypeEnum.SIGNATURE
        );
        this.hasFacialBiometry = biometryChecklist.some(
          item => item.type && item.type.code === ChecklistItemTypeEnum.FACIAL
        );

        if (this.hasSignatureBiometry) {
          this.setBiometryTitle();
        }
        if (this.isAntifraudBiometry) {
          this.setBiometryTitle();
        }

        this.calculateMaxSteps();
      });
  }

  private setUploadChecklistSubscriptionMobilePollRunning(): void {
    if (!this.ischecklistMobilePollRunning) {
      this.ischecklistMobilePollRunning = true;
      this.store$
        .select(fromProposal.selectors.selectUploadChecklistMobile)
        .pipe(
          takeUntil(this.ngUnsubscribe),
          filter(state => !!state && !!state.data),
          map(state => state.data),
          delay(60000)
        )
        .subscribe(uploadChecklist => {
          this.uploadChecklistMoblie = uploadChecklist.filter(
            item =>
              item.type &&
              (item.type.code === ChecklistItemTypeEnum.UPLOAD ||
                item.type.code === ChecklistItemTypeEnum.CONTRACT_UPLOAD)
          );
          if (this.uploadChecklistMoblie) this.ischecklistMobilePollRunning = false;
          this.maxSizeAllowed = this.uploadChecklistMoblie[0]?.value;
          //  if(this.uploadChecklistMoblie)  this.getChecklistMobilePollRunning();
        });
    }
  }

  public getBiometryStatusEnum(event: ClientBiometryStatusEnum) {
    this.actualBiometriaStatus = event;
    if (this.proposalData?.proposalData?.client === 'F') {
      this.dispatchPollingCheckList();
    }
  }

  private dispatchPollingCheckList() {


    if (this.isFirstPolling || (this.comparationStatus !== this.actualBiometriaStatus)) {
      this.comparationStatus = this.actualBiometriaStatus;

      if (this.actualBiometriaStatus !== ClientBiometryStatusEnum.PENDING &&
        this.actualBiometriaStatus !== ClientBiometryStatusEnum.ANALYSIS &&
        this.actualBiometriaStatus !== ClientBiometryStatusEnum.IN_PROGRESS &&
        this.isChecklistPend) {
        this.getChecklistDispatch();
        this.pollingChecklist();
        this.isFirstPolling = false;
      }
    }
  }

  private setUploadChecklistSubscription(): void {
    this.store$
      .select(fromProposal.selectors.selectUploadChecklist)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(state => !!state && !!state.data),
        map(state => state.data)
      )
      .subscribe(uploadChecklist => {
        this.getEmail(uploadChecklist.find(cli =>
            (cli?.client?.type === 'RPP' && cli?.client?.person === 'F' && cli?.client?.email !== null) ||
          (cli?.client?.type === 'CLI' && cli?.client?.person === 'F' && cli?.client?.email !== null)
        ));
        this.uploadChecklist = uploadChecklist.filter(
          item =>
            item.type &&
            (item.type.code === ChecklistItemTypeEnum.UPLOAD ||
              item.type.code === ChecklistItemTypeEnum.CONTRACT_UPLOAD)
        );

        if (!this.biometryChecklist.length) {
          this.hasUploadRPP = this.uploadChecklist.some(
            item =>
              item.client.type === CustomerTypeEnum.MAIN_LEGAL_REPRESENTATIVE &&
              item.type.code === ChecklistItemTypeEnum.UPLOAD
          );
          this.showUploadRPO = this.uploadChecklist.some(
            item =>
              item.client.type === CustomerTypeEnum.OPTIONAL_LEGAL_REPRESENTATIVE &&
              item.type.code === ChecklistItemTypeEnum.UPLOAD
          );
        }

        this.hasAnalysis = this.biometryChecklist.some(item => item.status === ChecklistStatusEnum.ANALYSIS);
        this.handleUploadFinished(!this.uploadChecklist.length);
        this.calculateMaxSteps();
      });
  }

  private getEmail(cli){
    this.email = cli?.client?.email;
  }
  private setDownloadLinkSubscription(): void {
    this.store$
      .select(fromProposal.selectors.selectShowDownloadLink)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(showDownloadLink => {
        this.showContractDownload = showDownloadLink;
      });
  }

  private getSendType(): void {
    this.store$
      .select(fromFormalization.selectors.selectSendTypes)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(sendTypes => (this.sendTypes = sendTypes));

    this.store$.dispatch(new fromFormalization.actions.GetSendTypesAction());
  }

  public depositaryCase(): void {
    this.depositary
      ? (this.isDepositary = `${this.translate.instant('FORMALIZATION-STEP-DESCRIPTION-DEPOSITARY')}`)
      : (this.isDepositary = `${this.translate.instant('FORMALIZATION-STEP-DESCRIPTION')}`);
  }

  public handleBiometryFinished(response: boolean) {
    this.isBiometryAndSignatureDone = response;
    if (response && this.isCrossedFlux) {
      this.biometryTitle = '';
    }
  }

  public handlePaymentForm(paymentForm: FormPaymentModel): void {
    this.genTagger.setTag({
      page_location: this.category,
      page_section: this.translate.instant('FORMALIZATION-STEP-RECEIVEMENT'),
      event_element: 'input.radio',
      event_label: paymentForm?.description,
      event_action: Tag.Selecionar
    });
    if (paymentForm) {
      this.paymentForm = paymentForm;
      this.incompletePayment = false;
      return;
    }
    this.incompletePayment = true;
  }

  public handleBipartitePaymentForm(paymentBipartiteForm: FormBipartiteModel): void {
    if (paymentBipartiteForm) {
      this.paymentBipartiteForm = paymentBipartiteForm;
      this.incompleteBipartitePayment = false;
      return;
    }
    this.incompleteBipartitePayment = true;
  }

  public handleUploadFinished(response: boolean) {
    this.uploadsComplete = response;
    //if( this.uploadsComplete) this.pollingChecklist
  }

  public getProposalContract(): void {
    this.genTagger.setTag({
      page_location: this.category,
      page_section: this.translate.instant('FORMALIZATION-CONDITION-CARD-ABOUT'),
      event_element: 'a.href',
      event_label: this.showTitleConditionsLink(),
      event_action: Tag.Click
    });
    this.simulationService
      .getProposalContract(this.proposal.id, this.proposalData.contract.UUID)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: ConfirmationContractResponse) => this.downloadProposalContract(res));
  }

  private downloadProposalContract(contract: ConfirmationContractResponse) {
    const linkSource = `data:${contract.mimeType};base64,${contract.data}`;
    const downloadLink = document.createElement('a');
    const fileName = `${contract.name}.pdf`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  public getAgentsLegal(payload: { agentsLegal: AgentLegalPersonDTO[]; isReturningToEdit: boolean }): void {
    if (payload) { }
    this.store$.dispatch(new fromFormalization.actions.SendAgentLegalAction(payload));
  }

  public setAgentLegal() {
    this.uploadChecklist.forEach(item => {
      if (item.client.type === CustomerTypeEnum.OPTIONAL_LEGAL_REPRESENTATIVE) {
        this.clientList.push(item.client);
        this.clientList = [...this.clientList].sort((a, b) => +a.id - +b.id);
      }
    });
    this.showUploadRPO = false;
  }

  public noAdditionalAgents() {
    this.hasUploadRPP = false;
  }

  private verifyBipartido(hasBipartiteMandatory: boolean) {
    if (!hasBipartiteMandatory) {
      this.paymentBipartiteForm = { typeBipartite: 'C', flgBipartite: true };
      this.showBipartido = true;
      this.handleBipartitePaymentForm(this.paymentBipartiteForm);
    }
    this.calculateMaxSteps();
  }

  public finishFormalization(): void {
    this.verifyBipartido(this.bipartiteMandatory);

    if (!this.paymentBipartiteForm || typeof this.paymentBipartiteForm.flgBipartite === 'undefined') {
      this.paymentBipartiteForm = {
        typeBipartite: this.proposalData?.channelSupplier?.bipartite?.type || 'C',
        flgBipartite: false
      };
    }

    const payload = {
      proposal: { ...this.paymentBipartiteForm, id: this.proposalId },
      billet: !this.paymentForm ? null : this.paymentForm
    };
    this.store$.dispatch(new fromFormalization.actions.SubmitFormalizationAction(payload));
    this.refreshCheckList();
    this.isShowOpenFinanceModal();
  }

  refreshCheckList() {
    this.store$
      .select(fromFormalization.selectors.selectStatus)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(status => {
        if (status === 1) {
          this.store$.dispatch(new fromProposal.actions.GetUploadChecklistAction({ proposalId: this.proposal.id, isPendency: false }));
          this.setChecklistsSubscription();
        }
      });
  }

  public finishFormalizationMobile(): void {
    //    this.router.navigate(['/login']);
  }

  private calculateMaxSteps(): void {
    const flags = [];
    flags.push(this.hasBiometrySection());
    flags.push(this.hasUploadSection());
    flags.push(this.hasReceivementSection());
    flags.push(this.hasBipartiteSection());

    this.maxSteps = flags.reduce((acc, curr) => {
      if (curr) {
        acc += 1;
      }
      return acc;
    }, 0);
  }

  public getSectionPosition(currentSection: string): number {
    let position = 0;
    const hasBiometrySection = this.hasBiometrySection();
    const hasUploadSection = this.hasUploadSection();
    const hasReceivementSection = this.hasReceivementSection();

    switch (currentSection) {
      case 'biometry':
        position = 1;
        break;
      case 'upload':
        position = hasBiometrySection ? 2 : 1;
        break;
      case 'billet':
        position = 2;

        if (hasBiometrySection && hasUploadSection) {
          position = 3;
        }

        if (!hasBiometrySection && !hasUploadSection) {
          position = 1;
        }
        break;
      case 'bipartite':
        position = 3;

        if (
          (!hasBiometrySection && !hasUploadSection && hasReceivementSection) ||
          (!hasBiometrySection && hasUploadSection && !hasReceivementSection) ||
          (hasBiometrySection && !hasUploadSection && !hasReceivementSection)
        ) {
          position = 2;
        }

        if (hasBiometrySection && hasUploadSection && hasReceivementSection) {
          position = 4;
        }

        if (!hasBiometrySection && !hasUploadSection && !hasReceivementSection) {
          position = 1;
        }

        break;
      default:
        position = 0;
        break;
    }
    return position;
  }

  public scrollToNextSection(currentStep: string) {
    this.genTagger.setTag({
      page_location: this.category,
      page_section: this.translate.instant('FORMALIZATION-STEP-TITLE'),
      event_element: 'button',
      event_label: 'proximo step',
      event_action: Tag.Click
    });

    let nextStep = '';
    const hasUploadSection = this.hasUploadSection();
    const hasReceivementSection = this.hasReceivementSection();
    const hasBipartiteSection = this.hasBipartiteSection();

    switch (currentStep) {
      case 'biometry':
        if (hasUploadSection) {
          nextStep = 'upload';
        }

        if (!hasUploadSection && hasReceivementSection) {
          nextStep = 'billet';
        }

        if (!hasUploadSection && !hasReceivementSection && hasBipartiteSection) {
          nextStep = 'bipartite';
        }
        break;
      case 'upload':
        if (hasReceivementSection) {
          nextStep = 'billet';
        }

        if (!hasReceivementSection && hasBipartiteSection) {
          nextStep = 'bipartite';
        }
        break;
      case 'billet':
        if (hasBipartiteSection) {
          nextStep = 'bipartite';
        }
        break;
      default:
        nextStep = currentStep;
        break;
    }

    document.getElementById(nextStep).scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  private hasBiometrySection(): boolean {
    return !!this.biometryChecklist && !!this.biometryChecklist.length;
  }

  private hasUploadSection(): boolean {
    return !!this.uploadChecklist && !!this.uploadChecklist.length;
  }

  private hasReceivementSection(): boolean {
    return !!(this.proposal && this.proposal.paymentType && this.proposal.paymentType.code !== PaymentTypeEnum.ACCOUNT);
  }

  private hasBipartiteSection(): boolean {
    return !!this.showBipartido && this.proposalData.proposalData.product !== this.productType.CSCP;
  }

  public isFormalizationCompleted(): boolean {
    if (!this.proposalId) return false;

    const hasBiometrySection = this.hasBiometrySection();
    const hasUploadSection = this.hasUploadSection();
    const hasReceivementSection = this.hasReceivementSection();
    const hasBipartiteSection = this.hasBipartiteSection();

    const isBiometryComplete = hasBiometrySection ? this.isBiometryAndSignatureDone : true;
    const isUploadComplete = hasUploadSection ? this.uploadsComplete : true;
    const isReceivementComplete = hasReceivementSection ? !this.incompletePayment : true;
    const isBipartiteComplete = hasBipartiteSection ? !this.incompleteBipartitePayment : true;

    return isBiometryComplete && isUploadComplete && isReceivementComplete && isBipartiteComplete;
  }

  public isFormalizationCompletedMobile(): boolean {
    const hasUploadSection = this.hasUploadSection();
    const isUploadComplete = hasUploadSection ? this.uploadsComplete : true;
    return isUploadComplete
  }

  public showTitleConditionsLink() {
    if (this.proposalData && this.proposalData.proposalData) {
      if (this.proposalData.proposalData.product === this.productType.CDC) {
        return this.translate.instant('STEP-CONFIRMATION-FORMALIZATION-CONDITIONS-LINK-CDC');
      }
      if (this.proposalData.proposalData.product === this.productType.CSC) {
        return this.translate.instant('STEP-CONFIRMATION-FORMALIZATION-CONDITIONS-LINK-CSC') + '.';
      }
      if (this.proposalData.proposalData.product === this.productType.CSCP) {
        return this.translate.instant('STEP-CONFIRMATION-FORMALIZATION-CONDITIONS-LINK-CSCP') + '.';
      }
    }
  }

  public isShowOpenFinanceModal() {
    this.simulationService.getOpenFinance(this.proposalId.toString()).subscribe(data => {
      if (!!data) {
        this.loadOpenFinanceModal();
      }
    });
  }

  private loadOpenFinanceModal(): void {
    this.dialog.open(OpenFinanceModalComponent, {
      width: '520px',
      autoFocus: false,
      data: {
        type: 'proposal-formalization'
      }
    });
  }

  public handleProposalConditionsAcceptedEmitter(event: boolean): void {
    this.proposalConditionsAccepted = event;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.store$.dispatch(new fromProposal.actions.ClearProposalAction());
    this.store$.dispatch(new fromProposal.actions.ClearChecklistAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalStageAction());
    this.store$.dispatch(new fromFormalization.actions.ClearFormalizationAction());
  }
}
