import { HttpErrorResponse, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DocumentScanType } from '@app/document-scan/models/document-scan.enum';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { EnableCameraStepsComponent } from '../../../enable-camera-steps/enable-camera-steps.component';
import { PostFileDTOResponse } from '../../models/api/post.file.model';
import { ChecklistModel } from '../../models/checklist.model';
import { UploadService } from '../../service/upload.service';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit, OnChanges, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() baseModel: ChecklistModel;
  @Input() enableCancel = false;

  @Output() done = new EventEmitter();
  @Output() cancel = new EventEmitter();

  public maxFiles = 0;
  public showList: Array<{ data: string; id: string }> = [];

  public noLimits = false;
  public currentAmount = 0;
  public openDocumentUpload = false;

  public hasError = false;
  public msgError = '';

  public scanType: DocumentScanType = DocumentScanType.GENERIC;

  constructor(
    private sanitizer: DomSanitizer,
    private uploadService: UploadService,
    private translate: TranslateService,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.baseModel && changes.baseModel) {
      this.openDocumentUpload = true;
      this.checkMaxFiles();
    }
  }

  public checkMaxFiles(): void {
    if (!this.baseModel.document.maxAmount) {
      this.noLimits = true;
      return;
    }

    this.maxFiles = this.baseModel.document.maxAmount;
  }

  /**
   * Handles scan from file upload
   * @param scanOutput The output from the picture taken
   */
  public handleScan(scanOutput: any): void {
    this.openDocumentUpload = false;

    if (scanOutput.error || scanOutput.error === '0') {
      const modalRef = this.dialog.open(EnableCameraStepsComponent, {
        disableClose: true,
        position: { top: '150px', left: '10vw' }
      });
      modalRef
        .afterClosed()
        .pipe(take(1))
        .subscribe(() => this.close());

      return;
    }

    scanOutput.images.forEach(image => {
      this.uploadSingleFile(image);
    });
  }

  public uploadSingleFile(file: string): void {
    const { id, proposal, document, uploadGroup, artifact, encrypted } = this.baseModel;
    const obj: ChecklistModel = {
      id,
      proposal,
      document,
      uploadGroup,
      artifact,
      encrypted,
      file: {
        id: '1',
        data: file
      }
    };

    this.uploadService
      .uploadDocument(obj, 'image/png', `image${this.currentAmount}.png`)
      .pipe(filter(event => event.type === HttpEventType.Response))
      .subscribe(
        event => {
          const res = (event as HttpResponse<PostFileDTOResponse>).body;
          const fileId = res.id;

          this.showList.push({ data: file, id: fileId });
          this.currentAmount++;
          this.baseModel.files = [];
          this.baseModel.files.push({ id: fileId });
        },
        (error: HttpErrorResponse) => {
          this.hasError = true;
          this.msgError = this.translate.instant('FILE-UPLOAD-ERROR-MSG');
        }
      );
  }

  public deleteDocument(fileId: string): void {
    this.uploadService.deleteFile(this.baseModel.id, fileId).subscribe(
      () => {
        const index = this.showList.findIndex(ele => ele.id === fileId);
        this.showList.splice(index, 1);
        this.currentAmount--;
      },
      () => {
        this.hasError = true;
        this.msgError = this.translate.instant('ERROR-PHOTO-UPLOAD');
      }
    );
  }

  public disableFinishButton(): boolean {
    return !this.showList.length;
  }

  public newImage(): void {
    this.hasError = false;
    this.openDocumentUpload = true;
  }

  public save(): void {
    this.done.emit();
  }

  public close(): void {
    this.cancel.emit(true);
  }

  public transformToSafeResource(image: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(image);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
