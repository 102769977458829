import { PipeTransform, Pipe } from '@angular/core';
import { MaskPipe } from 'ngx-mask';

@Pipe({ name: 'maskPhone' })
export class MaskPhone extends MaskPipe implements PipeTransform {
  transform(document: any): any {
    if (!document) return;

    if (document.length > 10) {
      return super.transform(document, '(00) 00000-0000');
    } else {
      return super.transform(document, '(00) 0000-0000');
    }
  }
}
