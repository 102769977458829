import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PaymentShowcaseModel } from '@app/showcase/payments/models/payment.model';
import { PaymentsService } from '@app/showcase/payments/services/payments.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { TranslateService } from '@ngx-translate/core';
import { OpenProposalService } from '../../services/open-proposal.service';

@Component({
  selector: 'app-proposal-reason-cancel-dialog',
  templateUrl: './proposal-reason-cancel-dialog.component.html',
  styleUrls: ['./proposal-reason-cancel-dialog.component.scss']
})
export class ProposalReasonCancelDialogComponent implements OnInit {
  @Input() category: string;

  public reasonIdSelected: number;
  public reasons: any = [];
  public cameFromProposalsList = false;
  public cameFromPaymentList = false;
  public cameFromPendency = false;
  public isMassive = false;
  public item: PaymentShowcaseModel = {};
  public indexItem: number;
  private payload: any[] = [];
  public lastItem = false;
  public countItem = 1;

  readonly Selecionar = `${Tag.Selecionar} - Motivo`;
  readonly keepProposalAction: ExtendedTag = {
    event_action_type: Tag.Fechou_Modal,
    event_action_complement: this.translateService.instant('DO-YOU-REALLY-WANTED-CANCEL-THE-PROPOSAL-REASON-DIALOG')
  };

  constructor(
    public dialogRef: MatDialogRef<ProposalReasonCancelDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private proposalService: OpenProposalService,
    private paymentsService: PaymentsService,
    private genTagger: GenTagger,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.cameFromProposalsList = !!this.data && !!this.data.routedBy && this.data.routedBy === 'open-proposal';
    this.cameFromPaymentList = !!this.data && !!this.data.routedBy && this.data.routedBy === 'payments';
    this.cameFromPendency = !!this.data && !!this.data.routedBy && this.data.routedBy === 'pendency';
    this.isMassive = !!this.data && !!this.data.isMassive;

    if (!!this.isMassive && !!this.data.list && !!this.data.list.length) {
      this.indexItem = 0;
      this.item = this.data.list[this.indexItem];
      this.data.list.forEach((p: PaymentShowcaseModel) => {
        const obj = {
          proposalId: p.id.toString(),
          channel: '2',
          cancelId: '',
          reasonDetails: ''
        };
        this.payload.push(obj);
      });
    }

    if (this.cameFromProposalsList || (this.cameFromPaymentList && !this.isMassive) || this.cameFromPendency) {
      this.genTagger.setTag({
        event_category: this.category,
        event_action: `${Tag.AbriuModal} - ${this.translateService.instant(
          'DO-YOU-REALLY-WANTED-CANCEL-THE-PROPOSAL-REASON-DIALOG'
        )}`,
        event_label: this.translateService.instant('DO-YOU-REALLY-WANTED-CANCEL-THE-PROPOSAL-REASON-DIALOG')
      });
    }

    if (this.isMassive && this.cameFromPaymentList) {
      this.genTagger.setTag({
        event_category: this.category,
        event_action: `${Tag.AbriuModal} - ${this.translateService.instant('REASON-CANCEL-PROPOSAL-ASK')}`,
        event_label: this.translateService.instant('REASON-CANCEL-PROPOSAL-ASK')
      });
    }

    this.proposalService.getCancelReasons().subscribe(reasons => {
      this.reasons = reasons;
    });
  }

  sendTagger(event) {
    const selectedReason = this.reasons.find(reason => reason.idDominioConteudo === event);
    if (selectedReason.descricaoDominioConteudo) {
      this.genTagger.setTag({
        event_category: this.category,
        event_action: this.Selecionar,
        event_label: selectedReason.descricaoDominioConteudo
      });
    }
  }

  closeDialog() {
    this.genTagger.setTag({
      event_category: this.category,
      event_action: this.keepProposalAction,
      event_label: this.translateService.instant('KEEP-PROPOSAL')
    });
    this.dialogRef.close();
  }

  cancelProposal() {
    let reason: any = {};
    if (!!this.reasons && !!this.reasons.length) {
      reason = this.reasons.find(r => r.idDominioConteudo === this.reasonIdSelected);
    }
    if (!!this.data && !!reason) {
      if (this.cameFromProposalsList || this.cameFromPendency) {
        this.proposalService
          .cancelProposal(this.data.id, this.reasonIdSelected, reason.descricaoDominioConteudo)
          .subscribe(() => {
            this.dialogRef.close('cancelProposal');
          });
      } else if (this.cameFromPaymentList) {
        this.paymentsService
          .cancelProposal(this.data.id, this.reasonIdSelected, reason.descricaoDominioConteudo)
          .subscribe(() => {
            this.dialogRef.close('cancelProposal');
          });
      }
    }
  }

  goBack() {
    this.genTagger.setTag({
      event_category: this.category,
      event_action: `${Tag.Fechou_Modal} - ${this.translateService.instant('REASON-CANCEL-PROPOSAL-ASK')}`,
      event_label: this.translateService.instant('Back')
    });
    this.dialogRef.close();
  }

  nextItem() {
    const listLength = this.data.list.length;
    this.assignValues();
    this.indexItem = this.indexItem + 1;
    this.item = this.data.list[this.indexItem];
    this.countItem = this.indexItem + 1;
    this.lastItem = this.indexItem === listLength - 1;
  }

  massiveCancelProposal() {
    this.assignValues();
    this.paymentsService.massiveCancelProposal(this.payload).subscribe(response => {
      this.dialogRef.close(response);
    });
  }

  assignValues() {
    const obj = this.payload.find(i => i.proposalId === this.data.list[this.indexItem].id.toString());
    const reason = this.reasons.find(r => r.idDominioConteudo === this.reasonIdSelected);
    if (!!obj && !!reason) {
      obj.cancelId = this.reasonIdSelected.toString();
      obj.reasonDetails = reason.descricaoDominioConteudo;
    }
  }
}
