import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { AppState } from '../../../../../core/state';
import { RemoveAlert } from '../store/alert.actions';
import { alertsSelector } from '../store/alert.state';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.container.html',
  styleUrls: ['./alert.container.scss']
})
export class AlertContainerComponent implements OnInit, OnDestroy {
  alerts: any = [];
  private timeout = 10000;

  subscription: Subscription = new Subscription();

  constructor(private store$: Store<AppState>) {}

  ngOnInit() {
    this.subscription.add(
      this.store$.pipe(select(alertsSelector)).subscribe(state => {
        this.alerts = state.alerts;
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  closeAlert(id?: any) {
    const alert = this.alerts.find(a => a.id === id);
    this.store$.dispatch(new RemoveAlert(alert.id));
  }
}
