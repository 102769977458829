import { Component, OnInit, Input } from '@angular/core';
import { routerTransition } from '@app/router.animations';
import { Router } from '@angular/router';
import { ModalService } from '@shared/components/ui/modal/services/modal.service';
import { StartupService } from '@app/core/startup/services/startup.service';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss'],
  animations: [routerTransition()]
})
export class TermsComponent implements OnInit {
  @Input() terms = '';

  disableBtn = false;

  document: string;

  constructor(
    private router: Router,
    private modalService: ModalService,
    private startupService: StartupService,
    private sessionStorageService: SessionStorageService
  ) {}

  ngOnInit() {}

  public handleScroll(event) {
    const target = event.target;
    if (target.scrollTop + target.offsetHeight > target.scrollHeight - 1) {
      this.disableBtn = false;
    }
  }

  public handleAcceptTerms() {
    this.document = this.sessionStorageService.getUser().document;
    this.startupService.updateUserFlags(document, 'showTerms', false).subscribe(res => {
      this.router.navigate(['/products']);
    });
    this.closeModal();
  }

  public handleCloseTerms() {
    this.closeModal();
    this.router.navigate(['login']);
  }

  public closeModal() {
    this.modalService.closeModal('termsModal');
  }
}
