import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { GET_BRANCHS } from '@shared/graphql/queries/get-branchs.query';
import { GET_CHANNELS } from '@shared/graphql/queries/get-channels.query';
import { GET_PRODUCTS } from '@shared/graphql/queries/get-products.query';
import { GET_SALESPERSONS } from '@shared/graphql/queries/get-salespersons.query';
import { GET_STORES } from '@shared/graphql/queries/get-stores.query';
import { GET_SUBSEGS } from '@shared/graphql/queries/get-subsegs.query';
import {
  getBranchs,
  getBranchsVariables,
  getChannels,
  getChannelsVariables,
  getProducts,
  getSalespersons,
  getSalespersonsVariables,
  getStores,
  getStoresVariables,
  getSubsegs
} from '@shared/graphql/types/query-types';
import { Apollo } from 'apollo-angular';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ManagementFiltersService {
  constructor(private readonly apollo: Apollo, private readonly httpClient: HttpClient) {}

  getSubsidiaryFilters(filter: getBranchsVariables) {
    return this.apollo
      .use('mgmLink')
      .query<getBranchs>({
        query: GET_BRANCHS,
        variables: filter,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(({ data, errors }) => {
          if (errors && errors.length) throw errors[0];

          return data;
        }),
        catchError(e => throwError(e))
      );
  }

  getStoreFilters(filter: Partial<getStoresVariables>) {
    return this.apollo
      .use('mgmLink')
      .query<getStores>({
        query: GET_STORES,
        variables: filter,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(({ data, errors }) => {
          if (errors && errors.length) throw errors[0];

          return data;
        }),
        catchError(e => throwError(e))
      );
  }

  getSubsegmentsFilters() {
    return this.apollo
      .use('mgmLink')
      .query<getSubsegs>({
        query: GET_SUBSEGS,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(({ data, errors }) => {
          if (errors && errors.length) throw errors[0];

          return data;
        }),
        catchError(e => throwError(e))
      );
  }

  getCollaboratorsFilters(filter: getSalespersonsVariables) {
    return this.apollo
      .use('mgmLink')
      .query<getSalespersons>({
        query: GET_SALESPERSONS,
        errorPolicy: 'all',
        variables: filter,
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(({ data, errors }) => {
          if (errors && errors.length) throw errors[0];

          return data;
        }),
        catchError(e => throwError(e))
      );
  }

  getChannelFilters(filter: getChannelsVariables) {
    return this.apollo
      .use('mgmLink')
      .query<getChannels>({
        query: GET_CHANNELS,
        errorPolicy: 'all',
        variables: filter,
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(({ data, errors }) => {
          if (errors && errors.length) throw errors[0];

          return data;
        }),
        catchError(e => throwError(e))
      );
  }

  getProductFilters() {
    return this.apollo
      .use('mgmLink')
      .query<getProducts>({
        query: GET_PRODUCTS,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .pipe(
        map(({ data, errors }) => {
          if (errors && errors.length) throw errors[0];

          return data;
        }),
        catchError(e => throwError(e))
      );
  }

  getBipartiteInfoForStore(storeId: string) {
    const httpParams = new HttpParams({})
      .set('per_page', '1')
      .set('page', '1')
      .set('codigoFornecedorCanal', storeId);

    return this.httpClient.get(`${environment.apiRootUrl}/estrutura/1.0.0/fornecedorescanais/atributos`, {
      params: httpParams
    });
  }
}
