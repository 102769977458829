import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { AccessBlockedModalComponent } from './components/access-blocked-modal/access-blocked-modal.component';
import { ExpirationModalComponent } from './components/expiration-modal/expiration-modal.component';
import { HelpScreenComponent } from './components/help-screen/help-screen.component';
import { MfaModalComponent } from './components/mfa-modal/mfa-modal.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { RecoveryTokenComponent } from './components/recovery-token/recovery-token.component';
import { SplashComponent } from './components/splash/splash.component';
import { TokenInputComponent } from './components/token-input/token-input.component';
import { LoginContainerComponent } from './containers/login-container/login-container.component';
import { LojistaComponent } from './containers/lojista/lojista.component';
import { PasswordRecoveryContainer } from './containers/password-recovery/password-recovery.container.component';
import { SignInContainer } from './containers/sign-in/sign-in.container.component';
import { LoginRoutingModule } from './login-routing.module';

@NgModule({
  declarations: [
    PasswordRecoveryContainer,
    RecoveryTokenComponent,
    NewPasswordComponent,
    ExpirationModalComponent,
    LoginContainerComponent,
    SignInContainer,
    SplashComponent,
    LojistaComponent,
    HelpScreenComponent,
    AccessBlockedModalComponent,
    MfaModalComponent,
    TokenInputComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    SharedModule,
    LoginRoutingModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatIconModule,
    MatRadioModule,
    NgbModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class LoginModule {}
