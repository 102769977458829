<div class="snackbar-container" [ngClass]="type">
  <div class="snackbar-icon">
    <mat-icon>{{ icon }}</mat-icon>
  </div>
  <div class="snackbar-body">
    <ng-content></ng-content>
  </div>
  <button *ngIf="closable" class="snackbar-close" type="button" (click)="closeSnackbar()">
    <mat-icon class="close-icon">close</mat-icon>
  </button>
</div>
