<div class="wrapper-custom-input">
  <input
    *ngIf="!!customMask && !currencyMask"
    [type]="type"
    [mask]="customMask"
    [hiddenInput]="hiddenInput"
    [validation]="false"
    [formControl]="ngControl.control"
    [patterns]="customPatterns"
    [prefix]="prefix"
    [required]="required"
    matSuffix
    matInput
    (focus)="focus.emit($event)"
    (blur)="blur.emit($event)"
    autocomplete="off"
  />

  <input
    *ngIf="!customMask && !currencyMask"
    [minlength]="minlength"
    [maxlength]="maxlength"
    [type]="type"
    [formControl]="ngControl.control"
    [required]="required"
    (keypress)="detectCharacters($event)"
    matSuffix
    matInput
    (focus)="focus.emit($event)"
    (blur)="blur.emit($event)"
    autocomplete="off"
  />

  <input
    *ngIf="!customMask && !!currencyMask"
    currencyMask
    [options]="currencyMask"
    [maxlength]="maxlength"
    [formControl]="ngControl.control"
    [required]="required"
    matSuffix
    (focus)="focus.emit($event)"
    (blur)="blur.emit($event)"
    matInput
    autocomplete="off"
  />

  <ng-container *ngIf="ngControl.control && ngControl.control.dirty">
    <img
      src="./assets/icon-green.svg"
      *ngIf="ngControl.control.valid && ngControl.control.value !== ''"
      alt="icon right green "
      class="valid-icon align-icon"
      matSuffix
    />
    <img
      src="./assets/atention-red.svg"
      *ngIf="ngControl.errors"
      alt="icon right red"
      class="invalid-icon align-icon"
      matSuffix
    />
  </ng-container>
</div>

<ng-container *ngIf="ngControl.control.pristine">
  <div class="invalid-icon align-icon">
    <ng-content> </ng-content>
  </div>
</ng-container>
