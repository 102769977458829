<div>
  <div class="form-row">
    <div class="form-group col-md-9">
      <p class="desktop d-none d-md-block title">{{ 'Certified Agent' | translate }}</p>
    </div>
    <div *ngIf="window.screen.width >= 768" class="form-group col-md-3">
      <button
        type="button"
        class="action-button mt-0"
        (click)="newUserClick()"
        appTagger
        [event_category]="category"
        event_label="{{ 'NEW-AGENT' | translate }}"
      >
        <img src="./assets/santander/icons/ic_circle_add.svg" class="mr-2" alt="close" />
        {{ 'NEW-AGENT' | translate }}
      </button>
    </div>
  </div>
  <div class="mt-2" *ngIf="window.screen.width >= 768; else mobile">
    <div class="table-responsive">
      <table class="table table-borderless table-responsive-md" aria-describedby="Certified Agent">
        <thead class="">
          <th scope="col" class="text-nowrap">{{ 'Name' | translate }}</th>
          <th scope="col" class="text-nowrap">{{ 'CPF' | translate }}</th>
          <th scope="col" class="text-nowrap">{{ 'DUE-DATE' | translate }}</th>
          <th scope="col" class="text-nowrap w-2"></th>
        </thead>
        <tbody>
          <tr *ngFor="let user of users; let i = index">
            <td class="text-left col-profile">
              <div class="user-details">
                <span class="user-name">{{ user.agentName }}</span>
              </div>
            </td>
            <td class="text-nowrap text-left">
              <span class="user-code">{{ user.documentNumber | mask: '000.000.000-00' }}</span>
            </td>
            <td class="text-nowrap text-left">
              <span class="user-code">{{ user.expirationDate | date: 'dd/MM/yyyy' }}</span>
            </td>
            <td class="text-nowrap">
              <button
                type="button"
                class="simple-button mt-0"
                (click)="removeAgent(user.agentName, user.agentCertifiedNumber, user.certificationNumber)"
                appTagger
                [event_category]="category"
                event_label="excluir {{ user.agentCertifiedNumber }}"
              >
                <img src="./assets/santander/icons/ic_delete.svg" alt="delete" />
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="mt-4">
    <div class="container">
      <ng-template #mobile>
        <div *ngFor="let user of users">
          <hr class="my-0 mb-2" />
          <div class="row no-gutters">
            <div class="col">
              <app-certified-agent-card [data]="user"></app-certified-agent-card>
            </div>

            <div class="col-1 mt-2">
              <div *ngIf="window.screen.width < 768" class="actions-menu" [matMenuTriggerFor]="menu">
                <mat-icon appTagger [event_category]="category" event_label="opcões do usuário">more_vert</mat-icon>
                <mat-menu #menu="matMenu">
                  <ng-template [ngIf]="!!user">
                    <button
                      mat-menu-item
                      (click)="removeAgent(user.agentName, user.agentCertifiedNumber, user.certificationNumber)"
                      appTagger
                      [event_category]="category"
                      event_label="{{ 'DELETE' | translate }}"
                    >
                      {{ 'DELETE' | translate }}
                    </button>
                  </ng-template>
                </mat-menu>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div *ngIf="window.screen.width < 768" class="form-group col-md-3">
    <hr class="my-0 mb-2" />
    <button type="button" class="action-button mt-0" (click)="newUserClick()">
      <img src="./assets/santander/icons/ic_circle_add.svg" alt="close" />
      {{ 'NEW-AGENT' | translate }}
    </button>
  </div>
</div>
