import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SideContentService {
  sideContentcloseEvent$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  sideContentcloseEvent = this.sideContentcloseEvent$.asObservable();

  sideContentTitle$: BehaviorSubject<string> = new BehaviorSubject('');
  sideContentTitle = this.sideContentTitle$.asObservable();

  constructor() {}

  updateSideContentTitle(title: string) {
    this.sideContentTitle$.next(title);
  }

  close(shouldClose: boolean) {
    this.sideContentcloseEvent$.next(shouldClose);
  }
}
