<div class="modal-tutorial">
  <h2 mat-dialog-title>{{ data.title | translate }}</h2>
  <mat-dialog-content>{{ data.description | translate }}</mat-dialog-content>

  <mat-dialog-actions class="mt-3">
    <div class="btns d-flex w-100 justify-content-sm-around flex-column flex-sm-row">
      <button mat-stroked-button color="primary" class="btn mb-2 mb-sm-0" (click)="close()">
        {{ 'SKIP-TUTORIAL' | translate }}
      </button>
      <button mat-flat-button color="primary" class="btn" (click)="handleTutorial()">
        {{ 'GO-TO-TUTORIAL' | translate }}
      </button>
    </div>
    <div class="never-ask d-flex w-100 my-4 flex-column justify-content-center align-items-center">
      <mat-checkbox [(ngModel)]="isNeverAsk" class="">{{
        'TOUR-TUTORIAL-MODAL-NEVER-ASK-AGAIN' | translate
      }}</mat-checkbox>
      <div *ngIf="isNeverAsk" class="hint">{{ 'TOUR-TUTORIAL-MODAL-HINT' | translate }}</div>
    </div>
  </mat-dialog-actions>
</div>
