<div
  *ngIf="
    proposalData?.proposalData.client === personsType.PF ||
    (proposalData?.proposalData.client === personsType.PJ && clientList?.length <= 1)
  "
>
  <p class="qr-text">{{ title | translate }}</p>
  <p class="qr-subtext">
    {{ subtitle | translate }}
  </p>
  <div *ngIf="qrCodeSize" class="qr-canvas-container" [ngStyle]="containerStyles">
    <qrcode
      class="qr-code-canvas"
      *ngIf="parsedCrossedFluxUrl"
      [qrdata]="parsedCrossedFluxUrl"
      [width]="qrCodeSize"
      [errorCorrectionLevel]="'M'"
    ></qrcode>
    <div class="qr-code-loading" *ngIf="!parsedCrossedFluxUrl" [ngStyle]="overlayStyles">Carregando...</div>
    <div *ngIf="isTimerActive" class="timer-overlay" [ngStyle]="overlayStyles">
      <ng-container *ngIf="timeLeft !== '0:00'">
        <span class="timer-overlay-time-left">{{ timeLeft }}</span>
        <p class="timer-overlay-msg">{{ 'QR-CODE-TIME-LEFT-MSG' | translate }}</p>
      </ng-container>

      <ng-container *ngIf="timeLeft === '0:00'">
        <span
          class="material-icons timer-overlay-retry"
          (click)="retry()"
          appTagger
          [event_category]="category"
          event_label="{{ 'QR-CODE-TIMER-RETRY' | translate }}"
        >
          replay_circle_filled
        </span>
        <p class="timer-overlay-msg">{{ 'QR-CODE-TIMER-RETRY' | translate }}</p>
      </ng-container>
    </div>
  </div>
</div>

<div *ngIf="proposalData?.proposalData.client === personsType.PJ && clientList?.length > 1">
  <div *ngIf="!representantIsLoading">
    <p class="description">
      <span *ngIf="!someSignatureDone">
        {{ 'FORMALIZATION-PJ-SECTION-1-BLOCK-TITLE' | translate }}
      </span>
    </p>
    <p *ngIf="someSignatureDone">
      {{
        (currentPlatform.isDesktop
          ? 'FORMALIZATION-PJ-SECTION-1-BLOCK-TITLE-AGENT'
          : 'FORMALIZATION-PJ-SECTION-1-BLOCK-TITLE-AGENT-MOBILE') | translate
      }}
    </p>
    <p class="qr-text">{{ title | translate }}</p>
    <p class="qr-subtext">
      {{ subtitle | translate }}
    </p>
    <div class="row">
      <div class="col-12 col-md-5 d-flex align-items-center">
        <mat-radio-group (change)="representantEmit($event)" [(ngModel)]="selectedClient">
          <ng-container *ngFor="let client of clientList; index as i">
            <div class="{{ currentPlatform.isDesktop ? 'p-3' : '' }} pb-2 mb-2">
              <mat-radio-button
                color="primary"
                [value]="client"
                [disabled]="
                  client?.biometryStatus === clientBiometryStatusEnum.DONE ||
                  client?.biometryStatus === clientBiometryStatusEnum.ANALYSIS
                "
                [ngClass]="client?.biometryStatus === clientBiometryStatusEnum.DONE ? 'radio-disabled' : ''"
                [checked]="client?.id === selectedClient?.id"
                (click)="clientSelected(client.name, i)"
              >
                <p class="qr-subtext m-0">
                  {{ i === 0 ? 'Primeiro' : i === 1 ? 'Segundo' : 'Terceiro' }} representante legal
                </p>
                <p class="m-0">
                  <strong>{{ client?.name }}</strong>
                </p>
              </mat-radio-button>
            </div>
          </ng-container>
        </mat-radio-group>
      </div>

      <div class="col-12 col-md-7" *ngIf="currentPlatform.isDesktop">
        <div *ngIf="qrCodeSize" class="qr-canvas-container" [ngStyle]="containerStyles">
          <qrcode
            class="qr-code-canvas mt-3"
            *ngIf="parsedCrossedFluxUrl"
            [qrdata]="parsedCrossedFluxUrl"
            [width]="qrCodeSize"
            [errorCorrectionLevel]="'M'"
          ></qrcode>
          <div class="qr-default mt-3" *ngIf="!parsedCrossedFluxUrl">
            <span class="qr-default-text">{{ 'FORMALIZATION-QRCODE-LABEL-DEFAULT' | translate }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-4 mt-2" *ngIf="currentPlatform.isMobile">
      <div class="col-6" *ngIf="proposalData?.proposalData?.client === personsType.PJ">
        <app-outlined-button (click)="goBack()">
          {{ 'STEP-CONFIRMATION-BACK-BUTTON' | translate }}
        </app-outlined-button>
      </div>
      <div class="{{ proposalData?.proposalData?.client === personsType.PJ ? 'col-6' : 'col-12' }}">
        <app-filled-button [disabled]="!selectedClient" (click)="goToInstructions()">
          {{ 'FORMALIZATION-STEP-CONFIRM-QTD-AGENTS' | translate }}
        </app-filled-button>
      </div>
    </div>
  </div>

  <div *ngIf="representantIsLoading">
    <div [ngClass]="{ 'p-0': retrying, 'p-5': !retrying }">
      <div *ngIf="qrCodeSize" class="qr-canvas-container justify-content-center">
        <div *ngIf="isTimerActive">
          <ng-container *ngIf="timeLeft !== '0:00'">
            <div class="qr-default">
              <p class="qr-default-text">
                <span class="time-left">{{ timeLeft }}</span
                ><br />
                <span>{{ 'QR-CODE-TIME-LEFT-MSG' | translate }}</span>
              </p>
            </div>
          </ng-container>
          <ng-container *ngIf="timeLeft === '0:00'">
            <div class="qr-default">
              <p class="qr-default-text">
                <span class="material-icons timer-overlay-retry" (click)="retry()">
                  replay_circle_filled
                </span>
              </p>
            </div>
          </ng-container>
        </div>

        <qrcode
          class="qr-code-canvas mt-3"
          *ngIf="parsedCrossedFluxUrl && !isTimerActive"
          [qrdata]="parsedCrossedFluxUrl"
          [width]="qrCodeSize"
          [errorCorrectionLevel]="'M'"
        ></qrcode>
      </div>

      <div class="px-4 mt-5">
        <app-outlined-button
          (click)="retry()"
          appTagger
          [event_category]="category"
          event_label="{{ 'FORMALIZATION-NEW-QR-BUTTON' | translate }}"
          >{{ 'FORMALIZATION-NEW-QR-BUTTON' | translate }}</app-outlined-button
        >
      </div>
    </div>
  </div>
</div>
