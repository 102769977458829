<div class="modal-container text-center">
  <p class="modal-title">{{ title | translate }}</p>
  <p class="modal-subtitle">{{ 'STEP-REGISTER-RECOVERY-MODAL-SUBTITLE' | translate }}</p>
  <div class="row">
    <div class="col-12 col-md-6">
      <button
        class="outlined-button cancel-button"
        mat-button
        [mat-dialog-close]="false"
        (click)="registerClick('Não')"
      >
        {{ 'No' | translate }}
      </button>
    </div>
    <div class="col-12 col-md-6">
      <button class="filled-button" mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="registerClick('Sim')">
        {{ 'Yes' | translate }}
      </button>
    </div>
  </div>
</div>
