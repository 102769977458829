<div [ngClass]="customClass">
  <mat-toolbar class="header">
    <span (click)="action('back')">
      <img class="back" src="./assets/images/arrow-left-black.svg" />
    </span>
    <span class="title-calendar">
      <h5 *ngIf="calendarConfig.titleMobile">{{ calendarConfig.titleMobile }}</h5>
    </span>
  </mat-toolbar>

  <div class="container">
    <h2 *ngIf="calendarConfig.subTitleMobile" class="sub-title-mobile">{{ calendarConfig.subTitleMobile }}</h2>

    <!-- Calendário -->
    <section class="calendar">
      <div class="body-full-calendar">
        <!-- Header-calendário-desktop -->
        <div class="header-calendar">
          <span class="ic-close">
            <img
              (click)="closeDialogCalendar()"
              class="closeCalendar cursor--pointer"
              src="./assets/images/ic-get-out.svg"
            />
          </span>
          <span *ngIf="calendarConfig.isDateRange" (click)="clearDate()" class="mt-4 clearDate">Limpar</span>

          <mat-accordion>
            <mat-expansion-panel (click)="expandedToggle()" [expanded]="isExpanded">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{ yearSelected }}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <mat-card (click)="searchByYear(idxYears)" *ngFor="let year of calendarByYears; let idxYears = index">
                {{ year.year }}
              </mat-card>
            </mat-expansion-panel>
          </mat-accordion>
        </div>

        <!-- Collapse de anos -->

        <form *ngIf="calendarConfig.isDateRange" class="dateRange">
          <div class="row">
            <mat-form-field style="padding-right: 0px;" class="col-6 field-date">
              <mat-label>De</mat-label>
              <input
                style="font-weight: 500; padding-bottom: 10px; border-bottom: 1px solid #767676;"
                class="text-dark"
                [ngClass]="{ underlineRed: markedUnderlineDate('startDate') }"
                [value]="dateRange.startDate"
                matInput
                placeholder="dd / mm / aaaa"
                readonly
                #firstDate
              />
            </mat-form-field>

            <mat-form-field style="padding-left: 0px;" class="col-6 field-date">
              <mat-label>Até</mat-label>
              <input
                style="font-weight: 500; padding-bottom: 10px; border-bottom: 1px solid #767676"
                class="text-dark"
                [ngClass]="{ underlineRed: markedUnderlineDate('endDate') }"
                [value]="dateRange.endDate"
                matInput
                placeholder="dd / mm / aaaa"
                readonly
                #secondDate
              />
            </mat-form-field>
          </div>
        </form>

        <mat-divider *ngIf="!calendarConfig.isDateRange" class="mb-2"></mat-divider>

        <!-- Lista de meses -->
        <ng-container *ngFor="let month of calendarByYear">
          <div
            *ngIf="
              !dateComparePastDays(
                yearSelected,
                calendarConfig.isTruncateDays.toFront ? month.numberMonth - 1 : month.numberMonth
              )
            "
          >
            <span class="title-month div-table">{{ month.descMonth }}</span>
            <div class="div-table mt-4">
              <span class="body-calendar col-12">
                <table>
                  <thead>
                    <tr>
                      <th *ngFor="let week of arrWeeks">{{ week.shortWeek }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- first week at month -->
                    <tr>
                      <td
                        tabindex="0"
                        (click)="getDate(day, month.numberMonth, yearSelected)"
                        [ngClass]="{
                          dayPast: dateComparePastDays(yearSelected, month.numberMonth, day) || day === '',
                          dayAfter: dateCompareAfterDays(yearSelected, month.numberMonth, day) || day === '',
                          rangeDate: markPeriodDate(day, month.numberMonth, yearSelected),
                          startDate: startEndDate('startDate', day, month.numberMonth, yearSelected),
                          endDate: startEndDate('endDate', day, month.numberMonth, yearSelected)
                        }"
                        *ngFor="let day of month.days | slice: 0:7"
                      >
                        {{ day }}
                      </td>

                      <!-- second week at month -->
                    </tr>
                    <tr>
                      <td
                        tabindex="0"
                        (click)="getDate(day, month.numberMonth, yearSelected)"
                        [ngClass]="{
                          dayPast: dateComparePastDays(yearSelected, month.numberMonth, day),
                          dayAfter: dateCompareAfterDays(yearSelected, month.numberMonth, day) || day === '',
                          rangeDate: markPeriodDate(day, month.numberMonth, yearSelected),
                          startDate: startEndDate('startDate', day, month.numberMonth, yearSelected),
                          endDate: startEndDate('endDate', day, month.numberMonth, yearSelected)
                        }"
                        *ngFor="let day of month.days | slice: 7:14"
                      >
                        {{ day }}
                      </td>

                      <!-- third week at month -->
                    </tr>
                    <tr>
                      <td
                        tabindex="0"
                        (click)="getDate(day, month.numberMonth, yearSelected)"
                        [ngClass]="{
                          dayPast: dateComparePastDays(yearSelected, month.numberMonth, day),
                          dayAfter: dateCompareAfterDays(yearSelected, month.numberMonth, day) || day === '',
                          rangeDate: markPeriodDate(day, month.numberMonth, yearSelected),
                          startDate: startEndDate('startDate', day, month.numberMonth, yearSelected),
                          endDate: startEndDate('endDate', day, month.numberMonth, yearSelected)
                        }"
                        *ngFor="let day of month.days | slice: 14:21"
                      >
                        {{ day }}
                      </td>

                      <!-- fourth week at month -->
                    </tr>
                    <tr>
                      <td
                        tabindex="0"
                        (click)="getDate(day, month.numberMonth, yearSelected)"
                        [ngClass]="{
                          dayPast: dateComparePastDays(yearSelected, month.numberMonth, day),
                          dayAfter: dateCompareAfterDays(yearSelected, month.numberMonth, day) || day === '',
                          rangeDate: markPeriodDate(day, month.numberMonth, yearSelected),
                          startDate: startEndDate('startDate', day, month.numberMonth, yearSelected),
                          endDate: startEndDate('endDate', day, month.numberMonth, yearSelected)
                        }"
                        *ngFor="let day of month.days | slice: 21:28"
                      >
                        {{ day }}
                      </td>

                      <!-- fifth week at month -->
                    </tr>
                    <tr>
                      <td
                        tabindex="0"
                        (click)="getDate(day, month.numberMonth, yearSelected)"
                        [ngClass]="{
                          dayPast: dateComparePastDays(yearSelected, month.numberMonth, day),
                          dayAfter: dateCompareAfterDays(yearSelected, month.numberMonth, day) || day === '',
                          rangeDate: markPeriodDate(day, month.numberMonth, yearSelected),
                          startDate: startEndDate('startDate', day, month.numberMonth, yearSelected),
                          endDate: startEndDate('endDate', day, month.numberMonth, yearSelected)
                        }"
                        *ngFor="let day of month.days | slice: 28:35"
                      >
                        {{ day }}
                      </td>

                      <!-- sixth week at month -->
                    </tr>
                    <tr>
                      <td
                        tabindex="0"
                        (click)="getDate(day, month.numberMonth, yearSelected)"
                        [ngClass]="{
                          dayPast: dateComparePastDays(yearSelected, month.numberMonth, day),
                          dayAfter: dateCompareAfterDays(yearSelected, month.numberMonth, day) || day === '',
                          rangeDate: markPeriodDate(day, month.numberMonth, yearSelected),
                          startDate: startEndDate('startDate', day, month.numberMonth, yearSelected),
                          endDate: startEndDate('endDate', day, month.numberMonth, yearSelected)
                        }"
                        *ngFor="let day of month.days | slice: 35:42"
                      >
                        {{ day }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </span>
            </div>
            <br />
            <mat-divider></mat-divider>
            <br />
          </div>
        </ng-container>
      </div>
    </section>
    <div class="background-button"></div>
    <button (click)="action('saveDate')" mat-raised-button>{{ titleButton }}</button>
  </div>
</div>
