<div class="container">
  <div class="row">
    <div class="col">
      <img src="./assets/images/Path.svg" alt="Santander Logo" style="margin-left: 123px; margin-bottom: 18px;" />
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p
        style="font-weight: bold;
      text-align: center;"
      >
        Você nao tem ofertas de emprestimo no momento.
      </p>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <a href="#" style="color: red; margin-left: 114px;text-decoration: underline;">
        Quero saber mais
      </a>
    </div>
  </div>
</div>
