<form [formGroup]="form">
  <div class="input-row">
    <mat-form-field color="black" appearance="legacy" class="input">
      <mat-label>{{ 'PRODUCT-SELECTION-FIELD-LABEL' | translate }}</mat-label>
      <mat-select formControlName="product" name="product" (selectionChange)="onProductChange($event)" required>
        <mat-option *ngFor="let product of products" [value]="product" [ngClass]="renderProductColor(product)">
          {{ product?.code + ' - ' + product?.desc }}
        </mat-option>
      </mat-select>
      <mat-hint class="hint mt-1" *ngIf="showEmptyProductsMsg">
        {{ 'PRODUCT-UNAVAILABLE-WARNING-LABEL' | translate }}
      </mat-hint>
      <mat-error *ngIf="!form?.get('product')?.valid">
        {{ 'PRODUCT-SELECTION-INPUT-ERROR-MSG' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
</form>