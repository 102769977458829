import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-payment-plan-calendar',
  templateUrl: './payment-plan-calendar.component.html',
  styleUrls: ['./payment-plan-calendar.component.scss']
})
export class PaymentPlanCalendarComponent implements OnInit {
  @Input() public code: string;
  @Input() showTooltip: boolean;
  @Input() showGraphic: boolean;
  @Input() codePlan: any;
  @Input() flexPlanList: any;

  constructor() {}

  ngOnInit() {

  }
}
