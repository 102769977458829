<form class="search-form" (ngSubmit)="onSearch()" [formGroup]="searchForm">
  <div class="search-wrapper">
    <button type="submit">
      <img src="./assets/images/ic_search.svg" class="esquerda" alt="search" />
    </button>
    <input type="text" placeholder="Buscar" formControlName="searchTerm" />
    <button type="button" *ngIf="isFiltred" (click)="onReset()">
      <img src="./assets/images/ic_close.svg" alt="close" />
    </button>
  </div>
</form>
