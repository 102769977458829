<div class="col-12 terms">
  <button class="recover-link" (click)="openTerms()">{{ 'TERMS-AND-CONDITIONS' | translate }}</button>
  <p class="terms-textarea" [innerHTML]="termsAndConditions" (scroll)="handleScroll($event)"></p>
</div>

<div class="col-12 terms-agreement-checkbox">
  <mat-checkbox
    [disabled]="!scrollComplete || !formValid"
    [checked]="checked"
    (change)="handleTermsAndAgreementsCheckboxChange($event.checked)"
    color="primary"
    appTagger
    [event_category]="category"
    [event_action]="Selecionar"
    event_label="{{ 'ACCEPT-TERMS-AND-CONDITIONS' | translate }}"
    >{{ 'ACCEPT-TERMS-AND-CONDITIONS' | translate }}</mat-checkbox
  >
</div>
