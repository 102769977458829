<div class="wrapper">
  <!-- <h2 mat-dialog-title>
    {{ 'LOAD-CONTENT-ERROR-MODAL-TITLE' | translate }}
    <span class="close">
      <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
      </button>
    </span>
  </h2>-->
  <mat-dialog-content>
    <h3 class="modal-title">{{ 'LOAD-CONTENT-ERROR-MODAL-TITLE' | translate }}</h3>
    <p class="text-center">{{ 'LOAD-CONTENT-ERROR-MODAL-DESCRIPTION' | translate }}</p>
  </mat-dialog-content>
  <mat-dialog-actions class="d-flex justify-content-center">
    <button mat-flat-button color="primary" class="button-red px-5 mt-2" (click)="tryAgain()">
      {{ 'LOAD-CONTENT-ERROR-MODAL-CONFIRM-BUTTON' | translate }}
    </button>
  </mat-dialog-actions>
</div>
