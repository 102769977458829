import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { MinimumDataComponent } from './components/minimum-data/minimum-data.component';
import { ProductSelectionComponent } from './components/product-selection/product-selection.component';
import { IdentificationRoutingModule } from './identification-routing.module';
import { IdentificationContainer } from './identification.container';

@NgModule({
  declarations: [IdentificationContainer, ProductSelectionComponent, MinimumDataComponent],
  imports: [
    CommonModule,
    IdentificationRoutingModule,
    SharedModule,
    NgbModule,
    MatButtonModule,
    MatIconModule,
    MatDatepickerModule,
    MatInputModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDialogModule,
    MatRadioModule,
    MatTooltipModule,
    CurrencyMaskModule
  ],
  providers: [DatePipe]
})
export class IdentificationModule {}
