import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import { selectProduct } from '@app/core/state/identification/identification.selectors';
import * as fromProposal from '@app/core/state/proposal';
import * as fromProposalInformation from '@app/core/state/proposal-information';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import { ResetShowcaseAction } from '@app/core/state/showcase/showcase.actions';
import * as fromSimulation from '@app/core/state/simulation';
import { GetSimulationParameters, SimulationActionTypes } from '@app/core/state/simulation/simulation.actions';
import { selectOffers } from '@app/core/state/simulation/simulation.selectors';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Offers, SimulationOffer } from './model/offer.model';

@Component({
  selector: 'app-step-offers',
  templateUrl: './step-offers.component.html',
  styleUrls: ['./step-offers.component.scss']
})
export class StepOffersComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  proposal: ConfirmationData;
  offers: Offers;
  insuranceOffers: SimulationOffer[] = [];
  noInsuranceOffers: SimulationOffer[] = [];
  public proposalId: string;

  public isMobile: boolean;
  public innerWidth: any;
  public MOBILE_BREAKPOINT = 768;

  public productCode = ProductCodeEnum;

  public category: string;

  constructor(
    private readonly store$: Store<AppState>,
    private updates$: Actions,
    public dialog: MatDialog,
    public router: Router,
    private translateService: TranslateService,
    private genTagger: GenTagger,
    private taggerService: TaggerService
  ) {
    this.innerWidth = window.screen.width;
    this.isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;
  }

  ngOnInit() {
    this.getCurrentProposalId();
    this.selectOffers();

    this.updates$.pipe(ofType(SimulationActionTypes.GET_OFFER_SUCCESS)).subscribe(() => {
      this.selectOffers();
    });

    this.store$
      .pipe(
        select(getConfirmationData),
        filter(data => !!data),
        take(1)
      )
      .subscribe(state => {
        if (state.proposalData?.product) {

        this.taggerService.setProposalType(state.proposalData.product);
        this.category = `/portallojista/criarproposta/${state.proposalData.product}/ofertas`;
        this.genTagger.setTag({
          event_category: this.category,
          event_label: `Melhor oferta - ${state.proposalData.product}`,
          event_action: Tag.pgView
        });
        }
      });
  }

  public selectOffers() {
    this.store$
      .select(selectOffers)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: Offers) => {
        if (state) {
          this.offers = state;
          this.insuranceOffers = this.offers.simulationOffer.filter(f => f.hasInsurance);
          this.noInsuranceOffers = this.offers.simulationOffer.filter(f => !f.hasInsurance);

          if (this.offers.shopkeeperData.riskConditionType === true) {
            this.dialog.open(PionModalComponent, {
              data: {
                title: this.translateService.instant('MODAL-BANK2-TITLE'),
                description: this.translateService.instant('MODAL-BANK2-TEXT'),
                confirmText: this.translateService.instant('MODAL-BANK2-BOTTON'),
                type: 'alert'
              },
              width: '500px'
            });
          }
        }
      });
  }

  public getProposalInformationData() {
    this.store$.dispatch(new fromProposalInformation.actions.GetConfirmationData(this.proposalId));
    this.store$
      .select(getConfirmationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: ConfirmationData) => {
        if (state) {
          this.proposal = state;
        }
      });
  }

  public getCurrentProposalId() {
    this.store$
      .select(selectProposalId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        withLatestFrom(this.store$.select(selectProduct)),
        filter(arr => arr.every(Boolean))
      )
      .subscribe(([proposalId, product]) => {
        this.proposalId = proposalId as string;
        this.getProposalInformationData();
        this.taggerService.setPageLocation(`/portallojista/criarproposta/${product?.code?.toLowerCase()}/ofertas`)
        this.store$.dispatch(new GetSimulationParameters({ proposalId }));
      });
  }

  public getOffer() {
    const payload = {
      proposalId: parseInt(this.proposal.proposalData.id),
      purchaseValue: this.proposal.financedTotalValue,
      entryValue: this.proposal.entryValue,
      financedValue: this.proposal.financedValue
    };

    this.store$.dispatch(new fromSimulation.actions.GetOffer(payload));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.clearProposalData();
  }

  private clearProposalData(): void {
    if (this.router.url !== '/proposal/step-simulation') {
      this.store$.dispatch(new fromProposal.actions.ClearChecklistAction());
      this.store$.dispatch(new fromProposal.actions.ClearProposalStageAction());
      this.store$.dispatch(new fromProposal.actions.ClearProposalAction());
      this.store$.dispatch(new fromSimulation.actions.ClearAll());
      this.store$.dispatch(new fromProposalInformation.actions.ClearProposalInformationAction());
      this.store$.dispatch(new fromProposal.actions.ClearProposalIdAction());
      this.store$.dispatch(new ResetShowcaseAction());
    }
  }
}
