<div id="modal-content-wrapper" *ngIf="!cameFromProposalsList">
  <header id="modal-header" class="text-center mt-4">
    <img src="./assets/images/ic_exclamation_red.svg" width="44px" height="44px" alt="Alerta" class="mb-4" />
    <h1 id="modal-title mt-2 mb-2" mat-dialog-title>{{ 'CANCEL_PROPOSAL' | translate }}?</h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <p class="mb-4">
      {{ 'REQUESTING-PROPOSAL-CANCEL-MSG' | translate }} <b class="text-nowrap">{{ data.id }}</b>
      {{ 'TO' | translate }} <b class="text-nowrap">{{ data.userName }}</b> {{ 'AT-THE-COST-OF' | translate }}
      <b class="text-nowrap">{{ data.value | currency: 'BRL' }}</b
      ><b class="text-nowrap"> ({{ data.amountParcels }}x {{ data.valueParcels | currency: 'BRL' }})</b>.
    </p>
    <p class="mb-4">{{ 'DO-YOU-REALLY-WANTED-CANCEL' | translate }}?</p>
  </section>
  <footer id="modal-footer" mat-dialog-actions class="mb-3">
    <button
      mat-stroked-button
      id="modal-cancel-button"
      color="primary"
      (click)="cancelProposal()"
      appTagger
      [event_category]="category"
      event_action="{{ closeModal }} - {{ 'DELETE-PROPOSAL-PAYMENT-BUTTON-DIALOG' | translate }}"
      event_label="{{ 'DELETE-PROPOSAL-PAYMENT-BUTTON-DIALOG' | translate }}"
    >
      {{ 'DELETE-PROPOSAL-PAYMENT-BUTTON-DIALOG' | translate }}
    </button>
    <button
      mat-flat-button
      id="modal-action-button"
      color="primary"
      (click)="closeButton()"
      appTagger
      [event_category]="category"
      event_label="{{ 'KEEP-PROPOSAL-PAYMENT' | translate }}"
    >
      {{ 'KEEP-PROPOSAL-PAYMENT' | translate }}
    </button>
  </footer>
</div>

<div id="modal-content-wrapper" *ngIf="cameFromProposalsList">
  <header id="modal-header" class="text-center mt-4">
    <img src="./assets/images/ic_exclamation_red.svg" width="44px" height="44px" alt="Alerta" class="mb-4" />
    <h1 id="modal-title mt-2 mb-2" mat-dialog-title>{{ 'DO-YOU-REALLY-WANTED-CANCEL-THE-PROPOSAL' | translate }}</h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <p class="mb-4">{{ 'CANCEL-PROPOSAL-WILL-NOT-ABLE-RETRIEVE-MSG' | translate }}</p>
  </section>
  <footer id="modal-footer" mat-dialog-actions class="mb-3">
    <button
      mat-flat-button
      id="modal-action-button"
      color="primary"
      (click)="closeButton()"
      appTagger
      [event_category]="category"
      event_label="{{ 'KEEP-PROPOSAL-PAYMENT' | translate }}"
    >
      {{ 'KEEP-PROPOSAL' | translate }}
    </button>
    <button
      mat-stroked-button
      id="modal-cancel-button"
      color="primary"
      (click)="cancelProposal()"
      appTagger
      [event_category]="category"
      event_label="{{ 'DELETE-PROPOSAL-PAYMENT-BUTTON-DIALOG' | translate }}"
    >
      {{ 'DELETE-PROPOSAL-BUTTON-DIALOG' | translate }}
    </button>
  </footer>
</div>
