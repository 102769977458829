<div class="container help-container">
  <app-arrow-back (click)="goBackButton()"></app-arrow-back>

  <div class="info-message">
    <p class="title">{{ 'HELP-SCREEN-TITLE' | translate }}</p>
    <p class="text">
      {{ 'HELP-SCREEN-INFO-1' | translate }}
    </p>
    <p class="text">
      {{ 'HELP-SCREEN-INFO-2' | translate }}
    </p>
    <p class="hotline">{{ 'HOTLINE' | translate }}</p>
    <p class="hotline">{{ hotlineNumber }}</p>
  </div>
</div>
