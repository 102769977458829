import { Contract } from '@app/contracts/model/contract.model';
import { ContractsActions, ContractsActionTypes } from './contracts.actions';

export interface ContractsState {
  Contracts: {
    data: Contract[];
    isLoading: boolean;
  };
}

export const initialContractsState: ContractsState = {
  Contracts: {
    data: null,
    isLoading: false
  }
};

export function contractsReducer(state = initialContractsState, action: ContractsActions) {
  switch (action.type) {
    case ContractsActionTypes.GET_ACTIVE_CONTRACTS: {
      return {
        ...state,
        Contracts: { ...state.Contracts, isLoading: true }
      };
    }
    case ContractsActionTypes.GET_ACTIVE_CONTRACTS_SUCCESS: {
      return {
        ...state,
        Contracts: { data: action.payload, isLoading: false }
      };
    }
    case ContractsActionTypes.GET_ACTIVE_CONTRACTS_ERROR: {
      return {
        ...state,
        Contracts: { data: initialContractsState.Contracts.data, isLoading: false }
      };
    }
    default:
      return state;
  }
}
