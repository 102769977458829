import { AppState } from '@app/core/state';
import { CouponStatus } from '@app/proposal-workflow/models/validade-coupon.model';
import { createSelector } from '@ngrx/store';

export const simulationSelector = (state: AppState) => state.simulation;

export const selectStatusProposal = createSelector(
  simulationSelector,
  state => state.statusProposal
);

export const selectLoadingSteps = createSelector(
  simulationSelector,
  state => state.loadingSteps
);

export const selectLoading = createSelector(
  simulationSelector,
  state => state.isLoading
);

export const getErrorBestOffer = createSelector(
  simulationSelector,
  state => state.getErrorBestOffer
);

export const selectError = createSelector(
  simulationSelector,
  state => state.statusProposalError
);

export const selectSimulationData = createSelector(
  simulationSelector,
  state => state.simulationData
);
export const selectFlexInstallmentsNumber = createSelector(
  selectSimulationData,
  state => (state ? state.flexInstallmentsNumber : null)
);
export const selectOffers = createSelector(
  simulationSelector,
  state => state.offers
);
export const selectAllOffers = createSelector(
  simulationSelector,
  state => state.allOffers
);

export const getEnquadramento = createSelector(
  simulationSelector,
  state => state.isEnquadramento
);

/*=== Begin Simulation Parameters ===*/

export const getSimulationParameters = createSelector(
  simulationSelector,
  state => state.simulationParameters
);

export const getDaysToProposalExpiration = createSelector(
  simulationSelector,
  state => state.simulationParameters.daysToProposalExpiration
);

export const getMaxFinancedValue = createSelector(
  simulationSelector,
  state => state.simulationParameters.maxFinancedValue
);

export const getStatusCoupon = createSelector(
  selectStatusProposal,
  state => {
    if (state && state.isValidCoupon) {
      switch (state.isValidCoupon) {
        case 'true':
          return CouponStatus.VALID;
        case 'false':
          return CouponStatus.INVALID;
        default:
          return state.isValidCoupon;
      }
    }
    return null;
  }
);

export const getAllOffers = createSelector(
  simulationSelector,
  state => state.allOffers
);

export const getSubOfferId = createSelector(
  simulationSelector,
  state => state.subOfferId
);

export const getPayloadBestOffers = createSelector(
  simulationSelector,
  state => state
);

export const getBestOffer = createSelector(
  simulationSelector,
  state => state.bestOffer
);

export const getNppInstallments = createSelector(
  simulationSelector,
  state => state.nppInstallments
);

export const recalculateNpp = createSelector(
  simulationSelector,
  state => state.recalculateNpp
);

export const payloadBestOffers = createSelector(
  simulationSelector,
  state => state.payloadBestOffers
);

/*=== End Simulation Parameters ===*/

export const selectShowStepper = createSelector(
  simulationSelector,
  state => state.showStepper
);
