<app-side-content [show]="true" hasBackdrop="true">
  <div class="container px-5">
    <div class="row">
      <div class="col-md-12">
        <h1 class="header-title">{{ 'DETAIL-PROPOSAL' | translate }}</h1>
      </div>
    </div>
    <div class="row no-guters">
      <div class="col-md-4 pt-4">
        <p class="gray text-sm">{{ 'STEP-CONFIRMATION-RELEASED-VALUE' | translate }}</p>
        <p class="text-lg bold mb-4">
          {{ proposal?.financedTotalValue | localeCurrency }}
        </p>

        <p class="gray text-sm">{{ 'STEP-CONFIRMATION-INSTALLMENTS-QTY' | translate }}</p>
        <p class="text-lg bold mb-4">
          {{ proposal?.installmentTotalQuantity }}
          <span *ngIf="proposal?.planType === paymentPlanType.CUSTOMIZED_PLAN">x {{ proposal?.planType }}</span>
          <span *ngIf="proposal?.planType !== paymentPlanType.CUSTOMIZED_PLAN"
            >x {{ proposal?.installmentValue | localeCurrency }}</span
          >
        </p>
        <p class="gray text-sm mb-2">Taxas de empréstimo</p>
        <p class="gray mb-1">Taxa: {{ proposal?.interestRate }}{{ 'STEP-CONFIRMATION-PER-MONTH' | translate }}.</p>
        <p class="gray mb-1">IOF: {{ proposal?.iof | localeCurrency }}.</p>
        <p class="gray mb-1">CET: {{ proposal?.returnRate }}{{ 'STEP-CONFIRMATION-PER-MONTH' | translate }}.</p>
        <div class="mt-3" *ngIf="this.proposal?.contract.UUID && canDownloadCet">
          <div class="d-inline-flex p-1 pointer" mat-ripple (click)="downloadContract()">
            <img src="./assets/images/icon_download.svg" width="25px" alt="download" class="mr-2 align-self-start" />
            <div class="d-flex flex-column">
              <span class="red">Baixar a proposta</span>
              <span class="gray">(CET)</span>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <div class="row no-gutters details-row">
          <span class="gray text-sm col-md-6">
            Segmento
          </span>
          <span class="col-md-6">
            Crédito Pessoal
          </span>
        </div>

        <div class="row no-gutters details-row">
          <span class="gray text-sm col-md-6">
            Loja
          </span>
          <span class="col-md-6">
            --
          </span>
        </div>

        <div class="row no-gutters details-row">
          <span class="gray text-sm col-md-6">
            Valor da compra
          </span>
          <div class="col-md-6">{{ proposal?.financedValue | localeCurrency }}</div>
        </div>

        <div class="row no-gutters details-row">
          <span class="gray text-sm col-md-6">
            Valor da entrada
          </span>
          <span class="col-md-6">
            {{ 0.0 | localeCurrency }}
          </span>
        </div>

        <!--<div class="row no-gutters details-row">
          <div class="gray text-sm col-md-6">
            {{ 'STEP-CONFIRMATION-INTEREST-RATE' | translate }}
          </div>
          <div class="col-md-6">{{ proposal?.interestRate }}{{ 'STEP-CONFIRMATION-PER-MONTH' | translate }}</div>
        </div>-->

        <!-- <div class="row no-gutters details-row">
          <div class="gray text-sm col-md-6">
            {{ 'PAYMENT-PLAN' | translate }}
          </div>
          <div class="col-md-6">{{ proposal?.planType }}</div>
        </div> -->
        <!--
        <div class="row no-gutters details-row">
          <div class="gray text-sm col-md-6">
            {{ 'STEP-CONFIRMATION-IOF' | translate }}
            <mat-icon
              class="info-icon icon-v-inverse"
              matTooltip="{{ 'STEP-CONFIRMATION-IOF-TOOLTIP' | translate }}"
              matTooltipPosition="after"
              matTooltipHideDelay="5000"
              >error_outline
            </mat-icon>
          </div>
          <div class="col-md-6">
            {{ proposal?.iof | localeCurrency }}
          </div>
        </div>-->

        <div class="row no-gutters details-row" *ngIf="proposal?.registrationFeeType === 'IN_CASH'">
          <div class="gray text-sm col-md-6">
            {{ 'STEP-CONFIRMATION-REGISTER-TARIFF' | translate }}
          </div>
          <span class="col-md-6">
            {{ proposal?.registerTariff | localeCurrency }}
          </span>
        </div>

        <div class="row no-gutters details-row">
          <div class="gray text-sm col-md-6">
            {{ 'STEP-CONFIRMATION-INSURANCE' | translate }}
            <mat-icon
              class="info-icon icon-v-inverse"
              matTooltip="{{ 'STEP-CONFIRMATION-FINANCIAL-PROTECTION-TOOLTIP' | translate }}"
              matTooltipPosition="after"
              matTooltipHideDelay="5000"
              >error_outline
            </mat-icon>
          </div>
          <div class="col-md-6">
            {{ proposal?.insurance }}
          </div>
        </div>

        <div class="row no-gutters details-row">
          <span class="gray text-sm col-md-6">
            {{ 'Primeiro vencimento' }}
          </span>
          <span class="col-md-6">
            {{ proposal?.firstInstallmentDate | date: 'dd/MM/yyyy' }}
          </span>
        </div>

        <div class="row no-gutters details-row">
          <div class="gray text-sm col-md-6">
            {{ 'STEP-CONFIRMATION-PAYMENT-PLAN' | translate }}
          </div>
          <span class="col-md-6">
            {{ proposal?.liquidationType }}
          </span>
        </div>

        <!--<div class="row no-gutters details-row">
          <div class="gray text-sm col-md-6">
            {{ 'STEP-CONFIRMATION-RETURN-RATE' | translate }}
            <mat-icon
              class="info-icon icon-v-inverse"
              matTooltip="{{ 'STEP-CONFIRMATION-CET-TOOLTIP' | translate }}"
              matTooltipPosition="after"
              matTooltipHideDelay="5000"
              >error_outline
            </mat-icon>
          </div>
          <div class="col-md-6">{{ proposal?.returnRate }}{{ 'STEP-CONFIRMATION-PER-MONTH' | translate }}</div>
        </div>-->
      </div>
    </div>
    <div class="row footer">
      <div class="col-md-12">
        <div class="d-flex align-items-center justify-content-center mt-4 flex-column">
          <div
            (click)="continueProposal()"
            *ngIf="canContinue"
            class="w-100 mb-3 d-none d-md-block"
            [class.float-continue]="width < 768"
          >
            <button mat-flat-button color="primary" class="h-50 w-100">
              Continuar proposta
            </button>
          </div>
          <div class="btn-cancel pointer px-3 py-3" [class.disabled]="!canCancel" (click)="cancel()">
            <img src="./assets/images/ic_delete.svg" alt="cancel" width="25px" class="mr-2" />
            <span class="red">Cancelar a proposta</span>
          </div>
          <div class="pt-5 mt-5 d-md-none"></div>
        </div>
      </div>
    </div>
  </div>
</app-side-content>
