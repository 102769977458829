import { Component, Input, OnInit } from '@angular/core';
import { Tag } from '@app/tagging/tagger.directive';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GenTagger } from './../../../tagging/gen-tagger';

@Component({
  selector: 'app-filter-ordenation-dialog',
  templateUrl: './filter-ordenation-dialog.component.html',
  styleUrls: ['./filter-ordenation-dialog.component.scss']
})
export class FilterOrdenationDialogComponent implements OnInit {
  @Input() selected: string;

  public options: any;
  public optionSelectedValue: any;
  @Input() category = window.location.href;
  constructor(public activeModal: NgbActiveModal, private genTagger: GenTagger) {
    this.options = [
      { name: 'Tipo', value: 'type', checked: false },
      { name: 'Expira em', value: 'proposalValidityDate', checked: false },
      { name: 'Status', value: 'status', checked: false },
      { name: 'Valor financiado', value: 'valueFinancing', checked: false },
      { name: 'Cliente', value: 'clientName', checked: false }
    ];
  }

  ngOnInit() {
    this.optionSelectedValue = !!this.selected ? this.selected : this.options[0].value;
  }

  onChange(event) {
    this.genTagger.setTag({
      event_category: this.category,
      event_action: `${Tag.Selecionar} - ordenar por`,
      event_label: event
    });
  }

  public onSubmit() {
    const values = [
      {
        sort: this.optionSelectedValue,
        ordenation: 'asc'
      }
    ];
    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.Click,
      event_label: this.options[this.optionSelectedValue].name
    });
    this.activeModal.close(values);
  }
}
