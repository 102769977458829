import { Action } from '@ngrx/store';

/**
 * Action Types
 */
export enum AlertActionsTypes {
  ADD_ALERT = '[Alerts] Add Alert',
  ADD_ALERT_SUCCESS = '[Alerts] Add Alert Success',
  REMOVE_ALERT = '[Alerts] Remove Alert',
  REMOVE_ALERT_SUCCESS = '[Alerts] Remove Alert Success'
}

/**
 * Action Classes
 */

export class AddAlert implements Action {
  readonly type = AlertActionsTypes.ADD_ALERT;
  constructor(public payload: any) {}
}

export class AddAlertSuccess implements Action {
  readonly type = AlertActionsTypes.ADD_ALERT_SUCCESS;
  constructor(public payload: any) {}
}

export class RemoveAlert implements Action {
  readonly type = AlertActionsTypes.REMOVE_ALERT;
  constructor(public payload: any) {}
}

export class RemoveAlertSuccess implements Action {
  readonly type = AlertActionsTypes.REMOVE_ALERT_SUCCESS;
  constructor(public payload?: any) {}
}

export type AlertActions = AddAlert | AddAlertSuccess | RemoveAlert | RemoveAlertSuccess;
