import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-signature-pad',
  templateUrl: './signature-pad.component.html',
  styleUrls: ['./signature-pad.component.scss']
})
export class SignaturePadComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Output() saveSignature = new EventEmitter();
  @ViewChild('sigPad', { static: true }) sigPad: ElementRef;
  private sigPadElement: HTMLCanvasElement;
  private context: CanvasRenderingContext2D;

  public haveSignature = false;

  private signature: string;

  @Input() category: string;
  readonly Insert: Tag = Tag.Insert;
  constructor(private genTagger: GenTagger) {}

  ngOnInit() {
    this.sigPadElement = this.sigPad.nativeElement;
    this.context = this.sigPadElement.getContext('2d');
    this.context.strokeStyle = '#000';
  }

  public onTouchDown(e: TouchEvent): void {
    this.haveSignature = true;
    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'Assinatura',
      event_action: Tag.Insert
    });
    const coords = this.relativeCoordsMobile(e);
    this.context.moveTo(coords.x, coords.y);
  }

  public onTouchMove(e: TouchEvent): void {
    const coords = this.relativeCoordsMobile(e);
    this.context.lineTo(coords.x, coords.y);
    this.context.stroke();
  }

  private relativeCoordsMobile(event: TouchEvent): { x: number; y: number } {
    const r = this.sigPadElement.getBoundingClientRect();
    const x = ((event.changedTouches[0].clientX - r.left) / (r.right - r.left)) * this.sigPadElement.width;
    const y = ((event.changedTouches[0].clientY - r.top) / (r.bottom - r.top)) * this.sigPadElement.height;
    return { x, y };
  }

  public clear(): void {
    this.context.clearRect(0, 0, this.sigPadElement.width, this.sigPadElement.height);
    this.context.beginPath();
    this.haveSignature = false;
  }

  public save(): void {
    if (!this.haveSignature) return;
    this.signature = this.sigPadElement.toDataURL('image/png; base64');
    const canvasBase64 = this.signature.split('data:image/png;base64,');
    this.saveSignature.emit(canvasBase64[1]);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
