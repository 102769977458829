import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';

@Component({
  selector: 'app-payment-massive-upload-status-dialog',
  templateUrl: './payment-massive-upload-status-dialog.component.html',
  styleUrls: ['./payment-massive-upload-status-dialog.component.scss']
})
export class PaymentMassiveUploadStatusDialogComponent implements OnInit {
  @Input() data: any;
  readonly category = '/portallojista/pagamentos';

  public showError = false;
  constructor(
    public dialogRef: MatDialogRef<PaymentMassiveUploadStatusDialogComponent>,
    private genTagger: GenTagger
  ) {}

  ngOnInit() {
    if (this.data.status === 'error-upload') {
      this.showError = true;
    }

    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.pgView,
      event_label: 'Painel de Pagamento - Modal Arquivo Inválido'
    });
  }
}
