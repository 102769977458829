import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StartupService } from '@app/core/startup/services/startup.service';
import { environment } from '@env/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, filter, switchMap, tap } from 'rxjs/operators';

export const IGNORE_REFRESH_INTERCPTOR = 'IGNORE REFRESH';

@Injectable()
export class RefreshInterceptor implements HttpInterceptor {
  constructor(private startupService: StartupService, private dialog: MatDialog, private router: Router) {}

  private refreshingToken = false;

  private refreshedTokenChecker: Subject<{ access_token: string; refresh_token: string }> = new Subject<{
    access_token: string;
    refresh_token: string;
  }>();

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (!JSON.parse(environment.newGateway)) {
      return next.handle(request);
    }

    if (request.headers.get(IGNORE_REFRESH_INTERCPTOR)) {
      return next.handle(
        request.clone({
          headers: request.headers.delete(IGNORE_REFRESH_INTERCPTOR)
        })
      );
    }

    return next.handle(request).pipe(
      catchError(error => {
        if (request.url.endsWith('/protocol/openid-connect/token')) {
          if (error.status !== 401) {
            return this.clearUserAndNavigateToLogin();
          }
        } else if (error.status === 401) {
          return this.refreshToken(request, next);
        }

        return throwError(error);
      })
    );
  }

  getNewAccessToken() {
    if (this.refreshingToken) {
      return this.refreshedTokenChecker.asObservable().pipe(filter(token => token !== null));
    }

    this.refreshingToken = true;
    this.refreshedTokenChecker.next(null);

    return this.startupService.refresh().pipe(
      tap((res: any) => {
        this.refreshingToken = false;
        this.refreshedTokenChecker.next({
          access_token: res.access_token,
          refresh_token: res.refresh_token
        });
      })
    );
  }

  refreshToken(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    return this.getNewAccessToken().pipe(
      switchMap((tokenResponse: any) => {
        this.startupService.setUser({
          accessToken: tokenResponse.accessToken || tokenResponse.access_token || '',
          refreshToken: tokenResponse.refreshToken || tokenResponse.refresh_token || '',
          scope: tokenResponse.scope,
          expiresIn: tokenResponse.expiresIn,
          grantType: 'password'
        });

        return next
          .handle(
            request.clone({
              headers: request.headers.set('Authorization', 'Bearer ' + tokenResponse.access_token)
            })
          )
          .pipe(catchError(() => this.clearUserAndNavigateToLogin()));
      })
    );
  }

  private clearUserAndNavigateToLogin() {
    this.startupService.clearUser();
    this.dialog.closeAll();
    return this.router.navigateByUrl('/whitepage', { skipLocationChange: true }).then(() => {
      this.router.navigate(['/login'], { state: { message: 'AUTHENTICATION-ERROR' } }).then(() => {
        return this.startupService.startup();
      });
    });
  }
}
