import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { GetCrossedFluxInfoModel } from '@app/signup/models/api/get-crossed-flux-info.model';
import { environment } from '@env/environment';
import { Store, select } from '@ngrx/store';
import { RegistrationUnavailableModalComponent } from '@shared/components/registration-unavailable-modal/registration-unavailable-modal.component';
import { Apollo } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { delay, map, take } from 'rxjs/operators';

export enum typeInputChallenge {
  string = 'STRING',
  number = 'NUMBER',
  day = 'DAY',
  month = 'MONTH',
  year = 'YEAR',
  currency = 'CURRENCY',
  date = 'DATE',
  cpf = 'CPF',
  cep = 'CEP'
}

export interface ChallengeAnswerType {
  id: number;
  answer: string;
}

export interface ChallengeType {
  id: number;
  question: string;
  field: Field;
}

export interface Field {
  type: string;
  mask: string;
}

@Injectable({
  providedIn: 'root'
})
export class ChallengeService {
  dialogRef: MatDialogRef<RegistrationUnavailableModalComponent>;

  constructor(
    private http: HttpClient,
    private dialog: MatDialog,
    private $store: Store<AppState>,
    private apollo: Apollo
  ) { }

  startChallengeListMock = [
    {
      flowId: 'AAAA-BBBB-CCCC',
      challengeType: 'EXTERNAL_TOKEN',
      person: 'NATURAL_PERSON' // NATURAL_PERSON ou LEGAL_ENTITY
    },
    {
      flowId: 'AAAA-BBBB-CCCC',
      challengeType: 'POSITIVE_VALIDATION',
      person: 'LEGAL_ENTITY' // NATURAL_PERSON ou LEGAL_ENTITY
    },
    {
      flowId: 'AAAA-BBBB-CCCC',
      challengeType: 'REGISTER_FORM',
      person: 'NATURAL_PERSON' // NATURAL_PERSON ou LEGAL_ENTITY
    },
    {
      flowId: 'AAAA-BBBB-CCCC',
      challengeType: 'TERM',
      person: 'LEGAL_ENTITY' // NATURAL_PERSON ou LEGAL_ENTITY
    },
    {
      flowId: 'AAAA-BBBB-CCCC',
      challengeType: 'PASSWORD',
      person: 'NATURAL_PERSON' // NATURAL_PERSON ou LEGAL_ENTITY
    }
  ];

  // password-recovery
  startChallenge({ fType, documentNumber, idLoginType }): Observable<any> {
    return this.http.post<any>(`${environment.apiRootUrl}/shopkeeper-login/clients/${fType}`, {
      documentNumber,
      idLoginType,
      businessCode: environment.businessCode,
      channel: 'LOJISTA'
    });

    // if (payload.exists) {
    //   return new Observable(obs =>
    //     obs.next(this.startChallengeListMock[Math.floor(Math.random() * this.startChallengeListMock.length)])
    //   );
    // }

    // return new Observable(obs =>
    //   obs.next({
    //     validation: 'client already exists in the system'
    //   })
    // );

    // return new Observable(obs =>
    //   obs.next({
    //     flowId: 'AAAA-BBBB-CCCC',
    //     challengeType: 'BIOMETRY',
    //     person: 'LEGAL_ENTITY'
    //   })
    // );
  }

  goback(): Observable<any> {
    const objPayload = {
      ...this.getMainChallengeVariables()
    };

    return this.http.post<any>(
      `${environment.apiRootUrl}/shopkeeper-login/clients/challenge-goback`,
      { flowId: objPayload.flowId, fType: objPayload.fType }
    );
  }

  getChallenge(payload): Observable<any> {
    const { refresh } = payload;

    const objPayload = {
      ...this.getMainChallengeVariables(),
      refresh
    };
    return this.http.post<any>(
      `${environment.apiRootUrl}/shopkeeper-login/clients/${objPayload.fType}/${objPayload.flowId}/challenge`,
      { refresh }
    );

    // return of({
    //   type: 'POSITIVE_VALIDATION',
    //   remainingAttempts: 3,
    //   challenge: {
    //     term: { title: 'string', content: 'string' }, // FILL ONLY ON SIGN_UP_TERM CHALLENGE TYPE
    //     biometry: { qrCode: 'string' },
    //     registerForm: { term: { title: 'string', content: 'string' } },
    //     questions: [
    //       {
    //         type: 'POSITIVE_VALIDATION',
    //         remainingAttempts: 0,
    //         challenge: {
    //           id: 1,
    //           question: 'Data de Fundação',
    //           field: {
    //             mask: '',
    //             type: typeInputChallenge.date
    //           }
    //         }
    //       },
    //       {
    //         type: 'POSITIVE_VALIDATION',
    //         remainingAttempts: 0,
    //         challenge: {
    //           id: 2,
    //           question: 'Valor da parcelas',
    //           field: {
    //             mask: '',
    //             type: typeInputChallenge.currency
    //           }
    //         }
    //       },
    //       {
    //         type: 'POSITIVE_VALIDATION',
    //         remainingAttempts: 0,
    //         challenge: {
    //           id: 3,
    //           question: 'Dia de Vencimento',
    //           field: {
    //             mask: '',
    //             type: typeInputChallenge.day
    //           }
    //         }
    //       },
    //       {
    //         type: 'POSITIVE_VALIDATION',
    //         remainingAttempts: 0,
    //         challenge: {
    //           id: 4,
    //           question: 'CEP da Empresa',
    //           field: {
    //             mask: '',
    //             type: typeInputChallenge.cep
    //           }
    //         }
    //       }
    //     ]
    //   }
    // });
  }

  answerChallenge({ fType, flowId, challengeType, payload }): Observable<any> {
    return this.http.post<any>(
      `${environment.apiRootUrl}/shopkeeper-login/clients/${fType}/${flowId}/challenge-answer`,
      {
        challengeType,
        challengeAnswer: { ...payload }
      }
    );
  }

  showRegistrationUnavailableModal(options, config?) {
    this.dialogRef = this.dialog.open(RegistrationUnavailableModalComponent, {
      ...config,
      data: {
        title: options.title,
        description: options.description,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
        type: options.type
      }
    });
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map(res => {
        return res;
      })
    );
  }

  public getMainChallengeVariables() {
    let flowId = '';
    let fType = '';
    let challengeType = '';
    let isCrossedFlux = false;

    this.$store
      .pipe(
        select('challenge'),
        take(1)
      )
      .subscribe(res => {
        flowId = res.flowId;
        fType = res.fType;
        challengeType = res.getChallenge ? res.getChallenge.type : '';
        isCrossedFlux = res.isCrossedFlux;
      });

    return { flowId, fType, challengeType, isCrossedFlux };
  }

  public getCrossedFluxInfo(uuid: string): Observable<GetCrossedFluxInfoModel> {
    return of({
      challengeType: 'BIOMETRY',
      flowId: 'flow id aqui',
      flowType: 'sign-up',
      iv: 'iv aq',
      nonce: 'nonce aq'
    }).pipe(delay(4000));

    // const endpoint = `${environment.apiRootUrl}/TBD/${uuid}`;
    // return this.http.get<GetCrossedFluxInfoModel>(endpoint);
  }

  public getCrossedLink(): Observable<{ url: string }> {
    // return of({ link: 'www.bol.com' }).pipe(delay(3000));
    const objPayload = {
      ...this.getMainChallengeVariables()
    };
    const endpoint = `${environment.apiRootUrl}/shopkeeper-login/clients/${objPayload.fType}/${objPayload.flowId}/transferlink`;
    return this.http.get<{ url: string }>(endpoint);
  }

  public postBiometry(uuid, payload) {
    // console.log(payload);
    // return of({
    //   status: 'APPROVED'
    // }).pipe(delay(3000));

    return this.http.post<any>(`${environment.apiRootUrl}/biometry/sign-up/crossflow/${uuid}`, payload);
  }

  public getBiometry(uuid, isCrossedFlux) {
    // return of({
    //   iv: 'IVSTRING',
    //   nonce: 'NONCESTRING',
    //   status: ''
    // });
    if (!isCrossedFlux) {
      const objPayload = {
        ...this.getMainChallengeVariables()
      };
      return this.http.get<any>(
        `${environment.apiRootUrl}/shopkeeper-login/clients/${objPayload.fType}/${objPayload.flowId}/biometry`
      );
    } else {
      return this.http.get<any>(
        `${environment.apiRootUrl}/shopkeeper-login/clients/sign-up/${uuid}/biometry?crossFlow=1`
      );
    }
  }

  // Polling
  public checkBiometryStatus(uuid, timeDelay): Observable<{ status: string; nextStep: string }> {
    // return of(BiometryPollingStatusEnum.CANCELLED).pipe(delay(4000));

    const endpoint = `${environment.apiRootUrl}/shopkeeper-login/clients/crossflow/${uuid}/status`;
    return this.http.get<{ status: string; nextStep: string }>(endpoint).pipe(delay(timeDelay));
  }
}
