import gql from 'graphql-tag';

export const GET_SALESPERSONS = gql`
  query getSalespersons($search: String) {
    getSalespersons(search: $search) {
      idUser
      cdUser
      idSalesPerson
      dsSalesPerson
    }
  }
`;
