import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subject, timer } from 'rxjs';
import { map, repeatWhen, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pion-token-input',
  templateUrl: './token-input.component.html',
  styleUrls: ['./token-input.component.scss']
})
export class TokenInputComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() btnMessage: string;
  @Input() numberOfDigits = 6;
  @Input() notYoursText: string;
  @Input() tokenDestination: string;
  @Input() wrongCode: boolean;
  @Input() tokenDestinationMask: { [key: string]: string };
  @Input() tokenExpirationTime: number;
  @ViewChildren('digitInput') digitInputList: QueryList<ElementRef>;
  @Output() submitEmit: EventEmitter<any> = new EventEmitter();
  @Output() resendEmit: EventEmitter<any> = new EventEmitter();

  private readonly stopTimer$ = new Subject<void>();
  private readonly startTimer$ = new Subject<void>();
  public secondsLeft: number;
  public timeLeftText: string;
  public allowBtn = true;

  public naturalPerson: boolean;

  public digitsForm: FormGroup = this.fb.group({});
  public digitsList: number[] = [];

  constructor(private fb: FormBuilder) {
  }

  ngOnInit(): void {
    this.secondsLeft = this.tokenExpirationTime;
    this.calculateTimeLeft();
    this.setUpTimer();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.numberOfDigits && changes.numberOfDigits) {
      this.createDigitsForm();
    }
    // if (this.wrongCode && changes.wrongCode) {
    //   this.disableFieldsByDefault();
    // }
  }

  ngAfterViewInit(): void {
    this.disableFieldsByDefault();
  }

  private disableFieldsByDefault(): void {
    this.digitInputList.forEach(e => {
      e.nativeElement.value = '';
      if (e !== this.digitInputList.first) {
        e.nativeElement.disabled = true;
      } else {
        e.nativeElement.focus();
      }
    });
  }

  /**
   * Fills the form group property with the proper number of
   * FormControls based on input of desired digits.
   */
  private createDigitsForm(): void {
    this.digitsList = Array.from(Array(this.numberOfDigits).keys());
    this.digitsList.forEach(digit => {
      this.digitsForm.addControl('digit' + digit, new FormControl(null, [Validators.required]));
    });

    this.digitsForm.valueChanges.subscribe((r) => {
      if (this.digitsForm.valid) {
        this.submitEmit.emit(this.getFullToken());
      } else {
        this.submitEmit.emit(false);
      }
    });
  }

  /**
   * In case user presses the delete key into an input that has no value, it selects the previous one
   * and deletes it's content; as long as the current input isn't the first of the list
   * @param digit input position
   * @param e keyboard pressed event
   */
  public isDeletePressed(digit: number, e: KeyboardEvent): void {
    if (e.key === 'Backspace' && digit > 0 && this.digitInputList.toArray()[digit].nativeElement.value === '') {
      this.deletePrevious(digit);
    }
  }
  /**
   * Disables all the inputs after the current digit
   * @param digit input position
   */
  private disableFollowingDigits(digit: number): void {
    const dArr = this.digitInputList.toArray();
    for (let i = 0; i < this.digitInputList.length; i++) {
      if (i > digit) {
        dArr[i].nativeElement.value = '';
        dArr[i].nativeElement.disabled = true;
      }
    }
  }
  /**
   * In case the selected input has no value,
   * disables and deletes the value of the previous position
   * @param digit input position
   */
  private deletePrevious(digit: number): void {
    const dArr = this.digitInputList.toArray();
    const last = dArr[digit - 1].nativeElement;
    dArr[digit].nativeElement.disabled = true;
    last.value = '';
    last.select();
  }
  /**
   * When the value of the input changes, validates if it was deleted or changed.
   * In the first case, disables the following digits if the input is not the last of the list.
   * In the second case, enables the following input and then selects it if the input is not the last
   * of the list.
   * @param value value of the input
   * @param digit input position
   */
  public digitChanged(value: string, digit: number): void {
    const dArr = this.digitInputList.toArray();
    const lastDigit = this.digitInputList.length - 1;

    if (digit !== lastDigit) {
      if (value) {
        dArr[digit + 1].nativeElement.disabled = false;
        dArr[digit + 1].nativeElement.focus();
      } else {
        this.disableFollowingDigits(digit);
      }
    } else {
      this.allowBtn = true;
    }
  }

  /**
   * Method to retrieve full code value from form controls
   */
  private getFullToken(): string {
    let code = '';
    Object.values(this.digitsForm.controls).forEach(control => {
      code += control.value;
    });
    return code;
  }

  public submitToken(): void {
    this.allowBtn = false;
    this.submitEmit.emit(this.getFullToken());
    this.stopTimer();
  }

  public resendToken(): void {
    this.resendEmit.emit('resendToken');
    this.digitsForm.reset();
    this.disableFieldsByDefault();
    this.secondsLeft = this.tokenExpirationTime;
    this.startTimer();
  }

  public doesntGetCode(): void {
    this.resendEmit.emit('doesntGetCode');
    this.digitsForm.reset();
    this.disableFieldsByDefault();
    this.secondsLeft = this.tokenExpirationTime;
    this.startTimer();
  }

  /**
   * Configures timer for countdown
   */
  private setUpTimer(): void {
    timer(0, 1000)
      .pipe(
        map(second => this.tokenExpirationTime - second),
        takeUntil(this.stopTimer$),
        take(this.tokenExpirationTime + 1),
        repeatWhen(() => this.startTimer$)
      )
      .subscribe(decreasedTime => {
        this.secondsLeft = decreasedTime;
        if (decreasedTime < 0) {
          this.stopTimer();
        } else {
          this.calculateTimeLeft();
        }
      });
  }
  private startTimer(): void {
    this.startTimer$.next();
  }
  private stopTimer(): void {
    this.stopTimer$.next();
  }

  /**
   * Calculates the time left to be displayed on the view
   */
  private calculateTimeLeft(): void {
    const mins = Math.floor(this.secondsLeft / 60);
    const secs = this.secondsLeft - mins * 60;
    this.timeLeftText = (mins < 10 ? '0' : '') + mins + ' : ' + (secs < 10 ? '0' : '') + secs;
  }
}
