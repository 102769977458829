import { Directive, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter, map, mergeMap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';

@Directive({
  selector: '[appInvalidBrowserDetect]'
})
export class InvalidBrowserDetectDirective implements OnInit {
  private device;
  private urls = ['/proposal/step-formalization'];

  constructor(
    private router: Router,
    private dialog: MatDialog,
    private deviceService: DeviceDetectorService,
    private activeRouter: ActivatedRoute,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => this.activeRouter),
        map(route => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        mergeMap(route => route.data)
      )
      .subscribe(event => {
        this.urls.forEach(url => {
          if (this.router.url === url) this.checkCompatibility();
        });
      });
  }

  checkCompatibility() {
    this.device = this.deviceService.getDeviceInfo();
    const os = this.device.os.toLowerCase();
    const browser = this.device.browser.toLowerCase();
    if (os === 'mac') {
      if (browser !== 'safari') {
        this.openModal('MAC');
      }
    } else {
      if (browser !== 'chrome' && browser !== 'firefox') {
        this.openModal('WIN');
      }
    }
  }

  openModal(currentOs = 'WIN') {
    this.dialog.open(PionModalComponent, {
      maxWidth: '80vw',
      width: '500px',
      data: {
        title: this.translate.instant('INVALID-BROWSER-TITLE'),
        description: this.translate.instant(`INVALID-BROWSER-${currentOs}`),
        confirmText: this.translate.instant('INVALID-BROWSER-BUTTON'),
        type: ''
      }
    });
  }
}
