import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import * as fromFormalization from '@app/core/state/formalization';
import { FormalizationStatusEnum } from '@app/proposal-workflow/models/formalization-status.enum';
import { ConfirmationContractResponse } from '@app/proposal-workflow/models/step-register-requests';
import { SummaryModel, SummaryStageModel, SummarySteps } from '@app/proposal-workflow/models/summary.model';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { FormalizationProposalDTO } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { FormalizationService } from '../../services/formalization.service';
import { showDownloadContract } from './../../../../../core/state/proposal/proposal.selectors';

export enum SummaryEnum {
  BLANK = 0,
  GREEN = 3,
  YELLOW = 1
}
@Component({
  selector: 'app-formalization-summary',
  templateUrl: './formalization-summary.component.html',
  styleUrls: ['./formalization-summary.component.scss']
})
export class FormalizationSummaryComponent implements OnInit, OnChanges, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() proposal: FormalizationProposalDTO;
  @Input() summaryData: SummaryModel;
  @Input() summaryStatus: SummaryStageModel;
  public summaryResult = SummaryEnum;
  public productCodes = ProductCodeEnum;
  public showSuccessMessage = true;
  public proposalFlag: SummaryEnum;
  public proposalStageText: string;
  public nextStageCode: string;
  public BILLET_NEXT_STAGE_CODE = '14';

  public clientSteps: SummarySteps[] = [];
  public shopkeeperSteps: SummarySteps[] = [];

  public clientLastStepDescription: string;
  public shopkeeperLastStepDescription: string;

  public showDownloadContract = false;

  public modalText: string;

  @Input() category: string;
  constructor(
    private simulationService: SimulationService,
    private router: Router,
    private store$: Store<AppState>,
    private formalizationService: FormalizationService,
    private translateService: TranslateService,
    private genTagger: GenTagger,
    private taggerService: TaggerService
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.summaryStatus && changes.summaryStatus) {
      this.proposalStageText = this.summaryStatus.proposalStatus.vendorStatus;
      this.proposalFlag = this.getProposalStatus();
    }

    if (this.summaryData && this.summaryData != null && changes.summaryData) {
      this.clientSteps = this.summaryData[0].steps.filter(step => step.type === 'CUSTOMER');
      this.shopkeeperSteps = this.summaryData[0].steps.filter(step => step.type === 'SHOPKEEPER');
    }
  }

  ngOnInit() {
    this.genTagger.setTag({
      page_location: this.category + '/resultado',
      event_label: this.modalText,
      page_section: this.modalText,
      event_action: Tag.pgView
    });

    this.setSubmitResponseSubscription();
    this.setDownloadLinkSubscription();
    this.setDefaultLastStepDescriptions();

    this.modalText = this.translateService.instant('FORMALIZATION-SUMMARY-STATUS-TAGGER', {
      proposalId: 'id'
    });
  }

  private setDownloadLinkSubscription(): void {
    this.store$
      .select(showDownloadContract)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(data => !!data)
      )
      .subscribe(data => {
        this.showDownloadContract = data;
      });
  }

  private setSubmitResponseSubscription(): void {
    this.store$
      .select(fromFormalization.selectors.selectSubmitResponse)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(state => !!state)
      )
      .subscribe(res => (this.nextStageCode = res.nextStageCode));
  }

  private setDefaultLastStepDescriptions(): void {
    this.clientLastStepDescription = this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION.4');
    this.shopkeeperLastStepDescription = this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-18');
  }

  public downloadContract(): void {
    if (!this.proposal.id || !this.proposal.contract || !this.proposal.contract.UUID) return;

    const contractId = this.proposal.contract.UUID;

    this.simulationService
      .getProposalContract(this.proposal.id, contractId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: ConfirmationContractResponse) => {
        const linkSource = `data:${res.mimeType};base64,${res.data}`;
        const downloadLink = document.createElement('a');
        const fileName = `${res.name}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  }

  public handleBack(): void {
    this.store$.dispatch(new fromFormalization.actions.SetFormalizationStatusAction(FormalizationStatusEnum.PENDING));
    this.router.navigate(['/showcase/open-proposal']);
  }

  public emitBillet(): void {
    this.formalizationService
      .emitBillet(this.proposal.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: ConfirmationContractResponse) => {
        const linkSource = `data:${res.mimeType};base64,${res.data}`;
        const downloadLink = document.createElement('a');
        const fileName = `${res.name}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  }

  public getProposalStatus(): SummaryEnum {
    if (!this.summaryStatus.groups) return;
    return this.summaryStatus.groups.some(({ semaphore }) => semaphore.value !== SummaryEnum.GREEN)
      ? SummaryEnum.YELLOW
      : SummaryEnum.GREEN;
  }

  public closeSuccessMessage(): void {
    this.showSuccessMessage = false;

    this.genTagger.setTag({
      page_location: this.category + '/resultado',
      event_label: 'X',
      page_section: this.modalText,
      event_element: 'button',
      event_action: Tag.Click
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
