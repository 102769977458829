import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { SubmitIdentificationIncomeAction } from '@app/core/state/pre-analysis/pre-analysis.actions';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { ClearStatusProposal, UpdateNppOffer } from '@app/core/state/simulation/simulation.actions';
import { selectStatusProposal } from '@app/core/state/simulation/simulation.selectors';
import { InputChange } from '@app/proposal-workflow/models/input-change.model';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-monthlyIncome-modal',
  templateUrl: './monthlyIncome-modal.component.html',
  styleUrls: ['./monthlyIncome-modal.component.scss']
})
export class MonthlyIncomeModalComponent implements OnInit {
  public preAnalysis$ = this.store$.pipe(select(selectStatusProposal));
  protected ngUnsubscribe: Subject<any> = new Subject();
  public inputValue: number;
  public canDisplayCard = false;
  public nextStepButton = false;
  public warningIcon = false;
  public checkIcon = false;
  proposal: ConfirmationData;
  public isNPP = JSON.parse(environment.useNPP);
  readonly category = window.location.href;
  readonly pageView: Tag = Tag.pgView;
  readonly Insert: Tag = Tag.Insert;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MonthlyIncomeModalComponent>,
    private store$: Store<AppState>,
    private genTagger: GenTagger
  ) { }

  ngOnInit() {
    this.inputValue = 0;

    this.store$
      .select(getConfirmationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: ConfirmationData) => {
        if (state) {
          this.proposal = state;
        }
      });

    this.store$.dispatch(new ClearStatusProposal());
    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'Qual é a renda mensal?',
      event_action: Tag.pgView
    });
  }

  public closeModal(): void {
    this.dialogRef.close({});
  }

  public continueButton(): void {
    this.dialogRef.close({});
    this.preAnalysis$ = this.store$.pipe(select(selectStatusProposal));
  }

  public clear(): void {
    this.inputValue = 0;
  }

  submitIdentification() {
    this.store$.dispatch(new ClearStatusProposal());

    const payload: InputChange = {
      value: {
        preAnalysis: {
          income: this.inputValue
        }
      },
      input: InputTypes.INPUT_CREDIT
    };

    if (this.isNPP) {
      this.store$.dispatch(new UpdateNppOffer({
        proposalId: parseInt(this.proposal.proposalData.id),
        income: this.inputValue
      }));
    } else {
      this.store$.dispatch(
        new SubmitIdentificationIncomeAction({
          inputChanged: payload,
          income: this.inputValue,
          redirectProposal: false,
          offerPayload: this.data.isOffers ? this.getOfferPayload() : null
        })
      );

    }
    this.nextStepButton = true;
  }

  getOfferPayload() {
    return {
      proposalId: parseInt(this.proposal.proposalData.id),
      purchaseValue: this.proposal.purchaseValue,
      entryValue: this.proposal.entryValue,
      financedValue: this.proposal.financedValue
    };
  }
}
