<div [class]="getClass()">
  <div class="tooltip-icon" [ngClass]="{ visible: tooltipVisible }">
    <span
      (click)="toggleTooltipVisibility()"
      class="icon"
      appTagger
      [event_category]="category"
      [event_label]="eventLabelOpen"
    >
      <mat-icon>info_outline</mat-icon>
    </span>
    <div
      class="tooltip-content-container"
      [style.left.rem]="toLeft ? '-4' : '0'"
      [ngClass]="{ visible: tooltipVisible }"
    >
      <mat-icon
        (click)="toggleTooltipVisibility()"
        class="close"
        appTagger
        [event_category]="category"
        event_label="fechar"
        >close</mat-icon
      >

      <div class="tooltip-content" #containerText>
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
