export enum SimulationStatus {
  processing = 'processing',
  error = 'error',
  unapproved = 'unapproved',
  success = 'completed'
}

export enum Severity {
  ERROR = 'ERROR',
  WARN = 'WARN'
}
