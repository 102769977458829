<app-header-section
  *ngIf="formalization"
  [position]="pos.pos"
  [size]="pos.size"
  titleSection="STEP-REGISTER-INTRODUTION-BANK"
>
</app-header-section>

<div class="row">
  <div class="col-12 col-md-11">
    <div [formGroup]="bankForm">
      <div class="row">
        <div id="bank-input" class="col-12 col-md-6 mb-4">
          <mat-form-field *ngIf="comboParams?.banks as banks">
            <mat-label>{{ 'STEP-REGISTER-BANK-CODE' | translate }}</mat-label>
            <app-search-input
              valueFrom="id"
              label="description"
              [items]="banks | orderBy: 'description'"
              formControlName="bankCode"
            ></app-search-input>
          </mat-form-field>
        </div>

        <div class="col-12 col-md-6 mb-4">
          <mat-form-field *ngIf="comboParams?.accountTypes as accountTypes">
            <mat-label class="label-col">{{ 'STEP-REGISTER-ACCOUNT-TYPE' | translate }}</mat-label>
            <app-search-input
              valueFrom="id"
              label="description"
              [items]="accountTypes | orderBy: 'description'"
              formControlName="accountType"
            ></app-search-input>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <mat-form-field>
            <mat-label class="label-col">{{ 'STEP-REGISTER-AGENCY' | translate }}</mat-label>
            <app-custom-input [required]="true" formControlName="agency" customMask="0000"></app-custom-input>
            <mat-error *ngIf="bankForm.get('agency').touched && bankForm.get('agency').invalid">{{
              'STEP-REGISTER-AGENCY-ERROR' | translate
            }}</mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 mb-4">
          <mat-form-field>
            <mat-label class="label-col">{{ 'STEP-REGISTER-ACCOUNT-NUMBER' | translate }}</mat-label>
            <app-custom-input
              [required]="true"
              [customMask]="accountCodeMask"
              formControlName="accountCode"
            ></app-custom-input>
          </mat-form-field>
          <mat-error *ngIf="bankForm.get('accountCode').touched && bankForm.get('accountCode').invalid">{{
            'STEP-REGISTER-ACCOUNTCODE-ERROR' | translate
          }}</mat-error>
        </div>
      </div>

      <ng-container *ngIf="!hasSantanderAccount">
        <p>
          <strong>{{ 'STEP-REGISTER-CONFIRM-DATA-LABEL' | translate }}</strong>
        </p>

        <div class="row">
          <div class="col-12 col-md-6 mb-4">
            <mat-form-field>
              <mat-label class="label-col">{{ 'STEP-REGISTER-AGENCY' | translate }}</mat-label>
              <app-custom-input
                [required]="true"
                formControlName="confirmAgency"
                customMask="0000"
                regexFilter
              ></app-custom-input>
              <!-- <mat-error *ngIf="bankForm.get('confirmAgency').touched && bankForm.get('confirmAgency').invalid">{{
                'STEP-REGISTER-CONFIRM-AGENCY-ERROR' | translate
              }}</mat-error> -->
            </mat-form-field>
          </div>
          <div class="col-12 col-md-6 mb-4">
            <mat-form-field>
              <mat-label class="label-col">{{ 'STEP-REGISTER-ACCOUNT-NUMBER' | translate }}</mat-label>
              <app-custom-input
                [required]="true"
                [customMask]="confirmAccountCodeMask"
                formControlName="confirmAccountCode"
                regexFilter
              ></app-custom-input>
              <!-- <mat-error
                *ngIf="bankForm.get('confirmAccountCode').touched && bankForm.get('confirmAccountCode').invalid"
                >{{ 'STEP-REGISTER-CONFIRM-ACCOUNTCODE-ERROR' | translate }}</mat-error
              > -->
            </mat-form-field>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
