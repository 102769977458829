<!--<mat-drawer-container [hasBackdrop]="hasBackdrop">-->
<mat-drawer [ngClass]="customClass" mode="side" position="end" opened class="side-content" *ngIf="show">
  <header class="header-side" [ngClass]="customHeader ? '' : 'header-margin'">
    <div class="item-header-side1 mobile d-sm-none" *ngIf="customHeader">
      <img
        src="./assets/images/ic_arrow_left.svg"
        (click)="closeSideBar()"
        appTagger
        [event_category]="category"
        event_label="voltar"
      />
    </div>
    <div class="item-header-side2 mobile d-sm-none" *ngIf="customHeader">{{ sideNavTitle | translate }}</div>
    <div class="item-header-side3">
      <img
        src="./assets/images/ic_close.svg"
        (click)="closeMenuMobile()"
        appTagger
        [event_category]="category"
        event_action="Fechou modal - {{ name }}"
        event_label="x"
      />
    </div>
  </header>
  <ng-content></ng-content>
</mat-drawer>
<!--</mat-drawer-container>-->
<!---->
