export class FormPaymentModel {
  id: string;
  code: string;
  description: string;
}

export class BacenAnswersModel {
  code: BacenAnswersCodeEnum;
  flag: boolean;
}

export enum BacenAnswersCodeEnum {
  FIRSTANSWER = 'AUTLIMCRED',
  SECONDANSWER = 'AUTDEBPCTA'
}

export enum BacenAnswersValue {
  YES = 'S',
  NO = 'N'
}
