import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-not-available',
  templateUrl: './dialog-not-available.component.html',
  styleUrls: ['./dialog-not-available.component.scss']
})
export class DialogNotAvailableComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<DialogNotAvailableComponent>) {}

  ngOnInit(): void {}

  ok(): void {
    this.dialogRef.close(false);
  }
}
