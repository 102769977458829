<div>
  <div class="step-item" *ngFor="let item of itens">
    <div class="step-col pl-3">
      <img src="{{ item.icon }}" alt="icon" class="icon-status mr-2" />
    </div>
    <div class="step-col">
      <p class="bold">{{ item.groupName }}</p>
      <p>{{ item.text }} &nbsp;</p>
      <p>{{ item.date | dateFormatPipe }} &nbsp;</p>
    </div>
    <section
      class="example-section"
      [ngClass]="{
        'yellow-progress': item.color === 'YELLOW',
        'blank-progress': item.color === 'BLANK',
        'red-progress': item.color === 'RED',
        'green-progress': item.color === 'GREEN'
      }"
    >
      <div class="progress-div"></div>
    </section>
  </div>
</div>
