<div class="modal-header">
  <div>
    <h3 class="modal-title">{{ 'PROPOSALS-CANCEL-TITLE' | translate }}</h3>
    <button
      type="button"
      class="close"
      aria-label="Close"
      (click)="activeModal.dismiss()"
      appTagger
      [event_action]="closeAction"
      [event_category]="category"
      event_label="x"
    >
      <img aria-hidden="true" src="./assets/ic_close.svg" alt="icon close" />
    </button>
  </div>

  <p *ngIf="!cancelSuccess">
    {{ data.length }} {{ data.length === 1 ? 'solicitação' : 'solicitações' }} - {{ 'TOTAL' | translate }}
    {{ sumCancelValues | currency: 'BRL' }}
  </p>
  <p *ngIf="!!cancelSuccess">
    <span *ngIf="!!countSuccessCancel.length" class="count-values mr-4">
      <img aria-hidden="true" src="./assets/images/ic_check.svg" alt="icon check" class="mr-2" />
      {{ countSuccessCancel.length }} {{ countSuccessCancel.length === 1 ? 'cancelamento' : 'cancelamentos' }}
      <span *ngIf="window.screen.width > 768"
        >- {{ 'TOTAL' | translate }} {{ sumSuccessCancel | currency: 'BRL' }}</span
      >
    </span>
    <span *ngIf="!!countUnsuccessfulCancel.length" class="count-values">
      <img aria-hidden="true" src="./assets/images/ic_error.svg" alt="icon error" class="mr-2" />
      {{ countUnsuccessfulCancel.length }}
      {{ countUnsuccessfulCancel.length === 1 ? 'cancelamento' : 'cancelamentos' }}
      <span *ngIf="window.screen.width > 768"
        >- {{ 'TOTAL' | translate }} {{ sumUnsuccessfulCancel | currency: 'BRL' }}</span
      >
    </span>
  </p>
</div>

<div class="modal-body">
  <div class="table-responsive">
    <table class="table table-responsive-md">
      <thead>
        <th scope="col" class="text-nowrap first-column">{{ 'Number' | translate }}</th>
        <th scope="col" class="text-nowrap">{{ 'PRODUCT' | translate }}</th>
        <th scope="col" class="text-nowrap">{{ 'FINANCED-VALUE-INPUT' | translate }}</th>
        <th scope="col" class="text-nowrap">{{ 'CLIENT' | translate }}</th>
        <th scope="col" class="text-nowrap last-column">Status</th>
      </thead>

      <tbody>
        <tr *ngFor="let item of data" [ngClass]="{ 'unsuccessful-cancel': !!cancelSuccess && !item.successCancel }">
          <td class="text-nowrap first-column">
            <span>{{ item.id }}</span>
          </td>

          <td class="text-nowrap">
            <span *ngIf="item.type === 'CDC'" class="badge badge-cdc">{{ item.type }}</span>
            <span *ngIf="item.type === 'CSC'" class="badge badge-csc">{{ item.type }}</span>
            <span *ngIf="item.type === 'CSCP'" class="badge badge-cscp">{{ item.type }}</span>
          </td>

          <td class="text-nowrap">
            <div class="swap-text">
              <span class="swap-text-item">{{ item.value | currency: 'BRL' }}</span>
              <span class="swap-text-item">
                <small>{{ item.amountParcels }}x {{ item.valueParcels | currency: 'BRL' }}</small>
              </span>
            </div>
          </td>

          <td class="text-nowrap">
            <div class="swap-text">
              <span class="swap-text-item">{{ item.user }}</span>
              <span class="swap-text-item">
                {{ item.documentNumber | mask: '000.000.000-00' }}
              </span>
            </div>
          </td>

          <td class="status-column last-column">
            <div>
              <img src="./assets/santander/icons/{{ item.statusIcon }}" alt="icon" class="icon-status mr-2" />
              <span>{{ item.statusDescription }}</span>
              <img
                *ngIf="!!item.statusTooltipMessage"
                src="./assets/images/tooltip-payment-col.svg"
                alt="icon"
                class="icon-status ml-2"
                matTooltip="{{ item.statusTooltipMessage }}"
                matTooltipClass="custom-tooltip-proposal-massive-cancel"
                matTooltipHideDelay="1000"
              />
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="responsive-content">
    <ul>
      <li *ngFor="let item of data" [ngClass]="{ 'unsuccessful-cancel': !!cancelSuccess && !item.successCancel }">
        <div class="responsive-content-row">
          <div class="responsive-content-col mr-5">
            <span class="title">{{ 'Number' | translate }}</span>
            <span>{{ item.id }}</span>
          </div>

          <div class="responsive-content-col">
            <span class="title">{{ 'Value' | translate }}</span>
            <div class="swap-text">
              <span class="swap-text-item">{{ item.value | currency: 'BRL' }}</span>
              <span class="swap-text-item">
                <small>{{ item.amountParcels }}x {{ item.valueParcels | currency: 'BRL' }}</small>
              </span>
            </div>
          </div>
        </div>

        <div class="responsive-content-row">
          <div class="responsive-content-col">
            <span class="title">Status</span>
            <div class="status-column">
              <img src="./assets/santander/icons/{{ item.statusIcon }}" alt="icon" class="icon-status mr-2" />
              <span>{{ item.statusDescription }}</span>
              <img
                *ngIf="!!item.statusTooltipMessage"
                src="./assets/images/tooltip-payment-col.svg"
                alt="icon"
                class="icon-status ml-2"
                matTooltip="{{ item.statusTooltipMessage }}"
                matTooltipClass="custom-tooltip-proposal-massive-cancel"
                matTooltipHideDelay="1000"
              />
            </div>
          </div>
        </div>

        <div class="responsive-content-row space-between">
          <div class="responsive-content-col mr-5">
            <span class="title">{{ 'CLIENT' | translate }}</span>
            <div class="swap-text">
              <span class="swap-text-item">{{ item.user }}</span>
              <span class="swap-text-item">
                {{ item.documentNumber | mask: '000.000.000-00' }}
              </span>
            </div>
          </div>

          <div class="responsive-content-col">
            <span *ngIf="item.type === 'CDC'" class="badge badge-cdc">{{ item.type }}</span>
            <span *ngIf="item.type === 'CSC'" class="badge badge-csc">{{ item.type }}</span>
            <span *ngIf="item.type === 'CSCP'" class="badge badge-cscp">{{ item.type }}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>

<div *ngIf="!cancelSuccess" class="modal-footer">
  <p class="question">{{ 'CONFIRM-CANCEL-MULTIPLE-REQUEST' | translate }}</p>
  <button
    type="button"
    class="btn btn-close"
    (click)="activeModal.dismiss()"
    appTagger
    [event_category]="category"
    event_label="{{ 'KEEP-PROPOSAL-PAYMENT' | translate }}"
  >
    {{ 'KEEP-PROPOSAL-PAYMENT' | translate }}
  </button>
  <button
    type="button"
    class="btn btn-action"
    (click)="confirm()"
    appTagger
    [event_category]="category"
    event_label="{{ 'DELETE-PROPOSAL-PAYMENT-BUTTON-DIALOG' | translate }}"
  >
    {{ 'DELETE-PROPOSAL-PAYMENT-BUTTON-DIALOG' | translate }}
  </button>
</div>

<div *ngIf="!!cancelSuccess" class="modal-footer">
  <button
    type="button"
    class="btn btn-action"
    (click)="activeModal.dismiss()"
    appTagger
    [event_category]="category"
    event_label="fechar"
  >
    {{ 'Close' | translate }}
  </button>
</div>
