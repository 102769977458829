<div class="header d-flex align-items-center justify-content-between">
  <button mat-icon-button aria-label="Fechar" class="close-modal" (click)="back()">
    <img src="./assets/images/ic_arrow_left.svg" alt="back" />
  </button>
  <h3 class="modal-title">{{ data.item.title }}</h3>
  <button mat-icon-button aria-label="Fechar" class="close-modal" (click)="goHome()">
    <img src="./assets/images/ic_close.svg" alt="close" />
  </button>
</div>
<div class="search-form">
  <app-faq-search (search)="onSearch($event)"></app-faq-search>
</div>
<div class="faq-content">
  <div [innerHTML]="data.item.content"></div>
</div>
