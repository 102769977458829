<div class="enable-cam-container">
  <span class="material-icons">
    error
  </span>
  <h1>{{ 'ENABLE-CAM-TITLE' | translate }}</h1>
  <p class="enable-cam-subtitle">{{ 'ENABLE-CAM-SUBTITLE' | translate }}</p>
  <!-- ENABLE CAMERA INSTRUCTIONS -->
  <div class="cam-steps">
    <p>
      {{ 'ENABLE-CAM-STEP1-' + os | translate }}
      <span class="enable-cam-bold"> {{ 'ENABLE-CAM-STEP1-2-' + os | translate }}</span>
    </p>
    <p>
      {{ 'ENABLE-CAM-STEP2-' + os | translate }}
      <span class="enable-cam-bold"> {{ 'ENABLE-CAM-STEP2-2-' + os | translate }}</span>
    </p>
    <p>
      {{ 'ENABLE-CAM-STEP3-' + os | translate }}
      <span class="enable-cam-bold"> {{ 'ENABLE-CAM-STEP3-2-' + os | translate }}</span>
    </p>
    <p *ngIf="os === osEnum.ANDROID">
      {{ 'ENABLE-CAM-STEP4-' + os | translate }}
      <span class="enable-cam-bold"> {{ 'ENABLE-CAM-STEP4-2-' + os | translate }}</span>
    </p>
    <p>{{ 'ENABLE-CAM-STEP5-' + os | translate }}</p>
  </div>
  <button (click)="enableCameraInstructionsUnderstood()">{{ 'ENABLE-CAM-BUTTON' | translate }}</button>
</div>
