<div class="container mt-10 bg-sample">
  <div class="row">
    <div class="col-12 col-md-6">
      <h1 class="title">{{ 'FINANCIAL-STATEMENT_TITLE' | translate }}</h1>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-12 statement-tab">
      <mat-tab-group class="header-tab" (selectedTabChange)="onTabClick($event)" #extractTab>
        <mat-tab label="Propostas Efetivadas">
          <div class="body-tab">
            <div class="row mt-4 ">
              <div class="col-12 col-md-12">
                <mat-tab-group class="child-tab-group" #tabGroup (selectedTabChange)="onTabChange($event)">
                  <mat-tab class="child-tab" label="Recebidos">
                    <div class="body-tab">
                      <div class="row mt-4 ">
                        <div class="col-12 col-md-12">
                          <app-table [extractSubTab]="extractSubTab" [callControl]="callControl"
                            [tabSelected]="tabSelected" [perPage]="perPage"
                            [callControlRealizados]="callControlRealizados" [totalElements]="totalElements"
                            [totalPages]="totalPages" [previousPage]="previousPage" isShowingRecebidos="Recebidos"
                            [isShowFilters]="isShowFilters" [isHiddenFilters]="isHiddenFilters"
                            (productSelected)="receivedProduct($event)"></app-table>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                  <mat-tab class="child-tab" *ngIf="productType !== 'CSCP' " label="Realizados">
                    <div class="body-tab">
                      <div class="row mt-4 ">
                        <div class="col-12 col-md-12">
                          <app-table [extractSubTab]="extractSubTab" (productSelected)="receivedProduct($event)"
                            [perPage]="perPage" [tabSelected]="tabSelected"
                            [callControlRealizados]="callControlRealizados" [totalElements]="totalElements"
                            [totalPages]="totalPages" [previousPage]="previousPage"
                            [isShowingRecebidos]="isShowingRecebidos" [isShowFilters]="isShowFilters"
                            [isHiddenFilters]="isHiddenFilters"></app-table>
                        </div>
                      </div>
                    </div>
                  </mat-tab>
                </mat-tab-group>
              </div>
            </div>
          </div>

        </mat-tab>
        <mat-tab *ngIf="productType === 'CSCP'" label="Pagamentos Realizados">
          <div class="body-tab">
            <div class="row mt-4 ">
              <div class="col-12 col-md-12">
                <app-table (productSelected)="receivedProduct($event)" [perPage]="perPage"
                  [totalElements]="totalElements" [tabSelected]="tabSelected" [totalPages]="totalPages"
                  [previousPage]="previousPage" [isShowingRecebidos]="isShowingRecebidos"
                  [isShowFilters]="isShowFilters" [isHiddenFilters]="isHiddenFilters"></app-table>
              </div>
            </div>
          </div>
        </mat-tab>
        <mat-tab *ngIf="productType === 'CSCP'" label="Saldo Futuro">
          <div class="body-tab">
            <div class="row mt-4 ">
              <div class="col-12 col-md-12">
                <app-table (productSelected)="receivedProduct($event)" [perPage]="perPage"
                  [totalElements]="totalElements" [tabSelected]="tabSelected" [totalPages]="totalPages"
                  [previousPage]="previousPage" [isShowingRecebidos]="isShowingRecebidos"
                  [isShowFilters]="isShowFilters" [isHiddenFilters]="isHiddenFilters"></app-table>
              </div>
            </div>
          </div>
        </mat-tab>

      </mat-tab-group>
    </div>
  </div>
</div>
