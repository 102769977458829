import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
// @ts-ignore
import { MatDialog } from '@angular/material/dialog';
import { FaqContentModalComponent } from '@app/faq/faq-content-modal/faq-content-modal.component';
import { FaqSearchComponent } from '@app/faq/faq-search/faq-search.component';
import { FaqService } from './services/faq.service';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {
  @ViewChild('searchComponent', { static: true }) searchComponent: FaqSearchComponent;

  data;
  filteredData = [];
  selected = null;
  isFiltred = false;
  public innerWidth: number;
  public MOBILE_BREAKPOINT = 768;
  public onCategory = false;
  public foundItems = true;

  constructor(
    private modalDialog: MatDialog,
    private service: FaqService,
    private session: SessionStorageService,
    private loc: Location
  ) {}

  ngOnInit() {
    this.getFAQ();
    this.innerWidth = window.screen.width;
  }

  getFAQ() {
    this.service.getFaq().subscribe(res => {
      this.data = res;
      this.resetSearch();
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.screen.width;
  }

  selectFaq(item) {
    if (this.innerWidth < this.MOBILE_BREAKPOINT) {
      const dialogConfig = {
        width: '100vw',
        id: 'faq-modal',
        maxWidth: '100vw',
        panelClass: 'faq-content-modal',
        height: '100%',
        autoFocus: false,
        position: {
          right: '0px',
          top: '0px'
        },
        data: {
          item
        }
      };

      const modalRef = this.modalDialog.open(FaqContentModalComponent, dialogConfig);
      modalRef.afterClosed().subscribe(result => {
        if (result) {
          this.searchComponent.setSearchTerm(result);
          this.search(result);
        }
      });
    } else {
      this.selected = item;
    }
  }

  handleShowMore(category) {
    this.resetSearch();
    this.onCategory = true;
    this.filteredData = [];
    this.filteredData.push(category);
  }

  handleBack() {
    this.resetSearch();
    this.onCategory = !this.onCategory;
  }

  search(searchTerm) {
    this.selected = null;
    if (searchTerm && searchTerm.length > 0) {
      let j = 0;
      this.isFiltred = true;
      for (let i = 0; i < this.data.length; i++) {
        this.filteredData[i].topics = this.data[i].topics.filter(v => {
          return (
            this.removeAccents(new DOMParser().parseFromString(v.title, 'text/html').documentElement.textContent)
              .toLowerCase()
              .includes(this.removeAccents(searchTerm).toLowerCase()) ||
            this.removeAccents(new DOMParser().parseFromString(v.description, 'text/html').documentElement.textContent)
              .toLowerCase()
              .includes(this.removeAccents(searchTerm).toLowerCase()) ||
            this.removeAccents(new DOMParser().parseFromString(v.content, 'text/html').documentElement.textContent)
              .toLowerCase()
              .includes(this.removeAccents(searchTerm).toLowerCase())
          );
        });
        if (this.filteredData[i].topics.length > 0) j++;
      }
      if (j === 0) {
        this.foundItems = false;
      }
      if (j > 0) {
        this.foundItems = true;
      }
    } else {
      this.resetSearch();
    }
  }

  resetSearch() {
    this.foundItems = true;
    this.filteredData = [];
    this.data.forEach(val => this.filteredData.push(Object.assign({}, val)));
    this.selected = null;
    this.isFiltred = false;
  }

  removeAccents(stringToRemove) {
    let string = stringToRemove;
    const map = {
      a: /[\xE0-\xE6]/g,
      e: /[\xE8-\xEB]/g,
      i: /[\xEC-\xEF]/g,
      o: /[\xF2-\xF6]/g,
      u: /[\xF9-\xFC]/g,
      c: /\xE7/g,
      n: /\xF1/g
    };

    // tslint:disable-next-line:forin
    for (const char in map) {
      const rgx = map[char];
      string = string.replace(rgx, char);
    }

    return string;
  }

  public onGoBack(): void {
    if (!this.selected) {
      return this.loc.back();
    }
    this.resetSearch();
  }
}
