import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { OfferPageComponent } from './offer-page/offer-page.component';
import { PreAnaliseComponent } from './pre-analise/pre-analise.component';
import { ProposalPageComponent } from './proposal/proposal-page.component';
import { ProposalSimulationComponent } from './simulation/body/proposal-simulation/proposal-simulation.component';


const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        component: HomePageComponent
      },
      {
        path: 'proposal',
        component: ProposalPageComponent
      },
      {
        path: 'simulation',
        component: ProposalSimulationComponent
      },
      {
        path: 'offer',
        component: OfferPageComponent
      },
      {
        path: 'error',
        component: PreAnaliseComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
