import { Action } from '@ngrx/store';
import { Product } from '@shared/models/product.model';

export enum IdentificationActionTypes {
  GET_IDENTIFICATION_CONFIG = '[Identification] [App] Get Identification Config',
  GET_IDENTIFICATION_CONFIG_SUCCESS = '[Identification] [App] Get Identification Config Success',
  GET_IDENTIFICATION_CONFIG_ERROR = '[Identification] [App] Get Identification Config Error',

  SAVE_PRODUCT = '[Identification] [User] Save Product',
  CLEAR_PRODUCT = '[Identification] [User] Clear Product',

  SUBMIT_IDENTIFICATION = '[Identification] [User] Submit Identification',
  SUBMIT_IDENTIFICATION_SUCCESS = '[Identification] [App] Submit Identification Success',
  SUBMIT_IDENTIFICATION_ERROR = '[Identification] [App] Submit Identificacion Error',

  RESET_IDENTIFICATION = '[Identification] [App] Reset Identification',

  CANCEL_EXISTING_PROPOSAL = '[Identification] [App/User] Cancel Existing Proposal',
  CANCEL_EXISTING_PROPOSAL_SUCCESS = '[Identification] [App] Cancel Existing Proposal Success',
  CANCEL_EXISTING_PROPOSAL_ERROR = '[Identification] [App] Cancel Existing Proposal Error',

  GET_MANAGERS = '[Identification Page] Get Managers',
  GET_MANAGERS_SUCCESS = '[Identification API] Get Managers Success',
  GET_MANAGERS_ERROR = '[Identification API] Get Managers Error'
}

export class GetIdentificationConfigAction implements Action {
  readonly type = IdentificationActionTypes.GET_IDENTIFICATION_CONFIG;
  constructor(public payload?: any) {}
}

export class GetIdentificationConfigSuccessAction implements Action {
  readonly type = IdentificationActionTypes.GET_IDENTIFICATION_CONFIG_SUCCESS;
  constructor(public payload: any) {}
}

export class GetIdentificationConfigErrorAction implements Action {
  readonly type = IdentificationActionTypes.GET_IDENTIFICATION_CONFIG_ERROR;
  constructor(public payload: any) {}
}

export class SaveProductAction implements Action {
  readonly type = IdentificationActionTypes.SAVE_PRODUCT;
  constructor(public payload: Product) {}
}

export class ClearProductAction implements Action {
  readonly type = IdentificationActionTypes.CLEAR_PRODUCT;
  constructor() {}
}

export class SubmitIdentificationAction implements Action {
  readonly type = IdentificationActionTypes.SUBMIT_IDENTIFICATION;
  constructor(public payload: any) {}
}

export class SubmitIdentificationSuccessAction implements Action {
  readonly type = IdentificationActionTypes.SUBMIT_IDENTIFICATION_SUCCESS;
  constructor(public payload: any) {}
}

export class SubmitIdentificationErrorAction implements Action {
  readonly type = IdentificationActionTypes.SUBMIT_IDENTIFICATION_ERROR;
  constructor(public payload: any) {}
}

export class ResetIdentificationAction implements Action {
  readonly type = IdentificationActionTypes.RESET_IDENTIFICATION;
  constructor() {}
}

export class CancelExistingProposalAction implements Action {
  readonly type = IdentificationActionTypes.CANCEL_EXISTING_PROPOSAL;
  constructor(public payload: any) {}
}

export class CancelExistingProposalSuccessAction implements Action {
  readonly type = IdentificationActionTypes.CANCEL_EXISTING_PROPOSAL_SUCCESS;
  constructor(public payload: any) {}
}

export class CancelExistingProposalErrorAction implements Action {
  readonly type = IdentificationActionTypes.CANCEL_EXISTING_PROPOSAL_ERROR;
  constructor(public payload: any) {}
}

export class GetManagersAction implements Action {
  readonly type = IdentificationActionTypes.GET_MANAGERS;
  constructor() {}
}

export class GetManagersActionSuccess implements Action {
  readonly type = IdentificationActionTypes.GET_MANAGERS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetManagersActionError implements Action {
  readonly type = IdentificationActionTypes.GET_MANAGERS_ERROR;
  constructor(public payload: any) {}
}

export type IdentificationActions =
  | GetIdentificationConfigAction
  | GetIdentificationConfigSuccessAction
  | GetIdentificationConfigErrorAction
  | SaveProductAction
  | ClearProductAction
  | SubmitIdentificationAction
  | SubmitIdentificationSuccessAction
  | SubmitIdentificationErrorAction
  | ResetIdentificationAction
  | CancelExistingProposalAction
  | CancelExistingProposalSuccessAction
  | CancelExistingProposalErrorAction
  | GetManagersAction
  | GetManagersActionSuccess
  | GetManagersActionError;
