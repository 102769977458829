import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { StartupService } from './services/startup.service';
import { StartupRoutingModule } from './startup-routing.module';
import { StartupContainerComponent } from './startup.container';

export function startupFactory(startupService: StartupService) {
  return () => startupService.startup();
}

@NgModule({
  declarations: [StartupContainerComponent],
  imports: [CommonModule, StartupRoutingModule, NgbAlertModule, SharedModule],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: startupFactory,
      deps: [StartupService],
      multi: true
    },
    StartupService
  ]
})
export class StartupModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: StartupModule
  ) {
    if (parentModule) {
      throw new Error('StartupModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<StartupModule> {
    return {
      ngModule: StartupModule,
      providers: []
    };
  }
}
