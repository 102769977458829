import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-proposal-body',
  templateUrl: './proposal-body.component.html',
  styleUrls: ['./proposal-body.component.scss']
})
export class ProposalBodyComponent implements OnInit {
  public hiddenProposal = false;

  public propostasAberto = [
    {
      title: 'Etna',
      date: '20/04/2020',
      rate: '20',
      price: '944,00',
      totalPrice: '17.000,00'
    },
    {
      title: 'Etna',
      date: '20/04/2020',
      rate: '20',
      price: '944,00',
      totalPrice: '17.000,00'
    },
    {
      title: 'Etna',
      date: '20/04/2020',
      rate: '20',
      price: '944,00',
      totalPrice: '17.000,00'
    }
  ];
  public propostasPendencias = [
    {
      title: 'Etna',
      date: '20/04/2020',
      rate: '20',
      price: '944,00',
      totalPrice: '17.000,00'
    },
    {
      title: 'Etna',
      date: '20/04/2020',
      rate: '20',
      price: '944,00',
      totalPrice: '17.000,00'
    }
  ];
  public outrasAberto = [
    {
      title: 'Etna',
      date: '20/04/2020',
      rate: '20',
      price: '944,00',
      totalPrice: '17.000,00'
    },
    {
      title: 'Etna',
      date: '20/04/2020',
      rate: '20',
      price: '944,00',
      totalPrice: '17.000,00'
    }
  ];

  // DESKTOP CONFIG
  public customOptionsDesktop: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['<', '>'],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    },
    nav: true
  };

  public propostaAberto: Array<any> = [
    {
      title: 'Etna1',
      date: '20/04/2020'
    },
    {
      title: 'Etna2',
      date: '20/04/2020'
    },
    {
      title: 'Etna3',
      date: '20/04/2020'
    },
    {
      title: 'Etna4',
      date: '20/04/2020'
    },
    {
      title: 'Etna5',
      date: '20/04/2020'
    },
    {
      title: 'Etna6',
      date: '20/04/2020'
    },
    {
      title: 'Etna7',
      date: '20/04/2020'
    },
    {
      title: 'Etna8',
      date: '20/04/2020'
    }
  ];

  public ofertaPersonalizada: Array<any> = [
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    },
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    },
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    },
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    },
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    },
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    },
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    },
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    },
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    },
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    }
  ];

  public ofertaEspecial: Array<any> = [
    {
      title: 'Magazine Luiza',
      text: 'Desconto de ate 20% no mes de maio'
    },
    {
      title: 'Magazine Luiza',
      text: 'Desconto de ate 20% no mes de maio'
    },
    {
      title: 'Magazine Luiza',
      text: 'Desconto de ate 20% no mes de maio'
    },
    {
      title: 'Magazine Luiza',
      text: 'Desconto de ate 20% no mes de maio'
    },
    {
      title: 'Magazine Luiza',
      text: 'Desconto de ate 20% no mes de maio'
    }
  ];
  // variavel para abrir pag para quando nao ha oferta
  public withoutOffers = true;

  public transh = false;

  public uniqueProposal = {
    exist: true,
    title: 'Crédito especial',
    date: '13/02/2020',
    parcel: '18',
    price: '944,44',
    totalPrice: '17.000,00'
  };

  public otherOpenProposal = [
    {
      title: 'Crédito especial',
      date: '13/02/2020',
      parcel: '18',
      price: '944,44',
      totalPrice: '17.000,00'
    },
    {
      title: 'Crédito especial',
      date: '13/02/2020',
      parcel: '18',
      price: '944,44',
      totalPrice: '17.000,00'
    }
  ];

  constructor(public router: Router) {}

  ngOnInit() {}

  public deleteArray(i): void {
    this.hiddenProposal = true;
    // this.propostaAberto.splice(i, 1);
  }

  public chamadaPreAnalise(): void {
    this.router.navigate(['/products/error']);
  }
}
