import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import { ErrorChallenge } from '@app/core/state/challenge/challenge.actions';
import { selectChallengeError } from '@app/core/state/challenge/challenge.selectors';
import { ErrorCodesChallenge } from '@app/core/state/challenge/model/challenge.model';
import * as fromLogin from '@app/core/state/login';
import { LoginService } from '@app/login/services/login.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Countries } from '@shared/constants/countries.enum';
import { Internationalization } from '@shared/interfaces/internationalization.interface';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { FormValidationService } from '@shared/services/form-validation/form-validation.service';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { Observable, of } from 'rxjs';
import { delay, switchMap, take } from 'rxjs/operators';

@Component({
  selector: 'app-sign-in-container',
  templateUrl: './sign-in.container.component.html',
  styleUrls: ['./sign-in.container.component.scss']
})
export class SignInContainer implements OnInit, OnDestroy, Internationalization {
  public loginForm: FormGroup;
  public ibForm: FormGroup;
  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  public currentCountry: Countries = this.internationalizationService.currentCountry;
  public hideInputDocumentMask = false;

  public countries = Countries;
  public innerWidth: any;
  public MOBILE_BREAKPOINT = 768;

  public documentNumberMask: string;
  public documentIBMask: string;

  public recoveryType: string;

  public errorCodes = ErrorCodesChallenge;

  public capslockOn: boolean;

  public type_password = 'text';
  public arr_password = { pass: '', mask: '' };
  private timer: any;

  errors$ = this.store$.pipe(select(fromLogin.selectors.selectErrors));
  public challengeError$ = this.store$.pipe(select(selectChallengeError));

  IBDetails$: Observable<any>;

  readonly sandboxValues: string =
    'allow-forms allow-scripts allow-same-origin allow-popups allow-pointer-lock allow-top-navigation';
  showLoginIBConfigButton: boolean;

  readonly category = '/portallojista/login';
  readonly Insert: Tag = Tag.Insert;
  readonly FillingError: Tag = Tag.FillingError;

  constructor(
    private store$: Store<AppState>,
    private internationalizationService: InternationalizationService,
    private validationService: FormValidationService,
    private loginSvc: LoginService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private genTagger: GenTagger,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.createForm();

    this.store$
      .select(fromLogin.selectors.selectUser)
      .pipe(take(1))
      .subscribe(user => {
        const rememberUser = Boolean(user.documentNumber !== null);

        this.hideInputDocumentMask = rememberUser;
        this.loginForm.patchValue({ ...user, rememberUser }, { emitEvent: false });

        this.applyDocumentMask(user.documentNumber);
      });

    // apply mask and remove hidden mask if input change
    this.loginForm
      .get('documentNumber')
      .valueChanges.pipe(delay(1))
      .subscribe(value => {
        if (this.hideInputDocumentMask) {
          this.loginForm.get('documentNumber').reset();
        }
        this.hideInputDocumentMask = false;

        this.store$.dispatch(new fromLogin.actions.ForgetUserAction());

        setTimeout(() => {
          this.applyDocumentMask(value);
        }, 10);
      });

    this.loginForm
      .get('password')
      .valueChanges.pipe(delay(1))
      .subscribe(data => {
        this.passwordChangeStarts(data);
      });

    this.verifyCapslock();

    this.errors$.pipe(take(1)).subscribe(data => {
      if (data.length > 0 && data.includes('invalid_grant')) {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: Tag.FillingError,
          event_label: this.translate.instant('INVALID-LOGIN-MSG')
        });
      }
    });
  }

  private createStars(length) {
    return new Array(length + 1).join('•');
  }

  passwordChangeStarts(password) {
    if (password.length === 0) {
      this.arr_password.pass = '';
    } else if (this.arr_password.pass) {
      const currMaskCount = (password.match(/•/g) || []).length;
      const newPassword = this.arr_password.pass.substring(0, currMaskCount) + password.substring(currMaskCount);
      this.arr_password.pass = newPassword;
    } else {
      this.arr_password.pass = password;
    }

    this.arr_password.mask = this.createStars(password.length - 1) + password.substring(password.length - 1);
    clearTimeout(this.timer);
    this.timer = setTimeout(() => {
      this.arr_password.mask = this.createStars(password.length);
    }, 1000);
  }

  public verifyCapslock(): void {
    document.addEventListener('keydown', event => {
      const flag = event.getModifierState && event.getModifierState('CapsLock');
      flag ? (this.capslockOn = true) : (this.capslockOn = false);
    });
  }

  onPaste(event: ClipboardEvent) {
    const prevValue = this.loginForm.get('documentNumber').value;
    const pastedValue = event.clipboardData.getData('text').replace(/\D/g, '');

    const CPF_LENGTH = 11;

    let valueToPaste;

    if (this.currentCountry === this.countries.BR) {
      if (pastedValue.length === CPF_LENGTH) {
        valueToPaste = pastedValue;
      } else {
        valueToPaste = (prevValue + pastedValue).toString();
      }

      this.documentNumberMask = null;

      return setTimeout(() => {
        // clear field value
        this.loginForm.get('documentNumber').reset();

        // set new formed field value
        this.loginForm.get('documentNumber').setValue(valueToPaste);
      }, 10);
    }
  }

  applyDocumentMask(document: string) {
    this.documentNumberMask = this.pioneerMasks.documentNumber.hiddenMask;
    this.loginForm
      .get('documentNumber')
      .setValidators([...this.pioneerValidators.documentNumber, this.validationService.isCpfValid]);
  }

  ngOnDestroy() {
    this.closeSnackbar();
  }

  closeSnackbar() {
    this.store$.dispatch(new fromLogin.actions.ResetErrors());
  }

  dismissError() {
    // Clear error state to dismiss snackbar
    this.store$.dispatch(new ErrorChallenge(null));
  }

  /**
   * Create the form and define validators based on country
   */
  private createForm() {
    this.loginForm = new FormGroup({
      documentNumber: new FormControl('', this.pioneerValidators.documentNumber),
      password: new FormControl('', this.pioneerValidators.password),
      rememberUser: new FormControl(false)
    });
    this.ibForm = new FormGroup({
      documentNumber: new FormControl('', [Validators.required, this.validationService.isCpfValid])
    });
  }

  public handleSubmitLoginForm(): void {
    if (this.loginForm.invalid) return;

    const payload = this.loginForm.value;
    payload.password = this.arr_password.pass;
    if (payload.password.includes('%')) {
      payload.password = encodeURI(payload.password);
    }
    this.store$.dispatch(
      new fromLogin.actions.RememberUserAction(payload.rememberUser ? payload.documentNumber : null)
    );

    this.store$.dispatch(new fromLogin.actions.SubmitLoginDataAction(payload));
  }

  public handleSubmitIBForm(): void {
    this.IBDetails$ = this.loginSvc
      .getLoginIBDetails(this.ibForm.get('documentNumber').value.replace(/[^0-9]/g, ''))
      .pipe(
        switchMap(res => {
          const b = res.customer.encryption;
          let assembledURL = 'https://hom.auth.santander.com.br/#/loginpf?';
          assembledURL += `cpf=${encodeURI(b.document)}&`;
          assembledURL += `partner=${encodeURI(b.partner)}&`;
          assembledURL += `ticket=${encodeURI(b.ticket)}&`;
          assembledURL += `urlError=${encodeURI(b.urlError)}&`;
          assembledURL += `urlSuccess=${encodeURI(b.urlSuccess)}`;
          this.showLoginIBConfigButton = false;
          return of(this.sanitizer.bypassSecurityTrustResourceUrl(assembledURL));
        })
      );
  }

  /**
   * Handle password recover click
   */
  handlePassRecoverClick(recoveryType: string) {
    this.store$.dispatch(new ErrorChallenge(null));
    this.router.navigate(['login/recovery/pre-token', { fType: 'password-recovery', recoveryType: recoveryType }]);
  }
}
