import { Injectable } from '@angular/core';
import * as fromProposal from '@app/core/state/proposal';
import * as fromProposalInformation from '@app/core/state/proposal-information/proposal-information.selectors';
import { ActionData } from '@app/proposal-workflow/models/action-data.model';
import { AddressSignUpData, TravelerSignUpData } from '@app/proposal-workflow/models/proposal-data.model';
import { CustomerTypeEnum, MailTypeEnum, PhoneTypeEnum } from '@app/proposal-workflow/models/step-register-put.enum';
import { RegisterAddress, StepRegisterPut } from '@app/proposal-workflow/models/step-register-put.model';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { TaggerService } from '@app/tagging/tagger.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProposalStageService } from '@shared/services/proposal/proposal-stage.service';
import { SimulationProposalService } from '@shared/services/simulation/simulation-proposal.service';
import * as moment from 'moment';
import { from, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from '..';
import * as registerActions from '../register/register.actions';
import {
  RegisterAdditionalInformation,
  RegisterTravelersList
} from './../../../proposal-workflow/models/step-register-put.model';
import * as actions from './proposal-information.actions';

@Injectable()
export class ProposalInformationEffects {
  constructor(
    private actions$: Actions,
    private simulationService: SimulationService,
    private simulationProposalService: SimulationProposalService,
    private proposalStageService: ProposalStageService,
    private store$: Store<AppState>,
    private taggerService: TaggerService
  ) {}

  @Effect()
  UpdatePersonalDataSection = this.actions$.pipe(
    ofType(actions.ProposalInformationTypes.SET_PERSONAL_DATA_SECTION),
    filter((action: ActionData) => action && !!action.payload && !action.payload.__typename),
    withLatestFrom(this.store$.select(fromProposal.selectors.selectProposalId)),
    switchMap(([action, proposalId]) => {
      const payload: StepRegisterPut = {
        proposalId: +proposalId,
        clientId: +action.payload.clientId,
        mainFlag: action.payload.mainFlag,
        type: CustomerTypeEnum.MAIN_CLIENT,
        person: {
          id: +action.payload.person.id,
          type: SubmitPersonType.PF,
          name: action.payload.person.name,
          documentId: action.payload.person.documentId,
          documentNumber: action.payload.person.documentNumber,
          emails: [
            {
              id: +action.payload.person.mainEmail.id,
              clientId: +action.payload.person.mainEmail.clientId,
              mainFlag: action.payload.person.mainEmail.mainFlag,
              type: MailTypeEnum.PERSONAL,
              email: action.payload.person.mainEmail.email
            }
          ],
          phones: [
            {
              id: +action.payload.person.mainPhone.id,
              clientId: +action.payload.person.mainPhone.clientId,
              mainFlag: action.payload.person.mainPhone.mainFlag,
              type: PhoneTypeEnum.MOBILE_PHONE,
              ddiNumber: action.payload.person.mainPhone.ddiNumber,
              dddNumber: action.payload.person.mainPhone.dddNumber,
              phoneNumber: action.payload.person.mainPhone.phoneNumber
            }
          ],
          physical: {
            clientId: +action.payload.person.physical.clientId,
            genderId: +action.payload.person.physical.genderId,
            professionId: +action.payload.person.physical.professionId,
            birthDate: action.payload.person.physical.birthDate,
            nationalityId: +action.payload.person.physical.nationalityId,
            motherName: action.payload.person.physical.motherName
          }
        }
      };

      return from([
        new registerActions.SetCustomerData(payload),
        new actions.SetPersonalDataSectionSucess(action.payload)
      ]);
    })
  );

  @Effect()
  UpdateCompanyDataSection = this.actions$.pipe(
    ofType(actions.ProposalInformationTypes.SET_COMPANY_DATA_SECTION),
    filter((action: ActionData) => action && !!action.payload && !action.payload.__typename),
    withLatestFrom(this.store$.select(fromProposal.selectors.selectProposalId)),
    switchMap(([action, proposalId]) => {
      const payload: StepRegisterPut = {
        proposalId: +proposalId,
        clientId: +action.payload.clientId,
        mainFlag: action.payload.mainFlag,
        type: CustomerTypeEnum.MAIN_CLIENT,
        person: {
          id: +action.payload.person.id,
          type: SubmitPersonType.PJ,
          name: action.payload.person.name,
          documentId: action.payload.person.documentId,
          documentNumber: action.payload.person.documentNumber,
          phones: [
            {
              id: +action.payload.person.mainPhone.id,
              clientId: +action.payload.person.mainPhone.clientId,
              mainFlag: action.payload.person.mainPhone.mainFlag,
              type: PhoneTypeEnum.MOBILE_PHONE,
              ddiNumber: action.payload.person.mainPhone.ddiNumber,
              dddNumber: action.payload.person.mainPhone.dddNumber,
              phoneNumber: action.payload.person.mainPhone.phoneNumber
            }
          ],
          legal: {
            clientId: +action.payload.person.legal.clientId,
            openingDate: action.payload.person.legal.openingDate,
            companyType: action.payload.person.legal.companyType,
            legalNatureId: +action.payload.person.legal.legalNatureId,
            branchGroupCode: action.payload.person.legal.branchGroupCode,
            branchId: +action.payload.person.legal.branchId
          }
        }
      };

      return from([
        new registerActions.SetCompanyData(payload),
        new actions.SetCompanyDataSectionSuccess(action.payload)
      ]);
    })
  );

  @Effect()
  UpdateCompanyAddressDataSection = this.actions$.pipe(
    ofType(actions.ProposalInformationTypes.SET_ADDRESS_DATA_SECTION),
    filter((action: ActionData) => action && !!action.payload && !action.payload.__typename),
    withLatestFrom(this.store$.select(fromProposal.selectors.selectProposalId)),
    switchMap(([action, proposalId]) => {
      const actionPayload: AddressSignUpData = action.payload[0];
      const payload: RegisterAddress = {
        id: actionPayload.id,
        clientId: +actionPayload.clientId,
        proposalId: +proposalId,
        type: actionPayload.type,
        mainFlag: true,
        zipCode: actionPayload.zipCode,
        state: actionPayload.state,
        cityId: +actionPayload.cityId,
        city: actionPayload.city,
        neighborhood: actionPayload.neighborhood,
        streetTypeId: null,
        streetType: null,
        street: actionPayload.street,
        number: actionPayload.number,
        complement: actionPayload.complement
      };

      return from([
        new registerActions.SetAddressCompanyData(payload),
        new actions.SetAddressDataSectionSuccess([actionPayload])
      ]);
    })
  );

  @Effect()
  UpdateAddressDataSection = this.actions$.pipe(
    ofType(actions.ProposalInformationTypes.SET_COMPANY_REPRESENTANT_ADDRESS_DATA_SECTION),
    filter((action: ActionData) => action && !!action.payload && !action.payload.__typename),
    withLatestFrom(
      this.store$.select(fromProposal.selectors.selectProposalId),
      this.store$.select(fromProposalInformation.companyRepresentantDataSection)
    ),
    filter(([_, proposalId, companyData]) => companyData.person.addresses.length > 0),
    switchMap(([_, proposalId, companyData]) => {
      const actionPayload: AddressSignUpData = companyData.person.addresses[0];
      console.log(companyData, actionPayload);
      const payload: RegisterAddress = {
        id: actionPayload.id,
        clientId: companyData.person.id ? +companyData.person.id : null,
        proposalId: +proposalId,
        type: actionPayload.type,
        mainFlag: true,
        zipCode: actionPayload.zipCode,
        state: actionPayload.state,
        cityId: +actionPayload.cityId,
        city: actionPayload.city,
        neighborhood: actionPayload.neighborhood,
        streetTypeId: null,
        streetType: null,
        street: actionPayload.street,
        number: actionPayload.number,
        complement: actionPayload.complement
      };

      return from([
        new registerActions.SetAddressPersonData(payload),
        new actions.SetCompanyRepresentantAddressDataSectionSuccess([actionPayload])
      ]);
    })
  );

  @Effect()
  UpdateRepresentantDataSection = this.actions$.pipe(
    ofType(actions.ProposalInformationTypes.SET_COMPANY_REPRESENTANT_DATA_SECTION),
    filter((action: ActionData) => action && !!action.payload && !action.payload.__typename),
    withLatestFrom(
      this.store$.select(fromProposal.selectors.selectProposalId),
      this.store$.select(fromProposalInformation.companyRepresentantDataSection)
    ),
    switchMap(([action, proposalId, companyRepresentantData]) => {
      const [email] = companyRepresentantData.person.emails;
      const [phone] = companyRepresentantData.person.phones;

      const payload: StepRegisterPut = {
        proposalId: +proposalId,
        clientId: action.payload.clientId
          ? action.payload.clientId
          : companyRepresentantData.clientId
          ? companyRepresentantData.clientId
          : null,
        mainFlag: false,
        type: CustomerTypeEnum.MAIN_LEGAL_REPRESENTATIVE,
        companyRelationshipTypeId: action.payload.companyRelationshipTypeId,
        person: {
          id: action.payload.person.id
            ? +action.payload.person.id
            : companyRepresentantData.person.id
            ? +companyRepresentantData.person.id
            : null,
          proposalId: +proposalId,
          type: SubmitPersonType.PF,
          name: action.payload.person.name,
          documentId: action.payload.person.documentId,
          documentNumber: action.payload.person.documentNumber,
          emails: [
            {
              id: action.payload.person.mainEmail.id
                ? +action.payload.person.mainEmail.id
                : email && email.id
                ? +email.id
                : null,
              clientId: action.payload.person.mainEmail.clientId
                ? +action.payload.person.mainEmail.clientId
                : email && email.clientId
                ? +email.clientId
                : null,
              mainFlag: action.payload.person.mainEmail.mainFlag,
              type: MailTypeEnum.PERSONAL,
              email: action.payload.person.mainEmail.email
            }
          ],
          phones: [
            {
              id: action.payload.person.mainPhone.id
                ? +action.payload.person.mainPhone.id
                : phone && phone.id
                ? +phone.id
                : null,
              clientId: action.payload.person.mainPhone.clientId
                ? +action.payload.person.mainPhone.clientId
                : phone && phone.clientId
                ? +phone.clientId
                : null,
              mainFlag: action.payload.person.mainPhone.mainFlag,
              type: PhoneTypeEnum.MOBILE_PHONE,
              ddiNumber: action.payload.person.mainPhone.ddiNumber,
              dddNumber: action.payload.person.mainPhone.dddNumber,
              phoneNumber: action.payload.person.mainPhone.number
            }
          ],
          physical: {
            clientId: action.payload.person.physical.clientId ? +action.payload.person.physical.clientId : null,
            genderId: action.payload.person.physical.genderId ? +action.payload.person.physical.genderId : null,
            professionId: action.payload.person.physical.professionId
              ? +action.payload.person.physical.professionId
              : null,
            birthDate: action.payload.person.physical.birthDate,
            nationalityId: action.payload.person.physical.nationalityId
              ? +action.payload.person.physical.nationalityId
              : null,
            motherName: action.payload.person.physical.motherName
          }
        }
      };

      return from([
        new registerActions.SetCustomerData(payload),
        new actions.SetCompanyRepresentantDataSectionSuccess(action.payload),
        new actions.SetCompanyRepresentantAddressDataSection(companyRepresentantData.person.addresses)
      ]);
    })
  );

  @Effect()
  UpdateTravelersDataSection = this.actions$.pipe(
    ofType(actions.ProposalInformationTypes.SET_TRAVELER_DATA_SECTION),
    filter((action: ActionData) => action && !!action.payload && !action.payload.__typename),
    withLatestFrom(this.store$.select(fromProposalInformation.getSimulationProposalData)),
    switchMap(([action, proposal]) => {
      const actionPayload: TravelerSignUpData[] = action.payload.listTravelers;
      const travelersList = actionPayload.map(traveler => ({
        ...traveler,
        birthDate: this.formatDate(traveler.birthDate)
      }));
      const payload: RegisterTravelersList = {
        travelDate: action.payload.travelData,
        travelers: travelersList
      };

      return from([
        new registerActions.SetTravelersData(payload),
        new actions.SetTravelerDataSectionSuccess(actionPayload)
      ]);
    })
  );

  @Effect({ dispatch: false })
  UpdateAdditionalDataSection = this.actions$.pipe(
    ofType(actions.ProposalInformationTypes.SET_OBSERVATION_DATA_SECTION),
    filter((action: ActionData) => action && !!action.payload && !action.payload.__typename),
    withLatestFrom(this.store$.select(fromProposal.selectors.selectProposalId)),
    switchMap(([action, proposalId]) => {
      const payload: RegisterAdditionalInformation = {
        proposalId: +proposalId,
        orderNumber: action.payload.orderNumber,
        commentId: action.payload.commentId,
        comment: action.payload.comment
      };

      return from([new registerActions.SetAdditionalInformationData(payload)]);
    })
  );

  @Effect()
  getConfirmationData = this.actions$.pipe(
    ofType<actions.GetConfirmationData>(actions.ProposalInformationTypes.GET_CONFIRMATION_DATA),
    switchMap(action => {
      return this.simulationService.getProposalConfirmation(action.payload.toString()).pipe(
        map(response => new actions.GetConfirmationDataSuccess(response)),
        catchError(err => of(new actions.GetConfirmationDataError(err)))
      );
    })
  );

  @Effect()
  getInsurancesEffect = this.actions$.pipe(
    ofType<actions.GetInsurance>(actions.ProposalInformationTypes.GET_INSURANCE),
    switchMap(action => {
      return this.simulationProposalService.getNewCalculation(action.payload).pipe(
        map(response => {
          response.insurance.forEach(i => {
            i.installmentAmount = response.installmentAmount;
          });
          return response;
        }),
        map(response => new actions.GetInsuranceSuccess(response)),
        catchError(err => of(new actions.GetConfirmationDataError(err)))
      );
    })
  );

  @Effect()
  proposalStatusEffect = this.actions$.pipe(
    ofType<actions.GetStageProposal>(actions.ProposalInformationTypes.GET_STAGE_PROPOSAL),
    withLatestFrom(this.store$.select(fromProposal.selectors.selectProposalId)),
    switchMap(([_, proposalId]) => this.proposalStageService.getProposalStage(proposalId)),
    map(data => new actions.SetStageProposal({ status: true, data })),
    catchError(() => of(new actions.SetStageProposal({ status: false })))
  );

  formatDate(date) {
    if (date.includes('-')) {
      return moment(date).format('YYYY-MM-DD');
    } else {
      return moment(date, 'DDMMYYYY').format('YYYY-MM-DD');
    }
  }

  @Effect({ dispatch: false })
  GetConfirmationDataSuccessEffect = this.actions$.pipe(
    ofType<actions.GetConfirmationDataSuccess>(actions.ProposalInformationTypes.GET_CONFIRMATION_DATA_SUCCESS),
    map((action: ActionData) => this.taggerService.setProposalType(action.payload.proposalData?.product))
  );
}
