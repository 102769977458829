import gql from 'graphql-tag';

export const GET_LIST_SIMULATION_OFFERS = gql`
  query simulationOffersQuery($simulationId: Int, $offerId: Int) {
    listSimulationOffers(simulationId: $simulationId, offerId: $offerId) {
      identification {
        purchaseValue
        entryValue
        assignedValue
        releasedValue
      }
      dueDate
      offers {
        installments {
          amount
          value
        }
        addInsurance
        insuranceValue
        firstOneInDays
      }
      scoring {
        status
      }
      coupon {
        code
        status
      }
      registration {
        exempt
        canAskExempt
        addInInstallments
        payInCash
        value
      }
      payments {
        description
      }
    }
  }
`;

export const GET_SIMULATION_PARAMETERS = gql`
  query simulationParameters($id: String) {
    simulationParameters(id: $id) {
      isEnabledToInformIncome
      daysToProposalExpiration
      financedValue
      maxFinancedValue
      minFinancedValue
      dueDaysIntervalMin
      dueDaysIntervalMax
      defaultDaysToInitialPayment
      accountTypes {
        id
        description
      }
      banks {
        id
        name
      }
      valuesToAdd
      paymentForm {
        code
        description
      }
      paymentMethods {
        id
        description
        code
        months {
          code
          description
        }
      }
      insurance {
        id
        name
        insurancePolicyId
        insuranceCompanyId
        value
        percentage
        fgFinanced
        fgExemption
        fgSelected
        nrInsuranceSeq
        fgValueDef
        insuranceDescription
        characteristicDescription
        justificationDescription
        insuranceCompanyName
        insuranceType
      }
      partnerBanks {
        codigoEmpresa
        codigoBanco
        nomeBanco
        areaNegocio
        codigoAreaNegocio
      }
    }
  }
`;
