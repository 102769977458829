import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-exit-proposal-modal',
  templateUrl: './exit-proposal-modal.component.html',
  styleUrls: ['./exit-proposal-modal.component.scss']
})
export class ExitProposalModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public dialogRef: MatDialogRef<ExitProposalModalComponent>
  ) {}

  ngOnInit() {}

  public confirm() {
    this.close(true);
  }

  public cancel() {
    this.close(false);
  }

  public close(value) {
    this.dialogRef.close(value);
  }
}
