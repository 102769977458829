import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state';
import { userProfileSelector } from '@app/core/state/profile/profile.selectors';
import { switchMap, take } from 'rxjs/operators';
import { Countries } from '@shared/constants/countries.enum';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';

interface CellPhone {
  phoneInternationalCode: string;
  phoneAreaCode: string;
  phoneNumber: string;
}

interface HomePagePayload {
  supplierChannelCode: string;
  homePage: string;
}

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  constructor(
    private httpClient: HttpClient,
    private store$: Store<AppState>,
    private internationalizationService: InternationalizationService
  ) {}

  public env = environment;

  public currentCountry: Countries = this.internationalizationService.currentCountry;

  public updateProfileData(profileData): Observable<any> {
    return this.store$.select(userProfileSelector).pipe(
      take(1),
      switchMap(() => {
        profileData = {
          email: profileData.email,
          phoneInternationalCode: profileData.phoneInternationalCode,
          phoneAreaCode: profileData.phoneAreaCode,
          phoneNumber: profileData.phoneNumber
        };
        const urlEndPoint = '/shopuserprofile/1.0.0/profile';
        return this.httpClient.put(this.env.apiRootUrl + urlEndPoint, profileData);
      })
    );
  }

  public updateUserPassword(passwordChangedData): Observable<any> {
    return this.store$.select(userProfileSelector).pipe(
      take(1),
      switchMap(() => {
        const urlEndPoint = '/shopuserprofile/1.0.0/changepassword';
        return this.httpClient.put(this.env.apiRootUrl + urlEndPoint, passwordChangedData);
      })
    );
  }

  public updateUserPreferences(userPrefereceData): Observable<any> {
    return this.store$.select(userProfileSelector).pipe(
      take(1),
      switchMap((documentNumber: any) => {
        const urlEndPoint = '/shopuserprofile/1.0.0/preferences';
        return this.httpClient.put(this.env.apiRootUrl + urlEndPoint, userPrefereceData);
      })
    );
  }

  postHomePage(payload: HomePagePayload): Observable<any> {
    const supplierChannelCode = payload.supplierChannelCode;
    const homePage = payload.homePage;
    const microsserviceUrl = 'shopuserprofile/1.0.0';
    const url = `${this.env.apiRootUrl}/${microsserviceUrl}/preferences/LOJISTA/${supplierChannelCode}`;
    return this.httpClient.post<any>(url, { homePage });
  }
}
