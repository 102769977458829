<app-side-content (closeEvent)="onClose()" [show]="true" [customClass]="customClass" [name]="'detalhes da proposta'">
  <div class="modal-header">
    <div class="container" *ngIf="!!data">
      <div class="row">
        <div class="col-12">
          <span *ngIf="data?.productCode === 'CDC'" class="badge badge-cdc">{{ 'CDC label' | translate }}</span>
          <span *ngIf="data?.productCode === 'CSC'" class="badge badge-csc">{{ 'CSC' | translate }}</span>
          <span *ngIf="data?.productCode === 'CSCP'" class="badge badge-cscp">{{ 'CSCP' | translate }}</span>
        </div>
      </div>
    </div>
  </div>

  <div #modalBody class="modal-body">
    <div class="container" *ngIf="!!data">
      <div class="row alerts" *ngIf="
          data.expirationDays <= 10 &&
          data.expirationDays >= 0 &&
          !alertExpirationDays &&
          data?.status?.description !== 'Proposta paga'
        ">
        <div class="col-md-12">
          <ngb-alert class="warning-alert-details" type="warning" (close)="alertExpirationDays = true">
            Existem propostas que estão prestes a expirar. Solicite agora o pagamento para não perder o prazo
            estipulado.
          </ngb-alert>
        </div>
      </div>

      <div class="row header-values">
        <div class="col-md-4" *ngIf="data.proposalId">
          <p>{{ 'PENDENCY-SUMMARY-TITLE-NUMBER-PROPOSAL' | translate }}</p>
          <span class="bold-value">{{ data.proposalId }}</span>
        </div>
        <div class="col-md-8">
          <div class="row">
            <div class="col-md-6 mt-mobile" *ngIf="data.expirationDays">
              <p>{{ 'PENDENCY-SUMMARY-TITLE-EXPIRE' | translate }}</p>
              <div class="flex-box">
                <img src="./assets/santander/icons/ic_clock.svg" alt="icon urgent" [hidden]="!data.showClock"
                  class="icon-period mr-2" />

                <img [src]="
                    data.expirationDays > 10
                      ? './assets/santander/icons/ic_clock_yellow.svg'
                      : './assets/santander/icons/ic_clock_red.svg'
                  " alt="icon urgent" class="icon-status mr-2" [hidden]="data.showClock" />
                <span class="bold-value" [hidden]="data.showClock">
                  {{ data.expirationDays === 1 ? data.expirationDays + ' dia' : data.expirationDays + ' dias' }}
                </span>
                <span class="bold-value" [hidden]="!data.showClock">
                  0 dias
                </span>
              </div>
            </div>
            <div class="col-md-6 mt-mobile" *ngIf="data.status">
              <p>{{ 'Current Status' | translate }}</p>
              <div class="flex-box" appTagger [event_category]="category"
                event_label="ajuda - {{ data.status.description }}">
                <img src="./assets/santander/icons/{{ data.status.icon }}" alt="icon" class="icon-status mr-2" />
                <span class="bold-value">{{ data.status.description }}</span>
                <img *ngIf="!!data.status.tooltip" src="./assets/images/tooltip-payment-col.svg" alt="icon"
                  class="icon-status ml-2" matTooltip="{{ data.status.tooltip }}"
                  matTooltipClass="custom-tooltip-details" matTooltipHideDelay="1000" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row alerts" *ngIf="!warningAlertClosed">
        <div class="col-md-12">
          <ngb-alert class="warning-alert-details" type="warning" (close)="warningAlertClosed = true">{{
            data.tooltipAlert
            }}</ngb-alert>
        </div>
      </div>
      <ngb-alert *ngIf="data.errorDescription && !warningAlerterrorDescription" class="danger-alert-details"
        type="danger" (close)="warningAlerterrorDescription = true">
        <img src="./assets/santander/icons/ic_close_orange.svg" alt="icon" class="icon-status"
          (click)="warningAlerterrorDescription = true" />
        <span class="user-name"> {{ data.errorDescription }} </span>
      </ngb-alert>
      <div class="row info-values">
        <div class="col-md-4">
          <div class="margin-values" *ngIf="data.financingValue">
            <p *ngIf="data?.productCode === 'CDC'">{{ 'ESIGN-FINANCED-VALUE' | translate }}</p>
            <p *ngIf="data?.productCode === 'CSC'">{{ 'VALUE-INSTALLMENT' | translate }}</p>
            <p *ngIf="data?.productCode === 'CSCP'">{{ 'VALUE-INSTALLMENT' | translate }}</p>
            <span class="bold-value">{{ data.financingValue | currency: 'BRL' }}</span>
          </div>
          <div class="margin-values" *ngIf="data.entryValue">
            <p>{{ 'ENTRANCE-VALUE-LABEL' | translate }}</p>
            <span class="bold-value">{{ data.entryValue | currency: 'BRL' }}</span>
          </div>
          <div class="margin-values" *ngIf="data.installmentAmount">
            <p>{{ data.installmentAmount }}x {{ 'OF' | translate }}</p>
            <span class="bold-value flex-box">
              <span *ngIf="!data.planCustom">{{ data.installmentValue | currency: 'BRL' }}</span>
              <span *ngIf="data.planCustom">{{ 'CUSTOM-PLAN' | translate }}</span>
              <img *ngIf="!!data.installmentTooltip" src="./assets/images/tooltip-payment-col.svg" alt="icon"
                class="icon-status ml-2" matTooltip="{{ data.installmentTooltip }}"
                matTooltipClass="custom-tooltip-details" matTooltipHideDelay="1000" appTagger
                [event_category]="category" event_label="{{ data.installmentTooltip }}" />

              <app-paymenttooltip *ngIf="data.planCustom" class="font-size" [planPayments]="paymentePlan"
                [isPlanCustom]="data.planCustom" [showMoreDetails]="true" [showTarif]="false"
                [numberProposal]="data.proposalId" [getProposalConfirmationRes]="getProposalConfirmationRes"
                [alignTo]="'right'" appTagger [event_category]="category" event_label="tooltip"></app-paymenttooltip>
            </span>
          </div>
        </div>
        <div class="col-md-8 info-client">
          <div class="row" *ngIf="data.nameClient">
            <div class="col-md-6">
              <p>{{ 'Customer' | translate }}</p>
            </div>
            <div class="col-md-6">
              <p>
                <b>{{ data.nameClient }}</b>
              </p>
            </div>
          </div>
          <div class="row" *ngIf="data.namePj">
            <div class="col-md-6 mt-2">
              <p>{{ 'CLIENT REP' | translate }}</p>
            </div>
            <div class="col-md-6 mt-2">
              <h2>{{ data.namePj }}</h2>
            </div>
          </div>
          <div class="row" *ngIf="data.document.length === 11">
            <div class="col-md-6">
              <p>{{ 'DOCUMENT-NUMBER' | translate }}</p>
            </div>
            <div class="col-md-6">
              <p>
                <b>{{ data.document | mask: '000.000.000-00' }}</b>
              </p>
            </div>
          </div>
          <div class="row" *ngIf="data.document.length === 14">
            <div class="col-md-6">
              <p>{{ 'DOCUMENT-NUMBER-CNPJ' | translate }}</p>
            </div>
            <div class="col-md-6">
              <p>
                <b>{{ data.document | mask: '00.000.000/0000-00' }}</b>
              </p>
            </div>
          </div>
          <div class="row" *ngIf="data.nameCreation">
            <div class="col-md-6">
              <p>{{ 'Creation' | translate }}</p>
            </div>
            <div class="col-md-6">
              <p>
                <b>{{ data.nameCreation }}</b>
              </p>
            </div>
          </div>
          <div class="row" *ngIf="data.creationDate">
            <div class="col-md-6">
              <p>{{ 'Creation Date' | translate }}</p>
            </div>
            <div class="col-md-6">
              <p>
                <b>{{ data.creationDate | dateFormatPipe }}</b>
              </p>
            </div>
          </div>
          <div class="row" *ngIf="data.expirationDate">
            <div class="col-md-6">
              <p>{{ 'PENDENCY-SUMMARY-DATE-EXPIRE-PROPOSAL' | translate }}</p>
            </div>
            <div class="col-md-6">
              <p>
                <b>{{ data.expirationDate | dateFormatPipe }}</b>
              </p>
            </div>
          </div>
          <div class="row" *ngIf="data.paymentExpirationDate">
            <div class="col-md-6">
              <p>{{ 'Payment Expiration' | translate }}</p>
            </div>
            <div class="col-md-6">
              <p>
                <b>{{ data.paymentExpirationDate | dateFormatPipe }}</b>
              </p>
            </div>
          </div>
          <div class="row" *ngIf="registrationFeeType === 'IN_CASH'">
            <div class="col-md-6">
              <p class="row">
                {{ 'STEP-CONFIRMATION-REGISTER-TARIFF' | translate }}
                <app-paymenttooltip *ngIf="registerTariff > 0" class="font-size" [planPayments]="paymentePlan"
                  [showMoreDetails]="false" [showTarif]="true" [numberProposal]="data.proposalId" [alignTo]="'right'"
                  [registerTariff]="registerTariff" appTagger [event_category]="category" event_label="tooltip">
                </app-paymenttooltip>
              </p>
            </div>
            <span class="col-md-6">
              {{ registerTariff | localeCurrency }}
            </span>
          </div>
          <div class="row" *ngIf="insuranceValue > 0">
            <div class="col-md-6">
              <p class="row">
                {{ 'STEP-CONFIRMATION-INSURANCE' | translate }}
                <app-paymenttooltip *ngIf="insuranceValue > 0" class="font-size" [planPayments]="paymentePlan"
                  [showMoreDetails]="false" [showTarif]="false" [insurance]="data.insurance"
                  [numberProposal]="data.proposalId" [insuranceValue]="insuranceValue" [alignTo]="'right'" appTagger
                  [event_category]="category" event_label="tooltip"></app-paymenttooltip>
              </p>
            </div>
            <div class="col-md-6">
              {{ insuranceValue | currency: 'BRL' }}
            </div>
          </div>
          <div class="row" *ngIf="data.cet">
            <div class="col-md-6">
              <p>{{ 'ESIGN-CET' | translate }}</p>
            </div>
            <div class="col-md-6">
              <p>
                <b>{{ data.cet | number: '1.2-2' }}{{ 'STEP-CONFIRMATION-PER-MONTH' | translate }}</b>
              </p>
            </div>
          </div>
          <div *ngIf="data.splitFlow && data.splitFlow.length > 0" class="row bipartido">
            <div class="col-md-12 bipartido-title">
              <img src="./assets/santander/icons/ic_bipartition.svg" alt="icon bipartido" class="mr-2" />
              <p>{{ 'Split Flow' | translate }}</p>
            </div>
            <div class="col-md-12 bipartido-content" *ngFor="let split of data.splitFlow; let i = index">
              <div class="row" *ngIf="split.document">
                <div class="col-md-6">
                  <p>CNPJ N°{{ i + 1 }}</p>
                </div>
                <div class="col-md-6">
                  <p>
                    <b>{{ split.document | mask: '00.000.000/0000-00' }}</b>
                  </p>
                </div>
              </div>
              <div class="row" *ngIf="split.value">
                <div class="col-md-6">
                  <p>{{ 'Value' | translate }}</p>
                </div>
                <div class="col-md-6">
                  <p>
                    <b>{{ split.value | currency: 'BRL' }}</b>
                  </p>
                </div>
              </div>
              <div class="row" *ngIf="split.percentage">
                <div class="col-md-6">
                  <p>{{ 'Percentage' | translate }}</p>
                </div>
                <div class="col-md-6">
                  <p>
                    <b>{{ split.percentage | number: '1.2' }}%</b>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row border boleto" *ngIf="data.openValue">
        <div class="col-md-6 boleto-info">
          <img src="./assets/ic_barcode.svg" alt="icon warning" class="icon-status" />
          <p>
            {{ 'FORMALIZATION-SUMMARY-TICKET-TEXT' | translate }}
            <img src="./assets/images/tooltip-payment-col.svg" alt="icon warning" class="icon-status ml-2"
              matTooltip="{{ 'FORMALIZATION-SUMMARY-TICKET-TOOLTIP' | translate }}"
              matTooltipClass="custom-tooltip-details" matTooltipHideDelay="10" appTagger [event_category]="category"
              event_label="tooltip" />
          </p>
        </div>
        <div class="col-md-6">
          <button type="button" class="btn btn-action" (click)="emitCarnet()" appTagger [event_category]="category"
            event_label="emitir boleto">
            {{ 'GET-TICKET' | translate }}
          </button>
        </div>
      </div>

      <div class="row">
        <div class="col-12 buttons-flex">
          <button *ngIf="antecipateAssignment" type="button" class="btn btn-action"
            [ngClass]="window.screen.width > 575 ? 'mr-3' : 'mb-2'" [disabled]="!antecipateAssignment" appTagger
            [event_category]="category" event_label="{{ 'ANTECIPATE-CASH-ASSIGNMENT' | translate }}">
            {{ 'ANTECIPATE-CASH-ASSIGNMENT' | translate }}
          </button>
          <button *ngIf="
              data.status.description === 'Erro de pagamento' || data.status.description === 'Pagamento disponível'
            " type="button" class="btn btn-action"
            [ngClass]="{ 'ml-3': window.screen.width > 575 && antecipateAssignment }"
            [disabled]="disabledPaymentRequest" (click)="openDialogPaymentRequest()" appTagger
            [event_category]="category" event_label="{{ 'PAYMENT-REQUEST' | translate }}">
            {{ 'PAYMENT-REQUEST' | translate }}
          </button>
        </div>
        <div class="col-12 center-btn" [hidden]="!data?.canCancel">
          <button type="button" class="btn btn-action" (click)="openDialogProposalCancel()" appTagger
            [event_category]="category" event_label="{{ 'PROPOSAL-CANCEL' | translate }}">
            <img src="./assets/ic_delete_red.svg" alt="icon warning" class="icon-status mr-2" />
            {{ 'PROPOSAL-CANCEL' | translate }}
          </button>
        </div>
        <div class="col-12 center-btn" [hidden]="!data.bankSlipEnable || !data.bankSlipId">
          <button type="button" class="action-button" (click)="emitirBoletoButton()">
            {{ 'EMITBLETO' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</app-side-content>