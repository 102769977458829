import gql from 'graphql-tag';

export const GET_BRANCHS = gql`
  query getBranchs($search: String) {
    getBranchs(search: $search) {
      idUser
      cdUser
      idUnidComercial
      dsUnidComercial
    }
  }
`;
