import { PreAnalysisActions, PreAnalysisActionTypes } from './pre-analysis.actions';

export interface PreAnalysisState {
  PreAnalysisCode: string | null;
  isLoading: boolean;
  error: string;
  statusPreAnalysis: string;
  preAnalysisSubmitData: any;
}

export const initialPreAnalysisState: PreAnalysisState = {
  PreAnalysisCode: null,
  isLoading: false,
  error: null,
  statusPreAnalysis: null,
  preAnalysisSubmitData: null
};

export function preAnalysisReducer(state = initialPreAnalysisState, action: PreAnalysisActions) {
  switch (action.type) {
    case PreAnalysisActionTypes.POST_PREANALYSIS:
    case PreAnalysisActionTypes.GET_PREANALYSIS_STATUS: {
      return {
        ...state,
        isLoading: true
      };
    }
    case PreAnalysisActionTypes.SUBMIT_IDENTIFICATION: {
      return {
        ...state,
        preAnalysisSubmitData: action.payload
      };
    }
    case PreAnalysisActionTypes.POST_PREANALYSIS_SUCCESS: {
      return {
        ...state,
        PreAnalysisCode: action.payload.code,
        isLoading: false
      };
    }
    case PreAnalysisActionTypes.POST_PREANALYSIS_ERROR: {
      return {
        ...state,
        error: action.payload,
        isLoading: false
      };
    }
    case PreAnalysisActionTypes.GET_PREANALYSIS_STATUS_SUCCESS: {
      return {
        ...state,
        statusPreAnalysis: action.payload.properties.status,
        isLoading: false
      };
    }
    case PreAnalysisActionTypes.GET_PREANALYSIS_STATUS_ERROR: {
      return {
        ...state,
        isError: action.payload,
        isLoading: false
      };
    }
    default:
      return state;
  }
}
