import { Action } from '@ngrx/store';

export enum ProfileActionTypes {
  PROFILE_INIT_REQUEST = '[Profile] Requesting user Profile',
  PROFILE_INIT_REQUEST_SUCCESS = '[Profile] Sucessfuly retrieved user Profile',
  PROFILE_INIT_REQUEST_ERROR = '[Profile] Error retrieving user Profile',

  UPDATE_USER_PREFERENCES_ACTION = '[Profile] updating user preferences',
  UPDATE_USER_PROFILE = '[Profile] updating user profile',
  UPDATE_USER_PREFERENCES_REQUEST = '[Profile] Request user preferences',
  UPDATE_USER_PREFERENCES_SUCCESS = '[Profile] updating user preferences',
  UPDATE_USER_PREFERENCES_ERROR = '[Profile] Error updating user preferences',

  UPDATE_USER_PROFILE_REQUEST = '[Profile] Requesting user profile update',
  UPDATE_USER_PROFILE_SUCCESS = '[Profile] Sucessfuly updated user profile',
  UPDATE_USER_PROFILE_ERROR = '[Profile] Error updating user profile',

  CHANGE_INITIAL_SCREEN = '[Profile] Updating user initial screen',
  CHANGE_INITIAL_SCREEN_SUCCESS = '[Profile] Success updating user initial screen',
  CHANGE_INITIAL_SCREEN_ERROR = '[Profile] Error updating user initial screen',

  CLEAR_PROFILE = '[Profile] Clear Profile'
}

export class ProfileRequest implements Action {
  readonly type = ProfileActionTypes.PROFILE_INIT_REQUEST;
  constructor(public payload: any) {}
}

// NOTE: - After removing mock, try to strongly type payload variable
// with ProfileState again
export class ProfileRequestSuccess implements Action {
  readonly type = ProfileActionTypes.PROFILE_INIT_REQUEST_SUCCESS;
  constructor(public payload: any) {} // ProfileState
}

export class ProfileRequestError implements Action {
  readonly type = ProfileActionTypes.PROFILE_INIT_REQUEST_ERROR;
  constructor(public payload: any) {}
}

export class UpdateUserProfileAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_USER_PROFILE;
}

export class UpdateUserProfileRequest implements Action {
  readonly type = ProfileActionTypes.UPDATE_USER_PROFILE_REQUEST;
  constructor(public payload: any) {}
}

export class UpdateUserProfileSuccess implements Action {
  readonly type = ProfileActionTypes.UPDATE_USER_PROFILE_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateUserProfileError implements Action {
  readonly type = ProfileActionTypes.UPDATE_USER_PROFILE_ERROR;
  constructor(public payload: any) {}
}

export class UpdateUserPreferencesAction implements Action {
  readonly type = ProfileActionTypes.UPDATE_USER_PREFERENCES_ACTION;
  constructor(public payload: any) {}
}
export class UpdateUserPreferencesSuccess implements Action {
  readonly type = ProfileActionTypes.UPDATE_USER_PREFERENCES_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateUserPreferencesRequest implements Action {
  readonly type = ProfileActionTypes.UPDATE_USER_PREFERENCES_REQUEST;
  constructor(public payload: any) {}
}

export class UpdateUserPreferencesError implements Action {
  readonly type = ProfileActionTypes.UPDATE_USER_PREFERENCES_ERROR;
  constructor(public payload: any) {}
}

export class ChangeInitialScreen implements Action {
  readonly type = ProfileActionTypes.CHANGE_INITIAL_SCREEN;
  constructor(public payload: any) {}
}

export class ChangeInitialScreenSuccess implements Action {
  readonly type = ProfileActionTypes.CHANGE_INITIAL_SCREEN_SUCCESS;
  constructor(public payload: any) {}
}

export class ChangeInitialScreenError implements Action {
  readonly type = ProfileActionTypes.CHANGE_INITIAL_SCREEN_ERROR;
  constructor(public error: any) {}
}

export class ClearProfileAction implements Action {
  readonly type = ProfileActionTypes.CLEAR_PROFILE;
  constructor() {}
}

export type ProfileActions =
  | ProfileRequest
  | ProfileRequestError
  | ProfileRequestSuccess
  | UpdateUserPreferencesAction
  | UpdateUserProfileAction
  | UpdateUserPreferencesRequest
  | UpdateUserPreferencesError
  | UpdateUserPreferencesSuccess
  | UpdateUserProfileRequest
  | UpdateUserProfileSuccess
  | UpdateUserProfileError
  | ChangeInitialScreen
  | ChangeInitialScreenSuccess
  | ChangeInitialScreenError
  | ClearProfileAction;
