<section class="section-sidebar" *ngIf="!isMobile" [class.h-100]="!showStoreConditions || !simulationSidebar">
  <div class="loan-summary" *ngIf="showStoreConditions">
    <p *ngIf="proposal">
      {{ 'STEP-REGISTER-SIDEBAR-TITLE' | translate }}
      <span
        [ngClass]="{
          'text-cdc': proposal?.proposalData?.product === productCode.CDC,
          'text-csc': proposal?.proposalData?.product === productCode.CSC
        }"
      >
        {{ proposal?.proposalData?.product }}
      </span>
    </p>
  </div>

  <p
    class="proposal-description"
    *ngIf="
      offers &&
      showStoreConditions &&
      currentUrl === '/proposal/step-offer' &&
      proposal?.proposalData?.product === productCode.CDC
    "
  >
    Escolha uma das ofertas ao lado para o financiamento de {{ offers?.financedValue | localeCurrency }} solicitado ou
    edite os valores da proposta aqui em baixo.
  </p>

  <p
    class="proposal-description"
    *ngIf="
      offers &&
      showStoreConditions &&
      currentUrl === '/proposal/step-offer' &&
      proposal?.proposalData?.product === productCode.CSC
    "
  >
    Escolha uma das ofertas ao lado para o parcelamento de {{ offers?.financedValue | localeCurrency }} solicitado ou
    edite os valores da proposta aqui em baixo.
  </p>

  <p
    class="proposal-description"
    *ngIf="
      !simulationSidebar &&
      proposal?.proposalData?.product === productCode.CDC &&
      currentUrl !== '/proposal/step-register' &&
      currentUrl !== '/proposal/step-confirmation' &&
      currentUrl !== '/proposal/step-formalization'
    "
  >
    Escolha uma das ofertas ao lado para o financiamento de {{ proposal?.financedValue | localeCurrency }} solicitado ou
    edite os valores da proposta aqui em baixo.
  </p>

  <p
    class="proposal-description"
    *ngIf="
      !simulationSidebar &&
      proposal?.proposalData?.product === productCode.CSC &&
      currentUrl !== '/proposal/step-register' &&
      currentUrl !== '/proposal/step-confirmation' &&
      currentUrl !== '/proposal/step-formalization'
    "
  >
    Escolha uma das ofertas ao lado para o parcelamento de {{ proposal?.financedValue | localeCurrency }} solicitado ou
    edite os valores da proposta aqui em baixo.
  </p>

  <ng-container *ngIf="!simulationSidebar; else proposalConditions">
    <app-proposal-sumary
      *ngIf="proposal"
      [currentUrl]="currentUrl"
      [proposal]="proposal"
      [zoom]="zoom"
      (showStoreConditionsEmitter)="handleShowStoreConditions($event)"
    ></app-proposal-sumary>
  </ng-container>

  <ng-template #proposalConditions>
    <app-proposal-conditions
      [proposalData]="proposalData"
      (showStoreConditionsEmitter)="handleShowStoreConditions($event)"
      (changeValuesRecalculate)="recalculateValues($event)"
      [category]="category"
    ></app-proposal-conditions>
  </ng-template>
</section>

<ng-container *ngIf="isMobile && (currentUrl === '/proposal/step-offer' || currentUrl === '/proposal/step-simulation')">
  <app-proposal-conditions
    [proposalData]="proposalData"
    (showStoreConditionsEmitter)="handleShowStoreConditions($event)"
    (changeValuesRecalculate)="recalculateValues($event)"
    [category]="category"
  ></app-proposal-conditions>
