import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InputChange } from '@app/proposal-workflow/models/input-change.model';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
  selector: 'app-installment-info-seasonal',
  templateUrl: './installment-info-seasonal.component.html',
  styleUrls: ['./installment-info-seasonal.component.scss']
})
export class InstallmentInfoSeasonalComponent implements OnInit, OnChanges {
  @Input() months: any = [];
  @Input() selectedPaymentMethod: any;
  @Input() codeMonthsRange;
  @Input() monthsRange;
  @Input() category: string;
  @Input() installmentDefaultValue;
  @Input() installmentPaymentMethodsAmount
  @Input() installmentPaymentMethodsValue
  @Input() installmentDefaultAmount
  @Input() installmentPMethodsDate: any = [];
  @Input() firstInstallmentPMethodsDate;
  @Output() installmentInfoEmitter = new EventEmitter<InputChange>();
  @Output() public selectedValidatorsEmitter = new EventEmitter<boolean>();
  @Output() @Output() installmentInfoEmitterSeasonal = new EventEmitter<InputChange>();
  public formGroup: FormGroup;
  skipableInstallments: [any]

  constructor(private genTagger: GenTagger, private translate: TranslateService) {
    this.formGroup = new FormGroup({
      period: new FormControl('', [Validators.required, Validators.nullValidator]),
      firstDate: new FormControl('', [Validators.nullValidator, Validators.required])
    });
   }

  ngOnInit(): void {
    if (this.codeMonthsRange) {
      const period = this.months.find(m => m.code === this.codeMonthsRange);

      this.genTagger.setTag({
        event_category: this.category,
        event_action: `${Tag.Selecionar} - período`,
        event_label: period.description
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.firstInstallmentPMethodsDate && changes.firstInstallmentPMethodsDate.currentValue) ||
      (changes.installmentPMethodsDate && changes.installmentPMethodsDate.currentValue)
    ) {
      this.setFormValues();
      this.setSkipableInstallmentsValues();
    }
  }
  public setFormValues() {
    if (this.months && this.firstInstallmentPMethodsDate) {
      const period = this.months.find(m => parseInt(m.code) === this.monthsRange);
      const firstDate = {
        description: moment(this.firstInstallmentPMethodsDate).format('MM/YYYY'),
        code: moment(this.firstInstallmentPMethodsDate).format('DDMMYYYY')
      };
      this.formGroup.controls['firstDate'].setValue(firstDate);
      this.formGroup.controls['period'].setValue(period);
    }
    this.installmentInfoEmitterSeasonal.emit(this.formGroup.value.period);
  }
  monthChangeHandler($event) {
    const payload: InputChange = {
      value: {
        payment: {
          codeMonthsRange: this.formGroup.controls.period.value
        }
      },
      input: InputTypes.INSTALLMENT_INFO_MONTHS,
      type: 'SEASONAL'
    };
    this.formGroup.controls['period'].valueChanges.subscribe(valueChange => {
      this.getFormValues(this.formGroup);
    });
    this.installmentInfoEmitter.emit(payload);

    this.genTagger.setTag({
      event_category: this.category,
      event_action: `${Tag.Selecionar} - período`,
      event_label: this.months.find(item => item.code === this.formGroup.controls.period.value).description
    });
  }
  public getFormValues(form: any) {
    form.getRawValue();
    if (form.invalid) {
      this.selectedValidatorsEmitter.emit(true);
    } else {
      this.selectedValidatorsEmitter.emit(false);
    }
  }
    maxDateValidation(selectedDate, cb) {
    if (
      !moment(selectedDate).isAfter(
        moment(this.installmentPMethodsDate[this.installmentPMethodsDate.length - 1].values)
      )
    ) {
      cb();
    } else {
      this.formGroup.controls.firstDate.setValue(
        moment(this.installmentPMethodsDate[this.installmentPMethodsDate.length - 1].values).format('DD/MM/YYYY')
      );
      const payload: InputChange = {
        value: {
          payment: {
            firstInstallmentPMethodsDate: moment(this.formGroup.controls.firstDate.value, 'DD/MM/YYYY').format(
              'YYYY-MM-DD'
            )
          }
        },
        input: 'installmentInfoDateSeasonal'
      };

      this.installmentInfoEmitter.emit(payload);
    }
  }
  setDate() {
    if (this.formGroup.controls.firstDate.value.length === 8) {
      this.maxDateValidation(moment(this.formGroup.controls.firstDate.value, 'DDMMYYYY'), () => {
        const payload: InputChange = {
          value: {
            payment: {
              firstInstallmentPMethodsDate: moment(this.formGroup.controls.firstDate.value, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              )
            }
          },
          input: 'installmentInfoDateSeasonal'
        };

        this.formGroup.controls['firstDate'].valueChanges.subscribe(valueChange => {
          this.getFormValues(this.formGroup);
        });
        this.installmentInfoEmitter.emit(payload);
      });

      this.genTagger.setTag({
        event_category: this.category,
        event_action: Tag.Insert,
        event_label: this.translate.instant('FIRST-MONTH-INSTALLMENT')
      });
    }
  }

   public setSkipableInstallmentsValues() {
    const filterLimit = moment().add(1, 'year');
    if (this.firstInstallmentPMethodsDate) {
      this.skipableInstallments = [{
        description: moment(this.firstInstallmentPMethodsDate).format('MM/YYYY'),
        code: moment(this.firstInstallmentPMethodsDate).format('DDMMYYYY')
    }]
  }
  }
  compareObjects(o1: any, o2: any): boolean {
    return o1 === o2.code;
  }
}
