export class NotificationModel {
  id: string;
  title: string;
  message: string;
  isRead: boolean;
  icon?: string;
  creation?: string;
  attachmentDescription?: string;
  attachmentPath?: string;
  linkDescription: string;
  linkPath: string;
}

export class NotificationResponse {
  todayNotifications: Array<NotificationModel>;
  previousNotifications: Array<NotificationModel>;
}

export class NotificationsFiltered {
  newData: Array<NotificationModel> = new Array<NotificationModel>();
  oldData: Array<NotificationModel> = new Array<NotificationModel>();
}
