import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { InsuranceModalComponent } from '@shared/components/insurance-modal/insurance-modal.component';

@Component({
  selector: 'insurance-step',
  templateUrl: './insurance-step.component.html',
  styleUrls: ['./insurance-step.component.scss']
})
export class InsuranceStepComponent implements OnInit, OnChanges {
  @Input() insuranceStepData: any;
  @Output() public insuranceChangeEmitter = new EventEmitter();

  insurance: any;

  public formSimulacao: FormGroup;

  isValidFormSubmitted: boolean = null;
  public selectedIndex: number;
  iconPath = './assets/images/ic_bank_credit.svg';
  insuranceOptions: any[] = [];

  @Input() category: string;
  readonly Selecionar: Tag = Tag.Selecionar;

  constructor(public matDialog: MatDialog, private genTagger: GenTagger) {}

  ngOnInit() {
    this.insuranceOptions = Object.assign([], this.insuranceStepData.insurance);
    this.insuranceOptions.push({
      id: 0.1,
      name: 'Continuar desprotegido',
      installmentValue: null,
      value: null,
      description: null,
      icon: null
    });

    if (this.insuranceStepData.hasInsurance) {
      this.selectedIndex = this.insuranceOptions[0].id;
    } else {
      this.selectedIndex = this.insuranceOptions[1].id;
    }
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.insuranceStepData) {
      this.ngOnInit();
    }
  }
  public setOptionSelectedById(id: any) {
    const item = this.insuranceOptions.find(_item => _item.id === id);

    this.genTagger.setTag({
      event_action: 'selecionou – seguro',
      event_category: this.category,
      event_label: item.name
    });

    this.selectedIndex = id;
    if (this.selectedIndex === 0.1) {
      this.openDialog();
    } else {
      this.setOptionSelected(true);
    }
  }

  public setOptionSelected(isInsuranceSelected: boolean) {
    isInsuranceSelected !== true ? (this.selectedIndex = 0.1) : (this.selectedIndex = this.insuranceOptions[0].id);
    this.selectInsurance({ id: this.selectedIndex });
  }

  openDialog() {
    const isMobile = window.innerWidth <= 1024;
    const dialogRef = this.matDialog.open(InsuranceModalComponent, {
      width: isMobile ? '80%' : '810px',
      height: isMobile ? '520px' : '660px',
      maxWidth: '100%',
      data: { selectedOption: this.selectedIndex, category: this.category }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        this.setOptionSelected(result);
      }
    });
  }

  selectInsurance(insurance) {
    const insurancePayload = {
      value: {
        payment: {
          hasInsurance: insurance.id !== 0.1,
          insuranceId: insurance.id
        }
      },
      input: InputTypes.INSURANCE,
      type: 'Insurance'
    };

    if (!insurancePayload.value.payment.hasInsurance) {
      insurancePayload.value.payment.insuranceId = null;
    }

    this.selectedIndex = insurance.id;

    this.insuranceChangeEmitter.emit(insurancePayload);
  }
}
