<div class="mobile">
  <div class=" body container d-sm-none  ">
    <!-- Propostas em aberto -->
    <div class="row" *ngIf="propostaAberto.length > 0">
      <div class="col">
        <span>Propostas em aberto</span>
      </div>
    </div>
    <!-- Carousel -->
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let card of propostaAberto | slice: 0:2; let i = index">
        <div class="card shadow card-with-steper">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-10 align-self-start">
                    <p class="card-title specialOffer" style="margin-bottom: 0px;">{{ card.title }}</p>
                  </div>
                </div>
              </div>
              <div class="col-3 align-self-start">
                <img style="width: 20px;" (click)="deleteArray(i)" src="./assets/images/ic_lixeira.svg" />
              </div>
            </div>

            <div class="row" style="margin-bottom: 2%;">
              <div class="col align-self-start">
                <small>Iniciado em {{ card.date }}</small>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="row">
              <div class="col-2">
                <img class="icon-status" src="./assets/images/ic_check.svg" />
              </div>
              <div class="col">
                Simulacao
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img class="icon-status" src="./assets/images/ic_check.svg" />
              </div>
              <div class="col">
                Cadastro
              </div>
            </div>
            <div class="row">
              <div class="col-2">
                <img class="icon-status" src="./assets/images/ic_exclamation.svg" />
              </div>
              <div class="col">
                Formalização
              </div>
            </div>
            <div class="row" style="margin-bottom: 6%;">
              <div class="col-2">
                <img class="icon-status" src="./assets/images/ic_exclamation.svg" />
              </div>
              <div class="col">
                Finalização
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <button type="button" class="btn btn-outline-danger" (click)="chamadaPreAnalise()">Simular</button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template carouselSlide *ngIf="propostaAberto.length > 2">
        <div class="card shadow card-with-steper">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <img class="aro-propAberto" src="./assets/Line.svg" alt="" />
                <div class="iconSpecialOfferQrCode">
                  <img class="paper-icon-propAberto" src="./assets/ic_paper_stack.svg" />
                </div>
              </div>
            </div>
            <div class="row" style="margin-bottom: 2px;">
              <div class="col">
                <p style="font-size: 18px; font-weight: 500;">
                  Confira mais informacoes sobre suas propostas em aberto.
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <button type="button" class="btn btn-outline-danger" [routerLink]="['/products/proposal']">
                  Acessar
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>

    <!-- Ofertas Personalizadas-->
    <div class="row">
      <span>Ofertas Personalizadas</span>
    </div>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let card of ofertaPersonalizada">
        <div class="card shadow card-with-steper">
          <div class="card-body">
            <div class="row">
              <div class="col align-self-start">
                <p class="card-title specialOffer">{{ card.title }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <h3
                  style="    font-size: 24px;
                font-weight: bold;"
                >
                  {{ card.parcel }}x R${{ card.price }}
                </h3>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <p class="card-text" style="font-weight: 600;">Total de R$ {{ card.totalPrice }}</p>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start" style="font-weight: 600;">
                <p>Taxa {{ card.rate }}% ao mes</p>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <small>
                  *Sujeito a analise de credito
                </small>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <button type="button" class="btn btn-outline-danger" (click)="chamadaPreAnalise()">Simular</button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template carouselSlide *ngIf="ofertaPersonalizada.length > 2">
        <div class="card shadow card-with-steper">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <img class="aro-propAberto" src="./assets/Line.svg" alt="" />
                <div class="iconSpecialOfferQrCode">
                  <img class="paper-icon-propAberto" src="./assets/ic_paper_stack.svg" />
                </div>
              </div>
            </div>
            <div class="row" style="margin-bottom: 5px;">
              <div class="col">
                <p style="font-size: 18px; font-weight: 500;">
                  Confira mais ofertas personalizadas
                </p>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-start">
                <button type="button" class="btn btn-outline-danger">Acessar</button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>

    <!-- Compras parcelas-->
    <div class="row">
      <div class="card shadow">
        <div class="card-body">
          <div class="row">
            <div class="col align-self-start">
              <p class="card-title specialOffer">Compras Parceladas</p>
            </div>
          </div>
          <div class="row">
            <div class="col align-self-start">
              <p class="card-text" style="font-weight: 500;">
                Faça compras em lojas de diversos segmentos com condições especiais.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col align-self-start">
              <button [routerLink]="['/products/simulation']" type="button" class="btn btn-outline-danger">
                Simular
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <mat-divider></mat-divider>

    <!--OFERTAS ESPECIAIS-->
    <div class="row">
      <span class="specialOffer" style="margin-top: 9%">Ofertas Especiais</span>
    </div>
    <owl-carousel-o [options]="customOptions">
      <ng-template carouselSlide *ngFor="let card of ofertaEspecial | slice: 0:2">
        <div class="card shadow card-with-steper" style="padding: 0px;">
          <img
            src="./assets/flag-card.svg"
            alt=""
            style="width: 50px; position: absolute; left: 231px; bottom: 207px;"
          />
          <div class="card-body">
            <div class="row" style="margin-bottom: 9%;">
              <div class="col-5 align-self-center">
                <div
                  class="iconSpecialOffer"
                  style="background-color: #d00000; height: 70px; border-radius: 50%; width: 74px; margin-left: 96px;"
                ></div>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-center">
                <h3 class="specialOffer" style="text-align: center;">
                  {{ card.title }}
                </h3>
              </div>
            </div>
            <mat-divider></mat-divider>
            <p class="card-text" style="font-weight: 500; margin-bottom: 0px;">
              {{ card.text }}
            </p>
          </div>
          <div
            style=" background-color: #cc0000 !important; height: 50px; width: 100%; border-bottom-right-radius: 6px; border-bottom-left-radius: 6px;"
          >
            <div class="row">
              <div
                class="col"
                style="color: white; color: white;position: relative; top: 16px; right: -12px;"
                (click)="chamadaPreAnalise()"
              >
                Simular
              </div>
              <div class="col" style="color: white; ">
                <img
                  src="./assets/arrow-card.svg"
                  alt=""
                  style="width: 34px; position: relative; left: 69px; top: 17px;"
                />
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>

    <!--CARD ESPECIAIS -->
    <div class="row">
      <div class="card shadow specialCard">
        <div class="card-body">
          <div class="row">
            <div class="col">
              <img class="aro" src="./assets/Line.svg" alt="" />
              <div class="iconSpecialOfferQrCode">
                <img class="qrCode-icon" src="./assets/images/ic_qrcode.svg" />
              </div>
            </div>
            <div class="col-7">
              <div class="row">
                <p style="font-weight: 700">
                  Ler codio da loja
                </p>
              </div>
              <div class="row subText">
                Leia o QR Code da loja para simular sea compra parcelada.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="desktop">
  <div class="body container d-none d-md-block">
    <div class="row">
      <div class="col breadcrumbs">
        <small>
          Home
        </small>
      </div>
    </div>

    <div class="row">
      <span class="title">
        Separamos as melhores ofertas para você
      </span>
    </div>

    <!-- proposata em aberto -->
    <div class="row" *ngIf="openProposal && propostaAberto.length > 0">
      <div class="col">
        <span style="margin-left: 30px;">Propostas em aberto</span>
      </div>
      <div class="col text-right">
        <span [routerLink]="['/showcase/open-proposal']" class="access">Acessar todas as propostas</span>
      </div>
    </div>
    <!-- Carousel -->
    <owl-carousel-o [options]="customOptionsDesktopPersonalOffer" *ngIf="openProposal">
      <ng-template carouselSlide *ngFor="let card of propostaAberto; let i = index">
        <div class="card shadow card-with-steper">
          <div class="card-body">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-10 align-self-start">
                    <p class="card-title specialOffer" style="margin-bottom: 0px;">{{ card.title }}</p>
                  </div>
                </div>
              </div>
              <div class="col-2 align-self-start">
                <img
                  style="cursor: pointer; margin-left: auto; width: 20px;"
                  (click)="deleteArray(i)"
                  src="./assets/images/ic_lixeira.svg"
                />
              </div>
            </div>

            <div class="row" style="margin-bottom: 2%;">
              <div class="col-6 align-self-start">
                <small>Iniciado em {{ card.date }}</small>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-3">
                    <img class="icon-status" src="./assets/images/ic_check.svg" />
                  </div>
                  <div style="padding-left: 0px;" class="col">
                    Cadastro
                  </div>
                </div>
                <div class="row">
                  <div class="col-3">
                    <img class="icon-status" src="./assets/images/ic_exclamation.svg" />
                  </div>
                  <div style="padding-left: 0px;" class="col">
                    Formalização
                  </div>
                </div>
                <div class="row" style="margin-bottom: 6%;">
                  <div class="col-3">
                    <img class="icon-status" src="./assets/images/ic_exclamation.svg" />
                  </div>
                  <div style="padding-left: 0px;" class="col">
                    Finalização
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col align-self-start">
                <button type="button" class="btn btn-outline-danger" (click)="chamadaPreAnalise()">
                  Continuar proposta
                </button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>

    <!-- Ofertas Personalizadas-->
    <div class="row" *ngIf="!openProposal">
      <span style="font-size: 22px;">Ofertas Personalizadas</span>
    </div>
    <owl-carousel-o [options]="customOptionsDesktopPersonalOffer" *ngIf="!openProposal">
      <ng-template carouselSlide *ngFor="let card of ofertaPersonalizada">
        <div class="card shadow card-with-steper">
          <div class="card-body">
            <div class="row" style="margin-bottom: 35px;">
              <div class="col" style="margin-left: 18px;">
                <div class="row">
                  <h3 style="font-weight: bold; margin-bottom: 19px;">
                    Emprestimo pessoal
                  </h3>
                </div>
                <div class="row price">{{ card.parcel }}x</div>
                <div class="row price">R${{ card.price }}</div>
              </div>
              <div class="col align-self-end">
                <div class="row">Total de R$ {{ card.totalPrice }}*</div>
                <div class="row">Taxa {{ card.rate }}% ao mes</div>
                <div class="row">
                  <small>
                    * Sujeito a analise de credito
                  </small>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <button (click)="chamadaPreAnalise()" type="button" class="btn btn-outline-danger">Simular</button>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>

    <!-- Compras parcelas-->
    <div class="row card-parcel-payment">
      <div class="col">
        <div class="card-body">
          <div class="row">
            <div class="col align-self-start">
              <p class="card-title specialOffer">Compras Parceladas</p>
            </div>
          </div>
          <div class="row">
            <div class="col align-self-start">
              <p class="card-text" style="font-weight: 500;">
                Faça compras em lojas de diversos segmentos com condições especiais.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 align-self-center">
        <button
          [routerLink]="['/products/simulation']"
          type="button"
          class="btn btn-outline-danger button-parcel-payment"
        >
          Simular
        </button>
      </div>
    </div>
    <!--OFERTAS ESPECIAIS-->
    <div class="row"></div>

    <!-- proposata em aberto -->
    <div class="mt-5 row">
      <div class="col">
        <span style="margin-left: 30px;">Ofertas Especiais</span>
      </div>
      <div class="col text-right">
        <span [routerLink]="['/products/offer']" class="access">Acessar todas as ofertas</span>
      </div>
    </div>

    <owl-carousel-o [options]="customOptionsDesktopSpecialOffers">
      <ng-template carouselSlide *ngFor="let card of ofertaEspecial">
        <div class="card shadow card-carousel" style="padding: 0px;">
          <img
            src="./assets/flag-card.svg"
            alt=""
            style="width: 50px; position: absolute; left: 226px; bottom: 205px;"
          />
          <div class="card-body">
            <div class="row" style="margin-bottom: 9%;">
              <div class="col-5" style="margin-left: auto!important; margin-right: auto!important;">
                <div
                  class="iconSpecialOffer"
                  style="background-color: #d00000; height: 70px; border-radius: 50%; width: 74px;"
                ></div>
              </div>
            </div>
            <div class="row">
              <div class="col align-self-center">
                <h3 class="specialOffer">
                  {{ card.title }}
                </h3>
              </div>
            </div>
            <mat-divider></mat-divider>
            <!-- </div> -->
            <p class="card-text" style="font-weight: 500; margin-bottom: 0px;">
              {{ card.text }}
            </p>
          </div>
          <div class="button-offers">
            <div class="row">
              <div
                class="col"
                style="color: white; color: white;position: relative; top: 16px; right: -12px;"
                (click)="chamadaPreAnalise()"
              >
                Simular
              </div>
              <div class="col" style="color: white; ">
                <img
                  src="./assets/arrow-card.svg"
                  alt=""
                  style="width: 34px; position: relative; left: 69px; top: 17px;"
                />
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </owl-carousel-o>
  </div>
</div>
