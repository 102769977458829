<div class="row no-gutters notification">
  <div class="col-12">
    <div class="link card-title">
      <img [src]="'./assets/' + data.icon" *ngIf="data.icon" />
      <h2>{{ data.title }}</h2>
    </div>
    <p class="card-description">{{ data.message }}</p>
    <div class="link" *ngIf="data.attachmentDescription">
      <a (click)="setTag()" href="#">{{ data.attachmentDescription }}</a>
    </div>
    <p class="time">
      {{ data.creation | date: 'dd/MM/yy H:mm' }}
    </p>
  </div>
</div>
