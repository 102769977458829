<div id="modal-content-wrapper">
  <header id="modal-header" class="text-center mt-3">
    <h1 id="modal-title" mat-dialog-title><strong>Quer mesmo seguir com esta opção?</strong></h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <div class="info-section-1">
      Para isenção de tarifas será necessário enviar documentos para análise e o seu cliente só receberá o valor que
      precisa após a aprovação.
    </div>
    <section class="accordions">
      <mat-expansion-panel
        (opened)="changeStatus('Entenda como funciona')"
        (closed)="changeStatus('Entenda como funciona', false)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            Entenda como funciona
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ol>
          <li>Envie os documentos necessários para análise na fase de contratação do emprestimo;</li>
          <li>Se aprovados, a isenção da tarifa de cadastro é concedida;</li>
          <li>Se reprovados, você deverá escolher uma das outras opções de pagamento.</li>
        </ol>
      </mat-expansion-panel>
      <mat-expansion-panel
        (opened)="changeStatus('Confira os documentos necessários')"
        (closed)="changeStatus('Confira os documentos necessários', false)"
      >
        <mat-expansion-panel-header>
          <mat-panel-title>
            Confira os documentos necessários
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ul>
          <li>
            Comprovante de renda ou de patrimônio, como a cópia do seu holerite ou a declaração anual de Imposto de
            Renda;
          </li>
          <li>
            Comprovante de consulta do seu CPF ou CNPJ em um banco de dados e de proteção ao crédito á sua escolha, como
            SERASA ou Boa Vista;
          </li>
          <li>Comprovante de situação cadastral do seu CPF expedido pela Receita Federal;</li>
          <li>Certidão de protesto emitida no cartório da sua cidade.</li>
        </ul>
      </mat-expansion-panel>
    </section>
    <section class="after-accordions">
      <div class="info-section-2">
        Incluindo a taxa de cadastro nas parcelas, o valor é diluído e seu cliente não precisa esperar para receber o
        empréstimo ou produto financiado.
      </div>
    </section>
  </section>
  <footer id="modal-footer" class="footer-buttons d-none d-sm-block" mat-dialog-actions>
    <div class="row">
      <div class="col-6">
        <button
          mat-stroked-button
          id="modal-cancel-button"
          color="primary"
          (click)="cancelActionFunction()"
          appTagger
          [event_category]="modalData.category"
          event_label="Solicitar Isenção"
        >
          Solicitar Isenção
        </button>
      </div>
      <div class="col-6">
        <button
          mat-raised-button
          id="modal-action-button"
          color="primary"
          (click)="confirmActionFunction()"
          appTagger
          [event_category]="modalData.category"
          event_label="Incluir na parcela"
        >
          Incluir na parcela
        </button>
      </div>
    </div>
  </footer>
  <footer id="modal-footer" class="footer-buttons d-sm-none" mat-dialog-actions>
    <div class="pb-3">
      <button
        mat-stroked-button
        id="modal-cancel-button"
        color="primary"
        (click)="cancelActionFunction()"
        appTagger
        [event_category]="modalData.category"
        event_label="Solicitar Isenção"
      >
        Solicitar Isenção
      </button>
    </div>
    <div class="">
      <button
        mat-raised-button
        id="modal-action-button"
        color="primary"
        (click)="confirmActionFunction()"
        appTagger
        [event_category]="modalData.category"
        event_label="Incluir na parcela"
      >
        Incluir na parcela
      </button>
    </div>
  </footer>
</div>
