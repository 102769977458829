import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ChecklistItemClientDTO } from '../../../checklist/models/api/query.checklist.model';
import { ClientBiometryStatusEnum } from '../../models/biometry.model';

@Component({
  selector: 'app-agent-legal-status',
  templateUrl: './agent-legal-status.component.html',
  styleUrls: ['./agent-legal-status.component.scss']
})
export class AgentLegalStatusComponent implements OnInit, OnChanges {
  @Input() clientList: ChecklistItemClientDTO[];
  @Input() selectedClient: ChecklistItemClientDTO;
  @Input() isNotMobileCrossFlux = false;
  @Input() hasClientRetrying: boolean;
  @Output() actualBiometryStatus: EventEmitter<ClientBiometryStatusEnum> = new EventEmitter();
  public allDone = false;
  public someDone = false;
  public someInProgress = false;
  public clientBiometryStatusEnum = ClientBiometryStatusEnum;
  public someRetrying = false;
  public showClientInProgressTag = false;

  @Input() category: string;

  constructor(private genTagger: GenTagger) { }

  ngOnChanges(changes: SimpleChanges): void {
    changes.clientList?.currentValue.filter(res => {
      this.actualBiometryStatus.emit(res.biometryStatus);
    });
    if (
      (changes.clientList && this.clientList && this.clientList.length) ||
      (changes.hasClientRetrying && this.hasClientRetrying)
    ) {
      this.checkClientListConditions();
    }
  }

  ngOnInit() {
    if (!this.allDone && !this.isNotMobileCrossFlux && this.someInProgress) {
      this.genTagger.setTag({
        page_location: this.category,
        event_label: 'assinatura do representante em andamento via mobile',
        event_action: Tag.pgView
      });
    }
    if (this.isNotMobileCrossFlux && !this.allDone && this.clientList.length > 1) {
      this.genTagger.setTag({
        page_location: this.category,
        event_label: 'pode informar os informantes que recebaram o link para assinar o contrato em seus e-mails',
        event_action: Tag.pgView
      });
    }
  }

  private checkClientListConditions(): void {
    this.allDone = this.clientList.every(client => client.biometryStatus === ClientBiometryStatusEnum.DONE);
    this.someDone = this.clientList.some(client => client.biometryStatus === ClientBiometryStatusEnum.DONE);
    // this.someInProgress = this.clientList.some(
    //   client => client.biometryStatus === ClientBiometryStatusEnum.IN_PROGRESS
    // );
    const clientInProgress = this.clientList.find(
      client => client.biometryStatus === ClientBiometryStatusEnum.IN_PROGRESS
    );
    this.someInProgress = !!clientInProgress;
    this.someRetrying = this.hasClientRetrying;

    if (clientInProgress && !this.showClientInProgressTag) {
      this.genTagger.setTag({
        page_location: this.category,
        event_label: `Assinatura do ${clientInProgress.name} está em andamento via mobile.`,
        event_element: 'button',
        page_section: `Assinatura do ${clientInProgress.name} está em andamento via mobile.`,
        event_action: Tag.AbriuModal
      });
      this.showClientInProgressTag = true;
    }
  }
}
