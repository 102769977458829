export enum UserPreferences {
  PANEL = 'PANEL',
  PROPOSAL = 'PROPOSAL'
}

//   LOJISTA_MASTER
//   LOJISTA_MASTER_SEC

//   LOJISTA_FINANCEIRO

//   LOJISTA_REPRESENTANTE
//   LOJISTA_REPRESENTANTECDC
//   LOJISTA_REPRESENTANTECSC
//   LOJISTA_REPRESENTANTESUBSIDIO
//   LOJISTA_REPRESENTANTEBIPARTIDO
//   LOJISTA_REPRESENTANTESEGURO

//   LOJISTA_VENDEDOR
//   LOJISTA_VENDEDORCDC
//   LOJISTA_VENDEDORCSC
//   LOJISTA_VENDEDORSUBSIDIO
//   LOJISTA_VENDEDORBIPARTIDO
//   LOJISTA_VENDEDORSEGURO
export enum UserProfilesEnum {
  MASTER = 'Master',
  FINANCING = 'Financeiro',
  REPRESENTATIVE = 'Representante',
  SALESMAN = 'Vendedor'
}
