<div class="container-fluid px-0">
  <div class="row no-gutters">
    <div [ngClass]="isMobile ? 'bottom-sheet-offers-wrapper' : 'col-md-4 d-none d-md-block sidebar fixed-top'">
      <simulation-sidebar [category]="category"></simulation-sidebar>
    </div>
    <div class="col offset-md-4 col-md-8 content">
      <div class="content-items">
        <div class="content-container">
          <p class="best-offer">Melhor oferta</p>
          <p class="title-value" *ngIf="proposal?.proposalData?.product === productCode.CDC">
            Valor financiado {{ offers?.financedValue | localeCurrency }}
          </p>
          <p class="title-value" *ngIf="proposal?.proposalData?.product === productCode.CSC">
            Valor Cedido {{ offers?.financedValue | localeCurrency }}
          </p>
        </div>
        <div *ngIf="insuranceOffers.length > 0">
          <app-step-offer
            title="Parcelas que cabem no bolso"
            [offer]="offers"
            [simulationOffers]="insuranceOffers"
            [proposal]="proposal"
            [category]="category"
          >
          </app-step-offer>
        </div>
        <div *ngIf="noInsuranceOffers.length > 0 && proposal?.proposalData?.product === productCode.CDC">
          <div class="content-container">
            <p class="best-offer">Oferta sem seguro</p>
          </div>
          <app-step-offer
            title="Sem seguro contra imprevistos"
            [offer]="offers"
            [simulationOffers]="noInsuranceOffers"
            [proposal]="proposal"
            [category]="category"
          >
          </app-step-offer>
        </div>
        <!-- BEGIN - CSC -->
        <div
          *ngIf="
            noInsuranceOffers.length > 0 &&
            (proposal?.proposalData?.product === productCode.CSC ||
              proposal?.proposalData?.product === productCode.CSCP)
          "
        >
          <!-- <div class="content-container">
            <p class="best-offer">Melhor oferta</p>
          </div> -->
          <app-step-offer
            title="Parcelas que cabem no bolso"
            [offer]="offers"
            [simulationOffers]="noInsuranceOffers"
            [proposal]="proposal"
            [category]="category"
          >
          </app-step-offer>
        </div>
        <!-- END - CSC -->
      </div>
    </div>
  </div>
</div>
