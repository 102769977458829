import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core/state';
import { SetCompanyRepresentantDataSection } from '@app/core/state/proposal-information/proposal-information.actions';
import * as proposalInformationSelect from '@app/core/state/proposal-information/proposal-information.selectors';
import {
  ClientSignUpData,
  GetProposalInformationDTO,
  ProposalParam,
  ProposalParameters,
  ProposalSignUpData
} from '@app/proposal-workflow/models/proposal-data.model';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { DocumentTypeEnum } from '@shared/constants/documents.enum';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { BrazilValidators } from '@shared/interfaces/validators/brazil-validators.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, take, takeUntil } from 'rxjs/operators';
import { FormValidationService } from './../../../../../shared/services/form-validation/form-validation.service';

const BRAZIL_DDI_CODE = 55;

@Component({
  selector: 'app-representant-data',
  templateUrl: './representant-data.component.html',
  styleUrls: ['./representant-data.component.scss']
})
export class RepresentantDataComponent implements OnInit, OnDestroy, AfterContentChecked, OnChanges {
  protected ngUnsubscribe: Subject<any> = new Subject();

  @Input() public pos: { pos: number; size: number };
  @Input() public comboParams: ProposalParameters;
  @Input() public event_category: string;
  @Input() public setPersistedData: Observable<GetProposalInformationDTO>;
  @Output() public toStart = new EventEmitter<FormGroup>();

  public proposal: ProposalSignUpData;
  public maritalStatusChange$ = null;
  public personType: string;
  public product: string;
  public dataRepresentant: ClientSignUpData;

  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  public maskBirthDate = this.pioneerMasks.maskDate.maskBack;
  public proposalDataRes: GetProposalInformationDTO;
  public form: FormGroup;

  public relationshipTypes$: Observable<ProposalParam[]>;
  public initialValue = true;

  public validadeBirth = true;
  // initialize variables the masking
  public maskDocument = this.pioneerMasks.documentNumber.basicMask;
  public maskPhone = this.pioneerMasks.cellPhone.basicMask;

  public Insert: Tag = Tag.Insert;
  public Select: Tag = Tag.Selecionar;
  public focusItems = {};

  public cellPhoneRegex = /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;

  constructor(
    private internationalizationService: InternationalizationService,
    private simulationService: SimulationService,
    private formValidationService: FormValidationService,
    private store$: Store<AppState>,
    private genTagger: GenTagger,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.buildForm();
    this.getProposalData();
    this.valuesFormsSubscribre();
    this.getLegalNatureId();
    this.toStart.emit(this.form);
    this.setFormSubscription();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.form) {
      this.toStart.emit(this.form);
    }
  }
  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  private buildForm(): void {
    const validatorsBr = this.pioneerValidators as BrazilValidators;

    this.form = new FormGroup({
      clientId: new FormControl(null),
      proposalId: new FormControl(null),
      mainFlag: new FormControl(null),
      type: new FormControl(),
      companyRelationshipTypeId: new FormControl(null, Validators.required),
      person: new FormGroup({
        id: new FormControl(),
        type: new FormControl(),
        name: new FormControl(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(60),
          Validators.pattern(/^[a-zA-ZÀ-ÿ]{2,}(?: [a-zA-ZÀ-ÿ]+){1,}$/)
        ]),
        documentId: new FormControl(null),
        documentNumber: new FormControl(null, [
          Validators.required,
          Validators.minLength(11),
          Validators.maxLength(14),
          this.formValidationService.isCpfValidRepresentation
        ]),
        mainPhone: new FormGroup({
          id: new FormControl(),
          clientId: new FormControl(),
          mainFlag: new FormControl(),
          type: new FormControl(),
          ddiNumber: new FormControl(null),
          dddNumber: new FormControl(null),
          number: new FormControl(null, [Validators.required, Validators.minLength(10), Validators.maxLength(11), Validators.pattern(this.cellPhoneRegex)])
        }),
        mainEmail: new FormGroup({
          id: new FormControl(),
          clientId: new FormControl(),
          mainFlag: new FormControl(),
          type: new FormControl(),
          email: new FormControl(null, validatorsBr.email)
        })
      })
    });
  }

  private addPhysicalForm(): void {
    (this.form.get('person') as FormGroup).addControl(
      'physical',
      new FormGroup({
        clientId: new FormControl(),
        // REFACTOR: - Add dynamic validators to check masked or not masked date
        birthDate: new FormControl(null, [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(10)
        ]),
        nationalityId: new FormControl(null, Validators.required),
        genderId: new FormControl(null, Validators.required),
        professionId: new FormControl(null, Validators.required),
        motherName: new FormControl(null, [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(60),
          Validators.pattern(/^[a-zA-ZÀ-ÿ]{2,}(?: [a-zA-ZÀ-ÿ]+){1,}$/)
        ])
      })
    );
  }
  public valuesFormsSubscribre(): void {
    this.setPersistedData.subscribe(res => {
      if (!!res) {
        this.proposalDataRes = res;
        this.setValueRecovered();
        if (this.form.valid) {
          this.updateRepresentantData();
        }
        this.validadeBirth = false;
      }
      this.toStart.emit(this.form);
    })
    this.proposalDataRes = null;
  }

  public setValueRecovered(): void {
    let physicalPerson = this.proposalDataRes?.clients.find(F => F.person.type === 'F');
    if (!!physicalPerson) {
      this.form.get('person').get('physical').get('birthDate').patchValue(null);
      physicalPerson.person.physical.birthDate =
        physicalPerson.person.physical.birthDate ?
          physicalPerson.person.physical.birthDate.includes('x') ?
            physicalPerson.person.physical.birthDate
            : moment(physicalPerson.person.physical.birthDate).format('DDMMYYYY')
          : null;
      this.form.get('person').get('physical').get('birthDate').setValidators([]);
      this.form.get('person').get('physical').get('birthDate').updateValueAndValidity();
      if (physicalPerson.person.phones.length > 0) {
        this.form.get('person').get('documentNumber').patchValue(null);
        this.form.patchValue(physicalPerson);
        this.form.get('person').get('mainPhone').setValue({
          clientId: null,
          dddNumber: null,
          ddiNumber: null,
          id: null,
          mainFlag: null,
          number: null,
          type: null
        });
        this.form.get('person').get('mainPhone').patchValue(physicalPerson.person.phones[0]);
      }
    }

    if (this.form.valid) {
      this.validadeBirth = true;
      this.updateRepresentantData();
    }
    physicalPerson = null;
  }
  public getProposalData() {
    this.store$
      .select(proposalInformationSelect.getSimulationProposalData)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter((data: ProposalSignUpData) => !!data && Object.values(data).some(item => !!item)),
        take(1)
      )
      .subscribe((data: ProposalSignUpData) => {
        this.proposal = data;
        this.product = data.productCode;
        this.proposalDataRes?.clients.find(F => F.person.type === 'F');
        const secondaryClient = this.proposal?.secondaryClient;
        this.addPhysicalForm();

        if (secondaryClient.person) {
          this.personType = secondaryClient.person.type;
          secondaryClient.person.physical.birthDate = secondaryClient.person.physical?.birthDate
            ? secondaryClient.person.physical?.birthDate.includes('x')
              ? secondaryClient.person.physical?.birthDate
              : moment(secondaryClient.person.physical?.birthDate).format('DDMMYYYY')
            : null;
          this.form.patchValue(secondaryClient);
        }
        if (this.form.valid) {
          this.validadeBirth = true;
          this.updateRepresentantData();
        }

        this.maskDocument = this.pioneerMasks.documentNumber.maskBack;
        this.maskPhone = this.pioneerMasks.cellPhone.maskBack;
        this.getSectionData();

      });
  }
  protected setEmailValue(data: ClientSignUpData) {
    const setEmail = data.person.mainEmail
    if (!!setEmail) {
      this.form
        .get('person')
        .get('mainEmail')
        .patchValue(setEmail);
      return;
    } this.form
      .get('person')
      .get('mainEmail')
      .patchValue(data.person.emails[0]);
  }
  private getSectionData() {
    this.store$
      .select(proposalInformationSelect.companyRepresentantDataSection)
      .pipe(
        filter((data: ClientSignUpData) => !!data),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(data => {
        this.setEmailValue(data);
        const setEmail = data.person.mainEmail
        if (!!setEmail) {
          this.form
            .get('person')
            .get('mainEmail')
            .patchValue(setEmail);
          return;
        } this.form
          .get('person')
          .get('mainEmail')
          .patchValue(data.person.emails[0]);

        const phone = data.person.phones[0];
        if (phone) {
          const { clientId, dddNumber, ddiNumber, id, mainFlag, phoneNumber: number, type } = phone;
          const phoneObj = {
            clientId,
            dddNumber,
            ddiNumber,
            id,
            mainFlag,
            number: dddNumber + number,
            type
          };

          this.form
            .get('person')
            .get('mainPhone')
            .patchValue(phoneObj);
        }

        this.form.get('clientId').patchValue(data.clientId);
        this.form
          .get('person')
          .get('id')
          .patchValue(data.person.id);
      });
  }

  public getLegalNatureId(): void {
    this.store$
      .select(proposalInformationSelect.companyLegalNature)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(legalNatureId => {
        if (this.initialValue) {
          this.initialValue = false;
        }
        this.getRelationshipTypes(legalNatureId);
      });
  }

  public getRelationshipTypes(legalNatureId: string): void {
    this.relationshipTypes$ = this.simulationService.getLegalRepresentativeRelationship(legalNatureId).pipe(first());
  }

  private setFormSubscription(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged((prev, current) => JSON.stringify(prev) === JSON.stringify(current)),
        debounceTime(1000),
        filter(() => this.form.valid)
      )
      .subscribe(() => {
        this.updateRepresentantData();
      });
  }

  private updateRepresentantData(): void {
    const fullPhoneNumber = this.handlePhoneNumber('mainPhone');
    const data: ClientSignUpData = this.form.getRawValue();
    data.proposalId = this.proposal.id;
    data.clientId = data.person.id;
    data.person = {
      ...data.person,
      proposalId: this.proposal.id,
      documentId: this.comboParams.documentTypes.find(p => p.description === DocumentTypeEnum.CPF).id,
      mainEmail: {
        ...data.person.mainEmail,
        mainFlag: true
      },
      mainPhone: {
        ...data.person.mainPhone,
        mainFlag: true,
        ddiNumber: fullPhoneNumber?.ddiNumber ? fullPhoneNumber.ddiNumber : null,
        dddNumber: fullPhoneNumber?.dddNumber ? fullPhoneNumber.dddNumber : null,
        number: fullPhoneNumber?.number ? fullPhoneNumber.number : null,
      },

    };

    const birthdate = data.person.physical.birthDate?.replace(/[^0-9]/g, '');
    this.store$.dispatch(
      new SetCompanyRepresentantDataSection({
        ...data,
        person: {
          ...data.person,
          physical: {
            ...data.person.physical,
            birthDate: data.person.physical?.birthDate?.includes('x')
              ? 'xx/xx/xx' + birthdate
              : moment(data.person.physical.birthDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
          },
          addresses:
            !!this.proposal && this.proposal.secondaryClient && this.proposal.secondaryClient.person
              ? this.proposal.secondaryClient.person.addresses
              : null,
        }
      })
    );
    this.validadeBirth = false;
  }

  setBirthValidade(): void {
    this.form.controls.birthDate.valueChanges
      .pipe(
        filter(value => !!value),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(value => {
        if (!this.validadeBirth) {
          this.form.controls.birthDate.setValidators([
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(8),
            this.formValidationService.isValidDateOfBith
          ]);
        }
      });
  }

  public getMask() {
    return '(XX) XXXXX-XXXX';
  }

  public setMaskPhone(event) {
    this.maskPhone = this.pioneerMasks.cellPhone.basicMask;
  }

  public setMaskDocument(event) {
    this.maskDocument = this.pioneerMasks.documentNumber.basicMask;
  }

  public handlePhoneNumber(controlName: string) {
    const control = this.form.get('person').get(controlName);
    if (!control || (!!control && control.invalid)) {
      return;
    }

    return {
      ddiNumber: BRAZIL_DDI_CODE.toString(),
      dddNumber: control.value.number.substring(0, 2),
      number: control.value.number.substring(2, 11)
    };
  }

  public dateValidator(control: FormControl): { [s: string]: boolean } {
    if (control && control.value) {
      const date = moment(control.value, 'DDMMYYYY', true);
      const today = moment();
      if (date.isValid() && date.isBefore(today)) {
        return null;
      }
      return { invalidDate: true };
    }
    return null;
  }

  public checkFocus(key: string): void {
    if (!this.focusItems[key]) {
      let control: FormControl;

      if (key.includes('.')) {
        control = key.split('.').reduce((a: FormGroup, b: string) => a.get(b), this.form) as FormControl;
      } else {
        control = this.form.get(key) as FormControl;
      }

      this.focusItems[key] = true;
      control.reset();
    }
    if (key === 'person.physical.birthDate') {
      this.setDateOfBirthValidator();
    }
  }

  private setDateOfBirthValidator(): void {
    this.form.get('person').get('physical').get('birthDate').setValidators([Validators.required,
    Validators.minLength(2),
    Validators.maxLength(10),
    ...this.pioneerValidators.dateOfBirth]);
    this.form.get('person').get('physical').get('birthDate').updateValueAndValidity();
  }

  public onGenreChange() {
    const value = this.form
      .get('person')
      .get('physical')
      .get('genderId').value;
    const gender = this.comboParams.genders.find(p => p.id === value);
    this.genTagger.setTag({
      event_action: `${this.Select} - gênero`,
      event_category: this.event_category,
      event_label: gender.description.toLowerCase()
    });
  }

  public onLegalNatureChange() {
    let relationshipType: ProposalParam;
    const value = this.form.get('companyRelationshipTypeId').value;
    this.relationshipTypes$.subscribe(r => {
      relationshipType = r.find(c => c.id === value);
      this.genTagger.setTag({
        event_action: `${this.Select} - tipo de relacionamento com a empresa`,
        event_category: this.event_category,
        event_label: relationshipType.description.toLowerCase()
      });
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
