import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { SessionStorageService } from '../session-storage/session-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ShopkeeperService {
  constructor(private http: HttpClient, private sessionStorageService: SessionStorageService) {}

  getAgent(shopCode): Observable<any> {
    const user = this.sessionStorageService.getUser();
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: 'Bearer ' + user.accessToken
      })
    };
    const endpoint = `${environment.apiRootUrl}/shopkeeper-cert-agents/1.0.0/shop/certified-agent`;
    return this.http.get<any>(endpoint, httpOptions);
  }
}
