<div class="containerLoading">
  <div class="loading-indicator__spinner">
    <ng-lottie [options]="lottieSantander" width="200%" height="100%" (animCreated)="handleAnimation($event)">
    </ng-lottie>
  </div>

  <div class="section">
    <span class="itemDescription" [@inOutAnimation]="state">{{message}}</span>
    <span>Por favor, aguarde...</span>
  </div>
</div>
