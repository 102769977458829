import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import * as fromProposal from '@app/core/state/proposal';
import {
  GetConfirmationData,
  GetStageProposal,
  SetProposalData
} from '@app/core/state/proposal-information/proposal-information.actions';
import { selectProposalStage } from '@app/core/state/proposal/proposal.selectors';
import * as fromRegister from '@app/core/state/register';
import { SetPayloadBestOffers } from '@app/core/state/simulation/simulation.actions';
import { getPayloadBestOffers } from '@app/core/state/simulation/simulation.selectors';
import { AcceptedDataRequest } from '@app/proposal-workflow/models/accepted-data-request.model';
import { FormSection } from '@app/proposal-workflow/models/form-section.model';
import { LiquidationTypes } from '@app/proposal-workflow/models/liquidation-types.enum';
import { OsaCodeErrorsEnum } from '@app/proposal-workflow/models/osa-code-errors.enum';
import { ProceedErrorModalData } from '@app/proposal-workflow/models/proceed-error-modal-data.model';
import {
  CertifiedAgents,
  ClientSignUpData,
  ConfirmationData,
  GetProposalInformationDTO,
  ProposalParameters
} from '@app/proposal-workflow/models/proposal-data.model';
import { ProposalStageEnum } from '@app/proposal-workflow/models/proposal-stage.enum';
import { GetProposalStageDTO } from '@app/proposal-workflow/models/proposal-stage.model';
import { RegisterSections } from '@app/proposal-workflow/models/register-sections.enum';
import { RegisterProposalFlags } from '@app/proposal-workflow/models/step-register-put.enum';
import { ProposalProceedRequest, ProposalProceedResponse } from '@app/proposal-workflow/models/step-register-requests';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LgpdFormComponent } from '@shared/components/lgpd-form/lgpd-form.component';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { AddRequestAction, RemoveRequestAction } from '@shared/components/widgets/loading/store/loading.actions';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { SubsegmentsEnum } from '@shared/constants/subsegments.enum';
import { LgpdConsentAccessType } from '@shared/models/lgpd-consent.enum';
import { ProposalStageService } from '@shared/services/proposal/proposal-stage.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { debounceTime, filter, first, map, take, takeUntil, tap } from 'rxjs/operators';
import {
  BacenAnswersModel,
  BacenAnswersValue
} from '../step-formalization/components/form-payment/models/form-payment.model';
import {
  getConfirmationData,
  personalDataSection,
  stageStatusProposalSelector
} from './../../../core/state/proposal-information/proposal-information.selectors';
import { ProceedErrorModalComponent } from './components/proceed-error/proceed-error-modal.component';
import { RecoveryDataModalComponent } from './components/recovery-data-modal/recovery-data-modal.component';
const ACCEPTED_DATA_CODE = 'AUTRCCIPPE';

// TO DO Ivirson - Check with bank code. Actually is comparing with id
const SANTANDER_BANK_CODE = '33';

@Component({
  selector: 'app-step-register-container',
  templateUrl: './step-register-container.component.html',
  styleUrls: ['./step-register-container.component.scss']
})
export class StepRegisterContainerComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild('lgpdForm') lgpdForm: LgpdFormComponent;
  @ViewChild('modal') modal: LgpdFormComponent;

  public proposalStageEnum = ProposalStageEnum;
  public proposalId: string;
  public proposalData: GetProposalInformationDTO;
  public proposalDataRes: GetProposalInformationDTO;
  public mainClient: ClientSignUpData;
  public secondaryClient: ClientSignUpData;

  public comboParams$: Observable<ProposalParameters> = this.simulationService.getParametersProposal();
  public form: FormArray;
  public formAgent: FormGroup;
  public lgpdFormGroup: FormGroup;
  public listBySection: FormSection[] = [];
  public proposal: ConfirmationData;
  public certifiedAgents: CertifiedAgents[];
  public payloadBestOffers: any

  public MOBILE_BREAKPOINT = 768;

  public lgpdConsentAccessType = LgpdConsentAccessType;

  public personsType = SubmitPersonType;
  public productCode = ProductCodeEnum;
  public subsementsCode = SubsegmentsEnum;
  public registerSections = RegisterSections;
  public liquidationTypes = LiquidationTypes;
  public getPersistedData: BehaviorSubject<GetProposalInformationDTO> = new BehaviorSubject<GetProposalInformationDTO>(null);
  protected setPersistedData: Observable<GetProposalInformationDTO> = this.getPersistedData.asObservable();
  protected errorOccurredwhileRecording = false;
  public showLgpdSection = true;
  public lgpdFormIsValid = true;
  public mainClientData: ClientSignUpData;
  public bacenFormIsValid = true;
  public flagBackToSimulation$: Observable<GetProposalStageDTO>;
  public isStageAnalysisCD = false;
  public pollingRunning = false

  public Click = Tag.Click;
  public event_category: string;

  constructor(
    private translateService: TranslateService,
    private store$: Store<AppState>,
    private simulationService: SimulationService,
    private router: Router,
    public dialog: MatDialog,
    private proposalStageService: ProposalStageService,
    public genTagger: GenTagger
  ) {}

  ngOnInit() {
    this.getConfirmationData();
    this.getInformationDataProposal();
    this.buildForm();
    this.getCurrentStage();
    this.getAgentCertified();
  }
  private getAgentCertified() {
    this.simulationService.getAgentCertified(this.proposalId).subscribe(res => {
      if (res?.agentCode) {
        this.formAgent.get('certifiedAgentCode').setValue(res.agentCode);
      }
    })
  }

  private verifyStatus() {
    let maxAttempts = 0
    this.store$.dispatch(new AddRequestAction({ id: 'POLLING CASDATRO', request: null}));
    this.store$.dispatch(new GetStageProposal());

    // 41 - Erro NO ADP - Não deve seguir
    // 37 - Inclusão ADP - Não deve seguir
    const CALL_POLLING = [41, 37]
    const ANALYSIS_CD = [11]
    const CADASTRO = [3]

    this.store$.select(stageStatusProposalSelector)
    .pipe(
      filter(data => data !== null),
      debounceTime(2000),
      tap(() => maxAttempts++),
      map((res) => maxAttempts > 10 ? { status: false, data : null} : res),
      takeUntil(this.ngUnsubscribe)
    ).subscribe(statusProposal => {
      if (statusProposal.status) {
          if (CALL_POLLING.includes(statusProposal.data.idStage)) {
            this.store$.dispatch(new GetStageProposal())
          } else if (ANALYSIS_CD.includes(statusProposal.data.idStage)) {
            this.store$.dispatch(new RemoveRequestAction({ id: 'POLLING CASDATRO', request: null }));
            this.dialog.open(PionModalComponent, {
              data: {
                title: 'Atenção',
                description: 'Essa proposta irá passar por uma análise adicional de crédito, por favor acompanhar pelo painel de propostas',
                confirmText: 'Ok, eu entendi',
                type: 'alert',
              },
              maxWidth: '500px'
            }).afterClosed().pipe(takeUntil(this.ngUnsubscribe))
              .subscribe(() => {
                this.backToOpenProposals()
              })

          } else if (CADASTRO.includes(statusProposal.data.idStage)) {
            this.store$.dispatch(new RemoveRequestAction({ id: 'POLLING CASDATRO', request: null }));
          } else {
            this.store$.dispatch(new RemoveRequestAction({ id: 'POLLING CASDATRO', request: null }));
            this.router.navigate(['proposal/step-confirmation']);
          }
        } else {
          this.store$.dispatch(new RemoveRequestAction({ id: 'POLLING CASDATRO', request: null }));
          this.dialog.open(PionModalComponent, {
            data: {
              title: 'Atenção',
              description: 'Erro ao processar proposta, por gentileza continuar a proposta pelo painel de propostas',
              confirmText: 'Ok, eu entendi',
              type: 'alert',
            },
            maxWidth: '500px'
          }).afterClosed().pipe(takeUntil(this.ngUnsubscribe))
            .subscribe(() => {
              this.backToOpenProposals()
            })
        }
      })
  }

  private getCurrentStage() {
    this.proposalStageService.getProposalStage(this.proposalId)
      .pipe(take(1))
      .subscribe(stage => {
        this.isStageAnalysisCD = stage.idStage === this.proposalStageEnum.ANALYSIS_CD;
        if (stage.idStage === this.proposalStageEnum.CONFIRMATION) {
          this.store$.dispatch(new fromRegister.actions.ClearRegisterData());
          this.getPersistedData.next(null);
          this.router.navigate(['proposal/step-confirmation']);
        }
        if (this.isStageAnalysisCD) {
          setTimeout(() => {
            this.getCurrentStage();
          }, 10000);
        };
      });
  }

  public getInformationDataProposal() {
    this.flagBackToSimulation$ = this.store$
    .select(selectProposalStage)
  }

  private getConfirmationData() {
    this.store$
      .pipe(
        select(getConfirmationData),
        filter(data => !!data),
        take(1)
      )
      .subscribe(state => {
        this.proposal = state;
        if (state?.proposalData?.product === ProductCodeEnum.CDC) {
          this.event_category = '/portallojista/criarproposta/cdc/cadastro';
        } else if (state?.proposalData?.product === ProductCodeEnum.CSC) {
          this.event_category = '/portallojista/criarproposta/csc/cadastro';
          this.formAgent.get('certifiedAgentCode').clearValidators();
          this.formAgent.updateValueAndValidity();
        } else if (state?.proposalData?.product === ProductCodeEnum.CSCP ){
          this.event_category = '/portallojista/criarproposta/cscp/cadastro';
          this.formAgent.get('certifiedAgentCode').clearValidators();
          this.formAgent.updateValueAndValidity();
        }
        if(!(state.proposalData.product === ProductCodeEnum.CSC || state.proposalData.product === ProductCodeEnum.CSCP)){
          this.getCertifiedAgents();
        }
        this.genTagger.setTag({
          event_category: this.event_category,
          event_label: 'Cadastro',
          event_action: Tag.pgView
        });
      });
    this.form = new FormArray([]);
    this.getData();
    this.mainClientSubscription();
    //this.verifyIsFormValid();
  }

  private mainClientSubscription(): void {
    this.store$
      .select(personalDataSection)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(mainClient => (this.mainClient = mainClient));
  }

  public getData(): void {
    this.store$
      .select(fromProposal.selectors.selectProposalId)
      .pipe(
        filter(proposalId => !!proposalId),
        first()
      )
      .subscribe((proposalId: string) => {
        this.proposalId = proposalId;
        this.store$.dispatch(new GetConfirmationData(this.proposalId));

        this.getProposalInformationData();
      });
  }

  public getProposalInformationData(): void {
    this.simulationService
      .getProposalInformationData(this.proposalId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(res => !!res)
      )
      .subscribe((data: GetProposalInformationDTO) => {
        this.proposalData = data;
         if (this.errorOccurredwhileRecording && !!data) {
          this.proposalDataRes = data;
          this.getPersistedData.next(data);
         this.errorOccurredwhileRecording = false
       }
        const recoveredDataFromCipOrPe = data.flags.find(f => f.code === RegisterProposalFlags.RECOVERED_BY_CIP_OR_PE);
        const acceptedDataFromCipOrPe = data.flags.find(f => f.code === RegisterProposalFlags.ACCEPTED_DATA_CIP_OR_PE);

        if (!!data && !acceptedDataFromCipOrPe && (!!recoveredDataFromCipOrPe && recoveredDataFromCipOrPe.value)) {
          this.openRecoveryDataModal();
          return;
        }

        this.setDataIntoStore();
      });
  }

  public openRecoveryDataModal() {
    const dialogRef = this.dialog.open(RecoveryDataModalComponent, {
      width: window.innerWidth <= this.MOBILE_BREAKPOINT ? '80%' : '600px',
      maxWidth: '100%',
      data: {
        personType: this.proposalData.clients.find(c => c.mainFlag).person.type,
        event_category: this.event_category
      }
    });

    dialogRef.afterClosed().subscribe((result: boolean) => {
      if (!!result) {
        this.getClientsFromServices();
        return;
      }

      this.setDataIntoStore();

      this.submitAcceptedDataFlag(false);
    });
  }

  private setDataIntoStore(clientRetrieved?): void {
    const { ...proposalData } = this.proposalData;
    let { clients } = this.proposalData;
    if (clientRetrieved) {
      clients = clientRetrieved;
    }

    let mainClient: ClientSignUpData;
    let secondaryClient: ClientSignUpData;

    mainClient = clients.find(client => client.mainFlag);
    secondaryClient = clients.find(client => client.type === 'RPP');
    this.mainClient = mainClient;
    this.mainClientData = mainClient;
    this.secondaryClient = secondaryClient;
    this.store$.dispatch(new SetProposalData({ mainClient, secondaryClient, ...proposalData }));
  }

  private getClientsFromServices(): void {
    this.simulationService
      .getClientsFromServices(this.proposalId)
      .pipe(first())
      .subscribe((res: ClientSignUpData[]) => {
        this.proposalData = { clients: res, ...this.proposalData };
        this.setDataIntoStore(res);
        this.submitAcceptedDataFlag(true);
      });
  }

  public submitAcceptedDataFlag(userResponse: boolean) {
    const payload: AcceptedDataRequest = {
      proposalId: this.proposalId,
      code: ACCEPTED_DATA_CODE,
      flag: userResponse ? BacenAnswersValue.YES : BacenAnswersValue.NO,
      userCodeUpdate: this.mainClient.person.documentNumber
    };
    this.simulationService
      .setProposalAcceptedDataFlag(payload)
      .pipe(first())
      .subscribe();
  }

  public onFormLgpdLoaded(event: boolean) {
    if (!event) {
      this.lgpdFormIsValid = true;
      this.showLgpdSection = false;
      return;
    }

    this.showLgpdSection = true;
    this.lgpdFormIsValid = false;
  }

  public onFormLgpdAnswered() {
    this.lgpdFormIsValid = true;
    if (this.mainClient) {
      this.lgpdForm.saveConsent(this.mainClient.person.documentNumber, this.mainClient.person.mainEmail?.email);
    }
  }

  public getPos(sectionName: RegisterSections) {
    const hasBacen = !!(this.proposalData && this.proposalData.liquidationTypeCode === LiquidationTypes.DEBITO);
    const hasLgpd = !!(
      this.showLgpdSection &&
      this.mainClient &&
      this.mainClient.person &&
      this.mainClient.person.type === this.personsType.PF
    );
    const hasTraveler = !!(this.proposalData && this.proposalData.isTravelSubSegment);
    const hasRepresentant = !!(
      this.mainClient &&
      this.mainClient.person &&
      this.mainClient.person.type === this.personsType.PJ
    );
    const haveCertifiedAgent = this.proposal?.proposalData?.product === ProductCodeEnum.CDC

    let position: number;
    switch (sectionName) {
      case this.registerSections.PERSONAL_DATA:
        position = 1;
        break;
      case this.registerSections.ADDRESS_DATA:
        position = 2;
        break;
      case this.registerSections.TRAVELER_DATA:
        position = 3;
        break;
      case this.registerSections.REPRESENTANT_DATA:
        position = hasTraveler ? 4 : 3;
        break;
      case this.registerSections.REPRESENTANT_ADDRESS_DATA:
        position = hasTraveler ? 5 : 4;
        break;
      case this.registerSections.OBSERVATION_DATA:
        if (hasTraveler && hasRepresentant) {
          position = 6;
        }
        if (!hasTraveler && hasRepresentant) {
          position = 5;
        }
        if (hasTraveler && !hasRepresentant) {
          position = 4;
        }
        if (!hasTraveler && !hasRepresentant) {
          position = 3;
        }
        break;
      case this.registerSections.BACEN_DATA:
        if (hasTraveler && hasRepresentant) {
          position = 7;
        }
        if (!hasTraveler && hasRepresentant) {
          position = 6;
        }
        if (hasTraveler && !hasRepresentant) {
          position = 5;
        }
        if (!hasTraveler && !hasRepresentant) {
          position = 4;
        }
        break;
      case this.registerSections.CERTIFICATE_AGENTE:
        if ((hasTraveler && hasRepresentant && hasBacen)) {
          position = 8;
        }
        if (
          (!hasTraveler && hasRepresentant && hasBacen)
          || (hasTraveler && hasRepresentant && !hasBacen)
          ) {
          position = 7;
        }
        if ((hasTraveler && !hasRepresentant && hasBacen)
          || (!hasTraveler && hasRepresentant && !hasBacen)
        ) {
          position = 6;
        }
        if ((hasTraveler && !hasRepresentant && !hasBacen)
          || (!hasTraveler && !hasRepresentant && hasBacen)){
            position = 5;
        }
        if (!hasTraveler && !hasRepresentant && !hasBacen) {
          position = 4;
        }
        break;
      case this.registerSections.LGPD_DATA:
        if (hasTraveler && hasRepresentant && hasBacen && haveCertifiedAgent) position = 9;
        if ((hasTraveler && hasRepresentant && hasBacen && !haveCertifiedAgent)
          || (hasTraveler && hasRepresentant && !hasBacen && haveCertifiedAgent)
          || (!hasTraveler && hasRepresentant && hasBacen && haveCertifiedAgent)) {
          position = 8;
        }
        if (
          (!hasTraveler && hasRepresentant && hasBacen && !haveCertifiedAgent)
          || (hasTraveler && hasRepresentant && !hasBacen && !haveCertifiedAgent)
          || (!hasTraveler && hasRepresentant && !hasBacen && haveCertifiedAgent)
          || (hasTraveler && !hasRepresentant && hasBacen && haveCertifiedAgent)
          ) {
          position = 7;
        }
        if ((hasTraveler && !hasRepresentant && hasBacen && !haveCertifiedAgent)
          || (!hasTraveler && hasRepresentant && !hasBacen && !haveCertifiedAgent)
          || (hasTraveler && !hasRepresentant && !hasBacen && haveCertifiedAgent)
          || (!hasTraveler && !hasRepresentant && hasBacen && haveCertifiedAgent)
        ) {
          position = 6;
        }
        if ((hasTraveler && !hasRepresentant && !hasBacen && !haveCertifiedAgent)
          || (!hasTraveler && !hasRepresentant && hasBacen && !haveCertifiedAgent)
          || (!hasTraveler && !hasRepresentant && !hasBacen && haveCertifiedAgent)){
            position = 5;
        }
        if (!hasTraveler && !hasRepresentant && !hasBacen && !haveCertifiedAgent) {
          position = 4;
        }
        break;
    }

    const flags = [];
    flags.push(hasTraveler);
    flags.push(hasRepresentant);
    flags.push(hasRepresentant);
    flags.push(hasBacen);
    flags.push(haveCertifiedAgent);
    flags.push(hasLgpd);

    return {
      pos: position,
      size: this.getSectionsQty(flags)
    };
  }

  public getSectionsQty(flags: boolean[]): number {
    let qty = 3;
    qty =
      qty +
      flags.reduce((acc, curr) => {
        if (curr) {
          acc += 1;
        }
        return acc;
      }, 0);
    return qty;
  }
  public buildForm(): void {
    this.formAgent = new FormGroup({
      certifiedAgentCode: new FormControl(null)
    });
  }

  public getCertifiedAgents(): void {
    this.simulationService
      .getCertifiedAgents(this.proposal.proposalData.storeCode)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(res => !!res)
      )
      .subscribe((res: CertifiedAgents[]) => {
        this.certifiedAgents = res;
        if(!!this.certifiedAgents){
          this.formAgent.get('certifiedAgentCode').setValidators([ Validators.required]);
          this.formAgent.updateValueAndValidity();
        }
        this.certifiedAgents.forEach(a => {
          a.agentName = a.agentName.trimRight();
        });

        if (!!this.certifiedAgents.length && this.certifiedAgents.length === 1) {

          this.formAgent.patchValue({
            certifiedAgentCode: this.certifiedAgents[0].agentCertifiedNumber
          });

          this.genTagger.setTag({
            event_category: this.event_category,
            event_action: Tag.Click,
            event_label: this.translateService.instant('STEP-CONFIRMATION-WITHOUT-CERTIFIED-AGENT')
          });
        }
        this.certifiedAgents
      });
  }


  public listenFormChange(formGroup: FormGroup, section: number): void {
    this.form.push(formGroup);
    this.listBySection.push({ section, formGroup });

    const idx = this.listBySection.findIndex((list: FormSection) => list.formGroup.invalid);
    if (idx > 0 && this.listBySection.length === 4) {
      const item = this[`section${this.listBySection[idx].section}`];
      if (item) {
        this.scrollToNextSection(item.nativeElement);
      }
    }

    this.form.updateValueAndValidity();
  }

  public scrollToNextSection(currentSection: RegisterSections) {
    let target: string;

    switch (currentSection) {
      case this.registerSections.PERSONAL_DATA:
        target = this.registerSections.ADDRESS_DATA;
        break;
      case this.registerSections.ADDRESS_DATA:
        if (this.proposalData.isTravelSubSegment) {
          target = this.registerSections.TRAVELER_DATA;
          break;
        }
        target =
          this.mainClient.person.type === this.personsType.PF
            ? this.registerSections.OBSERVATION_DATA
            : this.registerSections.REPRESENTANT_DATA;
        break;
      case this.registerSections.TRAVELER_DATA:
        target =
          this.mainClient.person.type === this.personsType.PF
            ? this.registerSections.OBSERVATION_DATA
            : this.registerSections.REPRESENTANT_DATA;
        break;
      case this.registerSections.REPRESENTANT_DATA:
        target = this.registerSections.REPRESENTANT_ADDRESS_DATA;
        break;
      case this.registerSections.REPRESENTANT_ADDRESS_DATA:
        target = this.registerSections.OBSERVATION_DATA;
        break;
      case this.registerSections.OBSERVATION_DATA:
        if (this.mainClient.person.type === this.personsType.PF) {
          target = this.registerSections.LGPD_DATA;
        }
        if (this.proposalData.liquidationTypeCode === this.liquidationTypes.DEBITO) {
          target = this.registerSections.BACEN_DATA;
        }
        break;
      case this.registerSections.BACEN_DATA:
        if (this.mainClient.person.type === this.personsType.PF) {
          target = this.registerSections.LGPD_DATA;
          break;
        }
        break;
    }

    document.getElementById(target).scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  public openProceedModal(data?: ProceedErrorModalData) {
    const dialogRef = this.dialog.open(ProceedErrorModalComponent, {
      width: window.innerWidth <= this.MOBILE_BREAKPOINT ? '80%' : '600px',
      maxWidth: '100%',
      data
    });

    dialogRef.afterClosed().subscribe(() => {
      if (data.errorCode === OsaCodeErrorsEnum.INVALID_EMAIL) {
        this.scrollToNextSection(this.registerSections.PERSONAL_DATA);
      }
      if (data.errorCode === OsaCodeErrorsEnum.DENIED) {
        this.router.navigate(['/showcase/open-proposal']);
      }
    });
  }


  public saveRegister(): void {
    if(this.proposal?.proposalData?.product === ProductCodeEnum.CSC || this.proposal?.proposalData?.product === ProductCodeEnum.CSCP){
      this.proceedToConfirmation();
      return;
    }
    const payload = this.certifiedAgents?.find(
      a => a.agentCertifiedNumber === this.formAgent.getRawValue().certifiedAgentCode
    );

    this.simulationService.persistProposalCertifiedAgent(this.proposalId, payload).subscribe(
      () => {
        this.proceedToConfirmation();
      },
      err => {
        return;
      }
    );
  }


  public proceedToConfirmation() {
    const proposalValidateData: ProposalProceedRequest = {
      channel: 1,
      proposalId: this.proposalId,
      currentStage: this.proposalStageEnum.DATA_REGISTER
    };
   // if(!!event){
    this.store$
      .select(fromRegister.selectors.registerData)
      .pipe(first())
      .subscribe((data: fromRegister.reducer.RegisterState) => {
        const payload = {
          ...data,
          proposalValidateData
        };

        this.simulationService
          .proceedAndSaveRegister(payload)
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe(
            (res: ProposalProceedResponse) => {
              const modalData: ProceedErrorModalData = {
                errorCode: res.code
              };

              if (!res.validated) {
                if (res.code === OsaCodeErrorsEnum.INVALID_EMAIL) {
                  modalData.title = 'EMAIL';
                  modalData.msg = 'ENTER-NEW-EMAIL-TO-CONTINUE';
                  modalData.btnLabel = 'STEP-REGISTER-ERROR-EMAIL-PROCEED-MODAL-BTN-LABEL';
                }
                if (res.code === OsaCodeErrorsEnum.DENIED) {
                  modalData.title = 'STEP-REGISTER-ERROR-DENIED-PROCEED-MODAL-TITLE';
                  modalData.msg = 'STEP-REGISTER-ERROR-DENIED-PROCEED-MODAL-SUBTITLE';
                  modalData.btnLabel = 'Close';
                }
                this.openProceedModal(modalData);
                return;
              }
               this.verifyStatus();
          },
          (err) => {
            const error = err.error;
            const modalData: ProceedErrorModalData = {
              btnLabel: 'Exit'
            };

            if (err.status === 406) {
             this.errorOccurredwhileRecording = true;
             this.ModalError();
             return
            }

            if (this.checkErrorType(error)) {
              modalData.title = error.messages[0].message;
              modalData.msg = error.messages[1].message;
              modalData.errorCode = error.messages[2].message;
            } else {
              modalData.title = 'STEP-REGISTER-ERROR-PROCEED-MODAL-TITLE';
              modalData.msg = 'STEP-REGISTER-ERROR-PROCEED-MODAL-SUBTITLE';
              modalData.errorCode = error.code;
            }
            this.openProceedModal(modalData);
            return;
          });
      });
    //}
  }

  public backToOpenProposals(): void {
    this.router.navigate(['/showcase/open-proposal']);
  }

  public ModalError(): void {
    this.getPersistedData.next(null);
    const ref = this.dialog.open(PionModalComponent, {
      data: {
        title: this.translateService.instant('SAVE-SIMULATION-ERROR-MODAL-TITLE'),
        description: this.translateService.instant('SAVE-SIMULATION-ERROR-MODAL-DESCRIPTION'),
        confirmText: 'OK',
        cancelText: null,
        type: 'alert'
      },
      maxWidth: '500px'
    });
    ref.afterClosed().subscribe(() => {
      this.errorOccurredwhileRecording = true;
      this.getConfirmationData();
    });
  }

  private checkErrorType(error) {
    if ( ('errorId' in error) ) {
      return true;
    }
    return false;
  }

  public backToSimulation(): void {
    this.store$.select(getPayloadBestOffers).pipe(takeUntil(this.ngUnsubscribe)).subscribe( res=>{
      this.payloadBestOffers = res;
      if(!!res.payload){
      this.payloadBestOffers.isReturn = true;
      }
    })
    this.store$.dispatch(new SetPayloadBestOffers(this.payloadBestOffers.payload, this.payloadBestOffers.isReturn));
    const payload = {
      channel: 1,
      proposalId: this.proposalId,
      currentStage: this.proposalStageEnum.DATA_REGISTER
    };
    this.simulationService
      .proposalReverseProceed(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.router.navigate([`/proposal/step-simulation`]);
      });
  }

  public handleBacenAnswers(answers: BacenAnswersModel[]): void {
    if (answers) {
      this.bacenFormIsValid = true;
      return;
    }
    this.bacenFormIsValid = false;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
