export interface DocumentScanResponse {
  images: Array<string>;
  token?: string;
  error?: any;
}

export class DocumentScanModel implements DocumentScanResponse {
  images: Array<string> = [];
  token: string = null;
  error: string = null;
}

export enum DocumentScanType {
  GENERIC = 'generic',
  DOCUMENT = 'document'
}
