<div class="container identification-container">
  <div class="row">
    <div class="col bread-crumbs">
      <a class="crumb" [routerLink]="['/showcase/open-proposal']">{{'IDENTIFICATION-BREAD-CRUMBS-1'|translate}}</a>
      <img class="arrow" src="./assets/ic-arrow-right.svg" alt="back_arrow" />
      <a class="crumb" [routerLink]="['/identification']">{{'IDENTIFICATION-BREAD-CRUMBS-2'|translate}}</a>
    </div>
  </div>
  <div class="row title">
    <h1>{{ 'IDENTIFICATION-TITLE' | translate }}</h1>
  </div>

  <div class="row subtitle">
    <h4>{{ 'IDENTIFICATION-SUBTITLE' | translate }}</h4>
  </div>

  <div class="row">
    <app-product-selection [form]="identificationForm" [products]="productsList"></app-product-selection>
  </div>

  <div *ngIf="identificationForm.get('product')?.value != null && !unavailable">
    <div class="row" *ngIf="!!identificationForm">
      <app-minimum-data [form]="identificationForm" [products]="productsList"></app-minimum-data>
    </div>

    <div *ngIf="termsAndConditions" class="row">
      <app-terms-and-conditions
        [termsAndConditions]="termsAndConditions"
        [formValid]="identificationForm.valid"
        (checkedOutput)="handleCheckbox($event)"
      ></app-terms-and-conditions>
    </div>

    <div class="row identification-lgpd-form" *ngIf="(documentNumberStatusObs | async) && !hideLgpdToGetNewConsent">
      <app-lgpd-form
        #lgpdForm
        [accessType]="lgpdConsentAccessType.IDENTIFICATION"
        [documentNumber]="identificationForm?.get('minimumData')?.get('documentNumber')?.value"
        (lgpdAnsweredToRegister)="handleLgpdFormAnswered()"
        (consentSuccess)="submitIdentification()"
        (loaded)="handleLgpdFormLoaded($event)"
      ></app-lgpd-form>
    </div>
  </div>

  <div class="row identification-submit">
    <div class="col-12">
      <button
        mat-raised-button
        [disabled]="isSubmitDisabled()"
        appTagger
        [event_category]="category"
        event_label='{{"SUBMIT-IDENTIFICATION-BTN-TEXT" | translate}}'
        (click)="handleLgpdConsentReady()"
        class="btn"
      >
        {{ 'SUBMIT-IDENTIFICATION-BTN-TEXT' | translate }}
      </button>
    </div>
  </div>
</div>
