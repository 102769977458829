import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppState } from '@app/core/state';
import { userPreferencesSelector } from '@app/core/state/profile/profile.selectors';
import { select, Store } from '@ngrx/store';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import * as profileActions from '../../../core/state/profile/profile.actions';
import * as profileEnum from '../../interface/profile.enum';

@Component({
  selector: 'app-user-preferences',
  templateUrl: './user-preferences.component.html',
  styleUrls: ['./user-preferences.component.scss']
})
export class UserPreferencesComponent implements OnInit {
  userPreferences: FormGroup;
  public checked: string;
  pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  constructor(private internationalizationService: InternationalizationService, private store$: Store<AppState>) {}

  ngOnInit() {
    this.generateForm();
    this.store$.pipe(select(userPreferencesSelector)).subscribe((preferences: any) => {
      switch (preferences) {
        case profileEnum.UserPreferences.PANEL:
          this.checked = profileEnum.UserPreferences.PANEL;
          break;
        case profileEnum.UserPreferences.PROPOSAL:
          this.checked = profileEnum.UserPreferences.PROPOSAL;
          break;
        default:
          this.checked = '';
          break;
      }
    });
  }

  public submitUserPrefs() {
    this.store$.dispatch(
      new profileActions.UpdateUserPreferencesRequest({ homePage: this.userPreferences.value.option })
    );
  }

  private generateForm() {
    this.userPreferences = new FormGroup({
      option: new FormControl('')
    });
  }
}
