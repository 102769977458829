<h4>
  Ao solicitar a negociação de taxa a proposta seguirá para a análise e a formalização da proposta só continuará após o
  retorno da solicitação.
</h4>

<form [formGroup]="negotiationForm" (ngSubmit)="onSubmit()">
  <section>
    <mat-radio-group formControlName="type">
      <mat-radio-button
        class="mt-2"
        value="rate"
        appTagger
        [event_category]="event_category"
        event_label="selecionou - Negociar taxa cliente / retenção"
      >
        Negociar taxa cliente / retenção
      </mat-radio-button>
      <br />
      <mat-radio-button
        value="installment"
        appTagger
        [event_category]="event_category"
        event_label="selecionou - Negociar valor da parcela"
      >
        Negociar valor da parcela
      </mat-radio-button>
    </mat-radio-group>
  </section>

  <div
    class="mt-2"
    appTagger
    [event_category]="event_category"
    event_label="Informe uma justificativa para a solicitação"
  >
    <label for="negotiationDescription">Informe uma justificativa para a solicitação:</label>
    <textarea
      class="form-control"
      id="negotiationDescription"
      rows="3"
      formControlName="description"
      maxlength="280"
    ></textarea>
  </div>

  <button type="submit" role="button" class="action-button mt-5" [disabled]="formIsInvalid">
    Negociar
  </button>
</form>
