<div class="container">
  <form [formGroup]="bipartitePaymentForm">
    <mat-radio-group *ngIf="!hasBipartiteMandatory" formControlName="answer">
      <ng-container *ngFor="let item of options">
        <div class="send-type pt-3 pl-3 pr-3 pb-2 mb-2">
          <mat-radio-button color="primary" [value]="item.id" (click)="onBipartiteAnswerChange(item.name)">
            {{ item.name }}
          </mat-radio-button>
          <p *ngIf="item.description">{{ item.description }}</p>
        </div>
      </ng-container>
    </mat-radio-group>
    <mat-radio-group *ngIf="!!hasBipartiteMandatory" formControlName="answer" disabled="true">
      <ng-container appTagger [event_category]="category" event_label="{{ options[0].name }}">
        <div class="send-type pt-3 pl-3 pr-3 pb-2 mb-2">
          <mat-radio-button color="primary" [value]="options[0].id">
            {{ options[0].name }}
          </mat-radio-button>
          <p>{{ options[0].description }}</p>
        </div>
      </ng-container>
    </mat-radio-group>
  </form>
</div>
