export enum ErrorCodesChallenge {
  INVALID_INPUT = 'error.auth.invalidInput',
  INVALID_USER_EXIST = 'error.auth.client.already.registered',
  INVALID_USER_NOT_EXIST = 'error.auth.recovery.user.not.exist',
  INVALID_FORMAT_PASSWORD = 'error.auth.passwordPolicyViolated',
  INVALID_DOCUMENT_NUMBER = 'error.auth.invalid.document.number',
  INVALID_TERM_NOT_ACCEPT = 'error.auth.register.form.term.not.accepted',
  INVALID_NAME = 'error.auth.register.form.invalid.name',
  INVALID_PHONE_NUMBER = 'error.auth.register.form.invalid.phone.number',
  INVALID_EMAIL = 'error.auth.register.form.invalid.email',
  INVALID_BIRTH_DATE = 'error.auth.register.form.invalid.birth.date',
  GENERAL_ERROR = 'error.businessUnchecked',
  SAFETY_REASONS = 'error.auth.safetyReasons'
}
