import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { AlertContainerComponent } from './container/alert.container';
import { reducerMap } from './store/alert.reducer';
import { initialAlertState } from './store/alert.state';
import { AlertBoxComponent } from './components/alert-box/alert-box.component';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { AlertEffects } from './store/alert.effects';

@NgModule({
  declarations: [AlertContainerComponent, AlertBoxComponent],
  imports: [
    CommonModule,
    NgbAlertModule,
    TranslateModule,
    StoreModule.forFeature('alert', reducerMap, {
      initialState: initialAlertState
    }),
    EffectsModule.forFeature([AlertEffects])
  ],
  exports: [AlertContainerComponent, AlertBoxComponent]
})
export class AlertModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule?: AlertModule
  ) {
    if (parentModule) {
      throw new Error('AlertModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<AlertModule> {
    return {
      ngModule: AlertModule,
      providers: []
    };
  }
}
