import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import * as actions from '@app/core/state/identification/identification.actions';
import * as fromSimulation from '@app/core/state/simulation';
import { OpenFinanceModalComponent } from '@app/proposal-workflow/components/open-finance-modal/open-finance-modal.component';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { AddRequestAction } from '@shared/components/widgets/loading/store/loading.actions';
import { Product } from '@shared/models/product.model';
import { StoreModel } from '@shared/models/store.model';
import { Apollo } from 'apollo-angular';
import { ExecutionResult } from 'graphql';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { mapIdentificationConfigQuery, mapIdentificationSubmit } from '../mappers/identification.mappers';
import { CheckProposalsRes } from '../models/api/check-proposals-res.model';
import { GET_TERMS } from './../../shared/graphql/queries/domains.query';
import { PostIdentificationReqDTO, PostIdentificationResDTO } from './../models/api/post.identification.model';
import { IdentificationConfigResponse } from './../models/api/query.identification-config.model';
import { IdentificationData } from './../models/identification.model';

@Injectable({
  providedIn: 'root'
})
export class IdentificationService {
  public resetIdentification$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public dialogConfig = new MatDialogConfig();
  public formChangeEvent = new EventEmitter<boolean>();
  public cancelNewProposal = new EventEmitter<boolean>();
  readonly category = '/portallojista/criarproposta';

  // Data to adjust modal size
  public innerWidth: any;
  public MOBILE_BREAKPOINT = 768;

  public isMobile = false;

  public newProposal = null;

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private store$: Store<AppState>,
    private router: Router,
    private translate: TranslateService,
    private datePipe: DatePipe,
    private modal: MatDialog,
    private genTagger: GenTagger,
    private simulateService: SimulationService
  ) {
    this.innerWidth = window.screen.width;
    this.isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;
    this.configDialog();
  }

  private configDialog(): void {
    this.dialogConfig.disableClose = true;
    this.dialogConfig.autoFocus = false;
    this.dialogConfig.id = 'modal-component';
    this.dialogConfig.width = '31rem';
    this.dialogConfig.height = this.isMobile ? '25.5rem' : '22.5rem';
    this.dialogConfig.maxWidth = '90vw';
    this.dialogConfig.maxHeight = '95%';
  }

  public getIdentificationConfig(
    store: StoreModel,
    accessChannelType: string = 'Lojista',
    termType: string = 'Canal'
  ): Observable<IdentificationConfigResponse> {
    return this.apollo
      .watchQuery({
        query: GET_TERMS,
        variables: { accessChannelType, termType },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: ExecutionResult) => {
          return mapIdentificationConfigQuery(response);
        })
      );
  }

  public getManagers(storeCode: string): Observable<string[]> {
    const endpoint = `${environment.apiRootUrl}/shopuserprofile/1.0.0/stores/masterRoleName`;
    return this.http.post<string[]>(endpoint, { storeCode });
  }

  public submitIdentification(
    payload: IdentificationData,
    selectedTab: StoreModel,
    config: IdentificationConfigResponse
  ): Observable<PostIdentificationResDTO> {
    const requestPayload: PostIdentificationReqDTO = {
      ...mapIdentificationSubmit(payload, selectedTab, +config.terms[0].code)
    };
    const endpoint = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/proposal`;
    this.store$.dispatch(new AddRequestAction({ id: 'LOADING', request: null }));
    return this.http.post<PostIdentificationResDTO>(endpoint, requestPayload);
  }

  public checkIfProposalExists(
    supplierChannelCode: string,
    documentNumber: string,
    subSegmentId: number,
    companyCode: number = 2
  ): Observable<CheckProposalsRes> {
    const body = {
      supplierChannelCode,
      documentNumber,
      subSegmentId,
      companyCode
    };
    const endpoint = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/proposal/canCreateWithCompanyCode`;
    return this.http.post<CheckProposalsRes>(endpoint, body);
  }

  public submitPreAnalysis(id: string): Observable<any> {
    // This is a mock for the backend
    const payload = {
      identification: {
        persons: [
          {
            income: 0,
            cupom: ''
          }
        ]
      }
    };
    const endpoint = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/simulation/identification/pre-analysis/${id}`;
    return this.http.post(endpoint, payload);
  }

  public cancelExistingProposal(proposalId: string): Observable<any> {
    const requestPayload = {
      proposalId
    };
    const endpoint = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/ADP/cancel-proposal`;
    return this.http.post<any>(endpoint, requestPayload);
  }

  public resetIdentification(): void {
    this.resetIdentification$.next(true);
  }

  public handleSubmitResponse(submitResponse: PostIdentificationResDTO): void {
    // Happy path, no constraints, user can continue with simulation.
    if (submitResponse.id) {
      if (JSON.parse(environment.useNPP)) {
        this.store$.dispatch(
          new fromSimulation.actions.GetStatusProposals({
            simulationId: submitResponse.id,
            redirectProposal: true
          })
        );
      } else {
        this.submitPreAnalysis(submitResponse.id)
          .pipe(take(1))
          .subscribe(() => {
            this.store$.dispatch(
              new fromSimulation.actions.GetStatusProposals({
                simulationId: submitResponse.id,
                redirectProposal: true
              })
            );
          });
      }
    }
  }

  public handleNoOffersAvailable(product): void {
    let modalRef;
    const dialogConfig = {
      ...this.dialogConfig,
      height: this.isMobile ? '21rem' : '18rem'
    };
    this.simulateService.getOpenFinanceEnabled(product).subscribe(data => {
      if (!!data) {
        modalRef = this.modal.open(OpenFinanceModalComponent, {
          data: {
            with: '520px',
            autoFocus: false,
            type: 'proposal-denied'
          }
        });
      } else {
        let modalData;
        modalData = {
          title: this.translate.instant('NO-OFFERS-AVAILABLE-MODAL-TITLE'),
          description: this.translate.instant('NO-OFFERS-AVAILABLE-MODAL-DESCRIPTION'),
          confirmText: this.translate.instant('NO-OFFERS-AVAILABLE-MODAL-CONFIRM-TEXT')
        };
        modalRef = this.modal.open(PionModalComponent, {
          ...dialogConfig,
          ...modalData
        });
        modalRef
          .afterClosed()
          .pipe(take(1))
          .subscribe(() => {
            this.resetIdentification$.next(true);
            this.store$.dispatch(new actions.ResetIdentificationAction());
            this.genTagger.setTag({
              event_category: this.category,
              event_action: Tag.Fechou_Modal + ' - ' + modalData.title,
              event_label: modalData.confirmText
            });
          });
        modalRef
          .afterOpened()
          .pipe(take(1))
          .subscribe(() => {
            this.genTagger.setTag({
              event_category: this.category,
              event_action: Tag.AbriuModal + ' - ' + modalData.title,
              event_label: modalData.description
            });
          });
      }
    });
  }

  public handleProposalAlreadyExists(
    olderOpenProposalDate: string,
    proposalId: number,
    isCancellable: boolean,
    docType
  ): void {
    const creationDate = this.datePipe.transform(olderOpenProposalDate, 'dd/MM/yyyy');
    const dialogConfig = {
      ...this.dialogConfig,
      height: this.isMobile ? '29rem' : '25rem'
    };

    let doc = null;

    if (docType === 'cpf') {
      doc = 'EXISTING-CANCELLABLE-PROPOSAL-MODAL-DESCRIPTION-CPF';
    } else {
      doc = 'EXISTING-CANCELLABLE-PROPOSAL-MODAL-DESCRIPTION-CNPJ';
    }

    const descripMsg = isCancellable ? doc : 'EXISTING-CLIENT-PROPOSAL-MODAL';
    const confirmMsg = isCancellable
      ? 'EXISTING-CANCELLABLE-PROPOSAL-MODAL-CONFIRM-TEXT'
      : 'EXISTING-PROPOSAL-MODAL-CONFIRM-TEXT';
    const cancelMsg = isCancellable
      ? 'EXISTING-CANCELLABLE-PROPOSAL-MODAL-CANCEL-TEXT'
      : 'EXISTING-PROPOSAL-MODAL-CANCEL-TEXT';
    const data = {
      type: 'alert',
      title: this.translate.instant('EXISTING-PROPOSAL-MODAL-TITLE'),
      description: this.translate.instant(descripMsg, { creationDate }),
      confirmText: this.translate.instant(confirmMsg),
      // "Sim, recuperar" "Exibir proposta"
      cancelText: this.translate.instant(cancelMsg)
      // "Não, fazer nova" "Cancelar"
    };
    const modalRef = this.modal.open(PionModalComponent, {
      ...dialogConfig,
      data
    });

    modalRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(confirmed => {
        if (confirmed) {
          if (isCancellable) {
            this.router.navigate(['/proposal'], { queryParams: { id: proposalId } });
          } else {
            this.router.navigate(['/showcase/open-proposal']);
          }
          this.genTagger.setTag({
            event_category: this.category,
            event_action: `${Tag.Fechou_Modal} - ${data.title}`,
            event_label: data.confirmText
          });
        } else {
          if (isCancellable) {
            this.handleExitIdentification(proposalId);
          }
          this.genTagger.setTag({
            event_category: this.category,
            event_action: `${Tag.Fechou_Modal} - ${data.title}`,
            event_label: data.cancelText
          });
        }
      });
    modalRef
      .afterOpened()
      .pipe(take(1))
      .subscribe(() => {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: `${Tag.AbriuModal} - ${data.title}`,
          event_label: data.title
        });
      });
  }

  private handleCancelExistentProposal(proposalId: number): void {
    const dialogConfig = {
      ...this.dialogConfig,
      height: this.isMobile ? '29.5rem' : '26.5rem'
    };
    const data = {
      type: 'alert',
      title: this.translate.instant('CANCEL-PROPOSAL-WARNING-MODAL-TITLE'),
      description: this.translate.instant('CANCEL-PROPOSAL-WARNING-MODAL-DESCRIPTION'),
      confirmText: this.translate.instant('CANCEL-PROPOSAL-WARNING-MODAL-CONFIRM-TEXT'),
      cancelText: this.translate.instant('CANCEL-PROPOSAL-WARNING-MODAL-CANCEL-TEXT')
    };
    const modalRef = this.modal.open(PionModalComponent, {
      ...dialogConfig,
      data
    });

    modalRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(confirmed => {
        if (confirmed) {
          this.store$.dispatch(new actions.CancelExistingProposalAction(proposalId));
          this.store$.dispatch(new actions.SubmitIdentificationAction(this.newProposal));
          this.genTagger.setTag({
            event_category: this.category,
            event_action: `${Tag.Fechou_Modal} - ${data.title}`,
            event_label: data.confirmText
          });
        } else {
          this.cancelNewProposal.emit(true);
          this.genTagger.setTag({
            event_category: this.category,
            event_action: `${Tag.Fechou_Modal} - ${data.title}`,
            event_label: data.cancelText
          });
        }

        this.newProposal = null;
      });
    modalRef
      .afterOpened()
      .pipe(take(1))
      .subscribe(() => {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: `${Tag.AbriuModal} - ${data.title}`,
          event_label: `${data.description}`
        });
      });
  }

  public handleProductUnavailable(product: Product, managerNameList: string[]): Observable<boolean> {
    const productsDesc = product.code + ' - ' + product.desc;

    const managersName = managerNameList ? managerNameList.join(', ') : '';
    const heightBase = managersName.length > 24 ? 25.2 : 24;
    const dialogConfig = {
      ...this.dialogConfig,
      height: this.isMobile ? `${heightBase + 3.5}rem` : `${heightBase}rem`
    };
    const data = {
      type: 'alert',
      title: this.translate.instant('PRODUCT-UNAVAILABLE-MODAL-TITLE'),
      description: this.translate.instant('PRODUCT-UNAVAILABLE-MODAL-DESCRIPTION', {
        productsDesc,
        managersName
      }),
      confirmText: this.translate.instant('PRODUCT-UNAVAILABLE-MODAL-CONFIRM-TEXT')
    };
    const modalRef = this.modal.open(PionModalComponent, {
      ...dialogConfig,
      data
    });
    modalRef.afterClosed().subscribe(() => {
      this.genTagger.setTag({
        event_category: this.category,
        event_action: `${Tag.Fechou_Modal} - ${data.title}`,
        event_label: `${data.confirmText}`
      });
    });
    modalRef
      .afterOpened()
      .pipe(take(1))
      .subscribe(() => {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: `${Tag.AbriuModal} - ${data.title}`,
          event_label: `${data.description}`
        });
      });
    return modalRef.afterClosed();
  }

  public handleExitIdentification(proposalId?: number): Observable<boolean> {
    const modalRef = this.modal.open(PionModalComponent, {
      ...this.dialogConfig,
      data: {
        title: this.translate.instant('EXIT-IDENTIFICATION-MODAL-TITLE'),
        description: this.translate.instant('EXIT-IDENTIFICATION-MODAL-DESCRIPTION'),
        confirmText: this.translate.instant('EXIT-IDENTIFICATION-MODAL-CONFIRM-TEXT'),
        cancelText: this.translate.instant('EXIT-IDENTIFICATION-MODAL-CANCEL-TEXT')
      }
    });

    const modalResult$ = modalRef.afterClosed().pipe(
      take(1),
      tap(res => {
        if (!res) {
          if (proposalId) {
            this.handleCancelExistentProposal(proposalId);
          }
        }
      }),
      map(confirmed => !confirmed)
    );

    modalResult$.subscribe();
    return modalResult$;
  }
}
