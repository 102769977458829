<div class="container pre-token-container">
  <app-arrow-back [routerLink]="['/login']" appTagger [event_category]="category" event_label="voltar"></app-arrow-back>

  <div class="content">
    <!-- May be a separate component -->
    <h2 class="title" *ngIf="fType === 'sign-up'">{{ 'RECOVER-PASSWORD-TITLE-CREATE' | translate }}</h2>
    <h2 class="title" *ngIf="fType === 'password-recovery' && recoveryType !== 'primary-access'">
      {{ 'RECOVER-PASSWORD-TITLE' | translate }}
    </h2>
    <h2 class="title" *ngIf="recoveryType === 'primary-access'">{{ 'LOGIN-PRIMARY-ACCESS' | translate }}</h2>

    <p class="description" *ngIf="fType === 'sign-up'">
      {{ 'RECOVER-PASSWORD-PRE-TOKEN-DESCRIPTION-CREATE' | translate }}
    </p>
    <p class="description" *ngIf="fType === 'password-recovery'">
      {{ 'RECOVER-PASSWORD-PRE-TOKEN-DESCRIPTION' | translate }}
    </p>

    <div class="input-block input-row">
      <mat-form-field color="black" appearance="legacy" class="input">
        <input autocomplete="off" mask="{{ documentNumberMask }}" type="text" matInput class="input-with-icon"
          placeholder="{{ fieldPlaceholder | translate }}" [formControl]="preTokenField"
          (input)="characterChange($event)" appTagger [event_category]="category" event_label="cpf"
          [event_action]="Insert" maxlength="14" />
        <img *ngIf="preTokenField.valid" class="valid-icon" matSuffix src="./assets/icon-green.svg"
          alt="icon right green" />
        <mat-error *ngIf="preTokenField.value.length < 11">
          {{ 'MANDATORY-FIELD' | translate }}
        </mat-error>
        <mat-error *ngIf="preTokenField.value.length === 11 && preTokenField?.errors?.invalidCpf">
          {{ 'INVALID-DOCUMENT' | translate }}
        </mat-error>
      </mat-form-field>
    </div>

    <p class="text-error" *ngIf="userExist && !preTokenField.invalid">
      {{ 'User-Exist' | translate }}
    </p>
    <p class="text-error" *ngIf="(challengeError$ | async) === errorCodes.INVALID_USER_NOT_EXIST">
      {{ 'USER-NOT-EXISTS-ERROR-MSG' | translate }}
    </p>

    <button mat-raised-button color="primary" type="button" class="raised-button" [disabled]="preTokenField.invalid"
      (click)="actionButton(userExist, recoveryError)" appTagger [event_category]="category"
      event_label="enviar código">
      {{ labelButton | translate }}
    </button>
  </div>
</div>