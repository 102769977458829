<div>
  <pion-tooltip [alignTo]="alignTo" *ngIf="planSelected?.code === 'FLT' && !planSelected?.hasFlexInstallments"
    (openOut)="openToolTip($event)" (closeOut)="closeToolTip()">
    {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
    <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-FLT' | translate }}</p>
    <p class="font-weight-bold">
      {{ planSelected.installmentDefaultAmount }} x de {{ planSelected.installmentDefaultValue | localeCurrency }}
    </p>
    <p>
      <a (click)="openInstallmentsModal(planSelected?.code)" appTagger [event_category]="category"
        event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
        translate }}</a>
    </p>
  </pion-tooltip>

  <pion-tooltip [alignTo]="alignTo" *ngIf="planSelected?.code === 'ASC' && !planSelected?.hasFlexInstallments"
    (openOut)="openToolTip($event)" (closeOut)="closeToolTip()">
    {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
    <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-ASC' | translate }}</p>
    {{ 'TOOLTIP-PAYMENT-PLAN-FIRST-INSTALLMENT' | translate }}
    <p class="font-weight-bold mb-0">{{ planSelected.firstInstallmentValue | localeCurrency }}</p>
    {{ 'TOOLTIP-PAYMENT-PLAN-LAST-INSTALLMENT' | translate }}
    <p class="font-weight-bold">{{ planSelected.lastInstallmentValue | localeCurrency }}</p>
    <p>
      <a (click)="openInstallmentsModal(planSelected?.code)" appTagger [event_category]="category"
        event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
        translate }}</a>
    </p>
  </pion-tooltip>

  <pion-tooltip [alignTo]="alignTo" *ngIf="planSelected?.code === 'DEC' && !planSelected?.hasFlexInstallments"
    (openOut)="openToolTip($event)" (closeOut)="closeToolTip()">
    {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
    <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-DEC' | translate }}</p>
    {{ 'TOOLTIP-PAYMENT-PLAN-FIRST-INSTALLMENT' | translate }}
    <p class="font-weight-bold mb-0">{{ planSelected.firstInstallmentValue | localeCurrency }}</p>
    {{ 'TOOLTIP-PAYMENT-PLAN-LAST-INSTALLMENT' | translate }}
    <p class="font-weight-bold">{{ planSelected.lastInstallmentValue | localeCurrency }}</p>
    <p>
      <a (click)="openInstallmentsModal(planSelected?.code)" appTagger [event_category]="category"
        event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
        translate }}</a>
    </p>
  </pion-tooltip>

  <pion-tooltip [alignTo]="alignTo" *ngIf="planSelected?.code === 'BLN' && !planSelected?.hasFlexInstallments"
    (openOut)="openToolTip($event)" (closeOut)="closeToolTip()">
    {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
    <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-BLN' | translate }}</p>
    {{ 'TOOLTIP-PAYMENT-PLAN-PERIOD' | translate }}
    <p class="font-weight-bold mb-0">{{ planSelected.monthsRange }}</p>
    {{ 'TOOLTIP-PAYMENT-PLAN-FIRST-BALLOON' | translate }}
    <p class="font-weight-bold">{{ planSelected.firstInstallmentPMethodsDate }}</p>
    {{ 'TOOLTIP-PAYMENT-PLAN-INSTALLMENT-NORMAL' | translate }}
    <p class="font-weight-bold mb-0">
      {{ planSelected.installmentDefaultAmount }} x {{ planSelected.installmentDefaultValue | localeCurrency }}
    </p>
    {{ 'TOOLTIP-PAYMENT-PLAN-INSTALLMENTS-BALLOON' | translate }}
    <p class="font-weight-bold">
      {{ planSelected.installmentPaymentMethodsAmount }} x
      {{ planSelected.installmentPaymentMethodsValue | localeCurrency }}
    </p>
    <p>
      <a (click)="openInstallmentsModal(planSelected?.code)" appTagger [event_category]="category"
        event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
        translate }}</a>
    </p>
  </pion-tooltip>
  <pion-tooltip [alignTo]="alignTo" *ngIf="planSelected?.code === 'SEASONAL' && !planSelected?.hasFlexInstallments"
    (openOut)="openToolTip($event)" (closeOut)="closeToolTip()">
    {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
    <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-SEASONAL' | translate }}</p>
    {{ 'TOOLTIP-PAYMENT-PLAN-PERIOD' | translate }}
    <p class="font-weight-bold mb-0">{{ planSelected.monthsRange }}</p>
    {{ 'FIRST-MONTH-INSTALLMENT' | translate }}
    <p class="font-weight-bold">{{ planSelected.firstInstallmentPMethodsDate }}</p>
    {{ 'INSTALLMENTS-SASONAL' | translate }}
    <p class="font-weight-bold">
      {{ planSelected.installmentPaymentMethodsAmount }} x
      {{ planSelected.installmentPaymentMethodsValue | localeCurrency }}
    </p>
    {{ 'OTHER-INSTALLMENTS' | translate }}
    <p class="font-weight-bold mb-0">
      {{ planSelected.installmentDefaultAmount }} x {{ planSelected.installmentDefaultValue | localeCurrency }}
    </p>
    <p>
      <a (click)="openInstallmentsModal(planSelected?.code)" appTagger [event_category]="category"
        event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
        translate }}</a>
    </p>
  </pion-tooltip>
  <pion-tooltip [alignTo]="alignTo" *ngIf="planSelected?.code === 'FIXED' && !planSelected?.hasFlexInstallments"
    (openOut)="openToolTip($event)" (closeOut)="closeToolTip()">
    {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
    <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-FIXED' | translate }}</p>
    {{ 'NUMBER-OF-FIXED-INSTALLMENT' | translate }}
    <p class="font-weight-bold mb-0">{{ planSelected.installmentPaymentMethodsAmount }}</p>
    {{ 'INSTALLMENTS-VALUE-FIXED' | translate }}
    <p class="font-weight-bold">{{ planSelected.firstInstallmentValue | localeCurrency}}</p>

    {{ 'INSTALLMENTS-STANDARD-BALLOON' | translate }}
    <p class="font-weight-bold mb-0">
      {{ planSelected.installmentDefaultAmount }}
    </p>
    {{'VALUE-OF-INSTALLMENTS-STANDARD' | translate}}
    <p class="font-weight-bold mb-0">
      {{ planSelected.lastInstallmentValue | localeCurrency }}
    </p>
    <p>
      <a (click)="openInstallmentsModal(planSelected?.code)" appTagger [event_category]="category"
        event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
        translate }}</a>
    </p>
  </pion-tooltip>
  <pion-tooltip [alignTo]="alignTo" *ngIf="planSelected?.code === 'LACK' && !planSelected?.hasFlexInstallments"
    (openOut)="openToolTip($event)" (closeOut)="closeToolTip()">
    {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
    <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-LACK' | translate }}</p>
    {{ 'TOOLTIP-PAYMENT-PLAN-PERIOD' | translate }}
    <p class="font-weight-bold mb-0">{{ planSelected.monthsRange }}</p>
    {{ 'TOOLTIP-PAYMENT-PLAN-FIRST-JUMPED-INSTALLMENT' | translate }}
    <p class="font-weight-bold">{{ planSelected.firstInstallmentPMethodsDate }}</p>
    {{ 'TOOLTIP-PAYMENT-PLAN-INSTALLMENT-NORMAL' | translate }}
    <p class="font-weight-bold">
      {{ planSelected.installmentDefaultAmount }} x {{ planSelected.installmentDefaultValue | localeCurrency }}
    </p>
    <p>
      <a (click)="openInstallmentsModal(planSelected?.code)" appTagger [event_category]="category"
        event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
        translate }}</a>
    </p>
  </pion-tooltip>

  <pion-tooltip [alignTo]="alignTo" *ngIf="planSelected?.hasFlexInstallments" (openOut)="openToolTip($event)"
    (closeOut)="closeToolTip()">
    {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
    <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-FLEX' | translate }}</p>
    {{ 'TOOLTIP-PAYMENT-PLAN-INSTALLMENT-NORMAL' | translate }}
    <p class="font-weight-bold">{{ flexInstallmentsNumber }}</p>
    <p>
      <a (click)="openInstallmentsModal(planSelected?.code)" appTagger [event_category]="category"
        event_label="{{ 'TOOLTIP-PAYMENT-PLAN-FIRST-INSTALLMENTS-FLEXIBLE' | translate }}">{{
        'TOOLTIP-PAYMENT-PLAN-FIRST-INSTALLMENTS-FLEXIBLE' | translate }}</a>
    </p>
  </pion-tooltip>
</div>