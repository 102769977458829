import { SubsegmentModel } from '@shared/models/subsegment.model';
import { StoreModel } from '@shared/models/store.model';
export enum SubmitPersonType {
  PF = 'F',
  PJ = 'J'
}

export enum SubmitClientType {
  GUARANTOR = 'A',
  BUYER = 'C',
  PARTNER = 'S',
  SELLER = 'V'
}

export interface IdentificationPersonDTO {
  personType: string;
  clientType: string;
  clientName?: string;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  tradeName?: string;
  date?: string;
  genderCode?: string;
  email?: string;
  phoneIntCode?: string;
  phoneRegCode?: string;
  phoneNumber?: string;
  documents?: [
    {
      typeCode: string;
      code: string;
    }
  ];
  incomeTypeId?: number;
  income?: number;
  cupom?: string;
  economyActivityId?: number;
  partnershipTypeId?: number;
  partnerPercentage?: number;
  annualBilling?: number;
  monthlyOperationalIncome?: number;
  monthlyOperationalExpenses?: number;
  assets?: number;
  liabilities?: number;
  hasOtherMonthlyIncome?: boolean;
  otherMonthlyIncome?: number;
  otherIncomeOriginDescription?: string;
  fiscalYearEndDate?: string;
}

export interface IdentificationDTO {
  storeCode: string;
  userCode: string;
  subsegmentId: number;
  productCode: string;
  persons: IdentificationPersonDTO[];
  financedValue: number;
  installmentAmount?: number;
  entryValue?: number;
  termId?: number;
  travelDate?: string;
  paymentPlan?: string;
  paymentMethod?: string;
}

export interface PostIdentificationReqDTO {
  identification: IdentificationDTO;
  cancelLastProposal?: boolean;
}

export interface PostIdentificationResDTO {
  identification: IdentificationDTO;
  simulation?: {
    id: string;
    isNotPossibleCancelSimulation: boolean;
  };
  proposal?: {
    id: number;
    creationDate: string;
  };
  maximumLimitExceeded: boolean;
}

export interface Identification {
  economicActivity?: any;
  income?: number;
  cupom?: string;
  travelDate?: string;
  store?: StoreModel;
  subsegment?: SubsegmentModel;
  product?: any;
  financedValue?: number;
  installmentAmount?: number;
  redirectProposal?: boolean;
  paymentPlan?: string;
  paymentMethod?: string;
  offerPayload?: any
}
