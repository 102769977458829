import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AppState } from '@app/core/state';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { SwalService } from '@shared/services/swal/swal.service';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { ManagementService } from './../services/management.service';

@Component({
  selector: 'app-user-management-list',
  templateUrl: './user-management-list.component.html',
  styleUrls: ['./user-management-list.component.scss']
})
export class UserManagementListComponent implements OnInit, OnChanges {
  public window = window;

  // Pagination
  users = [];
  totalElements: any;
  page = 1;
  perPage = 20;
  previousPage: any;
  showDocument = [];
  paginaInicial = 1;
  paginaAnterior = this.paginaInicial;

  // FILTRO
  vendedor = true;
  representante = true;
  financeiro = true;
  master = true;
  colaborador = true;
  ativo = true;
  inativo = true;
  status: any;
  tipo: any;
  perfis: any;
  search = new FormControl('');

  public userChoice: string;
  searchForm: FormGroup;
  isFiltred = false;

  public saleId$: Observable<number> = this.store$.select('tabs').pipe(map(tab => +tab.selectedTab.id));

  @Input() count = new EventEmitter<number>();
  @Output() newUserEmitter = new EventEmitter<any>();
  @Output() userIdEmitter = new EventEmitter<any>();

  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  closeResult: string;

  public category = '/portallojista/gestaodeusuarios';
  readonly Insert: Tag = Tag.Insert;
  readonly pgView: Tag = Tag.pgView;

  constructor(
    private internationalizationService: InternationalizationService,
    private _managementService: ManagementService,
    private swalService: SwalService,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private genTagger: GenTagger,
    private store$: Store<AppState>,
    private translate: TranslateService
  ) {}
  get eventAction(): ExtendedTag {
    return {
      event_action_type: Tag.AbriuModal,
      event_action_complement: 'Gestão de usuários'
    };
  }

  get eventActionModal(): ExtendedTag {
    return {
      event_action_type: Tag.AbriuModal,
      event_action_complement: 'Filtrar usuários'
    };
  }

  ngOnInit() {
    this.searchUsers();
    this.searchForm = this.fb.group({
      searchTerm: []
    });

    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'gestão de usuários',
      event_action: Tag.pgView
    });

    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'gestão de usuários',
      event_action: this.eventAction
    });
    window.scrollTo(0, 0);
  }

  searchUsers() {
    if (this.ativo && this.inativo) {
      this.status = 'S,N';
    } else if (this.ativo) {
      this.status = 'S';
    } else if (this.inativo) {
      this.status = 'N';
    }

    if (this.master && this.colaborador) {
      this.tipo = 'M,U';
    } else if (this.master) {
      this.tipo = 'M';
    } else if (this.colaborador) {
      this.tipo = 'U';
    }

    if (this.vendedor && this.representante && this.financeiro) {
      this.perfis = 'V,R,F';
    } else if (!this.vendedor && !this.representante && !this.financeiro) {
      this.perfis = '';
    } else if (this.vendedor && this.representante) {
      this.perfis = 'V,R';
    } else if (this.representante && this.financeiro) {
      this.perfis = 'R,F';
    } else if (this.vendedor) {
      this.perfis = 'V';
    } else if (this.representante) {
      this.perfis = 'R';
    } else if (this.financeiro) {
      this.perfis = 'F';
    }

    let fornecedorCanalParams: any;
    fornecedorCanalParams = {
      perPage: this.perPage,
      page: this.page,
      status: this.status ? this.status : null,
      type: this.tipo ? this.tipo : null,
      profiles: this.perfis ? this.perfis : null,
      search: this.search.value ? this.search.value : ''
    };

    this.saleId$
      .pipe(
        first(),
        switchMap(id => this._managementService.getUsers(id, fornecedorCanalParams))
      )
      .subscribe((data: any) => {
        this.users = data.content;
        this.totalElements = data.totalElements;
      });
  }

  loadPage(page: number) {
    if (page !== this.previousPage) {
      this.previousPage = page;
      this.searchUsers();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.searchUsers();
      this.searchForm = this.fb.group({
        searchTerm: []
      });
    }, 2000);
  }

  newUserClick() {
    this.newUserEmitter.emit({
      type: 'new-user',
      userId: ''
    });
  }

  updateUserClick(userId) {
    this.newUserEmitter.emit({
      type: 'update-user',
      userId: userId
    });
  }

  removeUser(id, name) {
    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'excluir usuário',
      event_action: Tag.AbriuModal
    });
    this.swalService.createConfirmWarning(`Você está excluindo o usuário ${name}, deseja mesmo excluir?`).then(data => {
      if (data.value) {
        this.saleId$
          .pipe(
            first(),
            switchMap(saleId => this._managementService.deleteById(saleId, id))
          )
          .subscribe(() => {
            this.searchUsers();
          });
        this.genTagger.setTag({
          event_category: this.category,
          event_label: 'Sim, excluir',
          event_action: Tag.Fechou_Modal
        });
      } else {
        this.genTagger.setTag({
          event_category: this.category,
          event_label: 'Não, voltar',
          event_action: Tag.Fechou_Modal
        });
      }
    });
  }

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'Filtrar usuários',
      event_action: this.eventActionModal
    });
  }

  clearFilters() {
    this.vendedor = true;
    this.representante = true;
    this.financeiro = true;
    this.master = true;
    this.colaborador = true;
    this.ativo = true;
    this.inativo = true;
    this.searchUsers();
  }

  public tag(label) {
    let resolved_action: string;
    switch (label) {
      case 'MASTER':
      case 'Colaborador':
        resolved_action = 'Selecionou - tipo';
        break;

      case 'ativo':
      case 'ACTIVE':
      case 'INACTIVE':
      case 'inativo':
        resolved_action = 'Selecionou - status';
        break;

      case 'vendedor':
      case 'SELLER':
      case 'representante':
      case 'Representative':
      case 'financeiro':
      case 'FINANCIAL':
        resolved_action = 'Selecionou - perfis';
        break;

      default:
        resolved_action = 'NAO MAPEADO NO FILTRO DE USUARIOS - CORRIGIR';
    }
    this.genTagger.setTag({
      event_action: resolved_action,
      event_category: this.category,
      event_label: this.translate.instant(label)
    });
  }

  public onClick(proximaPagina) {
    this.genTagger.setTag({
      event_category: this.category,
      event_action: {
        event_action_type: Tag.Click,
        event_action_complement: proximaPagina > this.paginaAnterior ? 'Próximo' : 'Anterior'
      },
      event_label: 'Página - ' + (proximaPagina > this.paginaAnterior ? 'Próximo' : 'Anterior')
    });
    this.paginaAnterior = proximaPagina;
  }
}
