<div class="accordion">
  <mat-card class="col-12">
    <p class="tag-proposal">
      <span *ngIf="proposal?.proposalData?.product === 'CDC'" class="badge badge-cdc">{{
        'CDC label' | translate
      }}</span>
      <span *ngIf="proposal?.proposalData?.product === 'CSC'" class="badge badge-csc">{{ 'CSC' | translate }}</span>
      <span *ngIf="proposal?.proposalData?.product === 'CSCP'" class="badge badge-cscp">{{ 'CSCP' | translate }}</span>
    </p>
    <div class="card-label">
      <div class="col-12 col-lg-4 div-1-header">
        <span>{{ 'PENDENCY-SUMMARY-TITLE-NUMBER-PROPOSAL' | translate }}</span> <br />
        <h1>{{ proposalId }}</h1>
      </div>
      <div class="col-12 col-lg-4 div-1-header">
        <span>{{ 'PENDENCY-SUMMARY-TITLE-EXPIRE' | translate }}</span> <br />
        <h1>
          <img
            src="./assets/santander/icons/ic_clock.svg"
            alt="Status icon"
            *ngIf="data?.showClock"
            class="icon-status"
          />
          <img
            [src]="
              data?.expirationDays > 10
                ? './assets/santander/icons/ic_clock_yellow.svg'
                : './assets/santander/icons/ic_clock_red.svg'
            "
            alt="Status icon"
            class="icon-status"
            *ngIf="!data?.showClock"
          />
          {{ data?.expirationDays }}
          <span *ngIf="data?.expirationDays > 1">{{ 'PENDENCY-SUMMARY-TITLE-DAY' | translate }}s</span>
          <span *ngIf="data?.expirationDays === 1">{{ 'PENDENCY-SUMMARY-TITLE-DAY' | translate }}</span>
        </h1>
      </div>
      <div class="col-12 col-lg-4 div-1-header">
        <span>{{ 'PENDENCY-SUMMARY-TITLE-STATUS' | translate }}</span> <br />
        <h3
          appTagger
          [event_category]="categoria"
          event_label="Ajuda - {{ 'PENDENCY-SUMMARY-TITLE-STATUS' | translate }}"
        >
          <img class="icon-status" src="assets/santander/icons/{{ data?.status?.icon }}" alt="Status icon" />
          {{ data?.status?.description }}
        </h3>
      </div>
    </div>
  </mat-card>

  <div
    class="arrow d-none d-lg-flex cursor-p"
    (click)="openCard()"
    appTagger
    [event_category]="categoria"
    event_label="exibir detalhes da proposta"
  >
    Exibir detalhes da proposta
    <span class="material-icons arrow-icon">
      {{ isOpen ? 'expand_less' : 'expand_more' }}
    </span>
  </div>

  <ng-container *ngIf="isOpen">
    <div class="row no-guters">
      <div class="col-md-4 pt-4">
        <p class="gray text-sm">{{ 'ESIGN-FINANCED-VALUE' | translate }}</p>
        <p class="text-lg bold mb-4">
          {{ proposal?.financedValue | localeCurrency }}
        </p>

        <p class="gray text-sm">{{ 'Entry Price' | translate }}</p>
        <p *ngIf="!!proposal?.entryValue" class="text-lg bold mb-4">
          {{ proposal?.entryValue | localeCurrency }}
        </p>
        <p *ngIf="!proposal?.entryValue" class="text-lg bold mb-4">
          {{ 'STEP-CONFIRMATION-WITHOUT-ENTRY' | translate }}
        </p>

        <p class="gray text-sm">{{ 'FORMALIZATION-CONDITION-CARD-PARCELS-INFO' | translate }}</p>
        <p class="text-lg mb-4">
          <span class="text-sm-qtd">{{ proposal?.installmentTotalQuantity }}x de</span> <br />
          <strong *ngIf="proposal?.planType !== paymentPlanType.STANDARD_PLAN">
            {{ paymentPlanType.CUSTOMIZED_PLAN }}</strong
          >
          <strong *ngIf="proposal?.planType === paymentPlanType.STANDARD_PLAN">
            {{ proposal?.installmentValue | localeCurrency }}</strong
          >
        </p>
      </div>

      <div class="col-md-8">
        <div class="row no-gutters details-row">
          <span class="gray text-sm col-md-6" *ngIf="proposal?.proposalData?.client === personsType.PF">{{
            'PENDENCY-SUMMARY-NAME-CLIENT' | translate
          }}</span>
          <span class="gray text-sm col-md-6" *ngIf="proposal?.proposalData?.client === personsType.PJ">{{
            'PENDENCY-SUMMARY-NAME-CUSTOMER' | translate
          }}</span>
          <span class="col-md-6">
            {{ proposal?.personalData?.fullName }}
          </span>
        </div>

        <div class="row no-gutters details-row" *ngIf="proposal?.proposalData?.client === personsType.PF">
          <span class="gray text-sm col-md-6">{{ 'STEP-REGISTER-DOCUMENT-NUMBER' | translate }}</span>
          <span class="col-md-6">
            {{ proposal?.personalData?.documentNumber | mask: '000.000.000-00' }}
          </span>
        </div>

        <div class="row no-gutters details-row" *ngIf="proposal?.proposalData?.client === personsType.PJ">
          <span class="gray text-sm col-md-6">{{ 'STEP-REGISTER-COMPANY-DOCUMENT-NUMBER' | translate }}</span>
          <span class="col-md-6">
            {{ proposal?.personalData?.documentNumber | mask: '00.000.000/0000-00' }}
          </span>
        </div>

        <div class="row no-gutters details-row">
          <span class="gray text-sm col-md-6">{{ 'PENDENCY-SUMMARY-CREATE-PROPOSAL' | translate }}</span>
          <span class="col-md-6">
            {{ data?.nameCreation }}
          </span>
        </div>

        <div class="row no-gutters details-row">
          <div class="gray text-sm col-md-6">
            {{ 'PENDENCY-SUMMARY-DATE-CREATE-PROPOSAL' | translate }}
          </div>
          <div class="col-md-6">
            {{ proposal?.proposalData?.creationDate | date: 'dd/MM/yyyy' }}
          </div>
        </div>

        <div class="row no-gutters details-row border-b-gray">
          <div class="gray text-sm col-md-6">
            {{ 'PENDENCY-SUMMARY-DATE-EXPIRE-PROPOSAL' | translate }}
          </div>
          <div class="col-md-6">
            {{ proposal?.proposalData?.expirationDate | date: 'dd/MM/yyyy' }}
          </div>
        </div>

        <div class="row no-gutters details-row border-b-gray">
          <div class="gray text-sm col-md-6">
            {{ 'PENDENCY-SUMMARY-DATE-EXPIRE-PAG-PROPOSAL' | translate }}
          </div>
          <div class="col-md-6">
            {{ data?.paymentExpirationDate | date: 'dd/MM/yyyy' }}
          </div>
        </div>

        <div class="row no-gutters details-row border-b-gray">
          <div class="gray text-sm col-md-6">
            {{ 'STEP-CONFIRMATION-RETURN-RATE' | translate }}
            <mat-icon
              class="info-icon icon-v-inverse"
              matTooltip="{{ 'STEP-CONFIRMATION-CET-TOOLTIP' | translate }}"
              matTooltipPosition="after"
              matTooltipHideDelay="5000"
              >error_outline</mat-icon
            >
          </div>
          <div class="col-md-6">{{ proposal?.returnRate }}{{ 'STEP-CONFIRMATION-PER-MONTH' | translate }}</div>
        </div>

        <div *ngIf="certifiedAgent">
          <div class="row no-gutters details-row">
            <div class="gray text-sm col-md-6">
              {{ 'PENDENCY-SUMMARY-AGENT-CERT-BIPARITE' | translate }}
            </div>
            <div class="col-md-6">
              {{ certifiedAgent?.agentName }}
            </div>
          </div>

          <div class="row no-gutters details-row">
            <div class="gray text-sm col-md-6">
              {{ 'STEP-REGISTER-DOCUMENT-NUMBER' | translate }}
            </div>
            <div class="col-md-6">
              {{ certifiedAgent?.documentNumber | mask: '000.000.000-00' }}
            </div>
          </div>
        </div>

        <div *ngIf="proposalPartners?.length > 0">
          <div class="row no-gutters details-row border-b-black">
            <div class="text-sm col-md-6">
              <b>{{ 'PENDENCY-SUMMARY-FLOW-BIPARITE' | translate }}</b>
            </div>
            <div class="col-md-6"></div>
          </div>

          <div *ngFor="let partner of proposalPartners; let i = index">
            <div class="row mt-2 details-row">
              <div class="col-md-6">
                <p>{{ 'STEP-REGISTER-COMPANY-DOCUMENT-NUMBER' | translate }} Nº {{ i + 1 }}</p>
              </div>
              <div class="col-md-6 text-right">
                <h3>{{ partner?.partnerDocumentCode | mask: '00.000.000/0000-00' }}</h3>
              </div>
            </div>

            <div class="row mt-2 details-row">
              <div class="col-md-6">
                <p>{{ 'PENDENCY-SUMMARY-VALUE-BIPARITE' | translate }}</p>
              </div>
              <div class="col-md-6 text-right">
                <h3>{{ partner?.partnerValue | localeCurrency }}</h3>
              </div>
            </div>

            <div class="row mt-2 border-b-gray details-row">
              <div class="col-md-6">
                <p>{{ 'PENDENCY-SUMMARY-PERCENT-BIPARITE' | translate }}</p>
              </div>
              <div class="col-md-6 text-right">
                <h3>{{ partner?.partnerPecentage | localePercentage }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="arrow-progress">
      <div class="row">
        <div class="col-md-12" style="font-size: 13px;">
          <progress-card
            [data]="data?.proposalGroups"
            *ngIf="window.screen.width >= 768"
            class="step-component mt-3 mb-3"
          ></progress-card>
          <progress-card [data]="data?.proposalGroups" *ngIf="window.screen.width < 768" class="mobile"></progress-card>
        </div>
      </div>
    </div>

    <div class="arrow-additional d-none d-lg-block cursor-p" (click)="openCardAdditional()">
      <b>Informações adicionais</b>
      <span class="material-icons arrow-icon">
        {{ isOpenAdditional ? 'expand_less' : 'expand_more' }}
      </span>
    </div>

    <div *ngIf="isOpenAdditional">
      <div *ngFor="let traveler of proposalInformation?.travelers; let i = index">
        <div class="row mt-2 details-row">
          <div class="col-md-6">
            <p>Nome do viajante {{ i + 1 }}</p>
          </div>
          <div class="col-md-6 text-right">
            <h3>{{ traveler?.name }}</h3>
          </div>
        </div>

        <div class="row mt-2 details-row">
          <div class="col-md-6">
            <p>CPF</p>
          </div>
          <div class="col-md-6 text-right">
            <h3>{{ traveler?.documentNumber | mask: '000.000.000-00' }}</h3>
          </div>
        </div>

        <div class="row mt-2 border-b-gray details-row">
          <div class="col-md-6">
            <p>Data de nascimento</p>
          </div>
          <div class="col-md-6 text-right">
            <h3>{{ traveler?.birthDate | date: 'dd/MM/yyyy' }}</h3>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-md-12">
          <p>Número do pedido</p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>{{ data?.additionalInformation?.orderNumber }}</h3>
          <br />
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <p>Comentários</p>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12">
          <h3>{{ data?.additionalInformation?.comment }}</h3>
        </div>
      </div>
    </div>
  </ng-container>
</div>
