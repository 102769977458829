import { ChecklistItemTypeDTO } from '../../../checklist/models/api/query.checklist.model';
import { ChecklistFileModel } from '../../../checklist/models/checklist.model';

export interface PostBiometryDTORequest {
  id?: number;
  proposal?: {
    id: number;
    supplierChannel: {
      id: number;
    };
  };
  files?: ChecklistFileModel[];
  document?: {
    code: number;
    name: string;
    description?: string;
    maxAmount?: number;
    allowedTypes?: string;
    integrationTypes?: {
      id: number;
      description: string;
      integrationCode: string;
    }[];
  };
  type?: ChecklistItemTypeDTO;
  encryptedUnicoJwt: string;
  apiKeyUnico: string;
  client?: {
    id: number;
    name: string;
    email: string;
    documentNumber: string;
  };
  imageId?: string;
}

export enum BiometryResponseEnum {
  PENDING = 'P',
  CANCELLED = 'C',
  REMAKE = 'R',
  APPROVED = 'A',
  DENIED = 'N',
  ANALYSIS = ''
}

export interface PostBiometryDTOResponse {
  status: BiometryResponseEnum;
}
