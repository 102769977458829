import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Tag } from '@app/tagging/tagger.directive';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PaymentsService } from '../../services/payments.service';

@Component({
  selector: 'app-payment-table',
  templateUrl: './payment-table.component.html',
  styleUrls: ['./payment-table.component.scss']
})
export class PaymentTableComponent implements OnInit, OnDestroy {
  @Input() listPayments: any[];

  @Output() buttonListOrKebabAction = new EventEmitter();
  @Output() updateCheckSelectAll = new EventEmitter();
  @Output() orderAction = new EventEmitter();

  private values = [];
  private ordenationIconUp = 'chevron-up';
  private ordenationIconDown = 'chevron-down';
  public ordenationIcons = {
    type: this.ordenationIconDown,
    proposalValidityDate: this.ordenationIconDown,
    status: this.ordenationIconDown,
    valueFinancing: this.ordenationIconDown,
    clientName: this.ordenationIconDown
  };
  readonly categoria = '/portallojista/pagamentos';
  readonly Selecionar: Tag = Tag.Selecionar;

  onDestroy: Subject<any> = new Subject();

  private columnNameLabel = {
    type: 'Tipo',
    proposalValidityDate: 'Expira em',
    status: 'Status',
    valueFinancing: 'Valor financiado',
    clientName: 'Cliente'
  };

  constructor(private paymentsService: PaymentsService) {}

  ngOnInit() {
    this.restartOrdenationListener();
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public onButtonListOrKebabAction(button: string, id: number): void {
    console.log(button, id);
    this.buttonListOrKebabAction.emit({ button, id });
  }

  public onUpdateCheckSelectAll() {
    this.updateCheckSelectAll.emit();
  }

  private restartOrdenationListener() {
    this.paymentsService.restartOrdenation.pipe(takeUntil(this.onDestroy)).subscribe(resp => {
      if (!!resp) {
        this.values = [];
        Object.keys(this.ordenationIcons).forEach(item => {
          this.ordenationIcons[item] = this.ordenationIconDown;
        });
      }
    });
  }

  public order(sort: string): void {
    const item = {
      sort: sort,
      ordenation: '',
      columnNameLabel: this.columnNameLabel[sort]
    };

    this.ordenationIcons[sort] =
      this.ordenationIcons[sort] === this.ordenationIconDown ? this.ordenationIconUp : this.ordenationIconDown;
    item.ordenation = this.ordenationIcons[sort] === this.ordenationIconDown ? 'desc' : 'asc';

    if (!!this.values.length) {
      const indexSort = this.values.findIndex(i => i.sort === sort);
      if (indexSort >= 0) {
        this.values[indexSort].ordenation = item.ordenation;
      } else {
        this.values.push(item);
      }
    } else {
      this.values.push(item);
    }
    this.orderAction.emit([item]);
  }
}
