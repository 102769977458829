<div class="desktop d-none d-md-block" [ngClass]="{ 'tutorial-header': addTutorialClass }">
  <mat-toolbar class="header">
    <span>
      <img class="logo" src="{{ logo }}" alt="logo" />
    </span>
    <span (click)="drawer.close()" class="options-menu">
      <a
        *ngFor="let label of headerData; let i = index"
        (click)="choiceActionLabel(label.actionType, label.targetAddress); menuClick(label.label)"
        ngClass="{{ 'step' + (i + 5) }}"
        >{{ label.label | translate }}
      </a>
      <a (click)="showHelpMenu = !showHelpMenu; menuClick('HEADER-HELP')" class="options-menu step8" #refHelpMenu>
        <app-menu-help class="menu-help" [active]="showHelpMenu" [subMenuData]="helpMenuData"></app-menu-help>
        {{ 'HEADER-HELP' | translate }}
      </a>
    </span>
    <span
      (click)="choiceActionLabel('callComponent', 'notifications'); menuClick('NOTIFICATIONS')"
      class="cursor--pointer notificacao"
    >
      <img *ngIf="!addTutorialClass" src="./assets/images/ic_alert.svg" />
      <div *ngIf="isNotify && !addTutorialClass" class="div-alert"></div>
    </span>
    <span class="user-menu step9">
      <span class="name-user">{{ 'HELLO' | translate }}, {{ user }}! </span>
      <span class="name-store">{{ storeName }}</span>
      <i
        class="material-icons"
        (click)="menuCard = !menuCard; menuClick('MY-ACCOUNT')"
        alt="expand_menu_icon"
        #refUserMenu
        >expand_more</i
      >
    </span>
    <app-menu-profile *ngIf="menuCard" class="menu-card" [subMenuData]="subMenuData" #refCardMenu></app-menu-profile>
  </mat-toolbar>
</div>

<div class="mobile d-md-none" [ngClass]="{ 'tutorial-header': addTutorialClass }">
  <div *ngIf="currentUrl !== 'menu'" class="header">
    <img src="./assets/images/arraow-left.svg" />
    <span *ngIf="!showLogo" class="title">{{ titleHeader | translate }}</span>
    <img *ngIf="showLogo" src="./assets/images/logo_santander_mobile.svg" class="logo" alt="logo" />
    <img class="pr-2" src="./assets/images/ic_menu.svg" (click)="drawer.toggle(); menuClick('MOBILE-OPEN-MENU')" />
  </div>

  <!-- Header HomePage -->
  <div *ngIf="currentUrl === 'menu'" class="header-home-page">
    <div class="container">
      <div class="row row-up justify-content-between pl-4 pr-4 pt-2">
        <span class="cursor--pointer" (click)="choiceActionLabel('callComponent', 'notifications')">
          <img src="./assets/images/ic_alert.svg" />
          <div *ngIf="isNotify" class="div-alert"></div>
        </span>
        <img src="./assets/images/logo_santander_mobile.svg" class="logo" />
        <img class="step-1-mobile" (click)="drawer.toggle()" src="./assets/images/ic_menu.svg" />
      </div>
      <div class="row">
        <div class="col name">{{ 'HELLO' | translate }}, {{ user }}</div>
      </div>
      <div class="row">
        <div class="col sub-title">
          {{ 'MENU_SUBTITLE' | translate }}
        </div>
      </div>
    </div>
  </div>

  <mat-drawer-container class="menu-container" autosize [hasBackdrop]="true">
    <mat-drawer #drawer class="menu-sidenav" mode="side">
      <app-menu-mobile #resetableMenu (closeSidenav)="closeSidenav()"></app-menu-mobile>
      <div class="close-button">
        <img
          src="./assets/images/ic_close.svg"
          (click)="resetableMenu.resetOptions(); drawer.toggle(); menuClick('MOBILE-CLOSE-MENU')"
        />
      </div>
    </mat-drawer>
  </mat-drawer-container>
</div>
