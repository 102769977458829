import { Component, Input, OnInit } from '@angular/core';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { NegotiationDetailModel } from '@shared/models/negotiation-detail.model';

@Component({
  selector: 'app-negotiation-detail',
  templateUrl: './negotiation-detail.component.html',
  styleUrls: ['./negotiation-detail.component.scss']
})
export class NegotiationDetailComponent implements OnInit {
  @Input() negotiation: NegotiationDetailModel;
  @Input() event_category: string;

  constructor(private genTagger: GenTagger) {}

  ngOnInit() {
    this.genTagger.setTag({
      event_category: this.event_category,
      event_label: this.negotiation.status + this.negotiation.description,
      event_action: Tag.pgView
    });
  }
}
