import { Action } from '@ngrx/store';
import { PaymentPlanSelected } from './proposal.reducer';

export enum ProposalActionTypes {
  GET_OPEN_PROPOSALS = '[Proposal] [App] Get Open Proposals',
  GET_OPEN_PROPOSALS_SUCCESS = '[Proposal] [App] Get Open Proposals Success',
  GET_OPEN_PROPOSALS_ERROR = '[Proposal] [App] Get Open Proposals Error',

  GET_PENDING_PROPOSALS = '[Proposal] [App] Get Pending Proposals',
  GET_PENDING_PROPOSALS_SUCCESS = '[Proposal] [App] Get Pending Proposals Success',
  GET_PENDING_PROPOSALS_ERROR = '[Proposal] [App] Get Pending Proposals Error',

  GET_PROPOSAL = '[Proposal] [App] Get Proposal',
  GET_PROPOSAL_SUCCESS = '[Proposal] [App] Get Proposal Success',
  GET_PROPOSAL_ERROR = '[Proposal] [App] Get Proposal Error',
  CLEAR_PROPOSAL = '[Proposal] [App] Clear Proposal',

  GET_CHECKLIST = '[Proposal] [App] Get Checklist',
  GET_CHECKLIST_SUCCESS = '[Proposal] [App] Get Checklist Success',
  GET_CHECKLIST_ERROR = '[Proposal] [App] Get Checklist Error',

  GET_CHECKLIST_MOBILE = '[Proposal] [App] Get Checklist Mobile',
  GET_CHECKLIST_MOBILE_SUCCESS = '[Proposal] [App] Get Checklist Mobile Success',
  GET_CHECKLIST_MOBILE_ERROR = '[Proposal] [App] Get Checklist Mobile Error',

  GET_UPLOAD_CHECKLIST = '[Proposal] [App] Get Upload Checklist',
  GET_UPLOAD_CHECKLIST_SUCCESS = '[Proposal] [App] Get Upload Checklist Success',
  GET_UPLOAD_CHECKLIST_ERROR = '[Proposal] [App] Get Upload Checklist Error',

  GET_UPLOAD_CHECKLIST_MOBILE = '[Proposal] [App] Get Upload Checklist Moblie',
  GET_UPLOAD_CHECKLIST_MOBILE_SUCCESS = '[Proposal] [App] Get Upload Checklist Moblie Success',
  GET_UPLOAD_CHECKLIST_MOBILE_ERROR = '[Proposal] [App] Get Upload Checklist Moblie Error',

  CLEAR_CHECKLIST = '[Proposal] [App] Clear Checklist',
  CLEAR_CHECKLIST_MOBILE = '[Proposal] [App] Clear Checklist Mobile',

  SET_PROPOSAL_ID = '[Proposal] [App] Set Proposal Id',
  CLEAR_PROPOSAL_ID = '[Proposal] [App] Clear Proposal Id',

  GET_PROPOSAL_STAGE = '[Proposal] [App] Get Proposal Stage',
  GET_PROPOSAL_STAGE_SUCCESS = '[Proposal] [App] Get Proposal Stage Success',
  GET_PROPOSAL_STAGE_ERROR = '[Proposal] [App] Get Proposal Stage Error',
  CLEAR_PROPOSAL_STAGE = '[Proposal] [App] Clear Proposal Stage',

  GET_PENDING_PROPOSAL = '[Proposal] [App] Get Pending Proposal',
  GET_PENDING_PROPOSAL_SUCCESS = '[Proposal] [App] Get Pending Proposal Success',
  GET_PENDING_PROPOSAL_ERROR = '[Proposal] [App] Get Pending Proposal Error',

  SUBMIT_PENDING_PROPOSAL = '[Proposal] [App] Submit Pending Proposal',
  SUBMIT_PENDING_PROPOSAL_SUCCESS = '[Proposal] [App] Submit Pending Proposal Success',
  SUBMIT_PENDING_PROPOSAL_ERROR = '[Proposal] [App] Submit Pending Proposal Error',

  UPDATE_PROPOSAL = '[Proposal] [App] Update Proposal',
  UPDATE_PROPOSAL_SUCCESS = '[Proposal] [App] Update Proposal Success',
  SHOWCASE_CHOOSED_CARD = '[Proposal] [App] Showcase Choosed Card',

  SET_PAYMENT_PLAN_SELECTED = '[Proposal] [App] Set Payment Plan Selected',
  CLEAR_PAYMENT_PLAN_SELECTED = '[Proposal] [App] Clear Payment Plan Selected',

  GET_PROPOSAL_PENDENCY_BIOMETRY_FLOW_ENABLED = '[Proposal] Get Proposal Pendency Biometry Flow Enabled',
  SET_PROPOSAL_PENDENCY_BIOMETRY_FLOW_ENABLED = '[Proposal] Set Proposal Pendency Biometry Flow Enabled'
}

export class GetOpenProposalsAction implements Action {
  readonly type = ProposalActionTypes.GET_OPEN_PROPOSALS;
  constructor() {}
}
export class GetOpenProposalsSuccessAction implements Action {
  readonly type = ProposalActionTypes.GET_OPEN_PROPOSALS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetOpenProposalsErrorAction implements Action {
  readonly type = ProposalActionTypes.GET_OPEN_PROPOSALS_ERROR;
  constructor(public payload: any) {}
}

export class GetPendingProposalsAction implements Action {
  readonly type = ProposalActionTypes.GET_PENDING_PROPOSALS;
  constructor() {}
}
export class GetPendingProposalsSuccessAction implements Action {
  readonly type = ProposalActionTypes.GET_PENDING_PROPOSALS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetPendingProposalsErrorAction implements Action {
  readonly type = ProposalActionTypes.GET_PENDING_PROPOSALS_ERROR;
  constructor(public payload: any) {}
}

export class GetProposalAction implements Action {
  readonly type = ProposalActionTypes.GET_PROPOSAL;
  constructor(public payload: any) {}
}
export class GetProposalSuccessAction implements Action {
  readonly type = ProposalActionTypes.GET_PROPOSAL_SUCCESS;
  constructor(public payload: any) {}
}
export class GetProposalErrorAction implements Action {
  readonly type = ProposalActionTypes.GET_PROPOSAL_ERROR;
  constructor(public payload: any) {}
}

export class ClearProposalAction implements Action {
  readonly type = ProposalActionTypes.CLEAR_PROPOSAL;
  constructor() {}
}

export class GetChecklistAction implements Action {
  readonly type = ProposalActionTypes.GET_CHECKLIST;
  constructor(public payload: any) {}
}
export class GetChecklistSuccessAction implements Action {
  readonly type = ProposalActionTypes.GET_CHECKLIST_SUCCESS;
  constructor(public payload: any) {}
}
export class GetChecklistErrorAction implements Action {
  readonly type = ProposalActionTypes.GET_CHECKLIST_ERROR;
  constructor(public payload: any) {}
}
//CHECKLIST MOBILE
export class GetChecklistMobileAction implements Action {
  readonly type = ProposalActionTypes.GET_CHECKLIST_MOBILE;
  constructor(public payload: any) {}
}
export class GetChecklistMobileSuccessAction implements Action {
  readonly type = ProposalActionTypes.GET_CHECKLIST_MOBILE_SUCCESS;
  constructor(public payload: any) {}
}
export class GetChecklistMobileErrorAction implements Action {
  readonly type = ProposalActionTypes.GET_CHECKLIST_MOBILE_ERROR;
  constructor(public payload: any) {}
}

export class GetUploadChecklistAction implements Action {
  readonly type = ProposalActionTypes.GET_UPLOAD_CHECKLIST;
  constructor(public payload: any) {}
}
export class GetUploadChecklistSuccessAction implements Action {
  readonly type = ProposalActionTypes.GET_UPLOAD_CHECKLIST_SUCCESS;
  constructor(public payload: any) {}
}
export class GetUploadChecklistErrorAction implements Action {
  readonly type = ProposalActionTypes.GET_UPLOAD_CHECKLIST_ERROR;
  constructor(public payload: any) {}
}
//CHECKLIST MOBILE
export class GetUploadChecklistMobileAction implements Action {
  readonly type = ProposalActionTypes.GET_UPLOAD_CHECKLIST_MOBILE;
  constructor(public payload: any) {}
}
export class GetUploadChecklistMobileSuccessAction implements Action {
  readonly type = ProposalActionTypes.GET_UPLOAD_CHECKLIST_MOBILE_SUCCESS;
  constructor(public payload: any) {}
}
export class GetUploadChecklistMobileErrorAction implements Action {
  readonly type = ProposalActionTypes.GET_UPLOAD_CHECKLIST_MOBILE_ERROR;
  constructor(public payload: any) {}
}
export class ClearChecklistAction implements Action {
  readonly type = ProposalActionTypes.CLEAR_CHECKLIST;
  constructor() {}
}

//CHECKLIST MOBILE

export class ClearChecklistMobileAction implements Action {
  readonly type = ProposalActionTypes.CLEAR_CHECKLIST_MOBILE;
  constructor() {}
}

export class SetProposalIdAction implements Action {
  readonly type = ProposalActionTypes.SET_PROPOSAL_ID;
  constructor(public payload: any) {}
}
export class ClearProposalIdAction implements Action {
  readonly type = ProposalActionTypes.CLEAR_PROPOSAL_ID;
  constructor() {}
}

export class GetProposalStageAction implements Action {
  readonly type = ProposalActionTypes.GET_PROPOSAL_STAGE;
  constructor(public payload: any) {}
}
export class GetProposalStageSuccessAction implements Action {
  readonly type = ProposalActionTypes.GET_PROPOSAL_STAGE_SUCCESS;
  constructor(public payload: any) {}
}
export class GetProposalStageErrorAction implements Action {
  readonly type = ProposalActionTypes.GET_PROPOSAL_STAGE_ERROR;
  constructor(public payload: any) {}
}

export class ClearProposalStageAction implements Action {
  readonly type = ProposalActionTypes.CLEAR_PROPOSAL_STAGE;
  constructor() {}
}
export class GetPendingProposalAction implements Action {
  readonly type = ProposalActionTypes.GET_PENDING_PROPOSAL;
  constructor(public payload: { proposalId: number; portalStage: number }) {}
}
export class GetPendingProposalSuccessAction implements Action {
  readonly type = ProposalActionTypes.GET_PENDING_PROPOSAL_SUCCESS;
  constructor(public payload: any) {}
}
export class GetPendingProposalErrorAction implements Action {
  readonly type = ProposalActionTypes.GET_PENDING_PROPOSAL_ERROR;
  constructor(public payload: any) {}
}

export class SubmitPendingProposalAction implements Action {
  readonly type = ProposalActionTypes.SUBMIT_PENDING_PROPOSAL;
  constructor(public payload: any) {}
}
export class SubmitPendingProposalSuccessAction implements Action {
  readonly type = ProposalActionTypes.SUBMIT_PENDING_PROPOSAL_SUCCESS;
  constructor(public payload: any) {}
}
export class SubmitPendingProposalErrorAction implements Action {
  readonly type = ProposalActionTypes.SUBMIT_PENDING_PROPOSAL_ERROR;
  constructor(public payload: any) {}
}
export class UpdateProposals implements Action {
  readonly type = ProposalActionTypes.UPDATE_PROPOSAL;
  constructor(public payload: { proposalId: any; groupName: number }) {}
}
export class UpdateProposalsSuccess implements Action {
  readonly type = ProposalActionTypes.UPDATE_PROPOSAL_SUCCESS;
}
export class ShowcaseChoosedCard implements Action {
  readonly type = ProposalActionTypes.SHOWCASE_CHOOSED_CARD;
  constructor(public payload: any) {}
}

export class SetPaymentPlanSelectedAction implements Action {
  readonly type = ProposalActionTypes.SET_PAYMENT_PLAN_SELECTED;
  constructor(public payload: PaymentPlanSelected) {}
}

export class ClearPaymentPlanSelectedAction implements Action {
  readonly type = ProposalActionTypes.CLEAR_PAYMENT_PLAN_SELECTED;
  constructor() {}
}

export class GetProposalPendencyBiometryFlowEnabled implements Action {
  readonly type = ProposalActionTypes.GET_PROPOSAL_PENDENCY_BIOMETRY_FLOW_ENABLED;
}

export class SetProposalPendencyBiometryFlowEnabled implements Action {
  readonly type = ProposalActionTypes.SET_PROPOSAL_PENDENCY_BIOMETRY_FLOW_ENABLED;
  constructor(public payload: boolean) {}
}

export type ProposalActions =
  | GetOpenProposalsAction
  | GetOpenProposalsSuccessAction
  | GetOpenProposalsErrorAction
  | GetPendingProposalsAction
  | GetPendingProposalsSuccessAction
  | GetPendingProposalsErrorAction
  | GetProposalAction
  | GetProposalSuccessAction
  | GetProposalErrorAction
  | ClearProposalAction
  | GetChecklistAction
  | GetChecklistSuccessAction
  | GetChecklistErrorAction
  | GetChecklistMobileAction
  | GetChecklistMobileSuccessAction
  | GetChecklistMobileErrorAction
  | GetUploadChecklistAction
  | GetUploadChecklistSuccessAction
  | GetUploadChecklistErrorAction
  | GetUploadChecklistMobileAction
  | GetUploadChecklistMobileSuccessAction
  | GetUploadChecklistMobileErrorAction
  | ClearChecklistAction
  | ClearChecklistMobileAction
  | SetProposalIdAction
  | ClearProposalIdAction
  | GetProposalStageAction
  | GetProposalStageSuccessAction
  | GetProposalStageErrorAction
  | ClearProposalStageAction
  | GetPendingProposalAction
  | GetPendingProposalSuccessAction
  | GetPendingProposalErrorAction
  | SubmitPendingProposalAction
  | SubmitPendingProposalSuccessAction
  | SubmitPendingProposalErrorAction
  | UpdateProposals
  | UpdateProposalsSuccess
  | ShowcaseChoosedCard
  | SetPaymentPlanSelectedAction
  | ClearPaymentPlanSelectedAction
  | GetProposalPendencyBiometryFlowEnabled
  | SetProposalPendencyBiometryFlowEnabled;
