import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OsEnum } from '@app/proposal-workflow/models/os.enum';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-enable-camera-steps',
  templateUrl: './enable-camera-steps.component.html',
  styleUrls: ['./enable-camera-steps.component.scss']
})
export class EnableCameraStepsComponent implements OnInit {
  public os = OsEnum.ANDROID;
  public osEnum = OsEnum;

  constructor(
    public dialogRef: MatDialogRef<EnableCameraStepsComponent>,
    public deviceDetectorService: DeviceDetectorService
  ) {}

  ngOnInit() {
    this.getCurrentPlatform();
    this.dialogRef.addPanelClass('enable-camera-steps');
  }

  private getCurrentPlatform(): void {
    const deviceInfo = this.deviceDetectorService.getDeviceInfo();
    const os = deviceInfo.os ? deviceInfo.os : '';
    this.os = os.toUpperCase() as OsEnum;
  }

  public enableCameraInstructionsUnderstood(): void {
    this.dialogRef.close();
  }
}
