import gql from 'graphql-tag';

export const GET_SUBSEGS = gql`
  query getSubsegs {
    getSubsegs {
      idUser
      cdUser
      idSubseg
      cdSubseg
      dsSubseg
    }
  }
`;
