import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidatorFn } from '@angular/forms';
import { Domains } from '@shared/models/domains.model';
import * as moment from 'moment';
import { validateCpf } from './validators/cpf.validators';

@Injectable({
  providedIn: 'root'
})
export class FormValidationService {
  constructor() { }

  /**
   * RegExp pattern for checking if the value has a valid name (First Name + Last Name)
   */
  getPatternFullName() {
    // tslint:disable-next-line:max-line-length
    return /^([A-Za-zÁÉÍÓÚñáéíóúÑ]{1,}?[A-Za-zÁÉÍÓÚñáéíóúÑ\']+[\s])+([A-Za-zÁÉÍÓÚñáéíóúÑ]{1,}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])+[\s]?([A-Za-zÁÉÍÓÚñáéíóúÑ]{1,}?[A-Za-zÁÉÍÓÚñáéíóúÑ\'])?$/;
  }

  /**
   * RegExp pattern for checking if the value is a valid phone
   */
  getPatternPhone() {
    return /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)?(?:((?:9\d|[2-9])\d{3})\-?(\d{4}))$/;
  }

  /**
   * RegExp pattern for checking if the value is a valid email
   */
  getPatternEmail() {
    // tslint:disable-next-line:max-line-length
    return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  }

  /**
   * Check if cpf is valid
   * @param param0
   */
  isCpfValid({ value: cpf }: AbstractControl) {
    if (!cpf || validateCpf(cpf)) {
      return null;
    }

    return { invalidCpf: true };
  }

  isCpfValidRepresentation({ value: cpf }: AbstractControl) {
    if (cpf !== null) {
      if (cpf.includes('x') || !cpf || validateCpf(cpf)) {
        return null;
      }
    }
    return { invalidCpf: true };
  }

  isValidRgOrCpf({ value: rgOrCpf }: AbstractControl) {
    if (!rgOrCpf) {
      return null;
    }
    if (rgOrCpf.length === 11) {
      if (validateCpf(rgOrCpf)) {
        return null;
      }
    } else {
      return null;
    }

    return { invalidCpf: true };
  }

  isValidDateOfBith({ value }: AbstractControl) {
    if (!value) {
      return null;
    }
    const today = moment();
    const date = moment(value, 'DD/MM/YYYY');
    if (today.diff(date, 'days') > 0) {
      return null;
    }

    return { isValidMinDateToday: true };
  }

  /**
   * Check if value is a valid percentage
   * @param param0
   */
  isValidPercentage({ value }: AbstractControl) {
    if (!value || (value >= 0 && value <= 100)) {
      return null;
    }

    return { invalidPercentage: true };
  }

  /**
   * Check if minKey value is lower than maxKey value
   * @param minKey
   * @param maxKey
   * @param errorKey
   */
  isValidMinMax(minKey: string, maxKey: string, errorKey: string) {
    return (fg: FormGroup) => {
      let start = fg.controls[minKey].value;
      let end = fg.controls[maxKey].value;
      let errors = fg.controls[errorKey].errors;

      start = start !== null ? parseFloat(start) : null;
      end = end !== null ? parseFloat(end) : null;

      if (
        (start === null && end === null) ||
        (start !== null && end !== null && start <= end) ||
        (start !== null && end === null) ||
        (start === null && end !== null)
      ) {
        if (errors && errors.hasOwnProperty('invalidMinMax')) {
          delete errors.invalidMinMax;
          errors = Object.values(errors).length ? errors : null;
        }

        fg.controls[errorKey].setErrors(errors);
        return null;
      }

      if (start > end) fg.controls[errorKey].setErrors({ ...errors, invalidMinMax: true });
      return { invalidMinMax: true };
    };
  }

  /**
   * Check if dataInicio is defined and lower than dataFim
   * @param dataInicio
   * @param dataFim
   */
  isValidDataInicioFim(dataInicio: string, dataFim: string) {
    return (fg: FormGroup) => {
      const start = fg.controls[dataInicio].value;
      const end = fg.controls[dataFim].value;
      let errors = fg.controls[dataFim].errors;

      if (
        (start === null && end === null) ||
        (start !== null && end !== null && start <= end) ||
        (start !== null && end === null) ||
        (start === null && end !== null)
      ) {
        if (errors && errors.hasOwnProperty('invalidDataInicioFim')) {
          delete errors.invalidDataInicioFim;
          errors = Object.values(errors).length ? errors : null;
        }

        fg.controls[dataFim].setErrors(errors);
        return null;
      }

      if (start > end) fg.controls[dataFim].setErrors({ ...errors, invalidDataInicioFim: true });
      return { invalidDataInicioFim: true };
    };
  }

  /**
   * Validate all form fields
   * @param formGroup
   */
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  /**
   * Clear validation for all form fields
   * @param formGroup
   */
  clearValidationAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsUntouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.clearValidationAllFormFields(control);
      }
    });
  }

  forbiddenNamesValidator(options: Domains[]): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (!options || !control.value) {
        return null;
      }
      const index = options.filter(op => {
        return op.code === control.value;
      });
      return !index.length ? { forbiddenNames: { value: control.value } } : null;
    };
  }
}
