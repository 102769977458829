import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { AppState } from '@app/core/state';
import * as notificationsAction from '@app/core/state/notifications/notifications.actions';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { BottomSheetNotificationModel } from '../../model/bottomSheet.model';
import { NotificationService } from '../../services/notification.service';

@Component({
  selector: 'app-bottom-sheet',
  templateUrl: './bottom-sheet-notification.component.html',
  styleUrls: ['./bottom-sheet-notification.component.scss']
})
export class BottomSheetNotificationComponent implements OnInit {
  @Inject(MAT_BOTTOM_SHEET_DATA)
  public data: BottomSheetNotificationModel = new BottomSheetNotificationModel();

  readonly category = '/portallojista/notificacoes';

  constructor(
    private store$: Store<AppState>,
    private readonly notificationService: NotificationService,
    private genTagger: GenTagger,
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetNotificationComponent>
  ) {}

  ngOnInit() {
    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.pgView,
      event_label: 'Notificações Mobile'
    });
  }

  dismiss(): void {
    this._bottomSheetRef.dismiss();
  }

  markRead() {
    if (this.data.type) {
      this.store$.dispatch(new notificationsAction.MarkNotificationRead({ id: this.data.object.id }));
    } else {
      this.store$.dispatch(new notificationsAction.MarkAllNotificationsRead());
    }
    this.dismiss();
  }

  exclude() {
    if (this.data.object) {
      this.store$.dispatch(new notificationsAction.DeleteNotification({ id: this.data.object.id }));
    } else {
      this.store$.dispatch(new notificationsAction.DeleteAllNotifications());
    }
    this.dismiss();
  }
}
