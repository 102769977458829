import { CommonModule, CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { FaqRoutingModule } from '@app/faq/faq-routing.module';
import { FaqContentModalComponent } from '@app/faq/faq-content-modal/faq-content-modal.component';
import { FaqSearchComponent } from './faq-search/faq-search.component';
import { FaqComponent } from '@app/faq/faq.component';
import { FaqCategoryComponent } from './faq-category/faq-category.component';
registerLocaleData(localePt);

@NgModule({
  declarations: [FaqComponent, FaqContentModalComponent, FaqSearchComponent, FaqCategoryComponent],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    CurrencyPipe,
    DatePipe
  ],
  imports: [
    FaqRoutingModule,
    CommonModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatProgressBarModule,
    MatCardModule,
    TranslateModule,
    SharedModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class FaqModule {}
