import { CommonModule, CurrencyPipe, DatePipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { PendencyComponent } from '@app/pendency/pendency.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { DateRangePickerDialogComponent } from '@shared/components/date-range-picker-dialog/date-range-picker-dialog.component';
import { FilterOrdenationDialogComponent } from '@shared/components/filter-ordenation-dialog/filter-ordenation-dialog.component';
import { FilterPeriodDialogComponent } from '@shared/components/filter-period-dialog/filter-period-dialog.component';
import { SearchSellerDialogComponent } from '@shared/components/search-seller-dialog/search-seller-dialog.component';
import { SharedModule } from '@shared/shared.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PendencyModule } from '../pendency/pendency.module';
import { ActiveContractsComponent } from './active-contracts/active-contracts.component';
import { ActiveContractsCardComponent } from './active-contracts/component/active-contracts-card/active-contracts-card.component';
import { InstallmentPurchasesCardComponent } from './components/installment-purchases-card/installment-purchases-card.component';
import { PersonalizedOfferCardComponent } from './components/personalized-offer-card/personalized-offer-card.component';
import { SalaryInputDialogComponent } from './components/salary-input/salary-input-dialog/salary-input-dialog.component';
import { SalaryInputComponent } from './components/salary-input/salary-input/salary-input.component';
import { ShowcaseSectionComponent } from './components/showcase-section/showcase-section.component';
import { SideCalendarComponent } from './components/side-calendar/side-calendar.component';
import { SpecialOfferCardComponent } from './components/special-offer-card/special-offer-card.component';
import { StatementPeriodDownloadDialogComponent } from './financial-statement/components/statement-period-download-dialog/statement-period-download-dialog.component';
import { TableComponent } from './financial-statement/components/table/table.component';
import { FinancialStatementComponent } from './financial-statement/financial-statement.component';
import { ManagementComponent } from './management/management.component';
import { OffersComponent } from './offers/offers.component';
import { PaymentDetailDialogComponent } from './payments/components/payment-detail-dialog/payment-detail-dialog.component';
import { PaymentInvalidDataDialogComponent } from './payments/components/payment-invalid-data-dialog/payment-invalid-data-dialog.component';
import { PaymentMassiveUploadDialogComponent } from './payments/components/payment-massive-upload-dialog/payment-massive-upload-dialog.component';
import { PaymentMassiveUploadStatusDialogComponent } from './payments/components/payment-massive-upload-status-dialog/payment-massive-upload-status-dialog.component';
import { PaymentMultipleRequestComponent } from './payments/components/payment-multiple-request/payment-multiple-request.component';
import { PaymentRequestDialogComponent } from './payments/components/payment-request-dialog/payment-request-dialog.component';
import { PaymentRequestStatusDialogComponent } from './payments/components/payment-request-status-dialog/payment-request-status-dialog.component';
import { PaymentSellerDialogComponent } from './payments/components/payment-seller-dialog/payment-seller-dialog.component';
import { PaymentTableMobileComponent } from './payments/components/payment-table-mobile/payment-table-mobile.component';
import { PaymentTableComponent } from './payments/components/payment-table/payment-table.component';
import { PaymenttooltipComponent } from './payments/components/paymenttooltip/paymenttooltip.component';
import { CanActivateRouteGuard } from './payments/guard/can-active-router.guard';
import { PaymentsComponent } from './payments/payments.component';
import { ProposalCancelDialogComponent } from './proposals/components/proposal-cancel-dialog/proposal-cancel-dialog.component';
import { ProposalCardComponent } from './proposals/components/proposal-card/proposal-card.component';
import { ProposalDetailComponent } from './proposals/components/proposal-detail/proposal-detail.component';
import { ProposalExtractDialogComponent } from './proposals/components/proposal-extract-dialog/proposal-extract-dialog.component';
import { ProposalMassiveCancelDialogComponent } from './proposals/components/proposal-massive-cancel-dialog/proposal-massive-cancel-dialog.component';
import { ProposalPeriodDialogComponent } from './proposals/components/proposal-period-dialog/proposal-period-dialog.component';
import { ProposalReasonCancelDialogComponent } from './proposals/components/proposal-reason-cancel-dialog/proposal-reason-cancel-dialog.component';
import { ProposalResumeComponent } from './proposals/components/proposal-resume/proposal-resume.component';
import { ProposalSellerDialogComponent } from './proposals/components/proposal-seller-dialog/proposal-seller-dialog.component';
import { ProposalTableMobileComponent } from './proposals/components/proposal-table-mobile/proposal-table-mobile.component';
import { ProposalTableComponent } from './proposals/components/proposal-table/proposal-table.component';
import { OpenProposalComponent } from './proposals/open-proposal.component';
import { ShowcaseRoutingModule } from './showcase-routing.module';
import { ShowcaseComponent } from './showcase.component';
import { FiltersComponent as StoresListFiltersComponent } from './stores/list/filters/filters.component';
import { ListComponent as StoresListComponent } from './stores/list/list.component';
import { StoresComponent } from './stores/stores.component';
import { StoreSubsegmentComponent } from './stores/subsegment/store-subsegment.component';
import { SubsegmentComponent } from './subsegment/subsegment.component';
import { StatementDownloadFileTypeDialogComponentComponent } from './financial-statement/components/statement-download-file-type-dialog-component/statement-download-file-type-dialog-component.component';

registerLocaleData(localePt);

@NgModule({
  imports: [
    CommonModule,
    ShowcaseRoutingModule,
    TranslateModule,
    SharedModule,
    CarouselModule,
    CurrencyMaskModule,
    NgxMaskModule.forRoot(),
    MatIconModule,
    NgxSkeletonLoaderModule,
    MatProgressBarModule,
    MatCardModule,
    NgxSkeletonLoaderModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatOptionModule,
    MatButtonModule,
    MatCardModule,
    PendencyModule,
    MatRippleModule,
    MatDialogModule,
    MatTabsModule,
    MatMenuModule,
    MatCheckboxModule,
    MatRadioModule,
    MatExpansionModule,
    FormsModule,
    NgbModule
  ],
  declarations: [
    ShowcaseComponent,
    OpenProposalComponent,
    ProposalCardComponent,
    ProposalDetailComponent,
    SideCalendarComponent,
    ShowcaseSectionComponent,
    SpecialOfferCardComponent,
    InstallmentPurchasesCardComponent,
    ActiveContractsCardComponent,
    ActiveContractsComponent,
    PersonalizedOfferCardComponent,
    StoresComponent,
    StoresListComponent,
    StoresListFiltersComponent,
    StoreSubsegmentComponent,
    SubsegmentComponent,
    SalaryInputComponent,
    SalaryInputDialogComponent,
    OffersComponent,
    ProposalResumeComponent,
    ProposalCancelDialogComponent,
    PaymentsComponent,
    ManagementComponent,
    ProposalPeriodDialogComponent,
    ProposalSellerDialogComponent,
    ProposalReasonCancelDialogComponent,
    PaymentSellerDialogComponent,
    PaymentDetailDialogComponent,
    FinancialStatementComponent,
    ProposalTableMobileComponent,
    ProposalTableComponent,
    PaymentRequestDialogComponent,
    PaymentRequestStatusDialogComponent,
    PaymentMultipleRequestComponent,
    ProposalExtractDialogComponent,
    PaymentMassiveUploadDialogComponent,
    PaymentMassiveUploadStatusDialogComponent,
    StatementPeriodDownloadDialogComponent,
    ProposalMassiveCancelDialogComponent,
    PaymentInvalidDataDialogComponent,
    PaymentTableMobileComponent,
    PaymentTableComponent,
    PaymenttooltipComponent,
    TableComponent,
    StatementDownloadFileTypeDialogComponentComponent
  ],
  exports: [
    ShowcaseSectionComponent,
    ProposalCardComponent,
    ActiveContractsCardComponent,
    PersonalizedOfferCardComponent,
    InstallmentPurchasesCardComponent,
    SpecialOfferCardComponent
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    CurrencyPipe,
    DatePipe,
    CanActivateRouteGuard
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ShowcaseModule {}
