import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as actions from './notifications.actions';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state';
import { NotificationService } from '@app/profile/components/notifications/services/notification.service';

@Injectable()
export class NotificationsEffects {
  constructor(
    private store$: Store<AppState>,
    private actions$: Actions,
    private notificationService: NotificationService
  ) {}

  @Effect()
  getNotifications = this.actions$.pipe(
    ofType<actions.GetNotifications>(actions.NotificationsActionTypes.GET_NOTIFICATIONS),
    switchMap(action => {
      return this.notificationService.getNotifications().pipe(
        switchMap(response => {
          return [new actions.GetNotificationsSuccess(response)];
        }),
        catchError(error => {
          return [new actions.GetNotificationsError(error)];
        })
      );
    })
  );

  @Effect()
  markAsRead = this.actions$.pipe(
    ofType<actions.MarkNotificationRead>(actions.NotificationsActionTypes.MARK_NOTIFICATION_READ),
    switchMap(action =>
      this.notificationService.markAsReadById(action.payload.id).pipe(
        map(response => {
          return new actions.GetNotifications();
        }),
        catchError(error => {
          return of(new actions.GetNotificationsError(error));
        })
      )
    )
  );

  @Effect()
  deleteNotification = this.actions$.pipe(
    ofType<actions.DeleteNotification>(actions.NotificationsActionTypes.DELETE_NOTIFICATION),
    switchMap(action =>
      this.notificationService.deleteById(action.payload.id).pipe(
        map(response => {
          return new actions.GetNotifications();
        }),
        catchError(error => {
          return of(new actions.GetNotificationsError(error));
        })
      )
    )
  );

  @Effect()
  deleteAllNotification = this.actions$.pipe(
    ofType<actions.DeleteAllNotifications>(actions.NotificationsActionTypes.DELETE_ALL_NOTIFICATIONS),
    switchMap(action =>
      this.notificationService.deleteAll().pipe(
        map(response => {
          return new actions.GetNotifications();
        }),
        catchError(error => {
          return of(new actions.GetNotificationsError(error));
        })
      )
    )
  );

  @Effect()
  markAllNotificationRead = this.actions$.pipe(
    ofType<actions.MarkAllNotificationsRead>(actions.NotificationsActionTypes.MARK_ALL_NOTIFICATIONS_READ),
    switchMap(action =>
      this.notificationService.markAsReadAll().pipe(
        map(response => {
          return new actions.GetNotifications();
        }),
        catchError(error => {
          return of(new actions.GetNotificationsError(error));
        })
      )
    )
  );
}
