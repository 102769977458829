<mat-toolbar class="header">
  <span>
    <a [routerLink]="['/products/']">
      <img class="logo" src="./assets/images/logo_santander_mobile.svg" alt="logo" />
    </a>
  </span>
  <span class="title-group">
    <a class="active">Home</a>
    <a>Ofertas</a>
    <a>Propostas</a>
    <a>Contratos</a>
    <a>Ajuda</a>
    <a>
      <img class="alert-important" src="./assets/images/alert-important.svg" alt="alert-important" />
    </a>
    <span class="client-name">{{ 'HELLO' | translate }}, {{ clientName }} </span>
    <span>
      <img class="cursor--pointer arrow" src="./assets/images/arraow-left.svg" />
    </span>
  </span>
</mat-toolbar>
