import { Component, Inject, OnInit } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { CalendarConfig } from '@shared/components/ui/calendar/calendar.model';

@Component({
  selector: 'app-bottomSheetCalendar',
  templateUrl: './bottomSheetCalendar.component.html',
  styleUrls: ['./bottomSheetCalendar.component.scss']
})
export class BottomSheetCalendarComponent implements OnInit {
  public calendarConfig: CalendarConfig;
  constructor(
    private _bottomSheetRef: MatBottomSheetRef<BottomSheetCalendarComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA) public data: any
  ) {
    this.calendarConfig = this.data;
  }

  ngOnInit() {}
  public navAction(date: string): void {
    if (date) {
      this.closeDialogCalendar(date);
    }
  }

  public closeDialogCalendar(date?: string): void {
    this._bottomSheetRef.dismiss(date);
  }
}
