import { environment } from '@env/environment';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { StoreModel } from '@shared/models/store.model';
import { ExecutionResult } from 'apollo-link';
import * as moment from 'moment';
import { PostIdentificationReqDTO } from '../models/api/post.identification.model';
import { IdentificationConfigResponse } from '../models/api/query.identification-config.model';
import { IdentificationData } from '../models/identification.model';

export function mapIdentificationConfigQuery(response: ExecutionResult): IdentificationConfigResponse {
  let result: IdentificationConfigResponse;
  if (response.data && response.data.listTerms) {
    const terms = response.data.listTerms;

    result = {
      terms
    };
  }
  return result;
}

export function mapIdentificationSubmit(
  submitIdentification: IdentificationData,
  selectedTab: StoreModel,
  termId: number
): PostIdentificationReqDTO {
  const { product, documentNumber, date, phone, financedValue, entryValue, travelDate } = submitIdentification;

  return {
    productCode: product.code as ProductCodeEnum,
    documentNumber,
    birthdayDate: moment(date, 'DDMMYYYY').format('YYYY-MM-DD'),
    mainTelephoneAreaCode: phone ? phone.slice(0, 2) : '',
    mainTelephoneNumber: phone ? phone.slice(2) : '',
    supplierChannelCode: selectedTab.code,
    channelAccessCode: 'LOJISTA',
    financedValue,
    entryValue,
    travelDate: travelDate ? moment(travelDate, 'DDMMYYYY').format('YYYY-MM-DD') : null,
    termId,
    subSegmentId: product && product.subsegment ? product.subsegment.id : null,
    proposalVersion: JSON.parse(environment.useNPP) ? 'NPP' : null
  };
}
