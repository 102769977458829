import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'pion-terms-modal',
  templateUrl: 'terms-modal.component.html',
  styleUrls: ['terms-modal.component.scss']
})
export class TermsModalComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('termsContent', { static: true }) termsContent: ElementRef;
  @Output() accept = new EventEmitter<any>();
  @Output() skip = new EventEmitter();

  readonly category = '/portallojista/login';
  disableBtn = true;

  get actionOpenModal(): ExtendedTag {
    return {
      event_action_type: Tag.AbriuModal,
      event_action_complement: this.translate.instant('TERMS-AND-CONDITIONS')
    };
  }

  get actionCloseModal(): ExtendedTag {
    return {
      event_action_type: Tag.Fechou_Modal,
      event_action_complement: this.translate.instant('TERMS-AND-CONDITIONS')
    };
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private genTagger: GenTagger,
    private translate: TranslateService
  ) {}
  ngOnInit(): void {
    this.genTagger.setTag({
      event_category: this.category,
      event_action: this.actionOpenModal,
      event_label: 'Login - Modal Termos e Condições'
    });
  }
  ngOnDestroy(): void {
    this.genTagger.setTag({
      event_category: this.category,
      event_action: this.actionCloseModal,
      event_label: 'Login - Modal Termos e Condições'
    });
  }

  ngAfterViewInit() {
    setTimeout(() => this.checkScroll());
  }

  checkScroll() {
    if (
      this.termsContent &&
      this.termsContent.nativeElement.scrollHeight <= this.termsContent.nativeElement.clientHeight
    ) {
      this.disableBtn = false;
    }
  }

  handleScroll(event) {
    const target = event.target;
    if (target.scrollTop + target.offsetHeight >= target.scrollHeight - 1) {
      this.disableBtn = false;
    }
  }

  handleAcceptButtonClick() {
    this.accept.emit(this.data[0]);

    this.genTagger.setTag({
      event_category: this.category,
      event_action: this.actionCloseModal,
      event_label: this.translate.instant('AGREE')
    });
  }

  handleCloseButtonClick() {
    this.skip.emit();

    this.genTagger.setTag({
      event_category: this.category,
      event_action: this.actionCloseModal,
      event_label: 'Fechar'
    });
  }
}
