<mat-card class="menu-card">
  <mat-card-header>
    <mat-card-title>{{ 'HELLO' | translate }}, {{ user }}</mat-card-title>
    <mat-card-subtitle class="store-name">{{ storeName }}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>
    <mat-list>
      <mat-list-item
        *ngFor="let label of menus"
        class="item-list-line"
        (click)="choiceActionLabel(label.actionType, label.targetAddress)"
      >
        {{ label.label | translate }}
      </mat-list-item>
      <mat-list-item (click)="logout(); menuClick('Log Out App')" class="list-item-image">
        <img src="./assets/images/ic_logout.svg" class="icon_exit" alt="logout icon" />
        {{ 'Log Out App' | translate }}
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
