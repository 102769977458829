import { Action } from '@ngrx/store';

export enum ProposalNegotiationActionsTypes {
  NEGOTIATION_PROPOSAL = '[Proposal Negotiation] Negotiation',
  NEGOTIATION_PROPOSAL_SUCCESS = '[Proposal Negotiation] Negotiation Success',
  NEGOTIATION_PROPOSAL_ERROR = '[Proposal Negotiation] Negotiation Error',

  NEGOTIATION_PROPOSAL_POOLING = '[Proposal Negotiation] Negotiation Pooling',
  NEGOTIATION_PROPOSAL_POOLING_SUCCESS = '[Proposal Negotiation] Negotiation Pooling Success',

  CLEAR_NEGOTIATION_PROPOSAL = '[Proposal Negotiation] Clear'
}

export class NegotiationProposal implements Action {
  readonly type = ProposalNegotiationActionsTypes.NEGOTIATION_PROPOSAL;
  constructor(public payload: any) {}
}

export class NegotiationProposalSuccess implements Action {
  readonly type = ProposalNegotiationActionsTypes.NEGOTIATION_PROPOSAL_SUCCESS;
  constructor(public payload: any) {}
}

export class NegotiationProposalError implements Action {
  readonly type = ProposalNegotiationActionsTypes.NEGOTIATION_PROPOSAL_ERROR;
  constructor() {}
}

export class NegotiationProposalPooling implements Action {
  readonly type = ProposalNegotiationActionsTypes.NEGOTIATION_PROPOSAL_POOLING;
  constructor(public payload: any) {}
}

export class NegotiationProposalPoolingSuccess implements Action {
  readonly type = ProposalNegotiationActionsTypes.NEGOTIATION_PROPOSAL_POOLING_SUCCESS;
  constructor(public payload: any) {}
}

export class ClearNegotiationProposal implements Action {
  readonly type = ProposalNegotiationActionsTypes.CLEAR_NEGOTIATION_PROPOSAL;
  constructor() {}
}

export type ProposalNegotiationActions =
  | NegotiationProposal
  | NegotiationProposalSuccess
  | NegotiationProposalError
  | NegotiationProposalPooling
  | NegotiationProposalPoolingSuccess
  | ClearNegotiationProposal;
