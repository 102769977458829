<div id="modal-content-wrapper">
  <header id="modal-header" class="text-center mt-4">
    <img src="./assets/images/{{ info.icon }}.svg" width="44px" height="44px" [alt]="info.iconAltText" class="mb-4" />
    <h1 id="modal-title mt-2" mat-dialog-title>{{ info.title }}</h1>
  </header>
  <section *ngIf="!!info && !!info.text" id="modal-body" mat-dialog-content>
    <p class="mt-2">{{ info.text }}</p>
  </section>
  <footer id="modal-footer" mat-dialog-actions class="mt-4 mb-3">
    <button
      mat-flat-button
      id="modal-close-button"
      color="primary"
      (click)="closeDialog()"
      appTagger
      [event_category]="category"
      event_label="{{ info.buttonText }}"
      event_action="{{ closeAction }} - {{ info.title }}"
    >
      {{ info.buttonText }}
    </button>
  </footer>
</div>
