import { StartupActions, StartupActionTypes } from './startup.actions';

export interface StartupState {
  username: string | null;
  urlRequest: string | null;
  urlOrigin: string | null;
  isLoggedIn: boolean | null;
  tokenPP: string | null;
  alerts: any;
  userInfo: any;
  clientCredentials: any;
  plataform: string | null;
  isMobile: boolean | null;
  isDesktop: boolean | null;
  terms: string;
}

export const startupState: StartupState = {
  username: null,
  urlRequest: null,
  urlOrigin: null,
  isLoggedIn: null,
  tokenPP: null,
  alerts: [],
  userInfo: null,
  clientCredentials: null,
  plataform: 'CLIENTE',
  isMobile: false,
  isDesktop: false,
  terms: ''
};

export function startupReducer(state = startupState, action: StartupActions): StartupState {
  switch (action.type) {
    case StartupActionTypes.VERIFY_ACCESS_SUCCESS: {
      return {
        ...state,
        username: action.payload.username,
        urlRequest: null,
        isLoggedIn: true
      };
    }

    case StartupActionTypes.GET_CLIENT_CREDENTIALS_SUCCESS: {
      return {
        ...state,
        clientCredentials: action.payload.clientCredentials
        // isMobile: action.payload.isMobile,
        // isDesktop: action.payload.isDesktop
      };
    }

    case StartupActionTypes.GET_USER_INFO_SUCCESS: {
      return {
        ...state,
        userInfo: action.payload.userInfo,
        isMobile: action.payload.isMobile,
        isDesktop: action.payload.isDesktop
      };
    }

    case StartupActionTypes.GET_TERMS_SUCCESS: {
      return {
        ...state,
        terms: action.payload.clauses
      };
    }

    case StartupActionTypes.GET_TERMS_ERROR: {
      return {
        ...state,
        alerts: [...state.alerts, action.payload.message]
      };
    }

    case StartupActionTypes.GET_TERMS_SUCCESS: {
      return {
        ...state,
        terms: action.payload.text
      };
    }

    case StartupActionTypes.VERIFY_ACCESS_ERROR: {
      return {
        ...startupState,
        alerts: [...state.alerts, action.payload]
      };
    }

    case StartupActionTypes.GET_DEVICE_INFO_REQUEST: {
      return {
        ...startupState,
        isMobile: action.payload.isMobile,
        isDesktop: action.payload.isDesktop
      };
    }

    default:
      return state;
  }
}
