import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-modal',
  templateUrl: './content-modal.component.html',
  styleUrls: ['./content-modal.component.scss']
})
export class ContentModalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
