import { NotificationsActions, NotificationsActionTypes } from '@app/core/state/notifications/notifications.actions';

export interface NotificationsState {
  notifications: { newData: any[]; oldData: any[] };
}

export const initialNotificationsState: NotificationsState = {
  notifications: {
    newData: [],
    oldData: []
  }
};

export function notificationsReducer(
  state = initialNotificationsState,
  action: NotificationsActions
): NotificationsState {
  switch (action.type) {
    case NotificationsActionTypes.GET_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        notifications: {
          newData: action.payload.todayNotifications,
          oldData: action.payload.previousNotifications
        }
      };
    }
    case NotificationsActionTypes.GET_NOTIFICATIONS_ERROR: {
      return {
        ...state,
        notifications: initialNotificationsState.notifications
      };
    }
    default:
      return state;
  }
}
