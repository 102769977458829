import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state';
import { selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import { first, takeUntil } from 'rxjs/operators';
import { GetConfirmationData } from '@app/core/state/proposal-information/proposal-information.actions';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { Subject } from 'rxjs';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import { MatDialog } from '@angular/material/dialog';
import { ProposalCancelDialogComponent } from '@app/showcase/proposals/components/proposal-cancel-dialog/proposal-cancel-dialog.component';
import { DownloadContractService } from '@app/proposal-workflow/containers/step-formalization/services/download-contract.service';
import * as fromProposal from '@app/core/state/proposal';
import { Router } from '@angular/router';
import { SideContentService } from '@shared/services/side-content/side-content.service';

@Component({
  selector: 'app-proposal-detail',
  templateUrl: './proposal-detail.component.html',
  styleUrls: ['./proposal-detail.component.scss']
})
export class ProposalDetailComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  public width = window.screen.width;
  proposalId;
  public paymentPlanType = PaymentPlanType;
  canCancel = false;
  @Input() public isOpened = false;
  canDownloadCet = false;

  proposal: ConfirmationData;
  proposalData;
  canContinue = false;

  constructor(
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private downloadService: DownloadContractService,
    private router: Router,
    private sideContentService: SideContentService
  ) {}

  ngOnInit() {
    window.scrollTo(0, 0);
    this.loadProposalDetails();
  }

  private loadProposalDetails() {
    this.store$
      .select(selectProposalId)
      .pipe(first())
      .subscribe(proposalId => {
        this.proposalId = proposalId;
        this.setProposalDataFromShowcase();
        this.store$.dispatch(new GetConfirmationData(proposalId.toString()));
        this.getProposalData();
      });
  }

  private getProposalData() {
    this.store$
      .select(getConfirmationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: any) => {
        if (state) {
          this.proposal = state;
        }
        this.verifyDownloadCet();
        this.verifyCanCancel();
        this.verifyCanContinue();
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  cancel() {
    if (this.canCancel) {
      const ref = this.dialog.open(ProposalCancelDialogComponent, {
        width: '560px',
        autoFocus: false,
        id: 'cancel-proposal-dialog',
        data: {
          proposalId: this.proposalId
        }
      });
      ref.afterClosed().subscribe(isCancel => {
        if (isCancel) {
          console.log('proposal cancelled');
        }
      });
      ref.componentInstance.okExit.subscribe(() => {
        this.sideContentService.close(true);
        this.store$.dispatch(new fromProposal.actions.GetOpenProposalsAction());
      });
    }
  }

  private setProposalDataFromShowcase() {
    this.store$
      .select(fromProposal.selectors.selectOpenProposals)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(openProposals => {
        this.proposalData = openProposals.data.filter(s => s.id === this.proposalId)[0];
      });
  }

  downloadContract() {
    this.downloadService.downloadContractByUuid(this.proposalId, this.proposal.contract.UUID).subscribe((res: any) => {
      if (!res) return;
      const byteArray = new Uint8Array(
        atob(res.data)
          .split('')
          .map(char => char.charCodeAt(0))
      );
      const blob = new Blob([byteArray], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');

      link.href = url;
      link.download = 'Contrato.pdf';
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    });
  }

  private verifyDownloadCet() {
    if (this.proposalData) {
      const statusBlocked = [
        'PREAN',
        'PRENG',
        'PREDR',
        'PRSIM',
        'PRNAF',
        'PRNBF',
        'PRNFC',
        'PRNHS',
        'PRNMD',
        'PRPAG',
        'PGTLB',
        'EPGTO',
        'EDESB',
        'EPGPC',
        'PRCAN',
        'PREXP'
      ];

      this.canDownloadCet = !statusBlocked.includes(this.proposalData.statusProposal);
    }
  }

  private verifyCanCancel() {
    if (this.proposalData) {
      const statusBlocked = [
        'PRENG',
        'PREDR',
        'PRPAG',
        'PRNBF',
        'ADEAN',
        'ADAPR',
        'PEDBP',
        'ADAHS',
        'ADHSE',
        'ADHSP',
        'MSAPR',
        'CDPND',
        'PEDTR',
        'PGTLB',
        'EDESB',
        'EPGPC'
      ];
      this.canCancel = !statusBlocked.includes(this.proposalData.statusProposal);
    }
  }

  private verifyCanContinue() {
    this.canContinue = this.proposalData.stProposals.codigoBotaoVitrine === 'CONTINUE-PROPOSAL';
  }

  continueProposal() {
    this.sideContentService.close(true);
    this.router.navigate(['/showcase/payment'], { queryParams: { id: this.proposalId } });
  }
}
