import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { catchError, delay, last, switchMap, switchMapTo, take, tap, withLatestFrom } from 'rxjs/operators';
import { EMPTY, from, merge, of } from 'rxjs';
import { Store } from '@ngrx/store';
import { defaultSelectedFilters, State } from './management-filters.reducer';
import { ManagementFiltersService } from '../services/management-filters.service';
import {
  selectedSubsidiaryState,
  selectedStoreState,
  selectedFiltersState,
  shouldPassFiltersToProposalPanelSelector
} from './management-filters.selectors';

import * as fromTabs from '@app/core/state/tab';
import * as fromProfile from '@features/management-panel/state/profile/';
import * as filterActions from './management-filters.actions';

interface GetFiltersParams {
  activeProfileInfo: {
    isMasterShopkeeper: boolean;
    isGcOrGr: boolean;
  };
}

@Injectable()
export class ManagementFiltersEffects {
  private productsFiltersCall = this.filtrosService.getProductFilters().pipe(
    tap(productFilters => {
      this.store$.dispatch(new filterActions.LoadProductsFiltersSuccess(productFilters));
    }),
    catchError(e => {
      this.store$.dispatch(new filterActions.LoadProductsFiltersFailure(e));
      return EMPTY;
    })
  );

  @Effect()
  loadInitialFilters$ = this.actions$.pipe(
    ofType(filterActions.ManagementFiltersActionTypes.LoadInitialFilters),
    withLatestFrom(
      this.store$.select(fromProfile.selectors.userProfileTypesSelector),
      this.store$.select(shouldPassFiltersToProposalPanelSelector)
    ),
    switchMap(([, activeProfileInfo, shouldPassFiltersToProposalPanel]) => {
      let getInitialFilters = from([new filterActions.FiltersUpToDate(null)]);

      if (!shouldPassFiltersToProposalPanel) {
        getInitialFilters = this.getInitialFreshFilters({ activeProfileInfo });
      }

      return getInitialFilters;
    })
  );

  @Effect({ dispatch: false })
  loadProductsFiltersFailure$ = this.actions$.pipe(
    ofType(filterActions.ManagementFiltersActionTypes.LoadProductsFiltersFailure),
    delay(4000),
    take(6),
    switchMap(() => {
      return this.productsFiltersCall;
    })
  );

  @Effect()
  updateSelectedSubsidiaryFilter$ = this.actions$.pipe(
    ofType(filterActions.ManagementFiltersActionTypes.UpdateSelectedSubsidiaryFilter),
    withLatestFrom(this.store$.select(selectedSubsidiaryState)),
    switchMap(() => {
      return [new filterActions.LoadStoreFilters(''), new filterActions.FiltersUpToDate({})];
    })
  );

  @Effect()
  updateSelectedStoreFilter$ = this.actions$.pipe(
    ofType(filterActions.ManagementFiltersActionTypes.UpdateSelectedStoreFilter),
    switchMap(() => {
      return [
        new filterActions.LoadSubsegmentFilters(),
        new filterActions.LoadCollaboratorsFilters(''),
        new filterActions.FiltersUpToDate({})
      ];
    })
  );

  @Effect()
  loadSubsidiaryFilters$ = this.actions$.pipe(
    ofType(filterActions.ManagementFiltersActionTypes.LoadSubsidiaryFilters),
    switchMap(({ searchTerm }) => {
      const search = searchTerm === 'TODOS' ? '' : searchTerm;

      return this.filtrosService.getSubsidiaryFilters({ search }).pipe(
        switchMap(subsidiariesFilters => {
          return [new filterActions.LoadSubsidiaryFiltersSuccess(subsidiariesFilters)];
        }),
        catchError(e => [new filterActions.LoadSubsidiaryFiltersFailure(e)])
      );
    })
  );

  @Effect()
  loadStoreFilters$ = this.actions$.pipe(
    ofType(filterActions.ManagementFiltersActionTypes.LoadStoreFilters),
    withLatestFrom(this.store$.select(selectedStoreState)),
    switchMap(([{ searchTerm }, selectedStore]) => {
      const search = searchTerm === 'TODOS' ? '' : searchTerm;

      const storeToFilter =
        search !== (selectedStore && selectedStore.dsFornecCanal) ? null : selectedStore.idFornecCanal;

      return this.filtrosService.getStoreFilters({ idShop: storeToFilter || null, search }).pipe(
        switchMap(storeFilters => {
          return [new filterActions.LoadStoreFiltersSuccess(storeFilters)];
        }),
        catchError(e => [new filterActions.LoadStoreFiltersFailure(e)])
      );
    })
  );

  @Effect()
  loadSubsegmentFilters$ = this.actions$.pipe(
    ofType(filterActions.ManagementFiltersActionTypes.LoadSubsegmentFilters),
    switchMap(() => {
      return this.filtrosService.getSubsegmentsFilters().pipe(
        switchMap(subsegmentFilters => {
          return [new filterActions.LoadSubsegmentFiltersSuccess(subsegmentFilters)];
        }),
        catchError(e => [new filterActions.LoadSubsegmentFiltersFailure(e)])
      );
    })
  );

  @Effect()
  loadCollaboratorsFilters$ = this.actions$.pipe(
    ofType(filterActions.ManagementFiltersActionTypes.LoadCollaboratorsFilters),
    switchMap(({ searchTerm }) => {
      const search = searchTerm === 'TODOS' ? '' : searchTerm;

      return this.filtrosService.getCollaboratorsFilters({ search }).pipe(
        switchMap(collaborators => {
          return [new filterActions.LoadCollaboratorsFiltersSuccess(collaborators)];
        }),
        catchError(e => [new filterActions.LoadCollaboratorsFiltersFailure(e)])
      );
    })
  );

  @Effect()
  loadChannelFilters$ = this.actions$.pipe(
    ofType(filterActions.ManagementFiltersActionTypes.LoadChannelFilters),
    switchMap(({ searchTerm }) => {
      const search = searchTerm === 'TODOS' ? '' : searchTerm;

      return this.filtrosService.getChannelFilters({ search }).pipe(
        switchMap(channelFilters => {
          return [new filterActions.LoadChannelFiltersSuccess(channelFilters)];
        }),
        catchError(e => [new filterActions.LoadChannelFiltersFailure(e)])
      );
    })
  );

  @Effect()
  clearAllFilters$ = this.actions$.pipe(
    ofType(filterActions.ManagementFiltersActionTypes.ClearAllFilters),
    withLatestFrom(this.store$.select(fromProfile.selectors.userProfileTypesSelector)),
    switchMap(([, profileInfo]) => {
      const { isGcOrGr } = profileInfo;

      const actions$: any[] = this.getDefaultOptionsActions(isGcOrGr);

      return from([...actions$, new filterActions.FiltersUpToDate({})]);
    })
  );

  @Effect()
  filtersUpToDate$ = this.actions$.pipe(
    ofType(
      filterActions.ManagementFiltersActionTypes.UpdateSelectedSubsegmentFilter,
      filterActions.ManagementFiltersActionTypes.UpdateSelectedCollaboratorFilter,
      filterActions.ManagementFiltersActionTypes.UpdateSelectedChannelFilter,
      filterActions.ManagementFiltersActionTypes.UpdateSelectedProductFilter,
      filterActions.ManagementFiltersActionTypes.UpdateSelectedClientFilter,
      filterActions.ManagementFiltersActionTypes.UpdateSelectedBipartiteFilter
    ),
    withLatestFrom(this.store$.select(selectedFiltersState)),
    switchMap(([action]) => {
      if ((action as any).isDefault) return [];

      return [new filterActions.FiltersUpToDate({})];
    })
  );

  constructor(
    private actions$: Actions<filterActions.ManagementFiltersActions>,
    private readonly store$: Store<State>,
    private readonly filtrosService: ManagementFiltersService
  ) {}

  private getDefaultOptionsActions(isGcOrGr: boolean) {
    let actions$: any[] = [
      new filterActions.UpdateSelectedChannelFilter(defaultSelectedFilters.channel, true),
      new filterActions.UpdateSelectedCollaboratorFilter(defaultSelectedFilters.collaborator, true),
      new filterActions.UpdateSelectedProductFilter(defaultSelectedFilters.product, true),
      new filterActions.UpdateSelectedBipartiteFilter(defaultSelectedFilters.bipartite, true),
      new filterActions.UpdateSelectedClientFilter(defaultSelectedFilters.client, true),
      new filterActions.UpdateSelectedSubsegmentFilter(defaultSelectedFilters.subsegment, true)
    ];

    if (isGcOrGr) {
      actions$ = [
        new filterActions.UpdateSelectedSubsidiaryFilter(defaultSelectedFilters.subsidiary, true),
        new filterActions.UpdateSelectedStoreFilter(defaultSelectedFilters.store, true),

        ...actions$
      ];
    }
    return actions$;
  }

  private getInitialFreshFilters({ activeProfileInfo }: GetFiltersParams) {
    const { isMasterShopkeeper, isGcOrGr } = activeProfileInfo;

    const channelFiltersCall = this.filtrosService.getChannelFilters({ search: '' }).pipe(
      tap(channelFilters => this.store$.dispatch(new filterActions.LoadChannelFiltersSuccess(channelFilters))),
      catchError(e => [new filterActions.LoadChannelFiltersFailure(e)])
    );

    const collaboratorsFiltersCall = this.filtrosService.getCollaboratorsFilters({ search: '' }).pipe(
      tap(collaboratorsFilters =>
        this.store$.dispatch(new filterActions.LoadCollaboratorsFiltersSuccess(collaboratorsFilters))
      ),
      catchError(e => [new filterActions.LoadCollaboratorsFiltersFailure(e)])
    );

    const storeFiltersCall = of([]).pipe(
      withLatestFrom(this.store$.select(fromTabs.selectors.selectSelectedTab)),
      switchMap(([, tab]) => {
        return this.filtrosService.getStoreFilters({ idShop: (tab && +tab.id) || null, search: '' }).pipe(
          tap(storeFilters => {
            this.store$.dispatch(new filterActions.LoadStoreFiltersSuccess(storeFilters));

            if (isMasterShopkeeper && storeFilters && storeFilters.getStores) {
              const filteredStoreArray = storeFilters.getStores.filter(
                ({ idFornecCanal }) => tab && idFornecCanal === tab.id
              );

              let storeToSelect = storeFilters.getStores[0];

              if (filteredStoreArray && filteredStoreArray.length) {
                storeToSelect = filteredStoreArray[0];
              }

              this.store$.dispatch(new filterActions.UpdateSelectedStoreFilter(storeToSelect));
            }
          }),
          catchError(e => [new filterActions.LoadStoreFiltersFailure(e)])
        );
      })
    );

    const subsegmentFiltersCall = this.filtrosService.getSubsegmentsFilters().pipe(
      tap(subsegmentFilters => {
        this.store$.dispatch(new filterActions.LoadSubsegmentFiltersSuccess(subsegmentFilters));
      }),
      catchError(e => [new filterActions.LoadSubsegmentFiltersFailure(e)])
    );

    let initialFiltersArray$: any[] = [
      channelFiltersCall,
      collaboratorsFiltersCall,
      this.productsFiltersCall,
      storeFiltersCall,
      subsegmentFiltersCall
    ];

    if (isGcOrGr) {
      const subsidiaryFilterCall = this.filtrosService.getSubsidiaryFilters({ search: '' }).pipe(
        tap(subsidiaryFilters =>
          this.store$.dispatch(new filterActions.LoadSubsidiaryFiltersSuccess(subsidiaryFilters))
        ),
        catchError(e => [new filterActions.LoadSubsidiaryFiltersFailure(e)])
      );

      initialFiltersArray$ = initialFiltersArray$.concat(subsidiaryFilterCall);
    }

    if (isMasterShopkeeper) {
      const bipartiteFilterCall = of([]).pipe(
        withLatestFrom(this.store$.select(fromTabs.selectors.selectSelectedTab)),
        switchMap(([, tab]) => {
          if (tab) {
            return this.filtrosService.getBipartiteInfoForStore(tab.code).pipe(
              tap((value: any) => {
                const hasBipartite =
                  value &&
                  value.atributoFornecedorCanal &&
                  value.atributoFornecedorCanal.length &&
                  value.atributoFornecedorCanal[0].tipoBipartido;

                this.store$.dispatch(new filterActions.UpdateAvailableBipartiteFilter(!!hasBipartite));
              }),
              catchError(e => {
                this.store$.dispatch(new filterActions.UpdateAvailableBipartiteFilter(false));

                return of(e);
              })
            );
          }

          return of(tab);
        }),
        catchError(e => {
          return of(null);
        }),
        last()
      );

      initialFiltersArray$ = initialFiltersArray$.concat(bipartiteFilterCall);
    }

    const getAllFilters = merge(...initialFiltersArray$).pipe(
      catchError(e => {
        return EMPTY;
      })
    );

    return getAllFilters.pipe(
      last(),
      switchMapTo(
        from([
          ...this.getDefaultOptionsActions(isGcOrGr),
          new filterActions.UpdateShouldPassFiltersToProposalPanel(false, { statusFunil: null, statusPG: null }),
          new filterActions.FiltersUpToDate({})
        ])
      )
    );
  }
}
