import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-material',
  templateUrl: './alertMaterial.component.html',
  styleUrls: ['./alertMaterial.component.scss']
})
export class AlertMaterialComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<AlertMaterialComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}

  public titleModal = this.data.title;
  public textBody = this.data.text;
  public buttonNumber = this.data.numberButton;
  public buttonLabel = this.data.labelButton;

  ngOnInit() {}

  public clicked(label: string) {
    switch (label) {
      case 'Não, fazer uma nova':
        if (this.titleModal === 'Atenção!') {
          this.dialogRef.close();
          break;
        }
        this.titleModal = 'Atenção!';
        this.textBody = `
          Ao criar uma nova proposta as condições e tarifas da simulação
          anterior serão perdidas e não será mais possível recuperá-las.
          Quer mesmo continuar?
        `;
        this.buttonNumber = 2;
        this.buttonLabel = [{ text: 'Sim, confirmar', color: 'warn' }, { text: 'Não, cancelar', color: 'light' }];
        break;
      case 'Sim, recuperar':
        alert('PION-34');
        break;

      case 'Ok':
      case 'OK':
        this.dialogRef.close();
        break;

      case 'Manter proposta':
        this.dialogRef.close();
        break;

      case 'Apagar':
        this.dialogRef.close(true);
        break;

      default:
        break;
    }
  }
}
