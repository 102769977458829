<div class="row">
  <div class="col">
    <p class="title">{{ 'PASSWORD-TITLE' | translate }}</p>
    <p class="subtitle">
      {{ 'PASSWORD-SUBTITLE' | translate }}
    </p>
    <div class="rules-list">
      <ul>
        <li [ngClass]="{
            'has-error': password.errors?.minlength || password.errors?.maxlength,
            'is-ok': password.value !== '' && !password.errors?.maxlength && !password.errors?.minlength
          }">
          {{ 'PASSWORD-RULES-LIST1' | translate }}
        </li>
        <li [ngClass]="{
            'has-error': password.errors?.capitalWord,
            'is-ok': password.value !== '' && !password.errors?.capitalWord
          }">
          {{ 'PASSWORD-RULES-LIST2' | translate }}
        </li>
        <li [ngClass]="{
            'has-error': password.errors?.hasNumbers,
            'is-ok': password.value !== '' && !password.errors?.hasNumbers
          }">
          {{ 'PASSWORD-RULES-LIST3' | translate }}
        </li>
        <li [ngClass]="{
            'has-error': password.errors?.hasSpecialChars,
            'is-ok': password.value !== '' && !password.errors?.hasSpecialChars
          }">
          {{ 'PASSWORD-RULES-LIST4' | translate }}
        </li>
      </ul>
    </div>

    <form [formGroup]="passwordForm" autocomplete="off">
      <div class="col-12 input-row">
        <mat-form-field appearance="legacy">
          <mat-label>Nova senha</mat-label>
          <input matInput [type]="showPassword ? 'text' : 'password'" autocomplete="off" maxlength="10"
            formControlName="password" placeholder="{{ 'PASSWORD-INPUT-NEW-PASSWORD' | translate }}" appTagger
            [event_category]="category" event_label="{{ 'PASSWORD-INPUT-NEW-PASSWORD' | translate }} "
            [event_action]="Insert" />

          <mat-icon *ngIf="capslockOn" class="caps-lock-icon">keyboard_capslock</mat-icon>
          <button mat-icon-button matSuffix [hidden]="password.value === ''" class="show-hide-pass-button"
            (click)="handleShowHidePassword()" [attr.aria-pressed]="showPassword" tabindex="-1">
            <mat-icon>{{ !showPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>
      </div>
      <div class="col-12 input-row">
        <mat-form-field appearance="legacy">
          <mat-label>Confirmar nova senha</mat-label>
          <input matInput [type]="showConfirmPassword ? 'text' : 'password'" maxlength="10" autocomplete="off"
            formControlName="passwordConfirm" placeholder="{{ 'PASSWORD-INPUT-AGAIN-PASSWORD' | translate }}" appTagger
            [event_category]="category" event_label="{{ 'PASSWORD-INPUT-AGAIN-PASSWORD' | translate }}"
            [event_action]="Insert" />

          <mat-icon *ngIf="capslockOn" class="caps-lock-icon">keyboard_capslock</mat-icon>
          <button mat-icon-button matSuffix [hidden]="passwordConfirm.value === ''" class="show-hide-pass-button"
            (click)="handleShowHideConfirmPassword()" [attr.aria-pressed]="showConfirmPassword" tabindex="-1">
            <mat-icon>{{ !showConfirmPassword ? 'visibility_off' : 'visibility' }}</mat-icon>
          </button>
        </mat-form-field>

        <p class="passmsg" *ngIf="passwordConfirm.value !== '' && passwordConfirm.value !== password.value">
          {{ 'PASSWORD-MSG-ERROR' | translate }}
        </p>
      </div>

      <div class="col-12 input-row">
        <button mat-button class="btn" [disabled]="!passwordForm.valid || passwordConfirm.value !== password.value"
          (click)="submit()" mat-raised-button appTagger [event_category]="category"
          event_label="{{ 'PASSWORD-BTN-SAVE-PASSWORD' | translate }}">
          {{ 'PASSWORD-BTN-SAVE-PASSWORD' | translate }}
        </button>
      </div>
    </form>
  </div>
</div>