import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';

export interface DialogData {
  selectedOption: number;
  label?: string;
  isSelected?: boolean;
  seeMore?: boolean;
  category?: any;
}

@Component({
  selector: 'app-insurance-modal',
  templateUrl: './insurance-modal.component.html',
  styleUrls: ['./insurance-modal.component.scss']
})
export class InsuranceModalComponent implements OnInit {
  @ViewChild('containerText') containerText: ElementRef<HTMLElement>;

  constructor(
    public dialogRef: MatDialogRef<InsuranceModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private genTagger: GenTagger
  ) { }

  ngOnInit(): void {
    this.genTagger.setTag({
      event_category: this.data.category,
      event_action: 'abriu modal - proteção financeira',
      event_label: this.containerText?.nativeElement?.innerText
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  closeModal(): void {
    this.onNoClick();
  }
}
