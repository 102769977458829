<div class="canvas-container">
  <canvas #sigPad (touchstart)="onTouchDown($event)" (touchmove)="onTouchMove($event)"> </canvas>
  <div class="canvas-overlay">{{ 'ESIGN-SIGNATURE-PAD-INSTRUCTION' | translate }}</div>
</div>

<div
  class="w-100 delete-full"
  (click)="clear()"
  appTagger
  [event_category]="category"
  event_label="{{ 'ESIGN-SIGNATURE-PAD-CLEAN-FULL' | translate }}"
>
  <img src="./assets/images/ic_delete.svg" alt="Delete signature icon" />
  {{ 'ESIGN-SIGNATURE-PAD-CLEAN-FULL' | translate }}
</div>

<div
  class="save"
  [ngClass]="{ 'save-no__signature': !haveSignature }"
  (click)="save()"
  appTagger
  [event_category]="category"
  event_label="{{ 'ESIGN-SIGNATURE-PAD-SAVE' | translate }}"
>
  <img src="./assets/images/ic_circle_check.svg" alt="Delete signature icon" />
  <p>{{ 'ESIGN-SIGNATURE-PAD-SAVE' | translate }}</p>
</div>

<div
  class="delete-short"
  (click)="clear()"
  appTagger
  [event_category]="category"
  event_label="{{ 'ESIGN-SIGNATURE-PAD-CLEAN-SHORT' | translate }}"
>
  <img src="./assets/images/ic_delete.svg" alt="Delete signature icon" />
  <p>{{ 'ESIGN-SIGNATURE-PAD-CLEAN-SHORT' | translate }}</p>
</div>

<div class="w-100 pt-3 justify-content-center signature-pad-footer">
  <div class="row w-100">
    <div class="col-md-6 pr-0 pl-0 w-100">
      <button
        class="btn btn-modal-confirm btn-principal-sm"
        (click)="save()"
        [disabled]="!haveSignature"
        appTagger
        [event_category]="category"
        event_label="{{ 'ESIGN-SIGNATURE-PAD-CONTINUE' | translate }}"
      >
        {{ 'ESIGN-SIGNATURE-PAD-CONTINUE' | translate }}
      </button>
    </div>
  </div>
</div>
