<div class="table-responsive">
  <table class="table table-borderless table-responsive-md table--proposals">
    <thead>
      <th scope="col" class="text-nowrap"></th>
      <th scope="col" class="text-nowrap" (click)="onSort('Tipo')">
        {{ 'TYPE' | translate }}
        <img src="./assets/images/{{ ordenationIcons.type }}.svg" class="ml-2" (click)="order('type')" />
      </th>
      <th scope="col" class="text-nowrap" (click)="onSort('Expira em')">
        {{ 'EXPIRES_IN' | translate }}
        <img src="./assets/images/{{ ordenationIcons.proposalValidityDate }}.svg" class="ml-2"
          (click)="order('proposalValidityDate')" />
      </th>
      <th scope="col" class="text-nowrap col-status" (click)="onSort('Status')">
        {{ 'Status' | translate }}
        <img src="./assets/images/{{ ordenationIcons.status }}.svg" class="ml-2" (click)="order('status')" />
      </th>
      <th scope="col" class="text-nowrap" (click)="onSort('Valor financiado')">
        {{ 'FINANCED_AMOUNT' | translate }}
        <img src="./assets/images/{{ ordenationIcons.valueFinancing }}.svg" class="ml-2"
          (click)="order('valueFinancing')" />
      </th>
      <th scope="col" class="text-nowrap" colspan="3" (click)="onSort('Cliente')">
        {{ 'CLIENT' | translate }}
        <img src="./assets/images/{{ ordenationIcons.clientName }}.svg" class="ml-2" (click)="order('clientName')" />
      </th>
    </thead>
    <tbody>
      <tr *ngFor="let proposal of proposals; let i = index">
        <td class="text-nowrap">
          <img *ngIf="proposal.iconOpenFinanceOrPreviousProposal === 'openFinance' && !!openFinanceEnabled"
            src="./assets/images/ok-santander.svg" width="20" alt="icon" />
          <img *ngIf="proposal.iconOpenFinanceOrPreviousProposal === 'previousProposal' && !!openFinanceEnabled"
            src="./assets/images/tooltip-payment-col.svg" width="20" alt="icon"
            matTooltip="{{ 'OPEN-FINANCE-DESCRIPTION-NEW-PROPOSAL' | translate }}" matTooltipClass="icon"
            matTooltipHideDelay="10000" />
          <img *ngIf="proposal.bipartido" src="./assets/santander/icons/ic_bipartition.svg" alt="icon bipartido"
            width="20" matTooltipClass="icon" matTooltip="A proposta é composta por um ou mais parceiros."
            matTooltipHideDelay="100000" />
        </td>
        <td class="text-nowrap">
          <span *ngIf="proposal.type === 'CDC'" class="badge badge-cdc">{{ proposal.type }}</span>
          <span *ngIf="proposal.type === 'CSC'" class="badge badge-csc">{{ proposal.type }}</span>
          <span *ngIf="proposal.type === 'CSCP'" class="badge badge-cscp">{{ proposal.type }}</span>
        </td>
        <td class="text-nowrap">
          <img src="./assets/santander/icons/ic_clock.svg" alt="icon urgent" [hidden]="!proposal.showClock"
            class="icon-period mr-2" />

          <img [src]="
              proposal.expiringDays > 10
                ? './assets/santander/icons/ic_clock_yellow.svg'
                : './assets/santander/icons/ic_clock_red.svg'
            " alt="icon urgent" class="icon-period mr-2" [hidden]="proposal.showClock" />

          <div class="swap-text">
            <span class="swap-text-item" *ngIf="proposal.expiringDays <= 5 || proposal.showClock">
              {{ !proposal.showClock ? proposal.expiringDays : '0' }}
              {{ proposal.expiringDays === 1 && !proposal.showClock ? 'dia' : 'dias' }}
            </span>
            <span class="swap-text-item width-text">{{ proposal.date | date: 'dd/MM/yyyy' }}</span>
            <span class="swap-text-item" *ngIf="proposal.expiringDays > 5 && !proposal.showClock">
              {{ proposal.expiringDays }} dias
            </span>
          </div>
        </td>
        <td>
          <img src="./assets/santander/icons/{{ proposal.statusIcon }}" alt="icon" class="icon-status" />
          <span class="user-name">{{ proposal.statusDescription }}</span>
          <img *ngIf="!!proposal.statusText" src="./assets/images/tooltip-payment-col.svg" alt="icon"
            class="icon-tooltip ml-1" matTooltip="{{ proposal.statusText }}" matTooltipClass="icon"
            matTooltipHideDelay="10000" />
        </td>
        <td class="text-nowrap">
          <div class="swap-text">
            <span class="swap-text-item">{{ proposal.value | currency: 'BRL' }}</span>
            <span class="swap-text-item">
              <small>{{ proposal.amountParcels }}x de {{ proposal.valueParcels | currency: 'BRL' }}</small>
            </span>
          </div>
        </td>
        <td class="text-nowrap">
          <img src="./assets/santander/icons/ic_star.svg" alt="icon star" class="icon-star"
            ngbTooltip="A proposta foi iniciada por um cliente e é uma venda potencial."
            *ngIf="!proposal.sellerAssociate" />
          <div class="swap-text">
            <span class="swap-text-item">{{ proposal.user }}</span>
            <span class="swap-text-item" *ngIf="proposal.documentNumber.length === 11">
              {{ proposal.documentNumber | mask: '000.000.000-00' }}
            </span>
            <span class="swap-text-item" *ngIf="proposal.documentNumber.length === 14">
              {{ proposal.documentNumber | mask: '00.000.000/0000-00' }}
            </span>
          </div>
        </td>
        <td class="text-nowrap">
          <button class="btn btn-action btn-proposal" type="button" (click)="onHandleProposalActionBtn(proposal)"
            appTagger event_category="/portallojista/propostas"
            event_label="{{ proposal.button | translate }} - proposta {{ proposal.id }}">
            <img src="./assets/santander/icons/{{ proposal.iconStatusProposal }}" alt="icon urgent"
              class="icon-status" />
            {{ proposal.button | translate }}
          </button>
        </td>
        <td class="text-nowrap">
          <button class="btn-kebab" mat-icon-button [matMenuTriggerFor]="menu" aria-label="Menu de opções"
            [disabled]="!proposal.kebab.length">
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onKebabAction(kebab.code, proposal.id)" *ngFor="let kebab of proposal.kebab">
              <span>{{ kebab.description }}</span>
            </button>
          </mat-menu>
        </td>
      </tr>
    </tbody>
  </table>
</div>
