import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Contract } from '@app/contracts/model/contract.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contract-card',
  templateUrl: './contract-card.component.html',
  styleUrls: ['./contract-card.component.scss']
})
export class ContractCardComponent implements OnInit, OnChanges {
  @Input() data: Contract;
  @Output() actionClick: EventEmitter<any> = new EventEmitter();
  public tooltipText = '';
  public progressPercentage: number;

  constructor(public translate: TranslateService) {}

  ngOnInit() {
    console.log(this.data);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && this.data) {
      // const rate = (Math.round(this.data.rate * 100) / 100).toFixed(2);
      // const iof = (Math.round(this.data.iof * 100) / 100).toFixed(2);
      // const cet = (Math.round(this.data.cet * 100) / 100).toFixed(2);
      // this.progressPercentage = (this.data.currentInstallment / this.data.totalInstallments) * 100;
      // this.tooltipText = `${this.translate.instant('CONTRACT-TOOLTIP-TEXT', { rate, iof, cet })}`;
    }
  }

  public handleClick(): void {
    this.actionClick.emit(this.data);
  }
}
