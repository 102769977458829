import { SignUpService } from './../../../signup/services/sign-up.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';

import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
// NGRX stuff
import * as challenge from '@app/core/state/challenge';
// Enums
import { Countries } from '@shared/constants/countries.enum';
import { environment } from '@env/environment';
import { AppState } from '@app/core/state';
import { HeaderService } from '@shared/components/ui/header/services/header.service';
import { Location } from '@angular/common';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';

enum ChallengeStatus {
  RETRY = 'RETRY',
  REPROVED = 'REPROVED'
}

@Component({
  selector: 'app-recovery-token',
  templateUrl: './recovery-token.component.html',
  styleUrls: ['./recovery-token.component.scss']
})
export class RecoveryTokenComponent implements OnInit, OnDestroy {
  public tokenExpirationTime = environment.timerSecondsRecoveryPassword;
  public tokenDestination: string;
  public tokenDestinationMask: { [key: string]: string };
  public currentCountry = this.internationalizationService.currentCountry;
  public pioneerMasks = this.internationalizationService.pioneerMasks;
  public countries = Countries;
  public getChallenge: any;

  public title;
  public subTitle;
  public personLegalEntity: boolean;

  challengeStatus = ChallengeStatus;

  readonly category = '/portallojista/recuperarsenha/token';

  constructor(
    private store$: Store<AppState>,
    private internationalizationService: InternationalizationService,
    private router: Router,
    private headerService: HeaderService,
    private signUpService: SignUpService,
    public loc: Location,
    public genTagger: GenTagger
  ) {}

  ngOnInit() {
    this.store$.dispatch(new challenge.actions.GetChallenge({ refresh: false }));

    this.genTagger.setTag({
      event_category: this.category + '',
      event_action: Tag.pgView,
      event_label: 'Recuperar senha'
    });

    this.store$.pipe(select('challenge')).subscribe(res => {
      this.getChallenge = res.getChallenge;

      const wrongCode = res.answerChallenge && res.answerChallenge.status === 'RETRY';

      this.challengeStatus = res.answerChallenge && res.answerChallenge.status;

      console.log(this.challengeStatus);
      console.log(res.answerChallenge);

      this.signUpService.statusWrongCode$.next(wrongCode);

      this.personLegalEntity = res.legalEntity;
    });
  }

  public sendToken(token: string): void {
    this.store$.dispatch(new challenge.actions.AnswerChallenge({ token: { skip: false, answer: token } }));
    // this.router.navigate(['/signup/biometry']);
  }

  public resendToken(type: string): void {
    if (type === 'resendToken') {
      this.store$.dispatch(new challenge.actions.GetChallenge({ refresh: true }));
    } else {
      const countryCanRedirect =
        environment.currentCountry === Countries.BR ||
        environment.currentCountry === Countries.CO ||
        environment.currentCountry === Countries.PE;

      if (this.personLegalEntity && countryCanRedirect) {
        this.store$.dispatch(new challenge.actions.AnswerChallenge({ token: { skip: true, answer: '' } }));
      } else {
        this.headerService.setGobackUrl(this.router.url);
        this.router.navigate(['/login/recovery/help']);
      }
    }
  }

  ngOnDestroy(): void {}
}
