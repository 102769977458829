import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { CustomerTypeEnum } from '@app/proposal-workflow/models/step-register-put.enum';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TranslateService } from '@ngx-translate/core';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { BrazilMasks } from '@shared/interfaces/masks/brazil-masks.interface';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { isValidRgOrCpf } from '@shared/validators/brazil.validators';
import { DeviceInfo } from 'ngx-device-detector';
import { CarouselComponent, OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { Subject } from 'rxjs';
import { delay, filter } from 'rxjs/operators';
import { ChecklistItemClientDTO } from '../../../checklist/models/api/query.checklist.model';
import { AgentLegalPersonDTO } from '../../models/api/post.agent-legal.model';
import { ClientBiometryStatusEnum } from '../../models/biometry.model';
import { BiometryService, BiometryStageEnum } from '../../services/biometry.service';

@Component({
  selector: 'app-agent-legal',
  templateUrl: './agent-legal.component.html',
  styleUrls: ['./agent-legal.component.scss']
})
export class AgentLegalComponent implements OnInit, OnChanges {
  protected ngUnsubscribe: Subject<any> = new Subject();

  public form: FormGroup;
  public nameClient: string;
  public phoneMask: string;
  public phoneMaskAgentAdditional: string;
  public actionAgent = false;
  public qtdAgents = 2;
  public isReturningToEdit = false;
  public isConfirmQtdAgent = false;
  public additionalAgentList: FormArray;
  public masks = this.internationalizationService.pioneerMasks as BrazilMasks;
  public readonly customPatterns = { 0: { pattern: /\d/ }, 9: { pattern: /\d/ } };

  @Input() currentPlatform: { deviceInfo: DeviceInfo; isMobile: boolean; isDesktop: boolean };
  @Input() proposal: ConfirmationData;
  @Input() clientList: ChecklistItemClientDTO[];
  @Output() agentsLegalEmitter: EventEmitter<{
    agentsLegal: AgentLegalPersonDTO[];
    isReturningToEdit: boolean;
  }> = new EventEmitter();
  @Output() fluxAgentMobile: EventEmitter<boolean> = new EventEmitter();
  @Output() singleAgentLegalEmitter: EventEmitter<ChecklistItemClientDTO> = new EventEmitter();
  @Output() goToInstructionsEmitter: EventEmitter<void> = new EventEmitter();
  @Output() noAdditionalAgentsEmitter: EventEmitter<boolean> = new EventEmitter();

  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;

  public carouselOptions: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    autoHeight: false,
    responsive: {
      0: {
        items: 1
      }
    },
    nav: false
  };
  @ViewChild('agentsCarousel') carousel: CarouselComponent;
  public hidePrev = true;
  public hideNext = false;
  public nextForm = 0;
  public showInfoMessageFlag = false;
  public productType = ProductCodeEnum;

  @Input() category: string;
  readonly Insert: Tag = Tag.Insert;
  constructor(
    private internationalizationService: InternationalizationService,
    private formBuilder: FormBuilder,
    private simulationService: SimulationService,
    private biometryService: BiometryService,
    private genTagger: GenTagger,
    private translateService: TranslateService
  ) {
    this.buildForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.proposal && this.proposal) {
      // this.nameClient = this.proposal.personalData.fullName;
      this.simulationService.getProposalClients(this.proposal.proposalData.id).subscribe(r => {
        const client = r.clients.find(f => !f.mainFlag);
        this.nameClient = client ? client.person.name : '';
      });
    }

    if (!!this.clientList && changes.clientList && (this.clientList.length > 1 || this.hasOnlyOlrOnCSC())) {
      this.actionAgent = true;
      this.handleChecklistChange();
    }
  }

  ngOnInit() {
    this.handlePhoneMask();
  }

  private buildForm(): void {
    this.form = this.formBuilder.group({
      additionalAgentList: this.formBuilder.array([this.createAgentAdditional()])
    });
  }

  private hasOnlyOlrOnCSC(): boolean {
    if (
      (this.clientList.length > 0 &&
      this.proposal &&
      this.proposal.proposalData &&
      this.proposal.proposalData.product === this.productType.CSC) ||
      (this.clientList.length > 0 &&
        this.proposal &&
        this.proposal.proposalData &&
        this.proposal.proposalData.product === this.productType.CSCP)
    ) {
      return this.clientList.every(client => client.type === CustomerTypeEnum.OPTIONAL_LEGAL_REPRESENTATIVE);
    }
    return false;
  }

  public createAgentAdditional(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(null),
      name: new FormControl(null, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^[a-zA-ZÀ-ÿ]{2,}(?: [a-zA-ZÀ-ÿ]+){1,}$/)
      ]),
      documentNumber: new FormControl({ value: null, disabled: false }, [
        Validators.required,
        Validators.minLength(11),
        Validators.maxLength(11),
        isValidRgOrCpf
      ]),
      email: new FormControl(null, [Validators.required, Validators.email]),
      cellPhone: new FormControl({ value: null, disabled: false }, [
        Validators.required,
        Validators.maxLength(11),
        Validators.minLength(11)
      ])
    });
  }

  private handlePhoneMask(): void {
    this.form
      .get('additionalAgentList')
      ['controls'][0].controls.cellPhone.valueChanges.pipe(
        delay(1),
        filter(val => !!val)
      )
      .subscribe(value => {
        if (value.length > 10) {
          if (value[0] === '0' || value[2] === '0' || value.length < 11) {
            this.form.get('additionalAgentList')['controls'][0].controls.cellPhone.setErrors({ incorrect: true });
          } else {
            this.form.get('additionalAgentList')['controls'][0].controls.cellPhone.setErrors(null);
          }
        }

        if (value.length < 11) {
          this.phoneMask = this.masks.phone.mainPhoneMask;
        } else {
          this.phoneMask = this.masks.phone.mask;
        }
      });
  }

  private handlePhoneMaskAgentAdditional(): void {
    this.form
      .get('additionalAgentList')
      ['controls'][1].controls.cellPhone.valueChanges.pipe(
        delay(2),
        filter(val => !!val)
      )
      .subscribe(value => {
        if (value[0] === '0' || value[2] === '0' || value.length < 11) {
          this.form.get('additionalAgentList')['controls'][1].controls.cellPhone.setErrors({ incorrect: true });
        } else {
          this.form.get('additionalAgentList')['controls'][1].controls.cellPhone.setErrors(null);
        }

        if (value.length < 11) {
          this.phoneMaskAgentAdditional = this.masks.phone.mainPhoneMask;
        } else {
          this.phoneMaskAgentAdditional = this.masks.phone.mask;
        }
      });
  }

  public handleChecklistChange() {
    if (this.isReturningToEdit) return;
    this.clientList = [...this.clientList].sort((a, b) => +a.id - +b.id);

    const agentsLegal = this.clientList.filter(
      client => client.type === CustomerTypeEnum.OPTIONAL_LEGAL_REPRESENTATIVE
    );

    for (let i = 0; i < agentsLegal.length - 1; i++) {
      this.addAgent();
    }

    this.form.get('additionalAgentList').patchValue(agentsLegal);
    const controls = this.form.get('additionalAgentList')['controls'];
    for (let j = 0; j < controls.length; j++) {
      controls[j].get('name').disable();
      controls[j].get('documentNumber').disable();
      // controls[j].get('cellPhone').disable();
    }

    const agentSigned = agentsLegal.filter(client => client.biometryStatus === ClientBiometryStatusEnum.DONE);
    controls.forEach(control => {
      agentSigned.forEach(agent => {
        if (control.get('id').value === agent.id) control.get('email').disable();
      });
    });

    this.isReturningToEdit = true;
    if (this.isReturningToEdit) this.isConfirmQtdAgent = true;
  }

  public noAdditionalAgents() {
    if (this.currentPlatform.isMobile) {
      this.singleAgentLegalEmitter.emit(this.clientList[0]);
      this.goToInstructionsEmitter.emit();
      return;
    }

    if (!this.clientList.length) {
      this.goToInstructionsEmitter.emit();
    }
    this.noAdditionalAgentsEmitter.emit(true);

    this.biometryService.setBiometryStage(BiometryStageEnum.QR_CODE);
  }

  public initializeAgents(value) {
    this.actionAgent = value;
    this.form.get('additionalAgentList').reset();
    document.getElementById('biometry').scrollIntoView({ behavior: 'smooth', block: 'start' });
    if (this.currentPlatform.isMobile) this.fluxAgentMobile.emit(true);
  }

  public addAgent() {
    this.additionalAgentList = this.form.get('additionalAgentList') as FormArray;
    this.additionalAgentList.push(this.createAgentAdditional());
    this.handlePhoneMaskAgentAdditional();
  }

  public deleteAgentAdditional() {
    this.additionalAgentList = this.form.get('additionalAgentList') as FormArray;
    const index = this.additionalAgentList.length - 1;
    this.additionalAgentList.removeAt(index);
  }

  private filterNotChangedAgent() {
    return this.form.getRawValue().additionalAgentList.filter(agent => {
      return !this.clientList.find(item => {
        return (
          item.id === agent.id &&
          item.name === agent.name &&
          item.email === agent.email &&
          item.documentNumber === agent.documentNumber &&
          item.phoneDDD === agent.cellPhone.slice(0, 2) &&
          item.phoneNumber === agent.cellPhone.slice(2, agent.cellPhone.length)
        );
      });
    });
  }

  public signature() {
    const agents = this.filterNotChangedAgent().map(agent => {
      const agentValue = {
        documentNumber: agent.documentNumber,
        email: agent.email,
        id: agent.id,
        name: agent.name
      };
      return {
        ...agentValue,
        phoneDDD: agent.cellPhone.slice(0, 2),
        phoneNumber: agent.cellPhone.slice(2, agent.cellPhone.length)
      };
    });
    console.log('::::::AGENTS::::: ', agents);
    this.agentsLegalEmitter.emit({
      agentsLegal: agents,
      isReturningToEdit: this.isReturningToEdit
    });
  }

  changeOnSlider(slider: SlidesOutputData) {
    if (slider.slides[0] === undefined) return;
    if (slider.startPosition === 1 && this.form.get('additionalAgentList')['controls'][0].valid) {
      this.nextStepCarousel();
    }
    if (slider.startPosition === 0) this.prevStepCarousel();
  }

  confirmQtdAgent(confirm) {
    this.isConfirmQtdAgent = confirm;
    this.form.get('additionalAgentList').reset();
    document.getElementById('biometry').scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  nextStepCarousel() {
    this.carousel.next();
    this.nextForm = 1;
  }

  prevStepCarousel() {
    this.carousel.prev();
    this.nextForm = 0;
  }

  public showInfoMessage(): boolean {
    if (
      (this.currentPlatform.isDesktop && this.form.controls.additionalAgentList['controls'].length > 0) ||
      (this.currentPlatform.isMobile && this.isConfirmQtdAgent)
    ) {
      this.showInfoMessageTagging();
      return true;
    }

    return false;
  }

  private showInfoMessageTagging(): void {
    if (!this.showInfoMessageFlag) {
      this.showInfoMessageFlag = true;
      this.genTagger.setTag({
        page_location: this.category,
        page_section: this.translateService.instant('FORMALIZATION-STEP-MSG-LINK'),
        event_element: 'a-href',
        event_label: this.translateService.instant('FORMALIZATION-STEP-MSG-LINK'),
        event_action: Tag.AbriuModal
      });
    }
  }

  public onRpoChange(index: number, key: string) {
    this.genTagger.setTag({
      event_action: Tag.Insert,
      event_category: this.category,
      event_label: `​representante ${++index} - ${key}}`
    });
  }

  public onDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
