import gql from 'graphql-tag';

export const GET_BIPARTITE_PARTNERS = gql`
  query listarGruposFornecedoresCanal($proposta: Int!) {
    listarGruposFornecedoresCanal(proposta: $proposta) {
      general {
        idAtributoFornecedorCanal
        idFornecedorCanal
        cdFornecedorCanal
        nmFornecCanal
        dataValidadeIni
      }
      biPartyRelationship {
        fornecedorCanalAtributosParceiros {
          idFornecedorCanalPrimario
          idFornecedorCanalSecundario
          cdFornecedorCanal
          nmFornecCanalPrimario
          nmFornecCanalSecundario
          cnpjFornecCanalSecundario
          idFornecRelac
          statusRelacionamento
          dsStatusRelacionamento
          percentualFixoBiPartido
        }
      }
      proposalData {
        fgBipartido
        bipartiteType
        totalValueFinanc
        channelSupplierId
        proposalId
      }
    }
  }
`;
