import { ActionReducerMap } from '@ngrx/store';

import { AlertState } from './alert.state';
import { AlertActionsTypes, AlertActions } from './alert.actions';

export function alertsReducer(state = [], action: AlertActions) {
  switch (action.type) {
    case AlertActionsTypes.ADD_ALERT_SUCCESS: {
      return [...state, action.payload];
    }

    case AlertActionsTypes.REMOVE_ALERT_SUCCESS: {
      return state.filter(a => action.payload !== a.id);
    }

    default:
      return state;
  }
}

/**
 * Reducer Map
 */

export const reducerMap: ActionReducerMap<AlertState> = {
  alerts: alertsReducer
};
