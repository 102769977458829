import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CalendarConfig } from './calendar.model';

@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {
  @Input() public calendarConfig: CalendarConfig;
  @Input() public closeCalendar = false;
  @Input() public customClass = '';
  @Output() public navAction = new EventEmitter();
  @Output() public closeCalendarE = new EventEmitter();

  minDate: moment.Moment;
  maxDate: moment.Moment;

  public arrMonths = [
    { dayMonth: 1, month: this.translate.instant('JANUARY') },
    { dayMonth: 2, month: this.translate.instant('FEBRUARY') },
    { dayMonth: 3, month: this.translate.instant('MARCH') },
    { dayMonth: 4, month: this.translate.instant('APRIL') },
    { dayMonth: 5, month: this.translate.instant('MAY') },
    { dayMonth: 6, month: this.translate.instant('JUNE') },
    { dayMonth: 7, month: this.translate.instant('JULY') },
    { dayMonth: 8, month: this.translate.instant('AUGUST') },
    { dayMonth: 9, month: this.translate.instant('SEPTEMBER') },
    { dayMonth: 10, month: this.translate.instant('OCTOBER') },
    { dayMonth: 11, month: this.translate.instant('NOVEMBER') },
    { dayMonth: 12, month: this.translate.instant('DECEMBER') }
  ];
  public arrWeeks = [
    { shortWeek: this.translate.instant('SUNDAY-SHORT'), longWeek: this.translate.instant('SUNDAY') },
    { shortWeek: this.translate.instant('MONDAY-SHORT'), longWeek: this.translate.instant('MONDAY') },
    { shortWeek: this.translate.instant('TUESDAY-SHORT'), longWeek: this.translate.instant('TUESDAY') },
    { shortWeek: this.translate.instant('WEDNESDAY-SHORT'), longWeek: this.translate.instant('WEDNESDAY') },
    { shortWeek: this.translate.instant('THURSDAY-SHORT'), longWeek: this.translate.instant('THURSDAY') },
    { shortWeek: this.translate.instant('FRIDAY-SHORT'), longWeek: this.translate.instant('FRIDAY') },
    { shortWeek: this.translate.instant('SATURDAY-SHORT'), longWeek: this.translate.instant('SATURDAY') }
  ];
  public arrYears = [];

  public numberMonth = 0;
  public count = 0;

  public fullCalendar = [];
  public calendarByYears = [];
  public calendarByYear: any;

  public yearSelected: number;

  public isExpanded = false;

  public titleButton: string;

  public d = new Date();
  public date = '';
  public dateRange = { startDate: 'dd / mm / aaaa', endDate: 'dd / mm / aaaa' };

  constructor(public dialog: MatDialog, private translate: TranslateService) {}

  ngOnInit() {
    this.defineYearsList();
    this.defineMonthsList();

    if (this.calendarConfig.minDate) {
      this.minDate = this.calendarConfig.minDate;
    }
    if (this.calendarConfig.maxDate) {
      this.maxDate = this.calendarConfig.maxDate;
    }

    if (this.calendarConfig.isDateRange) this.titleButton = this.translate.instant('SELECT-PERIOD');
    else this.titleButton = this.translate.instant('SAVE');

    this.calendarByYear = this.calendarByYears.find((calendar: any) => calendar.year === moment().year());
    this.yearSelected = this.calendarByYear.year;
    this.calendarByYear = this.calendarByYear.months;

    // default
    this.calendarConfig = {
      titleMobile: this.calendarConfig.titleMobile || '',
      subTitleMobile: this.calendarConfig.subTitleMobile || '',
      startYear: this.calendarConfig.startYear || null,
      endYear: this.calendarConfig.endYear || null,
      isTruncateYears: this.calendarConfig.isTruncateYears || false,
      isTruncateDays: this.calendarConfig.isTruncateDays || { toBack: false, toFront: false },
      isDateRange: this.calendarConfig.isDateRange || false
    };
  }

  /**
   * defined according to DOM action
   * @param action
   */
  public action(action: string): void {
    if (action === 'back') {
      this.navAction.emit(action);
    } else if (action === 'saveDate') {
      if (this.calendarConfig.isDateRange) {
        if (this.dateRange.endDate === 'dd / mm / aaaa') return;
        this.navAction.emit(this.dateRange);
      } else {
        if (!this.date && this.dateRange.endDate === 'dd / mm / aaaa') return;
        return this.navAction.emit(this.date || this.dateRange.endDate !== 'dd / mm / aaaa');
      }
    }
  }

  public jumpDayWeek(year: number, month: number, day = 1): Array<string> {
    const jumpDay = [];
    const dayAtWeek = new Date(year, month - 1, day).getDay();

    for (let i = 0; i < dayAtWeek; i++) jumpDay.push('');

    return jumpDay;
  }

  /**
   * optional: makes a month comparation if omited
   * @param day
   */
  public dateComparePastDays(year: string, month: string, day?: string): boolean {
    const curYear = new Date().getFullYear();
    const curMonth = new Date().getMonth();
    const curDay = new Date().getDate();
    const curDate = moment(`${curYear}-${curMonth + 1}-${curDay}`);

    const lastDay = this.getLastDay(Number(year), Number(month));

    if (!(this.minDate && this.maxDate)) {
      if (this.calendarConfig.isTruncateDays.toBack) {
        return moment(`${year}-${month}-${day || lastDay}`).isSameOrBefore(curDate);
      } else if (this.calendarConfig.isTruncateDays.toFront) {
        return moment(`${year}-${month}-${day || lastDay}`).isSameOrAfter(curDate);
      }
    } else {
      if (this.calendarConfig.isTruncateDays.toBack) {
        const dateNow: any = moment(`${year}-${month}-${day || lastDay}`, 'YYYY-MM-DD');
        return moment(dateNow).isSameOrBefore(this.minDate);
      }
    }
  }

  public dateCompareAfterDays(year: string, month: string, day?: string): boolean {
    const lastDay = this.getLastDay(Number(year), Number(month));
    const dateNow: any = moment(`${year}-${month}-${day || lastDay}`, 'YYYY-MM-DD');

    if (this.maxDate && this.calendarConfig.isTruncateDays.toBack) {
      return moment(dateNow).isAfter(this.maxDate);
    }
  }

  public getLastDay(year: number, month: number): number {
    return new Date(year, month, 0).getDate();
  }

  public getCurDate(d: Date): string {
    const date = d.toLocaleDateString();

    const year = date.substring(5, 9);
    const month = date.substring(0, 1);
    const day = date.substring(2, 4);

    return `${day.length < 2 ? '0' + day : day}/${month.length < 2 ? '0' + month : month}/${year}`;
  }

  public markPeriodDate(day: string, month: string, year: string): boolean {
    const date = `${year}-${month}-${day}`;

    if (this.dateRange.endDate === 'dd / mm / aaaa') return;
    return (
      moment(date).isSameOrAfter(moment(this.getDateFormat(this.dateRange.startDate))) &&
      moment(date).isSameOrBefore(moment(this.getDateFormat(this.dateRange.endDate))) &&
      day !== ''
    );
  }

  public getDate(day: string, month: string, year: string): void {
    const strDay = day.toString().length < 2 ? '0' + day : day;
    const strMonth = month.toString().length < 2 ? '0' + month : month;
    const date = `${strDay}/${strMonth}/${year}`;

    if (this.calendarConfig.isDateRange) {
      if (this.count === 0) this.dateRange.startDate = date;
      if (
        this.count === 1 &&
        moment(`${year}-${month}-${day}`).isAfter(moment(this.getDateFormat(this.dateRange.startDate)))
      ) {
        this.dateRange.endDate = date;
      } else {
        this.dateRange.startDate = date;
        this.dateRange.endDate = 'dd / mm / aaaa';
        this.count = 0;
      }

      if (this.count === 2) {
        this.dateRange.startDate = date;
        this.dateRange.endDate = 'dd / mm / aaaa';
        this.count = 0;
      } else this.count++;
    } else {
      this.date = date;
    }

    this.isExpanded = false;
  }

  public defineCalendar(jumpDays: Array<string>, month: number, year: number): void {
    const limitDay = this.getLastDay(year, month);
    const days: number[] = [];
    const calendar = { descMonth: '', numberMonth: 0, days: [] };

    for (let day = 1; day <= limitDay; day++) days.push(day);

    calendar.days.push(...jumpDays, ...days);
    calendar.numberMonth = this.numberMonth;
    calendar.descMonth = this.arrMonths[this.numberMonth - 1].month;

    this.fullCalendar.push({ ...calendar });
  }

  public expandedToggle(): void {
    this.isExpanded = !this.isExpanded;
  }

  public searchByYear(idxYears: number): void {
    this.expandedToggle();
    this.yearSelected = this.calendarByYears[idxYears].year;
    this.calendarByYear = this.calendarByYears[idxYears].months;
    this.isExpanded = true;
  }

  public defineYearsList(): void {
    if (this.calendarConfig.isTruncateYears) {
      for (let i = 0; i < 3; i++) this.arrYears.push(moment().year() + i);
    } else {
      const startYear = this.calendarConfig.startYear || moment().year() - 5;
      const endYear = this.calendarConfig.endYear || moment().year() + 5;

      for (let i = startYear; i <= endYear; i++) this.arrYears.push(i);
    }
  }

  public defineMonthsList(): void {
    let sumMonth = 12;
    this.arrYears.forEach((year: number, i: number) => {
      for (let month = 1; month <= 12; month++) {
        this.numberMonth = month;
        this.defineCalendar(this.jumpDayWeek(year, month), month, year);
      }

      this.calendarByYears.push({ year, months: this.fullCalendar.slice(12 * i, sumMonth) });
      sumMonth += 12;
    });
  }

  public startEndDate(id: string, day: string, month: string, year: string): boolean {
    const date = `${year}-${month}-${day}`;

    if (this.dateRange.endDate === 'dd / mm / aaaa' || this.dateRange.startDate === this.dateRange.endDate) return;
    if (id === 'startDate') {
      return moment(date).isSame(moment(this.getDateFormat(this.dateRange.startDate)));
    } else if (id === 'endDate') {
      return moment(date).isSame(moment(this.getDateFormat(this.dateRange.endDate)));
    }
  }

  public clearDate(): void {
    this.dateRange.startDate = 'dd / mm / aaaa';
    this.dateRange.endDate = 'dd / mm / aaaa';
  }

  public closeDialogCalendar(): void {
    this.dialog.closeAll();
    this.closeCalendarE.emit();
  }

  public markedUnderlineDate(ref: string): boolean {
    if (!this.calendarConfig.isDateRange) return;
    if (ref === 'startDate') {
      return this.dateRange.startDate === 'dd / mm / aaaa';
    } else if (ref === 'endDate') {
      return this.dateRange.startDate !== 'dd / mm / aaaa' && this.dateRange.endDate === 'dd / mm / aaaa';
    }
  }

  /**
   * date to be converted to standard 'YYYY / MM / DD'
   * @param document
   */
  public getDateFormat(document: string): moment.Moment {
    return moment(
      `
      ${document.substring(6, 10)}-
      ${document.substring(3, 5)}-
      ${document.substring(0, 2)}
      `
    );
  }
}
