<div class="disconnected-container">
  <header class="disconnected-header">
    <img src="./assets/images/logo_santander_mobile.svg" alt="Santander" />
  </header>
  <div [@routerTransition] class="text-center disconnected-body container-fluid justify-content-between">
    <img src="./assets/images/santander_icon_outline.svg" width="114px" alt="Santander" />
    <p class="mt-4">
      {{ message | translate }}
    </p>
  </div>
</div>
