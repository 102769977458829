import { Component, EventEmitter, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FinancialStatementService } from '../../services/financial-statement.service';
import { Observable } from 'rxjs';
import {  map } from 'rxjs/operators';
import { AppState } from '@app/core/state';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-statement-download-file-type-dialog-component',
  templateUrl: './statement-download-file-type-dialog-component.component.html',
  styleUrls: ['./statement-download-file-type-dialog-component.component.scss']
})
export class StatementDownloadFileTypeDialogComponentComponent  {
  public readonly category = '/portallojista/pagamentos/detalhe/extrato';
  public optionSelected = 'csv';
  shopId$: Observable<number> = this.store$.select('tabs').pipe(map(tab => tab.selectedTab.id));
  @Output() optionSelectedChange: EventEmitter<any> = new EventEmitter();

  constructor(
    public activeModal: NgbActiveModal,
    private store$: Store<AppState>,
    private financialStatementService: FinancialStatementService,
    ) { }

  downloadExtract(optionSelected){
  this.optionSelected = optionSelected
  this.activeModal.close()
  }

  public valueSelected(event){
    this.optionSelected = event.value;
    this.optionSelectedChange.emit(this.optionSelected);
  }
}

