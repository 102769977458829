import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { SignUpState } from '@app/signup/state/signup.reducer';
import { Store } from '@ngrx/store';
import * as fromSignUp from '../../state';

@Component({
  selector: 'app-sign-up-container',
  templateUrl: './sign-up.container.component.html',
  styleUrls: ['./sign-up.container.component.scss']
})
export class SignUpContainer implements OnInit, OnDestroy {
  constructor(private store$: Store<SignUpState>) {}

  public innerWidth: any;
  public MOBILE_BREAKPOINT = 768;

  ngOnInit() {
    this.innerWidth = window.screen.width;
    this.store$.dispatch(new fromSignUp.actions.GetSignUpRenderDataAction());
  }

  ngOnDestroy() {
    this.store$.dispatch(new fromSignUp.actions.ResetSignUpDataAction());
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.screen.width;
  }
}
