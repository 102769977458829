import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
// import { Observable } from 'rxjs';
import { AppState } from '@app/core/state';
import * as profileActions from '@app/core/state/profile/profile.actions';
import { homePageSelector } from '@app/core/state/profile/profile.selectors';
import { selectSelectedTab } from '@app/core/state/tab/tab.selectors';
import { InitialScreenOptions } from '@app/profile/interface/initial-screen-options.enum';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-set-initial-screen',
  templateUrl: './set-initial-screen.component.html',
  styleUrls: ['./set-initial-screen.component.scss']
})
export class SetInitialScreenComponent implements OnInit {
  /**
   * Error message that can be displayed to help the user with the form
   */
  validation_messsage = {
    initialScreen: [{ type: 'required', message: 'Por favor, selecione uma opção' }]
  };

  initialScreenForm: FormGroup;

  /**
   * Initial screen options enum
   */
  initialScreenOptions = InitialScreenOptions;

  /**
   * Data send inside ChangeInitialScreen action payload.
   */
  supplierChannelCode: string;
  // supplierChannelCode$: Observable<string>;

  /**
   * UI control
   */
  showUpdateSuccess = false;

  public categoria;
  readonly Selecionar: Tag = Tag.Selecionar;
  public initialScreenName = 'Definir tela inicial';

  constructor(
    private _formBuilder: FormBuilder,
    private store$: Store<AppState>,
    private genTagger: GenTagger,
    private translate: TranslateService
  ) {}

  get eventActionModal(): ExtendedTag {
    return {
      event_action_complement: 'Definir tela inicial',
      event_action_type: Tag.AbriuModal
    };
  }

  ngOnInit() {
    this.store$
      .select(selectSelectedTab)
      .pipe(
        filter(data => !!data),
        take(1)
      )
      .subscribe(supplierChannelData => {
        this.supplierChannelCode = supplierChannelData.code;
      });

    // this.supplierChannelCode$ =  this.store$.select(selectSelectedTab)

    this.store$
      .select(homePageSelector)
      .pipe(
        filter(data => !!data),
        take(1)
      )
      .subscribe(defaultHomePage => {
        this.setInitialScreenForm(defaultHomePage);
      });

    this.categoria = window.location.href;
    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: this.eventActionModal,
      event_label: 'definir tela inicial'
    });
  }

  setInitialScreenForm(defaultHomePage: string) {
    this.initialScreenForm = this._formBuilder.group({
      initialScreen: new FormControl(defaultHomePage, Validators.required)
    });
  }

  /**
   * Allows a form control to be accessed in a less verbose way in HTML,
   * i.e, initialScreenFormData.initialScreen.errors.required
   */
  get initialScreenFormData() {
    return this.initialScreenForm.controls;
  }

  setSelectedOption(selectedScreen: string) {
    console.log(this.initialScreenFormData.initialScreen);
    this.initialScreenForm.value.initialScreen = selectedScreen;
  }

  /**
   * Submit form method
   */
  sendInitialScreenOption() {
    const payload = {
      supplierChannelCode: this.supplierChannelCode,
      homePage: this.initialScreenForm.value.initialScreen
    };
    this.store$.dispatch(new profileActions.ChangeInitialScreen(payload));
    // REFACTOR: - Tratar caso erro ou sucesso
    this.showUpdateSuccess = true;
  }

  public menuClick(opcaoSelecionada) {
    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: 'Selecionou - tela inicial',
      event_label: this.translate.instant(opcaoSelecionada)
    });
  }
}
