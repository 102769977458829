import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-tutorial-modal',
  templateUrl: './tutorial-modal.component.html',
  styleUrls: ['./tutorial-modal.component.scss']
})
export class TutorialModalComponent implements OnInit {
  isNeverAsk = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<TutorialModalComponent>) {}

  ngOnInit() {}

  close(): void {
    this.dialogRef.close({ isNeverAsk: this.isNeverAsk, startTutorial: false });
  }

  handleTutorial() {
    this.dialogRef.close({ isNeverAsk: this.isNeverAsk, startTutorial: true });
  }
}
