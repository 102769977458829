import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  private pinchZoomEventsCache: PointerEvent[] = [];
  private prevDiff = -1;
  public currentZoom: BehaviorSubject<number> = new BehaviorSubject(100);

  constructor() {}

  public handlePointerMove(event: PointerEvent): void {
    this.pinchZoomEventsCache.forEach((cachedEvent, i) => {
      if (event.pointerId === cachedEvent.pointerId) {
        this.pinchZoomEventsCache[i] = event;
      }
    });

    if (this.pinchZoomEventsCache.length === 2) {
      const currDiff = Math.abs(this.pinchZoomEventsCache[0].clientX - this.pinchZoomEventsCache[1].clientX);

      if (this.prevDiff > 0) {
        const currentZoomValue = this.currentZoom.value;

        if (currDiff > this.prevDiff) {
          // Pinch is moving out -> ZOOM IN
          this.currentZoom.next(currentZoomValue + 1);
        }

        if (currDiff < this.prevDiff) {
          // Pinch is moving in -> ZOOM OUT
          this.currentZoom.next(currentZoomValue - 1);
        }
      }

      this.prevDiff = currDiff;
      console.log(this.currentZoom.value);
    }
  }

  public handlePointerDown(event: PointerEvent): void {
    this.pinchZoomEventsCache.push(event);
  }

  public handlePointerUp(event: PointerEvent): void {
    this.pinchZoomEventsCache.forEach((cachedEvent, i) => {
      if (cachedEvent.pointerId === event.pointerId) {
        this.pinchZoomEventsCache.splice(i, 1);
      }
    });

    if (this.pinchZoomEventsCache.length < 2) {
      this.resetZoom();
    }
  }

  private resetZoom(): void {
    this.pinchZoomEventsCache = [];
    this.prevDiff = -1;
    this.currentZoom.next(50);
  }
}
