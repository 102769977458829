import { createSelector } from '@ngrx/store';
import { AppState } from '@app/core/state';

export const notificationSelector = (state: AppState) => state.notifications;

export const selectAllNotifications = createSelector(
  notificationSelector,
  notification => notification.notifications
);

export const selectIsAllRead = createSelector(
  notificationSelector,
  notification => {
    return !(
      notification.notifications.newData.some(n => !n.isRead) || notification.notifications.oldData.some(n => !n.isRead)
    );
  }
);
