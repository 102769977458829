<div class="table-responsive">
  <table class="table table-borderless table-responsive-md table--payments">
    <thead>
      <th scope="col" class="text-nowrap"></th>
      <th scope="col" class="text-nowrap">
        {{ 'TYPE' | translate }}
        <img src="./assets/images/{{ ordenationIcons.type }}.svg" class="ml-2" (click)="order('type')" />
      </th>
      <th scope="col" class="text-nowrap col-date">
        {{ 'EXPIRES_IN' | translate }}
        <img
          src="./assets/images/{{ ordenationIcons.proposalValidityDate }}.svg"
          class="ml-2"
          (click)="order('proposalValidityDate')"
        />
      </th>
      <th scope="col" class="text-nowrap col-status">
        {{ 'Status' | translate }}
        <img src="./assets/images/{{ ordenationIcons.status }}.svg" class="ml-2" (click)="order('status')" />
      </th>
      <th scope="col" class="text-nowrap">
        {{ 'FINANCED_AMOUNT' | translate }}
        <img
          src="./assets/images/{{ ordenationIcons.valueFinancing }}.svg"
          class="ml-2"
          (click)="order('valueFinancing')"
        />
      </th>
      <th scope="col" class="text-nowrap" colspan="3">
        {{ 'CLIENT' | translate }}
        <img src="./assets/images/{{ ordenationIcons.clientName }}.svg" class="ml-2" (click)="order('clientName')" />
      </th>
    </thead>

    <tbody>
      <tr *ngFor="let payment of listPayments; let i = index">
        <td class="text-nowrap table-list-checkbox">
          <mat-checkbox
            color="primary"
            [(ngModel)]="payment.checked"
            (ngModelChange)="onUpdateCheckSelectAll()"
            appTagger
            [event_category]="categoria"
            event_label="proposta - {{ payment.id }}"
            [event_action]="Selecionar"
          ></mat-checkbox>
          <img
            *ngIf="payment.bipartido"
            src="./assets/santander/icons/ic_bipartition.svg"
            class="img-bipartido"
            alt="icon bipartido"
            width="20"
            matTooltipClass="icon"
            matTooltip="A proposta é composta por um ou mais parceiros."
            matTooltipHideDelay="100"
          />
        </td>

        <td class="text-nowrap">
          <span *ngIf="payment.type === 'CDC'" class="badge badge-cdc">{{ payment.type }}</span>
          <span *ngIf="payment.type === 'CSC'" class="badge badge-csc">{{ payment.type }}</span>
          <span *ngIf="payment.type === 'CSCP'" class="badge badge-cscp">{{ payment.type }}</span>
        </td>

        <td class="text-nowrap">
          <img
            src="./assets/santander/icons/ic_clock.svg"
            alt="icon urgent"
            [hidden]="!payment.showClock"
            class="icon-period mr-2"
          />

          <img
            [src]="
              payment.expiringDays > 10
                ? './assets/santander/icons/ic_clock_yellow.svg'
                : './assets/santander/icons/ic_clock_red.svg'
            "
            alt="icon urgent"
            class="icon-period mr-2"
            [hidden]="payment.showClock"
          />
          <div class="swap-text">
            <span class="swap-text-item" [hidden]="payment.showClock" *ngIf="payment.expiringDays <= 5">
              {{ payment.expiringDays }} {{ payment.expiringDays === 1 ? 'dia' : 'dias' }}
            </span>
            <span class="swap-text-item width-text">{{ payment.date | date: 'dd/MM/yyyy' }}</span>
            <span class="swap-text-item" [hidden]="payment.showClock" *ngIf="payment.expiringDays > 5">
              {{ payment.expiringDays }} dias
            </span>
            <span class="swap-text-item" [hidden]="!payment.showClock">0 dias</span>
          </div>
        </td>

        <td>
          <img src="./assets/santander/icons/{{ payment.statusIcon }}" alt="icon" class="icon-status" />
          <span class="user-name">{{ payment.statusDescription }}</span>
          <img
            *ngIf="!!payment.statusText"
            src="./assets/images/tooltip-payment-col.svg"
            alt="icon"
            class="icon-tooltip ml-1"
            matTooltip="{{ payment.statusText }}"
            matTooltipClass="custom-tooltip-proposal"
            matTooltipHideDelay="100"
          />
        </td>

        <td class="text-nowrap">
          <div class="swap-text">
            <span class="swap-text-item">{{ payment.value | currency: 'BRL' }}</span>
            <span class="swap-text-item">
              <small>{{ payment.amountParcels }}x de {{ payment.valueParcels | currency: 'BRL' }}</small>
            </span>
          </div>
        </td>

        <td class="text-nowrap">
          <img
            src="./assets/santander/icons/ic_star.svg"
            alt="icon star"
            class="icon-star"
            ngbTooltip="A proposta foi iniciada por um cliente e é uma venda potencial."
            *ngIf="!payment.sellerAssociate"
          />
          <div class="swap-text">
            <span class="swap-text-item">{{ payment.user }}</span>
            <span class="swap-text-item" *ngIf="payment.documentNumber.length === 11">
              {{ payment.documentNumber | mask: '000.000.000-00' }}
            </span>
            <span class="swap-text-item" *ngIf="payment.documentNumber.length === 14">
              {{ payment.documentNumber | mask: '00.000.000/0000-00' }}
            </span>
          </div>
        </td>

        <td class="text-nowrap">
          <button
            class="btn btn-action btn-payment"
            type="button"
            (click)="onButtonListOrKebabAction(payment.button, payment.id)"
          >
            <img src="./assets/santander/icons/{{ payment.iconStatusPayment }}" alt="icon urgent" class="icon-status" />
            {{ payment.button | translate }}
          </button>
        </td>

        <td class="text-nowrap table-list-menu">
          <button
            class="btn-kebab"
            mat-icon-button
            [matMenuTriggerFor]="menu"
            aria-label="Options menu"
            [disabled]="!payment.kebab.length"
            appTagger
            [event_category]="categoria"
            event_label="opções - proposta"
          >
            <mat-icon>more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button
              mat-menu-item
              (click)="onButtonListOrKebabAction(kebab.code, payment.id)"
              *ngFor="let kebab of payment.kebab"
            >
              <span>{{ kebab.description }}</span>
            </button>
          </mat-menu>
        </td>
      </tr>
    </tbody>
  </table>
</div>
