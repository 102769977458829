import {
  AdditionalSignUpData,
  AddressSignUpData,
  ClientSignUpData,
  ProposalSignUpData,
  TravelerSignUpData
} from '@app/proposal-workflow/models/proposal-data.model';
import { Action } from '@ngrx/store';
import { ProposalInformation } from './proposal-information.reducer';

export enum ProposalInformationTypes {
  SET_PROPOSAL_DATA = '[Set] Set Proposal data section',

  SET_PERSONAL_DATA_SECTION = '[Set] Set Personal data section',
  SET_PERSONAL_DATA_SECTION_SUCCESS = '[Set] Set Personal data section Success',
  SET_ADDRESS_DATA_SECTION = '[Set] Set Address data section',
  SET_ADDRESS_DATA_SECTION_SUCCESS = '[Set] Set Address data Success',

  SET_COMPANY_DATA_SECTION = '[Set] Set Company data section',
  SET_COMPANY_DATA_SECTION_SUCESS = '[Set] Set Company data section Success',
  SET_COMPANY_REPRESENTANT_DATA_SECTION = '[Set] Set Company Representant data section',
  SET_COMPANY_REPRESENTANT_DATA_SECTION_SUCCESS = '[Set] Set Company Representant data section success',
  SET_COMPANY_REPRESENTANT_ADDRESS_DATA_SECTION = '[Set] Set Company Representant Address data section',
  SET_COMPANY_REPRESENTANT_ADDRESS_DATA_SECTION_SUCCESS = '[Set] Set Company Representant Address data section success',
  SET_TRAVELER_DATA_SECTION = '[Set] Set Traveler data section',
  SET_TRAVELER_DATA_SECTION_SUCCESS = '[Set] Set Traveler data section Success',
  SET_OBSERVATION_DATA_SECTION = '[Set] Set Observation data section',

  GET_SIMULATION_PROPOSAL_DATA = '[Get] Get Simulaion Proposal data',
  GET_SIMULATION_PROPOSAL_DATA_SUCCESS = '[Get] Get Simulaion Proposal data success',
  GET_SIMULATION_PROPOSAL_DATA_ERROR = '[Get] Get Simulaion Proposal data error',

  GET_INSURANCE = '[Get] Get insurance simulation',
  GET_INSURANCE_SUCCESS = '[Get] Get insurance simulation success',

  SET_CONFIRMED_IN_CASH_TARIFF = '[Set] Confirmed In Cash Tariff',
  SET_CONFIRMED_EXEMPTION_REQUEST_TRIFF = '[Set] Confirmed Exemption Request Tariff',

  GET_CONFIRMATION_DATA = '[Get] Get Confirmation data',
  GET_CONFIRMATION_DATA_SUCCESS = '[Get] Get Confirmation data success',
  GET_CONFIRMATION_DATA_ERROR = '[Get] Get Confirmation data error',

  SAVE_CONFIRMATION_DATA = '[Save] Save Confirmation data',

  CLEAR_PROPOSAL_INFORMATION = '[Clear] Clear Proposal Information',

  GET_STAGE_PROPOSAL = '[Register] Get Stage Proposal',
  SET_STAGE_PROPOSAL = '[Register] Set stage proposal'
}

export class SetProposalData implements Action {
  readonly type = ProposalInformationTypes.SET_PROPOSAL_DATA;
  constructor(public payload: ProposalSignUpData) {}
}

export class GetInsurance implements Action {
  readonly type = ProposalInformationTypes.GET_INSURANCE;
  constructor(public payload: any) {}
}

export class GetInsuranceSuccess implements Action {
  readonly type = ProposalInformationTypes.GET_INSURANCE_SUCCESS;
  constructor(public payload: any) {}
}

export class SetPersonalDataSection implements Action {
  readonly type = ProposalInformationTypes.SET_PERSONAL_DATA_SECTION;
  constructor(public payload: ClientSignUpData) {}
}

export class SetPersonalDataSectionSucess implements Action {
  readonly type = ProposalInformationTypes.SET_PERSONAL_DATA_SECTION_SUCCESS;
  constructor(public payload: ClientSignUpData) {}
}

export class SetAddressDataSection implements Action {
  readonly type = ProposalInformationTypes.SET_ADDRESS_DATA_SECTION;
  constructor(public payload: AddressSignUpData[]) {}
}
export class SetAddressDataSectionSuccess implements Action {
  readonly type = ProposalInformationTypes.SET_ADDRESS_DATA_SECTION_SUCCESS;
  constructor(public payload: AddressSignUpData[]) {}
}

export class SetCompanyDataSection implements Action {
  readonly type = ProposalInformationTypes.SET_COMPANY_DATA_SECTION;
  constructor(public payload: ClientSignUpData) {}
}

export class SetCompanyDataSectionSuccess implements Action {
  readonly type = ProposalInformationTypes.SET_COMPANY_DATA_SECTION_SUCESS;
  constructor(public payload: ClientSignUpData) {}
}

export class SetCompanyRepresentantDataSection implements Action {
  readonly type = ProposalInformationTypes.SET_COMPANY_REPRESENTANT_DATA_SECTION;
  constructor(public payload: ClientSignUpData) {}
}
export class SetCompanyRepresentantDataSectionSuccess implements Action {
  readonly type = ProposalInformationTypes.SET_COMPANY_REPRESENTANT_DATA_SECTION_SUCCESS;
  constructor(public payload: ClientSignUpData) {}
}

export class SetCompanyRepresentantAddressDataSection implements Action {
  readonly type = ProposalInformationTypes.SET_COMPANY_REPRESENTANT_ADDRESS_DATA_SECTION;
  constructor(public payload: AddressSignUpData[]) {}
}
export class SetCompanyRepresentantAddressDataSectionSuccess implements Action {
  readonly type = ProposalInformationTypes.SET_COMPANY_REPRESENTANT_ADDRESS_DATA_SECTION_SUCCESS;
  constructor(public payload: AddressSignUpData[]) {}
}

export class SetTravelerDataSection implements Action {
  readonly type = ProposalInformationTypes.SET_TRAVELER_DATA_SECTION;
  constructor(public payload: { listTravelers: TravelerSignUpData[]; travelData: string }) {}
}

export class SetTravelerDataSectionSuccess implements Action {
  readonly type = ProposalInformationTypes.SET_TRAVELER_DATA_SECTION_SUCCESS;
  constructor(public payload: TravelerSignUpData[]) {}
}

export class SetObservationDataSection implements Action {
  readonly type = ProposalInformationTypes.SET_OBSERVATION_DATA_SECTION;
  constructor(public payload: AdditionalSignUpData) {}
}

export class GetSimulationProposalData implements Action {
  readonly type = ProposalInformationTypes.GET_SIMULATION_PROPOSAL_DATA;
  constructor(public payload: number | string) {}
}

export class GetSimulationProposalDataSuccess implements Action {
  readonly type = ProposalInformationTypes.GET_SIMULATION_PROPOSAL_DATA_SUCCESS;
  constructor(public payload: string) {}
}

export class GetSimulationProposalDataError implements Action {
  readonly type = ProposalInformationTypes.GET_SIMULATION_PROPOSAL_DATA_ERROR;
  constructor(public payload: any) {}
}

export class GetConfirmationData implements Action {
  readonly type = ProposalInformationTypes.GET_CONFIRMATION_DATA;
  constructor(public payload: string) {}
}

export class GetConfirmationDataSuccess implements Action {
  readonly type = ProposalInformationTypes.GET_CONFIRMATION_DATA_SUCCESS;
  constructor(public payload: string) {}
}

export class GetConfirmationDataError implements Action {
  readonly type = ProposalInformationTypes.GET_CONFIRMATION_DATA_ERROR;
  constructor(public payload: string) {}
}

export class ClearProposalInformationAction implements Action {
  readonly type = ProposalInformationTypes.CLEAR_PROPOSAL_INFORMATION;
  constructor() {}
}

export class SetConfirmedInCashTariff implements Action {
  readonly type = ProposalInformationTypes.SET_CONFIRMED_IN_CASH_TARIFF;
  constructor(public payload: any) {}
}

export class SetConfirmedExemptionRequestTariff implements Action {
  readonly type = ProposalInformationTypes.SET_CONFIRMED_EXEMPTION_REQUEST_TRIFF;
  constructor(public payload: any) {}
}

export class SaveConfirmationData implements Action {
  readonly type = ProposalInformationTypes.SAVE_CONFIRMATION_DATA;
  constructor(public payload: any) {}
}

export class GetStageProposal implements Action {
  readonly type = ProposalInformationTypes.GET_STAGE_PROPOSAL;
}

export class SetStageProposal implements Action {
  readonly type = ProposalInformationTypes.SET_STAGE_PROPOSAL;
  constructor(public payload: ProposalInformation['stageProposal']) {}
}

export type ProposalInformationAction =
  | SetProposalData
  | SetPersonalDataSection
  | SetPersonalDataSectionSucess
  | SetAddressDataSection
  | SetAddressDataSectionSuccess
  | SetCompanyDataSection
  | SetCompanyDataSectionSuccess
  | SetCompanyRepresentantDataSection
  | SetCompanyRepresentantDataSectionSuccess
  | SetCompanyRepresentantAddressDataSection
  | SetCompanyRepresentantAddressDataSectionSuccess
  | SetTravelerDataSection
  | SetTravelerDataSectionSuccess
  | SetObservationDataSection
  | GetSimulationProposalData
  | GetSimulationProposalDataSuccess
  | GetSimulationProposalDataError
  | GetInsurance
  | GetInsuranceSuccess
  | GetConfirmationData
  | GetConfirmationDataSuccess
  | GetConfirmationDataError
  | SetConfirmedInCashTariff
  | SetConfirmedExemptionRequestTariff
  | ClearProposalInformationAction
  | GetStageProposal
  | SetStageProposal;
