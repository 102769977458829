import { Action } from '@ngrx/store';

export enum PeriodFilterActionTypes {
  GET_SELECTED_PERIOD = '[Period Filter] Get selected period filter',
  SET_SELECTED_PERIOD = '[Period Filter] Set selected period filter'
}

export class GetSelectedPeriodFilter implements Action {
  readonly type = PeriodFilterActionTypes.GET_SELECTED_PERIOD;
}

export class SetSelectedPeriodFilter implements Action {
  readonly type = PeriodFilterActionTypes.SET_SELECTED_PERIOD;
  constructor(public payload) {}
}

export type PeriodFilterActions = GetSelectedPeriodFilter | SetSelectedPeriodFilter;
