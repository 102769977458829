import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@app/core/state';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { PROPOSALS_NEW_QUERY } from '@shared/graphql/queries/proposals.query';
import { DocumentTypeService } from '@shared/services/document-type/document-type.service';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { Apollo } from 'apollo-angular';
import { ExecutionResult } from 'apollo-link';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { GetPanelProposalListDTOResponse } from '../models/api/get.proposal-list.model';
import { GetProposalListResDTO } from './../models/api/query.proposal-list.model';
import { StatusProposals } from './../models/proposal.enum';
import { ProposalModel } from './../models/proposal.model';

const proposalsMock = {
  data: [
    {
      id: 1,
      statusType: 'pending',
      statusText: 'Pendente documento',
      value: 51125,
      amountParcels: 4,
      valueParcels: 100,
      user: 'José Dias',
      documentNumber: '06292570988',
      date: '22/04',
      expiringDays: 1,
      type: 'CDC',
      bipartido: true
    },
    {
      id: 2,
      statusType: 'progress',
      statusText: 'Proposta em andamento',
      value: 33500,
      amountParcels: 4,
      valueParcels: 100,
      user: 'Paulo Carvalho',
      documentNumber: '05064122255',
      date: '12/05',
      expiringDays: 4,
      type: 'CDC',
      bipartido: false
    },
    {
      id: 3,
      statusType: 'formalization',
      statusText: 'Proposta em formalização',
      value: 251223,
      amountParcels: 4,
      valueParcels: 100,
      user: 'Luiz Silva Santos',
      documentNumber: '06588877740',
      date: '23/04',
      expiringDays: 4,
      type: 'CDC',
      bipartido: false
    },
    {
      id: 4,
      statusType: 'analysis',
      statusText: 'Análise documental',
      value: 96330,
      amountParcels: 4,
      valueParcels: 100,
      user: 'Marcos Ricardo Abreu',
      documentNumber: '01033595866',
      date: '24/04',
      expiringDays: 4,
      type: 'CSC',
      bipartido: false
    },
    {
      id: 5,
      statusType: 'pending',
      statusText: 'Tarifa pendente',
      value: 96330,
      amountParcels: 4,
      valueParcels: 100,
      user: 'Marcos Ricardo Abreu',
      documentNumber: '01033595866',
      date: '24/04',
      expiringDays: 4,
      type: 'CSC',
      bipartido: false
    },
    {
      id: 6,
      statusType: 'cancelled',
      statusText: 'Proposta cancelada',
      value: 96330,
      amountParcels: 4,
      valueParcels: 100,
      user: 'Marcos Ricardo Abreu',
      documentNumber: '01033595866',
      date: '24/04',
      expiringDays: 4,
      type: 'CSC',
      bipartido: false
    },
    {
      id: 7,
      statusType: 'expired',
      statusText: 'Proposta expirada',
      value: 96330,
      amountParcels: 4,
      valueParcels: 100,
      user: 'Marcos Ricardo Abreu',
      documentNumber: '01033595866',
      date: '24/04',
      expiringDays: 4,
      type: 'CSC',
      bipartido: false
    }
  ],
  page: 1,
  perPage: 5,
  totalElements: 7,
  totalPages: 1
};

const proposalFiltersMock = {
  data: [
    {
      name: 'Todas propostas',
      amount: 147
    },
    {
      name: 'Proposta em andamento',
      icon: 'ic_warning_orange',
      amount: 47
    },
    {
      name: 'Proposta em negociação',
      icon: 'ic_clock',
      amount: 22
    },
    {
      name: 'Proposta em formalização',
      icon: 'ic_warning_orange',
      amount: 41
    },
    {
      name: 'Análise documental',
      icon: 'ic_clock',
      amount: 18
    },
    {
      name: 'Documentação pendente',
      icon: 'ic_warning_red',
      amount: 32
    },
    {
      name: 'Validação de documento',
      icon: 'ic_clock',
      amount: 53
    },
    {
      name: 'Proposta em análise',
      icon: 'ic_clock',
      amount: 41
    },
    {
      name: 'Proposta a expirar',
      icon: 'ic_warning_red',
      amount: 50
    },
    {
      name: 'Proposta cancelada',
      icon: 'ic_close',
      amount: 47
    },
    {
      name: 'Proposta negada',
      icon: 'ic_close',
      amount: 47
    },
    {
      name: 'Pagamento disponível',
      icon: 'ic_check_green',
      amount: 47
    }
  ]
};

const proposalDetailMock = {
  additionalInformation: {},
  cet: 0,
  creationDate: '2020-01-01',
  document: '51061769000126',
  entryValue: 2086.48,
  expirationDate: '2020-01-31',
  expirationDays: -490,
  financingValue: 18778.31,
  installmentAmount: '36',
  installmentValue: 925.47,
  installmentTooltip: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
  nameClient: 'CLIENTE 119321',
  nameCreation: 'VEND 1 - CVC SHOPPING MORUMBI',
  openValue: false,
  paymentExpirationDate: '2020-01-31',
  planCustom: false,
  productCode: 'CDC',
  proposalGroups: [
    {
      color: 'BLANK',
      groupName: 'CADASTRO'
    }
  ],
  proposalId: 123,
  splitFlow: [
    /*{
      document: "string",
      value: 0,
      percentage: 0
    }*/
  ],
  status: {
    button: 'SHOW-DETAILS',
    description: 'Proposta paga',
    icon: 'ic_check.svg',
    tooltip: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
  },
  contract: [
    {
      UUID: 'BLANK'
    }
  ]
};

const mapOpenProposals = (response: ExecutionResult): ProposalModel[] => {
  if (!response.data) return;
  const res = response.data as GetProposalListResDTO;

  const proposalList = res.proposalsList.edges.map(edge => edge.node);

  return proposalList as ProposalModel[];
  /*
  const prop = proposals.map(proposal => {
    const colorsMap = proposal.proposalGroups.map(group => {
      return {
        color: ProposalStatusGroup[group.color].icon,
        groupName: group.groupName
      };
    });

    proposal = {
      ...proposal,
      btnLabel: proposal.stProposals.codigoBotaoVitrine,
      btnAction: proposal.stProposals.codigoBotaoVitrine,
      statusLevel: proposal.stProposals.grupoStProposta.toString(),
      statusIcon: proposal.stProposals.codigoIconeVitrine,
      proposalGroups: colorsMap
    };

    return proposal;
  });

  return prop;*/
};

@Injectable({
  providedIn: 'root'
})
export class OpenProposalService {
  restartOrdenation$: BehaviorSubject<boolean> = new BehaviorSubject(null);
  restartOrdenation = this.restartOrdenation$.asObservable();

  constructor(
    private sessionStorageService: SessionStorageService,
    private apollo: Apollo,
    private documentService: DocumentTypeService,
    private http: HttpClient,
    private store$: Store<AppState>
  ) {}

  getFilterProposals(): Observable<any> {
    return of(proposalFiltersMock);
  }

  getProposals(storeId, page, perPage, filters): Observable<GetPanelProposalListDTOResponse> {
    return this.getProposalsByFilter(storeId, page, perPage, filters);
  }

  getProposalsByFilter(storeId, page, perPage, filters): Observable<GetPanelProposalListDTOResponse> {
    let sort: string = null;
    if (filters.sort) {
      const sortLength = filters.sort.length - 1;
      sort = `${filters.sort[sortLength].sort}:${filters.sort[sortLength].ordenation}`;
    }
    let payload = {
      pageable: {
        page,
        perPage,
        sort
      },
      shopId: storeId,
      ...filters
    };

    if (filters.salesman) {
      payload = {
        ...payload,
        seller: filters.salesman.toString()
      };
    }

    return this.http.post<GetPanelProposalListDTOResponse>(
      `${environment.apiRootUrl}/proposalpanel/1.0.0/listProposals`,
      payload
    );
  }

  getSummary(shopId, filters): Observable<any> {
    let payload = {
      pageable: {
        page: 1,
        perPage: 99999
      },
      shopId: shopId,
      ...filters
    };

    if (filters.salesman) {
      payload = {
        ...payload,
        seller: filters.salesman.toString()
      };
    }

    return this.http.post(`${environment.apiRootUrl}/proposalpanel/1.0.0/status/count`, payload);
  }

  // Listagem de vendedores
  getSellers(shopId, search): Observable<any> {
    const path = search === '' ? '' : `?search=${search}`;
    return this.http.get(`${environment.apiRootUrl}/proposalpanel/1.0.0/commercialUnit/${shopId}/vendors` + path);
  }

  public getOpenProposals(): Observable<any> {
    return this.store$.select('profile').pipe(
      switchMap(profile => {
        return this.documentService
          .getDocumentTypeByNumber(profile.userProfile.documentNumber)
          .pipe(map(res => ({ ...res, ...profile })));
      }),
      switchMap(profile => {
        return this.apollo
          .watchQuery<any>({
            query: PROPOSALS_NEW_QUERY,
            variables: {
              document: profile.userProfile.documentNumber,
              documentType: profile.documentId,
              companyCode: environment.businessCode
            },
            errorPolicy: 'all',
            fetchPolicy: 'no-cache'
          })
          .valueChanges.pipe(map((response: ExecutionResult) => mapOpenProposals(response)));
      })
    );
  }

  public getPendingProposals(): Observable<any> {
    const statusProposals = StatusProposals;
    const user = this.sessionStorageService.getUser();
    return this.apollo.watchQuery<any>({
      query: PROPOSALS_NEW_QUERY,
      variables: {
        nosalesman: true,
        customerDocument: user.document,
        statusCodes: [
          statusProposals.PROPOSAL_IN_FORMALIZATION,
          statusProposals.PENDING_DOCUMENT,
          statusProposals.PROPOSAL_IN_PROGRESS
        ]
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    }).valueChanges;
  }

  getCancelReasons(): Observable<any> {
    const queryString = 'nomeConteudoAdicional1=LOJISTA';
    return this.http.get(environment.apiRootUrl + `/sistema/1.0.0/dominios?per_page=20&page=1&codigoTabela=541`).pipe(
      switchMap((response: any) => {
        return this.http
          .get(
            environment.apiRootUrl +
              `/sistema/1.0.0/dominios/${response.dominios[0].idDominio}/conteudos?${queryString}`
          )
          .pipe(
            map((res: any) => {
              return res.dominiosconteudos;
            })
          );
      })
    );
  }

  cancelProposal(proposalId: number, reasonId: number, reasonDetails: string): Observable<any> {
    const payload = {
      proposalId: proposalId.toString(),
      channel: '2',
      cancelId: reasonId.toString(),
      reasonDetails
    };

    return this.http.post(`${environment.apiRootUrl}/proposalpanel/1.0.0/proposal/cancel`, payload);
  }

  public getProposalDetailMock(id: number): Observable<any> {
    return of(proposalDetailMock);
  }

  public restartOrdenationAfterCleanFilters(value: boolean) {
    this.restartOrdenation$.next(value);
  }
}
