import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router, RouterEvent } from '@angular/router';
import { AppState } from '@app/core/state';
import * as fromFormalization from '@app/core/state/formalization';
import * as fromProposal from '@app/core/state/proposal';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import { selectSubmitResponse } from '@app/core/state/showcase/showcase.selectors';
import * as fromSimulation from '@app/core/state/simulation';
import { clearBestOfferPayload, ClearEnquadramento, ClearAll as ClearSimulationData, SetShowStepper } from '@app/core/state/simulation/simulation.actions';
import { GetProposalStageDTO, ProposalMacroStage } from '@app/proposal-workflow/models/proposal-stage.model';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ExitProposalModalComponent } from '@shared/components/exit-proposal-modal/exit-proposal-modal.component';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { RemoveRequestAction } from '@shared/components/widgets/loading/store/loading.actions';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { ProposalStageService } from '@shared/services/proposal/proposal-stage.service';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { filter, first, switchMap, take, takeUntil } from 'rxjs/operators';
import { GetProposalInformationDTO } from './models/proposal-data.model';

@Component({
  selector: 'app-proposal-workflow',
  templateUrl: './proposal-workflow.container.html',
  styleUrls: ['./proposal-workflow.container.scss']
})
export class ProposalWorkflowContainerComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  public inRequest = false;
  private retry = 0;
  public identification: any;
  public limit: any;
  public offer: any;
  public dueDate: string;
  public loadSideBar: boolean;
  public proposalStageEnum = ProposalMacroStage;
  public proposalStage: GetProposalStageDTO;
  public currentUrl: string;

  public isCrossedFlux: boolean;
  public isMobile: boolean;
  public isNpp;

  private simulationId: string;

  /**
   * Data to adjust modal size
   */
  public innerWidth: any;
  public MOBILE_BREAKPOINT = 768;

  public category = window.location.href;

  public showStepper$: Observable<boolean>;

  constructor(
    public router: Router,
    public dialog: MatDialog,
    private readonly store$: Store<AppState>,
    private activatedRoute: ActivatedRoute,
    private simulationService: SimulationService,
    private sessionStorageService: SessionStorageService,
    private translate: TranslateService,
    private proposalStageSerivce: ProposalStageService,
    private genTagger: GenTagger,
    private taggerService: TaggerService
  ) {
    this.innerWidth = window.screen.width;
    this.isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;
    this.store$.pipe(select('simulation')).subscribe(res => {
      if (res && res.simulationData && res.simulationParameters) {
        this.loadSideBar = true;

        this.identification = res.simulationData.identification;
        this.limit = res.simulationData.limit;
        this.dueDate = res.firstInstallmentDueDate
          ? res.firstInstallmentDueDate
          : moment(res.simulationData.dueDate).format('DD/MM/YYYY');
      }
    });
    this.isNpp = JSON.parse(environment.useNPP);
  }

  handleCloseButton() {
    this.router.navigate(['']);
  }

  ngOnInit() {
    this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));
    this.store$.dispatch(new SetShowStepper(true));

    this.getProposalStage();
    this.getCurrentUrl();

    this.store$
      .select(fromProposal.selectors.selectProposalId)
      .pipe(
        first(),
        filter(proposalId => !!proposalId)
      )
      .subscribe(id => {
        this.proposalStageSerivce
          .getProposalStage(id)
          .pipe(first())
          .subscribe(res => {
            this.proposalStageSerivce.redirectProposal(res);
          });
      });

    this.showStepper$ = this.store$
      .select(fromSimulation.selectors.selectShowStepper)
      .pipe(takeUntil(this.ngUnsubscribe));

    this.store$
      .pipe(
        select(getConfirmationData),
        filter(data => !!data),
        take(1)
      )
      .subscribe(state => {
        if (state.proposalData.product === ProductCodeEnum.CDC) {
          this.category = '/portallojista/criarproposta/cdc/simulacao';
        } else if (state.proposalData.product === ProductCodeEnum.CSC) {
          this.category = '/portallojista/criarproposta/csc/simulacao';
        }else if(state.proposalData.product === ProductCodeEnum.CSCP){
          this.category = '/portallojista/criarproposta/cscp/simulacao';
        }

        if(state?.proposalData?.product) {
          this.taggerService.setProposalType(state.proposalData.product)
        }
      });
  }

  private getCurrentUrl() {
    this.currentUrl = this.router.url;
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: RouterEvent) => {
      this.currentUrl = event.url ? event.url : this.currentUrl;
    });
  }

  private getProposalStage(): void {
    this.proposalStageSubscription();
    this.queryParamsSubscription();
  }

  public queryParamsSubscription(): void {
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      if (params.uuid && params.uuidValidate) {
        this.isCrossedFlux = true;
        this.handleCrossedFlux(params.uuid, params.uuidValidate);
      }

      if (!!this.sessionStorageService.getCrossedFlux()) {
        this.isCrossedFlux = true;
        this.router.navigate(['/proposal/step-formalization']);
      }

      if (params.id) {
        this.isCrossedFlux = false;
        const proposalId = params.id;
        this.simulationId = params.id;
        this.store$.dispatch(new fromProposal.actions.SetProposalIdAction(proposalId));
        this.store$.dispatch(new fromSimulation.actions.SetSimulationId(proposalId));
        this.store$.dispatch(new fromProposal.actions.GetProposalStageAction(proposalId));
      }

      if (!params.length && !this.simulationId) {
        this.getStatusProposals();
      }
    });
  }

  public handleCrossedFlux(uuid: string, uuidValidate: string): void {
    this.store$
      .select(fromFormalization.selectors.selectCrossedFluxInfo)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(info => !!info)
      )
      .subscribe(info => {
        this.sessionStorageService.setCrossedFlux(info);
        this.router.navigate(['/proposal/step-formalization']);
      });

    // We need to do this because there's a race condition where this action is being
    // dispatched before we have clientCredentials in sessionStorage.
    const sessionStoragePolling = setInterval(() => {
      if (sessionStorage.getItem('@@user_data')) {
        if (uuid && uuidValidate) {
          this.store$.dispatch(new fromFormalization.actions.GetCrossedFluxInfoAction({ uuid, uuidValidate }));
        }
        clearInterval(sessionStoragePolling);
      }
    }, 1000);
  }

  public proposalStageSubscription(): void {
    this.store$
      .select(fromProposal.selectors.selectProposalStage)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(stage => (this.proposalStage = stage));
  }

  getStatusProposals() {
    this.store$
      .select(selectSubmitResponse)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(res => !!res && !!res.simulation)
      )
      .subscribe(res => {
        let id: any = res.simulation;
        if (res.simulation.id) {
          id = res.simulation.id;
        }
        if (id) {
          this.store$.dispatch(new fromSimulation.actions.SetSimulationId(id));
          this.store$.dispatch(new fromProposal.actions.SetProposalIdAction(id));
        }
      });
  }

  private tagCloseModal(): void {
    this.genTagger.setTag({
      event_action: Tag.Click,
      event_label: 'x',
      page_location: this.category,
      page_section: 'tab',
      event_element: 'button'
    });
  }

  public leave(): void {
    this.tagCloseModal();

    if (this.currentUrl === '/proposal/step-simulation') {
      this.leaveSimulation();
    } else {
      this.store$
        .select(selectProposalId)
        .pipe(
          first(),
          switchMap((id: string) => this.simulationService.getProposalInformationData(id).pipe(first()))
        )
        .subscribe((proposal: GetProposalInformationDTO) => {
          const expireDate = moment(proposal.expirationDate || 0);
          const dayToExpire = expireDate.diff(new Date(), 'days');

          const isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;

          const dialogRef = this.dialog.open(ExitProposalModalComponent, {
            data: {
              dayToExpire: dayToExpire
            },
            disableClose: true,
            autoFocus: false,
            id: 'modal-component',
            panelClass: 'cancel-modal',
            width: isMobile ? '19rem' : '31.875rem',
            height: isMobile ? '21.5rem' : '20.3125rem'
          });

          dialogRef
            .afterClosed()
            .pipe(take(1))
            .subscribe(res => {
              if (!res) {
                this.store$.dispatch(new fromProposal.actions.ClearProposalIdAction());
                this.router.navigate(['/identification']);
              }
            });
        });
    }
  }

  private leaveSimulation() {
    const isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;
    const ref = this.dialog.open(PionModalComponent, {
      width: isMobile ? '80%' : '510px',
      height: isMobile ? '42%' : '325px',
      autoFocus: false,
      data: {
        title: this.translate.instant('EXIT-SIMULATION-MODAL-TITLE'),
        description: this.translate.instant('EXIT-SIMULATION-MODAL-DESCRIPTION'),
        cancelText: this.translate.instant('EXIT-SIMULATION-MODAL-CANCEL-TEXT'),
        confirmText: this.translate.instant('EXIT-SIMULATION-MODAL-CONFIRM-TEXT'),
        type: null
      }
    });

    ref.afterOpened().subscribe(() => {
      this.genTagger.setTag({
        event_action: 'abriu modal – quer mesmo sair?',
        event_label: this.translate.instant('EXIT-SIMULATION-MODAL-DESCRIPTION'),
        event_category: this.category
      });

      this.genTagger.setTag({
        event_action: 'abriu modal – ' + this.translate.instant('EXIT-SIMULATION-MODAL-TITLE'),
        event_label: this.translate.instant('EXIT-SIMULATION-MODAL-DESCRIPTION'),
        event_category: this.category
      });
    });

    ref.afterClosed().subscribe(confirm => {
      if (!confirm) {
        this.store$.dispatch(new fromProposal.actions.ClearProposalIdAction());
        this.store$.dispatch(new clearBestOfferPayload());
        this.store$.dispatch(new ClearEnquadramento());
        this.store$.dispatch(new ClearSimulationData());
        this.router.navigate(['/identification']);

        this.genTagger.setTag({
          event_action: 'fechou modal – quer mesmo sair?',
          event_label: this.translate.instant('EXIT-SIMULATION-MODAL-CANCEL-TEXT'),
          event_category: this.category
        });

        this.genTagger.setTag({
          event_action: 'fechou modal – ' + this.translate.instant('EXIT-SIMULATION-MODAL-TITLE'),
          event_label: this.translate.instant('EXIT-SIMULATION-MODAL-CANCEL-TEXT'),
          event_category: this.category
        });
      } else {
        this.genTagger.setTag({
          event_action: 'fechou modal – quer mesmo sair?',
          event_label: this.translate.instant('EXIT-SIMULATION-MODAL-CONFIRM-TEXT'),
          event_category: this.category
        });

        this.genTagger.setTag({
          event_action: 'fechou modal – ' + this.translate.instant('EXIT-SIMULATION-MODAL-TITLE'),
          event_label: this.translate.instant('EXIT-SIMULATION-MODAL-CONFIRM-TEXT'),
          event_category: this.category
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
