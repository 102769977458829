import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@app/core/state';
import * as fromContracts from '@app/core/state/contracts';
import { selectActiveContracts } from '@app/core/state/contracts/contracts.selectors';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Contract } from './model/contract.model';

@Component({
  selector: 'app-contracts',
  templateUrl: './contracts.component.html',
  styleUrls: ['./contracts.component.scss']
})
export class ContractsComponent implements OnInit, OnDestroy {
  public contracts: { data: Contract[]; isLoading: boolean };
  public skeletonConfig;
  protected ngUnsubscribe: Subject<any> = new Subject();
  public window = window.screen.width;
  public MOBILE_BREAKPOINT = 768;

  constructor(private store$: Store<AppState>) {}

  ngOnInit() {
    this.getContracts();
  }

  private getContracts(): void {
    this.store$
      .select(selectActiveContracts)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(contracts => {
        this.contracts = contracts;
        if (this.contracts.data != null) {
          if (this.contracts.data.length % 2 === 1) {
            this.contracts.data.push({
              ...this.contracts.data[0],
              lastEmptyCard: true
            });
          }
        }
      });

    this.store$.dispatch(new fromContracts.actions.GetActiveContractsAction());
  }

  public handleActiveContractsEmit(contract: Contract): void {
    const url = 'https://www.cliente.santanderfinanciamentos.com.br/portalcliente/#/detalhe-contrato/20039999999';
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
