import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core/state';
import * as fromFormalization from '@app/core/state/formalization';
import * as fromProposal from '@app/core/state/proposal';
import { PendencyService } from '@app/pendency/pendency.service';
import { ChecklistDocumentEnum } from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/api/query.checklist.model';
import { ChecklistModel } from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/checklist.model';
import { FormalizationProposalDTO } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pendency-update-register',
  templateUrl: './pendency-update-register.component.html',
  styleUrls: ['./pendency-update-register.component.scss']
})
export class PendencyUpdateRegisterComponent implements OnInit, OnChanges, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() proposal: FormalizationProposalDTO;
  @Input() checklist: ChecklistModel[] = [];
  @Input() message: string;
  @Output() done: EventEmitter<any> = new EventEmitter();
  public checklistItems: ChecklistModel[];
  public maxSizeAllowed = '10';
  public completedChecklistIds: string[] = [];
  public personalForm: FormGroup;
  public crossedFluxLink: { link: string };
  public uploadsComplete = false;
  public registerUpdateComplete = false;
  public isBiometryOrSignatureEnabled = false;

  constructor(private store$: Store<AppState>, private pendencyService: PendencyService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.checklist.length && changes.checklist) {
      this.handleProposalAndChecklist();
    }
  }

  ngOnInit(): void {
    this.setMaxFileAllowedSubscription();
    this.getCrossedFluxId();
  }

  private handleProposalAndChecklist(): void {
    this.checklistItems = this.checklist.filter(
      item => item.document && item.document.docCode === ChecklistDocumentEnum.ID_DOC
    );

    // this.buildForm();
  }

  private getCrossedFluxId(): void {
    this.store$
      .select(fromFormalization.selectors.selectCrossedFluxLink)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(id => (this.crossedFluxLink = id));
  }

  private buildForm(): void {
    this.personalForm = new FormGroup({
      fullName: new FormControl(null, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^[a-zA-ZÀ-ÿ]{2,}(?: [a-zA-ZÀ-ÿ]+){1,}$/)
      ])
    });

    this.setFormSubscription();
  }

  private setFormSubscription(): void {
    this.personalForm.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      if (this.personalForm.valid) {
        this.registerUpdateComplete = true;
        return;
      }
      this.registerUpdateComplete = false;
      this.isBiometryOrSignatureEnabled = false;
    });
  }

  private setMaxFileAllowedSubscription(): void {
    this.store$
      .select(fromFormalization.selectors.selectMaxFileSizeAllowed)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(maxSizeAllowed => (this.maxSizeAllowed = maxSizeAllowed));

    this.store$.dispatch(new fromFormalization.actions.GetMaxFileSizeAllowedAction());
  }

  public handleUploadFinished(obj: { id: string; completed: boolean }): void {
    // If upload is successful and the element is not in the list, add it.
    if (obj.completed) {
      if (this.completedChecklistIds.find(ele => ele === obj.id)) return;
      this.completedChecklistIds.push(obj.id);
    }

    // If deletion is successful and the element is in the list, remove it.
    if (!obj.completed) {
      if (!this.completedChecklistIds.find(ele => ele === obj.id)) return;
      const index = this.completedChecklistIds.findIndex(ele => ele === obj.id);
      this.completedChecklistIds.splice(index, 1);
    }

    // Compare checklist to successful uploads to determine if we need to enable
    // submit button.
    this.uploadsComplete = this.checklistItems.every(ele => !!this.completedChecklistIds.find(id => ele.id === id));
    // this.isBiometryOrSignatureEnabled = false;
    this.resolvePendency();
  }

  public resolvePendency(): void {
    if (this.checklistItems.length > 0 && !this.uploadsComplete) {
      this.done.emit(false);
      return;
    }
    this.done.emit(true);
  }

  public handleBiometryFinished(response: boolean) {
    if (response) {
      this.done.emit(true);
    }
  }

  public isPendencyResolved(): boolean {
    return !!this.uploadsComplete && !!this.registerUpdateComplete && !this.isBiometryOrSignatureEnabled;
  }

  public submitUpdateRegister(): void {
    const payload = {
      name: this.personalForm.get('fullName').value,
      documentNumber: this.proposal.customer.documentNumber
    };

    this.pendencyService
      .updateRegisterPendency(payload, this.proposal.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        () => {
          this.isBiometryOrSignatureEnabled = true;
          // TO DO Ivirson refatorar
          // this.store$.dispatch(new fromFormalization.actions.GetCrossedFluxLinkAction(this.proposal.id));
        },
        () => {
          this.isBiometryOrSignatureEnabled = false;
        }
      );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.store$.dispatch(new fromProposal.actions.ClearChecklistAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalStageAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalIdAction());
  }
}
