export enum PaymentPlanType {
  FLT_PLAN = 'FLT',
  STANDARD_PLAN = 'Tradicional',
  ASCENDING = 'Crescente',
  DESCENDING = 'Decrescente',
  REMOVE_INSTALLMENT = 'Pula parcela',
  FLEX = 'Flex',
  BALL_INSTALLMENT = 'Balão',
  CUSTOMIZED_PLAN = 'Plano Customizado'
}
