import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'pion-tooltip',
  templateUrl: './pion-tooltip.component.html',
  styleUrls: ['./pion-tooltip.component.scss']
})
export class PionTooltipComponent {
  /**
   * alignTo: left or right
   * direction to align tooltip content, defaults to right
   */
  @Input() alignTo = 'bottom';
  @Input() category: string;
  @Input() eventLabelOpen = 'mostrar tooltip';
  @Input() toLeft = false;
  @Output() openOut = new EventEmitter<PionTooltipComponent>();
  @Output() closeOut = new EventEmitter<PionTooltipComponent>();

  @ViewChild('containerText') containerText: ElementRef<HTMLElement>;

  public tooltipVisible = false;

  getClass() {
    return 'tooltip-container ' + this.alignTo;
  }

  toggleTooltipVisibility() {
    this.tooltipVisible = !this.tooltipVisible;

    if (this.tooltipVisible) {
      this.openOut.emit(this);
    }

    if (!this.tooltipVisible) {
      this.closeOut.emit(this);
    }
  }
}
