import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthService } from '@app/core/auth/services/auth.service';
import { StartupService } from '@app/core/startup/services/startup.service';
import { AppState } from '@app/core/state';
import * as fromLogin from '@app/core/state/login';
import { userProfileSelector } from '@app/core/state/profile/profile.selectors';
import { ShowTourTutorialShowcaseAction } from '@app/core/state/showcase/showcase.actions';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TermsModalComponent } from '@shared/components/terms-modal/terms-modal.component';
import { DomainsService } from '@shared/services/domains/domains.service';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { TermsService } from '@shared/services/terms/terms.service';
import { of } from 'rxjs';
import { catchError, first, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import * as actions from './startup.actions';

@Injectable()
export class StartupEffects {
  constructor(
    private actions$: Actions,
    private startupService: StartupService,
    private authService: AuthService,
    private router: Router,
    private translateService: TranslateService,
    private termsService: TermsService,
    private domainsService: DomainsService,
    private sessionStorage: SessionStorageService,
    public dialog: MatDialog,
    private store$: Store<AppState> //  private lgpd: LgpdService
  ) {}

  @Effect()
  getClientCredentials = this.actions$.pipe(
    ofType<actions.GetClientCredentialsAction>(actions.StartupActionTypes.GET_CLIENT_CREDENTIALS),
    switchMap(action =>
      this.startupService.requestToken().pipe(
        mergeMap((response: any) => {
          this.startupService.setUser({
            accessToken: response.access_token,
            refreshToken: response.refresh_token,
            scope: response.scope,
            expiresIn: response.expires_in,
            tokenType: response.token_type
          });

          const actionPayload = {
            clientCredentials: response
            // isMobile: action.payload.isMobile,
            // isDesktop: action.payload.isDesktop
          };

          return [
            new actions.GetClientCredentialsSuccessAction(actionPayload),
            new fromLogin.actions.DocumentTypesRequest()
          ];
        }),
        catchError(err => {
          return of(new actions.GetUserInfoError(err));
        })
      )
    )
  );

  @Effect()
  getUserInfo = this.actions$.pipe(
    ofType<actions.GetUserInfoRequest>(actions.StartupActionTypes.GET_USER_INFO_REQUEST),
    switchMap(action =>
      this.startupService.getUserInfo().pipe(
        mergeMap(response => {
          this.startupService.setUserInfo(response);

          const actionPayload = {
            userInfo: response,
            isMobile: action.payload.isMobile,
            isDesktop: action.payload.isDesktop
          };

          return [new actions.GetUserInfoSuccess(actionPayload), new actions.VerifyAccess(response.document)];
        }),
        catchError(err => of(new actions.GetUserInfoError(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  getUserFlags = this.actions$.pipe(
    ofType<actions.VerifyAccess>(actions.StartupActionTypes.VERIFY_ACCESS),
    switchMap(action =>
      this.startupService.getUserInfos(action.payload).pipe(
        switchMap(response => {
          // This is here because we don't have the necessity to have this info on the Store.
          this.startupService.userProperties(response);

          if (response.showUpData === true) {
            return this.router.navigate(['data-update']);
          }

          if (response.showTerms === true && response.showUpData === false) {
            return this.router.navigate(['terms']);
          }

          if (response.showTutorial === true) {
            return this.router.navigate(['tutorial']);
          }

          // @fixed: o texto show_terms estava escrito errado por isso quebrava o fluxo de stream dos effects
          if (response.showTerms === false && response.showUpData === false && response.showTutorial === false) {
            return this.router.navigate(['showcase']);
            // return this.router.navigate(['select-store']);
          }
        }),
        catchError(() => setStartupError('Error'))
      )
    )
  );

  @Effect({ dispatch: false })
  getUserProperties = this.actions$.pipe(
    ofType<actions.VerifyAccessSuccess>(actions.StartupActionTypes.VERIFY_ACCESS),
    switchMap(action => {
      console.log('batata');
      return of({});
    })
  );

  /**
   * Roteiro termos transbordo
   */
  @Effect()
  getTerms = this.actions$.pipe(
    ofType<actions.GetTermsRequest>(actions.StartupActionTypes.GET_TERMS_REQUEST),
    switchMap(() =>
      this.termsService.getTerms().pipe(
        map(response => {
          // configure terms modal and data
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.id = 'pion-no-padding-dialog';
          dialogConfig.maxWidth = '600px';
          dialogConfig.height = '90%';
          dialogConfig.autoFocus = false;

          dialogConfig.data = response.listTerms;

          // open modal
          const termsModal = this.dialog.open(TermsModalComponent, dialogConfig);

          // send post request when accpet event emit
          termsModal.componentInstance.accept.subscribe(term => {
            this.domainsService
              .updateFlags({
                showTerms: false,
                termId: term.code
              })
              .subscribe(() => {
                termsModal.close();

                // show lgpd
                this.store$.pipe(
                  select(userProfileSelector),
                  first()
                );
              });
          });

          // Close modal and redirect to logoff screen if decline
          termsModal.componentInstance.skip.subscribe(() => {
            this.dialog.closeAll();

            this.router.navigate(['disconnected'], {
              state: {
                message: this.translateService.instant('LOGOFF-MESSAGE-FROM-TERMS')
              }
            });
          });

          return [new actions.GetTermsSuccess(response)];
        }),
        catchError(error => {
          return of(new actions.GetTermsError(error));
        })
      )
    )
  );

  @Effect({ dispatch: false })
  resolvePendingRequests = this.actions$.pipe(
    ofType<actions.ResolvePendingRequests>(actions.StartupActionTypes.RESOLVE_PENDING_REQUESTS),
    tap(() => this.authService.resolvePendingRequests())
  );

  @Effect({ dispatch: false })
  redirect = this.actions$.pipe(
    ofType<actions.Redirect>(actions.StartupActionTypes.REDIRECT),
    tap(() => this.router.navigate(['disconnected']))
  );

  handleOpenModalTutor() {
    console.log('handle tutor modal if expiration | startup effects');

    const user = this.sessionStorage.getUser();
    if (!user) return;

    const expirationDialog = this.dialog.getDialogById('expiration-modal-component');

    // if no expirationModal opened.. open tutor modal
    if (!expirationDialog) {
      this.store$.dispatch(new ShowTourTutorialShowcaseAction());
    } else {
      // if expirationModal is opened..
      // subscribe to expirationModal and open tutor after it closes
      expirationDialog.afterClosed().subscribe(() => {
        this.store$.dispatch(new ShowTourTutorialShowcaseAction());
      });
    }
  }
}

function setStartupError(message: string): string {
  console.log('[@@Initial] Error', message);

  return 'Error';
}
