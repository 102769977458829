<div class="modal-header">
  <h3 class="modal-title">{{ 'PERIOD' | translate }}</h3>
  <button type="button" class="close" aria-label="Close" (click)="closeModal()">
    <img aria-hidden="true" src="./assets/ic_close.svg" alt="icon close" />
  </button>
</div>
<div class="modal-body">
  <form action="#!">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-3">
          <label class="form-label" for="radio-group-periods">{{ 'DATES' | translate }}</label>
        </div>
        <div class="col-12 col-md-9">
          <mat-radio-group
            [(ngModel)]="periodo"
            [ngModelOptions]="{ standalone: true }"
            aria-labelledby="radio-group-periods"
            class="form-control-radio-group"
          >
            <mat-radio-button
              class="form-control-radio-button"
              *ngFor="let period of periods"
              [value]="period.value"
              (click)="clickPeriod(period.name)"
            >
              {{ period.name }}
            </mat-radio-button>
          </mat-radio-group>
        </div>
      </div>
    </div>

    <div class="modal-buttons align-right">
      <button
        type="button"
        (click)="onDismiss()"
        class="btn btn-secondary"
        appTagger
        [event_category]="category"
        event_label="{{ 'CLEAR_PERIOD' | translate }}"
      >
        {{ 'CLEAR_PERIOD' | translate }}
      </button>
      <button
        type="button"
        (click)="onSubmit()"
        class="btn btn-primary"
        appTagger
        [event_category]="category"
        event_label="{{ 'APPLY' | translate }}"
      >
        {{ 'APPLY' | translate }}
      </button>
    </div>
  </form>
</div>
