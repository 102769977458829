<div class="row">
  <div class="col text-center">
    <h2 class="font-weight-bold">
      {{ 'EXIT_CONFIRMATION' | translate }}
    </h2>
  </div>
</div>
<div class="row">
  <div class="col text-center">
    {{ 'EXIT_CONFIRMATION_TEXT_USER' | translate }} <strong>{{ userName }}</strong>.
  </div>
</div>
<div class="row mb-4">
  <div class="col text-center">{{ 'EXIT_CONFIRMATION_TEXT' | translate }}</div>
</div>
<div class="row">
  <div class="col text-center mb-2">
    <button mat-stroked-button class="w-100" color="warn" (click)="submitResponse('CONTINUE')" appTagger
      [event_category]='categoria' event_label='{{"CONFIRMATION_STAY_PAGE" | translate}}'>
      {{ 'CONFIRMATION_STAY_PAGE' | translate }}
    </button>
  </div>
  <div class="col text-center">
    <button mat-raised-button class="w-100" color="warn" (click)="submitResponse('EXIT')" #btnFocus
      [autofocus]="btnFocus.focus()" appTagger event_action="fechou modal - sair da conta" [event_category]="categoria"
      event_label="{{ 'CONFIRMATION_EXIT_PAGE' | translate }}">
      {{ 'CONFIRMATION_EXIT_PAGE' | translate }}
    </button>
  </div>
</div>
