import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pre-analise',
  templateUrl: './pre-analise.component.html',
  styleUrls: ['./pre-analise.component.scss']
})
export class PreAnaliseComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
