<ng-container *ngIf="currentCountry === countries.BR || scanType === documentScanTypes.GENERIC">
  <app-bra-document-scan (scanOutput)="scanCallback($event)"></app-bra-document-scan>
</ng-container>

<ng-container *ngIf="currentCountry === countries.PE && scanType === documentScanTypes.DOCUMENT">
  <app-per-document-scan
    [documentSide]="documentSide"
    [sessionId]="sessionId"
    [documentType]="documentType"
    (scanOutput)="scanCallback($event)"
  ></app-per-document-scan>
</ng-container>

<ng-container *ngIf="currentCountry === countries.AR && scanType === documentScanTypes.DOCUMENT">
  <app-arg-document-scan (scanOutput)="scanCallback($event)"></app-arg-document-scan>
</ng-container>
