import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core/state';
import { SetObservationDataSection } from '@app/core/state/proposal-information/proposal-information.actions';
import {
  getSimulationProposalData,
  observationDataSection
} from '@app/core/state/proposal-information/proposal-information.selectors';
import { SetAdditionalInformationData } from '@app/core/state/register/register.actions';
import { AdditionalSignUpData, ProposalSignUpData } from '@app/proposal-workflow/models/proposal-data.model';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { SubsegmentsEnum } from '@shared/constants/subsegments.enum';
import { Subject } from 'rxjs';
import { debounceTime, filter, first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-observation-data',
  templateUrl: './observation-data.component.html',
  styleUrls: ['./observation-data.component.scss']
})
export class ObservationDataComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();

  @Input() public pos: { pos: number; size: number };
  @Input() public proposal: ProposalSignUpData;
  @Output() public begin = new EventEmitter<FormGroup>();

  public form: FormGroup;
  public subsementsCode = SubsegmentsEnum;
  public commentId: string;

  // Tag
  public Insert: Tag = Tag.Insert;
  public Select: Tag = Tag.Selecionar;
  public event_category: string;

  constructor(private store$: Store<AppState>) {}

  ngOnInit() {
    this.buildForm();
    this.getProposalData();
    this.getSectionData();
    this.begin.emit(this.form);
    this.setFormSubscription();
  }

  private buildForm(): void {
    this.form = new FormGroup({
      commentId: new FormControl(),
      orderNumber: new FormControl(null, [Validators.minLength(0), Validators.maxLength(200)]),
      comment: new FormControl(null, [Validators.minLength(0), Validators.maxLength(200)]),
      proposalId: new FormControl({ value: this.proposal && this.proposal.id ? this.proposal.id : null })
    });
  }

  public getProposalData() {
    this.store$
      .select(getSimulationProposalData)
      .pipe(
        filter((data: ProposalSignUpData) => !!data && Object.values(data).some(item => !!item)),
        first()
      )
      .subscribe((data: ProposalSignUpData) => {
        if (data.productCode === ProductCodeEnum.CDC) {
          this.event_category = '/portallojista/criarproposta/cdc/cadastro';
        } else if (data.productCode === ProductCodeEnum.CSC) {
          this.event_category = '/portallojista/criarproposta/csc/cadastro';
        } else if (data.productCode === ProductCodeEnum.CSCP) {
          this.event_category = '/portallojista/criarproposta/cscp/cadastro';
        }
      });
  }

  public getSectionData() {
    this.store$
      .select(observationDataSection)
      .pipe(
        filter((data: AdditionalSignUpData) => !!data && Object.values(data).some(item => !!item)),
        first()
      )
      .subscribe((data: AdditionalSignUpData) => {
        this.commentId = data.commentId;
        this.form.patchValue(data);
        if (this.form.valid) {
          this.updateObservationData();
        }
      });
  }

  private setFormSubscription(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(1000),
        filter(() => this.form.valid && this.form.touched)
      )
      .subscribe(() => {
        this.updateObservationData();
      });
  }

  private updateObservationData() {
    this.store$.dispatch(
      new SetAdditionalInformationData({
        ...this.form.getRawValue(),
        commentId: this.commentId
      })
    );

    this.store$.dispatch(
      new SetObservationDataSection({
        ...this.form.getRawValue(),
        commentId: this.commentId
      })
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
