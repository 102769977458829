import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-faq-content-modal',
  templateUrl: './faq-content-modal.component.html',
  styleUrls: ['./faq-content-modal.component.scss']
})
export class FaqContentModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<FaqContentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {}

  ngOnInit() {}

  back() {
    this.dialogRef.close();
  }

  goHome() {
    this.dialogRef.close();
    if (this.router.url.includes('FAQ/login')) {
      this.router.navigate(['login']);
    } else {
      this.router.navigate(['showcase']);
    }
  }

  onSearch(term: any) {
    this.dialogRef.close(term);
  }
}
