import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { BipartitePartnerDTO } from '@app/pendency/models/bipartite.model';
import { ProposalDetailsService } from '@app/profile/components/proposal-details/service/proposal-details.service';
import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import {
  CertifiedAgents,
  ConfirmationData,
  GetProposalInformationDTO
} from '@app/proposal-workflow/models/proposal-data.model';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-pendency-summary',
  templateUrl: './pendency-summary.component.html',
  styleUrls: ['./pendency-summary.component.scss']
})
export class PendencySummaryComponent implements OnInit, OnChanges, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() proposal: ConfirmationData;
  @Input() proposalPartners: BipartitePartnerDTO[];
  public proposalId: string;
  public isOpen = false;
  public isOpenAdditional = false;
  public productCode = ProductCodeEnum;
  public paymentPlanType = PaymentPlanType;
  public personsType = SubmitPersonType;
  public proposalInformation: GetProposalInformationDTO;
  public data: any;
  public window = window;
  public certifiedAgent: CertifiedAgents;
  readonly categoria: '/portallojista/pendencia';

  constructor(private simulationService: SimulationService, private _proposalDetails: ProposalDetailsService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.proposal && changes.proposal) {
      this.proposalId = this.proposal.proposalData.id;
      this.certifiedAgent = this.proposal.certifiedAgent;
      this.getProposalDetailData();
      this.getProposalInformationData();
    }
  }

  ngOnInit() {}

  public openCard() {
    this.isOpen = !this.isOpen;
  }

  public openCardAdditional() {
    this.isOpenAdditional = !this.isOpenAdditional;
  }

  getProposalDetailData() {
    this._proposalDetails
      .getInfoUser(this.proposalId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.data = data;
      });
  }

  public getProposalInformationData(): void {
    this.simulationService
      .getProposalInformationData(this.proposalId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: GetProposalInformationDTO) => {
        this.proposalInformation = data;
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
