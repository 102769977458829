<section class="insurance-modal-section">
  <div #containerText>
    <!-- <mat-dialog-content> -->
    <div class="row">
      <div class="col">
        <img src="./assets/images/ic_bank_credit.svg" alt="bank credit icon" class="credit-icon" />
        <p class="title">Proteção financeira</p>
      </div>
    </div>

    <div class="row subtitle">
      <div class="col">
        <p class="details-title details-title--full">
          Com o seguro você tem mais tranquilidade no pagamento do seu empréstimo.
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="wrapper">
          <div>
            <img src="./assets/images/ic_wallet.svg" alt="wallet icon" class="ic_wallet" />
          </div>
          <div>
            <p class="data">
              Em caso de <strong>desemprego</strong>, exceto justa causa, pagaremos até 3 parcelas do seu financiamento,
              limitado a um valor total de R$ 5.000,00.
            </p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="wrapper">
          <div>
            <img src="./assets/images/ic_home.svg" alt="home icon" class="ic_home" />
          </div>
          <div>
            <p class="data">
              <strong>Pacote de assistências para sua residência</strong> com diversas opções como: Chaveiro,
              Eletricista, Encanador, Vidraceiro e muito mais.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12 col-md-6">
        <div class="wrapper">
          <div>
            <img src="./assets/images/ic_management_health.svg" alt="health management icon"
              class="ic_management_health" />
          </div>
          <div>
            <p class="data">
              Em caso de <strong>morte</strong> ou <strong>invalidez</strong>, seus beneficiários contam com até R$
              60.000,00 para pagar o financiamento.
            </p>
          </div>
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="wrapper">
          <div>
            <img src="./assets/images/ic_capitalization.svg" alt="capitalization icon" class="ic_capitalization" />
          </div>
          <div>
            <p class="data">
              <strong>Sorteios mensais no valor de 50 mil reais</strong> seguindo o resultado da loteria federal.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <p class="final-details">
          Tudo para você ficar protegido de imprevistos. Para mais informações
          <a href="https://www.santanderfinanciamentos.com.br/cdcprotegido" target="_blank">clique aqui</a>.
        </p>
      </div>
    </div>
    <!-- </mat-dialog-content> -->
  </div>

  <div class="row button" *ngIf="data.selectedOption != 0.1">
    <div class="col text-center">
      <button class="btn" (click)="closeModal()" appTagger [event_category]="category" event_label="Fechar">
        Fechar
      </button>
    </div>
  </div>

  <div class="row button" *ngIf="data.selectedOption === 0.1 || !data.selectedOption">
    <div class="col two-buttons text-center">
      <button mat-button mat-dialog-close class="btn-cancel" appTagger [event_category]="data.category"
        event_label="Continuar desprotegido" event_action="fechou modal – proteção financeira">
        Continuar desprotegido
      </button>
      <button mat-button [mat-dialog-close]="true" cdkFocusInitial class="btn-ok" appTagger
        [event_category]="data.category" event_action="fechou modal – proteção financeira"
        event_label="Continuar protegido">
        Continuar protegido
      </button>
    </div>
  </div>
</section>