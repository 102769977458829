import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { CalendarDialogComponent } from '@shared/components/ui/calendar-dialog/calendar-dialog.component';
import { CalendarConfig } from '@shared/components/ui/calendar/calendar.model';
import { Countries } from '@shared/constants/countries.enum';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import * as moment from 'moment';

interface PossibleDates {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-due-date',
  templateUrl: './due-date.component.html',
  styleUrls: ['./due-date.component.scss']
})
export class DueDateComponent implements OnInit {
  public currentCountry = this.internationalizationService.currentCountry;
  public countries = Countries;
  public totalSections = null;
  // NOTE: - CalendarComponent has to be updated to appropriately use max dates
  // values and possibly other mat-datepicker features
  minDate: moment.Moment;
  maxDate: moment.Moment;

  public showCalendar = false;
  public resize: number;
  public calendarConfig: CalendarConfig = {
    titleMobile: 'Primeiro vencimento',
    subTitleMobile: 'Qual data do primeiro vencimento?',
    isTruncateYears: true,
    isTruncateDays: { toBack: true }
  };

  @Input() dueDateData: any;
  @Input() defaultDaysToInitialPayment: number;
  @Input() public dueDaysIntervalMin: number;
  @Input() public dueDaysIntervalMax: number;

  @Output() public addForm = new EventEmitter<FormGroup>();
  form: FormGroup;
  @Output() public statusViewCalendar = new EventEmitter();

  @Output() public changeDateEmitter = new EventEmitter();

  possibleDates: PossibleDates[] = [];

  @Input() category: string;
  readonly Insert: Tag = Tag.Insert;
  readonly Selecionar: Tag = Tag.Selecionar;
  constructor(
    public dialog: MatDialog,
    private internationalizationService: InternationalizationService,
    private genTagger: GenTagger
  ) {
    this.totalSections = 5;
    this.resize = window.innerWidth;
  }

  ngOnInit() {
    this.setMinAndMaxDatesForBra();

    this.form = new FormGroup({
      dueDate: new FormControl(this.dueDateData.dueDate)
    });

    this.form.patchValue({
      dueDate: this.dueDateData.dueDate
    });

    this.addForm.emit(this.form);
  }

  setMinAndMaxDates(): void {
    const minLimitDate = new Date();
    minLimitDate.setDate(minLimitDate.getDate() + this.dueDaysIntervalMin);

    const maxLimitDate = new Date();
    maxLimitDate.setDate(maxLimitDate.getDate() + this.dueDaysIntervalMax);

    this.maxDate = moment(
      `${maxLimitDate.getFullYear()}-${maxLimitDate.getMonth() + 1}-${maxLimitDate.getDate()}`,
      'YYYY-MM-DD'
    );
    this.minDate = moment(
      `${minLimitDate.getFullYear()}-${minLimitDate.getMonth() + 1}-${minLimitDate.getDate()}`,
      'YYYY-MM-DD'
    );
  }

  setMinAndMaxDatesForBra() {
    if (!this.dueDateData || !this.dueDateData.maxAllowedFirstInstallmentDueDate) return;
    this.maxDate = moment(this.dueDateData.maxAllowedFirstInstallmentDueDate, 'DD/MM/YYYY');

    if (!this.dueDateData || !this.dueDateData.minAllowedFirstInstallmentDueDate) return;
    this.minDate = moment(this.dueDateData.minAllowedFirstInstallmentDueDate, 'DD/MM/YYYY').subtract(1, 'd');
  }

  setPossibleDates(): void {
    // NOTE: - substring may not be the best strategy
    if (!this.dueDateData.dueDate) return;
    const dayInsert = this.dueDateData.dueDate.substring(0, 2);
    const monthInsert = this.dueDateData.dueDate.substring(3, 5);
    const yearInsert = this.dueDateData.dueDate.substring(6, 10);
    let minTimeDiff: number;
    // Create a const with type moment.Moment based on the dueDate input decorator value:
    const defaultDate = moment([yearInsert, Number(monthInsert) - 1, dayInsert]);
    if (this.currentCountry === this.countries.PE) {
      for (const date = this.minDate; date <= this.maxDate; date.add(1, 'days')) {
        if (date.date() === 2 || date.date() === 7 || date.date() === 14) {
          this.possibleDates.push({ value: date.format('DD/MM/YYYY'), viewValue: date.format('DD/MM/YYYY') });
          // diff is a moment.js function that returns the time difference between two dates:
          if (Math.abs(date.diff(defaultDate)) < minTimeDiff || minTimeDiff === undefined) {
            this.dueDateData.dueDate = date.format('DD/MM/YYYY');
            minTimeDiff = Math.abs(date.diff(defaultDate));
          }
        }
      }
    } else if (this.currentCountry === this.countries.CO) {
      for (const date = this.minDate; date <= this.maxDate; date.add(1, 'days')) {
        if (date.date() === 2 || date.date() === 17) {
          this.possibleDates.push({ value: date.format('DD/MM/YYYY'), viewValue: date.format('DD/MM/YYYY') });
          // diff is a moment.js function that returns the time difference between two dates:
          if (date.diff(moment()) >= 0 && minTimeDiff === undefined) {
            this.dueDateData.dueDate = date.format('DD/MM/YYYY');
            minTimeDiff = Math.abs(date.diff(defaultDate));
          }
        }
      }
    }
  }

  isDueDateValid(): boolean | void {
    // NOTE: - substring may not be the best strategy
    if (!this.dueDateData.dueDate) return;
    const dayInsert = this.dueDateData.dueDate.substring(0, 2);
    const monthInsert = this.dueDateData.dueDate.substring(3, 5);
    const yearInsert = this.dueDateData.dueDate.substring(6, 10);
    const withinMinLimit = moment(this.minDate).isBefore(`${yearInsert}-${monthInsert}-${dayInsert}`);
    const withinMaxLimit = moment(this.maxDate).isAfter(`${yearInsert}-${monthInsert}-${dayInsert}`);
    return withinMinLimit && withinMaxLimit;
  }

  dueDateChanged(date?: string) {
    if (date) {
      this.dueDateData.dueDate = date;
      this.form.controls.dueDate.setValue(date);
    }
    const datePayload = {
      value: {
        payment: {
          firstInstallmentDueDate: moment(this.dueDateData.dueDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        }
      },
      input: InputTypes.DUE_DATE
    };

    // NOTE: - equipe front-end, redundante?
    // this.store$.dispatch(new SetSimulationDueDate(moment(this.dueDate, 'DD/MM/YYYY').add(1, 'day').format('YYYY-MM-DD') + 'T00:00:00Z'));
    this.changeDateEmitter.emit(datePayload);
  }

  public openCalendar() {
    // calendário mobile está incompleto
    // if (this.resize <= 579) {
    //   this.showCloseCalendar();
    // } else {
    this.openDialogCalendar();
    // }
  }

  public openDialogCalendar(): void {
    const refDialogCalendar = this.dialog.open(CalendarDialogComponent, {
      disableClose: true,
      position: { top: '100px', left: '35%' },
      data: {
        isTruncateDays: { toBack: true },
        isTruncateYears: true,
        minDate: this.minDate,
        maxDate: this.maxDate
      } as CalendarConfig
    });

    refDialogCalendar.afterClosed().subscribe((date: string) => {
      this.genTagger.setTag({
        event_action: 'preencheu campo',
        event_label: 'primeiro vencimento',
        event_category: this.category
      });

      if (date && date !== 'back') {
        this.dueDateChanged(date);
      }
    });
  }

  public validator(type: string): boolean {
    switch (type) {
      case 'date':
        return this.validatorDate(this.dueDateData.dueDate, 'birth');
      case 'dateTravel':
        return this.validatorDate(this.dueDateData.dueDate, 'travel');
      default:
        break;
    }
  }

  public validatorDate(date, type): boolean {
    if (!date) return;

    const lengthDate = date.length;
    const now = new Date().getFullYear();
    const dayInsert = date.substring(0, 2);
    const monthInsert = date.substring(2, 4);
    const yearInsert = date.substring(4, 8);

    if (lengthDate === 8 && now - yearInsert <= 100 && (dayInsert >= 1 && dayInsert <= 31)) {
      const withinNDaysBefore = moment(this.minDate).isBefore(`${yearInsert}-${monthInsert}-${dayInsert}`);
      const withinNDaysAfter = moment(this.maxDate).isAfter(`${yearInsert}-${monthInsert}-${dayInsert}`);

      return withinNDaysBefore && withinNDaysAfter;
    }
  }

  showCloseCalendar(): void {
    this.showCalendar = !this.showCalendar;
    this.statusViewCalendar.emit(this.showCalendar);
  }

  navAction(action: string): void {
    if (action === 'back') {
      this.showCloseCalendar();
    } else {
      this.dueDateChanged(action);
      this.showCloseCalendar();
    }
  }
}
