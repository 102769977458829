<div class="installments-modal-flex-container">
  <div class="pion-modal-header" [class.header-collapsed]="headerCollapsed">
    <div class="title">
      <img class="icon" src="./assets/images/flex-plan-icon.png" alt="installment" />
      <h3 class="modal-title">{{ 'FLEX-PLAN' | translate }}</h3>
      <button
        mat-icon-button
        aria-label="Fechar"
        class="close-modal"
        (click)="close()"
        appTagger
        [event_category]="data.category"
        event_label="fechar modal"
      >
        <img src="./assets/ic_close.svg" alt="fechar" />
      </button>
    </div>
    <div class="description">
      <p class="description1">
        No modelo <strong>flex</strong> do pagamento, é possível escolher
        <strong>até {{ data.maxAllowedFlexInstallmentsNumber }} parcelas</strong> para flexibilizá-las com um valor
        total de até <strong>{{ data.maxAllowedFlexValue | localeCurrency }}</strong>
      </p>
      <p *ngIf="data.currentPaymentPlan.code === 'FLT'">
        Seu <strong>plano atual</strong> é de
        <strong>{{ data.installmentAmount }}x {{ data.installmentValue | localeCurrency }}</strong
        >.
      </p>
      <p *ngIf="data.currentPaymentPlan.code !== 'FLT'">
        Seu <strong>plano atual</strong> é de <strong>{{ data.installmentAmount }}x Plano Customizado</strong>.
      </p>
    </div>
    <div class="list-header d-none d-sm-block">
      <div class="table-row" [ngClass]="currentCountry">
        <div class="col-2 installments">{{ 'FLEX-PLAN-NUMBER' | translate }}</div>
        <div class="col-2 value">{{ 'MONTH-YEAR' | translate }}</div>
        <div class="col-4 month">{{ 'FLEX-PLAN-CURRENT-INSTALLMENT' | translate }}</div>
        <div class="col-4 value-wish">{{ 'FLEX-PLAN-INSTALLMENT-WISH' | translate }}</div>
      </div>
    </div>
  </div>
  <div class="modal-scroll-area" (scroll)="onScroll($event)">
    <div class="list-table" [ngClass]="currentCountry">
      <div class="table-row" *ngFor="let installment of installments; index as i">
        <div class="col-2 col-sm-2 installments">
          <span class="table-label">{{ 'FLEX-PLAN-NUMBER-DESCRIPTION' | translate }}</span>
          <span>{{ installment.number }}</span>
        </div>
        <div class="col-4 col-sm-2 value">
          <span class="table-label">{{ 'MONTH-YEAR' | translate }}</span>
          <span>{{ installment.dueDateFormated }}</span>
        </div>
        <div class="col-5 col-sm-3 month">
          <span class="table-label">{{ 'FLEX-PLAN-CURRENT-INSTALLMENT' | translate }}</span>
          <span [class.value-overrided]="flexValues.controls[i].value > 0">{{
            installment.value | localeCurrency
          }}</span>
        </div>
        <div class="col-5 col-sm-6 value-wish">
          <span class="table-label">{{ 'FLEX-PLAN-INSTALLMENT-WISH' | translate }}</span>
          <mat-form-field class="w-100">
            <input
              class="text-left input-value"
              [class.has-value]="flexValues.controls[i].value > 0"
              matInput
              currencyMask
              (blur)="resetValue(flexValues.controls[i], 'blur')"
              [formControl]="flexValues.controls[i]"
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
              appTagger
              [event_category]="data.category"
              event_label="parcela desejada"
              [event_action]="Insert"
            />
            <button
              *ngIf="flexValues.controls[i].value > 0"
              mat-button
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="resetValue(flexValues.controls[i], 'clear')"
              appTagger
              [event_category]="data.category"
              event_label="resetar campo - parcela desejada"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-icon matSuffix class="danger" *ngIf="showWarningIcon(flexValues.controls[i])">warning</mat-icon>
          </mat-form-field>
          <div
            class="error"
            *ngIf="IsmaxAllowedInstallmentValue(flexValues.controls[i])"
            appTagger
            [event_category]="data.category"
            event_label="erro - valor maior que o requerido por parcela"
          >
            Máximo de {{ data.maxAllowedInstallmentValue | localeCurrency }}
          </div>
          <div
            class="error"
            *ngIf="IsminAllowedInstallmentValue(flexValues.controls[i])"
            appTagger
            [event_category]="data.category"
            event_label="erro - valor menor que o requerido por parcela"
          >
            Mínimo de {{ data.minAllowedInstallmentValue | localeCurrency }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pion-modal-footer">
    <div class="description-footer">
      <div class="row">
        <div class="col-4 col-sm-6">
          <p class="footer-title">
            {{ 'FLEX-PLAN-FLEX-INSTALLMENT' | translate }}
          </p>
          <p
            class="footer-content font-weight-bold"
            [ngClass]="{ 'max-value-error': showMaxAllowedFlexInstallmentsError() }"
          >
            {{ qtyInstallmentsInfo }}
          </p>
          <p class="max-value-msg-error" *ngIf="showMaxAllowedFlexInstallmentsError()">
            Quantidade parcelas editadas excede o limite.
          </p>
        </div>
        <div class="col-8 col-sm-6">
          <p class="footer-title text-right">
            {{ 'FLEX-PLAN-MAX-VALUE' | translate }}
          </p>
          <p class="footer-content text-right" [ngClass]="{ 'max-value-error': showMaxAllowedFlexValueError() }">
            {{ sumValidInstallments | localeCurrency }}/<span class="font-weight-bold">{{
              data.maxAllowedFlexValue | localeCurrency
            }}</span>
          </p>
          <p
            class="max-value-msg-error"
            *ngIf="showMaxAllowedFlexValueError()"
            appTagger
            [event_category]="data.category"
            event_label="Soma das parcelas editadas excede o valor máximo."
          >
            Soma das parcelas editadas excede o valor máximo.
          </p>
        </div>
      </div>

      <div class="row pb-3 pb-sm-5">
        <div class="col">
          <button
            mat-stroked-button
            color="primary"
            class="w-100 mb-2 mb-sm-0"
            [disabled]="disableCalculateBtn()"
            (click)="calculateFlex()"
            appTagger
            [event_category]="data.category"
            event_label="{{ 'FLEX-PLAN-CALCULETE-NEW' | translate }}"
          >
            {{ 'FLEX-PLAN-CALCULETE-NEW' | translate }}
          </button>
        </div>
        <div class="col">
          <button
            mat-flat-button
            color="primary"
            [disabled]="disableSave"
            class="w-100"
            (click)="save()"
            appTagger
            [event_category]="data.category"
            event_label="{{ 'FLEX-PLAN-SAVE-NEW' | translate }}"
          >
            {{ 'FLEX-PLAN-SAVE-NEW' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
