import { Component, Inject, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from '@env/environment';

@Component({
  selector: 'app-open-finance-modal',
  templateUrl: './open-finance-modal.component.html',
  styleUrls: ['./open-finance-modal.component.scss']
})
export class OpenFinanceModalComponent implements OnInit, OnChanges {
  // @Input() type;
  public containerStyles: { [key: string]: string | number };
  public overlayStyles: { [key: string]: string | number };
  public urlOpenFinance;
  public qrCodeSize = 160;
  public category: string;

  constructor(public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.qrCodeSize) {
      this.setStyles();
    }
  }

  ngOnInit() {
    this.urlOpenFinance = environment.urlOpenFinance;
  }

  public openWindowOpenFinance() {
    window.open(this.urlOpenFinance);
    this.closeModal();
  }

  public closeModal() {
    this.dialog.closeAll();
  }

  private setStyles(): void {
    this.containerStyles = {
      'height.px': this.qrCodeSize,
      'padding-left': `calc(50% - ${this.qrCodeSize / 2}px)`
    };

    this.overlayStyles = {
      'width.px': this.qrCodeSize,
      'height.px': this.qrCodeSize,
      padding: `${this.qrCodeSize / 4}px ${this.qrCodeSize / 8}px 0 ${this.qrCodeSize / 8}px`
    };
  }
}
