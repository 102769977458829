<div class="modal-container">
  <mat-icon class="modal-icon-error">{{ modalData?.errorIcon }}</mat-icon>

  <h3 class="modal-title">{{ modalData?.errorTitle }}</h3>

  <p *ngIf="modalData?.errorText" class="modal-description">
    {{ modalData.errorText }}
  </p>

  <span class="modal-code-error">
    {{ modalData?.errorCode }}
  </span>

  <button class="btn modal-button" (click)="handleReturn()">{{ modalData?.errorButton }}</button>
</div>
