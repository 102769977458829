import { getBestOffer } from './simulation.selectors';
import { Action } from '@ngrx/store';

export enum SimulationActionTypes {
  GET_SIMULATION = '[Simulation] Get simulation',
  GET_FRAMEWORK = '[Simulation] Get framework',
  GET_FRAMEWORK_SUCCESS = '[Simulation] Get framework success',
  GET_FRAMEWORK_ERROR = '[Simulation] Get framework error',
  GET_PAYLOAD_BESTOFFERS = '[Simulation] Get payload bestoffers',

  GET_SIMULATION_SUCCESS = '[Simulation] Get simulation success',
  GET_SIMULATION_ERROR = '[Simulation] Get simulation error',

  GET_OFFER = '[Simulation] Get best offer simulation',
  GET_OFFER_SUCCESS = '[Simulation] Get best offer simulation success',
  GET_OFFER_ERROR = '[Simulation] Get best offer simulation error',

  UPDATE_OFFER = '[Simulation] Update offer simulation',
  UPDATE_OFFER_SUCCESS = '[Simulation] Update offer simulation success',
  UPDATE_OFFER_ERROR = '[Simulation] Update offer simulation error',

  UPDATE_NPP_OFFER = '[Simulation] Update NPP offer simulation',
  UPDATE_NPP_OFFER_SUCCESS = '[Simulation] Update NPP offer simulation success',
  UPDATE_NPP_OFFER_ERROR = '[Simulation] Update NPP offer simulation error',

  GET_NPP_INSTALLMENTS = '[Simulation] Get NPP installments',
  GET_NPP_INSTALLMENTS_SUCCESS = '[Simulation] Get NPP installments success',
  GET_NPP_INSTALLMENTS_ERROR = '[Simulation] Get NPP installments error',

  GET_STATUS_PROPOSALS = '[Simulation] Get status proposals',
  GET_STATUS_PROPOSALS_INC = '[Simulation] Get status proposals Inc',
  GET_STATUS_PROPOSALS_SUCCESS = '[Simulation] Get status proposals success',
  GET_STATUS_PROPOSALS_ERROR = '[Simulation] Get status proposals error',

  LOADING_DISMISS = '[Simulation] Loading dismiss',

  GET_NEW_CALCULATION = '[Simulation] Get new calculation',
  GET_NEW_CALCULATION_START = '[Simulation] Get new calculation start',
  SAVE_CALCULATION_PAYLOAD = '[Simulation] Save Calculation Payload',
  GET_NEW_CALCULATION_SUCCESS = '[Simulation] Get new calculation success',
  GET_NEW_CALCULATION_ERROR = '[Simulation] Get new calculation error',

  POST_SIMULATION_REQUEST = '[Simulation] Post simulation request',
  POST_SIMULATION_SUCCESS = '[Simulation] Post simulation success',
  POST_SIMULATION_ERROR = '[Simulation] Post simulation error',

  SET_SIMULATION_ID = '[Simulation] Set simulation id',

  SHOW_SIMULATION_LOADER = '[Simulation] Show simulation loader',

  DELAY_PROPOSAL_STATUS = '[Simulation] Delay proposal status',

  SET_SIMULATION_DUE_DATE = '[Simulation] Set simulation due date',

  GET_SIMULATION_PARAMETERS = '[Simulation] Get simulation parameters',
  GET_SIMULATION_PARAMETERS_SUCCESS = '[Simulation] Get simulation parameters success',
  GET_SIMULATION_PARAMETERS_ERROR = '[Simulation] Get simulation parameters error',

  REDIRECT_STEP_REGISTER = '[Simulation] REDIRECT_STEP_REGISTER',

  REDIRECT_SIMULATION_SUMMARY = '[Simulation] REDIRECT_SIMULATION_SUMMARY',

  CLEAR_STATUS_PROPOSAL = '[Simulation] Clear Status Proposal',
  CLEAR_PAYLOAD_BESTOFFERS = '[Simulation] Clear Payload bestOffers',
  CLEAR_ALL = '[Simulation] CLEAR_ALL',
  CLEAR_GET_FRAMEWORK = '[Simulation] Clear_Framework',

  GET_NEW_FLEX_INSTALLMENTS_CALCULATION = '[Simulation] GET_NEW_FLEX_INSTALLMENTS_CALCULATION',

  VALIDATE_COUPON = '[Simulation] Validate Coupon',
  VALIDATE_COUPON_SUCCESS = '[Simulation] Validate Coupon success',
  VALIDATE_COUPON_ERROR = '[Simulation] Validate Coupon error',

  GET_ALL_OFFERS = '[Simulation] Get all offers simulation',
  GET_ALL_OFFERS_SUCCESS = '[Simulation] Get all offers simulation success',
  GET_ALL_OFFERS_ERROR = '[Simulation] Get all offers simulation error',

  SET_SUBOFFER_ID = '[Simulation] Set subOfferId',

  SET_PAYLOAD_BESTOFFERS = '[Simulation] Set payload bestOffers',

  POST_BEST_OFFER = '[Simulation] Post best offer',
  POST_BEST_OFFER_SUCCESS = '[Simulation] Post best offer success',
  POST_BEST_OFFER_ERROR = '[Simulation] Post best offer error',

  CLEAR_STATUS_COUPON = '[Simulation] Clear Status Coupon',

  SET_SHOW_STEPPER = '[Simulation] Set Show Stepper'
}

export class GetSimulation implements Action {
  readonly type = SimulationActionTypes.GET_SIMULATION;
  constructor() {}
}

export class GetEnquadramento implements Action {
  readonly type = SimulationActionTypes.GET_FRAMEWORK;
  constructor(public payload: any) {}
}

export class GetEnquadramentoSuccess implements Action {
  readonly type = SimulationActionTypes.GET_FRAMEWORK_SUCCESS;
  constructor(public payload: boolean) {}
}

export class GetEnquadramentoError implements Action {
  readonly type = SimulationActionTypes.GET_FRAMEWORK_ERROR ;
  constructor(public payload: any) {}
}

export class ClearEnquadramento implements Action {
  readonly type = SimulationActionTypes.CLEAR_GET_FRAMEWORK;
  constructor() {}
}

export class GetSimulationSuccess implements Action {
  readonly type = SimulationActionTypes.GET_SIMULATION_SUCCESS;
  constructor(public payload: any) {}
}

export class GetSimulationError implements Action {
  readonly type = SimulationActionTypes.GET_SIMULATION_ERROR;
  constructor(public payload: any) {}
}

export class GetStatusProposals implements Action {
  readonly type = SimulationActionTypes.GET_STATUS_PROPOSALS;
  constructor(public payload: any) {}
}

export class GetStatusProposalsInc implements Action {
  readonly type = SimulationActionTypes.GET_STATUS_PROPOSALS_INC;
  constructor(public payload: any) {}
}

export class GetStatusProposalsSuccess implements Action {
  readonly type = SimulationActionTypes.GET_STATUS_PROPOSALS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetStatusProposalsError implements Action {
  readonly type = SimulationActionTypes.GET_STATUS_PROPOSALS_ERROR;
  constructor(public payload: any) {}
}

export class LoadingDismiss implements Action {
  readonly type = SimulationActionTypes.LOADING_DISMISS;
}

export class GetNewCalculation implements Action {
  readonly type = SimulationActionTypes.GET_NEW_CALCULATION;
  constructor(public payload: any) {}
}

export class GetNewCalculationStart implements Action {
  readonly type = SimulationActionTypes.GET_NEW_CALCULATION_START;
  constructor(public payload: any) {}
}
export class SaveCalculationPayload implements Action {
  readonly type = SimulationActionTypes.SAVE_CALCULATION_PAYLOAD;
  constructor(public payload: any) {}
}
export class GetNewCalculationSuccess implements Action {
  readonly type = SimulationActionTypes.GET_NEW_CALCULATION_SUCCESS;
  constructor(public payload: any) {}
}

export class GetNewCalculationError implements Action {
  readonly type = SimulationActionTypes.GET_NEW_CALCULATION_ERROR;
  constructor(public payload: any) {}
}

export class GetNppInstallments implements Action {
  readonly type = SimulationActionTypes.GET_NPP_INSTALLMENTS;
  constructor(public payload: any) {}
}
export class GetNppInstallmentsSuccess implements Action {
  readonly type = SimulationActionTypes.GET_NPP_INSTALLMENTS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetNppInstallmentsError implements Action {
  readonly type = SimulationActionTypes.GET_NPP_INSTALLMENTS_ERROR;
  constructor(public payload: any) {}
}

export class SetSimulationId implements Action {
  readonly type = SimulationActionTypes.SET_SIMULATION_ID;
  constructor(public payload: any) {}
}

export class ShowSimulationLoader implements Action {
  readonly type = SimulationActionTypes.SHOW_SIMULATION_LOADER;
  constructor(public payload: any) {}
}

export class DelayProposalStatus implements Action {
  readonly type = SimulationActionTypes.DELAY_PROPOSAL_STATUS;
  constructor(public payload: any) {}
}

export class PostSimulationRequest implements Action {
  readonly type = SimulationActionTypes.POST_SIMULATION_REQUEST;
  constructor(public payload: any) {}
}

export class PostSimulationSuccess implements Action {
  readonly type = SimulationActionTypes.POST_SIMULATION_SUCCESS;
  constructor(public payload: any) {}
}

export class RedirectStepRegister implements Action {
  readonly type = SimulationActionTypes.REDIRECT_STEP_REGISTER;
  constructor() {}
}

export class RedirectSimulationSummary implements Action {
  readonly type = SimulationActionTypes.REDIRECT_SIMULATION_SUMMARY;
  constructor() {}
}

export class PostSimulationError implements Action {
  readonly type = SimulationActionTypes.POST_SIMULATION_ERROR;
  constructor(public payload: any) {}
}


export class SetSimulationDueDate implements Action {
  readonly type = SimulationActionTypes.SET_SIMULATION_DUE_DATE;
  constructor(public payload: any) {}
}

export class GetSimulationParameters implements Action {
  readonly type = SimulationActionTypes.GET_SIMULATION_PARAMETERS;
  constructor(public payload: any) {}
}

export class GetSimulationParametersSuccess implements Action {
  readonly type = SimulationActionTypes.GET_SIMULATION_PARAMETERS_SUCCESS;
  constructor(public payload: any) {}
}
export class getBestOfferPayload implements Action {
  readonly type = SimulationActionTypes.GET_PAYLOAD_BESTOFFERS;
  constructor(public payload: any) {}
}
export class clearBestOfferPayload implements Action {
  readonly type = SimulationActionTypes.CLEAR_PAYLOAD_BESTOFFERS ;
  constructor() {}
}

export class GetSimulationParametersError implements Action {
  readonly type = SimulationActionTypes.GET_SIMULATION_PARAMETERS_ERROR;
  constructor(public payload: any) {}
}

export class ClearStatusProposal implements Action {
  readonly type = SimulationActionTypes.CLEAR_STATUS_PROPOSAL;
  constructor() {}
}

export class ClearAll implements Action {
  readonly type = SimulationActionTypes.CLEAR_ALL;
  constructor() {}
}
export class GetNewFlexInstallmentsCalculation implements Action {
  readonly type = SimulationActionTypes.GET_NEW_FLEX_INSTALLMENTS_CALCULATION;
  constructor(public payload: any) {}
}

export class GetOffer implements Action {
  readonly type = SimulationActionTypes.GET_OFFER;
  constructor(public payload: any) {}
}

export class GetOfferSuccess implements Action {
  readonly type = SimulationActionTypes.GET_OFFER_SUCCESS;
  constructor(public payload: any) {}
}

export class GetOfferError implements Action {
  readonly type = SimulationActionTypes.GET_OFFER_ERROR;
  constructor(public payload: any) {}
}

export class UpdateOffer implements Action {
  readonly type = SimulationActionTypes.UPDATE_OFFER;
  constructor(public payload: any) {}
}

export class UpdateOfferSuccess implements Action {
  readonly type = SimulationActionTypes.UPDATE_OFFER_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateOfferError implements Action {
  readonly type = SimulationActionTypes.UPDATE_OFFER_ERROR;
  constructor(public payload: any) {}
}

export class ValidateCoupon implements Action {
  readonly type = SimulationActionTypes.VALIDATE_COUPON;
  constructor(public payload: any) {}
}

export class ValidateCouponSuccess implements Action {
  readonly type = SimulationActionTypes.VALIDATE_COUPON_SUCCESS;
  constructor(public payload: any) {}
}

export class ValidateCouponError implements Action {
  readonly type = SimulationActionTypes.VALIDATE_COUPON_ERROR;
  constructor(public payload: any) {}
}

export class GetAllOffers implements Action {
  readonly type = SimulationActionTypes.GET_ALL_OFFERS;
  constructor(public payload: any) {}
}

export class GetAllOffersSuccess implements Action {
  readonly type = SimulationActionTypes.GET_ALL_OFFERS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetAllOffersError implements Action {
  readonly type = SimulationActionTypes.GET_ALL_OFFERS_ERROR;
  constructor(public payload: any) {}
}
export class SetSubOfferId implements Action {
  readonly type = SimulationActionTypes.SET_SUBOFFER_ID;
  constructor(public payload: any) {}
}
export class SetPayloadBestOffers implements Action {
  readonly type = SimulationActionTypes.SET_PAYLOAD_BESTOFFERS;
  constructor(public payload: any, public isReturn) {}
}
export class PostBestOffer implements Action {
  readonly type = SimulationActionTypes.POST_BEST_OFFER;
  constructor(public payload: any, public subOfferId: any) {}
}
export class PostBestOfferSuccess implements Action {
  readonly type = SimulationActionTypes.POST_BEST_OFFER_SUCCESS;
  constructor(public payload: any) {}
}
export class PostBestOfferError implements Action {
  readonly type = SimulationActionTypes.POST_BEST_OFFER_ERROR;
  constructor(public payload: any) {}
}

export class UpdateNppOffer implements Action {
  readonly type = SimulationActionTypes.UPDATE_NPP_OFFER;
  constructor(public payload: any) {}
}

export class UpdateNppOfferSuccess implements Action {
  readonly type = SimulationActionTypes.UPDATE_NPP_OFFER_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateNppOfferError implements Action {
  readonly type = SimulationActionTypes.UPDATE_NPP_OFFER_ERROR;
  constructor(public payload: any) {}
}

export class ClearStatusCoupon implements Action {
  readonly type = SimulationActionTypes.CLEAR_STATUS_COUPON;
  constructor() {}
}

export class SetShowStepper implements Action {
  readonly type = SimulationActionTypes.SET_SHOW_STEPPER;
  constructor(public payload: boolean) {}
}

export type SimulationActions =
  | PostSimulationRequest
  | PostSimulationSuccess
  | PostSimulationError
  | GetSimulation
  | GetSimulationSuccess
  | GetSimulationError
  | GetOffer
  | GetOfferSuccess
  | GetOfferError
  | UpdateOffer
  | UpdateOfferSuccess
  | UpdateOfferError
  | GetStatusProposals
  | GetStatusProposalsInc
  | GetStatusProposalsSuccess
  | GetStatusProposalsError
  | LoadingDismiss
  | GetNewCalculation
  | GetNewCalculationSuccess
  | GetNewCalculationError
  | SetSimulationId
  | ShowSimulationLoader
  | DelayProposalStatus
  | SetSimulationDueDate
  | GetSimulationParameters
  | GetSimulationParametersSuccess
  | GetSimulationParametersError
  | GetNewCalculationStart
  | RedirectStepRegister
  | ClearStatusProposal
  | SaveCalculationPayload
  | ClearAll
  | GetNewFlexInstallmentsCalculation
  | ValidateCoupon
  | ValidateCouponSuccess
  | ValidateCouponError
  | GetAllOffers
  | GetAllOffersError
  | GetAllOffersSuccess
  | SetSubOfferId
  | SetPayloadBestOffers
  | PostBestOffer
  | PostBestOfferSuccess
  | PostBestOfferError
  | GetNppInstallments
  | GetNppInstallmentsSuccess
  | GetNppInstallmentsError
  | UpdateNppOffer
  | UpdateNppOfferSuccess
  | UpdateNppOfferError
  | ClearStatusCoupon
  | GetEnquadramentoSuccess
  | GetEnquadramento
  | ClearEnquadramento
  | clearBestOfferPayload
  | getBestOfferPayload
  | SetShowStepper;
