import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterEvent } from '@angular/router';
import { AppState } from '@app/core/state';
import * as fromProposal from '@app/core/state/proposal';
import * as fromProposalInformation from '@app/core/state/proposal-information';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { SetPaymentPlanSelectedAction } from '@app/core/state/proposal/proposal.actions';
import * as fromSimulation from '@app/core/state/simulation';
import { selectors as simulationSelectors } from '@app/core/state/simulation';
import { GetEnquadramento, GetStatusProposals } from '@app/core/state/simulation/simulation.actions';
import { getAllOffers, getBestOffer, payloadBestOffers, recalculateNpp, selectStatusProposal } from '@app/core/state/simulation/simulation.selectors';
import { Offers } from '@app/proposal-workflow/containers/step-offers/model/offer.model';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { ProposalMacroStage } from '@app/proposal-workflow/models/proposal-stage.model';
import { SidebarConditions } from '@app/proposal-workflow/models/sidebar-data.model';
import { BroadcastPaymentMethodService } from '@app/proposal-workflow/service/broadcast-payment-method.service';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { delay, filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'simulation-sidebar-npp',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarNppComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() sidebarData: SidebarConditions;
  public pioneerMasks: PioneerMasks;
  public payload: any;
  public proposalData: any;
  public proposal: ConfirmationData;
  public frequencedescription: any;

  public paymentType = 'Boleto bancário';

  public paymentShippingTypeId: number;
  public paymentShippingTypeDescription: string;
  public financedValue: number;
  public initialFinancedValue: number;
  public processing: string;

  installmentAmount: number;
  installmentValue: number;
  installmentAmountDouble: number;
  installmentValueDouble: number;

  public cardVisible: boolean;

  public rate = '';
  public proposalStage = ProposalMacroStage;

  public currentUrl: string;
  public currentStep = null;

  public simulationSidebar = false;

  public show = false;
  public in = false;

  @Input() public identification: any;
  @Input() public offer: any;
  @Input() public dueDate: string;
  simulationData: any;
  simulationParams: any;
  public productCode = ProductCodeEnum;

  /**
   * Data to adjust size
   */
  public innerWidth: any;
  public innerHeight: any;
  public MOBILE_BREAKPOINT = 768;
  public isMobile: boolean;

  zoom: string;

  public showStoreConditions = true;

  public offers: Offers;
  public allOffers: any;
  public bestOffer: any;
  public proposalId;

  @Input() category: string;
  constructor(
    private internationalizationService: InternationalizationService,
    private simulationService: SimulationService,
    private broadcastPaymentMethodService: BroadcastPaymentMethodService,
    private store$: Store<AppState>,
    public router: Router,
    private updates$: Actions
  ) {
    this.innerWidth = window.screen.width;
    this.isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;
  }

  ngOnInit() {
    this.pioneerMasks = this.internationalizationService.pioneerMasks;
    this.getCurrentUrl();
    this.getProposalId();
    this.showSimulationSidebar();
    this.getProposalStage();
    this.simulationStep();
    this.findZoomRange(window.innerHeight);
    this.getPersonalEmail();
  }

  private getPersonalEmail(): void {
    this.store$
      .select(fromProposalInformation.selectors.personalEmailSelector)
      .pipe(
        filter(email => !!email),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(() => {
        this.getConfirmationData(this.proposalId);
      });
  }

  private getCurrentUrl() {
    this.currentUrl = this.router.url;
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: RouterEvent) => {
      this.currentUrl = event.url ? event.url : this.currentUrl;
      this.getProposalId();
      this.showSimulationSidebar();
    });
  }

  public getProposalId(): void {
    this.store$
      .select(fromProposal.selectors.selectProposalId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(proposalId => !!proposalId)
      )
      .subscribe(proposalId => {
        this.proposalId = proposalId;
        if (
          this.currentUrl === '/proposal/step-register' ||
          this.currentUrl === '/proposal/step-formalization' ||
          this.currentUrl === '/proposal/step-confirmation' ||
          this.currentUrl === '/proposal/step-simulation/summary' ||
          this.currentUrl === '/proposal/step-offer'
        ) {
          this.getConfirmationData(proposalId.toString());
          this.getProposalInformationData();
        } else if (this.currentUrl === '/proposal/step-simulation') {
          this.getConfirmationData(proposalId.toString());
          this.getProposalInformationData();
          this.simulationStep();
        }
      });
  }

  private getConfirmationData(proposalId: string): void {
    this.store$.dispatch(new fromProposalInformation.actions.GetConfirmationData(proposalId));
  }

  private showSimulationSidebar() {
    if (this.currentUrl === '/proposal/step-simulation' || this.currentUrl === '/proposal/step-offer') {
      this.simulationSidebar = true;
    } else {
      this.simulationSidebar = false;
    }
  }

  public getProposalInformationData() {
    this.store$
      .select(getConfirmationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: ConfirmationData) => {
        if (state) {
          this.proposal = state;
          this.setSelectedPlan(state);
          if (this.currentUrl === '/proposal/step-offer') {
            this.selectOffersData();
          }
        }
      });
  }

  public setSelectedPlan(res) {
    this.store$.select(getBestOffer).pipe(filter(r=> !!r)).subscribe(
      res => {
      this.frequencedescription = res.bestOffer.paymentMethods[0].monthsRange[0].description
    })
    this.store$.dispatch(
      new SetPaymentPlanSelectedAction({
        code: res.planTypeSchemaData.planTypeCode,
        firstInstallmentPMethodsDate: moment(res.planTypeSchemaData.monthOccurrency).format('MM/YYYY'),
        installmentDefaultValue: res.installmentValue,
        installmentPaymentMethodsValue: res.planTypeSchemaData.installmentPaymentMethodsValue,
        firstInstallmentValue: res.planTypeSchemaData.firstInstallmentValue,
        lastInstallmentValue: res.planTypeSchemaData.lastInstallmentValue,
        installmentDefaultAmount: res.planTypeSchemaData.installmentDefaultAmount,
        installmentPaymentMethodsAmount: res.planTypeSchemaData.installmentPaymentMethodsAmount,
        monthsRange: this.frequencedescription,
        hasFlexInstallments: res.planTypeSchemaData.hasFlexInstallments,
        installments: this.simulationData ? this.simulationData.installments : []
      })
    );
  }

  private getProposalStage(): void {
    this.store$
      .select(fromProposal.selectors.selectProposalStage)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(state => {
        if (!state || !state.groupId || (state && state.groupId === ProposalMacroStage.SIMULATION)) {
          this.currentStep = ProposalMacroStage.SIMULATION;
          return;
        }

        if (state && state.groupId === ProposalMacroStage.FORMALIZATION) {
          this.currentStep = ProposalMacroStage.FORMALIZATION;
          return;
        }

        if (state && state.groupId === ProposalMacroStage.REGISTER) {
          this.currentStep = ProposalMacroStage.REGISTER;
          return;
        }

        if (state && state.groupId === ProposalMacroStage.CONFIRMATION) {
          this.currentStep = ProposalMacroStage.CONFIRMATION;
          return;
        }
      });
  }

  private simulationStep(recalculate = false) {
    this.store$
      .select(simulationSelectors.getBestOffer)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(bestOffer => {
        this.processing = bestOffer?.messages?.find(res => res?.message == 'PROCESSING')?.message;
        if (this.processing === 'PROCESSING') {
          const payload = {
            simulationId: this.proposalId,
            redirectProposal: false,
            recalculateNpp: true,
          }

          this.store$.dispatch(new GetStatusProposals(payload));
          this.store$.select(selectStatusProposal).pipe(take(1), takeUntil(this.ngUnsubscribe),delay(30000)).subscribe(res => {
            if (res.status === 'COMPLETED') {
              this.store$.select(recalculateNpp).pipe(takeUntil(this.ngUnsubscribe)).subscribe()
              this.store$.select(payloadBestOffers).pipe(takeUntil(this.ngUnsubscribe)).subscribe(res=>{
                this.payload= res;
              })
              this.store$.dispatch(new fromSimulation.actions.PostBestOffer(this.proposalId, this.payload));
              return;
            }

          })
        }
          setTimeout(() => {
            this.store$.dispatch(new GetEnquadramento(this.proposalId));
        }, 5000);

        this.bestOffer = bestOffer && bestOffer.bestOffer ? bestOffer.bestOffer : null;

        if (this.bestOffer) {
          let offerSimulation = {};

          offerSimulation = {
            proposalId: this.proposalId,
            purchaseValue: +this.bestOffer.entryValue + +this.bestOffer.financedValue,
            entryValue: +this.bestOffer.entryValue,
            financedValue: +this.bestOffer.financedValue,
            couponCode: this.proposal.cupom,
            shopkeeperData: this.bestOffer.shopkeeperData,
            simulationOffer: []
          };

          this.proposalData = {
            financedValue: this.bestOffer.financedValue,
            personalData: this.proposal.personalData,
            offersData: offerSimulation,
            calculationLimit: this.proposal.calculationLimit, // this.proposal.calculationLimit
            hasFlexInstallments: false, // this.simulationData.paymentMethods[0].hasFlexInstallments
            planType: this.bestOffer.flexPlanType, // this.simulationData.paymentPlan
            installmentAmount: this.bestOffer.term,
            installmentValue: this.bestOffer.defaultInstallmentValue, // this.simulationData.paymentMethods[0].lastInstallmentValue
            productCode: this.proposal.proposalData.product,
            stepOffer: false
          };
        }
      });

    this.store$
      .select(simulationSelectors.selectSimulationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        if (res) {
          this.simulationData = res;
          let offerSimulation = {};

          if (recalculate) {
            offerSimulation = {
              proposalId: this.proposalId,
              purchaseValue: +res.entryValue + +res.financedValue,
              entryValue: +res.entryValue,
              financedValue: +res.financedValue,
              couponCode: res.cupom,
              shopkeeperData: res.shopkeeperData, //  this.bestOffer.shopkeeperData,
              simulationOffer: []
            };
          } else {
            offerSimulation = {
              proposalId: this.proposalId,
              purchaseValue: +this.proposal.purchaseValue,
              entryValue: +this.proposal.entryValue,
              financedValue: +this.proposal.financedValue,
              couponCode: this.proposal.cupom,
              shopkeeperData: res.shopkeeperData, // this.bestOffer.shopkeeperData,
              simulationOffer: []
            };
          }
          this.proposalData = {
            financedValue: res.financedValue,
            personalData: this.proposal.personalData,
            offersData: offerSimulation,
            calculationLimit: this.proposal.calculationLimit,
            hasFlexInstallments: this.simulationData.paymentMethods[0].hasFlexInstallments,
            planType: this.simulationData.paymentPlan,
            installmentAmount: this.simulationData.installmentAmount,
            installmentValue: this.simulationData.paymentMethods[0].lastInstallmentValue,
            productCode: this.proposal.proposalData.product,
            stepOffer: false
          };
        }
      });
  }

  public findZoomRange(innerHeight: number) {
    switch (true) {
      case innerHeight >= 813:
        this.zoom = 'oneHundredPerCent';
        break;
      case innerHeight >= 762:
        this.zoom = 'ninetyNinePercent';
        break;
      case innerHeight >= 678:
        this.zoom = 'ninetySevenPercent';
        break;
      case innerHeight >= 610:
        this.zoom = 'ninetyFourPercent';
        break;
      case innerHeight >= 550:
        this.zoom = 'ninetyTwoPercent';
        break;
      case innerHeight < 550:
        this.zoom = 'ninetyPercent';
        break;

      default:
        break;
    }
  }

  @HostListener('window:resize', ['$event'])
  public resizeListener() {
    this.findZoomRange(window.innerHeight);
  }

  public handleShowStoreConditions(event: boolean): void {
    this.showStoreConditions = event;
  }

  selectOffersData() {
    this.store$
      .select(getAllOffers)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(state => {
        if (state) {
          this.allOffers = state;
          this.mountProposalDataOffers();
        } else {
          this.getOffer();
        }
      });
  }

  recalculateValues(event) {
    if (event.purchaseValue > -1 && event.entryValue > -1) {
      this.updateOffer(event.purchaseValue, event.entryValue, event.financedValue);
      return;
    }
  }

  public updateOffer(purchaseValue?, entryValue?, financedValue?) {
    const payload = {
      proposalId: parseInt(this.proposal.proposalData.id),
      purchaseValue: purchaseValue,
      entryValue: entryValue,
      financedValue: financedValue,
      paymentFormType: this.proposal.liquidationTypeCode
    };
    this.store$.dispatch(new fromSimulation.actions.UpdateNppOffer(payload));
  }

  saveConfirmationData(entryValue, purchaseValue) {
    let confirmationData: ConfirmationData;

    this.store$
      .select(getConfirmationData)
      .pipe(take(1))
      .subscribe(res => (confirmationData = res));

    confirmationData.entryValue = entryValue;
    confirmationData.purchaseValue = purchaseValue;

    this.store$.dispatch(new fromProposalInformation.actions.SaveConfirmationData(confirmationData));
    this.getConfirmationData(this.proposalId);
  }

  public getOffer() {
    this.store$.dispatch(new fromSimulation.actions.GetAllOffers(parseInt(this.proposal.proposalData.id)));
  }

  mountProposalDataOffers() {
    if (this.proposal && this.allOffers) {
      const offer = this.allOffers.offers[0];

      const offerNpp = {
        proposalId: this.proposalId,
        purchaseValue: offer ? +offer.purchaseValue : 0,
        entryValue: offer ? +offer.entryValue : 0,
        financedValue: offer ? +offer.financedValue : 0,
        couponCode: this.proposal.cupom,
        shopkeeperData: offer ? offer.shopkeeperData : this.proposal.shopkeeperData,
        simulationOffer: []
      };

      this.proposalData = {
        financedValue: offer ? +offer.financedValue : 0,
        personalData: this.proposal.personalData,
        offersData: offerNpp,
        calculationLimit: this.proposal.calculationLimit,
        stepOffer: true,
        productCode: this.proposal.proposalData.product
      };
    }
  }

  public subsidyChanged(event: any) {
    const payload = {
      subOfferId: this.bestOffer.subOfferId,
      subsidyPercentage: event.subsidiarySlider
    };
    this.store$.dispatch(new fromSimulation.actions.PostBestOffer(this.proposalId, payload));
    return;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
