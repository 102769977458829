import { Component, Input, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import { SaveProductAction } from '@app/core/state/identification/identification.actions';
import { userProfileSelector } from '@app/core/state/profile/profile.selectors';
import * as fromProposal from '@app/core/state/proposal';
import * as fromProposalInformation from '@app/core/state/proposal-information';
import {
  ClearNegotiationProposal,
  NegotiationProposal
} from '@app/core/state/proposal-negotiation/proposal-negotiation.actions';
import { ProposalNegotiationState } from '@app/core/state/proposal-negotiation/proposal-negotiation.reducer';
import { selectProposalNegotiation } from '@app/core/state/proposal-negotiation/proposal-negotiation.selectors';
import { ResetShowcaseAction } from '@app/core/state/showcase/showcase.actions';
import * as fromSimulation from '@app/core/state/simulation';
import { DownloadService } from '@app/esign/services/download.service';
import { ConfirmationContractResponse } from '@app/proposal-workflow/models/step-register-requests';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { ProposalCancelDialogComponent } from '@app/showcase/proposals/components/proposal-cancel-dialog/proposal-cancel-dialog.component';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/components/widgets/alert/alert.service';
import { NegotiationDetailModel } from '@shared/models/negotiation-detail.model';
import { NegotiationModel } from '@shared/models/negotiation.model';
import { IdleDetectService } from '@shared/services/idle-detect/idle-detect.service';
import { Observable, Subject } from 'rxjs';
import { takeUntil, withLatestFrom } from 'rxjs/operators';
import { Negotiation, NegotiationDetailEnum } from './models/negotiation-detail.model';
import { ProposalDetailsService } from './service/proposal-details.service';

@Component({
  selector: 'app-proposal-details',
  templateUrl: './proposal-details.component.html',
  styleUrls: ['./proposal-details.component.scss']
})
export class ProposalDetailsComponent implements OnInit, OnDestroy {
  @Input() src;
  protected ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild(MatAccordion) accordion: MatAccordion;

  public window = window;
  closed = false;
  alert = { type: '', message: '', icon: '' };
  public isOpen = true;
  currentColor: any;

  // Get data
  status: any;
  data: any;
  proposalGroups: any;
  additionalInformation: any;
  negotiationDetail: NegotiationDetailModel;
  showMinuta = false;
  splitFlow: any;
  canCancel = false;
  public proposals;
  public searchString: string;
  private filterObj: any = {
    period: '-1d'
  };
  totalElements: number;
  totalPages: number;
  page = 1;
  perPage = 30;
  initialPage = 1;
  public continueButtonLabel = '';
  public isProposalStatusAnaliseDocumental = false;
  private ANALISE_DOCUMENTAL = 'ANÁLISE DOCUMENTAL';

  // Current store
  shopId: any;
  public warningAlertClosedRejected = false;
  public warningAlertClosedExpired = false;
  public warningAlerterrorDescription = false;
  public showCETLink = false;
  public showAditionalInfo = false;

  private snackbarConfig: MatSnackBarConfig = {
    duration: 5000
  };

  public comments = '';
  public showAlert = false;

  public analysisShow = false;

  readonly category = `/portallojista/proposta/`;
  readonly openModal: Tag = Tag.AbriuModal;

  public maxPoolingNegotiation = Number(environment.maxAttemptsSimulation);
  public negotiation: Negotiation = {
    installmentValue: null,
    interestRate: null,
    retentionPercentage: null,
    negotiationStatus: null,
    status: null
  };
  public isNppNegotiationFormDisabled = false;
  private selectProposalNegotiation$: Observable<ProposalNegotiationState>;

  constructor(
    private _proposalDetails: ProposalDetailsService,
    private _downloadService: DownloadService,
    public alertService: AlertService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<ProposalDetailsComponent>,
    private router: Router,
    private renderer: Renderer2,
    private idleDetectService: IdleDetectService,
    private genTagger: GenTagger,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private simulationService: SimulationService,
    private store$: Store<AppState>
  ) {}

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.clearProposalData();
    this.getInfo();
    this.idleDetectService
      .watcher()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.dialogRef.close();
        if (this.dialog) {
          this.dialog.closeAll();
        }
      });

    this.getAnalysisCd();
  }

  setTaggers() {
    this.warningAlertClosedRejected = true;
    if (this.data) {
      this.genTagger.setTag({
        event_category: this.category + this.data.proposalId,
        event_label: 'detalhes da proposta',
        event_action: Tag.pgView
      });

      if (this.warningAlertClosedRejected) {
        this.genTagger.setTag({
          event_category: this.category + this.data.proposalId,
          event_label: `A proposta ${this.data.proposalId} foi negada.`,
          event_action: `${Tag.AbriuModal} - A proposta ${this.data.proposalId} foi negada.`
        });
      }

      if (this.warningAlertClosedExpired) {
        this.genTagger.setTag({
          event_category: this.category + this.data.proposalId,
          event_label: `A proposta ${this.data.proposalId} se encontra expirada.`,
          event_action: `${Tag.AbriuModal} - A proposta ${this.data.proposalId} se encontra expirada.`
        });
      }
    }
  }

  getInfo() {
    this._proposalDetails
      .getInfoUser(this.src)
      .pipe(withLatestFrom(this.store$.select(userProfileSelector)))
      .subscribe(
        ([data, userProfile]) => {
          this.data = data;
          this.status = data.status;
          this.proposalGroups = data.proposalGroups;
          this.additionalInformation = data.additionalInformation;
          this.negotiationDetail = data.negotiation;
          this.splitFlow = data.splitFlow;
          const groupPayment = data.proposalGroups.find(group => !!group && group.groupName === 'PAGAMENTO');
          this.showAlert = groupPayment.color === 'GREEN' ? false : true;
          this.setTaggers();
          this.handleNppNegotiation();

          const isPaymentUser = userProfile.userProfiles.some(
            role => role.includes('MASTER') || role.includes('FINANCEIRO')
          );

          if (
            this.status.description === 'Análise Documental' ||
            this.status.description === 'Proposta em análise' ||
            this.status.description === 'Em Negociação'
          ) {
            const groupConfirmation = this.proposalGroups.find(group => !!group && group.groupName === 'CONFIRMAÇÃO');
            if (groupConfirmation.color !== 'BLANK') {
              this.showCETLink = true;
            }
          }

          if (this.status.description === 'Proposta Negada') {
            this.isOpen = false;
          }
          const currentGroup = this.proposalGroups.find(group => !!group && group.color === 'YELLOW');
          this.currentColor = currentGroup;
          const groupName = currentGroup ? currentGroup.groupName : '';
          const capitalizedGroupName = groupName ? `${groupName[0]}${groupName.slice(1).toLowerCase()}` : '';

          if (capitalizedGroupName.length) {
            if (capitalizedGroupName.toLowerCase() === 'pagamento') {
              if (isPaymentUser) {
                this.continueButtonLabel = `Continuar para ${capitalizedGroupName}`;
              } else {
                this.continueButtonLabel = undefined;
              }
            } else {
              this.continueButtonLabel = `Continuar para ${capitalizedGroupName}`;
            }
          }
          this.showMinuta =
            this.data.proposalGroups[0] !== undefined &&
            this.data.proposalGroups[0].date !== undefined &&
            this.data.proposalGroups[0].color === 'GREEN';

          this.checkIsStatusPropostaAnaliseDocumental();

          if (this.additionalInformation !== undefined && this.additionalInformation !== null) {
            this.hasShowAditionalInfo();
            this.formatComments();
          }
        },
        error => {
          this.alertService.addAlertError(error);
        }
      );
  }

  private getAnalysisCd() {
    this.simulationService
      .getProposalAnalysisCd(this.src)
      .toPromise()
      .then(v => (this.analysisShow = v));
  }

  public closeAlert() {
    this.warningAlertClosedRejected = true;
    this.genTagger.setTag({
      event_category: this.category + this.data.proposalId,
      event_label: 'x',
      event_action: `${Tag.Fechou_Modal} - A proposta ${this.data.proposalId} foi negada.`
    });
  }

  private handleNppNegotiation(): void {
    this.isNppNegotiationFormDisabled = !!this.data && !this.data.negotiationCanEdit;
    this.store$.dispatch(new ClearNegotiationProposal());

    if (!this.selectProposalNegotiation$) {
      this.selectProposalNegotiation$ = this.store$.select(selectProposalNegotiation);
      this.selectProposalNegotiation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(state => {
        if (state.status === NegotiationDetailEnum.COMPLETED) {
          this.negotiation = state;
          this.getInfo();
        } else {
          this.negotiation.installmentValue = this.data.installmentValue;
          this.negotiation.interestRate = this.data.interestRate;
          this.negotiation.retentionPercentage = this.data.retentionPercentage;
        }
      });
    }
  }

  checkIsStatusPropostaAnaliseDocumental() {
    if (
      this.status !== '' &&
      this.status !== undefined &&
      this.status.description !== '' &&
      this.status.description !== undefined
    ) {
      this.isProposalStatusAnaliseDocumental =
        this.status.description.toUpperCase().trim() === this.ANALISE_DOCUMENTAL ? true : false;
    }
  }

  continueProposta() {
    if (this.continueButtonLabel !== undefined) {
      if (this.continueButtonLabel.toUpperCase().trim() !== 'CONTINUAR PARA PAGAMENTO') {
        this.store$.dispatch(new SaveProductAction({ code: this.data.productCode } as any));
        this.router.navigate(['/proposal'], { queryParams: { id: this.src } });
      } else {
        localStorage.setItem('idPaymentFromProposal', this.src);
        this.router.navigate(['/showcase/payments']);
      }
    }

    this.dialogRef.close('Continue Proposal');
  }

  saveNegotiation(negotiation: NegotiationModel): void {
    const element = this.renderer.selectRootElement('.modal-header', true);
    this._proposalDetails.saveNegotiation(this.src, negotiation.type, negotiation.description).subscribe(
      () => {
        this.alert.type = 'sucess';
        this.alert.message = 'Pedido de negociação de taxa aprovado.';
        this.alert.icon = 'check_circle';
        this.genTagger.setTag({
          event_category: this.category + this.data.proposalId,
          event_label: 'Pedido de negociação de taxa aprovado.',
          event_action: Tag.pgView
        });
      },
      error => {
        this.alert.type = 'error';
        this.alert.message = 'Pedido de negociação de taxa negado.';
        this.alert.icon = 'warning';
        this.alertService.addAlertError(error);
        this.genTagger.setTag({
          event_category: this.category + this.data.proposalId,
          event_label: 'Pedido de negociação de taxa negado.',
          event_action: Tag.pgView
        });
      },
      () => {
        this.getInfo();
        this.closed = true;
        element.scrollIntoView({ behavior: 'smooth' });

        setTimeout(() => {
          this.closed = false;
        }, 3000);
      }
    );
  }

  saveNppNegotiation(negotiationPayload: any): void {
    const payload = {
      proposalId: this.data.proposalId,
      negotiation: negotiationPayload
    };
    this.store$.dispatch(new NegotiationProposal(payload));
  }

  cancelProposal() {
    const ref = this.dialog.open(ProposalCancelDialogComponent, {
      width: '560px',
      autoFocus: false,
      data: {
        id: this.data.proposalId,
        userName: this.data.nameClient,
        value: this.data.financingValue,
        amountParcels: this.data.installmentAmount,
        valueParcels: this.data.installmentValue,
        routedBy: 'open-proposal'
      }
    });
    ref.componentInstance.category = this.category;
    ref.componentInstance.okExit.subscribe(() => {
      this.dialogRef.close('cancelProposal');
    });
  }

  emitCarnet() {
    this._proposalDetails.emitCarnet(this.src).subscribe(
      response => {
        if (!response) return;

        const blob = this._downloadService.base64toBlob(response.data, response.mimeType);
        this._downloadService.saveBlob(blob, `${response.name}.pdf`);
      },
      error => {
        this.snackBar.open(this.translateService.instant('PROPOSAL-DETAIL-RESPONSE-ERROR'), null, this.snackbarConfig);
        this.alertService.addAlertError(error);
      }
    );
  }

  public openCard() {
    this.isOpen = !this.isOpen;
    this.warningAlertClosedRejected = false;
    this.warningAlertClosedExpired = false;
  }

  public getProposalContract() {
    // minuta
    console.log(this.data, this.negotiationDetail);
    this._proposalDetails.downloadMinuta(this.data.proposalId).subscribe((res: ConfirmationContractResponse) => {
      const linkSource = `data:${res.mimeType};base64,${res.data}`;
      const downloadLink = document.createElement('a');
      const fileName = `${res.name}.pdf`;
      downloadLink.href = linkSource;
      downloadLink.download = fileName;
      downloadLink.click();
    });
  }

  private hasShowAditionalInfo(): void {
    this.showAditionalInfo = false;
    if (
      this.additionalInformation.nameTraveler ||
      this.additionalInformation.documentNumber ||
      this.additionalInformation.birthdate ||
      this.additionalInformation.orderNumber ||
      this.additionalInformation.comment.length > 0
    ) {
      this.showAditionalInfo = true;
    }
  }

  private formatComments() {
    this.additionalInformation.comment.forEach(comment => {
      this.comments += comment.concat(';\n');
    });
  }

  private clearProposalData(): void {
    this.store$.dispatch(new fromProposal.actions.ClearChecklistAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalStageAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalAction());
    this.store$.dispatch(new fromSimulation.actions.ClearAll());
    this.store$.dispatch(new fromProposalInformation.actions.ClearProposalInformationAction());
    this.store$.dispatch(new ResetShowcaseAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalIdAction());
  }
}
