import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { InstallmentsModalComponent } from '@app/proposal-workflow/components/installments-modal/installments-modal.component';

export interface DialogData {
  summary: any;
  country: string;
}

@Component({
  selector: 'app-summary-modal',
  templateUrl: './summary-modal.component.html',
  styleUrls: ['./summary-modal.component.scss']
})
export class SummaryModalComponent implements OnInit {
  public peruPlansIDs = {
    DOUBLE: 1,
    SIMPLE: 2
  };

  constructor(
    // @Inject(MAT_DIALOG_DATA) public modalData: DialogData,
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public matDialog: MatDialog
  ) {}

  ngOnInit() {
    console.log('', this.modalData.summary);
  }

  closeDialog() {
    this.matDialog.closeAll();
  }

  openInstallmentsModal() {
    let dialogConfig = new MatDialogConfig();

    dialogConfig = {
      width: this.modalData.isMobile ? '100%' : '38%',
      maxWidth: '612px',
      maxHeight: '655px',
      id: 'pion-side-modal',
      panelClass: 'installments-modal-container',
      height: this.modalData.isMobile ? '100%' : '38%',
      autoFocus: false,
      position: {
        right: '0px',
        top: '0px'
      },
      data: {
        installments: this.modalData.summary.installments
      }
    };

    this.matDialog.open(InstallmentsModalComponent, dialogConfig);
  }
}
