import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import * as fromProposal from '@app/core/state/proposal';
import { selectPendingProposal } from '@app/core/state/proposal/proposal.selectors';
import { ProceedErrorModalComponent } from '@app/proposal-workflow/containers/step-register/components/proceed-error/proceed-error-modal.component';
import { DocumentTypes } from '@app/proposal-workflow/models/document-types.enum';
import { OsaCodeErrorsEnum } from '@app/proposal-workflow/models/osa-code-errors.enum';
import { ProceedErrorModalData } from '@app/proposal-workflow/models/proceed-error-modal-data.model';
import { BankSignUpData } from '@app/proposal-workflow/models/proposal-data.model';
import {
  AccountValidatorRequest,
  AccountValidatorResponse
} from '@app/proposal-workflow/models/step-register-requests';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, map, takeUntil } from 'rxjs/operators';

const SANTANDER_BANK_CODE = '33';

@Component({
  selector: 'app-pendency-payment-error',
  templateUrl: './pendency-payment-error.component.html',
  styleUrls: ['./pendency-payment-error.component.scss']
})
export class PendencyPaymentErrorComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Output() bankDataForm = new EventEmitter<BankSignUpData>(null);
  public bankData: BankSignUpData;
  public documentNumber: string;
  public proposalId: string;
  public items: any;
  public formalization = false;
  public pendencyBankErrorMsg = 'PENDENCY-BANK-ERROR-MSG';
  public pendencyBankErrorMsgType = 'warning';

  constructor(
    private simulationService: SimulationService,
    private store$: Store<AppState>,
    public matDialog: MatDialog
  ) {}

  public ngOnInit(): void {
    this.getItems();
    this.setPendingProposalSubscription();
  }

  public getItems(): void {
    this.simulationService
      .getParametersProposal()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(items => (this.items = items));
  }

  public setPendingProposalSubscription(): void {
    this.store$
      .select(selectPendingProposal)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(
          pendingProposal =>
            !!pendingProposal &&
            !!pendingProposal.getProposalRegisterDataByCode &&
            !!pendingProposal.getProposalRegisterDataByCode.pioneerData
        ),
        map(pendingProposal => pendingProposal.getProposalRegisterDataByCode)
      )
      .subscribe(proposal => {
        const pioneerData = proposal.pioneerData;
        const propData = proposal.proposalData;

        this.bankData = pioneerData.bankAccountData ? pioneerData.bankAccountData : null;
        this.documentNumber =
          pioneerData.personalData && pioneerData.personalData.documentNumber
            ? pioneerData.personalData.documentNumber
            : null;
        this.proposalId = propData ? propData.id : null;
      });
  }

  public sendBankData(data: BankSignUpData) {
    if (!data || !this.isAccountDifferent(data)) {
      return;
    }

    this.validateBankAccount(data);
  }

  public validateBankAccount(data: BankSignUpData) {
    if (data.bankCode !== SANTANDER_BANK_CODE) {
      this.bankDataForm.emit(data);
      return;
    }

    const payload: AccountValidatorRequest = {
      accountNumber: data.accountCode,
      branchCode: data.agencyCode,
      documentType: DocumentTypes.CPF,
      userDocument: this.documentNumber
    };

    this.simulationService
      .proposalAccountValidator(payload)
      .pipe(
        first(),
        distinctUntilChanged((x, y) => JSON.stringify(x) !== JSON.stringify(y))
      )
      .subscribe((res: AccountValidatorResponse) => {
        if (!!res && res.status !== 'OK') {
          const modalData: ProceedErrorModalData = {
            title: 'STEP-REGISTER-ERROR-PROCEED-MODAL-TITLE',
            msg: 'STEP-REGISTER-ERROR-PROCEED-MODAL-SUBTITLE',
            btnLabel: 'Exit'
          };

          this.openProceedModal(modalData);
          return;
        }

        this.bankDataForm.emit(data);
      });
  }

  public openProceedModal(data?: ProceedErrorModalData) {
    const dialogRef = this.matDialog.open(ProceedErrorModalComponent, {
      width: window.innerWidth <= 768 ? '80%' : '600px',
      maxWidth: '100%',
      data
    });

    dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe(() => {
        this.pendencyBankErrorMsg = 'PENDENCY-BANK-ERROR-DENIED-MSG';
        this.pendencyBankErrorMsgType = 'error';

        if (data.errorCode === OsaCodeErrorsEnum.INVALID_EMAIL) {
          this.pendencyBankErrorMsg = 'PENDENCY-BANK-ERROR-EMAIL-MSG';
        }
        if (data.errorCode === OsaCodeErrorsEnum.DENIED) {
          this.pendencyBankErrorMsg = 'PENDENCY-BANK-ERROR-DENIED-MSG';
        }
      });
  }

  private isAccountDifferent(bankForm: BankSignUpData): boolean {
    if (!this.bankData) return true;

    return (
      bankForm.accountCode !== this.bankData.accountCode ||
      bankForm.accountTypeId !== this.bankData.accountTypeId ||
      bankForm.bankCode !== this.bankData.bankCode
    );
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.store$.dispatch(new fromProposal.actions.ClearChecklistAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalStageAction());
    this.store$.dispatch(new fromProposal.actions.ClearProposalIdAction());
  }
}
