import { AppState } from '@app/core/state';
import { createSelector } from '@ngrx/store';

export const identificationSelector = (state: AppState) => state.identification;

export const selectUserData = createSelector(
  identificationSelector,
  state => state.userData
);

export const selectRenderConfig = createSelector(
  identificationSelector,
  state => state.renderConfig
);

export const selectSubmitResponse = createSelector(
  identificationSelector,
  state => state.submitResponse
);

export const selectManagers = createSelector(
  identificationSelector,
  state => state.managers
);

export const selectProduct = createSelector(
  identificationSelector,
  state => state.selectedProduct
);
