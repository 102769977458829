import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatBottomSheet, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatDrawer } from '@angular/material/sidenav';
import { AppState } from '@app/core/state';
import * as notificationsAction from '@app/core/state/notifications/notifications.actions';
import { selectAllNotifications } from '@app/core/state/notifications/notifications.selectors';
import { GenTagger } from '@app/tagging/gen-tagger';
import { select, Store } from '@ngrx/store';
import { IdleDetectService } from '@shared/services/idle-detect/idle-detect.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BottomSheetNotificationComponent } from './components/bottom-sheet-notification/bottom-sheet-notification.component';
import { NotificationModel, NotificationsFiltered } from './model/notification.model';
import { NotificationService } from './services/notification.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit, OnDestroy {
  public window = window;
  @ViewChild('drawer') drawer: MatDrawer;
  notificationOptionMenu: MatBottomSheetRef;

  public data: NotificationsFiltered = new NotificationsFiltered();
  protected ngUnsubscribe: Subject<any> = new Subject();

  readonly category = '/portallojista/notificacoes';

  @Input() public isNotificationsOpened = true;

  constructor(
    private store$: Store<AppState>,
    private readonly notificationService: NotificationService,
    private _bottomSheet: MatBottomSheet,
    private idleDetectService: IdleDetectService,
    private genTagger: GenTagger
  ) {}

  ngOnInit() {
    this.getData();

    this.idleDetectService
      .watcher()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(res => {
        this.drawer.close();
        if (this.notificationOptionMenu) {
          this.notificationOptionMenu.dismiss();
        }
      });

    this.genTagger.setTag({
      event_category: this.category,
      event_action: 'Abriu modal - Notificações',
      event_label: 'Notificações'
    });

    this.genTagger.setTag({
      event_category: this.category,
      event_action: 'pageview',
      event_label: 'Notificações'
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public hiddenComponent(): void {
    this.isNotificationsOpened = false;
  }

  public getData(): void {
    this.store$.dispatch(new notificationsAction.GetNotifications());

    this.store$
      .pipe(select(selectAllNotifications))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(notifications => {
        setTimeout(() => {
          this.data = notifications;
        }, 200);
      });
  }

  public deleteAllCards() {
    this.store$.dispatch(new notificationsAction.DeleteAllNotifications());
  }

  public markAllAsReaded() {
    this.store$.dispatch(new notificationsAction.MarkAllNotificationsRead());
  }

  public deleteCard(item: NotificationModel, type: string) {
    this.genTagger.setTag({
      event_action: `clicou – ${item.title}`,
      event_category: this.category,
      event_label: `Excluir – ${item.message}`
    });

    this.store$.dispatch(new notificationsAction.DeleteNotification({ id: item.id }));
  }

  public markAsReaded(item: NotificationModel, type: string) {
    this.genTagger.setTag({
      event_action: `clicou – ${item.title}`,
      event_category: this.category,
      event_label: `Marcar como lida - ${item.message}`
    });

    this.store$.dispatch(new notificationsAction.MarkNotificationRead({ id: item.id }));
  }

  public openActions(index?: number, type?: string, object?: NotificationModel): void {
    this.notificationOptionMenu = this._bottomSheet.open(BottomSheetNotificationComponent, {
      data: { index: index, object: object, type: type }
    });
  }
}
