import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { MatRadioButton } from '@angular/material/radio';
import { BiometryService } from '@app/proposal-workflow/containers/step-formalization/components/biometry-controller/services/biometry.service';
import { ChecklistItemClientDTO } from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/api/query.checklist.model';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { CustomerTypeEnum } from '@app/proposal-workflow/models/step-register-put.enum';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { DeviceInfo } from 'ngx-device-detector';
import { interval, Subject, Subscription } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { ClientBiometryStatusEnum } from './../../containers/step-formalization/components/biometry-controller/models/biometry.model';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.scss']
})
export class QrCodeComponent implements OnChanges, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  protected timerSubscriptionController: Subject<any> = new Subject();
  @Input() title: string;
  @Input() subtitle: string;
  @Input() proposalData: ConfirmationData;
  @Input() crossedFluxUrl: { link: string; uuid: string };
  @Input() clientList: ChecklistItemClientDTO[];
  @Input() qrTitle: string;
  @Input() isTimerActive = false;
  @Input() qrCodeSize = 224;
  @Input() currentPlatform: { deviceInfo: DeviceInfo; isMobile: boolean; isDesktop: boolean };
  @Input() isAntiFraud = false;
  @Output() linkAccess: EventEmitter<any> = new EventEmitter();
  @Output() retryEmitter: EventEmitter<ChecklistItemClientDTO> = new EventEmitter();
  @Output() representantEmitter: EventEmitter<ChecklistItemClientDTO> = new EventEmitter();
  @Output() goToInstructionsEmitter: EventEmitter<void> = new EventEmitter();
  @Output() goBackEmitter: EventEmitter<void> = new EventEmitter();
  public maxTime = this.biometryService.crossedFluxExpirationTime;
  public timeLeft = this.parseTimeLeft(0, this.maxTime);
  public parsedCrossedFluxUrl: string;

  private timerSubscription: Subscription;

  public containerStyles: { [key: string]: string | number };
  public overlayStyles: { [key: string]: string | number };
  public personsType = SubmitPersonType;
  public representantIsLoading = false;
  public hasClientDone = false;
  public retrying = false;

  public clientBiometryStatusEnum = ClientBiometryStatusEnum;
  public customerTypeEnum = CustomerTypeEnum;
  public selectedClient: ChecklistItemClientDTO;
  public someSignatureDone = false;

  @Input() category: string;
  readonly Insert: Tag = Tag.Insert;

  constructor(private biometryService: BiometryService, private genTagger: GenTagger) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.crossedFluxUrl && this.crossedFluxUrl && this.crossedFluxUrl.link && this.crossedFluxUrl.uuid) {
      this.parsedCrossedFluxUrl = this.crossedFluxUrl.link;
      console.log(this.parsedCrossedFluxUrl);
    }

    if (this.qrCodeSize) {
      this.setStyles();
    }

    if (changes.isTimerActive) {
      this.resolveTimer();
    }

    if (this.isAntiFraud) {
      this.acceptOnlyMainLegalClient();
    }

    if (changes.clientList && this.clientList && this.clientList.length) {
      this.clientList = [...this.clientList].sort((a, b) => +a.id - +b.id);
      const currClient = this.clientList.find(client => this.selectedClient && client.id === this.selectedClient.id);

      if (
        !!currClient &&
        (currClient.biometryStatus === ClientBiometryStatusEnum.DONE ||
          currClient.biometryStatus === ClientBiometryStatusEnum.ANALYSIS)
      ) {
        this.parsedCrossedFluxUrl = '';
        this.representantIsLoading = false;
        this.selectedClient = null;
      }

      this.someSignatureDone = this.clientList.some(client => client.biometryStatus === ClientBiometryStatusEnum.DONE);
    }

    if (changes.clientList && !!this.clientList && this.clientList.length === 1) {
      if (!!this.selectedClient && this.selectedClient.id === this.clientList[0].id) return;
      this.selectedClient = this.clientList[0];
      this.representantEmitter.emit(this.selectedClient);
    }

    // if (changes.clientList && !!this.clientList && this.clientList.length === 1) {
    //   if (!!this.selectedClient && this.selectedClient.id === this.clientList[0].id) return;
    //   this.selectedClient = this.clientList[0];
    //   this.representantEmitter.emit(this.selectedClient);
    // }
  }

  private acceptOnlyMainLegalClient() {
    this.clientList = this.clientList.filter(item => item.type === CustomerTypeEnum.MAIN_LEGAL_REPRESENTATIVE);
  }

  private setStyles(): void {
    this.containerStyles = {
      'height.px': this.qrCodeSize,
      'padding-left': `calc(50% - ${this.qrCodeSize / 2}px)`
    };

    this.overlayStyles = {
      'width.px': this.qrCodeSize,
      'height.px': this.qrCodeSize,
      padding: `${this.qrCodeSize / 4}px ${this.qrCodeSize / 8}px 0 ${this.qrCodeSize / 8}px`
    };
  }

  private resolveTimer(): void {
    if (this.isTimerActive && !this.timerSubscription) {
      this.representantIsLoading = true;
      this.retrying = false;
      this.timerSubscription = interval(1000)
        .pipe(
          takeUntil(this.timerSubscriptionController),
          take(this.maxTime + 1)
        )
        .subscribe(timeElapsed => {
          this.timeLeft = this.parseTimeLeft(timeElapsed, this.maxTime);
          if (this.maxTime === timeElapsed) {
            this.timerSubscription = undefined;
          }
        });
    }

    if (!this.isTimerActive) {
      this.timeLeft = this.parseTimeLeft(0, this.maxTime);
      this.timerSubscription = undefined;
      this.timerSubscriptionController.next();
    }
  }

  /**
   * Method to parse time left based on number of seconds passed and max time allowed.
   * @param timeElapsed Number of seconds passed
   * @param maxTime Maximum number of seconds allowed
   * @returns Time left as a string with format 'M:SS'
   */
  private parseTimeLeft(timeElapsed: number, maxTime: number): string {
    const t = maxTime - timeElapsed;
    const minutes = Math.floor(t / 60);
    const seconds = t - minutes * 60;
    return `${minutes}:${seconds >= 10 ? seconds : '0' + seconds}`;
  }

  public retry(): void {
    this.retrying = true;
    this.retryEmitter.emit(this.selectedClient);
  }

  public accessLink(): void {
    this.linkAccess.emit();
  }

  public representantEmit(event: { source: MatRadioButton; value: ChecklistItemClientDTO }): void {
    this.selectedClient = event.value;
    this.representantEmitter.emit(event.value);
  }

  public goToInstructions() {
    this.goToInstructionsEmitter.emit();
  }

  public goBack(): void {
    this.goBackEmitter.emit();
  }

  public clientSelected(clientName: string, index: number): void {
    this.genTagger.setTag({
      event_category: this.category,
      event_label: `​assinatura representante - ${index + 1}​`,
      event_action: `${Tag.Selecionar} - escolha qual assinatura será feita primeiro`
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
