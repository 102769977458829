import { Injectable } from '@angular/core';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class DateParserFormatterI18n extends NgbDateParserFormatter {
  constructor(private translate: TranslateService) {
    super();
  }

  parse(value: string): NgbDateStruct {
    if (value) {
      if (this.translate.currentLang === 'en') {
        // MM-dd-yyyy
        const dateParts = value.trim().split('-');
        if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
          return { year: this.toInteger(dateParts[0]), month: null, day: null };
        } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
          return {
            year: this.toInteger(dateParts[1]),
            month: null,
            day: this.toInteger(dateParts[0])
          };
        } else if (
          dateParts.length === 3 &&
          this.isNumber(dateParts[0]) &&
          this.isNumber(dateParts[1]) &&
          this.isNumber(dateParts[2])
        ) {
          return {
            year: this.toInteger(dateParts[2]),
            month: this.toInteger(dateParts[0]),
            day: this.toInteger(dateParts[1])
          };
        }
      } else {
        // dd-MM-yyyy
        const dateParts = value.trim().split('/');
        if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
          return { year: this.toInteger(dateParts[0]), month: null, day: null };
        } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
          return {
            year: this.toInteger(dateParts[1]),
            month: this.toInteger(dateParts[0]),
            day: null
          };
        } else if (
          dateParts.length === 3 &&
          this.isNumber(dateParts[0]) &&
          this.isNumber(dateParts[1]) &&
          this.isNumber(dateParts[2])
        ) {
          return {
            year: this.toInteger(dateParts[2]),
            month: this.toInteger(dateParts[1]),
            day: this.toInteger(dateParts[0])
          };
        }
      }
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    let stringDate = '';
    if (date) {
      if (this.translate.currentLang === 'en') {
        // MM-dd-yyyy
        stringDate += this.isNumber(date.month) ? this.padNumber(date.month) + '-' : '';
        stringDate += this.isNumber(date.day) ? this.padNumber(date.day) + '-' : '';
        stringDate += date.year;
      } else {
        // dd-MM-yyyy
        stringDate += this.isNumber(date.day) ? this.padNumber(date.day) + '/' : '';
        stringDate += this.isNumber(date.month) ? this.padNumber(date.month) + '/' : '';
        stringDate += date.year;
      }
      return stringDate;
    }
    return null;
  }

  padNumber(value: number) {
    if (this.isNumber(value)) {
      return `0${value}`.slice(-2);
    } else {
      return '';
    }
  }

  isNumber(value: any): boolean {
    return !isNaN(this.toInteger(value));
  }

  toInteger(value: any): number {
    return parseInt(`${value}`, 10);
  }
}
