import { AbstractControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { BrazilValidators } from '@shared/interfaces/validators/brazil-validators.interface';
import { validateCnpj } from '@shared/services/form-validation/validators/cnpj.validators';
import { validateCpf } from '@shared/services/form-validation/validators/cpf.validators';
import {
  ValidateAge,
  ValidateDateStartingToday,
  ValidateFutureDate,
  ValidatePastDate
} from './multi-country.validators';

export const ValidateAgency = (): ValidatorFn => {
  return (form: FormGroup): null => {
    if (!form || !form.value) {
      // do nothing
    } else {
      const agency = form.get('agency');
      const confirmAgency = form.get('confirmAgency');

      if (agency.value !== confirmAgency.value) {
        confirmAgency.setErrors({ ...confirmAgency.errors, agencyNoMatch: true });
      } else {
        if (confirmAgency.errors && confirmAgency.errors['agencyNoMatch']) {
          delete confirmAgency.errors['agencyNoMatch'];
        }
      }
    }
    return null;
  };
};

export const ValidateAccountCode = (): ValidatorFn => {
  return (form: FormGroup): null => {
    if (!form || !form.value) {
      // do nothing
    } else {
      const accountCode = form.get('accountCode');
      const confirmAccountCode = form.get('confirmAccountCode');

      if (accountCode.value !== confirmAccountCode.value) {
        confirmAccountCode.setErrors({ ...confirmAccountCode.errors, accountCodeNoMatch: true });
      } else {
        if (confirmAccountCode.errors && confirmAccountCode.errors['accountCodeNoMatch']) {
          delete confirmAccountCode.errors['accountCodeNoMatch'];
        }
      }
    }
    return null;
  };
};

export const ValidateEntryValue = (): ValidatorFn => {
  return (form: FormGroup): null => {
    if (!form || !form.value) {
      // do nothing
    } else {
      const financedValue = form.get('financedValue');
      const entryValue = form.get('entryValue');

      if (entryValue.value !== null && financedValue.value !== null && entryValue.value >= financedValue.value) {
        entryValue.setErrors({ ...entryValue.errors, isEqualOrGreaterThanFinancedValue: true });
      } else {
        if (entryValue.errors && entryValue.errors['isEqualOrGreaterThanFinancedValue']) {
          entryValue.setErrors(null);
        }
      }
    }
    return null;
  };
};

export const validateSameValue = (firstValue: any, secondValue: any): boolean => {
  return firstValue === secondValue;
};

export const brazilValidators: BrazilValidators = {
  documentNumber: [Validators.required, Validators.maxLength(11)],
  password: [Validators.required, Validators.minLength(7)],
  fullName: [
    Validators.required,
    // tslint:disable-next-line: quotemark
    Validators.pattern("^[a-zA-Z'\u00C0-\u00FF]{2,}([ ]{1}[a-zA-Z'\u00C0-\u00FF]{2,})+$"),
    Validators.minLength(2),
    Validators.maxLength(60)
  ],
  // tslint:disable-next-line: quotemark
  nickName: [Validators.pattern("^([a-zA-Z' \u00C0-\u00FF]{2,})+$"), Validators.minLength(2), Validators.maxLength(20)],
  email: [
    Validators.required,
    Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$'),
    Validators.minLength(5),
    Validators.maxLength(60)
  ],
  street: [
    Validators.required,
    Validators.minLength(2),
    Validators.maxLength(40),
    Validators.pattern(/^[a-zA-Z0-9À-ÿ]+(?: [a-zA-Z0-9À-ÿ]+)*$/)
  ],
  complement: [
    Validators.minLength(1),
    Validators.maxLength(20),
    Validators.pattern(/^[a-zA-Z0-9À-ÿ]+(?: [a-zA-Z0-9À-ÿ]+)*$/)
  ],
  neighborhood: [
    Validators.required,
    Validators.minLength(1),
    Validators.maxLength(35),
    Validators.pattern(/^[a-zA-Z0-9À-ÿ]+(?: [a-zA-Z0-9À-ÿ]+)*$/)
  ],
  number: [Validators.required, Validators.minLength(10), Validators.maxLength(11)],
  cellPhone: [Validators.required, Validators.minLength(10)],
  businessPhone: [Validators.required, Validators.minLength(10)],
  acceptTerms: [Validators.required],
  preToken: [Validators.required],
  dateOfBirth: [Validators.required, ValidateAge(18, 100)],
  financedValue: [Validators.required, Validators.min(0.01), Validators.max(999999999.99)],
  documentHybrid: [Validators.required, Validators.minLength(11), Validators.maxLength(14), isValidRgOrCpf],
  dateOfFoundation: [Validators.required, ValidatePastDate(0, 1000)],
  travelDate: [Validators.required, ValidateFutureDate()],
  initDateProfile: [Validators.required, ValidateDateStartingToday()],
  entryValue: [Validators.max(999999999.99)]
};

export function isValidRgOrCpf({ value: rgOrCpf }: AbstractControl) {
  if (!rgOrCpf) {
    return null;
  }

  if (rgOrCpf.length === 11) {
    if (validateCpf(rgOrCpf)) {
      return null;
    }
  } else if (rgOrCpf.length === 14) {
    if (validateCnpj(rgOrCpf)) {
      return null;
    }
  } else {
    return null;
  }

  return { invalidCpf: true };
}
