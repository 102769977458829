<!-- AGENT LEGAL -->

<div class="border mt-2 rounded p-4 col-12" *ngIf="(biometryStage | async) === biometryStageEnum.AGENT_LEGAL">
  <app-agent-legal
    [currentPlatform]="currentPlatform"
    [proposal]="proposalData"
    [clientList]="clientList"
    [category]="category"
    (agentsLegalEmitter)="getAgentsLegal($event)"
    (noAdditionalAgentsEmitter)="noAdditionalAgents($event)"
    (singleAgentLegalEmitter)="handleSelectedClient($event)"
    (goToInstructionsEmitter)="goToInstructions()"
  >
  </app-agent-legal>
</div>

<!-- AGENT LEGAL STATUS -->
<app-agent-legal-status
  *ngIf="!isCrossedFlux && (biometryStage | async) !== biometryStageEnum.AGENT_LEGAL"
  [clientList]="clientList"
  [selectedClient]="selectedClient"
  (actualBiometryStatus)="actualBiometryStatus($event)"
  [isNotMobileCrossFlux]="!isCrossedFlux && currentPlatform.isMobile"
  [category]="category"
></app-agent-legal-status>

<!-- QR CODE -->

<div
  *ngIf="
    ((permitQrCode | async) === true ||
      (permitSMS | async) === true ||
      (permitEmail | async) === true ||
      (permitCamera | async) === true) &&
    typePerson === 'F' &&
    showButtonCam &&
    (getChangeBiometryStatus === clientBiometryStatusEnum.PENDING ||
      getChangeBiometryStatus === clientBiometryStatusEnum.REMAKE)
  "
>
  <div class="border mt-2 rounded p-4 col-12">
    <span *ngIf="(permitQrCode | async) === true">
      <app-qr-code
        [title]="
          currentPlatform.isDesktop
            ? biometryType === biometryTypeEnum.FACIAL
              ? 'QR-TITLE-BIOMETRY'
              : 'QR-TITLE-SIGNATURE'
            : ''
        "
        [subtitle]="currentPlatform.isDesktop ? 'QR-SUBTITLE' : ''"
        [currentPlatform]="currentPlatform"
        [crossedFluxUrl]="crossedFluxLink"
        [isTimerActive]="isCrossedFluxTimerActive"
        [proposalData]="proposalData"
        [clientList]="clientList"
        [isAntiFraud]="antifraudChecklistItem"
        (linkAccess)="goToAccessLink()"
        (retryEmitter)="generateNewLink($event)"
        (representantEmitter)="handleSelectedClient($event)"
        (goToInstructionsEmitter)="returnToGoToInstructions()"
        (goBackEmitter)="goToAgentLegal()"
        [category]="category"
      ></app-qr-code
    ></span>
    <div *ngIf="(permitSMS | async) === true && (permitEmail | async) === true" class="row">
      <p class="qr-division mb-2">{{ 'QR-DIVISION' | translate }}</p>
      <div class="col-12">
        <p class="p-2 text-start">
          {{ multipleOptions }}
        </p>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="(permitSMS | async) === true">
        <p *ngIf="(permitSMS | async) === true && (permitEmail | async) === false" class="p-2 text-start">
          {{ smsTitle }}
        </p>
        <div class="mt-2 mb-5 {{ currentPlatform.isDesktop ? 'col-6' : 'col-12' }} text-center mx-auto">
          <app-outlined-button (click)="smsSignature()">
            {{ smsTextButton }}
          </app-outlined-button>
        </div>
      </ng-container>

      <ng-container *ngIf="(permitEmail | async) === true">
        <p *ngIf="(permitEmail | async) === true && (permitSMS | async) === false" class="p-2 text-start">
          {{ informativeEmail }}
        </p>
        <div class="mt-2 mb-5 {{ currentPlatform.isDesktop ? 'col-6' : 'col-12' }} text-center mx-auto">
          <app-outlined-button (click)="handleAdditionalFluxButton(); sendToTagger(informativeEmail, textButtonEmail)">
            {{ textButtonEmail }}
          </app-outlined-button>
        </div>
      </ng-container>
      <ng-container *ngIf="(permitCamera | async) === true">
        <p
          *ngIf="(permitCamera | async) === true && (permitEmail | async) === false && (permitSMS | async) === false"
          class="p-2 text-start"
        >
          {{ additionalFluxTitle }}
        </p>
        <div class="mt-2 mb-5 {{ currentPlatform.isDesktop ? 'col-6' : 'col-12' }} text-center mx-auto">
          <app-outlined-button
            (click)="handleAdditionalFluxButton(); sendToTagger(additionalFluxTitle, additionalFluxButtonText)"
          >
            {{ additionalFluxButtonText }}
          </app-outlined-button>
        </div>
      </ng-container>
    </div>
    <div
      class="px-4 mb-4 mt-2 col-12"
      *ngIf="
        currentPlatform.isDesktop &&
        proposalData?.proposalData?.client === personType.PJ &&
        showButtonNotAntifraud() &&
        showParameter
      "
    >
      <app-outlined-button
        (click)="showBackToAgentLegalOrQrCode()"
        appTagger
        [event_category]="category"
        event_label="{{ 'STEP-CONFIRMATION-BACK-BUTTON' | translate }}"
      >
        {{ 'STEP-CONFIRMATION-BACK-BUTTON' | translate }}
      </app-outlined-button>
    </div>
  </div>
</div>

<div
  *ngIf="
    ((permitQrCode | async) === true ||
      (permitSMS | async) === true ||
      (permitEmail | async) === true ||
      (permitCamera | async) === true) &&
    typePerson === 'J' &&
    noAdditionalAgent &&
    !isCrossedFlux &&
    statusBiometryValueJuridica &&
    !(
      (biometryStage | async) === biometryStageEnum.BIOMETRY_STATUS ||
      (biometryStage | async) === biometryStageEnum.DONE ||
      (biometryStage | async) === biometryStageEnum.PENDING
    )
  "
  class="border mt-2 rounded p-4 col-12"
>
  <div>
    <span *ngIf="(permitQrCode | async) === true">
      <app-qr-code
        [title]="
          currentPlatform.isDesktop
            ? biometryType === biometryTypeEnum.FACIAL
              ? 'QR-TITLE-BIOMETRY'
              : 'QR-TITLE-SIGNATURE'
            : ''
        "
        [subtitle]="currentPlatform.isDesktop ? 'QR-SUBTITLE' : ''"
        [currentPlatform]="currentPlatform"
        [crossedFluxUrl]="crossedFluxLink"
        [isTimerActive]="isCrossedFluxTimerActive"
        [proposalData]="proposalData"
        [clientList]="clientList"
        [isAntiFraud]="antifraudChecklistItem"
        (linkAccess)="goToAccessLink()"
        (retryEmitter)="generateNewLink($event)"
        (representantEmitter)="handleSelectedClient($event)"
        (goToInstructionsEmitter)="returnToGoToInstructions()"
        (goBackEmitter)="goToAgentLegal()"
        [category]="category"
      ></app-qr-code
    ></span>
    <div *ngIf="(permitSMS | async) === true && (permitEmail | async) === true" class="row">
      <p class="qr-division mb-2">{{ 'QR-DIVISION' | translate }}</p>
      <div class="col-12">
        <p class="p-2 text-start">
          {{ multipleOptions }}
        </p>
      </div>
    </div>
    <div class="row">
      <ng-container *ngIf="(permitSMS | async) === true">
        <p *ngIf="(permitSMS | async) === true && (permitEmail | async) === false" class="p-2 text-start">
          {{ smsTitle }}
        </p>
        <div class="mt-2 mb-5 {{ currentPlatform.isDesktop ? 'col-6' : 'col-12' }} text-center mx-auto">
          <app-outlined-button (click)="smsSignature()">
            {{ smsTextButton }}
          </app-outlined-button>
        </div>
      </ng-container>

      <ng-container *ngIf="(permitEmail | async) === true">
        <p *ngIf="(permitEmail | async) === true && (permitSMS | async) === false" class="p-2 text-start">
          {{ informativeEmail }}
        </p>
        <div class="mt-2 mb-5 {{ currentPlatform.isDesktop ? 'col-6' : 'col-12' }} text-center mx-auto">
          <app-outlined-button (click)="handleAdditionalFluxButton(); sendToTagger(informativeEmail, textButtonEmail)">
            {{ textButtonEmail }}
          </app-outlined-button>
        </div>
      </ng-container>
      <ng-container *ngIf="(permitCamera | async) === true">
        <p
          *ngIf="(permitCamera | async) === true && (permitEmail | async) === false && (permitSMS | async) === false"
          class="p-2 text-start"
        >
          {{ additionalFluxTitle }}
        </p>
        <div class="mt-2 mb-5 {{ currentPlatform.isDesktop ? 'col-6' : 'col-12' }} text-center mx-auto">
          <app-outlined-button
            (click)="
              handleAdditionalFluxButton();
              hideParameters(true);
              sendToTagger(additionalFluxTitle, additionalFluxButtonText)
            "
          >
            {{ additionalFluxButtonText }}
          </app-outlined-button>
        </div>
      </ng-container>
    </div>
    <div
      class="px-4 mb-4 mt-2 col-12"
      *ngIf="
        currentPlatform.isDesktop && proposalData?.proposalData?.client === personType.PJ && showButtonNotAntifraud()
      "
    >
      <app-outlined-button
        (click)="showBackToAgentLegalOrQrCode()"
        appTagger
        [event_category]="category"
        event_label="{{ 'STEP-CONFIRMATION-BACK-BUTTON' | translate }}"
      >
        {{ 'STEP-CONFIRMATION-BACK-BUTTON' | translate }}
      </app-outlined-button>
    </div>
  </div>
</div>

<div *ngIf="(biometryStage | async) === biometryStageEnum.INSTRUCTIONS">
  <div *ngIf="currentPlatform.isDesktop && proposalConditionsAccepted" class="text-right">
    <span class="material-icons mx-auto col-1" (click)="handleClose()">
      close
    </span>
  </div>

  <div *ngIf="conditionCardSelfie()">
    <app-card-condition-selfie></app-card-condition-selfie>
    <button
      mat-raised-button
      class="w-100 button"
      id="acceptbt"
      appTagger
      [event_category]="category"
      event_label="{{ 'FORMALIZATION-BIOMETRY-BTN' | translate }}"
      (click)="acceptProposalConditionSelfie()"
    >
      {{ 'FORMALIZATION-BIOMETRY-BTN' | translate }}
    </button>
  </div>

  <div *ngIf="(currentPlatform.isDesktop || !conditionCardSelfie()) && !proposalConditionsAccepted">
    <app-card-condition
      [proposal]="proposal"
      [crossedFlux]="isCrossedFlux"
      [biometryType]="biometryType"
      (showStepper)="onShowStepper($event)"
    >
    </app-card-condition>
    <button
      mat-raised-button
      class="w-100 button"
      id="acceptbt"
      appTagger
      [event_category]="category"
      event_label="{{ 'FORMALIZATION-SIGNATURE-BTN' | translate }}"
      (click)="acceptProposalConditions()"
    >
      {{ 'FORMALIZATION-SIGNATURE-BTN' | translate }}
    </button>
  </div>

  <div *ngIf="proposalConditionsAccepted">
    <app-biometry-instructions
      [tutorial]="tutorialSteps"
      [category]="category"
      [tutorialNextButtonDisabled]="tutorialNextButtonDisabled"
      (closeEmitter)="closeBiometryInstructions()"
      (finishButtonEmitter)="handleNextStep()"
    ></app-biometry-instructions>
  </div>

  <div
    class="pl-0 pr-0 mb-4 mt-2 col-12"
    *ngIf="currentPlatform.isMobile && proposalData?.proposalData?.client === personType.PJ && showButtonNotAntifraud()"
  >
    <app-outlined-button (click)="showBackToAgentLegalOrQrCode()">
      {{ 'STEP-CONFIRMATION-BACK-BUTTON' | translate }}
    </app-outlined-button>
  </div>
</div>

<!-- BIOMETRY STATUS -->
<app-biometry-status
  *ngIf="
    (biometryStage | async) === biometryStageEnum.BIOMETRY_STATUS ||
    (biometryStage | async) === biometryStageEnum.DONE ||
    (biometryStage | async) === biometryStageEnum.PENDING
  "
  [status]="biometryStatusProgress"
  [hasChallengeQuestions]="biometryStatusHasQuestions"
  [isCrossedFlux]="isCrossedFlux"
  [typePerson]="typePerson"
  [clientList]="clientList"
  [currentPlatform]="currentPlatform"
  [typePerson]="typePerson"
  (returnToQrCodeEmitter)="returnToQrCode()"
  [category]="category"
  [proposal]="proposal"
>
</app-biometry-status>

<!-- FACIAL CAPTURE -->
<app-facial-capture
  *ngIf="(biometryStage | async) === biometryStageEnum.FACIAL_CAPTURE"
  [proposal]="proposal"
  (biometryResultsEmit)="handleFacialCapture($event)"
  (closeBiometryCaptureEmit)="handleFacialCapture()"
>
</app-facial-capture>

<!-- SIGNATURE CAPTURE -->
<app-signature-capture
  *ngIf="(biometryStage | async) === biometryStageEnum.SIGNATURE_CAPTURE"
  [signatureData]="proposal"
  [isCrossedFlux]="isCrossedFlux"
  (sendSignature)="handleSendSignature($event)"
>
</app-signature-capture>

<div *ngIf="errorFlag">
  <h3 class="error-msg col-12">
    {{ errorMessage }}
  </h3>
</div>
