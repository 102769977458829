import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NegotiationDetailEnum } from '@app/profile/components/proposal-details/models/negotiation-detail.model';
import { ProposalDetailsService } from '@app/profile/components/proposal-details/service/proposal-details.service';
import { environment } from '@env/environment.prod';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { AddRequestAction, RemoveRequestAction } from '@shared/components/widgets/loading/store/loading.actions';
import { EMPTY, of } from 'rxjs';
import { catchError, delay, switchMap, tap } from 'rxjs/operators';
import { AppState } from '..';
import * as actions from './proposal-negotiation.actions';

@Injectable()
export class ProposalNegotiationEffects {
  private currentPooling = 0;
  private maxPooling = Number(environment.maxAttemptsSimulation);
  private proposalId = null;

  constructor(
    private actions$: Actions,
    private _proposalDetailsService: ProposalDetailsService,
    private dialog: MatDialog,
    private translateService: TranslateService,
    private store$: Store<AppState>
  ) {}

  @Effect()
  negotiationProposal$ = this.actions$.pipe(
    ofType<actions.NegotiationProposal>(actions.ProposalNegotiationActionsTypes.NEGOTIATION_PROPOSAL),
    switchMap(action =>
      this._proposalDetailsService.negotiationProposal(action.payload.proposalId, action.payload.negotiation).pipe(
        switchMap(() => {
          this.currentPooling = 0;
          return [
            new AddRequestAction({ id: 'LOADING', request: null }),
            new actions.NegotiationProposalPooling(action.payload.proposalId)
          ];
        }),
        catchError(() => of(new actions.NegotiationProposalError()))
      )
    )
  );

  @Effect()
  negotiationProposalPooling$ = this.actions$.pipe(
    ofType<actions.NegotiationProposalPooling>(actions.ProposalNegotiationActionsTypes.NEGOTIATION_PROPOSAL_POOLING),
    switchMap(action => {
      this.proposalId = action.payload;

      return this._proposalDetailsService.negotiationProposalPooling(this.proposalId).pipe(
        switchMap(response => {
          let nextAction: any;

          if (
            !response ||
            !response.status ||
            response.status !== NegotiationDetailEnum.PROCESSING ||
            (response.status === NegotiationDetailEnum.PROCESSING && response.currentPooling >= this.maxPooling)
          ) {
            this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));
          }

          if (!response || !response.status) {
            return EMPTY;
          }

          if (response.status === NegotiationDetailEnum.COMPLETED) {
            return of(new actions.NegotiationProposalPoolingSuccess(response));
          }

          if (response.status === NegotiationDetailEnum.REPROVED) {
            return of(new actions.NegotiationProposalError());
          }

          if (response.status === NegotiationDetailEnum.PROCESSING) {
            if (response.currentPooling === this.maxPooling) {
              return of(new actions.NegotiationProposalError());
            }

            this.currentPooling = this.currentPooling++;
            nextAction = new actions.NegotiationProposalPooling(this.proposalId);
          }

          return of(nextAction).pipe(delay(10000));
        }),
        catchError(() => of(new actions.NegotiationProposalError()))
      );
    })
  );

  @Effect({ dispatch: false })
  negotiationProposalError$ = this.actions$.pipe(
    ofType<actions.NegotiationProposalError>(actions.ProposalNegotiationActionsTypes.NEGOTIATION_PROPOSAL_ERROR),
    tap(() => {
      this.dialog.open(PionModalComponent, {
        width: '560px',
        autoFocus: false,
        id: 'modal-error-negotiation',
        role: 'dialog',
        data: {
          title: this.translateService.instant('MODAL-ERROR-NEGOTIATION-TITLE'),
          description: this.translateService.instant('MODAL-ERROR-NEGOTIATION-DESCRIPTION'),
          confirmText: this.translateService.instant('MODAL-ERROR-NEGOTIATION-CONFIRM'),
          type: 'error'
        }
      });
    })
  );
}
