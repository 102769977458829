import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ModalOptions {
  title: string;
  description: string;
  confirmText: string;
  cancelText: string;
  type: string;
}

@Component({
  selector: 'app-registration-unavailable-modal',
  templateUrl: './registration-unavailable-modal.component.html',
  styleUrls: ['./registration-unavailable-modal.component.scss']
})
export class RegistrationUnavailableModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public dialogRef: MatDialogRef<RegistrationUnavailableModalComponent>
  ) {}

  ngOnInit() {}

  public cancelActionFunction() {
    this.close(false);
  }

  public close(value) {
    this.dialogRef.close(value);
  }

  @HostListener('keydown.esc')
  public onEsc() {
    if (!this.dialogRef.disableClose) {
      this.close(false);
    }
  }
}
