<div class="modal-container text-center">
  <div class="icon-container">
    <mat-icon class="modal-icon">cancel</mat-icon>
  </div>
  <p class="modal-title">{{ data.title | translate }}</p>
  <p class="modal-subtitle">{{ data.msg | translate }}</p>
  <p class="mutted text-center" *ngIf="data?.errorCode">{{ 'Code' | translate }}: {{ data.errorCode }}</p>
  <button class="filled-button" mat-button [mat-dialog-close]="false">
    {{ data?.btnLabel | translate }}
  </button>
</div>
