<div class="installments-modal-container">
  <div class="pion-modal-header">
    <div class="title">
      <img *ngIf="flexPlanList?.length === 1" class="icon" src="./assets/images/installment-icon.png"
        alt="installment" />
      <img *ngIf="flexPlanList?.length > 1 || data.isPlanCustom" class="icon"
        src="./assets/images/ic_mechanical_assistance.png" alt="installment" />
      <h3 *ngIf="flexPlanList?.length > 1 || data.isPlanCustom" class="modal-title">{{ 'FLEX-PLAN' | translate }}</h3>
      <h3 *ngIf="flexPlanList?.length === 1" class="modal-title">{{ 'INSTALLMENTS' | translate }}</h3>
      <button mat-icon-button aria-label="Fechar" appTagger [event_category]="category"
        event_label="{{ 'X' | translate }}" class="close-modal" mat-dialog-close>
        <img src="./assets/ic_close.svg" alt="fechar" />
      </button>
    </div>
    <div class="description">
      <p>
        <app-payment-plan-calendar [flexPlanList]="flexPlanList" [showTooltip]="false" [showGraphic]="false"
          [code]="isNPP ? data.currentPaymentPlan?.flexPlanType : data.currentPaymentPlan?.code"
          [codePlan]="data?.code"></app-payment-plan-calendar>
      </p>
      <p *ngIf="flexPlanList?.length === 1" [innerHTML]="'INSTALLMENT-MODAL-DESC' | translate"></p>
      <span class="descriptionPeriod" *ngIf="flexPlanType === 'BLN' || flexPlanType === 'LACK' || flexPlanType === 'SEASONAL' ||
         data.code === 'BLN' ||  data.code === 'LACK' ||  data.code === 'SEASONAL'
        ">
        <p>
          {{ 'THE-PERIOD-CHOSEN-FOR-THIS-MODEL-IS-THE' | translate }}
          <span class="monthsRange">{{ monthsRange }}</span>

          <span *ngIf="data.isModalPayment" class="monthsRange">{{
            data.data.planTypeSchemaData.frequencedescription}}</span>
        </p>
      </span>
      <span class="descriptionPeriod" *ngIf="flexPlanType === 'ASC' || flexPlanType === 'DEC'  ||
        data.code  === 'ASC'||  data.code === 'DEC'">
        <p *ngIf="!(data.isModalPayment)">
          Seu <strong> plano atual </strong> é de <span class="monthsRange">{{ quantityInstallment }} x</span>
          {{ 'MISCELLANEOUS-VALUE' | translate }}
        </p>
        <p *ngIf="data.isModalPayment">
          Seu <strong> plano atual </strong> é de <span class="monthsRange">{{
            data.data.planTypeSchemaData.installmentDefaultAmount }} x</span>
          {{ 'MISCELLANEOUS-VALUE' | translate }}
        </p>
      </span>
      <span class="descriptionPeriod" *ngIf="flexPlanType === 'FIXED' || data.code === 'FIXED'">
        <p *ngIf="!(data.isModalPayment)">
          Seu <strong> plano atual </strong> é de
          <span class="">{{ installmentPaymentMethodsAmount }}x de {{ firstInstallmentValue | localeCurrency
            }}
          </span>
          e <strong>{{ installmentQuantity }}x de {{ lastInstallmentsValue | localeCurrency }}</strong>
        </p>
        <p *ngIf="data.isModalPayment">
          Seu <strong> plano atual </strong> é de
          <span class="">{{ data.data.planTypeSchemaData.installmentPaymentMethodsAmount }}x de {{
            data.data.planTypeSchemaData.firstInstallmentValue | localeCurrency
            }}
          </span>
          e <strong>{{ data.data.planTypeSchemaData.installmentDefaultAmount }}x de {{
            data.data.planTypeSchemaData.lastInstallmentValue | localeCurrency }}</strong>
        </p>
      </span>
      <span class="descriptionPeriod"
        *ngIf="(flexPlanType === 'FLT' || data.code === 'FLT') && flexPlanList?.length > 1">
        <P *ngIf="!(data.isModalPayment)">Seu <strong>plano atual</strong> é de
          <strong><span class="nstallments-value">{{ quantityInstallment }}x de R$ {{ defaultInstallmentValue |
              localeCurrency }}</span></strong>
        </P>
        <P *ngIf="data.isModalPayment">Seu <strong>plano atual</strong> é de
          <strong><span class="nstallments-value">{{ data.data.planTypeSchemaData.installmentDefaultAmount }}x de R$ {{
              data.data.planTypeSchemaData.installmentDefaultValue |
              localeCurrency }}</span></strong>
        </P>
      </span>
    </div>
    <div class="list-header d-none d-md-block">
      <div class="table-row" [ngClass]="currentCountry">
        <div *ngIf="flexPlanList?.length === 1" class="installment">{{ 'INSTALLMENT-TITLE' | translate }}</div>
        <div class="installment">Nº</div>
        <div class="month">{{ 'MONTH-YEAR' | translate }}</div>
        <div class=""></div>
        <div class="value">
          <span class="text-installment-end">{{ 'INSTALLMENT-VALUE' | translate }} </span>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-scroll-area">
    <div class="list-table" [ngClass]="currentCountry">
      <div *ngFor="let installment of installments" class="table-row">
        <div class="installment">
          <div class="d-md-none list-table-responsive">{{ 'INSTALLMENT-TITLE' | translate }}</div>
          <div>{{ installment.number }}</div>
        </div>
        <div class="month">
          <div class="d-md-none list-table-responsive">{{ 'MONTH-YEAR' | translate }}</div>
          <div>{{ installment.dueDateFormated }}</div>
        </div>
        <div *ngIf="installment.label" class="badge badge-secondary informationInstallments" style="color:'#fff'; margin-bottom: 2em;  margin-left: 5em;
          margin-right: -9em; justify-content: space-between;">
          {{ installment?.label }}
        </div>
        <div class="value">
          <div class="d-md-none list-table-responsive">{{ 'INSTALLMENT-VALUE' | translate }}</div>
          <div>{{ installment.value | localeCurrency }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="pion-modal-footer" *ngIf="!isNPP && flexPlanList?.length > 1">
    <ng-container *ngIf="data?.isAllowedFlexInstallments && currentUrl === '/proposal/step-simulation'">
      <p class="flex-plan-description">
        {{ 'FLEX-PLAN-DESCRIPTION' | translate }}
      </p>
      <button class="plan-button" appTagger [event_category]="category" event_label="{{ 'FLEX-PLAN' | translate }}"
        (click)="openInstallmentsModalFlex()">
        <div class="button-icon">
          <img src="./assets/images/flex-plan-icon.png" alt="flex" />
        </div>
        <div class="text">
          <strong>{{ 'FLEX-PLAN' | translate }}</strong>
          <p>
            <span>{{ 'CUSTOM-PLAN' | translate }}</span>
            <mat-icon>north_east</mat-icon>
          </p>
        </div>
      </button>
    </ng-container>
  </div>
  <div class="pion-modal-footer">
    <div *ngIf="flexPlanType === 'BLN' || data.code === 'BLN' ">
      <div class="row ">
        <div class="informationInstallments col-6">{{ 'INSTALLMENT-BALLOON' | translate }}</div>
        <div class="informationInstallmentsValue col-6">{{ 'TRADITIONAL-VALUE' | translate }}</div>
      </div>
      <div *ngIf="!(data.isModalPayment)" class="row ">
        <div class="col-6 installmentQuantity">
          {{ installmentPaymentMethodsAmount}}x de {{ installmentPaymentMethodsValue | localeCurrency }}
        </div>
        <div class="col-6 defaultInstallmentValue">
          {{ installmentQuantity }}x de {{ defaultInstallmentValue | localeCurrency }} </div>
      </div>

      <div *ngIf="data.isModalPayment" class="row ">
        <div class="col-6 installmentQuantity">
          {{ data.data.planTypeSchemaData.installmentPaymentMethodsAmount}}x de {{
          data.data.planTypeSchemaData.installmentPaymentMethodsValue | localeCurrency }}
        </div>
        <div class="col-6 defaultInstallmentValue">
          {{ data.data.planTypeSchemaData.installmentDefaultAmount }}x de {{
          data.data.planTypeSchemaData.installmentDefaultValue | localeCurrency }} </div>
      </div>
    </div>
    <div *ngIf="(flexPlanType === 'FLT' || data.code === 'FLT') && flexPlanList?.length > 1">
      <div class="row ">
        <div class="informationInstallments col-6">{{ 'NUMBER-OF-INSTALLMENTS' | translate }}</div>
        <div class="informationInstallmentsValue col-6">{{ 'VALUE-OF-INSTALLMENTS' | translate }}</div>
      </div>
      <div *ngIf="!(data.isModalPayment)" class="row ">
        <div class="col-6 installmentQuantity">{{ quantityInstallment }}</div>
        <div class="col-6 defaultInstallmentValue">{{ defaultInstallmentValue | localeCurrency }}</div>
      </div>
      <div *ngIf="data.isModalPayment" class="row ">
        <div class="col-6 installmentQuantity">{{ data.data.planTypeSchemaData.installmentDefaultAmount }}</div>
        <div class="col-6 defaultInstallmentValue">{{ data.data.planTypeSchemaData.installmentDefaultValue |
          localeCurrency }}</div>
      </div>
    </div>

    <div *ngIf="flexPlanType === 'SEASONAL' || data.code === 'SEASONAL'">
      <div class="row ">
        <div class="informationInstallments col-6">{{ 'NUMBER-OF-INSTALLMENTS' | translate }}</div>
        <div class="informationInstallmentsValue col-6">{{ 'VALUE-INSTALLMENTS-SEASONAL' | translate }}</div>
      </div>
      <div *ngIf="!(data.isModalPayment)" class="row ">
        <div class="col-6 installmentQuantity">{{ installmentPaymentMethodsAmount }}</div>
        <div class="col-6 defaultInstallmentValue">{{ installmentPaymentMethodsValue | localeCurrency }}</div>
      </div>

      <div *ngIf="data.isModalPayment" class="row ">
        <div class="col-6 installmentQuantity">{{ data.data.planTypeSchemaData.installmentPaymentMethodsAmount }}</div>
        <div class="col-6 defaultInstallmentValue">{{ data.data.planTypeSchemaData.installmentPaymentMethodsValue |
          localeCurrency }}</div>
      </div>
    </div>
    <div *ngIf="flexPlanType === 'LACK' || data.code === 'LACK'">
      <div class="row ">
        <div class="informationInstallments col-6">{{ 'NUMBER-OF-INSTALLMENTS' | translate }}</div>
        <div class="informationInstallmentsValue col-6">{{ 'VALUE-OF-INSTALLMENTS' | translate }}</div>
      </div>
      <div *ngIf="!(data.isModalPayment)" class="row ">
        <div class="col-6 installmentQuantity">{{ installmentQuantity }}</div>
        <div class="col-6 defaultInstallmentValue">{{ defaultInstallmentValue | localeCurrency }}</div>
      </div>

      <div *ngIf="data.isModalPayment" class="row ">
        <div class="col-6 installmentQuantity">{{ data.data.planTypeSchemaData.installmentDefaultAmount }}</div>
        <div class="col-6 defaultInstallmentValue">{{ data.data.planTypeSchemaData.installmentDefaultValue |
          localeCurrency }}</div>
      </div>
    </div>
    <div *ngIf="flexPlanType === 'ASC' || data.code === 'ASC'">
      <div class="row ">
        <div class="informationInstallments col-6">{{ 'NUMBER-OF-INSTALLMENTS' | translate }}</div>
        <div class="informationInstallmentsValue col-6">{{ 'VALUE-MIN-AND-MAX' | translate }}</div>
      </div>
      <div *ngIf="!(data.isModalPayment)" class="row ">
        <div class="col-6 installmentQuantity">{{ quantityInstallment }}</div>
        <div class="col-6 defaultInstallmentValue">
          {{ defaultInstallmentValue | localeCurrency }}/{{ lastInstallmentsValue | localeCurrency }}
        </div>
      </div>
      <div *ngIf="data.isModalPayment" class="row ">
        <div class="col-6 installmentQuantity">{{ data.data.planTypeSchemaData.installmentDefaultAmount }}</div>
        <div class="col-6 defaultInstallmentValue">
          {{ data.data.planTypeSchemaData.firstInstallmentValue | localeCurrency }}/{{
          data.data.planTypeSchemaData.lastInstallmentValue | localeCurrency }}
        </div>
      </div>
    </div>
    <div *ngIf="flexPlanType === 'DEC' || data.code ==='DEC'">
      <div class="row ">
        <div class="informationInstallments col-6">{{ 'NUMBER-OF-INSTALLMENTS' | translate }}</div>
        <div class="informationInstallmentsValue col-6">{{ 'VALUE-MIN-AND-MAX' | translate }}</div>
      </div>
      <div *ngIf="!(data.isModalPayment)" class="row ">
        <div class="col-6 installmentQuantity">{{ quantityInstallment }}</div>
        <div class="col-6 defaultInstallmentValue">
          {{ lastInstallmentsValue | localeCurrency }}/{{ firstInstallmentValue | localeCurrency }}
        </div>
      </div>
      <div *ngIf="data.isModalPayment" class="row ">
        <div class="col-6 installmentQuantity">{{ data.data.planTypeSchemaData.installmentDefaultAmount }}</div>
        <div class="col-6 defaultInstallmentValue">
          {{ data.data.planTypeSchemaData.lastInstallmentsValue | localeCurrency }}/{{
          data.data.planTypeSchemaData.firstInstallmentValue | localeCurrency }}
        </div>
      </div>
    </div>

    <div *ngIf="flexPlanType === 'FIXED' || data.code === 'FIXED'">
      <div class="row ">
        <div class="informationInstallments col-6">{{ 'FIXED-INSTALLMENT' | translate }}</div>
        <div class="informationInstallmentsValue col-6">{{ 'TRADITIONAL-VALUE' | translate }}</div>
      </div>
      <div *ngIf="!(data.isModalPayment)" class="row ">
        <div class="col-6 installmentQuantity">
          {{ installmentPaymentMethodsAmount }}x de {{ firstInstallmentValue | localeCurrency }}
        </div>
        <div class="col-6 defaultInstallmentValue">
          {{ installmentQuantity }}x de {{ lastInstallmentsValue | localeCurrency }}
        </div>
      </div>
      <div *ngIf="data.isModalPayment" class="row ">
        <div class="col-6 installmentQuantity">
          {{ data.data.planTypeSchemaData.installmentPaymentMethodsAmount }}x de {{
          data.data.planTypeSchemaData.firstInstallmentValue | localeCurrency }}
        </div>
        <div class="col-6 defaultInstallmentValue">
          {{ data.data.planTypeSchemaData.installmentDefaultAmount }}x de {{
          data.data.planTypeSchemaData.lastInstallmentValue | localeCurrency }}
        </div>
      </div>
    </div>
    <div *ngIf="flexPlanList?.length > 1 || data.isPlanCustom">
      <button class="btn-submit " mat-raised-button color="primary" mat-dialog-close appTagger
        (click)="clearVariables()" [event_category]="category" event_label="">
        {{ 'SAVE-INSTALLMENTS' | translate }}
      </button>
    </div>
  </div>
</div>
