<div id="modal-content-wrapper">
  <header id="modal-header" class="text-center mt-4">
    <h1 id="modal-title mt-2 mb-2" mat-dialog-title>{{ 'PROPOSAL-EXTRACT' | translate }}</h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <ngb-alert [dismissible]="false" class="alert-extract-proposals" type="custom">
      <img src="./assets/images/ic_circle_information_white.svg" alt="Information" class="mb-3 mr-3" />
      <p>
        Você pode solicitar o pagamento das propostas que estão com status "Pagamento disponível" e "Erro de pagamento"
        de uma só vez. Para isso, é só fazer voltar ao menu anterior em Upload para pagamento e enviar o arquivo
        extraído.
      </p>
    </ngb-alert>
    <p class="text mb-2">
      {{ 'PROPOSAL-EXTRACT-DOWNLOADED-AUTOMATICALLY-MSG' | translate }}
      <a (click)="downloadFile()" appTagger [event_category]="category" event_label="{{ 'CLICK-HERE' | translate }}">{{
        'CLICK-HERE' | translate
      }}</a>
      {{ 'PROPOSAL-EXTRACT-DOWNLOADED-AUTOMATICALLY-FAILED-MSG' | translate }}
    </p>
  </section>
  <footer id="modal-footer" mat-dialog-actions class="mb-3">
    <button
      mat-flat-button
      id="modal-close-button"
      color="primary"
      (click)="dialogRef.close()"
      appTagger
      [event_category]="category"
      event_label="{{ 'Close' | translate }}"
    >
      {{ 'Close' | translate }}
    </button>
  </footer>
</div>
