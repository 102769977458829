import { Injectable } from '@angular/core';
import { TaggerService } from '@app/tagging/tagger.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TabService } from '@shared/services/tab/tab.service';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from './../index';
import * as actions from './tab.actions';
import * as selectors from './tab.selectors';

@Injectable()
export class TabsEffects {
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private tabService: TabService,
    private taggerService: TaggerService
  ) {}

  @Effect()
  getTabsEffect = this.actions$.pipe(
    ofType<actions.GetTabs>(actions.TabsActionTypes.GET_TABS),
    switchMap(action => {
      return this.tabService.getTabs(action.payload).pipe(
        switchMap(res => {
          return of(new actions.GetTabsSuccess(res));
        }),
        catchError(err => of(new actions.GetTabsError(err)))
      );
    })
  );

  @Effect()
  getProductsEffect = this.actions$.pipe(
    ofType<actions.GetProducts>(actions.TabsActionTypes.GET_PRODUCTS),
    withLatestFrom(this.store$.select(selectors.selectSelectedTab)),
    switchMap(([_action, selectedTab]) => {
      const storeCode = selectedTab.code;
      return this.tabService.getProducts(storeCode).pipe(
        switchMap(res => of(new actions.GetProductsSuccess(res))),
        catchError(err => of(new actions.GetProductsError(err)))
      );
    })
  );

  @Effect({ dispatch: false })
  SaveTabEffect = this.actions$.pipe(
    ofType<actions.SaveTab>(actions.TabsActionTypes.SAVE_TAB),
    map(_action => this.taggerService.setTabScope())
  );
}
