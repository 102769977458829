<div class="mat-card-header">
  <img *ngIf="!hideHeader" mat-card-avatar class="menu-header-image" src="./assets/images/photo.jpg" />
  <div *ngIf="!hideHeader" class="mat-card-header-text">
    <div class="mat-card-title">{{ 'HELLO' | translate }}, {{ user }}</div>
    <div class="mat-card-subtitle">{{ storeName }}</div>
  </div>
  <div *ngIf="hideHeader" class="header-help">
    <div class="item-header-help1">
      <img src="./assets/images/ic_arrow_left.svg" (click)="resetOptions()" />
    </div>
    <div class="item-header-help2">{{ 'HEADER-HELP' | translate }}</div>
    <div class="item-header-help3"></div>
  </div>
</div>
<div class="mat-card-content">
  <mat-list *ngIf="actualMenuData && actualMenuData.length > 0">
    <mat-list-item
      *ngFor="let option of actualMenuData"
      class="item-list-line"
      ngClass="{{ 'submenu-item-' + option?.code }}"
    >
      <div
        class="title-menu"
        *ngIf="option && option.code != subSubMenuCode"
        (click)="choiceActionLabel(option.actionType, option.targetAddress); menuClick(option.label)"
      >
        {{ option.label | translate }}
      </div>
      <div
        class="title-menu"
        *ngIf="option && option.code == subSubMenuCode"
        (click)="switchOptions(); menuClick(option.label)"
      >
        {{ option.label | translate }}
      </div>
      <div class="arrow-right">
        <img _ngcontent-bxx-c10="" src="./assets/images/arrow-left-black.svg" />
      </div>
    </mat-list-item>
    <mat-list-item *ngIf="!hideHeader" (click)="logout(); menuClick('Log Out App')" class="icons-exit">
      <img src="./assets/images/ic_logout.svg" class="icon_exit" />
      {{ 'Log Out App' | translate }}
    </mat-list-item>
  </mat-list>
</div>
