import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';

@Component({
  selector: 'app-user-identification',
  templateUrl: './user-identification.component.html',
  styleUrls: ['./user-identification.component.scss']
})
export class UserIdentificationComponent implements OnInit {
  constructor(private activatedRouter: ActivatedRoute, private sessionStorageService: SessionStorageService) {}

  ngOnInit() {
    if (window.location.href.includes('acessToken')) {
      const keys = this.sessionStorageService.getUser();
      const param = this.activatedRouter.snapshot.queryParams.accessToken;
      keys.accessToken = param;
      this.sessionStorageService.clearUser();
      this.sessionStorageService.setUser(keys);
    }
  }
}
