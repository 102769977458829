<div class="modal-header">
  <h3 class="modal-title">{{ 'FILTER BY SELLERS' | translate }}</h3>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
    appTagger
    event_action="fechou modal - {{ 'FILTER BY SELLERS' | translate }}"
    [event_category]="category"
    event_label="x"
  >
    <img aria-hidden="true" src="./assets/ic_close.svg" alt="icon close" />
  </button>
</div>
<div class="modal-body">
  <form [formGroup]="searchForm" (ngSubmit)="searchSellers()">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text" id="search">
                <img
                  src="./assets/images/ic_search.svg"
                  alt="search_icon"
                  (click)="searchSellers()"
                  class="icon-search"
                />
              </span>
            </div>
            <input
              type="text"
              class="form-control"
              formControlName="search"
              [placeholder]="'SEARCH_SELLERS_PLACEHOLDER' | translate"
              aria-label="Search"
              aria-describedby="search"
              appTagger
              [event_category]="category"
              event_label="Nome do vendedor"
            />
            <div class="input-group-prepend">
              <span class="input-group-text" id="search-cancel">
                <img
                  src="./assets/images/ic_cancel.svg"
                  alt="search_icon"
                  (click)="clearSearchSellers()"
                  class="icon-search-cancel"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12 col-md-4">
          <label class="form-label">{{ 'RESULT' | translate }}</label>
        </div>
        <div class="col-12 col-md-8">
          <p *ngIf="sellers.length === 0">{{ 'NO RECENTS' | translate }}.</p>
          <div class="search-results">
            <div class="form-group-inline" *ngFor="let seller of sellers">
              <mat-checkbox
                color="primary"
                [checked]="seller.checked"
                (change)="selectOption($event, 'RESULT')"
                [value]="seller.id"
                >{{ seller.name }}
              </mat-checkbox>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-4 col-md-4"></div>
        <div class="col-8 col-md-8">
          <hr />
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-4">
          <label class="form-label">{{ 'RECENT' | translate }}</label>
        </div>
        <div class="col-12 col-md-8">
          <!-- <p *ngIf="sellers.length === 0">{{ 'NO RESULTS' | translate }}.</p>
          <p *ngIf="sellers.length === 0">{{ 'NO RESULTS FOR SEARCH' | translate }}.</p>
          <div class="search-results">
            <div class="form-group-inline" *ngFor="let seller of sellers">
              <mat-checkbox color="primary" value="seller.id">{{ seller.name }}</mat-checkbox>
            </div>
          </div>-->
          <p *ngIf="salesmanSelected.length === 0">{{ 'NO RECENTS' | translate }}.</p>
          <div class="search-results">
            <div class="form-group-inline" *ngFor="let sellerRecent of salesmanSelected">
              <mat-checkbox
                color="primary"
                (change)="selectOption($event, 'RECENT')"
                [checked]="sellerRecent.checked"
                [value]="sellerRecent.id"
                >{{ sellerRecent.name }}</mat-checkbox
              >
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-buttons">
      <button
        type="button"
        (click)="clearFilters()"
        class="btn btn-secondary"
        appTagger
        [event_category]="category"
        event_label="{{ 'CLEAR_FILTERS' | translate }}"
      >
        {{ 'CLEAR_FILTERS' | translate }}
      </button>
      <button
        type="submit"
        (click)="onSubmit()"
        class="btn btn-primary"
        appTagger
        [event_category]="category"
        event_label="{{ 'APPLY' | translate }}"
      >
        {{ 'APPLY' | translate }}
      </button>
    </div>
  </form>
</div>

<div class="modal-footer">
  <!-- <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button> -->
</div>
