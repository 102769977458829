import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-arrow-back',
  templateUrl: './arrow-back.component.html',
  styleUrls: ['./arrow-back.component.scss']
})
export class ArrowBackComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
