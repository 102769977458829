import { Injectable } from '@angular/core';
import { GET_ALL_CONTRACTS } from '@shared/graphql/queries/contracts.query';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { Apollo } from 'apollo-angular';
import { ExecutionResult } from 'apollo-link';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Contract } from '../model/contract.model';

const mapActiveContracts = (response: ExecutionResult): Contract[] => {
  if (!response.data) return;
  const mappedList = response.data.listActiveContracts.map((activeContract: Contract) => ({
    ...activeContract,
    isFullCard: true
  }));
  if (mappedList.length > 3) mappedList.push({ ...mappedList[0], isFullCard: false });
  return mappedList;
};

@Injectable({
  providedIn: 'root'
})
export class ContractService {
  constructor(private apollo: Apollo, private sessionStorageService: SessionStorageService) {}

  public getContracts(): Observable<Contract> {
    return of({});
  }

  public getAllContracts(): Observable<any> {
    return this.apollo
      .watchQuery({
        query: GET_ALL_CONTRACTS,
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })

      .valueChanges.pipe(
        map((response: any) => {
          console.log(response.data.listActivesContracts);
          return response.data.listActivesContracts;
        })
      );
  }
}
