export enum CameraScriptsPath {
  JQUERY = './assets/js/jquery-2.2.4.min.js',
  JSZIP = './assets/js/jszip.min.js',
  DETECTOR = './assets/js/detector.js',
  STEGGER = './assets/js/snx-steg.js',

  PLATFORM = './assets/public/js/plataform.js',
  ADAPTER_LATEST = './assets/public/js/adapter-latest.js',
  FACE_API = './assets/public/js/face-api.js',
  APP_PLUGIN = './assets/public/js/app-plugin.js'

}
