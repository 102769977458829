<div *ngIf="(!listPayments || !listPayments.length) && firstLoad && false" class="container mt-header">
  <div class="row">
    <div class="col-12">
      <h1 class="title mt-5">{{ 'NO RECENT PAYMENTS' | translate }}</h1>
      <p class="lead mt-3 mb-5">{{ 'NO RECENT PROPOSALS DESCR' | translate }}</p>
    </div>
    <div class="bg-proposal">
      <img
        class="placeloader placeloader-left"
        src="./assets/santander/images/bg-placeloader-1.png"
        alt="placeloader background"
      />
      <img
        class="placeloader placeloader-right"
        src="./assets/santander/images/bg-placeloader-2.png"
        alt="placeloader background"
      />
    </div>
  </div>
</div>

<div *ngIf="(listPayments && listPayments.length) || !firstLoad || true" #contentContainer>
  <div class="container mt-10 fixed-header">
    <div class="row">
      <div class="col col-md-9">
        <h1 class="title">{{ 'PAYMENTS TITLE' | translate }}</h1>
      </div>

      <div class="col-auto col-md-3 btn-view-extract">
        <a [routerLink]="['/showcase/financial-statement']" class="btn d-flex" (click)="onClickFinancialStatement()">
          <img src="./assets/santander/icons/ic_bank_transactions.svg" />
          {{ 'VIEW EXTRACT' | translate }}
        </a>
        <button
          class="btn-kebab"
          mat-icon-button
          [matMenuTriggerFor]="menuHeader"
          aria-label="Options menu"
          appTagger
          [event_category]="categoria"
          event_label="Opções pagamentos"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menuHeader="matMenu">
          <button
            mat-menu-item
            (click)="buttonListOrKebabAction({ button: 'PROPOSAL-EXTRACT' })"
            appTagger
            [event_category]="categoria"
            event_label="{{ 'EXTRACT-FILTERED-PROPOSALS-BUTTON' | translate }}"
          >
            <span>{{ 'EXTRACT-FILTERED-PROPOSALS-BUTTON' | translate }}</span>
          </button>
          <button
            mat-menu-item
            (click)="buttonListOrKebabAction({ button: 'PAYMENT-MASSIVE-UPLOAD' })"
            appTagger
            [event_category]="categoria"
            event_label="{{ 'UPLOAD-FOR-PAYMENT-BUTTON' | translate }}"
          >
            <span>{{ 'UPLOAD-FOR-PAYMENT-BUTTON' | translate }}</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="row alerts mt-4" *ngIf="hasProposalExpiring && !alertExpirationDays">
      <div class="col-md-12">
        <ngb-alert class="warning-alert-details" type="warning" (close)="alertExpirationDays = true">
          <img src="./assets/santander/icons/ic_exclamation.svg" alt="exclamation" />
          <span class="ml-2">{{ 'MSG-PROPOSALS-EXPIRING' | translate }}</span>
        </ngb-alert>
      </div>
    </div>

    <div class="filter-container" [class.filter-container--open]="isFilterOpened && window.screen.width < 768">
      <div class="filter-container__header d-flex d-md-none" (click)="toggleFilters()">
        <div class="row no-gutters justify-content-between w-100">
          <div class="col">
            <h2
              class="mb-0 d-flex align-items-center"
              appTagger
              [event_category]="categoria"
              event_label="{{ isFilterOpened ? 'ocultar filtro' : 'expandir filtro' }}"
            >
              <span class="material-icons pr-1"> {{ isFilterOpened ? 'expand_more' : 'expand_less' }} </span>
              Filtros
            </h2>
          </div>

          <div class="col text-right" *ngIf="!!totalElements">
            <h2 class="mb-0 font-weight-normal">
              {{ totalElements }} {{ totalElements === 1 ? 'proposta' : 'propostas' }}
            </h2>
          </div>
        </div>
      </div>

      <div class="filter-container__content">
        <div class="row mt-4">
          <div class="col-12 col-md-8 order-1">
            <div class="row">
              <div class="col-12 col-md-7">
                <div class="input-group">
                  <div class="input-group-prepend">
                    <span class="input-group-text" id="search">
                      <img
                        src="./assets/images/ic_search.svg"
                        alt="search_icon"
                        class="icon-search"
                        (click)="searchByInput()"
                      />
                    </span>
                  </div>
                  <input
                    type="text"
                    class="form-control"
                    [placeholder]="'PROPOSAL SEARCH INPUT' | translate"
                    [(ngModel)]="searchString"
                    [ngModelOptions]="{ standalone: true }"
                    aria-label="Search"
                    aria-describedby="search"
                    (keydown.enter)="searchByInput()"
                  />
                </div>
              </div>
              <div class="col-12 col-md d-block d-md-flex">
                <button type="button" class="btn btn-light mr-0 mr-md-1" (click)="openDialogPeriod('test param')">
                  <img src="./assets/santander/icons/ic_events.svg" alt="icon calendar" />
                  <span class="button-text">{{ 'PERIOD' | translate }}</span>
                </button>
                <button
                  *ngIf="window.screen.width < 768"
                  type="button"
                  class="btn btn-light mr-0 mr-md-1"
                  (click)="openDialogProposalOrdenation()"
                  appTagger
                  [event_category]="categoria"
                  event_label="Ordenar"
                >
                  <img src="./assets/santander/icons/ic_events.svg" alt="icon sort" />
                  <span class="button-text">{{ 'SORT' | translate }}</span>
                </button>
                <button type="button" class="btn btn-light" (click)="openDialogSeller('test param 2')">
                  <img src="./assets/santander/icons/noun_filter.svg" alt="icon filter" />
                  <span class="button-text">{{ 'FILTER BY SELLERS' | translate }}</span>
                </button>
              </div>
            </div>
          </div>

          <div class="col-12 col-md-4 mt-2 mt-md-0 order-3 order-md-2">
            <div class="d-flex justify-content-between align-items-center flex-row-reverse flex-md-row">
              <button type="button" class="btn btn-transparent" (click)="cleanFilterObjReloadList()">
                <span class="button-text">{{ 'CLEAR ALL FILTERS' | translate }}</span>
              </button>
              <div class="showing-results" *ngIf="!!totalElements">
                {{ 'SHOWING' | translate }}
                <b>{{ totalElements }} {{ totalElements === 1 ? 'proposta' : 'propostas' }}</b>
              </div>
            </div>
          </div>

          <div class="col-12 mt-4 order-2 order-md-2" *ngIf="quickFilters && quickFilters.length > 1">
            <p class="lead light mb-2">{{ 'QUICK-FILTERS' | translate }}</p>

            <owl-carousel-o [options]="customOptions" #owlElement>
              <ng-template carouselSlide>
                <div class="slide">
                  <div class="filter-item" (click)="filterByStatus('')">
                    <div class="filter-col"></div>
                    <div class="filter-col position-relative">
                      <p class="filter-type mb-5">Todas as propostas</p>
                      <button type="button" class="btn filter-btn">
                        Filtrar {{ allFiltersAmount }} propostas
                        <img
                          src="./assets/santander/icons/{{ ordenationIconsAllProposals }}.svg"
                          alt="diagonal arrow "
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </ng-template>
              <ng-container *ngFor="let filter of quickFilters">
                <ng-template carouselSlide *ngIf="filter.amount > 0">
                  <div class="slide" [class.selected-filter]="selectedFilter(filter.name)">
                    <div class="filter-item" (click)="filterByStatus(filter.name)">
                      <div *ngIf="filter?.icon" class="filter-col">
                        <img
                          *ngIf="filter?.icon"
                          [src]="'./assets/santander/icons/' + filter.icon"
                          [alt]="filter.icon"
                        />
                      </div>
                      <div class="filter-col position-relative">
                        <p class="filter-type mb-5">{{ filter.name }}</p>
                        <button type="button" class="btn filter-btn">
                          Filtrar {{ filter.amount }} propostas
                          <img src="./assets/santander/icons/{{ filter.iconArrow }}.svg" alt="diagonal arrow" />
                        </button>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>
            </owl-carousel-o>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="!!listPayments && !!listPayments.length" class="row mt-4">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-borderless table-responsive-md table-select-all">
            <tbody>
              <tr>
                <td class="text-nowrap table-select-all-checkbox">
                  <mat-checkbox
                    color="primary"
                    [checked]="allComplete"
                    [indeterminate]="someChecked()"
                    (change)="checkSelectAll($event.checked)"
                    appTagger
                    [event_category]="categoria"
                    event_label="selecione diversas propostas para solicitações conjuntas"
                    [event_action]="Selecionar"
                  ></mat-checkbox>
                </td>
                <td class="table-select-all-text">
                  <p *ngIf="!checkedCount">
                    {{ 'PAYMENT-SELECT-ALL-PROPOSAL-MSG' | translate }}
                  </p>
                  <p *ngIf="!!checkedCount">
                    <b>{{ checkedCount }} {{ checkedCount === 1 ? 'proposta' : 'propostas' }}</b>
                    {{ checkedCount === 1 ? 'selecionada' : 'selecionadas' }} {{ 'WITH-TOTAL-OF' | translate }}
                    <b>{{ sumCheckedValues | currency: 'BRL' }}</b>
                  </p>
                </td>
                <td class="text-nowrap table-select-all-menu">
                  <button
                    class="btn-kebab d-block"
                    mat-icon-button
                    [matMenuTriggerFor]="menuSelectAll"
                    aria-label="Options menu"
                    [disabled]="!checkedCount || !canEnableKebab"
                    appTagger
                    [event_category]="categoria"
                    event_label="opções - selecione diversas propostas para solicitações conjuntas"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menuSelectAll="matMenu">
                    <button
                      mat-menu-item
                      (click)="buttonListOrKebabAction({ button: 'PAYMENT-MULTIPLE-REQUEST' })"
                      [disabled]="disabledPaymentRequest"
                    >
                      <span>{{ 'PAYMENT-MULTIPLE-REQUEST' | translate }}</span>
                    </button>
                    <button mat-menu-item (click)="buttonListOrKebabAction({ button: 'PAYMENT-MASSIVE-CANCEL' })">
                      <span>{{ 'PROPOSALS-CANCEL-BUTTON' | translate }}</span>
                    </button>
                  </mat-menu>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4" *ngIf="listPayments">
    <app-payment-table-mobile
      class="d-block d-md-none"
      [listPayments]="listPayments"
      (kebabAction)="buttonListOrKebabAction($event)"
      (updateCheckSelectAll)="updateCheckSelectAll()"
    ></app-payment-table-mobile>

    <div class="container d-none d-md-block">
      <app-payment-table
        [listPayments]="listPayments"
        (buttonListOrKebabAction)="buttonListOrKebabAction($event)"
        (updateCheckSelectAll)="updateCheckSelectAll($event)"
        (orderAction)="orderProposals($event)"
      ></app-payment-table>
    </div>

    <div class="my-3 my-md-5 pd-bottom">
      <ngb-pagination
        class="my-4"
        [collectionSize]="totalElements"
        (pageChange)="loadPage($event)"
        [boundaryLinks]="false"
        [maxSize]="paginationMaxSize"
        [pageSize]="perPage"
        size="sm"
      >
        <ng-template ngbPaginationPrevious>
          <span class="arrow-icon icon-prev"></span>
          {{ 'Previous' | translate }}
        </ng-template>
        <ng-template ngbPaginationNext>
          {{ 'Next' | translate }}
          <span class="arrow-icon icon-next"></span>
        </ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
