import { Directive, EventEmitter, OnInit, Output } from '@angular/core';

@Directive({
  selector: '[loadEvent]'
})
export class LoadDirective implements OnInit {
  @Output() load = new EventEmitter<void>();

  ngOnInit(): void {
    this.load.emit();
  }
}
