<div class="container">
  <!-- Formalization success analysis -->
  <div *ngIf="proposal" class="col-12">
    <div class="biometry-status" *ngIf="showSuccessMessage">
      <!-- Close button -->
      <div class="close-button">
        <span class="material-icons close-icon" (click)="closeSuccessMessage()">
          close
        </span>
      </div>

      <!-- Biometry status-->
      <img class="biometry-icon" src="./assets/images/Check.svg" alt="" />
      <h1
        class="biometry-description"
        innerHTML="{{ 'FORMALIZATION-SUMMARY-STATUS' | translate: { proposalId: proposal?.id } }}"
      ></h1>
    </div>
  </div>

  <!-- Summary steps content-->
  <div class="title" *ngIf="!!summaryData">{{ summaryData[0]?.title | translate }}</div>

  <div class="row step-content" *ngFor="let data of clientSteps; index as i; last as last">
    <div class="col-1 p-0 indicator-step">{{ data?.step }}.</div>
    <div class="col-11 p-0 description">{{ data?.description | translate }}</div>
    <ng-container *ngIf="last && data?.description !== clientLastStepDescription">
      <div class="col-1 p-0 indicator-step">{{ i + 2 }}.</div>
      <div class="col-11 p-0 description">{{ clientLastStepDescription }}</div>
    </ng-container>
  </div>

  <div class="title" *ngIf="!!summaryData">{{ summaryData[0]?.secondaryTitle | translate }}</div>

  <div class="row step-content" *ngFor="let data of shopkeeperSteps; index as i; last as last">
    <div class="col-1 p-0 indicator-step">{{ data?.step }}.</div>
    <div class="col-11 p-0 description">{{ data?.description | translate }}</div>
    <ng-container *ngIf="last && data?.description !== shopkeeperLastStepDescription">
      <div class="col-1 p-0 indicator-step">{{ i + 2 }}.</div>
      <div class="col-11 p-0 description">{{ shopkeeperLastStepDescription }}</div>
    </ng-container>
  </div>

  <div
    class="card shadow"
    *ngIf="nextStageCode === BILLET_NEXT_STAGE_CODE && proposal?.product?.code !== productCodes.CP"
  >
    <div class="card-body">
      <div class="row">
        <div class="col-md-6 d-flex">
          <img class="proposal-img" src="./assets/images/ic_bill_payment.svg" alt="" />
          <span>
            {{ 'FORMALIZATION-SUMMARY-TICKET-TEXT' | translate }}
            <mat-icon
              class="info-icon icon-v-inverse"
              matTooltip="{{ 'FORMALIZATION-SUMMARY-TICKET-TOOLTIP' | translate }}"
              matTooltipPosition="below"
              matTooltipHideDelay="5000"
              appTagger
              [event_category]="category"
              event_label="{{ 'FORMALIZATION-SUMMARY-TICKET-BUTTON-TEXT' | translate }}"
              >error_outline</mat-icon
            >
          </span>
        </div>
        <div class="col-md-6">
          <app-outlined-button
            (click)="emitBillet()"
            appTagger
            [event_category]="category"
            event_label="{{ 'FORMALIZATION-SUMMARY-TICKET-BUTTON-TEXT' | translate }}"
            >{{ 'FORMALIZATION-SUMMARY-TICKET-BUTTON-TEXT' | translate }}</app-outlined-button
          >
        </div>
      </div>
    </div>
  </div>

  <div class="card shadow">
    <div class="card-body">
      <div class="order-1 proposal-status">
        <!-- Proposal -->
        <div class="col-12 subtitle">
          <ng-container [ngSwitch]="proposal?.product?.code">
            <span *ngSwitchCase="productCodes.CP">{{ 'FORMALIZATION-SUMMARY-PROPOSAL-STATE-TITLE' | translate }}</span>
            <span *ngSwitchCase="productCodes.CDC">{{ productCodes.CDC }}</span>
            <span *ngSwitchCase="productCodes.CSC">{{ productCodes.CSC }}</span>
            <span *ngSwitchCase="productCodes.CSCP">{{ productCodes.CSCP }}</span>
          </ng-container>
        </div>
        <div class="col-12">
          <img
            class="proposal-icon"
            *ngIf="proposalFlag === summaryResult.BLANK"
            src="./assets/images/ic_cancel.svg"
            alt=""
          />
          <img
            class="proposal-icon"
            *ngIf="proposalFlag === summaryResult.YELLOW"
            src="./assets/images/ic_exclamation.svg"
            alt=""
          />
          <img
            class="proposal-icon"
            *ngIf="proposalFlag === summaryResult.GREEN"
            src="./assets/images/ic_check.svg"
            alt=""
          />
          <span class="proposal-text">{{ proposalStageText | firstLetterUppercase }}</span>
        </div>
      </div>

      <hr class="w-100 order-2 d-xl-none" />

      <div class="order-3 order-xl-2 proposal-status">
        <div class="col-12" *ngFor="let group of summaryStatus?.groups; index as i">
          <img
            class="proposal-icon"
            *ngIf="group?.semaphore?.value === summaryResult.BLANK"
            src="./assets/images/ic_cancel.svg"
            alt=""
          />
          <img
            class="proposal-icon"
            *ngIf="group?.semaphore?.value === summaryResult.YELLOW"
            src="./assets/images/ic_exclamation.svg"
            alt=""
          />
          <img
            class="proposal-icon"
            *ngIf="group?.semaphore?.value === summaryResult.GREEN"
            src="./assets/images/ic_check.svg"
            alt=""
          />
          <span class="status-text">{{ group?.groupName | firstLetterUppercase }}</span>
        </div>
      </div>

      <!-- Download proposal contract -->
      <div class="order-4 order-xl-3 download-proposal">
        <div class="col-12" *ngIf="showDownloadContract">
          <div class="float-left">
            <img class="icon-download" src="./assets/images/icon_download.svg" alt="" />
          </div>
          <span
            (click)="downloadContract()"
            class="download-text"
            appTagger
            [event_category]="category"
            event_label="baixar proposta"
            >{{ 'FORMALIZATION-SUMMARY-PROPOSAL-DOWNLOAD' | translate }}</span
          >
          <span class="download-info" *ngIf="proposal?.product?.code !== productCodes.CSC && proposal?.product?.code !== productCodes.CSCP">{{
            'FORMALIZATION-SUMMARY-PROPOSAL-DOWNLOAD-INFO' | translate
          }}</span>
        </div>
      </div>
    </div>
  </div>

  <!-- Back to intial process -->
  <div class="col-12">
    <button
      mat-raised-button
      (click)="handleBack()"
      class="btn"
      appTagger
      [event_category]="category"
      event_label="{{ 'FORMALIZATION-SUMMARY-BACK-TO-INITIAL-PAGE' | translate }}"
    >
      {{ 'FORMALIZATION-SUMMARY-BACK-TO-INITIAL-PAGE' | translate }}
    </button>
  </div>
</div>
