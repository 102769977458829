import gql from 'graphql-tag';

export const GET_SPECIAL_OFFERS = gql`
  query listSpecialOffers {
    listSpecialOffers {
      id
      name
      description
      typeId
      typeDescription
      companyCode
      productCode
      subsegmentId
      channelProviderId
      validStartDate
      validEndDate
      priorityNumberId
      descriptionPriorityNumber
      artifactCode
      titleName
      priceCode
      descriptionLink
      flagActive
      minimumValue
    }
  }
`;
