import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { GET_TERMS } from '@shared/graphql/queries/domains.query';

@Injectable({
  providedIn: 'root'
})
export class TermsService {
  urlBase = environment.apiRootUrl;

  constructor(private apollo: Apollo, private http: HttpClient) {}

  /**
   * Get terms with default params for 'cliente' app
   */
  // getTerms(app = 'Cliente', type = 'Canal') {
  //   const endpoint = `${this.urlBase}/sistema/1.0.0/termouso?tipoCanalAcesso=${app}&tipo=${type}`;

  //   return this.http.get<any>(endpoint);
  // }

  getTerms(app = 'Lojista', type = 'Canal') {
    return this.apollo
      .watchQuery<any>({
        query: GET_TERMS,
        variables: { accessChannelType: app, termType: type },
        errorPolicy: 'all'
      })
      .valueChanges.pipe(
        map((response: any) => {
          return response.data;
        })
      );
  }

  /**
   * Get terms clauses
   */
  getTermsClauses(id) {
    const endpoint = `${this.urlBase}/sistema/1.0.0/termousoclausula?identificadorTermoUso=${id}`;

    return this.http.get<any>(endpoint);
  }
}
