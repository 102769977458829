import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss']
})
export class LoadingBarComponent implements OnInit {
  /**
   * Input timer in seconds to load progress bar.
   */
  @Input() public timerS = 30;

  public timer: number;

  public interval: any;

  public progress = 0;

  public adValue: number;

  private bestOffer = false;

  constructor(public router: Router) {}

  ngOnInit() {
    this.fill();
  }

  public fill() {
    this.timer = this.timerS * 10;
    this.adValue = 100 / this.timer;
    this.interval = setInterval(() => {
      if (this.timer > 0) {
        this.timer--;
        this.progress += this.adValue;
      } else if (this.timer === 0 && !this.bestOffer) {
        this.router.navigate(['/proposal/best-offer-error']);
      }
    }, 100);
  }
}
