<app-side-content [customClass]="customClass" [show]="!closeCalendar">
  <h1 class="side-calendar-title">{{ 'SIDE-CALENDAR-TITLE' | translate }}</h1>
  <p class="side-calendar-subtitle">
    {{ 'SIDE-CALENDAR-SUBTITLE' | translate }}
  </p>
  <app-calendar
    (navAction)="emitNavAction($event)"
    [closeCalendar]="closeCalendar"
    [calendarConfig]="calendarConfig"
    (closeCalendarE)="closeCalendarEvent()"
    [customClass]="customClass"
  ></app-calendar>
</app-side-content>
