import { Component, OnInit } from '@angular/core';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';

@Component({
  selector: 'app-login-container',
  templateUrl: './login-container.component.html',
  styleUrls: ['./login-container.component.scss']
})
export class LoginContainerComponent implements OnInit {
  public innerWidth: any;
  public MOBILE_BREAKPOINT = 768;
  readonly category = '/portallojista/login';
  readonly Insert: Tag = Tag.Insert;

  constructor(private genTagger: GenTagger) {}

  ngOnInit() {
    history.pushState(null, null, location.href);
    this.innerWidth = window.innerWidth;

    this.genTagger.setTag({
      page_location: this.category,
      title: 'Portal do lojista - Login',
      event_action: Tag.pgView
    });
  }
}
