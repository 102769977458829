import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core/state';
import { userProfileSelector } from '@app/core/state/profile/profile.selectors';
import { ProfileService } from '@app/profile/service/profile/profile.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { select, Store } from '@ngrx/store';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public hideCurPass = false;
  public hideNewPass = false;
  public hideConfirmPass = false;
  public showConfirmChanges = false;
  public isOkay: boolean;

  public newPasswordForm: FormGroup;
  public userName: string;

  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;

  public categoria;
  readonly Insert: Tag = Tag.Insert;
  public changePassword = 'Alterar senha';

  get eventActionModal(): ExtendedTag {
    return {
      event_action_complement: 'Alterar senha',
      event_action_type: Tag.AbriuModal
    };
  }

  constructor(
    private internationalizationService: InternationalizationService,
    private profileService: ProfileService,
    private store$: Store<AppState>,
    private genTagger: GenTagger
  ) {}

  ngOnInit() {
    this.generateForm();
    this.categoria = window.location.href;
    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: this.eventActionModal,
      event_label: 'Alterar senha'
    });
  }

  private generateForm(): void {
    this.store$.pipe(select(userProfileSelector)).subscribe((user: any) => {
      this.userName = user.documentNumber;
    });
    this.newPasswordForm = new FormGroup({
      currentPassword: new FormControl('', [...this.pioneerValidators.password]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(10),
        this.hasCapitalWordValidator,
        this.hasNumbers,
        this.hasSpecialChars
      ]),
      passwordConfirm: new FormControl('', [Validators.required])
    });
  }

  public putPasswordUser(): void {}

  get currentPassword() {
    return this.newPasswordForm.get('currentPassword');
  }

  get password() {
    return this.newPasswordForm.get('password');
  }

  get passwordConfirm() {
    return this.newPasswordForm.get('passwordConfirm');
  }

  public save(): void {
    const obj = {
      actualPassword: this.currentPassword.value,
      newPassword: this.password.value,
      confirmNewPassword: this.passwordConfirm.value
    };
    this.profileService.updateUserPassword(obj).subscribe(
      res => {
        if (res) {
          this.showConfirmChanges = true;
          this.isOkay = true;
        }
      },
      error => {
        this.showConfirmChanges = true;
        this.isOkay = false;
      }
    );

    // this.store$.dispatch(new profileActions.UpdateUserProfileRequest(obj));
  }

  public unicodeNewPassword(): string {
    const newPassword = this.password.value;
    const fChar = newPassword.slice(0, 1);
    const sChar = newPassword.slice(newPassword.length - 1, newPassword.length);
    const unicode = `*`.repeat(newPassword.length - 2);

    return `${fChar}${unicode}${sChar}`;
  }

  public hasCapitalWordValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const regex = /(?=.*[a-z])(?=.*[A-Z])/g;

    if (control.value && !regex.test(control.value)) {
      return { capitalWord: true };
    }

    return null;
  }

  public hasNumbers(control: AbstractControl): { [key: string]: boolean } | null {
    const regex = /[0-9]/g;

    if (control.value && !regex.test(control.value)) {
      return { hasNumbers: true };
    }

    return null;
  }

  public hasSpecialChars(control: AbstractControl): { [key: string]: boolean } | null {
    const regex = /^(?=.*[!@#$%&*])[A-Za-z\d!@#$%&*]*$/g;
    if (control.value && !regex.test(control.value)) {
      return { hasSpecialChars: true };
    }

    return null;
  }
}
