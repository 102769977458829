import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { CryptoService } from '../crypto/crypto.service';

@Injectable()
export class UrlInterceptor implements HttpInterceptor {
  gatewayExceptions = [
    `lgn/realms/${environment.keycloak_realm}/protocol/openid-connect/token`,
    `/lgn/realms/${environment.keycloak_realm}/protocol/openid-connect/revoke/`,
    `${this.cryptoService.cipherPath}/sign`
  ];
  localExceptions = ['assets'];

  constructor(private cryptoService: CryptoService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!JSON.parse(environment.newGateway) || this.localExceptions.find(item => request.url.includes(item))) {
      return next.handle(request);
    }

    if (this.gatewayExceptions.find(item => request.url.includes(item))) {
      return next.handle(
        this.checkSlashUrl({
          request,
          urlWhitoutSlash: environment.urlBase + '' + request.url,
          urlWithSlash: environment.urlBase + '/' + request.url
        })
      );
    }

    if (request.url.includes('/api/')) {
      return next.handle(
        request.clone({
          url: request.url.replace('/api/', '/apigw/').replace('/1.0.0/', '/')
        })
      );
    }

    return next.handle(
      this.checkSlashUrl({
        request,
        urlWithSlash: environment.apiRootUrl + 'gw/' + request.url,
        urlWhitoutSlash: environment.apiRootUrl + 'gw' + request.url
      })
    );
  }

  checkSlashUrl({ request, urlWithSlash, urlWhitoutSlash }) {
    if (request.url.includes(environment.urlBase)) {
      return request;
    }

    if (request.url.startsWith('/')) {
      return request.clone({
        url: urlWhitoutSlash
      });
    } else {
      request = request.clone({
        url: urlWithSlash
      });
    }
  }
}
