import { PeriodFilterActions, PeriodFilterActionTypes } from './period-filter.actions';

export interface PeriodFilterState {
  period_filter: null;
}

export const initPeriodFilterState: PeriodFilterState = {
  period_filter: null
}

export function PeriodFilterReducer(state = initPeriodFilterState, action: PeriodFilterActions) {
  switch (action.type) {
    case PeriodFilterActionTypes.GET_SELECTED_PERIOD: {
      return {
        ...state,
        selected: true
      }
    }

    case PeriodFilterActionTypes.SET_SELECTED_PERIOD: {
      return {
        ...state,
        period_filter: action.payload
      }
    }

    default:
      return {...state};
  }
}
