import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BankSignUpData } from '@app/proposal-workflow/models/proposal-data.model';
import { SummaryModel } from '@app/proposal-workflow/models/summary.model';
import * as proposalInformationQueries from '@app/shared/graphql/queries/proposal-information.query';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { CONFIG_PROFILE_QUERY } from '@shared/graphql/queries/config.query';
import { GET_BIPARTITE_PARTNERS } from '@shared/graphql/queries/pendency.query';
import { configProfileQuery } from '@shared/graphql/types/query-types';
import { Apollo } from 'apollo-angular';
import { ApolloQueryResult } from 'apollo-client';
import { ExecutionResult } from 'graphql';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AddBipartitePartnerPayload, BipartitePartnerDTO, BipartitePartnersQueryDTO } from './models/bipartite.model';
import { PendencyTypeEnum } from './models/pendency-type.enum';
@Injectable({
  providedIn: 'root'
})
export class PendencyService {
  constructor(private readonly apollo: Apollo, private http: HttpClient, private translateService: TranslateService) {}

  public getProposalInformation(data: { proposalId: number; portalStage: PendencyTypeEnum }): Observable<any> {
    let query;
    let returnOnlyLastComment;
    switch (data.portalStage) {
      case PendencyTypeEnum.BPO_PENDENCY:
      case PendencyTypeEnum.CD_PENDENCY:
      case PendencyTypeEnum.HS_PREVENT_PENDENCY:
      case PendencyTypeEnum.BIPARTITE_PENDENCY:
      case PendencyTypeEnum.SHOPKEEPER_PENDENCY:
        query = proposalInformationQueries.GET_PROPOSAL_COMMENT_LIST;
        returnOnlyLastComment = true;
        break;
      case PendencyTypeEnum.PAYMENT_ERROR:
        query = proposalInformationQueries.GET_PROPOSAL_BANK_ACCOUNT_DATA;
        break;

      default:
        break;
    }
    if (!query) return;

    return this.apollo
      .watchQuery<any>({
        query: query,
        variables: {
          proposalCode: data.proposalId,
          returnOnlyLastComment
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(map((response: ExecutionResult) => response.data));
  }

  public updateBankAccountData(bankAccountData: BankSignUpData, proposalId: number): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-structure/proposal/${proposalId}/bank-account-data`;
    return this.http.put<any>(endpoint, bankAccountData);
  }

  public updateRegisterPendency(
    data: { name: string; documentNumber: string },
    proposalId: string | number
  ): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-structure/proposal/${proposalId}/register-data`;
    const payload = {
      id: proposalId,
      client: {
        name: data.name,
        documentNumber: data.documentNumber
      }
    };
    return this.http.put(endpoint, payload);
  }

  public getPendencyByProposalId(proposalId): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-structure/proposal/${proposalId}/pendency`;
    return this.http.get<any>(endpoint);
  }

  public updateBpoPendency(data: any, proposalId: string | number): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-structure/register/pendency/bpo`;
    const payload = {
      proposalId: proposalId,
      fields: data
    };
    return this.http.put<any>(endpoint, payload);
  }

  public proceed(proposalId: number, currentStage: number): Observable<any> {
    const body = {
      proposalId,
      channel: '2',
      currentStage
    };
    return this.http.post<any>(`${environment.apiRootUrl}/shopkeeper-structure/1.0.0/proposal/proceed`, body);
  }

  public getSuccessSummarySteps(): Observable<SummaryModel[]> {
    const summarySteps: SummaryModel[] = [
      {
        title: this.translateService.instant('PENDENCY-SUMMARY-SUCCESS-STEPS-TITLE'),
        steps: [
          {
            step: 1,
            description: this.translateService.instant('PENDENCY-SUMMARY-SUCCESS-STEPS-DESCRIPTION-1'),
            type: 'CUSTOMER'
          },
          {
            step: 2,
            description: this.translateService.instant('PENDENCY-SUMMARY-SUCCESS-STEPS-DESCRIPTION-2'),
            type: 'CUSTOMER'
          }
        ]
      }
    ];

    return of(summarySteps);
  }

  public getProposalPartners(proposalId: number): Observable<BipartitePartnerDTO[]> {
    const url = `${environment.apiRootUrl}/shopkeeper/1.0.0/proposal/${proposalId}/partner`;
    return this.http.get<BipartitePartnerDTO[]>(url);
  }

  public getPartners(proposalId: number): Observable<BipartitePartnerDTO[]> {
    const url = `${environment.apiRootUrl}/shopkeeper/1.0.0/proposal/${proposalId}/partner/v2`;
    return this.http.get<BipartitePartnerDTO[]>(url);
  }

  public getPartnersList(proposalId: number): Observable<BipartitePartnersQueryDTO> {
    return this.apollo
      .watchQuery<any>({
        query: GET_BIPARTITE_PARTNERS,
        variables: {
          proposta: proposalId
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: any) => {
          return response.data.listarGruposFornecedoresCanal;
        })
      );
  }

  public deletePartner(partnerId: number): Observable<any> {
    const url = `${environment.apiRootUrl}/shopkeeper/1.0.0/proposal/${partnerId}`;
    return this.http.delete<any>(url);
  }

  public addPartner(proposalId: number, payload: AddBipartitePartnerPayload): Observable<any> {
    const url = `${environment.apiRootUrl}/shopkeeper/1.0.0/proposal/${proposalId}/partner`;
    return this.http.put<any>(url, payload);
  }

  public getProposalPendencyBiometryFlowEnabled(): Observable<boolean> {
    return this.apollo
      .watchQuery({
        query: CONFIG_PROFILE_QUERY,
        variables: { profile: ['proposalPendencyBiometryFlow'] },
        errorPolicy: 'all'
      })
      .valueChanges.pipe(
        map((response: ApolloQueryResult<configProfileQuery>) => {
          return response?.data?.configProfiles[0]?.value === 'S' || false;
        }),
        catchError(() => {
          return of(false)
        })
      );
  }
}
