import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { AppState } from '@app/core/state';
import * as fromShowcase from '@app/core/state/showcase';
import { Store } from '@ngrx/store';
import { Countries } from '@shared/constants/countries.enum';
import { ArgentinaValidators } from '@shared/interfaces/validators/argentina-validators.interface';
import { ColombiaValidators } from '@shared/interfaces/validators/colombia-validators.interface';
import { PeruValidators } from '@shared/interfaces/validators/peru-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-salary-input',
  templateUrl: './salary-input.component.html',
  styleUrls: ['./salary-input.component.scss']
})
export class SalaryInputComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Output() continueEmit: EventEmitter<any> = new EventEmitter();

  public form: FormGroup;
  public currentCountry = this.internationalizationService.currentCountry;
  public pioneerMasks = this.internationalizationService.pioneerMasks;
  public pioneerValidators = this.internationalizationService.pioneerValidators;

  public economicActivities = [];

  constructor(
    private fb: FormBuilder,
    private internationalizationService: InternationalizationService,
    private store$: Store<AppState>
  ) {}

  ngOnInit(): void {
    this.createForm();
  }

  private getEconomicActivities(): void {
    this.store$
      .select(fromShowcase.selectors.economicActivities)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(economicActivities => {
        this.economicActivities = economicActivities;
      });

    this.store$.dispatch(new fromShowcase.actions.GetEconomicActivitiesAction());
  }

  private createForm(): void {
    this.form = this.fb.group({});

    switch (this.currentCountry) {
      case Countries.BR:
        // NA
        break;
      case Countries.AR:
        const validatorsArg = this.pioneerValidators as ArgentinaValidators;
        this.form.addControl('income', new FormControl('', validatorsArg.income));
        break;
      case Countries.PE:
        const validatorsPer = this.pioneerValidators as PeruValidators;
        this.form.addControl('income', new FormControl('', validatorsPer.income));
        this.form.addControl('economicActivity', new FormControl('', validatorsPer.economicActivity));
        this.getEconomicActivities();
        break;
      case Countries.CO:
        const validatorsCol = this.pioneerValidators as ColombiaValidators;
        this.form.addControl('income', new FormControl('', validatorsCol.income));
        this.form.addControl('economicActivity', new FormControl('', validatorsCol.economicActivity));
        this.getEconomicActivities();
        break;
      default:
        break;
    }
  }

  public submit(): void {
    this.continueEmit.emit(this.form.valid ? this.form.value : null);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
