<section id="6" class="simulation-section simulation-section-height">
  <header class="section-header">
    <h2 class="title">{{ 'PAYMENT-TYPE-TITLE' | translate }}</h2>
    <span>{{ paymentData?.sectionNumber }}</span>
  </header>

  <div class="section-main">
    <!-- card verde ARG e BRA -->
    <div class="card-info mb-5 cashback-card" *ngIf="isCashbackCardVisible && paymentData?.elegibleType !== 'CN'">
      <div class="row d-flex justify-content-end close-card-button">
        <button
          (click)="closeCard()"
          appTagger
          [event_category]="category"
          event_label="x"
          event_action="fechou modal – forma de pagamento"
        >
          <span class="material-icons close-card">close</span>
        </button>
      </div>

      <!-- imagem -->
      <div class="row">
        <div class="col-2 col-md-1 card-icon-container">
          <img src="./assets/images/ic_checking_account.svg" alt="bank-credit" class="card-icon" />
        </div>

        <!-- BRA -->
        <!-- cliente santander e elegível -->
        <div class="col" loadEvent (load)="loadModal(modalPaymentCE)" *ngIf="paymentData?.elegibleType === 'CE'">
          <p #modalPaymentCE>
            Ganhe {{ paymentData?.cashbackAmount | localeCurrency }} até o final do seu contrato! Basta escolher débito
            em conta Santander e pagar suas parcelas em dia para receber este benefício.
            <br />
            <a
              class="payment-way-link"
              (click)="openDialog()"
              appTagger
              [event_category]="category"
              [event_label]="'MODAL-CASHBACK-LINK-ACESS' | translate"
            >
              {{ 'MODAL-CASHBACK-LINK-ACESS' | translate }}
            </a>
          </p>
        </div>
        <!-- não é cliente santander e é elegível -->
        <div class="col" loadEvent (load)="loadModal(modalPaymentNE)" *ngIf="paymentData?.elegibleType === 'NE'">
          <p #modalPaymentNE>
            Seja correntista Santander e ganhe {{ paymentData?.cashbackAmount | localeCurrency }} até o final do seu
            contrato! Basta escolher débito em conta Santander e pagar suas parcelas em dia para receber este beneficio.
            <br />
            <a
              class="payment-way-link"
              (click)="openDialog()"
              appTagger
              [event_category]="category"
              [event_label]="'MODAL-CASHBACK-LINK-ACESS' | translate"
            >
              {{ 'MODAL-CASHBACK-LINK-ACESS' | translate }}
            </a>
          </p>
        </div>

        <!-- não é cliente santander e não é elegível -->
        <div class="col margin" *ngIf="paymentData?.elegibleType === 'NN'">
          <p>
            Abra sua conta Santander para garantir mais benefícios na proposta e tenha tudo que precisa em uma conta
            corrente.
          </p>
        </div>
        <!-- fim BRA -->
      </div>
    </div>
    <!-- fim card verde -->

    <!-- Opções de pagamento BRA e COL -->
    <div class="radio-group mb-5 pb-2 payment-type-radio">
      <mat-radio-group
        *ngIf="confirmationData?.accountantIndicator === 'S'"
        [(ngModel)]="data.paymentType"
        aria-label=""
        color="accent"
        class="row"
      >
        <mat-radio-button
          [ngClass]="!!isShowOthersBanks ? 'col-md-4' : 'col-md-6'"
          [value]="1"
          (click)="selectPayment(PaymentType.ACCOUNT_DEBIT); loadOthersBanks(false)"
        >
          {{ 'ACCOUNT-DEBIT' | translate }}
        </mat-radio-button>
        <mat-radio-button
          [ngClass]="!!isShowOthersBanks ? 'col-md-4' : 'col-md-6'"
          [value]="2"
          (click)="selectPayment(PaymentType.BILL); loadOthersBanks(false)"
        >
          {{ 'BANK BILL' | translate }}
        </mat-radio-button>
        <mat-radio-button
          [ngClass]="!!isShowOthersBanks ? 'col-md-4' : 'col-md-6'"
          [value]="4"
          (click)="selectPayment(PaymentType.OTHER_BANKS); loadOthersBanks(true)"
          *ngIf="!!isShowOthersBanks"
        >
          {{ 'OTHERS-BANKS' | translate }}
        </mat-radio-button>
      </mat-radio-group>
      <mat-radio-group
        *ngIf="confirmationData?.accountantIndicator === 'N'"
        [(ngModel)]="data.paymentType"
        aria-label=""
        color="accent"
        class="row"
      >
        <mat-radio-button
          class="col-md-6"
          [value]="2"
          (click)="selectPayment(PaymentType.BILL); loadOthersBanks(false)"
        >
          {{ 'BANK BILL' | translate }}
        </mat-radio-button>
        <mat-radio-button
          class="col-md-6"
          [value]="4"
          (click)="selectPayment(PaymentType.OTHER_BANKS); loadOthersBanks(true)"
          *ngIf="!!isShowOthersBanks"
        >
          {{ 'OTHERS-BANKS' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <!-- Dados bancários BRA e COL -->
    <div class="account-debit" *ngIf="data?.paymentType === 1 || (!!isShowOthersBanks && !!isLoadOthersBanks)">
      <div class="row" [formGroup]="accountFields">
        <div class="col-md-6">
          <mat-form-field class="full-width">
            <mat-label *ngIf="!!isLoadOthersBanks">
              {{ 'Choose the Institution' | translate }}
            </mat-label>
            <mat-select formControlName="santanderBank" *ngIf="!isLoadOthersBanks">
              <mat-option
                value="033"
                appTagger
                [event_category]="category"
                event_label="{{ 'Choose the Institution' | translate }}"
                [event_action]="Selecionar"
              >
                0033 - SANTANDER
              </mat-option>
            </mat-select>
            <mat-select formControlName="otherBank" *ngIf="!!isLoadOthersBanks">
              <mat-option
                *ngFor="let bank of othersBanks"
                [value]="bank.codigoBanco"
                appTagger
                [event_category]="category"
                event_label="{{ 'Choose the Institution' | translate }}"
                [event_action]="Selecionar"
              >
                {{ bank.nomeBanco }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>

      <div class="row" [formGroup]="accountFields">
        <div class="col-md-6">
          <mat-form-field class="full-width">
            <mat-label>{{ 'Account Type' | translate }}</mat-label>
            <mat-select formControlName="accountType">
              <mat-option
                *ngFor="let item of bankAccountType"
                [value]="item?.desc"
                appTagger
                [event_category]="category"
                event_label="{{ 'Account Type' | translate }} - {{ item?.type }}"
                [event_action]="Selecionar"
              >
                {{ item?.type }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-md-2">
          <mat-form-field class="full-width">
            <mat-label>{{ 'Agency' | translate }}</mat-label>
            <input
              matInput
              placeholder="Digite sua agência"
              mask="0000"
              formControlName="accountAgency"
              appTagger
              [event_category]="category"
              event_label="{{ 'Agency' | translate }}"
              [event_action]="Insert"
            />
          </mat-form-field>
        </div>
        <div class="col-md-4">
          <mat-form-field class="full-width">
            <mat-label>{{ 'Account and digit' | translate }}</mat-label>
            <input
              matInput
              placeholder="Digite sua conta e dígito"
              mask="00000000-0"
              formControlName="accountNumberAndDigit"
              appTagger
              [event_category]="category"
              event_label="{{ 'Account and digit' | translate }}"
              [event_action]="Insert"
            />
          </mat-form-field>
          <ng-container *ngIf="inValidBankAccount$ | async as error">
            <mat-hint *ngIf="error">&nbsp;</mat-hint>
          </ng-container>
        </div>
        <!-- <ng-container *ngIf="inValidBankAccount$ | async as valid"> -->
        <div class="row pt-0" *ngIf="!bankAccountServiceVallidated && !!isShowErrorValidationAccount">
          <!-- <div class="row pt-0" *ngIf="inValidBankAccount"> -->
          <div class="col px-4">
            <p class="error-message pt-0 mt-0 px-2">
              Dados inválidos, verifique os dados digitados e se a titularidade da conta pertence ao proponente dessa
              simulação
            </p>
          </div>
        </div>
        <!-- </ng-container> -->
      </div>
      {{ accountFields?.errors?.invalid_key }}
    </div>

    <!-- Card cinza BR -->
    <div class="card-gray" *ngIf="data?.paymentType === 2 && isSantanderClient">
      <p>
        Ative débito em conta e não se preocupe com o vencimento das parcelas, serão pagas sempre na data certa.
      </p>
    </div>
  </div>
</section>
