import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import { BipartiteData } from '@app/proposal-workflow/models/proposal-data.model';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { StatusProposals } from './../proposal.enum';

export interface Fee {
  id: string;
  feeType: string;
  feeValue: string;
}

export interface ProposalDTO {
  additionalInformation: string;
  certifiedAgent: string;
  channelSupplier: {
    code: number;
    id: string;
    name: string;
  };
  comments: string;
  createdByCustomer: boolean;
  createdDate: string;
  customer: {
    documentNumber: string;
    documentType: string;
    id: string;
    name: string;
    email: string;
  };
  entryValue: string;
  expireDate: string;
  fees: Fee[];
  financedValue: string;
  id: string;
  initialInstallmentValue: string;
  installmentAmount: string;
  installmentValue: string;
  installmentValueDouble: string;
  installmentAmountDouble: string;
  lastPaymentStatus: string;
  loanShares: [string];
  orderNumber: string;
  payments: string;
  product: {
    code: string;
    description: string;
  };
  proposalMessage: {
    message: string;
    status: string;
  };
  salesmen: [string];
  statusCode: StatusProposals;
  paymentType: {
    id: number;
    code?: PaymentTypeEnum;
    description: string;
  };
  customerType: string;
}

export interface GetFormalizationProposalListResDTO {
  getProposalById: FormalizationProposalDTO;
}

export interface FormalizationProposalDTO {
  id: string;
  createdDate: string;
  statusCode: string;
  expireDate: string;
  financedValue: string;
  totalFinancedValue: string;
  entryValue: string;
  installmentAmount: string | number;
  installmentValue?: string | number;
  installmentValueDouble?: string | number;
  installmentAmountDouble?: string | number;
  totalInstallmentAmount?: string | number;
  installmentsDetails?: any;
  lastPaymentStatus?: string;
  loanShares?: [string];
  orderNumber?: string;
  payments?: string;
  fees?: Fee[];
  customerType?: string;
  cetValue?: string;
  annualCetValue?: string;
  product: {
    code: ProductCodeEnum;
  };
  customer: {
    id?: string;
    documentNumber: string;
    email: string;
    name: string;
  };
  conditionFinanced: ConditionFinanced;
  paymentPlan: PaymentPlan[];
  conditionPlanFlex: ConditionPlanFlex;
  paymentType: {
    id: string;
    code?: PaymentTypeEnum;
    description: string;
    bipartite?: BipartiteData;
  };
  channelSupplier: {
    id: string;
    code?: number;
    description?: string;
  };
  integrationGenericFields: IntegrationGenericField[];
  contract?: { file?: string; UUID?: string };
  planType?: PaymentPlanType;
  insurance?: boolean;
  clientType: string;
  companyName?: string;
}

export interface ConditionPlanFlex {
  id: string;
  paymentDate: string;
  paymentValue: number;
  sequenceNumber: number;
  simulationId: number;
  updatedUserCode: string;
}

export interface IntegrationGenericField {
  id: string;
  hubIntegrationCode: string;
  genericFieldCode: string;
  fieldTypeCode: string;
  fieldValueCode: string;
}

export interface PaymentPlan {
  id: string;
  installmentValue: number;
  amortizationQuotaValue: number;
  interestRateValue: number;
  debitBalanceAmountValue: number;
  amortizationDate: string;
  installmentNumber: string;
  installmentBalanceDueAfter: string;
}

export interface ConditionFinanced {
  id?: string;
  tableCode?: string;
  maximumAprovedValue?: number;
  totalFinancedValue?: number;
  taxTypeId?: number;
  monthOccurrency?: number;
  frequenceId?: number;
  deadLineQuantity?: number;
  returnTaxPercentage?: number;
  annualReturnTaxPercentage?: number;
  contractTaxPercentage?: number;
  fabricationSubsidiaryTaxPercentage?: number;
  lodgeSubsidiaryTaxPercentage?: number;
  passiveTaxPercentage?: number;
  userUpdateCode?: string;
  dobleInstallmentAmount?: number;
  simpleInstallmentAmount?: number;
  maxInstallmentValue?: number;
  minInstallmentValue?: number;
}

export interface GetProposalListResDTO {
  proposalsList: {
    edges: [
      {
        cursor: any;
        node: ProposalDTO;
      }
    ];
  };
}

export enum PaymentTypeEnum {
  ACCOUNT = 'DC',
  MAIL = 'CA'
}

export enum GenericFieldEnum {
  IV = 'OSAIV',
  NONCE = 'OSANONCE'
}
