import { Injectable, Renderer2 } from '@angular/core';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';

export enum BiometryPictureEnum {
  OFFLINE = 1,
  ONLINE = 2,
  ERROR = 3,
  SUCCESS = 4
}

export enum BiometryErrorEnum {
  NOT_ALLOWED = 'E_NOT_ALLOWED',
  NO_DEVICE = 'E_NO_CAPTURE_DEVICE',
  NOT_FOUND = 'E_DEVICE_NOT_FOUND',
  NETWORK = 'E_NETWORK_ERROR',
  VIDEO_INIT = 'E_VIDEO_INIT_ERROR',
  UNKNOWN = 'E_UNKNOWN_ERROR',
  ABORT = 'E_ABORT',
  NOT_READABLE = 'E_DEVICE_NOT_READABLE',
  BROWSER = 'E_BROWSER_NOT_SUPPORTED',
  SECURITY = 'E_SECURITY_ERROR',
  INTERFACE = 'E_INTERFACE_NOT_IMPLEMENTED',
  MODEL = 'E_INVALID_MODEL_ERROR',
  PRELOADING = 'E_MODEL_PRELOADING_FAILED',
  STEGGER = 'STEGGER_ERROR'
}

@Injectable({
  providedIn: 'root'
})
export class BiometryManagerService {
  public sdkStage: BehaviorSubject<BiometryPictureEnum> = new BehaviorSubject(BiometryPictureEnum.OFFLINE);
  public imagesTaken: any[] = [];
  public ivParam = '';
  public nonceParam = '';
  public errorObject: BiometryErrorEnum;
  constructor() {}

  addJsToElement(src: string, id: string, renderer: Renderer2): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = id;
    script.src = src;
    renderer.appendChild(document.body, script);
    return script;
  }

  removeJsFromElement(id: string, renderer: Renderer2): void {
    const script = document.querySelector(`#${id}`);
    renderer.removeChild(document.body, script);
  }

  initiateBiometry() {
    this.sdkStage.next(BiometryPictureEnum.ONLINE);
  }

  // Needs definitions with the backend to know where this info will come from
  setIvNonce(iv: string, nonce: string): void {
    this.ivParam = iv;
    this.nonceParam = nonce;
  }

  getIvParam(): string {
    return this.ivParam;
  }

  getNonceParam(): string {
    return this.nonceParam;
  }

  successOnBiometry(images: any) {
    this.imagesTaken = [];
    this.imagesTaken.push(images);
    this.sdkStage.next(BiometryPictureEnum.SUCCESS);
  }

  errorOnBiometry(error: BiometryErrorEnum) {
    this.errorObject = error;
    this.sdkStage.next(BiometryPictureEnum.ERROR);
  }

  shutdownBiometry() {
    this.sdkStage.next(BiometryPictureEnum.OFFLINE);
  }

  getImages(): any[] {
    return this.imagesTaken;
  }

  getError(): BiometryErrorEnum {
    return this.errorObject;
  }

  cleanVariables(): void {
    this.imagesTaken = [];
  }

  log(message, customEvent = null): void {
    if (!environment.production) {
      console.log(message, customEvent);
    }
  }
}
