<div class="row align-items-center" style=" margin-bottom: 6%; margin-top: 4%;">
  <div class="col col-centered">
    <h1 style="font-weight: 600;">{{ titleModal }}</h1>
  </div>
</div>
<div class="row" style="margin-bottom: 6%;">
  <div class="col col-centered  ">
    <span style="font-weight: 600;">
      {{ textBody }}
    </span>
  </div>
</div>
<div class="row align-items-center">
  <div class="col col-centered" *ngFor="let button of data.labelButton">
    <button type="button" class="btn btn-{{ button.color }}" (click)="clicked(button.text)">{{ button.text }}</button>
  </div>
</div>
