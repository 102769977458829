<div class="container body-page">
  <div class="row">
    <div class="col">
      <img
        src="./assets/images/arrow-left-black.svg"
        class="d-none d-sm-block return"
        (click)="goBackPage()"
        alt="Go Back"
      />
    </div>
  </div>
  <div class="row mt-5">
    <div class="col-md-8 col-sm-12 content-body">
      <div class="row">
        <div class="col">
          <span class="title">
            {{ 'VALID-ACCOUNT' | translate }}
          </span>
        </div>
      </div>

      <div class="row">
        <div class="col col-sm-12 text-left">
          <span class="subtitle">
            {{ 'CONFIRM-INFORMATION-VALIDATION-CNPJ' | translate }}
          </span>
        </div>
      </div>
      <form *ngIf="challengeForm" [formGroup]="challengeForm">
        <div *ngFor="let input of inputs">
          <mat-form-field
            class="w-100"
            *ngIf="input.field.type !== typeInputChallegnge.currency && input.field.type !== typeInputChallegnge.date"
          >
            <mat-label>{{ input.question }}</mat-label>
            <input
              formControlName="{{ input.id }}"
              matInput
              placeholder="{{ input.question }}"
              mask="{{ input.field.mask }}"
            />
          </mat-form-field>
          <mat-form-field class="w-100" *ngIf="input.field.type === typeInputChallegnge.date">
            <mat-label>{{ input.question }}</mat-label>
            <input
              formControlName="{{ input.id }}"
              matInput
              placeholder="{{ input.question }}"
              mask="{{ $any(pioneerMasks).Date.mask }}"
            />
            <button
              mat-button
              matSuffix
              class="d-sm-none calendar-icon"
              type="button"
              mat-icon-button
              aria-label="open calendar to choose a date"
              (click)="openCalendar(input.id)"
            >
              <mat-icon>calendar_today</mat-icon>
            </button>
          </mat-form-field>
          <mat-form-field class="w-100" *ngIf="input.field.type === typeInputChallegnge.currency">
            <mat-label>{{ input.question }}</mat-label>
            <input
              *ngIf="input.field.type !== value"
              matInput
              formControlName="{{ input.id }}"
              placeholder="{{ input.question }}"
              mask="{{ $any(pioneerMasks).financedValue.mask }}"
              prefix="{{ $any(pioneerMasks).financedValue.prefix }}"
              thousandSeparator="{{ $any(pioneerMasks).financedValue.thousandSeparator }}"
            />
          </mat-form-field>
        </div>

        <div class="row">
          <div class="col">
            <button
              mat-raised-button
              [disabled]="challengeForm.invalid"
              (click)="sendAnswer()"
              class="button mb-1 w-100"
            >
              {{ 'CONTINUE' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
