import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppState } from '@app/core/state';
import { profileSelect } from '@app/core/state/profile/profile.selectors';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class CanActivateRouteGuard implements CanActivate {
  constructor(private store: Store<AppState>, private router: Router) {}

  canActivate(): Observable<boolean> {
    return this.store.select(profileSelect).pipe(
      map(user => {
        const canEnter = user.userProfile.userProfiles.some(
          role => role.includes('MASTER') || role.includes('FINANCEIRO')
        );

        if (!canEnter) {
          console.log('Failed!');
          this.router.navigate(['login']);
        }
        return canEnter;
      })
    );
  }
}
