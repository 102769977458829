<!-- DESKTOP -->
<div *ngIf="window.screen.width >= 768">
  <div class="row" *ngIf="data.nameClient">
    <div class="col-md-6 mt-2">
      <p>{{ 'CLIENT' | translate }}</p>
    </div>
    <div class="col-md-6 text-right mt-2">
      <h2>{{ data.nameClient }}</h2>
    </div>
  </div>
  <hr *ngIf="data.nameClient" />

  <div class="row" *ngIf="data.namePj">
    <div class="col-md-6 mt-2">
      <p>{{ 'CLIENT REP' | translate }}</p>
    </div>
    <div class="col-md-6 text-right mt-2">
      <h2>{{ data.namePj }}</h2>
    </div>
  </div>
  <hr *ngIf="data.namePj" />

  <div class="row" *ngIf="data.document && data.document.length === 11">
    <div class="col-md-6 mt-2">
      <p>{{ 'CPF' | translate }}</p>
    </div>
    <div class="col-md-6 text-right mt-2">
      <h2>{{ data.document | mask: '000.000.000-00' }}</h2>
    </div>
  </div>
  <div class="row" *ngIf="data.document && data.document.length === 14">
    <div class="col-md-6 mt-2">
      <p>{{ 'CNPJ' | translate }}</p>
    </div>
    <div class="col-md-6 text-right mt-2">
      <h2>{{ data.document | mask: '00.000.000/0000-00' }}</h2>
    </div>
  </div>
  <hr *ngIf="data.document" />

  <div class="row" *ngIf="data.nameCreation">
    <div class="col-md-6 mt-2">
      <p>{{ 'Creation' | translate }}</p>
    </div>
    <div class="col-md-6 text-right mt-2">
      <h2>{{ data.nameCreation }}</h2>
    </div>
  </div>
  <hr *ngIf="data.nameCreation" />

  <div class="row" *ngIf="data.creationDate">
    <div class="col-md-6 mt-2">
      <p>{{ 'Creation Date' | translate }}</p>
    </div>
    <div class="col-md-6 text-right mt-2">
      <h2>{{ data.creationDate | dateFormatPipe }}</h2>
    </div>
  </div>
  <hr *ngIf="data.creationDate" />

  <div class="row" *ngIf="data.expirationDate">
    <div class="col-md-6 mt-2">
      <p>{{ 'PENDENCY-SUMMARY-DATE-EXPIRE-PROPOSAL' | translate }}</p>
    </div>
    <div class="col-md-6 text-right mt-2">
      <h2>{{ data.expirationDate | dateFormatPipe }}</h2>
    </div>
  </div>
  <hr *ngIf="data.expirationDate" />

  <div class="row" *ngIf="data.paymentExpirationDate">
    <div class="col-md-6 mt-2">
      <p>{{ 'PENDENCY-SUMMARY-DATE-EXPIRE-PAG-PROPOSAL' | translate }}</p>
    </div>
    <div class="col-md-6 text-right mt-2">
      <h2>{{ data.paymentExpirationDate | dateFormatPipe }}</h2>
    </div>
  </div>
  <hr *ngIf="data.paymentExpirationDate && (data?.productCode !== 'CSC' && data?.productCode !== 'CSCP')">

  <div class="row" *ngIf="data.productCode !== 'CSC' && data.productCode !== 'CSCP'">
    <div class="col-md-6 mt-2">
      <p>{{ 'Total Effective Cost' | translate }}</p>
    </div>
    <div class="col-md-6 text-right mt-2">
      <h2>{{ data.cet | number: '1.2-2' }} {{ 'SIMULATION-SUMARY-PER-MONTH' | translate }}</h2>
    </div>
  </div>
  <!-- Bipartido -->
  <div *ngIf="data.splitFlow && data.splitFlow.length > 0" class="bipartido">
    <h2>
      <img src="./assets/santander/icons/ic_bipartition.svg" alt="icon bipartido" width="20" />
      <strong> {{ 'PENDENCY-SUMMARY-FLOW-BIPARITE' | translate }}</strong>
    </h2>
    <div *ngFor="let split of data.splitFlow; let i = index">
      <hr class="thicker" />
      <div class="row">
        <div class="col-md-6 mt-2">
          <p>{{ 'National Id' | translate }} N°{{ i + 1 }}</p>
        </div>
        <div class="col-md-6 text-right mt-2">
          <h2>{{ split.document | mask: '00.000.000/0000-00' }}</h2>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6 mt-2">
          <p>{{ 'Value' | translate }}</p>
        </div>
        <div class="col-md-6 text-right mt-2">
          <h2>{{ split.value | currency: 'BRL' }}</h2>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-md-6 mt-2">
          <p>{{ 'Percentage' | translate }}</p>
        </div>
        <div class="col-md-6 text-right mt-2">
          <h2>{{ split.percentage | number: '1.2' }}%</h2>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- MOBILE -->
<div class="mt-2" *ngIf="window.screen.width < 768">
  <div class="row" *ngIf="data.nameClient">
    <div class="col-md-6 mt-2">
      <p>{{ 'CLIENT' | translate }}</p>
    </div>
    <div class="col-md-6 mt-2 mb-2">
      <h2>{{ data.nameClient }}</h2>
    </div>
  </div>
  <div class="row" *ngIf="data.namePj">
    <div class="col-md-6 mt-2">
      <p>{{ 'CLIENT REP' | translate }}</p>
    </div>
    <div class="col-md-6 text-end mt-2">
      <h2>{{ data.namePj }}</h2>
    </div>
  </div>
  <div class="row" *ngIf="data.document && data.document.length === 11">
    <div class="col-md-6 mt-2">
      <p>{{ 'CPF' | translate }}</p>
    </div>
    <div class="col-md-6 mt-2 mb-2">
      <h2>{{ data.document | mask: '000.000.000-00' }}</h2>
    </div>
  </div>
  <div class="row" *ngIf="data.document && data.document.length === 14">
    <div class="col-md-6 mt-2">
      <p>{{ 'CNPJ' | translate }}</p>
    </div>
    <div class="col-md-6 mt-2 mb-2">
      <h2>{{ data.document | mask: '00.000.000/0000-00' }}</h2>
    </div>
  </div>
  <div class="row" *ngIf="data.nameCreation">
    <div class="col-md-6 mt-2">
      <p>{{ 'Creation' | translate }}</p>
    </div>
    <div class="col-md-6 mt-2 mb-2">
      <h2>{{ data.nameCreation }}</h2>
    </div>
  </div>
  <div class="row" *ngIf="data.creationDate">
    <div class="col-md-6 mt-2">
      <p>{{ 'Creation Date' | translate }}</p>
    </div>
    <div class="col-md-6 mt-2 mb-2">
      <h2>{{ data.creationDate | dateFormatPipe }}</h2>
    </div>
  </div>
  <div class="row" *ngIf="data.expirationDate">
    <div class="col-md-6 mt-2">
      <p>{{ 'PENDENCY-SUMMARY-DATE-EXPIRE-PROPOSAL' | translate }}</p>
    </div>
    <div class="col-md-6 mt-2 mb-2">
      <h2>{{ data.expirationDate | dateFormatPipe }}</h2>
    </div>
  </div>
  <div class="row" *ngIf="data.nameClient">
    <div class="col-md-6 mt-2">
      <p>{{ 'PENDENCY-SUMMARY-DATE-EXPIRE-PAG-PROPOSAL' | translate }}</p>
    </div>
    <div class="col-md-6 mt-2 mb-2">
      <h2>{{ data.paymentExpirationDate | dateFormatPipe }}</h2>
    </div>
  </div>
  <div class="row" *ngIf="data.productCode !== 'CSC' && data.productCode !== 'CSCP'">
    <div class="col-md-6 mt-2">
      <p>{{ 'Total Effective Cost' | translate }}</p>
    </div>
    <div class="col-md-6 mt-2 mb-2">
      <h2>{{ data.cet | number: '1.2-2' }} {{ 'SIMULATION-SUMARY-PER-MONTH' | translate }}</h2>
    </div>
  </div>
</div>
