import { PeriodBlnEnum } from './../../models/period-bln.enum';
import { Component, Input, OnChanges, OnInit, SimpleChanges, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { AppState } from '@app/core/state';
import { GetNppInstallments } from '@app/core/state/simulation/simulation.actions';
import { getNppInstallments } from '@app/core/state/simulation/simulation.selectors';
import { BestOfferResponseDTO } from '@app/proposal-workflow/models/best-offer.model';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-month-chart',
  templateUrl: './month-chart.component.html',
  styleUrls: ['./month-chart.component.scss']
})
export class MonthChartComponent implements OnInit, OnChanges, OnDestroy {
  @Input() bestOffer: BestOfferResponseDTO;
  @Input() selectedPeriod;
  @Input() proposalId;
  firstInstallmentDueDate;
  installmentPaymentMethodsValue;
  monthNumber;
  public periodBlnEnum = PeriodBlnEnum;
  public getMonthInstallmentPaymentMethodsValue;
  public getMonthFirstInstallmentDueDate;
  public datainstallmentPaymentMethodsValue;
  public dataGetMonthFirstInstallmentDueDate;
  protected ngUnsubscribe: Subject<any> = new Subject();
  constructor(private changeDetectorRef: ChangeDetectorRef) { }
  totalBars = 12;
  bars = [];

  ngOnInit() {
    if (!!this.bestOffer) {
      this.createdGraphic();
    }

  }



  ngOnChanges(change: SimpleChanges) {
  if(change.bestOffer || this.bestOffer){
      this.createdGraphic();
      const firtInstallment =  this.bestOffer?.bestOffer?.paymentMethods[0]?.firstInstallmentPMethodsDate
      this.installmentPaymentMethodsValue = firtInstallment? firtInstallment: null;
      this.datainstallmentPaymentMethodsValue = this.installmentPaymentMethodsValue?.split("-")? this.installmentPaymentMethodsValue?.split("-"): null;
      if(this.datainstallmentPaymentMethodsValue !== null){
      this.getMonthInstallmentPaymentMethodsValue = parseInt(this.datainstallmentPaymentMethodsValue[1], 10)
      }

      this.firstInstallmentDueDate = this.bestOffer?.bestOffer?.firstInstallmentDueDate
      this.dataGetMonthFirstInstallmentDueDate = this.firstInstallmentDueDate?.split("-");
      if(!!this.dataGetMonthFirstInstallmentDueDate){
      this.getMonthFirstInstallmentDueDate = parseInt(this.dataGetMonthFirstInstallmentDueDate[1], 10)
      }
    }
  }
  private createdGraphic() {
    if (this.bestOffer && this.bestOffer.bestOffer.installmentQuantity) {
      this.bars = [];
      switch (this.bestOffer.bestOffer.flexPlanType) {
        case 'ASC':
          this.createdGraphicGrowing();
          break;
        case 'DEC':
          this.createdGraphicDescending();
          break;
        case 'FLT':
          this.createdGraphicTraditional();
          break;
        case 'BLN':
          this.createdGraphicBalloon();
          break;
        case 'LACK':
          this.createdGraphicSkipInstallment();
          break;
        case 'SEASONAL':
          this.createdGraphicSeasonal();
          break;
        case 'FIXED':
          this.createdGraphicFixed();
          break;
      }
      this.changeDetectorRef.detectChanges();
    }
  }
  private createdGraphicGrowing() {
    for (let i = 0; i < this.totalBars; i++) {
      this.bars.push({
        percent: 20 + ((80 / 12) * (i + 1)),
        colorBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#ffff',
        borderBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
    }
  }
  private createdGraphicDescending() {
    for (let i = 0; i < this.totalBars; i++) {
      this.bars.push({
        percent: 20 + ((80 / 12) * (12 - i)),
        colorBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#ffff',
        borderBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
    }
  }
  private createdGraphicTraditional() {
    for (let i = 0; i < this.totalBars; i++) {
      this.bars.push({
        percent: 100,
        colorBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#ffff',
        borderBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
    }
  }
  private returnPeriod(p): string{
    if(p.code){
      return p.code;
    }
    else if(p.value.payment.codeMonthsRange){
      return p.value.payment.codeMonthsRange;
    }
  }
  private createdGraphicBalloon() {
    switch (this.returnPeriod(this.selectedPeriod)) {
      case PeriodBlnEnum.BIMESTRAL:
        this.createdGraphicBimestral();
        break;
      case PeriodBlnEnum.TRIMESTRAL:
        this.createdGraphicBalloonTrimestal();
        break;
      case PeriodBlnEnum.SEMESTRAL:
        this.createdGraphicBalloonSemestral();
        break;
      case PeriodBlnEnum.ANUAL:
        this.createdGraphicBalloonAnual();
        break;
    }
  }
  private createdGraphicBalloonAnual() {
    for (let i = 0; i < this.totalBars; i++) {
      let isTwentyPercentAnual = false;
      if ((i + 1) % 12 === 0) {
        isTwentyPercentAnual = true;
      }
      this.bars.push({
        percent: isTwentyPercentAnual ? 100 : 20,
        colorBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#ffff',
        borderBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
    }
  }
  private createdGraphicBalloonSemestral() {
    for (let i = 0; i < this.totalBars; i++) {
      let isTwentyPercentBimestral = false;
      if ((i + 1) % 6 === 0) {
        isTwentyPercentBimestral = true;
      }
      this.bars.push({
        percent: isTwentyPercentBimestral ? 100 : 20,
        colorBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#ffff',
        borderBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
    }
  }
  private createdGraphicBalloonTrimestal() {
    for (let i = 0; i < this.totalBars; i++) {
      let isTwentyPercentBimestral = false;
      if ((i + 1) % 3 === 0) {
        isTwentyPercentBimestral = true;
      }
      this.bars.push({
        percent: isTwentyPercentBimestral ? 100 : 20,
        colorBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#ffff',
        borderBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
    }
  }
  private createdGraphicBimestral() {
    let isTwentyPercentBimestral = false;
    for (let i = 0; i < this.totalBars; i++) {
      this.bars.push({
        percent: isTwentyPercentBimestral  ? 100 : 20,
        colorBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#ffff',
        borderBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
      isTwentyPercentBimestral = !isTwentyPercentBimestral;
    }
  }

  private createdGraphicSkipInstallment() {
   const period = this.selectedPeriod?.code?.toString()
    switch (period) {
      case PeriodBlnEnum.BIMESTRAL:
        this.createdGraphicSkipInstallmentBimestral();
        break;
      case PeriodBlnEnum.TRIMESTRAL:
        this.createdGraphicSkipInstallmentTrimestal();
        break;
      case PeriodBlnEnum.SEMESTRAL:
        this.createdGraphicSkipInstallmentSemestral();
        break;
      case PeriodBlnEnum.ANUAL:
        this.createdGraphicSkipInstallmentAnual();
        break;
    }
  }
  private createdGraphicSkipInstallmentBimestral() {
    let isTwentyPercentBimestral = false;
    for (let i = 0; i < this.totalBars; i++) {
      this.bars.push({
        percent: 100,
        colorBar: isTwentyPercentBimestral ? '#5ab8ca' : '#ffff',
        borderBar: isTwentyPercentBimestral ? '#5ab8ca' : '#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
      isTwentyPercentBimestral = !isTwentyPercentBimestral;
    }

  }
  private createdGraphicSkipInstallmentSemestral() {
    for (let i = 0; i < this.totalBars; i++) {
      let isSemestral = false;
      if ((i + 1) % 6 === 0) {
        isSemestral = true;
      }
      this.bars.push({
        percent: 100,
        colorBar: isSemestral ? '#ffff' : '#5ab8ca',
        borderBar: isSemestral ? '#e0e0e0' : '#5ab8ca',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
    }
  }

  private createdGraphicSkipInstallmentTrimestal() {
    for (let i = 0; i < this.totalBars; i++) {
      let isTwentyPercentBimestral = false;
      if ((i + 1) % 3 === 0) {
        isTwentyPercentBimestral = true;
      }
      this.bars.push({
        percent: 100,
        colorBar: isTwentyPercentBimestral ? '#ffff' : '#5ab8ca',
        borderBar: isTwentyPercentBimestral ? '#e0e0e0' : '#5ab8ca',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
    }
  }

  private createdGraphicSkipInstallmentAnual() {
    for (let i = 0; i < this.totalBars; i++) {
      let isTwentyPercentAnual = false;
      if ((i + 1) % 12 === 0) {
        isTwentyPercentAnual = true;
      }
      this.bars.push({
        percent: 100,
        colorBar: isTwentyPercentAnual ? '#ffff' : '#5ab8ca',
        borderBar: isTwentyPercentAnual ? '#e0e0e0' : '#5ab8ca',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
    }
  }

  private createdGraphicFixed() {
    if (this.bestOffer.bestOffer.customInstallmentValue > this.bestOffer.bestOffer.defaultInstallmentValue) {
      this.createdGraphicFixedBimestralValueBigDefault()
    }else if(this.bestOffer.bestOffer.customInstallmentValue === this.bestOffer.bestOffer.defaultInstallmentValue){
      this.createdGraphicFixedEqualValueFixed()
    }
    else {
      this.createdGraphicFixedBimestralValueFixedBig();
    }
  }

  private createdGraphicFixedEqualValueFixed(){
    for (let i = 0; i < this.totalBars; i++) {
      this.bars.push({
        percent: 100,
        colorBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#ffff',
        borderBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
    }
  }

  private createdGraphicFixedBimestralValueBigDefault(){
    for (let i = 0; i < this.totalBars; i++) {
      let isTwentyPercentBimestral = false;
      if (i+1 <= this.bestOffer.bestOffer.fixedInstallmentQuantity) {
        isTwentyPercentBimestral = true
      }
      this.bars.push({
        percent: isTwentyPercentBimestral ? 100 : 20,
        colorBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#ffff',
        borderBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });

    }
  }
  private createdGraphicFixedBimestralValueFixedBig(){
    for (let i = 0; i < this.totalBars; i++) {
      let  isTwentyPercentBimestral = false;
      if (i+1 <= this.bestOffer.bestOffer.fixedInstallmentQuantity) { isTwentyPercentBimestral = true }
      this.bars.push({
        percent: isTwentyPercentBimestral ? 20 : 100,
        colorBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#ffff',
        borderBar: this.bars.length < this.bestOffer.bestOffer.installmentQuantity ? '#5ab8ca' : '#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });

    }
  }

  private createdGraphicSeasonal() {
    switch (this.selectedPeriod.code) {
      case PeriodBlnEnum.BIMESTRAL:
        this.createdGraphicSeasonalBimestral();
        break;
      case PeriodBlnEnum.TRIMESTRAL:
        this.createdGraphicSeasonalTrimestral();
        break;
      case PeriodBlnEnum.SEMESTRAL:
        this.createdGraphicSeasonalSemestral();
        break;
      case PeriodBlnEnum.ANUAL:
        this.createdGraphicSeasonalAnual();
        break;
    }
  }

  private createdGraphicSeasonalBimestral() {
    let firstInstallmentPayment = this.getMonthInstallmentPaymentMethodsValue;
    if(firstInstallmentPayment < this.getMonthFirstInstallmentDueDate){
      firstInstallmentPayment = firstInstallmentPayment + 12
    }
    const diffMonth = this.getMonthInstallmentPaymentMethodsValue - this.getMonthFirstInstallmentDueDate
    let isTwentyPercentBimestral = true;

    for (let i = 0; i < this.totalBars; i++) {
      if(i < diffMonth){
        isTwentyPercentBimestral = false
      }
      this.bars.push({
        percent: 100,
        colorBar: (isTwentyPercentBimestral && this.bars.length < this.bestOffer.bestOffer.installmentQuantity)?  '#5ab8ca':'#ffff',
        borderBar: (isTwentyPercentBimestral && this.bars.length < this.bestOffer.bestOffer.installmentQuantity)? '#5ab8ca':'#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
      isTwentyPercentBimestral = !isTwentyPercentBimestral;

    }
  }

  private createdGraphicSeasonalTrimestral() {
    let firstInstallmentPayment = this.getMonthInstallmentPaymentMethodsValue;
    if(firstInstallmentPayment < this.getMonthFirstInstallmentDueDate){
      firstInstallmentPayment = firstInstallmentPayment + 12
    }
    const diffMonth = this.getMonthInstallmentPaymentMethodsValue - this.getMonthFirstInstallmentDueDate
    for (let i = 0; i < this.totalBars; i++) {
      let isTwentyPercentBimestral = false;
      if((i === diffMonth) || (((i - diffMonth) % 3 === 0) && i > diffMonth)){
        isTwentyPercentBimestral = true;
      }
      this.bars.push({
        percent: 100,
        colorBar: (isTwentyPercentBimestral && this.bars.length < this.bestOffer.bestOffer.installmentQuantity)? '#5ab8ca':'#ffff' ,
        borderBar: (isTwentyPercentBimestral && this.bars.length < this.bestOffer.bestOffer.installmentQuantity) ?  '#5ab8ca':'#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
    }
  }
  private createdGraphicSeasonalSemestral() {
    let firstInstallmentPayment = this.getMonthInstallmentPaymentMethodsValue;
    if(firstInstallmentPayment < this.getMonthFirstInstallmentDueDate){
      firstInstallmentPayment = firstInstallmentPayment + 12
    }
    const diffMonth = this.getMonthInstallmentPaymentMethodsValue - this.getMonthFirstInstallmentDueDate
    for (let i = 0; i < this.totalBars; i++) {
      let isTwentyPercentBimestral = false;
      if ((i === diffMonth) || (i === (diffMonth + 6))){
        isTwentyPercentBimestral = true;
      }
      this.bars.push({
        percent: 100,
        colorBar: (isTwentyPercentBimestral && this.bars.length < this.bestOffer.bestOffer.installmentQuantity)?  '#5ab8ca': '#ffff' ,
        borderBar: (isTwentyPercentBimestral && this.bars.length < this.bestOffer.bestOffer.installmentQuantity) ? '#5ab8ca' :'#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '100%'
      });
    }
  }

  private createdGraphicSeasonalAnual() {
    let firstInstallmentPayment = this.getMonthInstallmentPaymentMethodsValue

    if(firstInstallmentPayment < this.getMonthFirstInstallmentDueDate){
      firstInstallmentPayment = firstInstallmentPayment + 12
    }
    const diffMonth = firstInstallmentPayment - this.getMonthFirstInstallmentDueDate

    for (let i = 0; i < this.totalBars; i++) {
      let isTwentyPercentAnual = false;

      if (i === diffMonth) {
        isTwentyPercentAnual = true;
      }
      this.bars.push({
        percent: 100,
        colorBar: (isTwentyPercentAnual && this.bars.length < this.bestOffer.bestOffer.installmentQuantity) ? '#5ab8ca': '#ffff',
        borderBar: (isTwentyPercentAnual && this.bars.length < this.bestOffer.bestOffer.installmentQuantity) ? '#5ab8ca': '#6d6e6e',
        month: this.setMonth(i),
        height: this.setMonth(i) ? (this.bars.length ===  4 || this.bars.length ===  5) : '106%'
      });
    }
  }

  public setMonth(indice) {
    const [year, month, day] = this.bestOffer.bestOffer.firstInstallmentDueDate.split('-').
      map(p => parseInt(p, 10));
    const monthNumber = month - 1 + indice;
    const date = new Date(year, monthNumber, day);
    let monthValue = new Intl.DateTimeFormat('pt-BR', { month: 'short' }).format(date);
    this.monthNumber = monthNumber;
    return monthValue.substring(0, monthValue.length - 1);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
