import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AlertMaterialComponent } from '@app/products/dialog/alert/alertMaterial.component';

import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-offer-page',
  templateUrl: './offer-page.component.html',
  styleUrls: ['./offer-page.component.scss']
})
export class OfferPageComponent implements OnInit {
  public customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: true,
    navSpeed: 700,
    navText: ['', ''],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 3
      },
      740: {
        items: 3
      },
      940: {
        items: 3
      }
    },
    nav: false
  };

  public customOptionsDesktopPersonalOffer: OwlOptions = {
    loop: false,
    mouseDrag: false,
    touchDrag: false,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: [
      '<img class="arrow-left" src="./assets/images/arrow-left-black.svg" alt="">',
      '<img src="./assets/images/arrow-left-black.svg" class="arrow-right" alt="">'
    ],
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 2
      },
      940: {
        items: 2
      }
    },
    nav: true
  };

  public propostaAberto: Array<any> = [
    {
      title: 'Etna1',
      date: '20/04/2020'
    },
    {
      title: 'Etna2',
      date: '20/04/2020'
    },
    {
      title: 'Etna3',
      date: '20/04/2020'
    },
    {
      title: 'Etna4',
      date: '20/04/2020'
    },
    {
      title: 'Etna5',
      date: '20/04/2020'
    },
    {
      title: 'Etna6',
      date: '20/04/2020'
    },
    {
      title: 'Etna7',
      date: '20/04/2020'
    },
    {
      title: 'Etna8',
      date: '20/04/2020'
    }
  ];

  public ofertaPersonalizada: Array<any> = [
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    },
    {
      title: 'Emprestimo Pessoal',
      price: '1.111,11',
      parcel: '18',
      totalPrice: '20.000,00',
      rate: '1.91'
    }
  ];

  public ofertaEspecial: Array<any> = [
    {
      title: 'Magazine Luiza',
      text: 'Desconto de ate 20% no mês de maio'
    },
    {
      title: 'Magazine Luiza',
      text: 'Desconto de ate 20% no mês de maio'
    }
  ];

  constructor(public dialog: MatDialog) {}

  ngOnInit() {}

  public chamadaPreAnalise(): void {
    const preAnalise = true;

    if (preAnalise) {
      this.dialog.open(AlertMaterialComponent, {
        width: '500px',
        data: {
          title: 'Sem ofertas disponíveis',
          text: 'Não temos ofertas disponíveis para esse CPF no momento.',
          numberButton: '1',
          labelButton: [{ text: 'Ok', color: 'danger' }]
        }
      });
    }
  }
}
