import { Action } from '@ngrx/store';

export enum TabsActionTypes {
  GET_TABS = '[TABS] GET_TABS',
  GET_TABS_SUCCESS = '[TABS] GET_TABS_SUCCESS',
  GET_TABS_ERROR = '[TABS] GET_TABS_ERROR',

  SAVE_TAB = '[TABS] SAVE_TAB',
  CLEAR_TAB = '[TABS] CLEAR_TAB',

  GET_PRODUCTS = '[TABS] GET_PRODUCTS',
  GET_PRODUCTS_SUCCESS = '[TABS] GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_ERROR = '[TABS] GET_PRODUCTS_ERROR',

  GET_SECONDARY_USER_SELECTED_SUCCESS = '[TABS] GET_SECONDARY_USER_SUCCESS'
}

export class GetTabs implements Action {
  readonly type = TabsActionTypes.GET_TABS;
  constructor(public payload: any) {}
}

export class GetTabsSuccess implements Action {
  readonly type = TabsActionTypes.GET_TABS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetTabsError implements Action {
  readonly type = TabsActionTypes.GET_TABS_ERROR;
  constructor(public payload: any) {}
}

export class SaveTab implements Action {
  readonly type = TabsActionTypes.SAVE_TAB;
  constructor(public payload: any) {}
}

export class ClearTab implements Action {
  readonly type = TabsActionTypes.CLEAR_TAB;
  constructor(public payload: any) {}
}

export class GetProducts implements Action {
  readonly type = TabsActionTypes.GET_PRODUCTS;
  constructor(public payload?: any) {}
}

export class GetProductsSuccess implements Action {
  readonly type = TabsActionTypes.GET_PRODUCTS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetProductsError implements Action {
  readonly type = TabsActionTypes.GET_PRODUCTS_ERROR;
  constructor(public payload: any) {}
}

export class GetSecondaryUserSuccess implements Action {
  readonly type = TabsActionTypes.GET_SECONDARY_USER_SELECTED_SUCCESS;
  constructor(public paylod: any) {}
}

export type TabsActions =
  | GetTabs
  | GetTabsSuccess
  | GetTabsError
  | SaveTab
  | ClearTab
  | GetProducts
  | GetProductsSuccess
  | GetProductsError
  | GetSecondaryUserSuccess;
