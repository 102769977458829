import { createSelector } from '@ngrx/store';

export const tabSelector = (state: any) => state.tabs;

export const selectTabs = createSelector(
  tabSelector,
  state => state.tabs
);

export const selectSelectedTab = createSelector(
  tabSelector,
  state => state.selectedTab
);

export const selectProducts = createSelector(
  tabSelector,
  state => state.products
);

export const selectSecondaryUser = createSelector(
  tabSelector,
  state => state.selectUser
);
