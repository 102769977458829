<div *ngFor="let file of loadedChecklist; let i = index">
  <div *ngIf="currentUrl === '/proposal/step-formalization' && file === loadedChecklist[0]" class="titleGroup">
    {{ file?.uploadGroup?.description }}
  </div>

  <app-checklist-item
    [checklistItem]="file"
    [maxSizeAllowed]="maxSizeAllowed"
    (uploadFinished)="handleUploadFinished($event)"
    [category]="category"
  ></app-checklist-item>

  <div
    class="titleGroup"
    *ngIf="
      currentUrl === '/proposal/step-formalization' &&
      file?.uploadGroup?.code !== loadedChecklist[i + 1]?.uploadGroup?.code &&
      this.loadedChecklist[this.loadedChecklist.length - 1] !== file
    "
  >
    {{ loadedChecklist[i + 1]?.uploadGroup?.description }}
  </div>
</div>
