import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@app/core/state';
import * as fromShowcase from '@app/core/state/showcase';
import { SelectStoreAction } from '@app/core/state/showcase/showcase.actions';
import { Store } from '@ngrx/store';
import { StoreModel } from '@shared/models/store.model';
import { SubsegmentModel } from '@shared/models/subsegment.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Identification } from '../models/api/post.identification.model';

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.scss']
})
export class StoresComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  public selectedSubsegment: SubsegmentModel;
  public selectedStore: StoreModel;

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.getSubsegment();
  }

  /**
   * Get Subsegment selected in preview page
   */
  getSubsegment() {
    this.store
      .select(fromShowcase.selectors.selectSelectedSubsegment)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(selectedSubsegment => (this.selectedSubsegment = selectedSubsegment));
  }

  /**
   * Capture store and send request to save pre analysis
   * @param storeModel Object of Store
   */
  handleStore(storeModel: StoreModel) {
    const payload: Identification = {
      store: storeModel,
      subsegment: this.selectedSubsegment
    };
    this.store.dispatch(new SelectStoreAction(payload));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
