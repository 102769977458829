import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SignInContainer } from '@app/login/containers/sign-in/sign-in.container.component';
import { ApplicationHeaders } from '@shared/components/ui/header/header.models';
import { PasswordRecoveryContainer } from './containers/password-recovery/password-recovery.container.component';
import { RecoveryTokenComponent } from './components/recovery-token/recovery-token.component';
import { NewPasswordComponent } from './components/new-password/new-password.component';
import { PreTokenComponent } from './components/pre-token/pre-token.component';
import { LoginContainerComponent } from './containers/login-container/login-container.component';
import { HelpScreenComponent } from './components/help-screen/help-screen.component';
import { ChallengeTermsComponent } from './components/challenge-terms/challenge-terms.component';
import { LojistaComponent } from './containers/lojista/lojista.component';

const routes: Routes = [
  {
    path: '',
    component: LoginContainerComponent,
    data: {
      header: ApplicationHeaders.Login
    }
  },
  {
    path: 'sign-in',
    component: SignInContainer,
    data: { header: ApplicationHeaders.Login }
  },
  {
    path: 'select-store',
    component: LojistaComponent,
    data: { header: ApplicationHeaders.Login }
  },
  {
    path: 'recovery',
    component: PasswordRecoveryContainer,
    children: [
      {
        path: 'new-password',
        component: NewPasswordComponent,
        data: { header: ApplicationHeaders.Login }
      },
      { path: '', redirectTo: 'recovery-token', pathMatch: 'full' },
      { path: 'pre-token', component: PreTokenComponent, data: { header: ApplicationHeaders.Login } },
      { path: 'help', component: HelpScreenComponent, data: { header: ApplicationHeaders.Login } },
      { path: '**', component: RecoveryTokenComponent, data: { header: ApplicationHeaders.Login } }
    ]
  },
  {
    path: 'terms',
    component: ChallengeTermsComponent,
    data: { header: ApplicationHeaders.Login }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class LoginRoutingModule {}
