import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GetProposalStageDTO, ProposalMacroStage } from '@app/proposal-workflow/models/proposal-stage.model';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProposalStageService {
  constructor(private http: HttpClient, private router: Router) {}

  public getProposalStage(proposalId: number | string): Observable<GetProposalStageDTO> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-structure/stageProposal/${proposalId}`;
    return this.http.get<GetProposalStageDTO>(endpoint);
  }

  public redirectProposal(stage: GetProposalStageDTO): void {
    if (!stage) return;

    let target: string;
    switch (stage.groupId) {
      case ProposalMacroStage.SIMULATION:
        target = '/proposal/step-simulation';
        break;
      case ProposalMacroStage.REGISTER:
        target = '/proposal/step-register';
        break;
      case ProposalMacroStage.CONFIRMATION:
        target = '/proposal/step-confirmation';
        break;
      case ProposalMacroStage.FORMALIZATION:
        target = '/proposal/step-formalization';
        break;
      default:
        break;
    }
    if (!this.router.url.includes(target)) {
      this.router.navigate([target]);
    }
  }
}
