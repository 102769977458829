import { Action } from '@ngrx/store';
import { FType } from './login.reducer';

export enum LoginActionTypes {
  REMEMBER_USER = '[Login] [User] Remember User',
  FORGET_USER = '[Login] [User] Forget User',

  SUBMIT_LOGIN_DATA = '[Login] [User] Submit Login Data',
  SUBMIT_LOGIN_DATA_SUCCESS = '[Login] [App] Submit Login Data Success',
  SUBMIT_LOGIN_DATA_ERROR = '[Login] [App] Submit Login Data Error',
  SUBMIT_LOGIN_DATA_BLOCK = '[Login] [App] Submit Login Data Block',
  SUBMIT_LOGIN_DATA_INCORRECT = '[Login] [App] Submit Login Data Incorrect',

  SUBMIT_LOGOUT = '[Logout] Submit Logout',

  GET_RECOVERY_TOKEN = '[Login] Get Recovery Token',
  GET_RECOVERY_TOKEN_SUCCESS = '[Login] Get Recovery Token Success',
  GET_RECOVERY_TOKEN_ERROR = '[Login] Get Recovery Token Error',

  SUBMIT_RECOVERY_TOKEN = '[Login] [User] Submit Recovery Token',
  SUBMIT_RECOVERY_TOKEN_SUCCESS = '[Login] [App] Submit Recovery Token Success',
  SUBMIT_RECOVERY_TOKEN_ERROR = '[Login] [App] Submit Recovery Token Error',

  REFRESH_RECOVERY_TOKEN = '[Login] [User] Refresh Recovery Token',
  REFRESH_RECOVERY_TOKEN_SUCCESS = '[Login] [App] Refresh Recovery Token Success',
  REFRESH_RECOVERY_TOKEN_ERROR = '[Login] [App] Refresh Recovery Token Error',

  SUBMIT_NEW_PASSWORD = '[Login] [User] Submit New Password',
  SUBMIT_NEW_PASSWORD_SUCCESS = '[Login] [App] Submit New Password Success',
  SUBMIT_NEW_PASSWORD_ERROR = '[Login] [App] Submit New Password Error',

  GET_USER_PROPERTIES = '[Login] Get User Properties',
  GET_USER_PROPERTIES_SUCCESS = '[Login] Get User Properties Success',
  GET_USER_PROPERTIES_ERROR = '[Login] Get User Properties Error',

  REVOKE_TOKEN = '[Login] Revoke token',
  REVOKE_TOKEN_SUCCESS = '[Login] Revoke token success',
  REVOKE_TOKEN_ERROR = '[Login] Revoke token error',

  RESET_LOGIN_DATA = '[Login] [App] Reset Login Data',
  RESET_ERRORS = '[Login] [App] Reset Errors',

  UPDATE_USER_FLAGS = '[Login] UPDATE_USER_FLAGS',
  UPDATE_USER_FLAGS_SUCCESS = '[Login] UPDATE_USER_FLAGS_SUCCESS',
  UPDATE_USER_FLAGS_ERROR = '[Login] UPDATE_USER_FLAGS_ERROR',

  DOCUMENT_TYPES_REQUEST = '[Login] Requesting document types',
  DOCUMENT_TYPES_REQUEST_SUCCESS = '[Login] Requesting document types success',
  DOCUMENT_TYPES_REQUEST_ERROR = '[Login] Requesting document types error',

  SET_TUTORIAL_FLAG_TO_FALSE = '[Login] Set Tutorial Flag to False',

  SET_FTYPE = '[Login] Set fType',
  SUBMIT_LOGIN_WITH_STORE_DATA = '[Login] [User] Submit Login With Store Data'
}

export class DocumentTypesRequest implements Action {
  readonly type = LoginActionTypes.DOCUMENT_TYPES_REQUEST;
  constructor() {}
}

export class DocumentTypesRequestSuccess implements Action {
  readonly type = LoginActionTypes.DOCUMENT_TYPES_REQUEST_SUCCESS;
  constructor(public payload: any) {}
}

export class DocumentTypesRequestError implements Action {
  readonly type = LoginActionTypes.DOCUMENT_TYPES_REQUEST_ERROR;
  constructor(public payload: any) {}
}

export class RememberUserAction implements Action {
  readonly type = LoginActionTypes.REMEMBER_USER;
  constructor(public payload: any) {}
}

export class ForgetUserAction implements Action {
  readonly type = LoginActionTypes.FORGET_USER;
  constructor() {}
}

export class SubmitLoginDataAction implements Action {
  readonly type = LoginActionTypes.SUBMIT_LOGIN_DATA;
  constructor(public payload: any) {}
}

export class SubmitLoginDataSuccessAction implements Action {
  readonly type = LoginActionTypes.SUBMIT_LOGIN_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class SubmitLoginDataErrorAction implements Action {
  readonly type = LoginActionTypes.SUBMIT_LOGIN_DATA_ERROR;
  constructor(public payload: any) {}
}

export class SubmitLoginBlockDataErrorAction implements Action {
  readonly type = LoginActionTypes.SUBMIT_LOGIN_DATA_BLOCK;
  constructor(public payload: any) {}
}

export class SubmitLoginDataIncorrectAction implements Action {
  readonly type = LoginActionTypes.SUBMIT_LOGIN_DATA_INCORRECT;
  constructor(public payload: any) {}
}

export class GetUserProperties implements Action {
  readonly type = LoginActionTypes.GET_USER_PROPERTIES;
  constructor(public payload: any) {}
}

export class GetUserPropertiesSuccess implements Action {
  readonly type = LoginActionTypes.GET_USER_PROPERTIES_SUCCESS;
  constructor(public payload: any) {}
}

export class GetUserPropertiesError implements Action {
  readonly type = LoginActionTypes.GET_USER_PROPERTIES_ERROR;
  constructor(public payload: any) {}
}

export class SubmitRecoveryTokenAction implements Action {
  readonly type = LoginActionTypes.SUBMIT_RECOVERY_TOKEN;
  constructor(public payload: any) {}
}

export class SubmitRecoveryTokenSuccessAction implements Action {
  readonly type = LoginActionTypes.SUBMIT_RECOVERY_TOKEN_SUCCESS;
  constructor(public payload: any) {}
}

export class SubmitRecoveryTokenErrorAction implements Action {
  readonly type = LoginActionTypes.SUBMIT_RECOVERY_TOKEN_ERROR;
  constructor(public payload: any) {}
}

export class RefreshRecoveryTokenAction implements Action {
  readonly type = LoginActionTypes.REFRESH_RECOVERY_TOKEN;
  constructor() {}
}

export class RefreshRecoveryTokenSuccessAction implements Action {
  readonly type = LoginActionTypes.REFRESH_RECOVERY_TOKEN_SUCCESS;
  constructor(public payload: any) {}
}

export class RefreshRecoveryTokenErrorAction implements Action {
  readonly type = LoginActionTypes.REFRESH_RECOVERY_TOKEN_ERROR;
  constructor(public payload: any) {}
}

export class SubmitNewPasswordAction implements Action {
  readonly type = LoginActionTypes.SUBMIT_NEW_PASSWORD;
  constructor(public payload: any) {}
}

export class SubmitNewPasswordSuccessAction implements Action {
  readonly type = LoginActionTypes.SUBMIT_NEW_PASSWORD_SUCCESS;
  constructor() {}
}

export class SubmitNewPasswordErrorAction implements Action {
  readonly type = LoginActionTypes.SUBMIT_NEW_PASSWORD_ERROR;
  constructor(public payload: any) {}
}

export class ResetLoginData implements Action {
  readonly type = LoginActionTypes.RESET_LOGIN_DATA;
  constructor() {}
}

export class ResetErrors implements Action {
  readonly type = LoginActionTypes.RESET_ERRORS;
  constructor() {}
}

export class GetRecoveryToken implements Action {
  readonly type = LoginActionTypes.GET_RECOVERY_TOKEN;
  constructor(public payload: any) {}
}
export class GetRecoveryTokenSuccess implements Action {
  readonly type = LoginActionTypes.GET_RECOVERY_TOKEN_SUCCESS;
  constructor(public payload: any) {}
}
export class GetRecoveryTokenError implements Action {
  readonly type = LoginActionTypes.GET_RECOVERY_TOKEN_ERROR;
  constructor(public payload: any) {}
}

export class RevokeToken implements Action {
  readonly type = LoginActionTypes.REVOKE_TOKEN;
  constructor(public payload: any) {}
}

export class RevokeTokenSuccess implements Action {
  readonly type = LoginActionTypes.REVOKE_TOKEN_SUCCESS;
  constructor() {}
}

export class RevokeTokenError implements Action {
  readonly type = LoginActionTypes.REVOKE_TOKEN_ERROR;
  constructor(public payload: any) {}
}

export class UpdateUserFlags implements Action {
  readonly type = LoginActionTypes.UPDATE_USER_FLAGS;
  constructor(public payload: any) {}
}

export class UpdateUserFlagsSuccess implements Action {
  readonly type = LoginActionTypes.UPDATE_USER_FLAGS_SUCCESS;
  constructor(public payload: any) {}
}

export class UpdateUserFlagsError implements Action {
  readonly type = LoginActionTypes.UPDATE_USER_FLAGS_ERROR;
  constructor(public payload: any) {}
}

export class SubmitLogoutAction implements Action {
  readonly type = LoginActionTypes.SUBMIT_LOGOUT;
}

export class SetTutorialFlagToFalse implements Action {
  readonly type = LoginActionTypes.SET_TUTORIAL_FLAG_TO_FALSE;
  constructor(public payload: string) {}
}

export class SetFtype implements Action {
  readonly type = LoginActionTypes.SET_FTYPE;
  constructor(public payload: FType) {}
}

export class SubmitLoginWithStoreAction implements Action {
  readonly type = LoginActionTypes.SUBMIT_LOGIN_WITH_STORE_DATA;
  constructor(public payload: any) {}
}

export type LoginActions =
  | RememberUserAction
  | ForgetUserAction
  | SubmitLoginDataAction
  | SubmitLoginDataSuccessAction
  | SubmitLoginDataErrorAction
  | SubmitLoginBlockDataErrorAction
  | SubmitLoginDataIncorrectAction
  | SubmitLogoutAction
  | ResetErrors
  | GetUserProperties
  | GetUserPropertiesSuccess
  | GetUserPropertiesError
  | GetRecoveryToken
  | GetRecoveryTokenSuccess
  | GetRecoveryTokenError
  | SubmitRecoveryTokenAction
  | SubmitRecoveryTokenSuccessAction
  | SubmitRecoveryTokenErrorAction
  | RefreshRecoveryTokenAction
  | RefreshRecoveryTokenSuccessAction
  | RefreshRecoveryTokenErrorAction
  | SubmitNewPasswordAction
  | SubmitNewPasswordSuccessAction
  | SubmitNewPasswordErrorAction
  | RevokeToken
  | RevokeTokenSuccess
  | RevokeTokenError
  | ResetLoginData
  | UpdateUserFlags
  | UpdateUserFlagsSuccess
  | UpdateUserFlagsError
  | DocumentTypesRequest
  | DocumentTypesRequestSuccess
  | DocumentTypesRequestError
  | SetTutorialFlagToFalse
  | SubmitLoginWithStoreAction
  | SetFtype;
