import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CalendarConfig } from '@shared/components/ui/calendar/calendar.model';

@Component({
  selector: 'app-side-calendar',
  templateUrl: './side-calendar.component.html',
  styleUrls: ['./side-calendar.component.scss']
})
export class SideCalendarComponent implements OnInit {
  @Input() public calendarConfig: CalendarConfig;
  @Input() public closeCalendar = false;
  @Output() public navAction = new EventEmitter();
  public customClass = 'side-calendar';
  constructor() {}

  ngOnInit() {}
  closeCalendarEvent() {
    this.closeCalendar = true;
  }
  emitNavAction(e) {
    this.navAction.emit(e);
  }
}
