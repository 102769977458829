import { Component, DoCheck, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core/state';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { ClearStatusCoupon, UpdateNppOffer, ValidateCoupon } from '@app/core/state/simulation/simulation.actions';
import { getStatusCoupon } from '@app/core/state/simulation/simulation.selectors';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { CouponStatus } from '@app/proposal-workflow/models/validade-coupon.model';
import { Tag } from '@app/tagging/tagger.directive';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-discount-coupon',
  templateUrl: './discount-coupon.component.html',
  styleUrls: ['./discount-coupon.component.scss']
})
export class DiscountCouponComponent implements OnInit, DoCheck, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() public couponData: any;
  public totalSections = '7';
  public couponForm: FormGroup;
  private couponInput$: Observable<any>;
  private oldCouponValue: string = null;
  public statusCoupon: string = null;
  private firstValidate = false;
  @Input() category: string;
  readonly FillingError: Tag = Tag.FillingError;
  readonly isNPP = JSON.parse(environment.useNPP);
  private proposal: ConfirmationData;

  constructor(private store$: Store<AppState>) {
    this.store$.dispatch(new ClearStatusCoupon());
    this.couponForm = new FormGroup({
      coupon: new FormControl('', [Validators.required, Validators.minLength(3), Validators.maxLength(6)])
    });
    this.couponInput$ = this.couponForm.controls.coupon.valueChanges;
  }

  ngOnInit() {
    this.couponForm.patchValue({
      coupon: this.couponData.coupon
    });

    this.getProposal();
    this.getCouponInputValid();
    this.getStatusCoupon();
  }

  ngDoCheck() {
    if (!this.couponData.coupon || this.firstValidate) return;
    this.couponForm.patchValue({ coupon: this.couponData.coupon });
    this.validCupom();
  }

  private getProposal(): void {
    this.store$
      .select(getConfirmationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: ConfirmationData) => {
        if (state) {
          this.proposal = state;
        }
      });
  }

  private getCouponInputValid(): void {
    this.couponInput$
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(2000),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.validCupom(false);
      });
  }

  private getStatusCoupon(): void {
    this.store$
      .select(getStatusCoupon)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(s => {
        this.statusCoupon = s;
      });
  }

  private validCupom(onlyValidation = true): void {
    if (
      (!this.oldCouponValue && this.couponForm.controls.coupon.value) ||
      this.couponForm.controls.coupon.value !== this.oldCouponValue
    ) {
      this.firstValidate = true;
      this.oldCouponValue = this.couponForm.controls.coupon.value;
      this.handleValidateCupom(onlyValidation);
    }
  }

  private handleValidateCupom(onlyValidation: boolean): void {
    if (this.isNPP) {
      this.store$.dispatch(
        new UpdateNppOffer({
          proposalId: this.couponData.proposalId,
          voucher: this.couponForm.controls.coupon.value
        })
      );
    } else {
      this.store$.dispatch(
        new ValidateCoupon({
          proposalId: this.couponData.proposalId,
          code: this.couponForm.controls.coupon.value,
          appliedCoupon: this.statusCoupon && this.statusCoupon === CouponStatus.VALID,
          offerPayload: this.couponData.stepOffer ? this.getOfferPayload() : null,
          onlyValidation
        })
      );
    }
  }

  private getOfferPayload() {
    return {
      proposalId: parseInt(this.proposal.proposalData.id),
      purchaseValue: this.proposal.purchaseValue,
      entryValue: this.proposal.entryValue,
      financedValue: this.proposal.financedValue
    };
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
