import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpErrorResponse } from '@angular/common/http';
import { Alert, VivereErrorSeverity, EmitterAlertInterface } from '@shared/models/alert.model';

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  constructor(private translateService: TranslateService) {}

  static getAlertError(message: string, type: string) {
    const errorEmptyAlert: EmitterAlertInterface = {
      error: {
        messages: [{ message: '' }, { message }]
      }
    };

    if (type === VivereErrorSeverity.WARN) {
      errorEmptyAlert.error.statusCode = 400;
      errorEmptyAlert.error.severity = type;
      errorEmptyAlert.error.type = false;
    }

    if (type === VivereErrorSeverity.ERROR) {
      errorEmptyAlert.error.statusCode = 500;
      errorEmptyAlert.error.severity = type;
      errorEmptyAlert.error.type = false;
    }

    return new Alert(null, errorEmptyAlert as HttpErrorResponse);
  }

  addAlert(message: string) {
    return new Alert(this.translateService.instant(message), null);
  }

  addAlertMessageError(message: string, error) {
    return new Alert(this.translateService.instant(message), error);
  }

  addAlertError(error) {
    return new Alert(null, error);
  }

  addEmptyAlert(message: string, type: string = 'ERROR') {
    return AlertService.getAlertError(this.translateService.instant(message), type);
  }

  addGraphQLAlert(errors) {
    let message;

    if (!errors.extensions) {
      message = errors;
    }

    if (errors.extensions && errors.extensions.message && !errors.extensions.messages) {
      message = errors.extensions.message;
    }

    if (errors.extensions && errors.extensions.message && errors.extensions.messages) {
      if (errors.extensions.messages[2]) {
        message = errors.extensions.messages[2].message;
      } else {
        message = errors.extensions.messages[0].message + ' ' + errors.extensions.messages[1].message;
      }
    }

    return AlertService.getAlertError(this.translateService.instant(message), 'ERROR');
  }
}
