<ul class="payment-list">
  <li class="payment-item" *ngFor="let payment of listPayments; let i = index">
    <div class="row no-gutters mb-3">
      <div class="col-1">
        <mat-checkbox
          color="primary"
          [(ngModel)]="payment.checked"
          (ngModelChange)="onUpdateCheckSelectAll()"
        ></mat-checkbox>
      </div>
      <div class="col-1" *ngIf="payment.bipartido">
        <img
          *ngIf="payment.bipartido"
          src="./assets/santander/icons/ic_bipartition.svg"
          class="img-bipartido"
          alt="icon bipartido"
          width="20"
          matTooltipClass="icon"
          matTooltip="A proposta é composta por um ou mais parceiros."
          matTooltipHideDelay="100"
        />
      </div>

      <div class="col pl-1" (click)="onButtonListOrKebabAction(payment.button, payment.id)">
        <div class="row no-gutters mb-3">
          <div class="col-auto pr-3">
            <span class="payment-item__label">{{ 'EXPIRES_IN' | translate }}</span>

            <div class="d-flex align-items-center w-100">
              <img
                src="./assets/santander/icons/ic_clock.svg"
                alt="icon urgent"
                [hidden]="!payment.showClock"
                class="icon-period mr-2"
              />

              <img
                [src]="
                  payment.expiringDays > 10
                    ? './assets/santander/icons/ic_clock_yellow.svg'
                    : './assets/santander/icons/ic_clock_red.svg'
                "
                alt="icon urgent"
                class="icon-period mr-2"
                [hidden]="payment.showClock"
              />

              <div class="swap-text d-inline-block payment-item__value">
                <span class="swap-text-item" *ngIf="payment.expiringDays <= 5">
                  {{ payment.expiringDays }} {{ payment.expiringDays === 1 ? 'dia' : 'dias' }}
                </span>
                <span class="swap-text-item">{{ payment.date | date: 'dd/MM/yyyy' }}</span>
                <span class="swap-text-item" *ngIf="payment.expiringDays > 5"> {{ payment.expiringDays }} dias </span>
              </div>
            </div>
          </div>

          <div class="col">
            <span class="payment-item__label">{{ 'Valor' | translate }}</span>

            <div class="swap-text payment-item__value">
              <span class="swap-text-item">{{ payment.value | currency: 'BRL' }}</span>
              <span class="swap-text-item">
                <small>{{ payment.amountParcels }}x de {{ payment.valueParcels | currency: 'BRL' }}</small>
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <span class="payment-item__label">{{ 'Status' | translate }}</span>

            <div>
              <img src="./assets/santander/icons/{{ payment.statusIcon }}" alt="icon" class="icon-status mr-2" />
              <span class="user-name payment-item__value">{{ payment.statusDescription }}</span>
              <img
                *ngIf="!!payment.statusText"
                src="./assets/images/tooltip-payment-col.svg"
                alt="icon"
                class="icon-tooltip ml-1"
                matTooltip="{{ payment.statusText }}"
                matTooltipClass="custom-tooltip-proposal"
                matTooltipHideDelay="100"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-2">
        <button
          class="btn-kebab"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="Menu de opções"
          [disabled]="!payment?.kebab?.length && !payment?.button?.length"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onButtonListOrKebabAction(payment.button, payment.id)">
            <span>{{ payment.button | translate }}</span>
          </button>
          <button mat-menu-item (click)="onKebabAction(kebab.code, payment.id)" *ngFor="let kebab of payment.kebab">
            <span>{{ kebab.description }}</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-1">
        <img
          src="./assets/santander/icons/ic_star.svg"
          alt="icon star"
          class="icon-star"
          ngbTooltip="A proposta foi iniciada por um cliente e é uma venda potencial."
          triggers="click:blur"
          placement="right"
          *ngIf="!payment.sellerAssociate"
        />
      </div>

      <div class="col-10 pl-1">
        <span class="payment-item__label">{{ 'CLIENT' | translate }}</span>

        <div class="swap-text payment-item__value">
          <span class="swap-text-item">{{ payment.user }}</span>
          <span class="swap-text-item">
            {{ payment.documentNumber | mask: '000.000.000-00' }}
          </span>
        </div>
      </div>
    </div>
  </li>
</ul>
