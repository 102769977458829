<div class="container">
  <div class="row">
    <div class="col text-right">
      <button class="close" mat-button matSuffix mat-icon-button aria-label="Close" (click)="closeModal()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
  </div>
  <h2 class="title">{{ 'MODAL-CASHBACK-TITLE' | translate }}</h2>

  <!-- <div *ngIf="data.isSantanderClient"> -->
  <p class="subtitle">{{ 'MODAL-CASHBACK-SUBTITLE' | translate }}</p>

  <div class="card-info">
    <p class="m-0">
      Você receberá {{ data.cashbackValue | localeCurrency }} de volta sempre que fizer o pagamento da parcela.
    </p>
  </div>

  <!-- <div *ngIf="!data.isSantanderClient">
    <p>
      O cashback é um benefício oferecido apenas para clientes correntistas Santander que optarem pelo pagamento por
      débito automático. Isso significa que ao fazer o pagamento da parcela do empréstimo, receberão
      {{ data.cashbackValue | localeCurrency }} de volta na conta.
    </p>

    <p>
      Ainda não é correntista? Abra sua conta no nosso site:
      <a href="https://santander.com.br" rel="noopener noreferrer" target="_blank">santander.com.br</a>.
    </p>
  </div> -->

  <div class="row expand-details" [ngClass]="{ in: in, out: show }" (click)="expandDetailsCashback()">
    <div class="col-10 expand-details-text">
      {{ 'MODAL-CASHBACK-LINK-ACESS' | translate }}
    </div>
    <div class="col-2 expand-details-icon">
      <mat-icon class="icon-arrow" *ngIf="show">keyboard_arrow_up</mat-icon>
      <mat-icon class="icon-arrow" *ngIf="!show">keyboard_arrow_down</mat-icon>
    </div>
  </div>
  <mat-dialog-content class="mat-typography">
    <div class="modal-scroll-area cashback-details" *ngIf="cashbackDetailsVisible" (scroll)="handleScroll($event)">
      <p>{{ 'MODAL-CASHBACK-CONDITIONS-TITLE' | translate }}</p>

      <p>{{ 'MODAL-CASHBACK-CONTENT1-CONDITIONS' | translate }}</p>

      <p>{{ 'MODAL-CASHBACK-CONTENT2-CONDITIONS' | translate }}</p>

      <p>{{ 'MODAL-CASHBACK-CONTENT3-CONDITIONS' | translate }}</p>

      <p>{{ 'MODAL-CASHBACK-CONTENT4-CONDITIONS' | translate }}</p>
    </div>
  </mat-dialog-content>
  <hr class="divider" />
  <mat-dialog-actions>
    <p class="cashback-obs">{{ 'MODAL-CASHBACK-ATENTION' | translate }}</p>
    <div class="pr-0 pl-0 col">
      <button class="btn h-50" [mat-dialog-close]="true">{{ 'MODAL-CASHBACK-BUTTON-UNDERSTOOD' | translate }}</button>
    </div>
  </mat-dialog-actions>
</div>
