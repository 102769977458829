/**
 * Removes file type/base64 prefix from string to return only the file
 * @param data
 */
export function handleFileB64(data: string | File): string | File {
  const B64_PREFIX = ';base64,';

  if (typeof data !== 'string' || !data.includes(B64_PREFIX)) {
    return data;
  }

  return data.split(B64_PREFIX)[1];
}
