import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import { selectPaymentPlan, selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import { selectFlexInstallmentsNumber } from '@app/core/state/simulation/simulation.selectors';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PionTooltipComponent } from '@shared/components/pion-tooltip/pion-tooltip.component';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { InstallmentsModalComponent } from '../installments-modal/installments-modal.component';

@Component({
  selector: 'app-tooltip-payment-plan',
  templateUrl: './tooltip-payment-plan.component.html',
  styleUrls: ['./tooltip-payment-plan.component.scss']
})
export class TooltipPaymentPlanComponent implements OnInit, OnDestroy {
  onDestroy = new Subject();
  planSelected: any;

  dialogConfig = new MatDialogConfig();
  public innerWidth: any;
  public MOBILE_BREAKPOINT = 768;
  @Input() public installmentStepData;
  @Input() public alignTo: string;
  @Input() private toolTextTitle: string;
  public currentCountry = this.internationalizationService.currentCountry;
  installments: any;
  flexInstallmentsNumber: any;

  readonly category = '/portallojista/pagamentos/detalhe';

  public currentUrl = this.router.url;

  constructor(
    public store$: Store<AppState>,
    private internationalizationService: InternationalizationService,
    public matDialog: MatDialog,
    public router: Router,
    public simulationService: SimulationService,
    private genTagger: GenTagger,
    private translateService: TranslateService
  ) {
    this.store$
      .select(selectPaymentPlan)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(planSelected => {
        this.planSelected = planSelected;
      });

    this.store$
      .select(selectFlexInstallmentsNumber)
      .pipe(
        filter(data => !!data),
        take(1)
      )
      .subscribe(flexInstallmentsNumber => {
        this.flexInstallmentsNumber = flexInstallmentsNumber;
      });
  }

  ngOnInit() {
    if (this.currentUrl !== '/proposal/step-simulation' && this.currentUrl !== '/proposal/step-simulation/summary') {
      this.getProposalId();
    }

    this.innerWidth = window.screen.width;
  }

  public getProposalId(): void {
    this.store$
      .select(selectProposalId)
      .pipe(
        takeUntil(this.onDestroy),
        filter(proposalId => !!proposalId)
      )
      .subscribe(proposalId => {
        this.getInstallments(proposalId);
      });
  }

  public getInstallments(proposalId) {
    const listInstallments = [];
    this.simulationService
      .getProposalInstallments(proposalId)
      .pipe(takeUntil(this.onDestroy))
      .subscribe(data => {
        data.paymentPlan.sort((a, b) => {
          if (+a.installmentNumber < +b.installmentNumber) return -1;
          if (+a.installmentNumber > +b.installmentNumber) return 1;
          return 0;
        });
        data.paymentPlan.map(installment => {
          if (installment.installmentNumber !== '0') {
            listInstallments.push({
              number: installment.installmentNumber,
              dueDateFormated: installment.amortizationDate
                ? moment(installment.amortizationDate).format('DD/MM/YYYY')
                : null,
              value: installment.installmentValue
            });
          }
        });
        this.planSelected.installments = listInstallments;
      });
  }

  openInstallmentsModal(code?: any) {
    this.dialogConfig = new MatDialogConfig();

    const isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;

    this.dialogConfig = {
      width: isMobile ? '100%' : '48%',
      maxWidth: '618px',
      id: 'pion-side-modal',
      panelClass: 'installments-modal-container',
      height: '100%',
      autoFocus: false,
      position: {
        right: '0px',
        top: '0px'
      },
      data: {
        installments: this.planSelected.installments,
        country: this.currentCountry,
        isMobile: isMobile, // Usado para abrir o modal de installments
        code: code,
        close: this.closeInstallmentsModal
      }
    };

    this.matDialog.open(InstallmentsModalComponent, this.dialogConfig);
  }

  closeInstallmentsModal() {}

  openToolTip(piontToolTip: PionTooltipComponent): void {
    this.genTagger.setTag({
      event_category: this.category,
      event_label: `ajuda - ${this.toolTextTitle}`,
      event_action: Tag.Click
    });

    this.genTagger.setTag({
      event_category: this.category,
      event_label: piontToolTip.containerText.nativeElement.textContent,
      event_action: `abriu tooltip – ${this.toolTextTitle}`
    });
  }

  closeToolTip() {
    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'x',
      event_action: `fechou tooltip – ${this.translateService.instant('TOOLTIP-PAYMENT-PLAN-TITLE')}`
    });
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
