import { AppState } from '@app/core/state';
import { createSelector } from '@ngrx/store';

export const proposalInformationSelect = (state: AppState) => state.proposalInformation;

export const proposalInformationData = createSelector(
  proposalInformationSelect,
  state => state
);

export const personalDataSection = createSelector(
  proposalInformationSelect,
  state => (state.proposalData ? state.proposalData.mainClient : null)
);

export const addressDataSection = createSelector(
  proposalInformationSelect,
  state => state.proposalData.mainClient.person.addresses
);

export const addressDataSectionId = createSelector(
  proposalInformationSelect,
  state => state.proposalData.mainClient.person.addresses[0].id
);

export const companyRepresentantDataSection = createSelector(
  proposalInformationSelect,
  state => state.proposalData.secondaryClient
);

export const companyRepresentantAddressDataSection = createSelector(
  proposalInformationSelect,
  state => (!!state.proposalData.secondaryClient ? state.proposalData.secondaryClient.person.addresses : [])
);

export const travelerDataSection = createSelector(
  proposalInformationSelect,
  state => state.proposalData.travelers
);

export const observationDataSection = createSelector(
  proposalInformationSelect,
  state => (!!state.proposalData ? state.proposalData.additionalInformation : null)
);

export const getSimulationProposalData = createSelector(
  proposalInformationSelect,
  state => state.proposalData
);

export const getConfirmationData = createSelector(
  proposalInformationSelect,
  state => state.confirmationData
);

export const selectInsurance = createSelector(
  proposalInformationSelect,
  state => state.insurances
);

export const companyLegalNature = createSelector(
  proposalInformationSelect,
  state => state.proposalData.mainClient.person.legal.legalNatureId
);

export const personalEmailSelector = createSelector(
  proposalInformationSelect,
  state =>
    state.proposalData &&
    state.proposalData.mainClient &&
    state.proposalData.mainClient.person &&
    state.proposalData.mainClient.person.mainEmail
      ? state.proposalData.mainClient.person.mainEmail.email
      : null
);

export const selectConfirmedInCashTariff = createSelector(
  proposalInformationSelect,
  state => state.hasConfirmedInCashTariff
);

export const selectConfirmedExemptionRequestTariff = createSelector(
  proposalInformationSelect,
  state => state.hasConfirmeddExemptionRequestTariff
);

export const stageStatusProposalSelector = createSelector(
  proposalInformationSelect,
  state => state.stageProposal
);
