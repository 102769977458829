import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class CountryNavigationService {
  constructor(private router: Router) {}

  /**
   * This method will have a dynamyic navigation based on current country on environment.
   * @param baseUrl example step-simulation
   */
  public dynamicNavigationbyCountry(baseUrl: string) {
    this.router.navigate([`${baseUrl}/${environment.currentCountry}`]);
  }

  /**
   * Will concat url with the current country
   * @param baseUrl example 'step-simulation
   */
  public getURLByCountry(baseUrl: string): string {
    return `${baseUrl}/${environment.currentCountry}`;
  }
}
