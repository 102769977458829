import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { PaymentShowcaseModel } from '../../models/payment.model';
import { PaymentsService } from '../../services/payments.service';

@Component({
  selector: 'app-payment-massive-upload-dialog',
  templateUrl: './payment-massive-upload-dialog.component.html',
  styleUrls: ['./payment-massive-upload-dialog.component.scss']
})
export class PaymentMassiveUploadDialogComponent implements OnInit {
  public files: FileList;
  private maxSize = 10000000; // 10000000 bytes = 10 mb
  public warningAlertClosed = true;
  public fileName = '';
  public unabledUploadButton = false;
  readonly category = '/portallojista/pagamentos';
  readonly Insert = Tag.Insert;

  constructor(
    public dialogRef: MatDialogRef<PaymentMassiveUploadDialogComponent>,
    private paymentsService: PaymentsService,
    private genTagger: GenTagger
  ) {}

  ngOnInit() {
    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.pgView,
      event_label: 'Painel de Pagamento - Modal Upload de pagamento massivo'
    });
  }

  public onChange(event: any): void {
    this.fileName = '';
    this.files = event.srcElement.files;

    if (!!this.files.length) {
      const file: File = this.files[0];
      const fileSize = file.size;

      if (fileSize > this.maxSize) {
        this.warningAlertClosed = false;
        this.unabledUploadButton = false;
      } else {
        this.warningAlertClosed = true;
        this.unabledUploadButton = true;
        this.fileName = file.name;
      }
    }
  }

  public upload() {
    if (!!this.files && !!this.files.length) {
      const file: File = this.files[0];
      this.paymentsService.paymentMassiveUpload(file).subscribe(
        data => {
          if (!data.length) {
            this.dialogRef.close('error-upload');
          }
          const listPayments: PaymentShowcaseModel[] = data.map(item => ({
            id: item.proposalId,
            type: item.product,
            value: item.financingValue,
            amountParcels: item.installmentAmount,
            valueParcels: item.installmentValue,
            user: item.name,
            documentNumber: item.cpf,
            salesman: item.salesman,
            statusDescription: item.statusDescription,
            statusIcon: item.statusIcon,
            checked: true
          }));
          this.dialogRef.close(listPayments);
        },
        error => {
          this.dialogRef.close('error-upload');
        }
      );
      // se der erro no serviço, fechar modal passando o parâmetro abaixo
    }
  }
}
