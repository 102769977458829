import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TranslateService } from '@ngx-translate/core';
import { PaymentRequestStatusEnum } from '../../models/payment.enum';
import { PaymentRequestStatusDialogInfoModel, PaymentRequestStatusDialogModel } from '../../models/payment.model';

@Component({
  selector: 'app-payment-request-status-dialog',
  templateUrl: './payment-request-status-dialog.component.html',
  styleUrls: ['./payment-request-status-dialog.component.scss']
})
export class PaymentRequestStatusDialogComponent implements OnInit {
  readonly category = window.location.href;
  readonly closeAction = Tag.Fechou_Modal;

  public info: PaymentRequestStatusDialogInfoModel = {
    icon: '',
    iconAltText: '',
    title: '',
    text: '',
    buttonText: ''
  };

  private isSuccess = false;

  constructor(
    public dialogRef: MatDialogRef<PaymentRequestStatusDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PaymentRequestStatusDialogModel,
    private translateService: TranslateService,
    private genTagger: GenTagger
  ) {}

  ngOnInit() {
    switch (this.data.status) {
      case PaymentRequestStatusEnum.SCHEDULED_PAYMENT:
        this.info.icon = 'ic_exclamation';
        this.info.iconAltText = this.translateService.instant('WARNING');
        this.info.title = this.translateService.instant('SCHEDULED-PAYMENT');
        this.info.text = this.data.text;
        this.info.buttonText = this.translateService.instant('UNDERSTOOD');
        this.isSuccess = false;
        break;
      case PaymentRequestStatusEnum.PAYMENT_REQUEST_SUCCESS:
        this.info.icon = 'ic_check';
        this.info.iconAltText = this.translateService.instant('SUCCESS');
        this.info.title = this.translateService.instant('SUCCESSFUL-PAYMENT-REQUEST');
        this.info.text = '';
        this.info.buttonText = this.translateService.instant('Close');
        this.isSuccess = true;
        break;
        case PaymentRequestStatusEnum.REQUEST_SCHEDULING_SUCCESSFULLY_MADE:
        this.info.icon = 'ic_check';
        this.info.iconAltText = this.translateService.instant('SUCCESS');
        this.info.title = this.translateService.instant('REQUEST_SCHEDULING_SUCCESSFULLY_MADE');
        this.info.text = '';
        this.info.buttonText = this.translateService.instant('Close');
        this.isSuccess = true;
        break;
      case PaymentRequestStatusEnum.PAYMENT_REQUEST_FAILURE:
        this.info.icon = 'ic_error';
        this.info.iconAltText = this.translateService.instant('FAILURE');
        this.info.title = this.translateService.instant('PAYMENT-REQUEST-FAILED');
        this.info.text = this.data.text;
        this.info.buttonText = this.translateService.instant('Close');
        this.isSuccess = false;
        break;
      default:
        console.log('not found - failed to load');
    }
    this.genTagger.setTag({
      event_category: this.category,
      event_label: this.info.title,
      event_action: Tag.AbriuModal
    });
  }

  public closeDialog() {
    this.dialogRef.close(this.isSuccess);
  }
}
