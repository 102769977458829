import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-data',
  templateUrl: './client-data.component.html',
  styleUrls: ['./client-data.component.scss']
})
export class ClientDataComponent implements OnInit {
  public window = window;
  @Input() data: any;
  constructor() {}

  ngOnInit() {}
}
