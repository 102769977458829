import { PipeTransform, Pipe } from '@angular/core';

@Pipe({ name: 'truncateSize' })
export class TruncateSize implements PipeTransform {
  transform(sizeArtifact: any): any {
    if (!sizeArtifact) return;

    // remove unit
    const str = sizeArtifact.substring(0, sizeArtifact.length - 2);

    // convert to number
    const sizeNumber = parseFloat(str);

    // save unit store
    const unitStore = sizeArtifact.slice(-2);

    return sizeNumber.toFixed(2) + ' ' + unitStore;
  }
}
