import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ModalOptions } from '../interfaces/ModalOptions.interface';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  /** Store modal instances inside an array */
  private stack = [];

  constructor(private ngbModalService: NgbModal) {
    this.stack = [];
  }

  /**
   * Add modal instance to stack making it possible to use it everywhere
   * @param modalInstance
   */
  addModal(modalInstance, options: ModalOptions): void {
    this.stack.push({
      identifier: modalInstance.identifier,
      instance: modalInstance,
      modalRef: null,
      isOpen: false,
      options
    });

    /**
     * Check if modal should start automatically, it needs setTimeout to not break the application when running
     * Error: ExpressionChangedAfterItHasBeenCheckedError
     */
    if (options.autostart) {
      setTimeout(() => this.openModal(modalInstance.identifier));
    }
  }

  /**
   * Get modal instance from stack
   * @param id
   */
  getModal(id: string): any {
    const modal = this.stack.find(m => m.identifier === id);

    if (!modal) {
      throw new Error(`Cannot find modal with identifier ${id}`);
    }

    return modal;
  }

  /**
   * Get modal stack count
   */
  getModalStackCount(): number {
    return this.stack.length;
  }

  /**
   * Open NgbModal based on a modal instance
   * @param id
   */
  openModal(id: string): void {
    const modal = this.getModal(id);

    /**
     * Add NgbModalRef to the stack
     */
    this.stack = this.stack.map(m => {
      if (m.identifier === id) {
        m.modalRef = this.ngbModalService.open(modal.instance.contentModal, modal.options);
        m.isOpen = true;
      }

      return m;
    });

    /** Emit "open" event to parent container */
    modal.instance.openFinished.emit();
  }

  closeModal(id: string): NgbModalRef {
    const modalInstance = this.getModal(id);

    if (!modalInstance.modalRef) {
      return;
    }

    const modalIdx = this.stack.findIndex(m => m.identifier === id);
    this.stack[modalIdx] = { ...this.stack[modalIdx], isOpen: false };

    /** Emit "close" event to parent container */
    modalInstance.instance.closeFinished.emit();

    return modalInstance.modalRef.close();
  }

  /**
   * Remove modal instance from stack
   * @param id
   */
  removeModal(id: string): void {
    const modal = this.stack.find(m => m.identifier === id);

    if (modal) {
      this.stack = this.stack.filter(m => m.identifier !== id);
    }
  }

  /**
   * Clear entire modal stack
   */
  clearStack(): void {
    this.stack.forEach(m => {
      this.closeModal(m.identifier);
      this.removeModal(m.identifier);
    });

    /** Certifies that every modal is closed */
    this.ngbModalService.dismissAll();
  }
}
