<section id="4" class="data-fields simulation-section simulation-section-height">
  <header class="section-header">
    <h2 class="title">Quer adicionar o seguro?</h2>
    <span>{{ insuranceStepData.sectionNumber }}</span>
  </header>
  <div class="row">
    <div class="col">
      <div class="data-description-desktop">
        <p>
          Deixe seu cliente mais tranquilo adicionado o seguro.
        </p>
        <p>
          Ele estará protegido em qualquer imprevisto.
        </p>
      </div>
      <div class="data-description-mobile">
        <p>
          Deixe seu cliente mais tranquilo adicionado o seguro. Ele estará protegido em qualquer imprevisto.
        </p>
      </div>
    </div>
  </div>

  <mat-radio-group class="mobile">
    <mat-card
      *ngFor="let item of insuranceOptions"
      class="card-outline"
      [ngClass]="item.id == selectedIndex ? 'card-active' : ''"
      (click)="setOptionSelectedById(item.id)"
    >
      <div class="card-content d-flex item-insurance">
        <div class="">
          <mat-radio-button
            [value]="item.id"
            (change)="setOptionSelectedById($event.value)"
            [checked]="selectedIndex == item.id"
          ></mat-radio-button>
        </div>
        <div class="d-flex flex-column" appTagger [event_category]="category" event_label="{{ item.name }}">
          <div class="card-icon" *ngIf="item.id != 0.1">
            <img [src]="iconPath" alt="bank-credit" class="card-icon" />
          </div>
          <div class="label" *ngIf="item.id != insuranceOptions.length">
            {{ item.name }}
          </div>
          <div class="" *ngIf="item.id == insuranceOptions.length">
            {{ item.name }}
          </div>
          <div class="total-price" *ngIf="item.installmentValue">
            <span> {{ item.installmentAmount }}x&nbsp;{{ item.installmentValue | localeCurrency }} </span>
          </div>
          <div class="description" *ngIf="item.description">
            {{ item.description }}
          </div>
        </div>
      </div>
    </mat-card>
  </mat-radio-group>

  <mat-radio-group class="desktop">
    <mat-card
      *ngFor="let item of insuranceOptions"
      class="card-outline"
      [ngClass]="item.id == selectedIndex ? 'card-active' : ''"
      (click)="setOptionSelectedById(item.id)"
    >
      <div class="card-content d-flex item-insurance">
        <div class="">
          <mat-radio-button
            [value]="item.id"
            (change)="setOptionSelectedById($event)"
            [checked]="selectedIndex == item.id"
          ></mat-radio-button>
        </div>

        <div class="credit-icon" *ngIf="item.id != 0.1">
          <img [src]="iconPath" alt="bank-credit" class="card-icon" />
        </div>
        <div class="label" *ngIf="item.description">
          {{ item.name }}
        </div>
        <div class="" *ngIf="!item.description">
          {{ item.name }}
        </div>
        <div class="total-price" *ngIf="item.installmentValue">
          <span> {{ item.installmentAmount }}x </span>
          <span> {{ item.installmentValue | localeCurrency }} </span>
        </div>
        <div class="description" *ngIf="item.description">
          {{ item.description }}
        </div>
      </div>
    </mat-card>
  </mat-radio-group>

  <div class="step-info">
    <p>
      Veja mais
      <button
        class="insurance-btn"
        (click)="openDialog()"
        appTagger
        [event_category]="category"
        event_label="vantagens de seguro"
      >
        vantagens do seguro
      </button>
    </p>
  </div>
</section>
