import { ColombiaMasks } from '@shared/interfaces/masks/colombia-masks.interface';

export const colombiaMasks: ColombiaMasks = {
  documentNumber: { mask: '0000000000000', hiddenMask: '00XXXXXXXXX00' },
  cellPhone: { mask: '0000000000', prefix: '(+57) ' },
  date: { mask: '00/00/0000' },
  maskDate: { mask: '00/00/0000', maskBack: 'AA/AA/AAAA' },
  income: {
    options: {
      align: 'left',
      allowNegative: false,
      decimal: ',',
      thousands: '.',
      precision: 2,
      prefix: '$ ',
      suffix: ' Pesos',
      min: 0.01,
      max: 999999999.99,
      inputMode: 'FINANCIAL'
    },
    maxLength: '22'
  },
  preToken: { mask: '000.000.000-00' },
  zipCode: { mask: '000000' }
};
