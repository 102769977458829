import { Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TranslateService } from '@ngx-translate/core';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { Product } from '@shared/models/product.model';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.scss']
})
export class ProductSelectionComponent implements OnInit, OnChanges {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() form: FormGroup;
  @Input() products: Product[];
  @Input() category: Product[];

  showEmptyProductsMsg: boolean;
  readonly Selecionar: Tag = Tag.Selecionar;

  constructor(private elementRef: ElementRef, private gentagger: GenTagger, private translate: TranslateService) {}

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    // Disable select if products array is empty
    if (changes.products) {
      !this.products || !this.products.length ? this.form.get('product').disable() : this.form.get('product').enable();
      this.showEmptyProductsMsg = !this.products || !this.products.length ? true : false;
    }
  }

  public onProductChange(event: MatSelectChange): void {
    const productCode = event.value.code;
    // This needs to be changed so colors are not hardcoded here and instead, they're injected from general sass file
    let productColor;
    if (productCode === ProductCodeEnum.CDC) {
      productColor = '#40ace2';
    } else if (productCode ===  ProductCodeEnum.CSCP || productCode ===  ProductCodeEnum.CSC)  {
      productColor = '#f8ae45';
    } else if (productCode === ProductCodeEnum.CP) {
      productColor = '#c350ff';
    }
    this.gentagger.setTag({
      event_category: '/portallojista/criarproposta',
      event_action: `Tag.Selecionar - ${this.translate.instant('PRODUCT-SELECTION-FIELD-LABEL')}`,
      event_label: `${productCode} - ${this.products.find(a => a.code === productCode).desc}`
    });
    this.elementRef.nativeElement.style.setProperty('--product-color', productColor);
  }

  public renderProductColor(product: Product): string {
    if (!product || !product.code) return;
    const code = product.code.toLowerCase();
    return `product-${code}`;
  }
}
