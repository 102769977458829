import { createSelector } from '@ngrx/store';
import { AppState } from '@app/core/state';

export const challengeSelect = (state: AppState) => state.challenge;

export const getTerms = createSelector(
  challengeSelect,
  state => state.getChallenge
);

export const selectChallengeStatus = createSelector(
  challengeSelect,
  state => state.challengeStatus
);

export const selectChallengeError = createSelector(
  challengeSelect,
  state => state.error && state.error.errorId
);

export const errorChallenge = createSelector(
  challengeSelect,
  state => state
);

export const selectChallengeType = createSelector(
  challengeSelect,
  state => state.getChallenge.type
);

export const selectLatestBiometryStatus = createSelector(
  challengeSelect,
  state => state.latestBiometryStatus
);
