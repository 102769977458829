import { Component } from '@angular/core';
import { routerTransition } from '../router.animations';
import { Router } from '@angular/router';

@Component({
  selector: 'app-disconnected',
  templateUrl: './disconnected.component.html',
  styleUrls: ['./disconnected.component.scss'],
  animations: [routerTransition()]
})
export class DisconnectedComponent {
  message: string;

  constructor(router: Router) {
    const navigationExtras = router.getCurrentNavigation().extras;

    if (navigationExtras.state) {
      this.message = navigationExtras.state.message;
    } else {
      this.message = 'STANDARD-DICONNECTED-MSG';
    }
  }
}
