import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FaqService {
  constructor(private http: HttpClient) {}

  getFaq(): Observable<any> {
    return this.http.get<any>(`${environment.apiRootUrl}/shopkeeper-faq/1.0.0/faqs`);
  }
}
