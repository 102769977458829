export interface GetPanelProposalListDTOResponse {
  content: PanelProposalDTO[];
  page: number;
  perPage: number;
  totalElements: number;
  totalPages: number;
}

export interface PanelProposalDTO {
  amountParcels: number;
  bipartido: boolean;
  button: PanelProposalActionButtonEnum;
  date: string;
  documentNumber: string;
  expiringDays: number;
  id: number;
  kebab: { description: string; code: string }[];
  sellerAssociate: boolean;
  showClock: boolean;
  statusDescription: string;
  statusIcon: string;
  statusId: number;
  statusText: string;
  type: string;
  isOpenFinance?: boolean;
  iconStatusProposal: string;
  iconOpenFinanceOrPreviousProposal: 'openFinance' | 'previousProposal' | '';
  user: string;
  value: number;
  valueParcels: number;
  stage: {
    portalStage: number;
    groupName: string;
  };
}

export enum PanelProposalActionButtonEnum {
  RESUME = 'SHOW-DETAILS',
  CONTINUE = 'CONTINUE-PROPOSAL',
  PENDING = 'SOLVE-PENDING',
  EMITBLETO = 'EMITBLETO'
}
