import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header-desktop',
  templateUrl: './header-desktop.component.html',
  styleUrls: ['./header-desktop.component.scss']
})
export class HeaderDesktopComponent implements OnInit {
  public clientName = 'Jô';

  constructor() {}

  ngOnInit() {}
}
