import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './ui/modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ModalService } from './services/modal.service';

@NgModule({
  declarations: [ModalComponent],
  imports: [CommonModule, NgbModule, TranslateModule],
  exports: [ModalComponent],
  bootstrap: [ModalComponent],
  providers: [ModalService]
})
export class ModalModule {}
