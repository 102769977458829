<div class="payment-error">
  <div class="payment-error-title">
    <h2 class="payment-error-title-primary">{{ 'PENDENCY-BANK-INFORMATION' | translate }}</h2>
    <h2 class="payment-error-title-primary"></h2>
  </div>

  <pion-snackbar icon="error" [closable]="false" type="{{ pendencyBankErrorMsgType }}">
    <p class="msg">{{ pendencyBankErrorMsg | translate: { proposalId: proposalId } }}</p>
  </pion-snackbar>

  <h3 class="payment-error-title-secondary">{{ 'PENDENCY-UPDATE-DATA' | translate }}</h3>

  <div class="payment-error-pendingInput" *ngIf="items">
    <app-bank-data
      [comboParams]="items"
      [formalization]="formalization"
      [bankData]="bankData"
      (pendencyEmitter)="sendBankData($event)"
    ></app-bank-data>
  </div>
</div>
