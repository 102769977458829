import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import * as fromSimulation from '@app/core/state/simulation';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { SimulationOffer } from '../model/offer.model';

@Component({
  selector: 'app-step-offer-npp',
  templateUrl: './step-offer.component.html',
  styleUrls: ['./step-offer.component.scss']
})
export class StepOfferNppComponent implements OnInit {
  public productCode = ProductCodeEnum;
  @Input() simulationOffers: SimulationOffer[];
  @Input() offer: any;
  @Input() proposal: ConfirmationData;
  @Input() title: string;
  @Input() category: string;
  constructor(private readonly store$: Store<AppState>, private updates$: Actions, private router: Router) {}

  selecionar(offer: any) {
    // const payload = {
    //   entryValue: offer.entryValue,
    //   financedValue: offer.financedValue,
    //   hasInsurance: offer.simulationOffer.hasInsurance,
    //   proposalId: parseInt(this.proposal.proposalData.id),
    //   purchaseValue: offer.purchaseValue,
    //   redirectToSimulation: true
    // };

    // this.store$.dispatch(new fromSimulation.actions.UpdateOffer(payload));

    this.store$.dispatch(new fromSimulation.actions.SetSubOfferId(offer.subOfferId));

    this.router.navigate(['/proposal/step-simulation']);
  }

  ngOnInit() {}
}
