import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceInfo } from 'ngx-device-detector';
import { ChecklistItemClientDTO } from '../../../checklist/models/api/query.checklist.model';
import { ClientBiometryStatusEnum } from '../../models/biometry.model';

export enum BiometryStatusEnum {
  BIOMETRY_FAILED = 1,
  BIOMETRY_PENDING = 2,
  SIGNATURE_BIOMETRY_SUCCESS = 3,
  FACIAL_BIOMETRY_SUCCESS = 4,
  FINISHED = 5,
  BIOMETRY_DENIED = 6
}
@Component({
  selector: 'app-biometry-status',
  templateUrl: './biometry-status.component.html',
  styleUrls: ['./biometry-status.component.scss']
})
export class BiometryStatusComponent implements OnInit, OnChanges {
  @Input() currentPlatform: { deviceInfo: DeviceInfo; isMobile: boolean; isDesktop: boolean };
  @Input() status: BiometryStatusEnum;
  @Input() hasChallengeQuestions = false;
  @Input() isCrossedFlux = false;
  @Input() clientList: ChecklistItemClientDTO[];
  @Output() nextAction: EventEmitter<void> = new EventEmitter();
  @Output() returnToQrCodeEmitter: EventEmitter<void> = new EventEmitter();
  @Input() category: string;
  @Input() typePerson: string;
  @Input() proposal: any;
  public clientBiometryStatusEnum = ClientBiometryStatusEnum;
  public productType: string;

  public title = '';
  public subtitle = '';
  public color = '';
  public icon = '';
  public showBackButton = true;

  constructor(public translate: TranslateService, private genTagger: GenTagger, private taggerService: TaggerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.status && changes.status) {
      this.checkStatus();
    }

    if (this.clientList && changes.clientList) {
      this.showBackButton = this.clientList.every(
        client =>
          client.biometryStatus === ClientBiometryStatusEnum.DONE ||
          client.biometryStatus === ClientBiometryStatusEnum.ANALYSIS
      )
        ? false
        : true;
    }
  }

  ngOnInit() {
    window.scrollTo(0, 0);
    this.taggerService.setProposalType(this.proposal?.product?.code);
  }

  public handleNextAction(): void {
    this.nextAction.emit();
  }

  public checkStatus(): void {
    switch (this.status) {
      case BiometryStatusEnum.BIOMETRY_FAILED:
        this.title = `${this.translate.instant('FORMALIZATION-BIOMETRY-FAILED')}`;
        this.subtitle = `${this.translate.instant('FORMALIZATION-BIOMETRY-FAILED-TEXT')}`;
        this.color = 'failed';
        this.icon = 'ic_exclamation';
        break;

      case BiometryStatusEnum.BIOMETRY_DENIED:
        this.title = `${this.translate.instant('FORMALIZATION-BIOMETRY-DENIED')}`;
        this.subtitle = `${this.translate.instant('FORMALIZATION-BIOMETRY-DENIED-DESCRIPTION')}`;
        this.color = 'failed';
        this.icon = 'ic_exclamation_white';
        break;

      case BiometryStatusEnum.BIOMETRY_PENDING:
        this.title = `${this.translate.instant('FORMALIZATION-BIOMETRY-PENDING')}`;
        this.subtitle = `${this.translate.instant('FORMALIZATION-BIOMETRY-PENDING-TEXT')}`;
        this.color = 'pending';
        this.icon = '';
        break;

      case BiometryStatusEnum.SIGNATURE_BIOMETRY_SUCCESS:
        this.title = `${this.translate.instant('FORMALIZATION-BIOMETRY-SIGNATURE-DONE')}`;
        this.subtitle = `${this.translate.instant(
          this.isCrossedFlux
            ? 'FORMALIZATION-BIOMETRY-SIGNATURE-DONE-SUBTITLE-CROSSFLUX'
            : 'FORMALIZATION-BIOMETRY-SIGNATURE-DONE-SUBTITLE'
        )}`;
        this.color = 'success';
        this.icon = 'Check';
        break;

      case BiometryStatusEnum.FACIAL_BIOMETRY_SUCCESS:
        this.title = `${this.translate.instant('FORMALIZATION-BIOMETRY-FACIAL-DONE')}`;
        this.subtitle = `${this.translate.instant(
          this.isCrossedFlux
            ? 'FORMALIZATION-BIOMETRY-SIGNATURE-DONE-SUBTITLE-CROSSFLUX'
            : 'FORMALIZATION-BIOMETRY-SIGNATURE-DONE-SUBTITLE'
        )}`;
        this.color = 'success';
        this.icon = 'Check';
        break;

      case BiometryStatusEnum.FINISHED:
        this.title = `${this.translate.instant('FORMALIZATION-BIOMETRY-DONE')}`;
        this.genTagger.setTag({
          event_category: this.category,
          event_label: `${this.translate.instant('FORMALIZATION-BIOMETRY-DONE')}`,
          event_action: Tag.pgView
        });
        this.subtitle = `${this.translate.instant(
          this.isCrossedFlux
            ? 'FORMALIZATION-BIOMETRY-SIGNATURE-DONE-SUBTITLE-CROSSFLUX'
            : 'FORMALIZATION-BIOMETRY-SIGNATURE-DONE-SUBTITLE'
        )}`;
        this.color = 'success';
        this.icon = 'Check';
        break;

      default:
        break;
    }


    const productType = this.taggerService.getProposalType();

    if (productType) {
      this.category = `/portallojista/criarproposta/${productType}/formalizacao/biometria-resultado`;
      if (this.isCrossedFlux) {
        this.category = `/portallojista/criarproposta/${productType}/formalizacao/cliente/biometria-resultado`;
      }
      this.genTagger.setTag({
        page_location: this.category,
        event_label: this.subtitle,
        page_section: this.title,
        event_action: Tag.pgView
      });
    }
  }

  public returnToQrCode() {
    this.returnToQrCodeEmitter.emit();
  }
}
