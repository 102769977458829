import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { GenTagger } from '@app/tagging/gen-tagger';
import * as moment from 'moment';
import { NotificationModel } from '../../model/notification.model';

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss']
})
export class NotificationCardComponent implements OnChanges {
  @Input() data: NotificationModel;
  @Input() category: string;

  constructor(private genTagger: GenTagger) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.data && changes.data.currentValue && changes.data.currentValue.creation) {
      this.data.creation = this.formatDateUTCLocal(this.data.creation);
    }
  }

  setTag(): void {
    this.genTagger.setTag({
      event_action: `clicou - ${this.data.title}`,
      event_label: this.data.attachmentDescription,
      event_category: this.category
    });
  }

  private formatDateUTCLocal(date): string {
    return moment
      .utc(date)
      .local()
      .format();
  }
}
