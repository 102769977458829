import { SubmitPersonType } from '@app/identification/models/api/post.identification.model';
import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import { IntegrationGenericField } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { ChecklistItemTypeDTO } from '../../checklist/models/api/query.checklist.model';
import { ChecklistModel } from '../../checklist/models/checklist.model';

export interface BiometryState {
  biometrics: BiometryItem[];
  results: any;
}

// export interface BiometryItem {
//   id: number;
//   type: BiometryItemTypeEnum;
//   token: string;
//   files: { order: BiometryItemOrderEnum; data: string }[];
//   document: {
//     id: number;
//     integrationType?: {
//       id: number;
//       description: string;
//       integrationCode: string;
//     };
//   };
// }

export interface BiometryItem {
  token: string;
  checklist: BiometryChecklistItem;
}

export interface BiometryChecklistItem {
  id: number;
  type: ChecklistItemTypeDTO;
  files: BiometryFile[];
  document: BiometryDocument;
}

export interface BiometryFile {
  order: BiometryItemOrderEnum;
  data: string;
}

export interface BiometryDocument {
  id: number;
  integrationType?: {
    id: number;
    description: string;
    integrationCode: string;
  };
}

export interface CrossedFluxInfo {
  proposal: {
    id: string;
    name: string;
    email: string;
    document: string;
    clientId: string;
    proposalDate: string;
    channelSupplierId: string;
    channelSupplierName: string;
    companyName: string;
    valueFinancing: string;
    installmentAmount: string;
    installmentValue: string;
    cetValue: string;
    annualCetValue: string;
    integrationGenericFields: IntegrationGenericField[];
    planType: PaymentPlanType;
    totalFinancedValue: string;
    clientType: SubmitPersonType;
    insurance: string;
    product: { code: ProductCodeEnum };
  };
  contract: {
    file: string;
  };
  checklists: ChecklistModel[];
}

export enum BiometryItemTypeEnum {
  DOCUMENT = 'documentary',
  FACIAL = 'facial'
}

export enum BiometryItemOrderEnum {
  DOC_FRONT = 1,
  DOC_BACK = 2,
  FACIAL = 3
}

export enum CrossedFluxPollingStatusEnum {
  NOT_INITIATED = 'NOT_INITIATED',
  INITIATED = 'INITIATED'
}

export enum CrossedFluxLogoutReasonsEnum {
  TIMEOUT = 1,
  INVALID_QR = 2,
  COMPLETE = 3
}

export enum ClientBiometryStatusEnum {
  PENDING = 1,
  IN_PROGRESS = 2,
  DONE = 3,
  REMAKE = 4,
  ANALYSIS = 5
}
