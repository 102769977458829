import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { selectProduct } from '@app/core/state/identification/identification.selectors';
import { selectShowTutorialSmula } from '@app/core/state/login/login.selectors';
import * as fromProposalInformation from '@app/core/state/proposal-information';
import { GetInsurance, GetInsuranceSuccess } from '@app/core/state/proposal-information/proposal-information.actions';
import {
  getConfirmationData,
  selectInsurance
} from '@app/core/state/proposal-information/proposal-information.selectors';
import { SetPaymentPlanSelectedAction } from '@app/core/state/proposal/proposal.actions';
import { selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import {
  GetNewCalculation,
  GetSimulationParameters,
  PostSimulationRequest,
  SaveCalculationPayload
} from '@app/core/state/simulation/simulation.actions';
import { getSimulationParameters, getStatusCoupon } from '@app/core/state/simulation/simulation.selectors';
import { InstallmentStepComponent } from '@app/proposal-workflow/components/installment-step/installment-step.component';
import { OpenFinanceModalComponent } from '@app/proposal-workflow/components/open-finance-modal/open-finance-modal.component';
import { PaymentStepComponent } from '@app/proposal-workflow/components/payment-step/payment-step.component';
import { InputChange } from '@app/proposal-workflow/models/input-change.model';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { PartnerBanksModel } from '@app/proposal-workflow/models/partner-banks.model';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { ScrollToSimulationStepService } from '@app/proposal-workflow/service/scroll-to-simulation-step.service';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { Store, select } from '@ngrx/store';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { TutorialService } from '@shared/services/tutorial/tutorial.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';

@Component({
  selector: 'bra-step-simulation',
  templateUrl: './bra-step-simulation.component.html',
  styleUrls: ['./bra-step-simulation.component.scss']
})
export class BraStepSimulationComponent implements OnInit, OnDestroy {
  @Input() public identification: any;
  @ViewChild(PaymentStepComponent) paymentStepComponent: PaymentStepComponent;

  public formSimulacao: FormGroup;
  public entireForm: FormArray;
  public isValidFormSubmitted: boolean;
  public registrationFeesSubscriber: any;
  public totalSections = null;
  public showCloseCalendar: boolean;
  public proposalData = null;
  public hasInsurance = true;
  public hasTariff: 'EXEMPTION_REQUEST' | 'IN_INSTALLMENTS' | 'IN_CASH';

  // Validation
  financedValue: number;
  numberOfInstallments: number;
  paymentPlan: string;
  public selectedPaymentMethod: string;
  accountData = null;
  bankAccountServiceVallidated = false;
  newPaymentMethodSelected = '';
  public othersBanks: PartnerBanksModel[];
  couponVallidated = false;

  get installment() {
    return this.formSimulacao.get('installment').value;
  }

  get insurance() {
    return this.formSimulacao.get('insurance').value;
  }

  simulationId: string;

  preventScroll = false;

  /**
   * Calculation payload
   */
  calculationPayload: any = {
    proposalId: '2075338',
    preAnalysis: {
      income: 10000
    },
    payment: {
      entryValue: 0,
      financedValue: 48760,
      insuranceId: 0,
      registrationFeeType: 'IN_INSTALLMENTS',
      installmentAmount: 12,
      paymentPlan: 'FLT',
      firstInstallmentDueDate: '2021-03-11',
      firstInstallmentPMethodsDate: '',
      codeMonthsRange: 0,
      billShippingOptions: 'PHYSICAL',
      hasInsurance: false
    },
    pricing: {
      couponCode: '',
      paymentMethod: 'B'
    }
  };

  simulationPayload: any = {
    proposalId: ''
  };
  public blnValidators = false;

  public calculationError = false;

  public defaultDaysToInitialPayment: number;

  public simulationParameters: any;

  public installmentStepData;

  public registrationFeeData;

  public insuranceStepData;

  public paymentData;

  public dueDateData;

  public simulationParams: any;

  public disableConfirmButton = false;

  public isEnabledToInformIncome = false;

  public dueDaysIntervalMax: number;

  public dueDaysIntervalMin: number;

  protected ngUnsubscribe: Subject<any> = new Subject();

  public confirmationData: ConfirmationData;

  public conditionPlanFlex: any = [];

  @ViewChild('creditValueDiv') creditValueDiv: ElementRef;

  @ViewChild('installmentDiv') installmentDiv: ElementRef;

  @ViewChild('registrationFeeDiv', { static: true }) registrationFeeDiv: ElementRef;

  @ViewChild('insuranceDiv', { static: true }) insuranceDiv: ElementRef;

  @ViewChild('dueDateDiv', { static: true }) dueDateDiv: ElementRef;

  @ViewChild('paymentDiv', { static: true }) paymentDiv: ElementRef;

  @ViewChild('paymentStepCmpt') paymentStepCmpt: PaymentStepComponent;

  @ViewChild('cuponDiv') cuponDiv: ElementRef;

  @ViewChild('installmentCmpt') installmentCmpt: InstallmentStepComponent;

  public category: string;
  public selectedBln: any;
  readonly Insert: Tag = Tag.Insert;
  readonly Selecionar: Tag = Tag.Selecionar;

  scrollInstallment() {
    this.installmentDiv.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  scrollCreditValue() {
    this.creditValueDiv.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  scrollRegistrationFee() {
    if (!this.registrationFeeData.hasTariff) {
      this.scrollInsurance();
    } else {
      this.registrationFeeDiv.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  scrollInsurance() {
    if (!this.insuranceStepData.showInsuranceStep) {
      this.scrollDueDate();
    } else {
      this.insuranceDiv.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  scrollDueDate() {
    this.dueDateDiv.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  scrollPayment() {
    this.paymentDiv.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  constructor(
    public matDialog: MatDialog,
    public renderer: Renderer2,
    private store$: Store<AppState>,
    private cdref: ChangeDetectorRef,
    private tutorialService: TutorialService,
    public scrollToSimulationStepService: ScrollToSimulationStepService,
    private store: Store<any>,
    private simulationService: SimulationService,
    private genTagger: GenTagger,
    private taggerService: TaggerService
  ) {
    this.clearProposalData();
    this.store$
      .select(selectProposalId)
      .pipe(
        withLatestFrom(this.store$.select(selectProduct)),
        filter(data => !!data),
        take(1)
      )
      .subscribe(([proposalId, product]) => {
        this.calculationPayload = {
          ...this.calculationPayload,
          proposalId
        };
        this.simulationPayload = {
          ...this.simulationPayload,
          proposalId
        };
        this.store$.dispatch(
          new fromProposalInformation.actions.GetConfirmationData(this.calculationPayload.proposalId)
        );

        this.taggerService.setPageLocation(`/portallojista/criarproposta/${product?.code?.toLowerCase()}/ofertas`)
        this.store$.dispatch(new GetSimulationParameters(this.simulationPayload));
      });
    this.getInsurancesSubscription();

    this.store$.pipe(select(getStatusCoupon)).subscribe(statusCoupon => {
      if (!statusCoupon || statusCoupon === 'VALID') {
        this.couponVallidated = true;
      } else {
        this.couponVallidated = false;
      }
    });

    this.store$
      .pipe(
        select(getConfirmationData),
        filter(data => !!data),
        take(1)
      )
      .subscribe(state => {
        this.confirmationData = state;

        this.calculationPayload.payment.firstInstallmentDueDate = this.confirmationData.firstInstallmentDate;

        this.calculationPayload.payment.entryValue = this.confirmationData.entryValue;

        this.calculationPayload.payment.financedValue = Number(this.confirmationData.financedValue);

        this.calculationPayload.payment.installmentAmount = Number(this.confirmationData.installmentTotalQuantity);

        this.calculationPayload.pricing.paymentMethod = this.confirmationData.liquidationTypeCode;

        this.calculationPayload.pricing.couponCode = this.confirmationData.cupom;

        this.calculationPayload.payment.paymentPlan = this.confirmationData.planTypeSchemaData.planTypeCode;

        this.calculationPayload.payment.codeMonthsRange = this.confirmationData.planTypeSchemaData.frequenceId;

        this.calculationPayload.payment.registrationFeeType = this.confirmationData.registrationFeeType;

        this.calculationPayload.payment.firstInstallmentPMethodsDate = this.confirmationData.planTypeSchemaData.monthOccurrency;
        this.calculationPayload.payment.hasInsurance = this.confirmationData.hasInsurance;

        if (this.confirmationData.conditionPlanFlex && this.confirmationData.conditionPlanFlex.length > 0) {
          this.calculationPayload.flexInstallments = this.confirmationData.conditionPlanFlex;
          this.calculationPayload.flexInstallmentsInput = [];

          this.confirmationData.conditionPlanFlex.forEach(v => {
            const data = {
              installmentNumber: v.sequenceNumber,
              installmentAmountFlex: v.paymentValue
            };

            this.calculationPayload.flexInstallmentsInput.push(data);
          });
        }

        this.conditionPlanFlex = this.confirmationData.conditionPlanFlex;

        this.store$.dispatch(new GetNewCalculation(this.calculationPayload));
        this.store$.dispatch(new SaveCalculationPayload(this.calculationPayload));
        this.getInsurances();
      });

    this.store$
      .select(getSimulationParameters)
      .pipe(
        filter(data => !!data),
        take(1)
      )
      .subscribe(params => {
        this.isEnabledToInformIncome = params.isEnabledToInformIncome;
        this.dueDaysIntervalMax = params.dueDaysIntervalMax;
        this.dueDaysIntervalMin = params.dueDaysIntervalMin;

        this.simulationParams = params;
      });
    this.entireForm = new FormArray([]);
  }

  getInsurancesSubscription() {
    this.store$
      .select(selectInsurance)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(insurances => {
        this.insuranceStepData = {
          sectionNumber: '3/5',
          hasInsurance: this.calculationPayload.payment.hasInsurance,
          showInsuranceStep:
            this.confirmationData && this.confirmationData.showInsurance ? this.confirmationData.showInsurance : false,
          insurance: insurances
        };
        this.controlSteps();
      });
  }

  addForm(fg: FormGroup) {
    this.entireForm.push(fg);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private clearProposalData(): void {
    this.store$.dispatch(new fromProposalInformation.actions.ClearProposalInformationAction());
  }

  ngOnInit() {
    this.scrollToSimulationStepService.scrollToSimulationStep.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      switch (res) {
        case 'SCROLL_TO_INSTALLMENT':
          this.scrollInstallment();
          break;
        case 'SCROLL_TO_DUEDATE':
          this.scrollDueDate();
          break;
        case 'SCROLL_TO_PAYMENT':
          this.scrollPayment();
          break;
        default:
          break;
      }
    });
    // Tutorial
    this.store
      .select(selectShowTutorialSmula)
      .pipe(take(1))
      .subscribe((showTutorial: boolean) => {
        if (showTutorial) {
          this.tutorialService.showTutorial('simulation');
        }
      });

    this.simulationService.changeValuesSidebar.subscribe(data => {
      const event = {
        value: data,
        input: InputTypes.SIDEBAR_VALUES
      };

      this.simulationChanged(event);
    });

    this.formSimulacao = new FormGroup({
      installment: new FormControl('', [Validators.required]),
      paymentDate: new FormControl('', [Validators.required]),
      insurance: new FormControl('', [Validators.required])
    });

    this.store$
      .pipe(
        select('simulation'),
        takeUntil(this.ngUnsubscribe),
        filter(data => !!data.simulationParameters && !!data.simulationData)
      )
      .subscribe(res => {
        if (!this.confirmationData) {
          return;
        }
        this.calculationError = res.calculationError;
        this.calculationPayload = res.calculationPayload;

        this.calculationPayload.paymentMethods = res.simulationData.paymentMethods;

        this.defaultDaysToInitialPayment = res.simulationParameters.defaultDaysToInitialPayment;

        this.installmentStepData = {
          sectionNumber: '1/5',
          installments: res.simulationData.installments,
          installmentPMethodsDate: res.simulationData.installmentPMethodsDate,
          installmentValue: res.simulationData.installmentValue, // quantidade de parcelas
          installmentAmount: res.simulationData.installmentAmount, // quantidade de parcelas
          paymentMethods: res.simulationParameters.paymentMethods, // planos de pagamento -> vem de parametros
          selectedPaymentMethod: res.simulationData.paymentMethods, // planos de pagamento selecionado -> vem de parametros
          codeMonthsRange: res.calculationPayload.payment.codeMonthsRange,
          firstInstallmentPMethodsDate: res.calculationPayload.payment.firstInstallmentPMethodsDate,
          tariff: res.simulationData.tariff, //  modal taxas do emprestimo
          registrationFeeType: res.simulationData.registrationFeeType,
          calcContractFee: res.simulationData.calcContractFee, // taxa de juros mensal -> modal taxas do emprestimo
          annualCalcContractFee: res.simulationData.annualCalcContractFee, // taxa de juros anual -> modal taxas do emprestimo
          annualInternalReturnRate: res.simulationData.annualInternalReturnRate, // custo efetivo total (CET) - modal taxas do emprestimo
          monthlyInternalReturnRate: res.simulationData.monthlyInternalReturnRate, // custo efetivo total (CET) - modal taxas do emprestimo
          tax: res.simulationData.tax, // IOF -> modal taxas do emprestimo
          maxInstallmentAmount: res.simulationData.maxInstallmentAmount, // maximo de parcelas
          minInstallmentAmount: res.simulationData.minInstallmentAmount, // minimo de parcelas
          minAllowedInstallmentValue: res.simulationData.minAllowedInstallmentValue,
          maxAllowedInstallmentValue: res.simulationData.maxAllowedInstallmentValue,
          maxAllowedFlexValue: res.simulationData.maxAllowedFlexValue,
          maxAllowedFlexInstallmentsNumber: res.simulationData.maxAllowedFlexInstallmentsNumber,
          isAllowedFlexInstallments: res.simulationData.isAllowedFlexInstallments,
          conditionPlanFlex: this.conditionPlanFlex ? this.conditionPlanFlex : [],
          insurance: res.simulationData.insurance,
          hasInsurance: res.calculationPayload.payment.hasInsurance
        };
        //  this.installmentStepData.selectedPaymentMethod.push(...this.installmentStepData.selectedPaymentMethod, {
        //   flexPlanName:'Parcela fixa',
        //   flexPlanType: 'FIXED',
        //  },
        //  {
        //   flexPlanName:'Sazonal',
        //   flexPlanType: 'SEASONAL',
        //  }
        //  )

        this.othersBanks = res.simulationParameters.partnerBanks;

        this.setSelectedPlan(res);

        this.registrationFeeData = {
          // tarifa de cadastro vlTarifaParcelada -> valor parcelado da tarifa / value -> valor total da tarifa
          sectionNumber: '2/5',
          hasTariff: res.simulationData.tariff.length > 0,
          tariff: res.simulationData.tariff[0],
          installmentAmount: res.simulationData.installmentAmount,
          registrationFeeType: res.simulationData.registrationFeeType,
          registrationFeeTypeSelected: this.confirmationData
            ? this.confirmationData.registrationFeeType
            : res.simulationData.registrationFeeType
        };

        this.dueDateData = {
          sectionNumber: '4/5',
          dueDate: moment(res.simulationData.firstInstallmentDueDate).format('DD/MM/YYYY'),
          minAllowedFirstInstallmentDueDate: moment(res.simulationData.minAllowedFirstInstallmentDueDate).format(
            'DD/MM/YYYY'
          ),
          maxAllowedFirstInstallmentDueDate: moment(res.simulationData.maxAllowedFirstInstallmentDueDate).format(
            'DD/MM/YYYY'
          ),
          isValidFirstInstallmentDueDate: res.simulationData.isValidFirstInstallmentDueDate
        };

        this.paymentData = {
          sectionNumber: '5/5',
          saleOffTypeCode: res.simulationData.saleOffTypeCode, // tipo de pagamento
          accountantIndicator:
            this.confirmationData && this.confirmationData.accountantIndicator === 'S' ? true : false,
          isEligible: false,
          elegibleType: res.simulationData.cashback.elegibleType,
          cashbackAmount: res.simulationData.cashback.cashbackAmount,
          liquidationTypeCode: res.simulationData.liquidationTypeCode
        };

        // Insurance é montado pelo state reducers this.calculationPayload.payment.hasInsurance
        if (this.confirmationData.showInsurance) {
          res.simulationData.insurance.forEach(i => {
            i.installmentAmount = res.simulationData.installmentAmount;
          });
          this.store$.dispatch(new GetInsuranceSuccess(res.simulationData));
          this.getInsurancesSubscription();
        }

        this.controlSteps();
        this.disableConfirmButton = !res.simulationData.isValidSubmitButton;
      });

    this.store$
      .pipe(
        select(getConfirmationData),
        filter(data => !!data),
        take(1)
      )
      .subscribe(state => {
        this.proposalData = state.proposalData;
        if (state.proposalData.product === ProductCodeEnum.CDC) {
          this.category = '/portallojista/criarproposta/cdc/simulacao';
          this.genTagger.setTag({ event_category: this.category, event_label: 'Simulação', event_action: Tag.pgView });
        } else if (state.proposalData.product === ProductCodeEnum.CSC) {
          this.category = '/portallojista/criarproposta/csc/simulacao';
          this.genTagger.setTag({ event_category: this.category, event_label: 'Simulação', event_action: Tag.pgView });
        } else if (state.proposalData.product === ProductCodeEnum.CSCP) {
          this.category = '/portallojista/criarproposta/cscp/simulacao';
          this.genTagger.setTag({ event_category: this.category, event_label: 'Simulação', event_action: Tag.pgView });
        }
      });
    this.openFinanceModalPreAnalysis();
  }

  private controlSteps() {
    let qtSections = 3;
    if (this.registrationFeeData && this.registrationFeeData.hasTariff) {
      qtSections++;
    }

    if (this.insuranceStepData && this.insuranceStepData.showInsuranceStep) {
      qtSections++;
    }

    let count = 1;
    this.installmentStepData.sectionNumber = `${count++}/${qtSections}`;
    if (this.registrationFeeData && this.registrationFeeData.hasTariff) {
      this.registrationFeeData.sectionNumber = `${count++}/${qtSections}`;
    }
    if (this.insuranceStepData && this.insuranceStepData.showInsuranceStep) {
      this.insuranceStepData.sectionNumber = `${count++}/${qtSections}`;
    }
    this.dueDateData.sectionNumber = `${count++}/${qtSections}`;
    this.paymentData.sectionNumber = `${count++}/${qtSections}`;
  }

  public setSelectedPlan(res) {
    if (this.calculationPayload.flexInstallmentsInput) {
      this.conditionPlanFlex = this.calculationPayload.flexInstallmentsInput;
      this.conditionPlanFlex.forEach(x => {
        x.sequenceNumber = x.installmentNumber;
        x.paymentValue = x.installmentAmountFlex;
      });
    } else {
      this.conditionPlanFlex = [];
    }

    const plan = res.simulationParameters.paymentMethods.filter(a => a.code === res.simulationData.paymentPlan)[0];
    this.store$.dispatch(
      new SetPaymentPlanSelectedAction({
        code: plan.code,
        description: plan.description,
        firstInstallmentPMethodsDate: moment(res.simulationData.paymentMethods[0].firstInstallmentPMethodsDate).format(
          'MM/YYYY'
        ),
        installmentDefaultValue: res.simulationData.paymentMethods[0].installmentDefaultValue,
        installmentPaymentMethodsValue: res.simulationData.paymentMethods[0].installmentPaymentMethodsValue,
        firstInstallmentValue: res.simulationData.paymentMethods[0].firstInstallmentValue,
        lastInstallmentValue: res.simulationData.paymentMethods[0].lastInstallmentValue,
        installmentDefaultAmount: res.simulationData.paymentMethods[0].installmentDefaultAmount,
        installmentPaymentMethodsAmount: res.simulationData.paymentMethods[0].installmentPaymentMethodsAmount,
        monthsRange: res.simulationData.paymentMethods[0].monthsRange[0].description,
        installments: res.simulationData.installments,
        hasFlexInstallments: res.simulationData.paymentMethods[0].hasFlexInstallments
      })
    );
  }

  public statusViewCalendar(event: boolean): void {
    this.showCloseCalendar = event;
  }

  accountChangedHandler(event: InputChange): void {
    this.accountData = {
      bankCode: '033',
      branchCode: event.value.branchCode,
      accountType: event.value.accountType,
      accountNumber: event.value.accountNumber,
      checksum: event.value.accountDigit
    };

    this.bankAccountServiceVallidated = event.value.bankAccountServiceVallidated;
    this.newPaymentMethodSelected = event.value.newPaymentMethodSelected;
    this.enableSubmitBtn();
  }
  selectedBlnValidatorsEmitter(res: boolean) {
    if (res) {
      this.blnValidators = true;
    } else {
      this.blnValidators = false;
    }
  }
  simulationChanged(event: InputChange): void {
    if (event.input === InputTypes.INPUT_CREDIT) {
      this.calculationPayload.payment.financedValue = event.value.payment.financedValue;
    }

    if (event.input === InputTypes.INSTALLMENT) {
      this.calculationPayload.payment.installmentAmount = event.value.payment.installmentAmount;
    }

    if (event.input === InputTypes.REGISTRATION_FEE) {
      this.hasTariff = event.value.payment.registrationFee;
      this.calculationPayload.payment.registrationFeeType = event.value.payment.registrationFee;
      // IN_CASH: A VISTA;  IN_INSTALLMENTS: incluir na parcela; EXEMPTION_REQUEST: solicitar isenção
    }

    if (event.input === InputTypes.INSURANCE) {
      this.hasInsurance = event.value.payment.hasInsurance;
      this.calculationPayload.payment.hasInsurance = event.value.payment.hasInsurance;
      this.calculationPayload.payment.insuranceId = event.value.payment.hasInsurance
        ? event.value.payment.insuranceId
        : null;
    }

    if (event.input === InputTypes.DUE_DATE) {
      this.calculationPayload.payment.firstInstallmentDueDate = event.value.payment.firstInstallmentDueDate;
    }
    if (event.input === InputTypes.INSTALLMENT_INFO_MONTHS) {
      this.calculationPayload.payment.codeMonthsRange = event.value.payment.codeMonthsRange;
    }

    if (event.input === InputTypes.INSTALLMENT_INFO_DATE_BLN) {
      this.calculationPayload.payment.firstInstallmentPMethodsDate = event.value.payment.firstInstallmentPMethodsDate;
    }

    if (event.input === InputTypes.INSTALLMENT_INFO_DATE_LACK) {
      this.calculationPayload.payment.firstInstallmentPMethodsDate = event.value.payment.firstInstallmentPMethodsDate;
    }
    if (event.input === InputTypes.PAYMENT_PLAN) {
      this.calculationPayload.payment.paymentPlan = event.value.payment.paymentPlan;
      this.calculationPayload.payment.firstInstallmentPMethodsDate = '';
      this.calculationPayload.payment.codeMonthsRange = event.month ? event.month : 0;
    }

    if (event.input === InputTypes.SIDEBAR_VALUES) {
      this.calculationPayload.payment.entryValue = event.value.entryValue;
      this.calculationPayload.payment.financedValue = event.value.financedValue;
    }

    this.store$.dispatch(new GetNewCalculation(this.calculationPayload));
    this.getInsurances();
  }

  getInsurances() {
    if (!this.calculationPayload.payment.hasInsurance) {
      this.store$.dispatch(
        new GetInsurance({
          ...this.calculationPayload,
          payment: {
            ...this.calculationPayload.payment,
            hasInsurance: true
          }
        })
      );
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    this.cdref.detectChanges();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  enableSubmitBtn(): boolean {
    this.disableConfirmButton = true;
    if (((this.paymentStepComponent?.paymentMethodSelected === 'B' &&
      this.confirmationData?.liquidationTypeCode === 'B')
      || ((this.paymentStepComponent?.paymentMethodSelected === 'D') &&
        (this.paymentStepComponent?.accountFields?.valid &&
          this.bankAccountServiceVallidated && this.confirmationData?.liquidationTypeCode === 'D')
      )) && (this.couponVallidated || !this.blnValidators)) {
      this.disableConfirmButton = false;
    }
    return this.disableConfirmButton;
  }

  removeFocusSubmitBtn() {
    const element = document.activeElement;
    if (element instanceof HTMLElement) {
      element.blur();
    }
  }

  goSumary() {
    const payload: any = {
      SimulationInput: {
        proposalId: this.calculationPayload.proposalId,
        preAnalysis: {
          income: this.calculationPayload.preAnalysis.income
        },
        payment: {
          entryValue: this.calculationPayload.payment.entryValue,
          financedValue: this.calculationPayload.payment.financedValue,
          hasInsurance: this.calculationPayload.payment.hasInsurance,
          insuranceId: this.calculationPayload.payment.insuranceId,
          registrationFeeType: this.calculationPayload.payment.registrationFeeType,
          installmentAmount: this.calculationPayload.payment.installmentAmount,
          paymentPlan: this.calculationPayload.payment.paymentPlan,
          firstInstallmentDueDate: this.calculationPayload.payment.firstInstallmentDueDate,
          firstInstallmentPMethodsDate: this.calculationPayload.payment.firstInstallmentPMethodsDate,
          codeMonthsRange: this.calculationPayload.payment.codeMonthsRange,
          billShippingOptions: this.calculationPayload.payment.billShippingOptions
        },
        pricing: {
          couponCode: this.calculationPayload.pricing.couponCode,
          paymentMethod: this.calculationPayload.pricing.paymentMethod
        }
      },
      accountData: {
        bankCode: '',
        branchCode: '',
        accountType: '',
        accountNumber: '',
        checksum: ''
      }
    };

    if (this.calculationPayload.pricing.paymentMethod !== 'D') {
      delete payload.accountData;
    } else {
      payload.accountData = { ...this.accountData };
    }

    if (this.calculationPayload.flexInstallmentsInput) {
      payload.SimulationInput.flexInstallmentsInput = this.calculationPayload.flexInstallmentsInput;
    }

    // OpenFinance
    this.isShowOpenFinanceModal(payload);

    // this.store$.dispatch(new PostSimulationRequest(payload));
  }

  public isShowOpenFinanceModal(payload) {
    this.simulationService.getOpenFinance(this.calculationPayload.proposalId).subscribe(data => {
      if (!!data) {
        this.loadOpenFinanceModal(payload);
      } else {
        this.store$.dispatch(new PostSimulationRequest(payload));
      }
    });
  }

  private loadOpenFinanceModal(payload): void {
    this.matDialog
      .open(OpenFinanceModalComponent, {
        width: '520px',
        autoFocus: false,
        data: {
          // MOCK
          type: 'proposal-acepted'
        }
      })
      .afterClosed()
      .subscribe(() => {
        this.store$.dispatch(new PostSimulationRequest(payload));
      });
  }
  public openFinanceModalPreAnalysis() {
    this.simulationService.getOpenFinancePreAnalysis(this.calculationPayload.proposalId).subscribe(data => {
      if (data.isBestOffer === true) {
        this.openFinancePreAnalysis('preAnaliseShow');
      }
    });
  }

  private openFinancePreAnalysis(type): void {
    this.matDialog.open(OpenFinanceModalComponent, {
      width: '520px',
      autoFocus: false,
      data: {
        type: type
      }
    });
  }
}
