import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import * as fromProposal from '@app/core/state/proposal';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { getProposalById, selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import { ProposalStageEnum } from '@app/proposal-workflow/models/proposal-stage.enum';
import { ConfirmationContractResponse } from '@app/proposal-workflow/models/step-register-requests';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { PaymentPlan } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ModalOptions, PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { ProposalStageService } from '@shared/services/proposal/proposal-stage.service';
import { Subject, combineLatest } from 'rxjs';
import { filter, first, map, takeUntil } from 'rxjs/operators';
import { CertifiedAgents, ConfirmationData } from './../../models/proposal-data.model';

@Component({
  selector: 'step-confirmation',
  templateUrl: './step-confirmation.component.html',
  styleUrls: ['./step-confirmation.component.scss']
})
export class StepConfirmationComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  public innerWidth: number;

  public proposal: ConfirmationData;
  public proposalId: string;

  public proposalStageEnum = ProposalStageEnum;

  public productCode = ProductCodeEnum;

  public form: FormGroup;
  public certifiedAgents: CertifiedAgents[];

  public showContractDownload = false;

  public event_category = '';
  public event_label = '';

  public Select: Tag = Tag.Selecionar;

  public docData: string;
  public mimeType: string;
  public docName: string;
  public showDoc = false;
  public pageVariable = 1;
  private pdfViewer;
  private totalPdfPages = 0;
  public disableNextStep = true;

  public analysisShow = false;
  public listPaymentPlan: PaymentPlan[];

  constructor(
    public matDialog: MatDialog,
    private readonly store$: Store<AppState>,
    public router: Router,
    private simulationService: SimulationService,
    private proposalStageService: ProposalStageService,
    private genTagger: GenTagger,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.innerWidth = window.screen.width;
    this.getCurrentProposalId();
    this.getProposalData();
    this.buildForm();
    this.setDownloadLinkSubscription();
    this.getAnalysisCd();
  }

  private getAnalysisCd() {
    this.simulationService
      .getProposalAnalysisCd(this.proposalId)
      .toPromise()
      .then(v => ((this.analysisShow = v), this.modalAdditionalAnalysisConfirmation(this.analysisShow)));
  }

  public modalAdditionalAnalysisConfirmation(showModal: boolean) {
    if (showModal) {
      this.matDialog
        .open(PionModalComponent, {
          data: {
            disableClose: true,
            title: 'Atenção',
            description:
              'Lembramos que essa proposta passará por uma análise adicional de crédito após a etapa de cadastro',
            confirmText: 'OK',
            type: 'alert'
          },
          maxWidth: '500px'
        })
        .afterClosed();
    }
  }

  public buildForm(): void {
    this.form = new FormGroup({
      certifiedAgentCode: new FormControl(null, Validators.required)
    });
  }

  public getCurrentProposalId() {
    this.store$
      .select(selectProposalId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(proposalId => !!proposalId)
      )
      .subscribe((proposalId: string) => {
        this.proposalId = proposalId;
        this.store$.dispatch(new fromProposal.actions.GetProposalAction(proposalId));
      });
  }

  public getProposalData() {
    const proposalByID$ = this.store$.select(fromProposal.selectors.selectProposal).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(proposalData => !!proposalData.data),
      map(proposalData => proposalData.data)
    );
    const confirmationData$ = this.store$.select(getConfirmationData).pipe(takeUntil(this.ngUnsubscribe));
    const getProposalById$ = this.store$.select(getProposalById).pipe(takeUntil(this.ngUnsubscribe));

    combineLatest([proposalByID$, confirmationData$, getProposalById$]).subscribe(
      ([proposalByID, confirmationData, proposalById]) => {
        if (confirmationData) {
          this.proposal = confirmationData;
          const product = this.proposal.proposalData ? this.proposal.proposalData.product : null;

          if (this.proposal.proposalData.product === ProductCodeEnum.CDC) {
            this.event_category = '/portallojista/criarproposta/cdc/confirmacao';
          } else if (this.proposal.proposalData.product === ProductCodeEnum.CSC) {
            this.event_category = '/portallojista/criarproposta/csc/confirmacao';
          } else if (this.proposal.proposalData.product === ProductCodeEnum.CSCP) {
            this.event_category = '/portallojista/criarproposta/cscp/confirmacao';
          }
          this.genTagger.setTag({
            event_category: this.event_category,
            event_action: Tag.pgView,
            event_label: 'Confirmacao'
          });
          if (product !== ProductCodeEnum.CSC && product !== ProductCodeEnum.CSCP) {
            this.getCertifiedAgents();
          }

          this.store$.dispatch(
            new fromProposal.actions.GetChecklistAction({
              proposalId: this.proposal.proposalData.id,
              isPendency: false
            })
          );
          // this.loadContract();
        }
        if (!!proposalById && !!proposalById.data) {
          this.listPaymentPlan = proposalById.data.paymentPlan;
        }
      }
    );
  }

  public handleBackButton() {
    this.genTagger.setTag({
      event_category: this.event_category,
      event_action: Tag.Click,
      event_element: 'button',
      event_label: 'voltar'
    });
    const payload = {
      channel: 1,
      proposalId: this.proposalId,
      currentStage: this.proposalStageEnum.CONFIRMATION
    };
    this.simulationService.proposalReverseProceed(payload).subscribe(() => {
      this.router.navigate([`/proposal/step-register`]);
    });
  }

  public proceedToFormalization() {
    const payload = {
      channel: 2,
      proposalId: this.proposalId,
      currentStage: this.proposalStageEnum.CONFIRMATION
    };
    this.simulationService
      .proposalProceed(payload)
      .pipe(first())
      .subscribe(() => {
        this.getProposalStage();
      });
  }

  public getProposalStage(): void {
    this.proposalStageService
      .getProposalStage(this.proposalId)
      .pipe(first())
      .subscribe(res => {
        if (!!res.idStage && res.idStage !== this.proposalStageEnum.CONFIRMATION) {
          this.router.navigate(['/proposal/step-formalization']);
        }
      });
  }

  public loadContract(): void {
    this.simulationService
      .getProposalContract(this.proposalId, this.proposal.contract.UUID)
      .subscribe((res: ConfirmationContractResponse) => {
        this.docData = `data:${res.mimeType};base64,${res.data}`;
        this.mimeType = res.mimeType;
        this.docName = res.name;
        this.showDoc = true;
      });
  }

  public getProposalContract() {
    let desc = 'A CET orçamento não contêm os dados pessoais do cliente final devido à LGPD,';
    desc = desc.concat(' contendo apenas as taxas e condições simuladas na proposta.');

    const dialog = this.matDialog.open(PionModalComponent, {
      width: '560px',
      autoFocus: false,
      id: 'step-confirmation-cet',
      data: <ModalOptions>{
        title: 'Atenção',
        description: desc,
        confirmText: 'Ok',
        type: 'alert'
      }
    });

    dialog.afterClosed().subscribe(confirm => {
      if (confirm) {
        this.downloadContract();
      }
    });
  }

  private downloadContract() {
    this.simulationService
      .getProposalContract(this.proposalId, this.proposal.contract.UUID)
      .subscribe((res: ConfirmationContractResponse) => {
        const linkSource = `data:${res.mimeType};base64,${res.data}`;
        const downloadLink = document.createElement('a');
        const fileName = `${res.name}.pdf`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      });
  }

  public getCertifiedAgents(): void {
    this.simulationService
      .getCertifiedAgents(this.proposal.proposalData.storeCode)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(res => !!res)
      )
      .subscribe((res: CertifiedAgents[]) => {
        this.certifiedAgents = res;
        this.certifiedAgents.forEach(a => {
          a.agentName = a.agentName.trimRight();
        });
        if (!!this.certifiedAgents.length && this.certifiedAgents.length === 1) {
          this.form.patchValue({
            certifiedAgentCode: this.certifiedAgents[0].agentCertifiedNumber
          });
          this.genTagger.setTag({
            event_category: this.event_category,
            event_action: Tag.Click,
            event_label: this.translate.instant('STEP-CONFIRMATION-WITHOUT-CERTIFIED-AGENT')
          });
        }
      });
  }

  private setDownloadLinkSubscription(): void {
    this.store$
      .select(fromProposal.selectors.selectShowDownloadLink)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(data => !!data)
      )
      .subscribe(showDownloadLink => {
        this.showContractDownload = showDownloadLink;
      });
  }

  public showTitleConditionsLink() {
    if (this.proposal && this.proposal.proposalData) {
      if (this.proposal.proposalData.product === this.productCode.CDC) {
        return this.translate.instant('STEP-CONFIRMATION-FORMALIZATION-CONDITIONS-LINK-CDC');
      }
      if (this.proposal.proposalData.product === this.productCode.CSC) {
        return this.translate.instant('STEP-CONFIRMATION-FORMALIZATION-CONDITIONS-LINK-CSC') + '.';
      }
      if (this.proposal.proposalData.product === this.productCode.CSCP) {
        return this.translate.instant('STEP-CONFIRMATION-FORMALIZATION-CONDITIONS-LINK-CSCP') + '.';
      }
    }
  }

  public setPDFContractStyles(): void {
    const iframe = document.querySelector('pdf-viewer');
    this.pdfViewer = <HTMLElement>iframe.firstChild;
    this.pdfViewer.style.overflow = 'hidden';
  }

  afterLoadComplete(pdfData: any) {
    this.totalPdfPages = pdfData.numPages;
    this.setPDFContractStyles();
  }

  showScroll(): void {
    this.pdfViewer.style.overflowY = 'scroll';
    this.pdfViewer.addEventListener(
      'scroll',
      e => {
        if (this.pageVariable === this.totalPdfPages) {
          this.disableNextStep = false;
        }
      },
      true
    );
  }

  pageChanging(e) {
    this.pageVariable = e;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
