<div class="extra-info">
  <form [formGroup]="formGroup">
    <mat-form-field color="black" appearance="legacy">
      <mat-label>{{ 'NUMBER-OF-FIXED-INSTALLMENT' | translate }}</mat-label>
      <mat-select formControlName="numberFixedInstallments" (selectionChange)="changeHandler($event)">
        <mat-option *ngFor="let installment of months" [value]="installment" appTagger [event_category]="category"
          event_label="{{ installment }}" [event_action]="Selecionar">
          {{ installment }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <div>
      <mat-form-field [class.mat-form-field-invalid]="formGroup.get('valueOfFixedInstallments').errors" color="black"
        appearance="legacy">
        <mat-label>{{ 'INSTALLMENTS-VALUE-FIXED' | translate }}</mat-label>
        <input class="input-text" currencyMask formControlName="valueOfFixedInstallments" (blur)="changeHandler()"
          matInput placeholder="Valor das parcelas fixas"
          [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
          [ngModel]="customInstallmentValue" />
        <mat-hint style="color:#8a8a8a">Valor mínimo: R$
          {{ minCustomInstallmentValue | localeCurrency }}
          Valor máximo: R$ {{ maxCustomInstallmentValue | localeCurrency }}
        </mat-hint>
      </mat-form-field>
      <p class="label mt-5">{{ 'FIXED-INSTALLMENT' | translate }}</p>
      <p class="value font-weight-bold">
        {{ fixedInstallmentQuantity }} de
        {{ customInstallmentValue | localeCurrency }}
      </p>
      <p class="label">{{ 'INSTALLMENTS-STANDARD' | translate }}</p>
      <p class="value font-weight-bold">
        {{ installmentDefaultAmount }} de
        {{ defaultInstallmentValue | localeCurrency }}
      </p>
    </div>
  </form>
</div>