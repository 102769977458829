<div class="container" style="padding: 0 !important">
  <div class="barGraph">
    <div class="graphArea">
      <div *ngFor="let p of bars" class="collumnGraph">
        <div class="barra"
          [ngStyle]="{'height': p.percent +'%', 'background-color': p.colorBar, 'border-color': p.borderBar}">
        </div>
        <div [style.height.%]="100" class="textMont" [ngStyle]="{'color': p.borderBar, 'height': p.height}">
          {{p.month}}</div>
      </div>
    </div>
  </div>
</div>