<div id="modal-content-wrapper">
  <header id="modal-header" class="text-center mt-4">
    <img src="./assets/images/ic_exclamation.svg" width="44px" height="44px" alt="Alerta" class="mb-4" />
    <h1 id="modal-title mt-2 mb-2" mat-dialog-title
      *ngIf="!data.planCustom && data.typeOfRequest !== 'REQUEST-SCHEDULING'">{{ 'PAYMENT-REQUEST' | translate }}
    </h1>

    <h1 id="modal-title mt-2 mb-2" mat-dialog-title *ngIf="data.typeOfRequest === 'REQUEST-SCHEDULING'">
      {{ 'REQUEST-SCHEDULING' | translate }}
    </h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <p class="mb-4" *ngIf="!data.planCustom && data.typeOfRequest !== 'REQUEST-SCHEDULING'">
      {{ 'REQUESTING-PAYMENT-FOR-PROPOSAL' | translate }} <strong class="text-nowrap">{{ data.id }}</strong>
      {{ 'TO' | translate }} <strong class="text-nowrap">{{ data.userName }}</strong>
      {{ 'AT-THE-COST-OF' | translate }} <strong class="text-nowrap">{{ data.value | currency: 'BRL' }}</strong><strong
        class="text-nowrap"> ({{ data.amountParcels }}x {{ data.valueParcels | currency: 'BRL' }})</strong>.
    </p>
    <p class="mb-4" *ngIf="data.planCustom && data.typeOfRequest !== 'REQUEST-SCHEDULING'">
      {{ 'REQUESTING-PAYMENT-FOR-PROPOSAL' | translate }} <strong class="text-nowrap">{{ data.id }}</strong>
      {{ 'TO' | translate }} <strong class="text-nowrap">{{ data.userName }}</strong>
      {{ 'AT-THE-COST-OF' | translate }} <strong class="text-nowrap">{{ data.value | currency: 'BRL' }}</strong><strong
        class="text-nowrap">
        ({{ data.amountParcels }}x {{ 'TO' | translate }} {{ 'CUSTOM-PLAN' | translate }} )</strong>.
    </p>
    <p class="mb-4" *ngIf="data.typeOfRequest === 'REQUEST-SCHEDULING'">
      {{ 'REQUESTING-SCHEDULING-FOR-PROPOSAL' | translate }} <strong class="text-nowrap">{{ data.id }}</strong>
      {{ 'TO' | translate }} <strong class="text-nowrap">{{ data.userName }}</strong>
      {{ 'AT-THE-COST-OF' | translate }} <strong class="text-nowrap">{{ data.value | currency: 'BRL' }}</strong><strong
        class="text-nowrap"> ({{ data.amountParcels }}x {{ data.valueParcels | currency: 'BRL' }})</strong>.
    </p>
  </section>
  <footer id="modal-footer" mat-dialog-actions class="mb-3">
    <button mat-stroked-button id="modal-close-button" color="primary" (click)="closeDialog()">
      {{ 'NO_BACK' | translate }}
    </button>
    <button mat-flat-button id="modal-action-button" color="primary" (click)="requestPayment()">
      {{ 'CONTINUE-BUTTON' | translate }}
    </button>
  </footer>
</div>
