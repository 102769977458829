import { ConfirmationData, ProposalSignUpData } from '@app/proposal-workflow/models/proposal-data.model';
import { GetProposalStageDTO } from '@app/proposal-workflow/models/proposal-stage.model';
import { ProposalInformationAction, ProposalInformationTypes } from './proposal-information.actions';

export interface ProposalInformation {
  proposalData: ProposalSignUpData;
  confirmationData: ConfirmationData;
  insurances: any;
  hasConfirmedInCashTariff: boolean;
  hasConfirmeddExemptionRequestTariff: boolean;
  error: any;
  stageProposal: { status: false } | { status: true; data: GetProposalStageDTO };
}

export const proposalInformationInitial: ProposalInformation = {
  proposalData: {
    id: null,
    productCode: null,
    travelData: null,
    liquidationTypeCode: null,
    creationDate: null,
    expirationDate: null,
    mainClient: null,
    secondaryClient: null,
    travelers: null,
    additionalInformation: null,
    flags: null,
    isTravelSubSegment: null,
    storeCode: null,
    isDataFromService: false
  },
  confirmationData: null,
  insurances: [],
  hasConfirmedInCashTariff: false,
  hasConfirmeddExemptionRequestTariff: false,
  error: null,
  stageProposal: null
};

export function proposalInformationReducer(state = proposalInformationInitial, action: ProposalInformationAction): any {
  switch (action.type) {
    case ProposalInformationTypes.SET_PROPOSAL_DATA: {
      return {
        ...state,
        proposalData: action.payload
      };
    }
    case ProposalInformationTypes.GET_INSURANCE: {
      return {
        ...state,
        calculationError: false
      };
    }
    case ProposalInformationTypes.GET_INSURANCE_SUCCESS: {
      return {
        ...state,
        insurances: action.payload.insurance
      };
    }

    case ProposalInformationTypes.SET_ADDRESS_DATA_SECTION_SUCCESS: {
      return {
        ...state,
        proposalData: {
          ...state.proposalData,
          mainClient: {
            ...state.proposalData.mainClient,
            person: {
              ...state.proposalData.mainClient.person,
              addresses: action.payload
            }
          }
        }
      };
    }

    case ProposalInformationTypes.SET_PERSONAL_DATA_SECTION_SUCCESS:
    case ProposalInformationTypes.SET_COMPANY_DATA_SECTION_SUCESS: {
      return {
        ...state,
        proposalData: {
          ...state.proposalData,
          mainClient: {
            ...state.proposalData.mainClient,
            person: {
              ...state.proposalData.mainClient.person,
              ...action.payload.person,
              mainEmail: !!action.payload.person.emails ? action.payload.person.emails[0] : null,
              mainPhone: !!action.payload.person.phones ? action.payload.person.phones[0] : null
            }
          }
        }
      };
    }

    case ProposalInformationTypes.SET_COMPANY_REPRESENTANT_DATA_SECTION_SUCCESS: {
      return {
        ...state,
        proposalData: {
          ...state.proposalData,
          secondaryClient: {
            ...state.proposalData.secondaryClient,
            ...action.payload,
            person: {
              ...(state.proposalData.secondaryClient ? state.proposalData.secondaryClient.person : null),
              ...action.payload.person
            }
          }
        }
      };
    }

    case ProposalInformationTypes.SET_COMPANY_REPRESENTANT_ADDRESS_DATA_SECTION:
    case ProposalInformationTypes.SET_COMPANY_REPRESENTANT_ADDRESS_DATA_SECTION_SUCCESS: {
      return {
        ...state,
        proposalData: {
          ...state.proposalData,
          secondaryClient: {
            ...state.proposalData.secondaryClient,
            person: {
              ...state.proposalData.secondaryClient.person,
              addresses: action.payload
            }
          }
        }
      };
    }

    case ProposalInformationTypes.SET_TRAVELER_DATA_SECTION_SUCCESS: {
      return {
        ...state,
        proposalData: {
          ...state.proposalData,
          travelers: action.payload
        }
      };
    }

    case ProposalInformationTypes.SET_OBSERVATION_DATA_SECTION: {
      return {
        ...state,
        proposalData: {
          ...state.proposalData,
          additionalInformation: action.payload
        }
      };
    }

    case ProposalInformationTypes.GET_SIMULATION_PROPOSAL_DATA_SUCCESS: {
      return {
        ...state,
        proposalData: action.payload
      };
    }

    case ProposalInformationTypes.GET_SIMULATION_PROPOSAL_DATA_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }

    case ProposalInformationTypes.GET_CONFIRMATION_DATA_SUCCESS: {
      return {
        ...state,
        confirmationData: action.payload
      };
    }

    case ProposalInformationTypes.GET_CONFIRMATION_DATA_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }

    case ProposalInformationTypes.SET_CONFIRMED_IN_CASH_TARIFF: {
      return {
        ...state,
        hasConfirmedInCashTariff: action.payload
      };
    }
    case ProposalInformationTypes.SET_CONFIRMED_EXEMPTION_REQUEST_TRIFF: {
      return {
        ...state,
        hasConfirmeddExemptionRequestTariff: action.payload
      };
    }

    case ProposalInformationTypes.SET_STAGE_PROPOSAL: {
      return {
        ...proposalInformationInitial,
        stageProposal: action.payload
      };
    }

    case ProposalInformationTypes.CLEAR_PROPOSAL_INFORMATION: {
      return {
        ...proposalInformationInitial
      };
    }

    default:
      return state;
  }
}
