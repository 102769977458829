import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

const RX_DATE = /^\d{4}-\d{2}-\d{2}$/;
const RX_DATE_LONG = /^\d{4}-\d{2}-\d{2}\s\d{2}:\d{2}:\d{2}$/;

@Pipe({
  name: 'dateFormatPipe',
  pure: false
})
export class DateFormatPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any, flagDash: boolean): any {
    if (RX_DATE.test(value)) {
      const dateFormat = this.translate.currentLang === 'pt' || 'es-pe' ? 'dd/MM/yyyy' : 'yyyy-MM-dd';
      const datePipe: DatePipe = new DatePipe('en');
      return datePipe.transform(value, dateFormat);
    } else if (RX_DATE_LONG.test(value)) {
      let dateFormat = this.translate.currentLang === 'pt' || 'es-pe' ? 'dd/MM/yyyy HH:mm:ss' : 'yyyy-MM-dd HH:mm:ss';
      if (flagDash) {
        dateFormat = this.translate.currentLang === 'pt' || 'es-pe' ? 'dd/MM/yyyy - HH:mm:ss' : 'yyyy-MM-dd - HH:mm:ss';
      }
      const datePipe: DatePipe = new DatePipe('en');
      return datePipe.transform(value, dateFormat);
    } else {
      return value ? value : '';
    }
  }
}
