<div class="container terms-body">
  <div class="row sign-up-container container-fluid">
    <div class="col-sm-12 pr-0 text-center">
      <div class="container body-page">
        <div class="pion-dialog-container">
          <div class="modal-header">
            <div class="modal-icon text-center">
              <img src="./assets/images/satander-icon.png" alt="Logo Santander" class="modal-logo" />
            </div>

            <h3 class="modal-title">{{ 'TERMS-AND-CONDITIONS' | translate }}</h3>
          </div>
          <div class="modal-scroll-area" (scroll)="handleScroll($event)">
            <div class="text-term text-center" [innerHTML]="data"></div>
          </div>
          <div class="row">
            <div class="col">
              <button
                mat-raised-button
                class="w-100 buttonAgree"
                (click)="handleAcceptButtonClick()"
                [disabled]="disableBtn"
                appTagger
                [event_category]="category"
                event_label="{{ 'AGREE' | translate }}"
              >
                {{ 'AGREE' | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
