import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PanelProposalDTO } from '../../models/api/get.proposal-list.model';

@Component({
  selector: 'app-proposal-table-mobile',
  templateUrl: './proposal-table-mobile.component.html',
  styleUrls: ['./proposal-table-mobile.component.scss']
})
export class ProposalTableMobileComponent implements OnInit {
  @Input() proposals: PanelProposalDTO[];

  @Output() kebabAction = new EventEmitter();
  @Output() handleProposalActionBtn = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public onKebabAction(code, id): void {
    this.kebabAction.emit({ code, id });
  }

  public onHandleProposalActionBtn(proposal: PanelProposalDTO): void {
    this.handleProposalActionBtn.emit(proposal);
  }
}
