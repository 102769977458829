import { Action } from '@ngrx/store';
import { SignUpUserData } from '@app/signup/models/sign-up.model';

export enum SignUpActionTypes {
  GET_SIGN_UP_RENDER_DATA = '[SignUp] [App] Get SignUp Render Data',
  GET_SIGN_UP_RENDER_DATA_SUCCESS = '[SignUp] [App] Get SignUp Data Success',
  GET_SIGN_UP_RENDER_DATA_ERROR = '[SignUp] [App] Get SignUp Data Error',

  GET_AFIP_DATA = '[SignUp] [User] Get Afip Data',
  GET_AFIP_DATA_SUCCESS = '[SignUp] [App] Get Afip Data Success',
  GET_AFIP_DATA_ERROR = '[SignUp] [App] Get Afip Data Error',

  SAVE_USER_DATA = '[SignUp] [User] Save User Data',
  CLEAR_USER_DATA = '[SignUp] [App] Clear User Data',

  SUBMIT_CONFIRMATION_TOKEN = '[SignUp] [User] Submit Confirmation Token',
  SUBMIT_CONFIRMATION_TOKEN_SUCCESS = '[SignUp] [App] Submit Confirmation Token Success',
  SUBMIT_CONFIRMATION_TOKEN_ERROR = '[SignUp] [App] Submit Confirmation Token Error',

  REFRESH_CONFIRMATION_TOKEN = '[SignUp] [User] Refresh Confirmation Token',
  REFRESH_CONFIRMATION_TOKEN_SUCCESS = '[SignUp] [App] Refresh Confirmation Token Success',
  REFRESH_CONFIRMATION_TOKEN_ERROR = '[SignUp] [App] Refresh Confirmation Token Error',

  SUBMIT_NEW_PASSWORD = '[SignUp] [User] Submit New Password',
  SUBMIT_NEW_PASSWORD_SUCCESS = '[SignUp] [App] Submit New Password Success',
  SUBMIT_NEW_PASSWORD_ERROR = '[SignUp] [App] Submit New Password Error',

  RESET_CHALLENGE_RESULT = '[SignUp] [App] Reset Challenge Result',
  RESET_SIGN_UP_DATA = '[SignUp] [App] Reset SignUp Data'
}

export class GetSignUpRenderDataAction implements Action {
  readonly type = SignUpActionTypes.GET_SIGN_UP_RENDER_DATA;
  constructor() {}
}

export class GetSignUpRenderDataSuccessAction implements Action {
  readonly type = SignUpActionTypes.GET_SIGN_UP_RENDER_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class GetSignUpRenderDataErrorAction implements Action {
  readonly type = SignUpActionTypes.GET_SIGN_UP_RENDER_DATA_ERROR;
  constructor(public payload: any) {}
}

export class GetAfipDataAction implements Action {
  readonly type = SignUpActionTypes.GET_AFIP_DATA;
  constructor(public payload: any) {}
}

export class GetAfipDataSuccessAction implements Action {
  readonly type = SignUpActionTypes.GET_AFIP_DATA_SUCCESS;
  constructor(public payload: any) {}
}

export class GetAfipDataErrorAction implements Action {
  readonly type = SignUpActionTypes.GET_AFIP_DATA_ERROR;
  constructor(public payload: any) {}
}

export class SaveUserDataAction implements Action {
  readonly type = SignUpActionTypes.SAVE_USER_DATA;
  constructor(public payload: SignUpUserData) {}
}

export class ClearUserDataAction implements Action {
  readonly type = SignUpActionTypes.CLEAR_USER_DATA;
  constructor() {}
}

export class SubmitConfirmationTokenAction implements Action {
  readonly type = SignUpActionTypes.SUBMIT_CONFIRMATION_TOKEN;
  constructor(public payload: any) {}
}

export class SubmitConfirmationTokenSuccessAction implements Action {
  readonly type = SignUpActionTypes.SUBMIT_CONFIRMATION_TOKEN_SUCCESS;
  constructor() {}
}

export class SubmitConfirmationTokenErrorAction implements Action {
  readonly type = SignUpActionTypes.SUBMIT_CONFIRMATION_TOKEN_ERROR;
  constructor() {}
}

export class RefreshConfirmationTokenAction implements Action {
  readonly type = SignUpActionTypes.REFRESH_CONFIRMATION_TOKEN;
  constructor() {}
}

export class RefreshConfirmationTokenSuccessAction implements Action {
  readonly type = SignUpActionTypes.REFRESH_CONFIRMATION_TOKEN_SUCCESS;
  constructor(payload: any) {}
}

export class RefreshConfirmationTokenErrorAction implements Action {
  readonly type = SignUpActionTypes.REFRESH_CONFIRMATION_TOKEN_ERROR;
  constructor(public payload: any) {}
}

export class SubmitNewPasswordAction implements Action {
  readonly type = SignUpActionTypes.SUBMIT_NEW_PASSWORD;
  constructor(public payload: any) {}
}

export class SubmitNewPasswordSuccessAction implements Action {
  readonly type = SignUpActionTypes.SUBMIT_NEW_PASSWORD_SUCCESS;
  constructor() {}
}

export class SubmitNewPasswordErrorAction implements Action {
  readonly type = SignUpActionTypes.SUBMIT_NEW_PASSWORD_ERROR;
  constructor(public payload: any) {}
}

export class ResetChallengeResultAction implements Action {
  readonly type = SignUpActionTypes.RESET_CHALLENGE_RESULT;
  constructor() {}
}

export class ResetSignUpDataAction implements Action {
  readonly type = SignUpActionTypes.RESET_SIGN_UP_DATA;
  constructor() {}
}

export type SignUpActions =
  | GetSignUpRenderDataAction
  | GetSignUpRenderDataSuccessAction
  | GetSignUpRenderDataErrorAction
  | GetAfipDataAction
  | GetAfipDataSuccessAction
  | GetAfipDataErrorAction
  | SaveUserDataAction
  | ClearUserDataAction
  | SubmitConfirmationTokenAction
  | SubmitConfirmationTokenSuccessAction
  | SubmitConfirmationTokenErrorAction
  | RefreshConfirmationTokenAction
  | RefreshConfirmationTokenSuccessAction
  | RefreshConfirmationTokenErrorAction
  | SubmitNewPasswordAction
  | SubmitNewPasswordSuccessAction
  | SubmitNewPasswordErrorAction
  | ResetChallengeResultAction
  | ResetSignUpDataAction;
