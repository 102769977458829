<div class="modal-header">
  <h3 class="modal-title">{{ 'ORDER-BY' | translate }}</h3>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss()"
    appTagger
    event_action="fechou modal - ordenar por"
    [event_category]="category"
    event_label="x"
  >
    <img aria-hidden="true" src="./assets/ic_close.svg" alt="icon close" />
  </button>
</div>
<div class="modal-body">
  <mat-radio-group
    [(ngModel)]="optionSelectedValue"
    [ngModelOptions]="{ standalone: true }"
    aria-labelledby="radio-group-options"
  >
    <mat-radio-button
      *ngFor="let option of options"
      [value]="option.value"
      class="radio-button"
      (change)="onChange(option.name)"
      >{{ option.name }}</mat-radio-button
    >
  </mat-radio-group>
  <div class="modal-buttons">
    <button
      type="button"
      (click)="onSubmit()"
      class="btn btn-primary"
      appTagger
      [event_category]="category"
      event_label="{{ 'APPLY' | translate }}"
    >
      {{ 'APPLY' | translate }}
    </button>
  </div>
</div>
