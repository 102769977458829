<mat-drawer *ngIf="isNotificationsOpened" opened="true" #drawer class="sidenav" position="end" mode="over">
  <div class="sidenav-content">
    <div class="button-close w-100">
      <mat-icon
        class="float-right btn-close"
        (click)="hiddenComponent()"
        appTagger
        [event_category]="category"
        event_action="Fechou modal - Notificações"
        event_label="{{ 'X' | translate }}"
      >
        close
      </mat-icon>
    </div>

    <div class="mx-3">
      <div class="row no-gutters">
        <div class="col ml-4 mt-4">
          <h1 class="my-0">{{ 'NOTIFICATIONS' | translate }}</h1>
        </div>
        <div class="col-1 mt-4">
          <div
            *ngIf="window.screen.width < 768"
            class="actions-menu link justify-content-center align-self-center"
            appTagger
            [event_category]="category"
            event_label="{{ 'Opções' | translate }}"
          >
            <mat-icon (click)="openActions()">more_vert</mat-icon>
          </div>
          <div
            *ngIf="window.screen.width >= 768"
            class="actions-menu link justify-content-center align-self-center"
            [matMenuTriggerFor]="menu"
            appTagger
            [event_category]="category"
            event_label="{{ 'Opções' | translate }}"
          >
            <mat-icon>more_vert</mat-icon>
            <mat-menu #menu="matMenu">
              <span mat-menu-item class="menu-title" aria-disabled="true">{{ 'All notifications' | translate }}</span>
              <mat-divider></mat-divider>
              <button
                mat-menu-item
                appTagger
                [event_category]="category"
                event_label="Excluir todas"
                (click)="deleteAllCards()"
              >
                {{ 'DELETE' | translate }}
              </button>
              <mat-divider></mat-divider>
              <button
                mat-menu-item
                appTagger
                [event_category]="category"
                event_label="Marcar todas como lidas"
                (click)="markAllAsReaded()"
              >
                {{ 'Mark as read plural' | translate }}
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>

    <hr class="ml-hr" />

    <div class="mx-3">
      <ng-template [ngIf]="data.newData.length">
        <p class="notifications-period">{{ 'Today' | translate }}</p>
        <div *ngFor="let item of data.newData; let i = index">
          <div class="row no-gutters">
            <div class="justify-content-center align-self-center">
              <mat-icon
                class="read-indicator"
                [ngClass]="{ 'card-readed': item.isRead, 'card-unreaded': !item.isRead }"
              >
                lens</mat-icon
              >
            </div>

            <div class="col ml-1">
              <app-notification-card [category]="category" [data]="item"></app-notification-card>
            </div>

            <div class="col-1 mt-2">
              <div
                *ngIf="window.screen.width < 768"
                class="actions-menu"
                appTagger
                [event_category]="category"
                event_label="{{ 'Opções' | translate }}"
              >
                <mat-icon (click)="openActions(i, 'newData', item)">more_vert</mat-icon>
              </div>

              <div *ngIf="window.screen.width >= 768" class="actions-menu" [matMenuTriggerFor]="menu">
                <mat-icon>more_vert</mat-icon>
                <mat-menu #menu="matMenu">
                  <button mat-menu-item (click)="deleteCard(item, 'newData')">
                    {{ 'DELETE' | translate }}
                  </button>
                  <ng-template [ngIf]="!item.isRead">
                    <mat-divider></mat-divider>
                    <button mat-menu-item (click)="markAsReaded(item, 'newData')">
                      {{ 'Mark as read' | translate }}
                    </button>
                  </ng-template>
                </mat-menu>
              </div>
            </div>
          </div>
          <hr class="my-0 ml-4" />
        </div>
      </ng-template>

      <p *ngIf="data.oldData.length" class="notifications-period mt-4">{{ 'OLDER' | translate }}</p>
      <div *ngFor="let item of data.oldData; let i = index">
        <div class="row no-gutters">
          <div class="justify-content-center align-self-center">
            <mat-icon class="read-indicator" [ngClass]="{ 'card-readed': item.isRead, 'card-unreaded': !item.isRead }">
              lens
            </mat-icon>
          </div>

          <div class="col ml-1">
            <app-notification-card [category]="category" [data]="item"></app-notification-card>
          </div>

          <div class="col-1 mt-2">
            <div
              *ngIf="window.screen.width < 768"
              class="actions-menu"
              appTagger
              [event_category]="category"
              event_label="{{ 'Opções' | translate }}"
            >
              <mat-icon (click)="openActions(i, 'oldData', item)">more_vert</mat-icon>
            </div>

            <div
              appTagger
              [event_category]="category"
              event_label="opções"
              class="actions-menu"
              *ngIf="window.screen.width >= 768"
              [matMenuTriggerFor]="menu"
            >
              <mat-icon>more_vert</mat-icon>
              <mat-menu #menu="matMenu">
                <button mat-menu-item (click)="deleteCard(item, 'oldData')">
                  {{ 'DELETE' | translate }}
                </button>
                <ng-template [ngIf]="!item.isRead">
                  <mat-divider></mat-divider>
                  <button mat-menu-item (click)="markAsReaded(item, 'oldData')">
                    {{ 'Mark as read' | translate }}
                  </button>
                </ng-template>
              </mat-menu>
            </div>
          </div>
        </div>
        <hr class="my-0 ml-4" />
      </div>
    </div>
  </div>
</mat-drawer>
