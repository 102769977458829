
import { Injectable } from '@angular/core';
import { SummaryModel } from '@app/proposal-workflow/models/summary.model';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class FormalizationStepsService {
  constructor(private translateService: TranslateService) {}

  public getB1Steps(): Observable<SummaryModel[]> {
    const summarySteps: SummaryModel[] = [
      {
        title: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-TITLE'),
        secondaryTitle: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-SECONDARY-TITLE'),
        steps: [
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-1'),
            type: 'CUSTOMER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-2'),
            type: 'CUSTOMER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-3'),
            type: 'CUSTOMER'
          },
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-4'),
            type: 'SHOPKEEPER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-5'),
            type: 'SHOPKEEPER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-6'),
            type: 'SHOPKEEPER'
          },
        ]
      }
    ];

    return of(summarySteps);
  }

  public getB2Steps(): Observable<SummaryModel[]> {
    const summarySteps: SummaryModel[] = [
      {
        title: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-TITLE'),
        secondaryTitle: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-SECONDARY-TITLE'),
        steps: [
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-7'),
            type: 'CUSTOMER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-8'),
            type: 'CUSTOMER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-2'),
            type: 'CUSTOMER'
          },
          {
            step: 4,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-22'),
            type: 'CUSTOMER'
          },
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-23'),
            type: 'SHOPKEEPER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-9'),
            type: 'SHOPKEEPER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-5'),
            type: 'SHOPKEEPER'
          },
          {
            step: 4,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-6'),
            type: 'SHOPKEEPER'
          }
        ]
      }
    ];

    return of(summarySteps);
  }

  public getC1Steps(): Observable<SummaryModel[]> {
    const summarySteps: SummaryModel[] = [
      {
        title: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-TITLE'),
        secondaryTitle: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-SECONDARY-TITLE'),
        steps: [
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-10'),
            type: 'CUSTOMER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-11'),
            type: 'CUSTOMER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-2'),
            type: 'CUSTOMER'
          },
          {
            step: 4,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-3'),
            type: 'CUSTOMER'
          },
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-12'),
            type: 'SHOPKEEPER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-13'),
            type: 'SHOPKEEPER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-4'),
            type: 'SHOPKEEPER'
          },
          {
            step: 4,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-5'),
            type: 'SHOPKEEPER'
          },
          {
            step: 5,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-6'),
            type: 'SHOPKEEPER'
          }
        ]
      }
    ];

    return of(summarySteps);
  }

  public getC2Steps(): Observable<SummaryModel[]> {
    const summarySteps: SummaryModel[] = [
      {
        title: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-TITLE'),
        secondaryTitle: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-SECONDARY-TITLE'),
        steps: [
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-14'),
            type: 'CUSTOMER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-15'),
            type: 'CUSTOMER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-2'),
            type: 'CUSTOMER'
          },
          {
            step: 4,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-3'),
            type: 'CUSTOMER'
          },
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-12'),
            type: 'SHOPKEEPER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-13'),
            type: 'SHOPKEEPER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-4'),
            type: 'SHOPKEEPER'
          },
          {
            step: 4,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-5'),
            type: 'SHOPKEEPER'
          },
          {
            step: 5,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-6'),
            type: 'SHOPKEEPER'
          }
        ]
      }
    ];

    return of(summarySteps);
  }

  public getC3Steps(): Observable<SummaryModel[]> {
    const summarySteps: SummaryModel[] = [
      {
        title: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-TITLE'),
        secondaryTitle: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-SECONDARY-TITLE'),
        steps: [
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-7'),
            type: 'CUSTOMER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-8'),
            type: 'CUSTOMER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-2'),
            type: 'CUSTOMER'
          },
          {
            step: 4,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-16'),
            type: 'CUSTOMER'
          },
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-17'),
            type: 'SHOPKEEPER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-4'),
            type: 'SHOPKEEPER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-5'),
            type: 'SHOPKEEPER'
          },
          {
            step: 4,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-18'),
            type: 'SHOPKEEPER'
          }
        ]
      }
    ];

    return of(summarySteps);
  }

  public getD1Steps(): Observable<SummaryModel[]> {
    const summarySteps: SummaryModel[] = [
      {
        title: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-TITLE'),
        secondaryTitle: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-SECONDARY-TITLE'),
        steps: [
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-7'),
            type: 'CUSTOMER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-8'),
            type: 'CUSTOMER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-2'),
            type: 'CUSTOMER'
          },
          {
            step: 4,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-21'),
            type: 'CUSTOMER'
          },
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-9'),
            type: 'SHOPKEEPER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-5'),
            type: 'SHOPKEEPER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-6'),
            type: 'SHOPKEEPER'
          }
        ]
      }
    ];

    return of(summarySteps);
  }

  public getD2Steps(): Observable<SummaryModel[]> {
    const summarySteps: SummaryModel[] = [
      {
        title: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-TITLE'),
        secondaryTitle: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-SECONDARY-TITLE'),
        steps: [
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-7'),
            type: 'CUSTOMER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-8'),
            type: 'CUSTOMER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-2'),
            type: 'CUSTOMER'
          },
          {
            step: 4,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-21'),
            type: 'CUSTOMER'
          },
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-19'),
            type: 'SHOPKEEPER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-20'),
            type: 'SHOPKEEPER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-5'),
            type: 'SHOPKEEPER'
          },
          {
            step: 4,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-6'),
            type: 'SHOPKEEPER'
          }
        ]
      }
    ];

    return of(summarySteps);
  }

  public getD3Steps(): Observable<SummaryModel[]> {
    const summarySteps: SummaryModel[] = [
      {
        title: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-TITLE'),
        secondaryTitle: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-SECONDARY-TITLE'),
        steps: [
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-7'),
            type: 'CUSTOMER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-8'),
            type: 'CUSTOMER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-2'),
            type: 'CUSTOMER'
          },
          {
            step: 4,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-21'),
            type: 'CUSTOMER'
          },
          {
            step: 1,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-20'),
            type: 'SHOPKEEPER'
          },
          {
            step: 2,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-5'),
            type: 'SHOPKEEPER'
          },
          {
            step: 3,
            description: this.translateService.instant('FORMALIZATION-SUMMARY-STEPS-DESCRIPTION-6'),
            type: 'SHOPKEEPER'
          }
        ]
      }
    ];

    return of(summarySteps);
  }
}
