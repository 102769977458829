import { AbstractControl, ValidatorFn, Validators } from '@angular/forms';
import { ColombiaValidators } from '@shared/interfaces/validators/colombia-validators.interface';
import { ValidateAge } from './multi-country.validators';

export const ValidateSalary = (): ValidatorFn => {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (!control || !control.value) return null;
    const val = +control.value;
    if (val < 0.01) {
      return { ['INCOME-INPUT-ERROR-MSG-TOO-SMALL']: true };
    } else if (val > 999999999.99) {
      return { ['INCOME-INPUT-ERROR-MSG']: true };
    } else {
      return null;
    }
  };
};

export const colombiaValidators: ColombiaValidators = {
  documentType: [Validators.required],
  documentNumber: [Validators.required, Validators.maxLength(13), Validators.minLength(4)],
  firstName: [Validators.required, Validators.minLength(5), Validators.maxLength(60)],
  middleName: [Validators.required, Validators.minLength(5), Validators.maxLength(60)],
  lastName: [Validators.required, Validators.minLength(5), Validators.maxLength(60)],
  nickName: [Validators.required, Validators.minLength(2), Validators.maxLength(60)],
  password: [Validators.required, Validators.minLength(6), Validators.maxLength(12)],
  email: [
    Validators.required,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    Validators.minLength(5),
    Validators.maxLength(40)
  ],
  cellPhone: [Validators.required, Validators.minLength(10)],
  income: [Validators.required, ValidateSalary(), Validators.min(0.01), Validators.max(999999999.99)],
  economicActivity: [Validators.required],
  acceptTerms: [Validators.required],
  preToken: [Validators.required],
  dateOfBirth: [Validators.required, ValidateAge(18, 100)],
  financedValue: [Validators.required, Validators.min(0.01), Validators.max(999999999.99)]
};
