<div>
  <div class="section">
    <!-- FORMALIZATION-TITLE -->
    <div *ngIf="title" class="step-title col-12 order-2 col-lg-12 order-lg-1">
      <div class="section-title">{{ title | translate }}</div>
    </div>
    <div class="row section-header" [ngClass]="title ? '' : 'p-description'">
      <!-- DESCRIPTION-SIDE-STEP -->
      <div *ngIf="description" class="step-description col-12 order-3 col-lg-10 order-lg-2">
        <div class="section-description mb-2">{{ description | translate }}</div>
      </div>
      <!-- STEP-NUMBER-INDICATION -->
      <div *ngIf="indicationStep && maxStepIndication" class="step-indicator col-12 order-1 col-lg-2 order-lg-3">
        <p class="section-step">{{ indicationStep }} / {{ maxStepIndication }}</p>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
