<div>
  <app-modal autostart="true" identifier="termsModal" closable="false" backdrop="false" keyboard="false" size="sm">
    <ng-container body>
      <button mat-icon-button aria-label="Fechar" class="close-terms" (click)="handleCloseTerms()" mat-dialog-close>
        <img src="./assets/ic_close.svg" alt="fechar" />
      </button>

      <div class="modal-icon text-center">
        <img src="./assets/images/satander-icon.png" alt="Logo Santander" class="modal-logo" />
      </div>
      <h3 class="modal-title">Términos y Condiciones</h3>

      <div class="modal-scroll-area" (scroll)="handleScroll($event)">
        {{ terms }}
      </div>

      <hr class="modal-divider" />

      <button [disabled]="disableBtn" (click)="handleAcceptTerms()" class="btn modal-button">Estoy de acuerdo</button>
    </ng-container>
  </app-modal>
</div>

<ng-template #error>
  <p class="generic-error-message">Error al cargar los términos y condiciones</p>
</ng-template>
