import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@app/core/state';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ManagementService {
  constructor(private translate: TranslateService, private store$: Store<AppState>, private httpClient: HttpClient) {}

  public validateDocument(shopId, documentNumber: string): Observable<any> {
    const body = {
      shopId,
      documentNumber
    };
    const urlEndPoint = `/usermanager/1.0.0/verification`;
    return this.httpClient.post(environment.apiRootUrl + urlEndPoint, body);
  }

  public getUsers(id, params): Observable<any> {
    const urlEndPoint = `/usermanager/1.0.0/commercialUnit/${id}/users`;
    return this.httpClient.post(environment.apiRootUrl + urlEndPoint, params);
  }

  public deleteById(id, userId: string): any {
    const urlEndPoint = `/usermanager/1.0.0/userManagement/${userId}/${id}`;
    return this.httpClient.put(environment.apiRootUrl + urlEndPoint, {});
  }

  public searchPerfil(): any {
    const urlEndPoint = `/usermanager/1.0.0/perfil/searchPerfils`;
    return this.httpClient.get(environment.apiRootUrl + urlEndPoint);
  }

  // Lista os perfis que estao vinculados ao usuario
  public getUsuarioById(shopId: any, idUser: any) {
    const body = {
      shopId,
      idUser
    };
    const urlEndPoint = `/usermanager/1.0.0/perfil`;
    return this.httpClient.post(environment.apiRootUrl + urlEndPoint, body);
  }

  // Cadastra um novo usuario
  public saveUser(params): any {
    const urlEndPoint = `/usermanager/1.0.0/shopkeeper`;
    return this.httpClient.post(environment.apiRootUrl + urlEndPoint, params);
  }

  // Lista os produtos que a loja possui
  public getProductsByShop(shopId) {
    const urlEndPoint = `/usermanager/1.0.0/product/${shopId}`;
    return this.httpClient.get(environment.apiRootUrl + urlEndPoint);
  }

  /*
   * Atualiza informacoes de um usuario existente
   * params:
   * @userCode: documento do usuario
   * @params: objeto que pode ser usado para montar
  !*/
  public updateUser(params): any {
    const urlEndPoint = `/usermanager/1.0.0/shopkeeper`;
    return this.httpClient.put(environment.apiRootUrl + urlEndPoint, params);
  }

  /*
   * Lista informacoes de um usuario existente
   * params:
   * @userId: id do usuario
   * @shopId: id da loja vinculada ao usuario
  !*/
  public getUserDetailsById(userId: string, shopId: string) {
    const body = {
      userId,
      shopId
    };
    const urlEndPoint = `/usermanager/1.0.0/userManagement`;
    return this.httpClient.post(environment.apiRootUrl + urlEndPoint, body);
  }

  /*
   * Lista historico de perfis associados a um usuario existente
   * params:
   * @shopId: id da loja vinculada ao usuario
   * @userId: id do usuario
   * @any: objeto que pode ser usado para montar paginacao
  !*/
  public getHistoricoPerfisById(shopId: string, userId: string, params): any {
    const urlEndPoint = `/usermanager/1.0.0/perfil/historyPerfis/${shopId}/${userId}`;
    return this.httpClient.get(environment.apiRootUrl + urlEndPoint, { params });
  }
}
