<div class="row no-gutters  mx-4 mt-4">
  <div class="col-12 d-flex justify-content-between">
    <div class="title">
      <img [src]="'./assets/' + data.object?.icon" *ngIf="data.object?.icon" />
      <h1>{{ data.object?.title || 'All notifications' | translate }}</h1>
    </div>
    <mat-icon class="btn-close" appTagger [event_category]="category"
      event_action="fechou modal – todas as notificações" event_label="X" (click)="dismiss()">close</mat-icon>
  </div>
</div>
<hr class="row" />
<div class="row m-4">
  <div class="col-12 p-0 ">
    <button class="btn m-0" appTagger [event_category]="category" event_label="{{ 'DELETE' | translate }}"
      (click)="exclude()">
      {{ 'DELETE' | translate }}
    </button>
  </div>
  <div *ngIf="!data.object?.isRead || !data.object" class="col-12 p-0 ">
    <button class="btn-secondary mt-3" appTagger [event_category]="category"
      event_label="{{ 'Mark as read' | translate }}" (click)="markRead()">
      <span *ngIf="data.type">{{ 'Mark as read' | translate }}</span>
      <span *ngIf="!data.type">{{ 'Mark as read plural' | translate }}</span>
    </button>
  </div>
</div>