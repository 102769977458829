import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { LoginService } from '@app/login/services/login.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-mfa-modal',
  templateUrl: './mfa-modal.component.html',
  styleUrls: ['./mfa-modal.component.scss']
})
export class MfaModalComponent implements OnInit {

  public digitsForm: FormGroup = this.fb.group({});
  public digitsList: number[] = [];

  public formCode: FormGroup;
  public submitForm = false;

  public codeInvalid = false;
  public sucessMsg = false;

  public positionToolTip: any;
  public data = null
  private MOBILE_BREAKPOINT = 768;

  constructor(
    private readonly loginService: LoginService,
    private fb: FormBuilder,
    public activeModal: NgbActiveModal
  ) {

  }

  ngOnInit(): void {

    this.formCode = new FormGroup({
      code: new FormControl(null, [Validators.required, Validators.minLength(6), Validators.maxLength(6)])
    });
    this.setpositionToolTip();
  }

  setpositionToolTip() {
    if (window.screen.width < this.MOBILE_BREAKPOINT) {
      this.positionToolTip = 'above';
    } else {
      this.positionToolTip = 'right';
    }
  }

  get f(): { [key: string]: AbstractControl } {
    return this.formCode.controls;
  }

  public close() {
    this.activeModal.close()
  }

  requestCode() {
    this.loginService.resendCodeMFA(this.data).subscribe(
      r => {
        if (r.auth2Data.emailSent) {
          this.sucessMsg = true;
          setTimeout(() => {
            this.sucessMsg = false;
          }, 4000);
        }
      }

    );
  }

  submit() {
    this.codeInvalid = false;
    this.submitForm = true;
    if (this.formCode.invalid) return;
    this.loginService
      .validationMFA(this.data, this.formCode.value.code)
      .subscribe({
        next: res => this.activeModal.close(res),
        error: () => this.codeInvalid = true
      })
  }

  sendToken(event: any) {
    if (event) {
      this.formCode?.get('code')?.setValue(event);
    } else {
      this.formCode?.get('code')?.setValue(null);
    }
  }
}
