import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import * as notificationsAction from '@app/core/state/notifications/notifications.actions';
import { selectIsAllRead } from '@app/core/state/notifications/notifications.selectors';
import * as fromProfile from '@app/core/state/profile';
import { menuSelector } from '@app/core/state/profile/profile.selectors';
import * as fromTab from '@app/core/state/tab';
import { ProfileRenderService } from '@app/profile/service/profile-render.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { clearManagementPanelFiltersFromHeaderNavigation } from '@shared/functions/clear-mgm-flters-from-header';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HeaderItemCode } from '../../models/header-shopkeeper.model';
import { HeaderItem } from '../../services/header.service';

@Component({
  selector: 'app-header-shopkeeper',
  templateUrl: './header-shopkeeper.component.html',
  styleUrls: ['./header-shopkeeper.component.scss']
})
export class HeaderShopkeeperComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  public showCalendar = false;
  public logo = `${environment.assetsPath}logo.svg`;
  public menuCard = false;
  public isNotify = true;
  public menuCardMobile = false;
  public category;

  public step: boolean;
  public flagUnderlineMenu: string;

  public addTutorialClass: boolean;
  public currentUrl: string;
  public user: string;
  public storeName: string;
  public titleHeader = 'Title Header';
  public headerData: Array<HeaderItem>;
  public subMenuData: {
    user?: string;
    menuItems?: any;
  };
  public helpMenuData: Array<HeaderItem> = [];

  public showHelpMenu = false;
  public showLogo = false;

  @ViewChild('drawer', { static: true }) refSidenav: MatSidenav;
  mobileMenu: any[];

  tourArrowClass: string;
  public MOBILE_BREAKPOINT = 768;
  public subscription: Subscription;

  public innerWidth: number;

  /**
   * References to menu options elements
   */
  @ViewChild('refHelpMenu', { static: true }) refHelpMenu: ElementRef;
  @ViewChild('refUserMenu', { static: true }) refUserMenu: ElementRef;
  @ViewChild('refCardMenu') refCardMenu: ElementRef;

  constructor(
    private router: Router,
    private store$: Store<AppState>,
    private profileRenderService: ProfileRenderService,
    private _eref: ElementRef,
    private genTagger: GenTagger,
    private translate: TranslateService
  ) {
    let arrUrl = this.router.url.split('/');

    if (arrUrl.includes('tutorials')) {
      this.addTutorialClass = true;
    } else if (arrUrl.includes('identification')) {
      this.showLogo = true;
    }

    this.router.events.subscribe(() => {
      arrUrl = router.url.split('/');

      this.addTutorialClass = arrUrl.includes('tutorials');

      this.currentUrl = arrUrl[arrUrl.length - 1];

      this.defineTitleHeader();
    });

    this.currentUrl = arrUrl[arrUrl.length - 1];
  }

  ngOnInit(): void {
    this.store$.dispatch(new notificationsAction.GetNotifications());
    this.store$.select(selectIsAllRead).subscribe(isAllNotificationsRead => {
      this.isNotify = !isAllNotificationsRead;
    });
    this.defineTitleHeader();

    this.store$.pipe(select(menuSelector)).subscribe((state: any) => {
      if (state == null || state.length === 0) return;

      this.headerData = state
        .filter(menu => {
          return menu && menu.code && menu.code !== HeaderItemCode.HEAD_SUBMENU;
        })
        .filter(menu => {
          return menu && menu.code && menu.code !== HeaderItemCode.HEAD_HELP;
        });
      // this.headerData.push(this.MenuContratos);
      // console.log(this.headerData);
      this.helpMenuData = state.filter(e => e.code === HeaderItemCode.HEAD_HELP);

      this.subMenuData = {
        menuItems: state.filter(menu => {
          return menu && menu.code && menu.code === HeaderItemCode.HEAD_SUBMENU;
        })
      };

      if (
        this.subMenuData &&
        this.subMenuData.menuItems &&
        this.subMenuData.menuItems.length > 0 &&
        this.subMenuData.menuItems[0].submenu &&
        this.subMenuData.menuItems[0].submenu.length > 0
      ) {
        this.subMenuData = this.subMenuData.menuItems[0].submenu;
      }

      this.mobileMenu = [].concat(this.headerData, this.helpMenuData, this.subMenuData);

      if (this.helpMenuData.length > 0 && this.helpMenuData[0].submenu && this.helpMenuData[0].submenu.length > 0) {
        this.helpMenuData = this.helpMenuData[0].submenu;
      }
    });

    this.setProfileSubscription();
    this.onResize();
  }

  private setProfileSubscription(): void {
    const profile$ = this.store$.select(fromProfile.selectors.userProfileSelector).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(state => !!state)
    );

    const selectedStore$ = this.store$.select(fromTab.selectors.selectSelectedTab).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(state => !!state)
    );

    combineLatest([profile$, selectedStore$]).subscribe(([profile, selectedStore]) => {
      this.storeName = selectedStore.name ? this.parseStoreName(selectedStore.name) : '';
      if (profile.nickName) {
        this.user = profile.nickName;
        return;
      }
      const fullName = profile.name.split(' ');
      this.user = [fullName.splice(0, 1), fullName.splice(-1)].join(' ');
    });
  }

  private parseStoreName(name: string): string {
    const storeWords = name.split(' ');
    if (storeWords.length === 1) {
      return storeWords[0];
    }
    return `${storeWords[0]} ${storeWords[1]}`;
  }

  onFinish() {
    console.log('finished');
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.screen.width;
  }

  @HostListener('window:mousedown', ['onClick($event)'])
  public onClick(event): void {
    // REFACTOR: - buscar diminuir num if else (tentar outra abordagem)
    if (event) {
      if (
        !event.target.contains(this.refHelpMenu.nativeElement) &&
        event.target.contains(this.refUserMenu.nativeElement)
      ) {
        this.showHelpMenu = false;
      } else if (
        !event.target.contains(this.refUserMenu.nativeElement) &&
        event.target.contains(this.refHelpMenu.nativeElement)
      ) {
        this.menuCard = false;
      } else if (
        event.target.contains(this.refUserMenu.nativeElement) &&
        event.target.contains(this.refHelpMenu.nativeElement)
      ) {
        this.menuCard = false;
        this.showHelpMenu = false;
      } else if (!this.refCardMenu || !this._eref.nativeElement.contains(event.target)) {
        // REFACTOR
        if (this.refHelpMenu && this.refHelpMenu.nativeElement.contains(event.target)) {
          return;
        }
        this.showHelpMenu = false;
        this.menuCard = false;
      }
    }
  }

  public closeSidenav(): void {
    this.refSidenav.close();
  }

  public defineTitleHeader(): void {
    switch (this.currentUrl) {
      case 'special-offers':
        this.titleHeader = 'COFR-MENU-LABEL';
        break;
      case 'subsegment':
      case 'store-selection':
        this.titleHeader = 'SELECT-STORE';
        break;
      case 'contracts':
        this.titleHeader = 'CCTR-MENU-LABEL';
        break;
      case 'proposal':
      case 'open-proposal':
        this.titleHeader = 'CPRP-MENU-LABEL';
        break;
      case 'payments':
        this.titleHeader = 'CPAG-MENU-LABEL';
        break;
      case 'management':
        this.titleHeader = 'LGST-MENU-LABEL';
        break;
      default:
        this.titleHeader = 'Title Header';
        break;
    }
  }

  public choiceActionLabel(actionType, targetAddress): void {
    switch (actionType) {
      case 'routeTo':
        clearManagementPanelFiltersFromHeaderNavigation(targetAddress, this.store$);
        this.router.routeReuseStrategy.shouldReuseRoute = () => {
          return false;
        };
        this.router.onSameUrlNavigation = 'reload';
        this.router.navigate([targetAddress]);
        break;
      case 'callComponent':
        this.profileRenderService.loadComponent(targetAddress);
        break;
      default:
        console.log(actionType + ' not defined for: ' + targetAddress);
        break;
    }
  }

  goHome() {
    this.router.navigate(['/showcase']);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public menuClick(opcaoSelecionada) {
    this.category = window.location.href;
    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.Click,
      event_label: this.translate.instant(opcaoSelecionada)
    });
  }
}
