<div class="row" [class.offers-title-row]="position > 1">
  <div class="col-12 col-md-8 order-2 order-md-1">
    <p>
      <strong *ngIf="titleSection">{{ titleSection | translate }}</strong>
    </p>
  </div>
  <div class="col-12 col-md-4 order-1 order-md-2 text-md-right">
    <p class="counter">{{ position }} / {{ size }}</p>
  </div>
</div>
