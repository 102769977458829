import { Injectable } from '@angular/core';
import { AppState } from '@app/core/state';
import { userProfileSelector } from '@app/core/state/profile/profile.selectors';
import { selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import { selectSelectedTab } from '@app/core/state/tab/tab.selectors';
import { Store } from '@ngrx/store';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import JSEncrypt from 'jsencrypt';
import { filter, map, take } from 'rxjs/operators';
export interface StoreScope {
  store_code?: string,
  store_name?: string,
  proposal_id?: string | number,
  user_type?: string,
  user_id?: string,
  session_id?: string,
  client_id?: string,
  timestamp?: string;
}
@Injectable({
  providedIn: 'root'
})

export class TaggerService {
  storeScope;
  proposalType;
  pageLocation;
  previousPageLocation;

  constructor(private store$: Store<AppState>){
    this.setInitialData();
  }

  setProposalType(type: ProductCodeEnum) {
    this.proposalType = type;
  }

  getProposalType() {
    return this.proposalType;
  }

  setPageLocation(url) {
    this.previousPageLocation = this.pageLocation;
    this.pageLocation = url;
  }

  getPreviousPageLocation() {
    return this.previousPageLocation;
  }

  getPageLocation() {
    return this.pageLocation;
  }

  getStoreVal(){
    if (!this.storeScope) this.setInitialData();
    return {...this.storeScope, timestamp: this.timestamp()};
  }

  setInitialData() {
    this.storeScope = {
      event: 'appevent'
    }
    this.setCookies();
    this.setUserScope();
    this.setTabScope();
    this.setProposalScope();
  }

  setUserScope() {
    this.getFromStoreUserScope().subscribe((user) => this.storeScope = { ...this.storeScope, ...user });
  }

  setTabScope() {
    this.getFromStoreTabScope().subscribe((tab) => this.storeScope = { ...this.storeScope, ...tab });
  }

  setProposalScope() {
    this.getFromStoreProposalScope().subscribe((proposal) => this.storeScope = { ...this.storeScope, ...proposal });
  }

  setCookies() {
    this.storeScope = {
      ...this.storeScope,
      session_id: window['sessionId'],
      client_id: window['clientId']
    }
  }

  checkClienteId() {
    this.setCookies();
    if (!window['clientId']) return false
    if (window['clientId'] !== this.storeScope.client_id) {
      return false
    } else {
      return true
    }
  }

  private getFromStoreUserScope() {
    return this.store$.select(userProfileSelector)
    .pipe(
      take(1),
      filter(state => !!state),
      map((user) => ({
        user_type: user?.userProfiles?.toString(),
        user_id: this.hashCode(user?.documentNumber)
      }))
    )
  }

  private getFromStoreTabScope() {
    return this.store$.select(selectSelectedTab)
    .pipe(
      take(1),
      filter(state => !!state),
      map((tab) => ({
        store_code: tab?.code,
        store_name: tab?.name,
      }))
    )
  }

  private getFromStoreProposalScope() {
    return this.store$.select(selectProposalId)
    .pipe(
      filter(state => !!state),
      take(1),
      map((proposal) => ({
        proposal_id: proposal,
      }))
    )
  }



  /**
   * Generate the hash of a string
   * @date 11/22/2023
   * @author lilian.n.silva
   *
   * @private
   * @param {string} str
   * @returns {string}
   */
  private hashCode(str: string) {
    const encrypt = new JSEncrypt();
    const hash = encodeURIComponent(encrypt.encrypt(str) as string);
    return hash;
  }

  /**
   * Get current timestamp
   * @date 09/20/2021
   * @author rafael.reinoldes
   *
   * @public
   * @returns {string}
   */
  public timestamp() {
    // Get local time as ISO string with offset at the end
    const now = new Date();
    const tzo = -now.getTimezoneOffset();
    const dif = tzo >= 0 ? '+' : '-';
    const pad = num => {
      const norm = Math.abs(Math.floor(num));
      return (norm < 10 ? '0' : '') + norm;
    };
    return (
      now.getFullYear() +
      '-' +
      pad(now.getMonth() + 1) +
      '-' +
      pad(now.getDate()) +
      'T' +
      pad(now.getHours()) +
      ':' +
      pad(now.getMinutes()) +
      ':' +
      pad(now.getSeconds()) +
      '.' +
      pad(now.getMilliseconds()) +
      dif +
      pad(tzo / 60) +
      ':' +
      pad(tzo % 60)
    );
  }

}
