import gql from 'graphql-tag';

export const GET_STORES = gql`
  query getStores($idShop: Int, $search: String) {
    getStores(idShop: $idShop, search: $search) {
      idUser
      cdUser
      idFornecCanal
      dsFornecCanal
    }
  }
`;
