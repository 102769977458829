import { debounceTime } from 'rxjs/operators';
import { BestOffer } from './../../models/best-offer.model';
import { Component, EventEmitter, Input, OnInit, OnChanges, Output, SimpleChanges } from '@angular/core';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { InputChange } from '@app/proposal-workflow/models/input-change.model';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { BrazilMasks } from '@shared/interfaces/masks/brazil-masks.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';

@Component({
  selector: 'app-installment-info-fixed',
  templateUrl: './installment-info-fixed.component.html',
  styleUrls: ['./installment-info-fixed.component.scss']
})
export class InstallmentInfoFixedComponent implements OnInit, OnChanges {
  public formGroup: FormGroup;
  public installmentFixed = [];
  lastInstallments;
  public masks = this.internationalizationService.pioneerMasks as BrazilMasks;
  public code: any;
  @Input() installmentPMethodsDate;
  @Input() offerId: number;
  @Input() lastInstallmentValue;
  @Input() installmentDefaultAmount;
  @Input() selectedPaymentMethod: any;
  @Input() fixedInstallmentQuantity;
  @Input() installmentPaymentMethodsAmount;
  @Input() defaultInstallmentValue;
  @Input() category: string;
  @Input() bestOffer
  @Input() months: any = [];
  @Input() codeMonthsRange: any;
  @Input() minCustomInstallmentValue: any;
  @Input() maxCustomInstallmentValue;
  @Input() customInstallmentValue: any;
  @Output() installmentInfoEmitter = new EventEmitter<InputChange>();
  @Output() public selectedValidatorsEmitter = new EventEmitter<boolean>();
  constructor(private genTagger: GenTagger, private internationalizationService: InternationalizationService) {
    this.formGroup = new FormGroup({
      numberFixedInstallments: new FormControl('', [Validators.required, Validators.nullValidator]),
      valueOfFixedInstallments: new FormControl('', [
        Validators.nullValidator,
        Validators.required,
        Validators.min(this.minCustomInstallmentValue),
        Validators.max(this.maxCustomInstallmentValue)
      ])
    });
  }

  ngOnInit(): void {
    this.setFormValues();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.months || changes.codeMonthsRange) &&
      changes.maxCustomInstallmentValue &&
      changes.minCustomInstallmentValue && changes.fixedInstallmentQuantity
    ) {
      this.setFormValues();
    }
  }

  changeHandler($event?) {
    this.setValidator();

    if (this.formGroup.status === 'VALID') {
      const valeuInstallment = this.formGroup.controls['valueOfFixedInstallments'].value;
      const payload: InputChange = {
        value: {
          payment: {
            codeMonthsRange: this.formGroup.controls.numberFixedInstallments.value
          }
        },
        input: InputTypes.INSTALLMENT_INFO_MONTHS,
        fixedInstallmentQuantity: this.formGroup.controls['numberFixedInstallments'].value,
        customInstallmentValue: parseFloat(valeuInstallment),

        type: 'FIXED'
      };
      this.formGroup.controls['numberFixedInstallments'].valueChanges.subscribe(valueChange => { });

      this.installmentInfoEmitter.emit(payload);
      this.getFormStatus(this.formGroup);
      this.genTagger.setTag({
        event_category: this.category,
        event_action: `${Tag.Selecionar} - Período`,
        event_label: this.months.find(item => item === this.formGroup.controls.numberFixedInstallments.value)
      });
    }else{
    this.getFormStatus(this.formGroup);
    }
  }
  private setValidator() {
    this.formGroup.controls['valueOfFixedInstallments'].setValidators([
      Validators.required,
      Validators.min(this.minCustomInstallmentValue),
      Validators.max(this.maxCustomInstallmentValue)
    ]);
    this.formGroup.get('valueOfFixedInstallments').updateValueAndValidity();
  }

  private setFormValues() {
    this.minCustomInstallmentValue ? this.minCustomInstallmentValue : this.bestOffer?.minCustomInstallmentValue;
    this.fixedInstallmentQuantity ? this.fixedInstallmentQuantity: this.bestOffer?.fixedInstallmentQuantity
    if (this.months && this.codeMonthsRange && !!this.maxCustomInstallmentValue && !!this.minCustomInstallmentValue) {
      const codeMonthsRange = parseInt(this.fixedInstallmentQuantity);
      const codePeriod = this.months.find(m => m === codeMonthsRange);
      this.code = codePeriod;

      const minValue = this.minCustomInstallmentValue.toFixed(2).replace('.', ',');
      if (this.code && minValue) {
        this.formGroup.controls['numberFixedInstallments'].setValue(this.code);
        this.formGroup.controls['valueOfFixedInstallments'].setValue(minValue);
        this.installmentInfoEmitter.emit(this.formGroup.value.numberFixedInstallments);
      }
      this.setValidator();
      this.getFormStatus(this.formGroup);

    }
    this.getFormStatus(this.formGroup);
  }

  public getFormStatus(form: any) {
    form.getRawValue();
    if (form.status ==='INVALID') {
      this.selectedValidatorsEmitter.emit(true);
    } else {
      this.selectedValidatorsEmitter.emit(false);
    }
  }
}
