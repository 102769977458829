import { Injectable } from '@angular/core';
import { UserData } from '@app/core/startup/models/user.model';
import * as AesJS from 'aes-js';
@Injectable({
  providedIn: 'root'
})
/**
 * This service is responsible for manage the session Storage data
 */
export class SessionStorageService {
  // Keys for the session Storage
  readonly USER_DATA = '@@user_data';
  readonly HISTORY = 'history';
  readonly CROSSED_FLUX = 'crossed_flux';
  readonly CROSSED_FLUX_TIMER = 'crossed_flux_timer';

  constructor() {}

  private encrypt(text: string): string {
    let encText = '';

    try {
      const emBase64 = window.btoa(encodeURI(text));
      const aesOfb = new AesJS.ModeOfOperation.ofb(
        [10, 11, 12, 13, 14, 15, 15, 17, 18, 19, 20, 21, 22, 23, 24, 27],
        [37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 54]
      );
      const textBytes = AesJS.utils.utf8.toBytes(emBase64);
      const encryptedBytes = aesOfb.encrypt(textBytes);
      encText = AesJS.utils.hex.fromBytes(encryptedBytes);
    } catch (e) {
      this.clearUser();
    }

    return encText;
  }

  private decrypt(encText: string): any {
    let decText = '';

    try {
      const aesOfb = new AesJS.ModeOfOperation.ofb(
        [10, 11, 12, 13, 14, 15, 15, 17, 18, 19, 20, 21, 22, 23, 24, 27],
        [37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 54]
      );
      const encryptedBytes = AesJS.utils.hex.toBytes(encText);
      const decryptedBytes = aesOfb.decrypt(encryptedBytes);
      const decryptedString = AesJS.utils.utf8.fromBytes(decryptedBytes);
      decText = decodeURI(window.atob(decryptedString));
    } catch (e) {
      this.clearUser();
    }

    return decText;
  }

  public getCrossedFlux(): any {
    return JSON.parse(sessionStorage.getItem(this.CROSSED_FLUX));
  }

  public setCrossedFlux(data: any): void {
    sessionStorage.setItem(this.CROSSED_FLUX, JSON.stringify(data));
  }

  public clearCrossedFlux(): void {
    sessionStorage.removeItem(this.CROSSED_FLUX);
  }

  public getCrossedFluxTimer(): any {
    return JSON.parse(sessionStorage.getItem(this.CROSSED_FLUX_TIMER));
  }

  public setCrossedFluxTimer(data: any): void {
    sessionStorage.setItem(this.CROSSED_FLUX_TIMER, JSON.stringify(data));
  }

  public clearCrossedFluxTimer(): void {
    sessionStorage.removeItem(this.CROSSED_FLUX_TIMER);
  }

  setHistory(records) {
    sessionStorage.setItem(this.HISTORY, JSON.stringify(records));
  }

  getHistory() {
    return JSON.parse(sessionStorage.getItem(this.HISTORY));
  }

  /**
   * Set user data on sessionStorage
   * @param userData
   */
  setUser(userData: UserData): void {
    if (!userData) return;

    const cUser = this.encrypt(JSON.stringify(userData));

    sessionStorage.setItem(this.USER_DATA, cUser);
  }

  authTokenModelConverter(authTokenResponse, documentNumber) {
    const userData: UserData = {
      accessToken: authTokenResponse.access_token,
      refreshToken: authTokenResponse.refresh_token,
      scope: authTokenResponse.scope,
      expiresIn: authTokenResponse.expires_in,
      tokenType: authTokenResponse.token_type,
      document: documentNumber,
      stores: authTokenResponse.stores,
      grantType: 'password',
      rememberUser: authTokenResponse.rememberUser
    };

    return userData;
  }

  /**
   * Clear user data on session Storage
   */
  clearUser(): void {
    sessionStorage.removeItem(this.USER_DATA);
  }

  /**
   * Retrieve user data on session Storage
   */
  getUser(): UserData {
    const cUser = sessionStorage.getItem(this.USER_DATA);

    if (!cUser) return;

    const userData = this.decrypt(cUser);

    if (userData) {
      const parsed: UserData = JSON.parse(userData);
      return parsed;
    }
  }
}
