import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-help-screen',
  templateUrl: './help-screen.component.html',
  styleUrls: ['./help-screen.component.scss']
})
export class HelpScreenComponent implements OnInit {
  public helpScreenInfo: string;
  public hotlineNumber: string;

  constructor(private router: Router, private loc: Location) {
    this.hotlineNumber = '1-800-400-0000';
  }

  ngOnInit() {}

  public goBackButton() {
    this.loc.back();
  }
}
