<div id="modal-content-wrapper" class="pion-modal">
  <header id="modal-header" class="text-center mt-4">
    <img *ngIf="modalData?.type === 'alert'" src="./assets/images/ic_exclamation.svg" width="44px" height="44px"
      class="mb-4" alt="Alerta" />
    <img *ngIf="modalData?.type === 'success'" src="./assets/images/ic_check.svg" width="44px" height="44px"
      class="mb-4" alt="Sucesso" />
    <mat-icon *ngIf="modalData?.type === 'error'" class="modal-icon-error">cancel</mat-icon>
    <h1 id="modal-title" mat-dialog-title>{{ modalData?.title }}</h1>
  </header>
  <section *ngIf="modalData?.description || modalData?.errorCode" id="modal-body" mat-dialog-content>
    <p [innerHTML]="modalData?.description | translate"></p>
    <div *ngIf="modalData?.errorCode" class="error-code mt-3 text-center text-muted">
      Código: {{ modalData?.errorCode }}
    </div>
  </section>
  <footer id="modal-footer" class="modal-footer"
    [ngClass]="{ 'modal-footer--top-spacing': !modalData?.description && !modalData?.errorCode }" mat-dialog-actions>
    <button *ngIf="modalData?.cancelText" mat-stroked-button id="modal-cancel-button" color="primary"
      (click)="cancelActionFunction()">
      {{ modalData?.cancelText }}
    </button>
    <button mat-flat-button id="modal-action-button" color="primary" (click)="confirmActionFunction()">
      {{ modalData?.confirmText }}
    </button>
  </footer>
</div>
