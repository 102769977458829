import { Component, OnInit } from '@angular/core';
import * as challenge from '@app/core/state/challenge';
import { Store, select } from '@ngrx/store';
import { AppState } from '@app/core/state';
import { getTerms } from '@app/core/state/challenge/challenge.selectors';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';

@Component({
  selector: 'challenge-terms',
  templateUrl: 'challenge-terms.component.html',
  styleUrls: ['challenge-terms.component.scss']
})
export class ChallengeTermsComponent implements OnInit {
  disableBtn = true;

  data: any;

  readonly category = '/portallojista/login';

  constructor(private store$: Store<AppState>, private genTagger: GenTagger) {
    this.store$.dispatch(new challenge.actions.GetChallenge({ refresh: false }));
  }

  ngOnInit() {
    this.store$.pipe(select(getTerms)).subscribe(res => {
      if (res && res.challenges && res.challenges.term && res.challenges.term.title && res.challenges.term.content) {
        this.data = res.challenges.term.content;
      }
    });

    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.pgView,
      event_label: 'Login - Modal Termos e Condições'
    });
  }

  checkScroll() {
    if (
      this.data.content &&
      this.data.content.nativeElement.scrollHeight <= this.data.content.nativeElement.clientHeight
    ) {
      this.disableBtn = false;
    }
  }

  handleScroll(event) {
    const target = event.target;
    if (target.scrollTop + target.offsetHeight > target.scrollHeight - 1) {
      this.disableBtn = false;
    }
  }

  handleAcceptButtonClick() {
    // this.accept.emit();

    this.store$.dispatch(new challenge.actions.AnswerChallenge({ term: true }));
  }

  handleCloseButtonClick() {
    // this.skip.emit();
  }
}
