import { HttpHandler, HttpInterceptor, HttpParams, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { StartupService } from '@app/core/startup/services/startup.service';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

const isRequestInterceptable = (request: HttpRequest<any>): boolean => {
  return (
    request.url.indexOf(`${environment.apiRootUrl}`) >= 0 &&
    request.url !== environment.apiRootUrl &&
    request.url !== environment.apiRootUrl + '/token'
  );
};

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private startupService: StartupService, private dialog: MatDialog, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let reqToBeUsed = request.clone();
    const currentAuth = this.startupService.getUser();
    if (isRequestInterceptable(request) && currentAuth) {
      reqToBeUsed = request.clone({
        headers: request.headers.set('Authorization', `Bearer ${currentAuth.accessToken}`)
      });
    }

    if (request.url.indexOf('/token') < 0 && currentAuth) {
      const accessToken = currentAuth.accessToken;
      reqToBeUsed = request.clone({ headers: request.headers.set('Authorization', 'Bearer ' + accessToken) });
    }

    if (request.url.indexOf('/token') >= 0 || request.url.indexOf('/revoke') >= 0) {
      let base64 = environment.apiKeyApiSecretVisit;

      const body: HttpParams = request.body;

      if ((body && body.get('grant_type') === 'password') || request.url.indexOf('/revoke') >= 0) {
        base64 = environment.apiKeyApiSecret;
      }

      reqToBeUsed = request.clone({
        headers: request.headers.set('Authorization', 'Basic ' + base64)
      });
    }

    return next.handle(reqToBeUsed);
  }
}
