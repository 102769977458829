<div class="bottom-sheet in " [ngClass]="{ in: in, out: show }" (click)="handleBottomSheet()">
  <ng-container *ngIf="currentUrl === '/proposal/step-formalization'; else simulationBottomSheet">
    <bottom-sheet-sumary
      *ngIf="proposal"
      [proposal]="proposal"
      [paymentType]="paymentType"
      [show]="show"
      [currentUrl]="currentUrl"
    >
    </bottom-sheet-sumary>
  </ng-container>

  <ng-template #simulationBottomSheet>
    <div *ngIf="currentUrl === '/proposal/step-simulation'" class="bottom-sheet-header">
      <div class="header-summary">
        <mat-icon class="icon-arrow" *ngIf="!show">keyboard_arrow_up</mat-icon>
        <mat-icon class="icon-arrow" *ngIf="show">keyboard_arrow_down</mat-icon>

        <div>
          <div class="summary">
            <p class="summary-text">{{ 'Summary' | translate }}</p>
          </div>
        </div>
      </div>

      <div class="header-values monthly" [ngClass]="{ hide: hideTransition }" *ngIf="!showInitialFee">
        <p
          class="value"
          [ngClass]="{
            'value-customized-plan': proposal?.planType !== paymentPlanType.FLT_PLAN,
            'value-standard-plan': proposal?.planType === paymentPlanType.FLT_PLAN
          }"
        >
          {{ proposal?.installmentAmount }}x
          <strong *ngIf="proposal?.planType !== paymentPlanType.FLT_PLAN">
            {{ paymentPlanType.CUSTOMIZED_PLAN }}</strong
          >
          <strong *ngIf="proposal?.planType === paymentPlanType.FLT_PLAN">
            {{ proposal?.installmentValue | localeCurrency }}</strong
          >
        </p>
      </div>
    </div>

    <ng-container *ngIf="currentUrl === '/proposal/step-simulation'">
      <div class="store-conditions">
        <div class="title">
          <p>{{ 'SIMULATION-SUMARY-TITLE' | translate }}</p>
        </div>
        <div class="conditions-values">
          <div class="info">
            <div class="sidebar-div d-flex">
              <div>
                <div class="sidebar-title">
                  <p class="sidebar-text">{{ 'PROPOSAL-SUMARY-FINANCED-VALUES' | translate }}</p>
                </div>
                <div class="sidebar-info">
                  <p>{{ proposal?.financedValue | localeCurrency }}</p>
                  <p>
                    <span>{{ proposal?.installmentAmount }}x </span>
                    <strong *ngIf="proposal?.planType !== paymentPlanType.FLT_PLAN">
                      {{ paymentPlanType.CUSTOMIZED_PLAN }}</strong
                    >
                    <strong *ngIf="proposal?.planType === paymentPlanType.FLT_PLAN">
                      {{ proposal?.installmentValue | localeCurrency }}</strong
                    >
                  </p>
                  <p *ngIf="currentUrl === '/proposal/step-formalization'">
                    {{ 'RATE-MONTH' | translate }}
                    {{ proposal?.interestRate }}
                    {{ 'STEP-CONFIRMATION-PER-MONTH-ABREV' | translate }}
                  </p>
                </div>
              </div>
              <div>
                <button mat-button color="primary" (click)="scroll.next('SCROLL_TO_INSTALLMENT')">
                  {{ 'SIMULATION-SUMARY-EDIT' | translate }}
                </button>
              </div>
            </div>

            <div class="sidebar-div d-flex">
              <div>
                <div class="sidebar-title">
                  <p class="sidebar-text">{{ 'PROPOSAL-SUMARY-FIRST-DUE-DATE' | translate }}</p>
                </div>
                <p class="sidebar-info">{{ proposal?.expireDate }}</p>
                <p class="text-sm gray-light">{{ 'PROPOSAL-SUMARY-FIRST-DUE-DATE-MSG' | translate }}</p>
              </div>
              <div>
                <button mat-button color="primary" (click)="scroll.next('SCROLL_TO_DUEDATE')">
                  {{ 'SIMULATION-SUMARY-EDIT' | translate }}
                </button>
              </div>
            </div>

            <div class="sidebar-div d-flex">
              <div>
                <div class="sidebar-title">
                  <p class="sidebar-text">{{ 'SIMULATION-SUMARY-PAYMENT-PLAN' | translate }}</p>
                </div>
                <p class="sidebar-info">{{ paymentType }}</p>
                <!-- <p *ngIf="proposal?.liquidationType === 'DEBITO'" class="sidebar-info">{{ 'SIMULATION-SUMARY-DEBIT' | translate }}</p> -->
              </div>
              <div>
                <button mat-button color="primary" (click)="scroll.next('SCROLL_TO_PAYMENT')">
                  {{ 'SIMULATION-SUMARY-EDIT' | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="div-btn row d-flex justify-content-center pt-4">
        <button class="w-75" mat-raised-button color="primary">
          {{ 'SIMULATION-SUMARY-CONTINUE-TO-REGISTER' | translate }}
        </button>
      </div>
    </ng-container>
  </ng-template>
</div>
