import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { TranslateService } from '@ngx-translate/core';
import { PaymentButtonEnum, PaymentRequestStatusEnum } from '../../models/payment.enum';
import { PaymentRequestDialogModel, PaymentRequestResponseServiceModel } from '../../models/payment.model';
import { PaymentsService } from '../../services/payments.service';
import { PaymentRequestStatusDialogComponent } from '../payment-request-status-dialog/payment-request-status-dialog.component';

@Component({
  selector: 'app-payment-request-dialog',
  templateUrl: './payment-request-dialog.component.html',
  styleUrls: ['./payment-request-dialog.component.scss']
})
export class PaymentRequestDialogComponent implements OnInit {
  @Output() reloadListPayments = new EventEmitter<boolean>();

  private statusPayment: string;
  private messagePayment: string;
  private msgPaymentAgendado =
    'não foi possível realizar a solicitação de pagamento porque atingimos o horário limite para esta ação. ' +
    'você não precisa se preocupar, ' +
    'o seu pagamento foi agendado para o próximo dia útil.';
  private msgPaymentError =
    'No momento não foi possível realizar o pagamento da proposta por indisponibilidade sistêmica. Por favor, tente novamente mais tarde.';
  readonly category = '/portallojista/criarproposta';
  readonly closeAction: ExtendedTag = {
    event_action_type: Tag.Fechou_Modal,
    event_action_complement: this.translate.instant('PAYMENT-REQUEST')
  };

  constructor(
    public dialogRef: MatDialogRef<PaymentRequestDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PaymentRequestDialogModel,
    private dialog: MatDialog,
    private paymentsService: PaymentsService,
    private genTagger: GenTagger,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.genTagger.setTag({
      event_category: this.category,
      event_label: this.translate.instant('PAYMENT-REQUEST'),
      event_action: `${Tag.AbriuModal} - ${this.translate.instant('PAYMENT-REQUEST')}`
    });
  }
  public requestPaymentScheduling(){
    const payload = [];
    payload.push(this.data.id);

    this.paymentsService.paymentRequestShedule(payload).subscribe((res: PaymentRequestResponseServiceModel[]) => {
      if (!!res && !!res.length) {
        if (res[0].isSuccess) {
          this.statusPayment = PaymentRequestStatusEnum.PAYMENT_REQUEST_SUCCESS;
          this.openDialogPaymentRequestStatus();
        } else {
          this.openDialogPaymentRequestByMessage(res[0].message);
        }
      } else {
        this.statusPayment = PaymentRequestStatusEnum.PAYMENT_REQUEST_FAILURE;
        this.messagePayment = this.msgPaymentError;
        this.openDialogPaymentRequestStatus();
      }
    });

    this.genTagger.setTag({
      event_action: this.closeAction,
      event_category: this.category,
      event_label: this.translate.instant('CONTINUE-BUTTON')
    });

    this.dialogRef.close();
  }
  public requestPayment(): void {
    if(this.data.typeOfRequest === 'REQUEST-SCHEDULING'){
      return this.requestPaymentScheduling();
    }
    const payload = [];
    payload.push(this.data.id);

    this.paymentsService.paymentRequest(payload).subscribe((res: PaymentRequestResponseServiceModel[]) => {
      if (!!res && !!res.length) {
        if (res[0].isSuccess) {
          this.statusPayment = PaymentRequestStatusEnum.PAYMENT_REQUEST_SUCCESS;
          this.openDialogPaymentRequestStatus();
        } else {
          this.openDialogPaymentRequestByMessage(res[0].message);
        }
      } else {
        this.statusPayment = PaymentRequestStatusEnum.PAYMENT_REQUEST_FAILURE;
        this.messagePayment = this.msgPaymentError;
        this.openDialogPaymentRequestStatus();
      }
    });

    this.genTagger.setTag({
      event_action: this.closeAction,
      event_category: this.category,
      event_label: this.translate.instant('CONTINUE-BUTTON')
    });

    this.dialogRef.close();
  }

  closeDialog() {
    this.genTagger.setTag({
      event_action: this.closeAction,
      event_category: this.category,
      event_label: this.translate.instant('NO_BACK')
    });
    this.dialogRef.close();
  }

  private openDialogPaymentRequestByMessage(statusMsg: string) {
    if (statusMsg.toLowerCase().trim() === this.msgPaymentAgendado.toLowerCase().trim()) {
      this.statusPayment = PaymentRequestStatusEnum.SCHEDULED_PAYMENT;
      this.messagePayment = statusMsg;
    } else {
      this.statusPayment = PaymentRequestStatusEnum.PAYMENT_REQUEST_FAILURE;
      this.messagePayment = statusMsg;
    }
    this.openDialogPaymentRequestStatus();
  }

  private openDialogPaymentRequestStatus() {
    if(this.data.typeOfRequest === 'REQUEST-SCHEDULING'){
      this.statusPayment = PaymentRequestStatusEnum.REQUEST_SCHEDULING_SUCCESSFULLY_MADE
    }
    this.dialog
      .open(PaymentRequestStatusDialogComponent, {
        width: '560px',
        autoFocus: false,
        data: {
          id: this.data.id,
          status: this.statusPayment,
          text: this.messagePayment
        }
      })
      .afterClosed()
      .subscribe((value: boolean) => {
        // if (!!value) {
        this.reloadListPayments.emit(true);
        // }
      });
  }
}
