import { SignUpRenderData, SignUpUserData } from '../models/sign-up.model';
import { SignUpActions, SignUpActionTypes, GetAfipDataSuccessAction } from './signup.actions';

export interface SignUpState {
  renderData: SignUpRenderData;
  userData: SignUpUserData;
  signUpId: string;
  challengeType: string;
  challengeResponse: 'APPROVED' | 'REJECTED';
  signUpOutcome: 'success' | 'failure';
}

export const initSignUpState: SignUpState = {
  renderData: {
    genders: null,
    documentTypes: null,
    economicActivities: null,
    terms: null
  },
  userData: null,
  signUpId: null,
  challengeType: null,
  challengeResponse: null,
  signUpOutcome: null
};

export function signUpReducer(state = initSignUpState, action: SignUpActions): SignUpState {
  switch (action.type) {
    case SignUpActionTypes.GET_SIGN_UP_RENDER_DATA_SUCCESS: {
      return { ...state, renderData: { ...action.payload } };
    }
    case SignUpActionTypes.GET_SIGN_UP_RENDER_DATA_ERROR: {
      return { ...state, renderData: { ...initSignUpState.renderData } };
    }
    case SignUpActionTypes.GET_AFIP_DATA_SUCCESS: {
      return { ...state, userData: { ...afipDataReducer(state.userData, action) } };
    }
    case SignUpActionTypes.SAVE_USER_DATA: {
      return { ...state, userData: { ...action.payload } };
    }
    case SignUpActionTypes.CLEAR_USER_DATA: {
      return { ...state, userData: initSignUpState.userData };
    }
    case SignUpActionTypes.SUBMIT_CONFIRMATION_TOKEN_SUCCESS: {
      return { ...state, challengeResponse: 'APPROVED' };
    }
    case SignUpActionTypes.SUBMIT_CONFIRMATION_TOKEN_ERROR: {
      return { ...state, challengeResponse: 'REJECTED' };
    }
    case SignUpActionTypes.REFRESH_CONFIRMATION_TOKEN: {
      return { ...state, challengeResponse: null };
    }
    case SignUpActionTypes.SUBMIT_NEW_PASSWORD_SUCCESS: {
      return { ...state, signUpOutcome: 'success' };
    }
    case SignUpActionTypes.SUBMIT_NEW_PASSWORD_ERROR: {
      return { ...state, signUpOutcome: 'failure' };
    }
    case SignUpActionTypes.RESET_CHALLENGE_RESULT: {
      return { ...state, challengeResponse: null };
    }
    case SignUpActionTypes.RESET_SIGN_UP_DATA: {
      return {
        ...state,
        userData: { ...initSignUpState.userData },
        signUpId: initSignUpState.signUpId,
        challengeType: initSignUpState.challengeType,
        challengeResponse: initSignUpState.challengeResponse,
        signUpOutcome: initSignUpState.signUpOutcome
      };
    }
    default:
      return state;
  }
}

export function afipDataReducer(state: SignUpUserData, action: GetAfipDataSuccessAction): SignUpUserData {
  return {
    ...state,
    fullName: action.payload.fullName,
    dateOfBirth: action.payload.dateOfBirth
  };
}
