import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ComponentFactoryResolver, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { selectShowTutorialPgto } from '@app/core/state/login/login.selectors';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { FilterOrdenationDialogComponent } from '@shared/components/filter-ordenation-dialog/filter-ordenation-dialog.component';
import { FilterPeriodDialogComponent } from '@shared/components/filter-period-dialog/filter-period-dialog.component';
import { SearchSellerDialogComponent } from '@shared/components/search-seller-dialog/search-seller-dialog.component';
import { SideContentService } from '@shared/services/side-content/side-content.service';
import { TutorialService } from '@shared/services/tutorial/tutorial.service';
import { CarouselComponent, OwlOptions } from 'ngx-owl-carousel-o';
import { Observable, Subscription } from 'rxjs';
import { debounceTime, first, map, switchMap, take } from 'rxjs/operators';
import { ProposalCancelDialogComponent } from '../proposals/components/proposal-cancel-dialog/proposal-cancel-dialog.component';
import { ProposalExtractDialogComponent } from '../proposals/components/proposal-extract-dialog/proposal-extract-dialog.component';
import { ProposalMassiveCancelDialogComponent } from '../proposals/components/proposal-massive-cancel-dialog/proposal-massive-cancel-dialog.component';
import { PaymentDetailDialogComponent } from './components/payment-detail-dialog/payment-detail-dialog.component';
import { PaymentInvalidDataDialogComponent } from './components/payment-invalid-data-dialog/payment-invalid-data-dialog.component';
import { PaymentMassiveUploadDialogComponent } from './components/payment-massive-upload-dialog/payment-massive-upload-dialog.component';
import { PaymentMassiveUploadStatusDialogComponent } from './components/payment-massive-upload-status-dialog/payment-massive-upload-status-dialog.component';
import { PaymentMultipleRequestComponent } from './components/payment-multiple-request/payment-multiple-request.component';
import { PaymentRequestDialogComponent } from './components/payment-request-dialog/payment-request-dialog.component';
import { PaymentButtonEnum, PaymentIconStatusEnum, PaymentStatusEnum } from './models/payment.enum';
import { PaymentShowcaseModel } from './models/payment.model';
import { PaymentsService } from './services/payments.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  @ViewChild('contentContainer', { read: ViewContainerRef }) contentContainer;
  @ViewChild('owlElement', { read: ViewContainerRef }) owlElement: CarouselComponent;

  public window = window;
  public listPayments: PaymentShowcaseModel[] = [];
  public quickFilters: any = [];
  public firstLoad = true;
  public disabledPaymentRequest = false;
  public alertExpirationDays = false;
  public hasProposalExpiring = false;
  public valueButton = ''
  readonly categoria = '/portallojista/pagamentos';
  readonly Pesquisar: Tag = Tag.Pesquisar;
  readonly Selecionar: Tag = Tag.Selecionar;


  public customOptions: OwlOptions = {
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    margin: 10,
    nav: true,
    navText: ['<', '>'],
    stagePadding: 0,
    autoHeight: false,
    responsive: {
      0: {
        items: 1
        // mouseDrag e touchDrag não funcionam com items = 1.5, por isso navegação é necessária
        // nav: false
      },
      568: {
        items: 3
      },
      940: {
        items: 5
      }
    }
  };

  // pagination
  public totalElements: number;
  public totalPages: number;
  public page = 1;
  public perPage = 30;
  public paginationMaxSize = 9;
  public previousPage: number;
  public initialPage = 1;

  public shopId$: Observable<any> = this.store.select('tabs').pipe(map(tab => tab.selectedTab.id));

  public allFiltersAmount: any;

  // checkbox
  public allComplete = false;
  public checkedCount: number;
  public sumCheckedValues: number;

  // input search
  public searchString: string;

  // filter
  isFilterOpened = false;
  private filterObj: any = {
    period: '-6m'
  };
  public canEnableKebab = true;

  private layoutChanges$: Subscription;
  private isLoadFilterPeriodStored = false;

  private ordenationIconUp = 'ic_diag_arrow_up';
  private ordenationIconDown = 'ic_diag_arrow_down';
  public ordenationIconsAllProposals = this.ordenationIconDown;
  private idPaymentFromProposal;

  constructor(
    private store: Store<AppState>,
    private tutorialService: TutorialService,
    private paymentsService: PaymentsService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    private cfr: ComponentFactoryResolver,
    private sideContentService: SideContentService,
    private breakpointObserver: BreakpointObserver,
    private genTagger: GenTagger,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: Tag.pgView,
      event_label: 'Pagamentos'
    });

    this.subscribeToLayoutChanges();
    // Id da proposta selecionada no click do continuar para Pagamento do DetailProposal
    this.idPaymentFromProposal = JSON.parse(localStorage.getItem('idPaymentFromProposal'));

    // Tutorial
    this.store
      .select(selectShowTutorialPgto)
      .pipe(take(1))
      .subscribe((showTutorial: boolean) => {
        if (showTutorial) {
          this.tutorialService.showTutorial('payment');
        }
      });

    // Pegar o shopId atual

    if (this.idPaymentFromProposal === null) {
      this.getQuickFilterPayments();
      this.getListPayments(this.page, this.perPage);
      this.getValidationAccount();
    } else {
      this.searchString = this.idPaymentFromProposal;
      this.searchByInput();
      localStorage.setItem('idPaymentFromProposal', null);
    }
  }

  // Check for layout changes to determine how many pages to show on pagination
  private subscribeToLayoutChanges(): void {
    this.layoutChanges$ = this.breakpointObserver
      .observe(['(orientation: portrait)', '(orientation: landscape)'])
      .pipe(debounceTime(300))
      .subscribe(() => {
        const isSmallScreen = this.breakpointObserver.isMatched('(max-width: 768px)');
        this.paginationMaxSize = isSmallScreen ? 4 : 9;
      });
  }

  private getValidationAccount(): void {
    this.paymentsService.getValidationAccount().subscribe(res => {
      if (!res) {
        this.disabledPaymentRequest = true;
        this.openDialogInvalidData();
      }
    });
  }

  private getQuickFilterPayments(): void {
    this.shopId$
      .pipe(
        first(),
        switchMap(shopId => this.paymentsService.getFilterPayments(shopId, this.filterObj))
      )
      .subscribe(payments => {
        this.quickFilters = payments;
        const filtersAmountFiltred = payments.filter(
          item =>
            item.name.toUpperCase().trim() !== 'PROPOSTAS À VENCER' &&
            item.name.toUpperCase().trim() !== 'PROPOSTAS A EXPIRAR' &&
            item.name.toUpperCase().trim() !== 'TODAS PROPOSTAS'
        );
        this.allFiltersAmount = filtersAmountFiltred.map(payment => payment.amount).reduce((total, qtd) => total + qtd);
        this.quickFilters.forEach(filter => {
          filter['iconArrow'] = this.ordenationIconDown;
        });
      });
  }

  private getListPayments(page: number, perPage: number): void {
    this.shopId$
      .pipe(
        first(),
        switchMap(shopId => this.paymentsService.getListPayments(shopId, page, perPage, this.filterObj))
      )
      .subscribe(payments => {
        this.listPayments = payments.content;
        // paginação
        this.page = payments.page;
        this.perPage = payments.perPage;
        this.totalElements = payments.totalElements;
        this.totalPages = payments.totalPages;

        if (!!payments.content && !!payments.content.length) {
          // checkbox
          this.listPayments.forEach(item => {
            Object.assign(item, { checked: false });
          });
        }
        this.resetValues(payments);
        this.setIconPayment();
        this.hasProposalToExpire();
      });
  }

  public checkSelectAll(checked: boolean): void {
    this.allComplete = checked;
    this.listPayments.forEach(item => (item.checked = checked));
    this.checkedCountAndSum();
  }

  public updateCheckSelectAll(): void {
    this.allComplete = this.listPayments.every(item => item.checked);
    this.checkedCountAndSum();
  }

  public someChecked(): boolean {
    return this.listPayments.filter(item => item.checked).length > 0 && !this.allComplete;
  }

  private checkedCountAndSum(): void {
    const checkedFilter = this.listPayments.filter(item => item.checked);
    // checkedCount
    this.checkedCount = checkedFilter.length;
    // sumCheckedValues
    this.sumCheckedValues = 0;
    this.canEnableKebab = true;
    checkedFilter.forEach(item => {
      if (!!item.value) {
        this.sumCheckedValues = this.sumCheckedValues + item.value;
        this.canProposalEnableKebab(item.statusDescription);
      }
    });
  }

  public searchByInput(): void {
    if (!!this.searchString) {
      this.genTagger.setTag({
        event_category: this.categoria,
        event_action: Tag.Pesquisar + ' - ' + this.searchString,
        event_label: 'busque por nº, cpf, nome, valor'
      });

      this.filterObj = {
        ...this.filterObj,
        search: this.searchString
      };
      this.getListPaymentsByFilter();
      this.getQuickFilterPayments();
    }
  }

  public filterByStatus(statusName: string): void {
    if (statusName.length === 0) {
      delete this.filterObj.status;
      this.clickFilterAllProposals();
    } else {
      if (!this.filterObj.status) {
        this.filterObj.status = new Array();
      }

      const filter = this.filterObj.status.filter(item => item !== statusName);
      if (filter.length === this.filterObj.status.length) {
        this.filterObj.status.push(statusName);
      } else {
        this.filterObj.status = filter;
      }

      this.clickFilterProposals(statusName);
    }
    this.getListPaymentsByFilter();
  }

  private clickFilterAllProposals(): void {
    this.ordenationIconsAllProposals =
      this.ordenationIconsAllProposals === this.ordenationIconDown ? this.ordenationIconUp : this.ordenationIconDown;

    this.quickFilters.forEach(filterSel => {
      filterSel.iconArrow = this.ordenationIconDown;
    });
  }

  private clickFilterProposals(statusName: string): void {
    this.ordenationIconsAllProposals = this.ordenationIconDown;
    this.quickFilters.forEach(filterSel => {
      if (filterSel.name === statusName) {
        filterSel.iconArrow =
          filterSel.iconArrow === this.ordenationIconDown ? this.ordenationIconUp : this.ordenationIconDown;
      }
    });
  }

  private getListPaymentsByFilter(): void {
    this.shopId$
      .pipe(
        first(),
        switchMap(shopId =>
          this.paymentsService.getListPaymentsByFilter(shopId, this.initialPage, this.perPage, this.filterObj)
        )
      )

      .subscribe(payments => {
        this.listPayments = payments.content;
        // paginação
        this.page = payments.page;
        this.perPage = payments.perPage;
        this.totalElements = payments.totalElements;
        this.totalPages = payments.totalPages;

        if (!!payments.content && !!payments.content.length) {
          // checkbox
          this.listPayments.forEach(item => {
            Object.assign(item, { checked: false });
          });
        }
        this.resetValues(payments);
        this.setIconPayment();
        this.hasProposalToExpire();
      });
    this.firstLoad = false;
  }

  public loadPage(pageNumber: number): void {
    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: Tag.Click,
      event_label: `página - ${pageNumber}`
    });

    if (this.page !== null) {
      if (this.page > pageNumber) {
        this.genTagger.setTag({
          event_category: this.categoria,
          event_action: Tag.Click,
          event_label: 'página - anterior'
        });
      } else {
        this.genTagger.setTag({
          event_category: this.categoria,
          event_action: Tag.Click,
          event_label: 'página - próximo'
        });
      }
    }
    this.page = pageNumber;
    this.getListPayments(pageNumber, this.perPage);
  }

  openDialogSeller(link) {
    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: Tag.Click,
      event_label: 'filtrar vendedores'
    });

    const modalRef = this.modalService.open(SearchSellerDialogComponent, {
      windowClass: 'modal-filter'
    });
    if (this.filterObj.seller) {
      modalRef.componentInstance.selected = this.filterObj.seller;
    } else {
      modalRef.componentInstance.selected = [];
    }
    modalRef.componentInstance.routedBy = 'payments';
    modalRef.componentInstance.category = this.categoria;

    modalRef.result.then(
      result => {
        this.filterObj = {
          ...this.filterObj,
          seller: result,
          salesman: result.map(r => r.id)
        };
        this.getListPaymentsByFilter();
        this.getQuickFilterPayments();
      },
      reason => {
        console.log(reason);
      }
    );
  }

  private openDialogPaymentDetail(id: number): void {
    this.contentContainer.clear();
    const component = PaymentDetailDialogComponent;
    const componentRef = this.contentContainer.createComponent(this.cfr.resolveComponentFactory(component));
    componentRef.instance.id = id;
    componentRef.instance.disabledPaymentRequest = this.disabledPaymentRequest;
    componentRef.instance.callDialog.subscribe((value: string) => {
      if (value === 'payment-request') {
        this.openDialogPaymentRequest(id);
      } else if (value === 'proposal-cancel') {
        this.openDialogProposalCancel(id);
      }
    });
    this.sideContentService.updateSideContentTitle('SIDEBAR-DETAILS');
  }

  openDialogPeriod(link) {
    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: Tag.Click,
      event_label: 'período'
    });

    const modalRef = this.modalService.open(FilterPeriodDialogComponent, {
      windowClass: 'modal-filter'
    });
    modalRef.componentInstance.isLoadFilterPeriodStored = this.isLoadFilterPeriodStored;
    modalRef.componentInstance.category = this.categoria;
    modalRef.result.then(
      result => {
        this.filterObj = {
          ...this.filterObj,
          period: result.toString()
        };
        this.getListPaymentsByFilter();
        this.getQuickFilterPayments();
        this.isLoadFilterPeriodStored = true;
      },
      reason => {
        if (reason === 'cancelled') {
          this.cleanFilterObj();
        }
      }
    );
  }

  public cleanFilterObj() {
    this.searchString = '';
    this.filterObj = { period: '' };
    this.getQuickFilterPayments();
    this.getListPayments(this.page, this.perPage);
    this.isLoadFilterPeriodStored = false;
  }

  private resetValues(payments: any): void {
    // pagination
    this.page = payments.page;
    this.perPage = payments.perPage;
    this.totalElements = payments.totalElements;
    this.totalPages = payments.totalPages;
    // checkbox
    this.allComplete = false;
    this.checkedCount = 0;
    this.sumCheckedValues = 0;
  }

  public cleanFilterObjReloadList() {
    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: Tag.Click,
      event_label: 'limpar filtros'
    });

    this.searchString = '';
    this.filterObj = { period: '-6m' };
    this.paymentsService.restartOrdenationAfterCleanFilters(true);
    this.getListPayments(this.initialPage, this.perPage);
    this.getQuickFilterPayments();
  }

  public buttonListOrKebabAction({ button, id }: { button: string; id?: number }): void {
    this.valueButton = button;
    switch (button) {
      case PaymentButtonEnum.SHOW_DETAILS:
      case PaymentButtonEnum.SHOW_DETAILS_KEBAB:
        this.openDialogPaymentDetail(id);
        break;
      case PaymentButtonEnum.EMITBLETO:
        this.openDialogPaymentDetail(id);
        break;
      case PaymentButtonEnum.PAYMENT_REQUEST:
      case PaymentButtonEnum.PAYMENT_REQUEST_AGAIN:
        this.openDialogPaymentRequest(id);
        break;
      case PaymentButtonEnum.PAYMENT_MULTIPLE_REQUEST:
        this.openDialogPaymentMultipleRequest(this.listPayments);
        break;
      case PaymentButtonEnum.PROPOSAL_EXTRACT:
        this.openDialogProposalExtract();
        break;
      case PaymentButtonEnum.PAYMENT_MASSIVE_UPLOAD:
        this.openDialogPaymentMassiveUpload();
        break;
      case PaymentButtonEnum.PROPOSAL_CANCEL:
        this.openDialogProposalCancel(id);
        break;
      case PaymentButtonEnum.PAYMENT_MASSIVE_CANCEL:
        this.openDialogProposalMassiveCancel();
        break;
      case PaymentButtonEnum.REQUEST_PAYMENT:
        this.openDialogPaymentRequest(id);
        break;
      case PaymentButtonEnum.REQUEST_SCHEDULING:
        this.openDialogPaymentRequest(id);
        break;
      default:
        console.log('not found - failed to load', button);
    }
  }

  private openDialogPaymentRequest(id: number): void {
    const payment = this.listPayments.find(p => p.id === id);
    if (!!payment) {
      const modalRef = this.dialog.open(PaymentRequestDialogComponent, {
        width: '560px',
        autoFocus: false,
        data: {
          id: id,
          userName: payment.user,
          value: payment.valuePrincipal,
          amountParcels: payment.amountParcels,
          valueParcels: payment.valueParcels,
          planCustom: payment.planCustom,
          typeOfRequest: this.valueButton
        }
      });
      modalRef.componentInstance.reloadListPayments.subscribe((reloadListPayments: boolean) => {
        if (!!reloadListPayments) {
          this.contentContainer.clear();
          this.getListPayments(this.page, this.perPage);
        }
      });
    }
  }

  private openDialogPaymentMultipleRequest(listPayments): void {
    const checkedFilter = listPayments.filter((item: PaymentShowcaseModel) => item.checked);
    let reloadListPayments = false;
    if (!!checkedFilter && !!checkedFilter.length) {
      const modalRef = this.modalService.open(PaymentMultipleRequestComponent, {
        windowClass: 'modal-payment-multiple-request'
      });
      modalRef.componentInstance.data = checkedFilter;
      modalRef.componentInstance.category = this.categoria;
      modalRef.componentInstance.data = JSON.parse(JSON.stringify(checkedFilter));
      modalRef.componentInstance.reloadListPayments.subscribe((value: boolean) => {
        reloadListPayments = value;
      });

      modalRef.result.then(
        result => {},
        reason => {
          if (!!reloadListPayments) {
            this.getListPayments(this.page, this.perPage);
          }
        }
      );
    }
  }

  private openDialogProposalExtract(): void {
    this.shopId$.pipe(first()).subscribe(shopId => {
      this.dialog.open(ProposalExtractDialogComponent, {
        width: '560px',
        autoFocus: false,
        data: {
          shopId,
          filter: this.filterObj
        }
      });
    });
  }

  private openDialogPaymentMassiveUpload(): void {
    this.dialog
      .open(PaymentMassiveUploadDialogComponent, {
        width: '661px',
        autoFocus: false
      })
      .afterClosed()
      .subscribe((value: any) => {
        if (value === 'error-upload') {
          this.openDialogPaymentMassiveUploadStatus(value);
        } else {
          this.openDialogPaymentMultipleRequest(value);
        }
      });
  }

  private openDialogPaymentMassiveUploadStatus(status): void {
    this.dialog
      .open(PaymentMassiveUploadStatusDialogComponent, {
        width: '560px',
        autoFocus: false,
        data: status
      })
      .afterClosed()
      .subscribe((value: string) => {
        if (value === 'retry-upload') {
          this.openDialogPaymentMassiveUpload();
        }
      });
  }

  private openDialogProposalCancel(id: number) {
    const proposal = this.listPayments.find(p => p.id === id);
    if (!!proposal) {
      const ref = this.dialog.open(ProposalCancelDialogComponent, {
        width: '560px',
        autoFocus: false,
        data: {
          id: id,
          userName: proposal.user,
          value: proposal.value,
          amountParcels: proposal.amountParcels,
          valueParcels: proposal.valueParcels,
          routedBy: 'payments'
        }
      });
      ref.componentInstance.category = this.categoria;
      ref.componentInstance.okExit.subscribe(() => {
        this.contentContainer.clear();
        this.getListPayments(this.page, this.perPage);
      });
    }
  }

  private openDialogProposalMassiveCancel(): void {
    const checkedFilter = this.listPayments.filter((item: PaymentShowcaseModel) => item.checked);
    let reloadListPayments = false;
    if (!!checkedFilter && !!checkedFilter.length) {
      const modalRef = this.modalService.open(ProposalMassiveCancelDialogComponent, {
        windowClass: 'modal-proposal-massive-cancel'
      });
      modalRef.componentInstance.data = checkedFilter;
      modalRef.componentInstance.category = this.categoria;
      modalRef.componentInstance.data = JSON.parse(JSON.stringify(checkedFilter));
      modalRef.componentInstance.reloadListPayments.subscribe((value: boolean) => {
        reloadListPayments = value;
      });
      modalRef.result.then(
        result => {},
        reason => {
          if (!!reloadListPayments) {
            this.getListPayments(this.page, this.perPage);
          }
        }
      );
    }
  }

  private openDialogInvalidData(): void {
    this.dialog.open(PaymentInvalidDataDialogComponent, {
      width: '560px',
      autoFocus: false
    });
  }

  public maxSizePagination() {
    return window.screen.width < 576 ? 5 : 10;
  }

  public toggleFilters(): void {
    this.isFilterOpened = !this.isFilterOpened;
  }

  public orderProposals(values: any): void {
    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: Tag.Selecionar + ' - ' + values[0].columnNameLabel,
      event_label: values[0].columnNameLabel
    });

    this.filterObj = {
      ...this.filterObj,
      sort: values
    };
    this.paymentsService.restartOrdenationAfterCleanFilters(false);
    this.getListPaymentsByFilter();
  }

  public openDialogProposalOrdenation(): void {
    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: Tag.AbriuModal + ' - ordenar por',
      event_label: 'ordenar por'
    });

    const modalRef = this.modalService.open(FilterOrdenationDialogComponent, {
      windowClass: 'modal-filter-ordenation'
    });
    modalRef.componentInstance.category = this.categoria;
    modalRef.componentInstance.selected =
      !!this.filterObj.sort && !!this.filterObj.sort.length ? this.filterObj.sort[0].sort : '';
    modalRef.result.then(
      result => {
        if (!!result && !!result.sort) {
          this.orderProposals(result);
        }
      },
      reason => {}
    );
  }

  public selectedFilter(name) {
    if (this.filterObj.status && this.filterObj.status.length) {
      return this.filterObj.status.some(item => item === name);
    }
  }

  private setIconPayment(): void {
    this.listPayments.forEach(payment => {
      payment['iconStatusPayment'] = this.getIconStatusbyDescription(payment.statusDescription);
    });
  }

  private getIconStatusbyDescription(statusDescription: string): string {
    let statusDescriptionReturn = '';
    if (statusDescription !== undefined && statusDescription !== '') {
      switch (statusDescription.toUpperCase().trim()) {
        case PaymentStatusEnum.PAYMENT_PENDING_TARRIF:
          statusDescriptionReturn = PaymentIconStatusEnum.PAYMENT_PENDING_TARRIF;
          break;
        case PaymentStatusEnum.PAYMENT_ERROR:
          statusDescriptionReturn = PaymentIconStatusEnum.PAYMENT_ERROR;
          break;
        case PaymentStatusEnum.PAYMENT_AVAILABLE:
          statusDescriptionReturn = PaymentIconStatusEnum.PAYMENT_AVAILABLE;
          break;
        case PaymentStatusEnum.PAYMENT_IN_RELEASE:
          statusDescriptionReturn = PaymentIconStatusEnum.PAYMENT_IN_RELEASE;
          break;
        case PaymentStatusEnum.PAYMENT_PROPOSAL_PAID:
          statusDescriptionReturn = PaymentIconStatusEnum.PAYMENT_PROPOSAL_PAID;
          break;
        case PaymentStatusEnum.PAYMENT_PROPOSAL_CANCEL:
          statusDescriptionReturn = PaymentIconStatusEnum.PAYMENT_PROPOSAL_CANCEL;
          break;
        default:
          statusDescriptionReturn = PaymentIconStatusEnum.PAYMENT_PENDING_TARRIF;
          break;
      }
    }
    return statusDescriptionReturn;
  }

  private hasProposalToExpire(): void {
    this.hasProposalExpiring = false;
    this.listPayments.forEach(payment => {
      if (payment.expiringDays <= 10 && payment.expiringDays >= 0 && !payment.showClock) {
        this.hasProposalExpiring = true;
      }
    });
  }

  private canProposalEnableKebab(statusDescription: string): void {
    if (statusDescription !== undefined && statusDescription !== '') {
      switch (statusDescription.toUpperCase().trim()) {
        case PaymentStatusEnum.PAYMENT_PENDING_TARRIF:
        case PaymentStatusEnum.PAYMENT_PROPOSAL_PAID:
        case PaymentStatusEnum.PAYMENT_IN_RELEASE:
          this.canEnableKebab = false;
          break;
        default:
          break;
      }
    }
  }

  onClickFinancialStatement() {
    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: Tag.Click,
      event_label: 'visualizar extrato'
    });
  }
}
