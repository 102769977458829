<div
  class="form-content"
  *ngIf="(showForm && showQuestions) || (accessType === lgpdConsentAccessType.PROFILE && showForm)"
>
  <div class="form-group">
    <div class="titleLgpd">
      <h3 [ngClass]="{ 'font-weight-bold': isIntegraProposal }">{{ 'LGPD-TITLE' | translate }}</h3>
    </div>
    <div *ngIf="!isIntegraProposal" class="shopkeeper-msg" innerText="{{ shopkeeperAlertMessage | translate }}"></div>
    <div class="paramFirstLine">{{ parameterFirstLineCompanyOffer }}</div>
    <div class="paramSecondLine">{{ parameterSecondLineCompanyOffer }}</div>
    <div class="row">
      <mat-radio-group [(ngModel)]="answerCompanyOffer" (change)="radioChange()">
        <mat-radio-button class="col-6" value="S">{{ 'Yes' | translate }}</mat-radio-button>
        <mat-radio-button class="col-6" value="N">{{ 'No' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="paramFirstLine">{{ parameterFirstLineSantanderGroupOffer }}</div>
    <div class="paramSecondLine">{{ parameterSecondLineSantanderGroupOffer }}</div>
    <div class="row">
      <mat-radio-group [(ngModel)]="answerGroupOffer" (change)="radioChange()">
        <mat-radio-button class="col-6" value="S">{{ 'Yes' | translate }}</mat-radio-button>
        <mat-radio-button class="col-6" value="N">{{ 'No' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>
    <div class="paramFirstLine">{{ parameterFirstLinePartnerOffer }}</div>
    <div class="paramSecondLine">{{ parameterSecondLinePartnerOffer }}</div>
    <div class="row">
      <mat-radio-group [(ngModel)]="answerPartnerOffer" (change)="radioChange()">
        <mat-radio-button class="col-6" value="S">{{ 'Yes' | translate }}</mat-radio-button>
        <mat-radio-button class="col-6" value="N">{{ 'No' | translate }}</mat-radio-button>
      </mat-radio-group>
    </div>
  </div>
</div>
