import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { SubmitLogoutAction } from '@app/core/state/login/login.actions';
import { GetClientCredentialsAction } from '@app/core/state/startup/startup.actions';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IdleDetectService {
  private isSessionExpired = false;
  private timer = null;
  private timeInSeconds = Number(environment.idleTimer) || 60000;
  public openComponents: Array<any> = [];
  public viewContainer = null;

  idleDetector: Subject<boolean>;

  constructor(
    private sessionStorageService: SessionStorageService,
    private store$: Store<AppState>,
    private dialog: MatDialog
  ) {
    this.idleDetector = new Subject();
  }

  watcher() {
    return this.idleDetector.asObservable();
  }

  startTimer(timeInSeconds: number) {
    this.timeInSeconds = timeInSeconds;

    if (this.timer) {
      this.stopTimer();
    }

    this.timer = setTimeout(() => {
      console.log('Sessão Expirada...');
      this.isSessionExpired = true;

      // const token = this.sessionStorageService.getUser().accessToken;
      // this.store$.dispatch(new RevokeToken(token));

      this.dialog.closeAll();
      this.idleDetector.next(this.isSessionExpired);
      this.store$.dispatch(new GetClientCredentialsAction());
      this.store$.dispatch(new SubmitLogoutAction());
    }, this.timeInSeconds);
  }

  resetTimer() {
    if (!this.isSessionExpired) {
      this.isSessionExpired = false;
      this.stopTimer();
      this.startTimer(this.timeInSeconds);
    }
  }

  stopTimer() {
    clearTimeout(this.timer);
  }
}
