<div class="biometry-section">
  <owl-carousel-o [options]="carouselOptions" #instructionsCarousel (changed)="changeOnSlider($event)">
    <ng-container>
      <ng-container *ngFor="let step of tutorial?.steps; index as i">
        <ng-template carouselSlide [id]="i === 0 ? 'initial' : i">
          <app-card-step [title]="step.text" [description]="step.description" [img]="step.img"></app-card-step>
        </ng-template>
      </ng-container>
    </ng-container>
  </owl-carousel-o>

  <div class="row">
    <ng-container *ngIf="hideNext === false; else elseif1">
      <div class="col-12">
        <button class="carousel-next" (click)="instructionsCarousel.next()" [disabled]="tutorialNextButtonDisabled">
          {{ 'FORMALIZATION-CONDITION-CARD-SIGN-PROPOSAL' | translate }}
        </button>
      </div>
    </ng-container>
    <ng-template #elseif1>
      <ng-container *ngIf="hideNext === null; else elseif2">
        <div class="col-6">
          <button
            class="carousel-prev"
            (click)="instructionsCarousel.prev()"
            appTagger
            [event_category]="category"
            event_label="{{ 'Previous' | translate }}"
          >
            {{ 'Previous' | translate }}
          </button>
        </div>
        <div class="col-6">
          <button
            (click)="handleFinishButton()"
            class="carousel-next"
            [disabled]="isFinishButtonDisabled || tutorialNextButtonDisabled"
          >
            {{
              (tutorial?.type === biometryStepsTypeEnum.BIOMETRY
                ? 'FORMALIZATION-BIOMETRY-BTN'
                : 'FORMALIZATION-SIGNATURE-BTN') | translate
            }}
          </button>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #elseif2>
      <ng-container *ngIf="hidePrev === true && hideNext === true; else elseif3">
        <div class="col-6">
          <button
            class="carousel-prev"
            (click)="instructionsCarousel.prev()"
            appTagger
            [event_category]="category"
            event_label="{{ 'Previous' | translate }}"
          >
            {{ 'Previous' | translate }}
          </button>
        </div>
        <div class="col-6">
          <button
            class="carousel-next"
            [disabled]="tutorialNextButtonDisabled"
            (click)="instructionsCarousel.next()"
            appTagger
            [event_category]="category"
            event_label="{{ 'Next' | translate }}"
          >
            {{ 'Next' | translate }}
          </button>
        </div>
      </ng-container>
    </ng-template>

    <ng-template #elseif3>
      <div [ngClass]="hidePrev ? 'col-6' : 'col-12'">
        <button
          class="carousel-next"
          [disabled]="tutorialNextButtonDisabled"
          (click)="instructionsCarousel.next()"
          appTagger
          [event_category]="category"
          event_label="{{ 'Next' | translate }}"
        >
          {{ 'Next' | translate }}
        </button>
      </div>
    </ng-template>
  </div>
</div>
