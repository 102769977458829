import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TranslateService } from '@ngx-translate/core';
import { CalendarDialogComponent } from '@shared/components/ui/calendar-dialog/calendar-dialog.component';
import { CalendarConfig } from '@shared/components/ui/calendar/calendar.model';
import { BrazilMasks } from '@shared/interfaces/masks/brazil-masks.interface';
import { Product } from '@shared/models/product.model';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { debounceTime, delay, filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-minimum-data',
  templateUrl: './minimum-data.component.html',
  styleUrls: ['./minimum-data.component.scss']
})
export class MinimumDataComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() products: Product[];
  public minimumData: FormGroup;
  private product: FormGroup;
  public masks = this.internationalizationService.pioneerMasks as BrazilMasks;
  public showMaskBirthFoundation = false;
  public showMasktravelDate = false;
  public documentNumberMask: string;
  public phoneMask: string;
  public showMaskTypedForDocument = true;

  readonly category = '/portallojista/criarproposta';
  readonly Insert: Tag = Tag.Insert;
  readonly Selecionar: Tag = Tag.Selecionar;

  public readonly customPatterns = { 0: { pattern: /\d/ }, 9: { pattern: /\d/ } };

  constructor(
    private internationalizationService: InternationalizationService,
    public dialog: MatDialog,
    private genTagger: GenTagger,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.minimumData = this.form.get('minimumData') as FormGroup;
    this.product = this.form.get('product') as FormGroup;
    this.handleDocumentNumberMask();
    this.handlePhoneMask();

    this.minimumData.controls['documentNumber'].valueChanges.pipe(debounceTime(1000)).subscribe(data => {
      const cpfcnpj = this.minimumData.controls['documentNumber'].valid;
      if (cpfcnpj) {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: Tag.Insert,
          event_label: this.translate.instant('CpfOrCnpj')
        });
      } else {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: Tag.FillingError,
          event_label: this.translate.instant('CpfOrCnpj-INPUT-ERROR-MSG')
        });
      }
    });

    this.minimumData.controls['date'].valueChanges.pipe(debounceTime(1000)).subscribe(data => {
      const date = this.minimumData.controls['date'].valid;
      if (date) {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: Tag.Insert,
          event_label: this.translate.instant('DATE-OF-BIRTH-FOUNDATION')
        });
      }
    });

    this.minimumData.controls['phone'].valueChanges.pipe(debounceTime(1000)).subscribe(data => {
      const phone = this.minimumData.controls['phone'].valid;
      if (phone) {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: Tag.Insert,
          event_label: this.translate.instant('Telephone')
        });
      }
    });

    this.minimumData.controls['financedValue'].valueChanges.pipe(debounceTime(1000)).subscribe(data => {
      const financedValue = this.minimumData.controls['financedValue'].valid;
      if (financedValue) {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: Tag.Insert,
          event_label: this.translate.instant('FINANCED-VALUE-INPUT')
        });
      }
    });

    this.minimumData.controls['entryValue'].valueChanges.pipe(debounceTime(1000)).subscribe(data => {
      const entryValue = this.minimumData.controls['entryValue'].valid;
      if (entryValue) {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: Tag.Insert,
          event_label: this.translate.instant('ENTRY-VALUE-INPUT')
        });
      }
    });

    this.minimumData.controls['travelDate'].valueChanges.pipe(debounceTime(1000)).subscribe(data => {
      const travelDate = this.minimumData.controls['travelDate'].valid;
      if (travelDate) {
        this.genTagger.setTag({
          event_category: this.category,
          event_action: Tag.Insert,
          event_label: this.translate.instant('TRAVEL-DATE')
        });
      }
    });
  }

  private handleDocumentNumberMask(): void {
    this.minimumData
      .get('documentNumber')
      .valueChanges.pipe(
        delay(1),
        filter(val => !!val)
      )
      .subscribe(value => {
        this.showMaskTypedForDocument = value.length !== 11;

        if (value.length > 11) {
          this.documentNumberMask = this.masks.documentNumber.companyMask;
        } else {
          this.documentNumberMask = this.masks.documentNumber.mask;
        }
      });
  }

  private handlePhoneMask(): void {
    this.minimumData
      .get('phone')
      .valueChanges.pipe(
        delay(1),
        filter(val => !!val)
      )
      .subscribe(value => {
        if (value[0] === '0' || value[2] === '0' || value.length < 10) {
          this.minimumData.get('phone').setErrors({ incorrect: true });
        } else {
          this.minimumData.get('phone').setErrors(null);
        }

        if (value.length < 11) {
          this.phoneMask = this.masks.phone.mainPhoneMask;
        } else {
          this.phoneMask = this.masks.phone.mask;
        }
      });
  }

  public openDialogCalendar(typeCalendar: string): void {
    let refDialogCalendar;

    if (typeCalendar === 'birth') {
      refDialogCalendar = this.dialog.open(CalendarDialogComponent, {
        disableClose: true,
        position: { top: '100px', left: '35%' },
        data: {
          startYear: new Date().getFullYear() - 100,
          endYear: new Date().getFullYear(),
          isTruncateDays: { toFront: true }
        } as CalendarConfig
      });
    } else if (typeCalendar === 'travel') {
      refDialogCalendar = this.dialog.open(CalendarDialogComponent, {
        disableClose: true,
        position: { top: '100px', left: '35%' },
        data: {
          isTruncateDays: { toBack: true },
          isTruncateYears: true
        } as CalendarConfig
      });
    }

    refDialogCalendar
      .afterClosed()
      .pipe(take(1))
      .subscribe((date: string) => {
        if (typeCalendar === 'birth') {
          this.minimumData.get('date').patchValue(date);
        } else if (typeCalendar === 'travel') {
          this.minimumData.get('travelDate').patchValue(date);
        }
      });
  }

  public handleSelectedProduct(): string {
    const selectedProduct =
      this.product && this.product.value && !!this.product.value.code ? this.product.value.code.toLowerCase() : '';

    return !!selectedProduct && this.products && this.products.length > 1 ? selectedProduct : 'group-normal';
  }
}
