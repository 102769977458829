import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { SetAddressDataSection } from '@app/core/state/proposal-information/proposal-information.actions';
import * as proposalInformationSelect from '@app/core/state/proposal-information/proposal-information.selectors';
import {
  AddressByZipCode,
  AddressSignUpData,
  ClientSignUpData,
  GetProposalInformationDTO,
  ProposalParam,
  ProposalParameters,
  ProposalSignUpData
} from '@app/proposal-workflow/models/proposal-data.model';
import { AddressTypeEnum } from '@app/proposal-workflow/models/step-register-put.enum';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { BrazilValidators } from '@shared/interfaces/validators/brazil-validators.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-address-data',
  templateUrl: './address-data.component.html',
  styleUrls: ['./address-data.component.scss']
})
export class AddressDataComponent implements OnInit, OnDestroy, OnChanges {
  protected ngUnsubscribe: Subject<any> = new Subject();

  @Input() public pos: { pos: number; size: number };
  @Input() public comboParams: ProposalParameters;
  @Output() public toStart = new EventEmitter<FormGroup>();
  @Input() public setPersistedData: Observable<GetProposalInformationDTO>;
  public proposalDataRes: GetProposalInformationDTO;

  public proposal: ProposalSignUpData;
  public cities$: Observable<ProposalParam[]>;
  public title: string;
  public focusItems = {};

  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  form: FormGroup;

  // initialize variables the masking
  public maskZipCode = this.pioneerMasks.zipCode.mask;
  public maskNumber = '00000';

  // Tag
  public Insert: Tag = Tag.Insert;
  public Select: Tag = Tag.Selecionar;
  public event_category: string;
  private previousState: string;
  private city: any;

  constructor(
    private internationalizationService: InternationalizationService,
    private simulationService: SimulationService,
    private genTagger: GenTagger,
    private store$: Store<AppState>,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.buildForm();
    this.toStart.emit(this.form);
    this.getProposalData();
    this.setAddressDataFormSubscription();
    this.setMaskZipCodeSubscription();
    this.valuesFormsSubscribe();
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (!!this.form) {
      this.toStart.emit(this.form);
    }
  }

  public buildForm() {
    const validatorsBr = this.pioneerValidators as BrazilValidators;

    this.form = new FormGroup({
      zipCode: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      streetTypeId: new FormControl(null),
      street: new FormControl(null, validatorsBr.street),
      number: new FormControl(null, [Validators.required, Validators.minLength(1), Validators.maxLength(5)]),
      complement: new FormControl(null, validatorsBr.complement),
      neighborhood: new FormControl(null, validatorsBr.neighborhood),
      state: new FormControl(null, Validators.required),
      cityId: new FormControl(null, Validators.required),
      mainFlag: new FormControl(null),
      clientId: new FormControl(null),
      id: new FormControl(null),
      type: new FormControl(null)
    });
  }

  public valuesFormsSubscribe(): void {
    this.setPersistedData.subscribe(res => {
      if (!!res) {
        this.proposalDataRes = res;
        if (
          !(
            !!this.proposalDataRes.clients[0].person.addresses &&
            this.proposalDataRes.clients[0].person.addresses.length
          )
        ) {
          this.form.reset();
          this.toStart.emit(this.form);
          return;
        }
        this.setValueRecovered();
      }
      this.toStart.emit(this.form);
    });
  }

  protected checkIfYouAreaLegalEntity(): ClientSignUpData{
    if(this.proposalDataRes.clients.length > 1){
      return this.proposalDataRes?.clients.find(J => J.person.type === 'J');
    }
    return this.proposalDataRes?.clients.find(F => F.person.type === 'F');
  }

  public setValueRecovered(): void {
    const address = this.checkIfYouAreaLegalEntity();
      if (!!address) {
        if(address.person?.addresses.length){
        this.form.patchValue({ number: null });
        this.form.patchValue({ zipCode: null });
        this.form.patchValue(address.person.addresses[0]);
        }
      }

    if (this.form.valid) {
      this.updateAddressData();
    }
  }
  public getProposalData() {
    this.store$
      .select(proposalInformationSelect.getSimulationProposalData)
      .pipe(
        filter((data: ProposalSignUpData) => !!data && Object.values(data).some(item => !!item)),
        tap(data => {
          if (data.productCode === ProductCodeEnum.CDC) {
            this.event_category = '/portallojista/criarproposta/cdc/cadastro';
          } else if (data.productCode === ProductCodeEnum.CSC) {
            this.event_category = '/portallojista/criarproposta/csc/cadastro';
          } else if (data.productCode === ProductCodeEnum.CSCP) {
            this.event_category = '/portallojista/criarproposta/cscp/cadastro';
          }

          this.title =
            data.mainClient.person.type === SubmitPersonType.PF
              ? 'STEP-REGISTER-INTRODUTION-ADDRESS'
              : 'STEP-REGISTER-INTRODUTION-COMPANY-ADDRESS';

          this.proposal = data;

          this.form.get('clientId').patchValue(data.mainClient.clientId);
          this.form
            .get('type')
            .patchValue(
              data.mainClient.person.type === SubmitPersonType.PF
                ? AddressTypeEnum.RESIDENTIAL
                : AddressTypeEnum.COMMERCIAL
            );
          if (data.mainClient.person.addresses && data.mainClient.person.addresses.length) {
            this.maskZipCode = this.pioneerMasks.zipCode.maskBack;
            this.form.patchValue(data.mainClient.person.addresses[0]);
          }
        }),
        first()
      )
      .subscribe(() => {
        if (this.form.get('state').valid) {
          this.getCities();
        }

        if (this.form.valid) {
          this.updateAddressData();
        }

        this.maskNumber = 'AAAAA';
        this.getSectionData();
      });
  }

  public getSectionData() {
    this.store$
      .select(proposalInformationSelect.addressDataSection)
      .pipe(
        filter((data: AddressSignUpData[]) => !!data.length),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(data => {
        data[0].zipCode = data[0].zipCode.substr(0, 5) + '-' + data[0].zipCode.substr(5, 7);
        this.form.patchValue({ id: data[0].id });
        if (this.form.get('state').valid) {
          this.getCities();
        }
      });
  }

  public getSectionDataAddressID() {
    this.store$
      .select(proposalInformationSelect.addressDataSectionId)
      .pipe(
        filter((data: number) => !!data),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(idAddress => {
        this.form.patchValue({ id: idAddress });
      });
  }

  private setMaskZipCodeSubscription() {
    this.form
      .get('zipCode')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        if (!!value && value.length < 8) {
          this.maskZipCode = this.pioneerMasks.zipCode.mask;
        }
      });
  }

  public checkNumber(): void {
    const value = this.form.get('number').value.replace(/\D/, '');
    this.form.get('number').setValue(value);
  }

  private setAddressDataFormSubscription(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        debounceTime(1000),
        distinctUntilChanged((prev, current) => JSON.stringify(prev) === JSON.stringify(current)),
        filter(() => this.form.valid)
      )
      .subscribe(() => {
        if (this.form.valid) {
          this.updateAddressData();
        }
      });
  }

  private updateAddressData(): void {
    let data: AddressSignUpData = this.form.getRawValue();
    this.cities$.subscribe(cities => {
      const city = cities.find(c => c.id === data.cityId);
      data = {
        ...data,
        city: city.description,
        clientId: this.proposal.mainClient.clientId,
        proposalId: this.proposal.id,
        type: AddressTypeEnum.COMMERCIAL
      };
      this.store$.dispatch(new SetAddressDataSection([data]));
    });
  }

  public getAddressByZipCode() {
    if (!!this.form.controls.zipCode.value) {
      this.simulationService
        .getAddressByZipCode(this.form.controls.zipCode.value)
        .pipe(first())
        .subscribe((data: AddressByZipCode) => {
          if(data.fgInexis === 'S') {
            this.dialog.open(PionModalComponent, {
              data: {
                title: "Atenção",
                description: "CEP não encontrado",
                confirmText: "Ok, eu entendi",
                type: 'error',
              }
            })

            this.form.controls.zipCode.setErrors({'not_found': true})

          } else if (!!data && !!data.zipCode) {
            // tslint:disable-next-line: no-shadowed-variable
            const { address: street, addressType: streetTypeId, zipCode, neighborhood, fgInexis, state, cityId } = data;
            const formData = {
              street,
              streetTypeId,
              zipCode,
              neighborhood,
              state,
              cityId
            };
            const oldFormValue = this.form.value;
            this.form.reset();
            this.form.patchValue({ ...oldFormValue, ...formData });
            this.onFieldChange('street', 'endereço');
            this.onFieldChange('neighborhood', 'bairro');
            if (data.zipCode.length < 8) {
              this.form.controls.zipCode.setValue(data.zipCode.padStart(9, '0'));
            }
            this.form.controls['streetTypeId'].setValue(null);
            this.getCities();
          } else {
            this.form.controls.zipCode.setValue(this.form.controls.zipCode.value.padStart(9, '0'));
          }
        });
    }
  }

  public getCities() {
    const state = this.form.controls.state.value;
    if (this.previousState !== state) {
      this.cities$ = this.simulationService.getCities(state).pipe(
        first(),
        distinctUntilChanged()
      );
    }
    this.previousState = state;
  }

  public checkFocus(key: string): void {
    if (!this.focusItems[key]) {
      this.form.get(key).reset();
      this.focusItems[key] = true;
    }
  }

  public onStateChange() {
    this.getCities();
    const value = this.form.get('state').value;
    const state = this.comboParams.states.find(p => p.id === value);
    this.genTagger.setTag({
      event_action: `${this.Select} - estado`,
      event_category: this.event_category,
      event_label: state.description.toLowerCase()
    });

    this.onCityChange();
  }

  public onCityChange() {
    let city: ProposalParam;
    const value = this.form.get('cityId').value;
    this.cities$.subscribe(cities => {
      city = cities.find(c => c.id === value);
      if (!!city) {
        this.genTagger.setTag({
          event_action: `${this.Select} - cidade`,
          event_category: this.event_category,
          event_label: city.description.toLowerCase()
        });
      }
      this.city = city?.description;
      if (this.form.valid) {
        this.updateAddressData();
      }
    });
  }

  public onFieldChange(field: string, label: string) {
    const value = this.form.get(field).value;

    if (!!value) {
      this.genTagger.setTag({
        event_action: `${this.Insert}`,
        event_category: this.event_category,
        event_label: label
      });
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
