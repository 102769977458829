import { DatePipe } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTabsModule } from '@angular/material/tabs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TaggerInterceptor } from '@app/tagging/tagger.interceptor';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AlertModule } from '@shared/components/widgets/alert/alert.module';
import { AlertService } from '@shared/components/widgets/alert/alert.service';
import { LoadingModule } from '@shared/components/widgets/loading/loading.module';
import { IdleDetectDirective } from '@shared/directives/idle-detect.directive';
import { InvalidBrowserDetectDirective } from '@shared/directives/invalid-browser-detect.directive';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CertifiedAgentModule } from './certified-agent/certified-agent.module';
import { ContractsModule } from './contracts/contracts.module';
import { CoreModule } from './core/core.module';
import { CryptIntercptor } from './core/crypto/crypto.interceptor';
import { UrlInterceptor } from './core/intercpeptor/url.interceptor';
import { StartupModule } from './core/startup/startup.module';
import { StateModule } from './core/state/state.module';
import { FaqModule } from './faq/faq.module';
import { IdentificationModule } from './identification/identification.module';
import { LoginModule } from './login/login.module';
import { NotFoundModule } from './not-found/not-found.module';
import { ProductsModule } from './products/products.module';
import { ProfileModule } from './profile/profile.module';
import { SharedModule } from './shared/shared.module';
import { ShowcaseModule } from './showcase/showcase.module';
import { SignUpModule } from './signup/signup.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

declare global {
  interface Navigator {
    msSaveBlob?: (blob: any, defaultName?: string) => boolean;
  }
}

@NgModule({
  declarations: [AppComponent, IdleDetectDirective, InvalidBrowserDetectDirective],
  imports: [
    HttpClientModule,
    BrowserAnimationsModule,
    CoreModule,
    SharedModule,
    StateModule,
    AppRoutingModule,
    LoadingModule,
    AlertModule,
    NotFoundModule,
    FaqModule,
    ProfileModule,
    ShowcaseModule,
    ProductsModule,
    ContractsModule,
    LoginModule,
    SignUpModule,
    CertifiedAgentModule,
    MatExpansionModule,
    MatSidenavModule,
    MatRadioModule,
    MatButtonModule,
    MatTabsModule,
    MatCardModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    DeviceDetectorModule,
    StartupModule,
    IdentificationModule
  ],
  exports: [BrowserAnimationsModule],
  providers: [
    AlertService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UrlInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CryptIntercptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TaggerInterceptor,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  constructor() {}
}
