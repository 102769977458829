import { AppState } from '@app/core/state';
import { Store } from '@ngrx/store';
import * as fromManagementFilters from '@app/showcase/management-panel/components/management-filters/state/';

export const clearManagementPanelFiltersFromHeaderNavigation = (targetAddress: string, store$: Store<AppState>) => {
  const routesToClearFilters = ['/showcase/open-proposal', '/showcase/management'];

  if (routesToClearFilters.includes(targetAddress)) {
    store$.dispatch(new fromManagementFilters.actions.ClearAllFilters());
    store$.dispatch(
      new fromManagementFilters.actions.UpdateShouldPassFiltersToProposalPanel(false, {
        statusFunil: null,
        statusPG: null
      })
    );
    localStorage.removeItem('mgm_managementFilters');
  }
};
