import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';

@Component({
  selector: 'app-new-header',
  templateUrl: './new-header.component.html',
  styleUrls: ['./new-header.component.scss']
})
export class NewHeaderComponent implements OnInit {
  public category = '/portallojista/login';
  public currentUrl: string;
  constructor(public router: Router, private _location: Location, private genTagger: GenTagger) {
    let arrUrl = this.router.url.split('/');
    this.router.events.subscribe(() => {
      arrUrl = router.url.split('/');
      this.currentUrl = arrUrl[arrUrl.length - 1];
    });
    this.currentUrl = arrUrl[arrUrl.length - 1];
  }

  ngOnInit() {
    this._location.back();

    if (window.location.href.includes('recover-password') === true) {
      this.category = '/portallojista/recuperarsenha';
    } else if (window.location.href.includes('primary-access') === true) {
      this.category = '/portallojista/primeiroacesso';
    } else {
      this.category = '/portallojista/login';
    }

  }

  redirectIdentification() {
    const url = 'https://www.santander.com.br/form/credenciamento';
    window.open(url, '_blank');
  }

  goBack() {
    this._location.back();

    if (window.location.href.includes('recover-password') === true) {
      this.category = '/portallojista/recuperarsenha';
    } else if (window.location.href.includes('primary-access') === true) {
      this.category = '/portallojista/primeiroacesso';
    } else {
      this.category = '/portallojista/login';
    }

    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.Click,
      event_label: 'voltar'
    });
  }
}
