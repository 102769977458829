<div class="container mt-5" *ngIf="!showSummary">
  <!-- BIOMETRY SECTION -->
  <div [ngClass]="!!biometryChecklist?.length ? 'min-height' : ''">
    <div class="mb-5" *ngIf="isCrossedFlux && showIntegraLgpdFlag && proposal && proposalConditionsAccepted">
      <app-lgpd-form #lgpdForm [accessType]="lgpdConsentAccessType.PROPOSAL_REGISTER"
        [documentNumber]="proposal?.customer?.documentNumber" [isIntegraProposal]="showIntegraLgpdFlag"
        (lgpdAnsweredToRegister)="onFormLgpdAnswered()" (loaded)="onFormLgpdLoaded($event)"></app-lgpd-form>
    </div>

    <app-formalization-section *ngIf="!!biometryChecklist?.length"
      [title]="!isCrossedFlux ? 'FORMALIZATION-STEP-TITLE' : ''" [description]="biometryTitle"
      [indicationStep]="!isCrossedFlux ? getSectionPosition('biometry') : null"
      [maxStepIndication]="!isCrossedFlux ? maxSteps : null" id="biometry">
      <div [ngClass]="
          isBiometryAndSignatureDone || hasAnalysis ? '' : isAntifraudBiometry ? 'anchor-content-sm' : 'anchor-content'
        ">
        <app-biometry-controller [proposal]="proposal" [proposalData]="proposalData" [checklist]="biometryChecklist"
          [uploadChecklist]="uploadChecklist" [isCrossedFlux]="isCrossedFlux" [crossedFluxLink]="crossedFluxLink"
          [clientList]="clientList" [showContractDownload]="showContractDownload"
          [tutorialNextButtonDisabled]="tutorialNextButtonDisabled" (agentsLegalEmitter)="agentsLegalEmitter($event)"
          (finish)="handleBiometryFinished($event)" (statusBiometry)="receivedStatusBiometry($event)"
          (stageBiometry)="ishandleSendSignature($event)" (getBiometryStatus)="getBiometryStatusEnum($event)"
          (ishandleFacialCapture)="ishandleFacialCapture($event)"
          (proposalConditionsAcceptedEmitter)="handleProposalConditionsAcceptedEmitter($event)" [category]="category">
        </app-biometry-controller>
      </div>
      <!-- DOCUMENT UPLOAD SECTION MOBILE -->

      <div *ngIf="ishandleSignature && clientType === 'F' && uploadChecklistMoblie?.length > 0">
        <app-formalization-section *ngIf="!!uploadChecklistMoblie?.length"
          [description]="'FORMALIZATION-STEP-DOCUMENT-UPLOAD'" [indicationStep]="getSectionPosition('upload')"
          [maxStepIndication]="maxSteps" id="upload">
          <div>
            <app-checklist [checklist]="uploadChecklistMoblie" [maxSizeAllowed]="maxSizeAllowed"
              [mobileSubscription]="mobileSubscription" (uploadFinished)="handleUploadFinished($event)"
              [category]="category"></app-checklist>
          </div>
          <div *ngIf="isCrossedFlux && getSectionPosition('upload') !== maxSteps" class="circle"
            (click)="scrollToNextSection('upload')" appTagger [event_category]="category" event_label="próximo passo">
            <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
          </div>
        </app-formalization-section>
      </div>
      <!-- FINISH FORMALIZATION SECTION MOBILE -->
      <div class="row finish-section mt-5"
        *ngIf="ishandleSignature && clientType === 'F' && uploadChecklistMoblie?.length > 0">
        <div class="col-12 mx-auto">
          <button mat-raised-button [disabled]="!uploadsComplete" [routerLink]="['/login']" class="btn" appTagger
            [event_category]="category" event_label="{{ 'FINISH-FORMALIZATION-BTN-TEXT' | translate }}">
            {{ 'FINISH-FORMALIZATION-BTN-TEXT' | translate }}
          </button>
        </div>
      </div>
      <div *ngIf="!isCrossedFlux && showContractDownload" class="info mt-3 mb-4">
        <span class="terms">{{ 'FORMALIZATION-CONDITION-CARD-ABOUT' | translate }}</span>
        <span class="loan-conditions" (click)="getProposalContract()" appTagger [event_category]="category"
          event_label="{{ showTitleConditionsLink() }}">
          {{ showTitleConditionsLink() }}</span>
        <span class="terms" *ngIf="proposalData?.proposalData?.product === productType.CDC">
          {{ 'FORMALIZATION-CONDITION-CARD-BUDGET-INFO-CE' | translate }}
        </span>
      </div>

      <div [ngClass]="isBiometryAndSignatureDone || hasAnalysis ? 'anchor-content-sm' : ''"></div>

      <div *ngIf="!isCrossedFlux && getSectionPosition('biometry') !== maxSteps" class="circle"
        (click)="scrollToNextSection('biometry')" appTagger [event_category]="category" event_label="próximo passo">
        <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
      </div>
    </app-formalization-section>
  </div>

  <!-- AGENT LEGAL NO BIOMETRY -->
  <!-- <app-formalization-section *ngIf="
      proposalData?.proposalData?.client === personType.PJ &&
      proposalData?.proposalData?.product === productType.CSC &&
      hasUploadRPP &&
      !showUploadRPO
    " [title]="!isCrossedFlux ? 'FORMALIZATION-STEP-TITLE' : ''" [description]="biometryTitle"
    [indicationStep]="!isCrossedFlux ? getSectionPosition('biometry') : null"
    [maxStepIndication]="!isCrossedFlux ? maxSteps : null" id="biometry">
    <div class="anchor-content agent-legal border mt-2 rounded p-4 col-12">
      <app-agent-legal [currentPlatform]="currentPlatform" [proposal]="proposalData" [clientList]="clientList"
        (agentsLegalEmitter)="getAgentsLegal($event)" (goToInstructionsEmitter)="noAdditionalAgents()"
        [category]="category">
      </app-agent-legal>
    </div>
    <div *ngIf="!isCrossedFlux && getSectionPosition('biometry') !== maxSteps" class="circle"
      (click)="scrollToNextSection('biometry')" appTagger [event_category]="category" event_label="próximo passo">
      <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
    </div>
  </app-formalization-section> -->

  <!-- <div class="px-4 mb-4 pt-3 col-12"
    *ngIf="proposalData?.proposalData?.client === personType.PJ && showUploadRPO && hasUploadRPP">
    <app-outlined-button (click)="setAgentLegal()">
      {{ 'FORMALIZATION-STEP-AGENT-LEGAL-BACK-BUTTON' | translate }}
    </app-outlined-button>
  </div> -->

  <div *ngIf="!biometryChecklist?.length">
    <div *ngIf="!isCrossedFlux && showContractDownload" class="info mt-3 mb-4 pt-3">
      <span class="terms">{{ 'FORMALIZATION-CONDITION-CARD-ABOUT' | translate }}</span>
      <span class="loan-conditions" (click)="getProposalContract()"> {{ showTitleConditionsLink() }}</span>
      <span class="terms" *ngIf="proposalData?.proposalData?.product === productType.CDC">
        {{ 'FORMALIZATION-CONDITION-CARD-BUDGET-INFO-CE' | translate }}
      </span>
    </div>
  </div>

  <!-- DOCUMENT UPLOAD SECTION -->
  <div [ngClass]="!!uploadChecklist?.length && !isCrossedFlux ? 'min-height' : ''">
    <app-formalization-section *ngIf="!!uploadChecklist?.length && !isCrossedFlux"
      [description]="'FORMALIZATION-STEP-DOCUMENT-UPLOAD'" [indicationStep]="getSectionPosition('upload')"
      [maxStepIndication]="maxSteps" id="upload">
      <div class="anchor-content-finish">
        <app-checklist [checklist]="uploadChecklist" [maxSizeAllowed]="maxSizeAllowed"
          (uploadFinished)="handleUploadFinished($event)" [category]="category"></app-checklist>
      </div>
      <div *ngIf="!isCrossedFlux && getSectionPosition('upload') !== maxSteps" class="circle"
        (click)="scrollToNextSection('upload')" appTagger [event_category]="category" event_label="próximo passo">
        <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
      </div>
    </app-formalization-section>
  </div>
  <!-- RECEIVEMENT SECTION BRAZIL-->
  <div *ngIf="!isCrossedFlux">
    <div [ngClass]="!isCrossedFlux && !isAccountDebit ? 'min-height' : ''">
      <app-formalization-section *ngIf="!isCrossedFlux && !isAccountDebit"
        [description]="'FORMALIZATION-STEP-RECEIVEMENT'" [indicationStep]="getSectionPosition('billet')"
        [maxStepIndication]="maxSteps" id="billet">
        <div class="anchor-content-finish">
          <app-form-payment [sendTypes]="sendTypes" [email]="email" (selectedPaymentForm)="handlePaymentForm($event)"
            [category]="category"></app-form-payment>
        </div>
        <div *ngIf="!isCrossedFlux && getSectionPosition('billet') !== maxSteps" class="circle"
          (click)="scrollToNextSection('billet')" appTagger [event_category]="category" event_label="próximo passo">
          <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
        </div>
      </app-formalization-section>
    </div>
  </div>

  <!-- BIPARTITE SECTION -->
  <div [ngClass]="!isCrossedFlux && showBipartido ? 'min-height' : ''" *ngIf="!isCrossedFlux && hasBipartiteSection()">
    <app-formalization-section [description]="'FORMALIZATION-BIPARTITE-PAYMENT'"
      [indicationStep]="getSectionPosition('bipartite')" [maxStepIndication]="maxSteps" id="bipartite">
      <div class="anchor-content-finish">
        <app-form-bipartite [proposalData]="proposalData" (selectedBipartiteForm)="handleBipartitePaymentForm($event)"
          [category]="category" [hasBipartiteMandatory]="!bipartiteMandatory">
        </app-form-bipartite>
      </div>
    </app-formalization-section>
  </div>

  <!-- FINISH FORMALIZATION SECTION -->

  <div class="row finish-section" *ngIf="!isCrossedFlux">
    <div class="col-12 mx-auto">
      <button mat-raised-button [disabled]="!isFormalizationCompleted()" (click)="finishFormalization()" class="btn"
        appTagger [event_category]="category" event_label="{{ 'FINISH-FORMALIZATION-BTN-TEXT' | translate }}">
        {{ 'FINISH-FORMALIZATION-BTN-TEXT' | translate }}
      </button>
    </div>
  </div>
</div>

<div class="container mt-5" *ngIf="showSummary">
  <app-formalization-summary [proposal]="proposal" [summaryData]="summaryData" [summaryStatus]="summaryStatus"
    [category]="category">
  </app-formalization-summary>
</div>