import { HttpEvent, HttpHandler, HttpInterceptor, HttpParams, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as nacl from 'tweetnacl';
import * as util from 'tweetnacl-util';
import { CryptoService } from './crypto.service';

const isUploadRestRequest = (request: HttpRequest<any>): boolean => {
  return request.url.endsWith('/uploadRest');
};

@Injectable({
  providedIn: 'root'
})
export class CryptIntercptor implements HttpInterceptor {
  constructor(private appService: CryptoService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!JSON.parse(environment.crypto.active)) {
      return next.handle(req);
    }

    try {
      let newReq = req.clone();

      if (newReq.url.includes(`lgn/realms/${environment.keycloak_realm}/protocol/openid-connect/token`)) {
        newReq = req.clone({
          url: `${environment.urlBase}${this.appService.cipherPath}/lgn/realms/${environment.keycloak_realm}/protocol/openid-connect/token`
        });
      } else if (JSON.parse(environment.newGateway)) {
        newReq = req.clone({
          url: req.url.replace('/apigw', this.appService.cipherPath)
        });
      } else {
        newReq = req.clone({
          url: req.url.replace('/api', this.appService.cipherPath)
        });
      }

      if (newReq.url.endsWith('sign')) {
        return next.handle(newReq);
      }

      const keys = nacl.box.keyPair();
      const one_time_code = nacl.randomBytes(24);

      if (newReq.url.includes('/token') || newReq.url.includes('/revoke')) {
        const form = newReq.body as HttpParams;
        // tslint:disable-next-line
        let formJson = {};
        form.keys().forEach(key => {
          formJson[key] = form.get(key);
        });

        newReq = newReq.clone({
          headers: newReq.headers.set('Content-type', 'application/json'),
          body: formJson
        });
      }

      if (newReq.urlWithParams.includes('?')) {
        const params = this.getQueryStringParams(newReq.urlWithParams);
        const object = params.reduce((a, [key, value]) => ({ [key]: value, ...a }), {});
        // tslint:disable-next-line
        const payload = JSON.stringify({
          cypher: util.encodeBase64(this.appService.encrypt(object, keys, one_time_code)),
          publicKey: util.encodeBase64(keys.publicKey),
          nonce: util.encodeBase64(one_time_code)
        });

        const data = encodeURIComponent(util.encodeBase64(this.appService.signData(payload)));

        newReq = newReq.clone({
          url: newReq.url.split('?')[0],
          params: new HttpParams().append('enc', data)
        });
      }

      if (isUploadRestRequest(newReq)) {
        const body = { ...newReq.body };
        const file = newReq.body.arquivo;

        // Remove unused properties from payload
        delete body.arquivo;
        delete body.files;

        newReq = newReq.clone({ body });

        const payload = JSON.stringify({
          cypher: util.encodeBase64(this.appService.encrypt(newReq.body, keys, one_time_code)),
          publicKey: util.encodeBase64(keys.publicKey),
          nonce: util.encodeBase64(one_time_code)
        });

        newReq = newReq.clone({
          body: {
            metaData: encodeURIComponent(util.encodeBase64(this.appService.signData(payload))),
            arquivo: file
          }
        });
      } else if (req.method !== 'GET') {
        if (newReq.body) {
          const newBody = this.appService.encrypt(
            Object.keys(newReq.body).length > 0 ? newReq.body : null,
            keys,
            one_time_code
          );

          const payload = JSON.stringify({
            cypher: util.encodeBase64(newBody),
            publicKey: util.encodeBase64(keys.publicKey),
            nonce: util.encodeBase64(one_time_code)
          });

          newReq = newReq.clone({
            body: {
              enc: encodeURIComponent(util.encodeBase64(this.appService.signData(payload)))
            }
          });
        }
      } else {
        // tslint:disable-next-line
        const payload = JSON.stringify({
          cypher: util.encodeBase64(this.appService.encrypt(util.encodeBase64(keys.publicKey), keys, one_time_code)),
          publicKey: util.encodeBase64(keys.publicKey),
          nonce: util.encodeBase64(one_time_code)
        });

        newReq = newReq.clone({
          headers: newReq.headers.set(
            'x-content',
            encodeURIComponent(util.encodeBase64(this.appService.signData(payload)))
          )
        });
      }

      return next.handle(newReq).pipe(
        map((res: any) => {
          if (res instanceof HttpResponse) {
            try {
              return res.clone({
                body: this.handleDecrypt(res.body.enc, keys)
              });
            } catch (error) {
              return res;
            }
          } else {
            return res;
          }
        }),
        catchError(error => {
          try {
            const parsedError = error;
            parsedError.error = this.handleDecrypt(parsedError.error.enc, keys);
            return throwError(parsedError);
          } catch {
            return throwError(error);
          }
        })
      );
    } catch (e) {
      console.log({ e });
      return next.handle(req);
    }
  }

  private handleDecrypt(data: string, keys: nacl.BoxKeyPair): any {
    const body = decodeURIComponent(data);
    const signature: any = nacl.sign.open(util.decodeBase64(body), this.appService.parsedOpenSignkey);
    const openSignature: any = JSON.parse(util.encodeUTF8(signature));
    const text = this.appService.decrypt(
      util.decodeBase64(openSignature.cypher),
      keys,
      util.decodeBase64(openSignature.nonce)
    );

    return JSON.parse(util.encodeUTF8(text));
  }

  private getQueryStringParams(url: string): Array<string[]> {
    return url
      .split('?')
      .pop()
      .split('&')
      .map(item => item.split('='));
  }
}
