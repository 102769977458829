import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { InstallmentsModalComponent } from '@app/proposal-workflow/components/installments-modal/installments-modal.component';
import { GenTagger } from '@app/tagging/gen-tagger';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { Subject } from 'rxjs';
import { first } from 'rxjs/operators';

import { PaymentsService } from '../../services/payments.service';
import { Installment, Insurance } from './../../models/payment.model';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state';
import { SetProposalIdAction } from '@app/core/state/proposal/proposal.actions';

import * as moment from 'moment';

@Component({
  selector: 'app-paymenttooltip',
  templateUrl: './paymenttooltip.component.html',
  styleUrls: ['./paymenttooltip.component.scss']
})
export class PaymenttooltipComponent implements OnInit, OnDestroy {
  onDestroy = new Subject();
  @Input() planPayments: Installment[];
  @Input() numberProposal: any;
  dialogConfig = new MatDialogConfig();
  public innerWidth: any;
  public MOBILE_BREAKPOINT = 768;
  @Input() public installmentStepData;
  @Input() public alignTo: string;
  @Input() isPlanCustom: boolean;
  @Input() getProposalConfirmationRes: any;
  public currentCountry = this.internationalizationService.currentCountry;
  installments: any;
  flexInstallmentsNumber: any;
  planSelected: any;
  public planFlex = true;
  public subOfferId
  public payload: any;
  @Input() public showMoreDetails: boolean;
  @Input() public insurance?: Insurance;
  @Input() public showTarif: boolean;
  @Input() public insuranceValue: number;
  @Input() public registerTariff: number;

  readonly category = '/portallojista/pagamentos/detalhe';

  constructor(
    private internationalizationService: InternationalizationService,
    public matDialog: MatDialog,
    public paymentsService: PaymentsService,
    private genTagger: GenTagger,
    private store$: Store<AppState>,
  ) {}

  ngOnInit() {
    this.getPlanCustom();
  }
  openInstallmentsModal(code?: any) {
    this.dialogConfig = new MatDialogConfig();


    const isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;

    this.dialogConfig = {
      width: isMobile ? '100%' : '48%',
      maxWidth: '618px',
      id: 'pion-side-modal',
      panelClass: 'installments-modal-container',
      height: '100%',
      autoFocus: false,
      position: {
        right: '0px',
        top: '0px'
      },
      data: {
        installments: this.planPayments,
        country: this.currentCountry,
        isMobile: isMobile, // Usado para abrir o modal de installments
        code: this.planSelected.planTypeSchemaData.planTypeCode,
        close: this.closeInstallmentsModal,
        isPlanCustom: this.isPlanCustom,
        data: this.getProposalConfirmationRes,
        isModalPayment: true
      }
    };

    const modalRef = this.matDialog.open(InstallmentsModalComponent, this.dialogConfig);

    modalRef
      .afterOpened()
      .pipe(first())
      .subscribe(() => {
        this.genTagger.setTag({
          event_category: '/portallojista/pagamentos/detalhe/parcelas',
          event_action: 'abriu modal – parcelas',
          event_label: 'Parcelas'
        });

        this.genTagger.setTag({
          event_category: '/portallojista/pagamentos/detalhe/parcelas',
          event_action: 'pageview',
          event_label: 'parcelas'
        });
      });
  }

  closeInstallmentsModal() {}

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  private formatDate(data: string){
    this.planSelected.planTypeSchemaData.monthOccurrency = moment(data).format('MM/YYYY')
  }

  private getPlanCustom(): void {
    this.paymentsService.getProposalConfirmation(this.numberProposal).subscribe(payments => {
      this.planSelected = payments;
      this.formatDate(this.planSelected.planTypeSchemaData.monthOccurrency);
      this.store$.dispatch(new SetProposalIdAction(this.numberProposal));
    });
  }
}
