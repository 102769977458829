import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { SetCompanyRepresentantAddressDataSection } from '@app/core/state/proposal-information/proposal-information.actions';
import * as proposalInformationSelect from '@app/core/state/proposal-information/proposal-information.selectors';
import {
  AddressByZipCode,
  AddressSignUpData,
  ProposalParam,
  ProposalParameters,
  ProposalSignUpData
} from '@app/proposal-workflow/models/proposal-data.model';
import { AddressTypeEnum } from '@app/proposal-workflow/models/step-register-put.enum';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, takeUntil, tap } from 'rxjs/operators';
import { GetProposalInformationDTO } from './../../../../models/proposal-data.model';

@Component({
  selector: 'app-representant-address-data',
  templateUrl: './representant-address-data.component.html',
  styleUrls: ['./representant-address-data.component.scss']
})
export class RepresentantAddressDataComponent implements OnInit, OnDestroy, AfterContentChecked {
  protected ngUnsubscribe: Subject<any> = new Subject();

  @Input() public pos: { pos: number; size: number };
  @Input() public comboParams: ProposalParameters;
  @Input() public formalization = true;
  @Input() public event_category: string;
  @Input() public setPersistedData: Observable<GetProposalInformationDTO>;
  @Output() public begin = new EventEmitter<FormGroup>();

  public proposal: ProposalSignUpData;
  public cities$: Observable<ProposalParam[]>;
  public proposalDataRes: GetProposalInformationDTO;

  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  form: FormGroup;

  public maskZipCode = this.pioneerMasks.zipCode.mask;
  public maskNumber = '00000';

  // Tag
  public Insert: Tag = Tag.Insert;
  public Select: Tag = Tag.Selecionar;
  public focusItems = {};
  private previousState: string;
  private city: string;

  constructor(
    private internationalizationService: InternationalizationService,
    private simulationService: SimulationService,
    private store$: Store<AppState>,
    private genTagger: GenTagger,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.buildForm();
    this.begin.emit(this.form);
    this.getProposalData();
    this.valuesFormsSubscribe();
    this.setAddressDataFormSubscription();
    this.setMaskZipCodeSubscription();
  }

  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  public buildForm() {
    this.form = new FormGroup({
      zipCode: new FormControl(null, [Validators.required, Validators.minLength(8)]),
      streetTypeId: new FormControl(null),
      street: new FormControl(null, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(40),
        Validators.pattern(/^[a-zA-Z0-9À-ÿ]{1,}(?: [a-zA-Z0-9À-ÿ]+){0,}$/)
      ]),
      number: new FormControl(null, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(5)
        // ,Validators.pattern(/^[a-zA-Z0-9]{1,}$/)
      ]),
      complement: new FormControl(null, [
        Validators.minLength(1),
        Validators.maxLength(20),
        Validators.pattern(/^[a-zA-Z0-9À-ÿ]{1,}(?: [a-zA-Z0-9À-ÿ]+){0,}$/)
      ]),
      neighborhood: new FormControl(null, [
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(35),
        Validators.pattern(/^[a-zA-Z0-9À-ÿ]{1,}(?: [a-zA-Z0-9À-ÿ]+){0,}$/)
      ]),
      state: new FormControl(null, Validators.required),
      cityId: new FormControl(null, Validators.required),
      mainFlag: new FormControl(null),
      clientId: new FormControl(null),
      id: new FormControl(null),
      type: new FormControl(null)
    });
  }
  public valuesFormsSubscribe(): void {
    this.setPersistedData.subscribe(res => {
      if(res){
        this.proposalDataRes = res;
        this.setValueRecovered();

     }
    this.begin.emit(this.form);
    })
  }

  public setValueRecovered(): void {

    const physicalPerson = this.proposalDataRes?.clients.find(F => F.person.type === 'F');
    if (!!physicalPerson) {
      this.form.patchValue({number: null});
      this.form.patchValue({zipCode: null});
      this.form.patchValue(physicalPerson.person.addresses[0]);
    }
    if (this.form.valid) {
      this.updateRepresentantAddressData();
    }
  }

  public getProposalData() {
    this.store$
      .select(proposalInformationSelect.getSimulationProposalData)
      .pipe(
        filter((data: ProposalSignUpData) => !!data && Object.values(data).some(item => !!item)),
        tap(data => {
          this.proposal = data;
          if (
            data.secondaryClient &&
            data.secondaryClient.person &&
            data.secondaryClient.person.addresses &&
            data.secondaryClient.person.addresses.length
          ) {
            this.form.patchValue(data.secondaryClient.person.addresses[0]);
          }

        }),
        first()
      )
      .subscribe(() => {
        if (this.form.get('state').valid) {
          this.getCities();
        }

        if (this.form.valid) {
          this.updateRepresentantAddressData();
        }

        this.maskZipCode = this.pioneerMasks.zipCode.maskBack;
        this.maskNumber = 'AAAAA';
        this.getSectionData();
      });
  }

  private getSectionData(): void {
    this.store$
      .select(proposalInformationSelect.companyRepresentantAddressDataSection)
      .pipe(
        filter((data: AddressSignUpData[]) => !!data && !!data.length),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe(data => {
        data[0].zipCode = data[0].zipCode.substr(0, 5) + '-' + data[0].zipCode.substr(5, 7);
        this.form.patchValue({ id: data[0].id });
        if (this.form.get('state').valid) {
          this.getCities();
        }
      });
  }

  private setAddressDataFormSubscription(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged((prev, current) => JSON.stringify(prev) === JSON.stringify(current)),
        debounceTime(1000),
        filter(() => this.form.valid)
      )
      .subscribe((data: AddressSignUpData) => {
        if (this.form.valid) {
                               this.updateRepresentantAddressData();
                             }
      });
  }

  private setMaskZipCodeSubscription() {
    this.form
      .get('zipCode')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        if (!!value && value.length < 8) {
          this.maskZipCode = this.pioneerMasks.zipCode.mask;
        }
      });
  }

  public setMaskNumber(value) {
    this.maskNumber = '00000';
  }

  public checkNumber(): void {
    const value = this.form.get('number').value.replace(/\D/, '');
    this.form.get('number').setValue(value);
  }
  private updateRepresentantAddressData(): void {
    let data: AddressSignUpData = this.form.getRawValue();
    this.cities$.subscribe(cities => {
      const city = cities.find(c => c.id === data.cityId);
      data = {
        ...data,
        city: city.description,
        type: AddressTypeEnum.RESIDENTIAL
      };
      this.store$.dispatch(new SetCompanyRepresentantAddressDataSection([data]));
    });
  }

  public getAddressByZipCode() {
    if (!!this.form.controls.zipCode.value) {
      this.simulationService
        .getAddressByZipCode(this.form.controls.zipCode.value)
        .pipe(first())
        .subscribe((data: AddressByZipCode) => {
          if(data.fgInexis === 'S') {
            this.dialog.open(PionModalComponent, {
              data: {
                title: "Atenção",
                description: "CEP não encontrado",
                confirmText: "Ok, eu entendi",
                type: 'error',
              }
            })

            this.form.controls.zipCode.setErrors({'not_found': true})
          } else if (!!data && !!data.zipCode) {
            // tslint:disable-next-line: no-shadowed-variable
            const { address: street, addressType: streetTypeId, zipCode, neighborhood, state, cityId } = data;
            const formData = {
              street,
              streetTypeId,
              zipCode,
              neighborhood,
              state,
              cityId
            };

            const oldFormValue = this.form.value;
            this.form.reset();
            this.form.patchValue({ ...oldFormValue, ...formData });

            if (data.zipCode.length < 8) {
              this.form.controls.zipCode.setValue(data.zipCode.padStart(9, '0'));
            }
            this.getCities();
          } else {
            this.form.controls.zipCode.setValue(this.form.controls.zipCode.value.padStart(9, '0'));
          }
        });
    }
  }

  public getCities() {
    const state = this.form.controls.state.value;
    if (this.previousState !== state) {
      this.cities$ = this.simulationService.getCities(state).pipe(
        first(),
        distinctUntilChanged()
      );
    }
    this.previousState = state;
  }

  public checkFocus(key: string): void {
    if (!this.focusItems[key]) {
      this.form.get(key).reset();
      this.focusItems[key] = true;
    }
  }

  public update() {
    if (this.form.valid) {
      this.updateRepresentantAddressData();
    }
  }

  public onStateChange() {
    this.getCities();
    const value = this.form.get('state').value;
    const state = this.comboParams.states.find(p => p.id === value);
    this.genTagger.setTag({
      event_action: `${this.Select} - estado`,
      event_category: this.event_category,
      event_label: state.description.toLowerCase()
    });
  }

  public onCityChange() {
    let city: ProposalParam;
    const value = this.form.get('cityId').value;
    this.cities$.subscribe(cities => {
      city = cities.find(c => c.id === value);
      this.genTagger.setTag({
        event_action: `${this.Select} - cidade`,
        event_category: this.event_category,
        event_label: city.description.toLowerCase()
      });
      this.city = city.description;
      this.update();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
