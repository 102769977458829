import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { GetNewCalculation, GetNewFlexInstallmentsCalculation } from '@app/core/state/simulation/simulation.actions';
import { InputChange } from '@app/proposal-workflow/models/input-change.model';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { select, Store } from '@ngrx/store';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { Countries } from '@shared/constants/countries.enum';
import { Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'installments-modal-flex',
  templateUrl: './installments-modal-flex.component.html',
  styleUrls: ['./installments-modal-flex.component.scss']
})
export class InstallmentsModalFlexComponent implements OnInit, OnDestroy {
  public countries = Countries;
  protected ngUnsubscribe: Subject<any> = new Subject();
  installments = [];
  currentCountry: string;
  headerCollapsed = false;
  innerWidth;
  @Input() isEditing = false;
  public MOBILE_BREAKPOINT = 768;

  flexValues = new FormArray([]);

  public disableSave = true;

  calculationPayload: any;

  readonly Insert: Tag = Tag.Insert;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialog: MatDialog,
    private store$: Store<AppState>,
    private genTagger: GenTagger
  ) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit() {
    this.store$
      .pipe(
        select('simulation'),
        takeUntil(this.ngUnsubscribe),
        filter(data => !!data.simulationParameters && !!data.simulationData)
      )
      .subscribe(res => {
        this.installments = res.simulationData.installments;
        this.calculationPayload = res.calculationPayload;
        this.genTagger.setTag({
          event_category: this.data.category,
          event_label: 'abriu modal - plano flex',
          event_action: Tag.pgView
        });
      });

    this.currentCountry = this.data.country;

    this.installments.forEach((installment, index) => {
      let value = 0;
      const installmentFlex = this.data.conditionPlanFlex.find(c => c.sequenceNumber === installment.number);
      if (installmentFlex) {
        value = installmentFlex.paymentValue;
      }

      this.flexValues.push(
        new FormControl(value, [
          Validators.required,
          Validators.max(this.data.maxAllowedInstallmentValue),
          Validators.min(this.data.minAllowedInstallmentValue)
        ])
      );
    });

    this.flexValues.valueChanges.subscribe(r => {
      this.disableSave = true;
    });
  }

  resetValue(control: FormControl, resetType) {
    if ((resetType === 'blur' && !control.value) || resetType === 'clear') {
      control.patchValue(0);
      control.markAsPristine();
      control.markAsUntouched();
      control.updateValueAndValidity();
    }
  }

  onScroll(e) {
    const scrollTop: number = (e.target as Element).scrollTop;
    if (this.innerWidth <= this.MOBILE_BREAKPOINT) {
      if (scrollTop > 0) {
        this.headerCollapsed = true;
      } else {
        this.headerCollapsed = false;
      }
    }
  }

  calculateFlex() {
    this.disableSave = false;
    const installmentNumber = [];

    const flexInstallmentsInput = this.flexValues
      .getRawValue()
      .filter((installment, i) => {
        if (installment > 0) {
          installmentNumber.push(i + 1);
          return installment;
        }
      })
      .map((installmentAmountFlex, installmentIndex) => {
        return {
          installmentNumber: installmentNumber[installmentIndex],
          installmentAmountFlex
        };
      });

    const payload: InputChange = {
      value: {
        flexInstallmentsInput
      },
      input: InputTypes.INSTALLMENT_FLEX_DATA
    };

    this.store$.dispatch(new GetNewFlexInstallmentsCalculation(payload));
  }

  get qtyInstallmentsInfo() {
    return `${this.qtyInstallmentsValid}/${this.data.maxAllowedFlexInstallmentsNumber}`;
  }

  get qtyInstallmentsValid() {
    return this.flexValues.controls.filter(installment => installment.valid).length;
  }

  get sumValidInstallments() {
    let max = 0;
    this.flexValues.controls
      .filter(installment => Number(installment.value) > 0)
      .forEach(control => (max += Number(control.value)));
    return max;
  }

  IsmaxAllowedInstallmentValue(control: FormControl) {
    const value = Number(control.value);
    return this.showWarningIcon(control) && value > Number(this.data.maxAllowedInstallmentValue);
  }

  IsminAllowedInstallmentValue(control: FormControl) {
    const value = Number(control.value);
    return this.showWarningIcon(control) && value < Number(this.data.minAllowedInstallmentValue);
  }

  isQtyInstallmentsValid() {
    return this.qtyInstallmentsValid > 0 && this.qtyInstallmentsValid <= this.data.maxAllowedFlexInstallmentsNumber;
  }

  isSumFlexInstallmentsValid() {
    return this.sumValidInstallments > 0 && this.sumValidInstallments <= Number(this.data.maxAllowedFlexValue);
  }

  disableCalculateBtn() {
    return !(this.isQtyInstallmentsValid() && this.isSumFlexInstallmentsValid());
  }

  showWarningIcon(control: FormControl) {
    return (
      (!control.untouched && !control.pristine && !control.valid) ||
      (control.value && (this.showMaxAllowedFlexValueError() || this.showMaxAllowedFlexInstallmentsError()))
    );
  }

  showMaxAllowedFlexInstallmentsError() {
    return this.qtyInstallmentsValid > this.data.maxAllowedFlexInstallmentsNumber;
  }
  showMaxAllowedFlexValueError() {
    return this.sumValidInstallments > Number(this.data.maxAllowedFlexValue);
  }

  save() {
    this.dialog.closeAll();
  }

  close() {
    const isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;
    const dialogRef = this.dialog.open(PionModalComponent, {
      data: {
        title: 'Tem certeza que deseja voltar à simulação?',
        description: 'As alterações feitas em seu plano de pagamento não serão salvas.',
        confirmText: 'Continuar edição',
        cancelText: 'Voltar para simulação',
        panelClass: 'close-modal',
        type: null
      },
      disableClose: true,
      id: 'modal-component',
      width: isMobile ? '80%' : '520px',
      height: isMobile ? '45%' : '330px',
      autoFocus: false
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        if (!res) {
          const calculationPayload = this.calculationPayload;

          if (calculationPayload.flexInstallmentsInput) {
            delete calculationPayload.flexInstallmentsInput;
            delete calculationPayload.flexInstallments;
          }

          this.store$.dispatch(new GetNewCalculation(calculationPayload));

          this.save();
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
