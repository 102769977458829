<div id="modal-content-wrapper" [hidden]="showError">
  <header id="modal-header" class="text-center mt-4">
    <img src="./assets/images/ic_error.svg" width="44px" height="44px" alt="Failure" class="mb-4" />
    <h1 id="modal-title mt-2" mat-dialog-title>{{ 'INVALID-FILE' | translate }}</h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <p class="mt-2">{{ 'INVALID-FILE-MSG' | translate }}</p>
  </section>
  <footer id="modal-footer" mat-dialog-actions class="mt-4 mb-3">
    <button
      mat-stroked-button
      id="modal-close-button"
      color="primary"
      (click)="dialogRef.close()"
      appTagger
      [event_category]="category"
      event_label="{{ 'BTN-CANCEL' | translate }}"
    >
      {{ 'BTN-CANCEL' | translate }}
    </button>
    <button
      mat-flat-button
      id="modal-action-button"
      color="primary"
      (click)="dialogRef.close('retry-upload')"
      appTagger
      [event_category]="category"
      event_label="{{ 'SEND-FILE' | translate }}"
    >
      {{ 'SEND-FILE' | translate }}
    </button>
  </footer>
</div>
