import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { DocumentScanModel } from '@app/document-scan/models/document-scan.enum';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-bra-document-scan',
  templateUrl: './bra-document-scan.component.html',
  styleUrls: ['./bra-document-scan.component.scss']
})
export class BraDocumentScanComponent implements OnInit, AfterViewInit {
  @ViewChild('videoElement') videoElement: any;

  @Output() scanOutput: EventEmitter<DocumentScanModel> = new EventEmitter();

  private documentResponse: DocumentScanModel = new DocumentScanModel();

  private readonly videoOptions = {
    video: {
      facingMode: 'environment',
      width: { min: 1280 },
      height: { min: 720 }
    }
  };
  public deviceInfo;
  public isMobile = false;
  public imageBase64: string;
  public scanTaken = false;
  public cameraReady = false;

  constructor(private deviceService: DeviceDetectorService) {}

  ngOnInit() {
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.isMobile = this.deviceService.isMobile();
    this.startVideo();
    window.scrollTo(0, 0);
  }

  ngAfterViewInit() {
    if (this.isMobile) {
      this.clearCanvas();
    }
  }

  scanDocument() {
    const canvas: any = document.getElementById('canvasImage');
    canvas.width = this.videoElement.nativeElement.videoWidth;
    canvas.height = this.videoElement.nativeElement.videoHeight;
    canvas
      .getContext('2d')
      .drawImage(
        this.videoElement.nativeElement,
        0,
        0,
        this.videoElement.nativeElement.videoWidth,
        this.videoElement.nativeElement.videoHeight
      );
    this.imageBase64 = canvas.toDataURL('image/png');
    this.videoElement.nativeElement.srcObject.getVideoTracks().forEach(track => {
      track.stop();
    });
    this.scanTaken = true;
    this.cameraReady = false;
  }

  acceptDocument() {
    this.documentResponse = {
      ...this.documentResponse,
      images: [this.imageBase64]
    };
    this.scanOutput.emit(this.documentResponse);
  }

  // Erase the image base 64
  // Renew the state of scan Taken ;
  // Renew the canvas ;
  // Restart the camera stream ;
  retake() {
    this.imageBase64 = '';
    this.scanTaken = false;
    this.startVideo();
  }

  // Receive a media stream and set to video element
  private setStreamOnVideoElement(stream: MediaStream) {
    this.videoElement.nativeElement.srcObject = new MediaStream(stream);
    this.videoElement.nativeElement.controls = false;
    this.videoElement.nativeElement.play();
    this.cameraReady = true;
  }

  // Clear canvas to print the photo
  private clearCanvas() {
    const canvasElement = document.getElementById('canvasImage') as HTMLCanvasElement;
    const canvasContext = canvasElement.getContext('2d');
    canvasContext.clearRect(0, 0, canvasElement.width, canvasElement.height);
  }

  //
  private startVideo() {
    if (!this.isMobile) {
      return console.error('This component is only for mobile');
    }
    if (this.isMobile === true && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia(this.videoOptions).then(
        stream => {
          this.setStreamOnVideoElement(stream);
          this.clearCanvas();
        },
        error => {
          this.documentResponse = {
            ...this.documentResponse,
            error: '' + error.code
          };
          this.scanOutput.emit(this.documentResponse);
        }
      );
    }
  }
}
