<div class="extra-info">
  <form [formGroup]="formGroup">
    <div>
      <mat-form-field color="black" appearance="legacy">
        <mat-label>{{ 'PERIOD' | translate }}</mat-label>
        <mat-select formControlName="period" (selectionChange)="monthChangeHandler($event)"
          [compareWith]="compareObjects">
          <mat-option *ngFor="let option of months" [value]="option.code" appTagger [event_category]="category"
            event_label="{{ option.description }}" [event_action]="Selecionar">
            {{ option.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>

      <mat-form-field color="black" class="calendarArrow" appearance="legacy">
        <mat-label>{{ 'FIRST-MONTH-INSTALLMENT' | translate }}</mat-label>
        <mat-select disabled class="set-color-black-disabled" formControlName="firstDate" (selectionChange)="setDate()"
          [compareWith]="compareObjects">
          <mat-option *ngFor="let skipInst of skipableInstallments" [value]="skipInst.code">
            {{ skipInst.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
    </div>
    <p class="label">{{ 'INSTALLMENTS-SASONAL' | translate }}</p>
    <p class="value font-weight-bold">
      {{ installmentPaymentMethodsAmount}} de
      {{ installmentPaymentMethodsValue | localeCurrency }}
    </p>

    <p class="label">{{ 'OTHER-INSTALLMENTS' | translate }}</p>

    <p class="value font-weight-bold">
      {{ installmentDefaultAmount }} de
      {{ installmentDefaultValue | localeCurrency }}
    </p>
  </form>
</div>