<div *ngIf="checklistItems?.length" class="pending">
  <div class="pending-title">
    <h2 class="pending-title-primary">{{ pendencyTitle | translate }}</h2>
    <h2 class="pending-title-primary"></h2>
  </div>

  <pion-snackbar *ngIf="message && checklistItems.length > 0" icon="error" [closable]="false" type="warning">
    <p [innerHTML]="message"></p>
  </pion-snackbar>

  <h3 *ngIf="checklistItems.length > 0" class="pending-title-secondary">
    {{ 'PENDENCY-ATTACH-PENDING-DOCUMENTS' | translate }}
  </h3>

  <div *ngFor="let file of checklistItems">
    <section id="nf-section" *ngIf="!!nfValue && file.document.docCode === '20'">
      <div class="nf-main">
        <p class="nf-label">{{ 'PENDENCY-BIPARTITE-NF-VALUE-LABEL' | translate }}*</p>
        <p class="nf-value">{{ nfValue | localeCurrency }}</p>
      </div>
    </section>
    <app-checklist-item
      [isPendency]="true"
      [checklistItem]="file"
      [maxSizeAllowed]="maxSizeAllowed"
      (uploadFinished)="handleUploadFinished($event)"
      [category]="category"
    ></app-checklist-item>
  </div>
</div>
