import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';
import { Countries } from '@shared/constants/countries.enum';

@Pipe({ name: 'documentNumber' })
export class DocumentNumberPipe implements PipeTransform {
  readonly onlyNumbersRegex: RegExp = new RegExp(/[^0-9]/, 'gi');

  constructor(private _decimalPipe: DecimalPipe) {}

  private thousandSeparator(value: string): string {
    return this._decimalPipe.transform(value, '1.0-0', 'pt-BR');
  }

  private transformBrazil(d: string): string {
    if (d.length === 11) {
      return `${d.slice(0, 3)}.${d.slice(3, 6)}.${d.slice(6, 9)}-${d.slice(9)}`;
    }
    if (d.length === 14) {
      return `${d.slice(0, 2)}.${d.slice(2, 5)}.${d.slice(5, 8)}/${d.slice(8, 12)}-${d.slice(12)}`;
    }
    return d;
  }

  private transformArgentina(d: string): string {
    if (d.length === 7) {
      return `0${this.thousandSeparator(d)}`;
    }
    if (d.length === 8) {
      return this.thousandSeparator(d);
    }
    return d;
  }

  private transformColombia(d: string): string {
    if (d.length >= 6 && d.length <= 7) {
      return d;
    }
    if (d.length >= 8 && d.length <= 11) {
      return this.thousandSeparator(d);
    }
    return d;
  }

  private transformPeru(d: string): string {
    return d;
  }

  transform(documentNumber: string | number): string {
    if (!documentNumber) return '';
    if (typeof documentNumber !== 'number' && typeof documentNumber !== 'string') {
      console.error('Invalid input type, DocumentNumberPipe only accepts inputs of type string or number');
      return '';
    }
    // Transform into string and remove everything that's not a number.
    const doc = `${documentNumber}`.replace(this.onlyNumbersRegex, '');

    switch (environment.currentCountry) {
      case Countries.BR:
        return this.transformBrazil(doc);
      case Countries.AR:
        return this.transformArgentina(doc);
      case Countries.CO:
        return this.transformColombia(doc);
      case Countries.PE:
        return this.transformPeru(doc);
      default:
        return doc;
    }
  }
}
