<div class="representants-list">
  <div class="representant-item" *ngFor="let representant of clientList">
    <div *ngIf="representant?.biometryStatus === clientBiometryStatusEnum.DONE">
      <img src="./assets/images/ic_check.svg" alt="" /> {{ representant.name }}
      <br *ngIf="isNotMobileCrossFlux" />
      <span class="status-subtext {{ isNotMobileCrossFlux ? 'ml-3' : '' }}">Assinatura realizada</span>
    </div>
    <div *ngIf="representant?.biometryStatus === clientBiometryStatusEnum.PENDING">
      <img src="./assets/images/ic_exclamation.svg" alt="" /> {{ representant.name }}
      <br *ngIf="isNotMobileCrossFlux" />
      <span class="status-subtext {{ isNotMobileCrossFlux ? 'ml-3' : '' }}">Aguardando assinatura</span>
    </div>
    <div *ngIf="representant?.biometryStatus === clientBiometryStatusEnum.REMAKE">
      <img src="./assets/images/ic_exclamation.svg" alt="" /> {{ representant.name }}
      <br *ngIf="isNotMobileCrossFlux" />
      <span class="status-subtext {{ isNotMobileCrossFlux ? 'ml-3' : '' }}">Necessário refazer biometria</span>
    </div>
    <div *ngIf="representant?.biometryStatus === clientBiometryStatusEnum.ANALYSIS">
      <img src="./assets/images/ic_exclamation.svg" alt="" /> {{ representant.name }}
      <br *ngIf="isNotMobileCrossFlux" />
      <span class="status-subtext {{ isNotMobileCrossFlux ? 'ml-3' : '' }}">Biometria em análise</span>
    </div>
    <div *ngIf="representant?.biometryStatus === clientBiometryStatusEnum.IN_PROGRESS">
      <img src="./assets/images/ic_clock_blue.svg" alt="" /> {{ representant.name }}
      <br *ngIf="isNotMobileCrossFlux" />
      <span class="status-subtext {{ isNotMobileCrossFlux ? 'ml-3' : '' }}">Assinatura em andamento</span>
    </div>
  </div>

  <div *ngIf="!allDone && !isNotMobileCrossFlux && someInProgress" class="signature-info">
    <img src="./assets/images/ic_mobile_recharge.svg" class="mr-2" alt="" />
    Assinatura do {{ selectedClient?.name }} está em andamento via mobile.
  </div>

  <div *ngIf="isNotMobileCrossFlux && !allDone && clientList.length > 1" class="signature-info">
    <div class="agents-info"><img src="./assets/images/ic_clock_blue.svg" alt="" /><br /></div>
    {{ 'FORMALIZATION-STEP-AGENT-LEGAL-MSG-MAIL' | translate }}
  </div>
</div>
