<div [formGroup]="digitsForm" class="d-flex">
  <ng-container *ngFor="let digit of digitsList">
    <input
      #digitInput
      id="{{ 'digit' + digit }}"
      formControlName="{{ 'digit' + digit }}"
      type="text"
      maxlength="1"
      autocomplete="off"
      (ngModelChange)="digitChanged($event, digit)"
      oninput="this.value=this.value.replace(/[^0-9]/g,'');"
      (keydown)="isDeletePressed(digit, $event)"
    />
  </ng-container>
</div>

