
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SignUpService {
  // false: token is valid
  // true: token is invalid
  public statusWrongCode$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public curStatusWrongCode = this.statusWrongCode$.asObservable();

  public takedPhoto$: BehaviorSubject<string> = new BehaviorSubject('');
  public takedPhotoObservable = this.takedPhoto$.asObservable();

  public sendDocInput$: BehaviorSubject<string> = new BehaviorSubject('');
  public getDocumentInput = this.sendDocInput$.asObservable();

  constructor(private http: HttpClient) {}

  // public getSignUpRenderData(): Observable<SignUpRenderData> {
  //   // Remove mock when GraphQL is ready.
  //   // Start MOCK
  //   let documentTypes;
  //   switch (environment.currentCountry) {
  //     case Countries.BR:
  //       // Brazil NA
  //       break;
  //     case Countries.AR:
  //       documentTypes = [
  //         { id: 1, code: 'DNI', description: 'DOC1' },
  //         { id: 2, code: 'Carnet de Extranjería', description: 'DOC2' }
  //       ];
  //       break;
  //     case Countries.PE:
  //       documentTypes = [
  //         { id: 1, code: 'DNI', description: 'DOC1' },
  //         { id: 2, code: 'Carnet de Extranjería', description: 'DOC2' }
  //       ];
  //       break;
  //     case Countries.CO:
  //       documentTypes = [
  //         { id: 1, code: 'Cédula de Ciudadanía', description: 'DOC1' },
  //         { id: 2, code: 'Cédula de Extranjería', description: 'DOC2' }
  //       ];
  //       break;
  //     default:
  //       documentTypes = [
  //         { id: 1, code: 'DNI', description: 'DOC1' },
  //         { id: 2, code: 'Carnet de Extranjería', description: 'DOC2' }
  //       ];
  //       break;
  //   }

  //   const mock = {
  //     genders: [{ id: 1, code: 'M', description: 'Masculino' }, { id: 2, code: 'F', description: 'Femenino' }],
  //     documentTypes,
  //     economicActivities: [
  //       { id: 1, code: 'Empleado', description: 'EA1' },
  //       { id: 2, code: 'Independiente', description: 'EA2' },
  //       { id: 3, code: 'Pensionado', description: 'EA3' }
  //     ],
  //     terms:
  //       'Términos y condiciones: Lorem ipsum dolor sit amet, consectetur adipiscing elit. \
  //       Nam gravida iaculis elit, euismod blandit nulla cursus nec. Cras auctor gravida erat ac euismod. \
  //       Fusce fringilla dui sed placerat maximus. Vestibulum iaculis vehicula vulputate. \
  //       Praesent vulputate ullamcorper massa non ultrices. Morbi dapibus dignissim posuere. \
  //       Mauris maximus feugiat neque. Donec semper, dolor nec pellentesque tincidunt, \
  //       nisi dui suscipit urna, non egestas leo diam ut mauris. Nam euismod cursus dapibus. \
  //       Donec id diam porttitor justo feugiat eleifend in sed lorem. Phasellus arcu justo, blandit vitae ullamcorper ut, \
  //       consequat eget ante. Phasellus a nulla vel nunc pharetra congue. Nullam lacinia, diam quis malesuada bibendum, \
  //       tortor risus facilisis felis, eget consequat elit dolor ut massa. Morbi nibh enim, volutpat id pulvinar rhoncus, \
  //       facilisis sed lectus. Pellentesque sagittis, justo nec pharetra consequat, orci dui volutpat ipsum, \
  //       id rutrum enim odio in nisl. Vestibulum a rhoncus ante.'
  //   };

  //   return of(mock);
  //   // End MOCK
  // }

  // public getAfipData(documentNumber: string, gender: string): Observable<PostAfipResponseDTO> {
  //   const endpoint = `${environment.apiRootUrl}/persons/afip/user?documentNumber=${documentNumber}&gender=${gender}`;
  //   return this.http
  //     .get<PostAfipResponseDTO>(endpoint)
  //     .pipe(map(response => ({ ...response, fullName: response.fullName.trim() })));
  // }

  // public postUserData(signUpData: SignUpUserData): Observable<PostUserDataResponseDTO> {
  //   const endpoint = `${environment.apiRootUrl}/shopkeeper-login/clients/sign-up`;

  //   const { acceptConsent, firstName, middleName, lastName, ...payload } = signUpData;

  //   const newPayload = {
  //     fullName: firstName ? `${firstName} ${middleName} ${lastName}` : signUpData.fullName,
  //     ...payload
  //   };

  //   return this.http.post<{ [key: string]: string }>(endpoint, newPayload).pipe(
  //     map(response => {
  //       return {
  //         signUpId: response.signupId,
  //         challengeType: response.challengeType
  //       };
  //     })
  //   );
  // }

  // public postConfirmationToken(confirmationToken: string, signUpId: string): Observable<PostChallengeResponseDTO> {
  //   const endpoint = `${environment.apiRootUrl}/shopkeeper-login/clients/sign-up/${signUpId}/challenge`;
  //   const reqBody: PostChallengeRequestDTO = {
  //     challenge: {
  //       answer: confirmationToken
  //     },
  //     signupId: signUpId
  //   };
  //   return this.http.post<PostChallengeResponseDTO>(endpoint, reqBody);
  // }

  // public refreshConfirmationToken(signUpId: string): Observable<PostRefreshChallengeResponseDTO> {
  //   const endpoint = `${environment.apiRootUrl}/shopkeeper-login/clients/sign-up/${signUpId}/refresh-challenge`;
  //   return this.http.post<PostRefreshChallengeResponseDTO>(endpoint, {});
  // }

  // public postNewPassword(password: string, signUpId: string): Observable<PostWrapUpResponseDTO> {
  //   const endpoint = `${environment.apiRootUrl}/shopkeeper-login/clients/sign-up/${signUpId}/wrap-up`;
  //   return this.http.post<PostWrapUpResponseDTO>(endpoint, { password });
  // }
}
