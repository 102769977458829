<ngb-carousel [showNavigationArrows]="false" (click)="navIndicatorClicked($event)">
  <ng-template ngbSlide>
    <!-- <div class="picsum-img-wrapper">
            <img [src]="images[0]" alt="Random first slide" />
        </div> -->
    <div class="picsum-img-wrapper">
      <p>{{ 'CAROUSEL-TEXT-1' | translate }}</p>
    </div>
  </ng-template>

  <ng-template ngbSlide>
    <!-- <div class="picsum-img-wrapper">
            <img [src]="images[1]" alt="Random second slide" />
        </div> -->
    <div class="picsum-img-wrapper">
      <p>{{ 'CAROUSEL-TEXT-2' | translate }}</p>
    </div>
  </ng-template>

  <ng-template ngbSlide>
    <!-- <div class="picsum-img-wrapper">
            <img [src]="images[2]" alt="Random third slide" />
        </div> -->
    <div class="picsum-img-wrapper">
      <p>{{ 'CAROUSEL-TEXT-3' | translate }}</p>
    </div>
  </ng-template>
</ngb-carousel>
