import { Action } from '@ngrx/store';
import { DomainsRegister } from '@shared/models/domains.model';

export enum DomainsActionTypes {
  GET_DOMAINS_REGISTER = '[Domains] Get domains register',
  GET_DOMAINS_REGISTER_SUCCESS = '[Domains] Get domains register success',
  GET_DOMAINS_REGISTER_ERROR = '[Domains] Get domains register error',

  GET_TERMS = '[Domains] Get Terms',
  GET_TERMS_SUCCESS = '[Domains] Get Terms Success',
  GET_TERMS_ERROR = '[Domains] Get Terms Error',

  UPDATE_TERMS = '[Domains] Update Terms Request',
  UPDATE_TERMS_SUCCESS = '[Domains] Update Terms Success',
  UPDATE_TERMS_ERROR = '[Domains] Update Terms Error'
}

export class GetDomainsRegister implements Action {
  readonly type = DomainsActionTypes.GET_DOMAINS_REGISTER;
  constructor(public payload: any) {}
}

export class GetDomainsRegisterSuccess implements Action {
  readonly type = DomainsActionTypes.GET_DOMAINS_REGISTER_SUCCESS;
  constructor(public payload: DomainsRegister) {}
}

export class GetDomainsRegisterError implements Action {
  readonly type = DomainsActionTypes.GET_DOMAINS_REGISTER_ERROR;
  constructor(public payload: any) {}
}

export class GetTerms implements Action {
  readonly type = DomainsActionTypes.GET_TERMS;
  constructor() {}
}

export class GetTermsSuccess implements Action {
  readonly type = DomainsActionTypes.GET_TERMS_SUCCESS;
  constructor(public payload: DomainsRegister) {}
}

export class GetTermsError implements Action {
  readonly type = DomainsActionTypes.GET_TERMS_ERROR;
  constructor(public payload: any) {}
}

export class UpdateTerms implements Action {
  readonly type = DomainsActionTypes.UPDATE_TERMS;
  constructor() {}
}

export class UpdateTermsSuccess implements Action {
  readonly type = DomainsActionTypes.UPDATE_TERMS_SUCCESS;
  constructor(public payload: DomainsRegister) {}
}

export class UpdateTermsError implements Action {
  readonly type = DomainsActionTypes.UPDATE_TERMS_ERROR;
  constructor(public payload: any) {}
}

export type DomainsActions =
  | GetDomainsRegister
  | GetDomainsRegisterSuccess
  | GetDomainsRegisterError
  | GetTerms
  | GetTermsSuccess
  | GetTermsError
  | UpdateTerms
  | UpdateTermsSuccess
  | UpdateTermsError;
