import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalePercentagePipe } from '@app/pipes/locale-percentage.pipe';
import { environment } from '@env/environment';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { RegexConstants } from '@shared/services/form-validation/validators/regex.constants';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Negotiation, negotiationStatusEnum } from '../models/negotiation-detail.model';

@Component({
  selector: 'app-npp-negotiation-form',
  templateUrl: './npp-negotiation-form.component.html',
  styleUrls: ['./npp-negotiation-form.component.scss']
})
export class NppNegotiationFormComponent implements OnInit, OnDestroy, OnChanges {
  readonly NEGOTIATION_STATUS = negotiationStatusEnum;
  readonly PRODUCT_CODE = ProductCodeEnum;

  @Input() productCode: string;
  @Input() installmentValue: number;
  @Input() interestRate: number;
  @Input() retentionPercentage: number;
  @Input() negotiation: Negotiation;
  @Input() isDisabled: boolean;

  @Output() saveNppNegotiation = new EventEmitter();

  public form: FormGroup;
  public isNPP = JSON.parse(environment.useNPP);
  public showInstallmentValue = false;
  public showNegotiateRateRadio = false;
  public showRetentionPercentage = false;

  private ngUnsubscribe$ = new Subject();

  constructor(private fb: FormBuilder, private localePercentage: LocalePercentagePipe) {
    this.form = this.fb.group({
      negotiationType: ['interestRate', [Validators.required]],
      desiredInstallmentValue: [{ value: null, disabled: true }],
      approvedInstallmentValue: [{ value: null, disabled: true }],
      desiredRate: [{ value: null, disabled: true }, [Validators.required, Validators.pattern(RegexConstants.percent)]],
      approvedInterestRate: [{ value: null, disabled: true }],
      desiredRetentionPercentage: [{ value: null, disabled: true }],
      approvedRetentionPercentage: [{ value: null, disabled: true }]
    });
  }

  get negotiationType(): AbstractControl {
    return this.form.get('negotiationType');
  }

  ngOnDestroy() {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }

  ngOnInit() {
    this.handleFormState();
    this.setFormSubscription();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.isDisabled &&
      changes.isDisabled.currentValue !== null &&
      changes.isDisabled.currentValue !== undefined
    ) {
      this.handleFormDisable();
    }

    if (changes.installmentValue && changes.installmentValue.isFirstChange()) {
      this.form.get('desiredInstallmentValue').setValue(this.installmentValue);
    }

    if (changes.interestRate && changes.interestRate.isFirstChange()) {
      this.form.get('desiredRate').setValue(this.formatLocalePercentage(this.interestRate));
    }

    if (changes.retentionPercentage && changes.retentionPercentage.isFirstChange()) {
      this.form.get('desiredRetentionPercentage').setValue(this.formatLocalePercentage(this.retentionPercentage));
    }

    if (changes.negotiation) {
      this.handleNegotiationData();
    }
  }

  private handleFormState(): void {
    if (!this.useDynamicForm()) return;

    this.negotiationType.valueChanges.pipe(takeUntil(this.ngUnsubscribe$)).subscribe(value => {
      if (value === 'installmentValue') {
        this.handleFormInstallmentValue();
      } else if (value === 'interestRate') {
        this.handleFormInterestRate();
      } else if (value === 'retentionPercentage') {
        this.handleFormRetentionPercentage();
      }
    });
  }

  private handleFormInstallmentValue(): void {
    this.form.get('desiredInstallmentValue').setValidators([Validators.required]);
    this.form.get('desiredInstallmentValue').enable();
    this.form.get('desiredRate').disable();
    this.form.get('desiredRate').clearValidators();
    this.form.get('desiredRetentionPercentage').disable();
    this.form.get('desiredRetentionPercentage').clearValidators();
  }

  private handleFormInterestRate(): void {
    this.form.get('desiredInstallmentValue').disable();
    this.form.get('desiredInstallmentValue').clearValidators();
    this.form.get('desiredRate').enable();
    this.form.get('desiredRate').setValidators([Validators.required, Validators.pattern(RegexConstants.percent)]);
    this.form.get('desiredRetentionPercentage').disable();
    this.form.get('desiredRetentionPercentage').clearValidators();
  }

  private handleFormRetentionPercentage(): void {
    this.form.get('desiredInstallmentValue').disable();
    this.form.get('desiredInstallmentValue').clearValidators();
    this.form.get('desiredRate').disable();
    this.form.get('desiredRate').clearValidators();
    this.form.get('desiredRetentionPercentage').enable();
    this.form
      .get('desiredRetentionPercentage')
      .setValidators([Validators.required, Validators.pattern(RegexConstants.percent)]);
  }

  private handleNegotiationData(): void {
    this.form.patchValue({
      approvedInstallmentValue: this.negotiation.installmentValue,
      approvedInterestRate: this.formatLocalePercentage(this.negotiation.interestRate),
      approvedRetentionPercentage: this.formatLocalePercentage(this.negotiation.retentionPercentage)
    });
  }

  private handleFormDisable(): void {
    const formState = this.isDisabled ? 'disable' : 'enable';
    this.form[formState]();
  }

  public onNegotiate(): void {
    const payload = {
      installmentValue: this.form.value.desiredInstallmentValue,
      interestRate: this.form.value.desiredRate.replace(',', '.'),
      retentionPercentage: this.form.value.desiredRetentionPercentage.replace(',', '.'),
      negotiationType: this.negotiationType.value
    };
    this.saveNppNegotiation.emit(payload);
  }

  public setFormSubscription(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe$),
        filter(() => this.form.valid)
      )
      .subscribe(() => {
        const isInstallmentValueChanged =
          !this.form.controls.desiredInstallmentValue.disabled &&
          +this.form.controls.desiredInstallmentValue.value !== +this.form.controls.approvedInstallmentValue.value;

        const isInterestRateChanged =
          !this.form.controls.desiredRate.disabled &&
          +this.form.controls.desiredRate.value !== +this.form.controls.approvedInterestRate.value;

        const isRetentionPercentageChanged =
          !this.form.controls.desiredRetentionPercentage.disabled &&
          +this.form.controls.desiredRetentionPercentage.value !==
            +this.form.controls.approvedRetentionPercentage.value;

        if (isInstallmentValueChanged || isInterestRateChanged || isRetentionPercentageChanged) {
          this.isDisabled = false;
          return;
        }

        this.isDisabled = true;
      });
  }

  private useDynamicForm(): boolean {
    return this.showInstallmentValue && this.showNegotiateRateRadio && this.showRetentionPercentage;
  }

  private formatLocalePercentage(value: any): string {
    return this.localePercentage.transform(value, '0.2-8').slice(0, -1);
  }
}
