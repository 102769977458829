import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PanelProposalDTO } from '../../models/api/get.proposal-list.model';
import { OpenProposalService } from '../../services/open-proposal.service';

@Component({
  selector: 'app-proposal-table',
  templateUrl: './proposal-table.component.html',
  styleUrls: ['./proposal-table.component.scss']
})
export class ProposalTableComponent implements OnInit, OnDestroy {
  @Input() proposals: PanelProposalDTO[];
  @Input() openFinanceEnabled: boolean;

  @Output() handleProposalActionBtn = new EventEmitter();
  @Output() kebabAction = new EventEmitter();
  @Output() orderAction = new EventEmitter();

  private;
  values = [];
  private ordenationIconUp = 'chevron-up';
  private ordenationIconDown = 'chevron-down';
  public ordenationIcons = {
    type: this.ordenationIconDown,
    proposalValidityDate: this.ordenationIconDown,
    status: this.ordenationIconDown,
    valueFinancing: this.ordenationIconDown,
    clientName: this.ordenationIconDown
  };

  onDestroy: Subject<any> = new Subject();

  constructor(private proposalService: OpenProposalService, private genTagger: GenTagger) {}

  ngOnInit() {
    this.restartOrdenationListener();
    this.ordenationIcons['proposalValidityDate'] = this.ordenationIconUp;
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

  public onHandleProposalActionBtn(proposal: PanelProposalDTO): void {
    this.handleProposalActionBtn.emit(proposal);
  }

  public onKebabAction(code, id): void {
    this.kebabAction.emit({ code, id });
  }

  private restartOrdenationListener() {
    this.proposalService.restartOrdenation.pipe(takeUntil(this.onDestroy)).subscribe(resp => {
      if (!!resp) {
        this.values = [];
        Object.keys(this.ordenationIcons).forEach(item => {
          this.ordenationIcons[item] = this.ordenationIconDown;
        });
      }
    });
  }

  public order(sort: string): void {
    const item = {
      sort: sort,
      ordenation: ''
    };

    this.ordenationIcons[sort] =
      this.ordenationIcons[sort] === this.ordenationIconDown ? this.ordenationIconUp : this.ordenationIconDown;
    item.ordenation = this.ordenationIcons[sort] === this.ordenationIconDown ? 'desc' : 'asc';

    if (!!this.values.length) {
      const indexSort = this.values.findIndex(i => i.sort === sort);
      if (indexSort >= 0) {
        this.values[indexSort].ordenation = item.ordenation;
      } else {
        this.values.push(item);
      }
    } else {
      this.values.push(item);
    }

    this.orderAction.emit(this.values);
  }

  public onSort(opcaoSelecionada) {
    this.genTagger.setTag({
      event_category: '/portallojista/propostas',
      event_action: {
        event_action_type: Tag.Selecionar,
        event_action_complement: opcaoSelecionada
      },
      event_label: opcaoSelecionada
    });
  }
}
