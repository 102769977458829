<app-header-section [position]="pos.pos" [size]="pos.size" [titleSection]="title"> </app-header-section>

<div class="row">
  <div class="col-12 col-md-11">
    <ng-template #disableInput>
      <input matInput disabled />
    </ng-template>

    <div [formGroup]="form">
      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <mat-form-field>
            <mat-label class="label-col">{{ 'STEP-REGISTER-ZIP-CODE' | translate }}</mat-label>
            <app-custom-input
              [customMask]="maskZipCode"
              formControlName="zipCode"
              [required]="true"
              (change)="getAddressByZipCode()"
              (focus)="checkFocus('zipCode')"
              regexFilter
              regexFilterPattern="^[0-9]$"
              appTagger
              [event_action]="Insert"
              [event_category]="event_category"
              event_label="cep"
            ></app-custom-input>
            <mat-error *ngIf="form.get('zipCode').touched && form.get('zipCode').invalid">{{
              'STEP-REGISTER-ZIPCODE-ERROR' | translate
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-12 mb-4">
          <mat-form-field class="form-input-lg">
            <mat-label class="label-col">{{ 'STEP-REGISTER-ADDRESS' | translate }}</mat-label>
            <app-custom-input
              (focus)="checkFocus('street')"
              [required]="true"
              formControlName="street"
              appTagger
              [event_action]="Insert"
              [event_category]="event_category"
              event_label="endereço"
            ></app-custom-input>
            <mat-error *ngIf="form.get('street').touched && form.get('street').invalid">{{
              'STEP-REGISTER-ADDRESS-ERROR' | translate
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mb-4">
          <div class="row">
            <div class="col-md-4">
              <mat-form-field>
                <mat-label class="label-col">{{ 'STEP-REGISTER-NUMBER' | translate }}</mat-label>
                <app-custom-input
                  [required]="true"
                  formControlName="number"
                  (input)="checkNumber()"
                  regexFilter
                  regexFilterPattern="^[0-9]$"
                  appTagger
                  [event_action]="Insert"
                  [event_category]="event_category"
                  event_label="número"
                >
                </app-custom-input>
                <mat-error *ngIf="form.get('number').touched && form.get('number').invalid">{{
                  'STEP-REGISTER-NUMBER-ERROR' | translate
                }}</mat-error>
              </mat-form-field>
            </div>
            <div class="col-md-8">
              <mat-form-field>
                <mat-label class="label-col">{{ 'STEP-REGISTER-COMPLEMENT' | translate }}</mat-label>
                <app-custom-input
                  (focus)="checkFocus('complement')"
                  formControlName="complement"
                  appTagger
                  [event_action]="Insert"
                  [event_category]="event_category"
                  event_label="complemento"
                >
                </app-custom-input>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-6 mb-4">
          <mat-form-field>
            <mat-label class="label-col">{{ 'STEP-REGISTER-NEIGHBORHOOD' | translate }}</mat-label>
            <app-custom-input
              (focus)="checkFocus('neighborhood')"
              [required]="true"
              formControlName="neighborhood"
              appTagger
              [event_action]="Insert"
              [event_category]="event_category"
              event_label="bairro"
            >
            </app-custom-input>
            <mat-error *ngIf="form.get('neighborhood').touched && form.get('neighborhood').invalid">{{
              'STEP-REGISTER-NEIGHBORHOOD-ERROR' | translate
            }}</mat-error>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6 mb-4" *ngIf="comboParams?.states as states">
          <mat-form-field>
            <mat-label class="label-col">{{ 'STEP-REGISTER-STATE' | translate }}</mat-label>
            <app-search-input
              valueFrom="id"
              label="id"
              [items]="states | orderBy: 'id'"
              formControlName="state"
              (changeOut)="onStateChange()"
              (focus)="checkFocus('state')"
            ></app-search-input>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6 mb-4" *ngIf="cities$ | async as cities; else citiesEmpty">
          <mat-form-field>
            <mat-label class="label-col">{{ 'STEP-REGISTER-CITY' | translate }}</mat-label>
            <app-search-input
              valueFrom="id"
              label="description"
              [items]="cities | orderBy: 'description'"
              formControlName="cityId"
              (focus)="checkFocus('cityId')"
              (changeOut)="onCityChange()"
            ></app-search-input>
          </mat-form-field>
        </div>
        <ng-template #citiesEmpty>
          <div class="col-12 col-md-6 mb-4">
            <mat-form-field>
              <mat-label class="label-col">{{ 'STEP-REGISTER-CITY' | translate }}*</mat-label>
              <input matInput disabled />
            </mat-form-field>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
