import { Component, EventEmitter, Input, OnChanges, OnInit, Output, Renderer2, SimpleChanges } from '@angular/core';
import { DocumentScanModel } from '@app/document-scan/models/document-scan.enum';
import { DocumentSidePeruSDK, DocumentTypePeruSDK } from '@app/document-scan/models/peru-sdk.enums';
import { environment } from '@env/environment';

declare const window: any;

@Component({
  selector: 'app-per-document-scan',
  templateUrl: './per-document-scan.component.html',
  styleUrls: ['./per-document-scan.component.scss']
})
export class PerDocumentScanComponent implements OnInit, OnChanges {
  @Input() sessionId = '';
  @Input() documentSide: DocumentSidePeruSDK = DocumentSidePeruSDK.FRONT;
  @Input() documentType: DocumentTypePeruSDK = DocumentTypePeruSDK.PE1;

  @Output() scanOutput: EventEmitter<DocumentScanModel> = new EventEmitter();

  private documentResponse: DocumentScanModel = new DocumentScanModel();

  constructor(private renderer: Renderer2) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.sessionId && this.documentSide && this.documentType) {
      this.loadPeruSDK();
    }
  }

  loadPeruSDK(): void {
    this.addJsToElement(environment.perAutoCaptureSDK, 'perusdkautocapture').onload = () => {
      window.TOCautocapture('peruSdkContainer', {
        locale: 'es',
        session_id: this.sessionId,
        document_type: this.documentType,
        document_side: this.documentSide,
        callback: this.peruSDKSucessCallback.bind(this),
        failure: this.peruSDKFailureCallback.bind(this)
      });
    };
  }

  addJsToElement(src: string, id: string): HTMLScriptElement {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = id;
    script.src = src;
    this.renderer.appendChild(document.body, script);
    return script;
  }

  peruSDKSucessCallback(token, picture) {
    this.documentResponse = {
      ...this.documentResponse,
      images: [picture],
      token: token
    };
    this.scanOutput.emit(this.documentResponse);
  }

  peruSDKFailureCallback(error) {
    this.documentResponse = {
      ...this.documentResponse,
      error: error
    };
    this.scanOutput.emit(this.documentResponse);
  }
}
