<div class="container biometry-body mb-2">
  <div class="row justify-content-center" *ngIf="!isLoadingMobile">
    <div class="col-12 col-md-8">
      <app-arrow-back
        (click)="goback()"
        *ngIf="(isCrossedFlux && (cameraOn || errorMessage)) || !isCrossedFlux"
      ></app-arrow-back>
      <div class="row" *ngIf="!cameraOn && !tipsCarousel && !errorMessage && !showStatusFinished">
        <div class="col body-page">
          <div class="row">
            <div class="col text-left mb-4">
              <span class="title" *ngIf="flowType != 'sign-up'">
                {{ 'RECOVER-PASSWORD-BIOMETRY-TITLE' | translate }}
              </span>
              <span
                class="title"
                *ngIf="flowType == 'sign-up'"
                appTagger
                event_label="tirar selfie"
                event_action="Click"
              >
                Tire uma selfie
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col text-left mb-4">
              <span class="sub-title" *ngIf="flowType != 'sign-up'">
                {{ 'RECOVER-PASSWORD-BIOMETRY-DESCRIPTION' | translate }}
              </span>
              <span class="sub-title" *ngIf="flowType == 'sign-up'">
                A foto é sua assinatura eletrônica e será usada quando for necessário confirmar sua identidade. Como
                prefere tirar sua selfie?
              </span>
            </div>
          </div>

          <mat-card>
            <div class="row">
              <div class="col text-center">
                <qrcode *ngIf="qrCodeLink" [qrdata]="qrCodeLink" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
              </div>
            </div>

            <div class="row">
              <div class="col text-left pl-5 mb-3">
                <span class="titleQR">
                  {{ 'BIOMETRY-QRCODE-TITLE' | translate }}
                </span>
              </div>
            </div>

            <div class="row">
              <div class="col text-left pl-5 mb-3">
                <span class="sub-title">
                  {{ 'BIOMETRY-QRCODE-SUBTITLE' | translate }}
                </span>
              </div>
            </div>

            <div class="row">
              <div class="col text-center mb-3 mt-3">
                <span>
                  {{ 'RECOVER-PASSWORD-BIOMETRY-OR' | translate }}
                </span>
              </div>
            </div>
            <div class="row">
              <div class="col text-center txt-color mb-4">
                <button mat-stroked-button (click)="goToCarousel()">
                  {{ 'RECOVER-PASSWORD-BIOMETRY-TAKE-PHOTO-DESKTOP' | translate }}
                </button>
              </div>
            </div>
          </mat-card>
        </div>
      </div>

      <div class="row" *ngIf="tipsCarousel">
        <div class="col body-page">
          <div class="row">
            <div class="col text-left mb-4">
              <span class="title">
                {{ 'TAKE-A-SELFIE' | translate }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col text-left mb-4">
              <span class="sub-title">
                {{ 'TIPS-BIOMETRY-TITLE' | translate }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col text-left mb-5">
              <app-biometry-instructions
                [tutorial]="tutorialSteps"
                (finishButtonEmitter)="goToCameraPage()"
              ></app-biometry-instructions>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="cameraOn">
        <div class="col-12 text-left">
          <span class="title">
            {{ 'TAKE-A-SELFIE' | translate }}
          </span>
        </div>
        <div class="col">
          <app-biometry *ngIf="showFacial"></app-biometry>
        </div>
      </div>

      <div class="row" *ngIf="showStatusFinished">
        <div class="col" *ngIf="isCrossedFluxSuccess">
          <div class="">
            <img class="mx-auto d-block mb-3" width="50px" src="./assets/images/ic_check.svg" alt="" />
          </div>
          <div class="text-center font-weight-bold">Tudo certo!</div>
        </div>
        <div class="col" *ngIf="!isCrossedFluxSuccess">
          <div class="">
            <mat-icon class="mb-3 mx-auto d-block modal-icon-error">cancel</mat-icon>
          </div>
          <div class="text-center font-weight-bold pt-3 mb-2">
            {{ 'MODAL-ERROR-RETRY-CHALLENGE-BIOMETRY-PHOTO-TITLE' | translate }}
          </div>
          <div class="text-center">{{ 'MODAL-ERROR-RETRY-CHALLENGE-BIOMETRY-PHOTO-DESCRIPTION' | translate }}</div>
        </div>
      </div>

      <div class="row" *ngIf="errorMessage">
        <div class="info-message">
          <p class="title">{{ 'BIOMETRY-ERROR-TITLE' | translate }}</p>
          <p class="text">
            {{ 'BIOMETRY-ERROR-DESCRIPTION' | translate }}
          </p>
          <p class="hotline">{{ 'BIOMETRY-ERROR-HOTLINE' | translate }}</p>
          <p class="hotline">{{ 'BIOMETRY-ERROR-CONTACT' | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
