import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { StepConfirmationComponent } from '@app/proposal-workflow/containers/step-confirmation/step-confirmation.component';
// import { StepSimulationComponent } from '@app/proposal-workflow/containers/step-simulation/step-simulation.component';
import { StepFormalizationComponent } from '@app/proposal-workflow/containers/step-formalization/step-formalization.component';
import { ProposalWorkflowContainerComponent } from '@app/proposal-workflow/proposal-workflow.container';
// import { BestOfferLoadingComponent } from './components/best-offer-loading/best-offer-loading.component';
import { ApplicationHeaders } from '@shared/components/ui/header/header.models';
import { Countries } from '@shared/constants/countries.enum';
import { BestOfferErrorComponent } from './components/best-offer-error/best-offer-error.component';
import { SimulationSummaryComponent } from './components/simulation-summary/simulation-summary.component';
import { StepOffersContainer } from './containers/step-offers/step-offers.container';
import { StepRegisterContainerComponent } from './containers/step-register/step-register-container.component';
import { StepSimulationContainer } from './containers/step-simulation/step-simulation.container';

const routes: Routes = [
  {
    path: 'best-offer-error',
    component: BestOfferErrorComponent
  },
  {
    path: 'step-offer',
    component: StepOffersContainer,
    data: {
      country: Countries.BR,
      header: ApplicationHeaders.Headless
    }
  },
  {
    path: '',
    component: ProposalWorkflowContainerComponent,
    children: [
      {
        path: 'step-simulation',
        component: StepSimulationContainer,
        data: {
          country: Countries.BR,
          header: ApplicationHeaders.Headless
        }
      },
      {
        path: 'step-simulation/summary',
        component: SimulationSummaryComponent,
        data: {
          country: Countries.BR,
          header: ApplicationHeaders.Headless
        }
      },
      {
        path: 'step-register',
        component: StepRegisterContainerComponent,
        data: {
          header: ApplicationHeaders.Headless
        }
      },
      {
        path: 'step-formalization',
        component: StepFormalizationComponent,
        data: {
          header: ApplicationHeaders.Headless
        }
      },
      {
        path: 'step-confirmation',
        component: StepConfirmationComponent,
        data: {
          header: ApplicationHeaders.Headless
        }
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProposalWorkflowRoutingModule { }
