import { createSelector } from '@ngrx/store';
import { selectManagementPanelFeatureState } from '../feature-selector';

export const profileSelect = createSelector(
  selectManagementPanelFeatureState,
  state => {
    return state.profile;
  }
);

export const menuSelector = createSelector(
  profileSelect,
  state => state.menus
);

export const userProfileSelector = createSelector(
  profileSelect,
  state => state.userProfile
);

export const userProfileTypesSelector = createSelector(
  profileSelect,
  state => {
    const { isGcOrGr, isMasterShopkeeper } = state;

    return { isGcOrGr, isMasterShopkeeper };
  }
);

export const userPreferencesSelector = createSelector(
  profileSelect,
  // state => state.userPreferences
  state => state.userPreferences.homePage
);

// NOTE: - Se redundante refatorar posteriormente
export const homePageSelector = createSelector(
  profileSelect,
  state => state.homePage
);
