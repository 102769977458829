<!-- AGENT ADDITIONAL -->
<div class="row">
  <div class="col-12 col-md-12">
    <!-- INFO INITIAL -->
    <div class="p-4 card-p-mobile" *ngIf="!actionAgent">
      <h3>
        <b *ngIf="currentPlatform.isDesktop">{{ 'FORMALIZATION-STEP-AGENT-LEGAL' | translate }}</b>
        <b *ngIf="currentPlatform.isMobile">{{ 'FORMALIZATION-STEP-AGENT-LEGAL_MOBILE' | translate }}</b>
      </h3>
      <br />
      <h3>
        {{ 'FORMALIZATION-STEP-AGENT-LEGAL-PRE-DESCRIPTION' | translate }} <b>{{ nameClient }}</b>
        {{ 'FORMALIZATION-STEP-AGENT-LEGAL-DESCRIPTION' | translate }}
      </h3>
      <br />

      <div class="row actions">
        <div class="col-12 col-md-12 mb-3">
          <app-outlined-button (click)="initializeAgents(true)" appTagger [event_category]="category"
            event_label="{{ 'FORMALIZATION-STEP-ADD-AGENTS' | translate }}">
            {{ 'FORMALIZATION-STEP-ADD-AGENTS' | translate }}
          </app-outlined-button>
        </div>
        <div class="col-12 col-md-12 mb-3">
          <app-filled-button (click)="noAdditionalAgents()" appTagger [event_category]="category"
            event_label="{{ 'FORMALIZATION-STEP-NO-AGENTS' | translate }}">
            {{ 'FORMALIZATION-STEP-NO-AGENTS' | translate }}
          </app-filled-button>
        </div>
      </div>
    </div>

    <div *ngIf="form && actionAgent" [formGroup]="form">
      <div class="row p-4 card-action-mobile">
        <!-- BTN ADD AGENT -->
        <div class="row action-agent">
          <div class="col-12 col-md-12 mb-3">
            <div *ngIf="currentPlatform.isDesktop || (currentPlatform.isMobile && !isConfirmQtdAgent)">
              <h3>
                <b>{{ 'FORMALIZATION-STEP-TEXT-QTD-AGENTS' | translate }}</b>
              </h3>
              <br />
              <h3>
                {{ 'FORMALIZATION-STEP-AGENT-LEGAL-SELECT-PRE-DESCRIPTION' | translate }} <b>{{ nameClient }}</b>
                {{ 'FORMALIZATION-STEP-AGENT-LEGAL-SELECT-DESCRIPTION' | translate }}
              </h3>
              <br />
              <h3>{{ 'FORMALIZATION-STEP-TEXT-QTD-AGENTS' | translate }}</h3>

              <button mat-button class="mat-elevation-z0 border border-ligth">
                {{ form?.get('additionalAgentList')['controls']?.length }}</button>&nbsp;
              <button mat-mini-fab aria-label="Remover" class="mat-elevation-z0 action"
                (click)="deleteAgentAdditional()"
                [disabled]="form?.get('additionalAgentList')['controls']?.length < 2 || isReturningToEdit" appTagger
                [event_category]="category" event_label="remover representante">
                <mat-icon>remove</mat-icon></button>&nbsp;

              <button mat-mini-fab aria-label="Adicionar" class="mat-elevation-z0 action" (click)="addAgent()"
                [disabled]="form?.get('additionalAgentList')['controls']?.length >= qtdAgents || isReturningToEdit"
                appTagger [event_category]="category" event_label="adicionar representante">
                <mat-icon>add</mat-icon>
              </button>
            </div>

            <!-- MSG LINK EMAIL -->
            <div class="info-link mt-4" *ngIf="showInfoMessage()">
              <div class="row">
                <div class="col-1 d-flex justify-content-center">
                  <mat-icon>error</mat-icon>
                </div>
                <div class="col-11">
                  <p>{{ 'FORMALIZATION-STEP-MSG-LINK' | translate }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- ADD AGENT ADDITIONAL -->
        <div *ngIf="currentPlatform.isDesktop">
          <div class="form-array" formArrayName="additionalAgentList"
            *ngFor="let item of form.get('additionalAgentList')['controls']; let i = index">
            <div [formGroupName]="i">
              <div class="row">
                <div class="col-12 col-md-12 mb-4">
                  <b *ngIf="i === 0">{{ 'FORMALIZATION-STEP-SECOND-AGENT-LEGAL' | translate }}</b>
                  <b *ngIf="i === 1">{{ 'FORMALIZATION-STEP-THIRD-AGENT-LEGAL' | translate }}</b>
                </div>

                <div class="col-12 col-md-6 mb-4">
                  <mat-form-field>
                    <mat-label> {{ 'ESIGN-NAME' | translate }} </mat-label>
                    <app-custom-input class="input-with-icon" [required]="true" formControlName="name"
                      (change)="onRpoChange(i, 'nome')"></app-custom-input>
                    <mat-error *ngIf="item.get('name').touched && item.get('name').invalid">{{
                      'STEP-REGISTER-NAME-ERROR' | translate
                      }}</mat-error>
                  </mat-form-field>
                </div>

                <div class="col-12 col-md-6 mb-4">
                  <mat-form-field>
                    <mat-label> {{ 'STEP-REGISTER-DOCUMENT-NUMBER' | translate }} </mat-label>
                    <app-custom-input [required]="true" [customMask]="$any(pioneerMasks).documentNumber.basicMask"
                      formControlName="documentNumber" (change)="onRpoChange(i, 'cpf')"></app-custom-input>
                  </mat-form-field>
                </div>
              </div>

              <div class="row">
                <div class="col-12 col-md-6 mb-4">
                  <mat-form-field>
                    <mat-label> {{ 'EMAIL' | translate }} *</mat-label>
                    <app-custom-input formControlName="email" (change)="onRpoChange(i, 'e-mail')"> </app-custom-input>
                    <mat-error *ngIf="item.get('email').touched && item.get('email').invalid">{{
                      'STEP-REGISTER-EMAIL-ERROR' | translate
                      }}</mat-error>
                  </mat-form-field>
                </div>
                <div class="col-12 col-md-6 mb-4">
                  <mat-form-field>
                    <mat-label> {{ 'Cell phone' | translate }} *</mat-label>
                    <app-custom-input [customMask]="$any(pioneerMasks).cellPhone.mask" formControlName="cellPhone"
                      (change)="onRpoChange(i, 'cellPhone')">
                    </app-custom-input>
                    <mat-error *ngIf="!form?.get('cellPhone')?.valid || form?.get('cellPhone')?.touched">
                      {{ 'INVALID-CELL-PHONE' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div *ngIf="currentPlatform.isMobile && isConfirmQtdAgent">
          <owl-carousel-o [options]="carouselOptions" formArrayName="additionalAgentList" #agentsCarousel
            (changed)="changeOnSlider($event)">
            <ng-container *ngFor="let item of form.get('additionalAgentList')['controls']; let i = index">
              <ng-template carouselSlide>
                <div [formGroupName]="i" *ngIf="i === nextForm">
                  <div class="row">
                    <div class="col-12 col-md-12 mb-4">
                      <b *ngIf="i === 0">{{ 'FORMALIZATION-STEP-SECOND-AGENT-LEGAL' | translate }}</b>
                      <b *ngIf="i === 1">{{ 'FORMALIZATION-STEP-THIRD-AGENT-LEGAL' | translate }}</b>
                    </div>

                    <div class="col-12 col-md-6 mb-4">
                      <mat-form-field>
                        <mat-label> {{ 'ESIGN-NAME' | translate }} </mat-label>
                        <app-custom-input class="input-with-icon" [required]="true"
                          formControlName="name"></app-custom-input>
                        <mat-error *ngIf="item.get('name').touched && item.get('name').invalid">{{
                          'STEP-REGISTER-NAME-ERROR' | translate
                          }}</mat-error>
                      </mat-form-field>
                    </div>

                    <div class="col-12 col-md-6 mb-4">
                      <mat-form-field>
                        <mat-label> {{ 'STEP-REGISTER-DOCUMENT-NUMBER' | translate }} </mat-label>
                        <app-custom-input [required]="true" [customMask]="$any(pioneerMasks).documentNumber.basicMask"
                          formControlName="documentNumber"></app-custom-input>
                      </mat-form-field>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-12 col-md-6 mb-4">
                      <mat-form-field>
                        <mat-label> {{ 'EMAIL' | translate }}</mat-label>
                        <app-custom-input formControlName="email"> </app-custom-input>
                        <mat-error *ngIf="item.get('email').touched && item.get('email').invalid">{{
                          'STEP-REGISTER-EMAIL-ERROR' | translate
                          }}</mat-error>
                      </mat-form-field>
                    </div>
                    <div class="col-12 col-md-6 mb-4">
                      <mat-form-field>
                        <mat-label> {{ 'Cell phone' | translate }} *</mat-label>
                        <app-custom-input [customMask]="$any(pioneerMasks).cellPhone.mask" formControlName="cellPhone">
                        </app-custom-input>
                        <mat-error *ngIf="!form?.get('cellPhone')?.valid ||form?.get('cellPhone')?.touched">
                          {{ 'INVALID-CELL-PHONE' | translate }}
                        </mat-error>
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ng-container>
          </owl-carousel-o>

          <div class="row">
            <ng-container>
              <div class="col-6" *ngIf="nextForm === 0">
                <button class="carousel-prev" [disabled]="isReturningToEdit" (click)="confirmQtdAgent(false)">
                  {{ 'FORMALIZATION-STEP-BACK' | translate }}
                </button>
              </div>
              <div class="col-6" *ngIf="nextForm === 0 && this.form.get('additionalAgentList')['controls'].length > 1">
                <button class="carousel-next" [disabled]="this.form.get('additionalAgentList')['controls'][0].invalid"
                  (click)="nextStepCarousel()">
                  {{ 'Next' | translate }}
                </button>
              </div>

              <div class="col-6" *ngIf="nextForm === 1">
                <button class="carousel-prev" (click)="prevStepCarousel()">
                  {{ 'Previous' | translate }}
                </button>
              </div>
              <div class="col-6"
                *ngIf="nextForm === 1 || this.form.get('additionalAgentList')['controls'].length === 1">
                <button class="carousel-next" [disabled]="form.invalid" (click)="signature()">
                  {{ 'FORMALIZATION-SIGNATURE-BTN' | translate }}
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="row actions" *ngIf="currentPlatform.isDesktop">
        <div class="col-12 col-md-6 mb-3">
          <app-outlined-button (click)="initializeAgents(false)" [disabled]="isReturningToEdit" appTagger
            [event_category]="category" event_label="{{ 'FORMALIZATION-STEP-BACK' | translate }}">
            {{ 'FORMALIZATION-STEP-BACK' | translate }}
          </app-outlined-button>
        </div>
        <div class="col-12 col-md-6 mb-3">
          <app-filled-button [disabled]="form.invalid" (click)="signature()" appTagger [event_category]="category"
            event_label="{{ 'FORMALIZATION-STEP-SIGNATURE-PROPOSAL' | translate }}">
            {{ 'FORMALIZATION-STEP-SIGNATURE-PROPOSAL' | translate }}
          </app-filled-button>
        </div>
      </div>

      <div class="row actions" *ngIf="currentPlatform.isMobile && !isConfirmQtdAgent">
        <div class="col-12 col-md-12 mb-3">
          <app-filled-button (click)="confirmQtdAgent(true)">
            {{ 'FORMALIZATION-STEP-CONFIRM-QTD-AGENTS' | translate }}
          </app-filled-button>
        </div>
      </div>
    </div>
  </div>
</div>