export enum SubsegmentsEnum {
  TOURISM = '301'
}

export const SubsegmentImagesMap = {
  4: './assets/viagem.svg',
  14: './assets/construcao.svg',
  5: './assets/tecnologia.svg',
  7: './assets/saude.svg',
  37: './assets/energia.svg',
  1: './assets/decoracao.svg'
};
