import { Component, Input, OnInit } from '@angular/core';
import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { Subject, timer } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'bottom-sheet-sumary',
  templateUrl: './bottom-sheet-sumary.component.html',
  styleUrls: ['../bottom-sheet/bottom-sheet.component.scss', './bottom-sheet-sumary.component.scss']
})
export class BottomSheetSumaryComponent implements OnInit {
  @Input() public proposal: ConfirmationData;
  @Input() public paymentType: any;
  @Input() public currentUrl: string;
  @Input() public show: boolean;

  public financedValue: number;

  /**
   * Countdown variables
   */
  private readonly stopTimer$ = new Subject<void>();
  private readonly startTimer$ = new Subject<void>();
  timeInterval = 5;
  secondsLeft: number;
  showInitialFee = false;
  showTransition: boolean;
  hideTransition: boolean;
  countIsInProgress = false;

  public paymentPlanType = PaymentPlanType;
  public personsType = SubmitPersonType;

  constructor() {}

  ngOnInit() {
    this.financedValue = Number(this.proposal.financedValue) - Number(this.proposal.entryValue);
  }

  private startTimer(): void {
    this.timeInterval = 5;
    this.setUpTimer();
    this.startTimer$.next();
  }

  private stopTimer(): void {
    this.showInitialFee = !this.showInitialFee;
    this.stopTimer$.next();
    this.countIsInProgress = false;
    this.startTimer();
  }

  /**
   * Configures timer for countdown
   */
  private setUpTimer(): void {
    if (this.countIsInProgress) return;
    timer(0, 1000)
      .pipe(
        map(second => this.timeInterval - second),
        takeUntil(this.stopTimer$),
        take(this.timeInterval + 1)
        // repeatWhen(() => this.startTimer$)
      )
      .subscribe(decreasedTime => {
        this.countIsInProgress = true;
        this.secondsLeft = decreasedTime;
        if (decreasedTime < 0) {
          this.stopTimer();
        } else {
          this.verifyTimeLeft();
        }
      });
  }

  private verifyTimeLeft(): void {
    if (this.secondsLeft === 1) {
      this.hideTransition = true;
    }
    if (this.secondsLeft === 0) {
      this.hideTransition = false;
      this.stopTimer();
    }
  }

  public isTaxShown(): boolean {
    if (this.proposal && this.proposal.proposalData) {
      const product = this.proposal.proposalData.product; // CP, CDC, CSC
      const client = this.proposal.proposalData.client; // PF ou PJ
      return (
        (product === ProductCodeEnum.CP && client === SubmitPersonType.PF) ||
        (product === ProductCodeEnum.CDC && client === SubmitPersonType.PF) ||
        (product === ProductCodeEnum.CDC && client === SubmitPersonType.PJ)
      );
    }
  }
}
