import { FaqComponent } from './../faq/faq.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationHeaders } from '@shared/components/ui/header/header.models';

const routes: Routes = [
  {
    path: '',
    component: FaqComponent
  },
  {
    path: 'login',
    component: FaqComponent,
    data: {
      header: ApplicationHeaders.Login
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class FaqRoutingModule {}
