import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaymentShowcaseModel } from '@app/showcase/payments/models/payment.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ProposalReasonCancelDialogComponent } from '../proposal-reason-cancel-dialog/proposal-reason-cancel-dialog.component';

@Component({
  selector: 'app-proposal-massive-cancel-dialog',
  templateUrl: './proposal-massive-cancel-dialog.component.html',
  styleUrls: ['./proposal-massive-cancel-dialog.component.scss']
})
export class ProposalMassiveCancelDialogComponent implements OnInit {
  @Input() data: PaymentShowcaseModel[];
  @Input() category: string;
  @Output() reloadListPayments = new EventEmitter<boolean>();

  public window = window;
  public sumCancelValues: number;
  public cancelSuccess = false;
  public countSuccessCancel: PaymentShowcaseModel[] = [];
  public sumSuccessCancel: number;
  public countUnsuccessfulCancel: PaymentShowcaseModel[] = [];
  public sumUnsuccessfulCancel: number;
  readonly closeAction = `${Tag.Fechou_Modal} - ${this.translateService.instant('PROPOSALS-CANCEL-TITLE')}`;

  constructor(
    public activeModal: NgbActiveModal,
    private dialog: MatDialog,
    private genTagger: GenTagger,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.sumCancelValues = this.sumValues();
    this.genTagger.setTag({
      event_category: this.category,
      event_label: this.translateService.instant('PROPOSALS-CANCEL-TITLE'),
      event_action: `${Tag.AbriuModal} - ${this.translateService.instant('PROPOSALS-CANCEL-TITLE')}`
    });
  }

  private sumValues(status?: string): number {
    let sumValues = 0;
    this.data.forEach((item: PaymentShowcaseModel) => {
      sumValues = sumValues + item.value;
      // if (!status && !!item.value) {
      //   sumValues = sumValues + item.value;
      // } else if (status === 'successful' && !!item.successPayment && !!item.value) {
      //   sumValues = sumValues + item.value;
      // } else if (status === 'unsuccessful' && !item.successPayment && !!item.value) {
      //   sumValues = sumValues + item.value;
      // }
    });
    return sumValues;
  }

  public confirm() {
    this.dialog
      .open(ProposalReasonCancelDialogComponent, {
        width: '560px',
        autoFocus: false,
        data: {
          routedBy: 'payments',
          isMassive: true,
          list: this.data
        }
      })
      .afterClosed()
      .subscribe(res => {
        if (!!res && !!res.length) {
          this.data.forEach((item: PaymentShowcaseModel) => {
            const sameResItem = res.find((i: any) => i.id === item.id);
            if (!!sameResItem) {
              item.successCancel = sameResItem.isSuccess;
              item.statusDescription = sameResItem.status;
              item.statusTooltipMessage = sameResItem.message;
              item.statusIcon = 'ic_clock.svg';
              if (!item.successCancel) {
                item.statusIcon = 'ic_warning_red.svg';
              }
            }
          });
          this.cancelSuccess = true;
          this.successfulCancel();
          this.unsuccessfulCancel();
          this.reloadListPayments.emit(true);
        }
      });
  }

  private successfulCancel(): void {
    this.countSuccessCancel = this.data.filter((item: PaymentShowcaseModel) => !!item.successCancel);
    this.sumSuccessCancel = this.sumValues('successful');
  }

  private unsuccessfulCancel(): void {
    this.countUnsuccessfulCancel = this.data.filter((item: PaymentShowcaseModel) => !item.successCancel);
    this.sumUnsuccessfulCancel = this.sumValues('unsuccessful');
  }
}
