import { DomainsActions, DomainsActionTypes } from '@app/core/state/domains/domains.actions';
import { Domains } from '@shared/models/domains.model';

export interface DomainsState {
  genders?: Domains[];
  addressTypes?: Domains[];
  federationUnits?: Domains[];
  nationalities?: Domains[];
  banks?: Domains[];
  accountTypes?: Domains[];
  companyTypes?: Domains[];
  economicActivityGroups?: Domains[];
  civilStates?: Domains[];
  certifiedAgents?: Domains[];
  legalNatures?: Domains[];
  companyRelationshipTypes?: Domains[];
  terms: object;
}

export const initialDomainsState: DomainsState = {
  genders: [],
  federationUnits: [],
  nationalities: [],
  banks: [],
  accountTypes: [],
  companyTypes: [],
  economicActivityGroups: [],
  civilStates: [],
  certifiedAgents: [],
  legalNatures: [],
  addressTypes: [],
  companyRelationshipTypes: [],
  terms: null
};

export function domainsReducer(state = initialDomainsState, action: DomainsActions): DomainsState {
  switch (action.type) {
    case DomainsActionTypes.GET_DOMAINS_REGISTER_SUCCESS: {
      return {
        ...state,
        genders: action.payload.genders,
        federationUnits: action.payload.federationUnits,
        nationalities: action.payload.nationalities,
        banks: action.payload.banks,
        accountTypes: action.payload.accountTypes,
        companyTypes: action.payload.companyTypes,
        economicActivityGroups: action.payload.economicActivityGroups,
        civilStates: action.payload.civilStates,
        certifiedAgents: action.payload.certifiedAgents,
        legalNatures: action.payload.legalNatures,
        addressTypes: action.payload.addressTypes,
        companyRelationshipTypes: action.payload.companyRelationshipTypes
      };
    }

    case DomainsActionTypes.GET_TERMS_SUCCESS: {
      return {
        ...state,
        terms: action.payload
      };
    }

    default:
      return state;
  }
}
