import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CryptoService } from '@app/core/crypto/crypto.service';
import * as loginActions from '@app/core/state/login/login.actions';
import { selectSelectedTab } from '@app/core/state/tab/tab.selectors';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { Observable } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { AppState } from './../../state/index';
import { GetClientCredentialsSuccessAction, VerifyAccess } from './../../state/startup/startup.actions';
import { UserData } from './../models/user.model';
const httpOptions = {
  headers: new HttpHeaders({
    Authorization: 'Basic ' + environment.apiKeyApiSecret,
    'Content-Type': 'application/x-www-form-urlencoded'
  })
};

export interface TokenResponse {
  scope?: string;
  tokenType?: string;
  expiresIn?: number;
  refreshToken?: string;
  accessToken?: string;
  stores?: string;
  auth2Data?: { is2Auth: boolean };
}

@Injectable({
  providedIn: 'root'
})
export class StartupService {
  MOCK_SERVER_URL = 'http://localhost:8080/api';
  readonly TOKEN_PARAMETER = 't';
  readonly REFRESH_TOKEN_PARAMETER = 'rt';
  readonly DOCUMENT = 'd';

  constructor(
    private http: HttpClient,
    private store$: Store<AppState>,
    private cryptoService: CryptoService,
    private sessionStorageService: SessionStorageService
  ) {}

  startup() {
    if (JSON.parse(environment.crypto.active)) {
      return this.cryptoService.start().pipe(switchMap(() => this.getToken().toPromise()));
    } else {
      return this.getToken().toPromise();
    }
  }

  getToken() {
    return this.requestToken().pipe(
      tap((response: any) => {
        const token: string = this.getParameterByName(this.TOKEN_PARAMETER);
        const refreshtoken: string = this.getParameterByName(this.REFRESH_TOKEN_PARAMETER);
        const document: string = this.getParameterByName(this.DOCUMENT);

        this.store$.dispatch(new loginActions.ResetErrors());

        if (token && refreshtoken && document) {
          this.sessionStorageService.setUser({
            accessToken: token,
            refreshToken: refreshtoken,
            document: document
          });

          this.store$.dispatch(new VerifyAccess(document));
        } else {
          this.setUser({
            accessToken: response.access_token,
            refreshToken: response.refresh_token,
            scope: response.scope,
            expiresIn: response.expires_in,
            tokenType: response.token_type
          });

          this.store$.dispatch(
            new GetClientCredentialsSuccessAction({
              clientCredentials: response
            })
          );
          this.store$.dispatch(new loginActions.DocumentTypesRequest());
        }
      })
    );
  }

  getParameterByName(name, url = window.location.href) {
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getFlags(username): Observable<any> {
    return this.http.get<any>(`${this.MOCK_SERVER_URL}/flags/${username}`);
  }

  getUserInfo(): Observable<any> {
    return this.http.get<any>(`${this.MOCK_SERVER_URL}/user`);
  }

  getUserInfos(headers: HttpHeaders = new HttpHeaders()): Observable<any> {
    return this.http.get<any>(`${environment.apiRootUrl}/shopuserprofile/1.0.0/properties`, { headers });
  }

  getTerms() {
    return this.http.get<any>(`${environment.apiRootUrl}/domains/1.0.0/term?codeCompany=2&typeClient=CDE`);
  }

  getUser(): UserData {
    const userData: UserData = this.sessionStorageService.getUser();

    if (userData) {
      return {
        accessToken: userData.accessToken,
        refreshToken: userData.refreshToken
      };
    }
  }

  setUser(userData: UserData): void {
    this.sessionStorageService.setUser(userData);
  }

  setUserInfo(userData): void {
    sessionStorage.setItem('@@user_info', JSON.stringify(userData));
  }

  clearUser(): void {
    this.sessionStorageService.clearUser();
  }

  login({ username, password }): Observable<TokenResponse> {
    if (environment.apiRootUrl != null && environment.apiRootUrl.length > 0) {
      const body = new HttpParams()
        .set('grant_type', 'password')
        .set('username', username)
        .set('password', password);

      return this.http.post<TokenResponse>(environment.apiRootUrl + '/token', body, httpOptions);
    }
  }

  requestTokenWSO2(body?: HttpParams): Observable<TokenResponse> {
    const newBody = new HttpParams().set('grant_type', 'client_credentials');
    return this.http.post<TokenResponse>(environment.apiRootUrl + '/token', body ? body : newBody, httpOptions);
  }

  requestToken(body?: HttpParams): Observable<TokenResponse> {
    if (JSON.parse(environment.newGateway)) {
      return this.requestTokenNewGateway(body);
    } else {
      return this.requestTokenWSO2(body);
    }
  }

  userProperties(userProperties) {
    this.userProperties = userProperties;
  }

  refresh(): Observable<TokenResponse> {
    return this.store$.select(selectSelectedTab).pipe(
      first(),
      switchMap(tab => {
        const refreshToken = this.getUser().refreshToken;
        const body = new HttpParams()
          .set('grant_type', 'refresh_token')
          .set('client_id', environment.kc_loj_clientid)
          .set('x-client-id', environment.kc_loj_clientid)
          .set('refresh_token', refreshToken)
          .set('storeId', tab.code);

        return this.requestTokenNewGateway(body);
      })
    );
  }

  private requestTokenNewGateway(body?: HttpParams): Observable<TokenResponse> {
    const newBody = new HttpParams()
      .set('grant_type', 'client_credentials')
      .set('client_id', environment.kc_loj_clientid_visitor)
      .set('client_secret', environment.kc_loj_clientsecret)
      .set('x-client-id', environment.kc_loj_clientid);

    return this.http.post<TokenResponse>(
      `/lgn/realms/${environment.keycloak_realm}/protocol/openid-connect/token`,
      body ? body : newBody,
      httpOptions
    );
  }

  updateUserFlags(username, flagToUpdate, flagValue): Observable<any> {
    this.userProperties[flagToUpdate] = flagValue;
    return this.http.put<any>(`${environment.apiRootUrl}/shopuserprofile/1.0.0/properties`, this.userProperties);
  }

  revokeToken(token): Observable<TokenResponse> {
    if (environment.apiRootUrl != null && environment.apiRootUrl.length > 0) {
      const body = new HttpParams().set('token', token).set('token_type_hint', 'access_token');

      return this.http.post<any>(environment.apiRootUrl + '/revoke', body, httpOptions);
    }
  }

  updateUserTutorialFlags(payload): Observable<any> {
    return this.http.put<any>(`${environment.apiRootUrl}/shopuserprofile/1.0.0/properties`, payload);
  }
}
