import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input() loading = false;

  public lottieConfig: Object;
  private anim: any;
  private animationSpeed = 2;

  handleAnimation(anim: any) {
    this.anim = anim;
  }

  constructor() {
    this.lottieConfig = {
      path: 'assets/media/lottie/santander-logo.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
  }
}
