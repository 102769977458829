import { Action } from '@ngrx/store';

export enum FormalizationActionTypes {
  SEND_AGENT_LEGAL = '[Formalization] [User] Send Agent Legal',
  SEND_AGENT_LEGAL_SUCCESS = '[Formalization] [App] Send Agent Legal Success',
  SEND_AGENT_LEGAL_ERROR = '[Formalization] [App] Send Agent Legal Error',

  SAVE_BIOMETRY_ITEM = '[Formalization] [App] Save Biometry Item',

  SEND_BIOMETRY = '[Formalization] [User] Send Biometry',
  SEND_BIOMETRY_SUCCESS = '[Formalization] [App] Send Biometry Success',
  SEND_BIOMETRY_PENDING = '[Formalization] [App] Send Biometry Pending',
  SEND_BIOMETRY_REMAKE = '[Formalization] [App] Send Biometry Remake',
  SEND_BIOMETRY_ERROR = '[Formalization] [App] Send Biometry Error',
  SEND_BIOMETRY_UPLOAD = '[Formalization] [App] Send Biometry Upload',
  CLEAR_BIOMETRY = '[Formalization] [App] Clear Biometry',

  SEND_SIGNATURE = '[Formalization] [User] Send Signature',
  SEND_SIGNATURE_SUCCESS = '[Formalization] [App] Send Signature Success',
  SEND_SIGNATURE_REMAKE = '[Formalization] [App] Send Signature Remake',
  SEND_SIGNATURE_ERROR = '[Formalization] [App] Send Signature Error',

  SUBMIT_FORMALIZATION = '[Formalization] [User] Submit Formalization',
  SUBMIT_FORMALIZATION_SUCCESS = '[Formalization] [App] Submit Formalization Success',
  SUBMIT_FORMALIZATION_ERROR = '[Formalization] [App] Submit Formalization Error',

  GET_CROSSED_FLUX_LINK = '[Formalization] [App] Get Crossed Flux Link',
  GET_CROSSED_FLUX_LINK_SUCCESS = '[Formalization] [App] Get Crossed Flux Link Success',
  GET_CROSSED_FLUX_LINK_ERROR = '[Formalization] [App] Get Crossed Flux Link Error',

  GET_CROSSED_FLUX_STATUS = '[Formalization] [App] Get Crossed Flux Status',
  GET_CROSSED_FLUX_STATUS_SUCCESS = '[Formalization] [App] Get Crossed Flux Status Success',
  GET_CROSSED_FLUX_STATUS_ERROR = '[Formalization] [App] Get Crossed Flux Status Error',

  CHECK_BIOMETRY_CROSS_FLUX_STATUS = '[Formalization] [App] Get Biometry Crossed Flux Status',
  CHECK_BIOMETRY_CROSS_FLUX_STATUS_SUCCESS = '[Formalization] [App] Get Biometry Crossed Flux Status Success',
  CHECK_BIOMETRY_CROSS_FLUX_STATUS_ERROR = '[Formalization] [App] Get Biometry Crossed Flux Status Error',

  GET_CROSSED_FLUX_INFO = '[Formalization] [App] Get Crossed Flux Info',
  GET_CROSSED_FLUX_INFO_SUCCESS = '[Formalization] [App] Get Crossed Flux Info Success',
  GET_CROSSED_FLUX_INFO_ERROR = '[Formalization] [App] Get Crossed Flux Info Error',

  CLEAR_CROSSED_FLUX = '[Formalization] [App] Clear Crossed Flux',

  CHECK_BIOMETRY_STATUS = '[Formalization] [App] Check Biometry Status',
  CHECK_BIOMETRY_STATUS_SUCCESS = '[Formalization] [App] Check Biometry Status Success',
  CHECK_BIOMETRY_STATUS_ERROR = '[Formalization] [App] Check Biometry Status Error',
  CLEAR_BIOMETRY_STATUS = '[Formalization] [App] Clear Biometry Status',

  SET_REPRESENTATIVE = '[Formalization] [App] Set Representative',

  GET_MAX_FILE_SIZE_ALLOWED = '[Formalization] [App] Get Max File Size Allowed',
  GET_MAX_FILE_SIZE_ALLOWED_SUCCESS = '[Formalization] [App] Get Max File Size Allowed Success',
  GET_MAX_FILE_SIZE_ALLOWED_ERROR = '[Formalization] [App] Get Max File Size Allowed Error',

  GET_SEND_TYPES = '[Formalization] [App] Get Send Types',
  GET_SEND_TYPES_SUCCESS = '[Formalization] [App] Get Send Types Success',
  GET_SEND_TYPES_ERROR = '[Formalization] [App] Get Send Types Error',

  GET_TUTORIAL_STEPS = '[Formalization] [App] Get Tutorial Steps',
  GET_TUTORIAL_STEPS_SUCCESS = '[Formalization] [App] Tutorial Steps Success',
  GET_TUTORIAL_STEPS_ERROR = '[Formalization] [App] Tutorial Steps Error',

  GET_SUMMARY_STEPS = '[Formalization] [App] Get Summary Steps',
  GET_SUMMARY_STEPS_SUCCESS = '[Formalization] [App] Summary Steps Success',
  GET_SUMMARY_STEPS_ERROR = '[Formalization] [App] Summary Steps Error',

  GET_SUMMARY_STATUS = '[Formalization] [App] Get Summary Status',
  GET_SUMMARY_STATUS_SUCCESS = '[Formalization] [App] Summary Status Success',
  GET_SUMMARY_STATUS_ERROR = '[Formalization] [App] Summary Status Error',

  GET_IV_NONCE = '[Formalization] [App] Get Iv Nonce',
  GET_IV_NONCE_SUCCESS = '[Formalization] [App] Get Iv Nonce Success',
  GET_IV_NONCE_ERROR = '[Formalization] [App] Get Iv Nonce Error',

  SET_FORMALIZATION_STATUS = '[Formalization] [App] Set Formalization Status',

  ABORT_FORMALIZATION = '[Formalization] [App] Abort Formalization',

  CLEAR_FORMALIZATION = '[Formalization] [App] Clear Formalization'
}

export class SendAgentLegalAction implements Action {
  readonly type = FormalizationActionTypes.SEND_AGENT_LEGAL;
  constructor(public payload: any) { }
}
export class SendAgentLegalSuccessAction implements Action {
  readonly type = FormalizationActionTypes.SEND_AGENT_LEGAL_SUCCESS;
  constructor(public payload: any) { }
}
export class SendAgentLegalErrorAction implements Action {
  readonly type = FormalizationActionTypes.SEND_AGENT_LEGAL_ERROR;
  constructor(public payload: any) { }
}

export class SaveBiometryItemAction implements Action {
  readonly type = FormalizationActionTypes.SAVE_BIOMETRY_ITEM;
  constructor(public payload: any) { }
}
export class SendBiometryAction implements Action {
  readonly type = FormalizationActionTypes.SEND_BIOMETRY;
  constructor(public payload: any) { }
}
export class SendBiometrySuccessAction implements Action {
  readonly type = FormalizationActionTypes.SEND_BIOMETRY_SUCCESS;
  constructor(public payload: any) { }
}
export class SendBiometryPendingAction implements Action {
  readonly type = FormalizationActionTypes.SEND_BIOMETRY_PENDING;
  constructor(public payload: any) { }
}
export class SendBiometryRemakeAction implements Action {
  readonly type = FormalizationActionTypes.SEND_BIOMETRY_REMAKE;
  constructor(public payload: any) { }
}
export class SendBiometryErrorAction implements Action {
  readonly type = FormalizationActionTypes.SEND_BIOMETRY_ERROR;
  constructor(public payload: any) { }
}

export class SendBiometryUploadAction implements Action {
  readonly type = FormalizationActionTypes.SEND_BIOMETRY_UPLOAD;
  constructor(public payload: any) { }
}

export class ClearBiometryAction implements Action {
  readonly type = FormalizationActionTypes.CLEAR_BIOMETRY;
  constructor() { }
}

export class SendSignatureAction implements Action {
  readonly type = FormalizationActionTypes.SEND_SIGNATURE;
  constructor(public payload: any) { }
}
export class SendSignatureSuccessAction implements Action {
  readonly type = FormalizationActionTypes.SEND_SIGNATURE_SUCCESS;
  constructor(public payload: any) { }
}

export class SendSignatureRemakeAction implements Action {
  readonly type = FormalizationActionTypes.SEND_SIGNATURE_REMAKE;
  constructor(public payload: any) { }
}

export class SendSignatureErrorAction implements Action {
  readonly type = FormalizationActionTypes.SEND_SIGNATURE_ERROR;
  constructor(public payload: any) { }
}

export class SubmitFormalizationAction implements Action {
  readonly type = FormalizationActionTypes.SUBMIT_FORMALIZATION;
  constructor(public payload: any) { }
}
export class SubmitFormalizationSuccessAction implements Action {
  readonly type = FormalizationActionTypes.SUBMIT_FORMALIZATION_SUCCESS;
  constructor(public payload: any) { }
}
export class SubmitFormalizationErrorAction implements Action {
  readonly type = FormalizationActionTypes.SUBMIT_FORMALIZATION_ERROR;
  constructor(public payload: any) { }
}

export class GetMaxFileSizeAllowedAction implements Action {
  readonly type = FormalizationActionTypes.GET_MAX_FILE_SIZE_ALLOWED;
  constructor() { }
}
export class GetMaxFileSizeAllowedSuccessAction implements Action {
  readonly type = FormalizationActionTypes.GET_MAX_FILE_SIZE_ALLOWED_SUCCESS;
  constructor(public payload: any) { }
}
export class GetMaxFileSizeAllowedErrorAction implements Action {
  readonly type = FormalizationActionTypes.GET_MAX_FILE_SIZE_ALLOWED_ERROR;
  constructor(public payload: any) { }
}

export class GetSendTypesAction implements Action {
  readonly type = FormalizationActionTypes.GET_SEND_TYPES;
  constructor() { }
}

export class GetSendTypesSuccessAction implements Action {
  readonly type = FormalizationActionTypes.GET_SEND_TYPES_SUCCESS;
  constructor(public payload: any) { }
}

export class GetSendTypesErrorAction implements Action {
  readonly type = FormalizationActionTypes.GET_SEND_TYPES_ERROR;
  constructor(public payload: any) { }
}

export class GetTutorialSteps implements Action {
  readonly type = FormalizationActionTypes.GET_TUTORIAL_STEPS;
  constructor(public payload?: any) { }
}

export class GetTutorialStepsSuccess implements Action {
  readonly type = FormalizationActionTypes.GET_TUTORIAL_STEPS_SUCCESS;
  constructor(public payload?: any) { }
}

export class GetTutorialStepsError implements Action {
  readonly type = FormalizationActionTypes.GET_TUTORIAL_STEPS_ERROR;
  constructor(public payload?: any) { }
}

export class GetCrossedFluxLinkAction implements Action {
  readonly type = FormalizationActionTypes.GET_CROSSED_FLUX_LINK;
  constructor(public payload: any) { }
}

export class GetCrossedFluxLinkSuccessAction implements Action {
  readonly type = FormalizationActionTypes.GET_CROSSED_FLUX_LINK_SUCCESS;
  constructor(public payload: any) { }
}

export class GetCrossedFluxLinkErrorAction implements Action {
  readonly type = FormalizationActionTypes.GET_CROSSED_FLUX_LINK_ERROR;
  constructor(public payload: any) { }
}

export class GetCrossedFluxStatusAction implements Action {
  readonly type = FormalizationActionTypes.GET_CROSSED_FLUX_STATUS;
  constructor(public payload: any) { }
}

export class GetCrossedFluxStatusSuccessAction implements Action {
  readonly type = FormalizationActionTypes.GET_CROSSED_FLUX_STATUS_SUCCESS;
  constructor(public payload: any) { }
}

export class GetCrossedFluxStatusErrorAction implements Action {
  readonly type = FormalizationActionTypes.GET_CROSSED_FLUX_STATUS_ERROR;
  constructor(public payload: any) { }
}

export class GetCrossedFluxInfoAction implements Action {
  readonly type = FormalizationActionTypes.GET_CROSSED_FLUX_INFO;
  constructor(public payload: any) { }
}

export class GetCrossedFluxInfoSuccessAction implements Action {
  readonly type = FormalizationActionTypes.GET_CROSSED_FLUX_INFO_SUCCESS;
  constructor(public payload: any) { }
}

export class GetCrossedFluxInfoErrorAction implements Action {
  readonly type = FormalizationActionTypes.GET_CROSSED_FLUX_INFO_ERROR;
  constructor(public payload: any) { }
}

export class ClearCrossedFluxAction implements Action {
  readonly type = FormalizationActionTypes.CLEAR_CROSSED_FLUX;
  constructor() { }
}

export class SetFormalizationStatusAction implements Action {
  readonly type = FormalizationActionTypes.SET_FORMALIZATION_STATUS;
  constructor(public payload: any) { }
}

export class CheckBiometryStatusAction implements Action {
  readonly type = FormalizationActionTypes.CHECK_BIOMETRY_STATUS;
  constructor(public payload?: any) { }
}

export class CheckBiometryStatusSuccessAction implements Action {
  readonly type = FormalizationActionTypes.CHECK_BIOMETRY_STATUS_SUCCESS;
  constructor(public payload: any) { }
}

export class CheckBiometryCrossFluxStatusAction implements Action {
  readonly type = FormalizationActionTypes.CHECK_BIOMETRY_CROSS_FLUX_STATUS;
  constructor(public payload: any) { }
}

export class CheckBiometryCrossFluxStatusSuccessAction implements Action {
  readonly type = FormalizationActionTypes.CHECK_BIOMETRY_CROSS_FLUX_STATUS_SUCCESS;
  constructor(public payload: any) { }
}

export class CheckBiometryCrossFluxStatusErrorAction implements Action {
  readonly type = FormalizationActionTypes.CHECK_BIOMETRY_CROSS_FLUX_STATUS_ERROR;
  constructor(public payload: any) { }
}

export class CheckBiometryStatusErrorAction implements Action {
  readonly type = FormalizationActionTypes.CHECK_BIOMETRY_STATUS_ERROR;
  constructor(public payload: any) { }
}

export class ClearBiometryStatusAction implements Action {
  readonly type = FormalizationActionTypes.CLEAR_BIOMETRY_STATUS;
  constructor() { }
}

export class SetRepresentativeAction implements Action {
  readonly type = FormalizationActionTypes.SET_REPRESENTATIVE;
  constructor(public payload: any) { }
}

export class AbortFormalizationAction implements Action {
  readonly type = FormalizationActionTypes.ABORT_FORMALIZATION;
  constructor(public payload?: any) { }
}

export class GetSummarySteps implements Action {
  readonly type = FormalizationActionTypes.GET_SUMMARY_STEPS;
  constructor(public payload?: any) { }
}

export class GetSummaryStepsSuccess implements Action {
  readonly type = FormalizationActionTypes.GET_SUMMARY_STEPS_SUCCESS;
  constructor(public payload?: any) { }
}

export class GetSummaryStepsError implements Action {
  readonly type = FormalizationActionTypes.GET_SUMMARY_STEPS_ERROR;
  constructor(public payload?: any) { }
}

export class GetSummaryStatus implements Action {
  readonly type = FormalizationActionTypes.GET_SUMMARY_STATUS;
  constructor(public payload?: any) { }
}

export class GetSummaryStatusSuccess implements Action {
  readonly type = FormalizationActionTypes.GET_SUMMARY_STATUS_SUCCESS;
  constructor(public payload?: any) { }
}

export class GetSummaryStatusError implements Action {
  readonly type = FormalizationActionTypes.GET_SUMMARY_STATUS_ERROR;
  constructor(public payload?: any) { }
}

export class GetIvNonceAction implements Action {
  readonly type = FormalizationActionTypes.GET_IV_NONCE;
  constructor(public payload?: any) { }
}

export class GetIvNonceSuccessAction implements Action {
  readonly type = FormalizationActionTypes.GET_IV_NONCE_SUCCESS;
  constructor(public payload?: any) { }
}

export class GetIvNonceErrorAction implements Action {
  readonly type = FormalizationActionTypes.GET_IV_NONCE_ERROR;
  constructor(public payload?: any) { }
}

export class ClearFormalizationAction implements Action {
  readonly type = FormalizationActionTypes.CLEAR_FORMALIZATION;
  constructor() { }
}

export type FormalizationActions =
  | SendAgentLegalAction
  | SendAgentLegalSuccessAction
  | SendAgentLegalErrorAction
  | SaveBiometryItemAction
  | SendBiometryAction
  | SendBiometrySuccessAction
  | SendBiometryPendingAction
  | SendBiometryRemakeAction
  | SendBiometryErrorAction
  | SendBiometryUploadAction
  | SendSignatureAction
  | SendSignatureSuccessAction
  | SendSignatureRemakeAction
  | SendSignatureErrorAction
  | ClearBiometryAction
  | SubmitFormalizationAction
  | SubmitFormalizationSuccessAction
  | SubmitFormalizationErrorAction
  | GetMaxFileSizeAllowedAction
  | GetMaxFileSizeAllowedSuccessAction
  | GetMaxFileSizeAllowedErrorAction
  | GetSendTypesAction
  | GetSendTypesSuccessAction
  | GetSendTypesErrorAction
  | GetTutorialSteps
  | GetTutorialStepsSuccess
  | GetTutorialStepsError
  | GetCrossedFluxLinkAction
  | GetCrossedFluxLinkSuccessAction
  | GetCrossedFluxLinkErrorAction
  | GetCrossedFluxStatusAction
  | GetCrossedFluxStatusSuccessAction
  | GetCrossedFluxStatusErrorAction
  | GetCrossedFluxInfoAction
  | GetCrossedFluxInfoSuccessAction
  | GetCrossedFluxInfoErrorAction
  | ClearCrossedFluxAction
  | SetFormalizationStatusAction
  | CheckBiometryStatusAction
  | CheckBiometryStatusSuccessAction
  | CheckBiometryStatusErrorAction
  | ClearBiometryStatusAction
  | CheckBiometryCrossFluxStatusAction
  | CheckBiometryCrossFluxStatusSuccessAction
  | CheckBiometryCrossFluxStatusErrorAction
  | SetRepresentativeAction
  | AbortFormalizationAction
  | GetSummarySteps
  | GetSummaryStepsSuccess
  | GetSummaryStepsError
  | GetSummaryStatus
  | GetSummaryStatusSuccess
  | GetSummaryStatusError
  | GetIvNonceAction
  | GetIvNonceSuccessAction
  | GetIvNonceErrorAction
  | ClearFormalizationAction;
