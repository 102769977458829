<div class="formalization-camera">
  <div class="mb-1 text-center pl-3 pt-3 pr-3 pb-0">
    <h3>
      Você está assinando um financiamento de
      <strong class="font-weight-bold">{{ +proposal?.totalFinancedValue | localeCurrency }}</strong> parcelado em
      <strong class="font-weight-bold">
        {{ proposal?.installmentAmount }}x
        <span *ngIf="proposal?.planType !== paymentPlanType.STANDARD_PLAN">
          {{ paymentPlanType.CUSTOMIZED_PLAN | translate }}
        </span>
        <span *ngIf="proposal?.planType === paymentPlanType.STANDARD_PLAN">
          de {{ proposal?.installmentValue | localeCurrency }}</span
        >
      </strong>
      <h3 *ngIf="proposal?.insurance">e com proteção financeira</h3>
    </h3>
  </div>
  <div class="formalization-step-camera mb-1">
    <app-biometry
      class="biometry-wrapper"
      (base64Content)="handleSuccessfulScan($event)"
      (error)="handleErrorScan($event)"
    >
      <span class="material-icons close-mat-icon-cam" (click)="closeBiometryCapture()">close</span>
    </app-biometry>
  </div>
</div>
