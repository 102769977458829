import { Component, Input, OnInit } from '@angular/core';
import { AppState } from '@app/core/state';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { Store } from '@ngrx/store';
import { Offers, SimulationOffer } from '../model/offer.model';
import * as fromSimulation from '@app/core/state/simulation';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { Actions } from '@ngrx/effects';
import { Router } from '@angular/router';

@Component({
  selector: 'app-step-offer',
  templateUrl: './step-offer.component.html',
  styleUrls: ['./step-offer.component.scss']
})
export class StepOfferComponent implements OnInit {
  public productCode = ProductCodeEnum;
  @Input() simulationOffers: SimulationOffer[];
  @Input() offer: Offers;
  @Input() proposal: ConfirmationData;
  @Input() title: string;
  @Input() category: string;
  constructor(private readonly store$: Store<AppState>, private updates$: Actions, private router: Router) {}

  selecionar(simumationOffer: SimulationOffer) {
    const payload = {
      entryValue: this.offer.entryValue,
      financedValue: this.offer.financedValue,
      hasInsurance: simumationOffer.hasInsurance,
      proposalId: parseInt(this.proposal.proposalData.id),
      purchaseValue: this.offer.purchaseValue,
      redirectToSimulation: true
    };

    this.store$.dispatch(new fromSimulation.actions.UpdateOffer(payload));
  }

  ngOnInit() {}
}
