<div class="confirmation-header mt-5">
  <br />
  <div *ngIf="analysisShow" class="card-infor mb-5 cashback-card">
    <img src="./assets/images/info-white.svg" class="imagem" />
    <span class="customText"
      >{{
        'WE REMIND YOU THAT THIS PROPOSAL WILL UNDERGO AN ADDITIONAL CREDIT ANALYSIS AFTER THE
      FORMALIZATION STAGE'
          | translate
      }}.</span
    >
  </div>
  <h1 class="confirmation-title">{{ 'STEP-CONFIRMATION-TITLE' | translate }}</h1>
</div>

<div class="confirmation-content">
  <app-step-confirmation-proposal *ngIf="proposal" [proposal]="proposal" [listPaymentPlan]="listPaymentPlan">
  </app-step-confirmation-proposal>
  <p class="credit-conditions" *ngIf="showDoc && innerWidth < 768">
    {{ 'STEP-ENABLE-BUTTON-CONTINUE' | translate }}
    <span class="font-weight-bold">
      {{ 'STEP-CONFIRMATION-CONTINUE-BUTTON' | translate }}
    </span>
    {{ 'STEP-ENABLE-BUTTON-CONTINUE-END' | translate }}
  </p>
</div>

<div class="confirmation-footer">
  <p
    class="credit-conditions"
    *ngIf="showDoc && innerWidth > 768 && proposal?.proposalData?.product === productCode.CDC"
  >
    {{ 'STEP-ENABLE-BUTTON-CONTINUE' | translate }}
    <span class="font-weight-bold">
      {{ 'STEP-CONFIRMATION-CONTINUE-BUTTON' | translate }}
    </span>
    {{ 'STEP-ENABLE-BUTTON-CONTINUE-END' | translate }}
  </p>
  <p class="credit-conditions gray" *ngIf="showContractDownload">
    {{ 'STEP-CONFIRMATION-CREDIT-CONDITIONS' | translate }}
    <a
      href="javascript:void(0)"
      (click)="getProposalContract()"
      class="link"
      appTagger
      [event_category]="event_category"
      event_label="{{ showTitleConditionsLink() }}"
      event_element="a.href"
      >{{ showTitleConditionsLink() }}</a
    >
    <span *ngIf="proposal?.proposalData?.product === productCode.CDC">
      {{ 'STEP-CONFIRMATION-CREDIT-CONDITIONS-COMPLEMENT' | translate }}
    </span>
  </p>

  <div class="button-confirmation">
    <div class="row confirmation-actions">
      <div class="col-12 col-md-6 back-button">
        <button class="btn-block mb-2" mat-stroked-button color="primary" (click)="handleBackButton()">
          {{ 'STEP-CONFIRMATION-BACK-BUTTON' | translate }}
        </button>
      </div>
      <div class="col-12 col-md-6">
        <button
          class="btn-block mb-2"
          mat-flat-button
          color="primary"
          (click)="proceedToFormalization()"
          appTagger
          [event_category]="event_category"
          event_element="button"
          event_label="continuar para formalizacaoo"
        >
          {{ 'STEP-CONFIRMATION-CONTINUE-BUTTON' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>
