import { Component, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TranslateService } from '@ngx-translate/core';
import { Countries } from '@shared/constants/countries.enum';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.scss']
})
export class CreatePasswordComponent implements OnInit {
  public showPassword = false;
  public showConfirmPassword = false;
  public capslockOn = false;
  public passwordForm: FormGroup;
  public currentCountry: Countries = this.internationalizationService.currentCountry;
  data;
  @Output() submitEmitter: EventEmitter<any> = new EventEmitter();

  readonly category = '/portallojista/recuperarsenha/cadastro';
  readonly FillingError: Tag = Tag.FillingError;
  readonly Insert: Tag = Tag.Insert;

  constructor(
    private internationalizationService: InternationalizationService,
    public genTagger: GenTagger,
    private translateService: TranslateService
  ) {}

  get password() {
    return this.passwordForm.get('password');
  }

  get passwordConfirm() {
    return this.passwordForm.get('passwordConfirm');
  }

  ngOnInit() {
    this.createForm();
    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.pgView,
      event_label: 'Cadastrar nova senha'
    });
  }

  private createForm(): void {
    this.passwordForm = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(10),
        this.hasCapitalWordValidator,
        this.hasNumbers,
        this.hasSpecialChars
      ]),
      passwordConfirm: new FormControl('', [Validators.required])
    });
    this.passwordForm.statusChanges.pipe(debounceTime(1000)).subscribe(errors => {
      if (this.passwordForm.controls['password'].errors !== null) {
        const err = this.passwordForm.controls['password'].errors;

        if (err.hasNumbers) {
          this.genTagger.setTag({
            event_category: this.category,
            event_action: Tag.FillingError,
            event_label: this.translateService.instant('PASSWORD-RULES-LIST3')
          });
        }

        if (err.capitalWord) {
          this.genTagger.setTag({
            event_category: this.category,
            event_action: Tag.FillingError,
            event_label: this.translateService.instant('PASSWORD-RULES-LIST2')
          });
        }

        if (err.hasSpecialChars) {
          this.genTagger.setTag({
            event_category: this.category,
            event_action: Tag.FillingError,
            event_label: this.translateService.instant('PASSWORD-RULES-LIST4')
          });
        }

        if (err.minlength > 7) {
          this.genTagger.setTag({
            event_category: this.category,
            event_action: Tag.FillingError,
            event_label: this.translateService.instant('PASSWORD-RULES-LIST1')
          });
        }
      }
    });
  }

  private hasCapitalWordValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const regex = /(?=.*[a-z])(?=.*[A-Z])/g;
    if (control.value && !regex.test(control.value)) {
      // this.genTagger.setTag({
      //   event_category: this.category,
      //   event_action: Tag.pgView,
      //   event_label: 'Cadastrar nova senha'
      // });
      return { capitalWord: true };
    }
    return null;
  }

  private hasNumbers(control: AbstractControl): { [key: string]: boolean } | null {
    const regex = /[0-9]/g;
    if (control.value && !regex.test(control.value)) {
      return { hasNumbers: true };
    }
    return null;
  }

  private hasSpecialChars(control: AbstractControl): { [key: string]: boolean } | null {
    const regex = /^(?=.*[!@#$%&*])[A-Za-z\d!@#$%&*]*$/g;
    if (control.value && !regex.test(control.value)) {
      return { hasSpecialChars: true };
    }

    return null;
  }

  public handleShowHidePassword(): void {
    this.showPassword = !this.showPassword;
  }

  public handleShowHideConfirmPassword(): void {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent) {
    this.toggleCapslock(event);
  }

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent) {
    this.toggleCapslock(event);
  }

  @HostListener('keyup', ['$event'])
  onKeyUp(event: KeyboardEvent) {
    this.toggleCapslock(event);
  }

  private toggleCapslock(event: MouseEvent | KeyboardEvent): void {
    this.capslockOn = event.getModifierState && event.getModifierState('CapsLock');
  }

  public submit(): void {
    this.submitEmitter.emit(this.password.value);
  }
}
