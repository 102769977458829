import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { DomainsRegister } from '@shared/models/domains.model';
import { map } from 'rxjs/operators';
import gql from 'graphql-tag';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class DomainsService {
  constructor(private apollo: Apollo, private http: HttpClient) {}

  getDomains(): Observable<DomainsRegister> {
    return this.apollo
      .watchQuery({
        query: gql`
          query proposalRegisterRender {
            proposalRegisterRender {
              accountTypes {
                id
                code
                description
              }
              addressTypes {
                id
                code
                description
              }
              banks {
                id
                code
                description
              }
              companyTypes {
                id
                code
                description
              }
              economicActivityGroups {
                id
                code
                description
              }
              genders {
                id
                code
                description
              }
              legalNatures {
                id
                code
                description
              }
              nationalities {
                id
                code
                description
              }
              federationUnits {
                id
                code
                description
              }
              companyRelationshipTypes {
                id
                code
                description
              }
            }
          }
        `,
        variables: {},
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: any) => {
          return response.data.proposalRegisterRender as DomainsRegister;
        })
      );
  }

  updateUserFlags(username, flagToUpdate, flagValue, code?): Observable<any> {
    const body = {
      [flagToUpdate]: flagValue,
      termId: code
    };
    return this.http.put<any>(`${environment.apiRootUrl}/shopuserprofile/1.0.0/properties`, body);
  }

  updateFlags(request): Observable<any> {
    return this.http.put<any>(`${environment.apiRootUrl}/shopuserprofile/1.0.0/properties`, request);
  }
}
