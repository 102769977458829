import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router, RouterEvent } from '@angular/router';
import { AppState } from '@app/core/state';
import { ClearAll as ClearSimulationData } from '@app/core/state/simulation/simulation.actions';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Store } from '@ngrx/store';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { CountryNavigationService } from '@shared/services/country-navigation/country-navigation.service';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})
export class StepperComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  public navLinks = [];
  public url: string;
  public currentStepLabel = '';
  public currentStepNumber: number;
  @Input() isCrossedFlux = false;
  @Output() closeOut = new EventEmitter();

  readonly category = '/portallojista/assinatura/mobile/biometria';

  constructor(
    private router: Router,
    public dialog: MatDialog,
    private countryNavigationService: CountryNavigationService,
    private store$: Store<AppState>,
    private genTagger: GenTagger
  ) {}

  ngOnInit(): void {
    this.setNavLinks();
    this.setRouterSubscription();
  }

  public setRouterSubscription(): void {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: RouterEvent) => {
      this.url = event.url ? event.url : this.url;
      this.currentStepLabel = this.getTitleFromUrl(this.url);
    });
  }

  public setNavLinks(): void {
    this.navLinks = [
      {
        label: `PROPOSAL-WORKFLOW-STEPPER-STAGE-1`,
        link: '/proposal/step-simulation',
        index: 0
      },
      {
        label: `PROPOSAL-WORKFLOW-STEPPER-STAGE-2`,
        link: '/proposal/step-register',
        index: 1
      },
      {
        label: `PROPOSAL-WORKFLOW-STEPPER-STAGE-3`,
        link: '/proposal/step-confirmation',
        index: 2
      },
      {
        label: `PROPOSAL-WORKFLOW-STEPPER-STAGE-4`,
        link: '/proposal/step-formalization',
        index: 3
      }
    ];

    this.url = this.router.routerState.snapshot.url;
    this.currentStepLabel = this.getTitleFromUrl(this.url);
  }

  public handleBackButton() {
    let targetUrl = this.countryNavigationService.getURLByCountry('/proposal/step-simulation');
    if (this.url === '/proposal/step-confirmation') targetUrl = '/proposal/step-register';
    this.router.navigate([targetUrl]);
  }

  public getTitleFromUrl(url: string): string {
    const title = this.navLinks.find(tab => url.includes(tab.link));
    return title ? title.label : '';
  }

  public leave(): void {
    const dialogRef = this.dialog.open(PionModalComponent, {
      data: {
        title: 'Quer mesmo sair?',
        description: 'A simulação não será salva e você pode perder as condições e tarifas desse empréstimo.',
        cancelText: 'Sair',
        confirmText: 'Continuar proposta',
        type: null
      },
      disableClose: true,
      id: 'modal-component',
      width: '520px',
      height: '330px'
    });
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(res => {
        if (!res) {
          this.store$.dispatch(new ClearSimulationData());
          this.router.navigate(['/showcase']);
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
