<div class="bottom-sheet-header">
  <div class="header-summary">
    <mat-icon class="icon-arrow" *ngIf="!show">keyboard_arrow_up</mat-icon>
    <mat-icon class="icon-arrow" *ngIf="show">keyboard_arrow_down</mat-icon>

    <div>
      <div class="summary">
        <p class="summary-text">{{ 'Summary' | translate }}</p>
      </div>
    </div>
  </div>

  <div class="header-values monthly" [ngClass]="{ hide: hideTransition }" *ngIf="!showInitialFee">
    <p
      class="value"
      [ngClass]="{
        'value-customized-plan': proposal?.planType !== paymentPlanType.STANDARD_PLAN,
        'value-standard-plan': proposal?.planType === paymentPlanType.STANDARD_PLAN
      }"
    >
      {{ proposal?.installmentTotalQuantity }}x
      <strong *ngIf="proposal?.planType !== paymentPlanType.STANDARD_PLAN">
        {{ paymentPlanType.CUSTOMIZED_PLAN }}</strong
      >
      <strong *ngIf="proposal?.planType === paymentPlanType.STANDARD_PLAN">
        {{ proposal?.installmentValue | localeCurrency }}
      </strong>
    </p>
  </div>
</div>

<div class="store-conditions">
  <div class="title">
    <p>{{ 'PROPOSAL-STORE-CONDITIONS-TITLE' | translate }}</p>
  </div>
  <div class="conditions-values">
    <div class="info">
      <div class="sidebar-div">
        <div>
          <div class="sidebar-title">
            <p class="sidebar-text">{{ 'PURCHASE-AMOUNT-TITLE' | translate }}</p>
          </div>
          <div class="sidebar-info">
            <p>{{ proposal?.financedValue | localeCurrency }}</p>
          </div>
        </div>
      </div>

      <div class="sidebar-div">
        <div>
          <div class="sidebar-title">
            <p class="sidebar-text">{{ 'Entry Price' | translate }}</p>
          </div>
          <div class="sidebar-info">
            <p>{{ proposal?.entryValue | localeCurrency }}</p>
          </div>
        </div>
      </div>

      <div class="sidebar-div">
        <div>
          <div class="sidebar-title">
            <p class="sidebar-text">{{ 'FINANCED_AMOUNT' | translate }}</p>
          </div>
          <div class="sidebar-info">
            <p>
              <strong>{{ financedValue | localeCurrency }}</strong>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="store-conditions">
  <div class="title b-top mt-5">
    <p>{{ 'PROPOSAL-STORE-SUMMARY' | translate }}</p>
  </div>
  <div class="conditions-values">
    <div class="info">
      <div class="sidebar-div">
        <!-- DOCUMENT NUMBER FOR PHYSICAL PERSON -->
        <div *ngIf="proposal.proposalData.client === personsType.PF">
          <div class="sidebar-title">
            <p class="sidebar-text">{{ 'STEP-CONFIRMATION-DOCUMENT-NUMBER' | translate }}</p>
          </div>
          <div class="sidebar-info">
            <p>{{ proposal?.personalData?.documentNumber | mask: '000.000.000-00' }}</p>
          </div>
        </div>
        <!-- DOCUMENT NUMBER FOR JURIDICAL PERSON -->
        <div *ngIf="proposal.proposalData.client === personsType.PJ">
          <div class="sidebar-title">
            <p class="sidebar-text">{{ 'STEP-REGISTER-COMPANY-DOCUMENT-NUMBER' | translate }}</p>
          </div>
          <div class="sidebar-info">
            <p>{{ proposal?.personalData?.documentNumber | mask: '00.000.000/0000-00' }}</p>
          </div>
        </div>
      </div>

      <div class="sidebar-div">
        <div>
          <div class="sidebar-title">
            <!-- BIRTH DATE FOR PHYSICAL PERSON -->
            <p class="sidebar-text" *ngIf="proposal.proposalData.client === personsType.PF">
              {{ 'STEP-CONFIRMATION-BIRTH-DATE' | translate }}
            </p>
            <!-- OPENING DATE FOR JURIDICAL PERSON -->
            <p class="sidebar-text" *ngIf="proposal.proposalData.client === personsType.PJ">
              {{ 'DATE-OF-FOUNDATION' | translate }}
            </p>
          </div>
          <div class="sidebar-info">
            <p>{{ proposal?.personalData?.birthDate | date: 'dd/MM/yyyy' }}</p>
          </div>
        </div>
      </div>

      <div class="sidebar-div" [class.mb-3]="proposal.proposalData.client === personsType.PJ">
        <div>
          <div class="sidebar-title">
            <!-- PHONE NUMBER FOR PHYSICAL PERSON -->
            <p class="sidebar-text" *ngIf="proposal.proposalData.client === personsType.PF">
              {{ 'STEP-CONFIRMATION-PHONE' | translate }}
            </p>
            <!-- PHONE NUMBER FOR JURIDICAL PERSON -->
            <p class="sidebar-text" *ngIf="proposal.proposalData.client === personsType.PJ">
              {{ 'STEP-REGISTER-MAIN-PHONE' | translate }}
            </p>
          </div>
          <div class="sidebar-info">
            <p>
              ({{ proposal?.personalData?.phone?.dddNumber }})
              {{ proposal?.personalData?.phone?.number | mask: '00000-0000' }}
            </p>
          </div>
        </div>
      </div>

      <div class="sidebar-div mb-3" *ngIf="proposal.proposalData.client === personsType.PF">
        <div>
          <div class="sidebar-title">
            <!-- EMAIL FOR PHYSICAL PERSON -->
            <p class="sidebar-text">
              {{ 'STEP-REGISTER-EMAIL' | translate }}
            </p>
          </div>
          <div class="sidebar-info">
            <p>{{ proposal?.personalData?.emailCode }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
