import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SignUpState } from './signup.reducer';

export const signUpSelector = createFeatureSelector<SignUpState>('signUp');

export const selectRenderData = createSelector(
  signUpSelector,
  (state: SignUpState) => state.renderData
);

export const selectUserData = createSelector(
  signUpSelector,
  (state: SignUpState) => state.userData
);

export const selectSignUpId = createSelector(
  signUpSelector,
  (state: SignUpState) => state.signUpId
);

export const selectChallengeResponse = createSelector(
  signUpSelector,
  (state: SignUpState) => state.challengeResponse
);

export const selectSignUpOutcome = createSelector(
  signUpSelector,
  (state: SignUpState) => state.signUpOutcome
);
