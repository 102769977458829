import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { LgpdConsentRegisterRequest, LgpdConsentRegisterResponse } from '@shared/models/lgpd-consent-register.model';
import { LgpdConsentAccessType } from '@shared/models/lgpd-consent.enum';
import { Observable } from 'rxjs';
import { LgpdConsentConsultRequest, LgpdConsentConsultResponse } from './../../models/lgpd-consent-consult.model';

@Injectable({
  providedIn: 'root'
})
export class LgpdService {
  constructor(private http: HttpClient) {}

  consentConsult(request: LgpdConsentConsultRequest): Observable<LgpdConsentConsultResponse> {
    // mock
    // console.log('consentConsultRequest', request);
    // return of(<LgpdConsentConsultResponse>{
    //   parameterFirstLineCompanyOffer: 'Aceito receber ofertas de produtos e serviços da Santander Financiamentos.',
    //   parameterSecondLineCompanyOffer:
    //     'É a oprtunidade de receber ofertas excusivas e serviços da Santander Financiamentos.',
    //   parameterFirstLinePartnerOffer: 'Aceito receber ofertas de produtos e serviços da Santander Financiamentos.',
    //   parameterSecondLinePartnerOffer: 'É a oprtunidade de receber ofertas excusivas e serviços da Grupo Santander.',
    //   parameterFirstLineSantanderGroupOffer:
    //     'Aceito receber ofertas de produtos e serviços da Santander Financiamentos.',
    //   parameterSecondLineSantanderGroupOffer:
    //     'É a oprtunidade de receber ofertas excusivas e serviços da Parceiras do Grupo Santander.',
    //   answerCompanyOffer: 'S',
    //   answerGroupOffer: 'N',
    //   answerPartnerOffer: 'S',
    //   showQuestions: true
    // });
    // end mock
    // request.documentNumber = '12776593031';
    this.setDocumentType(request);
    return this.http.post<LgpdConsentConsultResponse>(
      `${environment.apiRootUrl}/shopkeeper-login/consent-consult`,
      request
    );
  }

  consentRegister(request: LgpdConsentRegisterRequest): Observable<LgpdConsentRegisterResponse> {
    // mock
    // console.log('consentRegisterRequest', request);
    // return of(<LgpdConsentRegisterResponse>{
    //   message: 'SUCESSO',
    //   return: 'SUCESSOSO'
    // });
    // end mock
    // request.documentNumber = '12776593031'
    this.setDocumentType(request);
    return this.http.post<LgpdConsentRegisterResponse>(
      `${environment.apiRootUrl}/shopkeeper-login/consent-register`,
      request
    );
  }

  verifyShowConsentForm(documentNumber: string, email: string) {
    const request: LgpdConsentConsultRequest = {
      documentNumber: documentNumber,
      partnerUser: email,
      accessType: LgpdConsentAccessType.LOGIN,
      companyId: environment.businessCode
    };
    this.setDocumentType(request);

    return this.consentConsult(request);
  }

  private setDocumentType(request): void {
    if (request.documentNumber) {
      request.documentType = 'CPF';
    }
  }
}
