<div id="modal-content-wrapper">
  <header id="modal-header" class="text-center mt-3">
    <img src="./assets/images/ic_exclamation.svg" width="44px" height="44px" alt="Alerta" />
    <h1 id="modal-title" mat-dialog-title>
      <ng-cotainer *ngIf="data.daysToExpire > 0">
        {{ 'EXPIRATION-MODAL-TITLE' | translate: { days: data.daysToExpire } }}
      </ng-cotainer>
      <ng-cotainer *ngIf="data.daysToExpire <= 0">
        {{ 'EXPIRATION-MODAL-TITLE-TODAY' | translate }}
      </ng-cotainer>
    </h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <p>{{ 'LOGIN-POPUP-DESCRIPTION' | translate }}</p>
  </section>
  <footer id="modal-footer" class="pb-5" mat-dialog-actions>
    <button *ngIf="data.daysToExpire > 0" mat-stroked-button id="modal-cancel-button" color="primary" mat-dialog-close
      (click)="skipClick()" appTagger event_action="Fechou Modal - {{ 'LOGIN-POPUP-CANCEL' | translate }}"
      [event_category]="category" event_label="{{ 'LOGIN-POPUP-CANCEL' | translate }}">
      {{ 'LOGIN-POPUP-CANCEL' | translate }}
    </button>
    <button mat-flat-button class="mb-0" id="modal-action-button" color="primary" mat-dialog-close
      (click)="resetPasswordClick()" appTagger [event_category]="category"
      event_label="{{ 'LOGIN-POPUP-CONFIRM' | translate }}">
      {{ 'LOGIN-POPUP-CONFIRM' | translate }}
    </button>
  </footer>
</div>