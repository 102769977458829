<div class="contract-display-container" *ngIf="!isSigning">
  <div class="contract-display-header d-flex justify-content-between align-items-center container pt-3 pb-3">
    <span
      class="material-icons pl-3 icon-close"
      (click)="exitSignature()"
      appTagger
      [event_category]="category"
      event_label="x"
      [event_action]="closeAction + ' - contrato'"
      >clear</span
    >
  </div>

  <div #pdfViewerContainer class="pdf-viewer-container">
    <pdf-viewer
      *ngIf="showDoc"
      [src]="docData"
      [zoom]="(zoom$ | async) / 100"
      [zoom-scale]="'page-fit'"
      [show-all]="true"
      [original-size]="false"
      (after-load-complete)="afterDocumentLoad($event)"
    >
    </pdf-viewer>
  </div>

  <footer class="footer-mobile" *ngIf="isFooterVisible">
    <div class="d-flex align-items-center flex-column">
      <button class="btn btn-mobile-contract" (click)="downloadFile()">
        <mat-icon class="mr-2" appTagger [event_category]="category" event_label="{{ 'Contrato' | translate }}">
          cloud_download_filled</mat-icon
        >
        {{ 'Contrato' | translate }}
      </button>

      <button
        class="btn btn-signature"
        (click)="signContract()"
        appTagger
        [event_category]="category"
        event_label="{{ 'Assinar' | translate }}"
      >
        {{ 'Assinar' | translate }}
      </button>
    </div>
  </footer>
</div>

<div class="signature-block-container" body *ngIf="isSigning">
  <div class="d-flex justify-content-between align-items-center signature-block-header">
    <span
      class="material-icons icon-back"
      (click)="backToContract()"
      appTagger
      [event_category]="category"
      event_label="voltar"
      >arrow_back_ios_new</span
    >
    <h2 class="signature-block-title">{{ 'ESIGN-CONTRACT-SIGNATURE' | translate }}</h2>
    <div class="signature-block-title-landscape">
      <img
        class="signature-block-logo"
        src="./assets/images/logo-santander-consumer.svg"
        alt="Santander Consumer Logo"
      />
      <p class="signature-block-title-signer">
        {{ 'ESIGN-CONTRACT-SIGNATURE-BY' | translate: { name: signatureData?.customer?.name } }}
      </p>
    </div>
    <span
      class="material-icons pl-3 icon-close"
      (click)="exitSignature()"
      appTagger
      [event_category]="category"
      event_label="x"
      event_action="{{ closeAction }} - {{ 'ESIGN-CONTRACT-SIGNATURE' | translate }}"
      >clear</span
    >
  </div>

  <div class="container p-3 signature-block-body">
    <p class="pb-2 pt-2 signature-block-body-subtitle">
      {{ 'ESIGN-SIGNATURE-BLOCK-TITLE' | translate }}
    </p>

    <div class="proposal-data">
      <div class="row proposal-data-row bb-0">
        <div class="col-md-6 pl-0 proposal-data-info">
          <label class="proposal-data-info__label">{{ 'ESIGN-NAME' | translate }}</label>
        </div>
        <div class="col-md-6 pl-0">
          <p class="proposal-data-info__value">{{ signatureData?.customer?.name }}</p>
        </div>
      </div>
      <div class="row proposal-data-row bb-0">
        <div class="col-md-6 pl-0 proposal-data-info">
          <label class="proposal-data-info__label">{{ 'ESIGN-DOCUMENT-NUMBER' | translate }}</label>
        </div>
        <div class="col-md-6 pl-0">
          <p class="proposal-data-info__value">{{ signatureData?.customer?.documentNumber | documentNumber }}</p>
        </div>
      </div>
      <div class="row proposal-data-row bb-0">
        <div class="col-md-6 pl-0 proposal-data-info">
          <label class="proposal-data-info__label">{{ 'ESIGN-FINANCED-VALUE' | translate }}</label>
        </div>
        <div class="col-md-6 pl-0">
          <p class="proposal-data-info__value">{{ signatureData?.financedValue | localeCurrency }}</p>
        </div>
      </div>
      <div class="row proposal-data-row bb-0">
        <div class="col-md-6 pl-0 proposal-data-info">
          <label class="proposal-data-info__label">{{ 'ESIGN-INSTALLMENTS' | translate }}</label>
        </div>
        <div class="col-md-6 pl-0">
          <p class="proposal-data-info__value">
            {{ signatureData?.installmentAmount }}x
            <span *ngIf="signatureData?.planType !== paymentPlanType.STANDARD_PLAN">
              {{ paymentPlanType.CUSTOMIZED_PLAN | translate }}
            </span>
            <span *ngIf="signatureData?.planType === paymentPlanType.STANDARD_PLAN">
              de {{ signatureData?.installmentValue | localeCurrency }}</span
            >
          </p>
        </div>
      </div>
      <div class="row proposal-data-row bb-0" *ngIf="!(signatureData?.product?.code === productCodes.CSC || signatureData?.product?.code === productCodes.CSCP)">
        <div class="col-md-6 pl-0 proposal-data-info">
          <label class="proposal-data-info__label">{{ 'ESIGN-CET' | translate }}</label>
        </div>
        <div class="col-md-6 pl-0">
          <p class="proposal-data-info__value">
            {{ 'ESIGN-CET-PER-MONTH' | translate: { cet: cetTax } }}
            {{ 'ESIGN-CET-PER-YEAR' | translate: { annualCet: annualCetTax } }}
          </p>
        </div>
      </div>
    </div>

    <div class="signature-pad-container">
      <p class="signature-pad-title">{{ 'ESIGN-SIGNATURE-PAD-TITLE' | translate }}</p>
      <p class="signature-pad-suggestion">{{ 'ESIGN-SIGNATURE-PAD-SUGGESTION' | translate }}</p>
      <app-signature-pad (saveSignature)="saveSignature($event)" [category]="category"></app-signature-pad>
    </div>
  </div>
</div>
