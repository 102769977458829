import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { CommonModule, CurrencyPipe, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { ModalModule } from '@shared/components/ui/modal/modal.module';
import { TermsComponent } from '@shared/components/ui/terms/terms.component';
import { SharedModule } from '@shared/shared.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { NgxMaskModule } from 'ngx-mask';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { CardPropostaComponent } from './card-proposta/card-proposta.component';
import { AlertMaterialComponent } from './dialog/alert/alertMaterial.component';
import { HeaderDesktopComponent } from './header-desktop/header-desktop.component';
import { HomePageBodyComponent } from './home-page/body/home-page-body/home-page-body.component';
import { HomePageHeaderComponent } from './home-page/header/home-page-header.component';
import { HomePageComponent } from './home-page/home-page.component';
import { OfferPageComponent } from './offer-page/offer-page.component';
import { PreAnaliseComponent } from './pre-analise/pre-analise.component';
import { ProductsRoutingModule } from './products-routing.module';
import { ProductsComponent } from './products.component';
import { ProposalBodyComponent } from './proposal/body/proposal-body/proposal-body.component';
import { ProposalPageComponent } from './proposal/proposal-page.component';
import { ProposalSimulationComponent } from './simulation/body/proposal-simulation/proposal-simulation.component';
import { HeaderMobileComponent } from './simulation/header/header-mobile/header-mobile.component';
import { StepsComponent } from './simulation/header/steps/steps.component';
import { ProposalService } from './simulation/service/proposal.service';
registerLocaleData(localePt);

@NgModule({
  imports: [
    ModalModule,
    ProductsRoutingModule,
    CommonModule,
    FormsModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatFormFieldModule,
    MatExpansionModule,
    MatDividerModule,
    MatToolbarModule,
    MatDialogModule,
    MatCardModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    CurrencyMaskModule,
    CarouselModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    MatFormFieldModule,
    A11yModule,
    DragDropModule,
    ScrollingModule,
    PortalModule,
    CdkTableModule,
    CdkTreeModule,
    CdkStepperModule,
    SharedModule
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'pt-BR'
    },
    CurrencyPipe,
    ProposalService
  ],
  declarations: [
    TermsComponent,
    HomePageComponent,
    HomePageBodyComponent,
    HomePageHeaderComponent,
    ProposalPageComponent,
    // HeaderPropostasComponent,
    ProposalSimulationComponent,
    AlertMaterialComponent,
    HeaderMobileComponent,
    StepsComponent,
    ProposalBodyComponent,
    ProductsComponent,
    AlertMaterialComponent,
    HeaderDesktopComponent,
    PreAnaliseComponent,
    CardPropostaComponent,
    OfferPageComponent
  ],
  bootstrap: [ProductsComponent]
})
export class ProductsModule {}
