import gql from 'graphql-tag';

export const GET_CHANNELS = gql`
  query getChannels($search: String) {
    getChannels(search: $search) {
      idChannel
      dsChannel
    }
  }
`;
