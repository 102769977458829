import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { ErrorModalComponent } from '@app/proposal-workflow/components/error-modal/error-modal.component';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import * as AesJS from 'aes-js';
import { localStorageSync } from 'ngrx-store-localstorage';
import { environment } from 'src/environments/environment';
import { effects, reducers } from './';

function encrypt(text: string): string {
  let encText = '';

  try {
    const emBase64 = window.btoa(encodeURI(text));
    const aesOfb = new AesJS.ModeOfOperation.ofb(
      [10, 11, 12, 13, 14, 15, 15, 17, 18, 19, 20, 21, 22, 23, 24, 29],
      [37, 38, 39, 40, 41, 41, 43, 44, 45, 46, 47, 48, 49, 50, 51, 57]
    );
    const textBytes = AesJS.utils.utf8.toBytes(emBase64);
    const encryptedBytes = aesOfb.encrypt(textBytes);
    encText = AesJS.utils.hex.fromBytes(encryptedBytes);
  } catch (e) {
    window.localStorage.clear();
  }

  return encText;
}

function decrypt(encText: string): any {
  let decText = '';

  try {
    const aesOfb = new AesJS.ModeOfOperation.ofb(
      [10, 11, 12, 13, 14, 15, 15, 17, 18, 19, 20, 21, 22, 23, 24, 29],
      [37, 38, 39, 40, 41, 41, 43, 44, 45, 46, 47, 48, 49, 50, 51, 57]
    );
    const encryptedBytes = AesJS.utils.hex.toBytes(encText);
    const decryptedBytes = aesOfb.decrypt(encryptedBytes);
    const decryptedString = AesJS.utils.utf8.fromBytes(decryptedBytes);
    decText = decodeURI(window.atob(decryptedString));
  } catch (e) {
    window.localStorage.clear();
  }

  return decText;
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: [
      {
        login: {
          encrypt: state => encrypt(state),
          decrypt: state => decrypt(state)
        }
      },
      {
        startup: {
          encrypt: state => encrypt(state),
          decrypt: state => decrypt(state)
        }
      },
      {
        signUp: {
          encrypt: state => encrypt(state),
          decrypt: state => decrypt(state)
        }
      },
      {
        showcase: {
          encrypt: state => encrypt(state),
          decrypt: state => decrypt(state)
        }
      },
      {
        profile: {
          encrypt: state => encrypt(state),
          decrypt: state => decrypt(state)
        }
      },
      {
        proposal: {
          encrypt: state => encrypt(state),
          decrypt: state => decrypt(state)
        }
      },
      {
        preanalysis: {
          encrypt: state => encrypt(state),
          decrypt: state => decrypt(state)
        }
      },
      {
        fastCheckout: {
          encrypt: state => encrypt(state),
          decrypt: state => decrypt(state)
        }
      },
      {
        tabs: {
          encrypt: state => encrypt(state),
          decrypt: state => decrypt(state)
        }
      }
    ],
    rehydrate: true,
    storageKeySerializer: key => `loj_${key}`
  })(reducer);
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [ErrorModalComponent],
  imports: [
    MatIconModule,
    TranslateModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
        strictStateSerializability: false,
        strictActionSerializability: false
      }
    }),
    EffectsModule.forRoot(effects),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    CommonModule
  ]
})
export class StateModule {}
