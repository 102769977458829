import { Component, Input, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { AppState } from '@app/core/state';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';
import { FinancialStatementPeriodDialogModel } from '../../models/financial-statement.model';
import { FinancialStatementService } from '../../services/financial-statement.service';
import { StatementDownloadFileTypeDialogComponentComponent } from '../statement-download-file-type-dialog-component/statement-download-file-type-dialog-component.component';

@Component({
  selector: 'app-statement-period-download-dialog',
  templateUrl: './statement-period-download-dialog.component.html',
  styleUrls: ['./statement-period-download-dialog.component.scss']
})
export class StatementPeriodDownloadDialogComponent implements OnInit {
  @Input() financialStatementPeriod: FinancialStatementPeriodDialogModel[];
  shopId$: Observable<number> = this.store$.select('tabs').pipe(map(tab => tab.selectedTab.id));

  // Tag
  public readonly category = '/portallojista/pagamentos/detalhe/extrato';

  financialStatementPeriodSelected = [];
  @Input() selectedProduct: string;
  private periodSelected = [];
  public isShowingRecebidos;
  public optionSelected: string;
  public productSelected: string
  public tabSelected: string;
  public modalRef: NgbModalRef;


  constructor(
    public activeModal: NgbActiveModal,
    private financialStatementService: FinancialStatementService,
    private store$: Store<AppState>,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.addFinancialStatementPeriodSelected();
  }

  private addFinancialStatementPeriodSelected(): void {
    this.financialStatementPeriod.forEach((item: FinancialStatementPeriodDialogModel) => {
      if (item.checked) {
        this.financialStatementPeriodSelected.push(item.paymentDate);
      }
    });
  }

  public selectOption(event: MatCheckboxChange): void {
    if (event.checked) {
      const id = !this.financialStatementPeriodSelected.findIndex(x => x.id === event.source.value);
      this.financialStatementPeriodSelected.push(id);
    } else {
      const i = this.financialStatementPeriodSelected.findIndex(x => x.id === event.source.value);
      this.financialStatementPeriodSelected.splice(i, 1);
    }
  }

  public clearFinancialStatementPeriodSelected() {
    this.financialStatementPeriodSelected = [];
  }

  public downloadExtract() {
    this.financialStatementPeriodSelected.forEach(dataSelected => {
      if (dataSelected) {
        this.periodSelected.push(moment(dataSelected).format('DD/MM/YYYY'));
      }
    });
    if(this.selectedProduct === 'CSCP' && (this.tabSelected === 'Pagamentos Realizados' ||
    this.tabSelected === 'Saldo Futuro'
     )) {


      this.checkTheTabAndIfIsCSCPAndCallService();
    }else if (this.isShowingRecebidos ==='Recebidos' &&  this.tabSelected === 'Propostas Efetivadas') {
      if (this.selectedProduct === 'CSCP') {
        this.openDownloadFileTypeDialogCSCP(this.periodSelected, this.tabSelected);
      }

    } if (this.isShowingRecebidos === 'Recebidos') {
      this.openDownloadFileTypeDialogCSCP(this.periodSelected, this.tabSelected);
    } else if (this.isShowingRecebidos !== 'Recebidos' && this.tabSelected === 'Propostas Efetivadas') {

      this.openDownloadFileTypeDialogCSCP(this.periodSelected, this.tabSelected);
    }
  }

  private effectiveProposalsDownload(){
    this.shopId$
    .pipe(
      first(),
      switchMap(id =>
        this.financialStatementService.downloadEffectiveProposals(
          id,
          this.periodSelected,
          this.optionSelected
        )
      )
    )
    .subscribe(response => {
      this.processDownload(response);
    });

  }
  public downloadExtractPaymentMade(){
    this.shopId$
    .pipe(
      first(),
      switchMap(id =>
        this.financialStatementService.downloadExtractPaymentMade(
          id,
          this.periodSelected,
          this.optionSelected
        )
      )
    )
    .subscribe(response => {
      this.processDownload(response);
    });
  }

  private downloadFutureBalance(){
    this.shopId$
    .pipe(
      first(),
      switchMap(id =>
        this.financialStatementService.downloadExtractFutureBalances(
          id,
          this.periodSelected,
          this.optionSelected
        )
      )
    )
    .subscribe(response => {
      this.processDownload(response);
    });
  }
  private checkTheTabAndIfIsCSCPAndCallService() {
    if (this.tabSelected === 'Saldo Futuro') {
      this.openDownloadFileTypeDialogCSCP(this.periodSelected, this.tabSelected);
    }
    else  {
      this.openDownloadFileTypeDialogCSCP(this.periodSelected, this.tabSelected);
      //this.downloadExtractPaymentMade();
    }
  }

  openDownloadFileTypeDialogCSCP(date, type){
    this.modalRef = this.modalService.open(StatementDownloadFileTypeDialogComponentComponent,  {
  });
  this.modalRef.componentInstance.optionSelectedChange.subscribe(res=>{
    this.optionSelected = res
  })

  this.modalRef.result.then(
    result => {
      switch (this.tabSelected) {
        case 'Saldo Futuro':
          this.dowmloadMsssiveFutureBalances();
          break;
        case 'Pagamentos Realizados':
          this.downloadExtractPaymentMade();
          break;
        case 'Propostas Efetivadas':
          if(this.productSelected === 'CSCP'){
          this.effectiveProposalsDownload();
         // this.optionSelected = 'csv';
          this.financialStatementPeriodSelected = [];
          }
          if(this.isShowingRecebidos ==='Recebidos' && this.productSelected !== 'CSCP'){
            this.downloadExtractReceived();
          }
         else if (this.isShowingRecebidos !=='Recebidos'){
            this.downloadExtractRealizados();
         }

        break;


      }
       this.optionSelected = 'csv'

    },
    reason => {
      console.log('fechou');
    }
  )
}
  public dowmloadMsssiveFutureBalances() {
    console.log(this.financialStatementPeriodSelected)
    this.shopId$
      .pipe(
        first(),
        switchMap(id =>

          this.financialStatementService.downloadExtractFutureBalances(
            id,
            this.periodSelected,
            this.optionSelected,

          )
        )
      )
      .subscribe(response => {
        this.processDownload(response);
      });
    }

  public downloadExtractRealizados() {
    this.shopId$
      .pipe(
        first(),
        switchMap(id =>
          this.financialStatementService.downloadExtractSelectedPeriod(id, this.selectedProduct, this.periodSelected, this.optionSelected)
        )
      )
      .subscribe(response => {
        this.processDownload(response);
      });
  }

  public downloadExtractReceived() {
    this.shopId$
      .pipe(
        first(),
        switchMap(id =>
          this.financialStatementService.downloadExtractReceivedSelectedPeriod(
            id,
            this.selectedProduct,
            this.periodSelected,
            this.optionSelected
          )
        )
      )
      .subscribe(response => {
        this.processDownload(response);
      });
  }

  private processDownload(response) {
    const fileName = response.headers
      .get('content-disposition')
      .split(';')[1]
      .split('=')[1]
      .replace(/\"/g, '');

    const newBlob = new Blob([response.body], { type: 'text/csv' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(newBlob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(newBlob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
