import { BrazilMasks } from '@shared/interfaces/masks/brazil-masks.interface';

export const brazilMasks: BrazilMasks = {
  documentNumber: {
    mask: '000.000.000-009',
    basicMask: '000.000.000-00',
    maskBack: 'AAA.AAA.AAA-AA',
    hiddenMask: '000.XXX.XXX-09',
    companyMask: '00.000.000/0000-00',
    companyMaskBack: 'AA.AAA.AA0/0000-00',
    hiddenCompanyMask: '00.000.XXX/XXXX-00'
  },
  cellPhone: { mask: '(00) 0 0000-0000', basicMask: '(00) 00000-0000', prefix: '+55 ', maskBack: '(AA) AAAAA-AAAA' },
  businessPhone: { mask: '(00) 0000-0000', prefix: '+55 ' },
  preToken: { mask: '000.000.000-00' },
  date: { mask: '00/00/0000' },
  maskDate: { mask: '00/00/0000', maskBack: 'AA/AA/AAAA' },
  zipCode: { mask: 'AAAAA-AAA', maskBack: 'AAAAA-AAA' },
  cnpj: { mask: '00.000.000/0000-00' },
  bankAccountNumber: { mask: '00000000-0' },
  income: {
    options: {
      align: 'left',
      allowNegative: false,
      decimal: ',',
      thousands: '.',
      precision: 2,
      prefix: 'R$ ',
      suffix: '',
      min: 0.01,
      max: 999999999.99,
      inputMode: 'FINANCIAL'
    },
    maxLength: '17'
  },

  dateOfBirth: { mask: '00/00/0000' },
  phone: {
    mask: '(00) 00000-0000',
    cellPhone: '(00) 00000-0000',
    commercialPhone: '(00) 0000-0000',
    mainPhoneMask: '(00) 0000-00009',
    prefix: '+55 ',
    showMaskTyped: true
  },
  travelDate: { mask: '00/00/0000' },
  financedValue: {
    mask: 'separator.2',
    placeholder: 'R$ 0,00',
    prefix: 'R$ ',
    thousandSeparator: '.',
    maxLength: '17',
    options: {
      align: 'left',
      allowNegative: false,
      decimal: ',',
      thousands: '.',
      precision: 2,
      prefix: 'R$ ',
      suffix: '',
      min: 0.01,
      max: 999999999.99,
      inputMode: 'FINANCIAL'
    }
  },
  entryValue: {
    mask: 'separator.2',
    placeholder: 'R$ 0,00',
    prefix: 'R$ ',
    thousandSeparator: '.',
    maxLength: '17',
    options: {
      align: 'left',
      allowNegative: false,
      decimal: ',',
      thousands: '.',
      precision: 2,
      prefix: 'R$ ',
      suffix: '',
      min: 0.0,
      max: 999999999.99,
      inputMode: 'FINANCIAL'
    }
  },
  purchaseValue: {
    options: {
      align: 'left',
      allowNegative: false,
      decimal: ',',
      thousands: '.',
      precision: 2,
      prefix: 'R$ ',
      suffix: '',
      min: 0.01,
      max: 999999999.99,
      inputMode: 'FINANCIAL'
    },
    maxLength: '19'
  }
};
