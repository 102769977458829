<div *ngIf="registrationFeeOption">
  <mat-radio-button
    class="registration-fee-radio-button d-flex align-items-start"
    ngDefaultControl
    (change)="regFeeSelected()"
    [(ngModel)]="pickedRegFeeOption"
    [value]="registrationFeeOption?.id"
    [checked]="registrationFeeOption?.id === selectedFeeOption?.id"
  >
    <div class="align-items-start tariff-item">
      <div class="mat-radio-button-text mat-icon-div">
        <mat-icon>{{ registrationFeeOption?.icon }}</mat-icon>
      </div>
      <div class="mat-radio-button-text rf-op-label">
        <p class="rf-op-label">
          {{ registrationFeeOption?.label }}
        </p>
      </div>
      <div class="mat-radio-button-text rf-op-fee">
        <div class="row no-gutters">
          <div class="">{{ registrationFeeOption?.fee?.feePrefix }} &nbsp;</div>
          <div *ngIf="registrationFeeOption?.fee?.feeValue">
            {{ registrationFeeOption?.fee?.feeValue | localeCurrency }}
          </div>
        </div>
      </div>
      <div class="mat-radio-button-text rf-op-desc">
        {{ registrationFeeOption?.description }}
        <a
          *ngIf="registrationFeeOption?.descriptionMoreInfoURL"
          href="registrationFeeOption.descriptionMoreInfoURL"
          (click)="regFeeSelectedLink($event)"
          class="know-more-link"
          appTagger
          [event_category]="category"
          event_label="{{ 'KNOW-MORE' | translate }}"
          [event_action]="Selecionar"
        >
          {{ 'KNOW-MORE' | translate }}
        </a>
      </div>
    </div>
  </mat-radio-button>
</div>
