<div id="modal-content-wrapper">
  <header id="modal-header" class="text-center mt-4">
    <img src="./assets/images/ic_exclamation.svg" width="44px" height="44px" alt="Alerta" class="mb-4" />
    <h1 id="modal-title mt-2 mb-2" mat-dialog-title>{{ 'NOT-POSSIBLE-REQUEST-PAYMENTS-TITLE' | translate }}</h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <p class="mb-4">{{ 'INVALID-DATA-CHECKING-ACCOUNT-MSG' | translate }}</p>
  </section>
  <footer id="modal-footer" mat-dialog-actions class="mb-3">
    <button mat-flat-button id="modal-close-button" color="primary" (click)="dialogRef.close()">{{ 'UNDERSTOOD' | translate }}</button>
  </footer>
</div>
