import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ArgDocumentScanComponent } from './components/arg-document-scan/arg-document-scan.component';
import { BraDocumentScanComponent } from './components/bra-document-scan/bra-document-scan.component';
import { PerDocumentScanComponent } from './components/per-document-scan/per-document-scan.component';
import { DocumentScanWrapperComponent } from './components/document-scan-wrapper/document-scan-wrapper.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [
    ArgDocumentScanComponent,
    BraDocumentScanComponent,
    PerDocumentScanComponent,
    DocumentScanWrapperComponent
  ],
  imports: [CommonModule, TranslateModule, MatButtonModule],
  exports: [DocumentScanWrapperComponent]
})
export class DocumentScanModule {}
