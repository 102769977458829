import { StartupService } from '@app/core/startup/services/startup.service';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../state';
import { routerTransition } from '@app/router.animations';
import * as fromStartup from '../state/startup';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-initial',
  template: '',
  animations: [routerTransition()]
})
export class StartupContainerComponent implements OnInit {
  constructor(
    private store$: Store<AppState>,
    private route: ActivatedRoute,
    private deviceDetectorService: DeviceDetectorService,
    private startupService: StartupService
  ) {}

  ngOnInit() {}

  verifyAccess(): void {
    this.store$.dispatch(
      new fromStartup.actions.GetUserInfoRequest({
        isMobile: this.deviceDetectorService.isMobile(),
        isDesktop: this.deviceDetectorService.isDesktop()
      })
    );
  }
}
