import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { PreAnalysisService } from '@app/proposal-workflow/service/pre-analysis/pre-analysis.service';
import { Identification } from '@app/showcase/models/api/post.identification.model';
import { ProposalCancelDialogComponent } from '@app/showcase/proposals/components/proposal-cancel-dialog/proposal-cancel-dialog.component';
import { OpenProposalService } from '@app/showcase/proposals/services/open-proposal.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ModalOptions, PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { RemoveRequestAction } from '@shared/components/widgets/loading/store/loading.actions';
import { catchError, switchMap, take } from 'rxjs/operators';
import { AppState } from '..';
import { GetStatusProposals, SaveCalculationPayload } from '../simulation/simulation.actions';
import * as actions from './pre-analysis.actions';

@Injectable()
export class PreAnalysisEffects {
  constructor(
    private actions$: Actions,
    private preAnalysisService: PreAnalysisService,
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private genTagger: GenTagger,
    private openProposalService: OpenProposalService,
    public router: Router
  ) {}

  @Effect()
  submitIdentificationAction = this.actions$.pipe(
    ofType<actions.SubmitIdentificationAction>(actions.PreAnalysisActionTypes.SUBMIT_IDENTIFICATION),
    switchMap(action => {
      return this.preAnalysisService
        .submitIdentification({ ...action.payload, redirectProposal: action.payload.redirectProposal })
        .pipe(
          switchMap(response => {
            if (!response.simulation) {
              this.store$.dispatch(new RemoveRequestAction({ id: 'LOADING', request: null }));

              const isProposalExistent = response.proposal && +response.proposal.id > 0;
              const isNotPossibleCancelSimulation = response.simulation.isNotPossibleCancelSimulation;

              if (isProposalExistent) {
                this.dialog
                  .open(PionModalComponent, {
                    width: '560px',
                    autoFocus: false,
                    id: 'cancel-proposal-simulation',
                    data: <ModalOptions>{
                      title: 'Atenção',
                      description: 'Já existe uma proposta em andamento, deseja cancelar e continuar?',
                      cancelText: 'Não',
                      confirmText: 'Sim, cancelar',
                      type: 'alert'
                    }
                  })
                  .afterClosed()
                  .subscribe(confirm => {
                    if (confirm) {
                      const cancelRef = this.dialog.open(ProposalCancelDialogComponent, {
                        width: '560px',
                        autoFocus: false,
                        id: 'cancel-proposal-dialog',
                        data: {
                          proposalId: +response.proposal.id
                        }
                      });

                      cancelRef.componentInstance.okExit.subscribe(() => {
                        this.store$.dispatch(new actions.SubmitIdentificationAction(action.payload));
                      });
                    }
                  });
                return [];
              }

              if (isNotPossibleCancelSimulation) {
                const data = {
                  title: 'Não é possível Iniciar uma proposta nova',
                  description: `Você possui uma proposta em andamento e por isso não é possível solicitar uma nova simulação.
                    Sua proposta pode estar em análise documental, de crédito, ou em processo de pagamento.`,
                  confirmText: 'Ok',
                  type: 'error'
                };
                const _ref = this.dialog.open(PionModalComponent, {
                  width: '560px',
                  autoFocus: false,
                  id: 'cancel-proposal-simulation-warning',
                  data
                });
                _ref.afterOpened().subscribe(() => {
                  this.genTagger.setTag({
                    event_category: '/portallojista/login',
                    event_action: `${Tag.AbriuModal} - ${data.title}`,
                    event_label: data.description
                  });
                });
                _ref.afterClosed().subscribe(() => {
                  this.genTagger.setTag({
                    event_category: '/portallojista/login',
                    event_action: `${Tag.Fechou_Modal} - ${data.title}`,
                    event_label: data.confirmText
                  });
                });
                return [];
              }
            }
            return [
              new actions.SubmitIdentificationSuccessAction({
                ...response,
                redirectProposal: action.payload.redirectProposal,
                offerPayload: action.payload.offerPayload
              })
            ];
          }),
          catchError(err => {
            return [new actions.SubmitIdentificationErrorAction(err)];
          })
        );
    })
  );

  @Effect()
  SubmitIdentificationIncomeAction = this.actions$.pipe(
    ofType<actions.SubmitIdentificationIncomeAction>(actions.PreAnalysisActionTypes.SUBMIT_IDENTIFICATION_INCOME),
    switchMap(action => {
      const payload: Identification = {
        income: action.payload.income,
        cupom: action.payload.couponCode,
        installmentAmount: action.payload.installmentAmount,
        paymentPlan: action.payload.paymentPlan,
        paymentMethod: action.payload.paymentMethod,
        offerPayload: action.payload.offerPayload
      };

      let calculationPayload;

      this.store$
        .pipe(
          select('simulation'),
          take(1)
        )
        .subscribe(res => (calculationPayload = res.calculationPayload));

      if (action.payload.inputChanged.input === InputTypes.COUPON_CODE) {
        if (calculationPayload) {
          calculationPayload.pricing.couponCode = action.payload.inputChanged.value.pricing.couponCode;
        }
      }

      if (action.payload.inputChanged.input === InputTypes.PAYMENT_METHOD) {
        calculationPayload.pricing.paymentMethod = action.payload.inputChanged.value.pricing.paymentMethod;
      }

      if (action.payload.inputChanged.input === InputTypes.INPUT_CREDIT) {
        if (!action.payload.offerPayload) {
          calculationPayload.preAnalysis.income = action.payload.inputChanged.value.preAnalysis.income;
        }
      }

      return [
        new SaveCalculationPayload(calculationPayload),
        new actions.SubmitIdentificationAction({ ...payload, redirectProposal: action.payload.redirectProposal })
      ];
    })
  );

  @Effect()
  submitIdentificationSuccessAction = this.actions$.pipe(
    ofType<actions.SubmitIdentificationSuccessAction>(actions.PreAnalysisActionTypes.SUBMIT_IDENTIFICATION_SUCCESS),
    switchMap(action => {
      return [
        new GetStatusProposals({
          simulationId: action.payload.simulation,
          redirectProposal: action.payload.redirectProposal,
          offerPayload: action.payload.offerPayload
        })
      ];
    })
  );
}
