import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Tag } from './tagger.directive';
import { TaggerService } from './tagger.service';
import { InteractionEventTag, PageviewTag, RequestEventTag } from './tagging-iface';

@Injectable({ providedIn: 'root' })
export class GenTagger {
  constructor(private taggerService: TaggerService) {}

  /**
   * Check type of tag to push the proper values to the datalayer
   * @date 11/22/2023
   * @author lilian.n.silva
   *
   * @public
   * @param {(InteractionEventTag | PageviewTag | RequestEventTag)} tag
   */
  public setTag(tag: InteractionEventTag | PageviewTag | RequestEventTag) {
    if (!environment.featureToggle.tagueamentoGA.active) {
      return;
    }

    if (this.taggerService.checkClienteId()) {
      const globalData = this.taggerService.getStoreVal();

      let dataToTag;
      switch (tag.event_action) {
        case Tag.pgView:
          dataToTag = this.setPageviewTag(tag);
          break;
        case Tag.Request:
          dataToTag = this.setRequestTag(tag);
          break;
        default:
          dataToTag = this.setInteractionTag(tag);
          break;
      }

      const formattedData = this.setToLowercase({ ...globalData, ...dataToTag });
      window['dataLayer'].push(formattedData);

      // local logging ONLY, for readability
      // console.table(formattedData);

      // non-productive logging ONLY, for validation
      // console.log(JSON.stringify(formattedData));
    } else {
      setTimeout(() => {
        this.setTag(tag);
      }, 1000);
    }
  }

  /**
   * Adds interacting event tags to the datalayer such as clicks and selections
   * @date 11/22/2023
   * @author lilian.n.silva
   *
   * @public
   * @param {InteractionEventTag} tag
   * @returns {{ event_category: string; event_type: string; event_nointeraction: boolean; event_action: any; event_label: any; event_element: any; page_location: any; page_section: any; timestamp: string; session_id: any; client_id: any; }}
   */
  public setInteractionTag(tag: InteractionEventTag) {
    const eventAction = tag.event_action as any;
    let event_page_location: string;
    let event_action = eventAction;

    if (eventAction.event_action_type || eventAction.event_action_complement) {
      event_page_location = (eventAction.event_action_type + ' - ' + eventAction.event_action_complement) as Tag;
    } else if (typeof eventAction === 'string' && eventAction.split(' - ').length > 1) {
      const event_split = eventAction.split(' - ');
      event_action = event_split[0];
      event_page_location = event_split[1];
    }

    return {
      event_category: 'lojistabens',
      event_type: 'event',
      event_nointeraction: false,
      event_action: eventAction.event_action_type ? eventAction.event_action_type : event_action,
      event_label: this.maskFields(tag.event_label),
      event_element: tag.event_element || 'button',
      page_location: tag.page_location ? tag.page_location : tag.event_category,
      page_section: tag.page_section ? tag.page_section : event_page_location
    };
  }

  /**
   * Set page_view events to the datalayer
   * @date 11/22/2023
   * @author lilian.n.silva
   *
   * @public
   * @param {PageviewTag} tag
   * @returns {{ event_type: string; page_location: any; title: any; }}
   */
  public setPageviewTag(tag: PageviewTag) {
    const page_location = tag.page_location ? tag.page_location : tag.event_category;
    this.taggerService.setPageLocation(page_location);
    return {
      event_type: 'page_view',
      page_location: this.maskFields(page_location),
      title: tag.title ? tag.title : tag.event_label
    };
  }

  /**
   * Set request tags to the datalayer
   * @date 11/22/2023
   * @author lilian.n.silva
   *
   * @public
   * @param {RequestEventTag} tag
   * @returns {{ event_type: string; event_nointeraction: boolean; event_action: string; event_label: any; page_location: any; request_initiator: string; request_url: any; request_time: any; request_status: any; request_address: any; }}
   */
  public setRequestTag(tag: RequestEventTag) {
    return {
      event_type: 'request',
      event_nointeraction: true,
      event_action: 'request',
      event_label: this.maskFields(tag.event_label),
      page_location: this.maskFields(tag.page_location),
      request_initiator: 'xhr',
      request_url: tag.request_url,
      request_time: tag.request_time,
      request_status: tag.request_status,
      request_address: tag.request_address
    };
  }

  /**
   * Sets all nodes of a object to lowercase and cleans null values
   * @date 11/22/2023
   * @author lilian.n.silva
   *
   * @private
   * @param {*} data
   * @returns {*}
   */
  private setToLowercase(data) {
    for (let key in data) {
      if (data[key] === null || data[key] === undefined) {
        delete data[key];
      }

      if (typeof data[key] === 'string') {
        data[key] = data[key].toLowerCase();
      }
    }
    return data;
  }

  /**
   * mask actual IDs to turn it into the string: {id}
   * @date 12/14/2023
   * @author lilian.n.silva
   *
   * @private
   * @param {String} field
   * @returns {String} maskedField
   */
  private maskFields(field: string) {
    if (!field || field.length === 0) return field;
    const fieldSplitParams = field.split('?');
    const maskedField = fieldSplitParams[0]
      .replace(/\/[0-9]+\//g, '/{id}/')
      .replace(/\/[0-9]+$/g, '/{id}')
      .replace(/\b[a-zA-Z0-9]+(?:-[a-zA-Z0-9]+){2,}(?:\d{3})?\b/g, '{hash}');
    return fieldSplitParams.length > 1 ? `${maskedField}?{hash}` : maskedField;
  }
}
