<div id="modal-content-wrapper">
  <header id="modal-header" class="text-center">
    <h1 id="modal-title" mat-dialog-title>
      <strong>{{ 'REGISTRATION-FEE-MODAL-TITLE' | translate }}</strong>
    </h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <div class="info-section-1">
      {{ 'REGISTRATION-FEE-MODAL-DESCRIPTION' | translate }}
    </div>
    <div class="info-section-2">
      {{ 'REGISTRATION-FEE-MODAL-INFO' | translate }}
    </div>
  </section>
  <footer id="modal-footer" class="footer-buttons" mat-dialog-actions>
    <button
      mat-flat-button
      id="modal-cancel-button"
      color="primary"
      class="button-white"
      (click)="cancelActionFunction()"
    >
      {{ 'REGISTRATION-FEE-MODAL-BUTTON-PAY-CASH' | translate }}
    </button>
    <button
      mat-flat-button
      id="modal-action-button"
      color="primary"
      class="button-red"
      (click)="confirmActionFunction()"
    >
      {{ 'REGISTRATION-FEE-MODAL-BUTTON-INCLUDE-IN-PARCEL' | translate }}
    </button>
  </footer>
</div>
