<app-side-content [name]="profileName" [show]="true">
  <div class="container" *ngIf="!showUpdateSuccess; else updateSuccess">
    <div class="row">
      <div class="col-11">
        <p class="title">{{ 'Profile' | translate }}</p>
      </div>
    </div>

    <form [formGroup]="profileForm" (ngSubmit)="submitUserData()">
      <div class="row">
        <div class="col-12 col-md-6">
          <mat-form-field color="black" appearance="legacy" class="input">
            <mat-label>{{ 'Name' | translate }}</mat-label>
            <input autocomplete="off" type="text" matInput class="input-with-icon" formControlName="name" />
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
          <mat-form-field color="black" appearance="legacy" class="input">
            <mat-label>{{ 'DOCUMENT-NUMBER' | translate }}</mat-label>
            <input
              autocomplete="off"
              type="text"
              matInput
              class="input-with-icon"
              formControlName="documentNumber"
              mask="{{ $any(pioneerMasks).documentNumber.mask }}"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="input-row">
            <mat-form-field color="black" appearance="legacy" class="input">
              <mat-label>{{ 'Main Phone' | translate }}</mat-label>
              <input
                autocomplete="off"
                type="text"
                matInput
                class="input-with-icon"
                formControlName="phone"
                mask="{{ $any(pioneerMasks).cellPhone.mask }}"
                appTagger
                event_action="preencheu campo"
                [event_category]="event_category"
                event_label="telefone principal"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <mat-form-field color="black" appearance="legacy" class="input">
            <mat-label>{{ 'EMAIL' | translate }}</mat-label>
            <input
              autocomplete="off"
              type="email"
              matInput
              class="input-with-icon"
              formControlName="email"
              appTagger
              event_action="preencheu campo"
              [event_category]="event_category"
              event_label="e-mail"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row" *ngIf="mappedProfiles?.length">
        <div class="col-12 col-md-6">
          <mat-form-field color="black" appearance="legacy" class="input">
            <mat-label>{{ 'Profile' | translate }}</mat-label>
            <ng-container *ngFor="let profile of mappedProfiles; index as i">
              <input autocomplete="off" matInput class="input-with-icon" formControlName="{{ 'profile-' + i }}" />
            </ng-container>
          </mat-form-field>
        </div>
      </div>

      <button
        type="submit"
        class="action-button"
        [disabled]="!profileForm.valid"
        appTagger
        [event_category]="event_category"
        event_label="Salvar alterações"
      >
        {{ 'SAVE-CHANGES' | translate }}
      </button>
    </form>
  </div>

  <ng-template #updateSuccess>
    <section>
      <div class="container">
        <p class="title">{{ 'Profile' | translate }}</p>

        <div class="full-content">
          <div class="d-flex">
            <img src="./assets/images/Check.svg" alt="check icon" />
            <p>{{ 'SUCCESS-SAVE-PROFILE' | translate }}</p>
          </div>
        </div>

        <mat-card>
          <div class="row">
            <div class="col-12 col-md-6">
              <label>{{ 'Name' | translate }}</label>
              <p>{{ profileData.name }}</p>
              <label>{{ 'Main Phone' | translate }}</label>
              <p>
                {{ profileData?.phoneAreaCode + profileData?.phoneNumber | mask: $any(pioneerMasks).cellPhone.mask }}
              </p>
              <label>{{ 'Profile' | translate }}</label>
              <p *ngFor="let profile of mappedProfiles">
                {{ profile }}
              </p>
            </div>
            <div class="col-12 col-md-6">
              <label>{{ 'DOCUMENT-NUMBER' | translate }}</label>
              <p>{{ profileData.documentNumber | mask: $any(pioneerMasks).documentNumber.mask }}</p>
              <label>{{ 'EMAIL' | translate }}</label>
              <div class="long-word">
                <p>{{ profileData.email }}</p>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </section>
  </ng-template>
</app-side-content>
