import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import * as challenge from '@app/core/state/challenge';
import { DialogNotAvailableComponent } from '@app/signup/components/password/dialog-not-available/dialog-not-available.component';
import { DialogPasswordSuccessComponent } from '@app/signup/components/password/dialog-password-success/dialog-password-success.component';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { Countries } from '@shared/constants/countries.enum';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { Subject } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.scss']
})
export class NewPasswordComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  public currentCountry: Countries = this.internationalizationService.currentCountry;
  public readonly category = '/portallojista/recuperarsenha/cadastro';

  constructor(
    private store$: Store<AppState>,
    private readonly dialog: MatDialog,
    private internationalizationService: InternationalizationService,
    private router: Router,
    public genTagger: GenTagger
  ) {}

  ngOnInit() {
    this.store$.dispatch(new challenge.actions.GetChallenge({ refresh: true }));

    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.pgView,
      event_label: 'Cadastrar nova senha'
    });

    // this.store$
    //   .select(selectPasswordChangeOutcome)
    //   .pipe(
    //     takeUntil(this.ngUnsubscribe),
    //     filter(outcome => !!outcome)
    //   )
    //   .subscribe(outcome => this.promptDialog(outcome));
  }

  public handlePasswordEmit(password: string): void {
    // this.store$.dispatch(new fromLogin.actions.SubmitNewPasswordAction(password));
    this.store$.dispatch(new challenge.actions.AnswerChallenge({ password }));
  }

  private promptDialog(outcome: 'success' | 'failure'): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const component: any = outcome === 'failure' ? DialogNotAvailableComponent : DialogPasswordSuccessComponent;

    const dialogRef = this.dialog.open(component, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(() => {
        this.router.navigate(['/login']);
        // this.store$.dispatch(new fromLogin.actions.ResetLoginData());
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
