import { Action } from '@ngrx/store';

export enum StartupActionTypes {
  GET_CLIENT_CREDENTIALS = '[Startup] Get Client Credentials',
  GET_CLIENT_CREDENTIALS_SUCCESS = '[Startup] Get Client Credentials Success',
  GET_CLIENT_CREDENTIALS_ERROR = '[Startup] Get Client Credentials Error',

  VERIFY_ACCESS = '[Startup] Verify access',
  VERIFY_ACCESS_SUCCESS = '[Startup] Verify access success',
  VERIFY_ACCESS_ERROR = '[Startup] Verify access error',

  GET_USER_INFO_REQUEST = '[Startup] Get user info',
  GET_USER_INFO_SUCCESS = '[Startup] Get user info success',
  GET_USER_INFO_ERROR = '[Startup] Get user info error',

  GET_TERMS_REQUEST = '[Startup] Get terms request',
  GET_TERMS_SUCCESS = '[Startup] Get terms success',
  GET_TERMS_ERROR = '[Startup] Get terms error',

  RESOLVE_PENDING_REQUESTS = '[Startup] Resolve pending requests',
  REDIRECT = '[Startup] Redirect',

  GET_DEVICE_INFO_REQUEST = '[Startup] GET_DEVICE_INFO_REQUEST'
}

export class GetClientCredentialsAction implements Action {
  readonly type = StartupActionTypes.GET_CLIENT_CREDENTIALS;
  constructor(public payload?: any) {}
}

export class GetClientCredentialsSuccessAction implements Action {
  readonly type = StartupActionTypes.GET_CLIENT_CREDENTIALS_SUCCESS;
  constructor(public payload?: any) {}
}

export class GetClientCredentialsErrorAction implements Action {
  readonly type = StartupActionTypes.GET_CLIENT_CREDENTIALS_ERROR;
  constructor(public payload?: any) {}
}

export class VerifyAccess implements Action {
  readonly type = StartupActionTypes.VERIFY_ACCESS;
  constructor(public payload: any) {}
}

export class VerifyAccessSuccess implements Action {
  readonly type = StartupActionTypes.VERIFY_ACCESS_SUCCESS;
  constructor(public payload: any) {}
}

export class VerifyAccessError implements Action {
  readonly type = StartupActionTypes.VERIFY_ACCESS_ERROR;
  constructor(public payload: any) {}
}

export class GetUserInfoRequest implements Action {
  readonly type = StartupActionTypes.GET_USER_INFO_REQUEST;
  constructor(public payload?: any) {}
}

export class GetUserInfoSuccess implements Action {
  readonly type = StartupActionTypes.GET_USER_INFO_SUCCESS;
  constructor(public payload: any) {}
}

export class GetUserInfoError implements Action {
  readonly type = StartupActionTypes.GET_USER_INFO_ERROR;
  constructor(public payload: any) {}
}

export class GetTermsRequest implements Action {
  readonly type = StartupActionTypes.GET_TERMS_REQUEST;
  constructor() {}
}

export class GetTermsSuccess implements Action {
  readonly type = StartupActionTypes.GET_TERMS_SUCCESS;
  constructor(public payload: any) {}
}

export class GetTermsError implements Action {
  readonly type = StartupActionTypes.GET_TERMS_ERROR;
  constructor(public payload: any) {}
}

export class ResolvePendingRequests implements Action {
  readonly type = StartupActionTypes.RESOLVE_PENDING_REQUESTS;
  constructor() {}
}

export class Redirect implements Action {
  readonly type = StartupActionTypes.REDIRECT;
  constructor() {}
}

export class GetDeviceInfoRequest implements Action {
  readonly type = StartupActionTypes.GET_DEVICE_INFO_REQUEST;
  constructor(public payload?: any) {}
}

export type StartupActions =
  | GetClientCredentialsAction
  | GetClientCredentialsSuccessAction
  | GetClientCredentialsErrorAction
  | VerifyAccess
  | VerifyAccessSuccess
  | VerifyAccessError
  | GetUserInfoRequest
  | GetUserInfoSuccess
  | GetUserInfoError
  | GetTermsRequest
  | GetTermsSuccess
  | GetTermsError
  | ResolvePendingRequests
  | Redirect
  | GetDeviceInfoRequest;
