import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AppState } from '@app/core/state';
import * as fromProfile from '@app/core/state/profile';
import { ProfileData } from '@app/core/state/profile/profile.reducer';
import { UserProfilesEnum } from '@app/profile/interface/profile.enum';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { Store } from '@ngrx/store';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-profile-data',
  templateUrl: './profile-data.component.html',
  styleUrls: ['./profile-data.component.scss']
})
export class ProfileDataComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  public profileForm: FormGroup;
  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  public profileData: ProfileData;
  public confirmationPhone: string;
  public mappedProfiles: string[];
  public profileName = 'perfil';

  public showUpdateSuccess = false;
  public event_category;
  readonly Insert: Tag = Tag.Insert;

  constructor(
    private internationalizationService: InternationalizationService,
    private store$: Store<AppState>,
    private genTagger: GenTagger
  ) {}

  get eventActionModal(): ExtendedTag {
    return {
      event_action_complement: 'Perfil',
      event_action_type: Tag.AbriuModal
    };
  }

  ngOnInit() {
    this.generateForm();
    this.setProfileSubscription();
    this.event_category = window.location.href;
    this.genTagger.setTag({
      event_category: this.event_category,
      event_action: this.eventActionModal,
      event_label: 'Perfil'
    });
  }

  private setProfileSubscription(): void {
    this.store$
      .select(fromProfile.selectors.userProfileSelector)
      .pipe(
        take(1),
        filter(state => !!state)
      )
      .subscribe(profile => {
        this.profileData = profile;
        const { name, documentNumber, phoneAreaCode, phoneNumber, email, userProfiles } = this.profileData;
        const formValues = {
          name,
          documentNumber,
          phone: phoneAreaCode + phoneNumber,
          email
        };

        this.profileForm.patchValue(formValues);

        this.mappedProfiles = this.mapUserProfiles(userProfiles);

        if (!!this.mappedProfiles && this.mappedProfiles.length) {
          for (let i = 0; i < this.mappedProfiles.length; i++) {
            this.profileForm.addControl(
              `profile-${i}`,
              new FormControl({ value: this.mappedProfiles[i], disabled: true })
            );
          }
        }
      });
  }

  private mapUserProfiles(profiles: string[]): string[] {
    const mappedProfiles: string[] = [];

    const isMaster = profiles.some(profile => profile.includes(UserProfilesEnum.MASTER.toUpperCase()));
    const isSalesman = profiles.some(profile => profile.includes(UserProfilesEnum.SALESMAN.toUpperCase()));
    const isRepresentative = profiles.some(profile => profile.includes(UserProfilesEnum.REPRESENTATIVE.toUpperCase()));
    const isFinancial = profiles.some(profile => profile.includes(UserProfilesEnum.FINANCING.toUpperCase()));

    if (isMaster) {
      mappedProfiles.push(UserProfilesEnum.MASTER);
    }
    if (isSalesman) {
      mappedProfiles.push(UserProfilesEnum.SALESMAN);
    }
    if (isRepresentative) {
      mappedProfiles.push(UserProfilesEnum.REPRESENTATIVE);
    }
    if (isFinancial) {
      mappedProfiles.push(UserProfilesEnum.FINANCING);
    }

    return mappedProfiles;
  }

  private generateForm(): void {
    this.profileForm = new FormGroup({
      name: new FormControl({ value: '', disabled: true }),
      documentNumber: new FormControl({ value: '', disabled: true }),
      phone: new FormControl('', this.pioneerValidators.cellPhone),
      email: new FormControl('', this.pioneerValidators.email)
    });
  }

  public submitUserData() {
    const formValue = this.profileForm.getRawValue();

    this.profileData = {
      ...this.profileData,
      phoneInternationalCode: '55',
      phoneAreaCode: formValue.phone.substring(0, 2),
      phoneNumber: formValue.phone.substring(2, 12),
      email: formValue.email
    };

    this.store$.dispatch(new fromProfile.actions.UpdateUserProfileRequest(this.profileData));
    this.showUpdateSuccess = true;
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
