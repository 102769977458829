import { Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { InputChange } from '@app/proposal-workflow/models/input-change.model';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TranslateService } from '@ngx-translate/core';
import { CalendarDialogComponent } from '@shared/components/ui/calendar-dialog/calendar-dialog.component';
import { CalendarConfig } from '@shared/components/ui/calendar/calendar.model';
import * as moment from 'moment';

@Component({
  selector: 'app-installment-info-lack',
  templateUrl: './installment-info-lack.component.html',
  styleUrls: ['./installment-info-lack.component.scss']
})
export class InstallmentInfoLack implements OnInit, OnChanges {
  @Input() selectedPaymentMethod: any;
  @Input() months: any = [];
  @Input() installmentPMethodsDate: any = [];
  @Input() codeMonthsRange;
  @Input() firstInstallmentPMethodsDate;
  @Input() category: string;
  @Output() installmentInfoEmitterLack = new EventEmitter<InputChange>();
  @Output() public selectedValidatorsEmitter = new EventEmitter<boolean>();

  readonly Insert: Tag = Tag.Insert;
  readonly Selecionar: Tag = Tag.Selecionar;
  resize: number;
  public showCalendar = false;
  selectedLack: FormGroup;
  @Output() public statusViewCalendar = new EventEmitter();
  @Output() installmentInfoEmitter = new EventEmitter<InputChange>();
  skipableInstallments: [any];

  constructor(public dialog: MatDialog, private genTagger: GenTagger, private translate: TranslateService) {
    this.resize = window.innerWidth;
    this.selectedLack = new FormGroup({
      lackOption: new FormControl('', [Validators.required]),
      firstLackDate: new FormControl('', [Validators.required])
    });
  }

  public get invalid(): boolean {
    if (this.selectedLack) {
      return this.selectedLack.invalid;
    }
    return false;
  }

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (
      (changes.firstInstallmentPMethodsDate && changes.firstInstallmentPMethodsDate.currentValue) ||
      (changes.installmentPMethodsDate && changes.installmentPMethodsDate.currentValue)
    ) {
      this.setFormValues();
      this.setSkipableInstallmentsValues();
    }
  }

  private setFormValues() {
    if (this.codeMonthsRange !== undefined && this.firstInstallmentPMethodsDate !== undefined ) {
      const lackOption = this.months.find(m => m.code === this.codeMonthsRange);
      const firstLackDate = {
        description: moment(this.firstInstallmentPMethodsDate).format('MM/YYYY'),
        code: moment(this.firstInstallmentPMethodsDate).format('DDMMYYYY')
      };
      this.selectedLack.patchValue({
        lackOption,
        firstLackDate
      });

      this.genTagger.setTag({
        event_category: this.category,
        event_action: Tag.Insert,
        event_label: 'Primeira parcela pulada'
      });

      this.genTagger.setTag({
        event_category: this.category,
        event_action: `${Tag.Selecionar} - período`,
        event_label: lackOption.description
      });
      this.installmentInfoEmitterLack.emit(this.selectedLack.value.lackOption)

    }
    this.getFormValues(this.selectedLack)
  }

  private setSkipableInstallmentsValues() {
    const filterLimit = moment().add(1, 'year');

      this.skipableInstallments = [{
        description: moment(this.firstInstallmentPMethodsDate).format('MM/YYYY'),
        code: moment(this.firstInstallmentPMethodsDate).format('DDMMYYYY')
      }]

  }


  maxDateValidation(selectedDate, cb) {
    if (
      !moment(selectedDate).isAfter(
        moment(this.installmentPMethodsDate[this.installmentPMethodsDate.length - 1].values)
      )
    ) {
      cb();
    } else {
      this.selectedLack.controls.firstLackDate.setValue(
        moment(this.installmentPMethodsDate[this.installmentPMethodsDate.length - 1].values).format('DD/MM/YYYY')
      );
      const payload: InputChange = {
        value: {
          payment: {
            firstInstallmentPMethodsDate: moment(this.selectedLack.controls.firstLackDate.value, 'DD/MM/YYYY').format(
              'YYYY-MM-DD'
            )
          }
        },
        input: InputTypes.INSTALLMENT_INFO_DATE_LACK
      };

      this.installmentInfoEmitter.emit(payload);
    }
  }

  setDate() {
    if (this.selectedLack.controls.firstLackDate.value.length === 8) {
      this.maxDateValidation(moment(this.selectedLack.controls.firstLackDate.value, 'DDMMYYYY'), () => {
        const payload: InputChange = {
          value: {
            payment: {
              firstInstallmentPMethodsDate: moment(this.selectedLack.controls.firstLackDate.value, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              )
            }
          },
          input: InputTypes.INSTALLMENT_INFO_DATE_LACK
        };

        this.installmentInfoEmitter.emit(payload);
      });
    }

    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.Insert,
      event_label: 'Primeira parcela pulada'
    });
  }

  public openDialogCalendar(): void {
    const refDialogCalendar = this.dialog.open(CalendarDialogComponent, {
      disableClose: true,
      position: { top: '100px', left: '35%' },
      data: {
        isTruncateDays: { toBack: true },
        isTruncateYears: true
      } as CalendarConfig
    });

    refDialogCalendar.afterClosed().subscribe((date: string) => {
      if (date) this.selectedLack.controls.firstLackDate.setValue(date);
      this.maxDateValidation(date, () => {
        const payload: InputChange = {
          value: {
            payment: {
              firstInstallmentPMethodsDate: moment(this.selectedLack.controls.firstLackDate.value, 'DD/MM/YYYY').format(
                'YYYY-MM-DD'
              )
            }
          },
          input: InputTypes.INSTALLMENT_INFO_DATE_LACK
        };

        this.installmentInfoEmitter.emit(payload);
      });
    });
  }

  monthChangeHandler($event) {
    const payload: InputChange = {
      value: {
        payment: {
          codeMonthsRange: this.selectedLack.controls.lackOption.value
        }
      },
      input: InputTypes.INSTALLMENT_INFO_MONTHS
    };

    this.installmentInfoEmitter.emit(payload);
    this.genTagger.setTag({
      event_category: this.category,
      event_action: `${Tag.Selecionar} - Período`,
      event_label: this.months.find(item => item.code === this.selectedLack.controls.lackOption.value).description
    });
  }

  @HostListener('window : resize', ['$event'])
  showCloseCalendar(): void {
    this.showCalendar = !this.showCalendar;
    this.statusViewCalendar.emit(this.showCalendar);
  }

  navAction(action: string): void {
    if (action === 'back') {
      this.showCloseCalendar();
    } else {
      this.selectedLack.controls.firstLackDate.setValue(action);
      this.showCloseCalendar();
    }
  }
  public getFormValues(form: any) {
    form.getRawValue();
    if (form.status ==='INVALID') {
      this.selectedValidatorsEmitter.emit(true);
    } else {
      this.selectedValidatorsEmitter.emit(false);
    }
  }

  compareObjects(o1: any, o2: any): boolean {
    if (typeof o1 === 'string') {
      return o1 === o2.code;
    }
    return o1.code === o2.code;
  }

  compareObjectsDate(o1: any, o2: any): boolean {
    return o1 === o2.code;
  }
}
