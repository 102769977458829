<div class="content">
  <button
    type="button"
    class="btn btn-back"
    (click)="backManagementList()"
    appTagger
    [event_category]="category()"
    event_label="voltar"
  >
    <img src="./assets/santander/images/ic_arrow_left.svg" alt="icon arrow left" />
  </button>

  <div>
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h3 *ngIf="screenType === 'new-user'" class="title">{{ 'New User' | translate }}</h3>
          <h3 *ngIf="screenType === 'update-user'" class="title">{{ 'Edit User' | translate }}</h3>
        </div>
      </div>

      <form [formGroup]="newUserForm">
        <div class="row">
          <div class="col-12 col-md-12">
            <p class="form-label">{{ 'User Data Label' | translate }}</p>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field appearance="legacy" class="input w-100">
              <mat-label>{{ 'Document Number' | translate }}</mat-label>
              <input
                autocomplete="off"
                mask="{{ $any(pioneerMasks).documentNumber.mask }}"
                type="text"
                matInput
                formControlName="documentNumber"
                maxlength="14"
                appTagger
                [event_category]="category()"
                event_label="{{ 'Document Number' | translate }}"
                [event_action]="Insert"
              />
              <img
                [hidden]="newUserForm.get('documentNumber').value === '' || !newUserForm.get('documentNumber').valid"
                class="valid-icon"
                src="./assets/icon-green.svg"
                alt="icon right green"
                matSuffix
              />
              <img
                [hidden]="newUserForm.get('documentNumber').untouched || newUserForm.get('documentNumber').valid"
                class="invalid-icon"
                src="./assets/atention-red.svg"
                alt="icon right red"
                matSuffix
              />
              <mat-error *ngIf="!newUserForm.get('documentNumber').valid">
                {{ (documentExists ? 'User already exists' : 'PERSONAL-DOCUMENT-NUMBER-ERROR-MSG') | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field appearance="legacy" class="input w-100">
              <mat-label>{{ 'Name' | translate }}</mat-label>
              <input
                autocomplete="off"
                type="text"
                matInput
                class="input-with-icon"
                formControlName="fullName"
                maxlength="60"
                (keypress)="omitSpecialChar($event)"
                appTagger
                [event_category]="category()"
                event_label="{{ 'Name' | translate }}"
                [event_action]="Insert"
              />
              <img
                [hidden]="newUserForm.get('fullName').value === '' || !newUserForm.get('fullName').valid"
                class="valid-icon"
                src="./assets/icon-green.svg"
                alt="icon right green"
                matSuffix
              />
              <img
                [hidden]="newUserForm.get('fullName').untouched || newUserForm.get('fullName').valid"
                class="invalid-icon"
                src="./assets/atention-red.svg"
                alt="icon right red"
                matSuffix
              />
              <mat-error *ngIf="!newUserForm.get('fullName').valid">
                {{ 'FULL-NAME-INPUT-ERROR-MSG' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field color="black" appearance="legacy" class="input w-100">
              <mat-label>{{ 'CELL-PHONE' | translate }}</mat-label>
              <input
                autocomplete="off"
                mask="{{ $any(pioneerMasks).cellPhone.mask }}"
                type="text"
                matInput
                class="input-with-icon"
                formControlName="cellPhone"
                appTagger
                [event_category]="category()"
                event_label="{{ 'CELL-PHONE' | translate }}"
                [event_action]="Insert"
              />
              <img
                [hidden]="newUserForm.get('cellPhone').value === '' || !newUserForm.get('cellPhone').valid"
                class="valid-icon"
                src="./assets/icon-green.svg"
                alt="icon right green"
                matSuffix
              />
              <img
                [hidden]="newUserForm.get('cellPhone').untouched || newUserForm.get('cellPhone').valid"
                class="invalid-icon"
                src="./assets/atention-red.svg"
                alt="icon right red"
                matSuffix
              />
              <mat-error *ngIf="!newUserForm.get('cellPhone').valid">
                {{ 'CELL-PHONE-INPUT-ERROR-MSG' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field appearance="legacy" class="input w-100">
              <mat-label>{{ 'Business Phone' | translate }}</mat-label>
              <input
                autocomplete="off"
                mask="{{ $any(pioneerMasks).businessPhone.mask }}"
                type="text"
                matInput
                class="input-with-icon"
                formControlName="businessPhone"
                appTagger
                [event_category]="category()"
                event_label="{{ 'Business Phone' | translate }}"
                [event_action]="Insert"
              />
              <img
                [hidden]="newUserForm.get('businessPhone').value === '' || !newUserForm.get('businessPhone').valid"
                class="valid-icon"
                src="./assets/icon-green.svg"
                alt="icon right green"
                matSuffix
              />
              <img
                [hidden]="newUserForm.get('businessPhone').untouched || newUserForm.get('businessPhone').valid"
                class="invalid-icon"
                src="./assets/atention-red.svg"
                alt="icon right red"
                matSuffix
              />
              <mat-error *ngIf="!newUserForm.get('businessPhone').valid">
                {{ 'BUSINESS-PHONE-INPUT-ERROR-MSG' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row">
          <div class="col-12 col-md-6">
            <mat-form-field appearance="legacy" class="input w-100">
              <mat-label>{{ 'EMAIL' | translate }}</mat-label>
              <input
                autocomplete="off"
                type="text"
                matInput
                class="input-with-icon"
                formControlName="email"
                appTagger
                [event_category]="category()"
                event_label="{{ 'EMAIL' | translate }}"
                [event_action]="Insert"
              />
              <img
                [hidden]="newUserForm.get('email').value === '' || !newUserForm.get('email').valid"
                class="valid-icon"
                src="./assets/icon-green.svg"
                alt="icon right green"
                matSuffix
              />
              <img
                [hidden]="newUserForm.get('email').untouched || newUserForm.get('email').valid"
                class="invalid-icon"
                src="./assets/atention-red.svg"
                alt="icon right red"
                matSuffix
              />
              <mat-error *ngIf="!newUserForm.get('email').valid">
                {{ 'EMAIL-INPUT-ERROR-MSG' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field appearance="legacy" class="w-100">
              <mat-label>{{ 'Status' | translate }}</mat-label>
              <mat-select formControlName="status" (selectionChange)="statusChangeHandler($event)">
                <mat-option
                  *ngFor="let status of statusOptions"
                  [value]="status.value"
                  (click)="tag(category(), 'status', status.viewValue)"
                >
                  {{ status.viewValue }}
                </mat-option>
              </mat-select>
              <img
                [hidden]="newUserForm.get('status').value === '' || !newUserForm.get('status').valid"
                class="valid-icon"
                src="./assets/icon-green.svg"
                alt="icon right green"
                matSuffix
              />
              <img
                [hidden]="newUserForm.get('status').untouched || newUserForm.get('status').valid"
                class="invalid-icon"
                src="./assets/atention-red.svg"
                alt="icon right red"
                matSuffix
              />
              <mat-error *ngIf="!newUserForm.get('status').valid">
                {{ 'STATUS-INPUT-ERROR-MSG' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 col-md-12">
            <p class="form-label">{{ 'Current profiles' | translate }}</p>

            <div class="card mb-4" *ngIf="includeProfile.length == 0">
              <p>{{ 'No profile data' | translate }}</p>
              <p>{{ 'Add profile data' | translate }}</p>
            </div>

            <button
              mat-raised-button
              color="basic"
              type="button"
              class="raised-button basic-button w-100"
              (click)="open(selectProfile, 'new')"
              appTagger
              [event_category]="category()"
              event_label="{{ 'Associate New Profile' | translate }}"
            >
              {{ 'Associate New Profile' | translate }}
            </button>

            <div *ngIf="includeProfile.length > 0">
              <div *ngFor="let itens of includeProfile" class="profiles-list mt-3">
                <div class="card mb-4">
                  <div class="row">
                    <div class="col-8 col-md-8">
                      <p *ngIf="itens.cdProfile === 'LOJISTA_VENDEDOR'">
                        <img src="./assets/santander/icons/vendedor.svg" alt="vendedor" /> Vendedor
                      </p>
                      <p *ngIf="itens.cdProfile === 'LOJISTA_REPRESENTANTE'">
                        <img src="./assets/santander/icons/revendedor.svg" alt="revendedor" /> Representante
                      </p>
                      <p *ngIf="itens.cdProfile === 'LOJISTA_FINANCEIRO'">
                        <img src="./assets/santander/icons/fiscal.svg" alt="financeiro" /> Financeiro
                      </p>
                      <p *ngIf="itens.cdProfile === 'LOJISTA_MASTER_SEC'">
                        MASTER
                      </p>
                    </div>
                    <div class="col-4 col-md-4">
                      <button
                        type="button"
                        mat-raised-button
                        color="basic"
                        (click)="open(selectProfile, 'edit', itens)"
                        class="raised-button basic-button w-90"
                        appTagger
                        [event_category]="category()"
                        event_label="editar - perfil {{ itens.cdProfile }}"
                      >
                        {{ 'Edit' | translate }}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-5" *ngIf="hasPerfilValue">
          <div class="col-12 col-md-12">
            <p class="form-label">{{ 'Product' | translate }}</p>
          </div>

          <div class="form-group-card">
            <div class="col-12 col-md-2 input-row" [hidden]="!shopProducts.cdc">
              <mat-checkbox
                formControlName="cdc"
                color="primary"
                [checked]="this.includeProducts.cdc"
                (click)="tag(category(), 'produtos', 'CDC')"
              >
                {{ 'CDC label' | translate }}
              </mat-checkbox>
            </div>

            <div class="col-12 col-md-10 input-row" [hidden]="!shopProducts.cessao">
              <mat-checkbox
                formControlName="cessao"
                color="primary"
                [checked]="this.includeProducts.csc"
                (click)="tag(category(), 'produtos', 'Cessão')"
              >
                {{ 'Assigment label' | translate }}
              </mat-checkbox>
            </div>
          </div>

          <div class="col-12 col-md-6" *ngIf="newUserForm.get('cdc').value == true">
            <mat-form-field appearance="legacy" class="w-100 no-bg">
              <mat-label>{{ 'Allow insurance sale' | translate }}</mat-label>
              <mat-select formControlName="allowInsuranceSell" (selectionChange)="statusChangeHandler($event)">
                <mat-option
                  *ngFor="let insurance of insuranceSalesOptions"
                  [value]="insurance.value"
                  (click)="tag(category(), 'Permite venda de seguro', insurance.viewValue)"
                >
                  {{ insurance.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field appearance="legacy" class="w-100 no-bg">
              <mat-label>{{ 'Subsidy' | translate }}</mat-label>
              <mat-select formControlName="allowSubsidiary" (selectionChange)="statusChangeHandler($event)">
                <mat-option
                  *ngFor="let subsidy of subsidyOptions"
                  [value]="subsidy.value"
                  (click)="tag(category(), 'subsídio', subsidy.viewValue)"
                >
                  {{ subsidy.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="col-12 col-md-6">
            <mat-form-field appearance="legacy" class="w-100 no-bg">
              <mat-label>{{ 'Bipartido' | translate }}</mat-label>
              <mat-select formControlName="allowBipartido" (selectionChange)="statusChangeHandler($event)">
                <mat-option
                  *ngFor="let bipartido of bipartidoOptions"
                  [value]="bipartido.value"
                  (click)="tag(category(), 'bipartido', bipartido.viewValue)"
                >
                  {{ bipartido.viewValue }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>

        <div class="row mt-5">
          <div class="col-12 col-md-12">
            <p class="form-label">{{ 'Associated profiles history' | translate }}</p>

            <div class="card mb-4" *ngIf="!totalElementsHistory">
              <p>{{ 'No history data' | translate }}</p>
            </div>
          </div>

          <div *ngIf="screenType === 'update-user' && totalElementsHistory > 0">
            <div class="associated-profiles-history">
              <div class="col-12 col-md-12">
                <div *ngIf="window.screen.width < 768">
                  <div class="row table-row" *ngFor="let profile of profilesHistory">
                    <div class="col-12">
                      <p class="mat-label">{{ 'Profile' | translate }}</p>
                      <p class="mat-value">{{ profile.descriptionPerfil }}</p>
                    </div>

                    <div class="col-6 mt-3">
                      <p class="mat-label">{{ 'Start Date' | translate }}</p>
                      <p class="mat-value">{{ profile.dtInicio | date: 'dd/MM/yyyy' }}</p>
                    </div>

                    <div class="col-6 mt-3">
                      <p class="mat-label">{{ 'End Date' | translate }}</p>
                      <p class="mat-value">{{ profile.dtFim | date: 'dd/MM/yyyy' }}</p>
                    </div>

                    <div class="col-12 mt-3">
                      <p class="mat-label">{{ 'Granted by' | translate }}</p>
                      <p class="mat-value">{{ profile.nameUser }}</p>
                    </div>
                  </div>
                </div>

                <div *ngIf="window.screen.width >= 768">
                  <div class="row table-header">
                    <div class="col-3">{{ 'Profile' | translate }}</div>
                    <div class="col-3">{{ 'Start Date' | translate }}</div>
                    <div class="col-3">{{ 'End Date' | translate }}</div>
                    <div class="col-3">{{ 'Granted by' | translate }}</div>
                  </div>
                  <div class="row table-row" *ngFor="let profile of profilesHistory">
                    <div class="col-3">{{ profile.descriptionPerfil }}</div>
                    <div class="col-3">{{ profile.dtInicio | date: 'dd/MM/yyyy' }}</div>
                    <div class="col-3">{{ profile.dtFim | date: 'dd/MM/yyyy' }}</div>
                    <div class="col-3">{{ profile.nameUser }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-3 mb-5">
          <div class="col-12 col-md-12">
            <button
              mat-raised-button
              color="primary"
              type="button"
              class="raised-button primary-button w-100"
              [disabled]="!newUserForm.valid || includeProfile.length == 0"
              (click)="submit()"
              appTagger
              [event_category]="category()"
              event_label="{{ 'Save' | translate }}"
            >
              {{ 'Save' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<ng-template #selectProfile let-c="close" let-d="dismiss" class="modal modal-sidenav">
  <div class="modal-header">
    <h3 *ngIf="type === 'new'" class="title" id="modal-basic-title">
      {{ 'Associate New Profile' | translate }}
    </h3>
    <h3 *ngIf="type === 'edit'" class="title" id="modal-basic-title">{{ 'Edit Profile' | translate }}</h3>
    <button
      type="button"
      class="btn btn-back"
      aria-label="Back"
      (click)="d('Cross click')"
      appTagger
      [event_category]="associateCategory"
      event_label="voltar"
    >
      <img src="./assets/santander/images/ic_arrow_left.svg" alt="icon arrow left" />
    </button>

    <button
      type="button"
      class="btn btn-close"
      aria-label="Close"
      (click)="d('Cross click')"
      appTagger
      [event_category]="associateCategory"
      event_label="X"
      event_action="Fechou modal - {{ associateLabel }}"
    >
      <img src="./assets/ic_close.svg" alt="icon close" />
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="newAssociateForm">
      <div class="row">
        <div class="col-12 col-md-6">
          <mat-form-field appearance="legacy" class="input w-100">
            <mat-label>{{ 'Profile' | translate }}</mat-label>
            <mat-select formControlName="cdProfile" *ngIf="type == 'new'">
              <mat-option
                *ngFor="let profile of profileOptions"
                [value]="profile.code"
                (click)="tag(associateCategory, 'perfil', profile.description)"
              >
                {{ profile.description }}
              </mat-option>
            </mat-select>
            <input
              matInput
              disabled
              autocomplete="off"
              type="text"
              class="input-with-icon"
              [value]="getPerfilsDescription(newAssociateForm.get('cdProfile').value)"
              *ngIf="type == 'edit'"
            />
            <img
              [hidden]="newAssociateForm.get('cdProfile').value === '' || !newAssociateForm.get('cdProfile').valid"
              class="valid-icon"
              src="./assets/icon-green.svg"
              alt="icon right green"
              matSuffix
            />
            <img
              [hidden]="newAssociateForm.get('cdProfile').untouched || newAssociateForm.get('cdProfile').valid"
              class="invalid-icon"
              src="./assets/atention-red.svg"
              alt="icon right red"
              matSuffix
            />
            <mat-error *ngIf="!newAssociateForm.get('cdProfile').valid">
              {{ 'PROFILE-ERROR-MSG' | translate }}
            </mat-error>
          </mat-form-field>
        </div>
        <div class="col-12 col-md-6">
          <mat-form-field appearance="legacy" class="input w-100">
            <mat-label>{{ 'Granted By' | translate }}*</mat-label>
            <input
              matInput
              disabled
              autocomplete="off"
              type="text"
              class="input-with-icon"
              [value]="(profile$ | async)?.name"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12 col-md-6">
          <div class="form-group">
            <mat-form-field appearance="legacy" class="w-100">
              <input
                formControlName="dtValidadeIni"
                matInput
                placeholder="Data Início"
                maxlength="10"
                [dropSpecialCharacters]="false"
                mask="00/00/0000"
              />
              <input
                hidden="true"
                formControlName="dtValidadeIniPicker"
                matInput
                [min]="actualDate"
                [matDatepicker]="picker"
              />
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-datepicker #picker></mat-datepicker>
              <img
                [hidden]="
                  newAssociateForm.get('dtValidadeIni').value === '' || !newAssociateForm.get('dtValidadeIni').valid
                "
                class="valid-icon"
                src="./assets/icon-green.svg"
                alt="icon right green"
                matSuffix
              />
              <img
                [hidden]="
                  newAssociateForm.get('dtValidadeIni').untouched || newAssociateForm.get('dtValidadeIni').valid
                "
                class="invalid-icon"
                src="./assets/atention-red.svg"
                alt="icon right red"
                matSuffix
              />
              <mat-error
                *ngIf="
                  !newAssociateForm.get('dtValidadeIni').valid ||
                  newAssociateForm?.get('dtValidadeIni')?.errors?.invalid
                "
              >
                {{ 'DATE-INPUT-ERROR-MSG' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="form-group">
            <mat-form-field appearance="legacy" class="w-100">
              <input
                formControlName="dtValidadeFim"
                matInput
                placeholder="Data fim"
                maxlength="10"
                [dropSpecialCharacters]="false"
                mask="00/00/0000"
                appTagger
                [event_category]="associateCategory"
                event_label="Data fim"
                [event_action]="Insert"
              />
              <input
                hidden="true"
                formControlName="dtValidadeFimPicker"
                matInput
                [min]="transformToMoment(newAssociateForm.get('dtValidadeIni').value)"
                [matDatepicker]="dtValidadeFim"
                appTagger
                [event_category]="associateCategory"
                event_label="Data fim"
                [event_action]="Insert"
              />
              <mat-datepicker-toggle matSuffix [for]="dtValidadeFim"></mat-datepicker-toggle>
              <mat-datepicker #dtValidadeFim></mat-datepicker>
              <img
                [hidden]="!newAssociateForm.get('dtValidadeFim').value || !newAssociateForm.get('dtValidadeFim').valid"
                class="valid-icon"
                src="./assets/icon-green.svg"
                alt="icon right green"
                matSuffix
              />
              <img
                [hidden]="
                  newAssociateForm.get('dtValidadeFim').untouched || newAssociateForm.get('dtValidadeFim').valid
                "
                class="invalid-icon"
                src="./assets/atention-red.svg"
                alt="icon right red"
                matSuffix
              />
              <mat-error *ngIf="!newAssociateForm.get('dtValidadeFim').valid">
                {{ 'DATE-INPUT-ERROR-MSG' | translate }}
              </mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="row mt-3">
        <button
          mat-raised-button
          *ngIf="type === 'new'"
          color="primary"
          type="button"
          class="raised-button primary-button w-100"
          [disabled]="!newAssociateForm.valid"
          (click)="subimitNewPerfil(); d('Cross click')"
          appTagger
          [event_category]="category()"
          event_label="{{ 'Associate New Profile' | translate }}"
        >
          {{ 'Associate New Profile' | translate }}
        </button>
        <button
          mat-raised-button
          *ngIf="type === 'edit'"
          color="primary"
          type="button"
          class="raised-button primary-button w-100"
          [disabled]="!newAssociateForm.valid"
          (click)="subimitEditPerfil(); d('Cross click')"
        >
          {{ 'Edit Profile' | translate }}
        </button>
      </div>
    </form>
  </div>
  <div class="modal-footer right-itens"></div>
</ng-template>
