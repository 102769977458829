import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-expiration-modal',
  templateUrl: './expiration-modal.component.html',
  styleUrls: ['./expiration-modal.component.scss']
})
export class ExpirationModalComponent implements OnInit {
  @Output() goToReset = new EventEmitter();
  @Output() skip = new EventEmitter();
  resolvedTranslation: string;

  readonly category = '/portallojista/login';
  readonly Fechou_Modal = Tag.Fechou_Modal;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private genTagger: GenTagger,
    private translate: TranslateService
  ) {}

  get actionOpenModal(): ExtendedTag {
    if (this.data.daysToExpire > 1) {
      return {
        event_action_type: Tag.AbriuModal,
        event_action_complement: this.resolvedTranslation
      };
    }
    return {
      event_action_type: Tag.AbriuModal,
      event_action_complement: this.translate.instant('EXPIRATION-MODAL-TITLE-TODAY')
    };
  }

  ngOnInit() {
    this.translate
      .get('EXPIRATION-MODAL-TITLE', { days: this.data.daysToExpire })
      .pipe(first())
      .subscribe((text: string) => (this.resolvedTranslation = text));
    this.genTagger.setTag({
      event_category: this.category,
      event_action: this.actionOpenModal,
      event_label: this.translate.instant('LOGIN-POPUP-DESCRIPTION')
    });
  }

  resetPasswordClick() {
    this.goToReset.emit();
  }

  skipClick() {
    this.skip.emit();
  }
}
