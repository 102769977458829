import gql from 'graphql-tag';

export const GET_PROPOSAL_INFORMATION = gql`
  query getProposalRegisterScreenData($proposalId: ID!) {
    getProposalRegisterScreenData(proposalId: $proposalId) {
      id
      productCode
      travelData
      liquidationTypeCode
      creationDate
      expirationDate
      isTravelSubSegment
      storeCode
      clients {
        clientId
        proposalId
        mainFlag
        type
        companyRelationshipTypeId
        person {
          id
          type
          name
          documentId
          documentNumber
          emails {
            id
            clientId
            mainFlag
            type
            email
          }
          phones {
            id
            clientId
            mainFlag
            type
            ddiNumber
            dddNumber
            phoneNumber
          }
          addresses {
            id
            clientId
            mainFlag
            zipCode
            streetTypeId
            street
            number
            complement
            neighborhood
            cityId
            cityDescription
            state
          }
          legal {
            clientId
            openingDate
            companyType
            legalNatureId
            branchGroupCode
            branchId
          }
          physical {
            clientId
            birthDate
            nationalityId
            genderId
            professionId
            motherName
          }
          bankAccount {
            id
            clientId
            bankCode
            agencyCode
            accountTypeId
            accountCode
          }
        }
      }
      travelers {
        id
        proposalId
        mainFlag
        name
        birthDate
        documentCode
        documentNumber
      }
      additionalInformation {
        id
        proposalId
        orderNumber
        commentId
        comment
      }
      flags {
        code
        value
      }
    }
  }
`;
export const GET_PROPOSAL_INFORMATION_FROM_SERVICES = gql`
  query getClientsRegisterScreenData($proposalId: ID!) {
    getClientsRegisterScreenData(proposalId: $proposalId) {
      clients {
        clientId
        proposalId
        mainFlag
        type
        companyRelationshipTypeId
        person {
          id
          type
          name
          documentId
          documentNumber
          emails {
            id
            clientId
            mainFlag
            type
            email
          }
          phones {
            id
            clientId
            mainFlag
            type
            ddiNumber
            dddNumber
            phoneNumber
          }
          addresses {
            id
            clientId
            mainFlag
            zipCode
            streetTypeId
            street
            number
            complement
            neighborhood
            cityId
            cityDescription
            state
          }
          legal {
            clientId
            openingDate
            companyType
            legalNatureId
            branchGroupCode
            branchId
          }
          physical {
            clientId
            birthDate
            nationalityId
            genderId
            professionId
            motherName
          }
          bankAccount {
            id
            clientId
            bankCode
            agencyCode
            accountTypeId
            accountCode
          }
        }
      }
    }
  }
`;

export const GET_PROPOSAL_CLIENTS = gql`
  query getProposalClients($proposalId: ID!) {
    getProposalRegisterScreenData(proposalId: $proposalId) {
      id
      productCode
      clients {
        clientId
        proposalId
        mainFlag
        type
        companyRelationshipTypeId
        person {
          id
          type
          name
          documentId
          documentNumber
        }
      }
    }
  }
`;

export const GET_PARAMETERS_PROPOSAL = gql`
  query getProposalParams {
    getProposalParams {
      documentTypes {
        id
        code
        description
        domainCode
      }
      genders {
        id
        code
        description
        domainCode
      }
      nationalities {
        id
        code
        description
        domainCode
      }
      states {
        id
        code
        description
        domainCode
      }
      banks {
        id
        code
        description
        domainCode
      }
      accountTypes {
        id
        code
        description
        domainCode
      }
      addressTypes {
        id
        code
        description
        domainCode
      }
      professions {
        id
        code
        description
        domainCode
      }
      companyTypes {
        id
        code
        description
        domainCode
      }
      legalNatures {
        id
        code
        description
        domainCode
      }
      economicActivityGroups {
        id
        code
        description
        domainCode
      }
      economicActivities {
        id
        code
        description
        domainCode
      }
    }
  }
`;

export const GET_CANCEL_REASONS = gql`
  query getCancelReasons {
    getProposalParams {
      cancelReasons {
        id
        code
        description
        domainCode
      }
    }
  }
`;

export const GET_CITIES_BY_STATE = gql`
  query getCities($state: String!) {
    getCities(state: $state) {
      id
      code
      description
      domainCode
    }
  }
`;

export const GET_ACTIVITIES_BY_GROUP = gql`
  query getCompanyActivityBranchesByGroupCode($activityGroupCode: ID!) {
    getCompanyActivityBranchesByGroupCode(activityGroupCode: $activityGroupCode) {
      id
      code
      description
      domainCode
    }
  }
`;

export const GET_ADDRESS_BY_ZIP_CODE = gql`
  query getAddressByZipCode($zipCode: String!) {
    getAddressByZipCode(zipCode: $zipCode) {
      zipCode
      addressType
      address
      neighborhood
      state
      city
      fgInexis
      cityId
    }
  }
`;

export const GET_LEGAL_REPRESENTATIVE_RELATIONSHIP = gql`
  query getLegalRepresentativeRelationship($legalNatureCode: ID!) {
    getLegalRepresentativeRelationship(legalNatureCode: $legalNatureCode) {
      id
      code
      description
      domainCode
    }
  }
`;

export const GET_PROPOSAL_CONFIRMATION = gql`
  query getProposalConfirmationDataByCode($proposalCode: String!) {
    getProposalConfirmationDataByCode(proposalCode: $proposalCode) {
      proposalData {
        id
        creationDate
        expirationDate
        product
        client
        isTravelSubSegment
        recoveredDataByCIPorPE
        acceptedRecoveredData
        storeCode
      }
      showInsurance
      financedValue
      hasInsurance
      entryValue
      purchaseValue
      releasedAmount
      financedTotalValue
      installmentTotalQuantity
      installmentValue
      planType
      planTypeSchemaData {
        planTypeCode
        planTypeDescription
        hasFlexInstallments
        frequenceId
        monthOccurrency
        installmentDefaultValue
        installmentPaymentMethodsValue
        firstInstallmentValue
        lastInstallmentValue
        installmentDefaultAmount
        installmentPaymentMethodsAmount
        frequencedescription
      }
      firstInstallmentDate
      liquidationType
      liquidationTypeCode
      accountantIndicator
      cupom
      interestRate
      iof
      registrationFeeType
      registerTariffExemptionFlag
      registerTariffOneTimePaymentFlag
      registerTariff
      interestRateByYear
      insuranceValue
      returnRateByYear
      insurance
      returnRate
      annualReturnRate
      conditionPlanFlex {
        id
        simulationId
        sequenceNumber
        paymentDate
        paymentValue
        updatedUserCode
      }
      personalData {
        fullName
        documentCode
        documentNumber
        birthDate
        emailCode
        phone {
          ddiNumber
          dddNumber
          number
        }
      }
      calculationLimit {
        id
        proposalId
        minEntryValue
        recommendedEntryValue
        minPmtValue
        maxPmtValue
        termErrorFlag
        minTermAmount
        maxTermAmount
        minApprovedValue
        maxApprovedValue
        bannerShowFlag
        maxBannerValue
      }
      accountData {
        bankCode
        branchCode
        accountType
        accountNumber
      }
      contract {
        UUID
      }
      channelSupplier {
        id
        code
        name
        bipartite {
          type
          typeDescription
          invoice
          activeBipartyRelationship
        }
      }
      certifiedAgent {
        id
        proposalId
        agentName
        documentNumber
        agentCode
        certificationNumber
        expirationDate
        relationshipDate
        deadlineCertificationDate
        validDaysCertification
        daysEndLink
      }
      shopkeeperData {
        minEntryValue
        isEntryValueAdjusted
        isPossibleToIncreaseFinancedValue
        releasedAmount
        showReleasedAmount
        withheldAmount
        retentionPercentageByReleasedAmount
        releasedAmountPlusEntry
        retentionPercentageByPurchaseValue
      }
    }
  }
`;

export const GET_PROPOSAL_COMMENT_LIST = gql`
  query getProposalCommentList($proposalCode: String, $returnOnlyLastComment: Boolean) {
    proposalCommentsList(proposalId: $proposalCode, returnOnlyLastComment: $returnOnlyLastComment) {
      edges {
        node {
          observationDescription
          dateObservation
        }
      }
    }
  }
`;

export const GET_PROPOSAL_BANK_ACCOUNT_DATA = gql`
  query getProposalBankAccountData($proposalId: String!) {
    getProposalRegisterScreenData(proposalId: $proposalId) {
      clients {
        person {
          bankAccount {
            id
            clientId
            bankCode
            agencyCode
            accountTypeId
            accountCode
          }
        }
      }
    }
  }
`;

export const GET_PROPOSAL_INSTALLMENTS = gql`
  query getProposalInstallments($proposalCode: String) {
    getProposalById(proposalCode: $proposalCode) {
      paymentPlan {
        id
        installmentValue
        amortizationQuotaValue
        interestRateValue
        debitBalanceAmountValue
        amortizationDate
        installmentNumber
        installmentBalanceDueAfter
      }
    }
  }
`;
