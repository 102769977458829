<div class="container">
  <div class="row">
    <div class="col-12">
      <div class="modal-header">
        <div>
          <h3 class="modal-title">{{ 'DOWNLOAD-SELECTED-PERIOD' | translate }}</h3>
          <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()" appTagger
            [event_category]="category" event_action="fechou modal – baixar período selecionado" event_label="x">
            <img aria-hidden="true" src="./assets/ic_close.svg" alt="icon close" />
          </button>
        </div>
      </div>

      <div class="modal-body ">
        <table class="table">
          <thead>
            <th scope="col" class="text-nowrap first-column">{{ 'Date' | translate }}</th>
            <th scope="col" class="text-nowrap"
              *ngIf="isShowingRecebidos === 'Realizados' && tabSelected === 'Propostas Efetivadas'">
              {{ 'TOTAL-AMOUNT-FINANCED' | translate }}
            </th>
            <th scope="col" class="text-nowrap"
              *ngIf="isShowingRecebidos === 'Realizados' && tabSelected === 'Propostas Efetivadas'">
              {{ 'TOTAL-AMOUNT-RELEASED' | translate }}
            </th>
            <th scope="col" class="text-nowrap"
              *ngIf="selectedProduct !== 'CSCP' && isShowingRecebidos === 'Recebidos'">
              {{ 'DOCUMENT-NUMBER-CNPJ' | translate }}
            </th>
            <th scope="col" class="text-nowrap" *ngIf="tabSelected === 'Saldo Futuro'">{{ 'TOTAL-AMOUNT-SCHEDULED' |
              translate }}</th>
            <th scope="col" class="text-nowrap"
              *ngIf="tabSelected === 'Pagamentos Realizados' || (tabSelected === 'Propostas Efetivadas' && selectedProduct === 'CSCP')">
              {{ 'AMOUNT-RECEIVED' |
              translate }}</th>
            <th scope="col" class="text-nowrap"
              *ngIf="selectedProduct !== 'CSCP' && isShowingRecebidos === 'Recebidos'">{{ 'AMOUNT-RECEIVED' | translate
              }}
            </th>
            <th scope="col" class="text-nowrap">{{ 'NUMBER-OF-PROPOSALS' | translate }}</th>
          </thead>

          <tbody>
            <tr *ngFor="let item of financialStatementPeriod"
              [ngClass]="{ ' unsuccessful-payments': !!paymentSuccess && !item.successPayment }">
              <td class="text-nowrap first-column">
                <span class="swap-text-item">{{ item.paymentDate | date: 'dd/MMMM' }}</span>
              </td>

              <td class="text-nowrap"
                *ngIf="isShowingRecebidos === 'Realizados' && tabSelected === 'Propostas Efetivadas'">
                <span class="swap-text-item">{{ item.paymentFinanced | currency: 'BRL' }}</span>
              </td>

              <td class="text-nowrap"
                *ngIf="isShowingRecebidos === 'Realizados' && tabSelected === 'Propostas Efetivadas'">
                <span class="swap-text-item">{{ item.paymentValue | currency: 'BRL' }}</span>
              </td>

              <td class="text-nowrap" *ngIf="selectedProduct !== 'CSCP' &&  isShowingRecebidos === 'Recebidos'">
                <span class="swap-text-item" *ngIf="item.document?.length === 11">
                  {{ item.document | mask: '000.000.000-00' }}
                </span>
                <span class="swap-text-item" *ngIf="item.document?.length >= 12">
                  {{ item.document | mask: '00.000.000/0000-00' }}
                </span>
              </td>

              <!-- <td class="text-nowrap" *ngIf="selectedProduct !== 'CSCP'">
                <span class="swap-text-item">{{ item.receivedValue | currency: 'BRL' }}</span>
              </td> -->
              <td *ngIf="item.totalScheduledValue || item.totalScheduledValue === 0" class="text-nowrap">
                <span class="swap-text-item">{{ item.totalScheduledValue | currency: 'BRL' }}</span>
              </td>
              <td *ngIf="item.receivedValue || item.receivedValue === 0" class="text-nowrap">
                <span class="swap-text-item">{{ item.receivedValue | currency: 'BRL' }}</span>
              </td>

              <td class="text-nowrap ">
                <span class="swap-text-item text-center">{{ item.proposalQuantity }}</span>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="responsive-content">
          <ul>
            <li *ngFor="let item of financialStatementPeriod"
              [ngClass]="{ 'unsuccessful-payments': !!paymentSuccess && !item.successPayment }">
              <div class="responsive-content-row">
                <div class="responsive-content-col sm-mat-checkbox">
                  <mat-checkbox color="primary" (change)="selectOption($event)" [value]="item.id"> </mat-checkbox>
                </div>
                <div class="responsive-content-col mr-5">
                  <span class="title">{{ 'Date' | translate }}</span>
                  <span>{{ item.paymentDate | date: 'dd/MM' }}</span>
                </div>

                <div class="responsive-content-col" *ngIf="item.receivedValue || item.receivedValue === 0">
                  <span class="title">{{ 'AMOUNT-RECEIVED' | translate }}</span>
                  <span class="swap-text-item">{{ item.receivedValue | currency: 'BRL':true }}</span>
                </div>
                <div class="responsive-content-col"
                  *ngIf="isShowingRecebidos === 'Realizados' && tabSelected === 'Propostas Efetivadas'">
                  <span class="title">{{ 'TOTAL-AMOUNT-FINANCED' | translate }}</span>
                  <span class="swap-text-item">{{ item.paymentFinanced | currency: 'BRL':true }}</span>
                </div>

                <div class="responsive-content-col sm-mat-checkbox">
                  <button mat-icon-button [matMenuTriggerFor]="menuSM">
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menuSM="matMenu">
                    <button mat-menu-item>
                      <span> {{ 'DOWNLOAD-SELECTED-PERIOD' | translate }}</span>
                    </button>
                  </mat-menu>
                </div>
              </div>

              <div class="responsive-content-row">
                <div class="responsive-content-col ml-3"
                  *ngIf="isShowingRecebidos ===' Recebidos' && tabSelected === 'Propostas Efetivadas'">
                  <span class="title">{{ 'DOCUMENT-NUMBER-CNPJ' | translate }}</span>
                  <span class="swap-text-item" *ngIf="item.document?.length === 11">
                    {{ item.document | mask: '000.000.000-00' }}
                  </span>
                  <span class="swap-text-item" *ngIf="item.document?.length >= 12">
                    {{ item.document | mask: '00.000.000/0000-00' }}
                  </span>
                </div>
                <div class="responsive-content-col ml-3"
                  *ngIf="isShowingRecebidos === 'Realizados' && tabSelected === 'Propostas Efetivadas'">
                  <span class="title">{{ 'TOTAL-AMOUNT-RELEASED' | translate }}</span>
                  <span class="swap-text-item">{{ item.paymentValue | currency: 'BRL':true }}</span>
                </div>
              </div>

              <div class="responsive-content-row ">
                <div class="responsive-content-col ml-3">
                  <span class="title">{{ 'NUMBER-OF-PROPOSALS' | translate }}</span>
                  <span class="swap-text-item">{{ item.proposalQuantity }}</span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div *ngIf="!!financialStatementPeriodSelected" class="modal-footer">
        <p class="question">{{ 'CONFIRM-STATEMENT-REQUEST' | translate }}</p>
        <button type="button" class="btn btn-close" (click)="activeModal.dismiss()" appTagger
          [event_category]="category" event_label="{{ 'NO_BACK' | translate }}">
          {{ 'NO_BACK' | translate }}
        </button>
        <button type="button" class="btn btn-action" (click)="downloadExtract()" appTagger [event_category]="category"
          event_label="{{ 'DOWNLOAD_STATEMENT' | translate }}">
          {{ 'DOWNLOAD_STATEMENT' | translate }}
        </button>
      </div>

      <div *ngIf="!financialStatementPeriodSelected" class="modal-footer">
        <button type="button" class="btn btn-action" (click)="activeModal.dismiss()">Fechar</button>
      </div>
    </div>
  </div>
</div>
