import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { OffersService } from '@app/showcase/services/offers.service';
import { StoresFiltersService } from '@app/showcase/stores/services/stores-filters.service';
import { StoresService } from '@app/showcase/stores/services/stores.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import { SubsegmentService } from '../../../showcase/subsegment/services/subsegment.service';
import * as actions from './showcase.actions';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state';
import { selectPersonalizedOffers } from '@app/core/state/showcase/showcase.selectors';
import { MatDialog } from '@angular/material/dialog';
import { ErrorLoadingContentModalComponent } from '@shared/components/error-loading-content-modal/error-loading-content-modal.component';
import { TutorialService } from '@shared/services/tutorial/tutorial.service';

@Injectable()
export class ShowcaseEffects {
  constructor(
    private actions$: Actions,
    private offersService: OffersService,
    private router: Router,
    private storesService: StoresService,
    private storesFiltersService: StoresFiltersService,
    private subsegmentService: SubsegmentService,
    private store$: Store<AppState>,
    private dialog: MatDialog,
    private tutorialService: TutorialService
  ) {}

  @Effect({ dispatch: false })
  getPersonalizedOffersAction = this.actions$.pipe(
    ofType<actions.GetPersonalizedOffersAction>(actions.ShowcaseActionTypes.GET_PERSONALIZED_OFFERS),
    switchMap(action =>
      of(action).pipe(
        withLatestFrom(this.store$.select(selectPersonalizedOffers)),
        map(([act, state]) => {
          if (!state.isLoadSuccess) {
            this.offersService.getPersonalizedOffers().subscribe(
              res => {
                this.store$.dispatch(new actions.GetPersonalizedOffersSuccessAction(res));
              },
              err => {
                this.store$.dispatch(new actions.GetPersonalizedOffersErrorAction(err));

                if (err.status !== 404) {
                  // modal com retentativa
                  if (!state.loadAttempts) {
                    this.dialog
                      .open(ErrorLoadingContentModalComponent, {
                        autoFocus: false,
                        maxWidth: '500px',
                        role: 'alertdialog',
                        id: 'error-loading-content-modal'
                      })
                      .afterClosed()
                      .subscribe(tryAgain => {
                        if (tryAgain) {
                          this.store$.dispatch(new actions.IncrementPersonalizedOffersErrorAttemptAction());
                          this.store$.dispatch(new actions.GetPersonalizedOffersAction());
                        }
                      });
                  }
                }
              }
            );
          } else {
            this.store$.dispatch(new actions.DisableLoadingPersonalizedOffersAction());
          }
        })
      )
    )
  );

  @Effect()
  getSpecialOffersAction = this.actions$.pipe(
    ofType<actions.GetSpecialOffersAction>(actions.ShowcaseActionTypes.GET_SPECIAL_OFFERS),
    switchMap(() =>
      this.offersService.getSpecialOffers().pipe(
        switchMap(res => of(new actions.GetSpecialOffersSuccessAction(res))),
        catchError(err => of(new actions.GetSpecialOffersErrorAction(err)))
      )
    )
  );

  @Effect()
  getSubsegmentsAction = this.actions$.pipe(
    ofType<actions.GetSubsegmentsAction>(actions.ShowcaseActionTypes.GET_SUBSEGMENTS),
    switchMap(() =>
      this.subsegmentService.getSubsegments().pipe(
        switchMap(res => of(new actions.GetSubsegmentsSuccessAction(res))),
        catchError(err => of(new actions.GetSubsegmentsErrorAction(err)))
      )
    )
  );

  @Effect({ dispatch: false })
  saveSubsegmentAction = this.actions$.pipe(
    ofType<actions.SaveSubsegmentAction>(actions.ShowcaseActionTypes.SAVE_SUBSEGMENT),
    map(action => this.router.navigate(['/showcase/store-selection']))
  );

  @Effect()
  getStatesAction = this.actions$.pipe(
    ofType<actions.GetStatesAction>(actions.ShowcaseActionTypes.GET_STATES),
    switchMap(() =>
      this.storesFiltersService.getStates().pipe(
        switchMap(res => of(new actions.GetStatesSuccessAction(res))),
        catchError(err => of(new actions.GetStatesErrorAction(err)))
      )
    )
  );

  @Effect()
  getCitiesAction = this.actions$.pipe(
    ofType<actions.GetCitiesAction>(actions.ShowcaseActionTypes.GET_CITIES),
    switchMap(action =>
      this.storesFiltersService.getCitiesByState(action.payload).pipe(
        switchMap(res => of(new actions.GetCitiesSuccessAction(res))),
        catchError(err => of(new actions.GetCitiesErrorAction(err)))
      )
    )
  );

  @Effect()
  getStoresAction = this.actions$.pipe(
    ofType<actions.GetStoresAction>(actions.ShowcaseActionTypes.GET_STORES),
    switchMap(action =>
      this.storesService.getStores().pipe(
        switchMap(res => of(new actions.GetStoresSuccessAction(res))),
        catchError(err => of(new actions.GetStoresErrorAction(err)))
      )
    )
  );

  @Effect()
  selectStoreAction = this.actions$.pipe(
    ofType<actions.SelectStoreAction>(actions.ShowcaseActionTypes.SELECT_STORE),
    map(action => new actions.SubmitIdentificationAction({ ...action.payload, redirectProposal: true }))
  );

  @Effect()
  getEconomicActivitiesAction = this.actions$.pipe(
    ofType<actions.GetEconomicActivitiesAction>(actions.ShowcaseActionTypes.GET_ECONOMIC_ACTIVITIES),
    switchMap(action =>
      this.offersService.getEconomicActivities().pipe(
        switchMap(res => of(new actions.GetEconomicActivitiesSuccessAction(res))),
        catchError(err => of(new actions.GetEconomicActivitiesErrorAction(err)))
      )
    )
  );

  @Effect()
  getMaxFinancialValueAction = this.actions$.pipe(
    ofType<actions.GetMaxFinancialValueAction>(actions.ShowcaseActionTypes.GET_MAX_FINANCIAL_VALUE),
    switchMap(action =>
      this.offersService.getMaxFinancialValue().pipe(
        switchMap(res => of(new actions.GetMaxFinancialValueSuccessAction(res))),
        catchError(err => of(new actions.GetMaxFinancialValueErrorAction(err)))
      )
    )
  );

  // @Effect({ dispatch: false })
  // UserPreference = this.actions$.pipe(
  //   ofType<actions.ShowTourTutorialShowcaseAction>(actions.ShowcaseActionTypes.SHOW_TOUR_TUTORIAL_SHOWCASE),
  //   switchMap(action => {
  //     this.tutorialService.showTourTutorial('showcase');

  //     setTimeout(() => {
  //       const dialog = this.dialog.getDialogById('tourTutorialModal');
  //       if (dialog) {
  //         dialog.afterClosed().subscribe(result => {
  //           if (!result || !result.startTutorial) {
  //             this.store$.dispatch(new LoadShowcaseAction());
  //           }
  //         });
  //       } else {
  //         this.store$.dispatch(new LoadShowcaseAction());
  //       }
  //     }, 200);

  //     return [];
  //   })
  // );
}
