import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Contract } from '@app/contracts/model/contract.model';
import { AppState } from '@app/core/state';
import * as fromContracts from '@app/core/state/contracts';
import { SubmitIdentificationAction } from '@app/core/state/pre-analysis/pre-analysis.actions';
import { userProfileSelector } from '@app/core/state/profile/profile.selectors';
import * as fromProposal from '@app/core/state/proposal';
import * as fromShowcase from '@app/core/state/showcase';
import { selectIsLoadShowcase } from '@app/core/state/showcase/showcase.selectors';
import { PendencyComponent } from '@app/pendency/pendency.component';
import { ProfileRenderService } from '@app/profile/service/profile-render.service';
import { GetPersonalizedOffersResDTO } from '@app/showcase/models/api/post.personalized-offers.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { SpecialOfferTypeEnum } from '@shared/constants/offers.enum';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { SubsegmentModel } from '@shared/models/subsegment.model';
import * as moment from 'moment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Subject } from 'rxjs';
import { takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { ActiveContracts } from './active-contracts/models/active-contracts.model';
import { Identification } from './models/api/post.identification.model';
import { SpecialOffer } from './models/showcase.model';
import { ProposalDetailComponent } from './proposals/components/proposal-detail/proposal-detail.component';
import { StatusProposals } from './proposals/models/proposal.enum';

@Component({
  selector: 'app-showcase',
  templateUrl: './showcase.component.html',
  styleUrls: ['./showcase.component.scss']
})
export class ShowcaseComponent implements OnInit, OnDestroy {
  public openProposals: { data: any[]; isLoading: boolean };
  public pendingProposals: { data: any[]; isLoading: boolean };
  public flagPendingProposals = false;
  public personalizedOffers: { data: GetPersonalizedOffersResDTO[]; isLoading: boolean };
  public specialOffers: { data: SpecialOffer[]; isLoading: boolean };
  public maxFinancialValue: any;
  public contracts: { data: Contract[]; isLoading: boolean };

  public activeContracts: any;
  public proposalsOptions: OwlOptions;
  public offersOptions: OwlOptions;
  public activeContractsOptions: OwlOptions;
  public skeletonConfig: any;

  public showTutorial: any;
  public sidebarType: 'RESUME' | 'DETAILS';
  /**
   * Data to adjust modal size
   */
  public innerWidth: any;
  public MOBILE_BREAKPOINT = 768;
  public window = window.screen.width;
  protected ngUnsubscribe: Subject<any> = new Subject();
  private subsegments: SubsegmentModel[];

  public flagPendingProposta = true;
  public flagOneContract = false;
  proposalsSentToTagging: boolean;

  constructor(
    private router: Router,
    private store$: Store<AppState>,
    private profileRenderService: ProfileRenderService,
    public genTagger: GenTagger,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.configureSkeleton();
    this.configureCarousels();
    this.store$
      .select(selectIsLoadShowcase)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(isLoad => {
        if (isLoad) {
          this.getOpenProposals();
          this.getActiveContracts();
          this.getSubsegments();
          this.getPersonalizedOffers();
          this.getSpecialOffers();
          this.getMaxFinancialValue();
        }
      });

    // FOR PENDENCY TESTS
    // setTimeout(() => {
    //   this.store$.dispatch(
    //     new fromProposal.actions.ShowcaseChoosedCard({
    //       id: '4467', // 4467| 4262
    //       stage: {
    //         portalStage: PendencyTypeEnum.BPO_PENDENCY
    //       }
    //     })
    //   );
    //   this.handleSolvePending('4467');
    // }, 0);
    // END FOR PENDENCY TESTS
  }

  private getMaxFinancialValue(): void {
    this.store$
      .select(fromShowcase.selectors.maxFinancialValue)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(maxFinancialValue => {
        this.maxFinancialValue = maxFinancialValue;
      });

    this.store$.dispatch(new fromShowcase.actions.GetMaxFinancialValueAction());
  }

  public handlePersonalizedOfferEmit(offer: GetPersonalizedOffersResDTO): void {
    const payload: Identification = {
      financedValue: offer.financedAmount ? +offer.financedAmount.replace(',', '.') : 0,
      installmentAmount: offer.term ? Number(offer.term) : 0,
      product: { code: ProductCodeEnum.CP }
    };
    this.store$.dispatch(new fromShowcase.actions.SubmitIdentificationAction({ ...payload, redirectProposal: true }));
  }

  public handleInstallmentPurchasesEmit(): void {
    this.router.navigate(['/showcase/subsegment']);
  }

  public handleActiveContractsEmit(contract: ActiveContracts): void {
    const url = 'https://www.cliente.santanderfinanciamentos.com.br/portalcliente/#/detalhe-contrato/20039999999';
    window.open(url, '_blank');
  }

  public handleSpecialOfferEmit(offer: SpecialOffer): void {
    if (offer.isEmptyCard) {
      this.router.navigate(['/showcase/special-offers']);
      return;
    }

    if (offer.specialOffersTypeDescription === SpecialOfferTypeEnum.PRODUCT) {
      this.router.navigate(['/showcase/subsegment']);
      return;
    }

    if (offer.specialOffersTypeDescription === SpecialOfferTypeEnum.SUBSEGMENT) {
      this.store$.dispatch(
        new fromShowcase.actions.SaveSubsegmentAction(
          this.subsegments.find(subsegment => subsegment.id === offer.subsegmentId)
        )
      );
      this.router.navigate(['/showcase/store-selection']);
      return;
    }

    if (
      offer.specialOffersTypeDescription === SpecialOfferTypeEnum.STORE
      // offer.subsegmentId === SubsegmentsEnum.TOURISM
    ) {
      this.profileRenderService.loadComponent('special-travel-date');
      this.profileRenderService.onDateSelection().subscribe(travelDate => {
        const payload: Identification = {
          financedValue: null,
          installmentAmount: null,
          product: { code: offer.productCode ? offer.productCode : null },
          travelDate: moment(travelDate, 'DDMMYYYY').toISOString()
        };
        this.store$.dispatch(new SubmitIdentificationAction({ ...payload, redirectProposal: true }));
      });
    } else {
      const payload: Identification = {
        financedValue: null,
        installmentAmount: null,
        product: { code: offer.productCode ? offer.productCode : null }
      };
      this.store$.dispatch(new SubmitIdentificationAction({ ...payload, redirectProposal: true }));
    }
  }

  public handleContinueProposal(proposalId: string): void {
    this.router.navigate(['/proposal'], { queryParams: { id: proposalId } });
  }

  public handleDetailsProposal(proposalId) {
    this.setProposalId(proposalId);
    this.profileRenderService.loadComponent('proposal-detail');
  }

  public handleResumeProposal(proposalId: string): void {
    this.setProposalId(proposalId);
    this.profileRenderService.loadComponent('proposal-detail');
    // this.router.navigate(['/proposal'], { queryParams: { id: proposalId } });
    // this.openSidebar('RESUME');
  }

  public handleSolvePending(proposalId: string): void {
    this.setProposalId(proposalId);
    this.openSidebar('PENDING');
  }

  public openSidebar(type: string): void {
    const isMobile = window.innerWidth < this.MOBILE_BREAKPOINT;
    const dialogConfig: MatDialogConfig = {
      width: isMobile ? '100%' : '68%',
      maxWidth: '61.375rem',
      id: 'summary-side-modal',
      panelClass: 'summary-modal-container',
      height: '100%',
      autoFocus: false,
      position: {
        right: '0px',
        top: '0px'
      }
    };

    switch (type) {
      case 'RESUME':
        // this.dialog.open(ProposalResumeComponent, dialogConfig);
        break;
      case 'DETAILS':
        this.dialog.open(ProposalDetailComponent, dialogConfig);
        break;
      case 'PENDING':
        this.dialog.open(PendencyComponent, dialogConfig);
        break;
    }
  }

  public setProposalId(proposalId: string) {
    this.store$.dispatch(new fromProposal.actions.SetProposalIdAction(proposalId));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private configureSkeleton(): void {
    const size: number = window.innerWidth;
    if (size < 1000) {
      this.skeletonConfig = {
        appearance: 'circle',
        count: '1',
        theme: {
          width: '90%',
          height: '300px',
          'border-radius': '5px',
          'margin-left': '16px',
          'margin-bottom': '50px'
        }
      };
    } else if (size >= 1000 && size < 1200) {
      this.skeletonConfig = {
        appearance: 'circle',
        count: '2',
        theme: {
          width: '435px',
          height: '300px',
          'border-radius': '5px',
          'margin-left': '16px',
          'margin-bottom': '50px'
        }
      };
    } else if (size >= 1200) {
      this.skeletonConfig = {
        appearance: 'circle',
        count: '3',
        theme: {
          width: '345px',
          height: '300px',
          'border-radius': '5px',
          'margin-left': '16px',
          'margin-bottom': '50px'
        }
      };
    }
  }

  private configureCarousels(): void {
    this.proposalsOptions = {
      loop: false,
      mouseDrag: false,
      touchDrag: true,
      pullDrag: false,
      dots: true,
      navSpeed: 700,
      navText: [
        '<span class="material-icons"> chevron_left </span>',
        '<span class="material-icons"> chevron_right </span>'
      ],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 1
        },
        740: {
          items: 2
        },
        940: {
          items: 2
        }
      },
      nav: true,
      slideBy: 2
    };

    this.offersOptions = {
      loop: false,
      mouseDrag: false,
      touchDrag: true,
      pullDrag: false,
      dots: true,
      navSpeed: 700,
      navText: [
        '<span class="material-icons"> chevron_left </span>',
        '<span class="material-icons"> chevron_right </span>'
      ],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 1
        },
        740: {
          items: 2
        },
        940: {
          items: 3
        }
      },
      nav: true,
      slideBy: 1
    };

    this.activeContractsOptions = {
      loop: false,
      mouseDrag: false,
      touchDrag: true,
      pullDrag: false,
      dots: true,
      navSpeed: 700,
      navText: [
        '<span class="material-icons"> chevron_left </span>',
        '<span class="material-icons"> chevron_right </span>'
      ],
      responsive: {
        0: {
          items: 1
        },
        400: {
          items: 1
        },
        740: {
          items: 2
        },
        940: {
          items: 2
        }
      },
      nav: true,
      slideBy: 2
    };
  }

  private getOpenProposals(): void {
    this.store$
      .select(fromProposal.selectors.selectOpenProposals)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        withLatestFrom(this.store$.select(userProfileSelector)),
        tap(([proposals, profile]) => {
          if (proposals) {
            const labelString = proposals.data.map(oneProposal => {
              return {
                id: oneProposal.id,
                dataInicio: oneProposal.proposalDate,
                dataFim: oneProposal.proposalValidityDate,
                idTab: oneProposal.storeCode,
                idSubsegmento: oneProposal.subSegmentId,
                tipoUsuario: profile.documentType
              };
            });
            if (!this.proposalsSentToTagging) {
              this.genTagger.setTag({
                event_category: window.location.pathname,
                event_label: labelString,
                event_action: Tag.nonInteractionEvent
              });
              this.proposalsSentToTagging = !this.proposalsSentToTagging;
            }
          }
        })
      )
      .subscribe(([openProposals]) => {
        try {
          openProposals.data.sort((a, b) => {
            if (a.stProposals.codigoBotaoVitrine === 'SOLVE-PENDING') return -1;
            if (a.stProposals.codigoBotaoVitrine !== 'SOLVE-PENDING') return 1;
            if (b.stProposals.codigoBotaoVitrine === 'SOLVE-PENDING') return -1;
            if (b.stProposals.codigoBotaoVitrine !== 'SOLVE-PENDING') return 1;
          });
        } catch (error) {}
        this.openProposals = openProposals;
        this.pendingProposals = this.filterPendingProposals(openProposals);
        if (this.openProposals.data && this.openProposals.data.length) {
          if (this.openProposals.data.length > 1) {
            this.openProposals.data = this.openProposals.data.slice(0, 2);
            this.openProposals.data.push({
              ...this.openProposals.data[0],
              lastEmptyCard: true
            });
          }
        }

        if (this.pendingProposals.data && this.pendingProposals.data.length) {
          if (this.pendingProposals.data.length > 1 && this.pendingProposals.data.length % 2 === 1) {
            this.pendingProposals.data.push({
              ...this.pendingProposals.data[0],
              lastEmptyCard: true
            });
          }
        }
      });

    this.store$.dispatch(new fromProposal.actions.GetOpenProposalsAction());
  }

  private getPersonalizedOffers(): void {
    this.store$
      .select(fromShowcase.selectors.selectPersonalizedOffers)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(personalizedOffers => {
        this.personalizedOffers = {
          data: personalizedOffers.data ? [personalizedOffers.data] : [],
          isLoading: personalizedOffers.isLoading
        };
      });

    this.store$.dispatch(new fromShowcase.actions.GetPersonalizedOffersAction());
  }

  private getSpecialOffers(): void {
    this.store$
      .select(fromShowcase.selectors.selectSpecialOffers)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(specialOffers => {
        this.specialOffers = specialOffers;
        if (specialOffers.data && specialOffers.data.length > 3) {
          const firstThree = specialOffers.data.slice(0, 3);
          const emptyCard = specialOffers.data.slice(-1);
          this.specialOffers = { data: [...firstThree, ...emptyCard], isLoading: specialOffers.isLoading };
        }
      });

    this.store$.dispatch(new fromShowcase.actions.GetSpecialOffersAction());
  }

  private getActiveContracts(): void {
    this.store$
      .select(fromContracts.selectors.selectActiveContracts)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(contracts => {
        try {
          this.contracts = contracts;
          if (this.contracts.data && this.contracts.data.length > 0) {
            if (this.contracts.data.length > 1) {
              this.contracts.data = this.contracts.data.slice(0, 2);
            } else {
              this.flagOneContract = true;
            }

            this.contracts.data.push({ ...this.contracts.data[0], lastEmptyCard: true });
          }
        } catch (error) {}
      });
    this.store$.dispatch(new fromContracts.actions.GetActiveContractsAction());
  }

  private filterPendingProposals(openProposals: any): any {
    if (!openProposals || !openProposals.data) return {};
    return {
      ...openProposals,
      data: openProposals.data.filter((proposal: any) => {
        if (proposal.stProposals.codigoBotaoVitrine === 'SOLVE-PENDING') {
          return false;
        }
      })
    };
  }

  private orderProposals(openProposals: any): any {
    if (!openProposals || !openProposals.data) return {};
    return {
      ...openProposals,
      data: openProposals.data.filter(proposal => {
        if (
          proposal.statusCod === StatusProposals.PROPOSAL_IN_FORMALIZATION ||
          proposal.statusCod === StatusProposals.PENDING_DOCUMENT ||
          proposal.statusCod === StatusProposals.PROPOSAL_IN_PROGRESS
        ) {
          return proposal;
        }
      })
    };
  }

  private getSubsegments(): void {
    this.store$
      .select(fromShowcase.selectors.selectSubsegmentsList)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(subsegmentsList => {
        this.subsegments = subsegmentsList;
      });

    this.store$.dispatch(new fromShowcase.actions.GetSubsegmentsAction());
  }
}
