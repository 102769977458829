<p class="row title">{{ title | translate }}</p>
<p class="row subtitle">
  {{ subtitle | translate }}
  <strong *ngIf="tokenDestination && tokenDestinationMask?.mask && tokenDestinationMask?.prefix">
    {{ tokenDestinationMask.prefix }}{{ tokenDestination | mask: tokenDestinationMask?.mask }}
  </strong>
  <strong *ngIf="tokenDestination && !tokenDestinationMask?.mask">
    {{ tokenDestination }}
  </strong>
</p>
<div [formGroup]="digitsForm" class="row digits">
  <ng-container *ngFor="let digit of digitsList">
    <input
      #digitInput
      id="{{ 'digit' + digit }}"
      formControlName="{{ 'digit' + digit }}"
      type="password"
      maxlength="1"
      autocomplete="off"
      (ngModelChange)="digitChanged($event, digit)"
      oninput="this.value=this.value.replace(/[^0-9]/g,'');"
      (keydown)="isDeletePressed(digit, $event)"
    />
  </ng-container>
  <span class="material-icons" *ngIf="wrongCode">
    warning
  </span>
</div>

<div class="row" *ngIf="wrongCode">
  <p
    class="wrong-code-text"
    appTagger
    [event_category]="fType$ | async"
    event_label="Código incorreto. Tente novamente."
    [event_action]="FillingError"
  >
    {{ 'CODE-ERROR-MSG' | translate }}
  </p>
</div>

<div class="row">
  <a *ngIf="notYoursText">{{ notYoursText | translate }}</a>
</div>

<div class="row">
  <a (click)="doesntGetCode()">{{ 'DOESNT-GET-CODE' | translate }}</a>
</div>

<div class="row">
  <button
    mat-button
    class="submit-btn"
    [disabled]="!digitsForm.valid"
    (click)="submitToken()"
    appTagger
    [event_category]="category"
    event_label="criar nova senha"
  >
    {{ btnMessage | translate }}
  </button>
</div>

<div class="row bottom-text" *ngIf="!isTimeover && !(wrongCode && digitsForm.valid)">
  {{ timeLeftText }}
</div>

<div class="row" *ngIf="!isTimeover && !(wrongCode && digitsForm.valid)">
  <p>{{ 'CODE-WAITING-MSG' | translate }}</p>
</div>

<div class="row" *ngIf="isTimeover || (wrongCode && digitsForm.valid)">
  <a (click)="resendToken()" appTagger [event_category]="fType$ | async" event_label="reenviar código">
    {{ 'RESEND-CODE' | translate }}
  </a>
</div>
