import { HttpErrorResponse, HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { DownloadService } from '@app/esign/services/download.service';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { PostFileDTOResponse } from '../../models/api/post.file.model';
import { ChecklistFileModel, ChecklistModel } from '../../models/checklist.model';
import { UploadService } from '../../service/upload.service';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() checklistItem: ChecklistModel;
  @Input() maxSizeAllowed: string; // Value in MB
  @Output() uploadFinished = new EventEmitter();
  @ViewChild('fileUpload', { static: true }) fileUpload: ElementRef;

  public files: ChecklistFileModel[] = [];
  public filesSuccess: ChecklistFileModel[] = [];
  public filesError: ChecklistFileModel[] = [];
  public uploadsInProgress: { [key: string]: Subscription } = {};
  public errorMessage = '';
  public showImage = false;

  public showModal = false;
  public initialImage: string;

  public attachText = this.translate.instant('ATTACH');

  constructor(
    private uploadService: UploadService,
    private translate: TranslateService,
    private downloadService: DownloadService
  ) {}

  ngOnInit() {
    this.fileUpload.nativeElement.click();
  }

  public handleAttach(): void {
    this.filesError = [];
    this.errorMessage = '';
    this.fileUpload.nativeElement.click();
  }

  /**
   * This method handles the onchange event for the #fileUpload
   * input, transforms the FileList to File[] validating every file's size
   * and advancing them to the rest of the document upload flux.
   */
  public uploadFileHandler(): void {
    this.uploadFinished.emit(false);
    const filesArray: File[] = Array.from(this.fileUpload.nativeElement.files);

    if (JSON.parse(environment.featureToggle.validateFileSumSize.active as any)) {
      const currentFileSizeSum =
        this.filesSuccess.map(item => (item.data as File).size).reduce((a, b) => a + b, 0) / (1024 * 100) / 10;

      const fileSizeSum = filesArray.map(file => file.size).reduce((a, b) => a + b, 0) / (1024 * 100) / 10;

      if (fileSizeSum + currentFileSizeSum > +this.maxSizeAllowed) {
        for (const file of filesArray) {
          this.filesError.unshift({
            data: file,
            order: this.files.indexOf(file, 0),
            name: file.name,
            errorMsg: this.translate.instant('MAX-FILE-SIZE-ERROR-MSG')
          });
        }

        return;
      }
    }

    const maxFileNumber = this.checklistItem.document.maxAmount;

    if (maxFileNumber && filesArray.length + this.filesSuccess.length > maxFileNumber) {
      this.errorMessage = this.translate.instant('MAX-FILE-NUMBER-EXCEEDED-ERROR', { maxFileNumber: maxFileNumber });
      this.checkUploadStatus();
      return;
    }

    for (const file of filesArray) {
      // verifiy extension file
      if (
        file.type !== 'application/pdf' &&
        file.type !== 'image/png' &&
        file.type !== 'image/jpg' &&
        file.type !== 'image/jpeg'
      ) {
        this.errorMessage = this.translate.instant('FORMALIZATION-ERROR-TYPE-UPLOAD');
        this.checkUploadStatus();
        return;
      }

      if (this.isFileSizeValid(file)) {
        this.files.push({
          data: file,
          inProgress: false,
          progress: 0,
          loaded: '0',
          total: '0',
          name: file.name
        });
      }

      if (!this.isFileSizeValid(file)) {
        // Add to unsuccessfuly uploaded list
        this.filesError.unshift({
          data: file,
          order: this.files.indexOf(file, 0),
          name: file.name,
          errorMsg: this.translate.instant('MAX-FILE-SIZE-ERROR-MSG')
        });
      }
    }

    if (this.files.length) {
      this.uploadFiles();
    }

    this.checkUploadStatus();
  }

  public isFileSizeValid(file: File): boolean {
    const maxSizeInBytes = +this.maxSizeAllowed * Math.pow(1024, 2); // Transforming to bytes
    return file.size < maxSizeInBytes;
  }

  // Handles multiple files input
  private uploadFiles(): void {
    this.fileUpload.nativeElement.value = '';
    this.filesError = [];
    this.files.forEach((file: ChecklistFileModel) => {
      this.uploadSingleFile(file, false);
    });
  }

  private uploadSingleFile(file: ChecklistFileModel, isBase64: boolean): void {
    this.checklistItem.files = [];
    const { id, proposal, document, uploadGroup, artifact, encrypted } = this.checklistItem;
    const item: ChecklistModel = {
      id,
      proposal,
      document,
      uploadGroup,
      artifact,
      encrypted,
      file: {
        data: file.data
      }
    };

    this.downloadService.fileToBase64(item.file.data).then(base64 => {
      const type = (file.data as File).type;
      item.file.data = base64;

      const subscription = this.uploadService
        .uploadDocument(item, type)
        .pipe(
          tap(event => this.calculateUploadProgress(event, file)),
          filter(event => event.type === HttpEventType.Response),
          takeUntil(this.ngUnsubscribe)
        )
        .subscribe(
          (event: HttpResponse<PostFileDTOResponse>) => this.handleSuccessfulUpload(event, file),
          (error: HttpErrorResponse) => this.handleUnsuccessfulUpload(error, file)
        );

      this.uploadsInProgress[file.name] = subscription;
    });
  }

  public handleSuccessfulUpload(res: HttpResponse<PostFileDTOResponse>, file: ChecklistFileModel): void {
    const uploadingIndex = this.files.findIndex(f => f.name === file.name); // Get index from uploading list
    const fileId = res.body.id;

    this.files.splice(uploadingIndex, 1); // Remove from uploading files list

    // Add to successfully uploaded files list
    this.filesSuccess.unshift({
      id: fileId,
      data: file.data,
      name: file.name,
      total: file.total
    });

    this.attachText = this.translate.instant('ATTACH-NEW');

    this.checklistItem.files.push({ id: fileId }); // Add to output item

    this.checkUploadStatus();
  }

  public handleUnsuccessfulUpload(error: HttpErrorResponse, file: ChecklistFileModel): void {
    const uploadingIndex = this.files.findIndex(f => f.name === file.name); // Get index from uploading list

    this.files.splice(uploadingIndex, 1); // Remove from uploading files list

    // Add to unsuccessfuly uploaded list
    this.filesError.unshift({
      data: file.data,
      order: this.files.indexOf(file, 0),
      name: file.name,
      errorMsg: this.translate.instant('FILE-UPLOAD-ERROR-MSG')
    });

    this.checkUploadStatus();
  }

  public handleDisable() {
    if (this.checklistItem.document.maxAmount === null) {
      return false;
    }

    if (this.filesSuccess.length >= this.checklistItem.document.maxAmount) {
      return true;
    }

    if (this.filesSuccess.length === 0 && this.filesError.length === 0) {
      return false;
    }
  }

  public calculateUploadProgress(event: HttpEvent<PostFileDTOResponse>, file: ChecklistFileModel): void {
    switch (event.type) {
      case HttpEventType.Sent:
        file.inProgress = true;
        break;
      case HttpEventType.UploadProgress:
        // We artificially set progress to 99% when completed to account for
        // download and response events.
        const realProgress = Math.round((event.loaded * 100) / event.total);
        const progress = realProgress < 1 ? realProgress : realProgress - 1;

        file.loaded = this.formatSize(event.loaded);
        file.total = this.formatSize(event.total);
        file.progress = progress;
        break;
      case HttpEventType.Response:
        file.progress = 100;
        file.inProgress = false;
        break;
      default:
        break;
    }
  }

  public onCancelClick(file: ChecklistFileModel): void {
    this.uploadsInProgress[file.name].unsubscribe(); // Cancel specific upload

    const uploadingIndex = this.files.findIndex(f => f.name === file.name); // Get index from uploading list
    this.files.splice(uploadingIndex, 1); // Remove from uploading files list

    this.checkUploadStatus();
  }

  public onDeleteClick(file: ChecklistFileModel): void {
    if (file.data) {
      const { id: checklistId } = this.checklistItem;

      this.uploadService
        .deleteFile(checklistId, file.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(
          () => {
            const index = this.filesSuccess.findIndex(f => f.id === file.id);
            this.attachText = this.translate.instant('ATTACH');
            this.filesSuccess.splice(index, 1); // Remove from successfuly uploaded files list
            this.checkUploadStatus();
          },
          (error: HttpErrorResponse) => {
            this.errorMessage = this.translate.instant('FILE-DELETE-ERROR-MSG');
          }
        );
    }
  }

  public formatSize(bytes: number): string {
    const k = 1024;
    const decimals = 2;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(decimals)) + ' ' + sizes[i];
  }

  public checkUploadStatus(): void {
    this.uploadFinished.emit(this.filesSuccess.length && !this.files.length);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
