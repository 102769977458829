<div>
  <pion-tooltip [alignTo]="alignTo" *ngIf="
      planSelected?.planTypeSchemaData.planTypeCode === 'FLT' && !planSelected?.planTypeSchemaData.hasFlexInstallments
    " appTagger [event_category]="category" event_label="{{ 'TOOLTIP-PAYMENT-PLAN-FLT' | translate }}">
    <div [ngClass]="showMoreDetails ? 'show' : 'hide'">
      {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
      <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-FLT' | translate }}</p>
      <p class="font-weight-bold">
        {{ planSelected.planTypeSchemaData.installmentDefaultAmount }} x de
        {{ planSelected.planTypeSchemaData.installmentDefaultValue | localeCurrency }}
      </p>
      <p>
        <a (click)="openInstallmentsModal(true)" appTagger [event_category]="category"
          event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
          translate }}</a>
      </p>
    </div>
    <div class=" tamanhoFont" [ngClass]="!showMoreDetails && !showTarif ? 'show' : 'hide'">
      <span class="spacing">{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}</span>
      <span class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-FLT' | translate }}</span>
      <span class="spacing mt-2">{{ 'TOTAL-AMOUNT-OF-FINANCIAL-PROTECTION' | translate }}</span>
      <span class="font-weight-bold">{{ insuranceValue | localeCurrency }}</span>
    </div>
    <div class=" tamanhoFont" [ngClass]="!showMoreDetails && showTarif ? 'show' : 'hide'">
      <span class="spacing">{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}</span>
      <span class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-FLT' | translate }}</span>
      <span class="spacing mt-2">{{ 'TOTAL-VALUE-OF-REGISTRATION-FEE' | translate }}</span>
      <span class="font-weight-bold">{{ registerTariff | localeCurrency }}</span>
    </div>
  </pion-tooltip>

  <pion-tooltip [alignTo]="alignTo" *ngIf="
  planSelected?.planTypeSchemaData.planTypeCode === 'FIXED' && !planSelected?.planTypeSchemaData.hasFlexInstallmentss
" appTagger [event_category]="category" event_label="{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}">
    <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-FIXED' | translate }}</p>
    {{ 'NUMBER-OF-FIXED-INSTALLMENT' | translate }}
    <p class="font-weight-bold mb-0">{{ planSelected.planTypeSchemaData.installmentPaymentMethodsAmount }}</p>
    {{ 'INSTALLMENTS-VALUE-FIXED' | translate }}
    <p class="font-weight-bold">{{ planSelected.planTypeSchemaData.firstInstallmentValue | localeCurrency}}</p>

    {{ 'INSTALLMENTS-STANDARD-BALLOON' | translate }}
    <p class="font-weight-bold mb-0">
      {{ planSelected.planTypeSchemaData.installmentDefaultAmount }}
    </p>
    {{'VALUE-OF-INSTALLMENTS-STANDARD' | translate}}
    <p class="font-weight-bold mb-0">
      {{ planSelected.planTypeSchemaData.lastInstallmentValue | localeCurrency }}
    </p>
    <p>
      <a (click)="openInstallmentsModal(true)" appTagger [event_category]="category"
        event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
        translate }}</a>
    </p>
  </pion-tooltip>

  <pion-tooltip [alignTo]="alignTo" *ngIf="
      planSelected?.planTypeSchemaData.planTypeCode === 'ASC' && !planSelected?.planTypeSchemaData.hasFlexInstallmentss
    " appTagger [event_category]="category" event_label="{{ 'TOOLTIP-PAYMENT-PLAN-ASC' | translate }}">
    <div [ngClass]="showMoreDetails ? 'show' : 'hide'">
      {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
      <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-ASC' | translate }}</p>
      {{ 'TOOLTIP-PAYMENT-PLAN-FIRST-INSTALLMENT' | translate }}
      <p class="font-weight-bold mb-0">{{ planSelected.planTypeSchemaData.firstInstallmentValue | localeCurrency }}</p>
      {{ 'TOOLTIP-PAYMENT-PLAN-LAST-INSTALLMENT' | translate }}
      <p class="font-weight-bold">{{ planSelected.planTypeSchemaData.lastInstallmentValue | localeCurrency }}</p>
      <p>
        <a (click)="openInstallmentsModal(true)" appTagger [event_category]="category"
          event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
          translate }}</a>
      </p>
    </div>
    <div class=" tamanhoFont" [ngClass]="!showMoreDetails && !showTarif ? 'show' : 'hide'">
      <span class="spacing">{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}</span>
      <span class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-ASC' | translate }}</span>
      <span class="spacing mt-2">{{ 'TOTAL-AMOUNT-OF-FINANCIAL-PROTECTION' | translate }}</span>
      <span class="font-weight-bold">{{ insuranceValue | localeCurrency }}</span>
    </div>
    <div class=" tamanhoFont" [ngClass]="!showMoreDetails && showTarif ? 'show' : 'hide'">
      <span class="spacing">{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}</span>
      <span class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-ASC' | translate }}</span>
      <span class="spacing mt-2">{{ 'TOTAL-VALUE-OF-REGISTRATION-FEE' | translate }}</span>
      <span class="font-weight-bold">{{ registerTariff | localeCurrency }}</span>
    </div>
  </pion-tooltip>
  <pion-tooltip [alignTo]="alignTo" *ngIf="
      planSelected?.planTypeSchemaData.planTypeCode === 'DEC' && !planSelected?.planTypeSchemaData.hasFlexInstallments
    " appTagger [event_category]="category" event_label="{{ 'TOOLTIP-PAYMENT-PLAN-DEC' | translate }}">
    <div [ngClass]="showMoreDetails ? 'show' : 'hide'">
      {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
      <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-DEC' | translate }}</p>
      {{ 'TOOLTIP-PAYMENT-PLAN-FIRST-INSTALLMENT' | translate }}
      <p class="font-weight-bold mb-0">{{ planSelected.planTypeSchemaData.firstInstallmentValue | localeCurrency }}</p>
      {{ 'TOOLTIP-PAYMENT-PLAN-LAST-INSTALLMENT' | translate }}
      <p class="font-weight-bold">{{ planSelected.planTypeSchemaData.lastInstallmentValue | localeCurrency }}</p>
      <p>
        <a (click)="openInstallmentsModal(true)" appTagger [event_category]="category"
          event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
          translate }}</a>
      </p>
    </div>
    <div class=" tamanhoFont" [ngClass]="!showMoreDetails && !showTarif ? 'show' : 'hide'">
      <span class="spacing">{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}</span>
      <span class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-DEC' | translate }}</span>
      <span class="spacing mt-2">{{ 'TOTAL-AMOUNT-OF-FINANCIAL-PROTECTION' | translate }}</span>
      <span class="font-weight-bold">{{ insuranceValue | localeCurrency }}</span>
    </div>
    <div class=" tamanhoFont" [ngClass]="!showMoreDetails && showTarif ? 'show' : 'hide'">
      <span class="spacing">{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}</span>
      <span class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-DEC' | translate }}</span>
      <span class="spacing mt-2">{{ 'TOTAL-VALUE-OF-REGISTRATION-FEE' | translate }}</span>
      <span class="font-weight-bold">{{ registerTariff | localeCurrency }}</span>
    </div>
  </pion-tooltip>
  <pion-tooltip [alignTo]="alignTo" *ngIf="
      planSelected?.planTypeSchemaData.planTypeCode === 'BLN' && !planSelected?.planTypeSchemaData.hasFlexInstallments
    " appTagger [event_category]="category" event_label="{{ 'TOOLTIP-PAYMENT-PLAN-BLN' | translate }}">
    <div [ngClass]="showMoreDetails ? 'show' : 'hide'">
      {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
      <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-BLN' | translate }}</p>
      {{ 'TOOLTIP-PAYMENT-PLAN-PERIOD' | translate }}
      <p class="font-weight-bold mb-0">{{ planSelected.planTypeSchemaData.frequencedescription }}</p>
      {{ 'TOOLTIP-PAYMENT-PLAN-FIRST-BALLOON' | translate }}
      <p class="font-weight-bold">{{ planSelected.planTypeSchemaData.monthOccurrency }}</p>
      {{ 'TOOLTIP-PAYMENT-PLAN-INSTALLMENT-NORMAL' | translate }}
      <p class="font-weight-bold mb-0">
        {{ planSelected.planTypeSchemaData.installmentDefaultAmount }} x
        {{ planSelected.planTypeSchemaData.installmentDefaultValue | localeCurrency }}
      </p>
      {{ 'TOOLTIP-PAYMENT-PLAN-INSTALLMENTS-BALLOON' | translate }}
      <p class="font-weight-bold">
        {{ planSelected.planTypeSchemaData.installmentPaymentMethodsAmount }} x
        {{ planSelected.planTypeSchemaData.installmentPaymentMethodsValue | localeCurrency }}
      </p>
      <p>
        <a (click)="openInstallmentsModal(true)" appTagger [event_category]="category"
          event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
          translate }}</a>
      </p>
    </div>
    <div class=" tamanhoFont" [ngClass]="!showMoreDetails && !showTarif ? 'show' : 'hide'">
      <span class="spacing">{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}</span>
      <span class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-BLN' | translate }}</span>
      <span class="spacing mt-2">{{ 'TOTAL-AMOUNT-OF-FINANCIAL-PROTECTION' | translate }}</span>
      <span class="font-weight-bold">{{ insuranceValue | localeCurrency }}</span>
    </div>
    <div class=" tamanhoFont" [ngClass]="!showMoreDetails && showTarif ? 'show' : 'hide'">
      <span class="spacing">{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}</span>
      <span class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-BLN' | translate }}</span>
      <span class="spacing mt-2">{{ 'TOTAL-VALUE-OF-REGISTRATION-FEE' | translate }}</span>
      <span class="font-weight-bold">{{ registerTariff | localeCurrency }}</span>
    </div>
  </pion-tooltip>

  <pion-tooltip [alignTo]="alignTo" *ngIf="
  planSelected?.planTypeSchemaData.planTypeCode === 'SEASONAL' && !planSelected?.planTypeSchemaData.hasFlexInstallments
" appTagger [event_category]="category" event_label="{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}">

    <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-SEASONAL' | translate }}</p>
    {{ 'TOOLTIP-PAYMENT-PLAN-PERIOD' | translate }}
    <p class="font-weight-bold mb-0">{{ planSelected.planTypeSchemaData.frequencedescription }}</p>
    {{ 'FIRST-MONTH-INSTALLMENT' | translate }}
    <p class="font-weight-bold">{{ planSelected.planTypeSchemaData.monthOccurrency }}</p>
    {{ 'INSTALLMENTS-SASONAL' | translate }}
    <p class="font-weight-bold">
      {{ planSelected.planTypeSchemaData.installmentPaymentMethodsAmount }} x
      {{ planSelected.planTypeSchemaData.installmentPaymentMethodsValue | localeCurrency }}
    </p>
    {{ 'OTHER-INSTALLMENTS' | translate }}
    <p class="font-weight-bold mb-0">
      {{ planSelected.planTypeSchemaData.installmentDefaultAmount }} x {{
      planSelected.planTypeSchemaData.installmentDefaultValue | localeCurrency }}
    </p>
    <p>
      <a (click)="openInstallmentsModal(true)" appTagger [event_category]="category"
        event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
        translate }}</a>
    </p>
  </pion-tooltip>

  <pion-tooltip [alignTo]="alignTo" *ngIf="
      planSelected?.planTypeSchemaData.planTypeCode === 'LACK' && !planSelected?.planTypeSchemaData.hasFlexInstallments
    " appTagger [event_category]="category" event_label="{{ 'TOOLTIP-PAYMENT-PLAN-LACK' | translate }}">
    <div [ngClass]="showMoreDetails ? 'show' : 'hide'">
      {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
      <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-LACK' | translate }}</p>
      {{ 'TOOLTIP-PAYMENT-PLAN-PERIOD' | translate }}
      <p class="font-weight-bold mb-0">{{ planSelected.planTypeSchemaData.frequencedescription }}</p>
      {{ 'TOOLTIP-PAYMENT-PLAN-FIRST-JUMPED-INSTALLMENT' | translate }}
      <p class="font-weight-bold">{{ planSelected.planTypeSchemaData.monthOccurrency }}</p>
      {{ 'TOOLTIP-PAYMENT-PLAN-INSTALLMENT-NORMAL' | translate }}
      <p class="font-weight-bold">
        {{ planSelected.planTypeSchemaData.installmentDefaultAmount }} x
        {{ planSelected.planTypeSchemaData.installmentDefaultValue | localeCurrency }}
      </p>
      <p>
        <a (click)="openInstallmentsModal(true)" appTagger [event_category]="category"
          event_label="{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' | translate }}">{{ 'TOOLTIP-PAYMENT-PLAN-MORE-DETAILS' |
          translate }}</a>
      </p>
    </div>
    <div class=" tamanhoFont" [ngClass]="!showMoreDetails && !showTarif ? 'show' : 'hide'">
      <span class="spacing">{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}</span>
      <span class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-LACK' | translate }}</span>
      <span class="spacing mt-2">{{ 'TOTAL-AMOUNT-OF-FINANCIAL-PROTECTION' | translate }}</span>
      <span class="font-weight-bold">{{ insuranceValue | localeCurrency }}</span>
    </div>
    <div class=" tamanhoFont" [ngClass]="!showMoreDetails && showTarif ? 'show' : 'hide'">
      <span class="spacing">{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}</span>
      <span class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-LACK' | translate }}</span>
      <span class="spacing mt-2">{{ 'TOTAL-VALUE-OF-REGISTRATION-FEE' | translate }}</span>
      <span class="font-weight-bold">{{ registerTariff | localeCurrency }}</span>
    </div>
  </pion-tooltip>
  <pion-tooltip [alignTo]="alignTo" *ngIf="planSelected?.planTypeSchemaData.hasFlexInstallments" appTagger
    [event_category]="category" event_label="{{ 'TOOLTIP-PAYMENT-PLAN-FLEX' | translate }}">
    <div [ngClass]="showMoreDetails ? 'show' : 'hide'">
      {{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}
      <p class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-FLEX' | translate }}</p>
      {{ 'TOOLTIP-PAYMENT-PLAN-INSTALLMENT-NORMAL' | translate }}
      <p class="font-weight-bold">{{ planPayments[0]?.flexInstallmentsNumber }}</p>
      <p>
        <a (click)="openInstallmentsModal(true)" appTagger [event_category]="category"
          event_label="{{ 'TOOLTIP-PAYMENT-PLAN-FIRST-INSTALLMENTS-FLEXIBLE' | translate }}">{{
          'TOOLTIP-PAYMENT-PLAN-FIRST-INSTALLMENTS-FLEXIBLE' | translate }}</a>
      </p>
    </div>
    <div class=" tamanhoFont" [ngClass]="!showMoreDetails && !showTarif ? 'show' : 'hide'">
      <span class="spacing">{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}</span>
      <span class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-FLEX' | translate }}</span>
      <span class="spacing mt-2">{{ 'TOTAL-AMOUNT-OF-FINANCIAL-PROTECTION' | translate }}</span>
      <span class="font-weight-bold">{{ insuranceValue | localeCurrency }}</span>
    </div>
    <div class=" tamanhoFont" [ngClass]="!showMoreDetails && showTarif ? 'show' : 'hide'">
      <span class="spacing">{{ 'TOOLTIP-PAYMENT-PLAN-TITLE' | translate }}</span>
      <span class="font-weight-bold">{{ 'TOOLTIP-PAYMENT-PLAN-FLEX' | translate }}</span>
      <span class="spacing mt-2">{{ 'TOTAL-VALUE-OF-REGISTRATION-FEE' | translate }}</span>
      <span class="font-weight-bold">{{ registerTariff | localeCurrency }}</span>
    </div>
  </pion-tooltip>
</div>
