import { ChecklistModelMobile } from './../../../proposal-workflow/containers/step-formalization/components/checklist/models/checklist.model';
import { ChecklistDocumentEnum } from './../../../proposal-workflow/containers/step-formalization/components/checklist/models/api/query.checklist.model';
import { ChecklistItemTypeEnum } from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/api/query.checklist.model';
import { ChecklistModel } from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/checklist.model';
import { GetProposalStageDTO } from '@app/proposal-workflow/models/proposal-stage.model';
import { PanelProposalDTO } from '@app/showcase/proposals/models/api/get.proposal-list.model';
import { FormalizationProposalDTO } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import {
  GetChecklistSuccessAction,
  ProposalActions,
  ProposalActionTypes,
  GetChecklistMobileSuccessAction
} from './proposal.actions';

export interface PaymentPlanSelected {
  code?: any;
  description?: any;
  firstInstallmentPMethodsDate?: any;
  installmentDefaultValue?: any;
  installmentPaymentMethodsValue?: any;
  firstInstallmentValue?: any;
  lastInstallmentValue?: any;
  installmentDefaultAmount?: any;
  installmentPaymentMethodsAmount?: any;
  monthsRange?: [];
  installments?: any;
  hasFlexInstallments?: boolean;
  customInstallmentValue?: number;
}
export interface ProposalState {
  openProposals: {
    data: any;
    isLoading: boolean;
  };
  pendingProposals: {
    data: any;
    isLoading: boolean;
  };
  checklists: ChecklistModel[];
  checklistsMobile: ChecklistModelMobile[];
  showDownloadLink: boolean;
  showDownloadContract: boolean;
  biometryChecklist: {
    data: ChecklistModel[];
    isLoading: boolean;
  };
  uploadChecklist: {
    data: ChecklistModel[];
    isLoading: boolean;
  };

  biometryChecklistMobile: {
    data: ChecklistModelMobile[];
    isLoading: boolean;
  };
  uploadChecklistMobile: {
    data: ChecklistModelMobile[];
    isLoading: boolean;
  };
  proposalId: number | string;
  proposalStage: GetProposalStageDTO;
  proposalData: { data: FormalizationProposalDTO; isLoading: boolean };

  pendingProposal: {
    data: any;
    isLoading: boolean;
    error: any;
    bankData: boolean;
  };
  pendingProposalError: any;
  choosedProposalCard: PanelProposalDTO;
  updatedProposal: boolean;
  paymentPlanSelected: PaymentPlanSelected;
  isProposalPendencyBiometryFlowEnabled: boolean;
}

export const initialProposalState: ProposalState = {
  openProposals: {
    data: null,
    isLoading: false
  },
  pendingProposals: {
    data: null,
    isLoading: false
  },
  checklists: null,
  checklistsMobile: null,
  showDownloadLink: null,
  showDownloadContract: null,
  biometryChecklist: {
    data: null,
    isLoading: false
  },
  uploadChecklist: {
    data: null,
    isLoading: false
  },
  biometryChecklistMobile: {
    data: null,
    isLoading: false
  },
  uploadChecklistMobile: {
    data: null,
    isLoading: false
  },
  proposalId: null,
  proposalStage: {
    stageId: null,
    stageName: null,
    portalStage: null,
    groupId: null,
    groupName: null,
    actionStartedAt: null,
    actionEndedAt: null,
    position: null,
    status: null,
    retailOperator: null,
    flagBackToSimulation: null,
    stageProposal: null
  },
  proposalData: {
    data: null,
    isLoading: false
  },
  pendingProposal: {
    data: null,
    isLoading: false,
    error: null,
    bankData: false
  },
  pendingProposalError: null,
  choosedProposalCard: null,
  updatedProposal: null,
  paymentPlanSelected: null,
  isProposalPendencyBiometryFlowEnabled: null
};

export function proposalReducer(state = initialProposalState, action: ProposalActions): ProposalState {
  switch (action.type) {
    case ProposalActionTypes.GET_OPEN_PROPOSALS: {
      return {
        ...state,
        openProposals: { ...state.openProposals, isLoading: true }
      };
    }
    case ProposalActionTypes.GET_OPEN_PROPOSALS_SUCCESS: {
      return {
        ...state,
        openProposals: { data: action.payload, isLoading: false }
      };
    }
    case ProposalActionTypes.GET_OPEN_PROPOSALS_ERROR: {
      return {
        ...state,
        openProposals: { data: initialProposalState.openProposals.data, isLoading: false }
      };
    }
    case ProposalActionTypes.GET_PENDING_PROPOSALS: {
      return {
        ...state,
        pendingProposals: { ...state.pendingProposals, isLoading: true }
      };
    }
    case ProposalActionTypes.GET_PENDING_PROPOSALS_SUCCESS: {
      return {
        ...state,
        pendingProposals: { data: action.payload, isLoading: false }
      };
    }
    case ProposalActionTypes.GET_PENDING_PROPOSALS_ERROR: {
      return {
        ...state,
        pendingProposals: { data: initialProposalState.pendingProposals.data, isLoading: false }
      };
    }

    case ProposalActionTypes.GET_PROPOSAL: {
      return {
        ...state,
        proposalData: { ...state.proposalData, isLoading: true }
      };
    }
    case ProposalActionTypes.GET_PROPOSAL_SUCCESS: {
      return {
        ...state,
        proposalData: { data: action.payload, isLoading: false }
      };
    }
    case ProposalActionTypes.CLEAR_PROPOSAL:
    case ProposalActionTypes.GET_PROPOSAL_ERROR: {
      return {
        ...state,
        proposalData: { data: initialProposalState.proposalData.data, isLoading: false }
      };
    }

    case ProposalActionTypes.GET_CHECKLIST: {
      return {
        ...state,
        biometryChecklist: { ...state.biometryChecklist, isLoading: true },
        uploadChecklist: { ...state.uploadChecklist, isLoading: true }
      };
    }
    case ProposalActionTypes.GET_CHECKLIST_SUCCESS: {
      return {
        ...state,
        ...checklistReducer(action)
      };
    }
    case ProposalActionTypes.CLEAR_CHECKLIST:
    case ProposalActionTypes.GET_CHECKLIST_ERROR: {
      return {
        ...state,
        biometryChecklist: { data: initialProposalState.biometryChecklist.data, isLoading: false },
        uploadChecklist: { data: initialProposalState.uploadChecklist.data, isLoading: false },
        checklists: initialProposalState.checklists
      };
    }

    //CHECKLIST MOBILE
    case ProposalActionTypes.GET_CHECKLIST_MOBILE: {
      return {
        ...state,
        biometryChecklistMobile: { ...state.biometryChecklistMobile, isLoading: true },
        uploadChecklistMobile: { ...state.uploadChecklistMobile, isLoading: true }
      };
    }
    case ProposalActionTypes.GET_CHECKLIST_MOBILE_SUCCESS: {
      return {
        ...state,
        ...checklistMobileReducer(action)
      };
    }
    case ProposalActionTypes.CLEAR_CHECKLIST_MOBILE:
    case ProposalActionTypes.GET_CHECKLIST_MOBILE_ERROR: {
      return {
        ...state,
        biometryChecklistMobile: { data: initialProposalState.biometryChecklistMobile.data, isLoading: false },
        uploadChecklistMobile: { data: initialProposalState.uploadChecklistMobile.data, isLoading: false },
        checklistsMobile: initialProposalState.checklistsMobile
      };
    }

    case ProposalActionTypes.GET_UPLOAD_CHECKLIST: {
      return {
        ...state,
        uploadChecklist: { ...state.uploadChecklist, isLoading: true }
      };
    }
    case ProposalActionTypes.GET_UPLOAD_CHECKLIST_SUCCESS: {
      return {
        ...state,
        uploadChecklist: { data: action.payload, isLoading: false }
      };
    }
    case ProposalActionTypes.GET_UPLOAD_CHECKLIST_ERROR: {
      return {
        ...state,
        uploadChecklist: { data: initialProposalState.uploadChecklist.data, isLoading: false }
      };
    }
    //CHECKLIST MOBILE
    case ProposalActionTypes.GET_UPLOAD_CHECKLIST_MOBILE: {
      return {
        ...state,
        uploadChecklistMobile: { ...state.uploadChecklistMobile, isLoading: true }
      };
    }
    case ProposalActionTypes.GET_UPLOAD_CHECKLIST_MOBILE_SUCCESS: {
      return {
        ...state,
        uploadChecklistMobile: { data: action.payload, isLoading: false }
      };
    }
    case ProposalActionTypes.GET_UPLOAD_CHECKLIST_MOBILE_ERROR: {
      return {
        ...state,
        uploadChecklistMobile: { data: initialProposalState.uploadChecklistMobile.data, isLoading: false }
      };
    }
    case ProposalActionTypes.SET_PROPOSAL_ID: {
      return {
        ...state,
        proposalId: action.payload
      };
    }
    case ProposalActionTypes.CLEAR_PROPOSAL_ID: {
      return {
        ...state,
        proposalId: initialProposalState.proposalId
      };
    }

    case ProposalActionTypes.GET_PROPOSAL_STAGE_SUCCESS: {
      return {
        ...state,
        proposalStage: action.payload.stage
      };
    }
    case ProposalActionTypes.GET_PROPOSAL_STAGE_ERROR:
    case ProposalActionTypes.CLEAR_PROPOSAL_STAGE: {
      return {
        ...state,
        proposalStage: initialProposalState.proposalStage
      };
    }

    case ProposalActionTypes.GET_PENDING_PROPOSAL: {
      return {
        ...state,
        pendingProposal: { ...state.pendingProposal, isLoading: true }
      };
    }
    case ProposalActionTypes.GET_PENDING_PROPOSAL_SUCCESS: {
      return {
        ...state,
        pendingProposal: { ...state.pendingProposal, data: action.payload, isLoading: false }
      };
    }
    case ProposalActionTypes.SUBMIT_PENDING_PROPOSAL_ERROR:
    case ProposalActionTypes.GET_PENDING_PROPOSAL_ERROR: {
      return {
        ...state,
        pendingProposal: { ...state.pendingProposal, error: action.payload, isLoading: false }
      };
    }

    case ProposalActionTypes.SUBMIT_PENDING_PROPOSAL_SUCCESS: {
      return {
        ...state,
        pendingProposal: { ...state.pendingProposal, bankData: action.payload.updated }
      };
    }

    case ProposalActionTypes.SHOWCASE_CHOOSED_CARD: {
      return {
        ...state,
        choosedProposalCard: action.payload,
        updatedProposal: false,
        pendingProposal: { ...state.pendingProposal, bankData: false }
      };
    }
    case ProposalActionTypes.UPDATE_PROPOSAL_SUCCESS: {
      return {
        ...state,
        updatedProposal: true
      };
    }
    case ProposalActionTypes.SET_PAYMENT_PLAN_SELECTED: {
      return {
        ...state,
        paymentPlanSelected: action.payload
      };
    }
    case ProposalActionTypes.CLEAR_PAYMENT_PLAN_SELECTED: {
      return {
        ...state,
        paymentPlanSelected: initialProposalState.paymentPlanSelected
      };
    }
    case ProposalActionTypes.SET_PROPOSAL_PENDENCY_BIOMETRY_FLOW_ENABLED: {
      return {
        ...state,
        isProposalPendencyBiometryFlowEnabled: action.payload
      };
    }

    default:
      return state;
  }
}

export function checklistReducer(action: GetChecklistSuccessAction): Partial<ProposalState> {
  const checklist: ChecklistModel[] = action.payload;
  const showDownloadLink = checklist.some(
    item =>
      item.document.docCode !== ChecklistDocumentEnum.CONTRACT_STORE_DOC &&
      (item.type.code === ChecklistItemTypeEnum.CONTRACT_DOWNLOAD ||
        item.type.code === ChecklistItemTypeEnum.CONTRACT_UPLOAD)
  );

  const showDownloadContract = checklist.some(
    item =>
      item.document.docCode === ChecklistDocumentEnum.CONTRACT_DOC &&
      item.type.code === ChecklistItemTypeEnum.CONTRACT_UPLOAD
  );

  const biometryChecklistData = checklist.filter(
    item =>
      item.type.code === ChecklistItemTypeEnum.FACIAL ||
      item.type.code === ChecklistItemTypeEnum.SIGNATURE ||
      item.type.code === ChecklistItemTypeEnum.ANTIFRAUD
  );

  const uploadChecklistData = checklist.filter(
    item =>
      item.type.code === ChecklistItemTypeEnum.UPLOAD ||
      item.type.code === ChecklistItemTypeEnum.CONTRACT_DOWNLOAD ||
      item.document.docCode === ChecklistDocumentEnum.CONTRACT_STORE_DOC
  );

  return {
    checklists: checklist,
    showDownloadLink,
    showDownloadContract,
    biometryChecklist: {
      data: biometryChecklistData,
      isLoading: false
    },
    uploadChecklist: {
      data: uploadChecklistData,
      isLoading: false
    }
  };
}

//CHECKLIST MOBILE
export function checklistMobileReducer(action: GetChecklistMobileSuccessAction): Partial<ProposalState> {
  const checklistMobile: ChecklistModelMobile[] = action.payload;
  // const showDownloadLink = checklist.some(
  //   item =>
  //     item.document.docCode !== ChecklistDocumentEnum.CONTRACT_STORE_DOC &&
  //     (item.type.code === ChecklistItemTypeEnum.CONTRACT_DOWNLOAD ||
  //       item.type.code === ChecklistItemTypeEnum.CONTRACT_UPLOAD)
  // );

  // const showDownloadContract = checklist.some(
  //   item =>
  //     item.document.docCode === ChecklistDocumentEnum.CONTRACT_DOC &&
  //     item.type.code === ChecklistItemTypeEnum.CONTRACT_UPLOAD
  // );

  const biometryChecklistMobileData = checklistMobile.filter(
    item =>
      item.type.code === ChecklistItemTypeEnum.FACIAL ||
      item.type.code === ChecklistItemTypeEnum.SIGNATURE ||
      item.type.code === ChecklistItemTypeEnum.ANTIFRAUD
  );

  const uploadChecklisMobiletData = checklistMobile.filter(
    item =>
      item.type.code === ChecklistItemTypeEnum.UPLOAD ||
      item.type.code === ChecklistItemTypeEnum.CONTRACT_DOWNLOAD ||
      item.document.docCode === ChecklistDocumentEnum.CONTRACT_STORE_DOC
  );

  return {
    checklistsMobile: checklistMobile,
    biometryChecklistMobile: {
      data: biometryChecklistMobileData,
      isLoading: false
    },
    uploadChecklistMobile: {
      data: uploadChecklisMobiletData,
      isLoading: false
    }
  };
}
