import { IdentificationService } from '@app/identification/services/identification.service';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TermsModalComponent } from '../terms-modal/terms-modal.component';
import { Tag } from '@app/tagging/tagger.directive';

@Component({
  selector: 'app-terms-and-conditions',
  templateUrl: './terms-and-conditions.component.html',
  styleUrls: ['./terms-and-conditions.component.scss']
})
export class TermsAndConditionsComponent {
  @Input() termsAndConditions: string;
  @Input() formValid = true;
  @Output() checkedOutput = new EventEmitter<boolean>();

  readonly category = '/portallojista/criarproposta';
  readonly Selecionar: Tag = Tag.Selecionar;

  public scrollComplete = false;
  public checked: boolean;

  constructor(private formChangeEvent: IdentificationService, private dialog: MatDialog) {
    this.formChangeEvent.formChangeEvent.subscribe(changed => {
      this.checked = changed;
    });
  }

  public handleScroll(event: any): void {
    const target = event.target;
    if (target.scrollTop + target.offsetHeight > target.scrollHeight - 1) {
      this.scrollComplete = true;
    }
  }

  handleTermsAndAgreementsCheckboxChange(checked: boolean): void {
    this.checkedOutput.emit(checked);
    this.checked = checked;
  }

  public openTerms() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.id = 'pion-no-padding-dialog';
    dialogConfig.maxWidth = '600px';
    dialogConfig.height = '90%';
    dialogConfig.autoFocus = false;

    dialogConfig.data = [{ description: this.termsAndConditions }];

    const modalRef = this.dialog.open(TermsModalComponent, dialogConfig);

    modalRef.componentInstance.accept.subscribe(() => {
      this.scrollComplete = true;
      this.handleTermsAndAgreementsCheckboxChange(true);
      modalRef.close();
    });

    modalRef.componentInstance.skip.subscribe(() => {
      modalRef.close();
    });
  }
}
