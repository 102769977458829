<div class="wrapper">
  <!-- 1/5 -->
  <div>
    <installment-step #installmentCmpt *ngIf="installmentStepData"
      (validatorsEmitter)="selectedValidatorsEmitter($event)" (inputChanged)="simulationChanged($event)"
      [installmentStepData]="installmentStepData" [proposalData]="proposalData" [category]="category">
    </installment-step>
    <div class="circle" (click)="scrollRegistrationFee()" appTagger [event_category]="category"
      event_label="próximo passo">
      <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
    </div>
  </div>

  <!-- 2/5 -->
  <hr class="divider" *ngIf="registrationFeeData && registrationFeeData?.hasTariff" />
  <div #registrationFeeDiv [hidden]="!registrationFeeData?.hasTariff">
    <app-registration-fee *ngIf="registrationFeeData && registrationFeeData?.hasTariff"
      [registrationFeeData]="registrationFeeData" (selectedFeeOptionEmitter)="simulationChanged($event)"
      [category]="category">
    </app-registration-fee>
    <div class="circle" (click)="scrollInsurance()" *ngIf="registrationFeeData && registrationFeeData?.hasTariff"
      appTagger [event_category]="category" event_label="próximo passo">
      <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
    </div>
  </div>

  <!-- 3/5 -->
  <hr class="divider" *ngIf="insuranceStepData && insuranceStepData?.showInsuranceStep" />
  <div #insuranceDiv [hidden]="!insuranceStepData?.showInsuranceStep">
    <insurance-step *ngIf="insuranceStepData && insuranceStepData?.showInsuranceStep"
      [insuranceStepData]="insuranceStepData" (insuranceChangeEmitter)="simulationChanged($event)"
      [category]="category"></insurance-step>
    <div class="circle" (click)="scrollDueDate()" *ngIf="insuranceStepData && insuranceStepData?.showInsuranceStep"
      appTagger [event_category]="category" event_label="próximo passo">
      <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
    </div>
  </div>

  <!-- 4/5 -->
  <hr class="divider" *ngIf="dueDateData" />
  <div #dueDateDiv>
    <app-due-date *ngIf="dueDateData" (addForm)="addForm($event)" [dueDateData]="dueDateData"
      [dueDaysIntervalMax]="dueDaysIntervalMax" [dueDaysIntervalMin]="dueDaysIntervalMin"
      [defaultDaysToInitialPayment]="defaultDaysToInitialPayment" (changeDateEmitter)="simulationChanged($event)"
      [category]="category">
    </app-due-date>
    <div class="circle" (click)="scrollPayment()" appTagger [event_category]="category" event_label="próximo passo">
      <img src="./assets/images/ic_arrow_down.svg" class="img-icon" alt="" />
    </div>
  </div>

  <!-- 5/5 -->
  <hr class="divider" *ngIf="paymentData" />
  <div #paymentDiv>
    <payment-step #paymentStepCmpt *ngIf="paymentData" (addForm)="addForm($event)" [paymentData]="paymentData"
      [othersBanks]="othersBanks" [confirmationData]="confirmationData"
      (paymentChangeEmitter)="simulationChanged($event)" (accountChanged)="accountChangedHandler($event)"
      [category]="category"></payment-step>
  </div>

  <button class="btn-submit" mat-raised-button color="primary" (focus)="removeFocusSubmitBtn()" (click)="goSumary()"
    [disabled]="enableSubmitBtn()" appTagger [event_category]="category" event_label="Continuar">
    Continuar
  </button>
</div>