import { FocusMonitor } from '@angular/cdk/a11y';
import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self
} from '@angular/core';
import { FormControl, NgControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: CustomInputComponent }]
})
export class CustomInputComponent implements OnInit, MatFormFieldControl<any>, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() type: 'text' | 'number' = 'text';
  @Input() customMask = null;
  @Input() currencyMask = null;
  @Input() hiddenInput = false;
  @Input() patterns = null;
  @Input() maxlength = null;
  @Input() minlength = null;
  @Input() toolTipText = null;
  @Input() prefix = '';
  @Input() customPatterns = '';
  @Input() required = false;
  @Input() disableSpecialCharacters = false;
  @Output() focus = new EventEmitter<FocusEvent>();
  @Output() blur = new EventEmitter<FocusEvent>();

  value: any;
  stateChanges = new Subject<void>();
  placeholder: string;
  focused: boolean;
  disabled: boolean;
  controlType?: string;
  autofilled?: boolean;

  setDescribedByIds(ids: string[]): void {}
  setDisabledState?(isDisabled: boolean): void {}
  // tslint:disable-next-line: member-ordering
  onToutch: () => void;
  // tslint:disable-next-line: member-ordering
  onChange: (value: any) => void;

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onToutch = fn;
  }

  // tslint:disable-next-line: member-ordering
  static nextId = 0;

  // tslint:disable-next-line: member-ordering
  @HostBinding() id = `app-custom-input-${CustomInputComponent.nextId++}`;

  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private fm: FocusMonitor,
    private elRef: ElementRef<HTMLElement>,
    private errorMatcher: ErrorStateMatcher
  ) {
    if (this.ngControl != null) this.ngControl.valueAccessor = this;
  }

  ngOnInit() {
    this.fm.monitor(this.elRef.nativeElement, true).subscribe(origin => {
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl.control.value != null) {
      this.ngControl.control.markAsDirty();
    }

    this.ngControl.control.valueChanges.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      if (!!res) {
        this.ngControl.control.markAsDirty();
      }
    });
  }

  detectCharacters(e: KeyboardEvent) {
    if (this.disableSpecialCharacters) {
      // tslint:disable-next-line: deprecation
      const chr = String.fromCharCode(e.which);
      if ('qwertyuioplkjhgfdsaãzxcvbnñmQWERTYUIOPLKJHGFDSAZXCVBNM '.indexOf(chr) < 0) {
        return false;
      }
    }
  }

  get empty() {
    const { value } = this.ngControl;
    return value === null || value === '';
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input').focus();
    }
  }

  get errorState() {
    return this.errorMatcher.isErrorState(this.ngControl.control as FormControl, null);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
