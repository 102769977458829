import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import { selectProduct } from '@app/core/state/identification/identification.selectors';
import { homePageSelector } from '@app/core/state/profile/profile.selectors';
import * as fromProposal from '@app/core/state/proposal';
import * as fromProposalInformation from '@app/core/state/proposal-information';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import { ResetShowcaseAction } from '@app/core/state/showcase/showcase.actions';
import * as fromSimulation from '@app/core/state/simulation';
import { GetSimulationParameters, GetStatusProposals, SimulationActionTypes } from '@app/core/state/simulation/simulation.actions';
import { recalculateNpp, selectAllOffers, selectStatusProposal } from '@app/core/state/simulation/simulation.selectors';
import { InitialScreenOptions } from '@app/profile/interface/initial-screen-options.enum';
import { ErrorModalComponent } from '@app/proposal-workflow/components/error-modal/error-modal.component';
import { ErrorMessage } from '@app/proposal-workflow/models/error-message.model';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { Actions, ofType } from '@ngrx/effects';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { Subject } from 'rxjs';
import { delay, filter, skip, take, takeUntil, withLatestFrom } from 'rxjs/operators';
import { Offers, SimulationOffer } from './model/offer.model';

@Component({
  selector: 'app-step-offers-npp',
  templateUrl: './step-offers-npp.component.html',
  styleUrls: ['./step-offers-npp.component.scss']
})
export class StepOffersNppComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  proposal: ConfirmationData;
  offers: Offers;
  allOffers: any[];
  insuranceOffers: SimulationOffer[] = [];
  noInsuranceOffers: SimulationOffer[] = [];
  simulationOffer: SimulationOffer[] = [];
  public proposalId: string;
  public processing = '';

  public isMobile: boolean;
  public innerWidth: any;
  public MOBILE_BREAKPOINT = 768;

  public productCode = ProductCodeEnum;

  public category: string;

  constructor(
    private readonly store$: Store<AppState>,
    private updates$: Actions,
    public dialog: MatDialog,
    public router: Router,
    private translateService: TranslateService,
    private genTagger: GenTagger,
    private taggerService: TaggerService
  ) {
    this.innerWidth = window.screen.width;
    this.isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;

    this.store$
      .pipe(
        select(recalculateNpp),
        takeUntil(this.ngUnsubscribe),
        filter(data => !!data)
      )
      .subscribe(state => {
        if (!!state) {
          this.getOffer();
        }
      });
  }

  ngOnInit() {
    this.getCurrentProposalId();
    this.selectOffers();

    this.updates$
      .pipe(
        ofType(SimulationActionTypes.GET_ALL_OFFERS_SUCCESS),
        skip(1)
      )
      .subscribe(() => {
        this.selectOffers();
      });

    this.store$
      .pipe(
        select(getConfirmationData),
        filter(data => !!data),
        take(1)
      )
      .subscribe(state => {
        if (state.proposalData?.product) {

        this.category = `/portallojista/criarproposta/${state.proposalData.product}/ofertas`;
        this.taggerService.setProposalType(state.proposalData.product);
        this.genTagger.setTag({
          event_category: this.category,
          event_label: `Melhor oferta - ${state.proposalData.product}`,
          event_action: Tag.pgView
        });
        }
      });
  }

  public selectOffers() {
    this.store$
      .select(selectAllOffers)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: any) => {
        if (state) {
          if (state.messages && state.messages.length > 0) {
            this.processing = state?.messages.find(res => res?.message == 'PROCESSING')?.message;
            if (this.processing === 'PROCESSING') {
              setTimeout(() => {
                this.getStatusProposals();
              }, 1000)

              return;
            }
            this.handleErrorMessages(state.messages, state.offers);
          }
          if (state.offers) {
            this.allOffers = state.offers;

            // Sorting offers by priority
            this.allOffers.sort((a, b) => {
              return a.priority - b.priority;
            });

            // Set offerTitle in simulationOffer array
            this.simulationOffer = this.allOffers.map(offer => {
              offer.simulationOffer.offerTitle = offer.offerName;
              return offer.simulationOffer;
            });

            // Set value of proposal.financedValue when financed value was changed
            this.proposal.financedValue = this.allOffers[0].financedValue;
          }
        }
      });
  }

  private getStatusProposals() {
    this.store$
      .select(selectProposalId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(proposalId => !!proposalId)
      )
      .subscribe((id: string) => {
        this.proposalId = id;
      });

    if (!!this.proposalId) {
      const payload = {
        simulationId: this.proposalId,
        redirectProposal: true
      }
      this.store$.dispatch(new GetStatusProposals(payload));
      this.store$.select(selectStatusProposal).pipe(delay(1000)).subscribe(res => {

        if (res.status === 'COMPLETED' && this.router.url === '/proposal/step-offer') {
          this.getOffer();

          setTimeout(() => {
            this.selectOffers();
          }, 4000)

        }
      })
    }
  }

  public getProposalInformationData() {
    this.store$.dispatch(new fromProposalInformation.actions.GetConfirmationData(this.proposalId));
    this.store$
      .select(getConfirmationData)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: ConfirmationData) => {
        if (state) {
          this.proposal = state;
        }
      });
  }

  public getCurrentProposalId() {
    this.store$
      .select(selectProposalId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        withLatestFrom(this.store$.select(selectProduct)),
        filter(arr => arr.every(Boolean))
      )
      .subscribe(([proposalId, product]) => {
        this.proposalId = proposalId as string;
        this.getProposalInformationData();
        this.taggerService.setProposalType(product.code)
        this.taggerService.setPageLocation(`/portallojista/criarproposta/${product?.code?.toLowerCase()}/ofertas`)
        this.store$.dispatch(new GetSimulationParameters({ proposalId }));
      });
  }

  public getOffer() {
    this.store$.dispatch(new fromSimulation.actions.GetAllOffers(parseInt(this.proposal.proposalData.id)));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.clearProposalData();
  }

  private clearProposalData(): void {
    if (this.router.url !== '/proposal/step-simulation') {
      this.store$.dispatch(new fromProposal.actions.ClearChecklistAction());
      this.store$.dispatch(new fromProposal.actions.ClearProposalStageAction());
      this.store$.dispatch(new fromProposal.actions.ClearProposalAction());
      this.store$.dispatch(new fromSimulation.actions.ClearAll());
      this.store$.dispatch(new fromProposalInformation.actions.ClearProposalInformationAction());
      this.store$.dispatch(new fromProposal.actions.ClearProposalIdAction());
      this.store$.dispatch(new ResetShowcaseAction());
    }
  }

  private handleErrorMessages(messages: ErrorMessage[], offers: Array<any>): void {
    if (!!this.dialog.getDialogById('npp-offer-error')) return;

    const sub = this.dialog
      .open(ErrorModalComponent, {
        id: 'npp-offer-error',
        data: {
          errorIcon: 'cancel',
          errorTitle: messages[0].title,
          errorText: messages[0].message,
          errorCode: messages[0].description,
          errorButton: 'OK'
        },
        maxWidth: '80vw',
        width: '500px'
      })
      .afterClosed()
      .pipe(withLatestFrom(this.store$.select(homePageSelector)))
      .subscribe(([_, homePage]) => {
        if (offers.length === 0) {
          if (homePage === InitialScreenOptions.Panel) {
            this.router.navigate(['/showcase/open-proposal']);
          } else {
            this.router.navigate(['/identification']);
          }
        }

        sub.unsubscribe();
      });
  }
}
