import { Action } from '@ngrx/store';

export enum NotificationsActionTypes {
  GET_NOTIFICATIONS = '[Notifications] Get notifications',
  GET_NOTIFICATIONS_SUCCESS = '[Domains] Get notifications success',
  GET_NOTIFICATIONS_ERROR = '[Domains] Get notifications error',

  DELETE_ALL_NOTIFICATIONS = '[Notifications] Delete all notifications',
  DELETE_NOTIFICATION = '[Notifications] Delete notification',

  MARK_ALL_NOTIFICATIONS_READ = '[Notifications] Mark all notifications read',
  MARK_NOTIFICATION_READ = '[Notifications] Mark notification read'
}

export class GetNotifications implements Action {
  readonly type = NotificationsActionTypes.GET_NOTIFICATIONS;
  constructor() {}
}

export class GetNotificationsSuccess implements Action {
  readonly type = NotificationsActionTypes.GET_NOTIFICATIONS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetNotificationsError implements Action {
  readonly type = NotificationsActionTypes.GET_NOTIFICATIONS_ERROR;
  constructor(public payload: any) {}
}

export class DeleteAllNotifications implements Action {
  readonly type = NotificationsActionTypes.DELETE_ALL_NOTIFICATIONS;
  constructor() {}
}

export class DeleteNotification implements Action {
  readonly type = NotificationsActionTypes.DELETE_NOTIFICATION;
  constructor(public payload: any) {}
}

export class MarkAllNotificationsRead implements Action {
  readonly type = NotificationsActionTypes.MARK_ALL_NOTIFICATIONS_READ;
  constructor() {}
}

export class MarkNotificationRead implements Action {
  readonly type = NotificationsActionTypes.MARK_NOTIFICATION_READ;
  constructor(public payload: any) {}
}

export type NotificationsActions =
  | GetNotifications
  | GetNotificationsSuccess
  | DeleteAllNotifications
  | DeleteNotification
  | MarkAllNotificationsRead
  | MarkNotificationRead
  | GetNotificationsError;
