<ng-template #contentModal let-c="close" let-d="dismiss">
  <div class="modal-header" *ngIf="header">
    <h4 class="modal-title">{{ title | translate }}</h4>
    <button type="button" class="close" (click)="modalClose()" *ngIf="closable">
      <span class="text-white" aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <ng-content select="[body]"></ng-content>
  </div>

  <div class="modal-footer" hidden>
    <button class="btn btn-secundario btn-principal-sm" (click)="modalClose()">
      <span class="fa fa-angle-left" aria-hidden="true"></span>
      {{ 'Back' | translate }}
    </button>

    <ng-content select="[footer]"></ng-content>
  </div>
</ng-template>
