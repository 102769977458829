import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GenTagger } from './gen-tagger';
import { TaggerService } from './tagger.service';
import { RequestEventTag } from './tagging-iface';

@Injectable()
export class TaggerInterceptor implements HttpInterceptor {
  private origin = environment.env === 'PRD' ? window.location.origin : environment.urlBase;

  constructor(private router: Router, private genTagger: GenTagger, private taggerService: TaggerService) {}

  /**
   * Intercept API calls, validate if should be interceptable and set the values to tag
   * @date 11/22/2023
   * @author lilian.n.silva
   *
   * @param {HttpRequest<unknown>} request
   * @param {HttpHandler} next
   * @returns {Observable<HttpEvent<unknown>>}
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const startTime = performance.now();
    return next.handle(request).pipe(
      tap(
        (response: HttpResponse<any>): void => {
          if (!(response instanceof HttpResponse) || !response.url) {
            return;
          }

          this.sendToTag(response, startTime);
        },
        (error: HttpErrorResponse) => {
          this.sendToTag(error, startTime);
        }
      )
    );
  }

  private sendToTag(response, startTime) {
    const duration = performance.now() - startTime;
    const dataToTag: RequestEventTag = {
      event_type: 'request',
      event_nointeraction: true,
      event_action: 'request',
      request_initiator: 'xhr',
      page_location: `${this.findAndReplaceUrl(this.router.routerState.snapshot.url)}`,
      request_status: response.status,
      request_time: Math.floor(duration),
      request_url: response.url,
      event_label: this.createEventLabel(response.url),
      request_address: this.origin
    };
    this.genTagger.setTag(dataToTag);
  }

  private createEventLabel(url) {
    const splitUrl = url.split(this.origin);
    if (splitUrl.length <= 2) {
      return splitUrl.length > 1 ? splitUrl[1] : splitUrl[0];
    } else {
      return url;
    }
  }

  private findAndReplaceUrl(input: string) {
    const page_location = this.taggerService.getPageLocation();
    if (page_location) return page_location;
    const type = this.taggerService.getProposalType();
    const addedType = type ? `${type}/` : '';
    const urls = [
      { url: '/identification', new: '/portallojista/criarproposta' },
      { url: '/proposal/step-offer', new: `/portallojista/criarproposta/${addedType}ofertas` },
      { url: '/proposal/step-register', new: `/portallojista/criarproposta/${addedType}cadastro` },
      { url: '/proposal/step-simulation', new: `/portallojista/criarproposta/${addedType}simulacao` },
      { url: '/proposal/step-confirmation', new: `/portallojista/criarproposta/${addedType}confirmacao` },
      { url: '/proposal/step-formalization', new: `/portallojista/criarproposta/${addedType}formalizacao` }
    ];
    const replaceable = urls.find(found => found.url === input.split('?')[0]);
    if (replaceable) return replaceable.new;
    return input;
  }
}
