import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SummaryModel, SummaryStageModel } from '@app/proposal-workflow/models/summary.model';
import {
  FormalizationProposalDTO,
  GetFormalizationProposalListResDTO
} from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { CONFIG_PROFILE_QUERY } from '@shared/graphql/queries/config.query';
import { SEND_TYPE_QUERY } from '@shared/graphql/queries/domains.query';
import { GET_PROPOSAL_BY_ID, PROPOSAL_CHECKLISTS } from '@shared/graphql/queries/proposals.query';
import { Apollo } from 'apollo-angular';
import { ExecutionResult } from 'apollo-link';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  ChecklistDocumentEnum,
  ChecklistItemDTO,
  ChecklistItemTypeEnum
} from '../components/checklist/models/api/query.checklist.model';
import { FormalizationStepsService } from './formalization-steps.service';

const mapProposal = (response: ExecutionResult): FormalizationProposalDTO => {
  if (!response.data) return;
  const res = response.data as GetFormalizationProposalListResDTO;
  return res.getProposalById;
};

@Injectable({
  providedIn: 'root'
})
export class FormalizationService {
  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private formalizationStepsService: FormalizationStepsService,
    private translateService: TranslateService
  ) {}

  public getProposal(proposalId: number): Observable<FormalizationProposalDTO> {
    return this.apollo
      .watchQuery({
        query: GET_PROPOSAL_BY_ID,
        variables: {
          proposalId: proposalId
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(map((response: ExecutionResult) => mapProposal(response)));
  }

  public getMaxFileSizeAllowed(): Observable<any> {
    return this.apollo
      .watchQuery({
        query: CONFIG_PROFILE_QUERY,
        variables: { profile: ['maximumSizeDocument'] },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: ExecutionResult) => {
          if (!response.data) return;
          const res = response.data.configProfiles[0];
          return res.value;
        })
      );
  }

  public getSendType(): Observable<any> {
    return this.apollo
      .watchQuery({
        query: SEND_TYPE_QUERY,
        variables: {},
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: ExecutionResult) => {
          if (!response.data) return;
          const res = response.data.proposalRegisterRender.sendType;
          return res;
        })
      );
  }

  public getChecklist(payload: { proposalId: any; isPendency: boolean }): Observable<ChecklistItemDTO[]> {
    return this.apollo
      .watchQuery({
        query: PROPOSAL_CHECKLISTS,
        variables: {
          proposalId: payload.proposalId,
          reused: false,
          isPendency: false
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: ExecutionResult) => {
          if (!response.data) return;
          const checklist: ChecklistItemDTO[] = response.data.listCheckValidate;
          return checklist;
        })
      );
  }

  public finishFormalization(payload: any): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-formalization/finalize`;
    return this.http.post<any>(endpoint, payload);
  }

  public emitBillet(proposalId: any): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-structure/proposal/${proposalId}/carnet/tc/emit`;
    return this.http.put<any>(endpoint, null);
  }

  public getSummaryStatus(proposalId): Observable<any> {
    const params = '?enableColorHints=true&showAll=true';
    const endpoint = `${environment.apiRootUrl}/shopkeeper-structure/1.0.0/stageProposal/${proposalId}/macro-stages${params}`;
    return this.http.get<SummaryStageModel>(endpoint);
  }

  public getBipartite(): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-formalization/1.0.0/bipartite`;
    return this.http.get<boolean>(endpoint);
  }

  public sendMail(payload: any): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-formalization/email`;
    return this.http.post<any>(endpoint, payload);
  }
  public sendSMS(payload: any): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-formalization/sms`;
    return this.http.post<any>(endpoint, payload);
  }

  public getShowIntegraLgpdFlag(proposalId: number): Observable<boolean> {
    const endpoint = `${environment.apiRootUrl}/signature/partners/showLgpdSection/${proposalId}`;
    return this.http.get<boolean>(endpoint);
  }

  public getSummarySteps(
    productCode: string,
    stageCode: number,
    checklists: ChecklistItemDTO[]
  ): Observable<SummaryModel[]> {
    let summarySteps: SummaryModel[] = null;

    // Steps for product CDC
    if (productCode === ProductCodeEnum.CDC) {
      switch (true) {
        case stageCode === 33 && this.getNF(checklists):
          this.formalizationStepsService.getC3Steps().subscribe(res => (summarySteps = res));
          break;

        case stageCode === 33 && !this.getNF(checklists):
          this.formalizationStepsService.getB2Steps().subscribe(res => (summarySteps = res));
          break;

        case stageCode === 7 && this.getExemptionRegistrationFee(checklists):
          this.formalizationStepsService.getC2Steps().subscribe(res => (summarySteps = res));
          break;

        case stageCode === 14:
          this.formalizationStepsService.getC1Steps().subscribe(res => (summarySteps = res));
          break;

        default:
          this.formalizationStepsService.getB1Steps().subscribe(res => (summarySteps = res));
          break;
      }
    }

    // Steps for product CSC ou CSCP
    if (productCode === ProductCodeEnum.CSC || productCode === ProductCodeEnum.CSCP) {
      switch (true) {
        case stageCode === 33 && this.getNF(checklists):
          this.formalizationStepsService.getC3Steps().subscribe(res => (summarySteps = res));
          break;

        case stageCode === 33 && !this.getNF(checklists):
          this.formalizationStepsService.getB2Steps().subscribe(res => (summarySteps = res));
          break;

        case stageCode === 7 && this.getExemptionRegistrationFee(checklists):
          this.formalizationStepsService.getC2Steps().subscribe(res => (summarySteps = res));
          break;

        case stageCode === 14:
          this.formalizationStepsService.getC1Steps().subscribe(res => (summarySteps = res));
          break;

        case this.getStoreContract(checklists):
          this.formalizationStepsService.getD1Steps().subscribe(res => (summarySteps = res));
          break;

        case this.getContractUpload(checklists):
          this.formalizationStepsService.getB2Steps().subscribe(res => (summarySteps = res));
          break;

        case this.getContractDownload(checklists):
          this.formalizationStepsService.getD2Steps().subscribe(res => (summarySteps = res));
          break;

        case this.getWithoutContract(checklists):
          this.formalizationStepsService.getD3Steps().subscribe(res => (summarySteps = res));
          break;

        default:
          break;
      }
    }
    return of(summarySteps);
  }

  private getExemptionRegistrationFee(checklists: ChecklistItemDTO[]): boolean {
    return checklists.some(checklist => {
      return (
        checklist.document.docCode === ChecklistDocumentEnum.SEARCH_DEBT_DOC ||
        checklist.document.docCode === ChecklistDocumentEnum.SEARCH_PROTEST_DOC ||
        checklist.document.docCode === ChecklistDocumentEnum.SEARCH_STATUS_DOC ||
        checklist.document.docCode === ChecklistDocumentEnum.INCOME_PROOF_DOC
      );
    });
  }

  private getStoreContract(checklists: ChecklistItemDTO[]): boolean {
    return checklists.some(checklist => checklist.document.docCode === ChecklistDocumentEnum.CONTRACT_STORE_DOC);
  }

  private getNF(checklists: ChecklistItemDTO[]): boolean {
    return checklists.some(checklist => checklist.document.docCode === ChecklistDocumentEnum.INVOICE);
  }

  private getContractDownload(checklists: ChecklistItemDTO[]): boolean {
    return checklists.some(checklist => checklist.type.code === ChecklistItemTypeEnum.CONTRACT_DOWNLOAD);
  }

  private getContractUpload(checklists: ChecklistItemDTO[]): boolean {
    return checklists.some(checklist => {
      return checklist.type.code === ChecklistItemTypeEnum.CONTRACT_UPLOAD;
    });
  }

  private getWithoutContract(checklists: ChecklistItemDTO[]): boolean {
    return !checklists.some(checklist => {
      return (
        checklist.document.docCode === ChecklistDocumentEnum.CONTRACT_DOC ||
        checklist.document.docCode === ChecklistDocumentEnum.CONTRACT_STORE_DOC
      );
    });
  }
}
