import { MatDialogRef } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'cashback-benefits',
  templateUrl: './cashback-benefits.component.html',
  styleUrls: ['./cashback-benefits.component.scss']
})
export class CashbackBenefitsComponent {
  cashbackDetailsVisible = false;
  public show = false;
  public in = false;

  disableBtn = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<CashbackBenefitsComponent>) {}

  expandDetailsCashback() {
    this.show ? (this.in = true) : (this.in = false);
    this.show = !this.show;
    this.cashbackDetailsVisible = !this.cashbackDetailsVisible;
  }

  public closeModal(): void {
    this.dialogRef.close({});
  }

  public handleScroll(event) {
    const target = event.target;
    if (target.scrollTop + target.offsetHeight > target.scrollHeight - 1) {
      this.disableBtn = false;
    }
  }
}
