import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { AppState } from '@app/core/state';
import { GetStoresAction } from '@app/core/state/showcase/showcase.actions';
import { storesList } from '@app/core/state/showcase/showcase.selectors';
import { Store } from '@ngrx/store';
import { AddressModel } from '@shared/models/addresss.model';
import { PhoneModel } from '@shared/models/phone.model';
import { StoreModel } from '@shared/models/store.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FiltersModel } from '../models/filters.model';

@Component({
  selector: 'app-stores-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})
export class ListComponent implements OnInit, OnDestroy {
  @Output() selectedStore = new EventEmitter<StoreModel>();
  protected ngUnsubscribe: Subject<any> = new Subject();
  public stores: StoreModel[] = [];

  constructor(private store: Store<AppState>) {}

  ngOnInit() {
    this.loadStores();
  }

  /**
   * Load stores to show in list
   * @param filters (Optional) Object of Filters
   */
  loadStores(filters?: FiltersModel) {
    this.store
      .select(storesList)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(list => (this.stores = list));

    this.store.dispatch(new GetStoresAction(filters));
  }

  /**
   * Builder full address
   * @param address Object of Address
   */
  getAddress(address: AddressModel): string {
    if (address.complement) {
      return `${address.street}, ${address.number}, ${address.complement} - ${address.neighborhood}`;
    }
    return `${address.street}, ${address.number} - ${address.neighborhood}`;
  }

  /**
   * Builder full phone number
   * @param phone Object of Phone
   */
  getPhone(phone: PhoneModel): string {
    if (phone.number.startsWith('0800') || phone.number.startsWith('0300') || phone.number.startsWith('400')) {
      return phone.number;
    }
    return `(${phone.regionalCode}) ${phone.number}`;
  }

  /**
   * Select Store
   * @param store Object of Store
   */
  selectStore(store: StoreModel) {
    this.selectedStore.emit(store);
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
