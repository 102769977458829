import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alert-box.component.html',
  styleUrls: ['./alert-box.component.scss']
})
export class AlertBoxComponent implements OnInit {
  @Input() alerts: Array<any>;
  @Output() closeAlert = new EventEmitter();

  constructor(private translateService: TranslateService) {}

  ngOnInit() {}

  onCloseAlert(alert) {
    this.closeAlert.emit(alert);
  }
}
