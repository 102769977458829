import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContractsComponent } from './contracts.component';
import { ContractsRoutingModule } from './contracts-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { ContractCardComponent } from './components/contract-card/contract-card.component';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [ 
    ContractsComponent,
    ContractCardComponent
  ],
  imports: [
    CommonModule,
    ContractsRoutingModule,
    TranslateModule,
    SharedModule,
  ],
  exports: [
    ContractCardComponent
  ],
  bootstrap: [ContractsComponent],
})
export class ContractsModule { }
