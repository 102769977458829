import { ProposalWorkflowModule } from '@app/proposal-workflow/proposal-workflow.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AttachPendingDocumentsComponent } from './components/attach-pending-documents/attach-pending-documents.component';
import { PendencyPaymentErrorComponent } from './components/pendency-payment-error/pendency-payment-error.component';
import { PendencySummaryComponent } from './components/pendency-summary/pendency-summary.component';
import { PendencyComponent } from './pendency.component';
import { PendencyUpdateRegisterComponent } from './components/pendency-update-register/pendency-update-register.component';
import { SharedModule } from '@shared/shared.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DocumentScanModule } from '@app/document-scan/document-scan.module';
import { NgxCurrencyModule } from 'ngx-currency';
import { PendencySuccessComponent } from './components/pendency-success/pendency-success.component';
import { PendencyBipartiteComponent } from './components/pendency-bipartite/pendency-bipartite.component';
import { PendencyUpdateBpoComponent } from './components/pendency-update-bpo/pendency-update-bpo.component';

@NgModule({
  declarations: [
    AttachPendingDocumentsComponent,
    PendencyPaymentErrorComponent,
    PendencySummaryComponent,
    PendencyUpdateRegisterComponent,
    PendencyComponent,
    PendencySuccessComponent,
    PendencyBipartiteComponent,
    PendencyUpdateBpoComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProposalWorkflowModule,
    DocumentScanModule,
    MatTooltipModule,
    MatInputModule,
    MatFormFieldModule,
    MatIconModule,
    NgxCurrencyModule,
    MatCardModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatExpansionModule,
    MatDividerModule,
    MatSidenavModule,
    MatListModule,
    MatCheckboxModule,
    MatDialogModule,
    MatSelectModule,
    MatProgressBarModule
  ]
})
export class PendencyModule {}
