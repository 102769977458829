<app-side-content [name]="initialScreenName" [show]="true">
  <div class="container" *ngIf="!showUpdateSuccess; else updateSuccess">
    <div class="row">
      <div class="col-11">
        <p class="title">{{ 'SET-INITIAL-SCREEN' | translate }}</p>
      </div>
    </div>

    <form [formGroup]="initialScreenForm" (ngSubmit)="sendInitialScreenOption()">
      <p>
        <strong>{{ 'INITIAL-SCREEN' | translate }}</strong>
      </p>

      <!-- MOBILE BEGIN -->
      <mat-radio-group class="mobile" formControlName="initialScreen">
        <mat-card
          class="card-outline"
          [ngClass]="initialScreenForm.value.initialScreen === initialScreenOptions.Panel ? 'card-active' : ''"
          (click)="setSelectedOption(initialScreenOptions.Panel)"
        >
          <div class="row card-content">
            <div class="mobile-column-one ">
              <div class="col-md-1">
                <mat-radio-button
                  [value]="initialScreenOptions.Panel"
                  [checked]="initialScreenForm.value.initialScreen === initialScreenOptions.Panel"
                >
                  {{ 'PANEL' | translate }}
                </mat-radio-button>
              </div>
            </div>
          </div>
        </mat-card>

        <mat-card
          class="card-outline"
          [ngClass]="initialScreenForm.value.initialScreen === initialScreenOptions.NewProposal ? 'card-active' : ''"
          (click)="setSelectedOption(initialScreenOptions.NewProposal)"
        >
          <div class="row card-content">
            <div class="mobile-column-one">
              <div class="col-md-1">
                <mat-radio-button
                  [value]="initialScreenOptions.NewProposal"
                  [checked]="initialScreenForm.value.initialScreen === initialScreenOptions.NewProposal"
                >
                  {{ 'NEW-PROPOSAL' | translate }}
                </mat-radio-button>
              </div>
            </div>
          </div>
        </mat-card>
      </mat-radio-group>
      <!-- MOBILE END  -->

      <!-- DESKTOP BEGIN -->
      <mat-radio-group class="desktop" formControlName="initialScreen">
        <mat-radio-button
          [value]="initialScreenOptions.Panel"
          [checked]="initialScreenForm.value.initialScreen === initialScreenOptions.Panel"
          (click)="menuClick('PANEL')"
        >
          {{ 'PANEL' | translate }}
        </mat-radio-button>

        <mat-radio-button
          [value]="initialScreenOptions.NewProposal"
          [checked]="initialScreenForm.value.initialScreen === initialScreenOptions.NewProposal"
          (click)="menuClick('NEW-PROPOSAL')"
        >
          {{ 'NEW-PROPOSAL' | translate }}
        </mat-radio-button>
      </mat-radio-group>
      <!-- DEKTOP END -->

      <button
        type="submit"
        class="action-button mt-5"
        [disabled]="!initialScreenForm.valid"
        appTagger
        [event_category]="categoria"
        event_label="salvar alterações"
      >
        {{ 'SAVE-CHANGES' | translate }}
      </button>
    </form>
  </div>

  <ng-template #updateSuccess>
    <section>
      <div class="container">
        <p class="title">{{ 'SET-INITIAL-SCREEN' | translate }}</p>

        <div class="full-content">
          <div class="d-flex">
            <img src="./assets/images/Check.svg" alt="check icon" />
            <p>{{ 'SUCCESS-SAVE-PROFILE' | translate }}</p>
          </div>
        </div>

        <mat-card>
          <div class="row">
            <div class="col-12 col-md-6">
              <label class="label">{{ 'INITIAL-SCREEN' | translate }}</label>
              <p>
                {{
                  (initialScreenForm?.value?.initialScreen === initialScreenOptions.NewProposal
                    ? 'NEW-PROPOSAL'
                    : 'PANEL') | translate
                }}
              </p>
            </div>
          </div>
        </mat-card>
      </div>
    </section>
  </ng-template>
</app-side-content>
