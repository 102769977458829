import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { TaggerService } from '@app/tagging/tagger.service';
import { ChecklistModel } from './models/checklist.model';

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss']
})
export class ChecklistComponent implements OnInit, OnChanges {
  @Input() checklist: ChecklistModel[];
  @Input() maxSizeAllowed: string;
  @Output() uploadFinished = new EventEmitter();
  @Input() category: string;
  public completedChecklistIds: string[] = [];
  public uploadsComplete = false;
  public loadedChecklist: ChecklistModel[] = [];
  public currentUrl: string;

  constructor(private router: Router, private taggerService: TaggerService) {}

  ngOnInit() {
    this.getCurrentUrl();
  }

  private getCurrentUrl() {
    this.currentUrl = this.router.url;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.checklist && changes.checklist) {
      if (this.category?.includes('formalization')) {
        let proposalType = this.taggerService.getProposalType();
        this.taggerService.setPageLocation(`/criarproposta/${proposalType}/formalizacao/cliente/documentos`);
      }
      this.loadedChecklist = this.checklist;
      if (this.router.url === '/proposal/step-formalization') {
        this.loadedChecklist.sort((a, b) => {
          return parseFloat(a.uploadGroup.code) - parseFloat(b.uploadGroup.code);
        });
      }
    }
  }

  public handleUploadFinished(obj: { id: string; completed: boolean }): void {
    // If upload is successful and the element is not in the list, add it.
    if (obj.completed) {
      if (!this.completedChecklistIds.find(ele => ele === obj.id)) {
        this.completedChecklistIds.push(obj.id);
      }
    }

    // If deletion is successful and the element is in the list, remove it.
    if (!obj.completed) {
      if (this.completedChecklistIds.find(ele => ele === obj.id)) {
        const index = this.completedChecklistIds.findIndex(ele => ele === obj.id);
        this.completedChecklistIds.splice(index, 1);
      }
    }

    // Compare checklist to successful uploads to determine if we need to enable
    // submit button.
    this.uploadsComplete = this.checklist.every(ele => !!this.completedChecklistIds.find(id => ele.id === id));

    this.uploadFinished.emit(this.uploadsComplete);
  }
}
