import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-negotiation-form',
  templateUrl: './negotiation-form.component.html',
  styleUrls: ['./negotiation-form.component.scss']
})
export class NegotiationFormComponent implements OnInit {
  negotiationForm: FormGroup;
  formIsInvalid = true;
  validType = false;
  validDescription = false;
  @Input() event_category: string;
  @Output() saveNegotiation = new EventEmitter();

  constructor(private fb: FormBuilder) {}

  ngOnInit() {
    this.createForm();
    this.negotiationForm.get('type').valueChanges.subscribe(data => {
      this.validType = true;
      if (this.validDescription) {
        this.formIsInvalid = false;
      }
    });

    this.negotiationForm.get('description').valueChanges.subscribe(data => {
      this.validDescription = true;
      if (this.validType && data.length) {
        this.formIsInvalid = false;
      } else {
        this.validDescription = false;
        this.formIsInvalid = true;
      }
      console.log(this.formIsInvalid);
    });
  }

  private createForm(): void {
    this.negotiationForm = this.fb.group({
      type: [false, Validators.requiredTrue],
      description: [null, [Validators.required, Validators.maxLength(280)]]
    });
  }

  onSubmit(): void {
    if (this.formIsInvalid) {
      return;
    } else {
      this.saveNegotiation.emit(this.negotiationForm.value);
    }
  }
}
