export enum StatusProposals {
  DOCUMENTAL_ANALYSIS = 'D',
  IN_NEGOCIATION = 'G',
  PAYMENT_ERROR = 'R',
  PAYMENT_AVAILABLE = 'I',
  PAYMENT_IN_RELEASE = 'L',
  PENDING_DOCUMENT = 'B',
  CANCELLED_PROPOSAL = 'C',
  PROPOSAL_IN_ANALYSIS = 'S',
  PROPOSAL_IN_PROGRESS = 'A',
  PROPOSAL_IN_FORMALIZATION = 'F',
  EXPIRED_PROPOSAL = 'E',
  DENIED_PROPOSAL = 'N',
  MANUAL_PAYMENT = 'M',
  PAID_PROPOSAL = 'P',
  FORMALIZED_CONTRACT = 'H',
  PENDING_RATE = 'T',
  DOCUMENT_VALIDATION = 'V'
}

export enum ProposalStatusEnum {
  PAYMENT_AVAILABLE = 'PAGAMENTO DISPONÍVEL',
  PROPOSAL_IN_PROGRESS = 'PROPOSTA EM ANDAMENTO',
  PROPOSAL_IN_FORMALIZATION = 'PROPOSTA EM FORMALIZAÇÃO',
  PROPOSAL_IN_NEGOCIATION = 'PROPOSTA EM NEGOCIAÇÃO',
  PROPOSAL_IN_DOCUMENTAL_ANALYSIS = 'ANÁLISE DOCUMENTAL',
  PROPOSAL_IN_DOCUMENT_VALIDATION = 'VALIDAÇÃO DE DOCUMENTO',
  PROPOSAL_IN_PENDING_DOCUMENT = 'DOCUMENTAÇÃO PENDENTE',
  PROPOSAL_IN_DOCUMENT_PENDING = 'PENDENTE DOCUMENTO',
  PROPOSAL_IN_ANALYSIS = 'PROPOSTA EM ANÁLISE',
  PROPOSAL_DENIED = 'PROPOSTA NEGADA',
  PROPOSAL_EXPIRED = 'PROPOSTA EXPIRADA',
  PROPOSAL_CANCEL = 'PROPOSTA CANCELADA',
  PROPOSAL_TO_EXPIRY = 'PROPOSTA A EXPIRAR',
  PAYMENT_PENDING_TARRIF = 'TARIFA PENDENTE'
}

export enum ProposalButtonIconStatusEnum {
  PROPOSAL_IN_FORMALIZATION = 'ic_arrow_run_right.svg',
  PROPOSAL_IN_PROGRESS = 'ic_arrow_run_right.svg',
  PROPOSAL_IN_NEGOCIATION = 'ic_file.svg',
  PROPOSAL_IN_DOCUMENTAL_ANALYSIS = 'ic_file.svg',
  PROPOSAL_IN_DOCUMENT_VALIDATION = 'ic_file.svg',
  PROPOSAL_IN_ANALYSIS = 'ic_file.svg',
  PROPOSAL_EXPIRED = 'ic_file.svg',
  PROPOSAL_CANCEL = 'ic_file.svg',
  PROPOSAL_DENIED = 'ic_file.svg',
  PAYMENT_AVAILABLE = 'ic_file.svg',
  PROPOSAL_IN_PENDING_DOCUMENT = 'ic_urgent.svg',
  PROPOSAL_IN_DOCUMENT_PENDING = 'ic_urgent.svg',
  PROPOSAL_NO_STATUS_UN_LIST = 'ic_file.svg',
  PAYMENT_PENDING_TARRIF = 'ic_barcode.svg'
}
