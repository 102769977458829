import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PendencyService } from '@app/pendency/pendency.service';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ModalOptions, PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { Subject } from 'rxjs';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';
import { FormBipartiteModel } from './form-bipartite-model';
@Component({
  selector: 'app-form-bipartite',
  templateUrl: './form-bipartite.component.html',
  styleUrls: ['./form-bipartite.component.scss']
})
export class FormBipartiteComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() public proposalData: ConfirmationData;
  @Input() public hasBipartiteMandatory: Boolean;

  public bipartitePaymentForm: FormGroup;
  public options: any[] = [
    {
      id: true,
      name: 'Sim',
      description:
        'Você deverá indicar o(s) participante(s) através do Painel de Propostas após a conclusão desta etapa.'
    },
    { id: false, name: 'Não', description: '' }
  ];
  public answer = '';
  @Output() public selectedBipartiteForm: EventEmitter<FormBipartiteModel> = new EventEmitter();
  @Input() category: string;

  constructor(private dialog: MatDialog, private pendencyService: PendencyService, private genTagger: GenTagger) { }

  ngOnInit() {
    this.buildForm();
    this.selectSubscriptionQuestions();
    this.isBipartiteMandadory();
  }

  private openWarningMandatoryBipartiteModal(): void {
    this.dialog.open(PionModalComponent, {
      width: '560px',
      autoFocus: false,
      id: 'mandatory-bipartite-warning',
      data: <ModalOptions>{
        title: 'Atenção',
        description: 'O parceiro comercial é obrigatório para seguir para a próxima etapa',
        confirmText: 'Ok',
        type: 'alert'
      }
    });
  }

  public buildForm() {
    this.bipartitePaymentForm = new FormGroup({
      answer: new FormControl(null, [Validators.required])
    });
  }

  public selectSubscriptionQuestions(): void {
    this.bipartitePaymentForm.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(() => this.bipartitePaymentForm.valid)
      )
      .subscribe(value => this.validateAnswers(value));
  }

  public isBipartiteMandadory() {
    if (!!this.hasBipartiteMandatory) {
      this.bipartitePaymentForm.get('answer').setValue(true);
      this.pendencyService
        .getPartners(Number(this.proposalData.proposalData.id))
        .pipe(
          first(),
          map(partnerList => (partnerList.length > 0 ? true : false)),
          filter(hasPartner => !hasPartner),
          tap(() => this.openWarningMandatoryBipartiteModal())
        )
        .subscribe();
    }
  }

  public validateAnswers(value: { answer: boolean }): void {
    const { answer } = value;
    const { channelSupplier } = this.proposalData;
    const bipartiteRequest: FormBipartiteModel = {
      typeBipartite: channelSupplier.bipartite.type,
      flgBipartite: answer
    };

    this.selectedBipartiteForm.emit(bipartiteRequest);
  }

  public onBipartiteAnswerChange(option): void {
    this.genTagger.setTag({
      page_location: this.category,
      event_element: 'radio-button',
      page_section: 'o pagamento da operação será dividido com outro participante (lojista parceiro)?',
      event_label: option,
      event_action: Tag.Selecionar
    });
    this.validateAnswers({ answer: option === 'Sim' ? true : false });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
