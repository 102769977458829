<app-side-content [show]="true">
  <div class="desktop d-none d-md-block container container-margin">
    <div class="row">
      <div class="col-11">
        <h1>{{ 'USER-PREFERENCES' | translate }}</h1>
      </div>
    </div>

    <form [formGroup]="userPreferences" class="form-container" (ngSubmit)="submitUserPrefs()">
      <div class="row title">
        <mat-label>{{ 'ENTRY-SCREEN' | translate }}</mat-label>
      </div>

      <mat-radio-group formControlName="option">
        <mat-radio-button color="primary" value="PANEL" [checked]="checked == 'PANEL'" class="first-option">{{
          'PANEL' | translate
        }}</mat-radio-button>
        <mat-radio-button color="primary" value="PROPOSAL" class="second-option" [checked]="checked == 'PROPOSAL'"
          >{{ 'NEW-PROPOSAL' | translate }}
        </mat-radio-button>
      </mat-radio-group>

      <div class="row action-container">
        <div class="col-12">
          <button
            color="primary"
            class="full-width-button button"
            [disabled]="userPreferences.value.option.length == 0"
            type="submit"
            mat-raised-button
          >
            {{ 'SAVE-CHANGES' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>

  <div class="mobile d-sm-none container container-margin">
    <form [formGroup]="userPreferences" (ngSubmit)="submitUserPrefs()">
      <div class="row title">
        <mat-label>{{ 'ENTRY-SCREEN' | translate }}</mat-label>
      </div>

      <mat-radio-group formControlName="option">
        <mat-radio-button color="primary" value="PANEL" [checked]="checked == 'PANEL'" class="first-option"
          >{{ 'PANEL' | translate }}
        </mat-radio-button>
        <mat-radio-button color="primary" value="PROPOSAL" class="second-option" [checked]="checked == 'PROPOSAL'"
          >{{ 'NEW-PROPOSAL' | translate }}
        </mat-radio-button>
      </mat-radio-group>

      <div class="row action-container">
        <div class="col-12">
          <button
            color="primary"
            class="full-width-button button"
            [disabled]="userPreferences.value.option.length == 0"
            type="submit"
            mat-raised-button
          >
            {{ 'SAVE-CHANGES' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>
</app-side-content>
