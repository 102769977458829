import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'progress-card',
  templateUrl: './progress-card.component.html',
  styleUrls: ['./progress-card.component.scss']
})
export class ProgressCardComponent implements OnInit {
  @Input() data: any;

  public window = window;

  public colorCodes = [
    { color: 'GREEN', icon: './assets/images/ic_check.svg', text: 'Enviado' },
    { color: 'YELLOW', icon: './assets/images/ic_exclamation.svg', text: 'Incompleta' },
    { color: 'RED', icon: './assets/images/ic_exclamation_red.svg', text: 'Negado' },
    { color: 'BLANK', icon: './assets/circle_block.svg', text: '' }
  ];

  public itens: any;

  constructor() {}

  ngOnInit() {
    this.itens = this.data.map(itemData => ({
      ...this.colorCodes.find(item => item.color === itemData.color),
      ...itemData
    }));
  }
}
