<section id="7" class="simulation-section">
  <!-- <header class="section-header">
    <div class="row">
      <div class="col-10 pr-0">
        <h2 class="title">
          {{ 'Include coupon' | translate }}
        </h2>
      </div>
      <div class="col-2">
        <pion-tooltip alignTo="right">
          <p [innerHTML]="'STEPPER-SIMULATION-SECTION-7-INFO' | translate"></p>
        </pion-tooltip>
      </div>
    </div>
    <span>{{ couponData?.sectionNumber }}</span>
  </header> -->

  <div class="section-main">
    <div class="coupon">
      <form class="coupon-form" [formGroup]="couponForm">
        <div class="form-group">
          <div class="input-icon">
            <div class="d-flex">
              <label class="coupon-label" for="coupon">{{ 'Include Cupom' | translate }}</label>
              <pion-tooltip
                alignTo="bottom"
                [toLeft]="true"
                appTagger
                [event_category]="category"
                event_label="tooltip"
              >
                <p [innerHTML]="'DISCOUNT-COUPOM-INFO' | translate"></p>
              </pion-tooltip>
            </div>
            <input
              name="coupon"
              type="text"
              class="form-control"
              formControlName="coupon"
              mask="AAAAAAAAAA"
              autocomplete="off"
              onkeyup="this.value = this.value.toUpperCase()"
              appTagger
              [event_category]="category"
              event_label="incluir cupom"
            />
            <ng-container *ngIf="statusCoupon && couponForm.controls.coupon.value">
              <img
                class="mb-3"
                src="./assets/images/icon_confirm.svg"
                matSuffix
                alt="confirm-icon"
                *ngIf="statusCoupon === 'VALID'"
              />
              <img
                class="mb-3"
                src="./assets/error.svg"
                matSuffix
                alt="confirm-icon"
                *ngIf="statusCoupon === 'EXPIRED' || statusCoupon === 'INVALID'"
              />
              <p
                *ngIf="statusCoupon === 'VALID'"
                class="valid-coupon-text"
                appTagger
                [event_category]="category"
                event_label="{{ 'Valid coupon' | translate }}"
                [event_action]="FillingError"
              >
                {{ 'Valid coupon' | translate }}
              </p>
              <p
                *ngIf="statusCoupon === 'EXPIRED'"
                class="invalid-coupon-text"
                appTagger
                [event_category]="category"
                event_label="{{ 'Expired coupon' | translate }}"
                [event_action]="FillingError"
              >
                {{ 'Expired coupon' | translate }}
              </p>
              <p
                *ngIf="statusCoupon === 'INVALID'"
                class="invalid-coupon-text"
                appTagger
                [event_category]="category"
                event_label="{{ 'Invalid coupon' | translate }}"
                [event_action]="FillingError"
              >
                {{ 'Invalid coupon' | translate }}
              </p>
            </ng-container>
          </div>
        </div>
      </form>
    </div>
  </div>
</section>
