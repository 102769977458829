import { HttpErrorResponse } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { StartupService } from '@app/core/startup/services/startup.service';
import { AppState } from '@app/core/state';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { AlertService } from '@shared/components/widgets/alert/alert.service';
import { AddAlert } from '@shared/components/widgets/alert/store/alert.actions';
import { Apollo, ApolloModule } from 'apollo-angular';
import { HttpLink, HttpLinkModule } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { ErrorResponse, onError } from 'apollo-link-error';

const STEPMODEL = '409';
const mgmUri = `${environment.apiRootUrl}/dash-graphql/graphql`;
export const graphqlUri = `${environment.apiRootUrl}/shopkeeper-graphql/1.0.0/graphql`;
// export const graphqlUri = `http://localhost:4000/graphql`;

@NgModule({
  exports: [ApolloModule, HttpLinkModule]
})
export class GraphqlModule {
  constructor(
    private apollo: Apollo,
    private httpLink: HttpLink,
    private store$: Store<AppState>,
    private alertService: AlertService,
    private startup: StartupService
  ) {
    // inMemoryCache
    const cache = new InMemoryCache({
      dataIdFromObject: (o: any) => (o.id ? `${o.__typename}:${o.id}` : null)
    });

    const errorLink = onError(({ graphQLErrors, networkError, response, operation }: ErrorResponse) => {
      if (operation.operationName === 'updateEnderecoClientePorPersona') {
        response.errors = null;
      } else {
        if (graphQLErrors) {
          graphQLErrors.forEach(({ extensions }) => {
            if (extensions && extensions.messages && extensions.messages.length >= 2) {
              try {
                if (JSON.parse(this.getErroConsistencia(extensions.messages[2].message)).ErrorCode !== STEPMODEL) {
                  this.store$.dispatch(new AddAlert(this.alertService.addEmptyAlert(extensions.messages[0].message)));
                }
              } catch (e) {
                if (extensions.severity) {
                  this.store$.dispatch(new AddAlert(this.alertService.addEmptyAlert(extensions.messages[0].message)));
                } else {
                  this.store$.dispatch(new AddAlert(this.alertService.addEmptyAlert(extensions.message)));
                }
              }
            }
          });
        }
        if (networkError) {
          if (networkError instanceof HttpErrorResponse && networkError.error.error !== 'invalid_grant') {
            this.alertService.addAlertError(networkError);
          }
        }
      }
    });

    const addDates = new ApolloLink((operation, forward) => forward(operation).map(response => response));
    const link = addDates.concat(this.httpLink.create({ uri: graphqlUri }));
    const mgmLink = addDates.concat(this.httpLink.create({ uri: mgmUri }));
    this.apollo.createNamed('mgmLink', { link: ApolloLink.from([errorLink, mgmLink]), cache: cache });

    this.apollo.create({
      link: ApolloLink.from([errorLink, link]),
      cache: cache
    });
  }

  /**
   * Obtém o objeto do erro (no formato string) de consistência caso ele exista
   *
   * @private
   * @param {string} str
   * @returns {string}
   * @memberof GraphqlModule
   */
  private getErroConsistencia(str: string): string {
    const matches = str.match(/\{.+\}/gm);
    return matches && matches.length ? matches[0] : null;
  }
}
