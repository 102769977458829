import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LoginState } from './login.reducer';

export const loginSelector = createFeatureSelector<LoginState>('login');

export const selectUser = createSelector(
  loginSelector,
  (state: LoginState) => state.user
);

export const selectRecoveryId = createSelector(
  loginSelector,
  (state: LoginState) => state.recoveryId
);

export const selectChallengeResponse = createSelector(
  loginSelector,
  (state: LoginState) => state.challengeResponse
);

export const selectPasswordChangeOutcome = createSelector(
  loginSelector,
  (state: LoginState) => state.passwordChangeOutcome
);

export const selectErrors = createSelector(
  loginSelector,
  (state: LoginState) => state.errors
);

export const selectListDocumentType = createSelector(
  loginSelector,
  (state: LoginState) => state.loginType
);

// NOTE: - Consolidate/refactor to select active tutorial?
export const selectShowTutorialPrt = createSelector(
  loginSelector,
  (state: LoginState) => state.showTutorialPrt
);

export const selectShowTutorialSmula = createSelector(
  loginSelector,
  (state: LoginState) => state.showTutorialSmula
);

export const selectShowTutorialPgto = createSelector(
  loginSelector,
  (state: LoginState) => state.showTutorialPgto
);

export const selectShowTutorialForml = createSelector(
  loginSelector,
  (state: LoginState) => state.showTutorialForml
);

export const selectShowTutorialMenu = createSelector(
  loginSelector,
  (state: LoginState) => state.showTutorial
);

export const selectFtype = createSelector(
  loginSelector,
  (state: LoginState) => state.ftype
);

export const selectLoginForm = createSelector(
  loginSelector,
  (state: LoginState) => state.loginForm
);
