<div id="modal-content-wrapper">
  <header id="modal-header" class="text-center mt-3">
    <h1 id="modal-title" mat-dialog-title>{{ 'FORMALIZATION-EXIT-MODAL-TITLE' | translate }}</h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <p>{{ 'FORMALIZATION-EXIT-MODAL-DESCRIPTION' | translate: { dayToExpire: modalData.dayToExpire } }}</p>
  </section>

  <footer id="modal-footer">
    <button mat-stroked-button id="modal-cancel-button" color="primary" (click)="cancel()">
      {{ 'FORMALIZATION-EXIT-MODAL-BUTTON-CANCEL' | translate }}
    </button>
    <button mat-flat-button id="modal-action-button" color="primary" (click)="confirm()">
      {{ 'FORMALIZATION-EXIT-MODAL-BUTTON-CONFIRM' | translate }}
    </button>
  </footer>
</div>
