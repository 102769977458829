import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { Countries } from '@shared/constants/countries.enum';
import { Languages } from '@shared/constants/languages.enum';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { argentinaMasks } from '@shared/masks/argentina-masks.models';
import { brazilMasks } from '@shared/masks/brazil-masks.models';
import { colombiaMasks } from '@shared/masks/colombia-masks.models';
import { peruMasks } from '@shared/masks/peru-masks.models';
import { argentinaValidators } from '@shared/validators/argentina.validators';
import { brazilValidators } from '@shared/validators/brazil.validators';
import { colombiaValidators } from '@shared/validators/colombia.validators';
import { peruValidators } from '@shared/validators/peru.validators';
import { take } from 'rxjs/operators';
import { ArgentinaValidators } from '@shared/interfaces/validators/argentina-validators.interface';
import { BrazilValidators } from '@shared/interfaces/validators/brazil-validators.interface';
import { PeruValidators } from '@shared/interfaces/validators/peru-validators.interface';
import { ColombiaValidators } from '@shared/interfaces/validators/colombia-validators.interface';

@Injectable({
  providedIn: 'root'
})
export class InternationalizationService {
  public pioneerValidators: PioneerValidators = brazilValidators;
  public pioneerMasks: PioneerMasks = brazilMasks;
  public currentCountry: Countries = Countries.BR;
  public currentLanguage: Languages = Languages.BR;
  private renderer: Renderer2;

  constructor(
    private translate: TranslateService,
    private titleService: Title,
    private rendererFactory: RendererFactory2
  ) {
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  public setLanguage(currentLanguage: Languages): void {
    this.translate.addLangs(['en', 'pt-br', 'es', 'de', 'es-ar', 'es-pe', 'es-co']);
    this.translate.setDefaultLang('en');

    if (Object.values(Languages).includes(currentLanguage)) {
      this.currentLanguage = currentLanguage;
      this.translate.use(this.parseLanguageForTranslate(currentLanguage));
      this.renderer.setAttribute(document.querySelector('html'), 'lang', this.parseLanguageForIndex(currentLanguage));
      return;
    }
    console.error('Language not allowed.');
  }

  private parseLanguageForIndex(language: Languages): string {
    if (language.length > 2) {
      return language.slice(0, 3) + language.slice(3, 5).toUpperCase();
    }
    return language;
  }

  private parseLanguageForTranslate(language: Languages): string {
    if (language.length > 2) {
      return language.slice(0, 5);
    }
    return language;
  }

  public setCountry(currentCountry: Countries): void {
    if (Object.values(Countries).includes(currentCountry)) {
      this.currentCountry = currentCountry;
      this.setTitle();
      this.setValidators();
      this.setMasks();
      return;
    }
    console.error('Country not allowed.');
  }

  private setValidators():
    | ArgentinaValidators
    | BrazilValidators
    | PeruValidators
    | ColombiaValidators
    | PioneerValidators {
    switch (this.currentCountry.toLowerCase()) {
      case 'arg':
        return (this.pioneerValidators = argentinaValidators);
      case 'per':
        return (this.pioneerValidators = peruValidators);
      case 'bra':
        return (this.pioneerValidators = brazilValidators);
      case 'col':
        return (this.pioneerValidators = colombiaValidators);
      default:
        return this.pioneerValidators;
    }
  }

  private setMasks(): PioneerMasks {
    switch (this.currentCountry.toLowerCase()) {
      case 'arg':
        return (this.pioneerMasks = argentinaMasks);
      case 'per':
        return (this.pioneerMasks = peruMasks);
      case 'bra':
        return (this.pioneerMasks = brazilMasks);
      case 'col':
        return (this.pioneerMasks = colombiaMasks);
      default:
        return this.pioneerMasks;
    }
  }

  public setTitle(): void {
    this.translate
      .get('APP-TITLE')
      .pipe(take(1))
      .subscribe(title => {
        this.titleService.setTitle(title);
      });
  }
}
