import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppState } from '@app/core/state';
// NGRX stuff
import * as challenge from '@app/core/state/challenge';
import { selectChallengeStatus } from '@app/core/state/challenge/challenge.selectors';
import { environment } from '@env/environment';
import { select, Store } from '@ngrx/store';
import { HeaderService } from '@shared/components/ui/header/services/header.service';
// Enums
import { Countries } from '@shared/constants/countries.enum';
import { ChallengeService } from '@shared/services/challenge/challenge.service';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { take } from 'rxjs/operators';
import { SignUpService } from './../../services/sign-up.service';

enum ChallengeStatus {
  RETRY = 'RETRY',
  REPROVED = 'REPROVED'
}

@Component({
  selector: 'app-token',
  templateUrl: './confirmation-token.component.html',
  styleUrls: ['./confirmation-token.component.scss']
})
export class ConfirmationTokenComponent implements OnInit, OnDestroy {
  public tokenExpirationTime = environment.timerSecondsRecoveryPassword;
  public tokenDestination: string;
  public tokenDestinationMask: { [key: string]: string };
  public currentCountry = this.internationalizationService.currentCountry;
  public pioneerMasks = this.internationalizationService.pioneerMasks;
  public countries = Countries;
  public getChallenge: any;
  public answerChallenge: any;
  public title;
  public subTitle;
  public personLegalEntity: boolean;
  public category: string;

  challengeStatusCodes = ChallengeStatus;

  public challengeStatus$ = this.store$.pipe(select(selectChallengeStatus));

  constructor(
    private store$: Store<AppState>,
    private internationalizationService: InternationalizationService,
    private router: Router,
    private headerService: HeaderService,
    private signUpService: SignUpService,
    private activatedRouter: ActivatedRoute,
    private challengeService: ChallengeService,
    public loc: Location
  ) { }

  ngOnInit() {
    this.store$.dispatch(new challenge.actions.GetChallenge({ refresh: true }));

    this.store$
      .pipe(
        select('challenge'),
        take(1)
      )
      .subscribe(res => {
        this.answerChallenge = res;
        this.getChallenge = res.getChallenge;
        this.personLegalEntity = res.legalEntity;
      });
    if (this.activatedRouter.snapshot.params.recoveryType === 'recover-password') {
      this.category = '/portallojista/recuperarsenha';
    }
    else if (this.activatedRouter.snapshot.params.recoveryType === 'primary-access') {
      this.category = '/portallojista/primeiroacesso';
    }

  }

  private generateToken(boolean: Boolean) {
    // true: generate new token - correct
    // false: return the same token
    this.store$.dispatch(new challenge.actions.GetChallenge({ refresh: boolean }));
  }

  public sendToken(token: string): void {
    this.store$.dispatch(new challenge.actions.SetChallengeStatus(null));
    if (
      this.answerChallenge &&
      this.answerChallenge.answerChallenge &&
      this.answerChallenge.answerChallenge.status === ChallengeStatus.RETRY
    ) {
      // true: generate new token
      // false: return the same token - correct
      // this.store$.dispatch(new challenge.actions.GetChallenge({ refresh: false }));
      this.signUpService.statusWrongCode$.next(true);
    }

    if (
      this.answerChallenge &&
      this.answerChallenge.answerChallenge &&
      this.answerChallenge.answerChallenge.status === ChallengeStatus.REPROVED
    ) {
      // true: generate new token - correct
      // false: return the same token
      this.generateToken(true);
      this.signUpService.statusWrongCode$.next(true);
    }
    this.store$.dispatch(new challenge.actions.AnswerChallenge({ token: { skip: false, answer: token } }));
  }

  public resendToken(type: string): void {
    if (type === 'resendToken') {
      // true: generate new token - correct
      // false: return the same token
      this.store$.dispatch(new challenge.actions.GetChallenge({ refresh: true }));
    } else {
      const countryCanRedirect =
        environment.currentCountry === Countries.BR ||
        environment.currentCountry === Countries.CO ||
        environment.currentCountry === Countries.PE;

      if (this.personLegalEntity && countryCanRedirect) {
        this.store$.dispatch(new challenge.actions.AnswerChallenge({ token: { skip: true, answer: '' } }));
      } else {
        this.headerService.setGobackUrl(this.router.url);
        this.router.navigate(['/login/recovery/help']);
      }
    }
  }

  ngOnDestroy(): void { }

  goback() {
    this.challengeService.goback().subscribe(res => {
      this.loc.back();
    });
  }
}
