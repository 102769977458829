import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { PaymentPlan } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { ProductCodeEnum } from '@shared/constants/product.enum';
@Component({
  selector: 'app-step-confirmation-proposal',
  templateUrl: './step-confirmation-proposal.component.html',
  styleUrls: ['./step-confirmation-proposal.component.scss']
})
export class StepConfirmationProposalComponent implements OnInit, OnChanges {
  @Input() public proposal: ConfirmationData;
  @Input() public listPaymentPlan: PaymentPlan[];
  public innerWidth: number;
  public productCode = ProductCodeEnum;
  public paymentPlanType = PaymentPlanType;
  public planType = PaymentPlanType.STANDARD_PLAN;
  public event_category = '';
  public showCSC = false;
  public showCSCP = false;
  public percentFinancedValue = 0;
  public percentRegisterTariff = 0;
  public percentInsuranceValue = 0;
  public percentIof = 0;
  public totalInsurance = 0;

  constructor() {}

  ngOnInit() {
    this.innerWidth = window.screen.width;
    if (this.proposal.proposalData.product === ProductCodeEnum.CDC) {
      this.showCSC = false;
      this.showCSCP = false;
      this.event_category = '/portallojista/criarproposta/cdc/confirmacao';
      this.calculetePercentFields();
      this.sumTotalInsurance();
    } else if (this.proposal.proposalData.product === ProductCodeEnum.CSC) {
      this.showCSC = true;
      this.showCSCP = false;
      this.event_category = '/portallojista/criarproposta/csc/confirmacao';
    } else if (this.proposal.proposalData.product === ProductCodeEnum.CSCP) {
      this.showCSC = false;
      this.showCSCP = true
      this.event_category = '/portallojista/criarproposta/cscp/confirmacao';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.proposal && changes.proposal) {
      this.planType =
        this.proposal.planType === PaymentPlanType.STANDARD_PLAN &&
        !this.proposal.planTypeSchemaData.hasFlexInstallments
          ? PaymentPlanType.STANDARD_PLAN
          : PaymentPlanType.CUSTOMIZED_PLAN;
    }
  }

  private calculetePercentFields() {
    this.percentFinancedValue = this.calculatePercent(this.proposal.financedTotalValue, this.proposal.financedValue);
    this.percentRegisterTariff = this.calculatePercent(this.proposal.financedTotalValue, this.proposal.registerTariff);
    this.percentInsuranceValue = this.calculatePercent(this.proposal.financedTotalValue, this.proposal.insuranceValue);
    this.percentIof = this.calculatePercent(this.proposal.financedTotalValue, this.proposal.iof);
  }

  private calculatePercent(financedTotalValue, valueField): number {
    if (valueField === 0) {
      return 0;
    } else {
      return (valueField * 100) / financedTotalValue / 100;
    }
  }

  private sumTotalInsurance(): void {
    if (!!this.listPaymentPlan) {
      this.totalInsurance = 0;
      this.listPaymentPlan.forEach(a => {
        this.totalInsurance += a.installmentValue;
      });
    }
  }
}
