<div class="card shadow card-with-steper">
  <div class="card-body" *ngIf="!data?.lastEmptyCard; else emptyCard">
    <!-- HEADER DO CARD -->
    <div class="row contract-card-header">
      <div class="col-12 font-weight-bold initial-width">
        <p class="no-margin-bottom proposal-title">
          {{ 'CONTRACT-NUMBER' | translate }} {{ data?.contractNumber }}
        </p>
      </div>
    </div>
    <hr />
    <!-- FIM HEADER DO CARD -->

    <!-- CORPO DO CARD -->
    <div class="row">
      <div class="col-12 initial-width">
        <hr *ngIf="width <= 540" />
        <div class="contract-values">
          <div>
            {{ 'TOTAL-LOAN' | translate }}  <strong>{{ data?.appreciation | localeCurrency }} </strong>
          </div>
          <div *ngIf="data.paymentMode!=90; else planoCustomizado">
            {{ 'TOTAL-INSTALLMENTS-1' | translate }} <strong> {{ data?.portion.amount }}</strong>  {{ 'TOTAL-QUOTA' | translate }}<strong> {{ data?.portion.value | localeCurrency }}</strong>
          </div>
          <ng-template #planoCustomizado >
            <div  class="planoCustomizado">
              {{ 'TOTAL-INSTALLMENTS-1' | translate }}  <strong> {{ data?.portion.amount }}</strong>  {{ 'TOTAL-QUOTA' | translate }} <strong> Plano Customizado</strong>
            </div>
        </ng-template> 
          <div class="typeCompany"> <strong> {{ data?.objectFinanced.description }}</strong></div>
        </div>
      </div>
    </div>
    <!-- FIM DO CORPO DO CARD -->
    <!-- FOOTER DO CARD -->
    <div class="row">
      <div class="col-12">
        <!-- <button mat-button class="btn-card" (click)="handleButtonAction(data)">
          Ingreso.
        </button> -->
      </div>
    </div>
    <!-- FIM FOOTER DO CARD -->
  </div>


  <ng-template #emptyCard>  
    <div class="card-body empty-card" [ngClass]="data?.isFullCard ? 'empty-card' : 'empty-card-showcase'">
      <div class="empty-card-img">
        <img class="paper-icon" src="./assets/ic_paper_stack.svg" />
      </div>
      <div>
        <p>
          {{ 'CONTRACT-CARD-EMPTY-MESSAGE' | translate }}
        </p>
      </div>
    </div>
</ng-template>

</div>
