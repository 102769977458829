import { ChecklistFileModel } from '../../../checklist/models/checklist.model';

export enum SignatureStatusEnum {
  SIGNED = 'A',
  REMAKE = 'R'
}

export interface PostSignatureDTORequest {
  id: string;
  proposal: {
    id: number;
    supplierChannel: {
      id: string;
    };
  };
  client: {
    id: number;
    name: string;
    email: string;
    documentNumber: string;
  };
  document: {
    code: number;
    name: string;
    description?: string;
    maxAmount?: number;
    allowedTypes?: string;
    integrationTypes?: {
      id: number;
      description: string;
      integrationCode: string;
    }[];
  };
  type: {
    id: number;
    code: string;
    description: string;
  };
  files: ChecklistFileModel[];
}

export interface PostSignatureDTOResponse {
  status: SignatureStatusEnum;
}
