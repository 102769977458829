import { Directive, HostListener, Input } from '@angular/core';
import { GenTagger } from '@app/tagging/gen-tagger';
import { TaggerService } from './tagger.service';

export enum Tag {
  Click = 'clicou',
  Insert = 'preencheu campo',
  Enviar_Form = 'enviar_form',
  Selecionar = 'selecionou',
  Validar = 'validar',
  error = 'erro de preenchimento',
  Nao_Validar = 'nao-validar',
  FillingError = 'erro de preenchimento',
  pgView = 'pageview',
  handle = 'clicou',
  interact = 'clicou',
  Pesquisar = 'pesquisou',
  nonInteractionEvent = 'nonInteractionEvent',
  qrCode = 'leu qrcode',
  AbriuModal = 'abriu modal',
  Fechou_Modal = 'fechou modal',
  Request = 'request'
}

@Directive({
  selector: '[appTagger]'
})
export class TaggerDirective {
  constructor(public genTagger: GenTagger, private taggerService: TaggerService) {}

  @Input() event_category: string;
  @Input() event_label: string;
  @Input() event_action: Tag | Tag[] | string = Tag.Click;
  @Input() event_element: string;
  @Input() listHoverEvent = false;

  setTag(event_action: Tag | string) {
    let { event_category, event_label, event_element } = this;
    event_category = event_category.length === 0 ? this.taggerService.getPageLocation() : event_category;
    this.genTagger.setTag({ event_category, event_label, event_action, event_element });
  }

  @HostListener('click')
  sendClickTag() {
    this.event_element = 'button';
    try {
      if (typeof this.event_action === 'string' && this.event_action === Tag.Click) {
        this.setTag(Tag.Click);
      } else {
        if (this.event_action.includes(Tag.Click)) {
          this.setTag(Tag.Click);
        } else {
          // generic action (to avoid tag duplication might not be used in others events)
          if (
            typeof this.event_action === 'string' &&
            !this.event_action.includes(Tag.Enviar_Form) &&
            !this.event_action.includes(Tag.Selecionar) &&
            !this.event_action.includes(Tag.Insert) &&
            !this.event_action.includes(Tag.interact) &&
            !this.event_action.includes(Tag.Validar) &&
            !this.event_action.includes(Tag.Nao_Validar)
          ) {
            this.setTag(this.event_action);
          }
        }
      }
    } catch {
      console.log('error');
    }
  }

  @HostListener('submit')
  sendSubmitTag() {
    this.event_element = 'button';
    if (typeof this.event_action === 'string' && this.event_action === Tag.Enviar_Form) {
      this.setTag(Tag.Enviar_Form);
    } else {
      if (this.event_action.includes(Tag.Enviar_Form)) {
        this.setTag(Tag.Enviar_Form);
      }
    }
  }

  @HostListener('change')
  sendBlurTag() {
    if (typeof this.event_action === 'string' && this.event_action === Tag.Selecionar) {
      this.event_element = 'input.radio';
      this.setTag(Tag.Selecionar);
    } else {
      this.event_element = 'input.radio';
      if (this.event_action.includes(Tag.Selecionar)) {
        this.setTag(Tag.Selecionar);
      }
    }
    if (typeof this.event_action === 'string' && this.event_action === Tag.Insert) {
      this.event_element = 'input';
      this.setTag(Tag.Insert);
    } else {
      this.event_element = 'input';
      if (this.event_action.includes(Tag.Insert)) {
        this.setTag(Tag.Insert);
      }
    }
  }

  @HostListener('focus')
  sendFocusTag() {
    this.event_element = 'input';
    try {
      if (typeof this.event_action === 'string' && this.event_action === Tag.interact) {
        this.setTag(Tag.interact);
      } else {
        if (this.event_action.includes(Tag.interact)) {
          this.setTag(Tag.interact);
        }
      }
    } catch {
      console.log('error on focus');
    }
  }

  @HostListener('valid')
  sendValidTag() {
    this.event_element = 'input';
    if (typeof this.event_action === 'string' && this.event_action === Tag.Validar) {
      this.setTag(Tag.Validar);
    } else {
      if (this.event_action.includes(Tag.Validar)) {
        this.setTag(Tag.Validar);
      }
    }
  }

  @HostListener('mouseover')
  hoverTag() {
    this.event_element = 'button';
    if (this.listHoverEvent) {
      if (this.event_action === Tag.Click) {
        this.sendClickTag();
      }
    }
  }

  @HostListener('invalid')
  sendInvalidTag() {
    this.event_element = 'input';
    if (typeof this.event_action === 'string' && this.event_action === Tag.Nao_Validar) {
      this.setTag(Tag.Nao_Validar);
    } else {
      if (this.event_action.includes(Tag.Nao_Validar)) {
        this.setTag(Tag.Nao_Validar);
      }
    }
  }
}
