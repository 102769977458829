import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-payment-table-mobile',
  templateUrl: './payment-table-mobile.component.html',
  styleUrls: ['./payment-table-mobile.component.scss']
})
export class PaymentTableMobileComponent implements OnInit {
  @Input() listPayments: any[];

  @Output() kebabAction = new EventEmitter();
  @Output() updateCheckSelectAll = new EventEmitter();

  constructor() {}

  ngOnInit() {}

  public onKebabAction(code, id): void {
    this.kebabAction.emit({ code, id });
  }

  public onUpdateCheckSelectAll() {
    this.updateCheckSelectAll.emit();
  }

  public onButtonListOrKebabAction(button: string, id: number): void {
    this.kebabAction.emit({ button, id });
  }
}
