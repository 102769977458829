<app-side-content [name]="changePassword" [show]="true">
  <section *ngIf="!showConfirmChanges; else confirmChanges">
    <div class="content">
      <p class="desktop d-none d-md-block title">{{ 'CHANGE-PASSWORD' | translate }}</p>
      <p class="subtitle">
        {{ 'PASSWORD-SUBTITLE' | translate }}
      </p>
      <div class="rules-list">
        <ul>
          <li [ngClass]="{
              'has-error': password.errors?.minlength || password.errors?.maxlength,
              'is-ok': password.value !== '' && !password.errors?.maxlength && !password.errors?.minlength
            }">
            {{ 'PASSWORD-RULES-LIST1' | translate }}
          </li>
          <li [ngClass]="{
              'has-error': password.errors?.capitalWord,
              'is-ok': password.value !== '' && !password.errors?.capitalWord
            }">
            {{ 'PASSWORD-RULES-LIST2' | translate }}
          </li>
          <li [ngClass]="{
              'has-error': password.errors?.hasNumbers,
              'is-ok': password.value !== '' && !password.errors?.hasNumbers
            }">
            {{ 'PASSWORD-RULES-LIST3' | translate }}
          </li>
          <li [ngClass]="{
              'has-error': password.errors?.hasSpecialChars,
              'is-ok': password.value !== '' && !password.errors?.hasSpecialChars
            }">
            {{ 'PASSWORD-RULES-LIST4' | translate }}
          </li>
        </ul>
      </div>

      <form (ngSubmit)="save()" [formGroup]="newPasswordForm">
        <!-- Current password -->
        <div class="form-group">
          <input [type]="hideCurPass ? 'text' : 'password'" class="form-control" formControlName="currentPassword"
            placeholder="{{ 'PASSWORD-INPUT-CURRENT-PASSWORD' | translate }}" maxlength="10" appTagger
            [event_category]="categoria" event_label="senha atual" [event_action]="Insert" />
          <div [hidden]="currentPassword.value === ''" class="show-hide-pass-button"
            (click)="hideCurPass = !hideCurPass">
            {{ hideCurPass ? ('Hide' | translate) : ('Show' | translate) }}
          </div>
        </div>

        <!-- New password -->
        <div class="form-group">
          <input [type]="hideNewPass ? 'text' : 'password'" class="form-control" formControlName="password"
            placeholder="{{ 'PASSWORD-INPUT-NEW-PASSWORD' | translate }}" maxlength="10" appTagger
            [event_category]="categoria" event_label="nova senha" [event_action]="Insert" />
          <div [hidden]="password.value === ''" class="show-hide-pass-button" (click)="hideNewPass = !hideNewPass">
            {{ hideNewPass ? ('Hide' | translate) : ('Show' | translate) }}
          </div>
        </div>

        <!-- Confirm password -->
        <div class="form-group">
          <input [type]="hideConfirmPass ? 'text' : 'password'" class="form-control" formControlName="passwordConfirm"
            placeholder="{{ 'PASSWORD-INPUT-AGAIN-PASSWORD' | translate }}" maxlength="10" appTagger
            [event_category]="categoria" event_label="confirme a nova senha" [event_action]="Insert" />
          <div [hidden]="passwordConfirm.value === ''" class="show-hide-pass-button"
            (click)="hideConfirmPass = !hideConfirmPass">
            {{ hideConfirmPass ? ('Hide' | translate) : ('Show' | translate) }}
          </div>
          <p class="passmsg" *ngIf="passwordConfirm.value !== '' && passwordConfirm.value !== password.value">
            {{ 'PASSWORD-MSG-ERROR' | translate }}
          </p>
        </div>

        <button type="submit" class="action-button"
          [disabled]="!newPasswordForm.valid || passwordConfirm.value !== password.value" appTagger
          [event_category]="categoria" event_label="{{ 'PASSWORD-BTN-SAVE-PASSWORD' | translate }}">
          {{ 'PASSWORD-BTN-SAVE-PASSWORD' | translate }}
        </button>
      </form>
    </div>
  </section>

  <ng-template #confirmChanges>
    <section>
      <div class="content">
        <p class="title">{{ 'CHANGE-PASSWORD' | translate }}</p>

        <div class="full-content" *ngIf="isOkay == true">
          <div class="d-flex">
            <img src="./assets/images/Check.svg" />
            <p>{{ 'SUCCESS-CHANGE-PASSWORD' | translate }}</p>
          </div>
        </div>

        <div class="full-content error" *ngIf="isOkay === false">
          <div class="d-flex">
            <img src="./assets/images/ic_cancel.svg" />
            <p>Senha atual inválida, favor tentar novamente.</p>
          </div>
        </div>

        <mat-card *ngIf="isOkay === true">
          <label>{{ 'LABEL-NEW-PASSWORD' | translate }}</label>
          <p>{{ unicodeNewPassword() }}</p>
        </mat-card>
      </div>
    </section>
  </ng-template>
</app-side-content>