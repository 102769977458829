import { Component, HostListener, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProposalService } from '../../service/proposal.service';
import { Category } from '../../shared/category.model';

import { AlertMaterialComponent } from '@app/products/dialog/alert/alertMaterial.component';
import { CalendarDialogComponent } from '@shared/components/ui/calendar-dialog/calendar-dialog.component';
import { CalendarConfig } from '@shared/components/ui/calendar/calendar.model';

import { Subscription } from 'rxjs';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-proposal-simulation',
  templateUrl: './proposal-simulation.component.html',
  styleUrls: ['./proposal-simulation.component.scss']
})
@HostListener('window:resize', ['$event'])
export class ProposalSimulationComponent implements OnInit, OnDestroy {
  public isPreAnalysis = true;
  public calendarConfig: CalendarConfig = {
    titleMobile: 'Data de viagem',
    subTitleMobile: 'Qual data deseja viajar?',
    isTruncateYears: true,
    isTruncateDays: { toBack: true }
  };
  public existingProposals = {
    date: '20/02/2020',
    isProposal: false
  };
  public tabs: Array<object>;
  public breadcrumbs = [{ name: 'Home', path: './home' }, { name: 'Compras parceladas', path: './compras-parceladas' }];
  public categories: Array<Category> = [
    {
      url_image: './assets/viagem.svg',
      segment: 'Viagens',
      name: 'viagens'
    },
    {
      url_image: './assets/decoracao.svg',
      segment: 'Móveis e decoração',
      name: 'moveisDecoracao'
    },
    {
      url_image: './assets/construcao.svg',
      segment: 'Materiais de construção',
      name: 'materiaisContrucao'
    },
    {
      url_image: './assets/tecnologia.svg',
      segment: 'Tecnologia',
      name: 'tecnologia'
    },
    {
      url_image: './assets/saude.svg',
      segment: 'Equipamento médico',
      name: 'equipamentoMedico'
    },
    {
      url_image: './assets/energia.svg',
      segment: 'Energia solar',
      name: 'energiaSolar'
    }
  ];

  public arrStates: Array<any> = [
    { AC: 'Acre' },
    { AL: 'Alagoas' },
    { AP: 'Amapá' },
    { AM: 'Amazonas' },
    { BA: 'Bahia' },
    { CE: 'Ceará' },
    { DF: 'Distrito Federal' },
    { ES: 'Espírito Santo' },
    { GO: 'Goiás' },
    { MA: 'Maranhão' },
    { MT: 'Mato Grosso' },
    { MS: 'Mato Grosso do Sul' },
    { MG: 'Minas Gerais' },
    { PA: 'Pará' },
    { PB: 'Paraíba' },
    { PR: 'Paraná' },
    { PE: 'Pernambuco' },
    { PI: 'Piauí' },
    { RJ: 'Rio de Janeiro' },
    { RN: 'Rio Grande do Norte' },
    { RS: 'Rio Grande do Sul' },
    { RO: 'Rondônia' },
    { RR: 'Roraima' },
    { SC: 'Santa Catarina' },
    { SP: 'São Paulo' },
    { SE: 'Sergipe' },
    { TO: 'Tocantins' }
  ];

  public showCalendar = false;
  public dateTravel = '';

  public resize: number;

  public changeInitial: string;
  public changeState: string;
  public initials = [];
  public states = [];

  public searchByName: string;

  public typeSegment: Category;

  public subscription: Array<Subscription> = new Array<Subscription>();

  public form: FormGroup = new FormGroup({
    sg: new FormControl(),
    st: new FormControl(),
    tb: new FormControl()
  });

  constructor(public proposalService: ProposalService, public dialog: MatDialog, private readonly route: Router) {
    this.proposalService.defineFlow = {
      countSteps: 1,
      choiceSegment: true
    };

    this.resize = window.innerWidth;
  }

  ngOnInit() {
    /* Siglas */
    this.arrStates.forEach((el: any) => {
      this.initials.push(Object.keys(el).shift());
    });

    /* Estados */
    this.arrStates.forEach((el: any) => {
      this.states.push(Object.values(el).shift());
    });
  }

  public onResize(event: any): void {
    this.resize = event.target.innerWidth;
  }

  public selectionChangeByStates(event: any, type: string, searchTerm: string): void {
    if (type === 'sg') {
      // Filtra de acordo com a sigla selecionada
      this.subscription.push(
        this.proposalService.getTabs().subscribe((tabs: Array<any>) => {
          this.tabs = tabs.filter((tab: any) => tab.shortState === searchTerm.toLocaleLowerCase());
        })
      );

      // Recupera a lista de siglas e atualiza os inputs
      this.arrStates.forEach(el => {
        if (Object.keys(el).shift() === event.value) {
          this.changeState = el[Object.keys(el).shift()];
        }
      });
    } else if (type === 'st') {
      // Filtra de acordo com o estado selecionado
      this.subscription.push(
        this.proposalService.getTabs().subscribe((tabs: Array<any>) => {
          this.tabs = tabs.filter((tab: any) => tab.longState === searchTerm.toLocaleLowerCase());
        })
      );

      // Recupera a lista de estados e atualiza os inputs
      this.arrStates.forEach(el => {
        if (Object.values(el).shift() === event.value) {
          this.changeInitial = Object.keys(el).shift();
        }
      });
    }
  }

  public selectSegment(categoryName: string): void {
    this.proposalService.defineFlow = {
      countSteps: 2,
      choiceSegment: false
    };

    this.typeSegment = this.categories.filter((category: Category) => category.name === categoryName).shift();
    this.restartInputs();

    this.openDialogIsProposal();
  }

  public closeSegment(): void {
    this.proposalService.defineFlow = {
      countSteps: 1,
      choiceSegment: true
    };

    // Encerra as inscrições assíncronas
    if (this.subscription.length > 0) this.subscription.forEach((subscribe: Subscription) => subscribe.unsubscribe());
  }

  public searchByTabName(): string {
    if (!this.form.value.st || !this.form.value.sg) return;
    // filtra por estado caso o input esteja undefined
    if (!this.searchByName) this.goFilterByStates();
    this.tabs = this.tabs.filter((tab: any) => {
      return tab.name.toLocaleLowerCase().match(this.searchByName.toLocaleLowerCase());
    });
  }

  public goFilterByStates(): void {
    // realiza o filtro por estado
    this.subscription.push(
      this.proposalService.getTabs().subscribe((tabs: Array<any>) => {
        this.tabs = tabs.filter((tab: any) => tab.shortState === this.form.value.sg.toLocaleLowerCase());
      })
    );
  }

  public goBreadcrumb(path: string): void {
    console.log('Tratar path de: ', path);
  }

  public restartInputs(): void {
    this.changeInitial = null;
    this.changeState = null;
    this.dateTravel = '';
    this.tabs = [];
  }

  public selectDateTravel(): void {
    this.showCalendar = true;
  }

  public navAction(action: string): void {
    if (action === 'back') {
      this.showCalendar = false;
    } else {
      this.dateTravel = action;
      this.showCalendar = false;
    }
  }

  public selectTab(tab: any): void {
    this.preAnalysis();
  }

  public formControl(): void {
    console.log('Dados do form: ', this.form.value);
  }

  public openDialogIsProposal(): void {
    if (this.existingProposals.isProposal) {
      this.dialog.open(AlertMaterialComponent, {
        width: '500px',
        data: {
          title: 'Proposta Existente',
          text: `
            Voce já possui uma porposta em aberto criada no dia
            ${this.existingProposals.date}.
            Deseja recuperá-la?
          `,
          numberButton: '2',
          labelButton: [{ text: 'Sim, recuperar', color: 'danger' }, { text: 'Não, fazer uma nova', color: 'light' }],
          teste: true
        }
      });
    }
  }

  public openDialogCalendar(): void {
    const refDialogCalendar = this.dialog.open(CalendarDialogComponent, {
      disableClose: true,
      position: { top: '100px', left: '220px' },
      data: {
        isTruncateDays: { toBack: true },
        isTruncateYears: true
      } as CalendarConfig
    });

    this.subscription.push(
      refDialogCalendar.afterClosed().subscribe((dateTravel: string) => (this.dateTravel = dateTravel))
    );
  }

  public preAnalysis(): void {
    this.route.navigate(['proposal/step-simulation']);
    /* if (this.isPreAnalysis) {
      this.dialog.open(AlertMaterialComponent, {
        width: '500px',
        data: {
          title: 'Sem ofertas disponíveis',
          text: 'Não temos ofertas disponíveis para esse CPF no momento.',
          numberButton: '1',
          labelButton: [{ text: 'Ok', color: 'danger' }]
        }
      });
    } */
  }

  ngOnDestroy() {
    // Encerra as inscrições assíncronas
    if (this.subscription.length > 0) this.subscription.forEach((subscribe: Subscription) => subscribe.unsubscribe());
  }
}
