<div class="extra-info">
  <form [formGroup]="selectedBln">
    <div>
      <mat-form-field color="black" appearance="legacy">
        <mat-label>{{ 'INSTALLMENTS-PERIOD-BALLOON' | translate }}</mat-label>
        <mat-select formControlName="lackOption" (selectionChange)="monthChangeHandler($event)"
          [compareWith]="compareObjects">
          <mat-option *ngFor="let blnOption of months" [value]="blnOption.code" appTagger [event_category]="category"
            event_label="{{ blnOption.description }}" [event_action]="Selecionar">
            {{ blnOption.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>
      <mat-form-field color="black" class="calendarArrow" appearance="legacy">
        <mat-label>{{ 'INSTALLMENTS-FIRST-BALLOON' | translate }}</mat-label>
        <mat-select disabled class="set-color-black-disabled" formControlName="firstBlnDate"
          (selectionChange)="setDate()" [compareWith]="compareObjects">
          <mat-option *ngFor="let skipInst of installmentPMethodsDate" [value]="skipInst.code">
            {{ skipInst.description }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <span class="label">{{ 'INSTALLMENTS-STANDARD-BALLOON' | translate }}</span>
    <p class="value font-weight-bold">
      {{ selectedPaymentMethod.installmentDefaultAmount}} de
      {{ selectedPaymentMethod.installmentDefaultValue| localeCurrency }}
    </p>
    <span class="label">{{ 'INSTALLMENTS-BALLOON' | translate }}</span>
    <p class="value font-weight-bold">
      {{ selectedPaymentMethod.installmentPaymentMethodsAmount }} de
      {{ selectedPaymentMethod.installmentPaymentMethodsValue | localeCurrency }}
    </p>
  </form>
</div>
