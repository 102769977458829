<div class="offer-card" *ngFor="let offer of simulationOffers">
  <div class="card-header">
    <p class="title">{{ title }}</p>
    <p [class]="proposal?.proposalData?.product">{{ proposal?.proposalData?.product }}</p>
  </div>
  <div class="card-body">
    <div class="financied-value">
      <p class="subtitle" *ngIf="proposal?.proposalData?.product === productCode.CDC">Valor do financiamento</p>
      <p class="subtitle" *ngIf="proposal?.proposalData?.product === productCode.CSC">Valor da cessão</p>
      <p class="subtitle" *ngIf="proposal?.proposalData?.product === productCode.CSCP">Valor da cessão</p>
      <p class="card-text" *ngIf="proposal?.proposalData?.product === productCode.CDC">
        {{ proposal?.installmentTotalQuantity }} parcelas de
      </p>
      <p
        class="card-text mt-2"
        *ngIf="
          proposal?.proposalData?.product === productCode.CSC || proposal?.proposalData?.product === productCode.CSCP
        "
      >
        {{ proposal?.installmentTotalQuantity }}x de
      </p>
      <p class="financied-total" *ngIf="offer.hasInsurance">
        {{ offer.installmentValueWithoutInsurance | localeCurrency }}
      </p>
      <p class="financied-total" *ngIf="!offer.hasInsurance">{{ offer.installmentValue | localeCurrency }}</p>
    </div>
    <div class="insurance-value" *ngIf="offer.hasInsurance">
      <p class="subtitle">Com seguro</p>
      <p class="card-text">+ {{ offer.installmentInsuranceValue | localeCurrency }} com seguro e benefícios</p>
      <p>
        Total de <span class="proposal-total">{{ offer.installmentValue | localeCurrency }}</span>
      </p>
    </div>
    <div class="summary">
      <p class="subtitle" *ngIf="proposal?.proposalData?.product === productCode.CDC">Resumo do empréstimo</p>
      <p class="subtitle" *ngIf="proposal?.proposalData?.product === productCode.CSC">Resumo da cessão</p>
      <p class="subtitle" *ngIf="proposal?.proposalData?.product === productCode.CSCP">Resumo da cessão</p>
      <p class="card-text">Primeira parcela em {{ offer.dueDaysIntervalMax }} dias</p>
    </div>
  </div>
  <div class="card-footer">
    <button
      class="simulation-button"
      (click)="selecionar(offer)"
      appTagger
      [event_category]="category"
      event_label="Simular o financiamento"
    >
      <span *ngIf="proposal?.proposalData?.product === productCode.CP">Simular o empréstimo</span>
      <span *ngIf="proposal?.proposalData?.product === productCode.CDC">Simular o financiamento</span>
      <span *ngIf="proposal?.proposalData?.product === productCode.CSC">Simular o parcelamento</span>
      <span *ngIf="proposal?.proposalData?.product === productCode.CSCP">Simular o parcelamento</span>
    </button>
  </div>
</div>
