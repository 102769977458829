import { IdentificationData } from '@app/showcase/models/identification/identification.model';
import { SubsegmentModel } from '@shared/models/subsegment.model';
import { GetPersonalizedOffersResDTO } from '@app/showcase/models/api/post.personalized-offers.model';
import { SpecialOffer } from '@app/showcase/models/showcase.model';
import { CityModel } from '@shared/models/city.model';
import { StateModel } from '@shared/models/state.model';
import { StoreModel } from '@shared/models/store.model';
import { ShowcaseActions, ShowcaseActionTypes } from './showcase.actions';
import { PostIdentificationResDTO } from '@app/showcase/models/api/post.identification.model';

export interface ShowcaseState {
  specialOffers: {
    data: SpecialOffer[];
    isLoading: boolean;
  };
  personalizedOffers: {
    data: GetPersonalizedOffersResDTO;
    isLoadSuccess?: boolean;
    loadAttempts?: number;
    isLoading: boolean;
  };
  maxFinancialValue: {
    data: any;
    isLoading: boolean;
  };
  selectedSubsegment: SubsegmentModel;
  subsegmentsList: SubsegmentModel[];
  statesList: StateModel[];
  citiesList: CityModel[];
  storesList: StoreModel[];
  selectedStore: StoreModel;
  economicActivities: any[];
  submitResponse: PostIdentificationResDTO;
  userData: IdentificationData;
  isLoadShowCase: boolean;
}

export const initialShowcaseState: ShowcaseState = {
  specialOffers: {
    data: null,
    isLoading: false
  },
  personalizedOffers: {
    data: null,
    isLoadSuccess: false,
    loadAttempts: 0,
    isLoading: false
  },
  maxFinancialValue: {
    data: null,
    isLoading: false
  },
  selectedSubsegment: null,
  subsegmentsList: null,
  statesList: null,
  citiesList: null,
  storesList: null,
  selectedStore: null,
  economicActivities: null,
  submitResponse: null,
  userData: null,
  isLoadShowCase: false
};

export function showcaseReducer(state = initialShowcaseState, action: ShowcaseActions) {
  switch (action.type) {
    case ShowcaseActionTypes.GET_PERSONALIZED_OFFERS: {
      return {
        ...state,
        personalizedOffers: { ...state.personalizedOffers, isLoading: true }
      };
    }
    case ShowcaseActionTypes.RESET_PERSONALIZED_OFFERS_DATA: {
      return {
        ...state,
        personalizedOffers: initialShowcaseState.personalizedOffers
      };
    }
    case ShowcaseActionTypes.DISABLE_LOADING_PERSONALIZED_OFFERS: {
      return {
        ...state,
        personalizedOffers: { ...state.personalizedOffers, isLoading: false }
      };
    }
    case ShowcaseActionTypes.GET_PERSONALIZED_OFFERS_SUCCESS: {
      return {
        ...state,
        personalizedOffers: { data: action.payload, isLoadSuccess: true, isLoading: false }
      };
    }
    case ShowcaseActionTypes.GET_PERSONALIZED_OFFERS_ERROR: {
      return {
        ...state,
        personalizedOffers: { ...initialShowcaseState.personalizedOffers, isLoading: false }
      };
    }
    case ShowcaseActionTypes.INCREMENT_PERSONALIZED_OFFERS_ERROR_ATTEMPT: {
      return {
        ...state,
        personalizedOffers: { ...state.personalizedOffers, loadAttempts: state.personalizedOffers.loadAttempts + 1 }
      };
    }
    case ShowcaseActionTypes.GET_SPECIAL_OFFERS: {
      return {
        ...state,
        specialOffers: { ...state.specialOffers, isLoading: true }
      };
    }
    case ShowcaseActionTypes.GET_SPECIAL_OFFERS_SUCCESS: {
      return {
        ...state,
        specialOffers: { data: action.payload, isLoading: false }
      };
    }
    case ShowcaseActionTypes.GET_SPECIAL_OFFERS_ERROR: {
      return {
        ...state,
        specialOffers: { data: initialShowcaseState.specialOffers.data, isLoading: false }
      };
    }
    case ShowcaseActionTypes.GET_STATES_SUCCESS: {
      return {
        ...state,
        statesList: action.payload
      };
    }
    case ShowcaseActionTypes.GET_STATES_ERROR: {
      return {
        ...state,
        statesList: initialShowcaseState.statesList,
        error: action.payload,
        isLoading: false
      };
    }
    case ShowcaseActionTypes.GET_CITIES_SUCCESS: {
      return {
        ...state,
        citiesList: action.payload
      };
    }
    case ShowcaseActionTypes.GET_CITIES_ERROR: {
      return {
        ...state,
        citiesList: initialShowcaseState.citiesList,
        error: action.payload,
        isLoading: false
      };
    }
    case ShowcaseActionTypes.GET_STORES_SUCCESS: {
      return {
        ...state,
        storesList: action.payload
      };
    }
    case ShowcaseActionTypes.GET_STORES_ERROR: {
      return {
        ...state,
        storesList: initialShowcaseState.storesList,
        error: action.payload,
        isLoading: false
      };
    }
    case ShowcaseActionTypes.SELECT_STORE: {
      return {
        ...state,
        selectedStore: action.payload
      };
    }
    case ShowcaseActionTypes.GET_SUBSEGMENTS_SUCCESS: {
      return {
        ...state,
        subsegmentsList: action.payload
      };
    }
    case ShowcaseActionTypes.GET_SUBSEGMENTS_ERROR: {
      return {
        ...state,
        subsegmentsList: initialShowcaseState.subsegmentsList
      };
    }
    case ShowcaseActionTypes.SAVE_SUBSEGMENT: {
      return {
        ...state,
        selectedSubsegment: action.payload
      };
    }
    case ShowcaseActionTypes.GET_ECONOMIC_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        economicActivities: action.payload
      };
    }
    case ShowcaseActionTypes.GET_ECONOMIC_ACTIVITIES_ERROR: {
      return {
        ...state,
        economicActivities: initialShowcaseState.economicActivities
      };
    }
    case ShowcaseActionTypes.GET_MAX_FINANCIAL_VALUE: {
      return {
        ...state,
        maxFinancialValue: { ...state.maxFinancialValue, isLoading: true }
      };
    }

    case ShowcaseActionTypes.GET_MAX_FINANCIAL_VALUE_SUCCESS: {
      return {
        ...state,
        maxFinancialValue: { data: action.payload, isLoading: false }
      };
    }
    case ShowcaseActionTypes.GET_MAX_FINANCIAL_VALUE_ERROR: {
      return {
        ...state,
        maxFinancialValue: initialShowcaseState.maxFinancialValue
      };
    }
    case ShowcaseActionTypes.SUBMIT_IDENTIFICATION: {
      return { ...state, userData: { ...action.payload } };
    }
    case ShowcaseActionTypes.SUBMIT_IDENTIFICATION_SUCCESS: {
      return { ...state, submitResponse: { ...action.payload } };
    }
    case ShowcaseActionTypes.SUBMIT_IDENTIFICATION_ERROR: {
      return { ...state, userData: initialShowcaseState.userData };
    }
    case ShowcaseActionTypes.LOAD_SHOWCASE: {
      return { ...state, isLoadShowCase: true };
    }
    case ShowcaseActionTypes.RESET_SHOWCASE: {
      return { ...initialShowcaseState };
    }

    default:
      return state;
  }
}
