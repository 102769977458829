import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TranslateService } from '@ngx-translate/core';
import { SendTypesEnum } from '@shared/constants/send-type.enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BacenAnswersModel, FormPaymentModel } from './models/form-payment.model';

@Component({
  selector: 'app-form-payment',
  templateUrl: './form-payment.component.html',
  styleUrls: ['./form-payment.component.scss']
})
export class FormPaymentComponent implements OnInit, OnDestroy, OnChanges {
  @Input() public sendTypes: FormPaymentModel[] = [];
  @Input() public email: string;
  @Output() public selectedPaymentForm: EventEmitter<FormPaymentModel> = new EventEmitter();
  @Output() public bacenAnswers: EventEmitter<BacenAnswersModel[]> = new EventEmitter();
  protected ngUnsubscribe: Subject<any> = new Subject();
  public expansion = false;
  public sendTypesEnum = SendTypesEnum;

  public parsedSendTypes: FormPaymentModel[] = [];
  public obfuscatedEmail: string;
  public paymentResponse: FormPaymentModel;

  public paymentForm = this.fb.group({
    paymentType: ['']
  });
  @Input() category: string;
  constructor(private fb: FormBuilder, private genTagger: GenTagger, private translateService: TranslateService) {}

  ngOnChanges(changes: SimpleChanges) {
    if (this.sendTypes && changes.sendTypes) {
      this.initializeForm(this.sendTypes);
      this.sortSendTypes();
    }

    if (this.email && changes.email) {
      this.obfuscateEmail(4);
    }
  }

  ngOnInit() {
    this.selectSubscription();
  }

  public selectSubscription(): void {
    this.paymentForm
      .get('paymentType')
      .valueChanges.pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => {
        if (value) {
          this.paymentResponse = this.sendTypes.find(type => type.id === value);
          this.selectedPaymentForm.emit(this.paymentResponse);
        }
      });
  }

  public handleShowOptions(): void {
    this.genTagger.setTag({
      page_location: this.category,
      page_section: !this.expansion ? 'Exibir outra forma de pagamento' : 'Ocultar outra forma de pagamento',
      event_element: 'button',
      event_label: 'selecione como deseja receber seus boletos',
      event_action: Tag.Click
    });

    const index = this.parsedSendTypes.findIndex(type => type.id === this.paymentResponse.id);

    if (index > 0) this.parsedSendTypes.reverse();

    this.expansion = !this.expansion;
  }

  public initializeForm(sendTypes: FormPaymentModel[]): void {
    const email = sendTypes.find(type => type.code === SendTypesEnum.EMAIL);
    this.paymentForm.get('paymentType').patchValue(email ? email.id : '');
  }

  /**
   * Method to obfuscate client's email
   * @param digits Number of digits to obfuscate
   */
  public obfuscateEmail(digits: number): void {
    const auxArray = this.email.split('').splice(digits);
    this.obfuscatedEmail = `${'*'.repeat(digits)}${auxArray.join('')}`;
  }

  /**
   * Sorting to always display email as first element in the list
   */
  public sortSendTypes(): void {
    this.parsedSendTypes = [
      ...this.sendTypes.reduce((acc, type) => {
        if (type.code === SendTypesEnum.EMAIL) {
          return [type, ...acc];
        }
        return [...acc, type];
      }, [])
    ];

    this.filterSendTypes();
  }

  /**
   * For now, SMS is being parametrized but must not be shown on screen, in the future
   * remove this method if it's necessary for SMS to be shown
   */
  public filterSendTypes(): void {
    this.parsedSendTypes = [...this.parsedSendTypes.filter(type => type.code !== SendTypesEnum.SMS)];
  }

  public onSendTypeChange(typeCode: string): void {
    this.genTagger.setTag({
      page_location: this.category,
      page_section: this.translateService.instant('RECEIVE-BY-' + typeCode),
      event_element: 'input.radio',
      event_label: 'Selecione como deseja receber seus boletos',
      event_action: Tag.Selecionar
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
