import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CertifiedAgentService } from '@app/certified-agent/services/certified-agent.service';
import { AppState } from '@app/core/state';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { SwalService } from '@shared/services/swal/swal.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-certified-agent-list',
  templateUrl: './certified-agent-list.component.html',
  styleUrls: ['./certified-agent-list.component.scss']
})
export class CertifiedAgentListComponent implements OnInit {
  public window = window;
  users = [];
  page = 1;
  perPage = 20;
  previousPage: any;

  public saleId$: Observable<number> = this.store$.select('tabs').pipe(map(tab => +tab.selectedTab.code));

  @Input() count = new EventEmitter<number>();
  @Output() newUserEmitter = new EventEmitter<any>();
  @Output() userIdEmitter = new EventEmitter<any>();

  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;
  closeResult: string;

  public category = '/portallojista/agentecertificado';
  readonly Insert: Tag = Tag.Insert;
  readonly pgView: Tag = Tag.pgView;

  constructor(
    private internationalizationService: InternationalizationService,
    private swalService: SwalService,
    private genTagger: GenTagger,
    private store$: Store<AppState>,
    private translate: TranslateService,
    private agentService: CertifiedAgentService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {}

  get eventAction(): ExtendedTag {
    return {
      event_action_type: Tag.AbriuModal,
      event_action_complement: 'Agente Certificado'
    };
  }

  get eventActionModal(): ExtendedTag {
    return {
      event_action_type: Tag.AbriuModal,
      event_action_complement: 'Filtrar agente'
    };
  }

  ngOnInit() {
    this.getCertifiedAgentList();
    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'Agente Certificado',
      event_action: this.eventAction
    });
  }

  newUserClick() {
    this.newUserEmitter.emit({
      type: 'new-user'
    });
  }

  getCertifiedAgentList(): void {
    this.agentService.getCertifiedAgents().subscribe((data: any) => {
      this.users = data;
    });
  }

  removeAgent(name, agentCertifiedNumber, certificationNumber) {
    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'excluir agente',
      event_action: Tag.AbriuModal
    });
    this.swalService
      .createConfirmWarning(`Você está excluindo o agente certificado ${name}, deseja mesmo excluir?`)
      .then(data => {
        if (data.value) {
          this.agentService.deleteAgentRelationship(agentCertifiedNumber, certificationNumber).subscribe(result => {
            this.dialog
              .open(PionModalComponent, {
                data: {
                  type: 'success',
                  title: this.translateService.instant('EXCLUDED-AGENT'),
                  description: this.translateService.instant('AGENT-HAS-BEEN-SUCCESSFULLY-DELETED'),
                  confirmText: this.translateService.instant('OK'),
                  autoFocus: false,
                  closable: false
                }
              })
              .afterClosed()
              .subscribe(receive => {
                if (!!receive) {
                  this.getCertifiedAgentList();
                }
              });
          });
          this.genTagger.setTag({
            event_category: this.category,
            event_label: 'Sim, excluir',
            event_action: Tag.Fechou_Modal
          });
        } else {
          this.genTagger.setTag({
            event_category: this.category,
            event_label: 'Não, voltar',
            event_action: Tag.Fechou_Modal
          });
        }
      });
  }

  public tag(label) {
    this.genTagger.setTag({
      event_action: 'Selecionou - tipo',
      event_category: this.category,
      event_label: this.translate.instant(label)
    });
  }
}
