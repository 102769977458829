import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DownloadContractService {
  constructor(private http: HttpClient) {}

  downloadContract(proposalId: string): Observable<{ data: string }> {
    const endpoint = `${environment.apiRootUrl}/formalization/1.0.0/proposal/${proposalId}/contract`;
    return this.http.get<{ data: string }>(endpoint);
  }

  downloadContractByUuid(proposalId: string, artifactId: string): Observable<{ data: string }> {
    const endpoint = `${environment.apiRootUrl}/structure/1.0.0/artifact/${proposalId}?artifactId=${artifactId}`;
    return this.http.get<{ data: string }>(endpoint);
  }
}
