import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filled-button',
  templateUrl: './filled-button.component.html',
  styleUrls: ['./filled-button.component.scss']
})
export class FilledButtonComponent {
  @Input() disabled;
  @Output() click = new EventEmitter();
}
