<app-side-content [name]="nameUser" [show]="true">
  <section>
    <div class="content">
      <app-user-management-list
        *ngIf="tela === 'listagem'"
        (newUserEmitter)="changeScreen($event)"
        [count]="count"
      ></app-user-management-list>
      <app-update-user
        *ngIf="tela === 'update-user' || tela === 'new-user'"
        (back)="changeScreen($event)"
        [screenType]="tela"
        [userId]="userId"
      ></app-update-user>
    </div>
  </section>
</app-side-content>
