import { ComponentFactoryResolver, Injectable, ViewContainerRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { CertifiedAgentComponent } from '@app/certified-agent/certified-agent.component';
import { ChangePasswordComponent } from '@app/profile/components/change-password/change-password.component';
import { NotificationsComponent } from '@app/profile/components/notifications/notifications.component';
import { ProfileDataComponent } from '@app/profile/components/profile-data/profile-data.component';
import { UserPreferencesComponent } from '@app/profile/components/user-preferences/user-preferences.component';
import { SideCalendarComponent } from '@app/showcase/components/side-calendar/side-calendar.component';
import { CalendarConfig } from '@shared/components/ui/calendar/calendar.model';
import { IdleDetectService } from '@shared/services/idle-detect/idle-detect.service';
import { SideContentService } from '@shared/services/side-content/side-content.service';
import { ProfileManagementComponent } from '../components/profile-management/profile-management.component';
import { ProposalDetailsComponent } from '../components/proposal-details/proposal-details.component';
import { SetInitialScreenComponent } from '../components/set-initial-screen/set-initial-screen.component';

@Injectable({ providedIn: 'root' })

/**
 * This service is responsible to render entry components on the screen for profile Side Content
 */
export class ProfileRenderService {
  private selectedDate: Subject<string> = new Subject<string>();

  constructor(
    private cfr: ComponentFactoryResolver,
    private sideContentService: SideContentService,
    private idleDetectService: IdleDetectService
  ) {}

  private viewContainer = null;

  async loadComponent(componentToCall) {
    this.viewContainer.clear();
    switch (componentToCall) {
      case 'profile':
        this.loadProfileComponent();
        this.saveComponentsList('profile');
        break;

      case 'change-password':
        this.loadChangePasswordComponent();
        this.saveComponentsList('change-password');
        break;

      case 'set-initial-screen':
        this.loadSetInitialScreenComponent();
        this.saveComponentsList('set-initial-screen');
        break;

      case 'user-preferences':
        this.loadUserPreferencesComponent();
        this.saveComponentsList('user-preferences');
        break;

      case 'user-management':
        this.loadProfileManagement();
        this.saveComponentsList('user-management');
        break;

      case 'notifications':
        this.loadNotificationsComponent();
        this.saveComponentsList('notifications');
        break;

      case 'proposal-detail':
        this.loadProposalDetailComponent();
        this.saveComponentsList('proposal-detail');
        break;

      case 'special-travel-date':
        this.loadDateComponent();
        this.saveComponentsList('special-travel-date');
        break;

      case 'certified-agent':
        this.loadCertifiedAgent();
        this.saveComponentsList('certified-agent');
        break;
    }
  }

  async loadDateComponent() {
    const component = SideCalendarComponent;
    const componentRef = this.viewContainer.createComponent(this.cfr.resolveComponentFactory(component));
    const calendarConf = {
      isTruncateDays: { toBack: true },
      isTruncateYears: true
    } as CalendarConfig;
    componentRef.instance.calendarConfig = calendarConf;
    componentRef.instance.navAction.subscribe(date => {
      if (date !== 'back') {
        this.selectedDate.next(date);
        this.onDateSelection();
        componentRef.instance.closeCalendar = true;
      } else {
        componentRef.instance.closeCalendar = true;
      }
    });
    return componentRef;
  }
  public onDateSelection(): Observable<string> {
    return this.selectedDate;
  }

  async loadProfileManagement() {
    const component = ProfileManagementComponent;
    this.sideContentService.updateSideContentTitle('PROFILE-MANAGEMENT');
    return this.viewContainer.createComponent(this.cfr.resolveComponentFactory(component));
  }

  async loadProfileComponent() {
    const component = ProfileDataComponent;
    this.sideContentService.updateSideContentTitle('PROFILE');
    return this.viewContainer.createComponent(this.cfr.resolveComponentFactory(component));
  }

  async loadChangePasswordComponent() {
    const component = ChangePasswordComponent;
    this.sideContentService.updateSideContentTitle('CHANGE-PASSWORD');

    return this.viewContainer.createComponent(this.cfr.resolveComponentFactory(component));
  }

  async loadSetInitialScreenComponent() {
    const component = SetInitialScreenComponent;
    this.sideContentService.updateSideContentTitle('SET-INITIAL-SCREEN');

    return this.viewContainer.createComponent(this.cfr.resolveComponentFactory(component));
  }

  async loadUserPreferencesComponent() {
    const component = UserPreferencesComponent;
    this.sideContentService.updateSideContentTitle('USER-PREFERENCES');
    return this.viewContainer.createComponent(this.cfr.resolveComponentFactory(component));
  }

  async loadNotificationsComponent() {
    const component = NotificationsComponent;
    return this.viewContainer.createComponent(this.cfr.resolveComponentFactory(component));
  }

  async loadProposalDetailComponent() {
    const component = ProposalDetailsComponent;
    return this.viewContainer.createComponent(this.cfr.resolveComponentFactory(component));
  }

  async loadCertifiedAgent() {
    const component = CertifiedAgentComponent;
    this.sideContentService.updateSideContentTitle('certified-agent');
    return this.viewContainer.createComponent(this.cfr.resolveComponentFactory(component));
  }

  configureViewContainer(vcr: ViewContainerRef) {
    vcr.clear();
    this.viewContainer = vcr;
    this.idleDetectService.viewContainer = vcr;
  }

  public saveComponentsList(component: string) {
    if (!this.idleDetectService.openComponents.find(item => item === component)) {
      this.idleDetectService.openComponents.push(component);
    }
  }
}
