import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FinancialStatementService {
  private paymentId: number;

  constructor(private http: HttpClient) {}

  public getListPayments(page, perPage, product, initialDate, endDate): Observable<any> {
    const ca = `productCode=${product}&initialDate=${initialDate}&endDate=${endDate}&page=${page}&perPage=${perPage}`;
    return this.http.get(`${environment.apiRootUrl}/paymentpanel/1.0.0/extract?${ca}`);
  }

  public getListPaymentsExtractReceived(page, perPage, product, initialDate, endDate): Observable<any> {
    const ca = `productCode=${product}&initialDate=${initialDate}&endDate=${endDate}&page=${page}&perPage=${perPage}`;
    return this.http.get(`${environment.apiRootUrl}/paymentpanel/1.0.0/extract-received?${ca}`);
  }

  // Listagem de vendedores
  getSellers(shopId, search): Observable<any> {
    const path = search === '' ? '' : `?search=${search}`;
    return this.http.get(`${environment.apiRootUrl}/paymentpanel/1.0.0/commercialUnit/${shopId}/vendors` + path);
    // return of(sellersMock);
  }

  public setPaymentId(id: number) {
    this.paymentId = id;
  }

  public getPaymentId() {
    return this.paymentId;
  }

  public downloadExtract(shopId: number, product: string, date: string, formatType: string ): Observable<any> {
    return this.http
      .get(`${environment.apiRootUrl}/paymentpanel/1.0.0/extract/${product}?cdFornecCanal=${shopId}&date=${date}&formatType=${formatType}`, {
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response;
        })
      );
  }

  public downloadExtractReceived(shopId: number, product: string, date: string, formatType: string): Observable<any> {
    return this.http
      .get(
        `${environment.apiRootUrl}/paymentpanel/1.0.0/extract-received/${product}?cdFornecCanal=${shopId}&date=${date}&formatType=${formatType}`,
        {
          responseType: 'blob',
          observe: 'response'
        }
      )
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response;
        })
      );
  }

  public downloadExtractFutureBalances(shopId: number, date: string[], formatType: string): Observable<any> {
    return this.http
      .get(
        `${environment.apiRootUrl}/paymentpanel/1.0.0/extract-cscp/future-balance-download?cdFornecCanal=${shopId}&date=${date}&formatType=${formatType}`,
        {
          responseType: 'blob',
          observe: 'response'
        }
      )
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response;
        })
      );
  }

  public downloadExtractPaymentMade(shopId: number, date: string[], formatType: string): Observable<any> {
    return this.http
      .get(
        `${environment.apiRootUrl}/paymentpanel/1.0.0/extract-cscp/payments-made-download?cdFornecCanal=${shopId}&date=${date}&formatType=${formatType}`,
        {
          responseType: 'blob',
          observe: 'response'
        }
      )
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response;
        })
      );
  }

  public downloadExtractSelectedPeriod(shopId: number, product: string, date: string[], formatType: string): Observable<any> {
    return this.http
      .get(`${environment.apiRootUrl}/paymentpanel/1.0.0/extract/${product}?cdFornecCanal=${shopId}&date=${date}&formatType=${formatType}`, {
        responseType: 'blob',
        observe: 'response'
      })
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response;
        })
      );
  }

  public downloadExtractReceivedSelectedPeriod(shopId: number, product: string, date: string[], formatType: string): Observable<any> {
    return this.http
      .get(
        `${environment.apiRootUrl}/paymentpanel/1.0.0/extract-received/${product}?cdFornecCanal=${shopId}&date=${date}&formatType=${formatType}`,
        {
          responseType: 'blob',
          observe: 'response'
        }
      )
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response;
        })
      );
  }

  public downloadEffectiveProposals(shopId: number,  date: string[], formatType: string): Observable<any> {
    return this.http
      .get(
        `${environment.apiRootUrl}/paymentpanel/1.0.0/extract-cscp/effective-proposals-download?cdFornecCanal=${shopId}&date=${date}&formatType=${formatType}`,
        {
          responseType: 'blob',
          observe: 'response'
        }
      )
      .pipe(
        map((response: HttpResponse<Blob>) => {
          return response;
        })
      );
  }

  public getShowReceived(): Observable<any> {
    return this.http.get(`${environment.apiRootUrl}/paymentpanel/1.0.0/show-received`);
  }

  public getPaymentsMade(page, perPage, initialDate, endDate): Observable<any> {
    const ca = `initialDate=${initialDate}&endDate=${endDate}&page=${page}&perPage=${perPage}`;
    return this.http.get(`${environment.apiRootUrl}/paymentpanel/1.0.0/extract-cscp/payments-made-datatable?${ca}`);
  }

  public getFutureBalance(page, perPage, initialDate, endDate): Observable<any> {
    const ca = `initialDate=${initialDate}&endDate=${endDate}&page=${page}&perPage=${perPage}`;
    return this.http.get(`${environment.apiRootUrl}/paymentpanel/1.0.0/extract-cscp/future-balance-datatable?${ca}`);
  }

  public getEffectiveProposals(page, perPage, initialDate, endDate): Observable<any> {
    const ca = `initialDate=${initialDate}&endDate=${endDate}&page=${page}&perPage=${perPage}`;
    return this.http.get(`${environment.apiRootUrl}/paymentpanel/1.0.0/extract-cscp/effective-proposals-datatable?${ca}`);
  }

}
