import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { RegistrationFeeModalComponent } from '@shared/components/registration-fee-modal/registration-fee-modal.component';
import { PaymentExemptionModalComponent } from '@shared/components/registration-fee-payment-exemption-modal/payment-exemption-modal.component';
import { SummaryModalComponent } from '@shared/components/summary-modal/summary-modal.component';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class SimulationModalService {
  constructor(private dialog: MatDialog) {}

  dialogRef: MatDialogRef<RegistrationFeeModalComponent>;

  summaryDialogRef: MatDialogRef<SummaryModalComponent>;

  public open(options, config?) {
    this.dialogRef = this.dialog.open(PionModalComponent, {
      ...config,
      data: {
        title: options.title,
        description: options.description,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
        type: options.type
      }
    });
  }

  public openRegistrationFeeModal(options, config?) {
    this.dialogRef = this.dialog.open(RegistrationFeeModalComponent, {
      ...config,
      data: {
        title: options.title,
        description: options.description,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
        type: options.type,
        category: config.data.category
      }
    });
  }

  public confirmed(): Observable<any> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map(res => {
        return res;
      })
    );
  }

  public summaryConfirmed(): Observable<any> {
    return this.summaryDialogRef.afterClosed().pipe(
      take(1),
      map(res => {
        return res;
      })
    );
  }

  public openPaymentExemptionModal(options, config?) {
    this.dialogRef = this.dialog.open(PaymentExemptionModalComponent, {
      ...config,
      panelClass: 'payment-exemption',
      data: {
        title: options.title,
        description: options.description,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
        type: options.type,
        category: config.data.category
      }
    });
  }

  public openSummaryModal(options, config?) {
    this.summaryDialogRef = this.dialog.open(SummaryModalComponent, {
      ...config,
      data: {
        installment: options.installment,
        installmentValue: options.installmentValue,
        dueDate: options.dueDate,
        payment: options.payment
      }
    });
  }
}
