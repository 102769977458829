import { ProposalNegotiationActions, ProposalNegotiationActionsTypes } from './proposal-negotiation.actions';

export interface ProposalNegotiationState {
  installmentValue: number;
  interestRate: number;
  negotiationStatus: string;
  status: string;
}

export const initialState = {
  installmentValue: null,
  interestRate: null,
  negotiationStatus: null,
  status: null
};

export function proposalNegotiationReducer(
  state = initialState,
  action: ProposalNegotiationActions
): ProposalNegotiationState {
  switch (action.type) {
    case ProposalNegotiationActionsTypes.NEGOTIATION_PROPOSAL_POOLING_SUCCESS: {
      return {
        ...state,
        ...action.payload
      };
    }

    case ProposalNegotiationActionsTypes.CLEAR_NEGOTIATION_PROPOSAL: {
      return {
        ...initialState
      };
    }

    default:
      return state;
  }
}
