import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import * as profileActions from '@app/core/state/profile/profile.actions';
import { ShowTourTutorialShowcaseAction } from '@app/core/state/showcase/showcase.actions';
import { GetClientCredentialsAction } from '@app/core/state/startup/startup.actions';
import * as fromTab from '@app/core/state/tab';
import { ExpirationModalComponent } from '@app/login/components/expiration-modal/expiration-modal.component';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { TermsModalComponent } from '@shared/components/terms-modal/terms-modal.component';
import { DomainsService } from '@shared/services/domains/domains.service';
import { LgpdService } from '@shared/services/lgpd/lgpd.service';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { ShopkeeperService } from '@shared/services/shopkeeper/shopkeeper.service';
import { TermsService } from '@shared/services/terms/terms.service';
import { of } from 'rxjs';
import { catchError, first, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as loginActions from '../login/login.actions';
import * as actions from './domains.actions';

@Injectable()
export class DomainsEffects {
  constructor(
    private actions$: Actions,
    private domainsService: DomainsService,
    public dialog: MatDialog,
    private router: Router,
    private translateService: TranslateService,
    private termsService: TermsService,
    private sessionStorageService: SessionStorageService,
    private store$: Store<AppState>,
    private lgpd: LgpdService,
    private shopkeeperService: ShopkeeperService
  ) {}

  @Effect()
  getFormRegisterPj = this.actions$.pipe(
    ofType<actions.GetDomainsRegister>(actions.DomainsActionTypes.GET_DOMAINS_REGISTER),
    switchMap(action =>
      this.domainsService.getDomains().pipe(
        switchMap(response => {
          return [new actions.GetDomainsRegisterSuccess(response)];
        }),
        catchError(err => {
          return of(new actions.GetDomainsRegisterError(err));
        })
      )
    )
  );

  /**
   * Roteiro termos login
   */
  @Effect({ dispatch: false })
  getTermsClauses = this.actions$.pipe(
    ofType<actions.GetTerms>(actions.DomainsActionTypes.GET_TERMS),
    withLatestFrom(this.store$.select(fromTab.selectors.selectSelectedTab), this.store$.select('login')),
    switchMap(([action, selectedTab, login]) =>
      this.termsService.getTerms().pipe(
        map(response => {
          // configure terms modal and data
          console.log(response, 'abrir dialog');
          const dialogConfig = new MatDialogConfig();
          dialogConfig.disableClose = true;
          dialogConfig.id = 'pion-no-padding-dialog';
          // dialogConfig.maxWidth = '600px'; // width applied in css file of the component
          dialogConfig.height = '90%';
          dialogConfig.autoFocus = false;
          dialogConfig.backdropClass = 'bg-dialog-blocked'; // remove transparency from the background of the dialog

          dialogConfig.data = response.listTerms;

          // open modal
          const termsModal = this.dialog.open(TermsModalComponent, dialogConfig);

          const currentTutorialFlag = login.showTutorial;

          // send post request when accpet event emit
          termsModal.componentInstance.accept.subscribe(term => {
            this.domainsService
              .updateFlags({
                showTerms: false,
                showTutorial: currentTutorialFlag,
                termId: term.code
              })
              .subscribe(
                () => {
                  termsModal.close();
                  this.store$.dispatch(new loginActions.GetUserProperties({ isAfterShowTerms: true }));

                  this.store$
                    .pipe(
                      select('login'),
                      first()
                    )
                    .subscribe(state => {
                      if (state.isShowModalDaysToExpire) {
                        const modalExpiration = this.handleOpenExpirationModal();

                        modalExpiration.componentInstance.goToReset.subscribe(() =>
                          this.router.navigate(['/login/recovery/pre-token', { fType: 'password-recovery' }])
                        );

                        modalExpiration.componentInstance.skip.subscribe(() => {
                          this.store$.dispatch(new profileActions.ProfileRequest(selectedTab.code));
                          this.hasNoAccreditedAgentModal(selectedTab.code);
                          this.router.navigate(['/showcase']);
                        });
                      } else {
                        this.store$.dispatch(new profileActions.ProfileRequest(selectedTab.code));
                        this.hasNoAccreditedAgentModal(selectedTab.code);
                        this.router.navigate(['/showcase']);
                      }
                    });
                },
                error => {
                  console.log('erro', error);
                }
              );
          });

          // Close modal and redirect to logoff screen if decline
          termsModal.componentInstance.skip.subscribe(() => {
            this.dialog.closeAll();

            this.router
              .navigate(['/login'], {
                state: null,
                replaceUrl: true
              })
              .then(() => {
                this.sessionStorageService.clearUser();
                this.store$.dispatch(new GetClientCredentialsAction());
              });
          });
        }),
        catchError(error => {
          return of(new actions.GetTermsError(error));
        })
      )
    )
  );

  handleOpenExpirationModal() {
    let daysToExpire;
    this.store$
      .select('login')
      .pipe(first())
      .subscribe(state => (daysToExpire = state.daysToExpire));

    return this.dialog.open(ExpirationModalComponent, {
      disableClose: true,
      id: 'expiration-modal-component',
      width: '510px',
      autoFocus: false,
      data: {
        daysToExpire: daysToExpire
      }
    });
  }

  /**
   * Handle if should display the tutorial modal after expiration modal.
   */
  handleOpenModalTutor() {
    console.log('handle tutor modal if expiration | domains effect');

    const expirationDialog = this.dialog.getDialogById('expiration-modal-component');

    // if no expirationModal opened.. open tutor modal
    if (!expirationDialog) {
      this.store$.dispatch(new ShowTourTutorialShowcaseAction());
    } else {
      // if expirationModal is opened..
      // subscribe to expirationModal and open tutor after it closes
      expirationDialog.afterClosed().subscribe(() => {
        this.store$.dispatch(new ShowTourTutorialShowcaseAction());
      });
    }
  }

  hasNoAccreditedAgentModal(shopCode) {
    let showModal = false;
    this.store$.select(fromTab.selectors.selectProducts).subscribe(products => {
      products.map(product => {
        if (product.code === 'CDC') {
          showModal = true;
        }
      });
    });

    if (showModal) {
      this.shopkeeperService.getAgent(shopCode).subscribe(
        res => {
          if (res.length === 0) {
            this.openAccreditedAgentModal();
          }
        },
        error => {
          this.openAccreditedAgentModal();
        }
      );
    }
  }

  openAccreditedAgentModal() {
    this.dialog.open(PionModalComponent, {
      data: {
        title: this.translateService.instant('CERTIFIED-AGENT-NOT-EXISTS'),
        description: this.translateService.instant('CERTIFIED-AGENT-NOT-EXISTS-MSG'),
        confirmText: 'Entendi',
        type: 'error'
      },
      width: '500px'
    });
  }
}
