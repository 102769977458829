<div class="wrapper">
  <div class="simulation-section simulation-section-height">
    <div class="card-infor mb-5 cashback-card" *ngIf="analysisShow">
      <img src="./assets/images/info-white.svg" class="imagem" />
      <span class="customText">{{
        'WE REMIND YOU THAT THIS PROPOSAL WILL UNDERGO AN ADDITIONAL CREDIT ANALYSIS AFTER THE FORMALIZATION STAGE'
        | translate
        }}.</span>
    </div>
    <header class="section-header">
      <h1 class="header-title">{{ 'SIMULATION-SUMARY-RESUME' | translate }}</h1>
      <p [class]="proposal?.proposalData?.product" *ngIf="innerWidth >= MOBILE_BREAKPOINT">
        {{ proposal?.proposalData?.product }}
      </p>
    </header>

    <div class="section-main">
      <div class="row">
        <div class="col-md-4 pt-4">
          <p class="gray text-sm mr" *ngIf="proposal?.proposalData?.product === productCode.CDC">
            {{ 'SIMULATION-SUMARY-FINANCED-VALUE' | translate }}
          </p>
          <p class="gray text-sm mr" *ngIf="proposal?.proposalData?.product === productCode.CSC">
            {{ 'SIMULATION-SUMARY-FINANCED-VALUE-CSC' | translate }}
          </p>
          <p class="gray text-sm mr" *ngIf="proposal?.proposalData?.product === productCode.CSCP">
            {{ 'SIMULATION-SUMARY-FINANCED-VALUE-CSCP' | translate }}
          </p>
          <p class="text-lg bold mb-4">
            {{ proposal?.financedValue | localeCurrency }}
          </p>

          <p class="gray text-sm mr">{{ 'SIMULATION-SUMARY-ENTRY' | translate }}</p>
          <p class="text-lg bold mb-4">
            {{ proposal?.entryValue | localeCurrency }}
          </p>
          <span *ngIf="
              proposal?.planType === paymentPlanType.STANDARD_PLAN && !proposal?.planTypeSchemaData.hasFlexInstallments
            ">
            <p class="gray text-sm mr">
              {{ proposal?.installmentTotalQuantity }}x{{ 'SIMULATION-SUMARY-OF' | translate }}
            </p>
            <p class="text-lg bold mb-4">
              {{ proposal?.installmentValue | localeCurrency }}
            </p>
            <p class="title-txt mb-4" *ngIf="innerWidth <= MOBILE_BREAKPOINT">
              {{ 'SIMULATION-SUMARY-TITLE' | translate }}
            </p>
          </span>
          <span *ngIf="
              proposal?.planType != paymentPlanType.STANDARD_PLAN || proposal?.planTypeSchemaData.hasFlexInstallments
            ">
            <p class="gray text-sm mr">
              {{ 'SIMULATION-SUMARY-QTINSTALLMENTS' | translate }}
            </p>
            <p class="text-lg bold mb-4">
              {{ proposal?.installmentTotalQuantity }}
            </p>
          </span>
          <!-- RESPONSIVE COLUMNS - BEGIN -->
          <div *ngIf="innerWidth <= MOBILE_BREAKPOINT">
            <div class="">
              <div class="row details-row">
                <div class="pl-0 col">
                  <p class=" gray text-sm col-md-4">
                    {{ 'SIMULATION-SUMARY-FIRST-DUE-DATE' | translate }}
                  </p>
                  <p class="col-md-4">
                    {{ proposal?.firstInstallmentDate | date: 'dd/MM/yyyy' }}
                  </p>
                </div>
                <div class="col">
                  <p class="gray text-sm col-md-8 pr-0 pl-0">
                    {{ 'SIMULATION-SUMARY-PAYMENT-FORM' | translate }}
                  </p>
                  <p class="col-md-8  pr-0 pl-0">
                    {{ proposal?.liquidationType }}
                  </p>
                </div>
              </div>

              <div class="row details-row">
                <div class="pl-0 col">
                  <p class="gray text-sm col-md-6">
                    {{ 'SIMULATION-SUMARY-INTEREST-RATE' | translate }}
                  </p>
                  <div class="col-md-6">
                    <div>{{ proposal?.interestRate }}{{ 'SIMULATION-SUMARY-PER-MONTH' | translate }}.</div>
                    <div>{{ proposal?.interestRateByYear }}{{ 'SIMULATION-SUMARY-PER-YEAR' | translate }}.</div>
                  </div>
                </div>
                <div class="col">
                  <p class="gray text-sm col-md-6  pr-0 pl-0">
                    {{ 'SIMULATION-SUMARY-PAYMENT-PLAN' | translate }}
                  </p>
                  <div class="col-md-6  pr-0 pl-0">
                    <div class="d-flex flex-row">
                      <span *ngIf="
                          proposal?.planType != paymentPlanType.STANDARD_PLAN ||
                          proposal?.planTypeSchemaData.hasFlexInstallments
                        ">
                        {{ 'SIMULATION-SUMARY-CUSTOM-PLAN' | translate }}
                      </span>
                      <span *ngIf="
                          proposal?.planType === paymentPlanType.STANDARD_PLAN &&
                          !proposal?.planTypeSchemaData.hasFlexInstallments
                        ">
                        {{ proposal?.planTypeSchemaData.planTypeDescription }}
                      </span>

                      <app-tooltip-payment-plan class="font-size" [alignTo]="'left'" appTagger
                        [event_category]="category" event_label="tooltip"
                        [toolTextTitle]="getToolTipText"></app-tooltip-payment-plan>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row details-row">
                <div class="pl-0 col">
                  <p class="gray text-sm col-md-6">
                    {{ 'SIMULATION-SUMARY-IOF' | translate }}
                    <mat-icon class="info-icon icon-v-inverse"
                      matTooltip="{{ 'SIMULATION-SUMARY-IOF-TOOLTIP' | translate }}" matTooltipPosition="after"
                      matTooltipHideDelay="5000" appTagger [listHoverEvent]="true" [event_category]="category"
                      event_label="ajuda - {{ 'SIMULATION-SUMARY-IOF-TOOLTIP' | translate }}">error_outline
                    </mat-icon>
                  </p>
                  <P class="col-md-6">
                    {{ proposal?.iof | localeCurrency }}
                  </P>
                </div>
                <div class="col">
                  <p class="gray text-sm col-md-6  pr-0 pl-0">
                    {{ 'SIMULATION-SUMARY-REGISTER-TARIFF' | translate }}
                  </p>

                  <!-- Condições -->
                  <p *ngIf="proposal?.registrationFeeType === RegistrationFee.IN_INSTALLMENTS"
                    class="col-md-6  pr-0 pl-0">
                    {{ 'SIMULATION-SUMARY-REGISTER-TARIFF-IN-INSTALLMENTS' | translate }}
                  </p>
                  <p *ngIf="
                      proposal?.registrationFeeType === RegistrationFee.IN_CASH ||
                      proposal?.registrationFeeType === RegistrationFee.EXEMPTION_REQUEST
                    " class="col-md-6  pr-0 pl-0">
                    {{ proposal?.registerTariff | localeCurrency }}
                  </p>
                  <p *ngIf="proposal?.registrationFeeType === RegistrationFee.EXEMPT_CUSTOMER"
                    class="col-md-6  pr-0 pl-0">
                    {{ 'SIMULATION-SUMARY-REGISTER-TARIFF-EXEMPT-CUSTOMER' | translate }}
                  </p>
                  <!--/ Condições -->
                </div>
              </div>

              <div class="row details-row">
                <div class="pl-0 col">
                  <p class="gray text-sm col-md-6 pr-0">
                    {{ 'SIMULATION-SUMARY-INSURANCE' | translate }}
                    <mat-icon class="info-icon icon-v-inverse"
                      matTooltip="{{ 'SIMULATION-SUMARY-FINANCIAL-PROTECTION-TOOLTIP' | translate }}"
                      matTooltipPosition="after" matTooltipHideDelay="5000" appTagger [listHoverEvent]="true"
                      [event_category]="category"
                      event_label="ajuda - {{ 'SIMULATION-SUMARY-FINANCIAL-PROTECTION-TOOLTIP' | translate }}">
                      error_outline
                    </mat-icon>
                  </p>
                  <p class="col-md-6">
                    {{ proposal?.insuranceValue | localeCurrency }}
                  </p>
                </div>
                <div class="col">
                  <p class="gray text-sm col-md-6  pr-0 pl-0">
                    {{ 'SIMULATION-SUMARY-RETURN-RATE' | translate }}
                    <mat-icon class="info-icon icon-v-inverse"
                      matTooltip="{{ 'SIMULATION-SUMARY-CET-TOOLTIP' | translate }}" matTooltipPosition="after"
                      matTooltipHideDelay="5000" appTagger [listHoverEvent]="true" [event_category]="category"
                      event_label="ajuda - {{ 'SIMULATION-SUMARY-CET-TOOLTIP' | translate }}">error_outline
                    </mat-icon>
                  </p>
                  <div class="col-md-6  pr-0 pl-0">
                    <div>{{ proposal?.returnRate }}{{ 'SIMULATION-SUMARY-PER-MONTH' | translate }}.</div>
                    <div>{{ proposal?.returnRateByYear }}{{ 'SIMULATION-SUMARY-PER-YEAR' | translate }}.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- RESPONSIVE COLUMNS - END -->
        </div>
        <div class="col-md-8 col-sm-6" *ngIf="innerWidth > MOBILE_BREAKPOINT">
          <div class="row no-gutters details-row">
            <span class="gray text-sm col-md-4">
              {{ 'SIMULATION-SUMARY-FIRST-DUE-DATE' | translate }}
            </span>
            <span class="col-md-8">
              {{ proposal?.firstInstallmentDate | date: 'dd/MM/yyyy' }}
            </span>
          </div>

          <div class="row no-gutters details-row">
            <div class="gray text-sm col-md-4">
              {{ 'SIMULATION-SUMARY-PAYMENT-FORM' | translate }}
            </div>
            <span class="col-md-8">
              {{ proposal?.liquidationType }}
            </span>
          </div>

          <div class="row no-gutters details-row" *ngIf="proposal?.proposalData?.product !== productCode.CSC && proposal?.proposalData?.product !== productCode.CSCP">
            <div class="gray text-sm col-md-4">
              {{ 'SIMULATION-SUMARY-INTEREST-RATE' | translate }}
            </div>
            <span class="col-md-4"> {{ proposal?.interestRate }}{{ 'SIMULATION-SUMARY-PER-MONTH' | translate }}. </span>
            <span class="col-md-4">
              {{ proposal?.interestRateByYear }}{{ 'SIMULATION-SUMARY-PER-YEAR' | translate }}.
            </span>
          </div>
          <div class="row no-gutters details-row">
            <div class="gray text-sm col-md-4">
              {{ 'SIMULATION-SUMARY-PAYMENT-PLAN' | translate }}
            </div>
            <span class="col-md-8">
              <div class="d-flex flex-row">
                <span *ngIf="
                    proposal?.planType != paymentPlanType.STANDARD_PLAN ||
                    proposal?.planTypeSchemaData.hasFlexInstallments
                  ">{{ 'SIMULATION-SUMARY-CUSTOM-PLAN' | translate }}</span>
                <span *ngIf="
                    proposal?.planType === paymentPlanType.STANDARD_PLAN &&
                    !proposal?.planTypeSchemaData.hasFlexInstallments
                  ">{{ proposal?.planTypeSchemaData.planTypeDescription }}</span>
                <app-tooltip-payment-plan [toolTextTitle]="getToolTipText" class="font-size"
                  [alignTo]="'left'"></app-tooltip-payment-plan>
              </div>
            </span>
          </div>

          <div *ngIf="proposal?.proposalData?.product !== productCode.CSC && proposal?.proposalData?.product !== productCode.CSCP">
            <div class="row no-gutters details-row">
              <div class="gray text-sm col-md-4">
                {{ 'SIMULATION-SUMARY-IOF' | translate }}
                <mat-icon class="info-icon icon-v-inverse"
                  matTooltip="{{ 'SIMULATION-SUMARY-IOF-TOOLTIP' | translate }}" matTooltipPosition="after"
                  matTooltipHideDelay="5000" appTagger [listHoverEvent]="true" [event_category]="category"
                  event_label="ajuda - {{ 'SIMULATION-SUMARY-IOF-TOOLTIP' | translate }}">error_outline
                </mat-icon>
              </div>
              <div class="col-md-8">
                {{ proposal?.iof | localeCurrency }}
              </div>
            </div>

            <!-- Tarifa de cadastro -->
            <div class="row no-gutters details-row">
              <div class="gray text-sm col-md-4">
                {{ 'SIMULATION-SUMARY-REGISTER-TARIFF' | translate }}
              </div>
              <span *ngIf="proposal?.registrationFeeType === RegistrationFee.IN_INSTALLMENTS" class="col-md-8">
                {{ 'SIMULATION-SUMARY-REGISTER-TARIFF-IN-INSTALLMENTS' | translate }}
              </span>
              <span *ngIf="
                  proposal?.registrationFeeType === RegistrationFee.IN_CASH ||
                  proposal?.registrationFeeType === RegistrationFee.EXEMPTION_REQUEST
                " class="col-md-8">
                {{ proposal?.registerTariff | localeCurrency }}
              </span>
              <span *ngIf="proposal?.registrationFeeType === RegistrationFee.EXEMPT_CUSTOMER" class="col-md-8">
                {{ 'SIMULATION-SUMARY-REGISTER-TARIFF-EXEMPT-CUSTOMER' | translate }}
              </span>
            </div>
            <!--/ Tarifa de cadastro -->

            <div class="row no-gutters details-row">
              <div class="gray text-sm col-md-4">
                {{ 'SIMULATION-SUMARY-INSURANCE' | translate }}
                <mat-icon class="info-icon icon-v-inverse"
                  matTooltip="{{ 'SIMULATION-SUMARY-FINANCIAL-PROTECTION-TOOLTIP' | translate }}"
                  matTooltipPosition="after" matTooltipHideDelay="5000" appTagger [listHoverEvent]="true"
                  [event_category]="category"
                  event_label="ajuda - {{ 'SIMULATION-SUMARY-FINANCIAL-PROTECTION-TOOLTIP' | translate }}">error_outline
                </mat-icon>
              </div>
              <div class="col-md-8">
                {{ proposal?.insuranceValue | localeCurrency }}
              </div>
            </div>

            <div class="row no-gutters details-row">
              <div class="gray text-sm col-md-4">
                {{ 'SIMULATION-SUMARY-RETURN-RATE' | translate }}
                <mat-icon class="info-icon icon-v-inverse"
                  matTooltip="{{ 'SIMULATION-SUMARY-CET-TOOLTIP' | translate }}" matTooltipPosition="after"
                  matTooltipHideDelay="5000" appTagger [listHoverEvent]="true" [event_category]="category"
                  event_label="ajuda - {{ 'SIMULATION-SUMARY-CET-TOOLTIP' | translate }}">error_outline
                </mat-icon>
              </div>
              <span class="col-md-4"> {{ proposal?.returnRate }}{{ 'SIMULATION-SUMARY-PER-MONTH' | translate }}. </span>
              <span class="col-md-4">
                {{ proposal?.returnRateByYear }}{{ 'SIMULATION-SUMARY-PER-YEAR' | translate }}.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="row footer">
        <div class="col-md-12">
          <div class="d-flex align-items-center justify-content-between mt-4 flex-row">
            <button mat-stroked-button id="summary-voltar-button" color="primary"
              class="btn d-none d-md-block d-lg-block" [routerLink]="['/proposal/step-simulation']" appTagger
              [listHoverEvent]="true" [event_category]="category" (click)="simulationSumaryBackEdit()"
              event_label="{{ 'SIMULATION-SUMARY-BACK-EDIT-BUTTON' | translate }}">
              {{ 'SIMULATION-SUMARY-BACK-EDIT-BUTTON' | translate }}
            </button>
            <button mat-flat-button id="summary-continuar-button" color="primary" class="btn" (click)="goRegister()"
              appTagger [listHoverEvent]="true" [event_category]="category"
              [event_label]="'SIMULATION-SUMARY-CONTINUE-REGISTER-BUTTON' | translate">
              {{ 'SIMULATION-SUMARY-CONTINUE-REGISTER-BUTTON' | translate }}
            </button>
            <div class="pt-5 mt-5 d-md-none"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
