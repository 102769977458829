import { Action } from '@ngrx/store';

export enum ChallengeActionTypes {
  START_CHALLENGE = '[Challenge] Start Challenge',
  START_CHALLENGE_SUCCESS = '[Challenge] Start Challenge Success',

  GET_CHALLENGE = '[Challenge] Get Challenge',
  GET_CHALLENGE_SUCCESS = '[Challenge] Get Challenge Success',

  ANSWER_CHALLENGE = '[Challenge] Answer Challenge',
  ANSWER_CHALLENGE_SUCCESS = '[Challenge] Answer Challenge Successs',
  ANSWER_CHALLENGE_ERROR = '[Challenge] Answer Challenge Error',

  ERROR_CHALLENGE = '[Challenge] Error Challenge',

  SET_CHALLENGE_STATUS = '[Challenge] Set Challenge Stauts',

  REDIRECT_TOKEN = '[Challenge] Redirect Token',
  REDIRECT_POSITIVE_VALIDATION = '[Challenge] Redirect Positive Validation',
  REDIRECT_REGISTER_FORM = '[Challenge] Redirect Register Form',
  REDIRECT_BIOMETRY = '[Challenge] Redirect Biometry',
  REDIRECT_TERM = '[Challenge] Redirect Biometry',
  REDIRECT_PASSWORD = '[Challenge] Redirect Password',
  REDIRECT_LOGIN = '[Challenge] Redirect Login',
  REDIRECT_SIGN_UP_DATA = '[Challenge] Redirect Sign Up Data',

  CHECK_STATUS = '[Challenge] Check status',

  SHOW_ERROR_RETRY_BIOMETRY_CHALLENGE = '[Challenge] Show Error Retry Biometry Challenge',
  SHOW_RETRY_MODAL_BIOMETRY_CHALLENGE = '[Challenge] Show Retry Modal Biometry Challenge',
  RETRY_BIOMETRY_CHALLENGE = '[Challenge] Retry Biometry Challenge',

  GET_CROSSED_FLUX_INFO = '[Challenge] [App] Get Crossed Flux Info',
  GET_CROSSED_FLUX_INFO_SUCCESS = '[Challenge] [App] Get Crossed Flux Info Success',
  GET_CROSSED_FLUX_INFO_ERROR = '[Challenge] [App] Get Crossed Flux Info Error',
  GET_CROSSED_FLUX_LINK = '[Challenge] [App] Get Crossed Flux Link',
  GET_CROSSED_FLUX_LINK_SUCCESS = '[Challenge] [App] Get Crossed Flux Link Success',
  GET_CROSSED_FLUX_LINK_ERROR = '[Challenge] [App] Get Crossed Flux Link Error',
  SET_CROSSED_FLUX = '[Challenge] [App] Set Crossed Flux',

  CHECK_BIOMETRY_STATUS = '[Challenge] [App] Check Biometry Status',
  CHECK_BIOMETRY_STATUS_SUCCESS = '[Challenge] [App] Check Biometry Status Success',
  CHECK_BIOMETRY_STATUS_ERROR = '[Challenge] [App] Check Biometry Status Error',
  CLEAR_BIOMETRY_STATUS = '[Challenge] [App] Clear Biometry Status',

  SET_CROSSED_FLUX_BIOMETRY_STATUS = '[Challenge] [App] Set Crossed Flux Biometry Status',

  GO_TO_NEXT_CHALLENGE = '[Challenge] Go to next Challenge'
}

export class CheckStatus implements Action {
  readonly type = ChallengeActionTypes.CHECK_STATUS;

  constructor(public payload: any = null) {}
}

export class SetChallengeStatus implements Action {
  readonly type = ChallengeActionTypes.SET_CHALLENGE_STATUS;

  constructor(public payload: any) {}
}

export class StartChallenge implements Action {
  readonly type = ChallengeActionTypes.START_CHALLENGE;

  constructor(public payload: any) {}
}

export class StartChallengeSuccess implements Action {
  readonly type = ChallengeActionTypes.START_CHALLENGE_SUCCESS;

  constructor(public payload: any) {}
}

export class GetChallenge implements Action {
  readonly type = ChallengeActionTypes.GET_CHALLENGE;

  constructor(public payload: any) {}
}

export class GetChallengeSuccess implements Action {
  readonly type = ChallengeActionTypes.GET_CHALLENGE_SUCCESS;

  constructor(public payload: any) {}
}

export class AnswerChallenge implements Action {
  readonly type = ChallengeActionTypes.ANSWER_CHALLENGE;

  constructor(public payload: any) {}
}

export class AnswerChallengeSuccess implements Action {
  readonly type = ChallengeActionTypes.ANSWER_CHALLENGE_SUCCESS;

  constructor(public payload: any) {}
}

export class AnswerChallengeError implements Action {
  readonly type = ChallengeActionTypes.ANSWER_CHALLENGE_ERROR;

  constructor(public payload: any) {}
}

export class ErrorChallenge implements Action {
  readonly type = ChallengeActionTypes.ERROR_CHALLENGE;

  constructor(public payload: any) {}
}

export class RedirectToken implements Action {
  readonly type = ChallengeActionTypes.REDIRECT_TOKEN;

  constructor() {}
}

export class RedirectPositiveValidation implements Action {
  readonly type = ChallengeActionTypes.REDIRECT_POSITIVE_VALIDATION;

  constructor() {}
}

export class RedirectRegisterForm implements Action {
  readonly type = ChallengeActionTypes.REDIRECT_SIGN_UP_DATA;

  constructor() {}
}

export class RedirectBiometry implements Action {
  readonly type = ChallengeActionTypes.REDIRECT_BIOMETRY;

  constructor() {}
}

export class RedirectTerm implements Action {
  readonly type = ChallengeActionTypes.REDIRECT_TERM;

  constructor() {}
}

export class RedirectPassword implements Action {
  readonly type = ChallengeActionTypes.REDIRECT_PASSWORD;

  constructor() {}
}

export class RedirectLogin implements Action {
  readonly type = ChallengeActionTypes.REDIRECT_LOGIN;

  constructor() {}
}

export class RedirectSignUpData implements Action {
  readonly type = ChallengeActionTypes.REDIRECT_SIGN_UP_DATA;

  constructor() {}
}

export class ShowErrorRetryBiometryChallenge implements Action {
  readonly type = ChallengeActionTypes.SHOW_ERROR_RETRY_BIOMETRY_CHALLENGE;

  constructor() {}
}

export class ShowRetryModalBiometryChallenge implements Action {
  readonly type = ChallengeActionTypes.SHOW_RETRY_MODAL_BIOMETRY_CHALLENGE;

  constructor() {}
}

export class SetRetryBiometryChallenge implements Action {
  readonly type = ChallengeActionTypes.RETRY_BIOMETRY_CHALLENGE;

  constructor(public isRetry: boolean) {}
}

export class GetCrossedFluxInfoAction implements Action {
  readonly type = ChallengeActionTypes.GET_CROSSED_FLUX_INFO;
  constructor(public payload: any) {}
}

export class GetCrossedFluxInfoSuccessAction implements Action {
  readonly type = ChallengeActionTypes.GET_CROSSED_FLUX_INFO_SUCCESS;
  constructor(public payload: any) {}
}

export class GetCrossedFluxLinkAction implements Action {
  readonly type = ChallengeActionTypes.GET_CROSSED_FLUX_LINK;
  constructor() {}
}

export class GetCrossedFluxLinkSuccessAction implements Action {
  readonly type = ChallengeActionTypes.GET_CROSSED_FLUX_LINK_SUCCESS;
  constructor(public payload: any) {}
}
export class GetCrossedFluxLinkErrorAction implements Action {
  readonly type = ChallengeActionTypes.GET_CROSSED_FLUX_LINK_ERROR;
  constructor(public payload: any) {}
}

export class GetCrossedFluxInfoErrorAction implements Action {
  readonly type = ChallengeActionTypes.GET_CROSSED_FLUX_INFO_ERROR;
  constructor(public payload: any) {}
}

export class SetCrossedFluxAction implements Action {
  readonly type = ChallengeActionTypes.SET_CROSSED_FLUX;
  constructor(public payload: any) {}
}

export class CheckBiometryStatusAction implements Action {
  readonly type = ChallengeActionTypes.CHECK_BIOMETRY_STATUS;
  constructor(public payload?: any) {}
}

export class CheckBiometryStatusSuccessAction implements Action {
  readonly type = ChallengeActionTypes.CHECK_BIOMETRY_STATUS_SUCCESS;
  constructor(public payload: any) {}
}

export class CheckBiometryStatusErrorAction implements Action {
  readonly type = ChallengeActionTypes.CHECK_BIOMETRY_STATUS_ERROR;
  constructor(public payload: any) {}
}

export class ClearBiometryStatusAction implements Action {
  readonly type = ChallengeActionTypes.CLEAR_BIOMETRY_STATUS;
  constructor() {}
}
export class SetCrossedFluxBiometryStatusAction implements Action {
  readonly type = ChallengeActionTypes.SET_CROSSED_FLUX_BIOMETRY_STATUS;
  constructor(public payload) {}
}

export class GoToNextChallengeAction implements Action {
  readonly type = ChallengeActionTypes.GO_TO_NEXT_CHALLENGE;
  constructor(public payload) {}
}

export type ChallengeActions =
  | SetChallengeStatus
  | StartChallenge
  | GetChallenge
  | AnswerChallenge
  | ErrorChallenge
  | RedirectToken
  | RedirectPositiveValidation
  | RedirectRegisterForm
  | RedirectBiometry
  | RedirectTerm
  | RedirectPassword
  | StartChallengeSuccess
  | GetChallengeSuccess
  | AnswerChallengeSuccess
  | AnswerChallengeError
  | RedirectLogin
  | CheckStatus
  | RedirectSignUpData
  | ShowRetryModalBiometryChallenge
  | SetRetryBiometryChallenge
  | GetCrossedFluxInfoAction
  | GetCrossedFluxInfoSuccessAction
  | GetCrossedFluxLinkAction
  | GetCrossedFluxLinkSuccessAction
  | GetCrossedFluxLinkErrorAction
  | GetCrossedFluxInfoErrorAction
  | SetCrossedFluxAction
  | CheckBiometryStatusSuccessAction
  | CheckBiometryStatusErrorAction
  | ClearBiometryStatusAction
  | CheckBiometryStatusAction
  | SetCrossedFluxBiometryStatusAction
  | GoToNextChallengeAction
  | ShowErrorRetryBiometryChallenge;
