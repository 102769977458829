import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { Domains } from '@shared/models/domains.model';
import { map } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Address } from '@shared/models/address.model';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  constructor(private http: HttpClient) {}

  zipCodeSearch(zipCode: string): Observable<Address> {
    return this.http
      .get(`${environment.apiRootUrl}/sistema/1.0.0/ceps`, {
        params: {
          codigoCEP: zipCode
        }
      })
      .pipe(
        map((response: any) => {
          if (response.ceps) {
            return response.ceps[0] as Address;
          }
          return null;
        })
      );
  }

  searchCity(name: string, idState: string): Observable<Domains[]> {
    if (!name || !idState) {
      return of([]);
    }
    let search;
    if (typeof name === 'string') {
      search = { nomeMunicipio: name };
    } else {
      search = { codigoMunicipio: name };
    }
    return this.http
      .get(`${environment.apiRootUrl}/sistema/1.0.0/municipios`, {
        params: {
          page: '1',
          identificadorUF: idState.toString(),
          ...search
        }
      })
      .pipe(
        map((response: any) => {
          return response.municipios.map(c => {
            return <Domains>{ code: c.codigoMunicipio, description: c.nomeMunicipio };
          });
        })
      );
  }
}
