/**
 * NOTE: Talvez fique redundante com o profile enum;
 * refatorar se necessario (remover profile enum e
 * se UserPreferences tbm estiver redundante com HomePage
 * no state de Profile tbm refatorar)
 */
export enum InitialScreenOptions {
  NewProposal = 'CRIAR_PROP',
  Panel = 'LPRP'
}
