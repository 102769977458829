<div class="notification mt-2">
  <div class="row mb-1">
    <div class="col">
      <h4>Colaborador</h4>
    </div>
  </div>
  <div class="row mb-2">
    <div class="col-sm-5">
      <strong
        ><h2>{{ data.name }}</h2></strong
      >
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p>Tipo</p>
      <div class="square">
        <div class="block">
          <div class="master" *ngIf="data.type === 'M'">
            <strong><p>MAS</p></strong>
          </div>
          <div class="user" *ngIf="data.type === 'U'">
            <strong><p>USU</p></strong>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <p>CPF</p>
      <h3>{{ data.documentNumber }}</h3>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <p>Status</p>
      <img
        *ngIf="data.isRead"
        src="./assets/green_check.svg"
        placement="botton"
        ngbTooltip="Tooltip on top"
        alt="close"
      />
      <img *ngIf="!data.isRead" src="./assets/error.svg" placement="botton" ngbTooltip="Tooltip on top" alt="close" />
    </div>
    <div class="col">
      <p>Perfis</p>
      <img src="./assets/santander/icons/vendedor.svg" alt="vendedor" />
      <img src="./assets/santander/icons/revendedor.svg" alt="revendedor" />
      <img src="./assets/santander/icons/fiscal.svg" alt="fiscal" />
    </div>
  </div>
</div>
<!-- <p class="time">
      {{ data.creation | date: 'dd/MM/yy h:mm ' }}
    </p> -->
