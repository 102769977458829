import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state/index';
import * as fromProfile from '@app/core/state/profile';
import { menuSelector } from '@app/core/state/profile/profile.selectors';
import { GetClientCredentialsAction } from '@app/core/state/startup/startup.actions';
import * as fromTab from '@app/core/state/tab';
import { ProfileRenderService } from '@app/profile/service/profile-render.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { clearManagementPanelFiltersFromHeaderNavigation } from '@shared/functions/clear-mgm-flters-from-header';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { SideContentService } from '@shared/services/side-content/side-content.service';
import { combineLatest, Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HeaderItemCode } from '../../../models/header-shopkeeper.model';
import { HeaderItem } from '../../../services/header.service';
import { ExitPageComponent } from './../exit-page/exit-page.component';

@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Output() public closeSidenav = new EventEmitter<boolean>();

  public showFiller = false;
  public user: string;
  public storeName: string;
  public mobileMenu: any;
  public actualMenuData: any;
  public subSubMenuCode: any;
  helpMenuData: Array<HeaderItem>;
  hideHeader = false;
  public category: string;

  tutorialsSubmenu = [];

  constructor(
    private router: Router,
    private store$: Store<AppState>,
    private sideContentService: SideContentService,
    private profileRenderService: ProfileRenderService,
    private dialog: MatDialog,
    private sessionStorageService: SessionStorageService,
    private genTagger: GenTagger,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.subSubMenuCode = HeaderItemCode.HEAD_HELP;

    this.store$.pipe(select(menuSelector)).subscribe((state: any) => {
      if (!state) return;

      const headerData = state.filter(menu => {
        return menu && menu.code && menu.code !== HeaderItemCode.HEAD_SUBMENU;
      });

      let subMenuData = state.filter(menu => {
        return menu && menu.code && menu.code === HeaderItemCode.HEAD_SUBMENU;
      })[0];

      if (subMenuData && subMenuData.submenu && subMenuData.submenu.length > 0) {
        subMenuData = subMenuData.submenu;
      }

      this.mobileMenu = [...headerData, ...subMenuData];

      this.actualMenuData = this.mobileMenu;

      this.helpMenuData = this.mobileMenu.filter(menu => {
        return menu && menu.code && menu.code === HeaderItemCode.HEAD_HELP;
      });

      if (this.helpMenuData && this.helpMenuData.length > 0 && this.helpMenuData[0].submenu) {
        this.helpMenuData = this.helpMenuData[0].submenu;

        const tutorialsSubmenu = this.helpMenuData.filter(item => item.code === HeaderItemCode.HEAD_HELP_TUTORIAL);

        this.tutorialsSubmenu = tutorialsSubmenu[0] ? tutorialsSubmenu[0].submenu : [];
      }
    });

    this.setProfileSubscription();
    this.setSideContentListener();
  }

  private setProfileSubscription(): void {
    const profile$ = this.store$.select(fromProfile.selectors.userProfileSelector).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(state => !!state)
    );

    const selectedStore$ = this.store$.select(fromTab.selectors.selectSelectedTab).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(state => !!state)
    );

    combineLatest([profile$, selectedStore$]).subscribe(([profile, selectedStore]) => {
      this.storeName = selectedStore.name;
      if (profile.nickName) {
        this.user = profile.nickName;
        return;
      }
      const fullName = profile.name.split(' ');
      this.user = [fullName.splice(0, 1), fullName.splice(-1)].join(' ');
    });
  }

  switchOptions(): void {
    if (this.actualMenuData === this.mobileMenu) {
      this.actualMenuData = this.helpMenuData;
      this.hideHeader = true;
    } else {
      this.actualMenuData = this.mobileMenu;
      this.hideHeader = false;
    }
  }

  resetOptions(): void {
    // back to help if displaying tutorials list
    if (this.actualMenuData === this.tutorialsSubmenu) {
      this.actualMenuData = this.helpMenuData;
      this.hideHeader = true;
    } else {
      this.actualMenuData = this.mobileMenu;
      this.hideHeader = false;
    }
  }

  setSideContentListener() {
    this.sideContentService.sideContentcloseEvent.subscribe(shouldClose => {
      if (shouldClose) {
        this.closeSidenav.emit();
      }
    });
  }

  public choiceActionLabel(actionType, targetAddress): void {
    // if not targetAddress set menu items to tutorials
    if (actionType === 'callComponent' && !targetAddress) {
      this.actualMenuData = this.tutorialsSubmenu;
    }

    switch (actionType) {
      case 'routeTo':
        this.closeSidenav.emit();
        clearManagementPanelFiltersFromHeaderNavigation(targetAddress, this.store$);

        this.router.navigate([targetAddress]);

        // reset menu to initial
        this.actualMenuData = this.mobileMenu;
        this.hideHeader = false;

        break;
      case 'callComponent':
        this.profileRenderService.loadComponent(targetAddress);
        break;
      default:
        console.log(actionType + ' not defined for: ' + targetAddress);
        break;
    }
  }

  public logout(): void {
    const dialogRef = this.dialog.open(ExitPageComponent, {
      width: '520px',
      disableClose: true,
      data: {}
    });

    this.closeSidenav.emit();

    dialogRef.afterClosed().subscribe(result => {
      switch (result) {
        case 'CONTINUE':
          dialogRef.close();
          break;
        case 'EXIT':
          this.router.navigate(['/']);
          this.sessionStorageService.clearUser();
          this.store$.dispatch(new fromProfile.actions.ClearProfileAction());
          this.store$.dispatch(new GetClientCredentialsAction());
          break;
      }
    });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public menuClick(opcaoSelecionada) {
    this.category = window.location.href;
    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.Click,
      event_label: this.translate.instant(opcaoSelecionada)
    });
  }
}
