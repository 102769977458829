import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import * as fromProposal from '@app/core/state/proposal';
import { selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import { SimulationActionTypes } from '@app/core/state/simulation/simulation.actions';
import { BestOfferResponseDTO } from '@app/proposal-workflow/models/best-offer.model';
import { InputChange } from '@app/proposal-workflow/models/input-change.model';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { PaymentPlans } from '@app/proposal-workflow/models/payment-plans.enum';
import { Tax } from '@app/proposal-workflow/models/tax.model';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { environment } from '@env/environment';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { Countries } from '@shared/constants/countries.enum';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { InstallmentsModalFlexComponent } from '../installments-modal-flex/installments-modal-flex.component';
import { InstallmentsModalComponent } from '../installments-modal/installments-modal.component';
import { PaymentPlanInstallmentInfoComponent } from '../payment-plan-installment-info/payment-plan-installment-info.component';

@Component({
  selector: 'installment-step',
  templateUrl: './installment-step.component.html',
  styleUrls: ['./installment-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstallmentStepComponent implements OnInit, OnDestroy, OnChanges {
  @Output() inputChanged = new EventEmitter<InputChange>();
  @Output() public addForm = new EventEmitter<FormGroup>();
  @Output() public validatorsEmitter = new EventEmitter<boolean>();
  @Input() public installmentStepData = null;
  @Input() installmentStepData$: Observable<any>;
  @Input() public isErrorPlanFlex: boolean;
  @Input() public proposalData = null;
  @Input() bestOffer: BestOfferResponseDTO;
  dialogConfig = new MatDialogConfig();

  @ViewChild('paymentPlanInstallmentInfo', { static: true })
  paymentPlanInstallmentInfo: PaymentPlanInstallmentInfoComponent;

  protected ngUnsubscribe: Subject<any> = new Subject();

  public proposalId: string;

  public innerWidth: any;
  public MOBILE_BREAKPOINT = 768;

  public installmentOptions: FormGroup;
  public analysisShow = false;

  public paymentPlans = PaymentPlans;
  public countries = Countries;
  public selectedPeriod: string;
  public currentCountry = this.internationalizationService.currentCountry;

  currentInstallmentValue: number;

  defaultPaymentPlan: any;
  public currentPaymentPlan: any;
  public installmentValues: any;

  public isNPP = JSON.parse(environment.useNPP);
  public taxIOF: Tax;

  @Input() category: string;
  readonly Insert: Tag = Tag.Insert;
  readonly Selecionar: Tag = Tag.Selecionar;
  readonly FillingError: Tag = Tag.FillingError;

  constructor(
    public matDialog: MatDialog,
    private internationalizationService: InternationalizationService,
    private store$: Store<AppState>,
    private updates$: Actions,
    private translate: TranslateService,
    private genTagger: GenTagger,
    private simulationService: SimulationService
  ) {console.log(this.installmentStepData)}

  get installment() {
    return this.installmentOptions && this.installmentOptions.get('installment');
  }

  get paymentPlan() {
    return this.installmentOptions && this.installmentOptions.get('paymentPlan');
  }

  get invalid() {
    const code = this.isNPP ? this.currentPaymentPlan.flexPlanType : this.currentPaymentPlan.code;
    switch (code) {
      case 'LACK':
      case 'SKIP':
        if (this.paymentPlanInstallmentInfo && this.paymentPlanInstallmentInfo.infoLack) {
          return this.installmentOptions.invalid || this.paymentPlanInstallmentInfo.infoLack.invalid;
        }
      // tslint:disable-next-line: no-switch-case-fall-through
      case 'BLN':
        if (this.paymentPlanInstallmentInfo && this.paymentPlanInstallmentInfo.infoBln) {
          return this.installmentOptions.invalid || this.paymentPlanInstallmentInfo.infoBln.invalid;
        }
      // tslint:disable-next-line: no-switch-case-fall-through
      case 'FLT':
      case 'ASC':
      case 'DEC':
      default:
        return this.installmentOptions.invalid;
    }
  }

  ngOnInit() {
    this.getCurrentProposalId();
    this.simulationService
      .getProposalAnalysisCd(this.proposalId)
      .toPromise()
      .then(v => {
        this.analysisShow = v;
        window.dispatchEvent(new Event('resize'));
        this.modalAdditionalAnalysisInstallment(this.analysisShow);
      });

    this.setPlan();

    this.setValueForm();

    this.paymentPlan.valueChanges.pipe(debounceTime(500)).subscribe(newValue => {
      let month = 0;
      if (newValue.code === 'BLN') {
        month = this.isNPP ? Number(this.installmentStepData.seasonalPeriods[0].code) : Number(newValue.months[0].code);
      }
      const payload: InputChange = {
        value: {
          payment: {
            paymentPlan: this.isNPP ? newValue.flexPlanType : newValue.code
          }
        },
        input: InputTypes.PAYMENT_PLAN,
        month: month
      };

      this.currentPaymentPlan = newValue;
      if (this.installmentStepData.selectedPaymentMethod[0].code !== 'FIXED') {
        this.inputChanged.emit(payload);
      }
      this.genTagger.setTag({
        event_category: this.category,
        event_action: `${Tag.Selecionar} - ${this.translate.instant('PAYMENT-PLAN')}`,
        event_label: `${newValue.flexPlanName}`
      });
    });

    this.onResize();

    const [firstInstallment, secondInstallment] = this.installmentStepData.installments;

    this.installmentValues = {
      firstInstallmentValue: firstInstallment && firstInstallment.value ? firstInstallment.value : 0,
      monthlyInstallmentValue: secondInstallment && secondInstallment.value ? secondInstallment.value : 0
    };

    this.addForm.emit(this.installmentOptions);

    this.updates$
      .pipe(ofType(SimulationActionTypes.GET_NEW_CALCULATION_ERROR))
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((err: any) => {
        if (err.payload && err.payload.errorId === 'error.calculation.SIMRP002') {
          this.installment.markAsTouched();
          this.installment.setErrors({ incorrect: true });
        }
      });
  }

  private setPlan() {
    if (this.isNPP) {
      this.currentPaymentPlan = this.defaultPaymentPlan = this.installmentStepData.paymentMethods.find(
        x => x.flexPlanType === this.installmentStepData.selectedPaymentMethod[0].code
      );
    } else {
      this.currentPaymentPlan = this.defaultPaymentPlan = this.installmentStepData.paymentMethods.find(
        x => x.code === this.installmentStepData.selectedPaymentMethod[0].code
      );
    }
  }

  private setValueForm() {
    this.installmentOptions = new FormGroup({
      installment: new FormControl(this.installmentStepData.installmentAmount, [
        Validators.required,
        Validators.max(this.installmentStepData.maxInstallmentAmount),
        Validators.min(this.installmentStepData.minInstallmentAmount)
      ]),
      paymentPlan: new FormControl(this.defaultPaymentPlan, [Validators.required])
    });
  }

  private setValueFormInstallment() {
    this.installmentOptions.get('installment').setValue(this.installmentStepData.installmentAmount);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes &&
      changes.installmentStepData &&
      changes.installmentStepData.currentValue &&
      changes.installmentStepData.currentValue.tax &&
      changes.installmentStepData.currentValue.tax.length > 0
    ) {
      this.taxIOF = this.installmentStepData.tax.find((t: Tax) => t.description === 'IOF');
      this.setValueFormInstallment();
    }
    if (changes.installmentStepData && this.isErrorPlanFlex === true) {
      this.setPlan();
      this.setValueForm();
    }
  }

  public modalAdditionalAnalysisInstallment(showModal: boolean) {
    if (showModal) {
      this.matDialog
        .open(PionModalComponent, {
          data: {
            disableClose: true,
            title: 'Atenção',
            description:
              'Lembramos que essa proposta passará por uma análise adicional de crédito após a etapa de cadastro',
            confirmText: 'OK',
            type: 'alert'
          },
          maxWidth: '500px'
        })
        .afterClosed();
    }
  }

  public getCurrentProposalId() {
    this.store$
      .select(selectProposalId)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(proposalId => !!proposalId)
      )
      .subscribe((proposalId: string) => {
        this.proposalId = proposalId;
        this.store$.dispatch(new fromProposal.actions.GetProposalAction(proposalId));
      });
  }

  inputInstallmentChange() {
    const payload: InputChange = {
      value: {
        payment: {
          installmentAmount: this.installment.value === '' ? '0' : this.installment.value
        }
      },
      input: InputTypes.INSTALLMENT,
      type: 'Intallments'
    };

    this.installmentOptions
      .get('installment')
      .setValidators([
        Validators.required,
        Validators.max(this.installmentStepData.maxInstallmentAmount),
        Validators.min(this.installmentStepData.minInstallmentAmount)
      ]);

    this.currentInstallmentValue = this.installment.value;

    this.inputChanged.emit(payload);

    this.genTagger.setTag({
      event_category: this.category,
      event_action: `${Tag.Selecionar} - ${this.translate.instant('INSTALLMENT-AMMOUNT-FIELD-LABEL')}`,
      event_label: `${this.currentInstallmentValue}`
    });
  }

  compareObjects(o1: any, o2: any): boolean {
    return o1.code === o2.code;
  }

  compareObjectsInstallment(o1: any, o2: any): boolean {
    return o1 === o2;
  }

  compareObjectsPaymentPlan(o1: any, o2: any): boolean {
    return o1.flexPlanType === o2.flexPlanType;
  }

  // OnChanges roda antes do init
  // tenta add o setValidators na no valueChanges da linha 81
  isValidValues(res: boolean) {
    if (res) {
      this.validatorsEmitter.emit(true);
    } else {
      this.validatorsEmitter.emit(false);
    }
  }
  receivePeriodSelected(data: any) {
    this.selectedPeriod = data;
  }

  installmentInfoChangedHandler(data: any) {
    this.inputChanged.emit(data);
  }

  openInstallmentsModal() {
    this.dialogConfig = new MatDialogConfig();

    const isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;

    this.dialogConfig = {
      width: isMobile ? '100%' : '48%',
      maxWidth: '618px',
      id: 'pion-side-modal',
      panelClass: 'installments-modal-container',
      height: '100%',
      autoFocus: false,
      position: {
        right: '0px',
        top: '0px'
      },
      data: {
        installments: this.installmentStepData.installments,
        isAllowedFlexInstallments: this.installmentStepData.isAllowedFlexInstallments,
        country: this.currentCountry,
        currentPaymentPlan: this.currentPaymentPlan,
        isMobile: isMobile, // Usado para abrir o modal de installments
        close: this.closeInstallmentsModal,
        openFlexModal: this.openInstallmentsModalFlex
      }
    };

    const dialogRef = this.matDialog.open(InstallmentsModalComponent, this.dialogConfig);

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        if (result.openInstallmentFlex) {
          this.openInstallmentsModalFlex();
        }
      }
    });
  }

  closeInstallmentsModal() {}

  openInstallmentsModalFlex() {
    let dialogConfig = new MatDialogConfig();

    const isMobile = this.innerWidth < this.MOBILE_BREAKPOINT;

    dialogConfig = {
      width: isMobile ? '100%' : '48%',
      maxWidth: '669px',
      id: 'pion-side-modal',
      panelClass: 'installments-modal-container',
      height: '100%',
      autoFocus: false,
      position: {
        right: '0px',
        top: '0px'
      },
      data: {
        conditionPlanFlex: this.installmentStepData.conditionPlanFlex,
        minAllowedInstallmentValue: this.installmentStepData.minAllowedInstallmentValue,
        maxAllowedInstallmentValue: this.installmentStepData.maxAllowedInstallmentValue,
        maxAllowedFlexValue: this.installmentStepData.maxAllowedFlexValue,
        maxAllowedFlexInstallmentsNumber: this.installmentStepData.maxAllowedFlexInstallmentsNumber,
        installmentValue: this.installmentStepData.installmentValue, // quantidade de parcelas
        installmentAmount: this.installmentStepData.installmentAmount, // quantidade de parcelas
        currentPaymentPlan: this.currentPaymentPlan,
        country: this.currentCountry,
        isMobile: isMobile, // Usado para abrir o modal de installments
        close: this.closeInstallmentsModal,
        category: this.category
      }
    };

    this.matDialog.open(InstallmentsModalFlexComponent, dialogConfig);
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.innerWidth = window.screen.width;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
