import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { selectProduct } from '@app/core/state/identification/identification.selectors';
import { selectShowTutorialSmula } from '@app/core/state/login/login.selectors';
import * as fromProposalInformation from '@app/core/state/proposal-information';
import { GetInsurance } from '@app/core/state/proposal-information/proposal-information.actions';
import { getConfirmationData } from '@app/core/state/proposal-information/proposal-information.selectors';
import { SetPaymentPlanSelectedAction } from '@app/core/state/proposal/proposal.actions';
import { selectProposalId } from '@app/core/state/proposal/proposal.selectors';
import {
  getBestOfferPayload, GetEnquadramento,
  GetNewCalculation,
  GetSimulationParameters,
  PostBestOffer,
  PostSimulationRequest,
  SetPayloadBestOffers
} from '@app/core/state/simulation/simulation.actions';
import {
  getBestOffer,
  getEnquadramento,
  getErrorBestOffer,
  getPayloadBestOffers,
  getSimulationParameters,
  getStatusCoupon,
  getSubOfferId,
  recalculateNpp
} from '@app/core/state/simulation/simulation.selectors';
import { ErrorModalComponent } from '@app/proposal-workflow/components/error-modal/error-modal.component';
import { InstallmentStepComponent } from '@app/proposal-workflow/components/installment-step/installment-step.component';
import { PaymentStepComponent } from '@app/proposal-workflow/components/payment-step/payment-step.component';
import { BestOffer, BestOfferResponseDTO } from '@app/proposal-workflow/models/best-offer.model';
import { ErrorMessage } from '@app/proposal-workflow/models/error-message.model';
import { InputChange } from '@app/proposal-workflow/models/input-change.model';
import { InputTypes } from '@app/proposal-workflow/models/input-types.enum';
import { PartnerBanksModel } from '@app/proposal-workflow/models/partner-banks.model';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { ScrollToSimulationStepService } from '@app/proposal-workflow/service/scroll-to-simulation-step.service';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { select, Store } from '@ngrx/store';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { TutorialService } from '@shared/services/tutorial/tutorial.service';
import * as moment from 'moment';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, take, takeUntil, withLatestFrom } from 'rxjs/operators';

interface BestOfferPayload {
  subOfferId?: number;
  installmentAmount?: number;
  flexPlanType?: string;
  seasonalPeriodSelected?: number;
  firstInstallmentPMethodsDate?: string;
  registrationFeeType?: string;
  firstInstallmentDueDate?: string;
  hasInsurance?: boolean;
  subsidyPercentage?: number;
  codeMonthsRange?: string;
  isEnquadramento?: boolean;
  customInstallmentValue?: any;
  fixedInstallmentQuantity?: number;
  offerId?: number;
}
@Component({
  selector: 'bra-step-simulation-npp',
  templateUrl: './bra-step-simulation-npp.component.html',
  styleUrls: ['./bra-step-simulation-npp.component.scss']
})
export class BraStepSimulationNppComponent implements OnInit, OnDestroy {
  @Input() public identification: any;

  public formSimulacao: FormGroup;
  public entireForm: FormArray;
  public isValidFormSubmitted: boolean;
  public registrationFeesSubscriber: any;
  public totalSections = null;
  public showCloseCalendar: boolean;
  public proposalData = null;
  public isValid = false;
  public errPlanFlex = false;
  public isErrorPlanFlex = false;
  public largerShare: number;
  public payloadBestOffers: any;

  public installmentStepDataData$ = new BehaviorSubject<any>(null);
  public installmentStepData$: Observable<any> = this.installmentStepDataData$.asObservable();
  public isResService: boolean;
  @ViewChild(PaymentStepComponent) paymentStepComponent: PaymentStepComponent;

  // Validation
  financedValue: number;
  numberOfInstallments: number;
  paymentPlan: string;
  public selectedPaymentMethod: string;
  accountData = null;
  bankAccountServiceVallidated = false;
  newPaymentMethodSelected = '';
  public othersBanks: PartnerBanksModel[];
  simulationPayload: any = {
    proposalId: ''
  };
  isErroPlanFlex = false;

  couponVallidated = false;

  get installment() {
    return this.formSimulacao.get('installment').value;
  }

  get insurance() {
    return this.formSimulacao.get('insurance').value;
  }

  simulationId: string;

  preventScroll = false;

  proposalId;

  bestOfferPayload: BestOfferPayload = {
    subOfferId: 0,
    installmentAmount: 0,
    flexPlanType: '',
    seasonalPeriodSelected: 0,
    firstInstallmentPMethodsDate: '',
    registrationFeeType: '',
    firstInstallmentDueDate: '',
    hasInsurance: false,
    subsidyPercentage: 0,
    codeMonthsRange: '',
    customInstallmentValue: '',
    fixedInstallmentQuantity: null,
    offerId: null
  };

  /**
   * Calculation payload
   */
  calculationPayload: any = {
    proposalId: '2075338',
    preAnalysis: {
      income: 10000
    },
    payment: {
      entryValue: 0,
      financedValue: 48760,
      insuranceId: 0,
      registrationFeeType: 'IN_INSTALLMENTS',
      installmentAmount: 12,
      paymentPlan: 'FLT',
      firstInstallmentDueDate: '2021-03-11',
      firstInstallmentPMethodsDate: '',
      codeMonthsRange: 0,
      billShippingOptions: 'PHYSICAL',
      hasInsurance: false
    },
    pricing: {
      couponCode: '',
      paymentMethod: 'B'
    }
  };

  public calculationError = false;

  public defaultDaysToInitialPayment: number;

  public simulationParameters: any;

  public installmentStepData;

  public registrationFeeData;

  public insuranceStepData;

  public paymentData;

  public dueDateData;

  public simulationParams: any;

  public disableConfirmButton = true;

  public isEnabledToInformIncome = false;

  public dueDaysIntervalMax: number;

  public dueDaysIntervalMin: number;

  public subOfferId: any;

  protected ngUnsubscribe: Subject<any> = new Subject();

  public confirmationData: ConfirmationData;

  public isofferIdLack = false;
  public isofferIdSeasonal= false;
  public isofferIdFixed = false

  public conditionPlanFlex: any = [];

  public bestOffer: BestOfferResponseDTO;

  @ViewChild('creditValueDiv') creditValueDiv: ElementRef;

  @ViewChild('installmentDiv') installmentDiv: ElementRef;

  @ViewChild('registrationFeeDiv', { static: true }) registrationFeeDiv: ElementRef;

  @ViewChild('insuranceDiv', { static: true }) insuranceDiv: ElementRef;

  @ViewChild('dueDateDiv', { static: true }) dueDateDiv: ElementRef;

  @ViewChild('paymentDiv', { static: true }) paymentDiv: ElementRef;

  @ViewChild('paymentStepCmpt') paymentStepCmpt: PaymentStepComponent;

  @ViewChild('cuponDiv') cuponDiv: ElementRef;

  @ViewChild('installmentCmpt') installmentCmpt: InstallmentStepComponent;

  public category: string;
  readonly Insert: Tag = Tag.Insert;
  readonly Selecionar: Tag = Tag.Selecionar;

  scrollInstallment() {
    this.installmentDiv.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  scrollCreditValue() {
    this.creditValueDiv.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  scrollRegistrationFee() {
    if (!this.registrationFeeData.hasTariff) {
      this.scrollInsurance();
    } else {
      this.registrationFeeDiv.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  scrollInsurance() {
    if (!this.insuranceStepData.showInsuranceStep) {
      this.scrollDueDate();
    } else {
      this.insuranceDiv.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    }
  }

  scrollDueDate() {
    this.dueDateDiv.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  scrollPayment() {
    this.paymentDiv.nativeElement.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'nearest'
    });
  }

  constructor(
    public matDialog: MatDialog,
    public renderer: Renderer2,
    private store$: Store<AppState>,
    private cdref: ChangeDetectorRef,
    private tutorialService: TutorialService,
    public scrollToSimulationStepService: ScrollToSimulationStepService,
    private store: Store<any>,
    private simulationService: SimulationService,
    private genTagger: GenTagger,
    private taggerService: TaggerService
  ) {
    this.clearProposalData();

    this.store$
      .select(selectProposalId)
      .pipe(
        withLatestFrom(this.store$.select(selectProduct)),
        filter(arr => arr.every(Boolean)),
        take(1)
      )
      .subscribe(([proposalId, product]) => {
        this.proposalId = proposalId;
        this.simulationPayload = {
          ...this.simulationPayload,
          proposalId
        };

        this.taggerService.setPageLocation(`/portallojista/criarproposta/${product?.code?.toLowerCase()}/simulacao`)
        this.store$.dispatch(new fromProposalInformation.actions.GetConfirmationData(this.proposalId));
        this.store$.dispatch(new GetSimulationParameters(this.simulationPayload));
        this.store$.dispatch(new GetEnquadramento(this.proposalId));
      });
    this.store$
      .pipe(
        select(getConfirmationData),
        takeUntil(this.ngUnsubscribe),
        filter(data => !!data)
      )
      .subscribe(state => {
        this.confirmationData = state;

        // Get SubOfferId selected
        this.store$
          .select(getSubOfferId)
          .pipe(take(1))
          .subscribe(subOfferId => {
            let payload: BestOfferPayload = { subOfferId };


            // If it's recalculating, keep current modifications to simulation (paymentMethod, insurance, etc)
            if (Object.values(this.bestOfferPayload).some(value => !!value)) {
              payload = { ...this.bestOfferPayload, ...payload };
            }
            this.store$.dispatch( new getBestOfferPayload(payload))
            this.store$.select(getPayloadBestOffers).pipe(take(1), takeUntil(this.ngUnsubscribe), withLatestFrom(this.store$.select(selectProposalId))).subscribe(([res, proposalId])=>{
              if(res.isReturn === true){
                this.store$.dispatch(new PostBestOffer(proposalId, res.payload));
              }else{
                this.store$.dispatch(new PostBestOffer(proposalId, payload));
              }
            })

            this.store$.select(getEnquadramento).pipe(take(1))
            .subscribe()
          });
      });

    // Get BestOffer

    this.store$
      .select(getBestOffer)
      .pipe(filter(data => !!data))
      .subscribe(bestOfferData => {
        this.isResService = true;
        if(this.isErroPlanFlex === true){
          bestOfferData.flexPlanList =[
            {
              flexPlanName: 'Tradicional',
              flexPlanType: 'FLT'
            }
          ]
        }
        const isProcessing: boolean = bestOfferData?.messages?.find(res => res?.message == 'PROCESSING').message
        if(isProcessing){
          return
        }
        this.subOfferId = bestOfferData?.bestOffer?.subOfferId;
        if (!this.confirmationData) {
          return;
        }

        if (bestOfferData && bestOfferData.messages && bestOfferData.messages.length > 0) {
          this.handleErrorMessages(bestOfferData.messages);
        }

        this.bestOffer = bestOfferData;
        if(
          this.bestOffer.bestOffer.flexPlanType === 'ASC' ||
          this.bestOffer.bestOffer.flexPlanType === 'DESC' ||
          this.bestOffer.bestOffer.flexPlanType === 'FLT'
        ){
        this.isValid = false;
        this.isResService = true;
        }
        if(this.bestOffer.bestOffer.paymentMethods[0].code === 'FIXED' && this.bestOffer.bestOffer.offerId !== null && !this.isofferIdFixed ){
          this.bestOfferPayload.offerId = this.bestOffer?.bestOffer?.offerId;
          this.isofferIdFixed = !!this.bestOffer?.bestOffer?.offerId;
          this.bestOfferPayload.customInstallmentValue = this.bestOffer?.bestOffer?.customInstallmentValue;
          this.bestOfferPayload.fixedInstallmentQuantity = this.bestOffer?.bestOffer?.fixedInstallmentQuantity;
          this.store$.dispatch(new PostBestOffer(this.proposalId, this.bestOfferPayload));
        }
        if(this.bestOffer.bestOffer.paymentMethods[0].code === 'SEASONAL' && this.bestOffer.bestOffer.offerId !== null && !this.isofferIdSeasonal ){
          this.bestOfferPayload.offerId = this.bestOffer?.bestOffer?.offerId;
          this.isofferIdSeasonal = !!this.bestOffer?.bestOffer?.offerId;
          this.bestOfferPayload.customInstallmentValue = this.bestOffer?.bestOffer?.customInstallmentValue;
         // this.bestOfferPayload.fixedInstallmentQuantity = this.bestOffer?.bestOffer?.fixedInstallmentQuantity;
          this.bestOfferPayload.seasonalPeriodSelected = this.bestOffer?.bestOffer?.seasonalPeriodSelected
          this.store$.dispatch(new PostBestOffer(this.proposalId, this.bestOfferPayload));
        }
        if(this.bestOffer.bestOffer.paymentMethods[0].code === 'LACK' && this.bestOffer.bestOffer.offerId !== null && !this.isofferIdLack ){
          this.bestOfferPayload.offerId = this.bestOffer?.bestOffer?.offerId;
          this.isofferIdLack = !!this.bestOffer?.bestOffer?.offerId;
          this.bestOfferPayload.customInstallmentValue = this.bestOffer?.bestOffer?.customInstallmentValue;
          this.bestOfferPayload.fixedInstallmentQuantity = this.bestOffer?.bestOffer?.fixedInstallmentQuantity;
          this.bestOfferPayload.seasonalPeriodSelected = this.bestOffer?.bestOffer?.seasonalPeriodSelected
          this.store$.dispatch(new PostBestOffer(this.proposalId, this.bestOfferPayload));
        }

        const bestOffer = this.bestOffer.bestOffer;
        const hasPaymentMethods = bestOffer && bestOffer.paymentMethods && bestOffer.paymentMethods.length >= 1;
        const selectedPaymentMethod = hasPaymentMethods ? bestOffer.paymentMethods : [];
        const firstInstallmentPMethodsDate = hasPaymentMethods
          ? selectedPaymentMethod[0].firstInstallmentPMethodsDate
          : '';
        const codeMonthsRange =
          hasPaymentMethods &&
          selectedPaymentMethod[0].monthsRange &&
          selectedPaymentMethod[0].monthsRange.length >= 1 &&
          selectedPaymentMethod[0].monthsRange[0].code
            ? selectedPaymentMethod[0].monthsRange[0].code.toString()
            : bestOffer.seasonalPeriods && bestOffer.seasonalPeriods.length
            ? bestOffer.seasonalPeriods[0].code.toString()
            : '';

        // set default bestOfferPayload
      //  this.bestOfferPayload = this.bestOfferPayload;
        this.bestOfferPayload.installmentAmount = bestOffer.term;
        this.bestOfferPayload.flexPlanType = bestOffer.flexPlanType;
        this.bestOfferPayload.registrationFeeType = this.getRegistrationFeeType(bestOffer);
        this.bestOfferPayload.hasInsurance = bestOffer.protectionInsuranceIncluded;
        this.bestOfferPayload.codeMonthsRange = codeMonthsRange;
        this.bestOfferPayload.subsidyPercentage = bestOffer.subsidy.subsidyPercentage;

        this.installmentStepData = {
          sectionNumber: '1/5',
          installments: [], // não vem - parcelas do modal, outro endpoint
          installmentPMethodsDate: bestOffer.installmentPMethodsDate,
          installmentValue: bestOffer.defaultInstallmentValue, // valor da parcela
          installmentAmount: bestOffer.term, // quantidade de parcelas
          installmentAmountArray: bestOffer && bestOffer.installments ? bestOffer.installments : [],
          paymentMethods: this.bestOffer.flexPlanList,
          selectedPaymentMethod: selectedPaymentMethod,
          codeMonthsRange: codeMonthsRange, // res.calculationPayload.payment.codeMonthsRange - flex
          firstInstallmentPMethodsDate: firstInstallmentPMethodsDate,
          tariff: bestOffer.returnRate.tariff, //  modal taxas do emprestimo - res.simulationData.tariff - outro endpoint
          calcContractFee: bestOffer.returnRate.calcContractFee, // taxa de juros mensal -> modal taxas do emprestimo -
          annualCalcContractFee: bestOffer.returnRate.annualCalcContractFee, // taxa de juros anual -> modal taxas do emprestimo
          annualInternalReturnRate: bestOffer.returnRate.annualInternalReturnRate, // modal taxas do emprestimo -
          monthlyInternalReturnRate: bestOffer.returnRate.monthlyInternalReturnRate, // modal taxas do emprestimo -
          tax: bestOffer.returnRate.tax, // IOF -> modal taxas do emprestimo - res.simulationData.tax
          maxInstallmentAmount: 999, // maximo de parcelas - res.simulationData.maxInstallmentAmount
          minInstallmentAmount: 0, // minimo de parcelas - res.simulationData.minInstallmentAmount
          minAllowedInstallmentValue: 1.0, // res.simulationData.minAllowedInstallmentValue - flex
          maxAllowedInstallmentValue: 20001.3, // res.simulationData.maxAllowedInstallmentValue - flex
          maxAllowedFlexValue: 1000.0, // res.simulationData.maxAllowedFlexValue - flex
          maxAllowedFlexInstallmentsNumber: 3, // res.simulationData.maxAllowedFlexInstallmentsNumber - flex
          isAllowedFlexInstallments: true, // res.simulationData.isAllowedFlexInstallments - flex
          conditionPlanFlex: this.conditionPlanFlex ? this.conditionPlanFlex : [],
          seasonalPeriods: bestOffer.seasonalPeriods,
          insurance: bestOffer.insurance,
          protectionInsuranceIncluded: bestOffer.protectionInsuranceIncluded
        };
        this.installmentStepDataData$.next(this.installmentStepData)
        this.setSelectedPlan(this.bestOffer);

        const tariff = {
          description: bestOffer.tariff && bestOffer.tariff.length > 0 ? bestOffer.tariff[0].name : '',
          installmentValue: bestOffer.tariff && bestOffer.tariff.length > 0 ? bestOffer.tariff[0].installmentValue : 0,
          paymentType: this.confirmationData.registrationFeeType, // pegar do confirmationData
          value: bestOffer.tariff && bestOffer.tariff.length > 0 ? bestOffer.tariff[0].value : 0
        };

        this.registrationFeeData = {
          // tarifa de cadastro vlTarifaParcelada -> valor parcelado da tarifa / value -> valor total da tarifa
          sectionNumber: '2/5',
          hasTariff: bestOffer.tariff, // res.simulationData.tariff.length > 0
          tariff: tariff, // res.simulationData.tariff[0]
          installmentAmount: bestOffer.term,
          registrationFeeType: this.confirmationData.registrationFeeType || 'IN_INSTALLMENTS',
          registrationFeeTypeSelected: this.confirmationData.registrationFeeType || 'IN_INSTALLMENTS'
        };

        this.insuranceStepData = {
          sectionNumber: '3/5',
          hasInsurance: bestOffer.protectionInsuranceIncluded,
          showInsuranceStep: !!bestOffer.insurance && bestOffer.insurance.length > 0,
          insurance: bestOffer.insurance
        };

        this.dueDateData = {
          sectionNumber: '4/5',
          dueDate: moment(bestOffer.firstInstallmentDueDate).format('DD/MM/YYYY'),
          minAllowedFirstInstallmentDueDate: moment(bestOffer.minAllowedFirstInstallmentDueDate).format('DD/MM/YYYY'),
          maxAllowedFirstInstallmentDueDate: moment(bestOffer.maxAllowedFirstInstallmentDueDate).format('DD/MM/YYYY'),
          isValidFirstInstallmentDueDate: true
        };

        this.paymentData = {
          sectionNumber: '5/5',
          saleOffTypeCode: 'PHYSICAL', // tipo de pagamento - res.simulationData.saleOffTypeCode
          accountantIndicator: this.confirmationData.accountantIndicator, // this.confirmationData.accountantIndicator - confirmationData
          isEligible: false,
          elegibleType: 'CN', // res.simulationData.cashback.elegibleType
          cashbackAmount: 0.0, // res.simulationData.cashback.cashbackAmount
          liquidationTypeCode: this.confirmationData.liquidationTypeCode // res.simulationData.liquidationTypeCode - confirmationData
        };

        this.controlSteps();
      });

    this.store$.pipe(select(getStatusCoupon)).subscribe(statusCoupon => {
      if (!statusCoupon || statusCoupon === 'VALID') {
        this.couponVallidated = true;
      } else {
        this.couponVallidated = false;
      }
    });

    this.store$
      .select(getSimulationParameters)
      .pipe(
        filter(data => !!data),
        take(1)
      )
      .subscribe(params => {
        this.isEnabledToInformIncome = params.isEnabledToInformIncome;
        this.dueDaysIntervalMax = params.dueDaysIntervalMax;
        this.dueDaysIntervalMin = params.dueDaysIntervalMin;

        this.othersBanks = params.partnerBanks;

        this.simulationParams = params;
      });
    this.entireForm = new FormArray([]);

    this.store$
      .select(recalculateNpp)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(data => !!data)
      )
      .subscribe(state => {
        if (!!state) {
          this.store$.dispatch(new fromProposalInformation.actions.GetConfirmationData(this.proposalId));
        }
      });
  }

  addForm(fg: FormGroup) {
    this.entireForm.push(fg);
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  private clearProposalData(): void {
    this.store$.dispatch(new fromProposalInformation.actions.ClearProposalInformationAction());
  }

  ngOnInit() {
    this.getErrorBestOffer();

    this.scrollToSimulationStepService.scrollToSimulationStep.pipe(takeUntil(this.ngUnsubscribe)).subscribe(res => {
      switch (res) {
        case 'SCROLL_TO_INSTALLMENT':
          this.scrollInstallment();
          break;
        case 'SCROLL_TO_DUEDATE':
          this.scrollDueDate();
          break;
        case 'SCROLL_TO_PAYMENT':
          this.scrollPayment();
          break;
        default:
          break;
      }
    });
    // Tutorial
    this.store
      .select(selectShowTutorialSmula)
      .pipe(take(1))
      .subscribe((showTutorial: boolean) => {
        if (showTutorial) {
          this.tutorialService.showTutorial('simulation');
        }
      });

    this.simulationService.changeValuesSidebar.subscribe(data => {
      const event = {
        value: data,
        input: InputTypes.SIDEBAR_VALUES
      };
      this.simulationChanged(event);
    });

    this.formSimulacao = new FormGroup({
      installment: new FormControl('', [Validators.required]),
      paymentDate: new FormControl('', [Validators.required]),
      insurance: new FormControl('', [Validators.required])
    });

    this.store$
      .pipe(
        select(getConfirmationData),
        filter(data => !!data),
        take(1)
      )
      .subscribe(state => {
        this.proposalData = state.proposalData;
        if (state.proposalData.product === ProductCodeEnum.CDC) {
          this.category = '/portallojista/criarproposta/cdc/simulacao';
          this.genTagger.setTag({ event_category: this.category, event_label: 'Simulacao', event_action: Tag.pgView });
        } else if (state.proposalData.product === ProductCodeEnum.CSC) {
          this.category = '/portallojista/criarproposta/csc/simulacao';
          this.genTagger.setTag({ event_category: this.category, event_label: 'Simulacao', event_action: Tag.pgView });
        } else if (state.proposalData.product === ProductCodeEnum.CSCP) {
          this.category = '/portallojista/criarproposta/cscp/simulacao';
          this.genTagger.setTag({ event_category: this.category, event_label: 'Simulacao', event_action: Tag.pgView });
        }
      });
  }

  private controlSteps() {
    let qtSections = 3;
    if (this.registrationFeeData && this.registrationFeeData.hasTariff && this.proposalData.product !== ProductCodeEnum.CSCP) {
      qtSections++;
    }

    if (this.insuranceStepData && this.insuranceStepData.showInsuranceStep) {
      qtSections++;
    }

    let count = 1;
    this.installmentStepData.sectionNumber = `${count++}/${qtSections}`;
    if (this.registrationFeeData && this.registrationFeeData.hasTariff && this.proposalData.product !== ProductCodeEnum.CSCP) {
      this.registrationFeeData.sectionNumber = `${count++}/${qtSections}`;
    }
    if (this.insuranceStepData && this.insuranceStepData.showInsuranceStep) {
      this.insuranceStepData.sectionNumber = `${count++}/${qtSections}`;
    }
    this.dueDateData.sectionNumber = `${count++}/${qtSections}`;
    this.paymentData.sectionNumber = `${count++}/${qtSections}`;
  }

  public setSelectedPlan(res) {
    if (this.calculationPayload.flexInstallmentsInput) {
      this.conditionPlanFlex = this.calculationPayload.flexInstallmentsInput;
      this.conditionPlanFlex.forEach(x => {
        x.sequenceNumber = x.installmentNumber;
        x.paymentValue = x.installmentAmountFlex;
      });
    } else {
      this.conditionPlanFlex = [];
    }

    const plan = res.bestOffer.paymentMethods.filter(a => a.code === res.bestOffer.flexPlanType)[0];
    const paymentMethods =
      res.bestOffer && res.bestOffer.paymentMethods.length ? res.bestOffer.paymentMethods[0] : null;
     const fixedInstallmentQuantity = res?.bestOffer?.fixedInstallmentQuantity;
    const monthsRangeDescription =
      paymentMethods &&
      paymentMethods.monthsRange &&
      paymentMethods.monthsRange.length &&
      paymentMethods.monthsRange[0].description
        ? paymentMethods.monthsRange[0].description
        : res.bestOffer.seasonalPeriods && res.bestOffer.seasonalPeriods.length
        ? res.bestOffer.seasonalPeriods[0].description
        : '';

    if (plan && paymentMethods) {
      this.store$.dispatch(
        new SetPaymentPlanSelectedAction({
          code: plan.code,
          description: plan.description,
          firstInstallmentPMethodsDate: moment(paymentMethods.firstInstallmentPMethodsDate).format('MM/YYYY'),
          installmentDefaultValue: paymentMethods.installmentDefaultValue,
          installmentPaymentMethodsValue: paymentMethods.installmentPaymentMethodsValue,
          firstInstallmentValue: paymentMethods.firstInstallmentValue,
          lastInstallmentValue: paymentMethods.lastInstallmentValue,
          installmentDefaultAmount: paymentMethods?.code === 'FLT'? fixedInstallmentQuantity : paymentMethods.installmentDefaultAmount,
          installmentPaymentMethodsAmount: paymentMethods.installmentPaymentMethodsAmount,
          monthsRange: monthsRangeDescription,
          installments: res.bestOffer.installments,
          hasFlexInstallments: paymentMethods.hasFlexInstallments,
          customInstallmentValue: res.bestOffer.customInstallmentValue
        })
      );
    }
  }

  public statusViewCalendar(event: boolean): void {
    this.showCloseCalendar = event;
  }

  accountChangedHandler(event: InputChange): void {
    this.accountData = {
      bankCode: '033',
      branchCode: event.value.branchCode,
      accountType: event.value.accountType,
      accountNumber: event.value.accountNumber,
      checksum: event.value.accountDigit
    };

    this.bankAccountServiceVallidated = event.value.bankAccountServiceVallidated;
    this.newPaymentMethodSelected = event.value.newPaymentMethodSelected;
    this.enableSubmitBtn();
  }

  isValidateDueDate(date: any) {
    if (
      moment(date).isSameOrAfter(moment(this.bestOffer.bestOffer.minAllowedFirstInstallmentDueDate)) &&
      moment(date).isSameOrBefore(moment(this.bestOffer.bestOffer.maxAllowedFirstInstallmentDueDate))
    ) {
      this.dueDateData.isValidFirstInstallmentDueDate = true;
      return true;
    } else {
      this.dueDateData.isValidFirstInstallmentDueDate = false;
      return false;
    }
  }

  simulationChanged(event: InputChange): void {
    if (event.type === 'Intallments'){
      this.bestOfferPayload.isEnquadramento = null;
      this.bestOfferPayload.subOfferId = 0
    }
    if (event.type === 'Insurance'){
      this.bestOfferPayload.isEnquadramento = null;
    }
    if(event.isEnquadramento && event.type === 'TC'){
      this.bestOfferPayload.subOfferId = this.subOfferId;
      this.bestOfferPayload.isEnquadramento = event.isEnquadramento;
    }

    if(event.type === 'FIXED'){
      this.bestOfferPayload.offerId = this.bestOffer?.bestOffer?.offerId;
      this.bestOfferPayload.customInstallmentValue = event.customInstallmentValue;
      this.bestOfferPayload.fixedInstallmentQuantity = event.fixedInstallmentQuantity;
      this.bestOfferPayload.seasonalPeriodSelected = null;
    }else if(this.bestOfferPayload.flexPlanType === 'SEASONAL') {
      this.bestOfferPayload.seasonalPeriodSelected = this.bestOffer.bestOffer.seasonalPeriodSelected;
      this.bestOfferPayload.offerId = this.bestOffer.bestOffer.offerId;

    }

    else{
      this.bestOfferPayload.offerId = null;
      this.bestOfferPayload.fixedInstallmentQuantity = null;
      this.bestOfferPayload.seasonalPeriodSelected = null;
      this.bestOfferPayload.customInstallmentValue= '';
    }
    if (event.input === InputTypes.INSTALLMENT) {
      this.bestOfferPayload.installmentAmount = event.value.payment.installmentAmount;
      if(this.bestOfferPayload.flexPlanType === 'BLN'){
      this.bestOfferPayload.seasonalPeriodSelected = this.bestOffer.bestOffer.seasonalPeriodSelected;
      }
    }

    if (event.input === InputTypes.PAYMENT_PLAN) {
      this.bestOfferPayload.flexPlanType = event.value.payment.paymentPlan;
      this.bestOfferPayload.firstInstallmentPMethodsDate = '';
      this.bestOfferPayload.installmentAmount = null;
    }

    if (event.input === InputTypes.REGISTRATION_FEE) {
      this.bestOfferPayload.registrationFeeType = event.value.payment.registrationFee;
    }

    if (event.input === InputTypes.DUE_DATE) {
      if (this.isValidateDueDate(event.value.payment.firstInstallmentDueDate)) {
        // Remoção da atribuiçào do OfferId na alteração do DueDate
        // this.bestOfferPayload.offerId = this.bestOffer?.bestOffer?.offerId;
        this.bestOfferPayload.customInstallmentValue = this.bestOffer?.bestOffer?.customInstallmentValue;
        this.bestOfferPayload.seasonalPeriodSelected = this.bestOffer.bestOffer.seasonalPeriodSelected;
        this.bestOfferPayload.firstInstallmentDueDate = event.value.payment.firstInstallmentDueDate;
        if(this.bestOfferPayload.flexPlanType === 'BLN'){
          this.bestOfferPayload.seasonalPeriodSelected = this.bestOffer.bestOffer.seasonalPeriodSelected;
          }
      } else {
        return;
      }
    }

    if (event.input === InputTypes.INSURANCE) {
      this.bestOfferPayload.hasInsurance = event.value.payment.hasInsurance;
    }
    if (event.input === InputTypes.INSTALLMENT_INFO_MONTHS) {
      this.bestOfferPayload.codeMonthsRange = event.value.payment.codeMonthsRange;
      this.bestOfferPayload.seasonalPeriodSelected = Number(event.value.payment.codeMonthsRange);
    }

    if (event.input === InputTypes.INSTALLMENT_INFO_DATE_BLN) {
      this.bestOfferPayload.firstInstallmentPMethodsDate = event.value.payment.firstInstallmentPMethodsDate;
    }

    if (event.input === InputTypes.INSTALLMENT_INFO_DATE_LACK) {
      this.bestOfferPayload.firstInstallmentPMethodsDate = event.value.payment.firstInstallmentPMethodsDate;
    }


    this.largerShare = this.bestOffer.bestOffer.installments[this.bestOffer.bestOffer.installments.length -1]
    if((this.bestOfferPayload.seasonalPeriodSelected > this.bestOfferPayload.installmentAmount || this.bestOfferPayload.seasonalPeriodSelected === this.bestOfferPayload.installmentAmount ) && (this.bestOfferPayload.flexPlanType ==='BLN' || this.bestOfferPayload.flexPlanType === 'SEASONAL')){
      this.bestOfferPayload.installmentAmount = this.largerShare;
    }
    this.payloadBestOffers ={
      payload: this.bestOfferPayload,
      isReturn: false
    }
    this.store$.dispatch(new SetPayloadBestOffers(this.payloadBestOffers.payload, this.payloadBestOffers.isReturn));

    this.store$.dispatch(new PostBestOffer(this.proposalId, this.bestOfferPayload));

    return;
    if (event.input === InputTypes.INPUT_CREDIT) {
      this.calculationPayload.payment.financedValue = event.value.payment.financedValue;
    }

    if (event.input === InputTypes.DUE_DATE) {
      this.calculationPayload.payment.firstInstallmentDueDate = event.value.payment.firstInstallmentDueDate;
    }
    if (event.input === InputTypes.INSTALLMENT_INFO_MONTHS) {
      this.calculationPayload.payment.codeMonthsRange = event.value.payment.codeMonthsRange;
    }

    if (event.input === InputTypes.INSTALLMENT_INFO_DATE_BLN) {
      this.calculationPayload.payment.firstInstallmentPMethodsDate = event.value.payment.firstInstallmentPMethodsDate;
    }

    if (event.input === InputTypes.INSTALLMENT_INFO_DATE_LACK) {
      this.calculationPayload.payment.firstInstallmentPMethodsDate = event.value.payment.firstInstallmentPMethodsDate;
    }
    if (event.input === InputTypes.PAYMENT_PLAN) {
      this.calculationPayload.payment.paymentPlan = event.value.payment.paymentPlan;
      this.calculationPayload.payment.firstInstallmentPMethodsDate = '';
      this.calculationPayload.payment.codeMonthsRange = event.month ? event.month : 0;
    }

    if (event.input === InputTypes.SIDEBAR_VALUES) {
      this.calculationPayload.payment.entryValue = event.value.entryValue;
      this.calculationPayload.payment.financedValue = event.value.financedValue;
    }

    this.store$.dispatch(new GetNewCalculation(this.calculationPayload));
    this.getInsurances();
  }

  private getErrorBestOffer() {
    this.store$.select(getErrorBestOffer).subscribe(res => {
      this.isResService = false;
      if (res?.error?.messages !== null && res?.error?.messages !== undefined ) {
        for (let err of res?.error?.messages) {
          if (err.message === 'Não encontramos ofertas para esse tipo de plano(FLEX).') {
            this.errPlanFlex = true;
            break;
          }
        }
      }

      if (this.errPlanFlex === true) {
        this.bestOfferPayload.codeMonthsRange = '';
        this.bestOfferPayload.customInstallmentValue = '';
        this.bestOfferPayload.firstInstallmentDueDate = '';
        this.bestOfferPayload.firstInstallmentPMethodsDate = '';
        this.bestOfferPayload.fixedInstallmentQuantity = null;
        this.bestOfferPayload.flexPlanType = 'FLT';
        this.bestOfferPayload.hasInsurance = this.bestOffer?.bestOffer?.protectionInsuranceIncluded;
        this.bestOfferPayload.installmentAmount = this.bestOffer?.bestOffer?.installmentQuantity;
        this.bestOfferPayload.offerId = null;
        this.bestOfferPayload.registrationFeeType = 'IN_INSTALLMENTS';
        this.bestOfferPayload.seasonalPeriodSelected = null;
        this.bestOfferPayload.subOfferId = null;
        this.bestOfferPayload.subsidyPercentage = this.bestOffer?.bestOffer?.subsidy?.subsidyPercentage;
        this.store$.dispatch(new PostBestOffer(this.proposalId, this.bestOfferPayload));
        this.isErroPlanFlex = true;
        this.errPlanFlex = false;
        this.isErrorPlanFlex = true;
      }
    });
  }

  getInsurances() {
    if (!this.calculationPayload.payment.hasInsurance) {
      this.store$.dispatch(
        new GetInsurance({
          ...this.calculationPayload,
          payment: {
            ...this.calculationPayload.payment,
            hasInsurance: true
          }
        })
      );
    }
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit() {
    this.cdref.detectChanges();
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }
  validatorsForm(res: boolean) {
    if (res) {
      this.isValid = true;
    } else {
      this.isValid = false;
    }
    this.enableSubmitBtn();
  }

  enableSubmitBtn(): boolean {
    this.disableConfirmButton = true;
    if (!!this.paymentStepComponent && !!this.confirmationData) {
      if (
        ((this.paymentStepComponent.paymentMethodSelected === 'B' &&
          this.confirmationData.liquidationTypeCode === 'B' && (!this.isValid && this.isResService)) ||
          (this.paymentStepComponent.paymentMethodSelected === 'D' &&
            (this.paymentStepComponent.accountFields.valid &&
              this.bankAccountServiceVallidated &&
              this.confirmationData.liquidationTypeCode === 'D' && this.isResService))) &&
        (this.couponVallidated || !this.isValid)
      ) {
        this.disableConfirmButton = false;
      }
    }

    return this.disableConfirmButton;
  }

  removeFocusSubmitBtn() {
    const element = document.activeElement;
    if (element instanceof HTMLElement) {
      element.blur();
    }
  }

  goSumary() {
    const payload: any = {
      proposalId: this.proposalId,
      registrationFeeType: this.bestOfferPayload.registrationFeeType,
      paymentMethod: this.confirmationData.liquidationTypeCode,
      accountData: {
        bankCode: '',
        branchCode: '',
        accountType: '',
        accountNumber: '',
        checksum: ''
      }
    };

    if (this.confirmationData.liquidationTypeCode !== 'D') {
      delete payload.accountData;
    } else {
      payload.accountData = { ...this.accountData };
    }

    this.store$.dispatch(new PostSimulationRequest(payload));
  }

  private handleErrorMessages(messages: ErrorMessage[]): void {
    if (!!this.matDialog.getDialogById('npp-offer-error')) return;

    this.matDialog.open(ErrorModalComponent, {
      id: 'npp-offer-error',
      data: {
        errorIcon: 'cancel',
        errorTitle: messages[0].title,
        errorText: messages[0].message,
        errorCode: messages[0].description,
        errorButton: 'OK'
      },
      maxWidth: '80vw',
      width: '500px'
    });
  }

  private getRegistrationFeeType(bestOffer: BestOffer): string {
    let registrationFeeType = '';

    try {
      registrationFeeType = bestOffer.returnRate.tariff.find(tariffType => tariffType.description === 'TC').paymentType;
    } catch {
      registrationFeeType = 'IN_INSTALLMENTS';
    }

    return registrationFeeType;
  }
}
