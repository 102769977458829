import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ApplicationHeaders } from '@shared/components/ui/header/header.models';
import { AuthUserGuard } from './core/auth/auth.guard';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginModule),
    data: {
      disableLogoutTime: true
    }
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignUpModule)
  },

  {
    path: 'terms',
    loadChildren: () => import('./terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: 'data-update',
    loadChildren: () => import('./data-update/data-update.module').then(m => m.DataUpdateModule),
    canActivate: [AuthUserGuard]
  },
  {
    path: 'tutorial',
    loadChildren: () => import('./tutorial/tutorial.module').then(m => m.TutorialModule)
  },
  {
    path: 'identification',
    loadChildren: () => import('./identification/identification.module').then(m => m.IdentificationModule),
    canActivate: [AuthUserGuard]
  },
  {
    path: 'showcase',
    loadChildren: () => import('./showcase/showcase.module').then(m => m.ShowcaseModule),
    canActivate: [AuthUserGuard]
  },
  {
    path: 'not/FAQ',
    loadChildren: () => import('./faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'products',
    loadChildren: () => import('./products/products.module').then(m => m.ProductsModule),
    canActivate: [AuthUserGuard]
  },
  {
    path: 'proposal',
    loadChildren: () => import('./proposal-workflow/proposal-workflow.module').then(m => m.ProposalWorkflowModule),
    data: {
      header: ApplicationHeaders.Headless
    }
  },

  {
    path: 'contract',
    loadChildren: () => import('./contracts/contracts.module').then(m => m.ContractsModule),
    canActivate: [AuthUserGuard]
  },

  {
    path: 'tutorials',
    loadChildren: () => import('./tutorials/tutorials.module').then(m => m.TutorialsModule),
    canActivate: [AuthUserGuard]
  },
  {
    path: 'disconnected',
    loadChildren: () => import('./disconnected/disconnected.module').then(m => m.DisconnectedModule)
  },
  {
    path: 'showcase/payments',
    loadChildren: () => import('./showcase/showcase.module').then(m => m.ShowcaseModule),
    canActivate: [AuthUserGuard]
  },
  {
    path: 'not-found',
    loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundModule)
  },
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: '**', redirectTo: 'not-found' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
