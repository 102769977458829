// Backend Enum
export enum ProposalStageEnum {
  SIMULATION = 2,
  DATA_REGISTER = 3,
  FRAUD_ANALYSIS = 4,
  CONFIRMATION = 5,
  CREDIT_TABLE = 9,
  PAYMENT_ERROR = 8,
  DOCUMENTAL_ANALYSIS_BPS = 7,
  DOCUMENTAL_ANALYSIS_TABLE = 13,
  FORMALIZATION = 6,
  INTEGRATION_ERROR = 12,
  PAYMENT_RELEASE = 10,
  PRE_ANALYSIS = 11,
  ANALYSIS_CD = 11
}
