import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core/state';
import { SetBacenAnswersData } from '@app/core/state/register/register.actions';
import { FlagSignUpData, ProposalSignUpData } from '@app/proposal-workflow/models/proposal-data.model';
import { RegisterProposalFlags } from '@app/proposal-workflow/models/step-register-put.enum';
import { RegisterBacenAnswers } from '@app/proposal-workflow/models/step-register-put.model';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-bacen-questions',
  templateUrl: './bacen-questions.component.html',
  styleUrls: ['./bacen-questions.component.scss']
})
export class BacenQuestionsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe = new Subject<any>();

  @Input() proposal: ProposalSignUpData;
  @Output() public bacenAnswers: EventEmitter<RegisterBacenAnswers> = new EventEmitter();

  public flags: FlagSignUpData[];
  public proposalFlags = RegisterProposalFlags;
  public options: any[] = [{ id: true, description: 'Sim' }, { id: false, description: 'Não' }];
  public form: FormGroup;

  constructor(private store$: Store<AppState>) {}

  ngOnInit() {
    this.flags = this.proposal.flags.filter(
      f => f.code === this.proposalFlags.AUT_UTILZ_LIM_CRED || f.code === this.proposalFlags.AUT_DEB_PCIAL_CTA
    );

    this.buildForm();
    const firstFlag = this.flags.find(f => f.code === this.proposalFlags.AUT_UTILZ_LIM_CRED);

    const formData = {
      firstAnswer: firstFlag ? firstFlag.value : null
    };

    this.form.patchValue(formData);
    this.selectSubscriptionQuestions();

    if (formData.firstAnswer !== null) {
      this.validateAnswers(formData);
      return;
    }

    this.bacenAnswers.emit();
  }

  public buildForm() {
    this.form = new FormGroup({
      firstAnswer: new FormControl(null, [Validators.required])
    });
  }

  public selectSubscriptionQuestions(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(() => this.form.valid)
      )
      .subscribe(value => {
        this.validateAnswers(value);
      });
  }

  public validateAnswers(value: { firstAnswer: boolean }): void {
    const { firstAnswer } = value;
    const answers: RegisterBacenAnswers = {
      proposalId: +this.proposal.id,
      bacenAnswers: [
        {
          code: this.proposalFlags.AUT_UTILZ_LIM_CRED,
          flag: firstAnswer
        }
      ]
    };
    this.bacenAnswers.emit(answers);
    this.store$.dispatch(new SetBacenAnswersData(answers));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
