<app-header-section [position]="pos.pos" [size]="pos.size" [titleSection]="title"> </app-header-section>

<div class="row">
  <div class="col-12 col-md-11">
    <div *ngIf="form" [formGroup]="form">
      <div formGroupName="person">
        <!-- FIRST ROW -->
        <div class="row">
          <!-- FIRST COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- FULLNAME FOR NATURAL PERSON -->
            <mat-form-field *ngIf="personType === personsType.PF">
              <mat-label> {{ 'STEP-REGISTER-FULL-NAME' | translate }} </mat-label>
              <app-custom-input
                class="input-with-icon"
                [required]="true"
                formControlName="name"
                [minlength]="2"
                [maxlength]="60"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="nome completo"
                (focus)="checkFocus('person.name')"
              >
              </app-custom-input>
              <mat-hint>{{ 'STEP-REGISTER-NAME-HINT' | translate }}</mat-hint>
              <mat-error *ngIf="form.get('person').get('name').touched && form.get('person').get('name').invalid">
                {{ 'STEP-REGISTER-NAME-ERROR' | translate }}
              </mat-error>
            </mat-form-field>

            <!-- COMPANY NAME FOR JURIDICAL PERSON -->
            <mat-form-field *ngIf="personType === personsType.PJ">
              <mat-label> {{ 'STEP-REGISTER-COMPANY-NAME' | translate }} </mat-label>
              <app-custom-input
                class="input-with-icon"
                [required]="true"
                formControlName="name"
                [minlength]="2"
                [maxlength]="60"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="razão social"
                (focus)="checkFocus('person.name')"
              >
              </app-custom-input>
              <mat-error *ngIf="form.get('person').get('name').touched && form.get('person').get('name').invalid">
                {{ 'STEP-REGISTER-COMPANY-NAME-ERROR' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <!-- SECOND COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- DOCUMENT NUMBER FOR JURIDICAL PERSON -->
            <mat-form-field *ngIf="personType === personsType.PJ">
              <mat-label> {{ 'National Id' | translate }} </mat-label>
              <app-custom-input
                [required]="true"
                [customMask]="maskDocumentNumberCompany"
                formControlName="documentNumber"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="cnpj"
              ></app-custom-input>
            </mat-form-field>
          </div>
        </div>

        <!-- SECOND ROW -->
        <div class="row">
          <!-- FIRST COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- DOCUMENT NUMBER NATURAL PERSON -->
            <mat-form-field *ngIf="personType === personsType.PF">
              <mat-label> {{ 'STEP-REGISTER-DOCUMENT-NUMBER' | translate }} </mat-label>
              <app-custom-input
                [required]="true"
                [customMask]="maskDocumentNumber"
                formControlName="documentNumber"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="cpf"
              ></app-custom-input>
            </mat-form-field>

            <!-- FOUNDATION DATE JURIDICAL PERSON -->
            <mat-form-field *ngIf="!!form.get('person').get('legal')" formGroupName="legal">
              <mat-label> {{ 'STEP-REGISTER-FOUNDATION-DATE' | translate }}</mat-label>
              <app-custom-input
                [required]="true"
                [customMask]="$any(pioneerMasks).date.mask"
                formControlName="openingDate"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="data de fundação"
              ></app-custom-input>
            </mat-form-field>
          </div>

          <!-- SECOND COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- GENDER NATURAL PERSON -->
            <mat-form-field
              *ngIf="!!form.get('person').get('physical') && comboParams?.genders as genders"
              formGroupName="physical"
            >
              <mat-label> {{ 'STEP-REGISTER-GENDER' | translate }}</mat-label>
              <app-search-input
                valueFrom="id"
                label="description"
                [items]="genders | orderBy: 'description'"
                formControlName="genderId"
                (changeOut)="onGenreChange()"
              ></app-search-input>
            </mat-form-field>

            <!-- COMPANY TYPE JURIDICAL PERSON -->
            <mat-form-field
              *ngIf="!!form.get('person').get('legal') && comboParams?.companyTypes as types"
              formGroupName="legal"
            >
              <mat-label> {{ 'STEP-REGISTER-COMPANY-TYPE' | translate }}</mat-label>
              <app-search-input
                valueFrom="code"
                label="description"
                [items]="types | orderBy: 'description'"
                formControlName="companyType"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="tipo de empresa"
              ></app-search-input>
            </mat-form-field>
          </div>
        </div>

        <!-- THIRD ROW -->
        <div class="row">
          <!-- FIRST COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- BIRTH DATE NATURAL PERSON -->
            <mat-form-field *ngIf="!!form.get('person').get('physical')" formGroupName="physical">
              <mat-label> {{ 'STEP-REGISTER-BIRTH-DATE' | translate }}</mat-label>
              <app-custom-input
                [required]="true"
                formControlName="birthDate"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="data de nascimento"
              >
              </app-custom-input>
            </mat-form-field>

            <!-- MAIN PHONE JURIDICAL PERSON -->
            <mat-form-field *ngIf="personType === personsType.PJ" formGroupName="mainPhone">
              <mat-label> {{ 'STEP-REGISTER-MAIN-PHONE' | translate }}</mat-label>
              <app-custom-input
                [required]="true"
                formControlName="phoneNumber"
                [prefix]="getPrefixCode()"
                [customMask]="getMask()"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="telefone principal"
              ></app-custom-input>
            </mat-form-field>
          </div>

          <!-- SECOND COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- NATIONALITY NATURAL PERSON -->
            <mat-form-field
              *ngIf="!!form.get('person').get('physical') && comboParams?.nationalities as nationalities"
              formGroupName="physical"
            >
              <mat-label> {{ 'STEP-REGISTER-NATIONALITY' | translate }}</mat-label>
              <app-search-input
                valueFrom="id"
                label="description"
                [items]="nationalities | orderBy: 'description'"
                formControlName="nationalityId"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="nacionalidade"
              ></app-search-input>
            </mat-form-field>

            <!-- LEGAL NATURE JURIDICAL PERSON -->
            <mat-form-field
              *ngIf="!!form.get('person').get('legal') && comboParams?.legalNatures as nature"
              formGroupName="legal"
            >
              <mat-label> {{ 'STEP-REGISTER-LEGAL-NATURE' | translate }}</mat-label>
              <app-search-input
                valueFrom="id"
                label="description"
                [items]="nature | orderBy: 'description'"
                formControlName="legalNatureId"
                (changeOut)="onLegalNatureChange()"
              ></app-search-input>
            </mat-form-field>
          </div>
        </div>

        <!-- FOURTH ROW -->
        <div class="row">
          <!-- FIRST COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- EMAIL NATURAL PERSON -->
            <mat-form-field *ngIf="personType === personsType.PF && mainEmail" formGroupName="mainEmail">
              <mat-label> {{ 'STEP-REGISTER-EMAIL' | translate }}</mat-label>
              <app-custom-input
                formControlName="email"
                [required]="true"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="e-mail"
                (focus)="checkFocus('person.mainEmail.email')"
              >
              </app-custom-input>
              <mat-error
                *ngIf="
                  form
                    .get('person')
                    .get('mainEmail')
                    .get('email').touched &&
                  form
                    .get('person')
                    .get('mainEmail')
                    .get('email').invalid
                "
              >
                {{ 'STEP-REGISTER-EMAIL-ERROR' | translate }}
              </mat-error>
            </mat-form-field>

            <!-- ECONOMIC ACTIVITY GROUP JURIDICAL PERSON -->
            <mat-form-field
              *ngIf="!!form.get('person').get('legal') && comboParams?.economicActivityGroups as groups"
              formGroupName="legal"
            >
              <mat-label> {{ 'STEP-REGISTER-ECONOMIC-ACTIVITY-GROUP' | translate }}</mat-label>
              <app-search-input
                valueFrom="id"
                label="description"
                [items]="groups | orderBy: 'description'"
                formControlName="branchGroupCode"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="grupo da atividade econômica"
              ></app-search-input>
            </mat-form-field>
          </div>

          <!-- SECOND COLUMN -->
          <div class="col-12 col-md-6 mb-4">
            <!-- CELLPHONE NATURAL PERSON -->
            <mat-form-field *ngIf="personType === personsType.PF" formGroupName="mainPhone">
              <mat-label> {{ 'STEP-REGISTER-CELL-TELEPHONE' | translate }}</mat-label>
              <app-custom-input
                [required]="true"
                formControlName="phoneNumber"
                [prefix]="getPrefixCode()"
                [customMask]="getMask()"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="celular"
              ></app-custom-input>
              <mat-hint>{{ 'STEP-REGISTER-CELL-HINT' | translate }}</mat-hint>
            </mat-form-field>

            <!-- ECONOMIC ACTIVITY JURIDICAL PERSON -->
            <mat-form-field
              *ngIf="!!form.get('person').get('legal') && comboParams?.economicActivities as activities"
              formGroupName="legal"
            >
              <mat-label> {{ 'STEP-REGISTER-ECONOMIC-ACTIVITY' | translate }}</mat-label>
              <app-search-input
                valueFrom="id"
                label="description"
                [items]="activities | orderBy: 'description'"
                formControlName="branchId"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="atividade econômica"
              ></app-search-input>
            </mat-form-field>
          </div>
        </div>

        <!-- FIFTH ROW -->
        <div class="row">
          <!-- FIRST COLUMN -->
          <div class="col-12 col-md-6 mb-4 mt-3">
            <!-- MOTHER NAME NATURAL PERSON -->
            <mat-form-field *ngIf="!!form.get('person').get('physical')" formGroupName="physical">
              <mat-label> {{ 'STEP-REGISTER-MOTHER-NAME' | translate }} </mat-label>
              <app-custom-input
                [required]="true"
                formControlName="motherName"
                [minlength]="2"
                [maxlength]="60"
                appTagger
                [event_action]="Insert"
                [event_category]="event_category"
                event_label="nome completo da mãe"
                (focus)="checkFocus('person.physical.motherName')"
              >
              </app-custom-input>
              <mat-hint>{{ 'STEP-REGISTER-NAME-HINT' | translate }}</mat-hint>
              <mat-error
                *ngIf="
                  form
                    .get('person')
                    .get('physical')
                    .get('motherName').touched &&
                  form
                    .get('person')
                    .get('physical')
                    .get('motherName').invalid
                "
              >
                {{ 'STEP-REGISTER-NAME-ERROR' | translate }}
              </mat-error>
            </mat-form-field>
          </div>

          <!-- SECOND COLUMN -->
          <div class="col-12 col-md-6 mb-4 mt-3">
            <!-- PROFESSION NATURAL PERSON -->
            <mat-form-field
              *ngIf="!!form.get('person').get('physical') && comboParams?.professions as professions"
              formGroupName="physical"
            >
              <mat-label> {{ 'STEP-REGISTER-PROFESSION' | translate }}</mat-label>
              <app-search-input
                valueFrom="id"
                label="description"
                [items]="professions | orderBy: 'description'"
                formControlName="professionId"
                (changeOut)="onProfessionChange()"
              ></app-search-input>
            </mat-form-field>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
