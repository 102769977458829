import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import { ProfileRenderService } from '@app/profile/service/profile-render.service';
import { Store } from '@ngrx/store';
import { clearManagementPanelFiltersFromHeaderNavigation } from '@shared/functions/clear-mgm-flters-from-header';


// NOTE: - Export to a another file if it could be reused
// tslint:disable-next-line: class-name
interface menuData {
  actionType: string;
  code: string;
  label: string;
  position: number;
  submenu: menuData | null;
  targetAddress: string;
}

@Component({
  selector: 'app-menu-help',
  templateUrl: './menu-help.component.html',
  styleUrls: ['./menu-help.component.scss']
})
export class MenuHelpComponent implements OnInit {
  @Input() subMenuData: menuData;
  @Input() active;
  public category = window.location.href;

  constructor(
    private router: Router,
    private profileRenderService: ProfileRenderService,
    private store$: Store<AppState>
  ) { }

  ngOnInit() { }

  public helpHandler(actionType: string, targetAddress: string): void {
    switch (actionType) {
      case 'routeTo':
        clearManagementPanelFiltersFromHeaderNavigation(targetAddress, this.store$);

        this.router.navigate([targetAddress]);
        this.active = false;

        break;
      case 'callComponent':
        this.profileRenderService.loadComponent(targetAddress);
        this.active = false;
        break;
      default:
        console.log(actionType + ' not defined for: ' + targetAddress);
        break;
    }
    this.hideSubmenu();
  }

  private hideSubmenu() {
    this.active = false;
  }
}
