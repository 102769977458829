<div (window:resize)="onResize($event)">
  <!-- Versão Mobile -->
  <section class="mobile" *ngIf="resize <= 576">
    <!-- <div *ngIf="!showCalendar"> -->
    <div>
      <div class="container">
        <div class="pl-4 pr-4">
          <!-- Seleção do segmento -->
          <div *ngIf="proposalService.defineFlow.choiceSegment; else segmentSelectedMobile">
            <div class="row">
              <div class="col-12 mt-2 mb-4">
                <h2 class="title-page mb-0">Escolha o que quer comprar</h2>
              </div>
              <div class="col-12">
                <mat-card class="card-segment mb-4">
                  <div class="row no-gutters">
                    <div style="display: flex;" class="col-4">
                      <img src="./assets/images/ic_qrcode.svg" class="card-img" />
                      <img src="./assets/border-segment.svg" class="border-segment" />
                    </div>

                    <div class="col-8">
                      <div class="card-content">
                        <div class="mat-card-title">Já está na loja?</div>
                        <div class="mat-card-text text-secondary">
                          Aponte a câmera do celular para o código da caixa.
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-card>
              </div>
            </div>

            <!-- cards de segmento -->
            <div class="row">
              <div (click)="selectSegment(category.name)" class="col-6" *ngFor="let category of categories">
                <mat-card>
                  <img class="rounded d-block pt-5" [src]="category.url_image" style="width: 55px;" />
                  <div class="card-content">
                    <h5 class="mat-card-title">
                      {{ category.segment }}
                    </h5>
                  </div>
                </mat-card>
              </div>
            </div>
          </div>

          <!-- Lojas do segmento selecionado -->
          <ng-template #segmentSelectedMobile>
            <!-- Informação sobre card selecionado -->
            <div class="row">
              <div class="col-12 mt-2 mb-3">
                <h2 class="title-page">Lojas do segmento de {{ typeSegment.segment }}:</h2>
              </div>
              <div class="col-12">
                <mat-card class="card-segment mb-5">
                  <div class="row no-gutters">
                    <div style="display: flex;" class="col-4">
                      <img [src]="typeSegment.url_image" class="card-img segment" />
                      <img src="./assets/border-segment.svg" class="border-segment" />
                    </div>

                    <div class="col-8">
                      <div class="card-content p-3">
                        <div class="mat-card-text text-secondary" style="font-size: 14px;">
                          Segmento
                        </div>
                        <div class="mat-card-title">{{ typeSegment.segment }}</div>
                      </div>
                    </div>
                  </div>

                  <mat-form-field
                    (click)="selectDateTravel()"
                    *ngIf="typeSegment.name === 'viagens'"
                    class="datepicker"
                  >
                    <mat-label class="text-dark">Data da viagem</mat-label>
                    <input class="text-dark" matInput required [value]="dateTravel" [(ngModel)]="dateTravel" />
                    <mat-icon matSuffix>calendar_today</mat-icon>
                  </mat-form-field>

                  <div style="padding: 0px 11px;">
                    <small *ngIf="typeSegment.name === 'viagens'">
                      Se ainda não tem uma data fechada para viagem, escolha uma data aproximada.
                    </small>
                  </div>
                </mat-card>
              </div>
            </div>

            <!-- Seleção de siglas e estados e Input de Busca -->
            <form style="margin-top: -20px;" [formGroup]="form" (ngSubmit)="formControl()">
              <div class class="inputStates">
                <!-- Siglas -->
                <mat-form-field class="col-4">
                  <mat-label></mat-label>
                  <mat-select
                    [(ngModel)]="changeInitial"
                    (selectionChange)="selectionChangeByStates($event, 'sg', shortState.value)"
                    formControlName="sg"
                    #shortState
                    [value]=""
                  >
                    <mat-option #shortState value="{{ initial }}" *ngFor="let initial of initials">
                      {{ initial }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- Estados -->
                <mat-form-field class="col-8 pl-3">
                  <mat-label></mat-label>
                  <mat-select
                    [(ngModel)]="changeState"
                    (selectionChange)="selectionChangeByStates($event, 'st', longState.value)"
                    formControlName="st"
                    #longState
                    [value]=""
                  >
                    <mat-option #longState value="{{ state }}" *ngFor="let state of states">
                      {{ state }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- Input busca bootstrap -->
              <div class="row">
                <div class="col baseline">
                  <div class="input-group input-group-sm mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <img class="mt-1 svg" src="./assets/ic_search.svg" />
                      </span>
                    </div>
                    <input
                      formControlName="tb"
                      placeholder="Buscar loja"
                      type="text"
                      class="text-da form-control"
                      #searchTerm
                      (keyup)="searchByTabName()"
                      [value]="''"
                      [(ngModel)]="searchByName"
                    />
                  </div>
                  <button type="submit" class="text-danger ml-2 bg-transparent border-0">
                    Filtrar
                  </button>
                </div>
              </div>
            </form>

            <!-- Lista de Lojas -->
            <div class="row mt-2">
              <div class="col">
                <!-- Utilizando mock -->
                <ul class="list-group list-group-flush">
                  <li
                    (click)="selectTab(tab)"
                    *ngFor="let tab of tabs"
                    class="pl-0 pr-0 list-group-item d-flex justify-content-between align-items-center"
                  >
                    <small class="text-muted">
                      <h5 class="text-body font-weight-bold mb-0">{{ tab.name }}</h5>
                      {{ tab.address }}
                    </small>
                    <span class="">
                      <img class="svg" src="./assets/ic-arrow-right.svg" />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>

    <app-calendar [calendarConfig]="calendarConfig" (navAction)="navAction($event)" *ngIf="showCalendar"></app-calendar>
  </section>

  <!-- Versão desktop -->
  <section class="desktop" *ngIf="resize >= 768">
    <!-- <app-header-desktop></app-header-desktop> -->
    <div class="container">
      <span
        (click)="goBreadcrumb(breadcrumb.name)"
        *ngFor="let breadcrumb of breadcrumbs; let i = index"
        class="breadcrumbs text-muted"
      >
        {{ breadcrumb.name }}
        <mat-icon *ngIf="i + 1 < breadcrumbs.length">navigate_before</mat-icon>
      </span>
      <div *ngIf="proposalService.defineFlow.choiceSegment; else segmentSelectedDesktop">
        <h1 style="font-weight: 400;" class="mt-4 mb-4">Escolha o quer comprar</h1>
        <div class="row">
          <div *ngFor="let category of categories" class="mb-4 col-sm-4">
            <mat-card (click)="selectSegment(category.name)" class="card-segment selected">
              <img class="img-card" [src]="category.url_image" />
              <div class="card-content">
                <p class="mat-card-text">
                  {{ category.segment }}
                </p>
              </div>
            </mat-card>
          </div>
        </div>
      </div>

      <ng-template #segmentSelectedDesktop>
        <div class="row">
          <div class="col-5">
            <!-- Informação sobre card selecionado -->
            <div class="row">
              <div class="col-12 mt-4 mb-4">
                <h5 class="title-page mb-0">Lojas do segmento de {{ typeSegment.segment }}:</h5>
              </div>
              <div class="col-12" style="padding-right: 60px;">
                <mat-card>
                  <div class="row no-gutters">
                    <div style="display: flex;" class="col-12">
                      <img [src]="typeSegment.url_image" class="card-img p-2" />
                    </div>

                    <div class="col-12">
                      <div class="card-content">
                        <div class="mat-card-text text-secondary" style="font-size: 14px;">
                          Segmento
                        </div>
                        <div class="mat-card-title">{{ typeSegment.segment }}</div>
                      </div>
                    </div>
                  </div>

                  <!-- Data viagem -->
                  <mat-form-field
                    (click)="openDialogCalendar()"
                    *ngIf="typeSegment.name === 'viagens'"
                    class="datepicker"
                  >
                    <mat-label class="text-dark">Data da viagem</mat-label>
                    <input
                      class="cursor--pointer text-dark"
                      readonly
                      matInput
                      required
                      [value]="dateTravel"
                      [(ngModel)]="dateTravel"
                    />
                    <mat-icon class="cursor--pointer" matSuffix>calendar_today</mat-icon>
                  </mat-form-field>

                  <small *ngIf="typeSegment.name === 'viagens'">
                    Se ainda não tem uma data fechada para viagem, escolha uma data aproximada.
                  </small>
                </mat-card>
              </div>
            </div>
          </div>
          <div class="col-7">
            <!-- Seleção de siglas e estados e Input de Busca -->
            <form [formGroup]="form" (ngSubmit)="formControl()">
              <div class class="inputStates col-8">
                <!-- Siglas -->
                <mat-form-field class="col-4">
                  <mat-label></mat-label>
                  <mat-select
                    [(ngModel)]="changeInitial"
                    (selectionChange)="selectionChangeByStates($event, 'sg', shortState.value)"
                    formControlName="sg"
                    #shortState
                    [value]=""
                  >
                    <mat-option #shortState value="{{ initial }}" *ngFor="let initial of initials">
                      {{ initial }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <!-- Estados -->
                <mat-form-field class="col-8 pl-3">
                  <mat-label></mat-label>
                  <mat-select
                    [(ngModel)]="changeState"
                    (selectionChange)="selectionChangeByStates($event, 'st', longState.value)"
                    formControlName="st"
                    #longState
                    [value]=""
                  >
                    <mat-option #longState value="{{ state }}" *ngFor="let state of states">
                      {{ state }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <!-- Input busca bootstrap -->
              <div class="row">
                <div class="col baseline" style="margin-left: 30px;">
                  <div class="input-group input-group-sm mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <img class="ml-1 mt-1 svg" src="./assets/ic_search.svg" />
                      </span>
                    </div>
                    <input
                      formControlName="tb"
                      placeholder="Buscar loja"
                      type="text"
                      class="form-control"
                      #searchTerm
                      (keyup)="searchByTabName()"
                      [value]="''"
                      [(ngModel)]="searchByName"
                    />
                  </div>
                  <!-- Filtrar por input -->
                  <button type="submit" class="text-danger ml-2 bg-transparent border-0">
                    Filtrar
                  </button>
                </div>
              </div>
            </form>

            <!-- Lista de Lojas -->
            <div class="row mt-2">
              <div class="col" style="margin-left: 30px;">
                <!-- Utilizando mock -->
                <ul class="list-group list-group-flush">
                  <li
                    (click)="selectTab(tab)"
                    *ngFor="let tab of tabs"
                    class="pl-0 pr-0 list-group-item d-flex justify-content-between align-items-center"
                  >
                    <small class="text-muted">
                      <h5 class="text-body font-weight-bold mb-0">{{ tab.name }}</h5>
                      {{ tab.address }}
                    </small>
                    <span class="">
                      <img class="svg" src="./assets/ic-arrow-right.svg" />
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </section>
</div>
