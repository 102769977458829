<div class="container" *ngIf="subsegment">
  <div class="row">
    <!-- Title -->
    <div class="col-12">
      <h1 class="title">{{ 'SIMULATION_STORES_TITLE' | translate: { subsegment: subsegment.name } }}</h1>
    </div>

    <!-- Content -->
    <div class="col-12">
      <!-- Subsegment -->
      <div class="subsegment-card-outside">
        <mat-card class="subsegment-card">
          <div class="card-content">
            <div class="row">
              <div class="col-4 col-md-12">
                <div class="subsegment-image-outside">
                  <img class="subsegment-image" [src]="subsegment.imageUrl" />
                </div>
              </div>
              <div class="col-8 col-md-12 align-self-center">
                <div class="mat-card-text text-secondary">
                  <span>{{ 'SIMULATION_STORES_SUBSEGMENT_SEGMENT' | translate }}</span>
                </div>
                <span class="mat-card-title">{{ subsegment.name }}</span>
              </div>
            </div>

            <div class="row" *ngIf="isTourismSubsegment">
              <div class="col-12">
                <!-- Travel Date -->
                <mat-form-field class="w-100" (click)="openDialogCalendar()">
                  <mat-label class="text-dark">{{ 'SIMULATION_STORES_SUBSEGMENT_TRAVEL-DATE' | translate }}</mat-label>
                  <input
                    matInput
                    class="pointer text-dark"
                    readonly
                    required
                    matInput
                    [value]="dateTravel"
                    [(ngModel)]="dateTravel"
                  />
                  <mat-icon class="pointer" matSuffix>calendar_today</mat-icon>
                </mat-form-field>

                <div class="text-alert">
                  <small>
                    {{ 'SIMULATION_STORES_SUBSEGMENT_TEXT-ALERT' | translate }}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </mat-card>
      </div>
    </div>
  </div>
</div>
