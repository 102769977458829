import { Injectable } from '@angular/core';
import { GET_CITIES, GET_STATES } from '@shared/graphql/queries/stores-filters.query';
import { CityModel } from '@shared/models/city.model';
import { StateModel } from '@shared/models/state.model';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class StoresFiltersService {
  constructor(private apollo: Apollo) {}

  /**
   * Get all states
   */
  getStates(): Observable<StateModel[]> {
    return this.apollo
      .watchQuery<any>({
        query: GET_STATES,
        errorPolicy: 'all'
      })
      .valueChanges.pipe(
        map((response: any) => {
          if (response.data) {
            return response.data.listStates;
          }
          return null;
        })
      );
  }

  /**
   * Get all cities by id of state
   * @param stateCode Code of State
   */
  getCitiesByState(stateCode: string): Observable<CityModel[]> {
    return this.apollo
      .watchQuery<any>({
        query: GET_CITIES,
        variables: { stateCode: stateCode },
        errorPolicy: 'all'
      })
      .valueChanges.pipe(
        map((response: any) => {
          if (response.data) {
            return response.data.listCities;
          }
          return null;
        })
      );
  }
}
