import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { PaymentShowcaseModel } from '@app/showcase/payments/models/payment.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { DateRangePickerDialogComponent } from '@shared/components/date-range-picker-dialog/date-range-picker-dialog.component';
import { FilterPeriodDialogComponent } from '@shared/components/filter-period-dialog/filter-period-dialog.component';
import { SearchSellerDialogComponent } from '@shared/components/search-seller-dialog/search-seller-dialog.component';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { FinancialStatementService } from '../../services/financial-statement.service';
import { StatementDownloadFileTypeDialogComponentComponent } from '../statement-download-file-type-dialog-component/statement-download-file-type-dialog-component.component';
import { StatementPeriodDownloadDialogComponent } from '../statement-period-download-dialog/statement-period-download-dialog.component';


@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss']
})
export class TableComponent implements  OnChanges, OnDestroy {
  listPayments: any[];
  listPaymentsCSCP: any[];
  shopId$: Observable<number> = this.store$.select('tabs').pipe(map(tab => tab.selectedTab.id));
  @Input() public isShowingRecebidos: string;
  @Input() isShowFilters = false;
  @Input() isHiddenFilters = true;
  @Input() tabSelected: string;
  @Input() extractSubTab;
  @Input() callControl;
  @Input() callControlRealizados;
  @Output() productSelected = new EventEmitter<string>();
  public selectedProduct = 'CSC';
  public selectedDays = 90;
  public isToGetPaymentList = true;
  public firstChange = false;
  public subTab: any;
  public subTabChanges: boolean;
  public list = [];
  public selectedInitialDate = moment()
    .subtract(300, 'days')
    .format('DD/MM/YYYY');
  public selectedFinalDate = moment().format('DD/MM/YYYY');
  public modalRef: NgbModalRef;
  public isFirstCall = true;

  // pagination
  @Input() public totalElements: number;
  @Input() public totalPages: number;
  @Input() public page = 1;
  @Input() public perPage = 30;
  @Input() public previousPage: number;


  // checkbox
  public allComplete = false;
  public checkedCount: number;
  public sumCheckedValues: number;
  public optionSelected = 'csv';

  // Tag
  public readonly category = '/portallojista/pagamentos/detalhe/extrato';
  public Selected: Tag = Tag.Selecionar;

  // input search
  public searchString: string;

  // filter
  private filterObj: any = {
    period: '-1d'
  };
  financialStatementPeriodSelected = [];
  // Tagueamento
  readonly event_category: '/portallojista/propostas';

  constructor(
    private financialStatementService: FinancialStatementService,
    private modalService: NgbModal,
    private dialog: MatDialog,
    public genTagger: GenTagger,
    private store$: Store<AppState>,
    private translateService: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}
  ngOnChanges(changes: SimpleChanges): void {

    if(changes?.tabSelected && changes?.tabSelected?.currentValue === "Saldo Futuro"){
      this.selectedProduct = "CSCP";
      this.selectedDays = 0;
            this.selectedInitialDate = moment()
        .subtract(this.selectedDays, 'days')
        .format('DD/MM/YYYY');
      this.selectedFinalDate = moment().format('DD/MM/YYYY');
      this.getListFutureBalance();
    }
    this.subTab = changes.extractSubTab?.currentValue === undefined ? changes.isShowingRecebidos: changes.extractSubTab?.currentValue;
    this.isShowingRecebidos =  this.subTab?.tab?.textLabel? this.subTab?.tab?.textLabel: this.subTab?.currentValue;
    if(this.isShowingRecebidos === undefined) this.isShowingRecebidos = 'Recebidos'

    if (changes.tabSelected &&
      changes.tabSelected.previousValue !== undefined
      && changes.tabSelected.currentValue !== changes.tabSelected.previousValue
    ) {
      if (changes.tabSelected.currentValue !== 'Propostas Efetivadas') {

        this.selectedProduct = 'CSCP'
        this.selectedDays = 0;
        this.setDate();
        this.tabSelected = changes.tabSelected.currentValue;
       if(changes.tabSelected.currentValue === 'Pagamentos Realizados'){
          this.getPaymentsMade();
        }
      }
    }
    if(changes.tabSelected?.currentValue === 'Propostas Efetivadas'){
      this.tabSelected = changes.tabSelected.currentValue;
      this.selectedDays = 0;
      this.setDate();
      this.selectedProduct = 'CSCP'
      this.getEffectiveProposals();
      this.subTabChanges = changes?.extractSubTab?.previousValue?.tab?.isActive;
    }
    if(changes?.extractSubTab?.firstChange === false){
      this.selectedDays = 90;
      this.setDate();
      this.selectedProduct = 'CSC';
      this.getListPayments();
    }

    if (changes.callControl !== undefined && changes.callControl.currentValue === false) {
      this.getListPayments();
    }

    if(changes?.callControl?.currentValue === true) {
       this.getListPayments();
    }
    if(changes?.callControlRealizados?.currentValue === false) {
      this.getListPayments();
   }

  }
  getListFutureBalance(){

    this.totalElements = 0;
    this.financialStatementService.getFutureBalance(
      this.page,
      this.perPage,
      this.selectedInitialDate,
      this.selectedFinalDate
    ).pipe(filter(item => item.content)).subscribe(res=>{
      this.setListFutureBalance(res)})
  }

  getPaymentsMade(){
    this.totalElements = 0;
    this.financialStatementService.getPaymentsMade(
      this.page,
      this.perPage,
      this.selectedInitialDate,
      this.selectedFinalDate
    ).subscribe(res=>{this.setLitsPaymentsMade(res)})
  }

  getEffectiveProposals(){
    this.totalElements = 0;
    this.financialStatementService.getEffectiveProposals(
      this.page,
      this.perPage,
      this.selectedInitialDate,
      this.selectedFinalDate
    ).subscribe({
      next: (res)=>{

      this.setPaymentsList(res)
    },

    error: (error) =>{
      this.setPaymentsList(error);
    }
  }) ;
  }
  toggleShow() {
    this.isShowFilters = !this.isShowFilters;
    this.isHiddenFilters = !this.isHiddenFilters;
  }

  private getListPayments(): void {
    if (this.subTab?.tab?.textLabel === 'Recebidos' || this.subTab === undefined) {
      this.getListPaymentsExtractReceived();
    } else if(this.subTab?.tab?.textLabel === "Realizados" ){
     // this.selectedProduct = this.
      this.getListPaymentsExtract();
    }
  }

  private getListPaymentsExtract(): void {
    this.financialStatementService
      .getListPayments(this.page, this.perPage, this.selectedProduct, this.selectedInitialDate, this.selectedFinalDate)
      .subscribe(payments => {
        this.setPaymentsList(payments);
      });
  }

  private getListPaymentsExtractReceived(): void {

    this.financialStatementService
      .getListPaymentsExtractReceived(
        this.page,
        this.perPage,
        this.selectedProduct,
        this.selectedInitialDate,
        this.selectedFinalDate
      )
      .subscribe(payments => {
        this.setPaymentsList(payments);
      });
  }

  private setPaymentsList(payments): void {
    this.listPayments = payments.content;
    if(this.selectedProduct === 'CSCP'){
      this.listPaymentsCSCP = payments.content;
    }
    if (!!payments.content && !!payments.content.length) {
      // checkbox
      this.listPayments.forEach(item => {
        Object.assign(item, { checked: false });
      });
    }
    this.resetValues(payments);
  }
  private setListFutureBalance(payments): void {
    this.listPaymentsCSCP = payments.content;
    if (!!payments.content && !!payments.content.length) {
      // checkbox
      this.listPaymentsCSCP.forEach(item => {
        Object.assign(item, { checked: false });
      });
    }
    this.resetValues(payments);
  }
  private setLitsPaymentsMade(payments): void {
    this.listPaymentsCSCP = payments.content;
    if (!!payments.content && !!payments.content.length) {
      // checkbox
      this.listPaymentsCSCP.forEach(item => {
        Object.assign(item, { checked: false });
      });
    }
    this.resetValues(payments);
  }

  public checkSelectAll(checked: boolean): void {
    this.allComplete = checked;
    if(this.selectedProduct === 'CSCP'){
      this.listPaymentsCSCP.forEach(item => (item.checked = checked));
      this.checkedCountAndSum(this.listPaymentsCSCP);
    }else{
    this.listPayments.forEach(item => (item.checked = checked));
    this.checkedCountAndSum(this.listPayments);
    }
  }

  public updateCheckSelectAll(): void {
    this.allComplete = this.listPayments.every(item => item.checked);
    this.checkedCountAndSum(this.listPayments);
  }
  public updateCheckSelectAllCSCP(): void {
    this.allComplete = this.listPaymentsCSCP.every(item => item.checked);
    this.checkedCountAndSum(this.listPaymentsCSCP);
  }

  public someChecked(): boolean {
    return !!this.listPayments && this.listPayments.filter(item => item.checked).length > 0 && !this.allComplete;
  }

  private checkedCountAndSum(list: Array<any>): void {
    const checkedFilter = list.filter(item => item.checked);

    // checkedCount
    this.checkedCount = checkedFilter.length;

    // sumCheckedValues
    this.sumCheckedValues = 0;
    if (this.isShowingRecebidos === 'Recebidos' || this.tabSelected === 'Pagamentos Realizados') {
      checkedFilter.forEach(item => {
        if (!!item.receivedValue) {
          this.sumCheckedValues = this.sumCheckedValues + item.receivedValue;
        }
      });
    } else {
      checkedFilter.forEach(item => {
        if (!!item.paymentValue) {
          this.sumCheckedValues = this.sumCheckedValues + item.paymentValue;
        }
      });
    }
    if(this.tabSelected === 'Saldo Futuro'){
      checkedFilter.forEach(item => {
        if (!!item.totalScheduledValue) {
          this.sumCheckedValues = this.sumCheckedValues + item.totalScheduledValue;
        }
      });
    }
  }

  public searchByInput(): void {
    if (!!this.searchString) {
      this.filterObj = {
        ...this.filterObj,
        search: this.searchString
      };
    }
  }

  public filterByStatus(statusName: string): void {
    this.filterObj = {
      ...this.filterObj,
      status: statusName
    };
  }

  public loadPage(pageNumber: number): void {
    if (this.tabSelected === 'Saldo Futuro' || this.tabSelected === 'Pagamentos Realizados') {
      if (this.isFirstCall) {
        if (pageNumber !== 1) {
          this.isFirstCall = false
        }
        this.page = pageNumber;
        this.checkTheTabAndIfIsCSCPAndCallService();

        this.genTagger.setTag({
          event_category: this.category,
          event_action: Tag.Click,
          event_label: `página - ${pageNumber}`
        });
      } else {
        this.isFirstCall = true
      }
      return;
    }
    this.page = pageNumber;
      this.checkTheTabAndIfIsCSCPAndCallService();
      this.genTagger.setTag({
        event_category: this.category,
        event_action: Tag.Click,
        event_label: `página - ${pageNumber}`
      });
  }
  openDialogSeller(link) {
    const modalRef = this.modalService.open(SearchSellerDialogComponent, {
      windowClass: 'modal-filter'
    });
    if (this.filterObj.seller) {
      modalRef.componentInstance.selected = this.filterObj.seller;
    } else {
      modalRef.componentInstance.selected = [];
    }
    modalRef.result.then(
      result => {
        this.filterObj = {
          ...this.filterObj,
          seller: result,
          salesman: result.map(r => r.id)
        };
      },
      reason => {
        console.log(reason);
      }
    );
  }

  openDialogPeriod(link) {
    const modalRef = this.modalService.open(FilterPeriodDialogComponent, {
      windowClass: 'modal-filter'
    });
    modalRef.componentInstance.src = link;
    modalRef.result.then(
      result => {
        this.filterObj = {
          ...this.filterObj,
          period: result.toString()
        };
      },
      reason => {
        console.log(reason);
      }
    );
  }


  private resetValues(payments: any): void {
    // pagination
    if (payments.page > 1) {
      this.page = payments.page;
    }
    if (payments.perPage > 0) {
      this.perPage = payments.perPage;
    }
    this.totalElements = payments.totalElements;
    this.totalPages = payments.totalPages;

    // checkbox
    this.allComplete = false;
    this.checkedCount = 0;
    this.sumCheckedValues = 0;
  }

  public cleanFilterObj() {
    this.searchString = '';
    this.filterObj = {};
    this.getListPayments();
  }

  openDownloadFileTypeDialogCSCP(date, type){
    this.modalRef = this.modalService.open(StatementDownloadFileTypeDialogComponentComponent,  {
  });
  this.modalRef.componentInstance.optionSelectedChange.subscribe(res=>{
    this.optionSelected = res
  })

  this.modalRef.result.then(
    result => {
      if(type === 'Pagamentos Realizados'){
       this.financialStatementPeriodSelected.push(moment(date).format('DD/MM/YYYY'));
      this.shopId$
        .pipe(
          first(),
          switchMap(id => this.financialStatementService.downloadExtractPaymentMade(id, this.financialStatementPeriodSelected,  this.optionSelected ))
        )
        .subscribe(response => {
          this.processDownload(response);
        });
        this.optionSelected = 'csv'
      } else if (type === 'Propostas Efetivadas' && this.selectedProduct === 'CSCP') {
        this.financialStatementPeriodSelected.push(moment(date).format('DD/MM/YYYY'));
        this.shopId$
          .pipe(
            first(),
            switchMap(id => this.financialStatementService.downloadEffectiveProposals(id,  this.financialStatementPeriodSelected,  this.optionSelected ))
          )
          .subscribe(response => {
            this.processDownload(response);
          });
          this.optionSelected = 'csv'
      }
      else {
        this.financialStatementPeriodSelected.push(moment(date).format('DD/MM/YYYY'));
        this.shopId$
          .pipe(
            first(),
            switchMap(id => this.financialStatementService.downloadExtractFutureBalances(id,  this.financialStatementPeriodSelected,  this.optionSelected ))
          )
          .subscribe(response => {
            this.processDownload(response);
          });
          this.optionSelected = 'csv'
      }
      this.financialStatementPeriodSelected = []
    },
    reason => {
      console.log('fechou');
    }
  )
}
  openDownloadFileTypeDialog(date, type){
      this.modalRef = this.modalService.open(StatementDownloadFileTypeDialogComponentComponent,  {
    });
    this.modalRef.componentInstance.optionSelectedChange.subscribe(res=>{
      this.optionSelected = res
    })

    this.modalRef.result.then(
      result => {
        if(this.tabSelected === 'Propostas Efetivas' && this.selectedProduct === 'CSCP'){
          this.financialStatementPeriodSelected.push(moment(date).format('DD/MM/YYYY'));
          this.shopId$
          .pipe(
            first(),
            switchMap(id => this.financialStatementService.downloadEffectiveProposals(id, this.financialStatementPeriodSelected,  this.optionSelected ))
          )
          .subscribe(response => {
            this.processDownload(response);
          });
          this.optionSelected = 'csv';
          this.financialStatementPeriodSelected = []
        }
        else if(this.isShowingRecebidos === 'Recebidos' && this.selectedProduct !== 'CSCP'){

        const formatedDate = moment(date).format('DD/MM/YYYY');
        this.shopId$
          .pipe(
            first(),
            switchMap(id => this.financialStatementService.downloadExtractReceived(id, this.selectedProduct, formatedDate,  this.optionSelected ))
          )
          .subscribe(response => {
            this.processDownload(response);
          });
          this.optionSelected = 'csv'
        }
        else{
          this.downloadExtractRealizados(date, this.optionSelected );
        }
      },
      reason => {
        console.log('fechou');
      }
    )
  }
  openDialogFinancialStatementPeriodDownload(): void {
    if(this.selectedProduct === 'CSCP'){
      this.list = this.listPaymentsCSCP
    }else{
      this.list = this.listPayments
    }
    const checkedFilter = this.list.filter((item: PaymentShowcaseModel) => item.checked);
    if (!!checkedFilter && !!checkedFilter.length) {
      const modalRef = this.modalService.open(StatementPeriodDownloadDialogComponent, {
        windowClass: 'modal-filter',

      });
      this.tabSelected =  this.tabSelected? this.tabSelected: "Propostas Efetivadas"
      modalRef.componentInstance.productSelected =this.selectedProduct
      modalRef.componentInstance.selectedProduct = this.selectedProduct;
      modalRef.componentInstance.optionSelected = this.optionSelected;
      modalRef.componentInstance.tabSelected = this.tabSelected;
      modalRef.componentInstance.financialStatementPeriod = checkedFilter;
      modalRef.componentInstance.selectedProduct = this.selectedProduct;
      modalRef.componentInstance.isShowingRecebidos = this.isShowingRecebidos;
      modalRef.result.then(
        result => {
          this.genTagger.setTag({ event_category: this.category, event_action: Tag.pgView, event_label: 'parcelas' });
        },
        reason => {
          console.log(reason);
        }
      );
    }
    this.list = [];
  }

  public cleanFilterObjReloadList() {
    this.searchString = '';
    this.filterObj = {
      period: '-1d'
    };
  }

  openDialogDateRangePicker() {
    const modalRef = this.modalService.open(DateRangePickerDialogComponent, {
      windowClass: 'modal-filter'
    });
    modalRef.componentInstance.currentTab = this.tabSelected
    modalRef.result.then(
      result => {

        this.selectedInitialDate = result.dataInical;
        this.selectedFinalDate = result.dataFinal;
        this.checkTheTabAndIfIsCSCPAndCallService();

      },
      reason => {
        console.log(reason);
      }
    );

    this.genTagger.setTag({
      event_category: this.category,
      event_action: 'selecionou - período',
      event_label: this.translateService.instant('OTHER')
    });
  }

  private checkTheTabAndIfIsCSCPAndCallService() {
    if (this.selectedProduct === 'CSCP') {
      if (this.tabSelected === 'Saldo Futuro') {
        this.getListFutureBalance();
      }
      else if (this.tabSelected === 'Pagamentos Realizados') {
        this.getPaymentsMade();
      } else {
        this.getEffectiveProposals();
      }
    }
    else {
      this.getListPayments();
    }
  }

  public product(product: string){
    this.selectedProduct = product;
    this.productSelected.emit(this.selectedProduct);
    if (this.selectedProduct !== 'CSCP') {
    this.selectedDays = 90;
    this.selectedInitialDate = moment()
      .subtract(this.selectedDays, 'days')
      .format('DD/MM/YYYY');
    this.getListPayments();
    }

  }

  public selectProduct(product) {
    this.genTagger.setTag({
      event_category: this.category,
      event_action: 'selecionou - produtos',
      event_label: product
    });
  }

  // TO-DO: fazer download do extrato enviando apenas a data
  public downloadExtract(date) {
    if (this.isShowingRecebidos === 'Recebidos'  && this.selectedProduct !== 'CSCP') {
      this.downloadExtractReceived(date, this.isShowingRecebidos === 'Recebidos');
    }else if(this.isShowingRecebidos === 'Recebidos' && this.tabSelected === 'Propostas Efetivadas' && this.selectedProduct === 'CSCP'){
      const type = 'Propostas Efetivadas';
      this.downloadExtractPaymentMade(date, type);
    } else {
      this.downloadExtractReceived(date, this.isShowingRecebidos !== 'Recebidos');

    }
  }

  public downloadExtractCSCP(date, type) {
    if (type === 'Pagamentos Realizados') {
      this.downloadExtractPaymentMade(date, type);
    } else if(type === 'Saldo Futuro' ) {
      this.downloadExtractFutureBalances(date, type);
    }
  }

  public downloadExtractPaymentMade(date, type){
    this.openDownloadFileTypeDialogCSCP(date, type)
  }
  public downloadExtractFutureBalances(date, type){
    this.openDownloadFileTypeDialogCSCP(date, type)
  }

  public downloadExtractRealizados(date, optionSelected) {
    const formatedDate = moment(date).format('DD/MM/YYYY');
    this.shopId$
      .pipe(
        first(),
        switchMap(id => this.financialStatementService.downloadExtract(id, this.selectedProduct, formatedDate, optionSelected))
      )
      .subscribe(response => {
        this.processDownload(response);
      });
  }

  public downloadExtractReceived(date, type) {
    this.openDownloadFileTypeDialog(date, type)
  }

  private processDownload(response) {
    const fileName = response.headers
      .get('content-disposition')
      .split(';')[1]
      .split('=')[1]
      .replace(/\"/g, '');

    const newBlob = new Blob([response.body], { type: 'text/csv' });
    if (navigator.msSaveBlob) {
      navigator.msSaveBlob(newBlob, fileName);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        const url = URL.createObjectURL(newBlob);
        link.setAttribute('href', url);
        link.setAttribute('download', fileName);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }

  public selectPeriod(days) {
    let event_label = null;
    switch (days) {
      case 0:
        event_label = this.translateService.instant('TODAY');
      break;
      case 7:
        event_label = this.translateService.instant('SEVEN_DAYS');
        break;
      case 30:
        event_label = this.translateService.instant('THIRTY_DAYS');
        break;
      case 90:
        event_label = this.translateService.instant('NINETY_DAYS');
        break;
      default:
        event_label = days;
        break;
    }

    this.genTagger.setTag({
      event_action: 'selecionou - período',
      event_category: this.category,
      event_label
    });
    if (this.tabSelected === 'Saldo Futuro') {
      this.selectedDays = days;
      this.selectedFinalDate = moment()
        .add(days, 'days')
        .format('DD/MM/YYYY');
      this.selectedInitialDate = moment().format('DD/MM/YYYY');
      this.checkTheTabAndIfIsCSCPAndCallService();
    } else {
      this.selectedDays = days;
      this.selectedInitialDate = moment()
        .subtract(days, 'days')
        .format('DD/MM/YYYY');
      this.selectedFinalDate = moment().format('DD/MM/YYYY');
      this.checkTheTabAndIfIsCSCPAndCallService();
    }
  }
  setDate() {
    this.selectedInitialDate = moment()
      .subtract(this.selectedDays, 'days')
      .format('DD/MM/YYYY');
    this.selectedFinalDate = moment().format('DD/MM/YYYY');
  }
  setToday(day){
    this.selectedInitialDate = moment()
      .subtract(day, 'days')
      .format('DD/MM/YYYY');
    this.selectedFinalDate = moment().format('DD/MM/YYYY');
  }
  ngOnDestroy() {
    this.selectedProduct = '';
    this.modalService.dismissAll('StatementPeriodDownloadDialogComponent');
  }
}
