<ul class="proposal-list">
  <li class="proposal-item" *ngFor="let proposal of proposals; let i = index">
    <div class="row no-gutters mb-3">
      <div class="col-1 pt-1 text-center">
        <img
          *ngIf="proposal.bipartido"
          src="./assets/santander/icons/ic_bipartition.svg"
          ngbTooltip="A proposta é composta por um ou mais parceiros."
          alt="icon bipartido"
          triggers="click:blur"
          placement="right"
          container="body"
          width="20"
          class="icon-bipartido"
          matTooltipHideDelay="100000"
        />
      </div>

      <div class="col pl-1">
        <div class="row no-gutters mb-3">
          <div class="col-auto pr-3">
            <span class="proposal-item__label">{{ 'EXPIRES_IN' | translate }}</span>

            <div class="d-flex align-items-center w-100">
              <img
                src="./assets/santander/icons/ic_clock.svg"
                alt="icon urgent"
                [hidden]="!proposal.showClock"
                class="icon-period mr-2"
              />

              <img
                [src]="
                  proposal.expiringDays > 10
                    ? './assets/santander/icons/ic_clock_yellow.svg'
                    : './assets/santander/icons/ic_clock_red.svg'
                "
                alt="icon urgent"
                class="icon-period mr-2"
                [hidden]="proposal.showClock"
              />

              <div class="swap-text d-inline-block proposal-item__value">
                <span class="swap-text-item" *ngIf="proposal.expiringDays <= 5 || proposal.showClock">
                  {{ !proposal.showClock ? proposal.expiringDays : '0' }}
                  {{ proposal.expiringDays === 1 && !proposal.showClock ? 'dia' : 'dias' }}
                </span>
                <span class="swap-text-item">{{ proposal.date | date: 'dd/MM/yyyy' }}</span>
                <span class="swap-text-item" *ngIf="proposal.expiringDays > 5 && !proposal.showClock">
                  {{ proposal.expiringDays }} dias
                </span>
              </div>
            </div>
          </div>

          <div class="col">
            <span class="proposal-item__label">{{ 'FINANCED_AMOUNT' | translate }}</span>

            <div class="swap-text proposal-item__value">
              <span class="swap-text-item">{{ proposal.value | currency: 'BRL' }}</span>
              <span class="swap-text-item">
                <small>{{ proposal.amountParcels }}x de {{ proposal.valueParcels | currency: 'BRL' }}</small>
              </span>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <span class="proposal-item__label">{{ 'Status' | translate }}</span>

            <div>
              <img src="./assets/santander/icons/{{ proposal.statusIcon }}" alt="icon" class="icon-status mr-2" />
              <span class="user-name proposal-item__value">{{ proposal.statusDescription }}</span>
              <img
                *ngIf="proposal.statusText"
                src="./assets/images/tooltip-payment-col.svg"
                alt="icon"
                class="icon-tooltip ml-1"
                matTooltip="{{ proposal.statusText }}"
                matTooltipClass="custom-tooltip-proposal"
                matTooltipHideDelay="100"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="col-2">
        <button
          class="btn-kebab"
          mat-icon-button
          [matMenuTriggerFor]="menu"
          aria-label="Menu de opções"
          [disabled]="!proposal.kebab.length && !proposal.button"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button [hidden]="!proposal.button" mat-menu-item (click)="onHandleProposalActionBtn(proposal)">
            <span>{{ proposal.button | translate }}</span>
          </button>
          <button
            [hidden]="!proposal.kebab.length"
            mat-menu-item
            (click)="onKebabAction(kebab.code, proposal.id)"
            *ngFor="let kebab of proposal.kebab"
          >
            <span>{{ kebab.description }}</span>
          </button>
        </mat-menu>
      </div>
    </div>

    <div class="row no-gutters">
      <div class="col-1 pt-1">
        <img
          src="./assets/santander/icons/ic_star.svg"
          alt="icon star"
          class="icon-star"
          ngbTooltip="A proposta foi iniciada por um cliente e é uma venda potencial."
          triggers="click:blur"
          placement="right"
          container="body"
          *ngIf="!proposal.sellerAssociate"
        />
      </div>

      <div class="col-10 pl-1">
        <span class="proposal-item__label">{{ 'CLIENT' | translate }}</span>

        <div class="swap-text proposal-item__value">
          <span class="swap-text-item">{{ proposal.user }}</span>
          <span class="swap-text-item" *ngIf="proposal.documentNumber.length === 11">
            {{ proposal.documentNumber | mask: '000.000.000-00' }}
          </span>
          <span class="swap-text-item" *ngIf="proposal.documentNumber.length === 14">
            {{ proposal.documentNumber | mask: '00.000.000/0000-00' }}
          </span>
        </div>
      </div>
    </div>
  </li>
</ul>
