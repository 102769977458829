import { selectManagementPanelFeatureState } from '@features/management-panel/state/feature-selector';
import { createSelector } from '@ngrx/store';
import { FilterFunnel, SearchFilterRequest } from '@shared/graphql/types/query-types';

export const filtersState = createSelector(
  selectManagementPanelFeatureState,
  state => state.managementFilters
);

export const filtersInputSearchState = createSelector(
  filtersState,
  state => {
    const {
      selected: { product, collaborator, client, channel, bipartite, store, subsegment, subsidiary }
    } = state;

    const filteredSalesPerson = collaborator.filter(({ idSalesPerson: id }) => !!id);
    const idSalesPerson =
      filteredSalesPerson && filteredSalesPerson.length
        ? (filteredSalesPerson.map(({ idSalesPerson: id }) => id).filter(Boolean) as any)
        : null;

    const filterInput: FilterFunnel = {
      idBranch: subsidiary && subsidiary.idUnidComercial,
      idStore: store && store.idFornecCanal,
      bipartite: bipartite && (bipartite.key === 'TODOS' ? null : bipartite.key),
      cdProduct: product && (product.cdProduct === 'TODOS' ? null : product.cdProduct),
      idChannel: channel && channel.idChannel,
      idSalesPerson,
      idSubseg: subsegment && subsegment.idSubseg,
      tpClient: client && (client.key === 'TODOS' ? null : client.key)
    };

    return filterInput;
  }
);

export const sellerRankingInputSearchState = createSelector(
  filtersState,
  filtersInputSearchState,
  (filtros, filtersInput) => {
    const { sellerRankingFilter } = filtros.selected;
    const input: SearchFilterRequest = {
      filter: filtersInput,
      search: sellerRankingFilter
    };

    return { input };
  }
);

export const storeRankingInputSearchState = createSelector(
  filtersState,
  filtersInputSearchState,
  (filtros, filtersInput) => {
    const { storeRankingFilter } = filtros.selected;
    const input: SearchFilterRequest = {
      filter: filtersInput,
      search: storeRankingFilter
    };

    return { input };
  }
);

export const shouldPassFiltersToProposalPanelSelector = createSelector(
  filtersState,
  ({ shouldPassFiltersToProposalPanel }) => shouldPassFiltersToProposalPanel
);

export const hasBipartiteFilterState = createSelector(
  filtersState,
  state => state.hasBipartite
);

export const availableFiltersState = createSelector(
  filtersState,
  state => state.available
);

export const selectedFiltersState = createSelector(
  filtersState,
  state => state.selected
);
export const selectedSubsidiaryState = createSelector(
  filtersState,
  state => state.selected.subsidiary
);

export const selectedStoreState = createSelector(
  filtersState,
  state => state.selected.store
);

export const selectedSubsegmentState = createSelector(
  filtersState,
  state => state.selected.subsegment
);

export const selectedCollaboratorsState = createSelector(
  filtersState,
  state => state.selected.collaborator
);

export const selectedChannelState = createSelector(
  filtersState,
  state => state.selected.channel
);

export const selectedProductState = createSelector(
  filtersState,
  state => state.selected.product
);

export const selectedClientState = createSelector(
  filtersState,
  state => state.selected.client
);

export const selectedBipartiteState = createSelector(
  filtersState,
  state => state.selected.bipartite
);

export const availableSubsidiaryState = createSelector(
  availableFiltersState,
  state => state.subsidiaries
);

export const availableStoreState = createSelector(
  availableFiltersState,
  state => state.stores
);

export const availableSubsegmentState = createSelector(
  availableFiltersState,
  state => state.subsegments
);

export const availableCollaboratorsState = createSelector(
  availableFiltersState,
  state => state.collaborators
);

export const availableChannelState = createSelector(
  availableFiltersState,
  state => state.channels
);

export const availableProductState = createSelector(
  availableFiltersState,
  state => state.products
);

export const availableClientState = createSelector(
  availableFiltersState,
  state => state.clients
);

export const availableBipartiteState = createSelector(
  availableFiltersState,
  state => state.bipartite
);
