<div class="container recovery-token-container">
  <app-arrow-back (click)="loc.back()"></app-arrow-back>
  <app-token-input
    [title]="'RECOVERY-TOKEN-TITLE'"
    [subtitle]="'RECOVERY-TOKEN-SUBTITLE'"
    [btnMessage]="'RECOVERY-TOKEN-BTN-MSG'"
    notYoursText="{{ currentCountry === countries.BR ? null : 'INCORRECT-DESTINATION-MSG' }}"
    [numberOfDigits]="4"
    [tokenDestination]="tokenDestination"
    [tokenDestinationMask]="tokenDestinationMask"
    [tokenExpirationTime]="tokenExpirationTime"
    (submitEmit)="sendToken($event)"
    (resendEmit)="resendToken($event)"
  >
  </app-token-input>
</div>
