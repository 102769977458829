import {
  getBranchs_getBranchs,
  getChannels_getChannels,
  getProducts_getProducts,
  getSalespersons_getSalespersons,
  getStores_getStores,
  getSubsegs_getSubsegs
} from '@shared/graphql/types/query-types';
import { ManagementFiltersActions, ManagementFiltersActionTypes } from './management-filters.actions';

export const managementFiltersFeatureKey = 'managementFilters';

export interface SelectedFilters {
  subsidiary: getBranchs_getBranchs | null;
  store: getStores_getStores;
  subsegment: getSubsegs_getSubsegs | null;
  collaborator: getSalespersons_getSalespersons[] | null;
  channel: getChannels_getChannels | null;
  product: getProducts_getProducts | null;
  client: ({ key: string; value: string | null }) | null;
  bipartite: ({ key: boolean; description: string }) | any;
  storeRankingFilter: string;
  sellerRankingFilter: string;
  statusPG: string[];
  statusFunil: string[];
}

export const defaultSelectedFilters: Partial<SelectedFilters> = {
  subsidiary: { dsUnidComercial: 'TODOS', idUnidComercial: null, cdUser: null, idUser: null, __typename: 'Branch' },
  store: {
    idUser: null,
    cdUser: null,
    idFornecCanal: null,
    dsFornecCanal: 'TODOS',
    __typename: 'Store'
  },
  subsegment: {
    idUser: null,
    cdUser: null,
    idSubseg: null,
    cdSubseg: null,
    dsSubseg: 'TODOS',
    __typename: 'Subseg'
  },
  collaborator: [
    {
      idUser: null,
      cdUser: null,
      idSalesPerson: null,
      dsSalesPerson: 'TODOS',
      __typename: 'Salesperson'
    }
  ],
  channel: {
    idChannel: null,
    dsChannel: 'TODOS',
    __typename: 'Channel'
  },
  product: {
    cdProduct: 'TODOS',
    __typename: 'MgmProduct'
  },
  client: {
    key: 'TODOS',
    value: 'TODOS'
  },

  bipartite: {
    key: 'TODOS',
    description: 'TODOS'
  },
  sellerRankingFilter: null,
  storeRankingFilter: null
};

export interface AvailableFilters {
  subsidiaries: getBranchs_getBranchs[] | null;
  stores: getStores_getStores[] | null;
  subsegments: getSubsegs_getSubsegs[] | null;
  collaborators: getSalespersons_getSalespersons[] | null;
  channels: getChannels_getChannels[] | null;
  products: getProducts_getProducts[] | null;
  clients: ({ key: string; value: string | null })[] | null;
  bipartite: { key: boolean; description: string }[] | null;
}
export interface State {
  hasBipartite: boolean;
  shouldPassFiltersToProposalPanel: boolean;
  selected: SelectedFilters;
  available: AvailableFilters;
}

export const initialState: State = {
  hasBipartite: true,
  shouldPassFiltersToProposalPanel: false,
  selected: {
    subsidiary: null,
    store: null,
    subsegment: null,
    collaborator: [],
    channel: null,
    product: null,
    client: null,
    bipartite: null,
    sellerRankingFilter: null,
    storeRankingFilter: null,
    statusPG: null,
    statusFunil: null
  },
  available: {
    subsidiaries: [],
    stores: [],
    subsegments: [],
    collaborators: [],
    channels: [],
    products: [],
    clients: [defaultSelectedFilters.client, { key: 'F', value: 'PF' }, { key: 'J', value: 'PJ' }],
    bipartite: [defaultSelectedFilters.bipartite, { key: true, description: 'Sim' }, { key: false, description: 'Não' }]
  }
};

export function reducer(state = initialState, action: ManagementFiltersActions): State {
  switch (action.type) {
    case ManagementFiltersActionTypes.UpdateClientFiltersList: {
      return { ...state, available: { ...state.available, clients: [...state.available.clients] } };
    }

    case ManagementFiltersActionTypes.UpdateProductsFiltersList: {
      return { ...state, available: { ...state.available, products: [...state.available.products] } };
    }

    case ManagementFiltersActionTypes.UpdateBipartiteFiltersList: {
      return { ...state, available: { ...state.available, bipartite: [...state.available.bipartite] } };
    }

    case ManagementFiltersActionTypes.UpdateSelectedSubsidiaryFilter:
      return { ...state, selected: { ...state.selected, subsidiary: { ...action.payload } } };

    case ManagementFiltersActionTypes.UpdateSelectedStoreFilter:
      return { ...state, selected: { ...state.selected, store: { ...action.payload } } };

    case ManagementFiltersActionTypes.UpdateSelectedCollaboratorFilter: {
      return {
        ...state,
        selected: { ...state.selected, collaborator: [...action.payload] }
      };
    }
    case ManagementFiltersActionTypes.UpdateSelectedSubsegmentFilter: {
      return { ...state, selected: { ...state.selected, subsegment: { ...action.payload } } };
    }

    case ManagementFiltersActionTypes.UpdateSelectedChannelFilter:
      return { ...state, selected: { ...state.selected, channel: { ...action.payload } } };

    case ManagementFiltersActionTypes.UpdateSelectedProductFilter:
      return { ...state, selected: { ...state.selected, product: { ...action.payload } } };

    case ManagementFiltersActionTypes.UpdateSelectedClientFilter:
      return { ...state, selected: { ...state.selected, client: { ...action.payload } } };

    case ManagementFiltersActionTypes.UpdateSelectedBipartiteFilter:
      return { ...state, selected: { ...state.selected, bipartite: { ...action.payload } } };

    case ManagementFiltersActionTypes.UpdateSelectedSellerRankingSearchTerm: {
      const { searchTerm } = action;

      return { ...state, selected: { ...state.selected, sellerRankingFilter: searchTerm } };
    }

    case ManagementFiltersActionTypes.UpdateSelectedStoreRankingSearchTerm: {
      const { searchTerm } = action;

      return { ...state, selected: { ...state.selected, storeRankingFilter: searchTerm } };
    }

    case ManagementFiltersActionTypes.UpdateAvailableBipartiteFilter: {
      return { ...state, hasBipartite: action.payload };
    }

    case ManagementFiltersActionTypes.UpdateShouldPassFiltersToProposalPanel: {
      const {
        updateValue,
        status: { statusFunil, statusPG }
      } = action;

      return {
        ...state,
        shouldPassFiltersToProposalPanel: updateValue,
        selected: { ...state.selected, statusPG, statusFunil }
      };
    }

    case ManagementFiltersActionTypes.LoadChannelFiltersSuccess: {
      const getChannels = (action.payload && action.payload.getChannels) || [];
      let cleanArray = [defaultSelectedFilters.channel];
      cleanArray = [...cleanArray, ...getChannels];

      return {
        ...state,
        available: {
          ...state.available,
          channels: cleanArray
        }
      };
    }

    case ManagementFiltersActionTypes.LoadChannelFiltersFailure: {
      return {
        ...state,
        available: {
          ...state.available,
          channels: [defaultSelectedFilters.channel]
        }
      };
    }

    case ManagementFiltersActionTypes.LoadCollaboratorsFiltersSuccess: {
      let collaborators: getSalespersons_getSalespersons[] = [];

      if (action.payload && action.payload.getSalespersons && action.payload.getSalespersons.length) {
        collaborators = collaborators.concat(action.payload.getSalespersons);
      }

      return {
        ...state,
        available: { ...state.available, collaborators: [...defaultSelectedFilters.collaborator, ...collaborators] }
      };
    }

    case ManagementFiltersActionTypes.LoadCollaboratorsFiltersFailure: {
      return {
        ...state,
        available: { ...state.available, collaborators: [...defaultSelectedFilters.collaborator] }
      };
    }

    case ManagementFiltersActionTypes.LoadProductsFiltersSuccess: {
      const getProducts = (action.payload && action.payload.getProducts) || [];

      return {
        ...state,
        available: { ...state.available, products: [defaultSelectedFilters.product, ...getProducts] }
      };
    }

    case ManagementFiltersActionTypes.LoadProductsFiltersFailure: {
      return {
        ...state,
        available: { ...state.available, products: [defaultSelectedFilters.product] }
      };
    }

    case ManagementFiltersActionTypes.LoadStoreFiltersSuccess: {
      const getStores = (action.payload && action.payload.getStores) || [];

      return { ...state, available: { ...state.available, stores: [defaultSelectedFilters.store, ...getStores] } };
    }

    case ManagementFiltersActionTypes.LoadStoreFiltersFailure: {
      return { ...state, available: { ...state.available, stores: [defaultSelectedFilters.store] } };
    }

    case ManagementFiltersActionTypes.LoadSubsegmentFiltersSuccess: {
      const getSubsegs = (action.payload && action.payload.getSubsegs) || [];

      return {
        ...state,
        available: { ...state.available, subsegments: [defaultSelectedFilters.subsegment, ...getSubsegs] }
      };
    }

    case ManagementFiltersActionTypes.LoadSubsegmentFiltersFailure: {
      return {
        ...state,
        available: { ...state.available, subsegments: [defaultSelectedFilters.subsegment] }
      };
    }

    case ManagementFiltersActionTypes.LoadSubsidiaryFiltersSuccess: {
      const getBranchs = (action.payload && action.payload.getBranchs) || [];

      return {
        ...state,
        available: { ...state.available, subsidiaries: [defaultSelectedFilters.subsidiary, ...getBranchs] }
      };
    }
    case ManagementFiltersActionTypes.LoadSubsidiaryFiltersFailure: {
      return { ...state, available: { ...state.available, subsidiaries: [defaultSelectedFilters.subsidiary] } };
    }

    default:
      return state;
  }
}
