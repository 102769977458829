import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { SubsidyData } from '@app/proposal-workflow/models/subsidy-data.model';
import { debounceTime, tap } from 'rxjs/operators';

@Component({
  selector: 'app-subsidiary-value',
  templateUrl: './subsidiary-value.component.html',
  styleUrls: ['./subsidiary-value.component.scss']
})
export class SubsidiaryValueComponent implements OnInit, AfterViewInit, OnChanges {
  @Input() subsidyData: SubsidyData;
  @Output() subsidyValueChanged = new EventEmitter();

  public form: FormGroup;
  public editValues: boolean;
  public isExpanded = false;

  public buyerRate = 0;
  public shopkeeperRate = 0;
  public subsidyPercentage = 0;
  public withheldAmount = 0;
  public releasedAmount = 0;
  public retentionPercentage = 0;

  // subsidiarySlider
  public stepValue = 5;
  public minValue = 0;
  public maxValue = 0;
  public showSelectedRange = true;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      subsidiaryValue: new FormControl(0),
      subsidiarySlider: new FormControl(0)
    });
  }

  ngOnInit() {
    this.form
      .get('subsidiarySlider')
      .valueChanges.pipe(
        tap(() => {
          this.drawRuler();
        }),
        debounceTime(500)
      )
      .subscribe(() => {
        this.subsidyValueChanged.emit(this.form.value);
      });
  }

  ngAfterViewInit() {
    this.drawRuler();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes.subsidyData && changes.subsidyData.currentValue) {
      this.setValues();
    }
  }

  private setValues(): void {
    const data = this.subsidyData;
    if (!!data) {
      this.buyerRate = !!data.buyerRate ? data.buyerRate : 0;
      this.shopkeeperRate = !!data.shopkeeperRate ? data.shopkeeperRate : 0;
      this.subsidyPercentage = !!data.subsidyPercentage ? data.subsidyPercentage : 0;
      this.withheldAmount = !!data.withheldAmount ? data.withheldAmount : 0;
      this.releasedAmount = !!data.releasedAmount ? data.releasedAmount : 0;
      this.retentionPercentage = !!data.retentionPercentage ? data.retentionPercentage : 0;
      this.minValue = !!data.minSubsidyPercentage ? data.minSubsidyPercentage : 0;
      this.maxValue = !!data.maxSubsidyPercentage ? data.maxSubsidyPercentage : 0;
      this.form.get('subsidiarySlider').setValue(this.subsidyPercentage, { emitEvent: false });
      this.form.get('subsidiaryValue').setValue(this.withheldAmount, { emitEvent: false });
      this.drawRuler();
    }
  }

  private drawRuler(): void {
    const inputRange: any = document.querySelector('#subsidiarySlider');
    const selectedRange: any = document.querySelector('#subsidySliderSelectedRange');

    if (inputRange) {
      const percentRange = ((inputRange.value - inputRange.min) * 100) / (inputRange.max - inputRange.min) + '% 100%';
      inputRange.style.backgroundSize = percentRange;
      if (selectedRange) {
        this.showSelectedRange =
          this.form.get('subsidiarySlider').value > this.minValue &&
          this.form.get('subsidiarySlider').value < this.maxValue;
        selectedRange.style.left = this.form.get('subsidiarySlider').value + '%';
      }
      if (this.subsidyData) {
        const sliderState = this.subsidyData.enableSubsidySlider ? 'enable' : 'disable';
        this.form.get('subsidiarySlider')[sliderState]({ onlySelf: true, emitEvent: false });
      }
    }
  }

  public changeValues(): void {
    this.editValues = !this.editValues;
    if (!this.editValues && this.form.valid) {
      this.subsidyValueChanged.emit(this.form.value);
    }
    this.toggleFormFields();
  }

  private toggleFormFields(): void {
    const formState = this.editValues ? 'enable' : 'disable';
    this.form.get('subsidiaryValue')[formState]({ emitEvent: false });
  }
}
