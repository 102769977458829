import { Injectable } from '@angular/core';
import { CanActivate, Router, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { environment } from '@env/environment';

@Injectable()
export class CountryGuard implements CanActivate {
  activeRouter: any;
  constructor(private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    // Check if the routing is safe for the current country
    if (route && route.data && route.data.country === environment.currentCountry) {
      return true;
    }

    return false;
  }
}
