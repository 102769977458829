<div id="modal-content-wrapper">
  <header id="modal-header" class="text-center mt-4">
    <h1 id="modal-title mt-2 mb-2" mat-dialog-title>{{ 'PAYMENT-MASSIVE-UPLOAD' | translate }}</h1>
  </header>
  <section id="modal-body" mat-dialog-content>
    <p>
      Envie a planilha que você extraiu para solicitar o pagamento das propostas com status "Pagamento Disponível" e/ou
      "Erro de Pagamento" de uma só vez.
    </p>
    <div class="attach-container mb-2">
      <p class="flex">
        <img src="./assets/images/ic_upload.svg" alt="Upload" class="mr-2" />{{ 'PAYMENT-MASSIVE-FILE' | translate }}*
      </p>
      <p class="flex text-gray">
        {{ 'ACCEPTED-FORMATS' | translate }} *.csv - {{ 'MAX-FILE-SIZE-ALLOWED-MSG' | translate: { maxSize: 10 } }}.
      </p>
      <p *ngIf="!!fileName" class="flex text-gray">{{ fileName }}</p>
      <ngb-alert
        *ngIf="!warningAlertClosed"
        class="alert-payment-massive-upload"
        type="custom"
        (close)="warningAlertClosed = true"
        >{{ 'FILE-EXCEEDS-MAXIMUM-SIZE-MSG' | translate }}</ngb-alert
      >
      <label for="upload-archive" class="action">{{ 'ATTACH' | translate }}</label>
      <input
        type="file"
        name="upload archive"
        class="upload-archive"
        id="upload-archive"
        accept=".csv"
        (change)="onChange($event)"
        appTagger
        [event_category]="category"
        [event_action]="Insert"
        event_label="{{ 'ATTACH' | translate }}"
      />
    </div>
  </section>
  <footer id="modal-footer" mat-dialog-actions class="mb-3">
    <button
      mat-stroked-button
      id="modal-close-button"
      color="primary"
      (click)="dialogRef.close('error-upload')"
      appTagger
      [event_category]="category"
      event_label="{{ 'BTN-CANCEL' | translate }}"
    >
      {{ 'BTN-CANCEL' | translate }}
    </button>
    <button
      mat-flat-button
      id="modal-action-button"
      color="primary"
      (click)="upload()"
      [disabled]="!unabledUploadButton"
      appTagger
      [event_category]="category"
      event_label="{{ 'SEND-FILE' | translate }}"
    >
      {{ 'SEND-FILE' | translate }}
    </button>
  </footer>
</div>
