<div>
  <h4>{{ 'NPP-NEGOTIATION-TITLE' | translate }}</h4>

  <form class="npp-negotiation-form" [formGroup]="form">
    <section>
      <mat-radio-group formControlName="negotiationType">
        <!-- Início - Installment Value -->
        <ng-container *ngIf="productCode === PRODUCT_CODE.CDC && showInstallmentValue">
          <mat-radio-button class="mt-2" value="installmentValue">
            {{ 'NEGOTIATE-INSTALLMENT-VALUE' | translate }}
          </mat-radio-button>

          <div>
            <label for="desiredInstallmentValue" class="d-block small pb-0 mb-0 text-left">{{
              'INSERT-DESIRED-INSTALLMENT-VALUE' | translate
            }}</label>
            <mat-form-field color="black" appearance="legacy" class="input">
              <input
                autocomplete="off"
                type="text"
                matInput
                formControlName="desiredInstallmentValue"
                currencyMask
                [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
                maxlength="11"
                class="text-left"
                id="desiredInstallmentValue"
              />
            </mat-form-field>
            <ng-container *ngIf="negotiationType?.value === 'installmentValue' && negotiation?.negotiationStatus">
              <ng-template [ngTemplateOutlet]="negotiationStatus"></ng-template>
              <mat-form-field
                color="black"
                appearance="legacy"
                class="input"
                *ngIf="
                  negotiation?.negotiationStatus === NEGOTIATION_STATUS.APPROVED ||
                  negotiation?.negotiationStatus === NEGOTIATION_STATUS.PARTIAL
                "
              >
                <input
                  autocomplete="off"
                  type="text"
                  matInput
                  formControlName="approvedInstallmentValue"
                  currencyMask
                  [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
                  maxlength="11"
                  class="text-left"
                  id="approvedInstallmentValue"
                />
              </mat-form-field>
            </ng-container>
          </div>
        </ng-container>
        <!-- Fim - Installment Value -->

        <!-- Início - Negotiate Rate -->
        <mat-radio-button class="mt-2" value="interestRate" *ngIf="showNegotiateRateRadio">{{
          'NEGOTIATE-RATE' | translate
        }}</mat-radio-button>
        <label *ngIf="!showNegotiateRateRadio" for="">{{ 'NEGOTIATE-RATE' | translate }}</label>

        <div>
          <label for="desiredRate" class="d-block small pb-0 mb-0 text-left">{{
            'INSERT-DESIRED-RATE-VALUE' | translate
          }}</label>
          <div class="d-flex align-items-center">
            <mat-form-field color="black" appearance="legacy" class="input mr-2">
              <input
                autocomplete="off"
                type="text"
                matInput
                formControlName="desiredRate"
                [dropSpecialCharacters]="false"
                mask="0*,99"
                suffix="%"
                id="desiredRate"
              />
            </mat-form-field>
            <span>{{ 'PER-MONTH' | translate }}</span>
          </div>
          <ng-container *ngIf="negotiationType?.value === 'interestRate' && negotiation?.negotiationStatus">
            <ng-template [ngTemplateOutlet]="negotiationStatus"></ng-template>
            <div
              class="d-flex align-items-center"
              *ngIf="
                negotiation?.negotiationStatus === NEGOTIATION_STATUS.APPROVED ||
                negotiation?.negotiationStatus === NEGOTIATION_STATUS.PARTIAL
              "
            >
              <mat-form-field color="black" appearance="legacy" class="input mr-2">
                <input
                  autocomplete="off"
                  type="text"
                  matInput
                  formControlName="approvedInterestRate"
                  mask="0*,99"
                  suffix="%"
                  id="approvedInterestRate"
                />
              </mat-form-field>
              <span>{{ 'PER-MONTH' | translate }}</span>
            </div>
          </ng-container>
        </div>
        <!-- Fim - Negotiate Rate -->

        <!-- Início - Retention Percentage -->
        <ng-container *ngIf="(productCode === PRODUCT_CODE.CSC || productCode === PRODUCT_CODE.CSCP) && showRetentionPercentage">
          <mat-radio-button class="mt-2" value="retentionPercentage">{{
            'NEGOTIATE-RETENTION-RATE' | translate
          }}</mat-radio-button>

          <div>
            <label for="desiredRetentionPercentage" class="d-block small pb-0 mb-0 text-left">{{
              'INSERT-DESIRED-RETENTION-VALUE' | translate
            }}</label>
            <div class="d-flex align-items-center">
              <mat-form-field color="black" appearance="legacy" class="input mr-2">
                <input
                  autocomplete="off"
                  type="text"
                  matInput
                  formControlName="desiredRetentionPercentage"
                  mask="0*,99"
                  suffix="%"
                  id="desiredRetentionPercentage"
                />
              </mat-form-field>
              <span>{{ 'PER-MONTH' | translate }}</span>
            </div>
            <ng-container *ngIf="negotiationType?.value === 'retentionPercentage' && negotiation?.negotiationStatus">
              <ng-template [ngTemplateOutlet]="negotiationStatus"></ng-template>
              <div
                class="d-flex align-items-center"
                *ngIf="
                  negotiation?.negotiationStatus === NEGOTIATION_STATUS.APPROVED ||
                  negotiation?.negotiationStatus === NEGOTIATION_STATUS.PARTIAL
                "
              >
                <mat-form-field color="black" appearance="legacy" class="input mr-2">
                  <input
                    autocomplete="off"
                    type="text"
                    matInput
                    formControlName="approvedRetentionPercentage"
                    mask="0*,99"
                    suffix="%"
                    id="approvedRetentionPercentage"
                  />
                </mat-form-field>
                <span>{{ 'PER-MONTH' | translate }}</span>
              </div>
            </ng-container>
          </div>
        </ng-container>
        <!-- Fim - Retention Percentage -->
      </mat-radio-group>
    </section>

    <button
      type="submit"
      role="button"
      class="action-button mt-5"
      (click)="onNegotiate()"
      [disabled]="!form.valid || form.untouched || isDisabled"
    >
      {{ 'NEGOTIATE' | translate }}
    </button>
  </form>
</div>

<ng-template #negotiationStatus>
  <div [ngSwitch]="negotiation?.negotiationStatus">
    <span class="text-success small" *ngSwitchCase="NEGOTIATION_STATUS.APPROVED">{{
      'AMOUNT-APPROVED-IN-FULL' | translate
    }}</span>
    <span class="text-warning small" *ngSwitchCase="NEGOTIATION_STATUS.PARTIAL">{{
      'AMOUNT-PARTIALLY-APPROVED' | translate
    }}</span>
    <span class="text-danger small" *ngSwitchCase="NEGOTIATION_STATUS.DENIED">{{
      'CANNOT-NEGOTIATE-DESIRED-AMOUNT' | translate
    }}</span>
  </div>
</ng-template>
