import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core/state';
import { SetTravelerDataSection } from '@app/core/state/proposal-information/proposal-information.actions';
import * as proposalInformationSelect from '@app/core/state/proposal-information/proposal-information.selectors';
import {
  ProposalParameters,
  ProposalSignUpData,
  TravelerSignUpData
} from '@app/proposal-workflow/models/proposal-data.model';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { DocumentTypeEnum } from '@shared/constants/document.enum';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { PioneerMasks } from '@shared/interfaces/masks/pioneer-masks.interface';
import { PioneerValidators } from '@shared/interfaces/validators/pioneer-validators.interface';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import * as moment from 'moment';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, takeUntil } from 'rxjs/operators';

export const clone = items => items.map(item => (Array.isArray(item) ? clone(item) : { ...item }));

@Component({
  selector: 'app-traveler-data',
  templateUrl: './traveler-data.component.html',
  styleUrls: ['./traveler-data.component.scss']
})
export class TravelerDataComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();

  @Input() public pos: { pos: number; size: number };
  @Input() public comboParams: ProposalParameters;
  @Output() public start = new EventEmitter<FormGroup>();

  public proposal: ProposalSignUpData;

  public travelersQty = 5;

  public form: FormGroup;
  public additionalTravelersList: FormArray;
  public personType: string;
  public product: string;

  public travelers = {
    id: null,
    proposalId: null,
    mainFlag: null,
    name: null,
    birthDate: null,
    documentCode: null,
    documentNumber: null,
    additionalTravelersList: null
  };

  public pioneerValidators: PioneerValidators = this.internationalizationService.pioneerValidators;
  public pioneerMasks: PioneerMasks = this.internationalizationService.pioneerMasks;

  // Tag
  public Insert: Tag = Tag.Insert;
  public Select: Tag = Tag.Selecionar;
  public event_category: string;

  constructor(
    private internationalizationService: InternationalizationService,
    private store$: Store<AppState>,
    private formBuilder: FormBuilder,
    private simulationService: SimulationService
  ) {}

  ngOnInit() {
    this.buildForm();
    this.start.emit(this.form);
    this.getSectionData();
    this.setFormSubscription();
  }

  private buildForm(): void {
    this.form = new FormGroup({
      id: new FormControl(null),
      name: new FormControl(null, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^[a-zA-ZÀ-ÿ]{2,}(?: [a-zA-ZÀ-ÿ]+){1,}$/)
      ]),
      documentNumber: new FormControl({ value: null, disabled: false }, Validators.required),
      birthDate: new FormControl({ value: null, disabled: false }, [Validators.required, this.dateValidator]),
      dateTravel: new FormControl({ value: null, disabled: false }, [Validators.required, this.futureDateValidator]),
      additionalTravelersList: this.formBuilder.array([]),
      mainFlag: new FormControl(null),
      proposalId: new FormControl(null)
    });
  }

  public createTravelerAdditional(): FormGroup {
    return this.formBuilder.group({
      id: new FormControl(null),
      name: new FormControl(null, [
        Validators.required,
        Validators.minLength(2),
        Validators.maxLength(60),
        Validators.pattern(/^[a-zA-ZÀ-ÿ]{2,}(?: [a-zA-ZÀ-ÿ]+){1,}$/)
      ]),
      documentNumber: new FormControl({ value: null, disabled: false }, [
        Validators.required,
        Validators.minLength(11),
        Validators.maxLength(11)
      ]),
      documentCode: new FormControl({ value: null, disabled: true }, Validators.required),
      birthDate: new FormControl({ value: null, disabled: false }, [Validators.required, this.dateValidator]),
      mainFlag: new FormControl(null),
      proposalId: new FormControl(null)
    });
  }

  public getSectionData() {
    this.store$
      .select(proposalInformationSelect.getSimulationProposalData)
      .pipe(
        filter((data: ProposalSignUpData) => !!data && Object.values(data).some(item => !!item)),
        first()
      )
      .subscribe((data: ProposalSignUpData) => {
        if (data.productCode === ProductCodeEnum.CDC) {
          this.event_category = '/portallojista/criarproposta/cdc/cadastro';
        } else if (data.productCode === ProductCodeEnum.CSC) {
          this.event_category = '/portallojista/criarproposta/csc/cadastro';
        } else if (data.productCode === ProductCodeEnum.CSCP) {
          this.event_category = '/portallojista/criarproposta/cscp/cadastro';
        }

        this.proposal = data;
        this.form.get('dateTravel').patchValue(this.dateFormatForm(this.proposal.travelData));
        this.travelers = { ...data.travelers.find(t => t.mainFlag), additionalTravelersList: null };
        this.travelers.additionalTravelersList = data.travelers.filter(t => !t.mainFlag);

        for (let index = 0; index < data.travelers.length - 1; index++) {
          this.addTraveler();
        }

        this.getTravelersData();

        if (this.form.valid) {
          this.updateTravelerData();
        }
      });
  }

  public getTravelersData() {
    this.store$
      .select(proposalInformationSelect.travelerDataSection)
      .pipe(
        filter((data: TravelerSignUpData[]) => !!data && !!data.length),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe((data: TravelerSignUpData[]) => {
        data.forEach(t => {
          t.birthDate = t.birthDate
            ? t.birthDate.includes('x')
              ? t.birthDate
              : moment(t.birthDate).format('DDMMYYYY')
            : null;
        });

        this.travelers = { ...data.find(t => t.mainFlag), additionalTravelersList: null };
        this.travelers.additionalTravelersList = data.filter(t => !t.mainFlag);

        this.form.patchValue({
          ...this.travelers,
          dateTravel: this.dateFormatForm(this.proposal.travelData)
        });
      });
  }

  private setFormSubscription(): void {
    this.form.valueChanges
      .pipe(
        takeUntil(this.ngUnsubscribe),
        distinctUntilChanged((prev, current) => JSON.stringify(prev) === JSON.stringify(current)),
        debounceTime(2000),
        filter(() => this.form.valid && this.form.touched)
      )
      .subscribe(() => {
        this.proposal.travelData = this.form.get('dateTravel').valid
          ? this.form.get('dateTravel').value
          : this.proposal.travelData;
        this.updateTravelerData();
      });
  }

  public dateFormatForm(dateValue): string {
    if (dateValue) {
      const date = new Date(dateValue);
      return moment.utc(date, 'YYYY-MM-DD').format('DDMMYYYY');
    }
    return null;
  }

  public dateValidator(control: FormControl): { [s: string]: boolean } {
    if (control.value) {
      const date = moment(control.value, 'DDMMYYYY', true);
      const today = moment();
      if (date.isValid() && date.isBefore(today)) {
        return null;
      }
      return { invalidDate: true };
    }
    return null;
  }

  public futureDateValidator(control: FormControl): { [s: string]: boolean } {
    if (control.value) {
      const date = moment(control.value, 'DDMMYYYY', true);
      const today = moment();
      if (date.isValid() && date.isAfter(today)) {
        return null;
      }
      return { invalidDate: true };
    }
    return null;
  }

  public addTraveler() {
    this.additionalTravelersList = this.form.get('additionalTravelersList') as FormArray;
    this.additionalTravelersList.push(this.createTravelerAdditional());
  }

  public deleteTravelerAdditional(index, id) {
    this.additionalTravelersList = this.form.get('additionalTravelersList') as FormArray;
    this.additionalTravelersList.removeAt(index);
    if (id) {
      this.simulationService.deleteTraveler(this.proposal.id, id).subscribe();
    }
  }

  private updateTravelerData() {
    const documentCode = this.comboParams.documentTypes.find(p => p.description === DocumentTypeEnum.CPF).id;
    const formData = this.form.getRawValue();
    const { id, birthDate, documentNumber, name } = formData;
    const list: TravelerSignUpData[] = [];
    const traveler: TravelerSignUpData = {
      id: +id,
      birthDate: birthDate.includes('x') ? birthDate : moment(birthDate, 'DDMMYYYY').format('YYYY-MM-DD'),
      documentCode,
      documentNumber,
      mainFlag: true,
      name,
      proposalId: +this.proposal.id
    };
    list.push(traveler);
    if (formData.additionalTravelersList && formData.additionalTravelersList.length) {
      formData.additionalTravelersList.forEach(t => {
        const {
          id: travelerId,
          birthDate: travelerBirthDate,
          documentNumber: travelerDocumentNumber,
          name: travelerName
        } = t;
        const additionalTraveler: TravelerSignUpData = {
          id: +travelerId,
          birthDate: travelerBirthDate.includes('x')
            ? travelerBirthDate
            : moment(travelerBirthDate, 'DDMMYYYY').format('YYYY-MM-DD'),
          documentCode,
          documentNumber: travelerDocumentNumber,
          mainFlag: false,
          name: travelerName,
          proposalId: +this.proposal.id
        };
        list.push({ ...additionalTraveler });
      });
    }

    const dateTravelFormatted = moment(this.form.get('dateTravel').value, 'DDMMYYYY').format('YYYY-MM-DD');
    this.store$.dispatch(new SetTravelerDataSection({ listTravelers: clone(list), travelData: dateTravelFormatted }));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
