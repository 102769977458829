import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { CommonModule } from '@angular/common';
import { LoadingMessagesModalComponent } from '@shared/components/loading-messages-modal/loading-messages-modal';
import player from 'lottie-web';
import { LottieModule } from 'ngx-lottie';
import { LoadingIndicatorContainerComponent } from './loading-indicator/loading-indicator.container';
import { LoadingInterceptor } from './loading.interceptor';
import { reducerMap } from './store/loading.reducers';
import { initialLoadingState } from './store/loading.state';
import { SpinnerComponent } from './ui/spinner/spinner.component';

// Note we need a separate function as it's required
// by the AOT compiler.
// Or if you load `lottie-web` on demand
export function playerFactory() {
  return player;
}

@NgModule({
  imports: [
    CommonModule,
    LottieModule.forRoot({ player: playerFactory }),
    StoreModule.forFeature('loading', reducerMap, {
      initialState: initialLoadingState
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingInterceptor,
      multi: true
    }
  ],
  entryComponents: [LoadingMessagesModalComponent],
  declarations: [SpinnerComponent, LoadingIndicatorContainerComponent, LoadingMessagesModalComponent],
  exports: [SpinnerComponent, LoadingIndicatorContainerComponent, LoadingMessagesModalComponent]
})
export class LoadingModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule?: LoadingModule
  ) {
    if (parentModule) {
      throw new Error('LoadingModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<LoadingModule> {
    return {
      ngModule: LoadingModule,
      providers: []
    };
  }
}
