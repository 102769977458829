<app-header></app-header>

<app-alert></app-alert>

<app-loading-indicator></app-loading-indicator>

<div #mainDiv appIdleDetect appInvalidBrowserDetect [ngClass]="{ 'app-container': addMargin }">
  <router-outlet></router-outlet>
</div>

<app-modal [autostart]="false" identifier="deviceWarningModal" closable="false" backdrop="false" keyboard="false">
  <ng-container body>
    <h3 class="modal-title">Gerenciar dispositivos</h3>

    <div class="modal-scroll-area">
      <p>
        Parece que a sua conta já está conectada em outro dispositivo. Para usar aqui, precisamos desconectar sua conta
        no outro dispositivo aberto.
      </p>
    </div>

    <button class="btn modal-button" (click)="handleKeepSession()">Desconectar outro dispositivo</button>
    <button class="btn modal-button outlined" (click)="handleKillSession()">Agora não</button>
  </ng-container>
</app-modal>
