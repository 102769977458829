import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AppState } from '@app/core/state';
import * as fromFormalization from '@app/core/state/formalization/formalization.actions';
import * as fromIdentification from '@app/core/state/identification';
import { selectShowTutorialMenu } from '@app/core/state/login/login.selectors';
import * as fromProposalInformation from '@app/core/state/proposal-information/proposal-information.actions';
import * as fromProposal from '@app/core/state/proposal/proposal.actions';
import * as fromShowcase from '@app/core/state/showcase/showcase.actions';
import * as fromSimulation from '@app/core/state/simulation/simulation.actions';
import * as fromTab from '@app/core/state/tab';
import { IdentificationService } from '@app/identification/services/identification.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { Store } from '@ngrx/store';
import { SubsegmentsEnum } from '@shared/constants/subsegments.enum';
import { BrazilMasks } from '@shared/interfaces/masks/brazil-masks.interface';
import { BrazilValidators } from '@shared/interfaces/validators/brazil-validators.interface';
import { LgpdConsentAccessType } from '@shared/models/lgpd-consent.enum';
import { Product } from '@shared/models/product.model';
import { StoreModel } from '@shared/models/store.model';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { TutorialService } from '@shared/services/tutorial/tutorial.service';
import { ValidateEntryValue } from '@shared/validators/brazil.validators';
import { Observable, Subject, combineLatest } from 'rxjs';
import {
  debounceTime,
  delay,
  distinctUntilChanged,
  filter,
  map,
  shareReplay,
  take,
  takeUntil,
  tap
} from 'rxjs/operators';
import { CheckProposalsRes } from './models/api/check-proposals-res.model';
import { IdentificationConfigResponse } from './models/api/query.identification-config.model';
import { IdentificationData } from './models/identification.model';

@Component({
  selector: 'app-identification.container',
  templateUrl: './identification.container.html',
  styleUrls: ['./identification.container.scss']
})
export class IdentificationContainer implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @ViewChild('lgpdForm') lgpdForm;
  public identificationForm: FormGroup;
  public productsList: Product[];
  public selectedStore: StoreModel;
  public termsAndConditions: string;
  public renderConfig: IdentificationConfigResponse;
  public managers: string[];

  public acceptTerms = false;
  public lgpdFormIsValid = false;
  public unavailable = false;

  public validators = this.internationalizationService.pioneerValidators as BrazilValidators;
  public masks = this.internationalizationService.pioneerMasks as BrazilMasks;
  public lgpdConsentAccessType = LgpdConsentAccessType;

  public documentNumberStatusObs: Observable<any>;
  hideLgpdToGetNewConsent = false;
  docStatusWasValid = false;

  readonly category = '/portallojista/criarproposta';

  constructor(
    private store$: Store<AppState>,
    private internationalizationService: InternationalizationService,
    private fb: FormBuilder,
    private identificationService: IdentificationService,
    private store: Store<any>,
    private tutorialService: TutorialService,
    private genTagger: GenTagger,
    private taggerService: TaggerService
  ) {}

  ngOnInit() {
    this.store.dispatch(new fromShowcase.ResetShowcaseAction());
    this.store.dispatch(new fromIdentification.actions.ResetIdentificationAction());
    this.store.dispatch(new fromProposal.ClearProposalAction());
    this.store.dispatch(new fromProposal.ClearChecklistAction());
    this.store.dispatch(new fromProposal.ClearProposalStageAction());
    this.store.dispatch(new fromProposal.ClearProposalIdAction());
    this.store.dispatch(new fromSimulation.ClearAll());
    this.store.dispatch(new fromProposal.ClearPaymentPlanSelectedAction());
    this.store.dispatch(new fromProposalInformation.ClearProposalInformationAction());
    this.store.dispatch(new fromFormalization.ClearFormalizationAction());

    // Tutorial
    this.store
      .select(selectShowTutorialMenu)
      .pipe(take(1))
      .subscribe((showTutorial: boolean) => {
        if (showTutorial) {
          this.tutorialService.showTutorial('menu');
        }
      });

    this.getIdentificationConfig();
    this.createForm();
    this.setSubscriptions();
    this.genTagger.setTag({ event_category: this.category, event_action: Tag.pgView, event_label: 'Criar proposta' });
  }

  /**
   * Gets screen configuration (Terms and Conditions, Manager info and Domains) based on Selected Store,
   * its associated products.
   */
  private getIdentificationConfig(): void {
    const renderConfig$ = this.store$
      .select(fromIdentification.selectors.selectRenderConfig)
      .pipe(takeUntil(this.ngUnsubscribe));
    const productList$ = this.store$.select(fromTab.selectors.selectProducts).pipe(takeUntil(this.ngUnsubscribe));
    const selectedStore$ = this.store$.select(fromTab.selectors.selectSelectedTab).pipe(takeUntil(this.ngUnsubscribe));
    const managers$ = this.store$
      .select(fromIdentification.selectors.selectManagers)
      .pipe(takeUntil(this.ngUnsubscribe));

    combineLatest([productList$, renderConfig$, selectedStore$, managers$])
      .pipe(delay(1))
      .subscribe(([productList, renderConfig, selectedStore, managers]) => {
        this.selectedStore = selectedStore;
        this.productsList = productList;
        this.managers = managers;
        if (renderConfig) {
          const { terms } = renderConfig;
          if (terms && terms[0]) this.termsAndConditions = terms[0].description;
          this.renderConfig = { ...renderConfig };
        }
      });

    this.store$.dispatch(new fromIdentification.actions.GetIdentificationConfigAction());
    this.store$.dispatch(new fromIdentification.actions.GetManagersAction());
    this.store$.dispatch(new fromTab.actions.GetProducts());
  }

  /**
   * Creates base formGroup
   */
  private createForm(): void {
    this.identificationForm = this.fb.group({
      product: [null, Validators.required],
      minimumData: this.fb.group(
        {
          documentNumber: [null, this.validators.documentHybrid],
          date: [null, this.validators.dateOfBirth],
          phone: [null, this.validators.cellPhone],
          financedValue: [null, this.validators.financedValue],
          entryValue: [0, this.validators.entryValue]
        },
        {
          validators: ValidateEntryValue()
        }
      )
    });
    this.identificationForm.get('minimumData').disable();
  }

  /**
   * Sets important subscriptions for service calls/validator swaps
   */
  private setSubscriptions(): void {
    this.resetFormSubscription();
    this.productSubscription();
    this.documentNumberSubscription();
  }

  /**
   * Based on selected product and its subsegment, adds
   * travelDate to minimumDate formGroup.
   */
  private productSubscription(): void {
    this.identificationForm
      .get('product')
      .valueChanges.pipe(
        takeUntil(this.ngUnsubscribe),
        filter(val => !!val)
      )
      .subscribe((product: Product) => {
        this.handleForChangeEvent();
        if (product.isEnabledForSalesman) {
          this.unavailable = false;

          const isTourism = product && product.subsegment && product.subsegment.code === SubsegmentsEnum.TOURISM;
          const travelDateControl = isTourism ? new FormControl('', this.validators.travelDate) : null;
          const minimumData = this.identificationForm.get('minimumData') as FormGroup;

          !!travelDateControl
            ? minimumData.addControl('travelDate', travelDateControl)
            : minimumData.removeControl('travelDate');

          minimumData.reset({ entryValue: 0 });
          minimumData.get('entryValue').markAsTouched();
          this.identificationForm.get('minimumData').enable();
          this.store$.dispatch(new fromIdentification.actions.SaveProductAction(product));
          return;
        }

        this.unavailable = true;
        this.identificationService
          .handleProductUnavailable(product, this.managers)
          .pipe(take(1))
          .subscribe(() => this.identificationForm.get('product').reset());
      });
  }

  /**
   * Changes date validations depending on document length for Brazil.
   */
  private documentNumberSubscription(): void {
    const minimumData = this.identificationForm.get('minimumData') as FormGroup;
    const docNumberVal$ = minimumData.get('documentNumber').valueChanges.pipe(
      takeUntil(this.ngUnsubscribe),
      debounceTime(300),
      distinctUntilChanged(),
      tap((value: string) => {
        this.hideLgpdToGetNewConsent = true;
        this.handleForChangeEvent();
        minimumData.get('date').clearValidators();
        if (!value || (value && value.length <= 11)) {
          minimumData.get('date').setValidators(this.validators.dateOfBirth);
        } else {
          minimumData.get('date').setValidators(this.validators.dateOfFoundation);
        }
        minimumData.get('date').updateValueAndValidity();
      }),
      shareReplay()
    );

    docNumberVal$.subscribe();
    const docNumberStatus$ = minimumData.get('documentNumber').statusChanges;

    this.documentNumberStatusObs = combineLatest([docNumberStatus$, docNumberVal$]).pipe(
      takeUntil(this.ngUnsubscribe),
      tap(() => {
        this.hideLgpdToGetNewConsent = this.docStatusWasValid ? true : false;
      }),
      debounceTime(1000),
      map(([status, value]) => {
        const docStatusIsValid = status === 'VALID' && value && value.length === 11;
        this.docStatusWasValid = docStatusIsValid;
        this.hideLgpdToGetNewConsent = !docStatusIsValid;
        return docStatusIsValid;
      })
    );
  }

  public handleForChangeEvent(): void {
    this.acceptTerms = false;
    this.lgpdFormIsValid = false;
    this.identificationService.formChangeEvent.emit(false);
  }

  public handleCheckbox(checked: boolean): void {
    this.acceptTerms = checked;
  }

  public handleLgpdFormLoaded(event: boolean): void {
    this.lgpdFormIsValid = !event;
  }

  public handleLgpdFormAnswered(): void {
    this.lgpdFormIsValid = true;
  }

  public handleLgpdConsentReady(): void {
    this.lgpdFormIsValid = true;
    const { documentNumber } = this.identificationForm.get('minimumData').value;
    if (documentNumber && documentNumber.length === 11) {
      this.lgpdForm.saveConsent(documentNumber);
      return;
    }

    this.submitIdentification();
  }

  public handleLgpdConsentSuccess(): void {
    this.submitIdentification();
  }

  public isSubmitDisabled(): boolean {
    const { documentNumber } = this.identificationForm.get('minimumData').value;
    if (documentNumber && documentNumber.length === 11) {
      return !this.identificationForm.valid || !this.acceptTerms || !this.lgpdFormIsValid;
    }

    return !this.identificationForm.valid || !this.acceptTerms;
  }

  /**
   * General subscription to reset form
   */
  private resetFormSubscription(): void {
    this.identificationService.resetIdentification$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(reset => {
      if (reset) {
        this.identificationForm.reset();
        this.identificationForm.markAsPristine();
      }
    });
  }

  /**
   * Method to submit form data and dispatch submit action.
   */
  public submitIdentification(): void {
    this.taggerService.setProposalType(this.identificationForm.value.product);
    if (!this.acceptTerms || !this.identificationForm.valid) return;

    const documentType =
      this.identificationForm.get('minimumData').get('documentNumber').value.length <= 11 ? 'cpf' : 'cnpj';
    const payload: IdentificationData = {
      product: this.identificationForm.value.product,
      ...this.identificationForm.value.minimumData,
      acceptTerms: this.acceptTerms
    };

    this.identificationService
      .checkIfProposalExists(this.selectedStore.code, payload.documentNumber, payload.product.subsegment.id)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res: CheckProposalsRes) => {
        // res.canCreate = false;
        if (res.canCreate) {
          this.store$.dispatch(new fromIdentification.actions.SubmitIdentificationAction(payload));
        } else if (res.proposals && res.proposals.length > 0 && res.proposals[0]) {
          this.identificationService.newProposal = payload;
          this.identificationService.handleProposalAlreadyExists(
            res.proposals[0].dateTime,
            res.proposals[0].id,
            res.proposals[0].cancellable,
            documentType
          );
          // this.identificationService.cancelNewProposal.subscribe(cancelNewProposalRes => {
          //   if (cancelNewProposalRes) {
          //     this.identificationForm.get('product').setValue('');
          //     this.identificationForm
          //       .get('minimumData')
          //       .get('documentNumber')
          //       .setValue('');
          //     this.identificationForm
          //       .get('minimumData')
          //       .get('date')
          //       .setValue('');
          //     this.identificationForm
          //       .get('minimumData')
          //       .get('phone')
          //       .setValue('');
          //     this.identificationForm
          //       .get('minimumData')
          //       .get('financedValue')
          //       .setValue('');
          //     this.identificationForm
          //       .get('minimumData')
          //       .get('entryValue')
          //       .setValue('');
          //   }
          // });
        } else {
          this.identificationService.handleNoOffersAvailable(this.identificationForm.value.product.code);
        }
      });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
    this.identificationService.resetIdentification$.next(false);
  }
}
