import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DisconnectedComponent } from './disconnected.component';
import { ApplicationHeaders } from '@shared/components/ui/header/header.models';

const routes: Routes = [
  {
    path: '',
    component: DisconnectedComponent,
    data: { header: ApplicationHeaders.Headless }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DisconnectedRoutingModule {}
