import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-condition-selfie',
  templateUrl: './card-condition-selfie.component.html',
  styleUrls: ['./card-condition-selfie.component.scss']
})
export class CardConditionSelfieComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
