import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ProposalCarnetResponse } from '@shared/models/proposal-carnet';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProposalDetailsService {
  constructor(private httpClient: HttpClient) { }

  public getInfoUser(id): Observable<any> {
    const urlEndPoint = `/proposalpanel/1.0.0/detailProposal/${id}`;
    return this.httpClient.get(environment.apiRootUrl + urlEndPoint);
  }

  public saveNegotiation(proposalId: string, type: string, description: string): Observable<any> {
    const urlEndPoint = `/proposalpanel/1.0.0/negotiation`;
    const body = { proposalId, type, description };
    return this.httpClient.post<any>(environment.apiRootUrl + urlEndPoint, body);
  }

  public emitCarnet(id: number): Observable<ProposalCarnetResponse> {
    const urlEndPoint = `/proposalpanel/1.0.0/proposal/${id}/carnet/tc/emit`;
    return this.httpClient.put<ProposalCarnetResponse>(environment.apiRootUrl + urlEndPoint, { proposalId: id });
  }

  public downloadMinuta(id): Observable<any> {
    const urlEndPoint = `/proposalpanel/1.0.0/download/${id}`;
    return this.httpClient.get(environment.apiRootUrl + urlEndPoint);
  }

  public negotiationProposal(proposalId: any, payload: any): Observable<any> {
    const urlEndPoint = `/proposalpanel/1.0.0/negotiationProposal/${proposalId}`;
    return this.httpClient.post(environment.apiRootUrl + urlEndPoint, payload);
  }

  public negotiationProposalPooling(proposalId: any): Observable<any> {
    const urlEndPoint = `/proposalpanel/1.0.0/negotiationProposal/${proposalId}`;
    return this.httpClient.get(environment.apiRootUrl + urlEndPoint);
  }

  public downloadBoleto(id, artifactId): Observable<any> {
    const urlEndPoint = `/shopkeeper-structure/1.0.0/artifact/${id}?artifactId=${artifactId}`;
    return this.httpClient.get(environment.apiRootUrl + urlEndPoint);
  }
}
