import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.scss']
})
export class ErrorModalComponent implements OnInit, OnDestroy {
  constructor(
    public router: Router,
    private dialogRef: MatDialogRef<ErrorModalComponent>,
    private genTagger: GenTagger,
    @Inject(MAT_DIALOG_DATA) public modalData
  ) { }

  ngOnInit(): void {
    this.genTagger.setTag({
      event_category: '/portallojista/criarproposta',
      event_label: `${this.modalData.errorText}`,
      event_action: `${Tag.AbriuModal} - ${this.modalData?.errorTitle}`
    });
  }
  ngOnDestroy(): void {
    this.genTagger.setTag({
      event_category: '/portallojista/criarproposta',
      event_label: `${this.modalData?.errorButton}`,
      event_action: `${Tag.Fechou_Modal} - ${this.modalData?.errorTitle}`
    });
  }

  handleReturn() {
    this.dialogRef.close();
  }
}
