import { PostIdentificationResDTO } from '@app/identification/models/api/post.identification.model';
import { IdentificationConfigResponse } from '@app/identification/models/api/query.identification-config.model';
import { IdentificationData } from '@app/identification/models/identification.model';
import { Product } from '@shared/models/product.model';
import { IdentificationActions, IdentificationActionTypes } from './identification.actions';

export interface IdentificationState {
  renderConfig: IdentificationConfigResponse;
  userData: IdentificationData;
  submitResponse: PostIdentificationResDTO;
  selectedProduct: Product;
  managers: string[];
}

export const initialIdentificationState: IdentificationState = {
  renderConfig: null,
  userData: null,
  submitResponse: null,
  selectedProduct: null,
  managers: null
};

export function identificationReducer(
  state = initialIdentificationState,
  action: IdentificationActions
): IdentificationState {
  switch (action.type) {
    case IdentificationActionTypes.GET_IDENTIFICATION_CONFIG_SUCCESS: {
      return { ...state, renderConfig: { ...action.payload } };
    }
    case IdentificationActionTypes.SAVE_PRODUCT: {
      return { ...state, selectedProduct: { ...action.payload } };
    }
    case IdentificationActionTypes.CLEAR_PRODUCT: {
      return { ...state, selectedProduct: initialIdentificationState.selectedProduct };
    }
    case IdentificationActionTypes.SUBMIT_IDENTIFICATION: {
      return { ...state, userData: { ...action.payload } };
    }
    case IdentificationActionTypes.SUBMIT_IDENTIFICATION_SUCCESS: {
      return { ...state, submitResponse: { ...action.payload } };
    }
    case IdentificationActionTypes.SUBMIT_IDENTIFICATION_ERROR: {
      return { ...state, userData: initialIdentificationState.userData };
    }
    case IdentificationActionTypes.RESET_IDENTIFICATION: {
      return {
        ...state,
        userData: initialIdentificationState.userData,
        selectedProduct: initialIdentificationState.selectedProduct,
        submitResponse: initialIdentificationState.submitResponse
      };
    }
    case IdentificationActionTypes.GET_MANAGERS_SUCCESS: {
      return {
        ...state,
        managers: action.payload
      };
    }

    default:
      return state;
  }
}
