import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SalaryInputComponent } from './../salary-input/salary-input.component';

@Component({
  selector: 'app-salary-input-dialog',
  templateUrl: './salary-input-dialog.component.html',
  styleUrls: ['./salary-input-dialog.component.scss']
})
export class SalaryInputDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<SalaryInputComponent>) {}

  ngOnInit() {
    this.dialogRef.addPanelClass('salary-input-dialog');
  }

  public handleSubmit(data: { [key: string]: any }): void {
    this.dialogRef.close(data);
  }
}
