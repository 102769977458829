<section class="side-resume-modal" *ngIf="{
    comboParams: comboParams$ | async,
    proposalPendencyBiometryFlowEnabled: proposalPendencyBiometryFlowEnabled$ | async
  } as vm">
  <div class="row">
    <div class="col d-flex justify-content-end mb-3">
      <button class="btn-close" (click)="matDialog.closeAll()" appTagger [event_category]="categoria" event_label="x"
        [event_action]="closeModalAction">
        <img src="./assets/ic_close.svg" alt="fechar" />
      </button>
    </div>
  </div>
  <div *ngIf="!isPendencyResolved" class="pendency-container">
    <h1 class="pendency-container-title">{{ 'PENDENCY-TITLE' | translate }}</h1>

    <!-- PROPOSAL PENDENCY SUMMARY -->
    <app-pendency-summary *ngIf="proposalId" [proposal]="proposalInformation"
      [proposalPartners]="proposalPartners"></app-pendency-summary>

    <h1 class="pendency-container-subtitle">
      <span *ngIf="proposal?.product?.code === productType.CP">{{ 'PENDENCY-SUBTITLE' | translate }}</span>
      <span *ngIf="proposal?.product?.code === productType.CDC">{{ 'PENDENCY-SUBTITLE-CDC' | translate }}</span>
      <span *ngIf="proposal?.product?.code === productType.CSC">{{ 'PENDENCY-SUBTITLE-CSC' | translate }}</span>
      <span *ngIf="proposal?.product?.code === productType.CSCP">{{ 'PENDENCY-SUBTITLE-CSCP' | translate }}</span>
    </h1>

    <!-- PAYMENT ERROR - BANK DATA SECTION -->
    <app-pendency-payment-error *ngIf="pendencyType === pendencyTypeEnum.PAYMENT_ERROR"
      (bankDataForm)="handleBankDataUpdate($event)"></app-pendency-payment-error>

    <!-- ATTACH PENDING DOCUMENTS SECTION -->
    <div>
      <app-attach-pending-documents *ngIf="
          pendencyType === pendencyTypeEnum.CD_PENDENCY ||
          pendencyType === pendencyTypeEnum.SHOPKEEPER_PENDENCY ||
          pendencyType === pendencyTypeEnum.BIPARTITE_PENDENCY
        " [checklist]="checklist" [message]="pendencyMessage" [pendencyType]="pendencyType"
        (uploadFinished)="handleUploadFinished($event)" [category]="categoria" [nfValue]="
          (mainPartner ? mainPartner.partnerValue : proposalInformation.financedValue) + proposalInformation.entryValue
        ">
      </app-attach-pending-documents>
    </div>

    <!-- UPDATE REGISTER DATA SECTION -->
    <app-pendency-update-register *ngIf="pendencyType === pendencyTypeEnum.HS_PREVENT_PENDENCY" [proposal]="proposal"
      [checklist]="checklist" [message]="pendencyMessage" (done)="handleUpdateRegisterDone($event)">
    </app-pendency-update-register>

    <!-- BIPARTITE PENDENCY SECTION -->
    <app-pendency-bipartite *ngIf="pendencyType === pendencyTypeEnum.BIPARTITE_PENDENCY"
      [proposalInformation]="proposalInformation" [bipartiteData]="bipartiteData"
      (sendRegisteredPartners)="receiveRegisteredPartners($event)" [sendhandleUploadFinished]="sendhandleUploadFinished"
      (uploadFinished)="receiveRegisteredPartners($event)">
    </app-pendency-bipartite>

    <!-- PENDING UPDATE BPO DOCUMENTS SECTION -->
    <app-pendency-update-bpo *ngIf="pendencyType === pendencyTypeEnum.BPO_PENDENCY" [checklist]="checklist"
      [proposal]="proposal" [proposalInformation]="proposalInformation" [comboParams]="vm.comboParams"
      [message]="pendencyMessage" [pendencyType]="pendencyType" [nfValue]="
        (mainPartner ? mainPartner?.partnerValue : proposalInformation?.financedValue) + proposalInformation?.entryValue
      " (done)="handleBpoPendencyFinished($event)"
      [proposalPendencyBiometryFlowEnabled]="vm.proposalPendencyBiometryFlowEnabled"
      (updatePendencyValues)="updateBpoPendencies($event)">
    </app-pendency-update-bpo>

    <!-- SUBMIT AND CANCEL SECTION -->
    <button mat-raised-button color="primary" class="pendency-container-button-send" [disabled]="!this.formIsValid"
      (click)="submitPendingProposal(vm.proposalPendencyBiometryFlowEnabled)" appTagger [event_category]="categoria"
      event_label="enviar">
      {{ 'PENDENCY-SUBMIT-BUTTON-MSG' | translate }}
    </button>

    <div class="row footer">
      <div class="col-md-12">
        <div class="d-flex justify-content-center">
          <button type="button" class="btn btn-action" (click)="cancel()" appTagger [event_category]="category"
            event_label="{{ 'PROPOSAL-CANCEL' | translate }}">
            <img src="./assets/ic_delete_red.svg" alt="icon warning" class="icon-status mr-2" />
            {{ 'PROPOSAL-CANCEL' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isPendencyResolved" class="pendency-container">
    <app-pendency-success [proposal]="proposalInformation" [summaryData]="summaryData" [summaryStatus]="summaryStatus"
      (leaveEmitter)="refreshProposals()" (goToProposal)="goToProposal()" [bpoPendencyChanged]="bpoPendencyChanged"
      [proposalPendencyBiometryFlowEnabled]="vm.proposalPendencyBiometryFlowEnabled"></app-pendency-success>
  </div>
</section>