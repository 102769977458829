<div class="container">
  <div class="row">
    <button class="button-back" [routerLink]="['/showcase']">
      <img src="./assets/images/ic_arrow_left.svg" alt="Previous" />
    </button>
  </div>

  <div class="proposal">
    <h1 class="contract-title">{{ 'CONTRACTS-TITLE' | translate }}</h1>
  </div>

  <div class="row" *ngIf="contracts?.data && contracts?.data?.length > 0; else nocontracts">
    <ng-container *ngIf="window > 425" class="col">
      <div *ngFor="let card of contracts?.data; let i = index">
        <!-- <ng-container *ngIf="card.stProposals.codigoBotaoVitrine === SOLVE-PENDING ">  -->
        <ng-container class="col">
          <app-contract-card [data]="card" (actionClick)="handleActiveContractsEmit($event)"></app-contract-card>
        </ng-container>
      </div>
    </ng-container>

    <ng-container *ngIf="window <= 425" class="col">
      <div class="col-12" *ngFor="let card of contracts?.data">
        <ng-container class="col">
          <app-contract-card [data]="card" (actionClick)="handleActiveContractsEmit($event)"></app-contract-card>
        </ng-container>
      </div>
    </ng-container>
  </div>

  <ng-template #nocontracts>
    <div class="card shadow card-with-steper">
      <div class="card-body empty-card" [ngClass]="data?.isFullCard ? 'empty-card' : 'empty-card-showcase'">
        <div class="empty-card-img">
          <img class="paper-icon" src="./assets/ic_paper_stack.svg" />
        </div>
        <div>
          <p>
            {{ 'CONTRACT-CARD-EMPTY-MESSAGE' | translate }}
          </p>
        </div>
      </div>
    </div>
  </ng-template>
</div>
