import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ProfileRenderService } from '@app/profile/service/profile-render.service';
import { SideContentService } from '@shared/services/side-content/side-content.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-side-content',
  templateUrl: './side-content.component.html',
  styleUrls: ['./side-content.component.scss']
})
export class SideContentComponent implements OnDestroy, OnChanges {
  @Input() show;
  @Input() sideNavTitle: string;
  @Input() customHeader = true;
  @Input() customClass = '';
  @Input() hasBackdrop = false;
  @Input() name: string;
  @Output() closeEvent = new EventEmitter();
  onDestroy = new Subject();
  public category: string;
  constructor(
    private sideContentService: SideContentService,
    private profileRenderService: ProfileRenderService,
    private route: Router
  ) {
    this.sideContentService.sideContentTitle.subscribe((title: string) => {
      this.sideNavTitle = title;
    });
    this.sideContentService.sideContentcloseEvent.pipe(takeUntil(this.onDestroy)).subscribe(shouldClose => {
      if (shouldClose) {
        this.closeSideBar();
      }
    });
  }

  get getCloseAction() {
    return `fechou modal - ${this.name}`;
  }

  ngOnChanges(): void {
    // TODO: refactor to use category persistence engine, this is a dirty fix until that solution get in place.
    if (this.name === 'Gestão de usuário') {
      this.category = '/portallojista/gestaodeusuarios';
    } else if (this.name === 'Novo usuário') {
      this.category = '/portallojista/gestaodeusuarios/novousuario';
    } else if (this.name === 'Edita usuário') {
      this.category = '/portallojista/gestaodeusuarios/editarusuario';
    } else if (this.name === 'detalhes da proposta') {
      this.category = '/portallojista/pagamentos/detalhe';
    } else {
      this.category = window.location.href;
    }
  }

  public closeSideBar() {
    this.show = false;
    this.sideContentService.updateSideContentTitle('');
    this.closeEvent.emit(true);
  }

  public closeMenuMobile() {
    this.closeSideBar();
    this.sideContentService.close(true);
  }

  ngOnDestroy() {
    this.onDestroy.next();
    this.onDestroy.complete();
  }
}
