export enum PendencyTypeEnum {
  PAYMENT_ERROR = 12,
  BPO_PENDENCY = 16,
  CD_PENDENCY = 17,
  HS_PREVENT_PENDENCY = 18,
  BIPARTITE_PENDENCY = 33,
  SHOPKEEPER_PENDENCY = 75
}

export enum PendencyTypeFieldEnum {
  RZSOCIAL = 'RZSOCIAL', // CLI - PJ
  TPEMPRESA = 'TPEMPRESA', // CLI - PJ
  NMREPRES = 'NMREPRES', // RPP - PF
  NMCLIENTE = 'NMCLIENTE' // CLI - PF
}

export enum PendencyTypeCustomerEnum {
  CLI = 'CLI',
  RPP = 'RPP'
}
