import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { GET_SIMULATION_PARAMETERS } from '@shared/graphql/queries/simulation.query';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SimulationProposalService {
  private NPP = JSON.parse(environment.useNPP);

  constructor(private http: HttpClient, private apollo: Apollo) {}

  getNewCalculation(payload): Observable<any> {
    return this.http.post<any>(environment.apiRootUrl + '/shopkeeper-simulation/1.0.0/simulation/calculator', payload);
  }

  postSimulation(payload): Observable<any> {
    let endpoint: string;
    if (this.NPP) {
      endpoint = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/simulation/${payload.proposalId}/finish`;
    } else {
      endpoint = `${environment.apiRootUrl}/shopkeeper-simulation/1.0.0/proposal/finish`;
    }
    return this.http.post<any>(endpoint, payload);
  }

  getSimulationParameters(payload): Observable<any> {
    return this.apollo.watchQuery({
      query: GET_SIMULATION_PARAMETERS,
      variables: {
        id: payload.proposalId
      },
      errorPolicy: 'all',
      fetchPolicy: 'no-cache'
    }).valueChanges;
  }
}
