import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import { userProfileSelector } from '@app/core/state/profile/profile.selectors';
import { GenTagger } from '@app/tagging/gen-tagger';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-exit-page',
  templateUrl: './exit-page.component.html',
  styleUrls: ['./exit-page.component.scss']
})
export class ExitPageComponent implements OnInit {
  public userChoice: string;
  public userName: string;
  categoria: string;

  constructor(
    public dialogRef: MatDialogRef<ExitPageComponent>,
    private store$: Store<AppState>,
    private genTagger: GenTagger,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.store$
      .pipe(select(userProfileSelector))
      .pipe(take(1))
      .subscribe((profile: any) => {
        if (profile && profile.name) {
          this.userName = profile.name;
        }
      });
    this.categoria = window.location.href;
    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: 'Abriu modal - Sair da conta',
      event_label:
        this.translate.instant('EXIT_CONFIRMATION_TEXT_USER') + '. ' + this.translate.instant('EXIT_CONFIRMATION_TEXT')
    });
  }

  public submitResponse(clicked): void {
    this.userChoice = clicked;
    this.dialogRef.close(this.userChoice);
  }
}
