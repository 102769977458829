<div class="extra-info">
  <form [formGroup]="selectedLack">

    <mat-form-field color="black" appearance="legacy">
      <mat-label>Período</mat-label>
      <mat-select formControlName="lackOption" (selectionChange)="monthChangeHandler($event)"
        [compareWith]="compareObjects">
        <mat-option *ngFor="let lackOption of months" [value]="lackOption.code" appTagger [event_category]="category"
          event_label="{{ lackOption.description }}" [event_action]="Selecionar">
          {{ lackOption.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field color="black" class="calendarArrow" appearance="legacy">
      <mat-label>Primeira parcela pulada</mat-label>
      <mat-select class="set-color-black-disabled" disabled formControlName="firstLackDate"
        (selectionChange)="setDate()" [compareWith]="compareObjects">
        <mat-option *ngFor="let skipInst of skipableInstallments" [value]="skipInst.code" appTagger
          [event_category]="category" event_label="{{ skipInst.description }}" [event_action]="Insert">
          {{ skipInst.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <p class="label">Parcelas padrões</p>

    <p class="value">
      {{ selectedPaymentMethod.installmentDefaultAmount }} de
      {{ selectedPaymentMethod.installmentDefaultValue | localeCurrency }}
    </p>
  </form>
</div>