import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-proposal-status-information',
  templateUrl: './proposal-status-information.component.html',
  styleUrls: ['./proposal-status-information.component.scss']
})
export class ProposalStatusInformationComponent     {
  @Input() stageProposal: boolean;
  constructor() {}

}
