import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { ApplicationHeaders } from '@shared/components/ui/header/header.models';
import { HeaderService } from '@shared/components/ui/header/services/header.service';
import { ModalService } from '@shared/components/ui/modal/services/modal.service';
import { Countries } from '@shared/constants/countries.enum';
import { Languages } from '@shared/constants/languages.enum';
import { InternationalizationService } from '@shared/services/internationalization/internationalization.service';
import { ProfileRenderService } from './profile/service/profile-render.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  addMargin = false;

  @ViewChild('mainDiv', { read: ViewContainerRef, static: true }) mainDiv;

  constructor(
    public translate: TranslateService,
    private modalService: ModalService,
    private router: Router,
    private headerService: HeaderService,
    private internationalizationService: InternationalizationService,
    private sideContentService: ProfileRenderService
  ) {
    // Set up internationalization configurations based on environment variables for
    // country and language, this configurations include: validators, masks and document's
    // title and language.
    const language = environment.currentLanguage.toLowerCase();
    this.internationalizationService.setLanguage(language as Languages);
    const country = environment.currentCountry.toLowerCase();
    this.internationalizationService.setCountry(country as Countries);
  }

  ngOnInit() {
    this.sideContentService.configureViewContainer(this.mainDiv);

    /* Temporário (Chamada de action sem login) */
    // this.store$.dispatch(new ProfileActions.ProfileRequest());

    this.headerService.header$.subscribe(headers => {
      if (headers !== ApplicationHeaders.Login && headers !== '' && headers !== ApplicationHeaders.Headless) {
        // intercept calendar loading to adjust padding-top
        this.addMargin = true;
        return;
      }
      this.addMargin = false;
    });
  }

  handleKeepSession() {
    this.router.navigate(['/products']);
    this.modalService.closeModal('deviceWarningModal');
  }

  handleKillSession() {
    this.router.navigate(['/disconnected']);
    this.modalService.closeModal('deviceWarningModal');
  }
}
