import { Component, HostListener, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

interface ModalOptions {
  title: string;
  description: string;
  confirmText: string;
  cancelText: string;
  type: string;
}

@Component({
  selector: 'app-registration-fee-modal',
  templateUrl: './registration-fee-modal.component.html',
  styleUrls: ['./registration-fee-modal.component.scss']
})
export class RegistrationFeeModalComponent {
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: any,
    public dialogRef: MatDialogRef<RegistrationFeeModalComponent>
  ) {}

  public confirmActionFunction() {
    this.close(true);
  }

  public cancelActionFunction() {
    this.close(false);
  }

  public close(value) {
    this.dialogRef.close(value);
  }

  @HostListener('keydown.esc')
  public onEsc() {
    if (!this.dialogRef.disableClose) {
      this.close(false);
    }
  }
}
