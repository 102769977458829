import { TabsActionTypes, TabsActions } from './tab.actions';

export interface TabsState {
  tabs: [] | null;
  isLoading: boolean | null;
  error: any | null;
  selectedTab: any | null;
  products: [] | null;
  id: number;
}

export const startupState: TabsState = {
  tabs: [],
  isLoading: false,
  error: null,
  selectedTab: null,
  products: [],
  id: null
};

export function tabsReducer(state = startupState, action: TabsActions) {
  switch (action.type) {
    case TabsActionTypes.GET_TABS:
    case TabsActionTypes.GET_PRODUCTS: {
      return {
        ...state,
        isLoading: true
      };
    }

    case TabsActionTypes.GET_TABS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        tabs: action.payload
      };
    }

    case TabsActionTypes.GET_TABS_ERROR:
    case TabsActionTypes.GET_PRODUCTS_ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    case TabsActionTypes.SAVE_TAB: {
      return {
        ...state,
        selectedTab: action.payload
      };
    }

    case TabsActionTypes.CLEAR_TAB: {
      return {
        ...state,
        selectedTab: null
      };
    }

    case TabsActionTypes.GET_PRODUCTS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        products: action.payload
      };
    }

    case TabsActionTypes.GET_SECONDARY_USER_SELECTED_SUCCESS: {
      return {
        ...state,
        selectUser: action.paylod
      };
    }

    default:
      return state;
  }
}
