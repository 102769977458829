import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import * as formalizationActions from '@app/core/state/formalization/formalization.actions';
import { BiometryStepsType, BiometryTutorialSteps } from '@app/proposal-workflow/models/biometry-tutorial-steps.model';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { handleFileB64 } from '@shared/functions/files-handler';
import { CROSSED_FLUX_POLLING } from '@shared/graphql/queries/proposals.query';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { Apollo } from 'apollo-angular';
import { ExecutionResult } from 'apollo-link';
import * as moment from 'moment';
import { BehaviorSubject, interval, Observable, of, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { ChecklistItemDTO, ChecklistItemTypeEnum } from '../../checklist/models/api/query.checklist.model';
import { ChecklistStatusEnum } from '../../checklist/models/checklist.model';
import { GetCrossedFluxInfoDTOResponse, GetCrossedFluxLinkDTORequest } from '../models/api/get.crossed-flux-info.model';
import { GetCrossedFluxStatusDTOResponse } from '../models/api/get.crossed-flux-status.model';
import { GetIvNonceDTORequest, GetIvNonceDTOResponse } from '../models/api/get.iv-nonce.model';
import { PostAgentLegalDTORequest } from '../models/api/post.agent-legal.model';
import { PostSignatureDTORequest, PostSignatureDTOResponse } from '../models/api/post.signature.model';
import { BiometryChecklistStatus } from '../models/biometry-checklist-status.model';
import { CrossedFluxLogoutReasonsEnum } from '../models/biometry.model';
import { PostBiometryDTORequest, PostBiometryDTOResponse } from './../models/api/post.biometry.model';

export enum BiometryStageEnum {
  QR_CODE = 1,
  ACCESS_LINK = 2,
  INSTRUCTIONS = 3,
  SIGNATURE_CAPTURE = 4,
  FACIAL_CAPTURE = 5,
  CHALLENGE_QUESTIONS = 6,
  BIOMETRY_STATUS = 7,
  DONE = 8,
  PENDING = 9,
  CANCELLED = 10,
  AGENT_LEGAL = 11
}

@Injectable({
  providedIn: 'root'
})
export class BiometryService {
  public biometryStage: BehaviorSubject<BiometryStageEnum> = new BehaviorSubject(BiometryStageEnum.AGENT_LEGAL);
  private timerSubscriptionController: Subject<any> = new Subject();
  private timerSubscription: Subscription;
  public crossedFluxExpirationTime = Math.floor(+environment.crossedFluxExpirationTime / 1000);

  constructor(
    private http: HttpClient,
    private apollo: Apollo,
    private sessionStorageService: SessionStorageService,
    private router: Router,
    private modal: MatDialog,
    private translateService: TranslateService,
    private store$: Store<AppState>
  ) {}

  public getIvNonce(payload: GetIvNonceDTORequest): Observable<GetIvNonceDTOResponse> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-biometry/steganography`;
    return this.http.post<GetIvNonceDTOResponse>(endpoint, payload);
  }

  public sendAgentLegal(payload: PostAgentLegalDTORequest, isReturningToEdit: boolean): Observable<any> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-formalization/signature/businessassociate`;
    return isReturningToEdit
      ? this.http.put<PostAgentLegalDTORequest>(endpoint, payload)
      : this.http.post<PostAgentLegalDTORequest>(endpoint, payload);
  }

  public postBiometryUpload(payload: PostBiometryDTORequest, uuid: string): Observable<any> {
    const imagePayload = { ...payload, uuid, arquivo: handleFileB64(payload.files[0].data), files: undefined };
    const endpoint = `${environment.apiRootUrl}/shopkeeper-biometry/1.0.0/uploadRest`;
    return this.http.post<PostBiometryDTOResponse>(endpoint, imagePayload);
  }

  public getChecklistMobile(payload: { proposalId: any; isPendency: boolean ;reused: false}): Observable<any[]>{
    const endpoint = `${environment.apiRootUrl}/shopkeeper-biometry/checklist`;
    return this.http.post<any>(endpoint, payload);
  }
  public checkFeatureToggleActive(flag:string ):Observable<any> {
    const FLAG_NAME = flag;
    const today = moment(new Date()).format('YYYY-MM-DD');
    const endpoint = `/parametro/1.0.0/parametrogenerico/${FLAG_NAME}/matrizes/parametros?dataReferencia=${today}`;
    return this.http.get<any>(environment.apiRootUrl + endpoint).pipe(map(response => {
      return response.matrizParametros[0]?.listaValores[0]?.valor === 'S' ? true : false;
    }));
  }
  public postBiometry(payload: PostBiometryDTORequest): Observable<PostBiometryDTOResponse> {
    const isCrossedFlux: boolean = !!this.sessionStorageService.getCrossedFlux();
    const endpoint = `${environment.apiRootUrl}${
      isCrossedFlux ? '/shopkeeper-biometry/1.0.0/' : '/shopkeeper-formalization/biometry'
    }`;

    return this.http.post<PostBiometryDTOResponse>(endpoint, payload);
  }

  public sendSignature(payload: PostSignatureDTORequest): Observable<PostSignatureDTOResponse> {
    const isCrossedFlux: boolean = !!this.sessionStorageService.getCrossedFlux();
    const endpoint = `
      ${environment.apiRootUrl}${
      isCrossedFlux ? '/shopkeeper-biometry/signature/electronic' : '/shopkeeper-formalization/signature/electronic'
    }`;
    return this.http.post<PostSignatureDTOResponse>(endpoint, payload);
  }

  public checkBiometryStatus(
    proposalId: string
  ): Observable<
    {
      clientId: number;
      status: ChecklistStatusEnum;
    }[]
  > {
    return this.apollo
      .watchQuery({
        query: CROSSED_FLUX_POLLING,
        variables: {
          proposalId: proposalId,
          reused: false
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: ExecutionResult) => {
          if (!response.data) return [];
          const checklist: ChecklistItemDTO[] = response.data.listCheckValidate;

          const biometryChecklist = checklist.filter(
            item =>
              item.type &&
              (item.type.code === ChecklistItemTypeEnum.FACIAL ||
                item.type.code === ChecklistItemTypeEnum.SIGNATURE ||
                item.type.code === ChecklistItemTypeEnum.ANTIFRAUD)
          );

          return biometryChecklist.map(item => {
            return {
              clientId: +item.client.id,
              status: item.status
            };
          });
        })
      );
  }

  public setBiometryStage(stage: BiometryStageEnum): void {
    this.biometryStage.next(stage);
  }

  public getBiometryTutorialSteps(): Observable<BiometryTutorialSteps> {
    const tutorialSteps: BiometryTutorialSteps = {
      type: BiometryStepsType.BIOMETRY,
      steps: [
        {
          step: 1,
          text: 'FORMALIZATION-CAROUSEL-STEP-1-TITLE',
          description: 'FORMALIZATION-CAROUSEL-STEP-1-SUBTITLE',
          img: 'assets/images/biometry_light.svg'
        },
        {
          step: 2,
          text: 'FORMALIZATION-CAROUSEL-STEP-2-TITLE',
          description: 'FORMALIZATION-CAROUSEL-STEP-2-SUBTITLE',
          img: 'assets/images/biometry_hat.svg'
        },
        {
          step: 3,
          text: 'FORMALIZATION-CAROUSEL-STEP-3-TITLE',
          description: 'FORMALIZATION-CAROUSEL-STEP-3-SUBTITLE',
          img: 'assets/images/biometry_document.svg'
        },
        {
          step: 4,
          text: 'FORMALIZATION-CAROUSEL-STEP-4-TITLE',
          description: 'FORMALIZATION-CAROUSEL-STEP-4-SUBTITLE',
          img: 'assets/images/biometry_face.svg'
        }
      ]
    };

    return of(tutorialSteps);
  }

  public getSignatureTutorialSteps(): Observable<BiometryTutorialSteps> {
    const tutorialSteps: BiometryTutorialSteps = {
      type: BiometryStepsType.SIGNATURE,
      steps: [
        {
          step: 1,
          text: 'FORMALIZATION-CAROUSEL-SIGNATURE-STEP-1-TITLE',
          description: 'FORMALIZATION-CAROUSEL-SIGNATURE-STEP-1-SUBTITLE',
          img: 'assets/images/signature_check.svg'
        },
        {
          step: 2,
          text: 'FORMALIZATION-CAROUSEL-SIGNATURE-STEP-2-TITLE',
          description: 'FORMALIZATION-CAROUSEL-SIGNATURE-STEP-2-SUBTITLE',
          img: 'assets/images/signature_tap.svg'
        },
        {
          step: 3,
          text: 'FORMALIZATION-CAROUSEL-SIGNATURE-STEP-3-TITLE',
          description: 'FORMALIZATION-CAROUSEL-SIGNATURE-STEP-3-SUBTITLE',
          img: 'assets/images/signature_document.svg'
        }
      ]
    };

    return of(tutorialSteps);
  }

  public getCrossedFluxLink(payload: GetCrossedFluxLinkDTORequest): Observable<{ link: string }> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-formalization/transferlink`;
    return this.http.post<{ link: string }>(endpoint, payload);
  }

  public getBiometryCrossedFluxStatus(cheklistId: number): Observable<BiometryChecklistStatus> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-biometry/checklist/${cheklistId}/status`;
    return this.http.get<BiometryChecklistStatus>(endpoint);
  }

  public getCrossedFluxInfo(uuid: string, uuidValidate: string): Observable<GetCrossedFluxInfoDTOResponse> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-biometry/proposal/transferlink/${uuid}/validate/${uuidValidate}`;
    return this.http.get<GetCrossedFluxInfoDTOResponse>(endpoint);
  }

  public getCrossedFluxStatus(uuid: string): Observable<GetCrossedFluxStatusDTOResponse> {
    const endpoint = `${environment.apiRootUrl}/shopkeeper-formalization/transferlink/validate/${uuid}`;
    return this.http.get<GetCrossedFluxStatusDTOResponse>(endpoint);
  }

  /**
   * Method to initialize timer to logout user from crossed flux when
   * passing the maximum time limit.
   */
  public setCrossedFluxTimer(): void {
    const initialTime = this.sessionStorageService.getCrossedFluxTimer() || 0;

    if (!!this.timerSubscription) return;

    this.timerSubscription = interval(1000)
      .pipe(takeUntil(this.timerSubscriptionController))
      .subscribe(timer => {
        const currentTime = initialTime + timer;

        if (currentTime >= this.crossedFluxExpirationTime) {
          this.crossedFluxLogout(CrossedFluxLogoutReasonsEnum.TIMEOUT);
          return;
        }

        this.sessionStorageService.setCrossedFluxTimer(currentTime);
      });
  }

  public clearCrossedFluxTimer(): void {
    this.timerSubscriptionController.next();
    this.timerSubscriptionController.complete();
    this.timerSubscription = undefined;
    this.sessionStorageService.clearCrossedFluxTimer();
  }

  public crossedFluxLogout(reason: CrossedFluxLogoutReasonsEnum = CrossedFluxLogoutReasonsEnum.COMPLETE): void {
    this.clearCrossedFluxTimer();
    this.sessionStorageService.clearCrossedFlux();
    this.store$.dispatch(new formalizationActions.ClearCrossedFluxAction());

    if (reason === CrossedFluxLogoutReasonsEnum.COMPLETE) return;

    this.router.navigate(['/login']).then(() => {
      let modalData: MatDialogConfig<any> = {
        disableClose: true,
        id: `crossed-flux-logout-${reason}`,
        width: '20rem',
        height: '22.5rem',
        maxWidth: '90vw'
      };

      switch (reason) {
        case CrossedFluxLogoutReasonsEnum.TIMEOUT:
          modalData = {
            ...modalData,
            data: {
              title: this.translateService.instant('FORMALIZATION-CROSSED-FLUX-TIMEOUT'),
              confirmText: this.translateService.instant('FORMALIZATION-CROSSED-FLUX-TIMEOUT-OK'),
              description: this.translateService.instant('FORMALIZATION-CROSSED-FLUX-TIMEOUT-DESCRIPTION'),
              type: 'error'
            }
          };
          break;
        case CrossedFluxLogoutReasonsEnum.INVALID_QR:
          modalData = {
            ...modalData,
            data: {
              title: this.translateService.instant('FORMALIZATION-QRCODE-GENERATE'),
              confirmText: this.translateService.instant('FORMALIZATION-QRCODE-GENERATE-OK'),
              description: this.translateService.instant('FORMALIZATION-QRCODE-GENERATE-DESCRIPTION'),
              type: 'error'
            }
          };
          break;
        default:
          break;
      }

      this.modal.open(PionModalComponent, modalData);
    });
  }

  public handleBiometryDenied(): void {
    this.router.navigate(['/showcase/open-proposal']).then(() => {
      const modalRef: MatDialogConfig<any> = {
        disableClose: true,
        id: `biometry-denied`,
        width: '40rem',
        height: '22.5rem',
        maxWidth: '90vw',
        data: {
          title: this.translateService.instant('FORMALIZATION-BIOMETRY-DENIED'),
          confirmText: this.translateService.instant('FORMALIZATION-BIOMETRY-DENIED-BTN'),
          description: this.translateService.instant('FORMALIZATION-BIOMETRY-DENIED-DESCRIPTION'),
          type: 'error'
        }
      };

      this.modal.open(PionModalComponent, modalRef);
    });
  }
}
