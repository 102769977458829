import { AbstractControl, Validators } from '@angular/forms';
import { PeruValidators } from '@shared/interfaces/validators/peru-validators.interface';

export const peruValidators: PeruValidators = {
  documentType: [Validators.required],
  documentNumber: [Validators.required, Validators.minLength(6), Validators.maxLength(11)],
  firstName: [Validators.required, Validators.minLength(5), Validators.maxLength(60)],
  middleName: [Validators.required, Validators.minLength(5), Validators.maxLength(60)],
  lastName: [Validators.required, Validators.minLength(5), Validators.maxLength(60)],
  nickName: [Validators.required, Validators.minLength(2), Validators.maxLength(60)],
  password: [Validators.required, Validators.minLength(6), Validators.maxLength(12)],
  email: [
    Validators.required,
    Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    Validators.minLength(5),
    Validators.maxLength(40)
  ],
  cellPhone: [Validators.required, Validators.minLength(9)],
  dateOfBirth: [Validators.required],
  acceptTerms: [Validators.required],
  acceptConsent: [Validators.required],
  preToken: [Validators.required],
  financedValue: [Validators.required, Validators.min(0.01), Validators.max(999999999.99)],
  income: [Validators.required, Validators.min(0.01)],
  economicActivity: [Validators.required]
};

function isValidDni({ value: doc }: AbstractControl) {
  if (!doc) {
    return null;
  }

  const DNI_LENGTH = 8;

  const CAN_START_WITH_3_ZEROS = /^(0)?(0)?(0)?[1-9]*$/;

  if (doc.length <= DNI_LENGTH) {
    if (CAN_START_WITH_3_ZEROS.test(doc)) {
      return null;
    }
  }

  return { invalidDni: true };
}
