import { Action } from '@ngrx/store';
import { PreAnalysisRequestModel } from '@app/proposal-workflow/models/preAnalysis.model';

export enum PreAnalysisActionTypes {
  POST_PREANALYSIS = '[PREANALYSIS] POST_PREANALYSIS',
  POST_PREANALYSIS_SUCCESS = '[PREANALYSIS] POST_PREANALYSIS_SUCCESS',
  POST_PREANALYSIS_ERROR = '[PREANALYSIS] POST_PREANALYSIS_ERROR',
  GET_PREANALYSIS_STATUS = '[PREANALYSIS] POST_PREANALYSIS',
  GET_PREANALYSIS_STATUS_SUCCESS = '[PREANALYSIS] GET_PREANALYSIS_SUCCESS',
  GET_PREANALYSIS_STATUS_ERROR = '[PREANALYSIS] GET_PREANALYSIS_ERROR',

  SUBMIT_IDENTIFICATION = '[PreAnalysis] [User] Submit Identification',
  SUBMIT_IDENTIFICATION_INCOME = '[PreAnalysis] [User] Submit Identification Income',
  SUBMIT_IDENTIFICATION_SUCCESS = '[PreAnalysis] [App] Submit Identification Success',
  SUBMIT_IDENTIFICATION_ERROR = '[PreAnalysis] [App] Submit Identification Error',

  CANCEL_EXISTING_PROPOSAL = '[PreAnalysis] [App/User] Cancel Existing Proposal',
  CANCEL_EXISTING_PROPOSAL_SUCCESS = '[PreAnalysis] [App] Cancel Existing Proposal Success',
  CANCEL_EXISTING_PROPOSAL_ERROR = '[PreAnalysis] [App] Cancel Existing Proposal Error'
}

export class PostPreAnalysis implements Action {
  readonly type = PreAnalysisActionTypes.POST_PREANALYSIS;
  constructor(public payload: PreAnalysisRequestModel) {}
}
export class PostPreAnalysisSuccess implements Action {
  readonly type = PreAnalysisActionTypes.POST_PREANALYSIS_SUCCESS;
  constructor(public payload: any) {}
}
export class PostPreAnalysisError implements Action {
  readonly type = PreAnalysisActionTypes.POST_PREANALYSIS_ERROR;
  constructor(public payload: any) {}
}

export class GetPreAnalysisStatus implements Action {
  readonly type = PreAnalysisActionTypes.GET_PREANALYSIS_STATUS;
  constructor(public payload: any) {}
}
export class GetPreAnalysisStatusSuccess implements Action {
  readonly type = PreAnalysisActionTypes.GET_PREANALYSIS_STATUS_SUCCESS;
  constructor(public payload: any) {}
}
export class GetPreAnalysisStatusError implements Action {
  readonly type = PreAnalysisActionTypes.GET_PREANALYSIS_STATUS_ERROR;
  constructor(public payload: any) {}
}

export class SubmitIdentificationAction implements Action {
  readonly type = PreAnalysisActionTypes.SUBMIT_IDENTIFICATION;
  constructor(public payload: any) {}
}

export class SubmitIdentificationIncomeAction implements Action {
  readonly type = PreAnalysisActionTypes.SUBMIT_IDENTIFICATION_INCOME;
  constructor(public payload: any) {}
}
export class SubmitIdentificationSuccessAction implements Action {
  readonly type = PreAnalysisActionTypes.SUBMIT_IDENTIFICATION_SUCCESS;
  constructor(public payload: any) {}
}
export class SubmitIdentificationErrorAction implements Action {
  readonly type = PreAnalysisActionTypes.SUBMIT_IDENTIFICATION_ERROR;
  constructor(public payload: any) {}
}

export class CancelExistingProposalAction implements Action {
  readonly type = PreAnalysisActionTypes.CANCEL_EXISTING_PROPOSAL;
  constructor(public payload: any) {}
}

export class CancelExistingProposalSuccessAction implements Action {
  readonly type = PreAnalysisActionTypes.CANCEL_EXISTING_PROPOSAL_SUCCESS;
  constructor(public payload: any) {}
}

export class CancelExistingProposalErrorAction implements Action {
  readonly type = PreAnalysisActionTypes.CANCEL_EXISTING_PROPOSAL_ERROR;
  constructor(public payload: any) {}
}

export type PreAnalysisActions =
  | PostPreAnalysis
  | PostPreAnalysisSuccess
  | PostPreAnalysisError
  | GetPreAnalysisStatus
  | GetPreAnalysisStatusSuccess
  | GetPreAnalysisStatusError
  | SubmitIdentificationAction
  | SubmitIdentificationSuccessAction
  | SubmitIdentificationErrorAction
  | CancelExistingProposalAction
  | CancelExistingProposalSuccessAction
  | CancelExistingProposalErrorAction
  | SubmitIdentificationIncomeAction;
