import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DownloadService } from '@app/esign/services/download.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from '@shared/components/widgets/alert/alert.service';
import { ProposalDetailsService } from '../../../../profile/components/proposal-details/service/proposal-details.service';
import { PaymentDetailDialogModel } from '../../models/payment.model';
import { PaymentsService } from '../../services/payments.service';
import { Installment } from './../../models/payment.model';
import { Store } from '@ngrx/store';
import { AppState } from '@app/core/state';
import { GetNppInstallments } from '@app/core/state/simulation/simulation.actions';
@Component({
  selector: 'app-payment-detail-dialog',
  templateUrl: './payment-detail-dialog.component.html',
  styleUrls: ['./payment-detail-dialog.component.scss']
})
export class PaymentDetailDialogComponent implements OnInit {
  @Input() id: number;
  @Input() disabledPaymentRequest: boolean;
  @Output() callDialog = new EventEmitter<string>();
  @ViewChild('modalBody') modalBody: ElementRef<HTMLElement>;

  public window = window;
  public data: PaymentDetailDialogModel;
  public warningAlertClosed = false;
  public customClass = 'diaolog-details-payment';
  public antecipateAssignment = false;
  public warningAlerterrorDescription = false;
  public paymentePlan: Installment[];
  public paymenteSelectedTooltipTarif: any;
  public insuranceValue: number;
  public registerTariff: number;
  public registrationFeeType: string;
  public artifactId: string;
  public getProposalConfirmationRes: any;

  private snackbarConfig: MatSnackBarConfig = {
    duration: 5000
  };

  readonly category = '/portallojista/pagamentos/detalhe';
  constructor(
    private paymentsService: PaymentsService,
    public alertService: AlertService,
    public _proposalDetails: ProposalDetailsService,
    public _downloadService: DownloadService,
    private translateService: TranslateService,
    private snackBar: MatSnackBar,
    private genTagger: GenTagger,
    private store$: Store<AppState>,
  ) {}

  ngOnInit() {
    this.getInfo();
    this.getProposalConfirmation();
    this.genTagger.setTag({
      event_category: this.category,
      event_label: 'Detalhes da proposta',
      event_action: 'abriu modal – detalhes da proposta'
    });
  }

  getInfo() {
    this.paymentsService.getPaymentDetail(this.id).subscribe(
      (data: PaymentDetailDialogModel) => {
        this.data = data;
        this.paymentePlan = data.installments;
        this.warningAlertClosed = !!this.data.tooltipAlert ? false : true;
      },
      error => {
        // this.alertService.addAlertError(error);
      }
    );
  }
  public getProposalConfirmation() {
    this.paymentsService.getProposalConfirmation(this.id).subscribe((data: any) => {
      //  this.paymenteSelectedTooltipTarif = data.PlanTypeSchemaData;
      this.store$.dispatch(new GetNppInstallments(this.id));
      this.insuranceValue = data.insuranceValue;
      this.registerTariff = data.registerTariff;
      this.registrationFeeType = data.registrationFeeType;
      this.artifactId = data.contract.UUID;
      this.getProposalConfirmationRes = data;
    });
  }

  public emitCarnet() {
    this._proposalDetails.emitCarnet(this.id).subscribe(
      response => {
        if (!response) return;

        const blob = this._downloadService.base64toBlob(response.data, response.mimeType);
        this._downloadService.saveBlob(blob, `${response.name}.pdf`);
      },
      error => {
        this.snackBar.open(this.translateService.instant('PROPOSAL-DETAIL-RESPONSE-ERROR'), null, this.snackbarConfig);
        this.alertService.addAlertError(error);
      }
    );
  }

  public openDialogPaymentRequest(): void {
    this.callDialog.emit('payment-request');
  }

  public openDialogProposalCancel(): void {
    this.callDialog.emit('proposal-cancel');
  }

  public emitirBoletoButton(): void {
    this._proposalDetails.downloadBoleto(this.data.proposalId, this.data.bankSlipId).subscribe(
      response => {
        if (!response) return;

        const blob = this._downloadService.base64toBlob(response.data, response.mimeType);
        this._downloadService.saveBlob(blob, `${response.name}.pdf`);
      },
      error => {
        this.snackBar.open(
          this.translateService.instant('PROPOSAL-PAYMENT-DETAIL-RESPONSE-ERROR'),
          null,
          this.snackbarConfig
        );
        this.alertService.addAlertError(error);
      }
    );
  }

  public onClose(): void {
    this.genTagger.setTag({
      event_action: `fechou modal - ${this.modalBody.nativeElement.innerText}`,
      event_category: this.category,
      event_label: 'x'
    });
  }
}
