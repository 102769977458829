<div *ngIf="(!proposals || !proposals.length) && firstLoad">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md-6">
        <h1 class="title mt-5">{{ 'NO RECENT PROPOSALS' | translate }}</h1>
        <p class="lead mt-3 mb-5">{{ 'NO RECENT PROPOSALS DESCR' | translate }}</p>
      </div>

      <div class="col-12 col-md-6" [hidden]="fromGestao">
        <a
          [routerLink]="['/identification']"
          class="btn btn-primary btn-new-proposal mt-5 mb-5"
          appTagger
          [event_category]="categoria"
          [event_label]="'NEW PROPOSAL' | translate"
        >
          <img src="./assets/santander/icons/ic_document_add.svg" alt="add_icon" />
          {{ 'NEW PROPOSAL' | translate }}
        </a>
      </div>

      <div class="col-12 col-md-6" [hidden]="!fromGestao">
        <a [routerLink]="['/showcase/management']" class="btn btn-primary btn-new-proposal mt-5 mb-5">
          Voltar
        </a>
      </div>
    </div>
  </div>
  <div class="bg-proposal">
    <img
      class="placeloader placeloader-left"
      src="./assets/santander/images/bg-placeloader-1.png"
      alt="placeloader background"
    />
    <img
      class="placeloader placeloader-right"
      src="./assets/santander/images/bg-placeloader-2.png"
      alt="placeloader background"
    />
  </div>
</div>

<div *ngIf="proposals">
  <div class="container mt-10 fixed-header">
    <div class="row">
      <div class="col col-md-9">
        <h1 class="title">{{ 'PROPOSAL TITLE' | translate }}</h1>
      </div>

      <div class="col-auto col-md-3" [hidden]="fromGestao">
        <a
          [routerLink]="['/identification']"
          class="btn btn-primary btn-new-proposal"
          appTagger
          [event_category]="categoria"
          [event_label]="'NEW PROPOSAL' | translate"
        >
          <img src="./assets/santander/icons/ic_document_add.svg" alt="add icon" class="d-none d-md-inline" />
          {{ 'NEW PROPOSAL' | translate }}
        </a>
      </div>

      <div class="col-auto col-md-3" [hidden]="!fromGestao">
        <a [routerLink]="['/showcase/management']" class="btn btn-primary btn-new-proposal">
          Voltar
        </a>
      </div>
    </div>

    <div class="d-flex flex-column w-100">
      <div class="row mt-4 order-2 order-md-1">
        <div class="col-12 col-md-6" [hidden]="fromGestao">
          <div class="row">
            <div class="col-12 col-md-9">
              <div class="input-group">
                <div class="input-group-prepend">
                  <span class="input-group-text" id="search">
                    <img
                      src="./assets/images/ic_search.svg"
                      alt="search_icon"
                      (click)="searchByFilters()"
                      class="icon-search"
                    />
                  </span>
                </div>
                <input
                  type="text"
                  class="form-control"
                  [placeholder]="'PROPOSAL SEARCH INPUT' | translate"
                  [(ngModel)]="searchString"
                  [ngModelOptions]="{ standalone: true }"
                  aria-label="Search"
                  aria-describedby="search"
                  (keydown.enter)="searchByFilters()"
                  appTagger
                  [event_category]="categoria"
                  event_label="Busque por Nº, CPF ou nome"
                  [event_action]="Pesquisar"
                  (blur)="tagSearchBlur()"
                />
              </div>
            </div>
            <div class="col-12 col-md-3" [hidden]="fromGestao">
              <!-- <button type="button" class="btn btn-light" (click)="open(modalFilterByPeriod, 'new')"> -->
              <button
                type="button"
                class="btn btn-light"
                (click)="openDialogPeriod('test param')"
                appTagger
                [event_category]="categoria"
                [event_label]="'PERIOD' | translate"
              >
                <img src="./assets/santander/icons/ic_events.svg" alt="icon calendar" />
                <span class="button-text">{{ 'PERIOD' | translate }}</span>
              </button>

              <button
                *ngIf="window.screen.width < 768"
                type="button"
                class="btn btn-light"
                (click)="openDialogProposalOrdenation()"
              >
                <img src="./assets/santander/icons/ic_events.svg" alt="icon sort" />
                <span class="button-text">{{ 'SORT' | translate }}</span>
              </button>
            </div>
          </div>
        </div>

        <div class="col-12" [class]="fromGestao ? 'col-md-12' : 'col-md-6'">
          <button
            type="button"
            [hidden]="fromGestao"
            class="btn btn-light"
            (click)="openDialogSeller('test param 2')"
            appTagger
            [event_category]="categoria"
            event_label="filtrar vendedores"
          >
            <img src="./assets/santander/icons/noun_filter.svg" alt="icon filter" />
            <span class="button-text">{{ 'FILTER BY SELLERS' | translate }}</span>
          </button>
          <mat-checkbox
            *ngIf="!!isOpenFinanceEnabled"
            color="primary"
            style="margin-left: 1em;"
            (change)="filterOkSantander()"
            [(ngModel)]="filterOkSantanderBoolean"
          >
            {{ 'OPEN-FINANCE' | translate }}
          </mat-checkbox>

          <div class="showing-results">
            {{ 'SHOWING' | translate }} <strong> {{ totalElements }} propostas</strong>
          </div>
          <button
            type="button"
            [hidden]="fromGestao"
            (click)="cleanFilterObj()"
            class="btn btn-transparent showing-results"
            appTagger
            [event_category]="categoria"
            event_label="limpar filtros"
          >
            <span class="button-text">{{ 'CLEAR ALL FILTERS' | translate }}</span>
          </button>
        </div>
      </div>

      <div class="row mt-3 order-1 order-md-2" [hidden]="fromGestao" *ngIf="filters && filters.length > 1">
        <div class="col-12">
          <p class="lead light mb-2">Filtros rápidos</p>

          <owl-carousel-o [options]="customOptions" class="carousel-propostas">
            <ng-template carouselSlide>
              <div class="slide">
                <div
                  class="filter-item"
                  (click)="filterByStatus('')"
                  appTagger
                  [event_category]="categoria"
                  event_label="Todas as propostas"
                  [event_action]="Selecionar"
                >
                  <div class="filter-col"></div>
                  <div class="filter-col position-relative">
                    <p class="filter-type mb-5">
                      Todas as propostas
                    </p>
                    <button
                      type="button"
                      class="btn filter-btn"
                      appTagger
                      [event_category]="categoria"
                      event_label="mais filtros rápidos"
                    >
                      {{ allFiltersAmount }} propostas
                      <img src="./assets/santander/icons/{{ ordenationIconsAllProposals }}.svg" alt="diagonal arrow" />
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
            <ng-template carouselSlide *ngFor="let filter of filters">
              <div class="slide" [class.selected-filter]="selectedFilter(filter.name)">
                <div
                  class="filter-item"
                  (click)="filterByStatus(filter.name)"
                  appTagger
                  [event_category]="categoria"
                  [event_label]="filter.name"
                  [event_action]="Selecionar"
                >
                  <div *ngIf="filter?.icon" class="filter-col">
                    <img *ngIf="filter?.icon" [src]="'./assets/santander/icons/' + filter.icon" [alt]="filter.icon" />
                  </div>
                  <div class="filter-col position-relative">
                    <p class="filter-type mb-5">
                      {{ filter.name }}
                    </p>
                    <button type="button" class="btn filter-btn">
                      Filtrar {{ filter.amount }} propostas
                      <img src="./assets/santander/icons/{{ filter.iconArrow }}.svg" alt="diagonal arrow" />
                    </button>
                  </div>
                </div>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>

  <div class="mt-4">
    <app-proposal-table-mobile
      class="d-block d-md-none"
      [proposals]="proposals"
      (kebabAction)="kebabAction($event)"
      (handleProposalActionBtn)="handleProposalActionBtn($event)"
    ></app-proposal-table-mobile>

    <div class="container d-none d-md-block">
      <app-proposal-table
        class="d-none d-md-block"
        [proposals]="proposals"
        [openFinanceEnabled]="isOpenFinanceEnabled"
        (handleProposalActionBtn)="handleProposalActionBtn($event)"
        (kebabAction)="kebabAction($event)"
        (orderAction)="orderProposals($event)"
      ></app-proposal-table>
    </div>

    <div class="my-3 my-md-5">
      <ngb-pagination
        class="my-4"
        [collectionSize]="totalElements"
        [(page)]="page"
        (pageChange)="loadPage($event); generateTag($event)"
        [boundaryLinks]="false"
        [maxSize]="paginationMaxSize"
        [pageSize]="perPage"
        size="sm"
      >
        <ng-template ngbPaginationPrevious>
          <span class="arrow-icon icon-prev"></span>
          {{ 'Previous' | translate }}
        </ng-template>
        <ng-template ngbPaginationNext>
          {{ 'Next' | translate }}
          <span class="arrow-icon icon-next"></span>
        </ng-template>
      </ngb-pagination>
    </div>
  </div>
</div>
