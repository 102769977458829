import { SimulationActions, SimulationActionTypes } from '@app/core/state/simulation/simulation.actions';
import { Offers } from '@app/proposal-workflow/containers/step-offers/model/offer.model';

export interface SimulationState {
  calculationPayload: any;
  simulationData: any;
  getSimulationError: any;

  statusProposal?: any;
  statusProposalError?: any;
  isLoading?: boolean;

  simulationId: string;

  offers: Offers;

  allOffers?: any;
  subOfferId?: any;
  bestOffer?: any;

  inRequest: boolean;

  firstInstallmentDueDate: string;

  simulationParameters: any;
  payload: any;
  isReturn: boolean;

  calculationError: boolean;
  currentPooling: number;
  getErrorBestOffer: any;

  nppInstallments: any;
  recalculateNpp: any;

  showStepper: boolean;

  loadingSteps: any;
  isEnquadramento: any;
  payloadBestOffers: any;
}

export const initialSimulationState: SimulationState = {
  calculationPayload: null,
  simulationData: null,
  getSimulationError: null,

  statusProposal: null,
  statusProposalError: null,
  isLoading: false,

  simulationId: null,

  inRequest: false,
  offers: null,

  allOffers: null,
  subOfferId: null,
  bestOffer: null,
  payload: null,

  firstInstallmentDueDate: null,

  simulationParameters: null,

  calculationError: false,
  isReturn: false,
  currentPooling: 0,
  nppInstallments: null,
  recalculateNpp: null,

  showStepper: true,
  isEnquadramento: false,
  payloadBestOffers: null,
  getErrorBestOffer: null,

  loadingSteps: []
};

export function simulationReducer(state = initialSimulationState, action: SimulationActions): SimulationState {
  switch (action.type) {
    case SimulationActionTypes.GET_NEW_CALCULATION:
    case SimulationActionTypes.SAVE_CALCULATION_PAYLOAD: {
      return {
        ...state,
        calculationPayload: action.payload
      };
    }

    case SimulationActionTypes.GET_STATUS_PROPOSALS_INC: {
      return {
        ...state,
        currentPooling: state.currentPooling + 1,
        loadingSteps: action.payload.loadingSteps
      };
    }

    case SimulationActionTypes.GET_SIMULATION_ERROR: {
      return {
        ...state,
        getSimulationError: action.payload
      };
    }
    case SimulationActionTypes.GET_STATUS_PROPOSALS: {
      return {
        ...state,
        isLoading: true,
        calculationError: false,
        currentPooling: 0
      };
    }
    case SimulationActionTypes.GET_STATUS_PROPOSALS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        statusProposal: action.payload,
        simulationId: action.payload.simulationId,
        currentPooling: 0
      };
    }

    case SimulationActionTypes.GET_STATUS_PROPOSALS_ERROR: {
      return {
        ...state,
        isLoading: false,
        statusProposalError: action.payload,
        currentPooling: 0
      };
    }

    case SimulationActionTypes.GET_FRAMEWORK: {
      return{
        ...state,
        isEnquadramento: action.payload,
      };
    }

    case SimulationActionTypes.GET_FRAMEWORK_SUCCESS: {
      return{
        ...state,
        isEnquadramento: action.payload
      };
    }

    case SimulationActionTypes.CLEAR_GET_FRAMEWORK: {
      return{
        ...state,
        isEnquadramento: initialSimulationState.isEnquadramento
      };
    }

    case SimulationActionTypes.GET_OFFER: {
      return {
        ...state,
        isLoading: true,
        calculationError: false,
        currentPooling: 0
      };
    }
    case SimulationActionTypes.GET_OFFER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        offers: action.payload,
        currentPooling: 0
      };
    }
    case SimulationActionTypes.GET_ALL_OFFERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        allOffers: action.payload,
        currentPooling: 0
      };
    }
    case SimulationActionTypes.GET_OFFER_ERROR: {
      return {
        ...state,
        isLoading: false,
        statusProposalError: action.payload,
        currentPooling: 0
      };
    }

    case SimulationActionTypes.POST_BEST_OFFER_ERROR: {
      return {
        ...state,
        getErrorBestOffer: action.payload,
        bestOffer: initialSimulationState.bestOffer,
        subOfferId: state.subOfferId || initialSimulationState.subOfferId
      };
    }

    case SimulationActionTypes.UPDATE_OFFER: {
      return {
        ...state,
        isLoading: true,
        calculationError: false,
        currentPooling: 0
      };
    }
    case SimulationActionTypes.UPDATE_OFFER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        currentPooling: 0
      };
    }
    case SimulationActionTypes.UPDATE_OFFER_ERROR: {
      return {
        ...state,
        isLoading: false,
        statusProposalError: action.payload,
        currentPooling: 0
      };
    }
    case SimulationActionTypes.LOADING_DISMISS: {
      return {
        ...state,
        isLoading: false
      };
    }

    case SimulationActionTypes.GET_NEW_CALCULATION_SUCCESS: {
      return {
        ...state,
        simulationData: action.payload,
        calculationError: false
      };
    }

    case SimulationActionTypes.GET_NEW_CALCULATION_ERROR: {
      return {
        ...state,
        calculationError: true
      };
    }

    case SimulationActionTypes.SET_SIMULATION_ID: {
      return {
        ...state,
        simulationId: action.payload
      };
    }

    case SimulationActionTypes.SET_SUBOFFER_ID: {
      return {
        ...state,
        subOfferId: action.payload
      };
    }

    case SimulationActionTypes.SET_PAYLOAD_BESTOFFERS: {
      return {
        ...state,
        payload: action.payload,
        isReturn: action.isReturn
      };
    }


    case SimulationActionTypes.SHOW_SIMULATION_LOADER: {
      return {
        ...state,
        inRequest: action.payload
      };
    }

    case SimulationActionTypes.SET_SIMULATION_DUE_DATE: {
      return {
        ...state,
        firstInstallmentDueDate: action.payload
      };
    }

    case SimulationActionTypes.GET_SIMULATION_PARAMETERS_SUCCESS: {
      return {
        ...state,
        simulationParameters: action.payload.data.simulationParameters
      };
    }

    case SimulationActionTypes.CLEAR_STATUS_PROPOSAL: {
      return {
        ...state,
        statusProposal: initialSimulationState.statusProposal
      };
    }

    case SimulationActionTypes.VALIDATE_COUPON_SUCCESS: {
      return {
        ...state,
        statusProposal: {
          ...state.statusProposal,
          isValidCoupon: action.payload.response.status
        }
      };
    }

    case SimulationActionTypes.POST_BEST_OFFER_SUCCESS: {
      return {
        ...state,
        bestOffer: action.payload,
        getErrorBestOffer: initialSimulationState.getErrorBestOffer,
        subOfferId: action.payload?.bestOffer?.subOfferId
      };
    }

    case SimulationActionTypes.GET_NPP_INSTALLMENTS_SUCCESS: {
      return {
        ...state,
        nppInstallments: action.payload
      };
    }
    case SimulationActionTypes.UPDATE_NPP_OFFER_SUCCESS: {
      return {
        ...state,
        recalculateNpp: action.payload
      };
    }
    case SimulationActionTypes.GET_PAYLOAD_BESTOFFERS: {
      return{
        ...state,
        payloadBestOffers: action.payload,
      };
    }
    case SimulationActionTypes.CLEAR_PAYLOAD_BESTOFFERS: {
      return{
        ...state,
        payloadBestOffers: initialSimulationState.payloadBestOffers
      };
    }

    case SimulationActionTypes.CLEAR_ALL: {
      return {
        ...initialSimulationState
      };
    }

    case SimulationActionTypes.CLEAR_STATUS_COUPON: {
      return {
        ...state,
        statusProposal: state.statusProposal
          ? { ...state.statusProposal, isValidCoupon: null }
          : initialSimulationState.statusProposal
      };
    }

    case SimulationActionTypes.SET_SHOW_STEPPER: {
      return {
        ...state,
        showStepper: action.payload
      };
    }

    default:
      return state;
  }
}
