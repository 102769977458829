<mat-card class="menu-card padding-8" *ngIf="active" (click)="$event.stopPropagation()">
  <mat-card-content>
    <mat-list>
      <mat-list-item
        *ngFor="let menu of subMenuData"
        class="item-list-line"
        (click)="helpHandler(menu.actionType, menu.targetAddress)"
        appTagger
        [event_category]="category"
        event_label="{{ menu.label | translate }}"
      >
        <span>{{ menu.label | translate }}</span>

        <mat-icon *ngIf="menu?.submenu?.length > 0" class="submenu-indicator">chevron_right</mat-icon>

        <mat-card *ngIf="menu?.submenu?.length > 0" class="submenu-tutorial">
          <mat-list>
            <mat-list-item
              *ngFor="let submenu of menu.submenu"
              class="item-list-line"
              (click)="helpHandler(submenu.actionType, submenu.targetAddress)"
              >{{ submenu.label | translate }}</mat-list-item
            >
          </mat-list>
        </mat-card>
      </mat-list-item>
    </mat-list>
  </mat-card-content>
</mat-card>
