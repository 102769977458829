import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@shared/shared.module';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { BottomSheetNotificationComponent } from './components/notifications/components/bottom-sheet-notification/bottom-sheet-notification.component';
import { NotificationCardComponent } from './components/notifications/components/notification-card/notification-card.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { ProfileDataComponent } from './components/profile-data/profile-data.component';
import { ProfileManagementComponent } from './components/profile-management/profile-management.component';
import { UpdateUserComponent } from './components/profile-management/update-user/update-user.component';
import { UserManagementListComponent } from './components/profile-management/user-management-list/user-management-list.component';
import { UserCardComponent } from './components/profile-management/user-management-list/users-card/users-card.component';
import { ClientDataComponent } from './components/proposal-details/client-data/client-data.component';
import { NegotiationDetailComponent } from './components/proposal-details/negotiation-detail/negotiation-detail.component';
import { NegotiationFormComponent } from './components/proposal-details/negotiation-form/negotiation-form.component';
import { NppNegotiationFormComponent } from './components/proposal-details/npp-negotiation-form/npp-negotiation-form.component';
import { ProposalDetailsComponent } from './components/proposal-details/proposal-details.component';
import { SetInitialScreenComponent } from './components/set-initial-screen/set-initial-screen.component';
import { UserPreferencesComponent } from './components/user-preferences/user-preferences.component';

@NgModule({
  declarations: [
    ProfileManagementComponent,
    ProfileDataComponent,
    ChangePasswordComponent,
    UserPreferencesComponent,
    NotificationCardComponent,
    UserCardComponent,
    ClientDataComponent,
    BottomSheetNotificationComponent,
    NotificationsComponent,
    UserManagementListComponent,
    UpdateUserComponent,
    SetInitialScreenComponent,
    ProposalDetailsComponent,
    ClientDataComponent,
    NegotiationFormComponent,
    NegotiationDetailComponent,
    NppNegotiationFormComponent
  ],
  imports: [
    CommonModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatRadioModule,
    TranslateModule,
    MatFormFieldModule,
    MatInputModule,
    SharedModule,
    MatToolbarModule,
    MatSidenavModule,
    MatListModule,
    MatMenuModule,
    MatCardModule,
    MatSelectModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatExpansionModule,
    MatProgressBarModule,
    MatTooltipModule,
    NgbModule,
    CurrencyMaskModule
  ]
})
export class ProfileModule {}
