import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DisconnectedRoutingModule } from './disconnected-routing.module';
import { DisconnectedComponent } from './disconnected.component';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [CommonModule, DisconnectedRoutingModule, TranslateModule],
  declarations: [DisconnectedComponent],
  exports: [DisconnectedComponent]
})
export class DisconnectedModule {}
