import { HttpClient } from '@angular/common/http';
import { NotificationResponse, NotificationsFiltered } from './../model/notification.model';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { AppState } from '@app/core/state';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public data: NotificationsFiltered = new NotificationsFiltered();

  constructor(private translate: TranslateService, private store$: Store<AppState>, private httpClient: HttpClient) {}

  public getNotifications(): Observable<NotificationResponse> {
    const urlEndPoint = `/shopuserprofile/1.0.0/notifications`;
    return this.httpClient.get<any>(environment.apiRootUrl + urlEndPoint);
  }

  public deleteById(id: string): any {
    const urlEndPoint = `/shopuserprofile/1.0.0/notifications/${id}/statusnotification`;
    return this.httpClient.put(environment.apiRootUrl + urlEndPoint, { statusNotification: 'EX', idNotification: id });
  }

  public markAsReadById(id: string): any {
    const urlEndPoint = `/shopuserprofile/1.0.0/notifications/${id}/statusnotification`;
    return this.httpClient.put(environment.apiRootUrl + urlEndPoint, { statusNotification: 'IN', idNotification: id });
  }

  public deleteAll(): any {
    const urlEndPoint = `/shopuserprofile/1.0.0/notifications/statusnotification`;
    return this.httpClient.put(environment.apiRootUrl + urlEndPoint, { statusNotification: 'EX' });
  }

  public markAsReadAll(): any {
    const urlEndPoint = `/shopuserprofile/1.0.0/notifications/statusnotification`;
    return this.httpClient.put(environment.apiRootUrl + urlEndPoint, { statusNotification: 'IN' });
  }
}
