import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppState } from '@app/core/state';
import * as fromFormalization from '@app/core/state/formalization';
import * as fromProposal from '@app/core/state/proposal';
import * as fromProposalInformation from '@app/core/state/proposal-information';
import {
  ChecklistModel,
  ChecklistStatusEnum
} from '@app/proposal-workflow/containers/step-formalization/components/checklist/models/checklist.model';
import {
  BankSignUpData,
  ConfirmationData,
  ProposalParameters
} from '@app/proposal-workflow/models/proposal-data.model';
import { SummaryModel, SummaryStageModel } from '@app/proposal-workflow/models/summary.model';
import { SimulationService } from '@app/proposal-workflow/service/simulation.service';
import { ProposalCancelDialogComponent } from '@app/showcase/proposals/components/proposal-cancel-dialog/proposal-cancel-dialog.component';
import { PanelProposalDTO } from '@app/showcase/proposals/models/api/get.proposal-list.model';
import { FormalizationProposalDTO } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ProductCodeEnum } from '@shared/constants/product.enum';
import { IdleDetectService } from '@shared/services/idle-detect/idle-detect.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { GenTagger } from './../tagging/gen-tagger';
import { PartnerTypeEnum } from './models/bipartite-type.enum';
import { BipartitePartnerDTO, BipartitePartnersQueryDTO } from './models/bipartite.model';
import { PendencyTypeEnum } from './models/pendency-type.enum';
import { PendencyService } from './pendency.service';
import { Router } from '@angular/router';
import { PendencyBpoValue, UpdatePendencyBpoPayload } from './components/pendency-update-bpo/pendency-update-bpo.type';

@Component({
  selector: 'app-pendency',
  templateUrl: './pendency.component.html',
  styleUrls: ['./pendency.component.scss']
})
export class PendencyComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  public proposalId: string | number;
  public checklist: ChecklistModel[] = [];
  public proposal: FormalizationProposalDTO;
  public proposalInformation: ConfirmationData;
  public bankDataForm: BankSignUpData;
  public pendencyMessage: string;
  public summaryData: SummaryModel[];
  public summaryStatus: SummaryStageModel[];
  public pendencyTypeEnum = PendencyTypeEnum;
  public pendencyType: PendencyTypeEnum;
  public productType = ProductCodeEnum;
  public pendingDocuments: any;
  public isDesktop: boolean = this.deviceDetectorService.isDesktop();
  public uploadFinished = false;
  public uploadFinishedBipartido = false;
  public updateRegister = false;
  public bpoPendency = false;
  public isPendencyResolved = false;
  public bipartiteData: BipartitePartnersQueryDTO;
  public proposalPartners: BipartitePartnerDTO[];
  public uploadsFinished = false;
  public sendhandleUploadFinished: boolean;
  public comboParams$: Observable<ProposalParameters> = this.simulationService.getParametersProposal();
  readonly categoria: '/portallojista/pendencia';
  public formIsValid: boolean;
  public mainPartner: any;
  closeModalAction: ExtendedTag = {
    event_action_type: Tag.Fechou_Modal,
    event_action_complement: this.translateService.instant('PENDENCY-SUBTITLE')
  };
  public bpoPendencyChanged: boolean;
  public bpoPendencyValue: PendencyBpoValue[];
  public proposalPendencyBiometryFlowEnabled$ = this.store$.select(
    fromProposal.selectors.selectIsProposalPendencyBiometryFlowEnabled
  );

  constructor(
    private store$: Store<AppState>,
    private deviceDetectorService: DeviceDetectorService,
    public matDialog: MatDialog,
    private simulationService: SimulationService,
    private pendencyService: PendencyService,
    private genTagger: GenTagger,
    private idleDetectService: IdleDetectService,
    public dialogRef: MatDialogRef<PendencyComponent>,
    private translateService: TranslateService,
    public router: Router
  ) {}

  public ngOnInit(): void {
    this.setProposalSubscriptions();
    this.setBankDataStatusSubscription();
    this.setProposalUpdatedSubscription();
    this.setPendingProposalSubscription();
    this.getProposalData();
    this.setChecklistSubscription();
    this.getSummarySteps();
    this.getSummaryStatus();
    this.getBipartiteData();

    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: `${Tag.AbriuModal} - ${this.translateService.instant('PENDENCY-SUBTITLE')}`,
      event_label: this.translateService.instant('PENDENCY-SUBTITLE')
    });

    this.idleDetectService
      .watcher()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.dialogRef.close();
        if (this.matDialog) {
          this.matDialog.closeAll();
        }
      });
    this.store$.dispatch(new fromProposal.actions.GetProposalPendencyBiometryFlowEnabled());
  }

  private getProposalData(): void {
    this.store$
      .select(fromProposal.selectors.getShowcaseChoosedCard)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(value => !!value)
      )
      .subscribe((proposal: PanelProposalDTO) => {
        this.proposalId = `${proposal.id}`;
        this.pendencyType = proposal.stage ? proposal.stage.portalStage : null;

        this.store$.dispatch(new fromProposalInformation.actions.GetConfirmationData(this.proposalId));
        this.store$.dispatch(new fromProposal.actions.GetProposalAction(this.proposalId));
        this.store$.dispatch(
          new fromProposal.actions.GetPendingProposalAction({
            proposalId: +this.proposalId,
            portalStage: this.pendencyType
          })
        );

        this.getMainPartner();
      });
  }

  private setProposalSubscriptions(): void {
    const proposal$ = this.store$.select(fromProposal.selectors.selectProposal).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(state => !!state.data),
      map(proposalData => proposalData.data)
    );

    const proposalInformation$ = this.store$.select(fromProposalInformation.selectors.getConfirmationData).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(state => !!state)
    );

    combineLatest([proposal$, proposalInformation$]).subscribe(([proposal, proposalInformation]) => {
      this.store$.dispatch(
        new fromProposal.actions.GetChecklistAction({
          proposalId: this.proposalId,
          isPendency: true
        })
      );
      this.proposal = proposal;
      this.proposalInformation = proposalInformation;
    });
  }

  private setChecklistSubscription(): void {
    this.store$
      .select(fromProposal.selectors.selectChecklist)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(state => !!state)
      )
      .subscribe(checklist => {
        const checklistArray = Array.from(checklist);
        this.checklist = checklistArray.filter(item => item.status !== ChecklistStatusEnum.INDEXED);
        this.formIsValid = this.checklist.length === 0 ? true : false;
      });
  }

  private setPendingProposalSubscription(): void {
    this.store$
      .select(fromProposal.selectors.selectPendingProposal)
      .pipe(filter(data => !!data && !!data.proposalCommentsList))
      .subscribe(pendingProposal => {
        const pendingProposalEdges = pendingProposal.proposalCommentsList.edges;
        const comments = [];
        let pendingProposalFilter = [];

        pendingProposalFilter = pendingProposalEdges.filter(comment => comment.node.observationDescription);
        pendingProposalFilter.forEach((comment, _i) => {
          comments.push(comment.node.observationDescription.replace(/[/\n]/g, '<br/>'));
          comments.push('<br/>');
        });

        if (comments.length > 1) {
          comments.sort((a, b) => {
            return new Date(b.dateObservation).getTime() - new Date(a.dateObservation).getTime();
          });
        }
        this.pendencyMessage = comments.join(' ');
      });
  }

  private setBankDataStatusSubscription(): void {
    this.store$
      .select(fromProposal.selectors.selectBankDataStatus)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(success => !!success)
      )
      .subscribe(() => this.finish());
  }

  private setProposalUpdatedSubscription(): void {
    this.store$
      .select(fromProposal.selectors.updateProposals)
      .pipe(
        takeUntil(this.ngUnsubscribe),
        filter(success => !!success)
      )
      .subscribe(updatedProposal => {
        this.store$.dispatch(new fromFormalization.actions.GetSummarySteps({ isPendency: true }));
        this.store$.dispatch(new fromFormalization.actions.GetSummaryStatus(this.proposalId));
        this.isPendencyResolved = updatedProposal;
      });
  }

  private getSummarySteps(): void {
    this.store$
      .select(fromFormalization.selectors.selectSummarySteps)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => (this.summaryData = value));
  }

  private getSummaryStatus(): void {
    this.store$
      .select(fromFormalization.selectors.selectSummaryStatus)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(value => (this.summaryStatus = value));
  }

  public submitPendingProposal(proposalPendencyBiometryFlowEnabled: boolean): void {
    if (this.pendencyType === PendencyTypeEnum.PAYMENT_ERROR) {
      const { accountCode, accountTypeId, agencyCode, bankCode } = this.bankDataForm;
      const bankData = { accountCode, accountTypeId, agencyCode, bankCode };

      this.store$.dispatch(
        new fromProposal.actions.SubmitPendingProposalAction({
          bankData,
          proposalId: this.proposalId
        })
      );
      return;
    }
    if (
      this.pendencyType === PendencyTypeEnum.BPO_PENDENCY &&
      this.bpoPendencyValue &&
      this.bpoPendencyValue.length > 0 &&
      proposalPendencyBiometryFlowEnabled
    ) {
      this.pendencyService
        .updateBpoPendency(this.bpoPendencyValue, this.proposal.id)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(() => {
          this.finish();
        });
      return;
    }
    this.finish();
  }

  public finish(): void {
    this.store$.dispatch(
      new fromProposal.actions.UpdateProposals({
        proposalId: this.proposalId,
        groupName: this.pendencyType
      })
    );
  }

  public cancel() {
    const ref = this.matDialog.open(ProposalCancelDialogComponent, {
      width: '560px',
      autoFocus: false,
      id: 'cancel-proposal-dialog',
      data: {
        proposalId: this.proposalId,
        routedBy: 'pendency',
        value: this.proposal.financedValue,
        amountParcels: this.proposal.installmentAmount,
        valueParcels: this.proposal.installmentValue
          ? this.proposal.installmentValue
          : this.proposal.conditionFinanced.maxInstallmentValue
      }
    });

    ref.componentInstance.okExit.subscribe(() => this.refreshProposals());
  }

  public handleBankDataUpdate(bankData: BankSignUpData): void {
    this.bankDataForm = bankData;
    this.formIsValid = true;
  }

  public handleUploadFinished(uploadFinished: boolean): void {
    this.uploadFinished = uploadFinished;
    this.sendhandleUploadFinished = this.uploadFinished;
    this.formIsValid = uploadFinished;
    if (this.pendencyType === this.pendencyTypeEnum.BIPARTITE_PENDENCY) {
      this.receiveRegisteredPartners();
    }
  }

  public receiveRegisteredPartners(res?: any) {
    this.checkIfThereIsaNoteAttached(res);
    this.uploadFinishedBipartido = false;

    if (this.checklist.length > 0) {
      if (this.uploadFinished && this.formIsValid) {
        this.uploadFinishedBipartido = true;
      }
    } else {
      if (this.formIsValid) {
        this.uploadFinishedBipartido = true;
      }
    }

    this.formIsValid = this.uploadFinishedBipartido;
  }

  private checkIfThereIsaNoteAttached(res: any) {
    if (!res) {
      this.formIsValid = true;
      return;
    }

    this.uploadsFinished = res.length > 0 ? !res.includes('PENDING') : false;

    this.formIsValid = this.uploadsFinished;
  }

  public handleUploadFinishedBipartido(uploadFinished: boolean): void {
    this.formIsValid = true;
    this.checkIfThereIsaNoteAttached(this.uploadFinishedBipartido);
  }

  public handleUpdateRegisterDone(done: boolean): void {
    this.updateRegister = done;
    this.formIsValid = done;
  }

  public handleBpoPendencyFinished(done: boolean): void {
    this.bpoPendency = done;
    this.formIsValid = done;
    if (this.pendencyType === this.pendencyTypeEnum.BIPARTITE_PENDENCY) {
      this.receiveRegisteredPartners();
    }
  }

  public isPendencyReady(): boolean {
    return !!this.formIsValid;
  }

  public refreshProposals(): void {
    this.store$.dispatch(new fromProposal.actions.GetOpenProposalsAction());
    this.matDialog.closeAll();
  }

  public goToProposal() {
    this.router.navigate(['/proposal'], { queryParams: { id: this.proposalId } });
    this.matDialog.closeAll();
  }

  public getBipartiteData(): void {
    this.pendencyService
      .getPartnersList(+this.proposalId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: BipartitePartnersQueryDTO) => {
        this.bipartiteData = data;
        if (
          this.bipartiteData &&
          this.bipartiteData.proposalData &&
          this.bipartiteData.proposalData.fgBipartido === 'S'
        ) {
          this.getProposalPartners();
        }
      });
  }

  public getProposalPartners(): void {
    this.pendencyService
      .getProposalPartners(+this.proposalId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((data: BipartitePartnerDTO[]) => {
        this.proposalPartners = data;
      });
  }

  private getMainPartner() {
    this.pendencyService
      .getProposalPartners(+this.proposalId)
      .pipe(take(1))
      .subscribe((data: BipartitePartnerDTO[]) => {
        this.mainPartner = data.find(partner => partner.partnerType === PartnerTypeEnum.MAIN);
      });
  }

  public updateBpoPendencies(payload: UpdatePendencyBpoPayload): void {
    this.bpoPendencyChanged = payload.hasChanged;
    this.bpoPendencyValue = payload.pendencies;
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
