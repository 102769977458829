import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { ProductCodeEnum } from '@shared/constants/product.enum';

@Component({
  selector: 'app-proposal-sumary',
  templateUrl: './proposal-sumary.component.html',
  styleUrls: ['./proposal-sumary.component.scss']
})
export class ProposalSumaryComponent implements OnInit {
  @Input() proposal: ConfirmationData;
  @Input() currentUrl: string;
  @Input() zoom: string;

  @Output() showStoreConditionsEmitter = new EventEmitter<any>();

  public paymentPlanType = PaymentPlanType;
  public personsType = SubmitPersonType;
  public showStoreConditions = true;

  public financedValue: number;

  public whiteModal = false;

  public productCode = ProductCodeEnum;

  constructor() {}

  ngOnInit() {
    this.financedValue = Number(this.proposal.financedValue) - Number(this.proposal.entryValue);
  }

  public isTaxShown(): boolean {
    if (this.proposal && this.proposal.proposalData) {
      const product = this.proposal.proposalData.product; // CP, CDC, CSC
      const client = this.proposal.proposalData.client; // PF ou PJ
      return (
        (product === ProductCodeEnum.CP && client === SubmitPersonType.PF) ||
        (product === ProductCodeEnum.CDC && client === SubmitPersonType.PF) ||
        (product === ProductCodeEnum.CDC && client === SubmitPersonType.PJ)
      );
    }
  }

  public toggleStoreConditionsVisibility(): void {
    this.showStoreConditions = !this.showStoreConditions;
    this.showStoreConditionsEmitter.emit(this.showStoreConditions);
  }
}
