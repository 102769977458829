<div class="salary-input-container">
  <span class="material-icons salary-input-exit" (click)="submit()">
    close
  </span>
  <div class="row justify-content-center justify-content-sm-start">
    <span class="material-icons salary-input-icon">
      monetization_on
    </span>
  </div>
  <div class="row">
    <div class="salary-input-title">{{ 'INCOME-MODAL-TITLE' | translate }}</div>
  </div>
  <div class="row">
    <div class="salary-input-subtitle">
      {{ 'INCOME-MODAL-SUBTITLE' | translate }}
    </div>
  </div>

  <ng-container [formGroup]="form" *ngIf="form">
    <div class="row" *ngIf="form.get('income')">
      <mat-form-field class="input" appearance="legacy">
        <mat-label>{{ 'INCOME' | translate }}</mat-label>
        <input
          currencyMask
          matInput
          [options]="$any(pioneerMasks).income.options"
          maxlength="{{ $any(pioneerMasks).income.maxLength }}"
          placeholder="{{ 'INCOME-INPUT-PLACEHOLDER' | translate }}"
          formControlName="income"
          required
        />
        <mat-hint>{{ 'INCOME-HINT' | translate }}</mat-hint>
        <mat-error *ngIf="!form.get('income').valid && form.get('income').errors.min">
          {{ 'INCOME-INPUT-ERROR-MIN-MSG' | translate }}
        </mat-error>
        <mat-error *ngIf="!form.get('income').valid && form.get('income').errors.max">
          {{ 'INCOME-INPUT-ERROR-MAX-MSG' | translate }}
        </mat-error>
        <img
          [hidden]="form.get('income').value === '' || !form.get('income').valid"
          class="valid-icon"
          src="./assets/icon-green.svg"
          alt="icon right green"
          matSuffix
        />
        <img
          [hidden]="form.get('income').untouched || form.get('income').valid"
          class="invalid-icon"
          src="./assets/atention-red.svg"
          alt="icon right red"
          matSuffix
        />
      </mat-form-field>
    </div>

    <div class="row" *ngIf="form.get('economicActivity')">
      <mat-form-field color="black" appearance="legacy" class="input">
        <mat-label>{{ 'ECONOMIC-ACTIVITY' | translate }}</mat-label>
        <mat-select formControlName="economicActivity" name="economicActivity" required>
          <mat-option *ngFor="let economicActivity of economicActivities" [value]="economicActivity">
            {{ economicActivity.description }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="!form.get('economicActivity').valid">
          {{ 'ECONOMIC-ACTIVITY-INPUT-ERROR-MSG' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </ng-container>

  <div class="row">
    <button mat-raised-button class="salary-input-submit" [disabled]="form.invalid" (click)="submit()">
      Continuar
    </button>
  </div>
</div>
