<app-side-content [name]="nameUser" [show]="true">
  <section>
    <div class="content">
      <app-certified-agent-list *ngIf="tela === 'listagem'" (newUserEmitter)="changeScreen($event)" [count]="count">
      </app-certified-agent-list>
      <app-certified-agent-form *ngIf="tela === 'new-user'" (back)="changeScreen($event)" [screenType]="tela">
      </app-certified-agent-form>
    </div>
  </section>
</app-side-content>
