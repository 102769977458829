<div class="desktop h-100">
  <div class="container-fluid h-100">
    <div class="row h-100">
      <div class="col-12 col-md-5 col-xl-4 search-side">
        <div class="search-header">
          <h1 class="title mb-4 d-none d-sm-block">FAQ</h1>
          <app-faq-search #searchComponent (reset)="resetSearch()" (search)="search($event)"></app-faq-search>
        </div>
        <div class="faq-menu">
          <div class="faq-menu-wrapper w-100" *ngIf="!foundItems">
            <div class="category-title font-weight-normal">
              <small>Não foram encontradas perguntas para essa descrição.</small>
            </div>
          </div>
          <div>
            <div class="faq-menu-wrapper w-100" *ngFor="let category of filteredData; let i = index">
              <div *ngIf="category.topics.length > 0">
                <div class="category-title">
                  <a (click)="handleBack()"
                    ><img *ngIf="onCategory" class="esquerda" src="./assets/images/ic_arrow_right.svg" alt="arrow"
                  /></a>
                  {{ category.categoryTitle }}
                </div>
                <div
                  class="button-wrapper"
                  *ngFor="let item of category.topics; let i = index"
                  [ngClass]="
                    selected && selected.title === item.title && selected.content === item.content ? 'active' : ''
                  "
                >
                  <div *ngIf="i < 2 || onCategory">
                    <button class="button" (click)="selectFaq(item)">
                      <div>
                        <span class="faq-title">{{ item.title }}</span>
                        <span class="faq-description">{{ item.description }}</span>
                      </div>
                      <div class="icon">
                        <img src="./assets/images/ic_arrow_right.svg" alt="arrow" />
                      </div>
                    </button>
                  </div>
                </div>
                <div class="show-more">
                  <a
                    class="link primary"
                    *ngIf="!onCategory && category.topics.length > 2"
                    (click)="handleShowMore(category)"
                    type="button"
                  >
                    Exibir mais
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="d-none d-sm-flex flex-column col-sm-7 content-side">
        <app-arrow-back (click)="onGoBack()"></app-arrow-back>
        <div class="no-item" *ngIf="!selected">
          <h1>Perguntas frequentes</h1>
          <p>Encontre as respostas para as perguntas mais procuradas.</p>
        </div>
        <div class="content" *ngIf="selected">
          <h1 [innerHTML]="selected.title"></h1>
          <div [innerHTML]="selected.content"></div>
        </div>
      </div>
    </div>
  </div>
</div>
