<div class="card shadow">
  <div *ngIf="crossedFlux" class="mb-2 p-2">
    <div class="col-12 p-4 text-center mb-3">
      <img src="./assets/images/santander_consumer.svg" alt="Santander" />
    </div>
    <div class="mb-4">
      <div class="col-12">Olá, {{ proposal?.customer?.name }}!</div>
      <div class="col-12">
        Aqui você poderá assinar a proposta:
      </div>
    </div>

    <div class="mb-4" *ngIf="proposal?.clientType === personsType.PJ">
      <div class="col-12">
        <div class="parcels">
          Em nome da empresa
        </div>
      </div>
      <div class="col-12">
        {{ proposal?.companyName }}
      </div>
    </div>

    <div class="mb-4">
      <div class="col-12">
        <div class="parcels">
          Proposta aberta em
        </div>
      </div>
      <div class="col-12">
        {{ proposal?.createdDate | date: 'dd/MM/yyyy' }}
      </div>
    </div>

    <div class="mb-3">
      <div class="col-12">
        <div class="parcels">
          Valor de
        </div>
      </div>
      <div class="col-12">
        {{ +proposal?.financedValue | localeCurrency }}
      </div>
    </div>

    <div class="mb-3">
      <div class="col-12">
        <div class="parcels">
          Compra realizada na loja
        </div>
      </div>
      <div class="col-12">
        {{ proposal?.channelSupplier?.description }}
      </div>
    </div>
  </div>

  <div *ngIf="!crossedFlux" class="card-body">
    <div class="row">
      <div class="col-12 title">
        <div class="description">
          <span *ngIf="biometryType !== biometryTypeEnum.SIGNATURE">{{
            'FORMALIZATION-CONDITION-CARD-TITLE' | translate
          }}</span>
          <span *ngIf="biometryType === biometryTypeEnum.SIGNATURE">{{
            'FORMALIZATION-CONDITION-CARD-TITLE-SIGNATURE' | translate
          }}</span>
        </div>
      </div>
      <!-- Total financed -->
      <div class="col-12">
        <div class="parcels">
          {{ 'FORMALIZATION-CONDITION-CARD-FUND-INFO' | translate }}
        </div>
        <div class="total total-fund">
          <b class="font-weight-bold">{{ +proposal?.financedValue | localeCurrency }}</b>
        </div>
      </div>

      <!-- Montlhy Installment amount and installment value  -->
      <div class="col-12">
        <div class="col-12 p-0 parcels">
          {{ 'FORMALIZATION-CONDITION-CARD-PARCELS-INFO' | translate }}
        </div>
      </div>
      <div class="col-12 mt-1 pl-3 text-left total total-fund">
        {{ proposal?.conditionFinanced?.deadLineQuantity }}x
        <span class="font-weight-bold" *ngIf="proposal?.planType !== paymentPlanType.STANDARD_PLAN">
          {{ paymentPlanType.CUSTOMIZED_PLAN | translate }}
        </span>
        <span class="font-weight-bold" *ngIf="proposal?.planType === paymentPlanType.STANDARD_PLAN">
          de {{ proposal?.installmentValue | localeCurrency }}</span
        >
      </div>

      <!-- Brazilian Fee -->
      <div class="col-12">
        <div class="parcels">
          {{ 'FORMALIZATION-CONDITION-CARD-CET-INFO' | translate }}
        </div>
        <div class="cet-percent total-fund">
          <p class="my-1">
            {{
              'FORMALIZATION-CONDITION-CARD-CET-PERCENT'
                | translate: { cet: proposal?.conditionFinanced?.returnTaxPercentage | number: '1.2-2' }
            }}
          </p>
          <p>
            {{
              'FORMALIZATION-CONDITION-CARD-ANNUAL-CET-PERCENT'
                | translate: { annualCet: proposal?.conditionFinanced?.annualReturnTaxPercentage | number: '1.2-2' }
            }}
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
