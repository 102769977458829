import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import { SubmitLoginDataAction, SubmitLoginWithStoreAction } from '@app/core/state/login/login.actions';
import { ProfileData } from '@app/core/state/profile/profile.reducer';
import { userProfileSelector } from '@app/core/state/profile/profile.selectors';
import { SaveTab } from '@app/core/state/tab/tab.actions';
import { selectTabs } from '@app/core/state/tab/tab.selectors';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { environment } from '@env/environment';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { ModalService } from '@shared/components/ui/modal/services/modal.service';
import { ShopStatus } from '@shared/constants/shop-status.enum';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-new-login-tabscreen',
  templateUrl: './lojista.component.html',
  styleUrls: ['./lojista.component.scss']
})
export class LojistaComponent implements OnInit, OnDestroy {
  get shopStatus() {
    return ShopStatus;
  }
  public profileData: ProfileData = null;
  public flagCode: boolean;

  readonly categoria = '/portallojista/escolhadeloja';
  readonly Insert: Tag = Tag.Insert;

  protected ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private router: Router,
    private store$: Store<AppState>,
    private modalService: ModalService,
    private readonly dialog: MatDialog,
    private genTagger: GenTagger,
    private translateService: TranslateService
  ) {}

  tabs = [];
  store: any;
  selectStore: FormGroup;
  popupTitle: string;
  popupBody: string;
  userProfile: any;
  homePagePreference: string;

  @ViewChild('instance', /* TODO: add static flag */ { static: true } as any) instance: NgbTypeahead;
  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(
      debounceTime(200),
      distinctUntilChanged()
    );
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term =>
        term === ''
          ? this.tabs
          : this.tabs.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1 || v.code.indexOf(term) > -1)
      )
    );
  };

  formatter = (result: any) => {
    return result.name ? result.code + ' - ' + result.name : '';
  };

  selectTabHandler = item => {
    if (item.statusCode === ShopStatus.ACTIVE) {
      this.store$.dispatch(new SaveTab(item));
    } else {
      this.genTagger.setTag({
        event_category: this.categoria,
        event_action: Tag.FillingError,
        event_label: 'Loja inativa ou bloqueada. Selecione outra opção.'
      });
    }
  };

  get eventOpenModal(): ExtendedTag {
    return {
      event_action_type: Tag.AbriuModal,
      event_action_complement: 'A loja esta bloqueada'
    };
  }

  get eventCloseModal(): ExtendedTag {
    return {
      event_action_type: Tag.Fechou_Modal,
      event_action_complement: 'A loja esta bloqueada'
    };
  }

  ngOnInit() {
    this.selectStore = new FormGroup({
      storeSelected: new FormControl('', [Validators.required])
    });

    this.genTagger.setTag({
      event_category: this.categoria,
      event_action: Tag.pgView,
      event_label: 'Selecione uma loja'
    });

    this.store$
      .pipe(select(userProfileSelector))
      .pipe(take(1))
      .subscribe(profile => {
        this.profileData = profile;
      });

    this.selectStore.controls.storeSelected.valueChanges.pipe(filter(value => !!value)).subscribe(val => {
      if (val.statusCode === ShopStatus.INACTIVE) {
        this.showModalInactive();
      }
      if (val.statusCode === ShopStatus.BLOCKED) {
        this.showModalBlocked();
      }
    });

    this.store$
      .select(selectTabs)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(tabs => {
        this.tabs = tabs;

        if (this.tabs.length === 1 && this.tabs[0].statusCode === ShopStatus.ACTIVE) {
          const [tab] = this.tabs;

          this.selectStore.controls.storeSelected.setValue(tab);

          this.store$.dispatch(new SaveTab(tab));
          this.handleSubmitForm();
        }
        const allTabsBlockedOrInactive = this.tabs.every(
          tab => tab.statusCode === ShopStatus.BLOCKED || tab.statusCode === ShopStatus.INACTIVE
        );

        if (this.tabs.length > 0 && allTabsBlockedOrInactive) {
          const data = {
            description: 'Todas as lojas estão inativas e/ou bloqueadas',
            confirmText: 'Fechar',
            type: 'error'
          };
          const modalRef = this.dialog.open(PionModalComponent, {
            data,
            disableClose: true
          });
          modalRef.afterClosed().subscribe(res => {
            this.selectStore.get('storeSelected').reset();
            this.router.navigate(['/']);
            this.genTagger.setTag({
              event_category: '/portallojista/login',
              // event_action: `${Tag.Fechou_Modal} - ${data.title}`,
              event_action: `${Tag.Fechou_Modal} - `,
              event_label: data.confirmText
            });
          });
          modalRef.afterOpened().subscribe(res => {
            this.genTagger.setTag({
              event_category: '/portallojista/login',
              // event_action: `${Tag.Fechou_Modal} - ${data.title}`,
              event_action: `${Tag.AbriuModal} - `,
              event_label: data.description
            });
          });
        }
      });
  }

  showModalBlocked() {
    const data = {
      title: 'Loja Bloqueada',
      description: this.popupBody = 'TABSCREEN-POPUP-BLOCKED-BODY',
      confirmText: 'Fechar',
      type: 'error'
    };
    const modalRef = this.dialog.open(PionModalComponent, {
      data,
      disableClose: true
    });
    modalRef.afterClosed().subscribe(res => {
      this.selectStore.get('storeSelected').setValue('');
      (document.activeElement as HTMLElement).blur();
      this.instance.dismissPopup();

      this.genTagger.setTag({
        event_category: this.categoria,
        event_action: this.eventCloseModal,
        event_label: data.confirmText
      });
    });
    modalRef.afterOpened().subscribe(() => {
      this.genTagger.setTag({
        event_category: this.categoria,
        event_action: this.eventOpenModal,
        event_label: this.translateService.instant('TABSCREEN-POPUP-BLOCKED-BODY')
      });
    });
  }

  showModalInactive() {
    const modalRef = this.dialog.open(PionModalComponent, {
      data: {
        title: 'Loja Inativa',
        description: this.popupBody = 'TABSCREEN-POPUP-INACTIVE-BODY',
        confirmText: 'Fechar',
        type: 'error'
      },
      disableClose: true
    });
    modalRef.afterClosed().subscribe(res => {
      this.selectStore.get('storeSelected').setValue('');
      (document.activeElement as HTMLElement).blur();
      this.instance.dismissPopup();
    });
  }

  handleModal() {
    this.modalService.closeModal('tabscreenModal');
    this.selectStore.get('storeSelected').reset();
  }

  handleSubmitForm() {
    let payload;

    this.store$
      .pipe(
        select('login'),
        take(1)
      )
      .subscribe(login => (payload = login.loginForm));

    payload.storeId = this.selectStore.controls.storeSelected.value.code;

    if (JSON.parse(environment.newGateway)) {
      this.store$.dispatch(new SubmitLoginWithStoreAction(payload));
    } else {
      this.store$.dispatch(new SubmitLoginDataAction(payload));
    }
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
