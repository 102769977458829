<!-- HTML modal BR (Meio da tela) -->
<section>
  <div class="row">
    <div class="col d-flex justify-content-end">
      <button class="btn-close" (click)="closeDialog()">
        <img src="./assets/ic_close.svg" alt="fechar" />
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <p class="title bra">{{ 'SIMULATION-SUMMARY' | translate }}</p>
      <p *ngIf="modalData.summary.paymentPlan === 'FLT'" class="installment-value">
        {{ modalData.summary.numberOfInstallments }}x de
        <span class="font-weight-bold">{{ modalData.summary.installments[0].value | localeCurrency }}</span>
      </p>
      <p *ngIf="modalData.summary.paymentPlan !== 'FLT'" class="installment-value">
        {{ modalData.summary.numberOfInstallments }}x
        <span class="font-weight-bold">{{ 'SUMARY-DETAILS-CUSTOMIZED' | translate }}</span>
      </p>
      <p class="info">{{ 'MONTHLY-PAYMENT-LABEL' | translate }}</p>
    </div>
  </div>

  <div class="row subtitle border-0">
    <div class="col">
      <p class="details-title">{{ 'SIMULATION-DETAILS' | translate }}</p>
    </div>
  </div>
  <div class="line"></div>

  <div class="row details border-0">
    <div class="col">
      <p class="text">{{ 'AMMOUNT-INSTALLMENTS-LABEL' | translate }}</p>
    </div>
    <div class="col">
      <p class="data">
        {{ modalData.summary.financedValue | localeCurrency }}
      </p>
      <p *ngIf="modalData.summary.paymentPlan === 'FLT'" class="data">
        {{ modalData.summary.numberOfInstallments }}x de
        {{ modalData.summary.installments[0].value | localeCurrency }}
      </p>
      <p *ngIf="modalData.summary.paymentPlan !== 'FLT'" class="data">
        {{ modalData.summary.numberOfInstallments }}x
        {{ 'SUMARY-DETAILS-CUSTOMIZED' | translate }}
      </p>
    </div>
  </div>
  <div class="line"></div>

  <div class="row details border-0">
    <div class="col">
      <p class="text">{{ 'FIRST-DUE-DATE-LABEL' | translate }}</p>
    </div>
    <div class="col">
      <p class="data">{{ modalData.summary.dueDate }}</p>
      <p class="warning">{{ 'DATE-CHANGE-WARNING' | translate }}</p>
    </div>
  </div>
  <div class="line"></div>

  <div class="row details border-0">
    <div class="col">
      <p class="text">{{ 'FORM-PAYMENT' | translate }}</p>
    </div>
    <div class="col">
      <p class="data">{{ modalData.summary.payment }}</p>
    </div>
  </div>
  <div class="line"></div>

  <div class="buttons">
    <button class="btn edit" [mat-dialog-close]="false">{{ 'BACK-TO-EDIT' | translate }}</button>
    <button class="btn continue" [mat-dialog-close]="true">{{ 'CONTINUE-TO-REGISTER' | translate }}</button>
  </div>
</section>
