import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenTagger } from '@app/tagging/gen-tagger';

export interface ModalOptions {
  title: string;
  description: string;
  confirmText: string;
  cancelText: string;
  type: 'alert' | 'success' | 'error';
  errorCode?: string;
  largeButton?: boolean;
}

@Component({
  selector: 'app-pion-modal',
  templateUrl: './pion-modal.component.html',
  styleUrls: ['./pion-modal.component.scss']
})
export class PionModalComponent implements OnInit {
  readonly category = window.location.href;
  constructor(
    @Inject(MAT_DIALOG_DATA) public modalData: ModalOptions,
    public dialogRef: MatDialogRef<PionModalComponent>,
    private genTagger: GenTagger
  ) {}

  ngOnInit() {
    // COMENTADO POIS ESTÁ RETORNANDO DUPLICADO NO DATALAYER, POR ALGUNS MODAIS ESTAREM TAGUEADOS NO LOGIN.EFFECTS.TS
    // this.genTagger.setTag({
    //   event_category: this.category,
    //   event_label: `abriu modal - ${this.modalData.title}`,
    //   event_action: Tag.AbriuModal
    // });
  }

  public confirmActionFunction() {
    this.close(true);
  }

  public cancelActionFunction() {
    this.close(false);
  }

  public close(value) {
    this.dialogRef.close(value);
  }

  @HostListener('keydown.esc')
  public onEsc() {
    if (!this.dialogRef.disableClose) {
      this.close(false);
    }
  }
}
