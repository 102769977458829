import { PeruMasks } from '@shared/interfaces/masks/peru-masks.interface';

export const peruMasks: PeruMasks = {
  documentNumber: { mask: '00000000000', hiddenMask: '00XXXXXXX00', maxLength: '9' },
  cellPhone: { mask: '000 000 000', prefix: '(+51) ' },
  dateOfBirth: { mask: '00/00/0000' },
  preToken: { mask: '000.000.000-00' },
  date: { mask: '00/00/0000' },
  maskDate: { mask: '00/00/0000', maskBack: 'AA/AA/AAAA' },
  income: {
    options: {
      align: 'left',
      allowNegative: false,
      decimal: ',',
      thousands: '.',
      precision: 2,
      prefix: 'S/ ',
      suffix: ' Soles',
      min: 0.01,
      max: 999999999.99,
      inputMode: 'FINANCIAL'
    },
    maxLength: '20'
  },
  zipCode: { mask: '00000' }
};
