import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionsSubject, Store } from '@ngrx/store';
import * as challenge from '@app/core/state/challenge';
import { AppState } from '@app/core/state';
import { SignUpService } from '@app/signup/services/sign-up.service';
import { Location } from '@angular/common';
import { ChallengeService } from '@shared/services/challenge/challenge.service';
import { BiometryStepsType } from '@app/proposal-workflow/models/biometry-tutorial-steps.model';
import { BiometryManagerService, BiometryPictureEnum } from '@app/biometry/services/biometry-manager.service';
import { takeUntil } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { Subject } from 'rxjs';
import {
  CheckBiometryStatusAction,
  GetCrossedFluxLinkAction,
  GoToNextChallengeAction,
  SetCrossedFluxAction,
  SetRetryBiometryChallenge
} from '@app/core/state/challenge/challenge.actions';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { selectLatestBiometryStatus } from '@app/core/state/challenge/challenge.selectors';
import { BiometryPollingStatusEnum } from '@app/signup/models/biometry-challenge.model';
import * as actions from '@app/core/state/challenge/challenge.actions';

@Component({
  selector: 'app-biometry-challenge',
  templateUrl: './biometry-challenge.component.html',
  styleUrls: ['./biometry-challenge.component.scss']
})
export class BiometryChallengeComponent implements OnInit, OnDestroy {
  showFacial = true;
  public cameraOn = false;
  public tipsCarousel = false;
  public cameraMobile = false;
  public errorMessage = false;
  isLoadingMobile = true;
  qrCodeLink = '';
  public isPollRunning = false;
  errorFromOSA = false;

  public tutorialSteps = {
    type: BiometryStepsType.BIOMETRY,
    steps: [
      {
        step: 1,
        text: 'FORMALIZATION-CAROUSEL-STEP-1-TITLE',
        description: 'FORMALIZATION-CAROUSEL-STEP-1-SUBTITLE',
        img: 'assets/images/biometry_light.svg'
      },
      {
        step: 2,
        text: 'FORMALIZATION-CAROUSEL-STEP-2-TITLE',
        description: 'FORMALIZATION-CAROUSEL-STEP-2-SUBTITLE',
        img: 'assets/images/biometry_hat.svg'
      },
      {
        step: 3,
        text: 'FORMALIZATION-CAROUSEL-STEP-3-TITLE',
        description: 'FORMALIZATION-CAROUSEL-STEP-3-SUBTITLE',
        img: 'assets/images/biometry_document.svg'
      },
      {
        step: 4,
        text: 'FORMALIZATION-CAROUSEL-STEP-4-TITLE',
        description: 'FORMALIZATION-CAROUSEL-STEP-4-SUBTITLE',
        img: 'assets/images/biometry_face.svg'
      }
    ]
  };
  flowType;
  public facialBiometryStage$ = this.biometryManagerService.sdkStage;
  protected ngUnsubscribe: Subject<any> = new Subject();
  protected pollingComplete: Subject<any> = new Subject();

  iv = '';
  nonce = '';

  isCrossedFlux = false;

  public currentPlatform: { deviceInfo: DeviceInfo; isMobile: boolean; isDesktop: boolean };
  isCrossedFluxSuccess = false;
  showStatusFinished = false;
  uuid;

  constructor(
    private store$: Store<AppState>,
    private signupService: SignUpService,
    private challengeService: ChallengeService,
    public loc: Location,
    public biometryManagerService: BiometryManagerService,
    private sessionStorageService: SessionStorageService,
    public deviceDetectorService: DeviceDetectorService,
    private activatedRoute: ActivatedRoute,
    private actionListener$: ActionsSubject,
    private router: Router
  ) {}

  ngOnInit() {
    this.getCurrentPlatform();

    if (this.currentPlatform.isDesktop) {
      this.isLoadingMobile = false;
      this.store$.dispatch(new challenge.actions.GetChallenge({ refresh: false }));
      this.getQrCode();
      this.flowType = this.challengeService.getMainChallengeVariables().fType;
      this.pollBiometryStatus();
    }

    this.handleBiometryStage();
    this.handleRetry();

    if (this.currentPlatform.isMobile) {
      this.handleStartupChallenge();
      this.handleParamsQuery();
      this.handleSuccess();
    }
  }

  handleBiometrySuccess(images: any[]) {
    const payload = {
      data: images[0],
      id: '',
      type: ''
    };
    if (this.isCrossedFlux) {
      this.challengeService.postBiometry(this.uuid, { data: payload.data }).subscribe(response => {
        switch (response.status) {
          case 'APPROVED':
            this.store$.dispatch(new actions.SetCrossedFluxBiometryStatusAction(true));
            break;
          case 'REPROVED':
            this.store$.dispatch(new actions.SetCrossedFluxBiometryStatusAction(false));
            break;
          case 'RETRY':
            this.tipsCarousel = true;
            this.cameraOn = false;
            this.store$.dispatch(new actions.ShowRetryModalBiometryChallenge());
            break;
        }
      });
    } else {
      this.store$.dispatch(new challenge.actions.AnswerChallenge({ biometry: { isSkip: false, file: payload } }));
    }
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();

    this.pollingComplete.next();
    this.pollingComplete.complete();
  }

  public goToCameraPage(): void {
    if (this.iv && this.nonce) {
      this.biometryManagerService.setIvNonce(this.iv, this.nonce);
      this.cameraOn = true;
      this.tipsCarousel = false;
      this.errorMessage = false;
      this.showStatusFinished = false;
    } else {
      this.challengeService.getBiometry(this.uuid, this.isCrossedFlux).subscribe(
        response => {
          this.biometryManagerService.setIvNonce(response.iv, response.nonce);
          this.iv = response.iv;
          this.nonce = response.nonce;

          this.cameraOn = true;
          this.tipsCarousel = false;
          this.errorMessage = false;
          this.showStatusFinished = false;
        },
        err => {
          this.goToErrorMessagePage();
        }
      );
    }
  }

  goToCarousel() {
    this.tipsCarousel = true;
    this.pollingComplete.next();
    this.pollingComplete.complete();
  }

  public goToErrorMessagePage() {
    this.tipsCarousel = false;
    this.cameraOn = false;
    this.errorMessage = true;
    this.showStatusFinished = false;
  }

  goback() {
    if (!this.cameraOn && !this.tipsCarousel && !this.errorMessage) {
      this.challengeService.goback().subscribe(res => {
        this.loc.back();
      });
    } else if (this.tipsCarousel) {
      this.cameraOn = false;
      this.tipsCarousel = false;
      this.errorMessage = false;
      this.showStatusFinished = false;
      this.pollingComplete = new Subject();
      this.isPollRunning = false;
      this.pollBiometryStatus();
    } else if (this.errorFromOSA && this.errorMessage) {
      this.loc.back();
    } else if (this.cameraOn || this.errorMessage) {
      this.tipsCarousel = true;
      this.cameraOn = false;
      this.errorMessage = false;
      this.showStatusFinished = false;
    }
  }

  private handleRetry() {
    this.store$
      .select('challenge')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(state => {
        if (state.isRetryBiometry) {
          this.restartFacial();
          this.store$.dispatch(new SetRetryBiometryChallenge(false));
        }
      });
  }

  private handleBiometryStage() {
    this.facialBiometryStage$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(stage => {
      if (stage === BiometryPictureEnum.SUCCESS) {
        const images = this.biometryManagerService.getImages();
        this.biometryManagerService.initiateBiometry();
        this.handleBiometrySuccess(images);
      }

      if (stage === BiometryPictureEnum.ERROR) {
        // const error = this.biometryManagerService.getError();
        this.goToErrorMessagePage();
      }
    });
  }

  private handleParamsQuery() {
    this.activatedRoute.queryParams.pipe(takeUntil(this.ngUnsubscribe)).subscribe(params => {
      if (params.uuid) {
        this.uuid = params.uuid;
        this.isCrossedFlux = true;
        // this.store$.dispatch(new GetClientCredentialsAction());
        // this.actionListener$
        //   .pipe(
        //     takeUntil(this.ngUnsubscribe),
        //     filter(v => v.type === StartupActionTypes.GET_CLIENT_CREDENTIALS_SUCCESS)
        //   )
        //   .subscribe(action => {
        //     this.store$.dispatch(new GetCrossedFluxInfoAction(params.uuid));
        //   });
      } else {
        this.isCrossedFlux = false;
      }
      this.store$.dispatch(new SetCrossedFluxAction(this.isCrossedFlux));
    });
  }

  private getQrCode() {
    this.store$
      .select('challenge')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(state => {
        this.errorFromOSA = false;
        if (state.getChallenge && state.getChallenge.challenges && state.getChallenge.challenges.biometry) {
          this.errorFromOSA = true;
          this.goToErrorMessagePage();
        }
        this.qrCodeLink = state.crossedFluxLink;

        if (state.crossedFluxLink) {
          this.uuid = state.crossedFluxLink.split('=')[1];
        }
      });
    this.store$.dispatch(new GetCrossedFluxLinkAction());
  }

  private restartFacial() {
    this.showFacial = false;
    setTimeout(() => {
      this.showFacial = true;
    }, 2000);
  }

  private pollBiometryStatus(): void {
    if (!this.isPollRunning) {
      this.isPollRunning = true;

      this.store$
        .select(selectLatestBiometryStatus)
        .pipe(takeUntil(this.pollingComplete))
        .subscribe(latestStatus => {
          const { status } = latestStatus;
          if (!status || status === BiometryPollingStatusEnum.PENDING) {
            // if (latestStatus.attempt < 25) {
            const payload = { uuid: this.uuid, delay: 6000 };
            this.store$.dispatch(new CheckBiometryStatusAction(payload));
            // }
          }

          if (status === BiometryPollingStatusEnum.APPROVED) {
            // this.biometryStatusProgress = BiometryStatusEnum.BIOMETRY_FAILED;
            this.isPollRunning = false;
            this.store$.dispatch(new GoToNextChallengeAction(latestStatus.nextStep));
          }

          if (status === BiometryPollingStatusEnum.REPROVED) {
            // this.biometryStatusProgress = BiometryStatusEnum.FINISHED;
            this.isPollRunning = false;
            this.router.navigate(['/login']);
          }
        });
    }
  }

  private getCurrentPlatform(): void {
    this.currentPlatform = {
      deviceInfo: this.deviceDetectorService.getDeviceInfo(),
      isMobile: this.deviceDetectorService.isMobile(),
      isDesktop: this.deviceDetectorService.isDesktop()
    };
  }

  private handleStartupChallenge() {
    // this.actionListener$
    //   .pipe(
    //     takeUntil(this.ngUnsubscribe),
    //     filter(v => v.type === ChallengeActionTypes.GET_CROSSED_FLUX_INFO_SUCCESS)
    //   )
    //   .subscribe(action => {
    //     this.store$
    //       .select('challenge')
    //       .pipe(first())
    //       .subscribe(state => {
    //         this.iv = state.getChallenge.iv;
    //         this.nonce = state.getChallenge.nonce;
    //       });
    //     this.isLoadingMobile = false;
    //     this.tipsCarousel = true;
    //     this.showStatusFinished = false;
    //   });
    this.tipsCarousel = true;
    this.isLoadingMobile = false;
    this.showStatusFinished = false;
  }

  private handleSuccess() {
    this.store$
      .select('challenge')
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(state => {
        if (state.crossedFluxSuccess) {
          this.cameraOn = false;
          this.errorMessage = false;
          this.tipsCarousel = false;
          this.isCrossedFluxSuccess = state.crossedFluxSuccess.success;
          this.showStatusFinished = true;
        }
      });
  }
}
