import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import { RememberUserAction, ResetLoginData, SubmitLogoutAction } from '@app/core/state/login/login.actions';
import * as fromProfile from '@app/core/state/profile';
import { GetClientCredentialsAction } from '@app/core/state/startup/startup.actions';
import * as fromTab from '@app/core/state/tab';
import { ProfileRenderService } from '@app/profile/service/profile-render.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { clearManagementPanelFiltersFromHeaderNavigation } from '@shared/functions/clear-mgm-flters-from-header';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { combineLatest, Subject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';
import { ExitPageComponent } from './../exit-page/exit-page.component';

@Component({
  selector: 'app-menu-profile',
  templateUrl: './menu-profile.component.html',
  styleUrls: ['./menu-profile.component.scss']
})
export class MenuProfileComponent implements OnInit, OnChanges, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() public subMenuData: {
    actionType: string;
    code: string;
    label: string;
    mobileOnly: boolean;
    position: number;
    targetAddress: string;
  }[];
  @Output() public closeSidenav = new EventEmitter<boolean>();

  public menus = [];
  public user: string;
  public storeName: string;
  public category;

  constructor(
    private router: Router,
    private profileRenderService: ProfileRenderService,
    private dialog: MatDialog,
    private store$: Store<AppState>,
    private sessionStorageService: SessionStorageService,
    private genTagger: GenTagger,
    private translate: TranslateService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.subMenuData && changes.subMenuData) {
      this.menus = this.subMenuData.filter(e => !e.mobileOnly);
    }
  }

  ngOnInit() {
    this.setProfileSubscription();
  }

  private setProfileSubscription(): void {
    const profile$ = this.store$.select(fromProfile.selectors.userProfileSelector).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(state => !!state)
    );

    const selectedStore$ = this.store$.select(fromTab.selectors.selectSelectedTab).pipe(
      takeUntil(this.ngUnsubscribe),
      filter(state => !!state)
    );

    combineLatest([profile$, selectedStore$]).subscribe(([profile, selectedStore]) => {
      this.storeName = selectedStore.name;
      if (profile.nickName) {
        this.user = profile.nickName;
        return;
      }
      const fullName = profile.name.split(' ');
      this.user = [fullName.splice(0, 1), fullName.splice(-1)].join(' ');
    });
  }

  public choiceActionLabel(actionType, targetAddress): void {
    switch (actionType) {
      case 'routeTo':
        clearManagementPanelFiltersFromHeaderNavigation(targetAddress, this.store$);

        this.router.navigate([targetAddress]);
        break;
      case 'callComponent':
        this.profileRenderService.loadComponent(targetAddress);
        break;
      default:
        console.error(actionType + ' not defined for: ' + targetAddress);
        break;
    }
  }

  public logout(): void {
    const dialogRef = this.dialog.open(ExitPageComponent, {
      width: '520px',
      disableClose: true,
      data: {}
    });
    this.closeSidenav.emit();

    dialogRef
      .afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result === 'CONTINUE') {
          dialogRef.close();
        }

        if (result === 'EXIT') {
          const { document, rememberUser } = this.sessionStorageService.getUser();

          this.router.navigate(['/']);
          this.store$.dispatch(new SubmitLogoutAction());
          this.store$.dispatch(new ResetLoginData());
          this.sessionStorageService.clearUser();
          this.store$.dispatch(new fromProfile.actions.ClearProfileAction());
          this.store$.dispatch(new GetClientCredentialsAction());

          if (rememberUser !== null && rememberUser) {
            this.store$.dispatch(new RememberUserAction(document));
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public menuClick(opcaoSelecionada) {
    this.category = window.location.href;
    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.Click,
      event_label: this.translate.instant(opcaoSelecionada)
    });
  }
}
