import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@app/core/state';
import {
  PostIdentificationResDTO,
  SubmitClientType,
  SubmitPersonType
} from '@app/showcase/models/api/post.identification.model';
import { Store } from '@ngrx/store';
import { Countries } from '@shared/constants/countries.enum';
import { DocumentTypeEnum } from '@shared/constants/document.enum';
import { StoreModel } from '@shared/models/store.model';
import { Observable, of } from 'rxjs';
import { FiltersModel } from '../models/filters.model';
import { InternationalizationService } from './../../../shared/services/internationalization/internationalization.service';

const storesMock: any[] = [
  {
    id: 1,
    name: 'Tok&Stok',
    address: {
      zip: '555',
      state: 'SP',
      city: 'São Paulo',
      neighborhood: 'Indianópolis',
      street: 'Av. Ibirapuera',
      number: '2904',
      complement: ''
    },
    phone: {
      internationalCode: '55',
      regionalCode: '0',
      number: '08002008000'
    },
    code: ''
  },
  {
    id: 2,
    name: 'Etna - Brooklin',
    address: {
      zip: '333',
      state: 'SP',
      city: 'São Paulo',
      neighborhood: 'Brooklin',
      street: 'Av. Dr. Chucri Zaidan',
      number: '281',
      complement: ''
    },
    phone: {
      internationalCode: '55',
      regionalCode: '11',
      number: '33868757'
    },
    code: ''
  }
];

@Injectable({
  providedIn: 'root'
})
export class StoresService {
  constructor(
    private http: HttpClient,
    private store$: Store<AppState>,
    private router: Router,
    private internationalizationService: InternationalizationService,
    private datePipe: DatePipe
  ) {}

  /**
   * Get all stores
   * @param filters Filters params
   */
  getStores(filters?: FiltersModel): Observable<StoreModel[]> {
    // START MOCK
    return of(storesMock);
    // END MOCK

    // Params for filter stores
    // code block commented out for being after the "return" and being pointed by sonar as issue
    // const params = new HttpParams();
    // if (filters) {
    //   params.set('state', filters.state);
    //   params.set('city', filters.city);
    //   params.set('latitude', filters.latitude);
    //   params.set('longitude', filters.longitude);
    //   params.set('search', filters.searchTerm);
    // }

    // return this.http.get<StoreModel[]>(`${environment.apiRootUrl}/domains/1.0.0/stores`, { params });
  }

  public handleSubmitResponse(submitResponse: PostIdentificationResDTO): void {
    // Response indicates flux to follow:

    // Maximum limit of proposals by customer or store reached.
    if (submitResponse.maximumLimitExceeded) {
      this.handleNoOffersAvailable(submitResponse);
    }
    // Proposal already exists for current user.
    if (submitResponse.proposal && submitResponse.proposal.id) {
      this.handleProposalAlreadyExists(submitResponse);
    }
    // Happy path, no constraints, user can continue with simulation.
    if (submitResponse.simulation && submitResponse.simulation.id) {
      this.router.navigate(['/proposal']);
    }
  }

  private handleNoOffersAvailable(submitResponse: PostIdentificationResDTO): void {
    const docType = submitResponse.identification.persons[0].documents[0].typeCode;
  }

  private handleProposalAlreadyExists(submitResponse: PostIdentificationResDTO): void {
    const country = this.internationalizationService.currentCountry;
    const buyer = submitResponse.identification.persons.find(person => person.clientType === SubmitClientType.BUYER);
    let docType;

    switch (country) {
      case Countries.BR:
        docType = DocumentTypeEnum.CPF;
        break;
      case Countries.AR:
        docType = buyer.personType === SubmitPersonType.PF ? DocumentTypeEnum.DNI_ARG : DocumentTypeEnum.CUIT;
        break;
      case Countries.PE:
        docType = buyer.personType === SubmitPersonType.PF ? DocumentTypeEnum.GENERIC : DocumentTypeEnum.RUC;
        break;
      case Countries.CO:
        docType = buyer.personType === SubmitPersonType.PF ? DocumentTypeEnum.GENERIC : DocumentTypeEnum.NIT;
        break;
      default:
        docType = DocumentTypeEnum.GENERIC;
        break;
    }

    const date = submitResponse.proposal.creationDate;
    const creationDate = this.datePipe.transform(date, 'dd/MM/yyyy');
  }
}
