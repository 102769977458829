import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { PaymentPlanType } from '@app/proposal-workflow/models/payment-plan-type.enum';
import { ConfirmationData } from '@app/proposal-workflow/models/proposal-data.model';
import { SubmitPersonType } from '@app/showcase/models/api/post.identification.model';
import { FormalizationProposalDTO } from '@app/showcase/proposals/models/api/query.proposal-list.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TaggerService } from '@app/tagging/tagger.service';
import { Subject } from 'rxjs';
import { BiometryTypeEnum } from '../biometry-controller/biometry-controller.component';

@Component({
  selector: 'app-card-condition',
  templateUrl: './card-condition.component.html',
  styleUrls: ['./card-condition.component.scss']
})
export class CardConditionComponent implements OnChanges, OnDestroy {
  constructor(public genTagger: GenTagger, public taggerService: TaggerService) {}
  protected ngUnsubscribe: Subject<any> = new Subject();
  @Input() proposal: FormalizationProposalDTO;
  @Input() crossedFlux = false;
  @Input() biometryType: BiometryTypeEnum;
  public proposalData: ConfirmationData;
  public paymentPlanType = PaymentPlanType;
  public personsType = SubmitPersonType;
  public biometryTypeEnum = BiometryTypeEnum;
  @Output() showStepper = new EventEmitter<boolean>();
  private category: string;

  OnInit(): void {
    this.category = `/portallojista/criarproposta/${this.proposalData.proposalData.product}/formalizacao`;
    if (this.crossedFlux) {
      this.category = `${this.category}/cliente`;
    }
    this.genTagger.setTag({
      event_category: this.category,
      event_action: Tag.pgView,
      event_label: 'Aqui voce podera assinar a proposta'
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.crossedFlux) {
      this.showStepper.emit(!changes.crossedFlux.currentValue);
    }
  }

  ngOnDestroy(): void {
    this.showStepper.emit(true);
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
