import { Component, OnDestroy, OnInit } from '@angular/core';
import { AppState } from '@app/core/state';
import { GetSubsegmentsAction, SaveSubsegmentAction } from '@app/core/state/showcase/showcase.actions';
import { selectSubsegmentsList } from '@app/core/state/showcase/showcase.selectors';
import { Store } from '@ngrx/store';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubsegmentModel } from '@shared/models/subsegment.model';

@Component({
  selector: 'app-subsegment',
  templateUrl: './subsegment.component.html',
  styleUrls: ['./subsegment.component.scss']
})
export class SubsegmentComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<any> = new Subject();

  constructor(private store$: Store<AppState>) {}

  public subsegments: SubsegmentModel[] = [];

  ngOnInit() {
    this.getSubsegments();
  }

  private getSubsegments(): void {
    this.store$
      .select(selectSubsegmentsList)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(subsegmentsList => {
        this.subsegments = Array.from(subsegmentsList);
      });

    this.store$.dispatch(new GetSubsegmentsAction());
  }

  selectSubsegment(selectedSubsegment: SubsegmentModel) {
    this.store$.dispatch(new SaveSubsegmentAction(selectedSubsegment));
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
