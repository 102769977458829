import { Injectable } from '@angular/core';
import { ContractService } from '@app/contracts/services/contract.service';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import * as actions from './contracts.actions';

@Injectable()
export class ContractsEffects {
  constructor(private actions$: Actions, private contractsService: ContractService) {}

  @Effect()
  getActiveContractsAction = this.actions$.pipe(
    ofType<actions.GetActiveContractsAction>(actions.ContractsActionTypes.GET_ACTIVE_CONTRACTS),
    switchMap(() =>
      this.contractsService.getAllContracts().pipe(
        switchMap(res => of(new actions.GetActiveContractsSuccessAction(res))),
        catchError(err => of(new actions.GetActiveContractsErrorAction(err)))
      )
    )
  );
}
