<input
  autocomplete="off"
  [formControl]="ngControl.control"
  [matAutocomplete]="auto"
  #trigger="matAutocompleteTrigger"
  (click)="handleDropdownClick($event, trigger)"
/>
<img
  matSuffix
  src="./assets/images/ic_arrow_down.svg"
  alt="icon right green"
  class="align-icon"
  (click)="onOpenPanel($event, trigger)"
/>
<mat-autocomplete [displayWith]="showLabelFn.bind(this)" #auto="matAutocomplete">
  <mat-option *ngFor="let item of filteredOptions | async" [value]="item[valueFrom]">
    {{ item[label] }}
  </mat-option>
</mat-autocomplete>
<ng-content></ng-content>
