import { ProfileActions, ProfileActionTypes } from './profile.actions';

enum InitialScreenOptions {
  NewProposal = 'CRIAR_PROP',
  Panel = 'LPRP'
}

export interface ProfileState {
  isMasterShopkeeper: boolean;
  isGcOrGr: boolean;
  menus: Array<any>;
  userProfile: ProfileData;
  userPreferences: any;
  homePage: string;
  error: any;
}

export interface ProfileData {
  name: string;
  documentNumber: string;
  phoneInternationalCode: string;
  phoneAreaCode: string;
  phoneNumber: string;
  email: string;
  userProfiles: string[];
  codeProfiles: string[];
  nickName?: string;
}

export const initialState: ProfileState = {
  isMasterShopkeeper: false,
  isGcOrGr: false,
  menus: null,
  userProfile: null,
  userPreferences: null,
  homePage: InitialScreenOptions.NewProposal,
  error: null
};

// userProfile: {
//   name: 'Valentina',
//   documentNumber: '00000000000',
//   email: 'valentina.souza@gmail.com',
//   phoneInternationalCode: '+55',
//   phoneAreaCode: '11',
//   phoneNumber: '977774444'
// },

export function profileReducer(state = initialState, action: ProfileActions): ProfileState {
  switch (action.type) {
    case ProfileActionTypes.PROFILE_INIT_REQUEST:
    case ProfileActionTypes.UPDATE_USER_PREFERENCES_ERROR: {
      return {
        ...state
      };
    }

    case ProfileActionTypes.PROFILE_INIT_REQUEST_SUCCESS: {
      return {
        ...state,
        menus: action.payload.menus,
        userProfile: action.payload.userProfile,
        userPreferences: action.payload.userPreferences,
        homePage: action.payload.homePage
      };
    }
    case ProfileActionTypes.PROFILE_INIT_REQUEST_ERROR: {
      return {
        ...state,
        menus: []
        // error: action.payload
        // menus: action.payload,
        // userProfile: action.payload.userProfile
      };
    }

    case ProfileActionTypes.PROFILE_UPDATE_PROFILE_TYPE: {
      const { isMasterShopkeeper, isGcOrGr } = action.payload;
      return { ...state, isMasterShopkeeper, isGcOrGr };
    }

    case ProfileActionTypes.UPDATE_USER_PROFILE_REQUEST: {
      return {
        ...state,
        userProfile: action.payload
      };
    }
    case ProfileActionTypes.UPDATE_USER_PROFILE_ERROR: {
      return {
        ...state,
        error: action.payload
      };
    }
    case ProfileActionTypes.UPDATE_USER_PREFERENCES_REQUEST:
    case ProfileActionTypes.UPDATE_USER_PREFERENCES_ACTION: {
      return {
        ...state,
        userPreferences: action.payload
      };
    }
    case ProfileActionTypes.CHANGE_INITIAL_SCREEN_SUCCESS: {
      return {
        ...state,
        homePage: action.payload.homePage,
        error: null
      };
    }
    case ProfileActionTypes.CHANGE_INITIAL_SCREEN_ERROR: {
      return {
        ...state,
        error: action.error
      };
    }
    case ProfileActionTypes.CLEAR_PROFILE: {
      return {
        ...initialState
      };
    }
    default:
      return state;
  }
}
