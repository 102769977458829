import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppState } from '@app/core/state';
import { environment } from '@env/environment';
import { Store } from '@ngrx/store';
import * as proposalInformationQuery from '@shared/graphql/queries/proposal-information.query';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AcceptedDataRequest } from '../models/accepted-data-request.model';
import {
  ClientSignUpData,
  GetProposalInformationDTO,
  ProposalParam,
  ProposalParameters
} from '../models/proposal-data.model';
import {
  RegisterAdditionalInformation,
  RegisterAddress,
  RegisterBacenAnswers,
  RegisterTravelersList,
  StepRegisterPut
} from '../models/step-register-put.model';
import {
  AccountValidatorRequest,
  AccountValidatorResponse,
  certifiedAgent,
  ConfirmationContractResponse,
  ProposalProceedRequest,
  ProposalProceedResponse
} from '../models/step-register-requests';

@Injectable({
  providedIn: 'root'
})
export class SimulationService {
  destroy$: Subject<boolean> = new Subject<boolean>();
  changeValuesSidebar: Subject<any> = new Subject<any>();

  public refGlobalUrl = environment.apiRootUrl;

  public _defineFlow = new BehaviorSubject<object>({
    value: ''
  });

  idPosition$: BehaviorSubject<string> = new BehaviorSubject(null);
  currentHeader = this.idPosition$.asObservable();

  constructor(private http: HttpClient, private readonly apollo: Apollo, private store$: Store<AppState>) {}

  get defineFlow(): any {
    return this._defineFlow.value;
  }
  set defineFlow(defineFlow: any) {
    this._defineFlow.next(defineFlow);
  }

  public getParametersProposal(): Observable<ProposalParameters> {
    return this.apollo
      .watchQuery<any>({
        query: proposalInformationQuery.GET_PARAMETERS_PROPOSAL,
        variables: {},
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: any) => {
          return response.data.getProposalParams;
        })
      );
  }

  public getProposalClients(proposalId?: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: proposalInformationQuery.GET_PROPOSAL_CLIENTS,
        variables: {
          proposalId
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: any) => {
          return response.data.getProposalRegisterScreenData;
        })
      );
  }

  public getProposalConfirmation(proposalCode?: string): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: proposalInformationQuery.GET_PROPOSAL_CONFIRMATION,
        variables: {
          proposalCode
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: any) => {
          return response.data.getProposalConfirmationDataByCode;
        })
      );
  }

  public getProposalInformationData(proposalId: string): Observable<GetProposalInformationDTO> {
    return this.apollo
      .watchQuery<any>({
        query: proposalInformationQuery.GET_PROPOSAL_INFORMATION,
        variables: {
          proposalId
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: any) => {
          return !!response.data ? response.data.getProposalRegisterScreenData : null;
        })
      );
  }

  public getClientsFromServices(proposalId: string): Observable<ClientSignUpData[]> {
    return this.apollo
      .watchQuery<any>({
        query: proposalInformationQuery.GET_PROPOSAL_INFORMATION_FROM_SERVICES,
        variables: {
          proposalId
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: any) => {
          return !!response.data && !!response.data.getClientsRegisterScreenData.clients
            ? response.data.getClientsRegisterScreenData.clients
            : null;
        })
      );
  }

  public getCities(state: string): Observable<ProposalParam[]> {
    return this.apollo
      .watchQuery<any>({
        query: proposalInformationQuery.GET_CITIES_BY_STATE,
        variables: {
          state
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: any) => {
          return response.data.getCities;
        })
      );
  }

  public getAddressByZipCode(zipCode: string) {
    return this.apollo
      .watchQuery<any>({
        query: proposalInformationQuery.GET_ADDRESS_BY_ZIP_CODE,
        variables: {
          zipCode
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: any) => {
          return response.data.getAddressByZipCode;
        })
      );
  }

  public getLegalRepresentativeRelationship(legalNatureCode: string): Observable<ProposalParam[]> {
    return this.apollo
      .watchQuery<any>({
        query: proposalInformationQuery.GET_LEGAL_REPRESENTATIVE_RELATIONSHIP,
        variables: {
          legalNatureCode
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: any) => {
          if (!response.data) {
            return null;
          }

          return response.data.getLegalRepresentativeRelationship;
        })
      );
  }

  public getProposalInstallments(proposalCode: string | number): Observable<any> {
    return this.apollo
      .watchQuery<any>({
        query: proposalInformationQuery.GET_PROPOSAL_INSTALLMENTS,
        variables: {
          proposalCode
        },
        errorPolicy: 'all',
        fetchPolicy: 'no-cache'
      })
      .valueChanges.pipe(
        map((response: any) => {
          return response.data.getProposalById;
        })
      );
  }

  public setProposalInformationData(proposalData: any, proposalId: string | number): Observable<any> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/proposal/register-data`;
    proposalData['proposalId'] = proposalId;
    proposalData['channel'] = 2;
    return this.http.put(URL, proposalData).pipe(catchError(() => of('UPDATE FAILED')));
  }

  public proposalAccountValidator(payload: AccountValidatorRequest): Observable<AccountValidatorResponse> {
    const URL = `${this.refGlobalUrl}/shopkeeper-simulation/validate/bank-account`;
    return this.http.post<AccountValidatorResponse>(URL, payload);
  }
  
  public getAgentCertified(proposalId: string): Observable<certifiedAgent>{
    const URL = `${this.refGlobalUrl}/shopkeeper/1.0.0/proposal/${proposalId}/certified-agent`;
    return this.http.get<certifiedAgent>(URL);
  }

  public proposalProceed(payload: ProposalProceedRequest): Observable<ProposalProceedResponse> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/proposal/proceed`;
    return this.http.post<ProposalProceedResponse>(URL, payload);
  }

  public proposalReverseProceed(payload: ProposalProceedRequest): Observable<ProposalProceedResponse> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/proposal/status-revert`;
    return this.http.post<ProposalProceedResponse>(URL, payload);
  }

  public setProposalAcceptedDataFlag(payload: AcceptedDataRequest): Observable<any> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/flag`;
    return this.http.post<any>(URL, payload);
  }

  public getProposalContract(proposalId: string, artifactId: string): Observable<ConfirmationContractResponse> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/artifact/${proposalId}?artifactId=${artifactId}`;
    return this.http.get<ConfirmationContractResponse>(URL);
  }

  public deleteTraveler(proposalId: string, travelerId: string): Observable<any> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/proposal/${proposalId}/traveler/${travelerId}`;
    return this.http.delete<any>(URL);
  }

  public getCertifiedAgents(storeId: string): Observable<any> {
    const URL = `${this.refGlobalUrl}/shopkeeper-cert-agents/1.0.0/shop/certified-agent?validsOnly=true`;
    return this.http.get<any>(URL);
  }

  public persistProposalCertifiedAgent(proposalId: string, payload: any): Observable<any> {
    const URL = `${this.refGlobalUrl}/shopkeeper/1.0.0/shop/certified-agent/proposal/${proposalId}`;
    return this.http.put<any>(URL, payload);
  }
  // STEP REGISTER SECTIONS PERSISTANCE
  public registerPersonPersist(payload: StepRegisterPut): Observable<ClientSignUpData> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/register/person`;
    return this.http.put<any>(URL, payload);
  }

  public getProposalAnalysisCd(proposalId: string): Observable<boolean> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/proposal/${proposalId}/analysis-cd`;
    return this.http.get<boolean>(URL);
  }

  public registerCompanyPersist(payload: StepRegisterPut): Observable<any> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/register/company`;
    return this.http.put<any>(URL, payload);
  }

  public registerAddressPersist(payload: RegisterAddress): Observable<any> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/register/address`;
    return this.http.put<any>(URL, payload);
  }

  public registerTravelersPersist(payload: RegisterTravelersList): Observable<RegisterTravelersList> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/register/travelers`;
    return this.http.put<any>(URL, payload);
  }

  public registerAdditionalInformationPersist(payload: RegisterAdditionalInformation): Observable<any> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/register/additional-information`;
    return this.http.put<any>(URL, payload);
  }

  public registerBacenAnswersPersist(payload: RegisterBacenAnswers): Observable<any> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/register/bacen-answers`;
    return this.http.post<any>(URL, payload);
  }

  public getOpenFinance(proposalId: string): Observable<Boolean> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/openFinance/${proposalId}`;
    return this.http.get<Boolean>(URL).pipe(catchError(() => of(false)));
  }
  public getOpenFinancePreAnalysis(proposalId: string): Observable<any> {
    const URL = `${this.refGlobalUrl}/shopkeeper-simulation/1.0.0/openFinance/bestOffer/${proposalId}`;
    return this.http.get<Boolean>(URL).pipe(catchError(() => of(false)));
  }

  public getOpenFinanceEnabled(produto: string): Observable<boolean> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/enable-open-finance?produto=${produto}`;
    return this.http.get<boolean>(URL).pipe(catchError(() => of(false)));
  }

  // CHANGE REQUESTED: NEW ENDPOINT TO SAVE REGISTER DATA WHOLE PAGE ON THE CONTINUE BUTTON
  public proceedAndSaveRegister(payload: any): Observable<ProposalProceedResponse> {
    const URL = `${this.refGlobalUrl}/shopkeeper-structure/1.0.0/proposal/register`;
    return this.http.put<ProposalProceedResponse>(URL, payload);
  }
}
