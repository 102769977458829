<ng-container class="video-container">
  <video
    id="video"
    *ngIf="!scanTaken"
    webkit-playsinline="true"
    playsinline="true"
    reload="auto"
    class="pion-video"
    #videoElement
    autoplay
  ></video>

  <div class="container">
    <div class="row message-row">
      <div class="col text-center">
        <span *ngIf="cameraReady && !scanTaken" class="scan-message">{{
          'DOC-SCAN-MESSAGE-POSITION-DOCUMENT' | translate
        }}</span>
        <span *ngIf="scanTaken" class="scan-message">
          {{ 'DOC-SCAN-MESSAGE-IS-IT-READABLE' | translate }}
        </span>
      </div>
    </div>

    <div class="row button-row">
      <div class="col-12 text-center" *ngIf="!scanTaken">
        <button
          *ngIf="cameraReady && !scanTaken"
          (click)="scanDocument()"
          class="pion-photo-capture"
          mat-raised-button
          color="primary"
        >
          {{ 'TAKE-PICTURE' | translate }}
        </button>
      </div>
      <div class="col-6 text-center" *ngIf="scanTaken">
        <button *ngIf="scanTaken" (click)="retake()" class="pion-photo-capture" mat-raised-button color="secondary">
          {{ 'TAKE-ANOTHER' | translate }}
        </button>
      </div>
      <div class="col-6 text-center" *ngIf="scanTaken">
        <button (click)="acceptDocument()" class="pion-photo-capture" mat-raised-button color="primary">
          {{ 'Yes' | translate }}
        </button>
      </div>
    </div>
  </div>

  <canvas class="canvas" id="canvasImage"></canvas>
  <div class="center">
    <div></div>
  </div>
</ng-container>
