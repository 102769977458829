<mat-accordion class="subsidiary-value-accordion">
  <mat-expansion-panel hideToggle (opened)="isExpanded = true" (closed)="isExpanded = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        Quanto vou receber?
        <img src="./assets/images/chevron-down.svg" alt="chevron-down" *ngIf="!isExpanded" />
        <img src="./assets/images/chevron-up.svg" alt="chevron-up" *ngIf="isExpanded" />
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-container>
      <div class="subsidiary-value">
        <label for="" class="subsidiary-value__label">Taxa cliente</label>
        <p class="subsidiary-value__value">{{ shopkeeperRate | localePercentage }}</p>
      </div>

      <div class="subsidiary-value">
        <label for="" class="subsidiary-value__label">Taxa lojista</label>
        <p class="subsidiary-value__value">{{ buyerRate | localePercentage }}</p>
      </div>

      <!-- Subsidiary form -->
      <form [formGroup]="form">
        <div class="subsidiary-value" *ngIf="subsidyData?.showSubsidySlider">
          <label for="subsidiarySlider" class="subsidiary-value__label">Subsídio</label>
          <div class="subsidiary-value-slider">
            <input
              type="range"
              id="subsidiarySlider"
              formControlName="subsidiarySlider"
              step="{{ stepValue }}"
              min="{{ minValue }}"
              max="{{ maxValue }}"
            />
            <div class="subsidiary-value-slider__steps">
              <span>{{ minValue }}%</span>
              <span id="subsidySliderSelectedRange">
                <b *ngIf="showSelectedRange">
                  {{ form?.get('subsidiarySlider')?.value }}%
                </b>
              </span>
              <span>{{ maxValue }}%</span>
            </div>
          </div>
        </div>

        <div class="subsidiary-value">
          <label for="" class="subsidiary-value__label">Valor subsídio</label>
          <p *ngIf="!editValues" class="subsidiary-value__value">
            {{ form?.get('subsidiaryValue')?.value | localeCurrency }}
          </p>
          <mat-form-field *ngIf="editValues">
            <input
              class="text-left input-value"
              matInput
              currencyMask
              formControlName="subsidiaryValue"
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',', allowNegative: false }"
              maxlength="11"
            />
          </mat-form-field>
        </div>
      </form>

      <div class="subsidiary-value">
        <label for="" class="subsidiary-value__label">Valor liberado</label>
        <p class="subsidiary-value__value">{{ releasedAmount | localeCurrency }}</p>
      </div>

      <div class="subsidiary-value">
        <label for="" class="subsidiary-value__label">Retenção</label>
        <p class="subsidiary-value__value">{{ retentionPercentage | localePercentage }}</p>
      </div>

      <div class="subsidiary-value">
        <!-- <button
          mat-stroked-button
          color="primary"
          class="w-100 edit-button"
          (click)="changeValues()"
          *ngIf="!editValues"
        >
          Editar
        </button>
        <button
          mat-stroked-button
          color="primary"
          class="w-100 edit-button"
          (click)="changeValues()"
          *ngIf="editValues"
        >
          Salvar alterações
        </button> -->
      </div>
    </ng-container>
  </mat-expansion-panel>
</mat-accordion>
