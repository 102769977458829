import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { StartupService } from '@app/core/startup/services/startup.service';
import { AppState } from '@app/core/state';
import { GetTerms } from '@app/core/state/domains/domains.actions';
import * as profileActions from '@app/core/state/profile/profile.actions';
import { ResetPersonalizedOffersDataAction, ResetShowcaseAction } from '@app/core/state/showcase/showcase.actions';
import * as fromTab from '@app/core/state/tab';
import { ExpirationModalComponent } from '@app/login/components/expiration-modal/expiration-modal.component';
import { MfaModalComponent } from '@app/login/components/mfa-modal/mfa-modal.component';
import { TokenStatus } from '@app/login/interface/TokenStatus.enum';
import { LoginService } from '@app/login/services/login.service';
import { PasswordRecoveryService } from '@app/login/services/password-recovery.service';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { ExtendedTag } from '@app/tagging/tagging-iface';
import { environment } from '@env/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PionModalComponent } from '@shared/components/pion-modal/pion-modal.component';
import { LoginErrorEnum } from '@shared/constants/login-error.enum';
import { DocumentTypeService } from '@shared/services/document-type/document-type.service';
import { LgpdService } from '@shared/services/lgpd/lgpd.service';
import { SessionStorageService } from '@shared/services/session-storage/session-storage.service';
import { ShopkeeperService } from '@shared/services/shopkeeper/shopkeeper.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { EMPTY, of } from 'rxjs';
import { catchError, filter, first, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';
import * as tabActions from '../tab/tab.actions';
import * as actions from './login.actions';
import { selectRecoveryId } from './login.selectors';

@Injectable()
export class LoginEffects {
  private userInfo = null;
  readonly category = '/portallojista/login';
  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private router: Router,
    private loginService: LoginService,
    private startupService: StartupService,
    private readonly dialog: MatDialog,
    private sessionStorageService: SessionStorageService,
    private passwordRecoveryService: PasswordRecoveryService,
    private snackBar: MatSnackBar,
    private translateService: TranslateService,
    private lgpdService: LgpdService,
    private documentService: DocumentTypeService,
    private shopkeeperService: ShopkeeperService,
    private genTagger: GenTagger,
    private deviceService: DeviceDetectorService,
    private ngbModal: NgbModal
  ) {}

  get eventOpenModalForgotPassword(): ExtendedTag {
    return {
      event_action_type: Tag.AbriuModal,
      event_action_complement: 'Sua senha está bloqueada.'
    };
  }

  get eventOpenModal(): ExtendedTag {
    return {
      event_action_type: Tag.AbriuModal,
      event_action_complement: 'Gerenciar dispositivos'
    };
  }

  get closeModalForgotPassword(): ExtendedTag {
    return {
      event_action_type: Tag.Fechou_Modal,
      event_action_complement: 'Sua senha está bloqueada.'
    };
  }

  get closeModal(): ExtendedTag {
    return {
      event_action_type: Tag.Fechou_Modal,
      event_action_complement: 'Gerenciar dispositivos.'
    };
  }

  get openModalCertificatedAgent(): ExtendedTag {
    return {
      event_action_type: Tag.AbriuModal,
      event_action_complement: 'Agente certificado não cadastrado'
    };
  }

  get closeModalCertificatedAgent(): ExtendedTag {
    return {
      event_action_type: Tag.Fechou_Modal,
      event_action_complement: 'Agente certificado não cadastrado'
    };
  }

  @Effect()
  submitLoginDataAction = this.actions$.pipe(
    ofType<actions.SubmitLoginDataAction>(actions.LoginActionTypes.SUBMIT_LOGIN_DATA),
    switchMap(action =>
      this.loginService.login(action.payload).pipe(
        switchMap(response => {
          if (response.auth2Data?.is2Auth) {
            const dialogRef = this.ngbModal.open(MfaModalComponent);
            dialogRef.componentInstance.data = action.payload;
            return dialogRef.result;
          }

          return of(response);
        }),
        switchMap(response => {
          const userInfo = this.sessionStorageService.authTokenModelConverter(
            { ...response, rememberUser: action.payload.rememberUser },
            action.payload.documentNumber
          );

          const _actions : any = [new actions.SubmitLoginDataSuccessAction({...userInfo, ...action.payload})]

          if (!userInfo.accessToken && !JSON.parse(environment.newGateway)) {
            return _actions
           }

           if (!JSON.parse(environment.newGateway)) {
            _actions.push(new actions.GetUserProperties(action.payload.documentNumber))
           }

          this.sessionStorageService.setUser(userInfo);

          return [
            ..._actions,
            new ResetPersonalizedOffersDataAction(),
            new ResetShowcaseAction(),
            new profileActions.ClearProfileAction()
          ];
        }),
        catchError(err => {
          let nextAction: any = new actions.SubmitLoginDataErrorAction('');
          if (err.status === 406) {
            this.handleExcessiveSessions(action.payload, actions.SubmitLoginDataAction);
            return [new actions.SubmitLoginDataErrorAction('login_cancelled_by_user')];
          } else if (err && err.error && err.error.errorId === LoginErrorEnum.USER_BLOCKED) {
            nextAction = new actions.SubmitLoginBlockDataErrorAction('block_user');
          } else if (err.status === 401 || err.status === 500) {
            nextAction = new actions.SubmitLoginDataIncorrectAction({
              errorType: 'invalid_grant',
              errorBody: err.error
            });
          }
          return of(nextAction);
        })
      )
    )
  );

  @Effect({ dispatch: false })
  SubmitLoginDataIncorrectAction = this.actions$.pipe(
    ofType<actions.SubmitLoginDataIncorrectAction>(actions.LoginActionTypes.SUBMIT_LOGIN_DATA_INCORRECT),
    tap(action => {
      const message =
        action.payload.errorBody && action.payload.errorBody.passwordTries
          ? 'Digite os dados novamente. Tentativas restantes:' + action.payload.errorBody.passwordTries
          : 'Tente novamente ou utilize a opção "Esqueci minha senha" para recuperar seu acesso.';
      const title = 'Usuário ou Senha Inválidos.';

      const passDialog = this.dialog.open(PionModalComponent, {
        data: {
          title: title,
          description: message,
          confirmText: 'OK',
          cancelText: null,
          type: 'alert'
        },
        maxWidth: '500px'
      });
      const modalAction: ExtendedTag = {
        event_action_type: Tag.AbriuModal,
        event_action_complement: title
      };
      this.genTagger.setTag({
        event_action: modalAction,
        event_category: '/portallojista/escolhadeloja',
        event_label: `${message}`
      });
      passDialog
        .afterClosed()
        .pipe(first())
        .subscribe(() => {
          const modalCloseAction: ExtendedTag = {
            event_action_type: Tag.Fechou_Modal,
            event_action_complement: title
          };
          this.genTagger.setTag({
            event_action: modalCloseAction,
            event_category: '/portallojista/escolhadeloja',
            event_label: `${message}`
          });
        });
    })
  );

  @Effect({ dispatch: false })
  submitLoginBlockDataActionError = this.actions$.pipe(
    ofType<actions.SubmitLoginBlockDataErrorAction>(actions.LoginActionTypes.SUBMIT_LOGIN_DATA_BLOCK),
    tap(() => {
      const data = {
        title: this.translateService.instant('LOGIN-MODAL-PASSWORD-BLOCK'),
        description: this.translateService.instant('LOGIN-MODAL-PASSWORD-BLOCK-MSG'),
        confirmText: this.translateService.instant('RECOVER-PASSWORD-TITLE'),
        cancelText: 'Fechar',
        type: 'alert'
      };
      const dialog = this.dialog.open(PionModalComponent, {
        data,
        maxWidth: '610px'
      });
      dialog.afterClosed().subscribe(confirm => {
        if (confirm) {
          this.genTagger.setTag({
            event_category: '/portallojista/recuperarsenha',
            event_action: `${Tag.Fechou_Modal} - ${data.title}`,
            event_label: data.confirmText
          });
          this.router.navigate(['/login/recovery/pre-token', { fType: 'password-recovery' }]);
        } else {
          this.genTagger.setTag({
            event_category: '/portallojista/recuperarsenha',
            event_action: `${Tag.Fechou_Modal} - ${data.title}`,
            event_label: data.cancelText
          });
        }
      });
      dialog
        .afterOpened()
        .pipe(take(1))
        .subscribe(() => {
          this.genTagger.setTag({
            event_category: '/portallojista/login',
            event_action: this.eventOpenModalForgotPassword,
            event_label: this.translateService.instant('LOGIN-MODAL-PASSWORD-BLOCK-MSG')
          });
        });
    })
  );

  @Effect({ dispatch: false })
  submitLogoutAction = this.actions$.pipe(
    ofType<actions.SubmitLoginDataAction>(actions.LoginActionTypes.SUBMIT_LOGOUT),
    switchMap(action => this.loginService.logout().pipe(catchError(() => EMPTY)))
  );

  @Effect()
  submitLoginDataSuccessAction = this.actions$.pipe(
    ofType<actions.SubmitLoginDataSuccessAction>(actions.LoginActionTypes.SUBMIT_LOGIN_DATA_SUCCESS),
    switchMap(action => {
      if (JSON.parse(environment.newGateway)) {
        return this.loginService.getStores(action.payload);
      } else {
        return of({ stores: action.payload.stores });
      }
    }),
    map(res => {
      this.router.navigate(['/login/select-store']);
      return new tabActions.GetTabsSuccess(res.stores);
    })
  );

  @Effect()
  submitLoginWithStoreAction = this.actions$.pipe(
    ofType<actions.SubmitLoginWithStoreAction>(actions.LoginActionTypes.SUBMIT_LOGIN_WITH_STORE_DATA),
    switchMap(action =>
      this.loginService.loginWithStore(action.payload).pipe(
        map(response => {
          this.userInfo = this.sessionStorageService.authTokenModelConverter(response, action.payload.documentNumber);
          this.sessionStorageService.setUser(this.userInfo);
          return new actions.GetUserProperties(action.payload.documentNumber);
        })
      )
    )
  );

  @Effect()
  revokeToken = this.actions$.pipe(
    ofType<actions.RevokeToken>(actions.LoginActionTypes.REVOKE_TOKEN),
    switchMap(action =>
      this.startupService.revokeToken(action.payload).pipe(
        switchMap(() => [new actions.RevokeTokenSuccess()]),
        catchError(err => of(new actions.RevokeTokenError(err.error.error)))
      )
    )
  );

  @Effect({ dispatch: false })
  getUserProperties = this.actions$.pipe(
    ofType<actions.GetUserProperties>(actions.LoginActionTypes.GET_USER_PROPERTIES),
    withLatestFrom(this.store$.select(fromTab.selectors.selectSelectedTab)),
    switchMap(([action, selectedTab]) =>
      this.startupService.getUserInfos(action.payload).pipe(
        map(response => {
          const flagAfterShowTerms = action.payload.isAfterShowTerms ? action.payload.isAfterShowTerms : null;
          if (flagAfterShowTerms) {
            this.store$.dispatch(new actions.GetUserPropertiesSuccess(response));
            return;
          }

          const isShowModalDaysToExpire = response.daysToExpire <= environment.minimumExpireDays;
          this.store$.dispatch(new actions.GetUserPropertiesSuccess(response));

          if (isShowModalDaysToExpire) {
            const modalExpiration = this.handleOpenExpirationModal(response.daysToExpire);
            modalExpiration.componentInstance.goToReset.subscribe(() => {
              this.router.navigate(['/login/recovery/pre-token', { fType: 'password-recovery' }]);
            });
            modalExpiration.componentInstance.skip.subscribe(() => {
              this.store$.dispatch(new profileActions.ProfileRequest(selectedTab.code));
              this.hasNoAccreditedAgentModal(selectedTab.code);
            });
            return;
          } else if (!response.showTerms && !isShowModalDaysToExpire) {
            this.hasNoAccreditedAgentModal(selectedTab.code);
            this.store$.dispatch(new profileActions.ProfileRequest(selectedTab.code));
          } else if (response.showTerms) {
            this.store$.dispatch(new GetTerms());
          }
          this.checkCompatibility();
        }),
        catchError(error => {
          const snackbarConfig: MatSnackBarConfig = {
            duration: 4000
          };

          this.snackBar.open(this.translateService.instant('GET-USER-INFOS-ERROR'), null, snackbarConfig);

          return of(new actions.GetUserPropertiesError(error.message));
        })
      )
    )
  );

  @Effect()
  submitRecoveryTokenAction = this.actions$.pipe(
    ofType<actions.SubmitRecoveryTokenAction>(actions.LoginActionTypes.SUBMIT_RECOVERY_TOKEN),
    withLatestFrom(this.store$.select(selectRecoveryId)),
    switchMap(([action, recoveryId]) =>
      this.passwordRecoveryService.postRecoveryToken(action.payload, recoveryId).pipe(
        map(response => {
          if (response.status === TokenStatus.TOKEN_APPROVED) {
            return new actions.SubmitRecoveryTokenSuccessAction(response);
          } else {
            return new actions.SubmitRecoveryTokenErrorAction(response);
          }
        }),
        catchError(error => of(new actions.SubmitRecoveryTokenErrorAction(error)))
      )
    )
  );

  @Effect()
  refreshRecoveryTokenAction = this.actions$.pipe(
    ofType<actions.RefreshRecoveryTokenAction>(actions.LoginActionTypes.REFRESH_RECOVERY_TOKEN),
    withLatestFrom(this.store$.select(selectRecoveryId)),
    switchMap(([action, recoveryId]) =>
      this.passwordRecoveryService.refreshRecoveryToken(recoveryId).pipe(
        map(response => new actions.RefreshRecoveryTokenSuccessAction(response)),
        catchError(err => of(new actions.RefreshRecoveryTokenErrorAction(err)))
      )
    )
  );

  @Effect()
  submitNewPasswordAction = this.actions$.pipe(
    ofType<actions.SubmitNewPasswordAction>(actions.LoginActionTypes.SUBMIT_NEW_PASSWORD),
    withLatestFrom(this.store$.select(selectRecoveryId)),
    switchMap(([action, recoveryId]) =>
      this.passwordRecoveryService.postNewPassword(action.payload, recoveryId).pipe(
        map(response => {
          if (response.updated) {
            const snackbarConfig: MatSnackBarConfig = {
              duration: 2000
            };

            // Redirect to login
            this.router.navigate(['/login']);

            // Open snackbar with success message
            this.snackBar.open(this.translateService.instant('PASSWORD-UPDATED'), null, snackbarConfig);

            return new actions.ResetLoginData();
          }

          return EMPTY;
        }),
        catchError(err => of(new actions.SubmitNewPasswordErrorAction(err)))
      )
    )
  );

  @Effect()
  getRecoverytoken = this.actions$.pipe(
    ofType<actions.GetRecoveryToken>(actions.LoginActionTypes.GET_RECOVERY_TOKEN),
    switchMap(({ payload }) =>
      this.passwordRecoveryService.getRecoveryToken(payload.value, payload.deliveryMethod).pipe(
        switchMap(response => {
          this.router.navigate(['login/recovery/recovery-token']);

          return [new actions.GetRecoveryTokenSuccess(response)];
        }),
        catchError(err => of(new actions.GetRecoveryTokenError(err.error.error)))
      )
    )
  );

  @Effect()
  updateUserFlags = this.actions$.pipe(
    ofType<actions.UpdateUserFlags>(actions.LoginActionTypes.UPDATE_USER_FLAGS),
    switchMap(action => {
      return this.startupService.updateUserTutorialFlags(action.payload).pipe(
        switchMap(response => [new actions.UpdateUserFlagsSuccess(response)]),

        catchError(err => of(new actions.UpdateUserFlagsError(err.error.error)))
      );
    })
  );

  @Effect()
  getDocumentTypes = this.actions$.pipe(
    ofType<actions.DocumentTypesRequest>(actions.LoginActionTypes.DOCUMENT_TYPES_REQUEST),
    switchMap(() =>
      this.documentService.getDocumentTypes().pipe(
        map(({ loginTypes }) => {
          const documentList = loginTypes.map(tipos =>
             ({
              loginTypeId: 3,
              loginDescription: tipos.loginTypeDesc,
              personType: tipos.personType,
              documentFlag: tipos.documentFlag,
              documentId: tipos.documentId,
              documentMaxLength: tipos.charMaxAmount,
              documentMinLength: tipos.charMaxAmount
            })
          );
          return new actions.DocumentTypesRequestSuccess(documentList);
        }),
        catchError(() => of(new actions.RefreshRecoveryTokenAction()))
      )
    )
  );

  private handleOpenExpirationModal(daysToExpire: any) {
    return this.dialog.open(ExpirationModalComponent, {
      disableClose: true,
      id: 'expiration-modal-component',
      width: '510px',
      autoFocus: false,
      data: {
        daysToExpire: daysToExpire
      }
    });
  }

  handleExcessiveSessions(payload, action) {
    const data = {
      title: 'Gerenciar dispositivos',
      description: `Parece que a sua conta já está conectada em outro dispositivo.
        Para usar aqui, precisamos desconectar sua conta no outro dispositivo aberto.`,
      confirmText: 'Desconectar outro dispositivo',
      cancelText: 'Agora não',
      type: 'alert',
      largeButton: true
    };
    const dialog = this.dialog.open(PionModalComponent, {
      data
    });
    dialog.afterClosed().subscribe(res => {
      if (res) {
        this.store$.dispatch(new action({ ...payload, revokeSession: true }));
        this.genTagger.setTag({
          event_category: '/portallojista/login',
          event_action: `${Tag.Fechou_Modal} - ${data.title}`,
          event_label: data.confirmText
        });
      } else {
        const secondData = {
          title: 'Gerenciar dispositivos',
          description: 'Não foi possível fazer o login. Desconecte sua conta de outro dispositivo para continuar.',
          confirmText: 'OK',
          type: 'error'
        };
        const secondDialog = this.dialog.open(PionModalComponent, {
          data: secondData
        });
        secondDialog.afterOpened().subscribe(() => {
          this.genTagger.setTag({
            event_category: '/portallojista/login',
            event_action: `${Tag.AbriuModal} - ${secondData.title}`,
            event_label: secondData.description
          });
        });
        secondDialog.afterClosed().subscribe(() => {
          this.router.navigate(['/login']);
          this.genTagger.setTag({
            event_category: '/portallojista/login',
            event_action: `${Tag.Fechou_Modal} - ${secondData.title}`,
            event_label: secondData.confirmText
          });
        });
        this.genTagger.setTag({
          event_category: '/portallojista/login',
          event_action: `${Tag.Fechou_Modal} - ${data.title}`,
          event_label: data.cancelText
        });
      }
    });
    dialog
      .afterOpened()
      .pipe(take(1))
      .subscribe(() => {
        this.genTagger.setTag({
          event_category: '/portallojista/login',
          event_action: `${Tag.AbriuModal} - ${data.title}`,
          event_label: data.description
        });
      });
  }

  hasNoAccreditedAgentModal(shopCode) {
    let dontShowModal = false;
    this.store$
      .select(fromTab.selectors.selectProducts)
      .pipe(
        filter(data => !!data && data.length > 0),
        first()
      )
      .subscribe(products => {
        products.map(product => {
          if (product.code === 'CDC' && !dontShowModal) {
            dontShowModal = true;
            this.shopkeeperService
              .getAgent(shopCode)
              .pipe(first())
              .subscribe(
                res => {
                  if (res.length === 0) {
                    this.openAccreditedAgentModal();
                  }
                },
                error => {
                  this.openAccreditedAgentModal();
                }
              );
          }
        });
      });
  }

  checkCompatibility() {
    const device = this.deviceService.getDeviceInfo();
    const os = device.os.toLowerCase();
    const browser = device.browser.toLowerCase();
    if (os === 'mac') {
      if (browser !== 'safari') {
        this.openModal('MAC');
      }
    } else {
      if (browser !== 'chrome' && browser !== 'firefox') {
        this.openModal('WIN');
      }
    }
  }

  openModal(currentOs = 'WIN') {
    this.dialog.open(PionModalComponent, {
      maxWidth: '80vw',
      width: '500px',
      data: {
        title: this.translateService.instant('INVALID-BROWSER-TITLE'),
        description: this.translateService.instant(`INVALID-BROWSER-${currentOs}`),
        confirmText: this.translateService.instant('INVALID-BROWSER-BUTTON'),
        type: ''
      }
    });
  }

  openAccreditedAgentModal() {
    const data = {
      title: this.translateService.instant('CERTIFIED-AGENT-NOT-EXISTS'),
      description: this.translateService.instant('CERTIFIED-AGENT-NOT-EXISTS-MSG'),
      confirmText: 'Entendi',
      type: 'error',
      event_category: '/portallojista/escolhadeloja'
    };
    const noAgentAlert = this.dialog.open(PionModalComponent, {
      data,
      width: '500px'
    });
    noAgentAlert.afterOpened().subscribe(() =>
      this.genTagger.setTag({
        event_category: '/portallojista/escolhadeloja',
        event_action: this.openModalCertificatedAgent,
        event_label: 'Agente certificado não cadastrado'
      })
    );
    noAgentAlert.afterClosed().subscribe(() =>
      this.genTagger.setTag({
        event_category: '/portallojista/escolhadeloja',
        event_action: this.closeModalCertificatedAgent,
        event_label: data.confirmText
      })
    );
  }
}
