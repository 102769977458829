<!-- DESKTOP VERSION -->
<nav mat-tab-nav-bar class="d-none d-md-block sticky">
  <a
    mat-tab-link
    *ngFor="let link of navLinks"
    [routerLink]="link.link"
    routerLinkActive
    #rla="routerLinkActive"
    [active]="rla.isActive"
    [class.disabled]="true"
  >
    {{ link.label | translate }}
  </a>
</nav>

<!-- MOBILE VERSION -->
<nav class="d-block d-md-none">
  <div class="row no-gutters title">
    <div class="col-12 text-center">
      <div class="row no-gutters stepper-header">
        <div class="col-1">
          <button
            class="button-back"
            *ngIf="
              url === '/proposal/step-confirmation' || url === '/proposal/step-register';
              else simulationBackButton
            "
            (click)="handleBackButton()"
          >
            <img src="./assets/images/ic_arrow_left.svg" alt="Previous" />
          </button>
          <ng-template #simulationBackButton>
            <button
              class="button-back"
              *ngIf="url !== '/proposal/step-formalization'"
              [routerLink]="['/proposal/step-simulation']"
            >
              <img src="./assets/images/ic_arrow_left.svg" alt="Previous" />
            </button>
          </ng-template>
        </div>
        <div class="col-10 d-flex justify-content-center">
          <span>
            {{ currentStepLabel | translate }}
          </span>
        </div>
        <div class="col-1">
          <button *ngIf="!isCrossedFlux" (click)="close.emit()">
            <img src="./assets/images/ic_close.svg" alt="button-close" />
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col col-stepper-mobile">
      <div [ngClass]="url.includes('proposal/step-simulation') || url === '/proposal' ? 'bar' : 'bar-disabled'"></div>
      <div [ngClass]="url === '/proposal/step-register' ? 'bar' : 'bar-disabled'"></div>
      <div [ngClass]="url === '/proposal/step-confirmation' ? 'bar' : 'bar-disabled'"></div>
      <div class="mr-0" [ngClass]="url === '/proposal/step-formalization' ? 'bar' : 'bar-disabled'"></div>
    </div>
  </div>
</nav>
