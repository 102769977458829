import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ProceedErrorModalData } from '@app/proposal-workflow/models/proceed-error-modal-data.model';
import { GenTagger } from '@app/tagging/gen-tagger';
import { Tag } from '@app/tagging/tagger.directive';
import { TranslateService } from '@ngx-translate/core';
import { ProductCodeEnum } from '@shared/constants/product.enum';

@Component({
  selector: 'app-proceed-error-modal',
  templateUrl: './proceed-error-modal.component.html',
  styleUrls: ['./proceed-error-modal.component.scss']
})
export class ProceedErrorModalComponent implements OnInit, OnDestroy {
  public event_category: string;

  constructor(
    public dialogRef: MatDialogRef<ProceedErrorModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ProceedErrorModalData,
    private genTagger: GenTagger,
    private translateService: TranslateService
  ) {
    if (this.data.product === ProductCodeEnum.CDC) {
      this.event_category = '/portallojista/criarproposta/cdc/cadastro';
    } else if (this.data.product === ProductCodeEnum.CSC) {
      this.event_category = '/portallojista/criarproposta/csc/cadastro';
    }
  }

  ngOnInit() {
    this.genTagger.setTag({
      event_category: this.event_category,
      event_label: this.translateService.instant(this.data.msg),
      event_action: `${Tag.AbriuModal} - ${this.translateService.instant(this.data.title)}`
    });
  }

  ngOnDestroy(): void {
    this.genTagger.setTag({
      event_category: this.event_category,
      event_label: this.translateService.instant(this.data.btnLabel),
      event_action: `${Tag.Fechou_Modal} - ${this.translateService.instant(this.data.title)}`
    });
  }
}
